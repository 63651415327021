import { Measurement, MeasurementPostBody } from '@drainify/types';
import { isMeasurement } from '@drainify/utils';
import { Text } from 'preshape';
import React from 'react';
import Distance from '../DistanceInput/Distance';
import { useHasPermission } from '../Permissions/Permissions';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import SummaryCard from '../SummaryCard/SummaryCard';
import SummaryCardSection from '../SummaryCard/SummaryCardSection';

type Props = {
  measurement?: Measurement | MeasurementPostBody;
  readOnly?: boolean;
};

const MeasurementSummary = ({ measurement }: Props) => {
  const { editMeasurement } = useReportEditorContext();
  const hasUpdateProjectPermission = useHasPermission('projects:update');
  const { activeBooking } = useProjectContext();

  const editStep = (step: string) => {
    if (!hasUpdateProjectPermission || !!activeBooking?.completedAt) {
      return undefined;
    }

    return () => {
      if (isMeasurement(measurement)) {
        editMeasurement(measurement.uid, step);
      } else {
        editMeasurement(null, step);
      }
    };
  };

  return (
    <SummaryCard title="Measurement">
      <SummaryCardSection
        title="Name"
        onEdit={editStep('name')}
        value={measurement?.name}
      >
        <Text>{measurement?.name}</Text>
      </SummaryCardSection>
      <SummaryCardSection
        title="Description"
        onEdit={editStep('description')}
        value={measurement?.description}
      >
        <Text>{measurement?.description}</Text>
      </SummaryCardSection>
      <SummaryCardSection
        title="Length"
        onEdit={editStep('length')}
        value={measurement?.length}
      >
        <Distance value={measurement?.length} type={'long'} />
      </SummaryCardSection>
    </SummaryCard>
  );
};

export default MeasurementSummary;
