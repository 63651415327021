import React, { PropsWithChildren } from 'react';
import Card, { CardProps } from '../../../components/Card/Card';
import './PageMenu.css';

export type PageMenuProps = PropsWithChildren<CardProps>;

const PageMenu = (props: PageMenuProps) => {
  return <Card {...props} padding="x1" />;
};

export default PageMenu;
