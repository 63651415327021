import { BoxProps, sizeX3Px } from 'preshape';
import React from 'react';
import PlaceholderBlock from './PlaceholderBlock';

type Props = BoxProps;

const PlaceholderLabel = ({
  height = '1rem',
  width = '6rem',
  ...rest
}: Props) => {
  return (
    <PlaceholderBlock
      {...rest}
      borderRadius={sizeX3Px}
      height={height}
      margin="x2"
      width={width}
    />
  );
};

export default PlaceholderLabel;
