import { GeoJSONPointBlank } from '@drainify/utils';
import getNearestPointInFeatureCollection from '@turf/nearest-point';
import { sizeX16Px } from 'preshape';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import type Marker from './MapMarker/Marker';
import type PlanMarker from './MapMarker/PlanMarker';
import getGeometryCircle from './useGeometryStore/getGeometryCircle';
import getGeometryLine from './useGeometryStore/getGeometryLine';
import getGeometryPolygon from './useGeometryStore/getGeometryPolygon';

export type MapBounds = {
  bbox?: GeoJSON.BBox;
  padding?: google.maps.Padding;
};

type MapPadding = Required<Exclude<MapBounds['padding'], undefined>>;

type MapEventHandler<T> = (
  callback: (value: T) => void
) => (() => void) | undefined;

type MapScreenCoordinates = {
  x: number;
  y: number;
};

type MapLayerStyling = {
  fillColor?: string;
  fillOpacity?: number;
  strokeColor?: string;
  strokeOpacity?: number;
  strokePosition?: google.maps.StrokePosition;
  strokeWeight?: number;
  zIndex?: number;
};

type MapLayerBase<T> = {
  geometry?: T | null;
  style: MapLayerStyling;
  onClick?: MapEventHandler<GeoJSON.Point>;
  onPointerEnter?: MapEventHandler<GeoJSON.Point>;
  onPointerLeave?: MapEventHandler<GeoJSON.Point>;
  onPointerMove?: MapEventHandler<GeoJSON.Point>;
  onPointerOver?: MapEventHandler<GeoJSON.Point>;
};

export type MapLayerCircle = MapLayerBase<GeoJSON.Point>;
export type MapLayerLine = MapLayerBase<GeoJSON.LineString>;
export type MapLayerPolygon = MapLayerBase<GeoJSON.Polygon>;

export type MapLayer = MapLayerCircle | MapLayerLine | MapLayerPolygon;

const isMapLayerCircle = (layer: MapLayer): layer is MapLayerCircle =>
  layer.geometry?.type === 'Point';
const isMapLayerLine = (layer: MapLayer): layer is MapLayerLine =>
  layer.geometry?.type === 'LineString';
const isMapLayerPolygon = (layer: MapLayer): layer is MapLayerPolygon =>
  layer.geometry?.type === 'Polygon';

export type MapLayerUpdateFn<T extends MapLayer> = (
  layer?: Partial<T> | ((prevLayer: T) => T)
) => void;

export type MapLayerReturn<T extends MapLayer = any> = {
  update: MapLayerUpdateFn<T>;
  remove: () => void;
};

export type UseMapProps = {
  center: GeoJSON.Point;
  isInteractive?: boolean;
  onClick?: (point: GeoJSON.Point) => void;
  padding?: number;
  zoom: number;
};

export type UseMapResult = {
  bounds: MapBounds | null;
  container: HTMLElement | null;
  isInteractive: boolean;
  isMoving: boolean;
  isReady: boolean;
  offset: MapPadding;
  addPadding: (padding: Partial<MapPadding>) => () => void;
  addBoundsChangeListener: MapEventHandler<MapBounds>;
  addPointerClickListener: MapEventHandler<GeoJSON.Point>;
  addPointerDownListener: MapEventHandler<GeoJSON.Point>;
  addPointerUpListener: MapEventHandler<GeoJSON.Point>;
  addPointerMoveListener: MapEventHandler<GeoJSON.Point>;
  createMarker: () => Promise<Marker>;
  createPlan: (
    point: GeoJSON.Polygon,
    imageUrl: string | undefined,
    opacity: number
  ) => Promise<PlanMarker>;
  disableInteractivity: () => void;
  drawData: (data: GeoJSON.FeatureCollection, style: MapLayerStyling) => void;
  drawGeometry: <T extends MapLayer>(layer: T) => MapLayerReturn<T> | null;
  enableInteractivity: () => void;
  fitToBounds: (bounds: MapBounds) => boolean;
  fromScreenToGeoJSONPoint: (xy: MapScreenCoordinates) => GeoJSON.Point | null;
  fromGeoJSONPointToScreen: (
    point: GeoJSON.Point
  ) => MapScreenCoordinates | null;
  getNearestPoint: (
    point: GeoJSON.Point,
    points: GeoJSON.Point[],
    distance: number
  ) => GeoJSON.Point | null;
  getBounds: () => MapBounds | null;
  getScreenDist: (a: GeoJSON.Point, b: GeoJSON.Point) => number | undefined;
  getZoom: () => number | null;
  getProjection: () => google.maps.Projection | null;
  zoomLevel: number;
  mapCenter: GeoJSON.Point;
  ref: (element: HTMLElement) => void;
  toggleMapType: () => void;
  mapType: string;
};

const pointFromLngLat = (lng: number, lat: number): GeoJSON.Point => ({
  type: 'Point',
  coordinates: [lng, lat],
});

const useMap = ({
  center,
  isInteractive: isInteractiveProps = false,
  onClick,
  padding: padding = sizeX16Px,
  zoom,
}: UseMapProps): UseMapResult => {
  const refScreenOverlay = useRef<google.maps.OverlayView>();
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [bounds, setBounds] = useState<MapBounds | null>(null);
  const [offset, setOffset] = useState<MapPadding>({
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  });
  const [container, setContainer] = useState<HTMLElement | null>(null);
  const [isMoving, setIsMoving] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isInteractiveLocal, setIsInteractiveLocal] = useState(true);
  const [zoomLevel, setZoomLevel] = React.useState<number>(0);
  const [mapCenter, setMapCenter] =
    React.useState<GeoJSON.Point>(GeoJSONPointBlank);

  const disableInteractivity = () => {
    map?.set('draggable', false);
    setIsInteractiveLocal(false);
  };

  const enableInteractivity = () => {
    map?.set('draggable', true);
    setIsInteractiveLocal(true);
  };

  const fromScreenToGeoJSONPoint: UseMapResult['fromScreenToGeoJSONPoint'] =
    useCallback(
      ({ x, y }) => {
        const projection = refScreenOverlay.current?.getProjection();
        const latLng = projection?.fromContainerPixelToLatLng(
          new google.maps.Point(x, y)
        );
        return latLng ? pointFromLngLat(latLng.lng(), latLng.lat()) : null;
      },
      [map]
    );

  const fromGeoJSONPointToScreen: UseMapResult['fromGeoJSONPointToScreen'] =
    useCallback(
      ({ coordinates: [lng, lat] }) => {
        const projection = refScreenOverlay.current?.getProjection();
        const point = projection?.fromLatLngToContainerPixel(
          new google.maps.LatLng({ lat, lng })
        );
        return point ? { x: point.x, y: point.y } : null;
      },
      [map]
    );

  const getScreenDist = useCallback(
    (a: GeoJSON.Point, b: GeoJSON.Point) => {
      const aProjection = fromGeoJSONPointToScreen(a);
      const bProjection = fromGeoJSONPointToScreen(b);

      if (aProjection && bProjection) {
        return Math.hypot(
          bProjection.x - (aProjection?.x || 0),
          bProjection.y - (aProjection?.y || 0)
        );
      }
    },
    [fromGeoJSONPointToScreen]
  );

  const getNearestPoint = useCallback(
    (point: GeoJSON.Point, points: GeoJSON.Point[], distance: number) => {
      const nearestFeature = getNearestPointInFeatureCollection(
        point.coordinates,
        {
          type: 'FeatureCollection',
          features: points.map((point) => ({
            type: 'Feature',
            properties: {},
            geometry: point,
          })),
        }
      );

      const nearestPoint = points[nearestFeature.properties.featureIndex];
      const nearestPointDist = getScreenDist(point, nearestPoint);

      if (nearestPointDist !== undefined && nearestPointDist < distance) {
        return nearestPoint;
      }

      return null;
    },
    [getScreenDist]
  );

  const getBounds = useCallback((): MapBounds | null => {
    const bounds = map?.getBounds();
    const topRight = bounds?.getNorthEast();
    const bottomLeft = bounds?.getSouthWest();

    return bottomLeft !== undefined && topRight !== undefined
      ? {
          bbox: [
            bottomLeft.lng(),
            bottomLeft.lat(),
            topRight.lng(),
            topRight.lat(),
          ],
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        }
      : null;
  }, [map]);

  const addPadding = useCallback(
    (padding: Partial<typeof offset>, delta: 1 | -1 = 1) => {
      setOffset((offset) => ({
        top: offset.top + (padding.top || 0) * delta,
        right: offset.right + (padding.right || 0) * delta,
        bottom: offset.bottom + (padding.bottom || 0) * delta,
        left: offset.left + (padding.left || 0) * delta,
      }));

      return () => {
        addPadding(padding, -1);
      };
    },
    []
  );

  const getZoom = useCallback(() => {
    return map?.getZoom() ?? null;
  }, [map]);

  const getProjection = useCallback(() => {
    return map?.getProjection() ?? null;
  }, [map]);

  const createPointerListener = useCallback(
    (event: 'click' | 'pointermove' | 'pointerdown' | 'pointerup') => {
      return (
        callback: (point: GeoJSON.Point) => void
      ): (() => void) | undefined => {
        if (map) {
          const container = map.getDiv();

          const handler = (event: MouseEvent | PointerEvent) => {
            const { left, top } = container.getBoundingClientRect();
            const point = fromScreenToGeoJSONPoint({
              x: event.clientX - left,
              y: event.clientY - top,
            });

            if (point) {
              callback(point);
            }
          };

          container.addEventListener(event, handler);

          return () => {
            container.removeEventListener(event, handler);
          };
        }
      };
    },
    [map]
  );

  const addBoundsChangeListener: UseMapResult['addBoundsChangeListener'] =
    useCallback(
      (callback) => {
        if (map) {
          const boundsChangeListener = map.addListener('bounds_changed', () => {
            const bounds = getBounds();
            setZoomLevel(map.getZoom() || 0);
            setMapCenter({
              type: 'Point',
              coordinates: [
                map.getCenter()?.lng() || 0,
                map.getCenter()?.lat() || 0,
              ],
            });

            if (bounds) {
              callback(bounds);
            }
          });

          return () => {
            boundsChangeListener.remove();
          };
        }
      },
      [map, getBounds]
    );

  const addPointerClickListener: UseMapResult['addPointerClickListener'] =
    createPointerListener('click');

  const addPointerDownListener: UseMapResult['addPointerDownListener'] =
    createPointerListener('pointerdown');

  const addPointerUpListener: UseMapResult['addPointerUpListener'] =
    createPointerListener('pointerup');

  const addPointerMoveListener: UseMapResult['addPointerMoveListener'] =
    createPointerListener('pointermove');

  const fitToBounds: UseMapResult['fitToBounds'] = useCallback(
    ({ bbox, padding = bounds?.padding }) => {
      if (bbox && map) {
        const sw = [Math.max(bbox[1], bbox[3]), Math.min(bbox[0], bbox[2])];
        const ne = [Math.min(bbox[1], bbox[3]), Math.max(bbox[0], bbox[2])];

        const bounds = new google.maps.LatLngBounds(
          new google.maps.LatLng(sw[0], sw[1]),
          new google.maps.LatLng(ne[0], ne[1])
        );

        requestAnimationFrame(() => {
          map.fitBounds(bounds, {
            top: (padding?.top || 0) + offset.top,
            right: (padding?.right || 0) + offset.right,
            bottom: (padding?.bottom || 0) + offset.bottom,
            left: (padding?.left || 0) + offset.left,
          });
        });

        return true;
      }

      return false;
    },
    [bounds, offset, map]
  );

  const drawData = useCallback(
    (data: GeoJSON.FeatureCollection, style: MapLayerStyling) => {
      if (map) {
        const layer = new google.maps.Data({ map });
        layer.addGeoJson(data);
        layer.setStyle(style);

        return () => {
          layer.forEach((feature) => {
            layer.remove(feature);
          });

          layer.setMap(null);
        };
      }
    },
    [map]
  );

  const drawGeometry = useCallback(
    <T extends MapLayer>(layer: T): MapLayerReturn | null => {
      if (map) {
        if (isMapLayerCircle(layer)) return getGeometryCircle(map, layer);
        if (isMapLayerLine(layer)) return getGeometryLine(map, layer);
        if (isMapLayerPolygon(layer)) return getGeometryPolygon(map, layer);
      }

      return null;
    },
    [map]
  );

  const createMarker = useCallback(async () => {
    const Marker = (await import('./MapMarker/Marker')).default;
    const marker = new Marker();

    marker.setMap(map);
    marker.addListener('dragstart', () => setIsMoving(true));
    marker.addListener('dragend', () => setIsMoving(false));

    return marker;
  }, [map]);

  const createPlan = useCallback(
    async (
      point: GeoJSON.Polygon,
      imageUrl: string | undefined,
      opacity: number
    ) => {
      const PlanMarker = (await import('./MapMarker/PlanMarker')).default;

      const marker = new PlanMarker(point, imageUrl || '', opacity);

      marker.setMap(map);
      marker.addListener('dragstart', () => setIsMoving(true));
      marker.addListener('dragend', () => setIsMoving(false));

      return marker;
    },
    [map]
  );

  const toggleMapType = () => {
    const updatedMapType =
      map?.getMapTypeId() === google.maps.MapTypeId.ROADMAP
        ? google.maps.MapTypeId.HYBRID
        : google.maps.MapTypeId.ROADMAP;
    map?.setMapTypeId(updatedMapType);
    setMapType(updatedMapType);
  };

  const [mapType, setMapType] = React.useState('roadmap');

  useEffect(() => {
    if (container && !map) {
      const map = new google.maps.Map(container, {
        center: new google.maps.LatLng(
          center.coordinates[0],
          center.coordinates[1]
        ),
        clickableIcons: false,
        disableDefaultUI: true,
        fullscreenControl: false,
        keyboardShortcuts: false,
        tilt: 0,
        mapId: 'fa5583fc8393558b',
        mapTypeControl: false,
        noClear: true,
        scaleControl: false,
        streetViewControl: false,
        zoom: zoom,
        zoomControl: false,
      });

      map.addListener('dragstart', () => setIsMoving(true));
      map.addListener('dragend', () => setIsMoving(false));

      refScreenOverlay.current = new google.maps.OverlayView();
      refScreenOverlay.current.draw = () => {};
      refScreenOverlay.current.setMap(map);

      const idleListener = map.addListener('idle', () => {
        idleListener.remove();
        setIsReady(true);
        setMap(map);
      });

      return () => {
        idleListener.remove();
      };
    }
  }, [container, map]);

  useEffect(() => {
    if (onClick) {
      return addPointerClickListener(onClick);
    }
  }, [map, onClick]);

  useEffect(() => {
    // commented out. not sure of reprocussions yet so leaving here.
    // map?.setCenter(
    // new google.maps.LatLng(center.coordinates[0], center.coordinates[1])
    // );
  }, [map, center]);

  useEffect(() => {
    setBounds((prevBounds) => ({
      ...(prevBounds || {}),
      bbox: prevBounds?.bbox,
      padding: {
        top: padding,
        left: padding,
        right: padding,
        bottom: padding,
      },
    }));
  }, [padding]);

  useEffect(() => {
    map?.setZoom(zoom);
    setZoomLevel(zoom);
  }, [map, zoom]);

  useLayoutEffect(() => {
    const interval = setInterval(() => {
      if (container && isReady) {
        const element =
          container.querySelector('.gmnoscreen')?.parentElement?.parentElement;

        if (element) {
          element.style.position = 'absolute';
          element.style.bottom = '0px';
          element.style.right = `${offset.right}px`;

          clearInterval(interval);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [container, isReady, offset]);

  return {
    bounds,
    container,
    isInteractive: isInteractiveProps && isInteractiveLocal && !isMoving,
    isMoving,
    isReady,
    offset,
    addPadding,
    addBoundsChangeListener,
    addPointerClickListener,
    addPointerDownListener,
    addPointerMoveListener,
    addPointerUpListener,
    createMarker,
    createPlan,
    disableInteractivity,
    enableInteractivity,
    drawData,
    drawGeometry,
    fromScreenToGeoJSONPoint,
    fromGeoJSONPointToScreen,
    fitToBounds,
    getBounds,
    getNearestPoint,
    getScreenDist,
    getZoom,
    getProjection,
    zoomLevel,
    mapCenter,
    ref: setContainer,
    toggleMapType,
    mapType,
  };
};

export default useMap;
