import { Drawing } from '@drainify/types';
import { ReportElementType } from '@drainify/utils';
import { isClosed } from '@drainify/utils/src/drawing';
import { NearestPointOnLine } from '@turf/nearest-point-on-line';
import { Box, Button, Icons } from 'preshape';
import React, { PropsWithChildren } from 'react';
import { v4 } from 'uuid';
import { useMapLayersContext } from '../Map/MapLayers/MapLayers';
import MapMarker from '../Map/MapMarker/MapMarker';
import MapMarkerToolbar from '../Map/MapMarker/MapMarkerToolbar';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import { useReportMapContext } from '../Report/ReportMap/ReportMapProvider';
import ReportMapDrawingAdd from './ReportMapDrawingAdd';
import ReportMapDrawingSegment from './ReportMapDrawingSegment';
import ReportMapDrawingSegmentEditable from './ReportMapDrawingSegmentEditable';
import ReportMapDrawingShow from './ReportMapDrawingShow';

type Props = {
  drawing: Drawing;
};

const ReportMapDrawing = ({ drawing }: PropsWithChildren<Props>) => {
  const { isLayerVisible } = useMapLayersContext();
  const { reportEditor } = useReportEditorContext();
  const { focusedDrawingUid, hasFeature } = useReportMapContext();
  const [isDragging, setIsDragging] = React.useState(false);

  const visible = isLayerVisible(ReportElementType.DRAWING, drawing.uid);

  const handleDragEnd = (pointId: string) => (point: GeoJSON.Point) => {
    reportEditor.updateDrawing(drawing.uid, {
      points: drawing.points.map((e) => {
        if (e.uid !== pointId) {
          return e;
        } else {
          return {
            uid: pointId,
            point,
          };
        }
      }),
    });
    setIsDragging(false);
  };

  function handleOnEdit(
    point: NearestPointOnLine['geometry'],
    end: [string, string]
  ): void {
    const index = drawing.points.indexOf(
      drawing.points.find((e) => e.uid === end[1])!
    );
    if (index === 0) {
      const newPoint = { uid: v4(), point: point };
      reportEditor.updateDrawing(drawing.uid, {
        ...drawing,
        points: [
          newPoint,
          ...drawing.points.slice(0, drawing.points.length - 1),
          newPoint,
        ],
      });
    } else {
      reportEditor.updateDrawing(drawing.uid, {
        ...drawing,
        points: [
          ...drawing.points.slice(0, index),
          { uid: v4(), point: point },
          ...drawing.points.slice(index),
        ],
      });
    }
  }
  const handleDeletePoint = (uid: string) => {
    const index = drawing.points.indexOf(
      drawing.points.find((e) => e.uid === uid)!
    );
    if (index !== 0) {
      reportEditor.updateDrawing(drawing.uid, {
        points: drawing.points.filter((e) => e.uid !== uid),
      });
    } else {
      reportEditor.updateDrawing(drawing.uid, {
        points: [
          ...drawing.points.filter((e) => e.uid !== uid),
          drawing.points[1],
        ],
      });
    }
  };

  if (!visible) {
    return null;
  }

  return (
    <>
      {focusedDrawingUid === drawing.uid &&
        drawing.points.map((point, i) => (
          <Box key={i + `drawing`}>
            {i < drawing.points.length - 1 && isClosed(drawing) && (
              <ReportMapDrawingSegmentEditable
                key={drawing.uid + '-' + i + '-segment'}
                ids={[drawing.points[i].uid, drawing.points[i + 1].uid]}
                points={[drawing.points[i].point, drawing.points[i + 1].point]}
                onAdd={handleOnEdit}
              />
            )}
            {i < drawing.points.length - 1 && !isClosed(drawing) && (
              <ReportMapDrawingSegment
                key={drawing.uid + '-' + i + '-segment'}
                points={[drawing.points[i].point, drawing.points[i + 1].point]}
              />
            )}
            <MapMarker
              key={drawing.uid + '-' + i + '-marker'}
              point={point.point}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onDragStart={() => {
                setIsDragging(true);
              }}
              onDragEnd={
                hasFeature('Elements:move')
                  ? handleDragEnd(point.uid)
                  : undefined
              }
            >
              <MapMarkerToolbar
                enabled={true}
                showOnHover={isClosed(drawing)}
                tools={
                  isClosed(drawing) && drawing.points.length > 4
                    ? [
                        {
                          icon: <Icons.Trash2 />,
                          onClick: () => handleDeletePoint(point.uid),
                        },
                      ]
                    : undefined
                }
              >
                {isClosed(drawing) && (
                  <Box backgroundColor="black" borderRadius="full" padding="x1">
                    <Box backgroundColor={'black'} borderRadius="full" />
                  </Box>
                )}
                {!isClosed(drawing) && i === 0 && (
                  <Button
                    backgroundColor="black"
                    borderRadius="full"
                    textColor="white"
                    backgroundColorHover="accent-shade-5"
                    borderSize="x1"
                    borderColor="accent-shade-5"
                    size="x1"
                    textColorHover="white"
                    paddingHorizontal="x1"
                    paddingVertical="x1"
                    variant="primary"
                    alignChildrenVertical="middle"
                    onClick={() =>
                      reportEditor.updateDrawing(drawing.uid, {
                        points: [...drawing.points, point],
                      })
                    }
                  >
                    <Icons.Link2 size="1.25rem" />
                  </Button>
                )}
                {!isClosed(drawing) &&
                  i !== drawing.points.length - 1 &&
                  i !== 0 && (
                    <Box backgroundColor={'black'} borderRadius="full" />
                  )}
              </MapMarkerToolbar>
            </MapMarker>
          </Box>
        ))}
      {focusedDrawingUid === drawing.uid &&
        !isDragging &&
        !isClosed(drawing) && <ReportMapDrawingAdd drawing={drawing} />}

      {isClosed(drawing) && <ReportMapDrawingShow drawing={drawing} />}

      {focusedDrawingUid !== drawing.uid &&
        !isClosed(drawing) &&
        drawing.points.slice(0, drawing.points.length - 1).map((e, i) => {
          return (
            <ReportMapDrawingSegment
              key={drawing.uid + '-' + i + '-segment'}
              points={[drawing.points[i].point, drawing.points[i + 1].point]}
            />
          );
        })}
    </>
  );
};

export default ReportMapDrawing;
