import React, {
  createContext,
  PropsWithChildren,
  useContext,
} from 'react';

type Props = {};

type Context = {
  showObservations: boolean;
  showSimpleSections: boolean;

  setShowObservations: (show: boolean) => void;
  setShowSimpleSections: (show: boolean) => void;

  showNodeLabels: boolean;
  setShowNodeLabels: (show: boolean) => void;

  showSectionLabels: boolean;
  setShowSectionLabels: (show: boolean) => void;
};

export const ReportCustomerContext = createContext<Context>({
  showObservations: true,
  setShowObservations: () => {},

  showSectionLabels: false,
  setShowSectionLabels: () => {},

  showNodeLabels: false,
  setShowNodeLabels: () => {},

  showSimpleSections: false,
  setShowSimpleSections: () => {},
});

export const useReportCustomerContext = () => useContext(ReportCustomerContext);

const ReportCustomerProvider = ({ children }: PropsWithChildren<Props>) => {

  const [showObservations, setShowObservations] = React.useState(true);
  const [showSimpleSections, setShowSimpleSections] = React.useState(false);
  const [showNodeLabels, setShowNodeLabels] = React.useState(true);
  const [showSectionLabels, setShowSectionLabels] = React.useState(true)


  const value: Context = {
    showSimpleSections,
    setShowSimpleSections,

    showObservations,
    setShowObservations,

    showNodeLabels,
    setShowNodeLabels,

    showSectionLabels, 
    setShowSectionLabels
  };

  return (
      <ReportCustomerContext.Provider value={value}>
        {children}
      </ReportCustomerContext.Provider>
  );
};

export default ReportCustomerProvider;
