import { Appear, Box, Icons } from 'preshape';
import React from 'react';
import ReportMapObservationTooltip from '../ReportMapObservationTooltip';
import { ReportMapObservationRendererType } from '.';

const ReportMapObservationWL: ReportMapObservationRendererType = ({
  observation,
  onClick,
  visible,
}) => {
  return (
    <ReportMapObservationTooltip observation={observation}>
      <Appear animation="Pop" onClick={onClick} visible={visible}>
        <Box
          backgroundColor="negative-shade-5"
          borderColor="text-shade-1"
          borderSize="x2"
          borderRadius="full"
          textColor="dark-shade-1"
        >
          <Icons.X size="1rem" />
        </Box>
      </Appear>
    </ReportMapObservationTooltip>
  );
};

export default ReportMapObservationWL;
