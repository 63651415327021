import { Box, BoxProps, Text } from 'preshape';
import React, { PropsWithChildren } from 'react';

export type CardHeaderProps = BoxProps & {
  action?: JSX.Element;
  description?: string;
  title?: JSX.Element | string;
};

const CardHeader = ({
  action,
  description,
  title,
  ...rest
}: PropsWithChildren<CardHeaderProps>) => {
  return (
    <Box
      {...rest}
      alignChildrenHorizontal="between"
      alignChildrenVertical="middle"
      flex="horizontal"
      gap="x6"
    >
      {title && (
        <Box basis="0" grow>
          <Text size="x3" strong>
            {title}
          </Text>

          {description && (
            <Text size="x3" textColor="text-shade-4">
              {description}
            </Text>
          )}
        </Box>
      )}

      {action && <Box>{action}</Box>}
    </Box>
  );
};

export default CardHeader;
