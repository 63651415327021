import {
  Button,
  Buttons,
  Icons,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from 'preshape';
import React from 'react';
import { FULL_SCREEN_MODAL_WIDTH } from '../../../components/App/App';
import AsyncButton from '../../../components/AsyncButton/AsyncButton';
import CustomerList from '../../../components/Customer/CustomerList';
import { useHasPermission } from '../../../components/Permissions/Permissions';
import useCustomer from '../../../hooks/useCustomer';
import OrgCustomerPage from './OrgCustomerPage';

const OrgCustomersPage = () => {
  const [activeCustomer, setActiveCustomer] = React.useState<string>();
  const { query, update, remove } = useCustomer(activeCustomer);
  return (
    <>
      <CustomerList
        onSelect={(customer) => setActiveCustomer(customer.uid)}
        withSearch
      />
      <Modal
        visible={activeCustomer !== undefined}
        onClose={() => setActiveCustomer(undefined)}
        maxWidth={FULL_SCREEN_MODAL_WIDTH}
      >
        <ModalHeader>Admin customer</ModalHeader>
        <ModalBody>
          <OrgCustomerPage
            customer={query.data}
            update={(c) => update.mutateAsync(c)}
          />
        </ModalBody>
        <ModalFooter>
          <Buttons>
            {useHasPermission('customers:delete') ? (
              <AsyncButton
                grow
                variant="primary"
                color="negative"
                isError={remove.isError}
                isLoading={remove.isLoading}
                isSuccess={remove.isSuccess}
                onClick={() => {
                  remove.mutateAsync();
                  setActiveCustomer(undefined);
                }}
              >
                <Text>Delete Customer</Text>
                <Icons.Trash2 />
              </AsyncButton>
            ) : undefined}
            <Button size="x3" grow onClick={() => setActiveCustomer(undefined)}>
              Close
            </Button>
          </Buttons>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default OrgCustomersPage;
