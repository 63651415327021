import { Box, Text, useLocalStorage } from 'preshape';
import React, { PropsWithChildren } from 'react';
import useMeBookings from '../../hooks/useMeBookings';
import LoadingPage from '../../pages/mobile/Layout/LoadingPage';
import PrecacheProjectReport from './PrecacheProjectReport';

const PrecacheWrapper = ({ children }: PropsWithChildren<{}>) => {
  const { query } = useMeBookings();
  const [done, setDone] = React.useState(false);
  const [precached, setPrecached] = useLocalStorage<string[]>(
    'com.drainify.precached',
    []
  );

  React.useEffect(() => {
    if (query.data && !done) {
      const queryUids = query.data?.data.map((e) => e.uid);
      setDone(queryUids.every((e) => precached.includes(e)));
      if (queryUids.every((e) => precached.includes(e))) {
        setPrecached(queryUids.map((e) => e));
      }
    }
  }, [query, precached, done]);

  if (done) {
    return <>{children}</>;
  }

  if (!precached) {
    return <Text>Loading cache</Text>;
  }

  if (query.isFetching) {
    return <LoadingPage />;
  }

  const uncached = query.data?.data.filter((e) => !precached.includes(e.uid));

  if (uncached && uncached?.length > 0) {
    return (
      <>
        <LoadingPage />
        <Box style={{ position: 'absolute', top: '20px', left: '20px' }}>
          <Text size="x1">Precaching.</Text>
        </Box>
        <PrecacheProjectReport
          project={uncached[0].project}
          onDone={() => {
            setPrecached([...precached, uncached[0].uid]);
          }}
        />
      </>
    );
  }
  return <Text>{children}</Text>;
};

export default PrecacheWrapper;
