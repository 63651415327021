import { DateTime } from 'luxon';
import { Box, BoxProps, Icons, Label, Text } from 'preshape';
import React, { PropsWithChildren } from 'react';
import useValidDateInputs from './useValidDateInputs';

type DateRangeTextProps = BoxProps & {
  startDate?: string;
};

const DateRangeText = ({
  startDate: dirtyStartDate,
  ...rest
}: PropsWithChildren<DateRangeTextProps>) => {
  const [startDateTime, endDateTime] = useValidDateInputs(dirtyStartDate);

  let dateContent;

  if (dirtyStartDate && startDateTime?.equals(endDateTime)) {
    dateContent = (
      <Box alignChildren="middle" flex="horizontal" gap="x2">
        <Label
          backgroundColor="accent-shade-1"
          flex="horizontal"
          gap="x1"
          textColor="text-shade-1"
        >
          <Icons.Calendar />
          <Text>{startDateTime.toLocaleString(DateTime.DATE_FULL)}</Text>
        </Label>
      </Box>
    );
  }

  return (
    <Text flex="horizontal" {...rest} gap="x2" size="x3" strong wrap>
      {dateContent}
    </Text>
  );
};

export default DateRangeText;
