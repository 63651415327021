import { Box, BoxProps, Button, Icons, Text } from 'preshape';
import React, { PropsWithChildren } from 'react';

type Props = BoxProps & {
  action?: JSX.Element;
  backToLink?: string;
  backToText?: string;
  title: JSX.Element | string;
};

const PageHeader = ({
  action,
  backToLink,
  backToText,
  title,
}: PropsWithChildren<Props>) => {
  const hasBackLink = backToLink && backToText;

  return (
    <Box paddingHorizontal="x4" paddingVertical="x6">
      {hasBackLink && (
        <Box
          alignChildrenVertical="middle"
          flex="horizontal"
          gap="x8"
          margin="x2"
        >
          <Box alignChildrenVertical="middle" flex="horizontal" gap="x4">
            <Box alignChildrenVertical="middle" flex="horizontal" gap="x2">
              <Button
                paddingHorizontal="x2"
                size="x3"
                to={backToLink}
                variant="tertiary"
                uppercase={false}
              >
                <Icons.ChevronLeft />
                <Text>{backToText}</Text>
              </Button>

              <Text>/</Text>
            </Box>

            <Box grow>
              {typeof title === 'string' ? (
                <Text size="x4" strong>
                  {title}
                </Text>
              ) : (
                title
              )}
            </Box>
          </Box>
        </Box>
      )}

      <Box flex="horizontal" gap="x6">
        <Box grow>
          <Text size="x7" strong>
            {title}
          </Text>
        </Box>

        {action && <Box>{action}</Box>}
      </Box>
    </Box>
  );
};

export default PageHeader;
