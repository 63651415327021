import { Buttons, Grid } from 'preshape';
import React from 'react';
import DataTable from '../../../components/DataTable/DataTable';
import DataTableGroup from '../../../components/DataTable/DataTableGroup';
import DataTableItem from '../../../components/DataTable/DataTableItem';
import DataTableMessage from '../../../components/DataTable/DataTableMessage';
import { useReportContext } from '../../../components/Report/ReportProvider';
import SitePhotoCreateButton from '../../../components/SitePhotos/SitePhotoCreateButton';
import SitePhotoView from '../../../components/SitePhotos/SitePhotoView';
import Page from '../Layout/Page';
import PageBody from '../Layout/PageBody';
import PageHeader from '../Layout/PageHeader';

const SitePhotosPage = () => {
  const { report } = useReportContext();

  if (report?.sitePhotos.length === 0) { 
    return (
    <Page>
      <PageHeader title="Site photos" />
      <PageBody>
      <DataTable>
            <DataTableGroup>
              <DataTableItem>
                <DataTableMessage>
                  There are no photos to show here
                </DataTableMessage>
              </DataTableItem>
            </DataTableGroup>
          </DataTable>
        </PageBody>
        <Buttons padding="x6">
          <SitePhotoCreateButton variant="primary" />
        </Buttons>
      </Page>
    );
  }


  return (
    <Page>
      <PageHeader title="Site photos" />
      <PageBody flex="vertical" gap="x3" overflow="auto" basis="0" grow>
        <Grid margin="x2" repeat="2" repeatWidth="1fr">
          {report?.sitePhotos.map((e) => (
            <SitePhotoView sitePhoto={e} key={e.uid} />
          ))}
        </Grid>
      </PageBody>
      <Buttons padding="x6">
        <SitePhotoCreateButton variant="primary" />
      </Buttons>
    </Page>
  );
};

export default SitePhotosPage;
