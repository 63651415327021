import { InputLabel, Input, useFormContext, FormError } from 'preshape';
import React, { ChangeEvent } from 'react';
import { PasswordResetState } from '../../hooks/forms/usePasswordResetForm';

const PasswordResetInput = () => {
  const { form } = useFormContext<PasswordResetState, string>();

  return (
    <>
      <FormError name="currentPassword" margin="x4">
        <InputLabel label="Current password">
          <Input
            name="currentPassword"
            type="password"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              form.setState((s) => ({ ...s, currentPassword: e.target.value }))
            }
            value={form.state.currentPassword}
          />
        </InputLabel>
      </FormError>

      <FormError name="newPassword" margin="x4">
        <InputLabel label="New password">
          <Input
            name="newPassword"
            type="password"
            autoComplete="new-password"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              form.setState((s) => ({ ...s, newPassword: e.target.value }))
            }
            value={form.state.newPassword}
          />
        </InputLabel>
      </FormError>

      <FormError name="confirmNewPassword" margin="x4">
        <InputLabel label="Confirm new password">
          <Input
            name="confirmNewPassword"
            type="password"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              form.setState((s) => ({
                ...s,
                confirmNewPassword: e.target.value,
              }))
            }
            value={form.state.confirmNewPassword}
          />
        </InputLabel>
      </FormError>
    </>
  );
};

export default PasswordResetInput;
