import { Box } from 'preshape';
import React from 'react';
import DataTable, { DataTableProps } from '../DataTable/DataTable';
import DataTableGroup from '../DataTable/DataTableGroup';
import DataTableItem from '../DataTable/DataTableItem';
import DataTableMessage from '../DataTable/DataTableMessage';
import InfiniteScroll from '../InfiniteScroll/InfiniteScroll';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import { useReportContext } from '../Report/ReportProvider';
import SearchResult from '../Search/SearchResult';
import SectionView from './SectionView';

type Props = DataTableProps & {
  disabledUids?: string[];
  onItemClick?: (sectionUid: string) => void;
  selectedUids?: string[];
  validate?: boolean;
};

const SectionList = ({
  disabledUids,
  onItemClick,
  selectedUids,
  validate = true,
  ...rest
}: Props) => {
  const { report } = useReportContext();
  const { reportEditor } = useReportEditorContext();
  const [sectionNumber, setSectionNumber] = React.useState(5);

  if (!report) {
    return (
      <DataTable {...rest}>
        <DataTableGroup>
          {Array.from({ length: 5 }).map((_, index) => (
            <DataTableItem key={index}>
              <SectionView />
            </DataTableItem>
          ))}
        </DataTableGroup>
      </DataTable>
    );
  }

  if (!report.sections.length) {
    return (
      <DataTable {...rest}>
        <DataTableGroup>
          <DataTableItem>
            <DataTableMessage>
              There are no sections to show here
            </DataTableMessage>
          </DataTableItem>
        </DataTableGroup>
      </DataTable>
    );
  }

  return (
    <DataTable {...rest}>
      <DataTableGroup>
        {reportEditor.report.sections.slice(0, sectionNumber).map((section) => (
          <SearchResult
            keywords={reportEditor.getSectionKeywords(section.uid)}
            key={section.uid}
          >
            <DataTableItem
              disabled={disabledUids?.includes(section.uid)}
              onClick={onItemClick && (() => onItemClick(section.uid))}
              selected={selectedUids?.includes(section.uid)}
            >
              <Box flex="horizontal" alignChildrenHorizontal="between">
                <Box grow>
                  <SectionView section={section} validate={validate} />
                </Box>
              </Box>
            </DataTableItem>
          </SearchResult>
        ))}
        {sectionNumber < reportEditor.report.sections.length && (
          <InfiniteScroll onMore={() => setSectionNumber(sectionNumber + 5)} />
        )}
      </DataTableGroup>
    </DataTable>
  );
};

export default SectionList;
