import React from 'react';
import Card from '../../../components/Card/Card';
import OrgView from '../../../components/Org/OrgView';
import PlaceholderGradientAnimation from '../../../components/Placeholders/PlaceholderGradientAnimation';
import useMeOrg from '../../../hooks/useMeOrg';
import { orgSettings } from '../../settingsMenu';
import PageMenu from '../Layout/PageMenu';
import PageMenuItem from '../Layout/PageMenuItem';

const SettingsPageOrg = () => {
  const { query: queryOrg } = useMeOrg();

  return (
    <PlaceholderGradientAnimation
      animating={queryOrg.isFetching}
      margin="x12"
    >
      <Card title="Organisation">
        <OrgView org={queryOrg.data} />
      </Card>

      <PageMenu margin="x3">
        {orgSettings.map(({ Icon, title, to }) => (
          <PageMenuItem
            disabled={queryOrg.isLoading}
            icon={<Icon />}
            key={to}
            size="x2"
            to={to}
          >
            {title}
          </PageMenuItem>
        ))}
      </PageMenu>
    </PlaceholderGradientAnimation>
  );
};

export default SettingsPageOrg;
