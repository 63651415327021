import { Project } from '@drainify/types';
import React, { useContext } from 'react';
import useProjectReport from '../../hooks/useProjectReport';
import { NodeMapContext } from '../Node/NodeMapProvider';

type Props = {
  project: Project;
  onDone: () => void;
};

const PrecacheProjectReport = ({ project, onDone }: Props) => {
  const { query } = useProjectReport({ projectId: project.uid });
  const { setProject, setReport } = useContext(NodeMapContext);

  React.useEffect(() => {
    if (query.data) {
      setReport(query.data);
      setProject(project);

      setTimeout(() => {
        onDone();
      }, 500);
    }
  }, [query]);

  return null;
};

export default PrecacheProjectReport;
