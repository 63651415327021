import { LanguageCodes } from '@drainify/types';
import {
  getRepairableInspectionIds,
  getRepairReccomendationsSchema,
} from '@drainify/utils';
import { Text } from 'preshape';
import React, { useContext } from 'react';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import SummaryCard from '../SummaryCard/SummaryCard';
import SummaryCardSection from '../SummaryCard/SummaryCardSection';
import { WizardContext } from '../Wizard/Wizard';

const JobRepairSummary = () => {
  const { setActiveStepId } = useContext(WizardContext);
  const { activeBookingId } = useProjectContext();
  const { reportEditor } = useReportEditorContext();

  if (!activeBookingId) {
    return null;
  }

  return (
    <SummaryCard title="Repair reccomendations">
      {getRepairableInspectionIds(reportEditor, activeBookingId)
        .map((i) => reportEditor.getInspectionByUid(i))
        .filter((e) => !!e)
        .map((e) => (
          <SummaryCardSection
            key={e!.uid}
            onEdit={() => setActiveStepId(e!.uid)}
            title={`${reportEditor.getSectionName(
              reportEditor.getSectionByUid(
                reportEditor.getInspectionByUid(e!.uid)!.sectionUid!
              )
            )!}/${reportEditor.getInspectionName(
              reportEditor.getInspectionByUid(e!.uid)!
            )}`}
            value={e!.repairs?.join(', ')}
          >
            {e!.repairs
              ?.map(
                (e) => getRepairReccomendationsSchema(e).name[LanguageCodes.EN]
              )
              .map((e) => (
                <Text key={e} strong size="x3">
                  {e}
                </Text>
              ))}
          </SummaryCardSection>
        ))}
    </SummaryCard>
  );
};

export default JobRepairSummary;
