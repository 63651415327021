import React from 'react';
import PageMenu, { PageMenuProps } from '../../mobile/Layout/PageMenu';

const ContextMenu = (props: PageMenuProps) => {
  return (
    <PageMenu {...props} backgroundColor="background-shade-1" margin="x2" />
  );
};

export default ContextMenu;
