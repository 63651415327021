import { WithTracking } from './Api';
import { Org } from './Org';
import { OrgRole } from '.';

export enum InviteState {
  PENDING = 0,
  ACCEPTED = 1,
  REJECTED = 2,
  CANCELLED = 3,
}

export interface Invite extends WithTracking {
  uid: string;
  recipient: string;
  org: Org;
  state: InviteState;
  role: OrgRole;
}

export type InvitePostBody = Omit<Invite, 'uid' | 'org' | 'state'>;

export interface InviteAccept {
  fullName: string;
  password: string;
  confirmPassword: string;
  phoneNumber: string;
}
