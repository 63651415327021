import { Box, BoxProps } from 'preshape';
import React from 'react';

type Props = BoxProps;

const PlaceholderBlock = ({
  borderRadius = 'x1',
  height = '1rem',
  margin = 'x1',
  width = '100%',
  ...rest
}: Props) => {
  return (
    <Box
      {...rest}
      backgroundColor="background-shade-3"
      borderRadius={borderRadius}
      height={height}
      margin={margin}
      width={width}
    />
  );
};

export default PlaceholderBlock;
