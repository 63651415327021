import { Customer, CustomerPostBody, ResponseError } from '@drainify/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useFetch, { hasAllParams } from '../components/Api/useFetch';

const useCustomers = (customerId?: string) => {
  const fetch = useFetch();
  const key = [`/customer/${customerId}`, fetch];
  const queryClient = useQueryClient();
  const params = { customerId: customerId as string };

  const query = useQuery<Customer, ResponseError>(
    key,
    () => fetch('/customer/:customerId', { method: 'GET', params }),
    { enabled: hasAllParams(params) }
  );

  const update = useMutation<Customer, ResponseError, CustomerPostBody>(
    (body: CustomerPostBody) =>
      fetch('/customer/:customerId', { method: 'PUT', body, params }),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(key, data);
        queryClient.refetchQueries(['/customers']);
      },
    }
  );

  const remove = useMutation<void, ResponseError>(
    () => fetch('/customer/:customerId', { method: 'DELETE', params }),
    {
      onSuccess: () => {
        queryClient.setQueryData(key, null);
        queryClient.refetchQueries(['/customers']);
      },
    }
  );

  return { query, update, remove };
};

export default useCustomers;
