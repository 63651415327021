/* eslint-disable camelcase */
import { Location } from '@drainify/types';
import { fromPlaceResultToLocation, getAddressString } from '@drainify/utils';
import getBbox from '@turf/bbox';
import buffer from '@turf/buffer';
import {
  Box,
  Icons,
  Image,
  Input,
  InputProps,
  Link,
  Placement,
  PlacementContent,
  PlacementManager,
  PlacementReference,
  Text,
} from 'preshape';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useGeoLocation } from '../GeoLocation/GeoLocation';

type Props = InputProps & {
  name?: string;
  onChange: (location: Location) => void;
  value?: Location | null;
};

const MapSearchInput = ({ name, onChange, value, ...rest }: Props) => {
  const refAutocompleteService = useRef<google.maps.places.AutocompleteService>(
    new google.maps.places.AutocompleteService()
  );
  const refAutocompleteSessionToken =
    useRef<google.maps.places.AutocompleteSessionToken>(
      new google.maps.places.AutocompleteSessionToken()
    );

  const [valueString, setValueString] = useState(
    value ? getAddressString(value) : ''
  );
  const [results, setResults] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);
  const [visible, setVisible] = useState(false);
  const { position } = useGeoLocation();

  const handleInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setValueString(event.target.value);
    setVisible(true);
    const locationBias = getBbox(
      buffer(
        {
          type: 'Point',
          coordinates: position?.coordinates || [0, 0], // TODO - get a sensible default if no location information
        },
        5,
        { units: 'miles' }
      )
    );

    if (event.target.value && refAutocompleteService.current) {
      try {
        const { predictions } =
          await refAutocompleteService.current.getPlacePredictions({
            input: event.target.value,
            locationBias: new google.maps.LatLngBounds(
              new google.maps.LatLng(locationBias[1], locationBias[0]),
              new google.maps.LatLng(locationBias[3], locationBias[2])
            ),
            sessionToken: refAutocompleteSessionToken.current,
          });

        setResults(predictions);
      } catch (error) {
        console.error(error);
        setResults([]);
      }
    }
  };

  const handleSelect = async (
    result: google.maps.places.AutocompletePrediction
  ) => {
    const placesService = new google.maps.places.PlacesService(
      document.createElement('div')
    );

    placesService.getDetails(
      {
        placeId: result.place_id,
        fields: ['address_components', 'geometry'],
        sessionToken: refAutocompleteSessionToken.current,
      },
      (response) => {
        if (response) {
          onChange(fromPlaceResultToLocation(response));
        } else {
          console.error('No place found');
        }
      }
    );

    setVisible(false);
  };

  useEffect(() => {
    setValueString(value ? getAddressString(value) : '');
    refAutocompleteSessionToken.current =
      new google.maps.places.AutocompleteSessionToken();
  }, [value]);

  useEffect(() => {
    if (google.maps.places.AutocompleteService) {
      refAutocompleteService.current =
        new google.maps.places.AutocompleteService();
    }
  }, []);

  return (
    <PlacementManager>
      <PlacementReference>
        {(props) => (
          <Input
            {...rest}
            {...props}
            addonStart={<Icons.Search size="1.25rem" />}
            name={`${name}.address.street`}
            onChange={handleInputChange}
            placeholder="Search..."
            width="100%"
            value={valueString}
            size="x3"
          />
        )}
      </PlacementReference>

      <Placement
        animation="FadeSlideDown"
        width="reference"
        visible={visible}
        zIndex={1}
      >
        <PlacementContent
          backgroundColor="background-shade-2"
          borderRadius="x2"
          borderSize="x2"
        >
          {results.length === 0 && (
            <Text padding="x2" size="x2" strong>
              No results found
            </Text>
          )}

          {results.map((result) => (
            <Link
              display="block"
              key={result.place_id}
              onClick={() => handleSelect(result)}
              padding="x2"
              size="x2"
              strong
            >
              {result.description}
            </Link>
          ))}

          <Box alignChildrenHorizontal="end" flex="horizontal" padding="x2">
            <Image
              height={15}
              src="https://storage.googleapis.com/geo-devrel-public-buckets/powered_by_google_on_white.png"
            />
          </Box>
        </PlacementContent>
      </Placement>
    </PlacementManager>
  );
};

export default MapSearchInput;
