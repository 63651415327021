import { Plan } from '@drainify/types';
import { getFullFilePath } from '@drainify/utils';
import { Attributes, Box, BoxProps } from 'preshape';
import React, {
  forwardRef,
  useEffect,
  ForwardedRef,
  useState,
  useRef,
} from 'react';
import { createPortal } from 'react-dom';
import { useMapContext } from '../Map';
import { MarkerAnchor } from './Marker';
import PlanMarker from './PlanMarker';

export type MapMarkerProps = Omit<BoxProps, 'padding'> & {
  anchor?: MarkerAnchor;
  onDragStart?: (point: GeoJSON.Point) => void;
  onDrag?: (point: GeoJSON.Point) => void;
  onDragEnd?: (point: GeoJSON.Point) => void;
  points?: GeoJSON.Polygon | false | null;
  plan: Plan;
  opacity?: number;
};

const MapPlanMarker = (
  {
    children,
    onDragStart,
    onDrag,
    onDragEnd,
    opacity = 1,
    plan,
    points,
    ...rest
  }: Attributes<HTMLDivElement, MapMarkerProps>,
  ref: ForwardedRef<HTMLElement>
) => {
  const { createPlan } = useMapContext();
  const [planMarker, setMarker] = useState<PlanMarker>();
  const refUnmounted = useRef(false);

  useEffect(() => {
    return () => {
      refUnmounted.current = true;
    };
  }, []);

  useEffect(() => {
    let planMarker: PlanMarker;

    createPlan(
      points as GeoJSON.Polygon,
      getFullFilePath(plan.imageUrl),
      opacity
    ).then((m) => {
      planMarker = m;

      if (!refUnmounted.current) {
        setMarker(m);
      }
    });

    return () => {
      planMarker?.destroy();
    };
  }, [createPlan]);

  useEffect(() => {
    if (points) {
      planMarker?.setPosition(points, opacity);
    }
  }, [planMarker, points, opacity, google.maps.geometry]);

  useEffect(() => {
    if (planMarker) {
      let dragStartListener: google.maps.MapsEventListener;
      let dragListener: google.maps.MapsEventListener;
      let dragEndListener: google.maps.MapsEventListener;

      if (onDragStart)
        dragStartListener = planMarker.addListener('dragstart', onDragStart);
      if (onDrag) dragListener = planMarker.addListener('drag', onDrag);
      if (onDragEnd)
        dragEndListener = planMarker.addListener('dragend', onDragEnd);

      return () => {
        if (dragStartListener) dragStartListener.remove();
        if (dragListener) dragListener.remove();
        if (dragEndListener) dragEndListener.remove();
      };
    }
  }, [planMarker, onDrag, onDragEnd, onDragStart]);

  if (!planMarker) {
    return null;
  }

  return createPortal(
    <Box {...rest} ref={ref}>
      {children}
    </Box>,
    planMarker.element
  );
};

export default forwardRef(MapPlanMarker);
