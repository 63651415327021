import { ReportElementType } from '@drainify/utils';
import { Icons, Label, Text, themes } from 'preshape';
import React, { useMemo } from 'react';
import { useAppContext } from '../../../../Bootstrap/Bootstrap';
import MapLabel from '../../../../Map/MapMarker/MapLabel';
import useDrawGeometry from '../../../../Map/useDrawGeometry';
import { useReportEditorContext } from '../../../ReportEditorProvider';
import { reportMapLayerOrder } from '../ReportMapElements';
import ReportMapObservationTooltip from '../ReportMapObservationTooltip';
import { PIPE_BORDER_WIDTH, PIPE_WIDTH } from '../useDrawReportMapPipe';
import { ReportMapObservationRendererType } from '.';

const ReportMapObservationWL: ReportMapObservationRendererType = ({
  observation,
  onClick,
  visible,
}) => {
  const { theme } = useAppContext();
  const { reportEditor } = useReportEditorContext();

  const { poi, line } = useMemo(
    () => reportEditor.getObservationPoints(observation.uid),
    [reportEditor, observation]
  );

  const scale = (observation.attributes.percentage as number) / 100;

  useDrawGeometry(
    useMemo(
      () => ({
        geometry: line,
        style: {
          zIndex: reportMapLayerOrder[ReportElementType.OBSERVATION],
          strokeColor: themes[theme].colorAccentShade4,
          strokeWeight:
            observation.attributes.percentage && visible
              ? (PIPE_WIDTH - PIPE_BORDER_WIDTH * 2) * scale
              : 0,
        },
      }),
      [line, theme, visible, scale, observation.attributes.percentage]
    )
  );

  if (!poi || observation.attributes.percentage === undefined) {
    return null;
  }

  return (
    <MapLabel
      id={observation.uid}
      onClick={onClick}
      point={poi}
      strokeColor="accent-shade-3"
      visible={visible}
    >
      <ReportMapObservationTooltip observation={observation}>
        <Label
          backgroundColor="background-shade-1"
          borderColor="accent-shade-3"
          borderSize="x1"
          gap="x1"
          style={{ padding: '2px 6px' }}
          textColor="accent-shade-3"
        >
          <Icons.Droplet size="0.75rem" />
          <Text size="x1" strong>
            {observation.attributes.percentage}%
          </Text>
        </Label>
      </ReportMapObservationTooltip>
    </MapLabel>
  );
};

export default ReportMapObservationWL;
