import { SectionFlowCode, SectionPostBody } from '@drainify/types';
import { Box, Button, Buttons } from 'preshape';
import React from 'react';
import Card from '../Card/Card';
import SectionFlowVisualisation from '../Section/SectionFlowVisualisation';

type Props = {
  onChange: (flow: SectionFlowCode) => void;
  section: SectionPostBody;
  value?: SectionFlowCode;
};

const FlowInput = ({ onChange, section, value }: Props) => {
  return (
    <Box alignChildrenVertical="middle" flex="vertical" gap="x8" grow>
      <Card alignChildren="middle" flex="horizontal">
        <SectionFlowVisualisation gap="x4" section={section} />
      </Card>

      <Box alignChildren="middle" flex="vertical" gap="x4">
        <Buttons joined>
          <Button
            active={value === SectionFlowCode.ETS}
            grow
            onClick={() => onChange(SectionFlowCode.ETS)}
            size="x3"
          >
            Downstream
          </Button>

          <Button
            active={value === SectionFlowCode.STE}
            grow
            onClick={() => onChange(SectionFlowCode.STE)}
            size="x3"
          >
            Upstream
          </Button>
        </Buttons>
      </Box>
    </Box>
  );
};

export default FlowInput;
