import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useProjectContext } from '../../../components/Project/ProjectProvider';
import ReportMapSidebarToolsSiteSectionDefaults from '../../../components/Report/ReportMap/ReportMapSidebar/ReportMapSidebarToolsSiteSectionDefaults';
import Page from '../Layout/Page';
import PageBody from '../Layout/PageBody';
import PageHeader from '../Layout/PageHeader';

const SectionDefaultsPage = () => {
  const navigate = useNavigate();
  const { activeBooking } = useProjectContext();

  return (
    <Page>
      <PageHeader title="Section defaults" />
      <PageBody>
        <ReportMapSidebarToolsSiteSectionDefaults
          modalVisible={true}
          onClose={() =>
            navigate(`../../..?activeBookingId=${activeBooking?.uid}`)
          }
        />
      </PageBody>
    </Page>
  );
};

export default SectionDefaultsPage;
