import { Distance, DistanceUnit, UnitSystem } from '@drainify/types';
import {
  convertMToFeet,
  convertMToInches,
  convertMToMM,
  normaliseDistance,
} from '@drainify/utils';
import { useContext } from 'react';
import { RootContext } from '../Bootstrap/Bootstrap';

export type DisplayDistanceType = 'long' | 'short';

const getConvertedDistance = (
  type: DisplayDistanceType,
  unitSystem: UnitSystem | undefined,
  value: number
): Distance => {
  const distanceNorm = normaliseDistance([value, DistanceUnit.M]);

  if (unitSystem === 'imperial') {
    if (type === 'long') return convertMToFeet(distanceNorm[0]);
    if (type === 'short') return convertMToInches(distanceNorm[0]);
  }

  if (unitSystem === 'metric') {
    if (!unitSystem || type === 'long') return distanceNorm;
    if (type === 'short') return convertMToMM(distanceNorm[0]);
  }

  return distanceNorm;
};

function useDisplayDistance(
  type: DisplayDistanceType,
  value?: number | null
): Distance;
function useDisplayDistance(
  type: DisplayDistanceType,
  value: number[]
): Distance[];
function useDisplayDistance(
  type: DisplayDistanceType,
  value?: number | number[]
): Distance | Distance[];
function useDisplayDistance(
  type: DisplayDistanceType,
  value: null | number | number[] = 0
): Distance | Distance[] {
  const { unitSystem } = useContext(RootContext);

  if (value === null) {
    return [0, DistanceUnit.M];
  }

  if (Array.isArray(value)) {
    return value.map((value) => getConvertedDistance(type, unitSystem, value));
  }

  return getConvertedDistance(type, unitSystem, value);
}

export default useDisplayDistance;
