import { ModalFooterInsert, Text, useFormContext } from 'preshape';
import React, { useEffect, useState } from 'react';
import { useWizardContext } from './Wizard';

const WizardStepError = () => {
  const { activeStepId } = useWizardContext();
  const { getError } = useFormContext();
  const error = activeStepId && getError(activeStepId);
  const [errorState, setErrorState] = useState(error);

  useEffect(() => {
    if (error) {
      setErrorState(error);
    }
  }, [error]);

  const formatErrorMessage = (e?: string) => {
    if(e && e.startsWith('attributes.')) {
      e = e.substring('attributes.'.length)
    }
    if(e) {
      return e[0].toUpperCase() + e.substring(1)
    } else e;
  }

  return (
    <ModalFooterInsert visible={!!error}>
      <Text align="middle" size="x2" textColor="negative-shade-4" strong>
        {formatErrorMessage(errorState?.toString())}
      </Text>
    </ModalFooterInsert>
  );
};


export default WizardStepError;
