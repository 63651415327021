import { Image, Box } from 'preshape';
import React, {
  PropsWithChildren,
  ReactEventHandler,
  useEffect,
  useState,
} from 'react';
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  PercentCrop,
} from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
type Props = {
  onChange?: (crop: PercentCrop) => void;
  cropData?: PercentCrop;
  src?: string;
};

const ImageCropper = ({
  cropData,
  onChange,
  src,
}: PropsWithChildren<Props>) => {
  const [[width, height], setWidthHeight] = useState<[number, number]>([0, 0]);

  const handleLoad: ReactEventHandler<HTMLImageElement> = (event) => {
    const { width, height } = event.currentTarget;
    setWidthHeight([width, height]);
  };

  useEffect(() => {
    if (!cropData && width && height) {
      onChange?.(
        centerCrop(
          makeAspectCrop(
            {
              unit: '%',
              width: 90,
            },
            1,
            width,
            height
          ),
          width,
          height
        ) as PercentCrop
      );
    }
  }, [cropData, width, height]);

  useEffect(() => {
    setWidthHeight([0, 0]);
  }, [src]);

  return (
    <Box
      backgroundColor="background-shade-1"
      borderRadius="x1"
      borderSize="x2"
      overflow="hidden"
    >
      <ReactCrop
        aspect={1}
        circularCrop
        crop={cropData}
        onChange={(c, percentCrop) => onChange?.(percentCrop)}
        style={{ display: 'block' }}
      >
        <Image onLoad={handleLoad} key={src} src={src} />
      </ReactCrop>
    </Box>
  );
};

export default ImageCropper;
