import React from 'react';
import './VideoPlayerProgressbar.css';
import { useVideoContext } from '../VideoProvider';

const VideoPlayerProgressbarRunner = () => {
  const { played, handleSeekChange, handleSeekMouseDown, handleSeekMouseUp } =
    useVideoContext();

  return (
    <input
      type="range"
      style={{
        background:
          'linear-gradient(to right,#4c6ef5 0%,#4c6ef5 ' +
          played * 100 +
          '%, white ' +
          played * 100 +
          '%, white 100%)',
        width: '100%',
        border: 'none',
      }}
      min={0}
      width="100%"
      max={0.999999}
      step="any"
      value={played}
      onMouseDown={handleSeekMouseDown}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        handleSeekChange(parseFloat(e.target.value))
      }
      onMouseUp={(e: React.MouseEvent<HTMLInputElement>) =>
        handleSeekMouseUp(e.currentTarget.value)
      }
    />
  );
};

export default VideoPlayerProgressbarRunner;
