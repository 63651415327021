import getBboxPolygon from '@turf/bbox-polygon';
import getCenter from '@turf/center';
import getDistance from '@turf/distance';
import {
  lineString as lineStringFromCoords,
  point as pointFromCoords,
} from '@turf/helpers';
import getLineSliceAlong from '@turf/line-slice-along';
import transformTranslate from '@turf/transform-translate';
import { emptySectionPoints } from './section';

/**
 *
 */
export const GeoJSONPointBlank: GeoJSON.Point = {
  type: 'Point',
  coordinates: [0, 0],
};

/**
 *
 */
export const GeoJSONPolygonBlank: GeoJSON.Polygon = {
  type: 'Polygon',
  coordinates: [],
};

/**
 *
 */
const shift = (a: GeoJSON.Point, b: GeoJSON.Point, delta = 1): GeoJSON.Point =>
  pointFromCoords([
    a.coordinates[0] + b.coordinates[0] * delta,
    a.coordinates[1] + b.coordinates[1] * delta,
  ]).geometry;

/**
 *
 */
export const addPoints = (a: GeoJSON.Point, b: GeoJSON.Point) => shift(a, b);

/**
 *
 */
export const subtractPoints = (a: GeoJSON.Point, b: GeoJSON.Point) =>
  shift(a, b, -1);

/**
 *
 */
export const getBoundsFromPoint = (point: GeoJSON.Point, size = 0.1) => {
  const {
    coordinates: [minLng, maxLat],
  } = transformTranslate(point, size * 0.5, 45);
  const {
    coordinates: [maxLng, minLat],
  } = transformTranslate(point, size * 0.5, 225);
  const { geometry } = getBboxPolygon([minLng, minLat, maxLng, maxLat]);
  return geometry;
};

/**
 *
 */
export const getRadiusFromPoint = (point: GeoJSON.Point, size = 0.1) => {
  const {
    coordinates: [minLng, maxLat],
  } = transformTranslate(point, size * 0.5, 45);
  const {
    coordinates: [maxLng, minLat],
  } = transformTranslate(point, size * 0.5, 225);
  return [minLng, minLat, maxLng, maxLat];
};
/**
 *
 */
export const getCornersFromBbox = (bbox: GeoJSON.BBox) => {
  const [minLng, minLat, maxLng, maxLat] = bbox;

  return {
    ne: { lng: maxLng, lat: maxLat },
    nw: { lng: minLng, lat: maxLat },
    se: { lng: maxLng, lat: minLat },
    sw: { lng: minLng, lat: minLat },
  };
};

/**
 *
 */
export const getPointBetweenPoints = (
  pointA: GeoJSON.Point,
  pointB: GeoJSON.Point,
  distance: number
) => {
  if (distance === 0) {
    return pointA;
  }

  if (distance === Infinity) {
    return pointB;
  }

  const lineString = lineStringFromCoords([
    pointA.coordinates,
    pointB.coordinates,
  ]);

  const lineStringSplit = getLineSliceAlong(lineString, 0, distance, {
    units: 'meters',
  });

  const point = pointFromCoords(
    lineStringSplit.geometry.coordinates[1]
  ).geometry;

  return point;
};

/**
 *
 */
export const getMidPointBetweenPoints = (
  pointA: GeoJSON.Point,
  pointB: GeoJSON.Point
) => {
  const lineString = lineStringFromCoords([
    pointA.coordinates,
    pointB.coordinates,
  ]);

  const centerFeature = getCenter(lineString);

  if (centerFeature) {
    return centerFeature.geometry;
  }

  return null;
};

/**
 *
 */
export const getSectionPoints = (
  pointA?: GeoJSON.Point,
  pointB?: GeoJSON.Point,
  poiDistance?: number
) => {
  if (!pointA || !pointB) return emptySectionPoints;

  const distance = getDistance(pointA.coordinates, pointB.coordinates, {
    units: 'meters',
  });

  const poi = getPointBetweenPoints(
    pointA,
    pointB,
    poiDistance === undefined ? distance * 0.5 : poiDistance
  );

  const bbox: GeoJSON.BBox = [
    pointA.coordinates[0],
    pointA.coordinates[1],
    pointB.coordinates[0],
    pointB.coordinates[1],
  ];

  return {
    a: pointA,
    b: pointB,
    bbox,
    distance,
    line: lineStringFromCoords([pointA.coordinates, pointB.coordinates])
      .geometry,
    poi,
  };
};
