import { Box, BoxProps, Text } from 'preshape';
import React, { PropsWithChildren } from 'react';
import Card from '../Card/Card';
import CardEditButton from '../Card/CardEditButton';
import ReportErrorIndicator from '../Report/ReportErrorIndicator';
import SearchResult from '../Search/SearchResult';
import { NullAction, useSummaryCardAction } from './SummaryCard';

type Props = BoxProps & NullAction;

const SummaryCardSection = ({
  children,
  error,
  onEdit,
  title,
  ...props
}: PropsWithChildren<Props>) => {
  // Note: value needs to remain in props spread above
  const { value, ...rest } = props;
  const enableNullAction = 'value' in props;

  useSummaryCardAction({ title, value, onEdit, error }, enableNullAction);

  if (
    enableNullAction &&
    (Array.isArray(value) ? !value.length : value === undefined || value === '')
  ) {
    return null;
  }

  return (
    <SearchResult keywords={title}>
      <Card
        {...rest}
        action={
          onEdit && <CardEditButton onClick={onEdit} variant="tertiary" />
        }
        margin="x8"
        title={
          <Box alignChildrenVertical="middle" flex="horizontal" gap="x2">
            <Text>{title}</Text>
            {error && <ReportErrorIndicator error={error} />}
          </Box>
        }
      >
        {children}
      </Card>
    </SearchResult>
  );
};

export default SummaryCardSection;
