import { useStripe, useElements } from '@stripe/react-stripe-js';
import {
  Alert,
  Box,
  Button,
  Buttons,
  Icons,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Text,
} from 'preshape';
import React, { useContext, useEffect } from 'react';
import useSubscriptionsDetails from '../../hooks/useSubscriptionsDetails';
import { stripePaymentWithFallback } from '../../utils/payments';
import AsyncButton from '../AsyncButton/AsyncButton';
import { AuthenticationContext } from '../Authentication/Authenticate';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import { useUsageContext } from './UsageProvider';

const UserAddCredits = () => {
  const [visible, setVisible] = React.useState(false);
  const stripe = useStripe();
  const { usage, refetch: refetchUsage } = useUsageContext();
  const { user } = useContext(AuthenticationContext);
  const elements = useElements();
  const [error, setError] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);
  const [quantity, setQuantity] = React.useState<number>(0);
  const [confirmationVisbile, setConfirmationVisible] = React.useState(false);
  const { query: subscriptionsQuery } = useSubscriptionsDetails();

  useEffect(() => {
    if (!visible) {
      setQuantity(0);
    }
  }, [visible]);

  if (!stripe) {
    return null;
  }

  const handleSave = async () => {
    const payload = { quantity };
    const onSuccess = async () => {
      refetchUsage?.();
      setConfirmationVisible(true);
    };

    await stripePaymentWithFallback({
      onError: setError,
      onLoading: setLoading,
      onSuccess,
      payload,
      stripe,
      elements,
      user,
      endpoint: '/payment/credit',
      loading,
      cardless: true,
    });
  };

  if (!usage) {
    return null;
  }

  const userSubscription = subscriptionsQuery.data?.data.find(
    (e) => e.tier === usage.tier
  );

  return (
    <>
      <Button
        onClick={() => setVisible(true)}
        color="accent"
        variant="tertiary"
        size="x1"
      >
        Survey Credits
      </Button>
      {visible && (
        <Modal
          animation="FadeSlideUp"
          margin="x4"
          onClose={() => {
            setVisible(false);
            setQuantity(1);
          }}
          overlayBackgroundCloseOnClick={false}
          visible={visible}
        >
          <ModalHeader>
            <ModalTitle>Add credits</ModalTitle>
          </ModalHeader>

          <ModalBody flex="vertical" alignChildrenVertical="middle">
            <Box flex="vertical" gap="x3">
              <Box>
                <Text size="x6" strong>
                  Add credits
                </Text>
              </Box>
              <DropdownMenu
                options={[
                  {
                    name: '0',
                    value: 0,
                  },
                  {
                    name: '1',
                    value: 1,
                  },
                  {
                    name: '5',
                    value: 5,
                  },
                  {
                    name: '10',
                    value: 10,
                  },
                  {
                    name: '100',
                    value: 100,
                  },
                ]}
                value={quantity}
                onChange={(e) => {
                  setQuantity(e);
                }}
              />
              <Box flex="vertical" gap="x8">
                <Box flex="vertical" gap="x3">
                  <Text size="x2">{`${
                    userSubscription?.displayName
                  } survey price: £${(
                    userSubscription!.creditPrice / 100
                  ).toFixed(2)}`}</Text>
                  <Box shrink>
                    <Label size="x4">
                      Total:{' '}
                      {`£${(
                        (quantity *
                          ((userSubscription && userSubscription.creditPrice) ||
                            0)) /
                        100
                      ).toFixed(2)}`}
                    </Label>
                  </Box>
                </Box>
                {error && (
                  <Alert
                    alignChildrenVertical="middle"
                    backgroundColor="negative-shade-2"
                    color="negative"
                    flex="horizontal"
                    gap="x3"
                    padding="x3"
                  >
                    <Icons.AlertTriangle />
                    <Text basis="0" grow size="x2">
                      {`There was an issue: ${error}`}
                    </Text>
                  </Alert>
                )}
              </Box>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Buttons>
              <AsyncButton
                isError={error !== undefined}
                grow
                variant="primary"
                color="positive"
                disabled={quantity === 0}
                isLoading={loading}
                onClick={async () => {
                  if (!loading) {
                    handleSave();
                  }
                }}
              >
                <Icons.CreditCard /> confirm purchase
              </AsyncButton>
            </Buttons>
          </ModalFooter>
        </Modal>
      )}
      <Modal
        visible={confirmationVisbile}
        onClose={() => {
          setConfirmationVisible(false);
          setQuantity(1);
          setVisible(false);
        }}
      >
        <ModalHeader>Success</ModalHeader>
        <ModalBody>
          <Box>
            <Box flex="vertical" gap="x3">
              <Box flex="horizontal" alignChildrenHorizontal="middle">
                <Text textColor="positive-shade-5" align="middle">
                  <Icons.CheckCircle size="5rem" alignChildren="middle" />
                </Text>
              </Box>
              {quantity} surveys have been credited to your account!
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter flex="horizontal">
          <Button
            variant="primary"
            color="accent"
            grow
            onClick={() => {
              setConfirmationVisible(false);
              setQuantity(1);
              setVisible(false);
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default UserAddCredits;
