"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDistanceUnitText = exports.normaliseDistance = exports.convertMToInches = exports.convertMToFeet = exports.convertMToMM = exports.convertMMToM = exports.convertFeetToM = exports.convertInchesToM = void 0;
const types_1 = require("@drainify/types");
const convertInchesToM = (value) => [
    value * 305,
    types_1.DistanceUnit.M,
];
exports.convertInchesToM = convertInchesToM;
const convertFeetToM = (value) => [
    value / 3.281,
    types_1.DistanceUnit.M,
];
exports.convertFeetToM = convertFeetToM;
const convertMMToM = (value) => [
    value / 1000,
    types_1.DistanceUnit.M,
];
exports.convertMMToM = convertMMToM;
const convertMToMM = (value) => [
    value * 1000,
    types_1.DistanceUnit.MM,
];
exports.convertMToMM = convertMToMM;
const convertMToFeet = (value) => [
    value * 3.281,
    types_1.DistanceUnit.FEET,
];
exports.convertMToFeet = convertMToFeet;
const convertMToInches = (value) => [
    value * 39.37,
    types_1.DistanceUnit.INCHES,
];
exports.convertMToInches = convertMToInches;
const normaliseDistance = (distance) => {
    const [value, unit] = distance;
    if (unit === types_1.DistanceUnit.M)
        return distance;
    if (unit === types_1.DistanceUnit.MM)
        return (0, exports.convertMMToM)(value);
    if (unit === types_1.DistanceUnit.FEET)
        return (0, exports.convertFeetToM)(value);
    if (unit === types_1.DistanceUnit.INCHES)
        return (0, exports.convertInchesToM)(value);
    return distance;
};
exports.normaliseDistance = normaliseDistance;
const getDistanceUnitText = (unit, short) => {
    return {
        [types_1.DistanceUnit.FEET]: short ? 'ft' : 'Feet',
        [types_1.DistanceUnit.INCHES]: short ? 'in' : 'Inches',
        [types_1.DistanceUnit.M]: short ? 'm' : 'Meters',
        [types_1.DistanceUnit.MM]: short ? 'mm' : 'Millimeters',
    }[unit];
};
exports.getDistanceUnitText = getDistanceUnitText;
