import { Text, TextProps } from 'preshape';
import React, { PropsWithChildren } from 'react';

type Props = TextProps & {};

const SymbolTemplate = ({
  borderRadius = 'x2',
  ...props
}: PropsWithChildren<Props>) => {
  return (
    <Text
      {...props}
      alignChildren="middle"
      borderSize="x1"
      borderColor="text-shade-4"
      borderRadius={borderRadius}
      flex="vertical"
      ellipsis
      height={40}
      size="x3"
      strong
      width={40}
    />
  );
};

export default SymbolTemplate;
