import React from 'react';
import PlanLocationHack from '../../../components/Node/PlanLocationHack';
import Page from '../Layout/Page';
import PageHeader from '../Layout/PageHeader';

const SiteMapPage = () => {
  return (
    <Page>
      <PageHeader title="Site map" />
      <PlanLocationHack onChange={() => {}} />
    </Page>
  );
};

export default SiteMapPage;
