import { WithTracking } from './Api';
import { Org, OrgRole } from './Org';
import { User } from './User';

export type Member = WithTracking & {
  uid: string;
  user: User;
  org: Org;
  role: OrgRole;
  certificateUrl?: string;
  certificateNumber?: string;
};

export type MemberPostBody = Pick<Member, 'role'>;
