import { CountryData } from 'emoji-flags';
import { WithTracking } from './Api';
import { ImageCropData } from './Image';

export enum OrgRole {
  OWNER = 'owner',
  ADMIN = 'admin',
  ENGINEER = 'engineer',
}

export interface Org extends WithTracking {
  uid: string;
  name: string;
  logoCropData?: ImageCropData;
  logoUrlCropped?: string;
  logoUrlOriginal?: string;
  country: CountryData['code'];
  description?: string;
  contactEmail?: string;
  contactNumber?: string;
}

export type OrgPostBody = Omit<Org, 'uid'>;
