import { Icons } from 'preshape';

export const userSettings = [
   {
      title: 'Profile',
      description: 'Provide your personal details and profile information',
      Icon: Icons.User,
      to: '/settings/user/profile',
   },
   {
      title: 'Security',
      description: 'Update your password and secure your account',
      Icon: Icons.Key,
      to: '/settings/user/security',
   },
   {
      title: 'My performance',
      description: 'performance',
      Icon: Icons.Activity,
      to: '/settings/user/my-performance',
   },
];

export const orgSettings = [
   {
      title: 'Organisation Details',
      description: 'Provide your personal details and profile information',
      Icon: Icons.Briefcase,
      to: '/admin/org/profile',
   },
   {
      title: 'Members',
      description: 'Provide your personal details and profile information',
      Icon: Icons.Users,
      to: '/admin/org/members',
   },
   {
      title: 'Customers',
      description: 'Provide your personal details and profile information',
      Icon: Icons.Users,
      to: '/admin/org/customers',
   },
];

export const adminSettings = [
   {
      title: 'Contractor performance',
      description: 'Coming soon!',
      Icon: Icons.Briefcase,
   },
   {
      title: 'Metrics breakdown',
      description: 'Coming soon!',
      Icon: Icons.Activity,
   },
   {
      title: '...Something else!',
      description: 'Coming soon!',
      Icon: Icons.AlertCircle,
   },
];

export const classroomVideos = [
   {
      "name": "Getting to know Drainify Desktop",
      "url": "https://youtu.be/dCT-p4p0vm4",
      "category": "1. Getting to know Drainify",
      "description": "",
      "type": [
         "Desktop"
      ]
   },
   {
      "name": " Step 1 - Create A Project",
      "url": "https://youtu.be/I7msjN9oQdM",
      "category": "3. Step by Step Guide to Producing A Report",
      "description": "New and Existing customers",
      "type": [
         "Desktop"
      ]
   },
   {
      "name": "Step 2 - Create A Job and Booking",
      "url": "https://youtu.be/28ktoDT2fAI",
      "category": "3. Step by Step Guide to Producing A Report",
      "description": "Create a Booking and Dispatch to Engineer",
      "type": [
         "Desktop"
      ]
   },
   {
      "name": "Step 3 Creating a Section on Maps",
      "url": "https://youtu.be/LCF0sLhhREA",
      "category": "3. Step by Step Guide to Producing A Report",
      "description": "Creating a Section using the map",
      "type": [
         "Desktop"
      ]
   },
   {
      "name": "Step 3A Creating a Section - without a map",
      "url": "https://youtu.be/5yAqPJylK5M",
      "category": "3. Step by Step Guide to Producing A Report",
      "description": "Creating a Section without using the map",
      "type": [
         "Desktop"
      ]
   },
   {
      "name": "Step 4 Adding Observations Using The Video Player",
      "url": "https://youtu.be/wFB6mrKoBek",
      "category": "3. Step by Step Guide to Producing A Report",
      "description": "Coding Your Report using the Video Player",
      "type": [
         "Desktop"
      ]
   },
   {
      "name": "Manhole and Node Reporting",
      "url": "https://youtu.be/DNtjlq0hxHw",
      "category": "3. Step by Step Guide to Producing A Report",
      "description": "Manhole Reports",
      "type": [
         "Desktop"
      ]
   },
   {
      "name": "Adding a Team Member",
      "url": "https://youtu.be/N3SX_vfWsaY",
      "category": "4. How To",
      "description": "Node in an Unknown Position",
      "type": [
         "Desktop",
         "Mobile"
      ]
   },
   {
      "name": "Adding a change of directions or bend onto the map",
      "url": "https://youtu.be/aydUTIL6X9E",
      "category": "4. How To",
      "description": "Node in an Unknown Position",
      "type": [
         "Desktop"
      ]
   },
   {
      "name": "Multiple inspections on a single section or lateral - for before and after videos",
      "url": "https://youtu.be/4lPuwOeqmYQ",
      "category": "4. How To",
      "description": "Node in an Unknown Position",
      "type": [
         "Desktop"
      ]
   },
   {
      "name": "Uploading Plans to Scale",
      "url": "https://youtu.be/YwLG9IxcIqY",
      "category": "4. How To",
      "description": "Uploading Plans To Scale",
      "type": [
         "Desktop"
      ]
   },
   {
      "name": "Uploading Overlay Plans",
      "url": "https://youtu.be/Ldb5oqqbSrg",
      "category": "4. How To",
      "description": "Uploading Overlay Plans",
      "type": [
         "Desktop"
      ]
   },
   {
      "name": "Adding Nodes/Sections to Uploaded Plans",
      "url": "https://youtu.be/PhAyxkje2yw",
      "category": "4. How To",
      "description": "Adding Section/Lateral to Upoaded Plans",
      "type": [
         "Desktop"
      ]
   },
   {
      "name": "Uploading Videos",
      "url": "https://youtu.be/7g2xMbSdn90",
      "category": "4. How To",
      "description": "Video Uploads to Drainify",
      "type": [
         "Desktop",
         "Mobile"
      ]
   },
   {
      "name": "Importing XML Files",
      "url": "https://youtu.be/EsyLEEp8xbo",
      "category": "4. How To",
      "description": "XML File Imports from Cameras with Integrated Software",
      "type": [
         "Desktop"
      ]
   },
   {
      "name": "Connecting onto a junction to create a Section/Lateral",
      "url": "https://youtu.be/hT74D8OknXs",
      "category": "4. How To",
      "description": "Creating a Section with Junctions or Connections on Maps",
      "type": [
         "Desktop"
      ]
   },
   {
      "name": "The Polygon Tool",
      "url": "https://youtu.be/jZCPP_cgfMM",
      "category": "4. How To",
      "description": "Highlighting an Area or Boundary on Maps",
      "type": [
         "Desktop"
      ]
   },
   {
      "name": "The Measuring Tool",
      "url": "https://youtu.be/Mfqovkevqn0",
      "category": "4. How To",
      "description": "Measuring Distances on Map",
      "type": [
         "Desktop"
      ]
   },
   {
      "name": "Moving a Node",
      "url": "https://youtu.be/64ycwJQ0Y2U",
      "category": "4. How To",
      "description": "Node Not in the Correct Location on Maps?",
      "type": [
         "Desktop"
      ]
   },
   {
      "name": "Using the UNP (unknown position) node",
      "url": "https://youtu.be/86kdOLJ_D8E",
      "category": "4. How To",
      "description": "Node in an Unknown Position",
      "type": [
         "Desktop",
         "Mobile"
      ]
   },
   {
      "name": "Adding a team member",
      "url": "https://youtube.com/shorts/GddIBt11aRw?feature=share",
      "category": "4. How To",
      "description": "Adding a team member",
      "type": [
         "Mobile"
      ]
   },
   {
      "name": "The Measuring Tool",
      "url": "https://youtube.com/shorts/6FM2fJiAx8A?feature=share",
      "category": "4. How To",
      "description": "Measuring Distances on Map",
      "type": [
         "Mobile"
      ]
   },
   {
      "name": "The Polygon Tool",
      "url": "https://youtube.com/shorts/72xuRnloq5U?feature=share",
      "category": "4. How To",
      "description": "Highlighting an Area or Boundary on Maps",
      "type": [
         "Mobile"
      ]
   },
   {
      "name": "Creating a Bend in the Pipe",
      "url": "https://youtube.com/shorts/MeUmpr4ERYc?feature=share",
      "category": "4. How To",
      "description": "How to Add a Bend to Your Section or Lateral on Maps",
      "type": [
         "Mobile"
      ]
   },
   {
      "name": "Moving a Node",
      "url": "https://youtube.com/shorts/olDYOpRtMz4?feature=share",
      "category": "4. How To",
      "description": "Node Not in the Correct Location on Mobile Maps?",
      "type": [
         "Mobile"
      ]
   },
   {
      "name": "Getting to Know Drainify-Mobile",
      "url": "https://youtu.be/X0KxccNXAS8",
      "category": "1. Getting to Know Drainify",
      "description": "Mobile Icon Tour",
      "type": [
         "Mobile"
      ]
   },
   {
      "name": "Snap Reports",
      "url": "https://youtu.be/wPSUW92Ws60",
      "category": "1. Getting to Know Drainify",
      "description": "",
      "type": [
         "Mobile"
      ]
   },
   {
      "name": "Step 1 - Create A Job/Booking",
      "url": "https://youtube.com/shorts/zGgCW-tuC3s?feature=share",
      "category": "3. Step by Step Guide to Producing A Report",
      "description": "Create a Job/Booking",
      "type": [
         "Mobile"
      ]
   },
   {
      "name": "Step 2 - Creating Inspections on the Map",
      "url": "https://youtu.be/FGQk-HGHCig",
      "category": "3. Step by Step Guide to Producing A Report",
      "description": "Creating Inspections, Sections, and Laterals on the Map",
      "type": [
         "Mobile"
      ]
   },
   {
      "name": "Step 2A - Inspections without the Map",
      "url": "https://youtube.com/shorts/UdApqhuGYuc?feature=share",
      "category": "3. Step by Step Guide to Producing A Report",
      "description": "Creating Inspections, Sections, and Laterals No Map",
      "type": [
         "Mobile"
      ]
   },
   {
      "name": "Step 3 - Adding Observations",
      "url": "https://youtube.com/shorts/6t_6YFLcUpE?feature=share",
      "category": "3. Step by Step Guide to Producing A Report",
      "description": "Adding Obsevation and Images",
      "type": [
         "Mobile"
      ]
   },
   {
      "name": "Step 3A - AI Code Assistant",
      "url": "https://youtu.be/3JZnzR1Tg2c",
      "category": "3. Step by Step Guide to Producing A Report",
      "description": "",
      "type": [
         "Mobile"
      ]
   },
   {
      "name": "Node and Manhole Reporting",
      "url": "https://youtu.be/DNtjlq0hxHw",
      "category": "3. Step by Step Guide to Producing A Report",
      "description": "Manhole Reports",
      "type": [
         "Mobile"
      ]
   },
   {
      "name": "Plotting Assets on to Maps",
      "url": "https://youtu.be/q8CK05fHZjk",
      "category": "4. How To",
      "description": "Plotting unsurveyed drains and non-drainage assets on maps",
      "type": [
         "Desktop"
      ]
   },
   {
      "name": "Coding with AI Coding Assistant",
      "url": "https://youtu.be/00k0QWpwVa4",
      "category": "2. AI Coding Assistant",
      "description": "",
      "type": [
         "Desktop"
      ]
   },
   {
      "name": "Optimising AI Coding Assistant",
      "url": "https://youtu.be/ouXPUJKVRAI",
      "category": "2. AI Coding Assistant",
      "description": "",
      "type": [
         "Desktop"
      ]
   },
   {
      "name": "Using the QA tool",
      "url": "https://youtu.be/LwwQEUeZetQ",
      "category": "4. How To",
      "description": "Using the QA tool",
      "type": [
         "Desktop"
      ]
   },
]

export const mobileClassroomVideos = classroomVideos.filter((e) =>
   e.type.includes('Mobile')
);

export const desktopClassroomVideos = classroomVideos.filter((e) =>
   e.type.includes('Desktop')
);
