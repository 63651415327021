import { Box } from 'preshape';
import React from 'react';
import { useReportEditorContext } from '../../Report/ReportEditorProvider';
import SectionView from '../../Section/SectionView';
import VideoPlayerSidebarChapterContainer from './VideoPlayerSidebarChapterContainer';

type Props = {
  inspectionId: string;
  projectId: string;
};

const VideoPlayerSidebar = ({ inspectionId, projectId }: Props) => {
  const { reportEditor } = useReportEditorContext();
  return (
    <Box flex="vertical">
      <Box
        flex="horizontal"
        padding="x3"
        alignChildrenHorizontal="middle"
        backgroundColor="background-shade-3"
      >
        <SectionView
          section={reportEditor.getSectionByUid(
            reportEditor.getInspectionByUid(inspectionId)?.sectionUid
          )}
          shrink
        />
      </Box>
      <VideoPlayerSidebarChapterContainer
        inspectionId={inspectionId}
        projectId={projectId}
      />
    </Box>
  );
};

export default VideoPlayerSidebar;
