import { Box, TextProps, Icons, Label, Text } from 'preshape';
import React, { PropsWithChildren } from 'react';
import useValidTimeInputs from './useValidTimeInputs';

type TimeRangeTextProps = TextProps & {
  startTime?: [number, number];
};

const get24HoursTo12Hours = (hours: number) => {
  if (hours === 0) return 12;
  if (hours > 12) return hours - 12;

  return hours;
};

const TimeRangeText = ({
  startTime: dirtyStartTime,
  ...rest
}: PropsWithChildren<TimeRangeTextProps>) => {
  const startTime = useValidTimeInputs(dirtyStartTime);

  const isStartTimePM = startTime[0] >= 12;

  let timeContent;

  if (dirtyStartTime) {
    timeContent = (
      <Box flex="horizontal" gap="x2">
        <Label
          alignChildrenVertical="middle"
          backgroundColor="accent-shade-1"
          flex="horizontal"
          gap="x2"
          textColor="text-shade-1"
        >
          <Icons.Clock />
          <Text flex="horizontal" gap="x1">
            {get24HoursTo12Hours(startTime[0]).toString().padStart(2, '0')}:
            {startTime[1].toString().padStart(2, '0')}
            <Text inline strong uppercase>
              {isStartTimePM ? 'pm' : 'am'}
            </Text>
          </Text>
        </Label>
      </Box>
    );
  }

  return (
    <Text {...rest} gap="x2" size="x3" strong wrap>
      {timeContent}
    </Text>
  );
};

export default TimeRangeText;
