import { getFullFilePath, getFullVideoFilePath } from '@drainify/utils';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { Box, Button, Icons, Modal, ModalBody, Text } from 'preshape';
import React, { useContext } from 'react';
import { ServiceWorkerContext } from '../Bootstrap/ServiceWorkerProvider';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import Spinner from '../Spinner/Spinner';
import { convertToXML } from './XMLExportUtil';

const XMLExport = () => {
  const { reportEditor } = useReportEditorContext();
  const { project, activeBooking } = useProjectContext();
  const { updating, pendingChanges } = useContext(ServiceWorkerContext);
  const [loading, setLoading] = React.useState(false)

  const exportAsXML = async () => {
    const zip = new JSZip();

    const inspections = reportEditor.getJobInspections(activeBooking?.uid!)

    for(const inspection of inspections) {
      const observations = reportEditor.getInspectionObservations(inspection.uid)
      for(const observation of observations) {
        if(observation.imageUrl) {
          const response = await fetch(getFullFilePath(observation.imageUrl)!, {
            mode: 'cors'
          });
          const blob = await response.blob();
          zip?.file(`${inspection.uid}/${observation.uid}.jpg`, blob);
        }
      }
      if(inspection.video?.videoUrl) {
        const response = await fetch(getFullVideoFilePath(inspection.video.videoUrl)!);
        const blob = await response.blob();
        zip?.file(`${inspection.uid}/video.mp4`, blob);
      }
      setLoading(false)
    }

    zip.file(`${activeBooking?.jobId}.xml`, convertToXML(reportEditor, project!, activeBooking!), { });
  
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, `${activeBooking?.jobId}.zip`);
    });
  };

  return (
    <>
            <Button
              variant="tertiary"
              color="accent"
              flex="horizontal"
              disabled={updating || pendingChanges}
              gap="x2"
              onClick={async () => {
                setLoading(true)
                await exportAsXML()
              }
              }
              grow
              alignChildrenHorizontal="start"
            >
              <Icons.Download />
              Export to XML
            </Button>
            {
              loading && <Modal visible>
                <ModalBody>
                  <Text>Preparing export...</Text>
                  <Box flex='horizontal' alignChildrenHorizontal='middle'>
                    <Spinner/>
                  </Box>
                </ModalBody>
              </Modal>
            }
            </>
  );
};

export default XMLExport;
