import { quadtree } from 'd3-quadtree';
import { useMemo } from 'react';

export type Tick = {
  a: number;
  aOffset: number;
  n: number;
  x: (offset?: number) => number;
  y: (offset?: number) => number;
};

type Props = {
  segments?: number;
  size?: number;
};

const useTicks = ({ size = 230, segments = 8 }: Props) => {
  const segmentSize = (Math.PI * 2) / segments;
  const R = size * 0.5;

  const ticks = useMemo(() => {
    return Array.from({ length: segments }).map((_, n): Tick => {
      const a = n * segmentSize;
      const aOffset = a - Math.PI * 0.5;
      const x = (offset: number = 0) => R + (R - offset) * Math.cos(aOffset);
      const y = (offset: number = 0) => R + (R - offset) * Math.sin(aOffset);

      return { n: n || 12, a, aOffset, x, y };
    });
  }, [segments, R]);

  const ticksQuadtreeByXY = useMemo(() => {
    return quadtree(
      ticks,
      ({ x }) => x(),
      ({ y }) => y()
    );
  }, [ticks]);

  return {
    quadtree: ticksQuadtreeByXY,
    ticks,
    segmentSize,
    R,
  };
};

export default useTicks;
