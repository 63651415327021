import { addPoints } from '@drainify/utils';
import omit from 'lodash.omit';
import { useCallback, useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';
import { useMapContext } from '../Map';
import getGeometryProjection from './getGeometryProjection';
import { MessageResponseGetNextStore } from './getGeometryStore.worker';
import {
  GeoStore,
  GeoStoreGeometry,
  GeoStoreEntryOptions,
  GeoStoreEntry,
} from './types';
import { isGeometryPoint } from './utils';

export type useGeometryStoreResult = {
  store: GeoStore;
  register: (
    geometry: GeoStoreGeometry,
    opts?: GeoStoreEntryOptions,
    domRect?: DOMRect
  ) => string | undefined;
  update: (
    key: string,
    geometry: GeoStoreGeometry,
    opts?: GeoStoreEntryOptions,
    domRect?: DOMRect
  ) => void;
  remove: (key: string) => void;
};

const useGeometryStore = (): useGeometryStoreResult => {
  const {
    addBoundsChangeListener,
    fromGeoJSONPointToScreen,
    fromScreenToGeoJSONPoint,
    isReady,
  } = useMapContext();
  const refQueueUpdate = useRef(0);
  const refStore = useRef<GeoStore['map']>({});
  const refWorker = useRef<Worker>();

  const [store, setStore] = useState<GeoStore>({
    groups: {
      LineString: [],
      Point: [],
    },
    list: [],
    map: {},
  });

  // Scans through the store and updates the projections and translations
  const queueUpdateState = useCallback(() => {
    if (!isReady) {
      return;
    }

    Object.keys(refStore.current).forEach((key) => {
      refStore.current[key].geometryProjected = getGeometryProjection(
        fromGeoJSONPointToScreen,
        refStore.current[key].geometry
      );
    });

    if (refQueueUpdate.current) {
      window.clearTimeout(refQueueUpdate.current);
    }

    refQueueUpdate.current = window.setTimeout(() => {
      refWorker.current?.postMessage({
        eventName: 'getNextStore',
        eventId: refQueueUpdate.current,
        data: {
          store: refStore.current,
        },
      });
    });
  }, [fromGeoJSONPointToScreen, isReady, setStore]);

  // Update an existing entry
  const update = useCallback(
    (
      key: string,
      geometry: GeoStoreGeometry,
      opts?: GeoStoreEntryOptions,
      domRect?: DOMRect
    ) => {
      if (!isReady) {
        return;
      }

      // Stops infinite rendering.
      if (
        refStore.current[key]?.geometry === geometry &&
        refStore.current[key].opts === opts
      ) {
        return;
      }

      const entry: GeoStoreEntry = {
        domRect,
        geometry,
        geometryProjected: getGeometryProjection(
          fromGeoJSONPointToScreen,
          geometry
        ),
        geometryTranslated: geometry,
        key,
        opts,
      };

      refStore.current = {
        ...refStore.current,
        [key]: entry,
      };

      queueUpdateState();
    },
    [fromGeoJSONPointToScreen, isReady, queueUpdateState]
  );

  // Remove an existing entry
  const remove = useCallback(
    (key: string) => {
      refStore.current = omit(refStore.current, [key]);
      queueUpdateState();
    },
    [setStore, queueUpdateState]
  );

  // Register a new entry
  const register = useCallback(
    (geometry: GeoStoreGeometry, opts?: GeoStoreEntryOptions) => {
      const key = v4();
      update(key, geometry, opts);

      return key;
    },
    [update]
  );

  // When the map coordinate finish update then update all the
  // relative geometry information
  useEffect(() => {
    return addBoundsChangeListener(() => queueUpdateState());
  }, [addBoundsChangeListener, queueUpdateState]);

  useEffect(() => {
    refWorker.current = new Worker(
      new URL('./getGeometryStore.worker.ts', import.meta.url),
      { type: 'module' }
    );

    refWorker.current.addEventListener(
      'message',
      ({
        data: {
          eventName,
          eventId,
          data: { store },
        },
      }: MessageEvent<MessageResponseGetNextStore>) => {
        if (
          eventName !== 'getNextStore' &&
          eventId !== refQueueUpdate.current
        ) {
          return;
        }

        store.list.forEach((key) => {
          const entry = store.map[key];

          if (entry.relative && isGeometryPoint(entry.geometryProjected)) {
            const {
              coordinates: [x, y],
            } = addPoints(entry.geometryProjected, entry.relative.translate);

            store.map[key].geometryTranslated = fromScreenToGeoJSONPoint({
              x,
              y,
            });
          }
        });

        setStore(store);
      }
    );

    return () => {
      refWorker.current?.terminate();
      window.clearTimeout(refQueueUpdate.current);
      refQueueUpdate.current = 0;
      refWorker.current = undefined;
    };
  }, [fromScreenToGeoJSONPoint]);

  return {
    store,
    register,
    update,
    remove,
  };
};

export default useGeometryStore;
