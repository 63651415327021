import { getJobManagementSearchTypeSchema } from '@drainify/utils';
import { Icons, Input, Motion } from 'preshape';
import React from 'react';
import { useJobSearchContext } from '../../pages/desktop/Pages/AdminPage';
import { useLanguageContext } from '../Usage/LanguageProvider';

const JobAdminSearchOptionsSearch = () => {
  const { language } = useLanguageContext();
  const { searchString, setSearchString, searchType } = useJobSearchContext();
  const [hasFocus, setHasFocus] = React.useState<boolean>();

  const handleStartSearch = () => {
    setHasFocus(true);
  };

  const handleEndSearch = () => {
    setHasFocus(false);
  };

  return (
    <Motion
      animate={{
        width: hasFocus ? 'min(100%, 600px)' : 'min(100%, 300px)',
      }}
      initial={{ width: 'min(100%, 200px)' }}
      style={{ maxWidth: '100%' }}
      transition={{ bounceStiffness: 100, bounceDamping: 8 }}
    >
      <Input
        addonStart={<Icons.Search />}
        onBlur={handleEndSearch}
        backgroundColor="background-shade-2"
        borderSize="x0"
        placeholder={`Search ${
          getJobManagementSearchTypeSchema(searchType).name[language]
        }`}
        onChange={(event: any) => setSearchString(event.target.value)}
        value={searchString || ''}
        onFocus={handleStartSearch}
      />
    </Motion>
  );
};

export default JobAdminSearchOptionsSearch;
