import {
  NodeCode,
  ObservationCode,
  WincanXMLImport,
  WincanXMLSectionObservation,
  Node,
  TranscodedVideo,
  WincanXMLSection,
  PipeType,
  SectionFlowCode,
  PENDING_IMAGE_UPLOAD,
  isCircular,
  Ownership,
} from '@drainify/types';
import {
  ObservationSchemaEntry,
  ReportEditor,
  getObservationSchema,
} from '@drainify/utils';
import { DateTime } from 'luxon';
import { generatePath } from 'react-router-dom';
import resizeImage from '../../components/Image/ImageCompressor';

export const refToNodeCode = (ref: string): NodeCode => {
  const normalisedRef = ref.toUpperCase();
  if (normalisedRef.startsWith('MH') || normalisedRef.startsWith('SWMH'))
    return NodeCode.MH;
  if (normalisedRef.startsWith('IC')) return NodeCode.IC;
  if (normalisedRef.startsWith('BN')) return NodeCode.BN;
  if (normalisedRef.startsWith('BR') || normalisedRef.startsWith('SEWER'))
    return NodeCode.BR;
  if (normalisedRef.startsWith('CP')) return NodeCode.CP;
  if (normalisedRef.startsWith('GY') || normalisedRef.startsWith('GULLY'))
    return NodeCode.GY;
  if (normalisedRef.startsWith('LH')) return NodeCode.LH;
  if (normalisedRef.startsWith('OC')) return NodeCode.OC;
  if (normalisedRef.startsWith('OFF')) return NodeCode.UNP;
  if (normalisedRef.startsWith('OF')) return NodeCode.OF;
  if (normalisedRef.startsWith('OS')) return NodeCode.OS;
  if (normalisedRef.startsWith('RE')) return NodeCode.RE;
  if (normalisedRef.startsWith('SK')) return NodeCode.SK;
  if (normalisedRef.startsWith('RG')) return NodeCode.RG;
  if (normalisedRef.startsWith('WR')) return NodeCode.WR;
  if (normalisedRef.startsWith('WC')) return NodeCode.WC;
  if (normalisedRef.startsWith('RWG')) return NodeCode.RWG;
  if (normalisedRef.startsWith('FWG')) return NodeCode.FWG;
  if (normalisedRef.startsWith('SVP')) return NodeCode.SVP;
  if (normalisedRef.startsWith('CWG')) return NodeCode.CWG;
  if (normalisedRef.startsWith('TNK') || normalisedRef.startsWith('TANK'))
    return NodeCode.TNK;
  if (normalisedRef.startsWith('PIT')) return NodeCode.PIT;
  else return NodeCode.MH;
};

export const getDomesticCodeMSCC5 = (code: ObservationCode) => {
  if (code === ObservationCode.C) {
    return ObservationCode.C_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.CC) {
    return ObservationCode.CC_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.CCJ) {
    return ObservationCode.CCJ_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.CL) {
    return ObservationCode.CL_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.CLJ) {
    return ObservationCode.CLJ_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.CM) {
    return ObservationCode.CM_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.CMJ) {
    return ObservationCode.CMJ_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.CN) {
    return ObservationCode.CN_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.CR) {
    return ObservationCode.CR_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.CRJ) {
    return ObservationCode.CRJ_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.CS) {
    return ObservationCode.CS_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.CSJ) {
    return ObservationCode.CSJ_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.CU) {
    return ObservationCode.CU_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.CUW) {
    return ObservationCode.CUW_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.D) {
    return ObservationCode.D_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.F) {
    return ObservationCode.F_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.FC) {
    return ObservationCode.FC_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.FCJ) {
    return ObservationCode.FCJ_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.FL) {
    return ObservationCode.FL_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.FLJ) {
    return ObservationCode.FLJ_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.FM) {
    return ObservationCode.FM_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.FMJ) {
    return ObservationCode.FMJ_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.FR) {
    return ObservationCode.FR_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.FRJ) {
    return ObservationCode.FRJ_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.FS) {
    return ObservationCode.FS_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.FSJ) {
    return ObservationCode.FSJ_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.I) {
    return ObservationCode.I_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.JD) {
    return ObservationCode.JD_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.JDL) {
    return ObservationCode.JDL_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.JDM) {
    return ObservationCode.JDM_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.LC) {
    return ObservationCode.LC_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.LX) {
    return ObservationCode.LX_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.JN) {
    return ObservationCode.JN_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.JX) {
    return ObservationCode.JX_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.MC) {
    return ObservationCode.MC_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.OB) {
    return ObservationCode.OB_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.OJL) {
    return ObservationCode.OJL_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.OJM) {
    return ObservationCode.OJM_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.S) {
    return ObservationCode.S_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.SC) {
    return ObservationCode.SC_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.V) {
    return ObservationCode.V_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.WL) {
    return ObservationCode.WL_MSCC5_DOMESTIC;
  } else if (code === ObservationCode.HJ) {
    return ObservationCode.H;
  } else return code;
};

export const buildWincanAttributes = (
  observation: WincanXMLSectionObservation,
  schema?: ObservationSchemaEntry,
  uploadedVideoName?: string
) => {
  const attributes: Record<string, any> = {};

  const expectedAttributes = schema?.options?.map((e) => e.type) || [];
  if (schema?.path && schema.path.length > 1) {
    for (const parent of schema.path.filter((e) => e !== schema.code)) {
      const schema = getObservationSchema(parent);
      for (const newAttr of schema.options || []) {
        expectedAttributes?.push(newAttr.type);
      }
    }
  }

  if (observation.podcontinuousdefect) {
    attributes['continuous'] = true;
  }

  // Remarks
  if (observation.poddistance) {
    attributes['poddistance'] = observation.poddistance;
  }

  if (expectedAttributes?.includes('percentage')) {
    attributes['percentage'] = observation.podquantification1;
  }

  if (observation.podfromclock && expectedAttributes?.includes('angle')) {
    attributes['angleAt'] = observation.podfromclock;
  } else if (observation.podtoclock) {
    attributes['angle'] = [observation.podfromclock, observation.podtoclock];
  }

  if (observation.podquantification1) {
    attributes['number'] = observation.podquantification1 / 1000;
    attributes['diameter'] = observation.podquantification1 / 1000;
  }

  let remarks = undefined;
  if (observation.podremarks) {
    remarks = attributes['remarks'] = observation.podremarks;
  }

  let timeStamp = undefined;
  let imageUrl = undefined;
  if (
    observation.podmpegposition &&
    observation.podmovie === uploadedVideoName
  ) {
    const split = observation.podmpegposition
      .split(':')
      .map((e: string) => Number(e));
    timeStamp = split[0] * 60 * 60 + split[1] * 60 + split[2];
    imageUrl = PENDING_IMAGE_UPLOAD;
  }
  return { remarks, timeStamp, attributes, imageUrl };
};

export const extractNodeInformation = (
  reportEditor: ReportEditor,
  result: WincanXMLImport
) => {
  const nodes: Record<string, Node> = {};
  for (const inspection of [result.exportdata.section].flat()) {
    const startNodeRef = inspection.pcdstartnode;
    const finishNodeRef = inspection.pcdendnode || 'Unknown point';

    if (!nodes[startNodeRef]) {
      nodes[startNodeRef] = reportEditor.addNodeNoUpdate({
        code: refToNodeCode(startNodeRef),
        name: startNodeRef.substring(0, 10),
        attributes: {},
        depth: inspection.pcdstartdepth,
        createdAt: DateTime.now().toISO(),
      });
    } else if (inspection.pcdstartdepth) {
      reportEditor.updateNodeNoUpdate(nodes[startNodeRef].uid, {
        depth: inspection.pcdstartdepth,
      });
    }

    if (!nodes[finishNodeRef]) {
      let finishDepth = undefined;
      if (inspection.pcdenddepth) {
        finishDepth = inspection.pcdenddepth;
      }
      nodes[finishNodeRef] = reportEditor.addNodeNoUpdate({
        code: refToNodeCode(finishNodeRef),
        name: finishNodeRef.substring(0, 10),
        depth: finishDepth,
        attributes: {},
        createdAt: DateTime.now().toISO(),
      });
    }
  }
  return nodes;
};

export const extractSectionInspectionInformation = (
  reportEditor: ReportEditor,
  nodes: Record<string, Node>,
  inspection: WincanXMLSection,
  activeBookingId?: string
) => {
  const direction = inspection.sectioninspection.piddirection || 'U';

  const startNodeUid = nodes[inspection.pcdstartnode].uid;
  const endNodeUid = nodes[inspection.pcdendnode || 'Unknown point'].uid;
  const { uid: sectionUid } = reportEditor.addSectionNoUpdate({
    nodeStartUid: startNodeUid,
    nodeEndUid: endNodeUid,
    attributes: {
      sectionOwnership: Ownership.Z,
      material: inspection.pcdpipematerial,
      use: inspection.pcdusage,
      flow: direction === 'U' ? SectionFlowCode.STE : SectionFlowCode.ETS,
      diameter: isCircular(inspection.pcdpipeshape)
        ? inspection.pcdpipedimension1 / 1000
        : undefined,
      height: isCircular(inspection.pcdpipeshape)
        ? undefined
        : inspection.pcdpipedimension1 / 1000,
      width: !isCircular(inspection.pcdpipeshape)
        ? undefined
        : inspection.pcdpipedimension2
        ? inspection.pcdpipedimension2 / 1000
        : undefined,
      shape: inspection.pcdpipeshape,
      pipeType: PipeType.SECTION,
      length: Math.max(
        ...(inspection.sectioninspection.sectionobservation || []).map(
          (e) => e.poddistance
        )
      ),
      yearConstructed: inspection.pcdconstructionyear || undefined,
      drainageArea: inspection.pcddrainarea || undefined,
      jointLength: inspection.pcdpipejointlength || undefined,
      drainSewerType: inspection.pcdtype || undefined,
      nodeOneRef: inspection.pcdstartnode || undefined,
      nodeTwoRef: inspection.pcdendnode || undefined,
      expectedLength: inspection.pidlengthestimated || undefined,

      // landOwnership?: Ownership;
      // legalStatus?: LegalStatus;
      // liningType?: LiningType;
      // surfaceType?: SurfaceType;

      // nodeOneCoordinate?: string;
      // nodeTwoCoordinate?: string;
      // nodeThreeCoordinate?: string;
      // nodeThreeRef?: string;
      // pipeLengthRef?: PipeLengthRef;
      // locationTypeCode?: LocationTypeCode;
      // pipeUnitLength?: string;
    },
    createdAt: DateTime.now().toISO(),
  });

  return reportEditor.addInspectionNoAsync({
    sectionUid: sectionUid,
    jobId: activeBookingId!,
    notes: inspection.sectioninspection.pidremarks,
    attributes: {
      weather: inspection.sectioninspection.pidweather,
      date: inspection.sectioninspection.piddate,
      time: inspection.sectioninspection.pidtime,
      preCleaned: inspection.sectioninspection.pidcleaning,
      flowControlMeasures: inspection.pcdflowcontrol,
      purposeOfInspection: inspection.sectioninspection.pidpurpose,
      methodOfInspection: inspection.sectioninspection.pidmethod,
      temperature: inspection.sectioninspection.pidtemperature,

      // videoImageStorageMedia?: VideoImageStorageMedia;
      // videoImageLocationSystem?: string;
      // videoImageFormat?: VideoImageFormat;
      // videoImageFileName?: string;
      // videoVolumeRef?: string;

      // photographImageStorageFormat?: PhotographImageStorageFormat;
      // photographVolumeReference?: string;

      // critcatalityGrade?: CritcatalityGrade;
      // longitudinalLocationOfStartPoint?: string;
      // circumferentialLocationOfStartPoint?: string;
    },
    createdAt: DateTime.now().toISO(),
  });
};

export const extractObservationInformation = (
  observation: WincanXMLSectionObservation,
  reportEditor: ReportEditor,
  inspectionUid: string,
  continuous: {
    observationId: string;
    continuousRef: string;
  }[],
  videoName?: string
) => {
  const schema = getObservationSchema(
    getDomesticCodeMSCC5(observation.podcode)
  );

  const {
    remarks,
    timeStamp,
    attributes,
    imageUrl,
  }: {
    remarks: any;
    timeStamp: any;
    imageUrl?: string;
    attributes: Record<string, any>;
  } = buildWincanAttributes(observation, schema, videoName);

  const savedObservation = reportEditor.addObservationNoUpdate({
    code: schema ? schema.path || [schema.code] : [ObservationCode.GP],
    inspectionUid: inspectionUid,
    remarks: remarks,
    inspectionMarker:
      schema === undefined
        ? observation.podcode.endsWith('F')
          ? 'finish'
          : 'start'
        : undefined,
    distance: observation.poddistance,
    timeStamp: timeStamp,
    attributes: attributes,
    imageUrl: imageUrl,
  });
  if (observation.podcontinuousdefect) {
    continuous.push({
      observationId: savedObservation.uid,
      continuousRef: observation.podcontinuousdefect,
    });
  }
  return savedObservation;
};

export const addImageUploadJob = async (
  observation: WincanXMLSectionObservation,
  observationId: string,
  reportEditor: ReportEditor,
  imageUploads: (() => Promise<void>)[],
  observationFileUpload: any,
  projectId: string,
  acceptedFiles: any
): Promise<number> => {
  let size = 0;

  if (observation.podpicture1 || observation.podphoto1) {
    const realLabel = observation.podpicture1
      ? observation.podpicture1
      : observation.podphoto1?.replace('./', '');
    let file: File | undefined = undefined;

    const directoriesDeep = realLabel!.split('\\').length;
    if (directoriesDeep === 1) {
      // @ts-ignore
      file = acceptedFiles[realLabel];
    } else if (directoriesDeep === 2) {
      try {
        file =
          // @ts-ignore
          acceptedFiles[realLabel.split('\\')[0]][realLabel.split('\\')[1]];
      } catch (e) {
        return Promise.resolve(0);
      }
    }
    if (!file) {
      const directoriesDeep = realLabel!.split('/').length;
      if (directoriesDeep === 1) {
        // @ts-ignore
        file = acceptedFiles[realLabel];
      } else if (directoriesDeep === 2) {
        file =
          // @ts-ignore
          acceptedFiles[realLabel.split('/')[0]][realLabel.split('/')[1]];
      }
    }

    try {
      if (file && file.size > 0) {
        const compressedBlob = (await resizeImage({
          file: file!,
          maxSize: 500,
        })) as Blob;
        imageUploads.push(async () => {
          reportEditor.updateObservationNoAsync(observationId, {
            imageUrl: PENDING_IMAGE_UPLOAD,
          });
          const compressedImage = new File([compressedBlob], file!.name, {
            type: compressedBlob.type,
          });
          await observationFileUpload.upload(
            generatePath(
              '/projects/:projectId/report/observations/:observationId/image',
              {
                projectId,
                observationId,
              }
            ),
            compressedImage
          );
        });
        size = compressedBlob.size;
      }
    } catch (e) {
      console.error('observation photo doesnt exist');
    }
  }
  return Promise.resolve(size);
};

export const addVideoUploadJob = (
  inspection: WincanXMLSection,
  inspectionId: string,
  videoUploads: (() => Promise<void>)[],
  fetch: any,
  reportEditor: ReportEditor,
  nodeFileUpload: any,
  projectId: string,
  acceptedFiles: any
): number => {
  try {
    if (inspection.sectioninspection.sectionobservation) {
      const withVid = inspection.sectioninspection.sectionobservation.filter(
        (e) => !!e.podmovie
      );
      const video =
        withVid.length > 0 &&
        withVid[withVid.length - 1].podmovie?.replace('./', '');
      if (video) {
        const directoriesDeep = video.split('\\').length;

        let file: File | undefined = undefined;

        if (directoriesDeep === 1) {
          // @ts-ignore
          file = acceptedFiles[video];
        } else if (directoriesDeep === 2) {
          file =
            // @ts-ignore
            acceptedFiles[video.split('\\')[0]][video.split('\\')[1]];
        }

        if (!file) {
          const directoriesDeep = video!.split('/').length;
          if (directoriesDeep === 1) {
            // @ts-ignore
            file = acceptedFiles[video];
          } else if (directoriesDeep === 2) {
            file =
              // @ts-ignore
              acceptedFiles[video.split('/')[0]][video.split('/')[1]];
          }
        }

        if (file && file.size > 0) {
          videoUploads.push(async () => {
            const signedUrl = await fetch(
              '/video/:projectId/:inspectionId/preupload',
              {
                method: 'POST',
                body: {
                  fileSize: file!.size,
                },
                params: {
                  projectId,
                  inspectionId,
                },
              }
            );
            await nodeFileUpload.uploadSignedUrl(signedUrl, file);

            const video = (await fetch(
              '/video/:projectId/:inspectionId/postupload',
              {
                method: 'POST',
                body: {
                  frames:
                    reportEditor
                      .getInspectionObservations(inspectionId)
                      .filter(
                        (e) =>
                          e.imageUrl === PENDING_IMAGE_UPLOAD &&
                          e.timeStamp !== undefined
                      )
                      .flatMap((e) => ({
                        observationId: e.uid,
                        timeStamp: e.timeStamp,
                      })) || [],
                },
                params: {
                  projectId,
                  inspectionId,
                },
              }
            )) as unknown as TranscodedVideo;
            reportEditor.updateInspectionNoAsync(inspectionId, {
              video,
            });
          });
          return file.size;
        } else return 0;
      } else return 0;
    } else return 0;
  } catch (e) {
    if (process.env.NODE_ENV === 'development') {
      console.error('Failed uploading video');
      console.error(e);
    }
    return 0;
  }
};
