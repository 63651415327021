import { Section } from '@drainify/types';
import { getMidPointBetweenPoints, ReportElementType } from '@drainify/utils';
import round from 'lodash.round';
import { Label, Text, sizeX2Px, Box } from 'preshape';
import React, { useMemo } from 'react';
import { isMobile } from '../../../../utils/client';
import Distance from '../../../DistanceInput/Distance';
import InspectionVideo from '../../../Inspection/InspectionVideo';
import { useMapLayersContext } from '../../../Map/MapLayers/MapLayers';
import MapLabel from '../../../Map/MapMarker/MapLabel';
import MapMarkerToolbar from '../../../Map/MapMarker/MapMarkerToolbar';
import useGeometryStoreRegister from '../../../Map/useGeometryStore/useGeometryStoreRegister';
import { useProjectContext } from '../../../Project/ProjectProvider';
import SectionView from '../../../Section/SectionView';
import { useReportEditorContext } from '../../ReportEditorProvider';
import { useReportMapContext } from '../ReportMapProvider';
import ReportMapObservation from './ReportMapObservation';
import { reportMapBorderColorMap } from './ReportMapSection';
import useDrawReportMapPipe, { PIPE_WIDTH } from './useDrawReportMapPipe';

type Props = {
  section: Section;
  shouldRenderObservations: boolean;
  showLabels: boolean;
};

const ReportMapSectionCustomer = ({
  section,
  shouldRenderObservations,
  showLabels,
}: Props) => {
  const { isLayerVisible } = useMapLayersContext();
  const { reportEditor } = useReportEditorContext();
  const { focusSection, focusedInspectionUid, focusedElementUid } =
    useReportMapContext();
  const { activeBookingId, customerReportView } = useProjectContext();

  const visible = isLayerVisible(ReportElementType.SECTION, section.uid);
  const [nodeStart, nodeEnd] = reportEditor.getSectionNodes(section.uid);
  const renderObservations =
    shouldRenderObservations || (visible && focusedElementUid !== undefined);

  const lineString = useMemo<GeoJSON.LineString | undefined>(() => {
    if (nodeStart?.point && nodeEnd?.point) {
      return {
        type: 'LineString',
        coordinates: [
          nodeStart.point.coordinates,
          ...(section.additionalPoints || [])?.map((e) => e.point.coordinates),
          nodeEnd.point.coordinates,
        ],
      };
    }
  }, [nodeStart, nodeEnd]);

  const midPoint = useMemo(() => {
    if (
      nodeEnd?.point &&
      nodeStart?.point &&
      (!section.additionalPoints || section.additionalPoints.length === 0)
    ) {
      return getMidPointBetweenPoints(nodeStart?.point, nodeEnd?.point);
    } else if (
      nodeStart?.point &&
      section.additionalPoints &&
      section.additionalPoints.length > 1
    ) {
      return getMidPointBetweenPoints(
        nodeStart?.point,
        section.additionalPoints[0].point
      );
    }
  }, [nodeStart, nodeEnd]);

  const length = reportEditor.getSectionLength(section.uid);

  const observations =
    focusedInspectionUid &&
    reportEditor.getInspectionSection(focusedInspectionUid)?.uid === section.uid
      ? reportEditor.getInspectionObservations(focusedInspectionUid)
      : reportEditor.getSectionInspectionLatestObservations(
          section.uid,
          activeBookingId
        );

  const hasInspections =
    reportEditor.getSectionInspections(section.uid, activeBookingId).length > 0;

  useDrawReportMapPipe({
    pointA: nodeStart?.point,
    pointB: nodeEnd?.point,
    additionalPoints: section.additionalPoints,
    flow: section.attributes.flow,
    stroke:
      (section.attributes.use &&
        reportMapBorderColorMap[section.attributes.use]) ||
      undefined,
    visible,
    opacity: hasInspections ? 1 : 0.3,
  });

  useGeometryStoreRegister({
    geometry: lineString,
    opts: {
      id: `ReportMapSection.${section.uid}`,
      keepInFocus: true,
      padding: PIPE_WIDTH + sizeX2Px,
    },
  });

  if (!nodeStart?.point || !nodeEnd?.point) {
    return null;
  }

  return (
    <>
      <MapLabel
        id={section.uid}
        onClick={() => focusSection(section.uid)}
        point={midPoint}
        visible={(visible && showLabels)}
      >
        <MapMarkerToolbar
          information={
            <Box flex="vertical" gap="x3">
              <SectionView section={section} validate={false} />
              {reportEditor
                .getSectionInspections(section.uid)
                .filter((e) => e.jobId === customerReportView?.job.uid)
                .map((e) => (
                  <Box key={e.uid}>
                    <Text strong>Video</Text>
                    <InspectionVideo inspection={e} key={e.uid} />
                  </Box>
                ))}
            </Box>
          }
          enabled={visible}
          showOnHover
        >
          <Label alignChildren="middle" flex="vertical" size="x1">
            <Text strong>{reportEditor.getSectionName(section)}</Text>

            {length && (
              <Text weak>
                (
                <Distance inline type="long" value={round(length, 2)} />)
              </Text>
            )}
          </Label>
        </MapMarkerToolbar>
      </MapLabel>

      {!isMobile() &&
        renderObservations &&
        observations.map((observation) => (
          <ReportMapObservation
            key={observation.uid}
            observation={observation}
            showOnHover={true}
          />
        ))}
    </>
  );
};

export default ReportMapSectionCustomer;
