import { Member } from '@drainify/types';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Text,
  ModalProps,
  FormProvider,
  InputLabel,
  Input,
  Icons,
} from 'preshape';
import React, { ChangeEvent, PropsWithChildren } from 'react';
import { generatePath } from 'react-router-dom';
import useMemberForm from '../../hooks/forms/useMemberForm';
import useFileUpload from '../../hooks/useFileUpload';
import useOrgMember from '../../hooks/useOrgMember';
import useOrgMembers from '../../hooks/useOrgMembers';
import { isDesktop } from '../../utils/client';
import { FULL_SCREEN_MODAL_WIDTH } from '../App/App';
import FileUpload from '../FileUpload/FileUpload';
import OrgRoleList from '../Org/OrgRoleList';
import OrgRolesInformationButton from '../Org/OrgRolesInformationButton';
import { useUserContext } from '../User/UserProvider';
import Wizard from '../Wizard/Wizard';
import WizardControls from '../Wizard/WizardControls/WizardControls';
import WizardReviewStep from '../Wizard/WizardReviewStep';
import WizardStep from '../Wizard/WizardStep';
import WizardStepError from '../Wizard/WizardStepError';
import MemberSummary from './MemberSummary';

type Props = ModalProps & {
  member: Member;
  onClose: () => void;
  visible: boolean;
};

const MemberModal = ({
  member,
  onClose,
  visible,
  ...rest
}: PropsWithChildren<Props>) => {
  const { user } = useUserContext();
  const { update, remove } = useOrgMember(
    { orgId: member.org.uid, memberId: member.uid },
    { noQuery: true }
  );

  const form = useMemberForm(member);
  const [fileToUpload, setFileToUpload] = React.useState<File>();
  const certificateUpload = useFileUpload();
  const { query: orgRefresh } = useOrgMembers(member.org.uid);

  const handleSave = async () => {
    const { uid } = await update.mutateAsync(form.state);
    if (fileToUpload) {
      await certificateUpload.upload(
        generatePath('/orgs/:orgId/members/:memberId/certificate', {
          orgId: member.org.uid,
          memberId: uid,
        }),
        fileToUpload
      );
    }
    form.reset();
    orgRefresh.refetch();
    setFileToUpload(undefined);
    onClose();
  };

  const handleRemove = async () => {
    await remove.mutateAsync();
    form.reset();
    onClose();
  };

  return (
    <FormProvider form={form}>
      <Wizard
        flow="update"
        isError={update.isError}
        isLoading={update.isLoading}
        isSuccess={update.isSuccess}
        onSave={handleSave}
        onRemove={user?.uid === member.user.uid ? undefined : handleRemove}
        onCancel={onClose}
        reset={visible}
      >
        <Modal
          {...rest}
          animation="FadeSlideUp"
          margin="x4"
          maxWidth={FULL_SCREEN_MODAL_WIDTH}
          onClose={onClose}
          overlayBackgroundCloseOnClick={false}
          visible={visible}
        >
          <ModalHeader>
            <Text size="x5" strong>
              Update member
            </Text>
          </ModalHeader>

          <ModalBody>
            <WizardStep
              action={<OrgRolesInformationButton />}
              id="role"
              title="Role"
            >
              <OrgRoleList
                onSelect={(role) => form.setState((s) => ({ ...s, role }))}
                value={form.state.role}
              />
            </WizardStep>

            <WizardStep id="certificate" title="Certificate">
              <FileUpload
                maxWidth="300px"
                onChange={setFileToUpload}
                value={fileToUpload}
              >
                <Icons.File />
                <Text
                  style={{
                    WebkitUserSelect: 'none',
                    msUserSelect: 'none',
                    userSelect: 'none',
                    WebkitTouchCallout: 'none',
                    KhtmlUserSelect: 'none',
                    MozUserSelect: 'none',
                  }}
                >
                  Select Certification
                </Text>
              </FileUpload>
            </WizardStep>

            <WizardStep id="certificateNumber" title="Certificate Number">
              <InputLabel>
                <Input
                  size={isDesktop() ? 'x3' : 'x8'}
                  name="Certificate Number"
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    form.setState((s) => ({
                      ...s,
                      certificateNumber: event.target.value,
                    }))
                  }
                  value={form.state.certificateNumber || ''}
                />
              </InputLabel>
            </WizardStep>

            <WizardReviewStep>
              <MemberSummary member={form.state} certificate={fileToUpload} />
            </WizardReviewStep>
          </ModalBody>

          <ModalFooter>
            <WizardStepError />
            <WizardControls />
          </ModalFooter>
        </Modal>
      </Wizard>
    </FormProvider>
  );
};

export default MemberModal;
