import React from 'react';
import { Outlet } from 'react-router-dom';
import PageContext from '../../../components/Page/PageContext';
import { useProjectContext } from '../../../components/Project/ProjectProvider';
import useProjectBookings from '../../../hooks/useProjectBookings';
import useProjectReport from '../../../hooks/useProjectReport';

const ProjectReturnPageContext = () => {
  const { project, query: queryProject, activeBookingId } = useProjectContext();
  const { query: queryReport } = useProjectReport({ projectId: project?.uid });
  const { query: bookingQuery } = useProjectBookings(project?.uid);

  // todo - what going on here
  return (
    <PageContext
      backToLink={
        project
          ? `/projects/${project.uid}?activeBookingId=${activeBookingId}`
          : '/projects'
      }
      backToText={
        project
          ? ` ${
              bookingQuery.data?.data.find((e) => e.uid === activeBookingId)
                ?.jobId
            }`
          : 'Jobs'
      }
      query={[queryProject, queryReport]}
    >
      <Outlet />
    </PageContext>
  );
};

export default ProjectReturnPageContext;
