import { ObservationOptionPercentage } from '@drainify/utils';
import { InputLabel, InputLabelProps } from 'preshape';
import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import PercentageInput from '../../PercentageInput/PercentageInput';
import PercentageSymbol from '../../PercentageInput/PercentageSymbol';

type Props = InputLabelProps & {
  asSummary?: boolean;
  asIcon?: boolean;
  name?: string;
  option: ObservationOptionPercentage;
  onSelect: (value: number) => void;
  value?: number;
};

const ObservationOptionPercentageInput: ForwardRefRenderFunction<
  HTMLLabelElement,
  Props
> = ({ asSummary, name, onSelect, value, asIcon, ...rest }, ref) => {
  // Default set for percentage codes
  React.useEffect(() => {
    if (value === undefined) {
      onSelect(0);
    }
  }, []);

  if (asSummary) {
    return <PercentageSymbol value={value} asIcon={asIcon}/>;
  }

  return (
    <InputLabel {...rest} flex="vertical" grow ref={ref}>
      <PercentageInput name={name} onChange={onSelect} value={value} />
    </InputLabel>
  );
};

export default forwardRef(ObservationOptionPercentageInput);
