import { ObservationCode } from '@drainify/types';
import { getObservationSchema } from '@drainify/utils';
import { Text, TextProps } from 'preshape';
import React, { Fragment } from 'react';
import PlaceholderBlock from '../Placeholders/PlaceholderBlock';
import { useLanguageContext } from '../Usage/LanguageProvider';

type Props = TextProps & {
  code?: ObservationCode[];
};

const ObservationCodeText = ({ code, ...props }: Props) => {
  const { language } = useLanguageContext();
  if (!code) {
    return <PlaceholderBlock />;
  }

  return (
    <Text {...props}>
      {code
        .map((code) => getObservationSchema(code).name[language])
        .map((schema, index) => (
          <Fragment key={schema}>
            <Text inline>{schema}</Text>

            {index < code.length - 1 && <Text inline> → </Text>}
          </Fragment>
        ))}
    </Text>
  );
};

export default ObservationCodeText;
