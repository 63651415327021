import React from 'react';
import OrgSummary from '../../../components/Org/OrgSummary';
import PageContext from '../../../components/Page/PageContext';
import useMeOrg from '../../../hooks/useMeOrg';
import Page from '../Layout/Page';
import PageBody from '../Layout/PageBody';
import PageHeader from '../Layout/PageHeader';

const OrgProfilePage = () => {
  const { query } = useMeOrg();

  return (
    <PageContext backToLink="/settings/user" backToText="Back">
      <Page>
        <PageHeader title="Profile" />

        <PageBody>
          <OrgSummary org={query.data} />
        </PageBody>
      </Page>
    </PageContext>
  );
};

export default OrgProfilePage;
