import { Inspection } from '@drainify/types';
import { useParams } from 'react-router';
import useVideoStatus from '../../hooks/useVideoStatus';

type Props = {
  inspection: Inspection;
};

const BookingPendingVideos = ({ inspection }: Props) => {
  const { projectId } = useParams();
  useVideoStatus(projectId, inspection.uid);

  return null;
};

export default BookingPendingVideos;
