import { Customer, Project, ProjectPostBody, Location } from '@drainify/types';
import { getProjectLocation } from '@drainify/utils';
import {
  FormProvider,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalProps,
  ModalTitle,
} from 'preshape';
import React from 'react';
import useProjectForm from '../../hooks/forms/useProjectForm';
import { FULL_SCREEN_MODAL_WIDTH } from '../App/App';
import Card from '../Card/Card';
import CustomerCreateButton from '../Customer/CustomerCreateButton';
import CustomerList from '../Customer/CustomerList';
import Address from '../Location/Address';
import LocationInput from '../Location/LocationInput';
import Wizard from '../Wizard/Wizard';
import WizardControls from '../Wizard/WizardControls/WizardControls';
import WizardReviewStep from '../Wizard/WizardReviewStep';
import WizardStep from '../Wizard/WizardStep';
import WizardStepError from '../Wizard/WizardStepError';
import WizardStepOption from '../Wizard/WizardStepOption';
import WizardStepOptions from '../Wizard/WizardStepOptions';
import { useProjectContext } from './ProjectProvider';
import ProjectSummary from './ProjectSummary';

type Props = ModalProps & {
  initialActiveStepId?: string;
  onClose: () => void;
  project?: Project;
  onSave: (project: ProjectPostBody) => void;
  isCreateError?: boolean;
  isCreateLoading?: boolean;
  isCreateSuccess?: boolean;
};
const ProjectModal = ({
  initialActiveStepId,
  onClose,
  onSave,
  isCreateError,
  isCreateLoading,
  isCreateSuccess,
  project,
  visible,
  ...rest
}: Props) => {
  const { isUpdating, isUpdatingFailed, isUpdatingSuccess, remove } =
    useProjectContext();
  const form = useProjectForm(project);

  React.useEffect(() => form.reset(), [visible]);

  const setCustomer = (customer: Customer) => {
    form.setState((s) => ({
      ...s,
      customer,
    }));
  };

  const setLocationToCustomer = () => {
    form.setState((s) => ({
      ...s,
      location: undefined,
      siteLinkedToCustomer: true,
    }));
  };

  const setLocationSeparateToCustomer = () => {
    form.setState((s) => ({
      ...s,
      siteLinkedToCustomer: false,
    }));
  };

  const setLocation = (location: Location) => {
    form.setState((s) => ({
      ...s,
      location,
      siteLinkedToCustomer: false,
    }));
  };

  const save = async () => {
    if (!form.hasError && form.state) {
      onSave(form.state);
    }
  };

  return (
    <FormProvider form={form}>
      <Wizard
        flow={project ? 'update' : 'create'}
        initialActiveStepId={initialActiveStepId}
        isError={isCreateError || isUpdatingFailed}
        isLoading={isCreateLoading || isUpdating}
        isSuccess={isCreateSuccess || isUpdatingSuccess}
        onSave={save}
        onRemove={remove}
        onCancel={onClose}
        reset={visible}
      >
        <Modal
          {...rest}
          animation="FadeSlideUp"
          margin="x4"
          maxWidth={FULL_SCREEN_MODAL_WIDTH}
          onClose={onClose}
          overlayBackgroundCloseOnClick={false}
          visible={visible}
        >
          <ModalHeader>
            <ModalTitle>
              {project ? `Update project` : 'Create Project'}
            </ModalTitle>
          </ModalHeader>

          <ModalBody>
            <WizardStep
              id="customer"
              title="Customer"
              action={
                <CustomerCreateButton variant="tertiary" onSave={setCustomer} />
              }
            >
              <CustomerList
                onSelect={setCustomer}
                selectedUids={
                  form.state.customer ? [form.state.customer?.uid] : []
                }
                withSearch
              />
            </WizardStep>

            <WizardStep id="location" title="Address">
              <WizardStepOptions>
                <WizardStepOption
                  contentActive={
                    <Card borderSize="x2">
                      <Address
                        location={getProjectLocation(form.state)}
                        textColor="text-shade-1"
                        weak
                      />
                    </Card>
                  }
                  onSelect={setLocationToCustomer}
                  selected={form.state.siteLinkedToCustomer}
                  titleActive="Customer address"
                  titleInactive="Same as customer"
                />

                <WizardStepOption
                  contentActive={
                    <LocationInput
                      onChange={setLocation}
                      name="location"
                      textColor="text-shade-1"
                      value={getProjectLocation(form.state)}
                      weak
                    />
                  }
                  onSelect={setLocationSeparateToCustomer}
                  selected={!form.state.siteLinkedToCustomer}
                  titleActive="Different to customer address"
                  titleInactive="Different to customer"
                ></WizardStepOption>
              </WizardStepOptions>
            </WizardStep>

            <WizardReviewStep>
              <ProjectSummary project={form.state} />
            </WizardReviewStep>
          </ModalBody>

          <ModalFooter>
            <WizardStepError />
            <WizardControls />
          </ModalFooter>
        </Modal>
      </Wizard>
    </FormProvider>
  );
};

export default ProjectModal;
