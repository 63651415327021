import {
  Box,
  Button,
  Buttons,
  Placement,
  PlacementArrow,
  PlacementContent,
  PlacementManager,
  PlacementProps,
  PlacementReference,
} from 'preshape';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useMapContext } from '../Map';

type Props = PlacementProps & {
  enabled?: boolean;
  information?: JSX.Element;
  tools?: { icon: JSX.Element; onClick?: () => void }[];
  showOnHover?: boolean;
};

const MapMarkerToolbar = ({
  children,
  enabled = true,
  information,
  tools,
  showOnHover = false,
}: PropsWithChildren<Props>) => {
  const { isInteractive } = useMapContext();
  const [visible, setVisible] = useState(false);
  const refTimeoutHide = useRef(0);

  const handleShowToolbar = () => {
    if (showOnHover) {
      setVisible(true);
    }
    if (isInteractive && enabled) {
      window.clearTimeout(refTimeoutHide.current);
    }
  };

  const handleHideToolbar = () => {
    refTimeoutHide.current = window.setTimeout(() => {
      setVisible(false);
    }, 250);
  };

  useEffect(() => {
    if (!isInteractive || !enabled) {
      setVisible(false);
    }
  }, [isInteractive, enabled]);

  return (
    <PlacementManager>
      <PlacementReference>
        {(props) => (
          <Box
            {...props}
            onPointerOver={enabled ? handleShowToolbar : undefined}
            onPointerLeave={enabled ? handleHideToolbar : undefined}
            onClick={() => enabled && setVisible(true)}
          >
            {children}
          </Box>
        )}
      </PlacementReference>

      <Placement
        onPointerEnter={enabled ? handleShowToolbar : undefined}
        onPointerLeave={enabled ? handleHideToolbar : undefined}
        placement="top"
        textColor="text-shade-1"
        theme="night"
        visible={visible}
      >
        <PlacementArrow backgroundColor="background-shade-1" />
        <PlacementContent
          backgroundColor="background-shade-1"
          borderRadius="x2"
        >
          {information && <Box padding="x3">{information}</Box>}

          {tools && (
            <Buttons
              alignChildrenHorizontal="middle"
              onClick={(e) => e.stopPropagation()}
              padding="x1"
            >
              {tools.map(({ icon, onClick }, index) => (
                <Button
                  disabled={!onClick}
                  key={index}
                  onClick={onClick}
                  paddingHorizontal="x2"
                  variant="tertiary"
                >
                  {icon}
                </Button>
              ))}
            </Buttons>
          )}
        </PlacementContent>
      </Placement>
    </PlacementManager>
  );
};

export default MapMarkerToolbar;
