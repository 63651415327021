import { Button, ButtonProps, Icons, Text } from 'preshape';
import React from 'react';
import ConfirmationDeleteModal from '../../utils/ConfirmationDeleteModal';
import { useHasPermission } from '../Permissions/Permissions';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';

type Props = ButtonProps & {
  sectionUid?: string;
  onDelete?: () => void;
};

const SectionDeleteButton = ({ sectionUid, onDelete, ...rest }: Props) => {
  const { removeSection } = useReportEditorContext();
  const hasUpdateProjectPermission = useHasPermission('projects:update');
  const [alertVisible, setAlertVisible] = React.useState(false);
  const { activeBooking } = useProjectContext();

  if (!hasUpdateProjectPermission || !sectionUid) {
    return null;
  }

  return (
    <>
      <Button
        variant="tertiary"
        {...rest}
        color="negative"
        gap="x2"
        disabled={!!activeBooking?.completedAt}
        onClick={() => setAlertVisible(true)}
        type="button"
      >
        <Text>Delete section</Text>
        <Icons.Trash2 size="1rem" />
      </Button>
      <ConfirmationDeleteModal
        visible={alertVisible}
        onClose={() => setAlertVisible(false)}
        action={() => {
          removeSection(sectionUid);
          onDelete?.();
        }}
        content="Are you sure you want to delete this section?"
        subheading="You will lose all previous inspections, videos, observations and images associated with this section."
      />
    </>
  );
};

export default SectionDeleteButton;
