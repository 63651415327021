import { Box } from 'preshape';
import React from 'react';
import { ReportMapNodeRendererType } from '.';
import './css/CP.css';

const ReportMapNodeCP: ReportMapNodeRendererType = ({ locked }) => {
  return (
    <Box
      className="Icon"
      tag="svg"
      viewBox="0 0 24 24"
      height="1.75rem"
      width="1.75rem"
      stroke="white"
      fill="white"
      textColor="white"
      backgroundColor={locked ? 'text-shade-1' : 'accent-shade-5'}
      borderRadius="full"
    >
      {
        <svg id="CP" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
          <g id="Layer_6">
            <path
              className="cp-cls-1"
              d="M326.92,318.49c-15,2.92-29.87,3.56-44.77-.73-20.89-6.02-40.17-18.48-60.11-27.7-20.94-9.67-42.51-13.41-64.82-9.17-4.21,.8-8.38,1.81-12.52,2.98v119.3h210.62v-96.93c-7.64,7.53-19.66,10.54-28.39,12.24Z"
            />
          </g>
          <g>
            <polygon
              className="cp-cls-2"
              points="144.69 97.02 144.69 96.82 355.31 96.82 355.31 97.02 385.31 97.02 385.31 66.82 114.69 66.82 114.69 97.02 144.69 97.02"
            />
            <polygon
              className="cp-cls-2"
              points="385.31 130.89 355.31 130.89 355.31 224.78 435.43 224.78 435.43 275.22 355.31 275.22 355.31 403.18 144.69 403.18 144.69 275.22 64.57 275.22 64.57 224.78 144.69 224.78 144.69 130.89 114.69 130.89 114.69 194.78 34.57 194.78 34.57 305.22 114.69 305.22 114.69 433.18 385.31 433.18 385.31 305.22 465.43 305.22 465.43 194.78 385.31 194.78 385.31 130.89"
            />
          </g>
        </svg>
      }
    </Box>
  );
};

export default ReportMapNodeCP;
