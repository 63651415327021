import { Inspection, OCRResultStatus } from "@drainify/types";
import { TableRow, TableCell, Text, Button, Box, Icons } from "preshape"
import React from "react"
import useAIOCRResult from "../../hooks/useAIOCRResult";
import OCRView from "../../pages/OCRView";
import InspectionAdditionalSummaryVideo from "../Inspection/InspectionAdditionalSummaryVideo";
import InspectionConditionBadges from "../Inspection/InspectionConditionBadges";
import PlaceholderGradientAnimation from "../Placeholders/PlaceholderGradientAnimation";
import PlaceholderText from "../Placeholders/PlaceholderText";
import { useReportEditorContext } from "../Report/ReportEditorProvider";
import VideoPlayerSidebarChapterContainer from "../VideoPlayer/Sidebar/VideoPlayerSidebarChapterContainer";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import VideoPlayerSectionEditActions from "../VideoPlayer/VideoPlayerSectionEditActions";
import VideoProvider from "../VideoPlayer/VideoProvider";
import BuddyQAReviewButton from "./BuddyQAReviewButton";
import BuddyScore from "./BuddyScore";
import BuddySpeed from "./BuddySpeed";

type Props = {
  inspection: Inspection;
  projectId?: string;
  showDev: boolean;
}

const BuddyReviewToolInspectionSummary = ({ inspection, projectId, showDev }: Props) => {
  const { reportEditor } = useReportEditorContext();
  const section = reportEditor.getSectionByUid(inspection.sectionUid)
  const { query, update, remove, create } = useAIOCRResult(projectId, inspection.uid)
  const [showVideo, setShowVideo] = React.useState(false)

  const secondsToHHMMSS = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  }


  if (reportEditor.getInspectionByUid(inspection.uid)?.video === undefined) {
    return (
      <>
        <TableRow>
          <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}>
            <Text strong textColor='text-shade-3'> {reportEditor.getSectionName(section)} </Text>
            <Text size='x1' textColor='negative-shade-5' strong>Missing video</Text>
          </TableCell>
          <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}> <Box flex='horizontal' alignChildrenHorizontal='middle'> <Icons.X /> </Box> </TableCell>
          <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}> <Box flex='horizontal' alignChildrenHorizontal='middle'> <Icons.X /> </Box> </TableCell>
          <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}> <Box flex='horizontal' alignChildrenHorizontal='middle'> <Icons.X /> </Box> </TableCell>
          <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}><Icons.X />
          </TableCell>
          <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}> <Box flex='horizontal' alignChildrenHorizontal='middle'> <Icons.X /> </Box> </TableCell>
          <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}> <Box flex='horizontal' alignChildrenHorizontal='middle'> <Icons.X /> </Box> </TableCell>
          <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}> <Box flex='horizontal' alignChildrenHorizontal='middle'> <Icons.X /> </Box> </TableCell>
          {
            showDev &&
            <>
              <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}> <Box flex='horizontal' alignChildrenHorizontal='middle'> <Icons.X /> </Box> </TableCell>
              <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}> <Box flex='horizontal' alignChildrenHorizontal='middle'> <Icons.X /> </Box> </TableCell>
            </>
          }
        </TableRow>
        {
          showVideo &&
          <TableRow>
            <TableCell colSpan={12}>
              <Box padding='x3' alignChildrenHorizontal='middle'>
                <VideoProvider>
                  <Box flex='horizontal' alignChildrenHorizontal='middle'>
                    <Box flex='vertical'>
                      <VideoPlayer inspectionId={inspection.uid} projectId={projectId!} />
                      <VideoPlayerSectionEditActions inspectionId={inspection.uid} projectId={projectId!} />
                      <InspectionAdditionalSummaryVideo
                        section={reportEditor.getSectionByUid(
                          reportEditor.getInspectionByUid(inspection.uid)?.sectionUid
                        )}
                      />

                    </Box>
                    <Box backgroundColor='light-shade-3'>
                      <VideoPlayerSidebarChapterContainer inspectionId={inspection.uid} projectId={projectId!} />
                    </Box>
                  </Box>
                </VideoProvider>
              </Box>
            </TableCell>
          </TableRow>
        }
      </>
    )
  }

  if (query.data === null || query.data === undefined || query.isLoading || query.data.status === OCRResultStatus.PENDING) {
    return (
      <>
        <TableRow>
          <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}> <Text strong textColor='text-shade-3'> {reportEditor.getSectionName(section)} </Text> </TableCell>
          <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}>
            <PlaceholderGradientAnimation animating={query.data?.status === OCRResultStatus.PENDING}>
              <PlaceholderText />
            </PlaceholderGradientAnimation>
          </TableCell>
          <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}>
            <PlaceholderGradientAnimation animating={query.data?.status === OCRResultStatus.PENDING}>
              <PlaceholderText />
            </PlaceholderGradientAnimation>

          </TableCell>

          <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}>
            <Box>
              <PlaceholderGradientAnimation animating={query.data?.status === OCRResultStatus.PENDING}>
                <PlaceholderText borderRadius='15px' />
                <PlaceholderText borderRadius='15px' />
              </PlaceholderGradientAnimation>
            </Box>
          </TableCell>
          <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}>
            <PlaceholderGradientAnimation animating={query.data?.status === OCRResultStatus.PENDING}>
              <PlaceholderText />
            </PlaceholderGradientAnimation>
          </TableCell>
          <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}>
            <PlaceholderGradientAnimation animating={query.data?.status === OCRResultStatus.PENDING}>
              <PlaceholderText />
            </PlaceholderGradientAnimation>
          </TableCell>
          {
            showDev &&
            <>
              <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}>
                <PlaceholderGradientAnimation animating={query.data?.status === OCRResultStatus.PENDING}>
                  <PlaceholderText />
                </PlaceholderGradientAnimation>
              </TableCell>
              <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}>
                <PlaceholderGradientAnimation animating={query.data?.status === OCRResultStatus.PENDING}>
                  <PlaceholderText />
                </PlaceholderGradientAnimation>
              </TableCell>
            </>
          }
          <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}>
            <Box>
              <PlaceholderGradientAnimation animating={query.data?.status === OCRResultStatus.PENDING}>
                <PlaceholderText borderRadius='15px' />
                <PlaceholderText borderRadius='15px' />
              </PlaceholderGradientAnimation>
            </Box>
          </TableCell>
          <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}>
            <PlaceholderGradientAnimation animating={query.data?.status === OCRResultStatus.PENDING}>
              <PlaceholderText />
            </PlaceholderGradientAnimation>
          </TableCell>
          {
            showDev &&
            <>
              <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}>
                <PlaceholderGradientAnimation animating={query.data?.status === OCRResultStatus.PENDING}>
                  <PlaceholderText />
                </PlaceholderGradientAnimation>
              </TableCell>
              <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}>
                <Button disabled={query.data?.status === OCRResultStatus.PENDING} onClick={() => create.mutate()}>create</Button>
              </TableCell>
            </>
          }
        </TableRow>
        {
          showVideo &&
          <TableRow>
            <TableCell colSpan={12}>
              <Box padding='x3' alignChildrenHorizontal='middle'>
                <VideoProvider>
                  <Box flex='horizontal' alignChildrenHorizontal='middle'>
                    <Box flex='vertical'>
                      <VideoPlayer inspectionId={inspection.uid} projectId={projectId!} />
                      <VideoPlayerSectionEditActions inspectionId={inspection.uid} projectId={projectId!} />
                      <InspectionAdditionalSummaryVideo
                        section={reportEditor.getSectionByUid(
                          reportEditor.getInspectionByUid(inspection.uid)?.sectionUid
                        )}
                      />

                    </Box>
                    <Box backgroundColor='light-shade-3'>
                      <VideoPlayerSidebarChapterContainer inspectionId={inspection.uid} projectId={projectId!} />
                    </Box>
                  </Box>
                </VideoProvider>
              </Box>
            </TableCell>
          </TableRow>
        }
      </>
    )
  }

  if ((query.data?.matches.filter(e => e.interpolated).length >= query.data.matches.length * 0.25)) {
    return (
      <>
        <TableRow>
          <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}> <Text strong textColor='text-shade-3'> {reportEditor.getSectionName(section)} </Text> </TableCell>
          <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}>
            <Box margin='x0'>
              <Button variant='tertiary' flex='horizontal' gap='x2'>
                <Box
                  borderRadius='full' width={8} height={8}
                  backgroundColor='negative-shade-3'

                />
              </Button>
            </Box>

          </TableCell>
          <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}> <Box flex='horizontal' alignChildrenHorizontal='middle'> <Icons.HelpCircle /> </Box>

          </TableCell>
          <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}><BuddyQAReviewButton ignoreTooFast inspection={inspection} ocrResult={query.data} update={(result) => update.mutateAsync(result)} /> </TableCell>
          <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}>
            <Text size='x2' strong align='middle'>
              {Math.max(0, ...reportEditor.getInspectionObservations(inspection.uid).map(e => e.distance || 0)).toFixed(2)}m
            </Text>
          </TableCell>
          <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}>
            <Text size='x2' strong align='middle'>
              {secondsToHHMMSS(query.data.matches.length)}
            </Text>
          </TableCell>
          {
            showDev &&
            <>
              <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}>
                <Box flex='horizontal' gap='x1' alignChildrenHorizontal='middle'>
                  <Text size='x1' strong> {query.data.binarizor} </Text>
                  {query.data.usedCachedBB ? <Icons.Check size='0.75rem' /> : <Icons.X size='0.75rem' />}
                </Box>
              </TableCell>
              <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}>{((query.data.matches.filter(e => e.interpolated).length / query.data.matches.length) * 100).toFixed(2)}%</TableCell>
            </>
          }
          <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}><InspectionConditionBadges inspectionUid={inspection.uid} /> </TableCell>
          <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}>
            <Box flex='horizontal' alignChildrenHorizontal='middle'>
              <Button size='x1' variant='tertiary' onClick={() => setShowVideo(!showVideo)}><Icons.Play /></Button>
            </Box>
          </TableCell>
          {
            showDev && <>
              <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}><OCRView og={query.data} projectId={projectId} inspectionId={inspection.uid} /></TableCell>
              <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}><Button onClick={() => remove.mutate()}>delete</Button></TableCell>
            </>
          }
        </TableRow>
        {
          showVideo &&
          <TableRow>
            <TableCell colSpan={12}>
              <Box padding='x3' alignChildrenHorizontal='middle'>
                <VideoProvider>
                  <Box flex='horizontal' alignChildrenHorizontal='middle'>
                    <Box flex='vertical'>
                      <VideoPlayer inspectionId={inspection.uid} projectId={projectId!} />
                      <VideoPlayerSectionEditActions inspectionId={inspection.uid} projectId={projectId!} />
                      <InspectionAdditionalSummaryVideo
                        section={reportEditor.getSectionByUid(
                          reportEditor.getInspectionByUid(inspection.uid)?.sectionUid
                        )}
                      />

                    </Box>
                    <Box backgroundColor='light-shade-3'>
                      <VideoPlayerSidebarChapterContainer inspectionId={inspection.uid} projectId={projectId!} />
                    </Box>
                  </Box>
                </VideoProvider>
              </Box>
            </TableCell>
          </TableRow>
        }
      </>
    )
  }


  return (
    <>
      <TableRow>
        <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}> <Text strong textColor='text-shade-3'> {reportEditor.getSectionName(section)} </Text> </TableCell>
        <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}> <BuddyScore ocrResult={query.data} /> </TableCell>
        <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}><BuddySpeed ocrResult={query.data} /></TableCell>
        <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}><BuddyQAReviewButton inspection={inspection} ocrResult={query.data} update={(result) => update.mutateAsync(result)} /> </TableCell>
        <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}>
          <Text size='x2' align='middle' strong>
            {Math.max(0, ...reportEditor.getInspectionObservations(inspection.uid).map(e => e.distance || 0)).toFixed(2)}m
          </Text>

        </TableCell>
        <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}>
          <Text size='x2' align='middle' strong>
            {secondsToHHMMSS(query.data.matches.length)}
          </Text>
        </TableCell>
        {
          showDev && <>
            <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}>
              <Box flex='horizontal' gap='x1' alignChildrenHorizontal='middle'>
                <Text size='x1' strong> {query.data.binarizor} </Text>
                {query.data.usedCachedBB ? <Icons.Check size='0.75rem' /> : <Icons.X size='0.75rem' />}
              </Box>
            </TableCell>
            <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}>{((query.data.matches.filter(e => e.interpolated).length / query.data.matches.length) * 100).toFixed(2)}%</TableCell>
          </>
        }
        <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}><InspectionConditionBadges inspectionUid={inspection.uid} /> </TableCell>
        <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}>
          <Box flex='horizontal' alignChildrenHorizontal='middle'>
            <Button size='x1' variant='tertiary' active={showVideo} onClick={() => setShowVideo(!showVideo)}><Icons.Play /></Button>
          </Box>
        </TableCell>
        {
          showDev && <>
            {
              query.data &&
              query.data.matches.filter(e => e.dismissed).length > 0 && <Button onClick={() => {
                update.mutateAsync({
                  ...query.data,
                  matches: query.data.matches.map(m => ({
                    ...m,
                    dismissed: false

                  }))

                })
              }}>Reset</Button>
            }
            <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}><OCRView og={query.data} projectId={projectId} inspectionId={inspection.uid} /></TableCell>
            <TableCell style={{ borderColor: 'var(--color-background-shade-3)' }}><Button onClick={() => remove.mutate()}>delete</Button></TableCell>
          </>
        }
      </TableRow>
      {
        showVideo &&
        <TableRow>
          <TableCell colSpan={12}>
            <Box padding='x3' alignChildrenHorizontal='middle'>
              <VideoProvider>
                <Box flex='horizontal' alignChildrenHorizontal='middle'>
                  <Box flex='vertical'>
                    <VideoPlayer inspectionId={inspection.uid} projectId={projectId!} />
                    <VideoPlayerSectionEditActions inspectionId={inspection.uid} projectId={projectId!} />
                    <InspectionAdditionalSummaryVideo
                      section={reportEditor.getSectionByUid(
                        reportEditor.getInspectionByUid(inspection.uid)?.sectionUid
                      )}
                    />

                  </Box>
                  <Box backgroundColor='light-shade-3'>
                    <VideoPlayerSidebarChapterContainer inspectionId={inspection.uid} projectId={projectId!} />
                  </Box>
                </Box>
              </VideoProvider>
            </Box>
          </TableCell>
        </TableRow>
      }
    </>

  )

}

export default BuddyReviewToolInspectionSummary;
