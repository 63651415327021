import { Appear, Box, TypeColor } from 'preshape';
import React, { PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import { useMapContext } from '../../Map/Map';

export type TypePosition =
  | 'center'
  | 'edge-to-edge'
  | 'top-left'
  | 'top'
  | 'top-right'
  | 'right'
  | 'bottom-right'
  | 'bottom'
  | 'bottom-left'
  | 'left';

type Props = {
  color: TypeColor;
  minimap?: boolean;
  typePosition?: TypePosition;
  withColor?: boolean;
};

const MapNotification = ({
  children,
  color,
  minimap = false,
  withColor = true,
  typePosition = 'top-right',
}: PropsWithChildren<Props>) => {
  const { container, offset } = useMapContext();
  const minimapContiner = document.getElementById('google-map')!;

  if (!container) {
    return null;
  }

  return createPortal(
    <Appear
      absolute={typePosition}
      animation="Pop"
      padding="x4"
      style={{ right: offset.right }}
    >
      <Box
        alignChildren="middle"
        backgroundColor="background-shade-1"
        borderRadius="x3"
        flex="horizontal"
        gap="x2"
        padding="x2"
        textColor="text-shade-1"
        theme="night"
      >
        {withColor && (
          <Box
            backgroundColor={color}
            borderRadius="full"
            height="0.5rem"
            width="0.5rem"
          />
        )}

        {children}
      </Box>
    </Appear>,
    minimap ? minimapContiner : container
  );
};

export default MapNotification;
