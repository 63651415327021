import React from 'react';
import MapSidebarContentItem from '../../../Map/MapSidebar/MapSidebarContentItem';
import MapSidebarContentItemSection from '../../../Map/MapSidebar/MapSidebarContentItemSection';
import SectionCreateButton from '../../../Section/SectionCreateButton';
import SectionList from '../../../Section/SectionList';
import { useReportEditorContext } from '../../ReportEditorProvider';
import { useReportMapContext } from '../ReportMapProvider';

const ReportMapSidebarSections = () => {
  const { activeSidebar, focusSection } = useReportMapContext();
  const { reportEditor } = useReportEditorContext();

  if (activeSidebar !== 'sections') {
    return null;
  }

  return (
    <MapSidebarContentItem
      active={activeSidebar === 'sections'}
      action={
        <SectionCreateButton onCreate={focusSection} variant="tertiary" />
      }
      title="Sections"
    >
      <MapSidebarContentItemSection>
        <SectionList
          onItemClick={focusSection}
          validate={reportEditor.report.sections.length < 15}
        />
      </MapSidebarContentItemSection>
    </MapSidebarContentItem>
  );
};

export default ReportMapSidebarSections;
