import {
  Box,
  Button,
  Icons,
  Modal,
  ModalBody,
  ModalHeader,
  Text,
  TypeColor,
} from 'preshape';
import React, { PropsWithChildren } from 'react';

type Props = {
  title: string;
  color?: TypeColor;
  text?: string;
};

const Explainer = ({ title, children, color = 'white', text }: PropsWithChildren<Props>) => {
  const [visible, setVisible] = React.useState(false);

  return (
    <>
      <Button onClick={() => setVisible(true)} variant="tertiary" size="x1">
        <Box textColor={color}  flex='horizontal' gap='x1' alignChildrenVertical='middle'>
          {text && <Text size='x1'>{text}</Text>}
          <Icons.HelpCircle size="1.25rem" />
        </Box>
      </Button>
      <Modal visible={visible} onClose={() => setVisible(false)} zIndex={30}>
        <ModalHeader>
          <Text strong>{title}</Text>
        </ModalHeader>
        <ModalBody>
          <Box flex="vertical" gap="x2">
            {children}
          </Box>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Explainer;
