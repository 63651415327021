import { Box } from 'preshape';
import React from 'react';
import { ReportMapNodeRendererType } from '.';
import './css/FWG.css';

const ReportMapNodeFWG: ReportMapNodeRendererType = ({ locked }) => {
  return (
    <Box
      className="Icon"
      tag="svg"
      viewBox="0 0 24 24"
      height="1.75rem"
      width="1.75rem"
      stroke="white"
      fill="white"
      textColor="white"
      backgroundColor={locked ? 'text-shade-1' : 'accent-shade-5'}
      borderRadius="5px"
    >
      {
        <svg
          id="FWG-2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 500 500"
        >
          <line
            className="fwg-cls-2"
            x1="418.18"
            y1="77.49"
            x2="354.76"
            y2="140.91"
          />
          <line
            className="fwg-cls-2"
            x1="153.98"
            y1="341.69"
            x2="90.55"
            y2="405.11"
          />
          <line
            className="fwg-cls-2"
            x1="348.16"
            y1="341.69"
            x2="411.58"
            y2="405.11"
          />
          <line
            className="fwg-cls-2"
            x1="82.89"
            y1="76.42"
            x2="146.31"
            y2="139.84"
          />
          <text className="fwg-cls-3" transform="translate(142.41 280.64)">
            <tspan className="fwg-cls-1" x="0" y="0">
              F
            </tspan>
            <tspan x="53.79" y="0">
              WG
            </tspan>
          </text>
          <rect
            className="fwg-cls-2"
            x="81.82"
            y="77.49"
            width="336.36"
            height="336.36"
          />
          <circle className="fwg-cls-2" cx="250" cy="245.67" r="148.15" />
        </svg>
      }
    </Box>
  );
};

export default ReportMapNodeFWG;
