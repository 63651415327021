import { NodeCode } from '@drainify/types';
import { Box, Icons } from 'preshape';
import React, { PropsWithChildren } from 'react';

type Props = {
  locked?: boolean;
  code?: NodeCode;
  size?: string;
};

const ReportMapNodeIcon = ({
  locked,
  size = '1.75rem',
  ...rest
}: PropsWithChildren<Props>) => {
  return (
    <Box
      backgroundColor={locked ? 'text-shade-1' : 'accent-shade-3'}
      borderRadius="full"
      textColor="background-shade-1"
    >
      <Icons.Circle size={size} {...rest} />
    </Box>
  );
};

export default ReportMapNodeIcon;
