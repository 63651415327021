type LngLat = { lng: number; lat: number };
type LngLatSquare = {
  southwest: LngLat;
  northeast: LngLat;
};

type What3WordsResponse = {
  coordinates: LngLat;
  square: LngLatSquare;
  words: string;
};

/**
 *
 */
const lookupWhat3WordsByLngLat = async (
  longitude: number,
  latitude: number
): Promise<What3WordsResponse> => {
  const params = new URLSearchParams({
    coordinates: `${latitude},${longitude}`,
    key: process.env.WHAT_3_WORDS_API_KEY || '',
  }).toString();

  const response = await fetch(
    `https://api.what3words.com/v3/convert-to-3wa?${params}`
  );

  if (response.ok) {
    return response.json() as Promise<What3WordsResponse>;
  }

  throw new Error('What3Words words lookup failed');
};

/**
 *
 */
export const getWhat3WordsAddress = async (
  longitude: number,
  latitude: number
): Promise<string> => {
  const { words } = await lookupWhat3WordsByLngLat(longitude, latitude);
  return words;
};

/**
 *
 */
export const getWhat3WordsSquare = async (
  longitude: number,
  latitude: number
): Promise<LngLatSquare> => {
  const { square } = await lookupWhat3WordsByLngLat(longitude, latitude);
  return square;
};

/**
 *
 */
export const getWhat3WordsGrid = async (
  northEast: { lat: number; lng: number },
  southWest: { lat: number; lng: number }
): Promise<GeoJSON.FeatureCollection | null> => {
  const params = new URLSearchParams({
    'bounding-box': `${northEast.lat},${northEast.lng},${southWest.lat},${southWest.lng}`,
    format: 'geojson',
    key: process.env.WHAT_3_WORDS_API_KEY || '',
  }).toString();

  const response = await fetch(
    `https://api.what3words.com/v3/grid-section?${params}`
  );

  if (response.ok) {
    const json =
      (await response.json()) as GeoJSON.FeatureCollection<GeoJSON.MultiLineString>;

    return json;
  }

  return null;
};
