import {
  Invite,
  InvitePostBody,
  ResponseError,
  ResponseMultipleEntities,
} from '@drainify/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useFetch, { hasAllParams } from '../components/Api/useFetch';

const useOrgInvites = (orgId?: string) => {
  const fetch = useFetch();
  const key = ['invites', `/orgs/${orgId}/invites`, fetch];
  const params = { orgId: orgId as string };
  const queryClient = useQueryClient();

  const query = useQuery<ResponseMultipleEntities<Invite>, ResponseError>(
    key,
    () => fetch('/orgs/:orgId/invites', { method: 'GET', params }),
    { enabled: hasAllParams(params) }
  );

  const create = useMutation<Invite, ResponseError, InvitePostBody>(
    (body: InvitePostBody) => fetch('/invites', { method: 'POST', body }),
    {
      onSuccess: () => {
        queryClient.fetchQuery(key);
      },
    }
  );

  return { query, create };
};

export default useOrgInvites;
