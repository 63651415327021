import { Usage } from '@drainify/types';
import {
  Modal,
  ModalBody,
  Box,
  Button,
  Text,
  ModalHeader,
  Link,
  Icons,
  ModalFooter,
  Alert,
} from 'preshape';
import React from 'react';

type Props = {
  usage: Usage;
};

const UsageDetailsOverdue = ({ usage }: Props) => {
  return (
    <Modal visible>
      <ModalHeader>Failed payment</ModalHeader>
      <ModalBody>
        <Alert
          color="negative"
          backgroundColor="negative-shade-2"
          flex="vertical"
          gap="x4"
          padding="x4"
        >
          <Box flex="horizontal" alignChildrenHorizontal="middle" gap="x3">
            <Icons.AlertCircle size="2rem" />
            <Text strong size="x6">
              Action required - payment unsuccessful
            </Text>
          </Box>
          <Box flex="vertical" gap="x3">
            <Text>
              Address this matter within 21 days to avoid account closure
            </Text>
            <Text>
              We appreciate your cooperation in urgently resolving this matter
            </Text>
          </Box>
        </Alert>
      </ModalBody>
      <ModalFooter>
        <Box flex="horizontal" alignChildrenHorizontal="middle">
          <Link href={usage.resolutionUrl} target="_blank">
            <Button variant="primary" color="accent">
              Click here to resolve
            </Button>
          </Link>
        </Box>
      </ModalFooter>
    </Modal>
  );
};

export default UsageDetailsOverdue;
