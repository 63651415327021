import { GeoJSONPointBlank } from '@drainify/utils';
import { Box, CheckBox } from 'preshape';
import React, { useState } from 'react';
import { useMapContext } from '../Map';
import { useGeometryStoreContext } from './GeometryStore';

const O = 0.2;

const toPolygonString = (geometry: GeoJSON.Polygon) => {
  return geometry.coordinates[0].map((c) => c.join(',')).join(' ');
};

const toPolylineString = (geometry: GeoJSON.LineString) => {
  return geometry.coordinates.map((c) => c.join(',')).join(' ');
};

const useGeometryStoreResultDebugger = () => {
  const { container } = useMapContext();
  const { store } = useGeometryStoreContext();

  const height = container?.offsetHeight || 0;
  const width = container?.offsetWidth || 0;

  const [showLines, setShowLines] = useState(true);
  const [showPoints, setShowPoints] = useState(true);
  const [showPaddings, setShowPaddings] = useState(true);
  const [showBounds, setShowBounds] = useState(true);
  const [showAnchorPoints, setShowAnchorPoints] = useState(true);

  return (
    <>
      <Box
        absolute="top-left"
        height={height}
        style={{ pointerEvents: 'none' }}
        tag="svg"
        viewBox={`0 0 ${width} ${height}`}
        width={width}
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlns="http://www.w3.org/2000/svg"
      >
        {showLines &&
          store.groups.LineString.map((key) => {
            const { relative: { bounds, boundsPadded } = {} } = store.map[key];

            return (
              <g id={`LineString-${key}`} key={key}>
                {showPaddings && boundsPadded && (
                  <polygon
                    fill="blue"
                    fillOpacity={O}
                    points={toPolygonString(boundsPadded)}
                  />
                )}

                {showBounds && bounds && (
                  <polygon
                    fill="red"
                    fillOpacity={O * 2}
                    points={toPolygonString(bounds)}
                  />
                )}
              </g>
            );
          })}

        {showPoints &&
          store.groups.Point.map((key) => {
            const {
              geometryProjected,
              relative: {
                bounds,
                boundsPadded,
                translate: { coordinates: [tx, ty] } = GeoJSONPointBlank,
              } = {},
            } = store.map[key];

            return (
              <g id={`Point-${key}`} key={key}>
                <defs>
                  {bounds && (
                    <polygon
                      id={`${key}-bounds`}
                      points={toPolygonString(bounds)}
                      transform={`translate(${tx} ${ty})`}
                    />
                  )}

                  {boundsPadded && (
                    <polygon
                      id={`${key}-padding`}
                      points={toPolygonString(boundsPadded)}
                      transform={`translate(${tx} ${ty})`}
                    />
                  )}

                  <mask id={`${key}-mask`} x="0" y="0" width="600" height="250">
                    <use href={`#${key}-padding`} fill="white" />
                    <use href={`#${key}-bounds`} fill="black" />
                  </mask>
                </defs>

                {showBounds && (
                  <g>
                    <use
                      href={`#${key}-bounds`}
                      fill="none"
                      stroke="red"
                      strokeWidth={2}
                    />
                  </g>
                )}

                {showPaddings && (
                  <use
                    href={`#${key}-padding`}
                    fill="blue"
                    fillOpacity={O}
                    mask={`url(#${key}-mask)`}
                    stroke="blue"
                    strokeWidth={2}
                  />
                )}

                {showAnchorPoints && (
                  <g>
                    <polyline
                      stroke="red"
                      strokeDasharray="2 2"
                      strokeOpacity={O}
                      strokeWidth={2}
                      points={toPolylineString({
                        type: 'LineString',
                        coordinates: [
                          geometryProjected.coordinates,
                          [
                            geometryProjected.coordinates[0] + tx,
                            geometryProjected.coordinates[1] + ty,
                          ],
                        ],
                      })}
                    />

                    <circle
                      cx={geometryProjected.coordinates[0]}
                      cy={geometryProjected.coordinates[1]}
                      fill="red"
                      fillOpacity={O}
                      r={5}
                    />

                    <circle
                      cx={geometryProjected.coordinates[0]}
                      cy={geometryProjected.coordinates[1]}
                      fill="red"
                      fillOpacity={O * 2}
                      r={5}
                      transform={`translate(${tx} ${ty})`}
                    />
                  </g>
                )}
              </g>
            );
          })}
      </Box>

      <Box absolute="top-left" padding="x10">
        <Box margin="x6">
          <CheckBox
            borderSize='x1'
            checked={showLines}
            margin="x2"
            onChange={() => setShowLines((v) => !v)}
          >
            Show Lines
          </CheckBox>

          <CheckBox
            borderSize='x1'
            checked={showPoints}
            margin="x2"
            onChange={() => setShowPoints((v) => !v)}
          >
            Show Points
          </CheckBox>
        </Box>

        <Box margin="x6">
          <CheckBox
            borderSize='x1'
            checked={showPaddings}
            margin="x2"
            onChange={() => setShowPaddings((v) => !v)}
          >
            Show Paddings
          </CheckBox>

          <CheckBox
            borderSize='x1'
            checked={showBounds}
            margin="x2"
            onChange={() => setShowBounds((v) => !v)}
          >
            Show Bounds
          </CheckBox>

          <CheckBox
            borderSize='x1'
            checked={showAnchorPoints}
            margin="x2"
            onChange={() => setShowAnchorPoints((v) => !v)}
          >
            Show Anchor Points
          </CheckBox>
        </Box>
      </Box>
    </>
  );
};

export default useGeometryStoreResultDebugger;
