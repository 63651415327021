"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getInspectionStatusSchema = exports.getCodesetCodeSchema = exports.getLandOwnershipCodeSchema = exports.getLocationTypeCodeSchema = exports.getAccuracyOfGPSSchema = exports.getYesNoMethodSchema = exports.getCoatingMethodSchema = exports.getLiningTypeSchema = exports.getVideoImageStorageFormatsSchema = exports.getVideoImageFormatsSchema = exports.getPhotographImageStorageFormatsSchema = exports.getVideoStorageSchema = exports.getDrainSewerTypesSchema = exports.getPurposesOfInspectionSchema = exports.getFlowControlSchema = exports.getCriticalityGradeSchema = exports.getMethodOfInspectionSchema = exports.getPrecleanSchema = exports.getTempraturesSchema = exports.getWeatherSchema = exports.getJobManagementSearchTypeSchema = exports.getRepairReccomendationsSchema = exports.repairReccomendations = exports.jobManagementSearchTypes = exports.flowControlMeasures = exports.locationTypeCodes = exports.videoImageFormats = exports.photographImageStorageFormats = exports.videoStorageMedia = exports.codeset = exports.landOwnerships = exports.accuracyOfGPS = exports.yesNo = exports.coatingMethod = exports.liningType = exports.drainSewerTypes = exports.purposesOfInspection = exports.inspectionStatus = exports.criticalityGrades = exports.methodsOfInspection = exports.precleans = exports.tempratures = exports.weathers = void 0;
const types_1 = require("@drainify/types");
const schema_1 = require("./schema");
exports.weathers = [
    {
        code: types_1.Weather.D,
        name: {
            [types_1.LanguageCodes.EN]: 'No rain or snow',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.Weather.R,
        name: {
            [types_1.LanguageCodes.EN]: 'Rain',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.Weather.S,
        name: {
            [types_1.LanguageCodes.EN]: 'Melting snow or ice',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC, types_1.ProjectType.MSCC5_COMMERERCIAL],
    },
    {
        code: types_1.Weather.ONE,
        name: {
            [types_1.LanguageCodes.EN]: 'Dry - No precipitation during survey',
            [types_1.LanguageCodes.ES]: 'Seco',
            [types_1.LanguageCodes.FR]: 'Sec – pas de précipitations au cours de l’inspection',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.Weather.TWO,
        name: {
            [types_1.LanguageCodes.EN]: 'Heavy Rain',
            [types_1.LanguageCodes.ES]: 'Lluvias Fuertes',
            [types_1.LanguageCodes.FR]: 'Forte pluie',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.Weather.THREE,
        name: {
            [types_1.LanguageCodes.EN]: 'Light Rain',
            [types_1.LanguageCodes.ES]: 'Lluvias Ligeras',
            [types_1.LanguageCodes.FR]: 'Faible pluie',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.Weather.FOUR,
        name: {
            [types_1.LanguageCodes.EN]: 'Snow',
            [types_1.LanguageCodes.ES]: 'Nieve',
            [types_1.LanguageCodes.FR]: 'Neige',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.Weather.FIVE,
        name: {
            [types_1.LanguageCodes.EN]: 'Dry Weather/Wet Ground',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Temps sec/sol humide',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
];
exports.tempratures = [
    {
        code: types_1.Temperature.A,
        name: {
            [types_1.LanguageCodes.EN]: 'Above freezing',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.Temperature.B,
        name: {
            [types_1.LanguageCodes.EN]: 'Below freezing',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.Temperature.ONE,
        name: {
            [types_1.LanguageCodes.EN]: 'Dry - No precipitation during survey',
            [types_1.LanguageCodes.ES]: 'Seco',
            [types_1.LanguageCodes.FR]: 'Sec – pas de précipitations au cours de l’inspection',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.Temperature.TWO,
        name: {
            [types_1.LanguageCodes.EN]: 'Heavy Rain',
            [types_1.LanguageCodes.ES]: 'Lluvias Fuertes',
            [types_1.LanguageCodes.FR]: 'Forte pluie',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.Temperature.THREE,
        name: {
            [types_1.LanguageCodes.EN]: 'Light Rain',
            [types_1.LanguageCodes.ES]: 'Lluvias Ligeras',
            [types_1.LanguageCodes.FR]: 'Faible pluie',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.Temperature.FOUR,
        name: {
            [types_1.LanguageCodes.EN]: 'Snow',
            [types_1.LanguageCodes.ES]: 'Nieve',
            [types_1.LanguageCodes.FR]: 'Neige',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.Temperature.FIVE,
        name: {
            [types_1.LanguageCodes.EN]: 'Dry Weather/Wet Ground',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Temps sec/sol humide',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
];
exports.precleans = [
    {
        code: types_1.PreCleaned.Y,
        name: {
            [types_1.LanguageCodes.EN]: 'Yes',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.PreCleaned.N,
        name: {
            [types_1.LanguageCodes.EN]: 'No',
            [types_1.LanguageCodes.ES]: 'No',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.PreCleaned.Z,
        name: {
            [types_1.LanguageCodes.EN]: 'Unknown',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.PreCleaned.H,
        name: {
            [types_1.LanguageCodes.EN]: 'Heavy Cleaning',
            [types_1.LanguageCodes.ES]: 'Limpieza a fondo',
            [types_1.LanguageCodes.FR]: 'Nettoyage supplémentaire',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.PreCleaned.L,
        name: {
            [types_1.LanguageCodes.EN]: 'Light Cleaning',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Nettoyage standard (hydrodynamique)',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.PreCleaned.R,
        name: {
            [types_1.LanguageCodes.EN]: 'Rodding',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.PACP],
    },
    {
        code: types_1.PreCleaned.X,
        name: {
            [types_1.LanguageCodes.EN]: 'Not Known',
            [types_1.LanguageCodes.ES]: 'Desconocido',
            [types_1.LanguageCodes.FR]: 'Inconnu',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
    },
    {
        code: types_1.PreCleaned.Z_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Other',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Autre',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
    },
];
exports.methodsOfInspection = [
    {
        code: types_1.MethodOfInspection.A,
        name: {
            [types_1.LanguageCodes.EN]: 'Direct Inpection - man-entry',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.MethodOfInspection.B,
        name: {
            [types_1.LanguageCodes.EN]: 'CCTV',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.MethodOfInspection.C,
        name: {
            [types_1.LanguageCodes.EN]: 'Inspection from manhole to inspection chamber only',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
];
exports.criticalityGrades = [
    {
        code: types_1.CritcatalityGrade.A,
        name: {
            [types_1.LanguageCodes.EN]: 'Category A',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.CritcatalityGrade.B,
        name: {
            [types_1.LanguageCodes.EN]: 'Category B',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.CritcatalityGrade.C,
        name: {
            [types_1.LanguageCodes.EN]: 'Category C',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.CritcatalityGrade.Z,
        name: {
            [types_1.LanguageCodes.EN]: 'Unknown',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
];
exports.inspectionStatus = [
    {
        code: types_1.InspectionStatus.CI,
        name: {
            [types_1.LanguageCodes.EN]: 'Complete Inspection',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Inspection complétée',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.InspectionStatus.BM,
        name: {
            [types_1.LanguageCodes.EN]: 'Buried and Marked',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Enterré et repéré',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.InspectionStatus.NA,
        name: {
            [types_1.LanguageCodes.EN]: 'No Access',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Inaccessible',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.InspectionStatus.NE,
        name: {
            [types_1.LanguageCodes.EN]: 'Does Not Exist',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Inexistant',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.InspectionStatus.NF,
        name: {
            [types_1.LanguageCodes.EN]: 'Not Found',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Non trouvé',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.InspectionStatus.NI,
        name: {
            [types_1.LanguageCodes.EN]: 'Traffic',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Trafic',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.InspectionStatus.NO,
        name: {
            [types_1.LanguageCodes.EN]: 'Not Opened',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Non ouvert',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.InspectionStatus.SD,
        name: {
            [types_1.LanguageCodes.EN]: 'Surcharged/Debris',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'En charge/présence de débris',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
];
exports.purposesOfInspection = [
    {
        code: types_1.PurposeOfInspection.A,
        name: {
            [types_1.LanguageCodes.EN]: 'Maintenance Related',
            [types_1.LanguageCodes.ES]: 'Relaconado con Mantenimiento',
            [types_1.LanguageCodes.FR]: 'Inspection liée à l’entretien',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.MSCC5_DOMESTIC,
            types_1.ProjectType.PACP,
            types_1.ProjectType.LACP,
        ],
    },
    {
        code: types_1.PurposeOfInspection.B,
        name: {
            [types_1.LanguageCodes.EN]: 'Infiltration/Inflow Investigation',
            [types_1.LanguageCodes.ES]: 'Investigación de Infiltración e Influjo',
            [types_1.LanguageCodes.FR]: 'Inspection liée aux eaux de ruissellement',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.MSCC5_DOMESTIC,
            types_1.ProjectType.PACP,
            types_1.ProjectType.LACP,
        ],
    },
    {
        code: types_1.PurposeOfInspection.C,
        name: {
            [types_1.LanguageCodes.EN]: 'Post Rehabilitation Survey',
            [types_1.LanguageCodes.ES]: 'Inspección Posterior a la Rehabilitación',
            [types_1.LanguageCodes.FR]: 'Inspection après réhabilitation',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.MSCC5_DOMESTIC,
            types_1.ProjectType.PACP,
            types_1.ProjectType.LACP,
        ],
    },
    {
        code: types_1.PurposeOfInspection.D,
        name: {
            [types_1.LanguageCodes.EN]: 'Pre-Rehabilitation Survey',
            [types_1.LanguageCodes.ES]: 'Inspección Previa a una Rehabilitación',
            [types_1.LanguageCodes.FR]: 'Inspection avant réhabilitation',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.MSCC5_DOMESTIC,
            types_1.ProjectType.PACP,
            types_1.ProjectType.LACP,
        ],
    },
    {
        code: types_1.PurposeOfInspection.E,
        name: {
            [types_1.LanguageCodes.EN]: 'Pre-Acceptance New Sewers',
            [types_1.LanguageCodes.ES]: 'Pre-Aprobación  (tuberias nuevas)',
            [types_1.LanguageCodes.FR]: 'Acceptation préliminaire – nouvelles conduites d’égout',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.MSCC5_DOMESTIC,
            types_1.ProjectType.PACP,
            types_1.ProjectType.LACP,
        ],
    },
    {
        code: types_1.PurposeOfInspection.F,
        name: {
            [types_1.LanguageCodes.EN]: 'Routine Assessment',
            [types_1.LanguageCodes.ES]: 'Evaluación de Rutina',
            [types_1.LanguageCodes.FR]: 'Évaluation de routine',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.MSCC5_DOMESTIC,
            types_1.ProjectType.PACP,
            types_1.ProjectType.LACP,
        ],
    },
    {
        code: types_1.PurposeOfInspection.G,
        name: {
            [types_1.LanguageCodes.EN]: 'Capital Improvement Program Assessment',
            [types_1.LanguageCodes.ES]: 'Evaluación para un Programa de Mejoras Públicas (de Capital)',
            [types_1.LanguageCodes.FR]: "Évaluation dans le cadre d’un programme d'immobilisations",
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.PurposeOfInspection.H,
        name: {
            [types_1.LanguageCodes.EN]: 'Resurvey For Any Reason',
            [types_1.LanguageCodes.ES]: 'Re-inspección por Alguna Otra Razón',
            [types_1.LanguageCodes.FR]: 'Réinspection',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.MSCC5_DOMESTIC,
            types_1.ProjectType.PACP,
            types_1.ProjectType.LACP,
        ],
    },
    {
        code: types_1.PurposeOfInspection.I,
        name: {
            [types_1.LanguageCodes.EN]: 'Sewer System Evaluation Survey (SSES)',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'Inspection liée aux études de plans directeurs',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.MSCC5_DOMESTIC,
            types_1.ProjectType.PACP,
            types_1.ProjectType.LACP,
        ],
    },
    {
        code: types_1.PurposeOfInspection.J,
        name: {
            [types_1.LanguageCodes.EN]: 'End of warranty period',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.MSCC5_DOMESTIC,
            types_1.ProjectType.PACP,
            types_1.ProjectType.LACP,
        ],
    },
    {
        code: types_1.PurposeOfInspection.R,
        name: {
            [types_1.LanguageCodes.EN]: 'Pre-Existing Video',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'Vidéo ou photographies déjà existantes',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
    },
    {
        code: types_1.PurposeOfInspection.X,
        name: {
            [types_1.LanguageCodes.EN]: 'Other - state in remarks',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.MSCC5_DOMESTIC,
            types_1.ProjectType.PACP,
            types_1.ProjectType.LACP,
        ],
    },
];
exports.drainSewerTypes = [
    {
        code: types_1.DrainSewerType.A,
        name: {
            [types_1.LanguageCodes.EN]: 'Gravity drain/sewer',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.DrainSewerType.B,
        name: {
            [types_1.LanguageCodes.EN]: 'Rising main',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.DrainSewerType.V,
        name: {
            [types_1.LanguageCodes.EN]: 'Vacuum pipeline',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC, types_1.ProjectType.MSCC5_COMMERERCIAL],
    },
];
exports.liningType = [
    {
        code: types_1.LiningType.SP,
        name: {
            [types_1.LanguageCodes.EN]: 'Sprayed lining',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.LiningType.CF,
        name: {
            [types_1.LanguageCodes.EN]: 'Close fit lining',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.LiningType.DP,
        name: {
            [types_1.LanguageCodes.EN]: 'Lining with discrete pipes',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC, types_1.ProjectType.MSCC5_COMMERERCIAL],
    },
    {
        code: types_1.LiningType.CP,
        name: {
            [types_1.LanguageCodes.EN]: 'Lining with continuous pipes',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC, types_1.ProjectType.MSCC5_COMMERERCIAL],
    },
    {
        code: types_1.LiningType.SW,
        name: {
            [types_1.LanguageCodes.EN]: 'Spirally would lining',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC, types_1.ProjectType.MSCC5_COMMERERCIAL],
    },
    {
        code: types_1.LiningType.M,
        name: {
            [types_1.LanguageCodes.EN]: 'Lining inserted during manufacture',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC, types_1.ProjectType.MSCC5_COMMERERCIAL],
    },
    {
        code: types_1.LiningType.CIP,
        name: {
            [types_1.LanguageCodes.EN]: 'Cured in place lining',
            [types_1.LanguageCodes.ES]: 'Tubería Curada en Sitio (Encamisado o Manga)',
            [types_1.LanguageCodes.FR]: 'Chemisage',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_DOMESTIC,
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.PACP,
            types_1.ProjectType.LACP,
        ],
    },
    {
        code: types_1.LiningType.FF,
        name: {
            [types_1.LanguageCodes.EN]: 'Fold and Form or Deform/Reform',
            [types_1.LanguageCodes.ES]: 'Deformada/Reformada (o Doblada y Desdoblada)',
            [types_1.LanguageCodes.FR]: 'Tuyau déformé / reformé',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
    },
    {
        code: types_1.LiningType.FP,
        name: {
            [types_1.LanguageCodes.EN]: 'Formed-In-Place Liner',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Revêtement formé sur place',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
    },
    {
        code: types_1.LiningType.GP,
        name: {
            [types_1.LanguageCodes.EN]: 'Grout-In-Place Liner',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Revêtement coulé sur place',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
    },
    {
        code: types_1.LiningType.GRC,
        name: {
            [types_1.LanguageCodes.EN]: 'Grass Reinforced Cement',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Béton renforcé de fibres de verre',
        },
        projectTypes: [types_1.ProjectType.PACP],
    },
    {
        code: types_1.LiningType.N,
        name: {
            [types_1.LanguageCodes.EN]: 'None',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Aucun',
        },
        projectTypes: [types_1.ProjectType.PACP],
    },
    {
        code: types_1.LiningType.SC,
        name: {
            [types_1.LanguageCodes.EN]: 'Continuous Slip Liner',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'Tubage continu',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.LiningType.SE,
        name: {
            [types_1.LanguageCodes.EN]: 'Sectional Slip Liner',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'Tubage segmenté',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.LiningType.SL,
        name: {
            [types_1.LanguageCodes.EN]: 'Spray Liner',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'Revêtement projeté',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.LiningType.SN,
        name: {
            [types_1.LanguageCodes.EN]: 'Segmented Panel',
            [types_1.LanguageCodes.ES]: 'Paneles Segmentados (paneles unidos para formar sección completa)',
            [types_1.LanguageCodes.FR]: 'Panneaux segmentés (panneaux courbés assemblés pour former une section circulaire)',
        },
        projectTypes: [types_1.ProjectType.PACP],
    },
    {
        code: types_1.LiningType.SP,
        name: {
            [types_1.LanguageCodes.EN]: 'Segmented Pipe',
            [types_1.LanguageCodes.ES]: 'Tuberia Segmentada (secciones circulares)',
            [types_1.LanguageCodes.FR]: 'Tuyau segmenté (tuyau préfabriqué inséré dans la conduite, insertion continue ou éclatement, par exemple)',
        },
        projectTypes: [types_1.ProjectType.PACP],
    },
    {
        code: types_1.LiningType.SW,
        name: {
            [types_1.LanguageCodes.EN]: 'Spiral Wound',
            [types_1.LanguageCodes.ES]: 'Esprial Enrollada',
            [types_1.LanguageCodes.FR]: 'Tuyau enroulé en spirale',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
    },
    {
        code: types_1.LiningType.XX,
        name: {
            [types_1.LanguageCodes.EN]: 'Not Known',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'Inconnu',
        },
        projectTypes: [types_1.ProjectType.PACP],
    },
    {
        code: types_1.LiningType.ZZ,
        name: {
            [types_1.LanguageCodes.EN]: 'Other',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'Autre',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
    },
    {
        code: types_1.LiningType.SEG,
        name: {
            [types_1.LanguageCodes.EN]: 'Segmental linings',
            [types_1.LanguageCodes.ES]: 'Otra',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC, types_1.ProjectType.MSCC5_COMMERERCIAL],
    },
    {
        code: types_1.LiningType.Z,
        name: {
            [types_1.LanguageCodes.EN]: 'Other liner type',
            [types_1.LanguageCodes.ES]: 'Otra',
            [types_1.LanguageCodes.FR]: 'Autre',
        },
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC, types_1.ProjectType.MSCC5_COMMERERCIAL],
    },
];
exports.coatingMethod = [
    {
        code: types_1.CoatingMethod.CM,
        name: {
            [types_1.LanguageCodes.EN]: 'Cement Mortar',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Mortier de ciment',
        },
        projectTypes: [types_1.ProjectType.PACP],
    },
    {
        code: types_1.CoatingMethod.CT,
        name: {
            [types_1.LanguageCodes.EN]: 'Coal Tar or Bituminous',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Goudron ou bitume',
        },
        projectTypes: [types_1.ProjectType.PACP],
    },
    {
        code: types_1.CoatingMethod.EP,
        name: {
            [types_1.LanguageCodes.EN]: 'Epoxy',
            [types_1.LanguageCodes.ES]: 'Époxy',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.PACP],
    },
    {
        code: types_1.CoatingMethod.PE,
        name: {
            [types_1.LanguageCodes.EN]: 'Polyethylene',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Polyéthylène',
        },
        projectTypes: [types_1.ProjectType.PACP],
    },
    {
        code: types_1.CoatingMethod.PO,
        name: {
            [types_1.LanguageCodes.EN]: 'Polyurethane',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Polyuréthane',
        },
        projectTypes: [types_1.ProjectType.PACP],
    },
    {
        code: types_1.CoatingMethod.PU,
        name: {
            [types_1.LanguageCodes.EN]: 'Polyurea',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Polyrésine',
        },
        projectTypes: [types_1.ProjectType.PACP],
    },
    {
        code: types_1.CoatingMethod.PVC,
        name: {
            [types_1.LanguageCodes.EN]: 'Polyvinyl Chloride',
            [types_1.LanguageCodes.ES]: 'Polychlorure de vinyle',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.PACP],
    },
    {
        code: types_1.CoatingMethod.XX,
        name: {
            [types_1.LanguageCodes.EN]: 'Not Known',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Inconnu',
        },
    },
    {
        code: types_1.CoatingMethod.ZZ,
        name: {
            [types_1.LanguageCodes.EN]: 'Other',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Autre',
        },
    },
];
exports.yesNo = [
    {
        code: types_1.YesNo.Y,
        name: {
            [types_1.LanguageCodes.EN]: 'Yes',
            [types_1.LanguageCodes.ES]: 'Sí',
            [types_1.LanguageCodes.FR]: 'Oui',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
    },
    {
        code: types_1.YesNo.N,
        name: {
            [types_1.LanguageCodes.EN]: 'No',
            [types_1.LanguageCodes.ES]: 'No',
            [types_1.LanguageCodes.FR]: 'Non',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
    },
];
exports.accuracyOfGPS = [
    {
        code: types_1.AccuracyOfGPS.L,
        name: {
            [types_1.LanguageCodes.EN]: 'Survey Level',
            [types_1.LanguageCodes.ES]: 'Precisión submétrica',
            [types_1.LanguageCodes.FR]: 'Haute précision',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
    },
    {
        code: types_1.AccuracyOfGPS.M,
        name: {
            [types_1.LanguageCodes.EN]: 'Sub-Meter',
            [types_1.LanguageCodes.ES]: 'Precisión de un metro',
            [types_1.LanguageCodes.FR]: 'Moins d’un mètre',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
    },
    {
        code: types_1.AccuracyOfGPS.N,
        name: {
            [types_1.LanguageCodes.EN]: 'Nearest Meter',
            [types_1.LanguageCodes.ES]: 'Alta precisión',
            [types_1.LanguageCodes.FR]: 'À un mètre près',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
    },
];
exports.landOwnerships = [
    {
        code: types_1.Ownership.PR,
        name: {
            [types_1.LanguageCodes.EN]: 'Private',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.Ownership.PU,
        name: {
            [types_1.LanguageCodes.EN]: 'Public',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.Ownership.Z,
        name: {
            [types_1.LanguageCodes.EN]: 'Unknown',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC, types_1.ProjectType.MSCC5_COMMERERCIAL],
    },
];
exports.codeset = [
    {
        code: types_1.ProjectType.MSCC5_DOMESTIC,
        name: {
            [types_1.LanguageCodes.EN]: 'Domestic (MSCC5)',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
    },
    {
        code: types_1.ProjectType.MSCC5_COMMERERCIAL,
        name: {
            [types_1.LanguageCodes.EN]: 'Commercial (MSCC5)',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
    },
    {
        code: types_1.ProjectType.PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'PAPC',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
    },
    {
        code: types_1.ProjectType.LACP,
        name: {
            [types_1.LanguageCodes.EN]: 'LACP',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
    },
];
exports.videoStorageMedia = [
    {
        code: types_1.VideoImageStorageMedia.VHS,
        name: {
            [types_1.LanguageCodes.EN]: 'VHS',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.VideoImageStorageMedia.CD,
        name: {
            [types_1.LanguageCodes.EN]: 'CD',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.VideoImageStorageMedia.DVD,
        name: {
            [types_1.LanguageCodes.EN]: 'DVD',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.VideoImageStorageMedia.PHD,
        name: {
            [types_1.LanguageCodes.EN]: 'Portable hard drive',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.VideoImageStorageMedia.X,
        name: {
            [types_1.LanguageCodes.EN]: 'Other',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
];
exports.photographImageStorageFormats = [
    {
        code: types_1.PhotographImageStorageFormat.A,
        name: {
            [types_1.LanguageCodes.EN]: 'Still photographs',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.MSCC5_DOMESTIC,
        ],
    },
    {
        code: types_1.PhotographImageStorageFormat.C,
        name: {
            [types_1.LanguageCodes.EN]: 'Windows Meta File (WMF)',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.MSCC5_DOMESTIC,
        ],
    },
    {
        code: types_1.PhotographImageStorageFormat.D,
        name: {
            [types_1.LanguageCodes.EN]: 'Graphics Image File (GIF)',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.MSCC5_DOMESTIC,
        ],
    },
    {
        code: types_1.PhotographImageStorageFormat.E,
        name: {
            [types_1.LanguageCodes.EN]: 'JPEG file',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.MSCC5_DOMESTIC,
        ],
    },
    {
        code: types_1.PhotographImageStorageFormat.F,
        name: {
            [types_1.LanguageCodes.EN]: 'TIF',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.MSCC5_DOMESTIC,
        ],
    },
    {
        code: types_1.PhotographImageStorageFormat.G,
        name: {
            [types_1.LanguageCodes.EN]: 'PNG',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.MSCC5_DOMESTIC,
        ],
    },
    {
        code: types_1.PhotographImageStorageFormat.X,
        name: {
            [types_1.LanguageCodes.EN]: 'Other',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.MSCC5_DOMESTIC,
        ],
    },
];
exports.videoImageFormats = [
    {
        code: types_1.VideoImageFormat.F,
        name: {
            [types_1.LanguageCodes.EN]: 'Fixed to media',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.VideoImageFormat.MPEG1,
        name: {
            [types_1.LanguageCodes.EN]: 'MPEG1',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.VideoImageFormat.MPEG2,
        name: {
            [types_1.LanguageCodes.EN]: 'MPEG2',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.VideoImageFormat.MPEG4,
        name: {
            [types_1.LanguageCodes.EN]: 'MPEG4',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.VideoImageFormat.VOB,
        name: {
            [types_1.LanguageCodes.EN]: 'VOB',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.VideoImageFormat.AVI,
        name: {
            [types_1.LanguageCodes.EN]: 'AVI',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.VideoImageFormat.DIVX,
        name: {
            [types_1.LanguageCodes.EN]: 'DIVX',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.VideoImageFormat.Z,
        name: {
            [types_1.LanguageCodes.EN]: 'Other',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
];
exports.locationTypeCodes = [
    {
        code: types_1.LocationTypeCode.FWY,
        name: {
            [types_1.LanguageCodes.EN]: 'A footway beside a road',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.LocationTypeCode.VG,
        name: {
            [types_1.LanguageCodes.EN]: 'Verge',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.LocationTypeCode.RD,
        name: {
            [types_1.LanguageCodes.EN]: 'Road',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.LocationTypeCode.PD,
        name: {
            [types_1.LanguageCodes.EN]: 'Other pedestrian area',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.LocationTypeCode.FLD,
        name: {
            [types_1.LanguageCodes.EN]: 'Fields (farmland and public open space)',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.LocationTypeCode.PR,
        name: {
            [types_1.LanguageCodes.EN]: 'Property with buildings',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.LocationTypeCode.GDN,
        name: {
            [types_1.LanguageCodes.EN]: 'Gardens (within private property)',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.LocationTypeCode.BLG,
        name: {
            [types_1.LanguageCodes.EN]: 'Under a permanent building',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.LocationTypeCode.WLD,
        name: {
            [types_1.LanguageCodes.EN]: 'Woodland',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.LocationTypeCode.DIF,
        name: {
            [types_1.LanguageCodes.EN]: 'Difficult access (motorway, railway, watercourse, inside building)',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.LocationTypeCode.WWY,
        name: {
            [types_1.LanguageCodes.EN]: 'Under a waterway',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.LocationTypeCode.A,
        name: {
            [types_1.LanguageCodes.EN]: 'Primary major arterial roads, interstates, numbered roads or town/city center roads for heavy vehicles.',
            [types_1.LanguageCodes.ES]: 'Carreteras principales - urbanas ',
            [types_1.LanguageCodes.FR]: 'Route principale – autoroute, ville ou centre-ville avec véhicules lourds',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.LocationTypeCode.B,
        name: {
            [types_1.LanguageCodes.EN]: 'Secondary roads, non-numbered suburban/rural  for heavy vehicles.',
            [types_1.LanguageCodes.ES]: 'Carretera principal - rural o suburbana',
            [types_1.LanguageCodes.FR]: 'Route secondaire – banlieue/milieu rural (toutes autres routes avec véhicules lourds)',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.LocationTypeCode.C,
        name: {
            [types_1.LanguageCodes.EN]: 'Local rural streets with light traffic, town and city back streets, estate streets and curbside parking areas.',
            [types_1.LanguageCodes.ES]: 'Calles Livianas',
            [types_1.LanguageCodes.FR]: 'Route locale/rurale – trafic léger, rues de ville, rues patrimoniales et aires de stationnement',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.LocationTypeCode.D,
        name: {
            [types_1.LanguageCodes.EN]: 'Easement/Right of way',
            [types_1.LanguageCodes.ES]: 'Área de servidumbre/Derecho de Via',
            [types_1.LanguageCodes.FR]: 'Servitude / Emprise',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.LocationTypeCode.E,
        name: {
            [types_1.LanguageCodes.EN]: 'Woods',
            [types_1.LanguageCodes.ES]: 'Bosques',
            [types_1.LanguageCodes.FR]: 'Boisé',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.LocationTypeCode.F,
        name: {
            [types_1.LanguageCodes.EN]: 'Sidewalk',
            [types_1.LanguageCodes.ES]: 'Aceras',
            [types_1.LanguageCodes.FR]: 'Trottoir',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.LocationTypeCode.G,
        name: {
            [types_1.LanguageCodes.EN]: 'Parking lot',
            [types_1.LanguageCodes.ES]: 'Estacionamientos',
            [types_1.LanguageCodes.FR]: 'Stationnement',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.LocationTypeCode.H,
        name: {
            [types_1.LanguageCodes.EN]: 'Alley',
            [types_1.LanguageCodes.ES]: 'Callejón',
            [types_1.LanguageCodes.FR]: 'Ruelle',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.LocationTypeCode.I,
        name: {
            [types_1.LanguageCodes.EN]: 'Ditch',
            [types_1.LanguageCodes.ES]: 'Zanja',
            [types_1.LanguageCodes.FR]: 'Fossé',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.LocationTypeCode.J,
        name: {
            [types_1.LanguageCodes.EN]: 'Building',
            [types_1.LanguageCodes.ES]: 'Edificación',
            [types_1.LanguageCodes.FR]: 'Sous un bâtiment ou à proximité',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.LocationTypeCode.K,
        name: {
            [types_1.LanguageCodes.EN]: 'Creek',
            [types_1.LanguageCodes.ES]: 'Arroyo',
            [types_1.LanguageCodes.FR]: 'Ruisseau (comprend tous les cours d’eau)',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.LocationTypeCode.L,
        name: {
            [types_1.LanguageCodes.EN]: 'Railway',
            [types_1.LanguageCodes.ES]: 'Ferrocarril',
            [types_1.LanguageCodes.FR]: 'Voie ferrée',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.LocationTypeCode.M,
        name: {
            [types_1.LanguageCodes.EN]: 'Airport',
            [types_1.LanguageCodes.ES]: 'Aeropuerto',
            [types_1.LanguageCodes.FR]: 'Aéroport',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.LocationTypeCode.N,
        name: {
            [types_1.LanguageCodes.EN]: 'Levee/Floodwall',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Digue',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.LocationTypeCode.O,
        name: {
            [types_1.LanguageCodes.EN]: 'Dam',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Barrage',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.LocationTypeCode.P,
        name: {
            [types_1.LanguageCodes.EN]: 'Levee Pump Station',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Station de pompage de digue',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.LocationTypeCode.Y,
        name: {
            [types_1.LanguageCodes.EN]: 'Yard',
            [types_1.LanguageCodes.ES]: 'Jardin/Patio',
            [types_1.LanguageCodes.FR]: 'Cour',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.LocationTypeCode.Z,
        name: {
            [types_1.LanguageCodes.EN]: 'Other',
            [types_1.LanguageCodes.ES]: 'Otro',
            [types_1.LanguageCodes.FR]: 'Autre',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.MSCC5_DOMESTIC,
            types_1.ProjectType.PACP,
            types_1.ProjectType.LACP,
        ],
    },
];
exports.flowControlMeasures = [
    {
        code: types_1.FlowControlMeasures.BL,
        name: {
            [types_1.LanguageCodes.EN]: 'Flow blocked upstream',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.FlowControlMeasures.N,
        name: {
            [types_1.LanguageCodes.EN]: 'Not Controlled',
            [types_1.LanguageCodes.ES]: 'No controlado',
            [types_1.LanguageCodes.FR]: 'Non contrôlé',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.MSCC5_DOMESTIC,
            types_1.ProjectType.PACP,
        ],
    },
    {
        code: types_1.FlowControlMeasures.PB,
        name: {
            [types_1.LanguageCodes.EN]: 'Flow partialy blocked upstream',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.FlowControlMeasures.X,
        name: {
            [types_1.LanguageCodes.EN]: 'Other- to be recodred in remarks',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.FlowControlMeasures.B,
        name: {
            [types_1.LanguageCodes.EN]: 'Bypassed',
            [types_1.LanguageCodes.ES]: "Desviado 'Bypass'",
            [types_1.LanguageCodes.FR]: 'Dérivé',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
    },
    {
        code: types_1.FlowControlMeasures.D,
        name: {
            [types_1.LanguageCodes.EN]: 'De-watered Using Jetter',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
    },
    {
        code: types_1.FlowControlMeasures.L,
        name: {
            [types_1.LanguageCodes.EN]: 'Lift Station',
            [types_1.LanguageCodes.ES]: 'Estación de bombeo',
            [types_1.LanguageCodes.FR]: 'Pompé',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
    },
    {
        code: types_1.FlowControlMeasures.P,
        name: {
            [types_1.LanguageCodes.EN]: 'Plugged',
            [types_1.LanguageCodes.ES]: 'Controlado con tapón',
            [types_1.LanguageCodes.FR]: 'Obturé',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
    },
];
exports.jobManagementSearchTypes = [
    {
        code: types_1.JobManagementSearchType.JOB_NUMBER,
        name: {
            [types_1.LanguageCodes.EN]: 'Job number',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.MSCC5_DOMESTIC,
        ],
    },
    {
        code: types_1.JobManagementSearchType.POSTCODE,
        name: {
            [types_1.LanguageCodes.EN]: 'Postcode',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.MSCC5_DOMESTIC,
        ],
    },
    {
        code: types_1.JobManagementSearchType.CUSTOMER_NAME,
        name: {
            [types_1.LanguageCodes.EN]: 'Customer name',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.MSCC5_DOMESTIC,
        ],
    },
    {
        code: types_1.JobManagementSearchType.SITE_ADDRESS,
        name: {
            [types_1.LanguageCodes.EN]: 'Site address',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_DOMESTIC,
            types_1.ProjectType.MSCC5_COMMERERCIAL,
        ],
    },
];
exports.repairReccomendations = [
    {
        code: types_1.Repairs.CIPP,
        name: {
            [types_1.LanguageCodes.EN]: 'CIPP / No Dig Repairs',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'CIPP/No-Dig repairs are for multiple ‘single point’ repairs or extensive continuous damage in longer pipework',
    },
    {
        code: types_1.Repairs.CLEANSING_AND_DESCALING,
        name: {
            [types_1.LanguageCodes.EN]: 'Cleansing & Descaling',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'High-pressure jetting is ideal for cleaning softer blockages in good-condition pipes, while Electro and Hydro Descaling suit  heavily scaled pipes with tough deposits.',
    },
    {
        code: types_1.Repairs.CIPP_OR_EXCAVATION,
        name: {
            [types_1.LanguageCodes.EN]: 'CIPP / No Dig Repairs or Excavation Work',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'CIPP/No-Dig repairs are for multiple ‘single point’ repairs or extensive continuous damage in longer pipework, while excavations may be necessary for severe structural issues',
    },
    {
        code: types_1.Repairs.REROUNDING_OR_PIPE_BURSTING,
        name: {
            [types_1.LanguageCodes.EN]: 'Rerounding or Pipe Bursting',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'Re-rounding restores deformed pipes without replacement, while Pipe Bursting breaks and replaces severely damaged pipes for comprehensive repair',
    },
    {
        code: types_1.Repairs.ROOT_CUTTING,
        name: {
            [types_1.LanguageCodes.EN]: 'Root cutting',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'Root Cutting is required to restore maximum flow.',
    },
    {
        code: types_1.Repairs.COMPLEX_REPAIR,
        name: {
            [types_1.LanguageCodes.EN]: 'Complex Repairs',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'Attention Required – Pending further evaluation',
    },
];
exports.getRepairReccomendationsSchema = (0, schema_1.createSchemaLookup)(exports.repairReccomendations);
exports.getJobManagementSearchTypeSchema = (0, schema_1.createSchemaLookup)(exports.jobManagementSearchTypes);
exports.getWeatherSchema = (0, schema_1.createSchemaLookup)(exports.weathers);
exports.getTempraturesSchema = (0, schema_1.createSchemaLookup)(exports.tempratures);
exports.getPrecleanSchema = (0, schema_1.createSchemaLookup)(exports.precleans);
exports.getMethodOfInspectionSchema = (0, schema_1.createSchemaLookup)(exports.methodsOfInspection);
exports.getCriticalityGradeSchema = (0, schema_1.createSchemaLookup)(exports.criticalityGrades);
exports.getFlowControlSchema = (0, schema_1.createSchemaLookup)(exports.flowControlMeasures);
exports.getPurposesOfInspectionSchema = (0, schema_1.createSchemaLookup)(exports.purposesOfInspection);
exports.getDrainSewerTypesSchema = (0, schema_1.createSchemaLookup)(exports.drainSewerTypes);
exports.getVideoStorageSchema = (0, schema_1.createSchemaLookup)(exports.videoStorageMedia);
exports.getPhotographImageStorageFormatsSchema = (0, schema_1.createSchemaLookup)(exports.photographImageStorageFormats);
exports.getVideoImageFormatsSchema = (0, schema_1.createSchemaLookup)(exports.videoImageFormats);
exports.getVideoImageStorageFormatsSchema = (0, schema_1.createSchemaLookup)(exports.videoStorageMedia);
exports.getLiningTypeSchema = (0, schema_1.createSchemaLookup)(exports.liningType);
exports.getCoatingMethodSchema = (0, schema_1.createSchemaLookup)(exports.coatingMethod);
exports.getYesNoMethodSchema = (0, schema_1.createSchemaLookup)(exports.yesNo);
exports.getAccuracyOfGPSSchema = (0, schema_1.createSchemaLookup)(exports.accuracyOfGPS);
exports.getLocationTypeCodeSchema = (0, schema_1.createSchemaLookup)(exports.locationTypeCodes);
exports.getLandOwnershipCodeSchema = (0, schema_1.createSchemaLookup)(exports.landOwnerships);
exports.getCodesetCodeSchema = (0, schema_1.createSchemaLookup)(exports.codeset);
exports.getInspectionStatusSchema = (0, schema_1.createSchemaLookup)(exports.inspectionStatus);
