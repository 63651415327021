import { TranscodeUsageView } from '@drainify/types';
import React, { createContext, PropsWithChildren, useContext } from 'react';
import useOrgTranscodeUsage from '../../hooks/useOrgTranscodeUsage';
import { useOrgContext } from '../Org/OrgProvider';

type Context = {
  usage?: TranscodeUsageView;
  refetch?: () => void;
};

const TranscodeUsageContext = createContext<Context>({
  usage: undefined,
  refetch: undefined,
});

export const useTranscodeUsageContext = () => useContext(TranscodeUsageContext);

const TranscodeUsageProvider = ({ children }: PropsWithChildren<{}>) => {
  const { org } = useOrgContext();
  const { query } = useOrgTranscodeUsage({ orgId: org?.uid });

  const context: Context = {
    usage: query.data,
    refetch: () => query.refetch(),
  };

  return (
    <TranscodeUsageContext.Provider value={context}>
      {children}
    </TranscodeUsageContext.Provider>
  );
};

export default TranscodeUsageProvider;
