import { Box } from 'preshape';
import React from 'react';

type Props = {
  size?: string;
  fill?: string;
  stroke?: string;
};

const ScaleIcon = ({
  size = '1.25rem',
  fill = 'black',
  stroke = 'black',
}: Props) => {
  return (
    <Box
      className="Icon"
      tag="svg"
      viewBox="0 0 24 24"
      height={size}
      width={size}
      stroke={stroke} // pass this in
      fill={fill} // pass this in
      textColor="white" // pass this in
      borderRadius="full"
    >
      {
        <svg
          id="ruler"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 500 500"
        >
          <path
            d="M 631.2 185.62 l -14.14 -0.32 l 0.1 11.5 c 0 0 0 25.76 0 38.12 c -2.56 0.6 -3.2 0.88 -3.84 0.88 c -110.18 0.04 -220.36 0.04 -330.52 0.2 c -6.18 0 -8.16 -2.14 -8.02 -8.16 c 0.3 -13.98 0.1 -27.96 0.1 -42.4 h -13.42 v 50 h -342 c 0 -17.28 0 -50 0 -50 h -14 v 124 H 631.2 V 185.62 z M 277.44 293.46 v -44 h 340 v 44 H 277.44 z"
            fillRule="evenodd"
          />
        </svg>
      }
    </Box>
  );
};

export default ScaleIcon;
