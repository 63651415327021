import React, {
  createContext,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSearchContext } from '../Search/SearchProvider';

type WizardFlow = 'create' | 'update';

type WizardRegisterOpts = {
  withSearch?: boolean;
};

type Context = {
  activeStepId: string | null;
  activeStepOpts: WizardRegisterOpts | null;
  flow: WizardFlow;
  hasVisitedSummary: boolean;
  initialActiveStepId?: string;
  isError?: boolean;
  isLoading?: boolean;
  isSuccess?: boolean;
  onCancel?: () => void;
  onRemove?: () => void;
  onSave?: () => Promise<void>;
  registerStep: (id: string, opts: WizardRegisterOpts) => void;
  setActiveStepId: (id: string) => void;
  stepIds: string[];
};

type Props = {
  flow: WizardFlow;
  initialActiveStepId?: string;
  isError?: boolean;
  isLoading?: boolean;
  isSuccess?: boolean;
  onCancel?: () => void;
  onRemove?: () => void;
  onSave?: () => Promise<void>;
  reset?: boolean;
};

export const WizardContext = createContext<Context>({
  activeStepId: null,
  activeStepOpts: null,
  hasVisitedSummary: false,
  flow: 'create',
  isError: false,
  isLoading: false,
  isSuccess: false,
  onCancel: () => {},
  onSave: () => Promise.reject(new Error('No onSave provided')),
  registerStep: () => {},
  setActiveStepId: () => {},
  stepIds: [],
});

export const useWizardContext = () => useContext(WizardContext);

const Wizard = ({
  children,
  flow,
  initialActiveStepId,
  isError,
  isLoading,
  isSuccess,
  onCancel,
  onRemove,
  onSave,
  reset,
}: PropsWithChildren<Props>) => {
  const refUpdateTimeout = useRef<NodeJS.Timeout>();
  const refUnmounting = useRef(false);
  const refSteps = useRef<Record<string, WizardRegisterOpts>>({});
  const [stepIds, setStepIds] = useState<string[]>([]);
  const [activeStepId, setActiveStepId] = useState<string | null>(null);
  const [activeStepOpts, setActiveStepOpts] =
    useState<WizardRegisterOpts | null>(null);
  const [hasVisitedSummary, setHasVisitedSummary] = useState(false);
  const { setSearchString } = useSearchContext();

  const getFirstStepId = (steps: string[]) => {
    if (steps.includes('review') && flow === 'update') {
      return initialActiveStepId || 'review';
    }

    return steps[0];
  };

  const handleSetActiveStepId = (id: SetStateAction<string | null>) => {
    setSearchString('');
    setActiveStepId((prevId) => {
      const nextId = typeof id === 'function' ? id(prevId) : id;
      setActiveStepOpts(nextId ? refSteps.current[nextId] : null);
      return nextId;
    });
  };

  /**
   * Wizard step registration.
   *
   * Every time a wizard step mounts, it calls this register step,
   * which then adds it's id on the refSteps object. It then queues
   * an update to the state steps. (It also returns an unregister
   * that does the opposite.)
   *
   * Once there are no more register calls, the queued update call
   * then processes and sets the state.
   */
  const registerStep = useCallback(
    (stepId: string, opts: WizardRegisterOpts) => {
      const updateSteps = () => {
        if (refUpdateTimeout.current) {
          clearTimeout(refUpdateTimeout.current);
        }

        refUpdateTimeout.current = setTimeout(() => {
          setStepIds((stepIds) => {
            const nextStepIds = Object.keys(refSteps.current);

            if (stepIds.toString() !== nextStepIds.toString()) {
              refSteps.current = {};
              return nextStepIds;
            }

            return stepIds;
          });
        }, 0);
      };

      refSteps.current[stepId] = opts;
      updateSteps();

      return () => {
        if (!refUnmounting.current) {
          delete refSteps.current[stepId];
          updateSteps();
        }
      };
    },
    [stepIds]
  );

  useEffect(() => {
    return () => {
      if (refUpdateTimeout.current) {
        refUnmounting.current = true;
        clearTimeout(refUpdateTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    setHasVisitedSummary(false);
    handleSetActiveStepId(getFirstStepId(stepIds));
  }, [flow, reset]);

  useEffect(() => {
    handleSetActiveStepId(
      (activeStepId) => activeStepId || getFirstStepId(stepIds)
    );
  }, [stepIds]);

  useEffect(() => {
    if (initialActiveStepId) {
      handleSetActiveStepId(initialActiveStepId);
    } else {
      getFirstStepId(stepIds);
    }
  }, [initialActiveStepId]);

  useEffect(() => {
    if (!hasVisitedSummary && activeStepId === 'review') {
      setHasVisitedSummary(true);
    }
  }, [activeStepId, hasVisitedSummary]);

  const value: Context = {
    activeStepId,
    activeStepOpts,
    flow,
    hasVisitedSummary,
    initialActiveStepId,
    isError,
    isLoading,
    isSuccess,
    onCancel: onCancel,
    onRemove: onRemove,
    onSave: onSave,
    registerStep,
    setActiveStepId: handleSetActiveStepId,
    stepIds,
  };

  return (
    <WizardContext.Provider value={value}>{children}</WizardContext.Provider>
  );
};

export default Wizard;
