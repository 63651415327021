import { Invite, InvitePostBody, ResponseError } from '@drainify/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useFetch, { hasAllParams } from '../components/Api/useFetch';
import { DataHookSingleEntity } from '.';

const useInvite: DataHookSingleEntity<'inviteId', Invite, InvitePostBody> = (
  { inviteId },
  { noQuery } = {}
) => {
  const fetch = useFetch();
  const key = ['invites', `/invites/${inviteId}`, fetch];
  const params = { inviteId: inviteId as string };
  const queryClient = useQueryClient();

  const query = useQuery<Invite, ResponseError>(
    key,
    () => fetch('/invites/:inviteId', { method: 'GET', params }),
    { enabled: hasAllParams(params) && !noQuery }
  );

  const update = useMutation<Invite, ResponseError, InvitePostBody>(
    key,
    (body: InvitePostBody) =>
      fetch('/invites/:inviteId', { method: 'PUT', params, body }),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(key, data);
        queryClient.refetchQueries(['invites']);
      },
    }
  );

  const remove = useMutation<void, ResponseError>(
    key,
    () => fetch('/invites/:inviteId', { method: 'DELETE', params }),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(key, data);
        queryClient.refetchQueries(['invites']);
      },
    }
  );

  return { query, update, remove };
};

export default useInvite;
