import { runValidations } from '@drainify/utils';
import { useForm } from 'preshape';
import { useCallback, useMemo } from 'react';
import * as yup from 'yup';

export type SignupState = {
  fullName?: string;
  organisationName?: string;
  email?: string;
  confirmEmail?: string;
};

const validations = yup.object().shape({
  fullName: yup.string().required('Please enter your name.'),
  organisationName: yup
    .string()
    .required('Please enter your organisation name'),
  email: yup.string().email().required('Please enter your email'),
  confirmEmail: yup
    .string()
    .email()
    .required('Please re-enter your email')
    .when(['email'], (email) => {
      if (email) {
        return yup
          .string()
          .test(
            'Email does not match',
            'Email does not match',
            (e) => e === email
          );
      } else return yup.string();
    }),
});

const useSignupForm = () => {
  const initial = useMemo<SignupState>(
    () => ({
      fullName: '',
      organisationName: '',
      email: '',
      confirmEmail: '',
    }),
    []
  );

  const form = useForm({
    initial: initial,
    validate: useCallback((value, setError) => {
      setError(runValidations(validations, value));
    }, []),
  });

  return { form };
};

export default useSignupForm;
