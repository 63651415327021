import { Booking } from '@drainify/types';
import { Box, Text } from 'preshape';
import React from 'react';
import useJobDocuments from '../../hooks/useJobDocuments';
import { useProjectContext } from '../Project/ProjectProvider';
import Spinner from '../Spinner/Spinner';
import DocumentButton from './DocumentButton';

type Props = {
  job: Booking;
};

const DocumentList = ({ job }: Props) => {
  const { list } = useJobDocuments(job.uid);
  const { activeBookingId } = useProjectContext();

  if (list.isLoading) {
    return <Spinner />;
  }

  if (list.data?.data.length === 0) {
    return (
      <Box
        padding="x6"
        flex="horizontal"
        alignChildrenHorizontal="middle"
        backgroundColor="background-shade-2"
      >
        <Text textColor="text-shade-4" size="x2">
          No documents
        </Text>
      </Box>
    );
  }

  return (
    <>
      {list.data?.data.map((e) => (
        <DocumentButton key={e.uid} document={e} jobId={activeBookingId} />
      ))}
    </>
  );
};

export default DocumentList;
