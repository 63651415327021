import { Inspection, InspectionPostBody } from '@drainify/types';
import { isInspection } from '@drainify/utils';
import { DateTime } from 'luxon';
import { Box, Icons, Text } from 'preshape';
import React from 'react';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import ReportErrorIndicator from '../Report/ReportErrorIndicator';
import InspectionConditionBadges from './InspectionConditionBadges';

type Props = {
  inspection?: Inspection | InspectionPostBody;
  showScore?: boolean;
  validate?: boolean;
};

const InspectionView = ({
  inspection,
  validate = true,
  showScore = true,
  ...props
}: Props) => {
  const { reportEditor } = useReportEditorContext();

  const error =
    validate && isInspection(inspection)
      ? reportEditor.getInspectionError(inspection.uid)
      : null;

  return (
    <Text {...props} size="x3">
      <Box flex="horizontal" gap="x4" margin="x2">
        <Box alignChildrenVertical="middle" flex="horizontal" gap="x2" grow>
          <Box>
            <Icons.Search />
          </Box>

          <Box grow>
            <Text strong>{reportEditor.getInspectionName(inspection)}</Text>
          </Box>
        </Box>

        {validate && (
          <Box>
            <ReportErrorIndicator error={error} />
          </Box>
        )}
      </Box>

      {inspection && isInspection(inspection) && (
        <Box alignChildrenHorizontal="between" flex="horizontal">
          {
            <Text weak>
              {DateTime.fromISO(inspection?.createdAt).toFormat(
                'dd/MM/yy @ HH:mm'
              )}
            </Text>
          }
          {showScore && (
            <Box alignChildrenHorizontal="end" flex="vertical">
              <InspectionConditionBadges inspectionUid={inspection.uid} />
            </Box>
          )}
        </Box>
      )}
    </Text>
  );
};

export default InspectionView;
