import { Box, Button, Icons, Modal, ModalBody, Text } from 'preshape';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import BookingDataTableForUser from '../../../components/Booking/BookingDataTableForUser';
import { RootContext } from '../../../components/Bootstrap/Bootstrap';
import ClassroomIcon from '../../../components/IconsMisc/ClassroomIcon';
import { useHasPermission } from '../../../components/Permissions/Permissions';
import PlaceholderAvatar from '../../../components/Placeholders/PlaceholderAvatar';
import ProjectCreateButton from '../../../components/Project/ProjectCreateButton';
import ProjectSearchNoAnimate from '../../../components/Project/ProjectSearch/ProjectSearchNoAnimatie';
import useMe from '../../../hooks/useMe';
import WelcomeWizardMobile from '../../WelcomeWizardMobile';
import Page from '../Layout/Page';
import PageBody from '../Layout/PageBody';

const VERSION_HASH = process.env.VERSION_HASH!;

const HomePage = () => {
  const [updateAvailable, setUpdateAvailable] = React.useState(false);
  const [updatePrompt, setUpdatePrompt] = React.useState(false);
  const hasPermission = useHasPermission('projects:update');
  const { seenTutorials } = useContext(RootContext);
  const firstLogin = !seenTutorials.includes('mobile-start');
  const { query } = useMe();
  const userMe = query.data;
  const navigate = useNavigate();

  const checkForUpdates = async () => {
    fetch(process.env.DRAINIFY_API_URL + '/version').then(async (e) => {
      try {
        const { version } = await e.json();
        setUpdateAvailable(version !== VERSION_HASH);
      } catch (e) {}
    });
  };

  React.useEffect(() => {
    checkForUpdates();
  }, []);

  return (
    <>
      <Box
        basis="0"
        grow
        flex="vertical"
        style={{ overflowY: 'auto', overflowX: 'hidden' }}
      >
        <Page>
          {updateAvailable && (
            <Button
              color="positive"
              variant="primary"
              borderRadius="0px"
              elevate
              onClick={() => {
                if ('serviceWorker' in navigator) {
                  navigator.serviceWorker
                    .getRegistrations()
                    .then(async (registrations) => {
                      for (const registration of registrations) {
                        await registration.update();
                      }
                    });
                  setUpdatePrompt(true);
                }
              }}
            >
              <Text size="x1" strong>
                A new version is available. Click to update.
              </Text>
            </Button>
          )}

          {hasPermission && (
            <Box
              backgroundColor="accent-shade-5"
              padding="x2"
              flex="horizontal"
              gap="x2"
              elevate
            >
              <Box grow padding='x2'>
                <ProjectSearchNoAnimate />
              </Box>
            </Box>
          )}

          <PageBody flex="vertical" gap="x3" overflow="auto" basis="0" grow>
            <BookingDataTableForUser />
          </PageBody>
          <Box
            flex="horizontal"
            alignChildrenHorizontal="around"
            backgroundColor='background-shade-2'
            padding="x1"
            paddingBottom='x8'
            paddingTop='x2'
            borderTop
            borderColor='background-shade-3'
            elevate
          >


            <Button
                borderRadius="full"
                size="x1"
                padding="x0"
                borderColor="accent-shade-1"
                borderSize='x1'
                backgroundColor='background-shade-3'
                color="accent"
                onClick={() => navigate('/admin/org/classroom')}
                textColor='accent-shade-5'
            
            >
              <ClassroomIcon size='2rem'/>
              </Button>

            <Box textColor='accent-shade-5' alignChildrenVertical='middle' flex='vertical'>
              <ProjectCreateButton
                borderColor="accent-shade-1"
                borderRadius='full'
                borderSize='x1'
                variant='secondary'
                color="accent"
                shrink
                mini
              />
            </Box>

            {userMe ? (
              <Button
                borderRadius="full"
                size="x1"
                borderSize='x1'
                padding="x0"
                borderColor="accent-shade-1"
                backgroundColor='background-shade-3'
                color="accent"
                onClick={() => navigate('/settings/user')}
              >
                <Text textColor="accent-shade-3">
                  <Icons.Menu size="1.5rem" stroke="1px" />
                </Text>
              </Button>
            ) : (
              <PlaceholderAvatar size="3rem" />
            )}
          </Box>
          {firstLogin && <WelcomeWizardMobile />}
          <Modal visible={updatePrompt}>
            <ModalBody>Please restart the app to update.</ModalBody>
          </Modal>
        </Page>
      </Box>
    </>
  );
};

export default HomePage;
