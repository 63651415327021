import { Box, Button, Buttons, InputLabel, InputLabelProps } from 'preshape';
import React, { PropsWithChildren } from 'react';
import DropdownMenu, { DropdownOption } from '../DropdownMenu/DropdownMenu';

export type TimeFormat = [number, number];

type Props = InputLabelProps & {
  onChange: (time: TimeFormat) => void;
  time: TimeFormat;
};

const validateHoursMinutes = ([hours, minutes]: TimeFormat): TimeFormat => {
  const validMinutes =
    minutes >= 0 && minutes < 60 && minutes % 15 === 0 ? minutes : 0;
  const validHours = hours >= 0 && hours < 24 ? hours : 0;

  return [validHours, validMinutes];
};

const getHours = (pm: boolean): DropdownOption<number>[] =>
  Array.from({ length: 12 })
    .map((_, i) => i % 12)
    .map((hour) => ({
      name: (hour || (pm ? 12 : 0)).toString().padStart(2, '0'),
      value: hour + (pm ? 12 : 0),
    }));

const minutes: DropdownOption<number>[] = Array.from({ length: 4 })
  .map((_, i) => i * 15)
  .map((minute) => ({
    name: minute.toString().padStart(2, '0'),
    value: minute,
  }));

const HoursAndMinutesPicker = ({
  onChange,
  time: timeMaybeInvalid,
  ...props
}: PropsWithChildren<Props>) => {
  const time = validateHoursMinutes(timeMaybeInvalid);
  const isPm = time[0] >= 12;

  const handleChange = (time: TimeFormat) => {
    onChange([time[0], time[1]]);
  };

  const handlePmChange = (isPm: boolean) => {
    onChange([time[0] + (isPm ? 12 : -12), time[1]]);
  };

  return (
    <InputLabel {...props}>
      <Box flex="horizontal" gap="x4">
        <DropdownMenu
          basis="0"
          grow
          onChange={(value) => handleChange([value, time[1]])}
          value={time[0]}
          options={getHours(isPm)}
        />

        <DropdownMenu
          basis="0"
          grow
          onChange={(value) => handleChange([time[0], value])}
          value={time[1]}
          options={minutes}
        />

        <Buttons joined>
          <Button
            active={!isPm}
            borderColor="text-shade-1"
            borderColorActive="text-shade-1"
            borderColorHover="text-shade-1"
            onClick={isPm ? () => handlePmChange(false) : undefined}
            variant={!isPm ? 'primary' : 'secondary'}
          >
            AM
          </Button>

          <Button
            active={isPm}
            borderColor="text-shade-1"
            borderColorActive="text-shade-1"
            borderColorHover="text-shade-1"
            onClick={!isPm ? () => handlePmChange(true) : undefined}
            variant={isPm ? 'primary' : 'secondary'}
          >
            PM
          </Button>
        </Buttons>
      </Box>
    </InputLabel>
  );
};

export default HoursAndMinutesPicker;
