import {
  ResponseError,
  User,
  Booking,
  BookingPostBody,
  ProjectType,
  JobPurpose,
} from '@drainify/types';
import { getCodesetCodeSchema, jobPurposes } from '@drainify/utils';
import {
  Box,
  CheckBox,
  FormProvider,
  Input,
  InputLabel,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalPaddingOffset,
  ModalProps,
  ModalTitle,
  Text,
} from 'preshape';
import React, { ChangeEvent, PropsWithChildren, useContext } from 'react';
import { UseMutationResult } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import useBookingForm from '../../hooks/forms/useBookingForm';
import useBooking from '../../hooks/useBooking';
import { FULL_SCREEN_MODAL_WIDTH } from '../App/App';
import { AuthenticationContext } from '../Authentication/Authenticate';
import DataTable from '../DataTable/DataTable';
import DataTableGroup from '../DataTable/DataTableGroup';
import DataTableItem from '../DataTable/DataTableItem';
import DatePicker from '../DatePicker/DatePicker';
import { useProjectContext } from '../Project/ProjectProvider';
import TimePicker from '../TimePicker/TimePicker';
import { useLanguageContext } from '../Usage/LanguageProvider';
import UserSelectionList from '../User/UserSelectorList';
import UserView from '../User/UserView';
import Wizard from '../Wizard/Wizard';
import WizardControls from '../Wizard/WizardControls/WizardControls';
import WizardReviewStep from '../Wizard/WizardReviewStep';
import WizardStep from '../Wizard/WizardStep';
import WizardStepError from '../Wizard/WizardStepError';
import BookingSummary from './BookingSummary';

type Props = ModalProps & {
  assignedTo?: User[];
  onClose: () => void;
  onDone: UseMutationResult<Booking, ResponseError, BookingPostBody>;
  onRemove?: () => void;
  booking?: BookingPostBody;
  visible?: boolean;
  parentJobId?: string;
  defaultJobId?: string;
  initialActiveStepId?: string;
};

const BookingModal = ({
  assignedTo,
  onClose,
  onRemove,
  onDone,
  parentJobId,
  booking,
  defaultJobId,
  visible,
  initialActiveStepId,
  ...rest
}: PropsWithChildren<Props>) => {
  const { language } = useLanguageContext();
  const bookingForm = useBookingForm(
    booking,
    assignedTo,
    parentJobId,
    defaultJobId
  );
  const { setActiveBookingId } = useProjectContext();
  const { query, update } = useBooking(parentJobId);
  const setSearchParams = useSearchParams()[1];
  const { user } = useContext(AuthenticationContext);

  const closeModal = () => {
    bookingForm.reset();
    onClose();
  };

  const handleSelectAssignee = (user: User) => {
    bookingForm.setState(({ assignedTo, ...rest }) => {
      const nextAssignedTo =
        assignedTo &&
        (assignedTo.find(({ uid }) => uid === user.uid)
          ? assignedTo.filter(({ uid }) => uid !== user.uid)
          : [...assignedTo, user]);

      return { ...rest, assignedTo: nextAssignedTo };
    });
  };

  const handleDateChange = (bookedForDate: string) => {
    bookingForm.setState((bookingForm) => ({
      ...bookingForm,
      bookedForDate,
    }));
  };

  const handleTimeRangeChange = (bookedForTimeStart: [number, number]) => {
    bookingForm.setState((bookingForm) => ({
      ...bookingForm,
      bookedForTimeStart,
    }));
  };

  const setJobPurpose = (jobPurpose: JobPurpose) => {
    bookingForm.setState((s) => ({
      ...s,
      jobPurpose,
    }));
  };

  const setProjectType = (projectType: ProjectType) => {
    bookingForm.setState((s) => ({
      ...s,
      projectType,
    }));
  };

  const handleSave = async () => {
    if (!bookingForm.hasError) {
      const { uid } = await onDone.mutateAsync(bookingForm.state);
      if (parentJobId && query.data) {
        await update.mutateAsync({
          ...query.data,
          childJobId: uid,
        });
      }
      setActiveBookingId(uid);
      setSearchParams({ ['activeBookingId']: uid });
      closeModal();
    }
  };

  return (
    <FormProvider form={bookingForm}>
      <Wizard
        flow={booking ? 'update' : 'create'}
        isError={onDone.isError}
        isLoading={onDone.isLoading}
        isSuccess={onDone.isSuccess}
        onCancel={closeModal}
        onSave={handleSave}
        reset={visible}
        onRemove={onRemove}
        initialActiveStepId={initialActiveStepId}
      >
        <Modal
          {...rest}
          maxWidth={FULL_SCREEN_MODAL_WIDTH}
          onClose={closeModal}
          overlayBackgroundCloseOnClick={false}
          visible={visible}
        >
          <ModalHeader>
            <ModalTitle>Booking</ModalTitle>
          </ModalHeader>

          <ModalBody>
            <WizardStep id="jobId" title="Job number">
              <InputLabel value="Job ref">
                <Input
                  name="jobId"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    bookingForm.setState((s) => ({
                      ...s,
                      jobId: e.target.value,
                    }))
                  }
                  value={bookingForm.state.jobId}
                />
              </InputLabel>
            </WizardStep>

            <WizardStep id="clientJobRef" title="Client job ref">
              <InputLabel value="Client job ref">
                <Input
                  name="clientJobRef"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    bookingForm.setState((s) => ({
                      ...s,
                      clientJobRef: e.target.value,
                    }))
                  }
                  value={bookingForm.state.clientJobRef || ''}
                />
              </InputLabel>
            </WizardStep>

            <WizardStep id="bookedForDate">
              <DatePicker
                onChange={handleDateChange}
                startDate={bookingForm.state.bookedForDate}
                withSelectablePast
              />
            </WizardStep>

            <WizardStep id="bookedForTime" title="Time">
              <TimePicker
                onChange={handleTimeRangeChange}
                startTime={bookingForm.state.bookedForTimeStart}
              />
            </WizardStep>

            <WizardStep id="assignedTo" title="Assign">
              <Box flex="vertical" gap="x3">
                {bookingForm.state.assignedTo &&
                  bookingForm.state.assignedTo.length > 0 && (
                    <ModalPaddingOffset paddingHorizontal="x6">
                      <Box>
                        <DataTable>
                          <DataTableGroup>
                            <Text strong>Current assignee/s</Text>
                            {bookingForm.state.assignedTo.map((member) => (
                              <DataTableItem
                                key={member.uid}
                                onClick={() => handleSelectAssignee(member)}
                                selected={true}
                              >
                                <UserView user={member} />
                              </DataTableItem>
                            ))}
                          </DataTableGroup>
                        </DataTable>
                      </Box>
                    </ModalPaddingOffset>
                  )}
                <ModalPaddingOffset paddingHorizontal="x4">
                  <UserSelectionList
                    onSelect={handleSelectAssignee}
                    selected={bookingForm.state.assignedTo}
                    withSearch
                  />
                </ModalPaddingOffset>
              </Box>
            </WizardStep>

            <WizardStep id="projectType" title="Project type">
              <Box>
                {Object.values(ProjectType).map(
                  (projectType) =>
                    (projectType.startsWith('MSCC5') ||
                      user?.email?.endsWith('drainify.io')) && (
                      <CheckBox
                        borderSize="x1"
                        checked={bookingForm.state.projectType === projectType}
                        key={projectType}
                        onChange={() => setProjectType(projectType)}
                        margin="x2"
                      >
                        {getCodesetCodeSchema(projectType).name[language]}
                      </CheckBox>
                    )
                )}
              </Box>
            </WizardStep>

            <WizardStep id="jobPurpose" title="Job purpose">
              <Box>
                {jobPurposes
                  .filter((e) =>
                    bookingForm.state.projectType
                      ? e.projectTypes?.includes(bookingForm.state.projectType)
                      : false
                  )
                  .map((e) => (
                    <CheckBox
                      borderSize="x1"
                      checked={bookingForm.state.jobPurpose === e.code}
                      key={e.code}
                      onChange={() => setJobPurpose(e.code)}
                      margin="x2"
                    >
                      {e.name[language]}
                    </CheckBox>
                  ))}
              </Box>
            </WizardStep>

            <WizardReviewStep>
              <BookingSummary booking={bookingForm.state} />
            </WizardReviewStep>
          </ModalBody>

          <ModalFooter>
            <WizardStepError />
            <WizardControls />
          </ModalFooter>
        </Modal>
      </Wizard>
    </FormProvider>
  );
};

export default BookingModal;
