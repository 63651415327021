"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUsesSchema = exports.getShapeSchema = exports.getMaterialSchema = exports.uses = exports.shapes = exports.materials = void 0;
const types_1 = require("@drainify/types");
const schema_1 = require("./schema");
exports.materials = [
    {
        code: types_1.SectionMaterialCode.VC,
        name: {
            [types_1.LanguageCodes.EN]: 'Vitrified Clay',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.SectionMaterialCode.AC,
        name: {
            [types_1.LanguageCodes.EN]: 'Asbestos cement',
            [types_1.LanguageCodes.ES]: 'Asbesto Cemento',
            [types_1.LanguageCodes.FR]: 'Ciment-amiante',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.LACP,
            types_1.ProjectType.PACP,
        ],
    },
    {
        code: types_1.SectionMaterialCode.BR,
        name: {
            [types_1.LanguageCodes.EN]: 'Brick',
            [types_1.LanguageCodes.ES]: 'Ladrillo',
            [types_1.LanguageCodes.FR]: 'Brique',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.PACP],
    },
    {
        code: types_1.SectionMaterialCode.CI,
        name: {
            [types_1.LanguageCodes.EN]: 'Cast Iron',
            [types_1.LanguageCodes.ES]: 'Hierro Fundido',
            [types_1.LanguageCodes.FR]: 'Fonte',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.LACP],
    },
    {
        code: types_1.SectionMaterialCode.CAS,
        name: {
            [types_1.LanguageCodes.EN]: 'Cast Iron',
            [types_1.LanguageCodes.ES]: 'Hierro Fundido',
            [types_1.LanguageCodes.FR]: 'Fonte',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.SectionMaterialCode.CO,
        name: {
            [types_1.LanguageCodes.EN]: 'Concrete',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
    },
    {
        code: types_1.SectionMaterialCode.CS,
        name: {
            [types_1.LanguageCodes.EN]: 'Concrete Segments',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
    },
    {
        code: types_1.SectionMaterialCode.DI,
        name: {
            [types_1.LanguageCodes.EN]: 'Ductile Iron',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
    },
    {
        code: types_1.SectionMaterialCode.EP,
        name: {
            [types_1.LanguageCodes.EN]: 'Epoxy',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
    },
    {
        code: types_1.SectionMaterialCode.FC,
        name: {
            [types_1.LanguageCodes.EN]: 'Fibre Cement',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
    },
    {
        code: types_1.SectionMaterialCode.FRP,
        name: {
            [types_1.LanguageCodes.EN]: 'Fibre Reinforced Plastic',
            [types_1.LanguageCodes.ES]: 'Tuberia Reforzada con Fibra de Vidrio',
            [types_1.LanguageCodes.FR]: 'Conduite renforcée de fibre de verre',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.PACP],
    },
    {
        code: types_1.SectionMaterialCode.GCI,
        name: {
            [types_1.LanguageCodes.EN]: 'Grey Cast Iron',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
    },
    {
        code: types_1.SectionMaterialCode.MX,
        name: {
            [types_1.LanguageCodes.EN]: 'Mixed Material',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
    },
    {
        code: types_1.SectionMaterialCode.MAR,
        name: {
            [types_1.LanguageCodes.EN]: 'Masonry (Randomly coursed)',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
    },
    {
        code: types_1.SectionMaterialCode.MAC,
        name: {
            [types_1.LanguageCodes.EN]: 'Masonry (Regularly Coursed)',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
    },
    {
        code: types_1.SectionMaterialCode.Z,
        name: {
            [types_1.LanguageCodes.EN]: 'Other',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.SectionMaterialCode.PS,
        name: {
            [types_1.LanguageCodes.EN]: 'Polyester',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
    },
    {
        code: types_1.SectionMaterialCode.PE,
        name: {
            [types_1.LanguageCodes.EN]: 'Polyethylene',
            [types_1.LanguageCodes.ES]: 'Polietileno',
            [types_1.LanguageCodes.FR]: 'Polyéthylène',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.LACP,
            types_1.ProjectType.PACP,
        ],
    },
    {
        code: types_1.SectionMaterialCode.PF,
        name: {
            [types_1.LanguageCodes.EN]: 'Pitch Fibre',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.SectionMaterialCode.PP,
        name: {
            [types_1.LanguageCodes.EN]: 'Polypropylene',
            [types_1.LanguageCodes.ES]: 'Polipropileno',
            [types_1.LanguageCodes.FR]: 'Polypropylène',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.PACP],
    },
    {
        code: types_1.SectionMaterialCode.PVC,
        name: {
            [types_1.LanguageCodes.EN]: 'Polyvinyl Chloride',
            [types_1.LanguageCodes.ES]: 'Cloruro de Polivinilo',
            [types_1.LanguageCodes.FR]: 'Polychlorure de vinyle ',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.SectionMaterialCode.RC,
        name: {
            [types_1.LanguageCodes.EN]: 'Reinforced Concrete',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
    },
    {
        code: types_1.SectionMaterialCode.ST,
        name: {
            [types_1.LanguageCodes.EN]: 'Steel',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
    },
    {
        code: types_1.SectionMaterialCode.X,
        name: {
            [types_1.LanguageCodes.EN]: 'Unidentified Material',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
    },
    {
        code: types_1.SectionMaterialCode.XP,
        name: {
            [types_1.LanguageCodes.EN]: 'Unidentified type of iron or Steel',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
    },
    {
        code: types_1.SectionMaterialCode.XI,
        name: {
            [types_1.LanguageCodes.EN]: 'Unidentified Type of Plastic',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
    },
    {
        code: types_1.SectionMaterialCode.ABS,
        name: {
            [types_1.LanguageCodes.EN]: 'Acrylonitrile Butadiene Styrene',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Acrylonitrile butadiène styrène',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.SectionMaterialCode.CLC,
        name: {
            [types_1.LanguageCodes.EN]: 'Clay-lined Concrete Pipe',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Béton revêtu d’argile',
        },
        projectTypes: [types_1.ProjectType.PACP],
    },
    {
        code: types_1.SectionMaterialCode.CMP,
        name: {
            [types_1.LanguageCodes.EN]: 'Corrugated Metal Pipe',
            [types_1.LanguageCodes.ES]: 'Tuberia de Metal Corrugado',
            [types_1.LanguageCodes.FR]: 'Tôle ondulée',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.SectionMaterialCode.CP,
        name: {
            [types_1.LanguageCodes.EN]: 'Concrete Pipe (non-reinforced)',
            [types_1.LanguageCodes.ES]: 'Tuberia de Concreto (no-reforzado)',
            [types_1.LanguageCodes.FR]: 'Béton non armé',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.SectionMaterialCode.CSB,
        name: {
            [types_1.LanguageCodes.EN]: 'Concrete Segments (bolted)',
            [types_1.LanguageCodes.ES]: 'Segmentos de Concreto (atornillados)',
            [types_1.LanguageCodes.FR]: 'Segments de béton (boulonnés)',
        },
        projectTypes: [types_1.ProjectType.PACP],
    },
    {
        code: types_1.SectionMaterialCode.CSU,
        name: {
            [types_1.LanguageCodes.EN]: 'Concrete Segments (unbolted,',
            [types_1.LanguageCodes.ES]: 'Segmentos de Concreto (no atornillados)',
            [types_1.LanguageCodes.FR]: 'Segments de béton (non boulonnés)',
        },
        projectTypes: [types_1.ProjectType.PACP],
    },
    {
        code: types_1.SectionMaterialCode.CT,
        name: {
            [types_1.LanguageCodes.EN]: 'Clay Tile (not vitrified clay)',
            [types_1.LanguageCodes.ES]: 'Baldosas de Arcilla (no vitrificada)',
            [types_1.LanguageCodes.FR]: 'Tuile d’argile (pas en grès vitrifié)',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.SectionMaterialCode.DIP,
        name: {
            [types_1.LanguageCodes.EN]: 'Ductile Iron Pipe	',
            [types_1.LanguageCodes.ES]: 'Hierro Dúctil	',
            [types_1.LanguageCodes.FR]: 'Fonte ductile',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.SectionMaterialCode.OB,
        name: {
            [types_1.LanguageCodes.EN]: 'Pitch Fiber (Orangeburg)',
            [types_1.LanguageCodes.ES]: 'Orangeburg (cartón de fibra y brea)',
            [types_1.LanguageCodes.FR]: 'Fibre goudronnée (Orangeburg, carton imprégné de bitume)',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.SectionMaterialCode.PCP,
        name: {
            [types_1.LanguageCodes.EN]: 'Polymer Concrete Pipe		',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Béton polymère',
        },
        projectTypes: [types_1.ProjectType.PACP],
    },
    {
        code: types_1.SectionMaterialCode.PCCP,
        name: {
            [types_1.LanguageCodes.EN]: 'Pre-stressed Concrete Cylinder Pipe	',
            [types_1.LanguageCodes.ES]: 'Tubo de Concreto Pretensado	',
            [types_1.LanguageCodes.FR]: 'Conduite en béton précontraint',
        },
        projectTypes: [types_1.ProjectType.PACP],
    },
    {
        code: types_1.SectionMaterialCode.PSC,
        name: {
            [types_1.LanguageCodes.EN]: 'Plastic/Steel Composite	',
            [types_1.LanguageCodes.ES]: 'Compuesto de Acero y Plástico	',
            [types_1.LanguageCodes.FR]: 'Composé plastique/acier',
        },
        projectTypes: [types_1.ProjectType.PACP],
    },
    {
        code: types_1.SectionMaterialCode.RCP,
        name: {
            [types_1.LanguageCodes.EN]: 'Reinforced Concrete Pipe	',
            [types_1.LanguageCodes.ES]: 'Tubo de Concreto Reforzado	',
            [types_1.LanguageCodes.FR]: 'Béton armé',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.SectionMaterialCode.RPM,
        name: {
            [types_1.LanguageCodes.EN]: 'Reinforced Plastic Pipe (Truss Pipe)',
            [types_1.LanguageCodes.ES]: 'Tubo de Plástico Reforzado (Armado)',
            [types_1.LanguageCodes.FR]: 'Plastique renforcé',
        },
        projectTypes: [types_1.ProjectType.PACP],
    },
    {
        code: types_1.SectionMaterialCode.SB,
        name: {
            [types_1.LanguageCodes.EN]: 'Segmented Block	',
            [types_1.LanguageCodes.ES]: 'Ladrillo Segmentado	',
            [types_1.LanguageCodes.FR]: 'Blocs segmentés',
        },
        projectTypes: [types_1.ProjectType.PACP],
    },
    {
        code: types_1.SectionMaterialCode.SP,
        name: {
            [types_1.LanguageCodes.EN]: 'Steel Pipe	',
            [types_1.LanguageCodes.ES]: 'Tubo de Acero',
            [types_1.LanguageCodes.FR]: 'Acier',
        },
        projectTypes: [types_1.ProjectType.PACP],
    },
    {
        code: types_1.SectionMaterialCode.VCP,
        name: {
            [types_1.LanguageCodes.EN]: 'Vitrified Clay Pipe	',
            [types_1.LanguageCodes.ES]: 'Tubo de Arcilla Vitrificada	',
            [types_1.LanguageCodes.FR]: 'Grès vitrifié',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.SectionMaterialCode.WD,
        name: {
            [types_1.LanguageCodes.EN]: 'Wood	',
            [types_1.LanguageCodes.ES]: 'Madera	',
            [types_1.LanguageCodes.FR]: 'Bois',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.SectionMaterialCode.XXX,
        name: {
            [types_1.LanguageCodes.EN]: 'Not Known	',
            [types_1.LanguageCodes.ES]: 'No se sabe	',
            [types_1.LanguageCodes.FR]: 'Inconnu',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
    {
        code: types_1.SectionMaterialCode.ZZZ,
        name: {
            [types_1.LanguageCodes.EN]: 'Other',
            [types_1.LanguageCodes.ES]: 'Otro',
            [types_1.LanguageCodes.FR]: 'Autre',
        },
        projectTypes: [types_1.ProjectType.LACP, types_1.ProjectType.PACP],
    },
];
exports.shapes = [
    {
        code: types_1.SectionShapeCode.A,
        name: {
            [types_1.LanguageCodes.EN]: 'Arched',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.PACP,
            types_1.ProjectType.LACP,
        ],
    },
    {
        code: types_1.SectionShapeCode.B,
        name: {
            [types_1.LanguageCodes.EN]: 'Barrel',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.PACP,
            types_1.ProjectType.LACP,
        ],
    },
    {
        code: types_1.SectionShapeCode.C,
        name: {
            [types_1.LanguageCodes.EN]: 'Circular',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.MSCC5_DOMESTIC,
            types_1.ProjectType.PACP,
            types_1.ProjectType.LACP,
        ],
    },
    {
        code: types_1.SectionShapeCode.E,
        name: {
            [types_1.LanguageCodes.EN]: 'Egg',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.PACP,
            types_1.ProjectType.LACP,
        ],
    },
    {
        code: types_1.SectionShapeCode.H,
        name: {
            [types_1.LanguageCodes.EN]: 'Horseshoe',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.PACP,
            types_1.ProjectType.LACP,
        ],
    },
    {
        code: types_1.SectionShapeCode.O,
        name: {
            [types_1.LanguageCodes.EN]: 'Oval',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.PACP,
            types_1.ProjectType.LACP,
        ],
    },
    {
        code: types_1.SectionShapeCode.R,
        name: {
            [types_1.LanguageCodes.EN]: 'Rectangular',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.PACP,
            types_1.ProjectType.LACP,
        ],
    },
    {
        code: types_1.SectionShapeCode.T,
        name: {
            [types_1.LanguageCodes.EN]: 'Trapezoidal',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.PACP,
            types_1.ProjectType.LACP,
        ],
    },
    {
        code: types_1.SectionShapeCode.U,
        name: {
            [types_1.LanguageCodes.EN]: 'U-Shaped',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.PACP,
            types_1.ProjectType.LACP,
        ],
    },
    {
        code: types_1.SectionShapeCode.K,
        name: {
            [types_1.LanguageCodes.EN]: 'Kerb block',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.PACP,
            types_1.ProjectType.LACP,
        ],
    },
    {
        code: types_1.SectionShapeCode.CSC,
        name: {
            [types_1.LanguageCodes.EN]: 'Circular with smaller chamber',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.PACP,
            types_1.ProjectType.LACP,
        ],
    },
    {
        code: types_1.SectionShapeCode.RSC,
        name: {
            [types_1.LanguageCodes.EN]: 'Rectangular with smaller chamber',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.PACP,
            types_1.ProjectType.LACP,
        ],
    },
    {
        code: types_1.SectionShapeCode.Z,
        name: {
            [types_1.LanguageCodes.EN]: 'Other',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [
            types_1.ProjectType.MSCC5_COMMERERCIAL,
            types_1.ProjectType.MSCC5_DOMESTIC,
            types_1.ProjectType.PACP,
            types_1.ProjectType.LACP,
        ],
    },
];
exports.uses = [
    {
        code: types_1.SectionUseCode.C,
        name: {
            [types_1.LanguageCodes.EN]: 'Combined',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.SectionUseCode.F,
        name: {
            [types_1.LanguageCodes.EN]: 'Foul',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.SectionUseCode.S,
        name: {
            [types_1.LanguageCodes.EN]: 'Surface Water',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.SectionUseCode.T,
        name: {
            [types_1.LanguageCodes.EN]: 'Trade Effluent',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
    },
    {
        code: types_1.SectionUseCode.W,
        name: {
            [types_1.LanguageCodes.EN]: 'Culvert Watercourse',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
    },
    {
        code: types_1.SectionUseCode.Z,
        name: {
            [types_1.LanguageCodes.EN]: 'Other',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.SectionUseCode.CB_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Combined Pipe',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
    },
    {
        code: types_1.SectionUseCode.DP_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Dam Pipe',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
    },
    {
        code: types_1.SectionUseCode.FM_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Force Main',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
    },
    {
        code: types_1.SectionUseCode.LG_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Levee Gravity Pipe',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
    },
    {
        code: types_1.SectionUseCode.LP_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Levee Pressure Pipe',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
    },
    {
        code: types_1.SectionUseCode.PR_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Process Pipe',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
    },
    {
        code: types_1.SectionUseCode.SS_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Sanitary Sewage Pipe',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
    },
    {
        code: types_1.SectionUseCode.SW_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Stormwater Pipe',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
    },
    {
        code: types_1.SectionUseCode.XX_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Not Known',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
    },
    {
        code: types_1.SectionUseCode.ZZ_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Other',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
    },
];
exports.getMaterialSchema = (0, schema_1.createSchemaLookup)(exports.materials);
exports.getShapeSchema = (0, schema_1.createSchemaLookup)(exports.shapes);
exports.getUsesSchema = (0, schema_1.createSchemaLookup)(exports.uses);
