import { NodeCode, SectionNodePosition } from '@drainify/types';
import { nodes } from '@drainify/utils';
import React from 'react';
import DataTable, { DataTableProps } from '../DataTable/DataTable';
import DataTableGroup from '../DataTable/DataTableGroup';
import DataTableItem from '../DataTable/DataTableItem';
import { useProjectContext } from '../Project/ProjectProvider';
import NodeCodeView from './NodeCodeView';

type Props = DataTableProps & {
  nodePosition?: SectionNodePosition;
  onSelect: (code: NodeCode) => void;
  value?: NodeCode;
};

const NodeCodeList = ({ nodePosition, onSelect, value, ...rest }: Props) => {
  const { project, activeBooking } = useProjectContext();
  if (!project) {
    return null;
  }
  return (
    <DataTable {...rest}>
      <DataTableGroup>
        {nodes
          .filter((e) =>
            activeBooking
              ? e.projectTypes?.includes(activeBooking.projectType!)
              : true
          )
          .map((node) => (
            <DataTableItem
              key={node.code}
              keywords={`${node.code},${node.name}`}
              onClick={() => onSelect(node.code)}
              selected={node.code === value}
            >
              <NodeCodeView code={node.code} nodePosition={nodePosition} />
            </DataTableItem>
          ))}
      </DataTableGroup>
    </DataTable>
  );
};

export default NodeCodeList;
