import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseApp = initializeApp({
  apiKey: 'AIzaSyAYxGEnIWyv9In-xFLpr2yzdtb6jY5tkcc',
  authDomain: 'project-d-328516.firebaseapp.com',
  projectId: 'project-d-328516',
  storageBucket: 'project-d-328516.appspot.com',
  messagingSenderId: '362307105035',
  appId: '1:362307105035:web:3d6aa281bef730571d320e',
});

export const firestoreDb = getFirestore(firebaseApp);
