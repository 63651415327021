import { Attributes, Button, ButtonProps, Icons, Text } from 'preshape';
import React, { PropsWithChildren } from 'react';
import ConfirmationDeleteModal from '../../utils/ConfirmationDeleteModal';
import { useHasPermission } from '../Permissions/Permissions';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import { useReportMapContext } from '../Report/ReportMap/ReportMapProvider';

type Props = ButtonProps & {
  measurementUid?: string;
};

const MeasurementDeleteButton = ({
  measurementUid,
  ...rest
}: Attributes<HTMLButtonElement, PropsWithChildren<Props>>) => {
  const { removeMeasurement } = useReportEditorContext();
  const { unfocus } = useReportMapContext();
  const hasUpdateProjectPermission = useHasPermission('projects:update');
  const [alertVisible, setAlertVisible] = React.useState(false);

  if (!hasUpdateProjectPermission || !measurementUid) {
    return null;
  }

  return (
    <>
      <Button
        variant="tertiary"
        {...rest}
        color="negative"
        gap="x2"
        onClick={() => setAlertVisible(true)}
        type="button"
      >
        <Text>Delete measurement</Text>
        <Icons.Trash2 size="1rem" />
      </Button>
      <ConfirmationDeleteModal
        visible={alertVisible}
        onClose={() => setAlertVisible(false)}
        action={() => {
          unfocus();
          removeMeasurement(measurementUid);
        }}
        content="Are you sure you want to delete this measurement?"
        subheading=""
      />
    </>
  );
};

export default MeasurementDeleteButton;
