import { Box } from 'preshape';
import React from 'react';
import { ReportMapNodeRendererType } from '.';
import './css/GY.css';

const ReportMapNodeGY: ReportMapNodeRendererType = ({ locked }) => {
  return (
    <Box
      className="Icon"
      tag="svg"
      viewBox="0 0 24 24"
      height="1.75rem"
      width="1.75rem"
      stroke="white"
      fill="white"
      textColor="white"
      backgroundColor={locked ? 'text-shade-1' : 'accent-shade-5'}
      borderRadius="full"
    >
      {
        <svg id="GY" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
          <path
            className="gy-cls-1"
            d="M151.91,127.51c19.95,0,31.58,6.97,35.28,10.63-3.71,3.66-15.33,10.63-35.28,10.63s-31.58-6.97-35.28-10.63c3.71-3.66,15.33-10.63,35.28-10.63m0-30c-36.56,0-66.2,18.19-66.2,40.63s29.64,40.63,66.2,40.63,66.2-18.19,66.2-40.63-29.64-40.63-66.2-40.63h0Z"
          />
          <path
            className="gy-cls-1"
            d="M403.71,102.97h-142.72s0,0,0,0l-1.33,187.46s0,0,0,0h-43.06s0,0,0,0v-121.97s0,0,0,0c0,0-23.31,21.77-64.34,21.77-37.68,0-66.54-21.82-66.55-21.82,0,0,0,0,0,0l.77,155.51c.26,51.47,42.05,93.06,93.52,93.06h98.87c48.85,0,88.45-39.6,88.45-88.45v-97.02s0,0,0,0l36.76-.33c35.28-.21,63.76-28.89,63.72-64.17h0c-.04-35.38-28.73-64.03-64.1-64.03Z"
          />
        </svg>
      }
    </Box>
  );
};

export default ReportMapNodeGY;
