import { Box, Link, LinkProps, Text } from 'preshape';
import React, { PropsWithChildren } from 'react';

const PageMenuItem = ({
  description,
  icon,
  title,
  ...rest
}: PropsWithChildren<LinkProps> & {
  description: string;
  icon: JSX.Element;
  title: string;
}) => {
  return (
    <Link
      {...rest}
      backgroundColor="background-shade-2"
      borderRadius="x3"
      flex="vertical"
      gap="x4"
      padding="x4"
    >
      <Box>{icon}</Box>

      <Box>
        <Text margin="x2" size="x5" strong>
          {title}
        </Text>
        <Text margin="x2" size="x3" textColor="text-shade-3">
          {description}
        </Text>
      </Box>
    </Link>
  );
};

export default PageMenuItem;
