import { Node } from '@drainify/types';
import React, { useRef } from 'react';
import { useMapContext } from '../../../Map/Map';
import MapDroppable from '../../../Map/MapDroppable/MapDroppable';
import useMapDragAndDrop from '../../../Map/useMapDragAndDrop';
import { useReportEditorContext } from '../../ReportEditorProvider';
import ReportMapNodeIcon from '../ReportMapElements/ReportMapNodeIcon';

type Props = {
  node: Node;
};

const ReportMapToolAddNode = ({ node }: Props) => {
  const { reportEditor } = useReportEditorContext();
  const { container } = useMapContext();
  const refContainer = useRef<HTMLDivElement>(null);

  const handleCreateNode = async (point: GeoJSON.Point) => {
    reportEditor.updateNode(node.uid, { point });
  };

  const { onPointerDown } = useMapDragAndDrop({
    onDrop: handleCreateNode,
    ref: refContainer,
    target: container,
  });

  return (
    <MapDroppable
      name={node.code}
      onPointerDown={onPointerDown}
      ref={refContainer}
      title={'Drag and drop to set point'}
    >
      <ReportMapNodeIcon />
    </MapDroppable>
  );
};

export default ReportMapToolAddNode;
