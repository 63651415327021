import { Attributes } from 'preshape';
import React from 'react';
import PageMenuItem, {
  PageMenuItemProps,
} from '../../mobile/Layout/PageMenuItem';

const ContextMenuItem = (
  props: Attributes<HTMLButtonElement, PageMenuItemProps>
) => {
  return <PageMenuItem {...props} size="x2" />;
};

export default ContextMenuItem;
