import { useMemo } from 'react';

const useValidTimeInputs = (
  dirtyStartTime: [number, number] = [9, 0]
): [number, number] => {
  return useMemo(() => {
    let startTime = dirtyStartTime;

    if (
      !startTime ||
      startTime[0] < 0 ||
      startTime[0] > 23 ||
      startTime[1] < 0 ||
      startTime[1] > 59
    ) {
      startTime = [9, 0];
    }

    return startTime;
  }, [dirtyStartTime]);
};

export default useValidTimeInputs;
