import { SignedPostPolicyV4Output } from '@google-cloud/storage';
import { useCallback, useContext, useState } from 'react';
import { AuthenticationContext } from '../components/Authentication/Authenticate';
import { useUsageContext } from '../components/Usage/UsageProvider';

type UseFileUploadResponse = {
  uploadError: string | null;
  isError: boolean;
  isLoading: boolean;
  isLoadingProgress: number;
  isSuccess: boolean;
};

const useFileUpload = <T>(): UseFileUploadResponse & {
  remove: (path: string) => Promise<void>;
  update: (path: string, meta?: object) => Promise<void>;
  upload: (path: string, file: File, meta?: object) => Promise<T>;
  uploadSignedUrl: (
    signedUrl: SignedPostPolicyV4Output,
    file: File,
    meta?: object
  ) => Promise<T>;
  reset: () => void;
} => {
  const { user } = useContext(AuthenticationContext);
  const { refetch: updateUsage } = useUsageContext();

  const [state, setState] = useState<UseFileUploadResponse>({
    uploadError: null,
    isError: false,
    isLoading: false,
    isLoadingProgress: 0,
    isSuccess: false,
  });

  const reset = () => {
    setState({
      uploadError: null,
      isError: false,
      isLoading: false,
      isLoadingProgress: 0,
      isSuccess: false,
    });
  };

  const upload = useCallback(
    async (path: string, file: File, meta?: object) => {
      setState((s) => ({
        ...s,
        isError: false,
        isLoading: true,
        isLoadingProgress: 0,
        isSuccess: false,
      }));

      const token = await user?.getIdToken();

      return new Promise<T>((resolve, reject) => {
        try {
          if (!path) {
            throw new Error('Cannot upload file without a path');
          }

          const data = new FormData();
          const request = new XMLHttpRequest();

          if (meta) {
            Object.entries(meta).forEach(([key, value]) => {
              data.append(key, value);
            });
          }

          data.append('file', file);
          request.open('POST', `${process.env.DRAINIFY_API_URL}${path}`);
          if (token) {
            request.setRequestHeader('Authorization', `Bearer ${token}`);
          }
          request.upload.addEventListener('progress', (event) => {
            setState((s) => ({
              ...s,
              isError: false,
              isLoadingProgress: (event.loaded / event.total) * 100,
              isSuccess: false,
            }));
          });

          request.addEventListener('load', () => {
            setState((s) => ({
              ...s,
              data: request.response,
              isError: false,
              isLoading: false,
              isLoadingProgress: 100,
              isSuccess: true,
            }));
            updateUsage?.();

            resolve(request.response as T);
          });

          request.send(data);
          // } else {
          // throw new Error('File upload failed: Unauthenticated');
          // }
        } catch (error) {
          setState((s) => ({
            ...s,
            uploadError: (error as Error).toString(), // TODO(hhogg): Format API error.
            isError: true,
            isLoading: false,
            isLoadingProgress: 0,
            isSuccess: false,
          }));

          reject(error);
        }
      });
    },
    [user]
  );

  // TODO - this should NOT work offline
  const uploadSignedUrl = useCallback(
    async (signedUrl: SignedPostPolicyV4Output, file: File, meta?: object) => {
      setState((s) => ({
        ...s,
        isError: false,
        isLoading: true,
        isLoadingProgress: 0,
        isSuccess: false,
      }));

      const token = await user?.getIdToken();

      return new Promise<T>((resolve, reject) => {
        try {
          if (!signedUrl.url) {
            throw new Error('Cannot upload file without a path');
          }

          if (token) {
            const data = new FormData();
            const request = new XMLHttpRequest();

            if (meta) {
              Object.entries(meta).forEach(([key, value]) => {
                data.append(key, value);
              });
            }

            request.open('POST', signedUrl.url);

            for (const name of Object.keys(signedUrl.fields)) {
              const value = signedUrl.fields[name];
              data.append(name, value);
            }
            data.append('file', file);

            request.upload.addEventListener('progress', (event) => {
              setState((s) => ({
                ...s,
                isError: false,
                isLoadingProgress: (event.loaded / event.total) * 100,
                isSuccess: false,
              }));
            });

            request.addEventListener('load', () => {
              setState((s) => ({
                ...s,
                data: request.response,
                isError: false,
                isLoading: false,
                isLoadingProgress: 100,
                isSuccess: true,
              }));

              resolve(request.response as T);
            });

            request.send(data);
          } else {
            throw new Error('File upload failed: Unauthenticated');
          }
        } catch (error) {
          setState((s) => ({
            ...s,
            uploadError: (error as Error).toString(), // TODO(hhogg): Format API error.
            isError: true,
            isLoading: false,
            isLoadingProgress: 0,
            isSuccess: false,
          }));

          reject(error);
        }
      });
    },
    [user]
  );

  const update = async (path: string, meta?: object) => {
    setState((s) => ({
      ...s,
      isError: false,
      isLoading: true,
      isSuccess: false,
    }));

    try {
      if (!path) {
        throw new Error('Cannot upload file without a path');
      }

      await fetch(`${process.env.DRAINIFY_API_URL}${path}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(meta),
      });

      setState((s) => ({
        ...s,
        isError: false,
        isLoading: false,
        isSuccess: true,
      }));
    } catch (error) {
      setState((s) => ({
        ...s,
        uploadError: (error as Error).toString(), // TODO(hhogg): Format API error.
        isError: true,
        isLoading: false,
        isSuccess: false,
      }));

      throw new Error('File upload failed: Unauthenticated');
    }
  };

  const remove = async (path: string) => {
    setState((s) => ({
      ...s,
      isError: false,
      isLoading: true,
      isSuccess: false,
    }));

    try {
      if (!path) {
        throw new Error('Cannot upload file without a path');
      }

      await fetch(`${process.env.DRAINIFY_API_URL}${path}`, {
        method: 'DELETE',
        headers: {},
      });

      setState((s) => ({
        ...s,
        isError: false,
        isLoading: false,
        isSuccess: true,
      }));
    } catch (error) {
      setState((s) => ({
        ...s,
        uploadError: (error as Error).toString(), // TODO(hhogg): Format API error.
        isError: true,
        isLoading: false,
        isSuccess: false,
      }));

      throw new Error('File upload failed: Unauthenticated');
    }
  };

  return {
    ...state,
    update,
    upload,
    uploadSignedUrl,
    remove,
    reset,
  };
};

export default useFileUpload;
