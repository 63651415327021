import { Customer, CustomerPostBody } from '@drainify/types';
import React from 'react';
import Address from '../Location/Address';
import { useHasPermission } from '../Permissions/Permissions';
import SummaryCard from '../SummaryCard/SummaryCard';
import SummaryCardSection from '../SummaryCard/SummaryCardSection';
import { useWizardContext } from '../Wizard/Wizard';
import { useCustomerContext } from './CustomerProvider';

type Props = {
  customer?: Customer | CustomerPostBody;
};

const CustomerSummary = ({ customer }: Props) => {
  const { setActiveStepId } = useWizardContext();
  const { edit } = useCustomerContext();
  const hasUpdateProjectPermission = useHasPermission('customers:update');

  const editStep = (stepId: string) => {
    if (!hasUpdateProjectPermission) {
      return undefined;
    }

    return () => {
      setActiveStepId(stepId);
      edit(stepId);
    };
  };

  return (
    <SummaryCard title="Customer">
      <SummaryCardSection
        onEdit={editStep('name')}
        title="Name"
        value={customer?.name}
      >
        {customer?.name}
      </SummaryCardSection>

      <SummaryCardSection
        onEdit={editStep('email')}
        title="Email"
        value={customer?.email}
      >
        {customer?.email}
      </SummaryCardSection>

      <SummaryCardSection
        onEdit={editStep('phoneNumber')}
        title="Phone number"
        value={customer?.phoneNumber}
      >
        {customer?.phoneNumber}
      </SummaryCardSection>

      <SummaryCardSection
        onEdit={editStep('location')}
        title="Location"
        value={customer?.location}
      >
        <Address location={customer?.location} />
      </SummaryCardSection>
    </SummaryCard>
  );
};

export default CustomerSummary;
