import React, {
  createContext,
  useContext,
  useState,
  PropsWithChildren,
} from 'react';

type SearchContextProps = {
  searchString: string;
  setSearchString: (searchString: string) => void;
};

type SearchProviderProps = {};

const SearchContext = createContext<SearchContextProps>({
  searchString: '',
  setSearchString: () => {},
});

export const useSearchContext = () => useContext(SearchContext);

const SearchProvider = (props: PropsWithChildren<SearchProviderProps>) => {
  const [searchString, setSearchString] = useState('');

  const value = {
    searchString,
    setSearchString,
  };

  return <SearchContext.Provider {...props} value={value} />;
};

export default SearchProvider;
