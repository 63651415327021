import { Section } from '@drainify/types';
import {
  getMaterialSchema,
  getShapeSchema,
  getUsesSchema,
} from '@drainify/utils';
import { Box, BoxProps, Text } from 'preshape';
import React from 'react';
import SymbolTemplate from '../../Symbol/SymbolTemplate';
import { useLanguageContext } from '../../Usage/LanguageProvider';

type Attribute = Extract<
  keyof Section['attributes'],
  'material' | 'shape' | 'use'
>;

type Props<T extends Attribute> = BoxProps & {
  attribute: T;
  code?: Section['attributes'][T];
};

const schemaGetters = {
  material: getMaterialSchema,
  shape: getShapeSchema,
  use: getUsesSchema,
};

const SectionAttributeView = <T extends Attribute>({
  attribute,
  code,
  ...props
}: Props<T>) => {
  const schemaLookup = schemaGetters[attribute];
  const schemaEntry = code && schemaLookup(code);
  const { language } = useLanguageContext();

  return (
    <Box {...props} alignChildrenVertical="middle" flex="horizontal" gap="x2">
      <Box basis="0" grow>
        <Text size="x3" strong>
          {schemaEntry?.name[language]}
        </Text>
      </Box>

      <SymbolTemplate>{code?.replace(/_.*/, '')} </SymbolTemplate>
    </Box>
  );
};

export default SectionAttributeView;
