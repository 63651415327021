import { Address, Location } from '@drainify/types';
import { Text, Input, InputLabel, TextProps } from 'preshape';
import React, { ChangeEvent } from 'react';

type Props = TextProps & {
  location: Location;
  name?: string;
  onChange?: (location: Location) => void;
};

const AddressInput = ({ location, name, onChange, ...rest }: Props) => {
  const handleChange =
    (key: keyof Address) => (event: ChangeEvent<HTMLInputElement>) => {
      onChange?.({
        ...location,
        address: {
          ...location.address,
          [key]: event.target.value,
        },
      });
    };

  return (
    <Text {...rest}>
      <InputLabel label="Company (optional)" margin="x3">
        <Input
          name={`${name}.address.company`}
          onChange={handleChange('company')}
          value={location.address.company || ''}
        />
      </InputLabel>

      <InputLabel label="Street number" margin="x3">
        <Input
          name={`${name}.address.streetNumber`}
          onChange={handleChange('streetNumber')}
          value={location.address.streetNumber || ''}
        />
      </InputLabel>

      <InputLabel label="Street name" margin="x3">
        <Input
          name={`${name}.address.street`}
          onChange={handleChange('street')}
          value={location.address.street || ''}
        />
      </InputLabel>

      <InputLabel label="Town/City" margin="x3">
        <Input
          name={`${name}.address.town`}
          onChange={handleChange('town')}
          value={location.address.town || ''}
        />
      </InputLabel>

      <InputLabel label="County/State" margin="x3">
        <Input
          name={`${name}.address.county`}
          onChange={handleChange('county')}
          value={location.address.county || ''}
        />
      </InputLabel>

      <InputLabel label="Postcode/ZipCode" margin="x3">
        <Input
          name={`${name}.address.postcode`}
          onChange={handleChange('postcode')}
          value={location.address.postcode || ''}
        />
      </InputLabel>

      <InputLabel label="Country" margin="x3">
        <Input
          name={`${name}.address.country`}
          onChange={handleChange('country')}
          value={location.address.country || ''}
        />
      </InputLabel>
    </Text>
  );
};

export default AddressInput;
