import { ProjectType } from '@drainify/types';
import {
  ObservationScoreKey,
  getGradeLetter,
  getColorFromGrade,
  getGradeNumber,
} from '@drainify/utils';
import { Label, Text } from 'preshape';
import React from 'react';
import { useProjectContext } from '../Project/ProjectProvider';

type Props = {
  code: ObservationScoreKey;
  score?: number;
};

const GradeTitleMap: Record<ObservationScoreKey, string> = {
  SRV: 'Service',
  STR: 'Structural',
};

const ObservationScoreBadge = ({ code, score }: Props) => {
  const { activeBooking } = useProjectContext();
  const gradeLetter = getGradeLetter(code, score);
  const gradeNumber = getGradeNumber(score || 0, code);
  const gradeColor = getColorFromGrade(gradeLetter);
  const title = GradeTitleMap[code];
  const grade =
    activeBooking && activeBooking?.projectType === ProjectType.MSCC5_DOMESTIC
      ? gradeLetter
      : gradeNumber;

  return (
    <Label
      flex="horizontal"
      gap="x3"
      size="x1"
      textColor="light-shade-1"
      style={{
        backgroundColor: gradeColor,
        opacity: gradeLetter === '-' ? 0.3 : 1,
      }}
    >
      <Text grow uppercase>
        {title}
      </Text>

      <Text
        backgroundColor="dark-shade-1"
        borderRadius="x3"
        paddingHorizontal="x1"
        textColor="light-shade-1"
      >
        {grade}
      </Text>
    </Label>
  );
};

export default ObservationScoreBadge;
