import { Box, Motion } from 'preshape';
import React, { useContext } from 'react';
import { WizardContext } from './Wizard';

const WizardStepProgress = () => {
  const { activeStepId, stepIds } = useContext(WizardContext);

  return (
    <Box
      alignChildrenHorizontal="middle"
      container
      flex="horizontal"
      gap="x1"
      margin="x4"
      maxWidth='400px'
    >
      {stepIds.map((step) => (
        <Motion
          animate={{
            opacity: 1,
            scale: step === activeStepId ? 1 : 0.25,
          }}
          backgroundColor="accent-shade-4"
          key={step}
          initial={{
            height: 12,
            borderRadius: '50%',
            opacity: 0,
            scale: 0,
            width: 12,
          }}
        />
      ))}
    </Box>
  );
};

export default WizardStepProgress;
