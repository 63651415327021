import {
  Box,
  Button,
  Icons,
  Placement,
  PlacementArrow,
  PlacementContent,
  PlacementManager,
  PlacementReference,
  Text,
} from 'preshape';
import { PlacementManagerContext } from 'preshape/lib/cjs/Placement/PlacementManager';
import React from 'react';
import DatePicker from '../../components/DatePicker/DatePicker';
import { useJobSearchContext } from '../../pages/desktop/Pages/AdminPage';

const JobAdminSearchOptionsDate = () => {
  const { date } = useJobSearchContext();
  return (
    <Box flex="horizontal">
      <PlacementManager trigger="click">
        <PlacementReference>
          {(props) => (
            <Box>
              <Button
                {...props}
                variant="tertiary"
                flex="horizontal"
                gap="x1"
                borderSize="x1"
                active={date !== undefined}
              >
                <Icons.Calendar size="1rem" />
                <Text size="x1">{date || 'All'}</Text>
              </Button>
            </Box>
          )}
        </PlacementReference>

        <Placement placement="bottom" zIndex={1}>
          <PlacementArrow backgroundColor="background-shade-3" />
          <PlacementContent
            backgroundColor="background-shade-1"
            borderColor="background-shade-3"
            borderRadius="x3"
            borderSize="x2"
            overflow="hidden"
            textColor="text-shade-1"
          >
            <WrapWithClosure />
            <Box padding="x2" flex="horizontal" alignChildren="middle">
              <WrapWithClosureClear />
            </Box>
          </PlacementContent>
        </Placement>
      </PlacementManager>
    </Box>
  );
};

const WrapWithClosureClear = () => {
  const { date, setDate } = useJobSearchContext();
  const { onClose } = React.useContext(PlacementManagerContext);
  return (
    <Button
      disabled={date === undefined}
      onClick={() => {
        onClose?.();
        setDate(undefined);
      }}
    >
      Clear
    </Button>
  );
};

const WrapWithClosure = () => {
  const { date, setDate } = useJobSearchContext();
  const { onClose } = React.useContext(PlacementManagerContext);

  return (
    <DatePicker
      padding="x3"
      onChange={(e) => {
        onClose?.();
        setDate(e);
      }}
      startDate={date}
      withSelectablePast
    />
  );
};

export default JobAdminSearchOptionsDate;
