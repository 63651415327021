import {
  Booking,
  ResponseError,
  ResponseMultipleEntities,
} from '@drainify/types';
import { JobManagementSearch } from '@drainify/types/src/JobManagementSearch';
import { useQuery } from 'react-query';
import useFetch from '../components/Api/useFetch';

const useBookingsBySearch = (
  body: JobManagementSearch,
  offset: number = 0,
  pageSize: number = 5
) => {
  const fetch = useFetch();
  const key = ['job-management-search', fetch, offset, body];

  const query = useQuery<ResponseMultipleEntities<Booking>, ResponseError>(
    key,
    () =>
      fetch(
        '/job-management/search',
        { method: 'PUT', body },
        {
          offset: String(offset),
          pageSize: String(pageSize),
          sortField: 'createdAt',
          sortDirection: '1',
        }
      )
  );

  return { query };
};

export default useBookingsBySearch;
