import React from 'react';

type Props = {
  height: number;
  fill?: string;
};

const ratio = 130 / 160;

const Droplet = ({ height = 100, fill = 'currentColor' }: Props) => {
  return (
    <svg
      id="droplet"
      fill={fill}
      width={height * ratio}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 130 160"
    >
      <path
        className="cls-1"
        d="m62.07,9.42c8.73,11.11,17.02,22.54,24.65,34.44,6.1,9.51,11.79,19.26,16.38,29.61,2.46,5.56,4.56,11.25,5.68,17.26.27,1.46.44,2.95.65,4.43v4.84c-.04.23-.11.46-.12.69-.23,3.88-.88,7.68-1.95,11.41-7.75,27.02-34.34,43.79-62.04,38.91-15.4-2.71-27.4-10.72-36.08-23.52C4,119.75.95,110.74.17,101.44c-.22-2.64-.26-5.28.06-7.92.54-4.5,1.76-8.83,3.3-13.07,3.29-9.09,7.76-17.61,12.64-25.92,9.29-15.83,19.93-30.73,31.25-45.16,1.19-1.52,7.14-9.36,7.14-9.36,0,0,6.08,7.59,7.52,9.42Zm-9.04,126.06c.28,0,.55,0,.83,0,3.46-.05,5.94-3.33,5.05-6.67-.61-2.28-2.67-3.86-5.14-3.89-3.65-.04-7.19-.66-10.49-2.22-10.16-4.82-15.46-12.9-15.87-24.15-.11-3.05-2.39-5.37-5.35-5.34-2.94.03-5.31,2.42-5.19,5.46.09,2.36.26,4.73.7,7.04,3.19,16.87,18.24,29.46,35.47,29.76Z"
      />
    </svg>
  );
};

export default Droplet;
