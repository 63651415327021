import { WithTracking } from './Api';
import { Org } from './Org';
import { SubscriptionLineItem } from './SubscriptionLineItem';

export enum AccountState {
  TRIAL = 'trial',
  ACTIVE = 'active',
  OVERDUE = 'overdue',
  CANCELLED = 'cancelled',
}

export interface Usage extends WithTracking {
  org: Org;
  usedStorage: number;
  totalStorage: number;
  additionalStorage: number;
  buddyTokens: number;
  planCredits: number;
  additionalCredits: number;
  tier: string;
  resetDate: number;
  state: AccountState;
  resolutionUrl?: string;
  attempt?: number;
  upcomingInvoiceUrl?: string;
  lineItems?: SubscriptionLineItem[];
  amountDue?: number;
  endingBalance?: number;
  last4?: string;
  brand?: string;
  expMonth?: number;
  expYear?: number;
}
