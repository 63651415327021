import React from 'react';
import DataTable, { DataTableProps } from '../DataTable/DataTable';
import DataTableGroup from '../DataTable/DataTableGroup';
import DataTableItem from '../DataTable/DataTableItem';
import DataTableMessage from '../DataTable/DataTableMessage';
import InfiniteScroll from '../InfiniteScroll/InfiniteScroll';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import { useReportContext } from '../Report/ReportProvider';
import SearchResult from '../Search/SearchResult';
import ObservationView from './ObservationView';

type Props = DataTableProps & {
  disabledUids?: string[];
  onItemClick?: (observationUid: string) => void;
  selectedUids?: string[];
  inspectionUid?: string;
  shouldValidate?: boolean;
};

const ObservationsList = ({
  disabledUids,
  onItemClick,
  selectedUids,
  inspectionUid,
  shouldValidate = false,
  ...rest
}: Props) => {
  const { report } = useReportContext();
  const { reportEditor } = useReportEditorContext();
  const observations = reportEditor.getInspectionObservations(inspectionUid);
  const [observationNumber, setObservationNumber] = React.useState(5);

  if (!report) {
    return (
      <DataTable {...rest}>
        <DataTableGroup>
          {Array.from({ length: 5 }).map((_, index) => (
            <DataTableItem key={index}>
              <ObservationView reverse />
            </DataTableItem>
          ))}
        </DataTableGroup>
      </DataTable>
    );
  }

  if (!observations.length) {
    return (
      <DataTable {...rest}>
        <DataTableGroup>
          <DataTableItem>
            <DataTableMessage>
              There are no observations to show here
            </DataTableMessage>
          </DataTableItem>
        </DataTableGroup>
      </DataTable>
    );
  }

  return (
    <DataTable {...rest}>
      <DataTableGroup>
        {observations.slice(0, observationNumber).map((observation) => (
          <SearchResult
            key={observation.uid}
            keywords={reportEditor.getObservationKeywords(observation.uid)}
          >
            <DataTableItem
              disabled={disabledUids?.includes(observation.uid)}
              onClick={onItemClick && (() => onItemClick(observation.uid))}
              selected={selectedUids?.includes(observation.uid)}
            >
              <ObservationView observation={observation} validate={shouldValidate} />
            </DataTableItem>
          </SearchResult>
        ))}
        {observationNumber < observations.length && (
          <InfiniteScroll
            onMore={() => {
              setObservationNumber(observationNumber + 5);
            }}
          />
        )}
      </DataTableGroup>
    </DataTable>
  );
};

export default ObservationsList;
