import { NodeCode } from '@drainify/types';
import React from 'react';
import NodesIcon from '../IconsMisc/NodesIcon';

type Props = {
  code?: NodeCode;
  size?: string;
};

const NodeCodeIcon = ({ size = '1rem' }: Props) => {
  return <NodesIcon size={size} />;
};

export default NodeCodeIcon;
