import {
  ResponseError,
  ResponseMultipleEntities,
  SubscriptionTierDetails,
} from '@drainify/types';
import { useQuery } from 'react-query';
import useFetch from '../components/Api/useFetch';

const useSubscriptionsDetails = () => {
  const fetch = useFetch();
  const key = ['subscriptions', fetch];

  const query = useQuery<
    ResponseMultipleEntities<SubscriptionTierDetails>,
    ResponseError
  >(key, () => fetch('/subscriptions', { method: 'GET' }), {});

  return { query };
};

export default useSubscriptionsDetails;
