import groupBy from 'lodash.groupby';
import { Box, Icons, Modal, ModalBody, ModalHeader, Text } from 'preshape';
import React from 'react';
import ReactPlayer from 'react-player';
import ClassroomIcon from '../../../components/IconsMisc/ClassroomIcon';
import { desktopClassroomVideos } from '../../settingsMenu';
import PageMenu from '../Layout/PageMenu';
import PageMenuItem from '../Layout/PageMenuItem';

const ClassroomPage = () => {
  const [activeVideo, setActiveVideo] =
    React.useState<{ name: string; url: string }>();
  const groupedVideoes = groupBy(
    desktopClassroomVideos,
    ({ category }) => category
  );

  return (
    <>
      <Box margin="x16" flex="vertical" gap="x4">
        <Box flex='horizontal' alignChildren='middle'>
          <Text textColor='accent-shade-5'>
            <ClassroomIcon size='5rem'/>
          </Text>
        </Box>
        {Object.entries(groupedVideoes)
          .sort((a, b) => (a[0] < b[0] ? -1 : 1))
          .map(([category, videoes]) => (
            <Box key={category} flex="vertical" margin="x3">
              <Text strong size="x6">
                {category}
              </Text>
              <PageMenu>
                {videoes.map(({ name, url }) => (
                  <Box onClick={() => setActiveVideo({ name, url })} key={url}>
                    <PageMenuItem
                      description={name}
                      icon={<Icons.Activity />}
                      title={name}
                    />
                  </Box>
                ))}
              </PageMenu>
            </Box>
          ))}
      </Box>
      <Modal
        visible={activeVideo !== undefined}
        onClose={() => setActiveVideo(undefined)}
      >
        <ModalHeader>{activeVideo?.name}</ModalHeader>
        <ModalBody width={'70vw'} height={'70vh'}>
          <ReactPlayer url={activeVideo?.url} width="auto" height="100%" />
        </ModalBody>
      </Modal>
    </>
  );
};

export default ClassroomPage;
