import { UnitSystem } from '@drainify/types';
import { Box, Text, Toggle } from 'preshape';
import React, { useContext } from 'react';
import { RootContext } from '../Bootstrap/Bootstrap';

const UnitSystemToggle = () => {
  const { onChangeUnitSystem, unitSystem } = useContext(RootContext);

  return (
    <Box
      alignChildrenVertical="middle"
      flex="horizontal"
      gap="x2"
      onClick={() =>
        onChangeUnitSystem(
          unitSystem === UnitSystem.METRIC
            ? UnitSystem.IMPERIAL
            : UnitSystem.METRIC
        )
      }
    >
      <Box grow>
        <Text align="start" size="x2">
          Imperial Unit System
        </Text>
      </Box>

      <Box>
        <Toggle
          onChange={(value) =>
            onChangeUnitSystem(value ? UnitSystem.IMPERIAL : UnitSystem.METRIC)
          }
          onClick={(event) => event.stopPropagation()}
          size={24}
          value={unitSystem === UnitSystem.IMPERIAL}
        />
      </Box>
    </Box>
  );
};

export default UnitSystemToggle;
