import { Box, useFormContext } from 'preshape';
import React, { useContext } from 'react';
import { WizardContext } from '../Wizard';
import WizardControlsOnStepFromSummary from './WizardControlsOnStepFromSummary';
import WizardControlsOnStepTraverseSteps from './WizardControlsOnStepTraverseSteps';
import WizardControlsOnSummaryWithChanges from './WizardControlsOnSummaryWithChanges';
import WizardControlsOnSummaryWithNoChanges from './WizardControlsOnSummaryWithNoChanges';

const WizardControls = () => {
  const { activeStepId, flow, hasVisitedSummary } = useContext(WizardContext);
  const {
    form: { getIsDirty },
  } = useFormContext();

  const isDirty = getIsDirty();
  const isReviewStep = activeStepId === 'review';

  return (
    <Box>
      {/* When on a wizard step in the create flow for the first time */}
      {!isReviewStep && flow === 'create' && !hasVisitedSummary && (
        <WizardControlsOnStepTraverseSteps />
      )}

      {/* When on a wizard step in the create flow when editing */}
      {!isReviewStep && flow === 'create' && hasVisitedSummary && (
        <WizardControlsOnStepFromSummary />
      )}

      {/* When on a wizard step in the update flow without starting on the summary page */}
      {!isReviewStep && flow === 'update' && !hasVisitedSummary && (
        <WizardControlsOnSummaryWithChanges />
      )}

      {/* When on a wizard step in the update flow having come from the summary page */}
      {!isReviewStep && flow === 'update' && hasVisitedSummary && (
        <WizardControlsOnStepFromSummary />
      )}

      {/* When on the summary page with changes */}
      {isReviewStep && isDirty && <WizardControlsOnSummaryWithChanges />}

      {/* When on the summary page without changes */}
      {isReviewStep && !isDirty && <WizardControlsOnSummaryWithNoChanges />}
    </Box>
  );
};

export default WizardControls;
