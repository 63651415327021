import {
  Attributes,
  Box,
  Button,
  ButtonProps,
  Icons,
  Label,
  Text,
} from 'preshape';
import React from 'react';
import { useMatch } from 'react-router-dom';

export type PageMenuItemProps = ButtonProps & {
  count?: number;
  exact?: boolean;
  icon?: JSX.Element;
  to?: string;
};

const PageMenuItem = ({
  children,
  count,
  exact,
  icon,
  size = 'x3',
  to,
  ...rest
}: Attributes<HTMLButtonElement, PageMenuItemProps>) => {
  const pathName = to && (exact ? to : `${to}/*`);
  const active = pathName && useMatch(pathName);

  return (
    <Box
      borderColor="background-shade-3"
      className="PageMenu__item"
      flex="horizontal"
    >
      <Button
        {...rest}
        textColorActive={'text-shade-1'}
        backgroundColorActive="background-shade-3"
        active={!!active}
        alignChildrenVertical="middle"
        borderTop
        gap="x2"
        grow
        size={size}
        to={to}
        uppercase={false}
        variant="tertiary"
      >
        <Box alignChildrenVertical="middle" flex="horizontal" gap="x2" grow>
          {icon}
          <Text align="start" grow>
            {children}
          </Text>

          {count !== undefined && <Label size="x1">{count}</Label>}

          {to && <Icons.ChevronRight />}
        </Box>
      </Button>
    </Box>
  );
};

export default PageMenuItem;
