import { JobManagementSearchType, User } from '@drainify/types';
import { BookingStateCode, getAddressStringInformal } from '@drainify/utils';
import { DateTime } from 'luxon';
import {
  Modal,
  ModalBody,
  Icons,
  ModalHeader,
  Table,
  TableCell,
  TableRow,
  TableHeader,
  TableHeaderCell,
  Box,
  ModalFooter,
  TableBody,
  Button,
  Label,
  Text,
} from 'preshape';
import React, { createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import BookingStatus from '../../../components/Booking/BookingStatus';
import JobAdminSearchOptions from '../../../components/JobAdmin/JobAdminSearchOptions';
import { useHasPermission } from '../../../components/Permissions/Permissions';
import PlaceholderBlock from '../../../components/Placeholders/PlaceholderBlock';
import PlaceholderGradientAnimation from '../../../components/Placeholders/PlaceholderGradientAnimation';
import Spinner from '../../../components/Spinner/Spinner';
import useBookingsBySearch from '../../../hooks/useBookingsBySearch';

type Props = {
  onClose: () => void;
};

type Context = {
  date?: string;
  setDate: (s?: string) => void;
  state: BookingStateCode | undefined;
  setState: (b: BookingStateCode | undefined) => void;
  engineer?: User;
  setEngineer: (u: User | undefined) => void;
  searchString?: string;
  setSearchString: (s: string) => void;
  setSearchType: (s: JobManagementSearchType) => void;
  searchType: JobManagementSearchType;
};

const JobSearchContext = createContext<Context>({
  date: undefined,
  setDate: () => {},
  state: undefined,
  setState: () => {},
  engineer: undefined,
  setEngineer: () => {},
  searchString: undefined,
  setSearchString: () => {},
  setSearchType: () => {},
  searchType: JobManagementSearchType.POSTCODE,
});

export const useJobSearchContext = () => useContext(JobSearchContext);

const PAGE_SIZE = 10;

const AdminPage = ({ onClose }: Props) => {
  const hasPermission = useHasPermission('admin-page:view');

  const [date, setDate] = React.useState<string>();
  const [state, setState] = React.useState<BookingStateCode>();
  const [engineer, setEngineer] = React.useState<User>();
  const [searchString, setSearchString] = React.useState<string>();
  const [searchStringDebounced, setSearchStringDebounced] =
    React.useState<string>();
  const [searchType, setSearchType] = React.useState<JobManagementSearchType>(
    JobManagementSearchType.POSTCODE
  );
  const [offset, setOffset] = React.useState<number>(0);
  const [debounceTimeout, setDebounceTimeout] =
    React.useState<NodeJS.Timeout | null>(null);

  const handleSelectEngineer = (u: User | undefined) => {
    if (u === engineer) {
      setEngineer(undefined);
    } else {
      setEngineer(u);
    }
    setOffset(0);
  };

  const handleSetState = (state?: BookingStateCode) => {
    setState(state);
    setOffset(0);
  };

  const handleSetSearchtype = (searchType: JobManagementSearchType) => {
    setSearchType(searchType);
    setOffset(0);
  };

  const handleSetDate = (date?: string) => {
    setDate(date);
    setOffset(0);
  };

  const handleSetSearchString = (newSearchString?: string) => {
    setSearchString(newSearchString);

    const delay = 500;

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const newTimeout = setTimeout(() => {
      setSearchStringDebounced(newSearchString);
      setOffset(0);
    }, delay);
    setDebounceTimeout(newTimeout);
  };

  React.useEffect(() => {
    return () => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
    };
  }, [debounceTimeout]);

  const { query } = useBookingsBySearch(
    {
      date,
      state,
      engineer,
      searchType,
      searchString: searchStringDebounced,
    },
    offset,
    PAGE_SIZE
  );

  const context: Context = {
    date,
    state,
    engineer,
    searchString,
    searchType,
    setDate: handleSetDate,
    setState: handleSetState,
    setEngineer: handleSelectEngineer,
    setSearchString: handleSetSearchString,
    setSearchType: handleSetSearchtype,
  };
  const navigate = useNavigate();

  if (!hasPermission) {
    return null;
  }

  return (
    <Modal visible={true} onClose={onClose}>
      <ModalHeader>Job Management</ModalHeader>
      <ModalBody height="90vh" width="90vw">
        <Box>
          <JobSearchContext.Provider value={context}>
            <JobAdminSearchOptions />
          </JobSearchContext.Provider>
        </Box>
        <Table>
          <TableHeader>
            <TableHeaderCell style={{ borderStyle: 'none' }} colSpan={2}>
              <Box flex="horizontal" alignChildrenHorizontal="middle">
                <Icons.Hash />
              </Box>
            </TableHeaderCell>
            <TableHeaderCell style={{ borderStyle: 'none' }}>
              <Box flex="horizontal" alignChildrenHorizontal="middle">
                <Icons.Activity />
              </Box>
            </TableHeaderCell>
            <TableHeaderCell style={{ borderStyle: 'none' }}>
              <Box flex="horizontal" alignChildrenHorizontal="middle">
                <Icons.Calendar />
              </Box>
            </TableHeaderCell>
            <TableHeaderCell style={{ borderStyle: 'none' }}>
              <Box flex="horizontal" alignChildrenHorizontal="middle">
                <Icons.User />
                <Text size="x2"> Cus</Text>
              </Box>
            </TableHeaderCell>
            <TableHeaderCell style={{ borderStyle: 'none' }} align="middle">
              <Box flex="horizontal" alignChildrenHorizontal="middle">
                <Icons.User />
                <Text size="x2"> Eng </Text>
              </Box>
            </TableHeaderCell>
            <TableHeaderCell style={{ borderStyle: 'none' }}>
              <Box flex="horizontal" alignChildrenHorizontal="middle">
                <Icons.MapPin />
              </Box>
            </TableHeaderCell>
          </TableHeader>
          <TableBody>
            {query.isLoading && (
              <>
                <TableRow>
                  {' '}
                  <TableCell colSpan={7}>
                    {' '}
                    <PlaceholderGradientAnimation animating>
                      {' '}
                      <PlaceholderBlock height="2rem" />{' '}
                    </PlaceholderGradientAnimation>{' '}
                  </TableCell>{' '}
                </TableRow>
                <TableRow>
                  {' '}
                  <TableCell colSpan={7}>
                    {' '}
                    <PlaceholderGradientAnimation animating>
                      {' '}
                      <PlaceholderBlock height="2rem" />{' '}
                    </PlaceholderGradientAnimation>{' '}
                  </TableCell>{' '}
                </TableRow>
                <TableRow>
                  {' '}
                  <TableCell colSpan={7}>
                    {' '}
                    <PlaceholderGradientAnimation animating>
                      {' '}
                      <PlaceholderBlock height="2rem" />{' '}
                    </PlaceholderGradientAnimation>{' '}
                  </TableCell>{' '}
                </TableRow>
                <TableRow>
                  {' '}
                  <TableCell colSpan={7}>
                    {' '}
                    <PlaceholderGradientAnimation animating>
                      {' '}
                      <PlaceholderBlock height="2rem" />{' '}
                    </PlaceholderGradientAnimation>{' '}
                  </TableCell>{' '}
                </TableRow>
                <TableRow>
                  {' '}
                  <TableCell colSpan={7}>
                    {' '}
                    <PlaceholderGradientAnimation animating>
                      {' '}
                      <PlaceholderBlock height="2rem" />{' '}
                    </PlaceholderGradientAnimation>{' '}
                  </TableCell>{' '}
                </TableRow>
              </>
            )}
            {query.data?.data.map((e) => (
              <TableRow key={e.uid}>
                <TableCell
                  onClick={() => {
                    navigate(
                      `/projects/${e.project.uid}?activeBookingId=${e.uid}`
                    );
                    onClose();
                  }}
                >
                  <Icons.ExternalLink />
                </TableCell>
                <TableCell align="middle">
                  <Label>{e.jobId}</Label>
                </TableCell>
                <TableCell align="middle">
                  <BookingStatus booking={e} />
                </TableCell>
                <TableCell size="x2" align="middle">
                  {DateTime.fromJSDate(
                    new Date(e.bookedForDate)
                  ).toLocaleString(DateTime.DATE_FULL)}
                </TableCell>
                <TableCell align="middle" size="x2">
                  {e.customerName}
                </TableCell>
                <TableCell align="middle" size="x2">
                  {e.assignedTo?.map((e) => e.fullName).join(', ')}
                </TableCell>
                <TableCell size="x2" align="middle">
                  {getAddressStringInformal(e.location!)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {query.data?.data.length === 0 && (
          <Box flex="horizontal" alignChildrenHorizontal="middle">
            <Box
              backgroundColor="background-shade-3"
              padding="x3"
              borderRadius="10px"
            >
              <Text strong size="x2" textColor="text-shade-4">
                {' '}
                No data found. Please refine search{' '}
              </Text>
            </Box>
          </Box>
        )}
      </ModalBody>
      <ModalFooter>
        <Box flex="horizontal" alignChildrenHorizontal="middle">
          {query.isLoading ? (
            <Spinner />
          ) : (
            <>
              <Button
                size="x1"
                variant="tertiary"
                color="accent"
                onClick={() => setOffset(offset - PAGE_SIZE)}
                disabled={offset === 0}
              >
                <Icons.ChevronLeft />
              </Button>
              <Box flex="vertical" alignChildrenHorizontal="middle">
                <Text>
                  {offset / PAGE_SIZE + 1}/
                  {Math.ceil(
                    (query.data?.meta?.total || PAGE_SIZE) / PAGE_SIZE
                  )}{' '}
                </Text>
                <Text size="x1"> {query.data?.meta?.total} found </Text>
              </Box>
              <Button
                variant="tertiary"
                size="x1"
                color="accent"
                onClick={() => setOffset(offset + PAGE_SIZE)}
                disabled={query.data?.meta?.total! <= offset + PAGE_SIZE}
              >
                <Icons.ChevronRight />
              </Button>
            </>
          )}
        </Box>
      </ModalFooter>
    </Modal>
  );
};

export default AdminPage;
