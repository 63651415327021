import React, { createContext, PropsWithChildren, useContext } from 'react';
import getAggregatedQueryState, {
  Query,
  QueryState,
} from '../../utils/getAggregatedQueryState';
import { useProjectContext } from '../Project/ProjectProvider';

type PageContextProps = {
  backToLink?: string;
  backToText?: string;
  query?: Query | Query[];
  title?: string;
};

type PageContextCPProps = QueryState & PageContextProps;

const PageContextCP = createContext<PageContextCPProps>({
  hasData: true,
  isLoading: false,
  isLoadingError: false,
  isRefetching: false,
  title: undefined,
});

export const usePageContext = () => useContext(PageContextCP);

const PageContext = ({
  backToLink,
  backToText,
  query,
  title,
  ...rest
}: PropsWithChildren<PageContextProps>) => {
  const parentValue = usePageContext();
  const { activeBooking } = useProjectContext();
  const param = `?activeBookingId=${activeBooking?.uid}`;
  const state = getAggregatedQueryState(parentValue.query, query);

  return (
    <PageContextCP.Provider
      {...rest}
      value={{
        ...state,
        backToLink: backToLink || parentValue.backToLink + param,
        backToText: backToText || parentValue.backToText,
        title: title || parentValue.title,
        query,
      }}
    ></PageContextCP.Provider>
  );
};

export default PageContext;
