import { Measurement, MeasurementPostBody } from '@drainify/types';
import { ReportError } from '@drainify/utils';
import { DateTime } from 'luxon';
import { useForm, FormValidateFn } from 'preshape';
import { useCallback, useMemo } from 'react';
import { useReportEditorContext } from '../../components/Report/ReportEditorProvider';

const useMeasurementForm = (
  measurement?: Measurement | MeasurementPostBody,
  jobId?: string
) => {
  const { reportEditor } = useReportEditorContext();
  const initial = useMemo<Measurement | MeasurementPostBody>(
    () =>
      measurement || {
        jobId: jobId,
        points: [],
        createdAt: DateTime.now().toISO(),
      },
    [measurement, jobId]
  );

  const validate = useCallback<
    FormValidateFn<Measurement | MeasurementPostBody, ReportError>
  >(
    (measurement, setError) => {
      setError(reportEditor.validateMeasurement(measurement));
    },
    [reportEditor]
  );

  return useForm({
    initial,
    validate,
  });
};

export default useMeasurementForm;
