import { Attributes, Button, ButtonProps, Icons, Text } from 'preshape';
import React, { PropsWithChildren, useContext } from 'react';
import { useHasPermission } from '../Permissions/Permissions';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import { WizardContext } from '../Wizard/Wizard';

type Props = ButtonProps & {
  onCreate?: (nodeUid: string) => void;
  nextStep?: string;
};

const NodeCreateButton = ({
  onCreate,
  nextStep,
  ...rest
}: Attributes<HTMLButtonElement, PropsWithChildren<Props>>) => {
  const { createNewNode } = useReportEditorContext();
  const { activeBooking } = useProjectContext();
  const hasUpdateProjectPermission = useHasPermission('projects:update');
  const { setActiveStepId } = useContext(WizardContext);

  if (!hasUpdateProjectPermission) {
    return null;
  }

  return (
    <Button
      variant="tertiary"
      disabled={!!activeBooking?.completedAt}
      {...rest}
      color="accent"
      gap="x2"
      onClick={() => {
        createNewNode(onCreate);
        if (nextStep) {
          setActiveStepId(nextStep);
        }
      }}
      type="button"
    >
      <Text>New node</Text>
      <Icons.Plus size="1rem" />
    </Button>
  );
};

export default NodeCreateButton;
