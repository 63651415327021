import { ProductType } from '@drainify/types';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import {
  Alert,
  Box,
  Button,
  Buttons,
  CheckBox,
  Icons,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from 'preshape';
import React, { useContext, useEffect } from 'react';
import useSubscriptionsDetails from '../../hooks/useSubscriptionsDetails';
import { stripePaymentWithFallback } from '../../utils/payments';
import AsyncButton from '../AsyncButton/AsyncButton';
import { AuthenticationContext } from '../Authentication/Authenticate';
import { useOrgContext } from '../Org/OrgProvider';
import { useUsageContext } from './UsageProvider';

const UserAddBuddyTokens = () => {
  const [visible, setVisible] = React.useState(false);
  const [confirmationVisible, setConfirmationVisible] = React.useState(false);
  const stripe = useStripe();
  const { refetch: refetchUsage, usage } = useUsageContext();
  const { user } = useContext(AuthenticationContext);
  const [error, setError] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);
  const { org } = useOrgContext();
  const [tokenProduct, setTokenProduct] = React.useState<string>();
  const { query: subscriptionQuery } = useSubscriptionsDetails();
  const userSubscription = subscriptionQuery.data?.data.find(
    (e) => e.tier === usage?.tier
  );

  const elements = useElements();

  useEffect(() => {
    if (!visible) {
      setTokenProduct(undefined);
    }
  }, [visible]);

  if (!org || !stripe) {
    return null;
  }

  const handleSave = async () => {
    const payload = { tokenProduct };
    await stripePaymentWithFallback({
      onError: setError,
      onLoading: setLoading,
      onSuccess: async () => {
        setConfirmationVisible(true);
        setVisible(false);
        refetchUsage?.();
        setLoading(false);
      },
      payload,
      stripe,
      elements,
      user,
      endpoint: '/payment/token',
      loading,
      cardless: true,
    });
  };

  return (
    <>
      <Button
        onClick={() => setVisible(true)}
        color="accent"
        variant="tertiary"
        size="x1"
      >
        AI Meterage
      </Button>
      {visible && (
        <Modal
          animation="FadeSlideUp"
          margin="x4"
          onClose={() => setVisible(false)}
          overlayBackgroundCloseOnClick={false}
          visible={visible}
        >
          <ModalHeader>Add AI Meterage</ModalHeader>

          <ModalBody flex="vertical" alignChildrenVertical="middle" gap="x3">
            <Text size="x6" strong>
              AI Meterage
            </Text>
            <Box
              backgroundColor="background-shade-3"
              flex="vertical"
              gap="x2"
              padding="x3"
            >
              {userSubscription?.additionalProducts
                .filter((e) => e.type === ProductType.BUDDY_TOKEN)
                .map((e) => (
                  <CheckBox
                    key={e.stripeId}
                    checked={e.stripeId === tokenProduct}
                    onChange={() => setTokenProduct(e.stripeId)}
                  >
                    <Text strong>
                      {' '}
                      {e.displayName}- £{e.price / 100}{' '}
                    </Text>
                    <Text weak size="x1">
                      £X per meter
                    </Text>
                  </CheckBox>
                ))}
            </Box>
            <Alert color="accent" padding="x3" backgroundColor="accent-shade-2">
              <Text size="x1">Buddy tokens will auto be used</Text>
            </Alert>
          </ModalBody>
          <ModalFooter>
            <Buttons>
              <AsyncButton
                color="positive"
                variant="primary"
                disabled={tokenProduct === undefined}
                grow
                isError={error !== undefined}
                isLoading={loading}
                onClick={async () => {
                  setLoading(true);
                  handleSave();
                }}
              >
                <Icons.CreditCard />
                Confirm purchase
              </AsyncButton>
            </Buttons>
          </ModalFooter>
        </Modal>
      )}
      {confirmationVisible && (
        <Modal
          onClose={() => {
            setConfirmationVisible(false);
          }}
          visible
        >
          <ModalHeader>Success</ModalHeader>
          <ModalBody>
            <Box flex="vertical">
              <Box flex="vertical" gap="x3">
                <Box flex="horizontal" alignChildrenHorizontal="middle">
                  <Text textColor="positive-shade-5" align="middle">
                    <Icons.CheckCircle size="5rem" alignChildren="middle" />
                  </Text>
                </Box>
                AI meterage added
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Box flex="horizontal">
              <Button
                grow
                color="accent"
                onClick={() => setConfirmationVisible(false)}
                variant="primary"
              >
                Close
              </Button>
            </Box>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default UserAddBuddyTokens;
