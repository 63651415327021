import { Org, OrgPostBody, ResponseError } from '@drainify/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useFetch, { hasAllParams } from '../components/Api/useFetch';
import { DataHookSingleEntity } from '.';

const useOrg: DataHookSingleEntity<'orgId', Org, OrgPostBody> = ({ orgId }) => {
  const queryClient = useQueryClient();
  const fetch = useFetch();
  const key = [`/orgs/${orgId}`, fetch];
  const params = { orgId: orgId! };

  const query = useQuery<Org, ResponseError>(
    key,
    () => fetch('/orgs/:orgId', { method: 'GET', params }),
    { enabled: hasAllParams(params) }
  );

  const update = useMutation<Org, ResponseError, OrgPostBody>(
    (body) => fetch('/orgs/:orgId', { method: 'PUT', body, params }),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(key, data);
      },
    }
  );

  const remove = useMutation<void, ResponseError>(
    () => fetch('/orgs/:orgId', { method: 'DELETE', params }),
    {
      onSuccess: () => {
        queryClient.setQueryData(key, null);
      },
    }
  );

  return { query, update, remove };
};

export default useOrg;
