import {
  Inspection,
  InspectionPostBody,
  InspectionStatus,
  PreCleaned,
  Temperature,
  VideoImageFormat,
  VideoImageStorageMedia,
  Weather,
  inspectionAttributeApplicable,
} from '@drainify/types';
import {
  getInspectionStatusSchema,
  getPrecleanSchema,
  getTempraturesSchema,
  getVideoImageFormatsSchema,
  getVideoStorageSchema,
  getWeatherSchema,
  isInspection,
} from '@drainify/utils';
import React from 'react';
import { useHasPermission } from '../Permissions/Permissions';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import SummaryCard from '../SummaryCard/SummaryCard';
import SummaryCardSection from '../SummaryCard/SummaryCardSection';
import { useLanguageContext } from '../Usage/LanguageProvider';

type Props = {
  inspection?: Inspection | InspectionPostBody;
};

const InspectionAdditionalSummary = ({ inspection }: Props) => {
  const { language } = useLanguageContext();
  const { editInspectionAdditional, reportEditor } = useReportEditorContext();
  const hasUpdateProjectPermission = useHasPermission('projects:update');
  const error = reportEditor.validateInspection(inspection);
  const { activeBooking } = useProjectContext();

  const editInspectionStep = (step: string) => {
    if (!hasUpdateProjectPermission) {
      return undefined;
    }
    if (!!activeBooking?.completedAt) {
      return undefined;
    }

    return () => {
      if (isInspection(inspection)) {
        editInspectionAdditional(inspection.uid, step);
      }
    };
  };

  return (
    <SummaryCard title="Additional information">
      {inspectionAttributeApplicable(
        'attributes.inspectionStatus',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          error={error?.['attributes.inspectionStatus']}
          title="Inspection Status"
          onEdit={editInspectionStep('attributes.inspectionStatus')}
          value={inspection?.attributes?.inspectionStatus}
        >
          {inspection?.attributes.inspectionStatus &&
            Object.keys(InspectionStatus).includes(
              inspection.attributes.inspectionStatus
            ) &&
            getInspectionStatusSchema(inspection?.attributes?.inspectionStatus)
              .name[language]}
        </SummaryCardSection>
      )}
      {inspectionAttributeApplicable(
        'attributes.weather',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          error={error?.['attributes.weather']}
          title="Weather"
          onEdit={editInspectionStep('attributes.weather')}
          value={inspection?.attributes?.weather}
        >
          {inspection?.attributes.weather &&
            Object.keys(Weather).includes(inspection.attributes.weather) &&
            getWeatherSchema(inspection?.attributes?.weather).name[language]}
        </SummaryCardSection>
      )}
      {inspectionAttributeApplicable(
        'attributes.temperature',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          error={error?.['attributes.temperature']}
          title="Temperature"
          onEdit={editInspectionStep('attributes.temperature')}
          value={inspection?.attributes?.temperature}
        >
          {inspection?.attributes?.temperature &&
            Object.keys(Temperature).includes(
              inspection.attributes.temperature
            ) &&
            getTempraturesSchema(inspection.attributes.temperature).name[
              language
            ]}
        </SummaryCardSection>
      )}
      {inspectionAttributeApplicable(
        'attributes.preCleaned',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          error={error?.['attributes.preCleaned']}
          title="Pre-cleaned"
          onEdit={editInspectionStep('attributes.preCleaned')}
          value={inspection?.attributes?.preCleaned}
        >
          {inspection?.attributes.preCleaned &&
            Object.keys(PreCleaned).includes(
              inspection.attributes.preCleaned
            ) &&
            getPrecleanSchema(inspection?.attributes?.preCleaned).name[
              language
            ]}
        </SummaryCardSection>
      )}
      {inspectionAttributeApplicable(
        'attributes.dateCleaned',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Date Cleaned"
          error={error?.['attributes.dateCleaned']}
          onEdit={editInspectionStep('attributes.dateCleaned')}
          value={inspection?.attributes?.dateCleaned}
        >
          {inspection?.attributes?.dateCleaned}
        </SummaryCardSection>
      )}
      {inspectionAttributeApplicable(
        'attributes.pressureValue',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Pressure Value"
          error={error?.['attributes.pressureValue']}
          onEdit={editInspectionStep('attributes.pressureValue')}
          value={inspection?.attributes?.pressureValue}
        >
          {inspection?.attributes?.pressureValue}
        </SummaryCardSection>
      )}
      {inspectionAttributeApplicable(
        'attributes.flowControlMeasures',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Flow control measures"
          error={error?.['attributes.flowControlMeasures']}
          onEdit={editInspectionStep('attributes.flowControlMeasures')}
          value={inspection?.attributes?.flowControlMeasures}
        >
          {inspection?.attributes?.flowControlMeasures}
        </SummaryCardSection>
      )}
      {inspectionAttributeApplicable(
        'attributes.videoImageStorageMedia',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          error={error?.['attributes.videoImageStorageMedia']}
          title="Video image storage media"
          onEdit={editInspectionStep('attributes.videoImageStorageMedia')}
          value={inspection?.attributes?.videoImageStorageMedia}
        >
          {inspection?.attributes?.videoImageStorageMedia &&
            Object.keys(VideoImageStorageMedia).includes(
              inspection.attributes.videoImageStorageMedia
            ) &&
            getVideoStorageSchema(
              inspection?.attributes?.videoImageStorageMedia
            ).name[language]}
        </SummaryCardSection>
      )}
      {inspectionAttributeApplicable(
        'attributes.videoImageLocationSystem',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          error={error?.['attributes.videoImageLocationSystem']}
          title="Video image location system"
          onEdit={editInspectionStep('attributes.videoImageLocationSystem')}
          value={inspection?.attributes?.videoImageLocationSystem}
        >
          {inspection?.attributes?.videoImageLocationSystem}
        </SummaryCardSection>
      )}
      {inspectionAttributeApplicable(
        'attributes.videoImageFormat',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          error={error?.['attributes.videoImageFormat']}
          title="Video image format"
          onEdit={editInspectionStep('attributes.videoImageFormat')}
          value={inspection?.attributes?.videoImageFormat}
        >
          {inspection?.attributes.videoImageFormat &&
            Object.keys(VideoImageFormat).includes(
              inspection?.attributes.videoImageFormat
            ) &&
            getVideoImageFormatsSchema(inspection.attributes.videoImageFormat)
              .name[language]}
        </SummaryCardSection>
      )}
      {inspectionAttributeApplicable(
        'attributes.videoImageFileName',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Video image file name"
          error={error?.['attributes.videoImageFileName']}
          onEdit={editInspectionStep('attributes.videoImageFileName')}
          value={inspection?.attributes?.videoImageFileName}
        >
          {inspection?.attributes?.videoImageFileName}
        </SummaryCardSection>
      )}
      {inspectionAttributeApplicable(
        'attributes.videoVolumeRef',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Video volume ref"
          error={error?.['attributes.videoVolumeRef']}
          onEdit={editInspectionStep('attributes.videoVolumeRef')}
          value={inspection?.attributes?.videoVolumeRef}
        >
          {inspection?.attributes?.videoVolumeRef}
        </SummaryCardSection>
      )}
      {inspectionAttributeApplicable(
        'attributes.photographImageStorageFormat',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Photo image storage format"
          error={error?.['attributes.photographImageStorageFormat']}
          onEdit={editInspectionStep('attributes.photographImageStorageFormat')}
          value={inspection?.attributes?.photographImageStorageFormat}
        >
          {inspection?.attributes?.photographImageStorageFormat}
        </SummaryCardSection>
      )}
      {inspectionAttributeApplicable(
        'attributes.methodOfInspection',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Method of inspection"
          error={error?.['attributes.methodOfInspection']}
          onEdit={editInspectionStep('attributes.methodOfInspection')}
          value={inspection?.attributes?.methodOfInspection}
        >
          {inspection?.attributes?.methodOfInspection}
        </SummaryCardSection>
      )}
      {inspectionAttributeApplicable(
        'attributes.critcatalityGrade',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Critcatality grade"
          error={error?.['attributes.critcatalityGrade']}
          onEdit={editInspectionStep('attributes.critcatalityGrade')}
          value={inspection?.attributes?.critcatalityGrade}
        >
          {inspection?.attributes?.critcatalityGrade}
        </SummaryCardSection>
      )}
      {inspectionAttributeApplicable(
        'attributes.flowControlMeasures',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Flow control measures"
          error={error?.['attributes.flowControlMeasures']}
          onEdit={editInspectionStep('attributes.flowControlMeasures')}
          value={inspection?.attributes?.flowControlMeasures}
        >
          {inspection?.attributes?.flowControlMeasures}
        </SummaryCardSection>
      )}
      {inspectionAttributeApplicable(
        'attributes.purposeOfInspection',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Purpose of inspection"
          error={error?.['attributes.purposeOfInspection']}
          onEdit={editInspectionStep('attributes.purposeOfInspection')}
          value={inspection?.attributes?.purposeOfInspection}
        >
          {inspection?.attributes?.purposeOfInspection}
        </SummaryCardSection>
      )}
      {inspectionAttributeApplicable(
        'attributes.longitudinalLocationOfStartPoint',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Longitudinal location of start point"
          error={error?.['attributes.longitudinalLocationOfStartPoint']}
          onEdit={editInspectionStep(
            'attributes.longitudinalLocationOfStartPoint'
          )}
          value={inspection?.attributes?.longitudinalLocationOfStartPoint}
        >
          {inspection?.attributes?.longitudinalLocationOfStartPoint}
        </SummaryCardSection>
      )}
      {inspectionAttributeApplicable(
        'attributes.circumferentialLocationOfStartPoint',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Circumferential location of start point"
          error={error?.['attributes.circumferentialLocationOfStartPoint']}
          onEdit={editInspectionStep(
            'attributes.circumferentialLocationOfStartPoint'
          )}
          value={inspection?.attributes?.circumferentialLocationOfStartPoint}
        >
          {inspection?.attributes?.circumferentialLocationOfStartPoint}
        </SummaryCardSection>
      )}
      {inspectionAttributeApplicable(
        'attributes.time',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          error={error?.['attributes.time']}
          title="Time"
          onEdit={editInspectionStep('attributes.time')}
          value={inspection?.attributes?.time}
        >
          {inspection?.attributes?.time}
        </SummaryCardSection>
      )}
      {inspectionAttributeApplicable(
        'attributes.date',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          error={error?.['attributes.date']}
          title="Date"
          onEdit={editInspectionStep('attributes.date')}
          value={inspection?.attributes?.date}
        >
          {inspection?.attributes?.date}
        </SummaryCardSection>
      )}
      {inspectionAttributeApplicable(
        'attributes.technologyCCTV',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          error={error?.['attributes.technologyCCTV']}
          title="Used CCTV"
          onEdit={editInspectionStep('attributes.technologyCCTV')}
          value={inspection?.attributes?.technologyCCTV}
        >
          {inspection?.attributes?.technologyCCTV}
        </SummaryCardSection>
      )}
      {inspectionAttributeApplicable(
        'attributes.technologyLaser',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          error={error?.['attributes.technologyLaser']}
          title="Used Laser"
          onEdit={editInspectionStep('attributes.technologyLaser')}
          value={inspection?.attributes?.technologyLaser}
        >
          {inspection?.attributes?.technologyLaser}
        </SummaryCardSection>
      )}
      {inspectionAttributeApplicable(
        'attributes.technologySidewall',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          error={error?.['attributes.technologySidewall']}
          title="Used sidewall"
          onEdit={editInspectionStep('attributes.technologySidewall')}
          value={inspection?.attributes?.technologySidewall}
        >
          {inspection?.attributes?.technologySidewall}
        </SummaryCardSection>
      )}
      {inspectionAttributeApplicable(
        'attributes.technologySonar',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          error={error?.['attributes.technologySonar']}
          title="Used Sonar"
          onEdit={editInspectionStep('attributes.technologySonar')}
          value={inspection?.attributes?.technologySonar}
        >
          {inspection?.attributes?.technologySonar}
        </SummaryCardSection>
      )}
      {inspectionAttributeApplicable(
        'attributes.technologyZoom',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          error={error?.['attributes.technologyZoom']}
          title="Used Zoom"
          onEdit={editInspectionStep('attributes.technologyZoom')}
          value={inspection?.attributes?.technologyZoom}
        >
          {inspection?.attributes?.technologyZoom}
        </SummaryCardSection>
      )}
      {inspectionAttributeApplicable(
        'attributes.technologyOther',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          error={error?.['attributes.technologyOther']}
          title="Used other techonology"
          onEdit={editInspectionStep('attributes.technologyOther')}
          value={inspection?.attributes?.technologyOther}
        >
          {inspection?.attributes?.technologyOther}
        </SummaryCardSection>
      )}
      {inspectionAttributeApplicable(
        'attributes.workOrder',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          error={error?.['attributes.workOrder']}
          title="Work Order"
          onEdit={editInspectionStep('attributes.workOrder')}
          value={inspection?.attributes?.workOrder}
        >
          {inspection?.attributes?.workOrder}
        </SummaryCardSection>
      )}
      {inspectionAttributeApplicable(
        'attributes.mediaLabel',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          error={error?.['attributes.mediaLabel']}
          title="Media Label"
          onEdit={editInspectionStep('attributes.mediaLabel')}
          value={inspection?.attributes?.mediaLabel}
        >
          {inspection?.attributes?.mediaLabel}
        </SummaryCardSection>
      )}
      {inspectionAttributeApplicable(
        'attributes.consequenceOfFailure',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          error={error?.['attributes.consequenceOfFailure']}
          title="Consequence of Failure"
          onEdit={editInspectionStep('attributes.consequenceOfFailure')}
          value={inspection?.attributes?.consequenceOfFailure}
        >
          {inspection?.attributes?.consequenceOfFailure}
        </SummaryCardSection>
      )}
      {inspectionAttributeApplicable(
        'attributes.pipeSegmentReference',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          error={error?.['attributes.pipeSegmentReference']}
          title="Pipe Segment Reference"
          onEdit={editInspectionStep('attributes.pipeSegmentReference')}
          value={inspection?.attributes?.pipeSegmentReference}
        >
          {inspection?.attributes?.pipeSegmentReference}
        </SummaryCardSection>
      )}
      {inspectionAttributeApplicable(
        'attributes.locationDetails',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          error={error?.['attributes.locationDetails']}
          title="Location Details"
          onEdit={editInspectionStep('attributes.locationDetails')}
          value={inspection?.attributes?.locationDetails}
        >
          {inspection?.attributes?.locationDetails}
        </SummaryCardSection>
      )}
      {inspectionAttributeApplicable(
        'attributes.MHCoordinateSystem',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          error={error?.['attributes.MHCoordinateSystem']}
          title="MH Coordinate system"
          onEdit={editInspectionStep('attributes.MHCoordinateSystem')}
          value={inspection?.attributes?.MHCoordinateSystem}
        >
          {inspection?.attributes?.MHCoordinateSystem}
        </SummaryCardSection>
      )}
      {inspectionAttributeApplicable(
        'attributes.verticalDatum',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          error={error?.['attributes.verticalDatum']}
          title="Vertical Datum"
          onEdit={editInspectionStep('attributes.verticalDatum')}
          value={inspection?.attributes?.verticalDatum}
        >
          {inspection?.attributes?.verticalDatum}
        </SummaryCardSection>
      )}
      {inspectionAttributeApplicable(
        'attributes.GPSAccuracy',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          error={error?.['attributes.GPSAccuracy']}
          title="GPS Accuracy"
          onEdit={editInspectionStep('attributes.GPSAccuracy')}
          value={inspection?.attributes?.GPSAccuracy}
        >
          {inspection?.attributes?.GPSAccuracy}
        </SummaryCardSection>
      )}
      {inspectionAttributeApplicable(
        'attributes.additonalInfo',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          error={error?.['attributes.additonalInfo']}
          title="Additional info"
          onEdit={editInspectionStep('attributes.additonalInfo')}
          value={inspection?.attributes?.additonalInfo}
        >
          {inspection?.attributes?.additonalInfo}
        </SummaryCardSection>
      )}
    </SummaryCard>
  );
};

export default InspectionAdditionalSummary;
