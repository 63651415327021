import { BoxProps } from 'preshape';
import React, { PropsWithChildren } from 'react';
import { usePageContext } from '../../../components/Page/PageContext';
import PlaceholderGradientAnimation from '../../../components/Placeholders/PlaceholderGradientAnimation';

const PageBody = (props: PropsWithChildren<BoxProps>) => {
  const { isLoading } = usePageContext();

  return (
    <PlaceholderGradientAnimation
      animating={isLoading}
      padding="x4"
      {...props}
      grow
    />
  );
};

export default PageBody;
