import { Box, Button, Modal, ModalBody, Text } from 'preshape';
import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import Map from '../../components/Map/Map';
import GeometryStoreFocusEverything from '../../components/Map/useGeometryStore/GeometryStoreFocusOnEverything';
import ProjectCreateButton from '../../components/Project/ProjectCreateButton';
import ProjectProvider from '../../components/Project/ProjectProvider';
import ProjectSearch from '../../components/Project/ProjectSearch/ProjectSearch';
import ReportMapFocusOnElement from '../../components/Report/ReportMap/ReportMapFocusOnElement';
import ReportProvider from '../../components/Report/ReportProvider';
import Layout from './Layout/Layout';
import LayoutHeader from './Layout/LayoutHeader';
import LayoutMain from './Layout/LayoutMain';
import ProjectPage from './Pages/ProjectPage';
import ProjectsPage from './Pages/ProjectsPage';

const VERSION_HASH = process.env.VERSION_HASH!;

const DesktopApp = () => {
  const [updateAvailable, setUpdateAvailable] = React.useState(false);

  const checkForUpdates = async () => {
    fetch(process.env.DRAINIFY_API_URL + '/version').then(async (e) => {
      try {
        const { version } = await e.json();
        setUpdateAvailable(version !== VERSION_HASH);
      } catch (e) {}
    });
  };

  React.useEffect(() => {
    checkForUpdates();
    const handleKeyUp = (event: KeyboardEvent) => {
      const miscEvent = document.getElementById(`accel-${event.key}`);
      const ignoreEvent = document.getElementById(`accel-${event.key}-ignore`);
      if (ignoreEvent) {
        return;
      } else if (miscEvent && !miscEvent.hasAttribute('disabled')) {
        event.preventDefault();
        miscEvent.click();
      } else {
        return;
      }
    }

    document.addEventListener('keydown', function (event) {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    });

    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };

  }, []);

  const [updatePrompt, setUpdatePrompt] = React.useState(false);

  return (
    <Layout>
      <Modal visible={updatePrompt}>
        <ModalBody>Please restart the app to update.</ModalBody>
      </Modal>
      {updateAvailable && (
        <Button
          color="positive"
          variant="primary"
          borderRadius="0px"
          elevate
          onClick={() => {
            if ('serviceWorker' in navigator) {
              navigator.serviceWorker
                .getRegistrations()
                .then(async (registrations) => {
                  for (const registration of registrations) {
                    await registration.update();
                  }
                });
              setUpdatePrompt(true);
            }
          }}
        >
          <Text size="x1" strong>
            A new version is available. Click to update.
          </Text>
        </Button>
      )}
      <LayoutHeader>
        <Box alignChildrenVertical="middle" flex="horizontal" grow>
          <Box basis="0" grow flex="horizontal" gap="x3">
            <ProjectSearch />
            <ProjectCreateButton variant="primary" />
          </Box>
          <Box>
            <Routes>
              <Route path="/" element={<ProjectProvider />} />
            </Routes>
          </Box>
        </Box>
      </LayoutHeader>

      <LayoutMain>
        <Routes>
          <Route
            path="/"
            element={
              <Map isInteractive>
                <Outlet />
              </Map>
            }
          >
            <Route index element={<ProjectsPage />} />
            <Route path="/projects/:projectId" element={<ProjectProvider />}>
              <Route element={<ReportProvider />}>
                <Route element={<ProjectPage />}>
                  <Route index element={<GeometryStoreFocusEverything />} />

                  <Route
                    path=":type/:uid"
                    element={<ReportMapFocusOnElement withNotification />}
                  />
                </Route>
              </Route>
            </Route>
          </Route>
        </Routes>
      </LayoutMain>
    </Layout>
  );
};

export default DesktopApp;
