import React, { PropsWithChildren, useContext } from "react";
import { AuthenticationContext } from "../Authentication/Authenticate";


const FeatureFlag = ({ children, unlockForLees }: PropsWithChildren<{ unlockForLees?: boolean }>) => {
    const { user } = useContext(AuthenticationContext)
    if (user?.email?.endsWith('@drainify.io') || process.env.NODE_ENV === 'development') {
        return <>{ children }</>
    } 
    if(unlockForLees && user?.email?.toLowerCase().endsWith('lees@happydrains.co.uk')) {
        return <>{ children }</>
    }
    return null;
}

export default FeatureFlag;