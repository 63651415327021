import { Button } from "preshape"
import React from "react"
import OCRExtractionTool from "./OCRExtractionTool"

const OCRExtractionToolButton = () => {
    const [visible, setVisble] = React.useState(false)
    return (
        <>
        <Button onClick={() => setVisble(true)}>
            OCR Extraction Tool
        </Button>
        {
            visible && <OCRExtractionTool onClose={() => setVisble(false)}/>
        }
        </>
    )
}

export default OCRExtractionToolButton;