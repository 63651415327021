"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getObservationOptions = exports.getObservationSchema = exports.observationsTopLevel = exports.observationsFlatRoot = exports.observationsFlat = exports.isOptionContinuous = exports.isOptionRemarks = exports.isOptionPercentage = exports.isOptionEnum = exports.isOptionDistanceShort = exports.isOptionDistance = exports.isOptionCode = exports.isOptionBoolean = exports.isOptionAngleRange = exports.isOptionAngle = void 0;
const types_1 = require("@drainify/types");
const pacp_1 = require("./observationSchemas/pacp");
const schema_1 = require("./schema");
// const getObservationEnumMapFromSchemaEntries = (
// schemaEntries: SchemaEntry<string>[]
// ): ObservationOptionEnumMap => {
// const observationEnum: ObservationOptionEnumMap = {};
// schemaEntries.forEach(({ code, name }) => (observationEnum[code] = { name }));
// return observationEnum;
// };
const observations = [
    {
        code: types_1.ObservationCode.B,
        scoringFunction: () => ({ structure: 80 }),
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
        repairs: [types_1.Repairs.CIPP_OR_EXCAVATION],
        name: {
            [types_1.LanguageCodes.EN]: 'Broken',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'Pieces of pipe have visibly moved',
        options: [
            {
                name: 'Broken Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.BJ,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Broken on Joint',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [
                            types_1.ProjectType.MSCC5_COMMERERCIAL,
                            types_1.ProjectType.MSCC5_DOMESTIC,
                        ],
                        description: 'Breakage on joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.C,
        name: {
            [types_1.LanguageCodes.EN]: 'Cracks',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        scoringFunction: () => ({ structure: 1 }),
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        description: 'Cracks that are not visibly open',
        repairs: [types_1.Repairs.CIPP],
        options: [
            {
                required: true,
                name: 'Type of crack',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.CC,
                        scoringFunction: () => ({ structure: 10 }),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Crack Circumferential',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Circumferential cracks that are not visibly open',
                        options: [
                            {
                                name: 'Crack appears on a joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.CCJ,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Crack Circumferential Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        scoringFunction: () => ({}),
                                        description: 'Circumferential crack on joint',
                                    },
                                ],
                            },
                            {
                                required: true,
                                name: 'Start/end clock reference',
                                attribute: 'angle',
                                description: 'The clock reference the crack starts and ends at',
                                type: 'angleRange',
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.CL,
                        scoringFunction: () => ({ structure: 10 }),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Crack Longitudinal',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Longitudinal cracks that are not visibly open',
                        options: [
                            {
                                name: 'Longitudinal crack appears on a joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.CLJ,
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Crack Longitudinal Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        description: 'Longitudinal crack on joint',
                                    },
                                ],
                            },
                            {
                                required: true,
                                name: 'Crack longitudinal at',
                                attribute: 'angleAt',
                                description: 'The clock reference the crack is at',
                                type: 'angle',
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.CM,
                        scoringFunction: () => ({ structure: 40 }),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Crack Multiple',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        description: 'Multiple cracks that are not visibly open',
                        options: [
                            {
                                name: 'Multiple cracks appear on a joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.CMJ,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Crack Multiple Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        description: 'Multiple cracks on joint',
                                    },
                                ],
                            },
                            {
                                required: true,
                                name: 'Start/end clock reference',
                                attribute: 'angle',
                                description: 'Clock reference the crack starts and ends',
                                type: 'angleRange',
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.CS,
                        scoringFunction: () => ({ structure: 40 }),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Crack Spiral',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        description: 'Spiraling crack',
                        options: [
                            {
                                name: 'Spiraling cracks appear on a joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.CSJ,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Crack Spiral Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        description: 'Spiral crack on joint',
                                    },
                                ],
                            },
                            {
                                required: true,
                                name: 'Start/end clock reference',
                                attribute: 'angle',
                                description: 'Clock reference the crack starts and ends',
                                type: 'angleRange',
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.CR,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Crack Radiating',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        scoringFunction: () => ({ structure: 10 }),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        description: 'Radiating cracks that are not visibly open',
                        options: [
                            {
                                name: 'Crack Radiating Joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.CRJ,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        description: 'Radiating crack on joint',
                                    },
                                ],
                            },
                            {
                                required: true,
                                name: 'Start/end clock reference',
                                attribute: 'angle',
                                description: 'Clock reference the crack starts and ends',
                                type: 'angleRange',
                            },
                        ],
                    },
                ],
            },
            {
                required: false,
                name: 'Crack in roof slab',
                attribute: 'boolean',
                description: 'Crack in roof slab',
                type: 'boolean',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.C_MSCC5_DOMESTIC,
        name: {
            [types_1.LanguageCodes.EN]: 'Crack',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        scoringFunction: () => ({ service: 1 }),
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
        repairs: [types_1.Repairs.CIPP],
        description: 'Cracks that are not visibly open',
        options: [
            {
                required: true,
                name: 'Type of crack',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.CC_MSCC5_DOMESTIC,
                        scoringFunction: () => ({ structure: 10 }),
                        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Crack Circumferential',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Circumferential cracks that are not visibly open',
                        options: [
                            {
                                name: 'Crack appears on a joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.CCJ_MSCC5_DOMESTIC,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Crack Circumferential Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
                                        scoringFunction: () => ({}),
                                        description: 'Circumferential crack on joint',
                                    },
                                ],
                            },
                            {
                                required: true,
                                name: 'Start/end clock reference',
                                attribute: 'angle',
                                description: 'The clock reference the crack starts and ends at',
                                type: 'angleRange',
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.CL_MSCC5_DOMESTIC,
                        scoringFunction: () => ({ structure: 10 }),
                        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Crack Longitudinal',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Longitudinal cracks that are not visibly open',
                        options: [
                            {
                                name: 'Longitudinal crack appears on a joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.CLJ_MSCC5_DOMESTIC,
                                        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Crack Longitudinal Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        description: 'Longitudinal crack on joint',
                                    },
                                ],
                            },
                            {
                                required: true,
                                name: 'Crack longitudinal at',
                                attribute: 'angleAt',
                                description: 'The clock reference the crack is at',
                                type: 'angle',
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.CM_MSCC5_DOMESTIC,
                        scoringFunction: () => ({ structure: 40 }),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Crack Multiple',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
                        description: 'Multiple cracks that are not visibly open',
                        options: [
                            {
                                name: 'Multiple cracks appear on a joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.CMJ_MSCC5_DOMESTIC,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Crack Multiple Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
                                        description: 'Multiple cracks on joint',
                                    },
                                ],
                            },
                            {
                                required: true,
                                name: 'Crack multiple from/to',
                                attribute: 'angle',
                                description: 'Clock reference the crack starts and ends',
                                type: 'angleRange',
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.CS_MSCC5_DOMESTIC,
                        scoringFunction: () => ({ structure: 40 }),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Crack Spiral',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
                        description: 'Spiraling crack',
                        options: [
                            {
                                name: 'Spiraling cracks appear on a joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.CSJ_MSCC5_DOMESTIC,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Crack Spiral Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
                                        description: 'Spiral crack on joint',
                                    },
                                ],
                            },
                            {
                                required: true,
                                name: 'Start/end clock reference',
                                attribute: 'angle',
                                description: 'Clock reference the crack starts and ends',
                                type: 'angleRange',
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.CR_MSCC5_DOMESTIC,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Crack Radiating',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
                        description: 'Radiating cracks that are not visibly open',
                        options: [
                            {
                                name: 'Radiating crack appears on a joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.CRJ_MSCC5_DOMESTIC,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Crack Radiating Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
                                        description: 'Radiating crack on joint',
                                    },
                                ],
                            },
                            {
                                required: true,
                                name: 'Start/end clock reference',
                                attribute: 'angle',
                                description: 'Clock reference the crack starts and ends',
                                type: 'angleRange',
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        code: types_1.ObservationCode.CN_MSCC5_DOMESTIC,
        name: {
            [types_1.LanguageCodes.EN]: 'Connection',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'Connection other than an junction',
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
        options: [
            {
                required: true,
                name: 'Clock reference',
                attribute: 'angleAt',
                description: 'Clock reference the connection starts',
                type: 'angle',
            },
            {
                required: true,
                name: 'Diameter',
                attribute: 'diameter',
                description: 'Diameter of connection',
                type: 'distanceShort',
                quickOptions: [0.1, 0.15, 0.225],
            },
        ],
    },
    {
        code: types_1.ObservationCode.CN,
        name: {
            [types_1.LanguageCodes.EN]: 'Connection',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'Connection other than an junction',
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        options: [
            {
                name: 'Closed',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.CNC,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Connection Closed',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Connection other than a junction closed',
                    },
                ],
            },
            {
                required: true,
                name: 'Clock reference',
                attribute: 'angleAt',
                description: 'Clock reference the connection starts',
                type: 'angle',
            },
            {
                required: true,
                name: 'Diameter',
                attribute: 'diameter',
                description: 'Diameter of connection',
                type: 'distanceShort',
                quickOptions: [0.1, 0.15, 0.225],
            },
        ],
    },
    {
        code: types_1.ObservationCode.CU,
        repairs: [types_1.Repairs.COMPLEX_REPAIR],
        name: {
            [types_1.LanguageCodes.EN]: 'Loss of Vision',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        description: 'Lens of camera is obscured by debris water etc. Operator is unable to see drain clearly',
        options: [
            {
                required: false,
                name: 'type',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.CUW,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Loss of Vision - Water',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Loss of vision, Camera underwater',
                        options: [
                            {
                                name: 'Appears on joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.CUWJ,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Loss of Vision - Water at Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        description: 'Loss of vision, camera underwater on joint',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.CUD,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Loss of Vision - Silt',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Loss of vision, silt',
                        options: [
                            {
                                name: 'Appears on joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.CUDJ,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Loss of Vision - Silt at Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        description: 'Loss of vision, silt appears on joint',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.CUS,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Loss of Vision - Steam',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Loss of vision, steam',
                        options: [
                            {
                                name: 'Appears on joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.CUSJ,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Loss of Vision - Steam at Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        description: 'Loss of vision, steam appears on a joint',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.CUZ,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Loss of Vision - Other',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Loss of vision, other',
                        options: [
                            {
                                name: 'Appears on joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.CUZJ,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Loss of Vision - Other at Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        description: 'Loss of vision appears on joint',
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.CU_MSCC5_DOMESTIC,
        repairs: [types_1.Repairs.COMPLEX_REPAIR],
        name: {
            [types_1.LanguageCodes.EN]: 'Loss of Vision',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
        description: 'Lens of camera is obscured by debris water etc. Operator is unable to see drain clearly',
        options: [
            {
                required: false,
                name: 'type',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.CUW_MSCC5_DOMESTIC,
                        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
                        scoringFunction: () => ({}),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Loss of Vision - Water',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Loss of vision, Camera underwater',
                    },
                ],
            },
        ],
    },
    {
        code: types_1.ObservationCode.CX,
        name: {
            [types_1.LanguageCodes.EN]: 'Defective Connection',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'Defective due to damage',
        repairs: [types_1.Repairs.COMPLEX_REPAIR],
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
        options: [
            {
                name: 'Type of defective connection',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.CXI,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Defective Connection Intruding',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        scoringFunction: ({ attributes: { percentage = 0 } }) => {
                            if (percentage < 5)
                                return { service: 1 };
                            if (percentage < 20)
                                return { service: 2 };
                            if (percentage < 50)
                                return { service: 5 };
                            if (percentage < 75)
                                return { service: 8 };
                            return { service: 10 };
                        },
                        projectTypes: [
                            types_1.ProjectType.MSCC5_COMMERERCIAL,
                            types_1.ProjectType.MSCC5_DOMESTIC,
                        ],
                        description: 'Defective by intrusion',
                    },
                    {
                        code: types_1.ObservationCode.CXP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Defective Connection Incorrect Position',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        options: [
                            {
                                name: 'Incorrect position intruding',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.CXPI,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Defective Connection Incorrect Position Intruding',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        description: 'Incorrect position intruding',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.CXD,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Defective Connection Damage',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        options: [
                            {
                                name: 'Damage intruding',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.CXDI,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Defective Connection Damage Intruding',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        description: 'Damage intruding',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.CXB,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Defective Connection Blocked',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        options: [
                            {
                                name: 'Blocked intruding',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.CXBI,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Defective Connection Blocked Intruding',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        description: 'Blocked intruding',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.CXZ,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Defective Connection Other',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        description: 'Defective connection other',
                    },
                ],
            },
            {
                required: true,
                name: 'Clock reference',
                attribute: 'angleAt',
                description: 'Clock reference the defective connection starts',
                type: 'angle',
            },
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: true,
                name: 'Diameter',
                attribute: 'diameter',
                description: 'Diameter of connection',
                type: 'distanceShort',
                quickOptions: [0.1, 0.15, 0.225],
            },
        ],
    },
    {
        code: types_1.ObservationCode.D_MSCC5_DOMESTIC,
        name: {
            [types_1.LanguageCodes.EN]: 'Deformed',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        repairs: [types_1.Repairs.REROUNDING_OR_PIPE_BURSTING],
        description: 'Pipe has lost its structure',
        scoringFunction: ({ attributes: { percentage = 0 } }) => {
            if (percentage < 5)
                return { service: 1 };
            if (percentage < 20)
                return { service: 2 };
            if (percentage < 50)
                return { service: 5 };
            if (percentage <= 75)
                return { service: 8 };
            return {
                service: 10,
            };
        },
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
        options: [
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
                required: true,
            },
        ],
    },
    {
        code: types_1.ObservationCode.D,
        repairs: [types_1.Repairs.REROUNDING_OR_PIPE_BURSTING],
        name: {
            [types_1.LanguageCodes.EN]: 'Deformed',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'Pipe has lost its structure',
        scoringFunction: ({ attributes: { percentage = 0 } }) => {
            if (percentage <= 5)
                return { structure: 20, service: 2 };
            if (percentage <= 10)
                return { structure: 80, service: 2 };
            if (percentage <= 15)
                return { structure: 165, service: 2 };
            if (percentage < 20)
                return { structure: 165, service: 3 };
            if (percentage < 50)
                return { structure: 165, service: 5 };
            if (percentage < 75)
                return { structure: 165, service: 8 };
            return {
                structure: 165,
                service: 10,
            };
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        options: [
            {
                required: false,
                name: 'Deformation direction (brick only)',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.DH,
                        scoringFunction: ({ attributes: { percentage = 0 } }) => {
                            if (percentage <= 5)
                                return { structure: 20, service: 2 };
                            if (percentage <= 10)
                                return { structure: 40, service: 2 };
                            if (percentage <= 15)
                                return { structure: 60, service: 2 };
                            if (percentage < 20)
                                return { structure: 60, service: 3 };
                            if (percentage < 50)
                                return { structure: 60, service: 5 };
                            if (percentage < 75)
                                return { structure: 60, service: 8 };
                            return {
                                structure: 60,
                                service: 10,
                            };
                        },
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Deformed Horizontally',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Deformed Horizontally - brick only',
                    },
                    {
                        code: types_1.ObservationCode.DV,
                        scoringFunction: ({ attributes: { percentage = 0 } }) => {
                            if (percentage <= 5)
                                return { structure: 20, service: 2 };
                            if (percentage <= 10)
                                return { structure: 40, service: 2 };
                            if (percentage <= 15)
                                return { structure: 60, service: 2 };
                            if (percentage < 20)
                                return { structure: 60, service: 3 };
                            if (percentage < 50)
                                return { structure: 60, service: 5 };
                            if (percentage < 75)
                                return { structure: 60, service: 8 };
                            return {
                                structure: 60,
                                service: 10,
                            };
                        },
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Deformed Vertically',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Deformed vertically - brick only',
                    },
                ],
            },
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
                required: true,
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.DB,
        repairs: [types_1.Repairs.COMPLEX_REPAIR],
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        scoringFunction: () => ({}),
        name: {
            [types_1.LanguageCodes.EN]: 'Displaced bricks',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'Displaced bricks',
        options: [
            {
                required: true,
                name: 'Displaced bricks start/end',
                attribute: 'angle',
                description: 'Angle displacement starts and ends',
                type: 'angleRange',
            },
            {
                required: true,
                name: 'Single brick',
                attribute: 'boolean',
                description: 'Single brick is displaced',
                type: 'boolean',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.DE,
        name: {
            [types_1.LanguageCodes.EN]: 'Deposits',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        repairs: [types_1.Repairs.CLEANSING_AND_DESCALING],
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
        description: 'Attached or settled deposits',
        options: [
            {
                required: true,
                name: 'Type of deposits',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.DEE,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Deposits Encrustation',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        scoringFunction: ({ attributes: { percentage = 0 } }) => {
                            if (percentage < 5)
                                return { service: 1 };
                            if (percentage < 20)
                                return { service: 2 };
                            if (percentage < 50)
                                return { service: 5 };
                            if (percentage < 75)
                                return { service: 8 };
                            return { service: 10 };
                        },
                        projectTypes: [
                            types_1.ProjectType.MSCC5_COMMERERCIAL,
                            types_1.ProjectType.MSCC5_DOMESTIC,
                        ],
                        description: 'Attached scale deposits evident',
                        options: [
                            {
                                name: 'Deposit encrustration appears on a joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.DEEJ,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Deposits Encrustation Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [
                                            types_1.ProjectType.MSCC5_COMMERERCIAL,
                                            types_1.ProjectType.MSCC5_DOMESTIC,
                                        ],
                                        description: 'Deposit encrustation at joint',
                                    },
                                ],
                            },
                            {
                                name: 'Start/end clock reference',
                                attribute: 'angle',
                                description: 'Clock reference',
                                type: 'angleRange',
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.DEF,
                        scoringFunction: ({ attributes: { percentage = 0 } }) => {
                            if (percentage < 5)
                                return { service: 2 };
                            if (percentage < 20)
                                return { service: 4 };
                            if (percentage < 50)
                                return { service: 10 };
                            if (percentage <= 75)
                                return { service: 15 };
                            return { service: 20 };
                        },
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Attached Deposit Fouling',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Attached deposit encrustation',
                        options: [
                            {
                                name: 'Attached deposit encrustation appears on a joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.DEFJ,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Attached Deposit Encrustation Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        description: 'Attached deposit encrustation at joint',
                                    },
                                ],
                            },
                            {
                                name: 'Start/end clock reference',
                                attribute: 'angle',
                                description: 'Clock reference',
                                type: 'angleRange',
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.DEZ,
                        scoringFunction: ({ attributes: { percentage = 0 } }) => {
                            if (percentage < 5)
                                return { service: 2 };
                            if (percentage < 20)
                                return { service: 4 };
                            if (percentage < 50)
                                return { service: 10 };
                            if (percentage <= 75)
                                return { service: 15 };
                            return { service: 20 };
                        },
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Attached Deposits Other',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Attached deposit encrustation',
                        options: [
                            {
                                name: 'Attached deposit encrustation at joint appears at joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.DEZJ,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Attached Deposits Other Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        description: 'Attached deposit encrustation at joint',
                                    },
                                ],
                            },
                            {
                                name: 'Start/end clock reference',
                                attribute: 'angle',
                                description: 'Clock referenc',
                                type: 'angleRange',
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.DEG,
                        scoringFunction: ({ attributes: { percentage = 0 } }) => {
                            if (percentage < 5)
                                return { service: 1 };
                            if (percentage < 20)
                                return { service: 2 };
                            if (percentage < 50)
                                return { service: 5 };
                            if (percentage < 75)
                                return { service: 8 };
                            return { service: 10 };
                        },
                        projectTypes: [
                            types_1.ProjectType.MSCC5_COMMERERCIAL,
                            types_1.ProjectType.MSCC5_DOMESTIC,
                        ],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Deposits Grease',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Attached grease deposits evident',
                        options: [
                            {
                                name: 'Deposit greece appears on a joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.DEGJ,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Deposits Grease Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [
                                            types_1.ProjectType.MSCC5_COMMERERCIAL,
                                            types_1.ProjectType.MSCC5_DOMESTIC,
                                        ],
                                        description: 'Deposit greece at joint',
                                    },
                                ],
                            },
                            {
                                name: 'Start/end clock reference',
                                attribute: 'angle',
                                description: 'Clock referenc',
                                type: 'angleRange',
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.DER,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Deposits Coarse Settled',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Settled coarse deposits on the invert of the pipe',
                        scoringFunction: ({ attributes: { percentage = 0 } }) => {
                            if (percentage < 5)
                                return { service: 1 };
                            if (percentage < 20)
                                return { service: 2 };
                            if (percentage < 50)
                                return { service: 5 };
                            if (percentage < 75)
                                return { service: 8 };
                            return { service: 10 };
                        },
                        projectTypes: [
                            types_1.ProjectType.MSCC5_COMMERERCIAL,
                            types_1.ProjectType.MSCC5_DOMESTIC,
                        ],
                        options: [
                            {
                                name: 'Deposit coarse settled appears on a joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.DERJ,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Deposits Coarse Settled Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [
                                            types_1.ProjectType.MSCC5_COMMERERCIAL,
                                            types_1.ProjectType.MSCC5_DOMESTIC,
                                        ],
                                        description: 'Deposit coarse settled at joint',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.DEC,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Settled Deposits Hard or Compacted',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Settled deposits hard or compacted',
                        scoringFunction: ({ attributes: { percentage = 0 } }) => {
                            if (percentage <= 5)
                                return { service: 1 };
                            if (percentage < 20)
                                return { service: 2 };
                            if (percentage < 50)
                                return { service: 5 };
                            if (percentage < 75)
                                return { service: 8 };
                            return { service: 10 };
                        },
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        options: [
                            {
                                name: 'Settled deposits hard or compacted',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.DECJ,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Settled Deposits Hard or Compacted Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        description: 'Settled deposits hard or compacted at joint',
                                    },
                                ],
                            },
                            {
                                name: 'Start/end clock reference',
                                attribute: 'angle',
                                description: 'Clock reference',
                                type: 'angleRange',
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.DES,
                        scoringFunction: ({ attributes: { percentage = 0 } }) => {
                            if (percentage < 5)
                                return { service: 1 };
                            if (percentage < 20)
                                return { service: 2 };
                            if (percentage < 50)
                                return { service: 5 };
                            if (percentage <= 75)
                                return { service: 8 };
                            else
                                return {
                                    service: 10,
                                };
                        },
                        name: {
                            [types_1.LanguageCodes.EN]: 'Deposits Fine Settled',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        projectTypes: [
                            types_1.ProjectType.MSCC5_COMMERERCIAL,
                            types_1.ProjectType.MSCC5_DOMESTIC,
                        ],
                        description: 'Settled fine deposits on the invert of the pipe',
                        options: [
                            {
                                name: 'Deposit fine settled appears on a joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.DESJ,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Deposits Fine Settled Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [
                                            types_1.ProjectType.MSCC5_COMMERERCIAL,
                                            types_1.ProjectType.MSCC5_DOMESTIC,
                                        ],
                                        description: 'Deposit fine settled at joint',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.DEX,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Other Settled Deposits',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Other settled deposits',
                        scoringFunction: ({ attributes: { percentage = 0 } }) => {
                            if (percentage < 5)
                                return { service: 1 };
                            if (percentage < 20)
                                return { service: 2 };
                            if (percentage < 50)
                                return { service: 5 };
                            if (percentage < 75)
                                return { service: 8 };
                            return { service: 10 };
                        },
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        options: [
                            {
                                name: 'Other settled deposits',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.DEXJ,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Other Settled Deposits Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        description: 'Other settled deposits at joint',
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.DI,
        repairs: [types_1.Repairs.COMPLEX_REPAIR],
        name: {
            [types_1.LanguageCodes.EN]: 'Dropped invert',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        description: 'Dropped invert',
        options: [
            {
                name: 'Size of gap',
                attribute: 'diameter',
                description: 'Size of gap in mm',
                type: 'distanceShort',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.EX,
        name: {
            [types_1.LanguageCodes.EN]: 'Exfiltration',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        scoringFunction: () => ({}),
        repairs: [types_1.Repairs.CIPP],
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        description: 'Exfiltration',
        options: [
            {
                name: 'Exfiltration appears on a joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.EXJ,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Exfiltration Joint',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        description: 'Exfiltration on joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Exfiltation start/end',
                attribute: 'angle',
                description: 'Clock reference the exfiltration starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.MM,
        repairs: [types_1.Repairs.COMPLEX_REPAIR],
        name: {
            [types_1.LanguageCodes.EN]: 'Missing mortar',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        description: 'Missing mortar',
        options: [
            {
                required: true,
                name: 'Type of missing mortar',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.MMS,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Missing mortar surface (< 15mm)',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: '< 15MM',
                    },
                    {
                        code: types_1.ObservationCode.MMM,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Missing mortar medium (15-50mm)',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: '15 - 50mm',
                    },
                    {
                        code: types_1.ObservationCode.MMT,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Missing mortar total (>50mm)',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: '>50mm',
                    },
                ],
            },
            {
                required: true,
                name: 'Missing mortal start/ends',
                attribute: 'angle',
                description: 'Clock reference the missing mortal starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.F,
        name: {
            [types_1.LanguageCodes.EN]: 'Fractures',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'Fractures are visibly open. Pieces of pipe have not moved',
        repairs: [types_1.Repairs.CIPP],
        scoringFunction: () => ({ structure: 1 }),
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        options: [
            {
                required: true,
                name: 'Type of fracture',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.FC,
                        scoringFunction: () => ({ structure: 40 }),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Fractures Circumferential',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Circumferential fractures are visibly open. Pieces of pipe have not moved',
                        options: [
                            {
                                name: 'Cicumferential fracture appears on a joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.FCJ,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Fractures Circumferential Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        description: 'Cicumferential fracture on joint',
                                    },
                                ],
                            },
                            {
                                required: true,
                                name: 'Start/end clock reference',
                                attribute: 'angle',
                                description: 'Clock reference the fractures starts and ends',
                                type: 'angleRange',
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.FL,
                        scoringFunction: () => ({ structure: 40 }),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Fractures Longitudinal',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Longitudinal fractures are visibly open. Pieces of pipe have not moved',
                        options: [
                            {
                                name: 'Longitudinal fracture appears on a joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.FLJ,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Fractures Longitudinal Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        description: 'Longitudinal fracture on joint',
                                    },
                                ],
                            },
                            {
                                required: true,
                                name: 'Fracture longitudinal starts at',
                                attribute: 'angleAt',
                                description: 'Clock reference the fractures are at',
                                type: 'angle',
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.FM,
                        scoringFunction: () => ({ structure: 80 }),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Fracture Multiple',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Multiple fractures are visibly open. Pieces of pipe have not moved',
                        options: [
                            {
                                name: 'Multiple fractures appear on a joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.FMJ,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Fracture Multiple Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        description: 'Multiple fractures on joint',
                                    },
                                ],
                            },
                            {
                                required: true,
                                name: 'Start/end clock reference',
                                attribute: 'angle',
                                description: 'Fracture multiple from/to',
                                type: 'angleRange',
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.FR,
                        scoringFunction: () => ({ structure: 40 }),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Fracture Radiating',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Radiating fractures are visibly open. Pieces of pipe have not moved',
                        options: [
                            {
                                name: 'Radiating fracture appears on a joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.FRJ,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Fracture Radiating Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        description: 'Radiating fracture on joint',
                                    },
                                ],
                            },
                            {
                                required: true,
                                name: 'Fracture radiates from',
                                attribute: 'angleAt',
                                description: 'Clock reference the fractures are at',
                                type: 'angle',
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.FS,
                        scoringFunction: () => ({ structure: 80 }),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Fracture Spiral',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Spiral fractures are visibly open. Pieces of pipe have not moved',
                        options: [
                            {
                                name: 'Spiral fractures appear on a joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.FSJ,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Fracture Spiral Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        description: 'Spiral fractures on joint',
                                    },
                                ],
                            },
                            {
                                required: true,
                                name: 'Fracture spiral from/to',
                                attribute: 'angle',
                                description: 'Clock reference the fracture starts and ends',
                                type: 'angleRange',
                            },
                        ],
                    },
                ],
            },
            {
                required: true,
                name: 'Crack in roof slab',
                attribute: 'boolean',
                description: 'Crack in roof slab',
                type: 'boolean',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.F_MSCC5_DOMESTIC,
        name: {
            [types_1.LanguageCodes.EN]: 'Fractures',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'Fractures are visibly open. Pieces of pipe have not moved',
        scoringFunction: () => ({ structure: 1 }),
        repairs: [types_1.Repairs.CIPP],
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
        options: [
            {
                required: true,
                name: 'Type of fracture',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.FC_MSCC5_DOMESTIC,
                        scoringFunction: () => ({ structure: 40 }),
                        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Fractures Circumferential',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Circumferential fractures are visibly open. Pieces of pipe have not moved',
                        options: [
                            {
                                name: 'Cicumferential fracture appears on a joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.FCJ_MSCC5_DOMESTIC,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Fractures Circumferential Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
                                        description: 'Cicumferential fracture on joint',
                                    },
                                ],
                            },
                            {
                                required: true,
                                name: 'Start/end clock reference',
                                attribute: 'angle',
                                description: 'Clock reference the fractures starts and ends',
                                type: 'angleRange',
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.FL_MSCC5_DOMESTIC,
                        scoringFunction: () => ({ structure: 40 }),
                        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Fractures Longitudinal',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Longitudinal fractures are visibly open. Pieces of pipe have not moved',
                        options: [
                            {
                                name: 'Longitudinal fracture appears on a joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.FLJ_MSCC5_DOMESTIC,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Fractures Longitudinal Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
                                        description: 'Longitudinal fracture on joint',
                                    },
                                ],
                            },
                            {
                                required: true,
                                name: 'Fracture longitudinal starts at',
                                attribute: 'angleAt',
                                description: 'Clock reference the fractures are at',
                                type: 'angle',
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.FM_MSCC5_DOMESTIC,
                        scoringFunction: () => ({ structure: 80 }),
                        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Fracture Multiple',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Multiple fractures are visibly open. Pieces of pipe have not moved',
                        options: [
                            {
                                name: 'Multiple fractures appear on a joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.FMJ_MSCC5_DOMESTIC,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Fracture Multiple Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
                                        description: 'Multiple fractures on joint',
                                    },
                                ],
                            },
                            {
                                required: true,
                                name: 'Start/end clock reference',
                                attribute: 'angle',
                                description: 'Fracture multiple from/to',
                                type: 'angleRange',
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.FR_MSCC5_DOMESTIC,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Fracture Radiating',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Radiating fractures are visibly open. Pieces of pipe have not moved',
                        options: [
                            {
                                name: 'Radiating fracture appears on a joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.FRJ_MSCC5_DOMESTIC,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Fracture Radiating Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
                                        description: 'Radiating fracture on joint',
                                    },
                                ],
                            },
                            {
                                required: true,
                                name: 'Fracture radiates from',
                                attribute: 'angleAt',
                                description: 'Clock reference the fractures are at',
                                type: 'angle',
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.FS_MSCC5_DOMESTIC,
                        scoringFunction: () => ({ structure: 80 }),
                        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Fracture Spiral',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Spiral fractures are visibly open. Pieces of pipe have not moved',
                        options: [
                            {
                                name: 'Spiral fractures appear on a joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.FSJ_MSCC5_DOMESTIC,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Fracture Spiral Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
                                        description: 'Spiral fractures on joint',
                                    },
                                ],
                            },
                            {
                                required: true,
                                name: 'Fracture spiral from/to',
                                attribute: 'angle',
                                description: 'Clock reference the fracture starts and ends',
                                type: 'angleRange',
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        code: types_1.ObservationCode.FW,
        name: {
            [types_1.LanguageCodes.EN]: 'Flow from incoming pipe',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'Flow from incoming pipe by verticle dimension',
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        options: [
            {
                required: false,
                name: 'Amount of displacement',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.FWC,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Clear flow from incoming pipe',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Clear flow from incoming pipe',
                    },
                    {
                        code: types_1.ObservationCode.FWCS,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Wrong clear flow from incoming foul pipe',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Wrong clear flow from incoming foul pipe',
                    },
                    {
                        code: types_1.ObservationCode.FWT,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Turbid flow from incoming pipe',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Turbid flow from incoming pipe',
                    },
                    {
                        code: types_1.ObservationCode.FWTF,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Wrong turbid flow flow from incoming pipe',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Wrong turbid flow flow from incoming pipe',
                    },
                ],
            },
            {
                required: true,
                name: 'Clock reference',
                attribute: 'angle',
                description: 'The clock reference the crack starts and ends at',
                type: 'angleRange',
            },
            {
                required: true,
                attribute: 'percentage',
                name: 'Amount',
                description: 'Amount as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.GP,
        name: {
            [types_1.LanguageCodes.EN]: 'General photography',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
        description: 'General photography',
    },
    {
        code: types_1.ObservationCode.H,
        repairs: [types_1.Repairs.CIPP_OR_EXCAVATION],
        scoringFunction: ({ attributes: { angle = [0, 0] } }) => {
            const start = angle[0];
            const end = angle[1];
            const coverage = start <= end ? end - start + 1 : 12 - (start - end) + 1;
            if (coverage <= 3) {
                return {
                    structure: 80,
                };
            }
            else {
                return {
                    structure: 165,
                };
            }
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
        name: {
            [types_1.LanguageCodes.EN]: 'Holes',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'Section of pipe fabric is missing',
        options: [
            {
                required: true,
                name: 'Hole start/end',
                attribute: 'angle',
                description: 'The clock reference the crack starts and ends at',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.HS,
        name: {
            [types_1.LanguageCodes.EN]: 'Hazardous atmosphere hydrogen defiency',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        description: 'Methane',
        options: [
            {
                required: true,
                name: 'Deficiency',
                attribute: 'percentage',
                description: 'Hydrogen',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.GZ,
        name: {
            [types_1.LanguageCodes.EN]: 'Hazardous atmosphere other defiency',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        description: 'Other',
        options: [
            {
                required: true,
                name: 'Deficiency',
                attribute: 'percentage',
                description: 'Other',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.ME,
        name: {
            [types_1.LanguageCodes.EN]: 'Hazardous atmosphere methane defiency',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        description: 'Hydrogen',
        options: [
            {
                required: true,
                name: 'Deficiency',
                attribute: 'percentage',
                description: 'Methane',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.I,
        scoringFunction: () => ({}),
        repairs: [types_1.Repairs.CIPP],
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        name: {
            [types_1.LanguageCodes.EN]: 'Infiltration',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'Water is infiltrating the pipe by a joint of other defect',
        options: [
            {
                required: false,
                name: 'Type of infilatration',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.IS,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Infiltration Seeping',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Infiltration is seeping',
                        options: [
                            {
                                name: 'Infiltration appears on a joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.ISJ,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Infiltration Seeping Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        description: 'Infiltration seeping on joint',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.ID,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Infiltration Dripping',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Infiltration is dripping',
                        options: [
                            {
                                name: 'Infiltration appears on a joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.IDJ,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Infiltration Dripping Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        description: 'Infiltration dripping on joint',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.IR,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Infiltration Running',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Infiltration is running',
                        options: [
                            {
                                name: 'Infiltration appears on a joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.IRJ,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Infiltration Running Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        description: 'Infiltrationrunningdripping on joint',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.IG,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Infiltration Gushing',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Infiltration is gushing',
                        options: [
                            {
                                name: 'Infiltration appears on a joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.IGJ,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Infiltration Gushing Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        description: 'Infiltration gushing on joint',
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                required: true,
                name: 'Infiltration start/end',
                attribute: 'angle',
                description: 'Clock reference of the infiltration',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.I_MSCC5_DOMESTIC,
        repairs: [types_1.Repairs.CIPP],
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
        name: {
            [types_1.LanguageCodes.EN]: 'Infiltration',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'Water is infiltrating the pipe by a joint of other defect',
        options: [
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Clock reference of the infiltration',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.ING,
        name: {
            [types_1.LanguageCodes.EN]: 'Ingress of Soil',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'Ingress of soil',
        repairs: [types_1.Repairs.COMPLEX_REPAIR],
        scoringFunction: ({ attributes: { percentage = 0 } }) => {
            if (percentage < 5)
                return { service: 1 };
            if (percentage < 20)
                return { service: 2 };
            if (percentage < 50)
                return { service: 5 };
            if (percentage < 75)
                return { service: 8 };
            return { service: 10 };
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        options: [
            {
                name: 'Type of ingress',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.INGS,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Ingress of Sand',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        description: 'Sand',
                        options: [
                            {
                                name: 'Sand ingress appears on a joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.INGSJ,
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Ingress of Sand Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        description: 'Sand ingress on joint',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.INGP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Ingress of Peat',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        description: 'Peat',
                        options: [
                            {
                                name: 'Peat ingress appears on a joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.INGPJ,
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Ingress of Peat Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        description: 'Peat ingress on joint',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.INGF,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Ingress Fine',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        description: 'Fine',
                        options: [
                            {
                                name: 'Fine ingress appears on a joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.INGFJ,
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Ingress Fine Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        description: 'Fine ingress on joint',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.INGG,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Ingress Gravel',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        description: 'Gravel',
                        options: [
                            {
                                name: 'Gravel ingress appears on a joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.INGGJ,
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Ingress Gravel Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        description: 'Gravel ingress on joint',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.INGZ,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Ingress Other',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        description: 'Other',
                        options: [
                            {
                                name: 'Other ingress appears on a joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.INGZJ,
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Ingress Other Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        description: 'Other ingress on joint',
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss',
                type: 'percentage',
            },
            {
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Clock reference the ingress starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.JD,
        name: {
            [types_1.LanguageCodes.EN]: 'Joint Displaced',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        repairs: [types_1.Repairs.CIPP],
        description: 'Pipe has moved at join, perpendicular to axis of pipe',
        // This needs scoring. Do we require percentage? Rather than pipe thickness/displacement.
        scoringFunction: (observation, section) => {
            const displacement = observation.attributes['number'];
            if (displacement) {
                if ((0, types_1.isCircular)(section.attributes.shape) &&
                    section.attributes.diameter) {
                    const displacementPercentage = (displacement / section.attributes.diameter) * 100;
                    if (displacementPercentage < 5) {
                        return {
                            structure: 1,
                            service: 2,
                        };
                    }
                    else if (displacementPercentage <= 10) {
                        return {
                            structure: 40,
                            service: 2,
                        };
                    }
                    else if (displacementPercentage <= 19) {
                        return {
                            structure: 80,
                            service: 4,
                        };
                    }
                    else if (displacementPercentage <= 20) {
                        return {
                            structure: 80,
                            service: 10,
                        };
                    }
                    else if (displacementPercentage <= 75) {
                        return {
                            structure: 165,
                            service: 15,
                        };
                    }
                    else {
                        return {
                            structure: 165,
                            service: 20,
                        };
                    }
                }
                else if (!(0, types_1.isCircular)(section.attributes.shape) &&
                    section.attributes.height) {
                    const displacementPercentage = (displacement / section.attributes.height) * 100;
                    if (displacementPercentage < 5) {
                        return {
                            structure: 1,
                            service: 2,
                        };
                    }
                    else if (displacementPercentage <= 10) {
                        return {
                            structure: 40,
                            service: 2,
                        };
                    }
                    else if (displacementPercentage <= 19) {
                        return {
                            structure: 80,
                            service: 4,
                        };
                    }
                    else if (displacementPercentage <= 20) {
                        return {
                            structure: 80,
                            service: 10,
                        };
                    }
                    else if (displacementPercentage <= 75) {
                        return {
                            structure: 165,
                            service: 15,
                        };
                    }
                    else
                        return {
                            structure: 165,
                            service: 20,
                        };
                }
                else {
                    return { structure: 1 };
                }
            }
            else
                return { structure: 1 };
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        options: [
            {
                required: false,
                name: 'Amount of displacement',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.JDM,
                        scoringFunction: () => ({ service: 2, structure: 1 }),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Joint Displaced Medium',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Pipe has moved between 1 and 1.5 times the pipe wall thickness',
                    },
                    {
                        code: types_1.ObservationCode.JDL,
                        scoringFunction: () => ({ service: 5, structure: 2 }),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Joint Displaced Large',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Pipe has moved more than 1.5 times the pipe wall thickness',
                    },
                ],
            },
            {
                required: true,
                name: 'Displacement',
                attribute: 'number',
                description: 'Displacement measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Pipe wall thickness',
                attribute: 'thickness',
                description: 'Pipe wall thickness',
                type: 'distanceShort',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.JD_MSCC5_DOMESTIC,
        repairs: [types_1.Repairs.CIPP],
        name: {
            [types_1.LanguageCodes.EN]: 'Joint Displaced',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'Pipe has moved at join, perpendicular to axis of pipe',
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
        options: [
            {
                required: false,
                name: 'Amount of displacement',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.JDM_MSCC5_DOMESTIC,
                        scoringFunction: () => ({ service: 2, structure: 1 }),
                        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Joint Displaced Medium',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Pipe has moved between 1 and 1.5 times the pipe wall thickness',
                    },
                    {
                        code: types_1.ObservationCode.JDL_MSCC5_DOMESTIC,
                        scoringFunction: () => ({ service: 5, structure: 2 }),
                        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Joint Displaced Large',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Pipe has moved more than 1.5 times the pipe wall thickness',
                    },
                ],
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.JN_MSCC5_DOMESTIC,
        name: {
            [types_1.LanguageCodes.EN]: 'Junction',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'Lateral pipe was installed at construction',
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
        options: [
            {
                required: true,
                name: 'Clock reference',
                attribute: 'angleAt',
                description: 'Clock reference the junction starts and ends',
                type: 'angle',
            },
            {
                required: true,
                name: 'Diameter',
                attribute: 'number',
                description: 'Diameter of junction',
                type: 'distanceShort',
                quickOptions: [0.1, 0.15, 0.225],
            },
        ],
    },
    {
        code: types_1.ObservationCode.JN,
        name: {
            [types_1.LanguageCodes.EN]: 'Junction',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'Lateral pipe was installed at construction',
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        options: [
            {
                name: 'Amount',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.JNC,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Juntion Closed',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Juntion closed',
                    },
                    {
                        code: types_1.ObservationCode.JNB,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Juntion Blocked',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Juntion blocked',
                    },
                ],
            },
            {
                required: true,
                name: 'Junction at',
                description: 'Clock reference the junction starts',
                attribute: 'angleAt',
                type: 'angle',
            },
            {
                required: true,
                name: 'Diameter',
                attribute: 'number',
                description: 'Diameter of junction',
                type: 'distanceShort',
                quickOptions: [0.1, 0.15, 0.225],
            },
        ],
    },
    {
        code: types_1.ObservationCode.JX_MSCC5_DOMESTIC,
        repairs: [types_1.Repairs.CIPP_OR_EXCAVATION],
        name: {
            [types_1.LanguageCodes.EN]: 'Junction Defective',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'Lateral pipe is defective due to factors including cracks, fractures, obstruction, position, ...etc',
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
        options: [
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angleAt',
                description: 'Clock reference the junction defect',
                type: 'angle',
            },
            {
                required: true,
                name: 'Diameter',
                attribute: 'number',
                description: 'Diameter of junction',
                type: 'distanceShort',
                quickOptions: [0.1, 0.15, 0.225],
            },
        ],
    },
    {
        code: types_1.ObservationCode.JX,
        repairs: [types_1.Repairs.CIPP_OR_EXCAVATION],
        name: {
            [types_1.LanguageCodes.EN]: 'Junction Defective',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'Lateral pipe is defective due to factors including cracks, fractures, obstruction, position, ...etc',
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        options: [
            {
                name: 'type',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.JXB,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Juntion blocked',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Juntion blocked',
                    },
                    {
                        code: types_1.ObservationCode.JXP,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Juntion incorrect position',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Juntion incorrect position',
                    },
                    {
                        code: types_1.ObservationCode.JXD,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Juntion damaged',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Juntion damaged',
                    },
                    {
                        code: types_1.ObservationCode.JXZ,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Juntion damaged other',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Juntion damaged otherJuntion damaged other',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angleAt',
                description: 'Clock reference the junction defect',
                type: 'angle',
            },
            {
                required: true,
                name: 'Diameter',
                attribute: 'number',
                description: 'Diameter of junction',
                type: 'distanceShort',
                quickOptions: [0.1, 0.15, 0.225],
            },
        ],
    },
    {
        code: types_1.ObservationCode.L,
        name: {
            [types_1.LanguageCodes.EN]: 'Line Deviation',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
        description: 'Line Deviates',
        options: [
            {
                required: true,
                name: 'Direction of deviation',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.LD,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Line Deviates Downwards',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [
                            types_1.ProjectType.MSCC5_COMMERERCIAL,
                            types_1.ProjectType.MSCC5_DOMESTIC,
                        ],
                        description: 'Line deviated downwards',
                        options: [
                            {
                                required: false,
                                name: 'Amount',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.LDQ,
                                        scoringFunction: () => ({}),
                                        projectTypes: [
                                            types_1.ProjectType.MSCC5_COMMERERCIAL,
                                            types_1.ProjectType.MSCC5_DOMESTIC,
                                        ],
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Line Deviates Downwards Quarter',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        description: 'Quarter',
                                    },
                                    {
                                        code: types_1.ObservationCode.LDH,
                                        scoringFunction: () => ({}),
                                        projectTypes: [
                                            types_1.ProjectType.MSCC5_COMMERERCIAL,
                                            types_1.ProjectType.MSCC5_DOMESTIC,
                                        ],
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Line Deviates Downwards Half',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        description: 'Half',
                                    },
                                    {
                                        code: types_1.ObservationCode.LDF,
                                        scoringFunction: () => ({}),
                                        projectTypes: [
                                            types_1.ProjectType.MSCC5_COMMERERCIAL,
                                            types_1.ProjectType.MSCC5_DOMESTIC,
                                        ],
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Line Deviates Downwards Full',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        description: 'Full',
                                    },
                                ],
                            },
                            {
                                required: false,
                                name: 'Continuous',
                                attribute: 'continuous',
                                description: 'Continuous',
                                type: 'continuous',
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.LL,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Line Deviates Left',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [
                            types_1.ProjectType.MSCC5_COMMERERCIAL,
                            types_1.ProjectType.MSCC5_DOMESTIC,
                        ],
                        description: 'Line deviated leftwards',
                        options: [
                            {
                                required: false,
                                name: 'Amount',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.LLQ,
                                        scoringFunction: () => ({}),
                                        projectTypes: [
                                            types_1.ProjectType.MSCC5_COMMERERCIAL,
                                            types_1.ProjectType.MSCC5_DOMESTIC,
                                        ],
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Line Deviates Left Quarter',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        description: 'Quarter',
                                    },
                                    {
                                        code: types_1.ObservationCode.LLH,
                                        scoringFunction: () => ({}),
                                        projectTypes: [
                                            types_1.ProjectType.MSCC5_COMMERERCIAL,
                                            types_1.ProjectType.MSCC5_DOMESTIC,
                                        ],
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Line Deviates Left Half',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        description: 'Half',
                                    },
                                    {
                                        code: types_1.ObservationCode.LLF,
                                        scoringFunction: () => ({}),
                                        projectTypes: [
                                            types_1.ProjectType.MSCC5_COMMERERCIAL,
                                            types_1.ProjectType.MSCC5_DOMESTIC,
                                        ],
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Line Deviates Left Full',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        description: 'Full',
                                    },
                                ],
                            },
                            {
                                required: false,
                                name: 'Continuous',
                                attribute: 'continuous',
                                description: 'Continuous',
                                type: 'continuous',
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.LR,
                        projectTypes: [
                            types_1.ProjectType.MSCC5_COMMERERCIAL,
                            types_1.ProjectType.MSCC5_DOMESTIC,
                        ],
                        scoringFunction: () => ({}),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Line Deviates Right',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Line deviated rightwards',
                        options: [
                            {
                                required: false,
                                name: 'Amount',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.LRQ,
                                        scoringFunction: () => ({}),
                                        projectTypes: [
                                            types_1.ProjectType.MSCC5_COMMERERCIAL,
                                            types_1.ProjectType.MSCC5_DOMESTIC,
                                        ],
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Line Deviates Right Quarter',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        description: 'Quarter',
                                    },
                                    {
                                        code: types_1.ObservationCode.LRH,
                                        scoringFunction: () => ({}),
                                        projectTypes: [
                                            types_1.ProjectType.MSCC5_COMMERERCIAL,
                                            types_1.ProjectType.MSCC5_DOMESTIC,
                                        ],
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Line Deviates Right Half',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        description: 'Half',
                                    },
                                    {
                                        code: types_1.ObservationCode.LRF,
                                        scoringFunction: () => ({}),
                                        projectTypes: [
                                            types_1.ProjectType.MSCC5_COMMERERCIAL,
                                            types_1.ProjectType.MSCC5_DOMESTIC,
                                        ],
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Line Deviates Right Full',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        description: 'Full',
                                    },
                                ],
                            },
                            {
                                required: false,
                                name: 'Continuous',
                                attribute: 'continuous',
                                description: 'Continuous',
                                type: 'continuous',
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.LU,
                        projectTypes: [
                            types_1.ProjectType.MSCC5_COMMERERCIAL,
                            types_1.ProjectType.MSCC5_DOMESTIC,
                        ],
                        scoringFunction: () => ({}),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Line Deviates Upwards',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Line deviated downwards',
                        options: [
                            {
                                required: false,
                                name: 'Amount',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.LUQ,
                                        scoringFunction: () => ({}),
                                        projectTypes: [
                                            types_1.ProjectType.MSCC5_COMMERERCIAL,
                                            types_1.ProjectType.MSCC5_DOMESTIC,
                                        ],
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Line Deviates Upwards Quarter',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        description: 'Quarter',
                                    },
                                    {
                                        code: types_1.ObservationCode.LUH,
                                        scoringFunction: () => ({}),
                                        projectTypes: [
                                            types_1.ProjectType.MSCC5_COMMERERCIAL,
                                            types_1.ProjectType.MSCC5_DOMESTIC,
                                        ],
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Line Deviates Upwards Half',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        description: 'Half',
                                    },
                                    {
                                        code: types_1.ObservationCode.LUF,
                                        scoringFunction: () => ({}),
                                        projectTypes: [
                                            types_1.ProjectType.MSCC5_COMMERERCIAL,
                                            types_1.ProjectType.MSCC5_DOMESTIC,
                                        ],
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Line Deviates Upwards Full',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        description: 'Full',
                                    },
                                ],
                            },
                            {
                                required: false,
                                name: 'Continuous',
                                attribute: 'continuous',
                                description: 'Continuous',
                                type: 'continuous',
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        code: types_1.ObservationCode.LC,
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        name: {
            [types_1.LanguageCodes.EN]: 'Lining Changes',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'Lining material has changed',
        options: [
            {
                required: true,
                name: 'Lining Change',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.LCCF,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Lining Changes - Close Fit',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Lining material change, Lining change to close-fit',
                    },
                    {
                        code: types_1.ObservationCode.LCCIP,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Lining Changes - Cured in place',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Lining change to cured in place lining',
                    },
                    {
                        code: types_1.ObservationCode.LCCP,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Lining Changes - Lining with continuous pipes',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Lining change of lining with continuous pipes',
                    },
                    {
                        code: types_1.ObservationCode.LCDP,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Lining Changes - Discrete pipes',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Lining change to lining with discrete pipes',
                    },
                    {
                        code: types_1.ObservationCode.LCM,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Lining inserted during manufacture',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Lining change to lining inserted during manufacture',
                    },
                    {
                        code: types_1.ObservationCode.LCSEG,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Lining Changes - Segmental',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Lining Change to Segmental',
                    },
                    {
                        code: types_1.ObservationCode.LCSP,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Lining Changes - Sprayed lining',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Lining change to sprayed lining',
                    },
                    {
                        code: types_1.ObservationCode.LCSW,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Lining Changes - Spirally wound lining',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Lining change to spirally wound lining',
                    },
                    {
                        code: types_1.ObservationCode.LCZ,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Lining Changes - Other',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Lining change to other',
                    },
                ],
            },
        ],
    },
    {
        code: types_1.ObservationCode.LC_MSCC5_DOMESTIC,
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
        name: {
            [types_1.LanguageCodes.EN]: 'Lining Changes',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'Lining material has changed',
    },
    {
        code: types_1.ObservationCode.LX_MSCC5_DOMESTIC,
        repairs: [types_1.Repairs.COMPLEX_REPAIR],
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
        name: {
            [types_1.LanguageCodes.EN]: 'Lining Defective',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'Lining is defective',
        options: [
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.LX,
        repairs: [types_1.Repairs.COMPLEX_REPAIR],
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        name: {
            [types_1.LanguageCodes.EN]: 'Lining Defective',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'Lining is defective',
        options: [
            {
                required: true,
                name: 'Type of defective lining',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.LXD,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Defective Lining, Lining Detached',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Defective Lining, Lining Detached',
                    },
                    {
                        code: types_1.ObservationCode.LXE,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Defective lining,defective end of lining',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Defective lining,defective end of lining',
                    },
                    {
                        code: types_1.ObservationCode.LXCZ,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Defective lining,other lining defect',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Defective lining,other lining defect',
                    },
                    {
                        code: types_1.ObservationCode.LXCX,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Defective lining, lining defect at connection',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Defective lining, lining defect at connection',
                    },
                    {
                        code: types_1.ObservationCode.LXC,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Defective lining,discolouration of lining',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Defective lining,discolouration of lining',
                    },
                    {
                        code: types_1.ObservationCode.LXW,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Wrinkled',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Wrinkled',
                        options: [
                            {
                                required: true,
                                name: 'Amount',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.LXWL,
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        scoringFunction: () => ({}),
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Defective lining,longitudinal wrinkled lining',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        description: 'Defective lining,longitudinal wrinkled lining',
                                    },
                                    {
                                        code: types_1.ObservationCode.LXWC,
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        scoringFunction: () => ({}),
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Defective lining,circumferential wrinkled lining',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        description: 'Defective lining,circumferential wrinkled lining',
                                    },
                                    {
                                        code: types_1.ObservationCode.LXWH,
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        scoringFunction: () => ({}),
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Defective lining,spiral wrinkled lining',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        description: 'Defective lining,spiral wrinkled lining',
                                    },
                                    {
                                        code: types_1.ObservationCode.LXWM,
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        scoringFunction: () => ({}),
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Defective lining,complex (multiple) wrinkled lining',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        description: 'Defective lining,complex (multiple) wrinkled lining',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.LXB,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Defective lining,blistered lining or internal bulge',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Defective lining,blistered lining or internal bulge',
                    },
                    {
                        code: types_1.ObservationCode.LXEB,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Defect lining,external bulge',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Defect lining,external bulge',
                    },
                    {
                        code: types_1.ObservationCode.LXES,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Defect lining, end not sealed',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Defect lining, end not sealed',
                    },
                    {
                        code: types_1.ObservationCode.LXF,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Defect lining, seperated film',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Defect lining, seperated film',
                    },
                    {
                        code: types_1.ObservationCode.LXH,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Defect lining, hole inlining',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Defective lining, lining defect at connection',
                    },
                    {
                        code: types_1.ObservationCode.LXR,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Defect lining, resin missing',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Defect lining, resin missing',
                    },
                    {
                        code: types_1.ObservationCode.LXZ,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Defect lining other',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Defect lining other',
                    },
                    {
                        code: types_1.ObservationCode.LXS,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Crack, split or weld failiure',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Defect lining, crack or split in lining or weld failure',
                        options: [
                            {
                                required: false,
                                name: 'Type',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.LXSC,
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        scoringFunction: () => ({}),
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Defect lining, circumferential crack or split in lining or weld failure',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        description: 'Defective lining, lining defect at connection',
                                    },
                                    {
                                        code: types_1.ObservationCode.LXSH,
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        scoringFunction: () => ({}),
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Defect lining, spiral crack or split in lining or weld failure',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        description: 'Defect lining, spiral crack or split in lining or weld failure',
                                    },
                                    {
                                        code: types_1.ObservationCode.LXSL,
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        scoringFunction: () => ({}),
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Defect lining, longitudinal crack or split in lining or weld failure',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        description: 'Defect lining, longitudinal crack or split in lining or weld failure',
                                    },
                                    {
                                        code: types_1.ObservationCode.LXSM,
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        scoringFunction: () => ({}),
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Defect lining, complex crack or split in lining or weld failure',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        description: 'Defect lining, complex crack or split in lining or weld failure',
                                    },
                                    {
                                        code: types_1.ObservationCode.LXSF,
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        scoringFunction: () => ({}),
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Defect lining, lining appears soft',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        description: 'Defect lining, lining appears soft',
                                    },
                                    {
                                        code: types_1.ObservationCode.LXSS,
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        scoringFunction: () => ({}),
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Defective lining, seperated seam cover',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        description: 'Defective lining, seperated seam cover',
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Lining defect starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.MC_MSCC5_DOMESTIC,
        name: {
            [types_1.LanguageCodes.EN]: 'Material Change',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
        description: 'Pipe material has changed',
    },
    {
        code: types_1.ObservationCode.MC,
        name: {
            [types_1.LanguageCodes.EN]: 'Material Change',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        description: 'Pipe material has changed',
        options: [
            {
                required: true,
                name: 'Type',
                type: 'code',
                schema: [
                    {
                        name: {
                            [types_1.LanguageCodes.EN]: 'Material Change Asbestos Cement',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        code: types_1.ObservationCode.MCAC,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        description: 'Material change to asbestos cement',
                    },
                    {
                        name: {
                            [types_1.LanguageCodes.EN]: 'Material Change Brick',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        code: types_1.ObservationCode.MCBR,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        description: 'Material change to brick',
                    },
                    {
                        name: {
                            [types_1.LanguageCodes.EN]: 'Material Change Cast Iron',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        code: types_1.ObservationCode.MCCI,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        description: 'Material change to cast iron',
                    },
                    {
                        name: {
                            [types_1.LanguageCodes.EN]: 'Material Change Concrete',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        code: types_1.ObservationCode.MCCO,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        description: 'Material change to concrete',
                    },
                    {
                        name: {
                            [types_1.LanguageCodes.EN]: 'Material Change Concrete Segments',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        code: types_1.ObservationCode.MCCS,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        description: 'Material change to concrete segments',
                    },
                    {
                        name: {
                            [types_1.LanguageCodes.EN]: 'Material Change Ductile Iron',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        code: types_1.ObservationCode.MCDI,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        description: 'Material change to ductile iron',
                    },
                    {
                        name: {
                            [types_1.LanguageCodes.EN]: 'Material Change Epoxy',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        code: types_1.ObservationCode.MCEP,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        description: 'Material change to epoxy',
                    },
                    {
                        name: {
                            [types_1.LanguageCodes.EN]: 'Material Change Fibre Cement',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        code: types_1.ObservationCode.MCFC,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        description: 'Material change to fibre cement',
                    },
                    {
                        name: {
                            [types_1.LanguageCodes.EN]: 'Material Change Fibre Reinforced Plastic',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        code: types_1.ObservationCode.MCFRP,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        description: 'Material change to fibre Reinforced Plastic',
                    },
                    {
                        name: {
                            [types_1.LanguageCodes.EN]: 'Material Change Grey Cast Iron',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        code: types_1.ObservationCode.MCGCI,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        description: 'Material change to grey cast iron',
                    },
                    {
                        name: {
                            [types_1.LanguageCodes.EN]: 'Material Change Mixed Material',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        code: types_1.ObservationCode.MCMX,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        description: 'Material change to mixed material',
                    },
                    {
                        name: {
                            [types_1.LanguageCodes.EN]: 'Material Change Masonry (Randomly coursed)',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        code: types_1.ObservationCode.MCMAR,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        description: 'Material change to masonry (randomly coursed)',
                    },
                    {
                        name: {
                            [types_1.LanguageCodes.EN]: 'Material Change Masonry (Regularly Coursed)',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        code: types_1.ObservationCode.MCMAC,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        description: 'Material change to masonry (regularly coursed)',
                    },
                    {
                        name: {
                            [types_1.LanguageCodes.EN]: 'Material Change Other',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        code: types_1.ObservationCode.MCZ,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        description: 'Material change to other',
                    },
                    {
                        name: {
                            [types_1.LanguageCodes.EN]: 'Material Change Polyester',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        code: types_1.ObservationCode.MCPS,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        description: 'Material change to polyester',
                    },
                    {
                        name: {
                            [types_1.LanguageCodes.EN]: 'Material Change Polyethylene',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        code: types_1.ObservationCode.MCPE,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        description: 'Material change to polyethylene',
                    },
                    {
                        name: {
                            [types_1.LanguageCodes.EN]: 'Material Change Pitch Fibre',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        code: types_1.ObservationCode.MCPF,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        description: 'Material change to pitch fibre',
                    },
                    {
                        name: {
                            [types_1.LanguageCodes.EN]: 'Material Change Polypropylene',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        code: types_1.ObservationCode.MCPP,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        description: 'Material change to polypropylene',
                    },
                    {
                        name: {
                            [types_1.LanguageCodes.EN]: 'Material Change Polyvinyl Chloride',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        code: types_1.ObservationCode.MCPVC,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        description: 'Material change to polyvinyl chloride',
                    },
                    {
                        name: {
                            [types_1.LanguageCodes.EN]: 'Material Change Reinforced Concrete',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        code: types_1.ObservationCode.MCRC,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        description: 'Material change to reinforced concrete',
                    },
                    {
                        name: {
                            [types_1.LanguageCodes.EN]: 'Material Change Steel',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        code: types_1.ObservationCode.MCST,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        description: 'Material change to steel',
                    },
                    {
                        name: {
                            [types_1.LanguageCodes.EN]: 'Material Change Unidentified Material',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        code: types_1.ObservationCode.MCX,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        description: 'Material change to unidentified material',
                    },
                    {
                        name: {
                            [types_1.LanguageCodes.EN]: 'Material Change Unidentified type of iron or steel',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        code: types_1.ObservationCode.MCXP,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        description: 'Material change to unidentified type of iron or steel',
                    },
                    {
                        code: types_1.ObservationCode.MCXI,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Material Change Unidentified Type of Plastic',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        description: 'Material change to unidentified type of plastic',
                    },
                    {
                        code: types_1.ObservationCode.MCVC,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Material Change Vitrified Clay',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        description: 'Material change to vitrified clay',
                    },
                ],
            },
        ],
    },
    {
        code: types_1.ObservationCode.MB,
        repairs: [types_1.Repairs.COMPLEX_REPAIR],
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        scoringFunction: () => ({}),
        name: {
            [types_1.LanguageCodes.EN]: 'Missing bricks',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'Missing bricks',
        options: [
            {
                required: true,
                name: 'Missing brick start/end',
                attribute: 'angle',
                description: 'Angle missing bricks starts and ends',
                type: 'angleRange',
            },
            {
                required: true,
                name: 'Single brick',
                attribute: 'boolean',
                description: 'Single brick is missing',
                type: 'boolean',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.OB_MSCC5_DOMESTIC,
        repairs: [types_1.Repairs.CLEANSING_AND_DESCALING],
        name: {
            [types_1.LanguageCodes.EN]: 'Obstruction/Obstacle',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        scoringFunction: () => ({ service: 10 }),
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
        description: 'An obstruction or obstacle is affecting the flow through the pipe',
        options: [
            {
                attribute: 'percentage',
                name: 'Amount',
                description: 'Obstruction blockage as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.OB,
        repairs: [types_1.Repairs.CLEANSING_AND_DESCALING],
        name: {
            [types_1.LanguageCodes.EN]: 'Obstruction/Obstacle',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        scoringFunction: () => ({ service: 10 }),
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        description: 'An obstruction or obstacle is affecting the flow through the pipe',
        options: [
            {
                name: 'Type of obsticle',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.OBB,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Other obsticles, brick or masonry',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        description: 'Other obsticles,brick or masonry',
                    },
                    {
                        code: types_1.ObservationCode.OBM,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Other obsticles,pipe material',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        description: 'Other obsticles,pipe material',
                    },
                    {
                        code: types_1.ObservationCode.OBX,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Other obsticles,other object',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        description: 'Other obsticles,other object',
                    },
                    {
                        code: types_1.ObservationCode.OBI,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Other obsticles protruding through wall',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        description: 'Other obsticles protruding through wall',
                    },
                    {
                        code: types_1.ObservationCode.OBC,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Other obsticles through connection/junction',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        description: 'Other obsticles through connection/junction',
                    },
                    {
                        code: types_1.ObservationCode.OBP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Other obsticles external pipe or cable',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        description: 'Other obsticles external pipe or cable',
                    },
                    {
                        code: types_1.ObservationCode.OBS,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Other obsticles built in to structure',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        description: 'Other obsticles built in to structure',
                    },
                    {
                        code: types_1.ObservationCode.OBZ,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Other obsticles',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        description: 'Other obsticles',
                    },
                ],
            },
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Clock reference the obsticle starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.OD,
        name: {
            [types_1.LanguageCodes.EN]: 'Hazardous atmosphere oxygen defiency',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        description: 'Hazardous atmosphere oxygen defiency',
        options: [
            {
                required: true,
                name: 'Deficiency',
                attribute: 'percentage',
                description: 'Oxygen defiency',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.OJM_MSCC5_DOMESTIC,
        repairs: [types_1.Repairs.CIPP],
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
        scoringFunction: () => ({ structure: 1 }),
        name: {
            [types_1.LanguageCodes.EN]: 'Open Joint Medium',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'Pipe has moved between 1 and 1.5 times the pipe wall thickness',
        options: [
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.OJL_MSCC5_DOMESTIC,
        repairs: [types_1.Repairs.CIPP],
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
        scoringFunction: () => ({ structure: 2 }),
        name: {
            [types_1.LanguageCodes.EN]: 'Open Joint Large',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'Pipe has moved more than 1.5 times the pipe wall thickness',
        options: [
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.OJ,
        repairs: [types_1.Repairs.CIPP],
        name: {
            [types_1.LanguageCodes.EN]: 'Open Joint',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        scoringFunction: (observation, section) => {
            const displacement = observation.attributes['number'];
            if (displacement) {
                if ((0, types_1.isCircular)(section.attributes.shape) &&
                    section.attributes.diameter) {
                    const displacementPercentage = (displacement / section.attributes.diameter) * 100;
                    if (displacementPercentage < 5) {
                        return {
                            structure: 1,
                        };
                    }
                    else if (displacementPercentage <= 10) {
                        return {
                            structure: 80,
                        };
                    }
                    else {
                        return {
                            structure: 165,
                        };
                    }
                }
                else if (!(0, types_1.isCircular)(section.attributes.shape) &&
                    section.attributes.height) {
                    const displacementPercentage = (displacement / section.attributes.height) * 100;
                    if (displacementPercentage < 5) {
                        return {
                            structure: 1,
                        };
                    }
                    else if (displacementPercentage <= 10) {
                        return {
                            structure: 80,
                        };
                    }
                    else
                        return {
                            structure: 165,
                        };
                }
                else {
                    return { structure: 1 };
                }
            }
            else
                return { structure: 1 };
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        description: 'Pipe has moved at joint, along the axis of pipe',
        options: [
            {
                name: 'Amount',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.OJM,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({ structure: 1 }),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Open Joint Medium',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Pipe has moved between 1 and 1.5 times the pipe wall thickness',
                    },
                    {
                        code: types_1.ObservationCode.OJL,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({ structure: 2 }),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Open Joint Large',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Pipe has moved more than 1.5 times the pipe wall thickness',
                    },
                ],
            },
            {
                required: true,
                name: 'Displacement',
                attribute: 'number',
                description: 'Displacement measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Pipe wall thickness',
                attribute: 'thickness',
                description: 'Pipe wall thickness',
                type: 'distanceShort',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.PC,
        name: {
            [types_1.LanguageCodes.EN]: 'Pipe Length Changes',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC, types_1.ProjectType.MSCC5_COMMERERCIAL],
        description: 'Length of individual pipe changes',
        scoringFunction: () => ({}),
        options: [
            {
                required: true,
                name: 'New length',
                attribute: 'length',
                description: 'Length of pipe changes at this point',
                type: 'distanceShort',
            },
        ],
    },
    {
        code: types_1.ObservationCode.PP,
        name: {
            [types_1.LanguageCodes.EN]: 'Porous pipe material',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        description: 'Pipe  material is porous',
        scoringFunction: () => ({}),
        options: [
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Clock reference the repair point starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.R,
        name: {
            [types_1.LanguageCodes.EN]: 'Roots',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        repairs: [types_1.Repairs.ROOT_CUTTING],
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
        description: 'evidence of root ingress',
        options: [
            {
                name: 'Type of roots',
                type: 'code',
                required: true,
                schema: [
                    {
                        code: types_1.ObservationCode.RF,
                        repairs: [types_1.Repairs.CIPP],
                        projectTypes: [
                            types_1.ProjectType.MSCC5_COMMERERCIAL,
                            types_1.ProjectType.MSCC5_DOMESTIC,
                        ],
                        scoringFunction: () => ({ service: 1 }),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Root Fine',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Root Fine',
                        options: [
                            {
                                name: 'Root fine appears on a joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.RFJ,
                                        projectTypes: [
                                            types_1.ProjectType.MSCC5_COMMERERCIAL,
                                            types_1.ProjectType.MSCC5_DOMESTIC,
                                        ],
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Roots Fine Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        description: 'Root fine on joint',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.RM,
                        repairs: [types_1.Repairs.ROOT_CUTTING],
                        projectTypes: [
                            types_1.ProjectType.MSCC5_COMMERERCIAL,
                            types_1.ProjectType.MSCC5_DOMESTIC,
                        ],
                        scoringFunction: ({ attributes: { percentage = 0 } }) => {
                            if (percentage < 5)
                                return { service: 2 };
                            if (percentage < 20)
                                return { service: 4 };
                            if (percentage < 50)
                                return { service: 10 };
                            if (percentage <= 75)
                                return { service: 15 };
                            else
                                return {
                                    service: 20,
                                };
                        },
                        name: {
                            [types_1.LanguageCodes.EN]: 'Root Mass',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Root Mass',
                        options: [
                            {
                                name: 'Root mass appears on a joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.RMJ,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Root Mass Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [
                                            types_1.ProjectType.MSCC5_COMMERERCIAL,
                                            types_1.ProjectType.MSCC5_DOMESTIC,
                                        ],
                                        description: 'Root mass on joint',
                                    },
                                ],
                            },
                            {
                                attribute: 'percentage',
                                name: 'Cross sectional loss',
                                description: 'Cross sectional loss as a percentage',
                                type: 'percentage',
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.RT,
                        projectTypes: [
                            types_1.ProjectType.MSCC5_COMMERERCIAL,
                            types_1.ProjectType.MSCC5_DOMESTIC,
                        ],
                        scoringFunction: () => ({ service: 5 }),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Root Tap',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Root Tap',
                        options: [
                            {
                                name: 'Root tap appears on a joint',
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.RTJ,
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Root Tap Joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        scoringFunction: () => ({}),
                                        projectTypes: [
                                            types_1.ProjectType.MSCC5_COMMERERCIAL,
                                            types_1.ProjectType.MSCC5_DOMESTIC,
                                        ],
                                        description: 'Root tap on joint',
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.REM,
        name: {
            [types_1.LanguageCodes.EN]: 'Remark',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
        description: 'General remark',
    },
    {
        code: types_1.ObservationCode.RP,
        name: {
            [types_1.LanguageCodes.EN]: 'Repair Point',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'Repair point',
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        options: [
            {
                required: true,
                name: 'Type of repair point',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.RPR,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Repair Point Pipe replaced',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Pipe replaced',
                    },
                    {
                        code: types_1.ObservationCode.RPL,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Repair Point Localised lining',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Localised lining',
                    },
                    {
                        code: types_1.ObservationCode.RPI,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Repair Point Injected mortar',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Injected mortar',
                    },
                    {
                        code: types_1.ObservationCode.RPS,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Repair Point Other injected sealing material',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Other injected sealing material',
                    },
                    {
                        code: types_1.ObservationCode.RPH,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Repair Point Hole repaired',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Hole repaired',
                    },
                    {
                        code: types_1.ObservationCode.RPC,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Other repair to connection',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Other repair to connection',
                    },
                    {
                        code: types_1.ObservationCode.RPT,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Repair Point Localised lining of connection',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Localised lining of connection',
                    },
                    {
                        code: types_1.ObservationCode.RPZ,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Repair Point Other trenchless method',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Other trenchless method',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Clock reference the repair point starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.RX,
        repairs: [types_1.Repairs.COMPLEX_REPAIR],
        name: {
            [types_1.LanguageCodes.EN]: 'Defective repair',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'Previous repair was defective',
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        options: [
            {
                required: true,
                name: 'Type of defective repair',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.RXM,
                        scoringFunction: ({ attributes: { angle = [0, 0] } }) => {
                            const start = angle[0];
                            const end = angle[1];
                            const coverage = start <= end ? end - start + 1 : 12 - (start - end) + 1;
                            if (coverage <= 3) {
                                return {
                                    structure: 80,
                                };
                            }
                            else {
                                return {
                                    structure: 165,
                                };
                            }
                        },
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Part of wall missing',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Part of wall missing',
                    },
                    {
                        code: types_1.ObservationCode.RXC,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Crack in in repair material',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Crack in in repair material',
                    },
                    {
                        code: types_1.ObservationCode.RXH,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Hole in repair material',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Hole in repair material',
                    },
                    {
                        code: types_1.ObservationCode.RXMR,
                        scoringFunction: ({ attributes: { angle = [0, 0] } }) => {
                            const start = angle[0];
                            const end = angle[1];
                            const coverage = start <= end ? end - start + 1 : 12 - (start - end) + 1;
                            if (coverage <= 3) {
                                return {
                                    structure: 80,
                                };
                            }
                            else {
                                return {
                                    structure: 165,
                                };
                            }
                        },
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Missing repair material at',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Missing repair material at',
                    },
                    {
                        code: types_1.ObservationCode.RXS,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Seperation from host pipe',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Seperation from host pipe',
                        options: [
                            {
                                attribute: 'percentage',
                                name: 'Cross sectional loss',
                                description: 'Cross sectional loss',
                                type: 'percentage',
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.RXXM,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Exess material obstruction',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Exess material obstruction',
                    },
                    {
                        code: types_1.ObservationCode.RXZ,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Other defective repair',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Other defective repair',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Clock reference the intrusion starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.S_MSCC5_DOMESTIC,
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
        name: {
            [types_1.LanguageCodes.EN]: 'Surface Damage',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        scoringFunction: () => ({}),
        description: 'Such as corrosion, spalling and chemical attack',
    },
    {
        code: types_1.ObservationCode.S,
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        name: {
            [types_1.LanguageCodes.EN]: 'Surface Damage',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        scoringFunction: () => ({}),
        description: 'Such as corrosion, spalling and chemical attack',
        options: [
            {
                required: true,
                name: 'Type of surface damage',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.SW,
                        repairs: [types_1.Repairs.CLEANSING_AND_DESCALING],
                        scoringFunction: (_, section) => {
                            if (section.attributes.material === types_1.SectionMaterialCode.BR) {
                                return {
                                    structure: 10,
                                };
                            }
                            else {
                                return {
                                    structure: 5,
                                };
                            }
                        },
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Surface Damage Increased roughness',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Surface damage is increased roughness',
                    },
                    {
                        code: types_1.ObservationCode.SS,
                        repairs: [types_1.Repairs.CLEANSING_AND_DESCALING],
                        scoringFunction: (_, section) => {
                            if (section.attributes.material === types_1.SectionMaterialCode.BR) {
                                return {
                                    structure: 40,
                                };
                            }
                            else {
                                return {
                                    structure: 20,
                                };
                            }
                        },
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Surface Damage Spaling',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Surface damage is spaling',
                    },
                    {
                        code: types_1.ObservationCode.SB,
                        repairs: [types_1.Repairs.COMPLEX_REPAIR],
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Surface Damage Internal blister/bulge',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Surface damage is internal blister/bulge',
                    },
                    {
                        code: types_1.ObservationCode.SAV,
                        repairs: [types_1.Repairs.COMPLEX_REPAIR],
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Surface Damage Aggregate visable',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Surface damage is aggregate visable',
                    },
                    {
                        code: types_1.ObservationCode.SAP,
                        repairs: [types_1.Repairs.COMPLEX_REPAIR],
                        scoringFunction: () => ({ structure: 20 }),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Surface Damage Aggregate projecting from surface',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Surface damage is aggregate projecting from surface',
                    },
                    {
                        code: types_1.ObservationCode.SRV,
                        repairs: [types_1.Repairs.COMPLEX_REPAIR],
                        scoringFunction: () => ({ structure: 80 }),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Surface Damage Reinforcement visable',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Surface damage is reinforcement visable',
                    },
                    {
                        code: types_1.ObservationCode.SRP,
                        repairs: [types_1.Repairs.COMPLEX_REPAIR],
                        scoringFunction: () => ({ structure: 120 }),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Surface Damage Reinforcement projecting from surface',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Surface damage is reinforcement projecting from surface',
                    },
                    {
                        code: types_1.ObservationCode.SRC,
                        repairs: [types_1.Repairs.COMPLEX_REPAIR],
                        scoringFunction: () => ({ structure: 120 }),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Surface Damage Reinforcement corroded',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Surface damage is reinforcement corroded',
                    },
                    {
                        code: types_1.ObservationCode.SCP,
                        scoringFunction: () => ({ structure: 5 }),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Surface Damage Corrosion products',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Surface damage is corrosion products',
                    },
                    {
                        code: types_1.ObservationCode.SZ,
                        repairs: [types_1.Repairs.COMPLEX_REPAIR],
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Surface Damage Other damage',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Surface damage is other damage',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Clock reference the surface damage starts/ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.SA,
        name: {
            [types_1.LanguageCodes.EN]: 'Survey Abandoned',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
        scoringFunction: () => ({}),
        description: 'Used when a survey cannot continue for any reason',
    },
    {
        code: types_1.ObservationCode.SC,
        name: {
            [types_1.LanguageCodes.EN]: 'Shape/Dimension Change',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        description: 'Dimension of drain changes',
        options: [
            {
                required: true,
                name: 'Type',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.SCA,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Shape/Dimension Change Arched',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Shape changes to arched',
                    },
                    {
                        code: types_1.ObservationCode.SCB,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Shape/Dimension Change Barrel',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Shape changes to barrel',
                    },
                    {
                        code: types_1.ObservationCode.SCC,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Shape/Dimension Change Circular',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Shape changes to circular',
                    },
                    {
                        name: {
                            [types_1.LanguageCodes.EN]: 'Egg',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        code: types_1.ObservationCode.SCE,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        description: 'Shape/Dimension Change Shape changes to egg',
                    },
                    {
                        code: types_1.ObservationCode.SCH,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        name: {
                            [types_1.LanguageCodes.EN]: 'Shape/Dimension Change Horseshoe',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Shape changes to horseshoe',
                    },
                    {
                        name: {
                            [types_1.LanguageCodes.EN]: 'Shape/Dimension Change Oval',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        code: types_1.ObservationCode.SCO,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        description: 'Shape changes to oval',
                    },
                    {
                        name: {
                            [types_1.LanguageCodes.EN]: 'Shape/Dimension Change Rectangular',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        code: types_1.ObservationCode.SCR,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        description: 'Shape changes to rectangular',
                    },
                    {
                        name: {
                            [types_1.LanguageCodes.EN]: 'Shape/Dimension Change Trapezoidal',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        code: types_1.ObservationCode.SCT,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        description: 'Shape changes to trapezoidal',
                    },
                    {
                        name: {
                            [types_1.LanguageCodes.EN]: 'Shape/Dimension Change U-Shaped',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        code: types_1.ObservationCode.SCU,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        description: 'Shape changes to U-shape',
                    },
                    {
                        name: {
                            [types_1.LanguageCodes.EN]: 'Shape/Dimension Change Kerb block',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        code: types_1.ObservationCode.SCK,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        description: 'Shape changes to kerb block',
                    },
                    {
                        name: {
                            [types_1.LanguageCodes.EN]: 'Shape/Dimension Change Circular with smaller channel',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        code: types_1.ObservationCode.SCCSC,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        description: 'Shape changes to circular with smaller channel',
                    },
                    {
                        name: {
                            [types_1.LanguageCodes.EN]: 'Shape/Dimension Change Rectangular with smaller channel',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        code: types_1.ObservationCode.SCRSC,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        description: 'Shape changes to rectangular with smaller channel',
                    },
                    {
                        name: {
                            [types_1.LanguageCodes.EN]: 'Shape/Dimension Change Other',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        code: types_1.ObservationCode.SCZ,
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        scoringFunction: () => ({}),
                        description: 'Shape changes to other',
                    },
                ],
            },
        ],
    },
    {
        code: types_1.ObservationCode.SC_MSCC5_DOMESTIC,
        name: {
            [types_1.LanguageCodes.EN]: 'Shape/Dimension Change',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
        description: 'Dimension of drain changes',
        options: [
            {
                required: true,
                name: 'Diameter',
                attribute: 'diameter',
                description: 'Diameter of connection',
                type: 'distanceShort',
            },
        ],
    },
    {
        code: types_1.ObservationCode.SO,
        repairs: [types_1.Repairs.COMPLEX_REPAIR],
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        name: {
            [types_1.LanguageCodes.EN]: 'Other sealent',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        scoringFunction: ({ attributes: { percentage = 0 } }) => {
            if (percentage < 5)
                return { service: 1 };
            if (percentage <= 20)
                return { service: 2 };
            return { service: 5 };
        },
        description: 'Intruding',
        options: [
            {
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Clock reference the intrusion starts and ends',
                type: 'angleRange',
            },
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.SR,
        repairs: [types_1.Repairs.COMPLEX_REPAIR],
        scoringFunction: () => ({ service: 5, structure: 5 }),
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
        name: {
            [types_1.LanguageCodes.EN]: 'Sealing Ring',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'Sealing ring intrudes into pipe joint',
        options: [
            {
                required: false,
                name: 'Type of sealent ring issue',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.SRI,
                        scoringFunction: () => ({ service: 2, structure: 1 }),
                        projectTypes: [
                            types_1.ProjectType.MSCC5_COMMERERCIAL,
                            types_1.ProjectType.MSCC5_DOMESTIC,
                        ],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Sealent ring intruding',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Sealent ring intruding',
                    },
                    {
                        code: types_1.ObservationCode.SRB,
                        scoringFunction: () => ({
                            service: 2,
                            structure: 2,
                        }),
                        projectTypes: [
                            types_1.ProjectType.MSCC5_COMMERERCIAL,
                            types_1.ProjectType.MSCC5_DOMESTIC,
                        ],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Sealent ring broken',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Sealent ring broken',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Clock reference the intrusion starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.SV,
        repairs: [types_1.Repairs.COMPLEX_REPAIR],
        name: {
            [types_1.LanguageCodes.EN]: 'Soil Visible',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        scoringFunction: () => ({}),
        description: 'Soil is visible',
        options: [
            {
                name: 'soil is visible on joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.SVJ,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Soil Visible Joint',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        description: 'Soil is visible on joint',
                    },
                ],
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.V_MSCC5_DOMESTIC,
        name: {
            [types_1.LanguageCodes.EN]: 'Vermin Rat',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
        scoringFunction: () => ({}),
        description: 'Evidence of Vermin in pipe',
        options: [
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.V,
        name: {
            [types_1.LanguageCodes.EN]: 'Vermin',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        scoringFunction: () => ({}),
        description: 'Evidence of Vermin in pipe',
        options: [
            {
                required: false,
                name: 'Type of vermin',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.VR,
                        repairs: [types_1.Repairs.COMPLEX_REPAIR],
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Vermin Rat',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Vermin Rat',
                        options: [
                            {
                                name: 'Where vermin rat was observed',
                                required: false,
                                type: 'code',
                                schema: [
                                    {
                                        code: types_1.ObservationCode.VRC,
                                        scoringFunction: () => ({}),
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Vermin rat observed in connection',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        description: 'Vermin rat observed in connection',
                                    },
                                    {
                                        code: types_1.ObservationCode.VRJ,
                                        scoringFunction: () => ({}),
                                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                                        name: {
                                            [types_1.LanguageCodes.EN]: 'Vermin rat observed in open joint',
                                            [types_1.LanguageCodes.ES]: 'TODO',
                                            [types_1.LanguageCodes.FR]: 'TODO',
                                        },
                                        description: 'Vermin rat observed in open joint',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        code: types_1.ObservationCode.VZ,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Vermin other',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Vermin other',
                    },
                ],
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.VV,
        repairs: [types_1.Repairs.COMPLEX_REPAIR],
        name: {
            [types_1.LanguageCodes.EN]: 'Void visable',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        scoringFunction: () => ({}),
        description: 'Void visable through defect',
        options: [
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.WL_MSCC5_DOMESTIC,
        name: {
            [types_1.LanguageCodes.EN]: 'Water Level',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
        scoringFunction: () => ({}),
        description: 'Used to record changes in water level',
        options: [
            {
                attribute: 'percentage',
                name: 'Amount',
                description: 'Water level as a percentage',
                required: true,
                type: 'percentage',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.WL,
        name: {
            [types_1.LanguageCodes.EN]: 'Water Level',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        scoringFunction: () => ({}),
        description: 'Used to record changes in water level',
        options: [
            {
                name: 'Type of water',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.WLC,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Water Level Clear',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Water is clean',
                    },
                    {
                        code: types_1.ObservationCode.WLT,
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Water Level Turbid',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Water is turbid',
                    },
                ],
            },
            {
                attribute: 'percentage',
                name: 'Amount',
                description: 'Water level as a percentage',
                required: true,
                type: 'percentage',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.WX,
        repairs: [types_1.Repairs.COMPLEX_REPAIR],
        name: {
            [types_1.LanguageCodes.EN]: 'Weld failiure',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'Weld failiure',
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        options: [
            {
                required: true,
                name: 'Type of weld failiure',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.WXL,
                        scoringFunction: () => ({
                            structure: 10,
                        }),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Weld failure longitudinal',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Weld failure longitudinal',
                    },
                    {
                        code: types_1.ObservationCode.WXC,
                        scoringFunction: () => ({
                            structure: 10,
                        }),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Weld failure circumferential ',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Weld failure circumferential ',
                    },
                    {
                        code: types_1.ObservationCode.WXS,
                        scoringFunction: () => ({
                            structure: 40,
                        }),
                        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
                        name: {
                            [types_1.LanguageCodes.EN]: 'Weld failure spiral',
                            [types_1.LanguageCodes.ES]: 'TODO',
                            [types_1.LanguageCodes.FR]: 'TODO',
                        },
                        description: 'Weld failure spiral',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle of weld failiure',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.XP,
        repairs: [types_1.Repairs.CIPP_OR_EXCAVATION],
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
        scoringFunction: () => ({ structure: 165 }),
        name: {
            [types_1.LanguageCodes.EN]: 'Collapsed',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'Drain is suffering from complete loss of structure integrity',
        options: [
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.XB,
        repairs: [types_1.Repairs.CIPP_OR_EXCAVATION],
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
        scoringFunction: () => ({ structure: 165 }),
        name: {
            [types_1.LanguageCodes.EN]: 'Collapsed Brick sewer',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        description: 'Brick sewer is suffering from complete loss of structure integrity',
        options: [
            {
                required: true,
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
        ],
    },
    ...pacp_1.papcObservations,
];
const isOptionAngle = (o) => 'type' in o && o.type === 'angle';
exports.isOptionAngle = isOptionAngle;
const isOptionAngleRange = (o) => 'type' in o && o.type === 'angleRange';
exports.isOptionAngleRange = isOptionAngleRange;
const isOptionBoolean = (o) => 'type' in o && o.type === 'boolean';
exports.isOptionBoolean = isOptionBoolean;
const isOptionCode = (o) => 'type' in o && o.type === 'code';
exports.isOptionCode = isOptionCode;
const isOptionDistance = (o) => 'type' in o && o.type === 'distance';
exports.isOptionDistance = isOptionDistance;
const isOptionDistanceShort = (o) => 'type' in o && o.type === 'distanceShort';
exports.isOptionDistanceShort = isOptionDistanceShort;
const isOptionEnum = (o) => 'type' in o && o.type === 'enum';
exports.isOptionEnum = isOptionEnum;
const isOptionPercentage = (o) => 'type' in o && o.type === 'percentage';
exports.isOptionPercentage = isOptionPercentage;
const isOptionRemarks = (o) => 'type' in o && o.type === 'remarks';
exports.isOptionRemarks = isOptionRemarks;
const isOptionContinuous = (o) => 'type' in o && o.type === 'continuous';
exports.isOptionContinuous = isOptionContinuous;
/**
 * NOTE: This function mutates the above data structure.
 * This is done to auto add the `depth` to options and `path`
 * to schema entries properties.
 *
 * These properties are used when generating the form elements.
 */
const extractObservations = (entries, observations = [], path = []) => {
    entries.forEach((entry) => {
        const nextPath = [...path, entry.code];
        observations.push(Object.assign(Object.assign({}, entry), { path: nextPath }));
        if (entry.options) {
            entry.options.forEach((option) => {
                if ((0, exports.isOptionCode)(option)) {
                    (option.depth = nextPath.length),
                        extractObservations(option.schema, observations, nextPath);
                }
            });
        }
    });
    return observations;
};
exports.observationsFlat = extractObservations(observations);
exports.observationsFlatRoot = extractObservations(observations).filter((e) => {
    let filter = true;
    if (e.options) {
        for (const option of e.options) {
            if ((0, exports.isOptionCode)(option)) {
                if (option.required) {
                    filter = false;
                }
            }
        }
    }
    return filter;
});
exports.observationsTopLevel = exports.observationsFlat.filter(({ path }) => (path === null || path === void 0 ? void 0 : path.length) === 1);
exports.getObservationSchema = (0, schema_1.createSchemaLookup)(exports.observationsFlat);
const getObservationOptions = (path = [], options = []) => {
    var _a;
    if (path.length) {
        (_a = (0, exports.getObservationSchema)(path[0]).options) === null || _a === void 0 ? void 0 : _a.forEach((option) => {
            options.push(option);
            if ((0, exports.isOptionCode)(option)) {
                (0, exports.getObservationOptions)(path.slice(1), options);
            }
        });
    }
    return options;
};
exports.getObservationOptions = getObservationOptions;
