import { User } from './User';

export enum BookingStateCode {
  COMPLETED,
  PENDING,
  REPORT_OVERDUE,
  IN_PROGRESS,
  UNASSIGNED,
  SUBMITTED,
}

export interface JobManagementSearch {
  searchString?: string;
  state?: BookingStateCode;
  date?: string;
  searchType: JobManagementSearchType;
  engineer?: User;
}

export enum JobManagementSearchType {
  POSTCODE = 'POSTCODE',
  CUSTOMER_NAME = 'CUSTOMER_NAME',
  JOB_NUMBER = 'JOB_NUMBER',
  SITE_ADDRESS = 'SITE_ADDRESS',
}
