import { Button, ButtonProps, Icons, Text } from 'preshape';
import React from 'react';
import { useHasPermission } from '../Permissions/Permissions';
import { useReportEditorContext } from '../Report/ReportEditorProvider';

type Props = ButtonProps & {
  observationUid?: string;
  onDelete?: () => void;
};

const ObservationDeleteButton = ({
  observationUid,
  onDelete,
  ...rest
}: Props) => {
  const { removeObservation } = useReportEditorContext();
  const hasUpdateProjectPermission = useHasPermission('projects:update');

  if (!hasUpdateProjectPermission || !observationUid) {
    return null;
  }

  return (
    <Button
      variant="tertiary"
      {...rest}
      color="negative"
      gap="x2"
      onClick={() => {
        removeObservation(observationUid);
        onDelete?.();
      }}
      type="button"
    >
      <Text>Remove observation</Text>
      <Icons.Trash2 size="1rem" />
    </Button>
  );
};

export default ObservationDeleteButton;
