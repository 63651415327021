import { InvitePostBody } from '@drainify/types';
import React from 'react';
import OrgRoleView from '../Org/OrgRoleView';
import SummaryCard from '../SummaryCard/SummaryCard';
import SummaryCardSection from '../SummaryCard/SummaryCardSection';
import { useWizardContext } from '../Wizard/Wizard';

type Props = {
  invite?: InvitePostBody;
};

const InviteSummary = ({ invite }: Props) => {
  const { setActiveStepId, flow } = useWizardContext();

  const editStep = (stepId: string) => {
    return () => {
      setActiveStepId(stepId);
    };
  };

  return (
    <SummaryCard>
      <SummaryCardSection
        onEdit={flow === 'create' ? editStep('recipient') : undefined}
        title="Email address"
        value={invite?.recipient}
      >
        {invite?.recipient}
      </SummaryCardSection>

      <SummaryCardSection
        onEdit={editStep('role')}
        title="Role"
        value={invite?.role}
      >
        <OrgRoleView role={invite?.role} />
      </SummaryCardSection>
    </SummaryCard>
  );
};

export default InviteSummary;
