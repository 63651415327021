import { Attributes, Button, ButtonProps, Tooltip } from 'preshape';
import React, { PropsWithChildren } from 'react';

type Props = ButtonProps & {
  icon: JSX.Element;
  title: string;
};

const MapSidebarMenuItem = ({
  icon,
  title,
  ...rest
}: Attributes<HTMLButtonElement, PropsWithChildren<Props>>) => {
  return (
    <Tooltip content={title} placement="left">
      {(props) => (
        <Button {...props} {...rest} borderRadius="x0" variant="tertiary">
          {icon}
        </Button>
      )}
    </Tooltip>
  );
};

export default MapSidebarMenuItem;
