"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getJobPurposesSchema = exports.jobPurposes = void 0;
const types_1 = require("@drainify/types");
const schema_1 = require("./schema");
exports.jobPurposes = [
    {
        code: types_1.JobPurpose.BUILD_OVER,
        name: {
            [types_1.LanguageCodes.EN]: 'Build over',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.JobPurpose.GENERAL,
        name: {
            [types_1.LanguageCodes.EN]: 'General',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.JobPurpose.PRE_PURCHASE,
        name: {
            [types_1.LanguageCodes.EN]: 'Pre purchase',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.JobPurpose.INVESTIGATION_OF_KNOWN_STRUCTURAL_OR_SERVICE_DEFECTS,
        name: {
            [types_1.LanguageCodes.EN]: 'Investigation of known structural or service defects',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.PACP],
    },
    {
        code: types_1.JobPurpose.INVESTIGATION_OF_INFILTRATION_PROBLEMS,
        name: {
            [types_1.LanguageCodes.EN]: 'Investigation of infiltration problems',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.PACP],
    },
    {
        code: types_1.JobPurpose.POST_COMPLETION_INSPECTION_OF_REPAIRS_OR_RENOVATIONS,
        name: {
            [types_1.LanguageCodes.EN]: 'Post-completion inspection of repairs or renovations',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.PACP],
    },
    {
        code: types_1.JobPurpose.PRE_ADOPTION_SURVEY_PRIOR_TO_VESTING_AS_PUBLIC_SEWER,
        name: {
            [types_1.LanguageCodes.EN]: 'Pre-adoption survey prior to vesting as public sewer',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.PACP],
    },
    {
        code: types_1.JobPurpose.POST_COMPLETION_INSPECTION_OF_NEW_SEWERS,
        name: {
            [types_1.LanguageCodes.EN]: 'Post-completion inspection of new sewers',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.PACP],
    },
    {
        code: types_1.JobPurpose.SAMPLE_SURVEY_OF_SEWERS_TO_DETERMINE_ASSET_CONDITIOR,
        name: {
            [types_1.LanguageCodes.EN]: 'Sample survey of sewers to determine asset condition',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.PACP],
    },
    {
        code: types_1.JobPurpose.ROUTINE_INSPECTION_OF_CONDITION,
        name: {
            [types_1.LanguageCodes.EN]: 'Routine inspection of condition',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.PACP],
    },
    {
        code: types_1.JobPurpose.INVESTIGATION_OF_A_SUSPECTED_OPERATIONAL_PROBLEM,
        name: {
            [types_1.LanguageCodes.EN]: 'Investigation of a suspected operational problem',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.PACP],
    },
    {
        code: types_1.JobPurpose.INVESTMENT_PLANNING,
        name: {
            [types_1.LanguageCodes.EN]: 'Investment planning',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.PACP],
    },
    {
        code: types_1.JobPurpose.END_OF_WARRANTY_PERIOD,
        name: {
            [types_1.LanguageCodes.EN]: 'End of warranty period',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.PACP],
    },
    {
        code: types_1.JobPurpose.OTHER,
        name: {
            [types_1.LanguageCodes.EN]: 'Other',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.PACP],
    },
];
exports.getJobPurposesSchema = (0, schema_1.createSchemaLookup)(exports.jobPurposes);
