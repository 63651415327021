import { Box, Button, Buttons, Grid, Icons, Modal, ModalBody, ModalFooter, ModalHeader, Text } from 'preshape';
import React from 'react';
import SecretAIOCRPOI from '../../../../pages/SecretAIOCRPOI';
import { MOBILE_APP_WIDTH } from '../../../App/App';
import BuddyJobManagerButton from '../../../BuddyReviewTool/BuddyJobManagerButton';
import Card from '../../../Card/Card';
import DataTable from '../../../DataTable/DataTable';
import DataTableGroup from '../../../DataTable/DataTableGroup';
import DataTableItem from '../../../DataTable/DataTableItem';
import DataTableMessage from '../../../DataTable/DataTableMessage';
import InspectionVideo from '../../../Inspection/InspectionVideo';
import { useOrgContext } from '../../../Org/OrgProvider';
import { useProjectContext } from '../../../Project/ProjectProvider';
import VideoAddReplaceButton from '../../../Video/VideoAddReplaceButton';
import { useReportEditorContext } from '../../ReportEditorProvider';

const ReportMapSidebarBatchVideoUploadButton = () => {
  const [modalVisable, setModalVisable] = React.useState(false)
  const { reportEditor } = useReportEditorContext()
  const { project } = useProjectContext()
  const { org } = useOrgContext()

  return (
    <>
      <Box flex='vertical' gap='x2' alignChildrenHorizontal='middle'>
        <Button onClick={() => setModalVisable(true)} borderSize='x1'>
            <Icons.Film size='1.5rem' />
        </Button>
        <Text size='x2' strong> Video & AI </Text>
      </Box>
      {
        modalVisable &&


        <Modal visible={true} onClose={() => setModalVisable(false)}

          animation="FadeSlideUp"
          margin="x4"
          maxWidth={MOBILE_APP_WIDTH}

        >
          <ModalHeader>Video/AI</ModalHeader>
          <ModalBody>
          {
            reportEditor.report.inspections.length === 0 && 

      <DataTable>
            <DataTableGroup>
              <DataTableItem>
                <DataTableMessage>
                  There are no inspections to show here
                </DataTableMessage>
              </DataTableItem>
            </DataTableGroup>
          </DataTable>
          }
            <Grid
              repeat={3}
              repeatWidthMax="1fr"
              repeatWidthMin="100px"
              gap="x4"
              grow
            >
              {
                reportEditor.report.inspections.map(e =>
                  <Card key={e.uid}>
                    <Text size='x3' strong> {reportEditor.getSectionName(reportEditor.getSectionByUid(e.sectionUid))}/{reportEditor.getInspectionName(e)} </Text>

                    <InspectionVideo
                      inspection={e}
                    />
                    <VideoAddReplaceButton inspection={e} />
                    <SecretAIOCRPOI projectId={project!.uid} inspectionId={e.uid} orgId={org?.uid} />

                  </Card>)
              }
            </Grid>
          </ModalBody>
            <ModalFooter>
              <Buttons>
                <BuddyJobManagerButton />
              </Buttons>

            </ModalFooter>
        </Modal>
      }
    </>
  );
};

export default ReportMapSidebarBatchVideoUploadButton;
