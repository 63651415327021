import { Attributes, Image, ImageProps } from 'preshape';
import React from 'react';

const getDefaultAvatar = (uid?: string) => {
  return `https://api.dicebear.com/7.x/bottts/svg?seed=${uid}`;
};

interface Props extends ImageProps {
  uid?: string;
  src?: string;
  size?: number | string;
}

const Avatar = ({
  uid,
  src,
  size = '2rem',
  ...rest
}: Attributes<HTMLImageElement, Props>) => {
  const isDefaultAvatar = !src;

  return (
    <Image
      {...rest}
      backgroundColor="background-shade-1"
      borderSize={isDefaultAvatar ? 'x1' : undefined}
      borderColor="text-shade-1"
      borderRadius="full"
      height={size}
      src={src || getDefaultAvatar(uid)}
      width={size}
    />
  );
};

export default Avatar;
