import { ObservationCode } from '@drainify/types';
import { BoxProps } from 'preshape';
import React from 'react';
import SymbolTemplate from '../Symbol/SymbolTemplate';

type Props = BoxProps & {
  code?: ObservationCode[];
};

const ObservationSymbol = ({ code, ...rest }: Props) => {
  return (
    <SymbolTemplate {...rest}>
      {code?.[code.length - 1].replace(/_.*/, '')}
    </SymbolTemplate>
  );
};

export default ObservationSymbol;
