import { DateTime } from 'luxon';
import { Box, BoxProps, Button, Icons, Text } from 'preshape';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import DatePickerCalendar from './DatePickerCalendar';
import useValidDateInputs from './useValidDateInputs';
import './DatePicker.css';

type Props = BoxProps & {
  onChange: (startDate: string, endDate: string) => void;
  endDate?: string;
  startDate?: string;
  withRangeDate?: boolean;
  withSelectablePast?: boolean;
};

const DatePicker = ({
  onChange,
  endDate: dirtyEndDate,
  startDate: dirtyStartDate,
  withRangeDate,
  withSelectablePast,
  ...rest
}: PropsWithChildren<Props>) => {
  const [startDate, endDate] = useValidDateInputs(dirtyStartDate, dirtyEndDate);

  const [activeDateTime, setActiveDateTime] = useState(
    DateTime.now().startOf('month')
  );

  const handleChangeActiveDateTime = (direction: -1 | 1) => {
    setActiveDateTime(activeDateTime.plus({ months: direction }));
  };

  useEffect(() => {
    setActiveDateTime(startDate.startOf('month'));
  }, [startDate]);

  return (
    <Box flex="vertical" grow {...rest}>
      <Box alignChildren="middle" flex="horizontal" gap="x2" margin="x6">
        <Box>
          <Button
            borderRadius="full"
            onClick={() => handleChangeActiveDateTime(-1)}
            paddingHorizontal="x2"
            type="button"
            variant="secondary"
          >
            <Icons.ChevronLeft />
          </Button>
        </Box>

        <Box grow>
          <Text align="middle" size="x6" strong>
            {activeDateTime.toFormat('MMMM yyyy')}
          </Text>
        </Box>

        <Box>
          <Button
            borderRadius="full"
            onClick={() => handleChangeActiveDateTime(1)}
            paddingHorizontal="x2"
            type="button"
            variant="secondary"
          >
            <Icons.ChevronRight />
          </Button>
        </Box>
      </Box>

      <Box flex="vertical" grow>
        <DatePickerCalendar
          dateTime={activeDateTime}
          onChange={onChange}
          selectedEndDateTime={endDate}
          selectedStartDateTime={startDate}
          withRangeDate={withRangeDate}
          withSelectablePast={withSelectablePast}
        />
      </Box>
    </Box>
  );
};

export default DatePicker;
