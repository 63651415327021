"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isClosed = exports.isDrawing = exports.getDrawingValidations = void 0;
const yup_1 = require("yup");
const getDrawingValidations = () => {
    return (0, yup_1.object)().shape({
        points: (0, yup_1.array)()
            .test('Points', 'Must close polygon', (e) => {
            return (Array.isArray(e) && e.length > 0 && e[0].uid === e[e.length - 1].uid);
        })
            .min(3)
            .required(),
        name: (0, yup_1.string)().required(),
    });
};
exports.getDrawingValidations = getDrawingValidations;
/**
 *
 */
// export type SectionPoints = { // a: GeoJSON.Point | null; // b: GeoJSON.Point | null; // bbox: GeoJSON.BBox | null; // distance: number | null; // line: GeoJSON.LineString | null; // poi: GeoJSON.Point | null; };
/**
 *
 */
// export const emptySectionPoints: SectionPoints = { a: null, b: null, bbox: null, distance: null, line: null, poi: null, };
const isDrawing = (s) => !!s && 'uid' in s;
exports.isDrawing = isDrawing;
const isClosed = (drawing) => {
    return (drawing.points[0].uid === drawing.points[drawing.points.length - 1].uid);
};
exports.isClosed = isClosed;
