import { Box, Icons } from 'preshape';
import React from 'react';
import { ReportMapNodeRendererType } from '.';
import './css/BN.css';

const ReportMapNodeUNP: ReportMapNodeRendererType = ({ locked }) => {
  return (
    <Box
      backgroundColor={locked ? 'text-shade-4' : 'accent-shade-3'}
      borderRadius="full"
      textColor="background-shade-1"
    >
      <Icons.HelpCircle size="1.75rem" />
    </Box>
  );
};

export default ReportMapNodeUNP;
