import { Icons, Text } from 'preshape';
import React, { PropsWithChildren, useState } from 'react';
import AsyncButton from '../AsyncButton/AsyncButton';

const GeoLocationRequestButton = ({
  onError,
  onSuccess,
  hasPermission,
}: PropsWithChildren<{
  onError: PositionErrorCallback;
  onSuccess: PositionCallback;
  hasPermission: boolean;
}>) => {
  const [isRequesting, setIsRequesting] = useState(false);

  const handleSuccess: PositionCallback = (position) => {
    onSuccess(position);
  };

  const requestAccess = () => {
    if ('geolocation' in navigator) {
      setIsRequesting(true);
      navigator.geolocation.getCurrentPosition(handleSuccess, onError, {
        timeout: 3000,
      });
    }
  };

  if (hasPermission === true) {
    return null;
  }

  return (
    <AsyncButton
      color="accent"
      gap="x2"
      isLoading={isRequesting}
      onClick={() => requestAccess()}
      size="x3"
      variant="primary"
    >
      <Icons.MapPin />
      <Text>Allow Location Sharing</Text>
    </AsyncButton>
  );
};

export default GeoLocationRequestButton;
