import { Box, Button, Text } from 'preshape';
import React, { PropsWithChildren } from 'react';

type Props = {
  onEdit?: () => void;
  name: string;
};

const InspectionAdditionalSummaryCardVideo = ({
  onEdit,
  name,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <Button onClick={onEdit} variant="tertiary">
      <Box
        flex="vertical"
        alignChildrenHorizontal="between"
        alignChildrenVertical="middle"
      >
        <Text size="x2" strong titlecase>
          {name}
        </Text>
        {children}
      </Box>
    </Button>
  );
};

export default InspectionAdditionalSummaryCardVideo;
