import { hasPermission, permissions } from '@drainify/utils';
import { useLocalStorage } from 'preshape';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
} from 'react';
import { Navigate } from 'react-router-dom';
import useMeOrgMember from '../../hooks/useMeOrgMember';
import { AuthenticationContext } from '../Authentication/Authenticate';

type PermissionKey = keyof typeof permissions;

const PermissionContext = createContext<{
  hasPermission: (permission: PermissionKey) => boolean;
}>({
  hasPermission: () => false,
});

export const useHasPermission = (permission: PermissionKey) =>
  useContext(PermissionContext).hasPermission(permission);

const Permissions = (props: PropsWithChildren<{}>) => {
  const [permissionStore, setPermissionStore] = useLocalStorage<
    Partial<Record<keyof typeof permissions, boolean>>
  >('com.drainify.permissions', {});
  const { query } = useMeOrgMember();

  useEffect(() => {
    if (query.data) {
      const values: Partial<Record<PermissionKey, boolean>> = {};

      Object.keys(permissions).forEach((permission) => {
        values[permission as PermissionKey] = hasPermission(
          query.data.role,
          permission as PermissionKey
        );
      });

      setPermissionStore(values);
    }
  }, [query.data]);

  const hasPermissionFromStore = (permission: PermissionKey) => {
    return permissionStore[permission] || false;
  };
  const authState = useContext(AuthenticationContext);

  const redirectToLoginPage =
    !authState.isFetching && !authState.user && !authState.error; // TODO(hhogg): Handle login errors.

  if (redirectToLoginPage) {
    if (location.pathname && location.pathname !== '/') {
      return (
        <Navigate
          replace
          to={`/login?continueUrl=${location.pathname + location.search}`}
        />
      );
    }
    return <Navigate replace to="/login" />;
  }

  return (
    <PermissionContext.Provider
      {...props}
      value={{ hasPermission: hasPermissionFromStore }}
    />
  );
};

export default Permissions;
