import { Box } from 'preshape';
import React from 'react';
import { ReportMapNodeRendererType } from '.';
import './css/IC.css';

const ReportMapNodeIC: ReportMapNodeRendererType = ({ locked }) => {
  return (
    <Box
      className="Icon"
      tag="svg"
      viewBox="0 0 24 24"
      height="1.75rem"
      width="1.75rem"
      stroke="white"
      fill="white"
      textColor="white"
      backgroundColor={locked ? 'text-shade-1' : 'accent-shade-5'}
      borderRadius="full"
    >
      {
        <svg id="IC" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
          <circle className="ic-cls-1" cx="250" cy="250" r="194" />
          <circle className="ic-cls-2" cx="250" cy="250" r="77.52" />
          <line
            className="ic-cls-2"
            x1="412.99"
            y1="250"
            x2="354.14"
            y2="250"
          />
          <line className="ic-cls-2" x1="145.86" y1="250" x2="87.01" y2="250" />
          <line
            className="ic-cls-2"
            x1="250"
            y1="353.64"
            x2="250"
            y2="412.49"
          />
          <line className="ic-cls-2" x1="250" y1="88.51" x2="250" y2="147.36" />
          <text className="ic-cls-3" transform="translate(208.06 285.84)">
            <tspan x="0" y="0" color="white">
              IC
            </tspan>
          </text>
        </svg>
      }
    </Box>
  );
};

export default ReportMapNodeIC;
