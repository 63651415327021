import React, {
  createContext,
  useContext,
  useState,
  PropsWithChildren,
} from 'react';

type MapLayerKey = string | number;
type MapLayersState = Record<MapLayerKey, boolean>;
type LayerFunction<T = void> = (group: MapLayerKey, uuid?: string) => T;

type MapLayersContextProps = {
  hideAllLayers: () => void;
  isLayerVisible: LayerFunction<boolean>;
  showAllLayers: () => void;
  showLayers: (...layers: [MapLayerKey, string?][]) => void;
  toggleLayerVisibility: LayerFunction;
};

type MapLayersProps = {};

const MapLayersContext = createContext<MapLayersContextProps>({
  hideAllLayers: () => {},
  isLayerVisible: () => false,
  showAllLayers: () => {},
  showLayers: () => {},
  toggleLayerVisibility: () => {},
});

export const useMapLayersContext = () => useContext(MapLayersContext);

const MapLayers = ({ ...rest }: PropsWithChildren<MapLayersProps>) => {
  const [defaultVisibility, setDefaultVisibility] = useState(true);
  const [visibleLayers, setVisibleLayers] = useState<MapLayersState>({});

  const getLayerKey: LayerFunction<MapLayerKey> = (group, uuid) => {
    return uuid === undefined ? group : `${group}.${uuid}`;
  };

  const isLayerVisible: LayerFunction<boolean> = (group, uuid) => {
    if (uuid) {
      const groupedUuid = getLayerKey(group, uuid);

      if (groupedUuid in visibleLayers) return visibleLayers[groupedUuid];
      if (group in visibleLayers) return visibleLayers[group];

      return defaultVisibility;
    }

    if (group in visibleLayers) return visibleLayers[group];

    return defaultVisibility;
  };

  const toggleLayerVisibility: LayerFunction = (group, uuid) => {
    if (uuid) {
      const groupedUuid = getLayerKey(group, uuid);

      setVisibleLayers((visibleLayers) => ({
        ...visibleLayers,
        [groupedUuid]:
          visibleLayers[groupedUuid] === undefined
            ? visibleLayers[group] === false
            : !visibleLayers[groupedUuid],
      }));

      return;
    }

    setVisibleLayers((visibleLayers) => {
      const next: MapLayersState = {
        [group]: visibleLayers[group] === false,
      };

      Object.entries(visibleLayers).forEach(([key, value]) => {
        if (key !== group.toString() && !key.startsWith(`${group}.`)) {
          next[key] = value;
        }
      });

      return next;
    });
  };

  const hideAllLayers = () => {
    setDefaultVisibility(false);
    setVisibleLayers({});
  };

  const showAllLayers = () => {
    setDefaultVisibility(true);
    setVisibleLayers({});
  };

  const showLayers = (...layers: [MapLayerKey, string?][]) => {
    const next: MapLayersState = {};

    for (const [group, uuid] of layers) {
      next[getLayerKey(group, uuid)] = true;
    }

    setVisibleLayers((visibleLayers) => ({
      ...visibleLayers,
      ...next,
    }));
  };

  const context = {
    hideAllLayers,
    isLayerVisible,
    showAllLayers,
    showLayers,
    toggleLayerVisibility,
  };

  return <MapLayersContext.Provider {...rest} value={context} />;
};

export default MapLayers;
