import { ConflictDetails } from '@drainify/types';
import {
  Box,
  Button,
  Buttons,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from 'preshape';
import React from 'react';

type Props = {
  icon: JSX.Element;
  origin: string;
  originState: ConflictDetails;
  conflictingState: ConflictDetails;
  onClick: () => void;
};

const ConflictResolutionItem = ({
  originState,
  conflictingState,
  origin,
  icon,
  onClick,
}: Props) => {
  const [confirmationVisable, setConfirmationVisable] = React.useState(false);

  return (
    <>
      <Box
        backgroundColor="white"
        padding="x2"
        borderRadius="15px"
        borderColor="accent-shade-2"
        borderSize="x1"
        flex="vertical"
        gap="x3"
        maxWidth="150px"
        grow
      >
        <Box
          flex="horizontal"
          gap="x2"
          textColor="accent-shade-4"
          alignChildrenVertical="middle"
        >
          {icon}
          <Text strong size="x4">
            {origin}
          </Text>
        </Box>

        <Box>
          {showDiff(
            'Sections',
            originState.sections,
            conflictingState.sections
          )}
          {showDiff(
            'Inspections',
            originState.inspections,
            conflictingState.inspections
          )}
          {showDiff('Nodes', originState.nodes, conflictingState.nodes)}
          {showDiff(
            'Observations',
            originState.observations,
            conflictingState.observations
          )}
          {showDiff(
            'measurements',
            originState.measurements,
            conflictingState.measurements
          )}
          {showDiff('plans', originState.plans, conflictingState.plans)}
          {showDiff('scale', originState.scale, conflictingState.scale)}
          {showDiff('images', originState.images, conflictingState.images)}
          {showDiff('videos', originState.videos, conflictingState.videos)}
          {showDiff(
            'drawings',
            originState.polygons,
            conflictingState.polygons
          )}
          {showNegligableDifferences(originState, conflictingState)}
        </Box>

        <Box flex="horizontal">
          <Button
            size="x1"
            grow
            variant="primary"
            color="accent"
            onClick={() => {
              setConfirmationVisable(true);
            }}
          >
            <Text size="x1">Select</Text>
          </Button>
        </Box>
      </Box>
      {confirmationVisable && (
        <Modal visible>
          <ModalHeader>Confirm conflict resolution selection</ModalHeader>

          <ModalBody>
            <Text>Confirm you want to set the version to {origin}</Text>
            <Text>Differences:</Text>

            {showDiffConfirm(
              'Sections',
              originState.sections,
              conflictingState.sections
            )}
            {showDiffConfirm(
              'Inspections',
              originState.inspections,
              conflictingState.inspections
            )}
            {showDiffConfirm(
              'Nodes',
              originState.nodes,
              conflictingState.nodes
            )}
            {showDiffConfirm(
              'Observations',
              originState.observations,
              conflictingState.observations
            )}
            {showDiffConfirm(
              'measurements',
              originState.measurements,
              conflictingState.measurements
            )}
            {showDiffConfirm(
              'plans',
              originState.plans,
              conflictingState.plans
            )}
            {showDiffConfirm(
              'scale',
              originState.scale,
              conflictingState.scale
            )}
            {showDiffConfirm(
              'images',
              originState.images,
              conflictingState.images
            )}
            {showDiffConfirm(
              'videos',
              originState.videos,
              conflictingState.videos
            )}
            {showDiffConfirm(
              'drawings',
              originState.polygons,
              conflictingState.polygons
            )}
            {showNegligableDifferences(originState, conflictingState)}

            <Text strong> This cannot be undone</Text>
          </ModalBody>
          <ModalFooter>
            <Buttons>
              <Button
                onClick={() => setConfirmationVisable(false)}
                grow
                size="x3"
              >
                back
              </Button>
              <Button
                onClick={async () => {
                  onClick();
                  setConfirmationVisable(false);
                }}
                color="accent"
                variant="primary"
                grow
              >
                Confirm
              </Button>
            </Buttons>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default ConflictResolutionItem;

export const showDiff = (label: string, a: number, b: number) => {
  const delta = a - b;
  if (delta === 0) {
    return null;
  } else if (delta > 0) {
    return (
      <Text textColor="positive-shade-5" size="x2" strong>
        {label} {a}
      </Text>
    );
  } else {
    return (
      <Text textColor="negative-shade-5" size="x2" strong>
        {label} {a}
      </Text>
    );
  }
};

export const showDiffConfirm = (label: string, a: number, b: number) => {
  const delta = a - b;
  if (delta === 0) {
    return null;
  } else if (delta > 0) {
    return (
      <Text textColor="positive-shade-5" size="x2" strong>
        Add {Math.abs(delta)} {label}
      </Text>
    );
  } else {
    return (
      <Text textColor="negative-shade-5" size="x2" strong>
        Remove {Math.abs(delta)} {label}
      </Text>
    );
  }
};

export const showNegligableDifferences = (
  a: ConflictDetails,
  b: ConflictDetails
) => {
  const hasNegliableDifferences =
    a.sections == b.sections &&
    a.inspections == b.inspections &&
    a.nodes == b.nodes &&
    a.observations == b.observations &&
    a.measurements == b.measurements &&
    a.plans == b.plans &&
    a.scale == b.scale &&
    a.images == b.images &&
    a.videos == b.videos &&
    a.polygons == b.polygons;

  if (hasNegliableDifferences) {
    return (
      <Text size="x2">
        No difference in number of items. Likely renaming, repositioning or
        replacing.
      </Text>
    );
  } else return <></>;
};
