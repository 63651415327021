import {
  Booking,
  ResponseError,
  ResponseMultipleEntities,
} from '@drainify/types';
import { useQuery } from 'react-query';
import useFetch, { hasAllParams } from '../components/Api/useFetch';

const useOrgBookings = (orgId?: string) => {
  const fetch = useFetch();
  const key = ['bookings', `/orgs/${orgId}/bookings`, fetch];
  const params = { orgId: orgId as string };

  const query = useQuery<ResponseMultipleEntities<Booking>, ResponseError>(
    key,
    () => fetch('/orgs/:orgId/bookings', { method: 'GET', params }),
    { enabled: hasAllParams(params) }
  );

  return { query };
};

export default useOrgBookings;
