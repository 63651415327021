import { SectionNodePosition } from '@drainify/types';
import React from 'react';
import DataTable, { DataTableProps } from '../DataTable/DataTable';
import DataTableGroup from '../DataTable/DataTableGroup';
import DataTableItem from '../DataTable/DataTableItem';
import DataTableMessage from '../DataTable/DataTableMessage';
import InfiniteScroll from '../InfiniteScroll/InfiniteScroll';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import { useReportContext } from '../Report/ReportProvider';
import NodeCodeView from './NodeSymbol';
import NodeView from './NodeView';

type Props = DataTableProps & {
  disabledUids?: string[];
  nodePosition?: SectionNodePosition;
  onItemClick?: (nodeUid: string) => void;
  selectedUids?: string[];
  validate?: boolean;
};

const NodeList = ({
  disabledUids,
  nodePosition,
  validate = true,
  onItemClick,
  selectedUids,
  ...rest
}: Props) => {
  const { report } = useReportContext();
  const { reportEditor } = useReportEditorContext();
  const [nodeNumber, setNodeNumber] = React.useState(5);

  if (!report) {
    return (
      <DataTable {...rest}>
        <DataTableGroup>
          {Array.from({ length: 5 }).map((_, index) => (
            <DataTableItem key={index}>
              <NodeCodeView />
            </DataTableItem>
          ))}
        </DataTableGroup>
      </DataTable>
    );
  }

  if (!report.nodes.length) {
    return (
      <DataTable {...rest}>
        <DataTableGroup>
          <DataTableItem>
            <DataTableMessage>
              There are no nodes to show here
            </DataTableMessage>
          </DataTableItem>
        </DataTableGroup>
      </DataTable>
    );
  }

  return (
    <DataTable {...rest}>
      <DataTableGroup>
        {reportEditor.report.nodes.slice(0, nodeNumber).map((node) => (
          <DataTableItem
            disabled={disabledUids?.includes(node.uid)}
            key={node.uid}
            keywords={reportEditor.getNodeKeywords(node.uid)}
            onClick={onItemClick && (() => onItemClick(node.uid))}
            selected={selectedUids?.includes(node.uid)}
          >
            <NodeView
              node={node}
              nodePosition={nodePosition}
              validate={validate}
            />
          </DataTableItem>
        ))}
        {nodeNumber < reportEditor.report.nodes.length && (
          <InfiniteScroll onMore={() => setNodeNumber(nodeNumber + 5)} />
        )}
      </DataTableGroup>
    </DataTable>
  );
};

export default NodeList;
