import { Inspection } from '@drainify/types';
import { Box, Text } from 'preshape';
import React from 'react';
import { useParams } from 'react-router';
import useVideoStatus from '../../hooks/useVideoStatus';
import Spinner from '../Spinner/Spinner';

type Props = {
  inspection: Inspection;
};

const InspectionVideoThumbnailPending = ({ inspection }: Props) => {
  const { projectId } = useParams();
  useVideoStatus(projectId, inspection.uid);

  return (
    <Box flex="vertical" alignChildrenHorizontal="middle">
      <Spinner size="6rem" />
      <Text>Processing...</Text>
    </Box>
  );
};

export default InspectionVideoThumbnailPending;
