import { OCRResult, OCRResultStatus } from "@drainify/types"
import { Appear, Box, Button, Text } from "preshape"
import React from "react"
import { useReportEditorContext } from "../Report/ReportEditorProvider";

type Props = {
    ocrResult?: OCRResult;
}

const BuddyScore = ({ ocrResult }: Props) => {
    const { reportEditor } = useReportEditorContext()
    const [expandScore, setExpandScore] = React.useState(false);

    function truncateToOneDecimal(num: number) {
        return Math.trunc(num * 10) / 10;
    }

    if (!ocrResult || ocrResult.status === OCRResultStatus.PENDING) {
        return <Text>Loading</Text>
    }

    const appearencesInOCR = (distance?: number) => {
        if (distance === undefined) return 0;
        const results = ocrResult?.matches.filter(e => !isNaN(e.text)).filter(e => truncateToOneDecimal(e.text) === truncateToOneDecimal(distance)) || []
        return results?.length
    }

    const missing = [];

    for (const j of new Set(reportEditor.getInspectionObservations(ocrResult.inspectionId).map(e => e.distance))) {
        if (appearencesInOCR(j) < 2) {
            missing.push(j);
        }
    }

    let score = 100;
    const percentMissing = missing.length / Math.max(reportEditor.getInspectionObservations(ocrResult.inspectionId).length, 1)
    score -= 100 * percentMissing


    return <Box margin='x0'>
        <Button variant='tertiary' onClick={() => setExpandScore(!expandScore)} flex='horizontal' gap='x2'>
            <Box
                borderRadius='full' width={8} height={8}
                backgroundColor={score === 100 ? 'positive-shade-3' : score > 60 ? 'highlight' : 'negative-shade-3'}

            />
        </Button>
        {
            expandScore && <Appear>
                {
                    missing.map(e =>
                        <Text size='x1' key={e}>  Pause too brief at: {truncateToOneDecimal(e || 0)}</Text>
                    )
                }
            </Appear>
        }
    </Box>
}

export default BuddyScore;