import { Attributes, Box, BoxProps } from 'preshape';
import React, {
  forwardRef,
  useEffect,
  ForwardedRef,
  useState,
  useRef,
} from 'react';
import { createPortal } from 'react-dom';
import { useMapContext } from '../Map';
import Marker, { MarkerAnchor } from './Marker';

export type MapMarkerProps = Omit<BoxProps, 'padding'> & {
  anchor?: MarkerAnchor;
  onDragStart?: (point: GeoJSON.Point) => void;
  onDrag?: (point: GeoJSON.Point) => void;
  onDragEnd?: (point: GeoJSON.Point) => void;
  point?: GeoJSON.Point | false | null;
};

const MapMarker = (
  {
    anchor = 'center',
    children,
    onDragStart,
    onDrag,
    onDragEnd,
    point,
    ...rest
  }: Attributes<HTMLDivElement, MapMarkerProps>,
  ref: ForwardedRef<HTMLElement>
) => {
  const { createMarker } = useMapContext();
  const [marker, setMarker] = useState<Marker>();
  const refUnmounted = useRef(false);

  useEffect(() => {
    return () => {
      refUnmounted.current = true;
    };
  }, []);

  useEffect(() => {
    let marker: Marker;

    createMarker().then((m) => {
      marker = m;

      if (!refUnmounted.current) {
        setMarker(m);
      }
    });

    return () => {
      marker?.destroy();
    };
  }, [createMarker]);

  useEffect(() => {
    if (point) {
      marker?.setPosition(point);
    }
  }, [marker, point]);

  useEffect(() => {
    marker?.setAnchor(anchor);
  }, [marker, anchor]);

  useEffect(() => {
    if (marker) {
      const isDraggable = !!(onDrag || onDragEnd || onDragStart);

      marker.setDraggable(isDraggable);

      let dragStartListener: google.maps.MapsEventListener;
      let dragListener: google.maps.MapsEventListener;
      let dragEndListener: google.maps.MapsEventListener;

      if (onDragStart)
        dragStartListener = marker.addListener('dragstart', onDragStart);
      if (onDrag) dragListener = marker.addListener('drag', onDrag);
      if (onDragEnd) dragEndListener = marker.addListener('dragend', onDragEnd);

      return () => {
        if (dragStartListener) dragStartListener.remove();
        if (dragListener) dragListener.remove();
        if (dragEndListener) dragEndListener.remove();
      };
    }
  }, [marker, onDrag, onDragEnd, onDragStart]);

  if (!marker) {
    return null;
  }

  return createPortal(
    <Box {...rest} ref={ref}>
      {children}
    </Box>,
    marker.element
  );
};

export default forwardRef(MapMarker);
