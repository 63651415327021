import { Attributes, Box, BoxProps } from 'preshape';
import React from 'react';
import './Blueprint.css';

type Props = BoxProps & {};

const Blueprint = (props: Attributes<HTMLDivElement, Props>) => {
  return <Box {...props} className="Blueprint" />;
};

export default Blueprint;
