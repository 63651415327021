import {
  LanguageCodes,
  Observation,
  ObservationPostBody,
  PENDING_IMAGE_UPLOAD,
} from '@drainify/types';
import { getFullFilePath, getObservationOptions, getObservationSchema, isObservation, isOptionCode } from '@drainify/utils';
import { captureVideoFrame } from 'capture-video-frame';
import { Duration } from 'luxon';
import { Attributes, Box, Button, Icons, Text } from 'preshape';
import React from 'react';
import { generatePath } from 'react-router';
import useFileUpload from '../../../hooks/useFileUpload';
import Distance from '../../DistanceInput/Distance';
import resizeImage from '../../Image/ImageCompressor';
import ImageFromUrl from '../../Image/ImageFromUrl';
import ObservationOptionRenderer from '../../Observations/ObservationOptionRenderer/ObservationOptionRenderer';
import { useProjectContext } from '../../Project/ProjectProvider';
import { useReportEditorContext } from '../../Report/ReportEditorProvider';
import SymbolTemplate from '../../Symbol/SymbolTemplate';
import { useVideoContext } from '../VideoProvider';

type Props = {
  observation: Observation | ObservationPostBody;
  projectId: string;
  loading: boolean;
  setLoading: (loading: boolean) => void;
};

const VideoPlayerSidebarChapterView = ({
  observation,
  projectId,
  loading,
  setLoading,
}: Attributes<HTMLAnchorElement, Props>) => {
  const { reportEditor, editObservation } = useReportEditorContext();
  const observationName = reportEditor.getObservationName(observation);
  const { playedSeconds, player, handlePlay, handlePause } = useVideoContext();
  const observationFileUpload = useFileUpload<ObservationPostBody>();
  const { isCustomerView } = useProjectContext();
  const [expand, setExpand] = React.useState(false);

  const toggleTimestamp = async (observation: Observation) => {
    if (!loading) {
      setLoading(true);
      handlePause();

      if (observation.timeStamp && observation.imageUrl) {
        reportEditor.updateObservation(observation.uid, {
          ...observation,
          timeStamp: undefined,
          imageUrl: undefined,
        });
        // TODO: Delete the old image here
      } else {
        reportEditor.updateObservationNoAsync(observation.uid, {
          ...observation,
          timeStamp: playedSeconds,
          imageUrl: PENDING_IMAGE_UPLOAD,
        });
        const frame = captureVideoFrame(player?.current?.getInternalPlayer())
          .blob as Blob;
        const file = new File([frame], `${observation.uid}.jpg`, {
          type: frame.type,
        });
        const compressedBlob = (await resizeImage({
          file,
          maxSize: 500,
        })) as Blob;
        const compressedImage = new File([compressedBlob], 'snapshot.jpg', {
          type: compressedBlob.type,
        });

        await reportEditor.updateAsync?.(reportEditor.report);

        observationFileUpload.upload(
          generatePath(
            '/projects/:projectId/report/observations/:observationId/image',
            { projectId, observationId: observation.uid }
          ),
          compressedImage
        );
        handlePlay();
      }
      setTimeout(() => setLoading(false), 500);
    }
  };
  const observationSchema = getObservationSchema(
    observation.code[observation.code.length - 1]
  );

  const options =
    observationSchema && getObservationOptions(observationSchema.path);

  return (
    <Box flex='vertical'basis='0' grow gap='x1'>
      <Box flex="horizontal" alignChildrenHorizontal="between" grow gap="x2">
      <Text
        textColor="text-shade-1"
        flex="vertical"
        alignChildrenVertical="middle"
      >
        <Distance value={observation?.distance} type="long" />
        {observation.inspectionMarker !== undefined ? (
          observation.inspectionMarker === 'finish' ? (
            <Text
              alignChildren="middle"
              borderSize="x2"
              flex="vertical"
              borderRadius="8px"
              ellipsis
              height={40}
              size="x1"
              strong
              width={40}
            >
              Finish
            </Text>
          ) : (
            <Text
              alignChildren="middle"
              borderSize="x2"
              borderRadius="8px"
              flex="vertical"
              ellipsis
              height={40}
              size="x1"
              strong
              width={40}
            >
              Start
            </Text>
          )
        ) : (
          <SymbolTemplate>
            {observation.code[observation.code.length - 1]?.replace(/_.*/, '')}
          </SymbolTemplate>
        )}
      </Text>

      <Box width="100px">
        <ImageFromUrl
          src={getFullFilePath(observation.imageUrl)}
          height="100%"
        />
      </Box>

      <Box flex="vertical" alignChildrenVertical="between" grow>
        <Text align="start">{observationName}</Text>

        {observation.timeStamp && (
          <Box
            padding="x1"
            textColor="text-shade-1"
            backgroundColor="accent-shade-1"
            borderColor="accent-shade-2"
            borderSize="x1"
          >
            {Duration.fromMillis(observation.timeStamp * 1000).toFormat(
              'mm:ss'
            )}
          </Box>
        )}
      </Box>

      {!isCustomerView && (
        <Box flex="vertical" gap="x2" alignChildrenVertical="middle" grow>
          <Box
            borderSize="x1"
            padding="x1"
            flex="vertical"
            alignChildrenVertical="middle"
            alignChildrenHorizontal="middle"
            textColor="accent-shade-5"
            onClick={(e) => {
              e.stopPropagation();
              toggleTimestamp(observation as Observation);
            }}
          >
            {observation.imageUrl ? (
              <Icons.CameraOff size="1rem" />
            ) : (
              <Icons.Camera size="1rem" />
            )}
          </Box>
          {isObservation(observation) && (
            <Box
              borderSize="x1"
              textColor="accent-shade-5"
              padding="x1"
              onClick={() => editObservation(observation.uid)}
            >
              Edit
            </Box>
          )}
        </Box>
      )}
    </Box>
    { isCustomerView &&

      <>
      <Button onClick={() => setExpand(!expand)}>Details {expand ? <Icons.ChevronUp/> : <Icons.ChevronDown/>}</Button>

      {
        expand && 
            <Box flex="vertical" alignChildrenHorizontal='middle' gap='x3'>
              <Text>{observationSchema.name[LanguageCodes.EN]}</Text>
                {options
                  ?.filter(({ type }) => type !== 'continuous')
                  .map(
                    (option) =>
                      !isOptionCode(option) && observation.attributes[option.attribute] !== false && (
                        <Box>
                          <Text size='x1'>{option.name}</Text>
                            <ObservationOptionRenderer
                              asSummary
                              height={5}
                              name={`attributes.${option.attribute}`}
                              width={5}
                              option={option}
                              value={observation.attributes[option.attribute]}
                            />
                          </Box>
                      )
                  )}
                  {
                    observation.remarks && <Box><Text strong>Remarks</Text><Text size='x1'>{observation.remarks} </Text></Box>
                  }
              </Box>

      }
      </>
    }
    </Box>
  );
};

export default VideoPlayerSidebarChapterView;
