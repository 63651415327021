import { StatusCodes } from 'http-status-codes';
import { User } from '.';

export type ApiErrorProps = {
  errors?: any;
  code: TypeApiErrors;
  message: string;
  status: StatusCodes;
};

export type ResponseError = ApiErrorProps;

export type ResponseMultipleEntities<T> = {
  data: T[];
  meta?: PageMeta;
};

export type PaginatedEntities<T> = {
  data: T[];
  meta: PageMeta;
};

export type WithTracking = {
  createdAt?: string;
  updatedAt?: string;
  createdBy?: User;
};

export type PageMeta = {
  offset: number;
  total: number;
};

export type TypeApiErrors =
  | 'drainify/billing-not-found'
  | 'drainify/billing-not-updated'
  | 'drainify/customer-exists'
  | 'drainify/customer-not-created'
  | 'drainify/customer-not-found'
  | 'drainify/customer-not-updated'
  | 'drainify/customer-not-removed'
  | 'drainify/credits-not-sufficient'
  | 'drainify/unhandled'
  | 'drainify/file-download'
  | 'drainify/file-upload'
  | 'drainify/file-type-unsupported'
  | 'drainify/permission-denied'
  | 'drainify/invite-exists'
  | 'drainify/invite-not-created'
  | 'drainify/invite-not-found'
  | 'drainify/invite-not-removed'
  | 'drainify/invoice-not-possible'
  | 'drainify/member-exists'
  | 'drainify/member-not-created'
  | 'drainify/member-not-found'
  | 'drainify/member-not-updated'
  | 'drainify/member-not-removed'
  | 'drainify/mixpanel-integration-error'
  | 'drainify/node-not-found'
  | 'drainify/observation-not-found'
  | 'drainify/org-exists'
  | 'drainify/org-not-created'
  | 'drainify/org-not-found'
  | 'drainify/org-not-updated'
  | 'drainify/org-not-removed'
  | 'drainify/project-exists'
  | 'drainify/project-not-created'
  | 'drainify/project-not-found'
  | 'drainify/project-not-updated'
  | 'drainify/project-not-removed'
  | 'drainify/report-exists'
  | 'drainify/report-not-created'
  | 'drainify/report-not-found'
  | 'drainify/report-not-updated'
  | 'drainify/report-not-removed'
  | 'drainify/report-view-no-access-token'
  | 'drainify/report-view-invalid-access-token'
  | 'drainify/user-belongs-to-org'
  | 'drainify/user-exists'
  | 'drainify/user-not-created'
  | 'drainify/user-not-found'
  | 'drainify/user-not-updated'
  | 'drainify/user-not-removed'
  | 'drainify/email-not-found'
  | 'drainify/email-not-created'
  | 'drainify/booking-exists'
  | 'drainify/booking-not-created'
  | 'drainify/booking-not-found'
  | 'drainify/booking-not-updated'
  | 'drainify/booking-not-removed'
  | 'drainify/document-not-found'
  | 'drainify/document-not-created'
  | 'drainify/site-photo-not-found'
  | 'drainify/storage-limit-exceeded'
  | 'drainify/streaming-no-range-provided'
  | 'drainify/video-already-exists'
  | 'drainify/video-not-found'
  | 'drainify/usage-not-found'
  | 'drainify/usage-not-sufficient'
  | 'drainify/signup-email-in-use'
  | 'drainify/signup-request-not-found'
  | 'drainify/signup-request-not-created'
  | 'drainify/payment-intent-not-found'
  | 'drainify/payment-intent-not-created'
  | 'drainify/payment-intent-not-updated'
  | 'drainify/signup-request-not-updated'
  | 'drainify/subscription-details-not-found';
