import {
  InspectionMarker,
  ObservationCode,
  ObservationPostBody,
  PENDING_IMAGE_UPLOAD,
} from '@drainify/types';
import { getFullFilePath } from '@drainify/utils';
import { captureVideoFrame } from 'capture-video-frame';
import { Box, Button, Buttons, Icons, Text } from 'preshape';
import React from 'react';
import { generatePath } from 'react-router';
import useFileUpload from '../../hooks/useFileUpload';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import resizeImage from '../Image/ImageCompressor';
import ImageFromUrl from '../Image/ImageFromUrl';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import Spinner from '../Spinner/Spinner';
import VideoPlayerProgressbarPlayPauseButton from './Progressbar/VideoPlayerProgressbarPlayPauseButton';
import VideoPlayerCreateObservationButton from './VideoPlayerCreateObservationButton';
import { useVideoContext } from './VideoProvider';

type Props = {
  inspectionId: string;
  projectId: string;
  onClose?: () => void;
};

const VideoPlayerSectionEditActions = ({
  inspectionId,
  projectId,
  onClose,
}: Props) => {
  const {
    player,
    playedSeconds,
    handlePause,
    handleSetPlaybackRate,
    playbackRate,
  } = useVideoContext();

  const nodeFileUploader = useFileUpload<Node>();
  const { reportEditor, editObservation, editSection, editNode } =
    useReportEditorContext();
  const inspection = reportEditor.getInspectionByUid(inspectionId);
  const section = reportEditor.getSectionByUid(inspection?.sectionUid);
  const observationFileUpload = useFileUpload<ObservationPostBody>();
  const [isAdditionalDetailsVisible, setIsAdditionalDetailsVisible] =
    React.useState(false);

  const startNode = reportEditor.getNodeByUid(section?.nodeStartUid);
  const endNode = reportEditor.getNodeByUid(section?.nodeEndUid);
  const [startNodeLoading, setStartNodeLoading] = React.useState(false);
  const [endNodeLoading, setEndNodeLoading] = React.useState(false);

  const handleCaptureNodeImage = async (nodeId?: string, isStart?: boolean) => {
    if (nodeId) {
      if (isStart) {
        setStartNodeLoading(true);
      } else {
        setEndNodeLoading(true);
      }
      const frame = captureVideoFrame(player?.current?.getInternalPlayer())
        .blob as Blob;
      const file = new File([frame], `${nodeId}.jpg`, { type: frame.type });

      reportEditor.updateNode(nodeId, {
        imageUrl: PENDING_IMAGE_UPLOAD,
      });

      nodeFileUploader.upload(
        generatePath('/projects/:projectId/report/nodes/:nodeId/image', {
          projectId,
          nodeId,
        }),
        file
      );

      if (isStart) {
        setStartNodeLoading(false);
      } else {
        setEndNodeLoading(false);
      }
    }
  };

  const newInitialWaterLevel = async () => {
    handlePause();
    const frame = captureVideoFrame(player?.current?.getInternalPlayer())
      .blob as Blob;
    const { uid } = reportEditor.addObservationNoUpdate({
      code: [ObservationCode.WL],
      attributes: {
        percentage: 0,
      },
      distance: 0,
      inspectionUid: inspectionId,
      timeStamp: playedSeconds,
      inspectionMarker: 'start',
      imageUrl: PENDING_IMAGE_UPLOAD,
    });
    const file = new File([frame], `${uid}.jpg`, { type: frame.type });
    const compressedBlob = (await resizeImage({
      file,
      maxSize: 500,
    })) as Blob;
    const compressedImage = new File(
      [compressedBlob],
      'observation-snapshot.jpg',
      {
        type: compressedBlob.type,
      }
    );
    await reportEditor.updateAsync?.(reportEditor.report);
    observationFileUpload.upload(
      generatePath(
        '/projects/:projectId/report/observations/:observationId/image',
        {
          projectId,
          observationId: uid,
        }
      ),
      compressedImage
    );
    editObservation(uid, 'attributes.percentage');
  };

  const finish = async () => {
    if (section) {
      if (
        !reportEditor
          .getInspectionObservations(inspection?.uid)
          .find((e) => e.code.includes(ObservationCode.SA))
      ) {
        const newUid = await newGeneralPhotography(
          'finish',
          true,
          section.attributes.length
        );

        reportEditor.updateObservationNoAsync(newUid, {
          distance: section.attributes.length,
        });
      }
      handlePause();
      const frame = captureVideoFrame(player?.current?.getInternalPlayer())
        .blob as Blob;
      const file = new File([frame], 'name.jpg', { type: frame.type });
      const compressedBlob = (await resizeImage({
        file,
        maxSize: 500,
      })) as Blob;
      const compressedImage = new File(
        [compressedBlob],
        'observation-snapshot.jpg',
        {
          type: compressedBlob.type,
        }
      );
      editSection(section?.uid, 'attributes.length', compressedImage);
      onClose?.();
    }
  };

  const newGeneralPhotography = async (
    inspectionMarker?: InspectionMarker,
    skipEdit?: boolean,
    distance?: number
  ) => {
    handlePause();
    const frame = captureVideoFrame(player?.current?.getInternalPlayer())
      .blob as Blob;
    const { uid } = reportEditor.addObservationNoUpdate({
      code: [ObservationCode.GP],
      attributes: {},
      inspectionUid: inspectionId,
      timeStamp: playedSeconds,
      inspectionMarker,
      distance: inspectionMarker === 'finish' ? distance : undefined,
      imageUrl: PENDING_IMAGE_UPLOAD,
    });

    await reportEditor.updateAsync?.(reportEditor.report);
    const file = new File([frame], `${uid}.jpg`, { type: frame.type });
    const compressedBlob = (await resizeImage({ file, maxSize: 500 })) as Blob;
    const compressedImage = new File(
      [compressedBlob],
      'observation-snapshot.jpg',
      {
        type: compressedBlob.type,
      }
    );
    observationFileUpload.upload(
      generatePath(
        '/projects/:projectId/report/observations/:observationId/image',
        {
          projectId,
          observationId: uid,
        }
      ),
      compressedImage
    );
    if (!skipEdit) {
      editObservation(uid, 'distance');
    }
    return uid;
  };

  return (
    <>
      <Box
        backgroundColor="background-shade-3"
        flex="horizontal"
        alignChildrenHorizontal="around"
        alignChildrenVertical="middle"
        padding="x2"
      >
        <VideoPlayerProgressbarPlayPauseButton />
        <Box width="100px">
          <DropdownMenu
            onChange={handleSetPlaybackRate}
            shrink
            options={[
              {
                value: 0.5,
                name: '0.5x',
              },
              {
                value: 1,
                name: '1x',
              },
              {
                value: 1.5,
                name: '1.5x',
              },
              {
                value: 2,
                name: '2x',
              },
              {
                value: 5,
                name: '5x',
              },
            ]}
            value={playbackRate}
          ></DropdownMenu>
        </Box>
        <Box
          flex="vertical"
          alignChildrenVertical="around"
          gap="x2"
          padding="x1"
        >
          <Buttons flex="horizontal" gap="x2">
            <Button
              variant="secondary"
              flex="vertical"
              id="accel-s"
              borderSize="x1"
              onClick={newInitialWaterLevel}
              disabled={
                reportEditor
                  .getInspectionObservations(inspectionId, ObservationCode.WL)
                  .filter((e) => e.distance === 0).length !== 0
              }
            >
              <Text titlecase size="x1">
                Start
              </Text>
            </Button>
            <Button
              onClick={finish}
              borderSize="x1"
              id="accel-f"
              variant="secondary"
              disabled={
                reportEditor
                  .getInspectionObservations(inspectionId)
                  .filter(
                    (e) =>
                      e.inspectionMarker === 'finish' ||
                      e.code.includes(ObservationCode.SA)
                  ).length !== 0
              }
            >
              <Text titlecase size="x1">
                Finish
              </Text>
            </Button>
          </Buttons>
        </Box>

        <Box
          flex="vertical"
          alignChildrenVertical="around"
          gap="x2"
          padding="x1"
        >
          <Buttons flex="horizontal" gap="x2">
            <VideoPlayerCreateObservationButton
              inspectionId={inspectionId}
              projectId={projectId}
            />
            <Button
              id="accel-g"
              onClick={() => newGeneralPhotography()}
              variant="secondary"
              borderSize="x1"
            >
              <Text alignChildren="middle" size="x1" strong>
                GP
              </Text>
            </Button>
          </Buttons>
        </Box>

        <Box flex="horizontal">
          <Box flex="vertical" alignChildrenVertical="middle">
            <Button
              variant="tertiary"
              onClick={() =>
                setIsAdditionalDetailsVisible(!isAdditionalDetailsVisible)
              }
            >
              <Text size="x1">More...</Text>
              {isAdditionalDetailsVisible ? (
                <Icons.ChevronDown />
              ) : (
                <Icons.ChevronUp />
              )}
            </Button>
          </Box>
        </Box>
      </Box>
      {isAdditionalDetailsVisible && (
        <Box
          flex="horizontal"
          alignChildrenHorizontal="around"
          backgroundColor="background-shade-3"
          padding="x3"
        >
          <Box flex="vertical" alignChildrenHorizontal="middle">
            <Box>
              <Text size="x1" align="middle" strong>
                <Text strong size="x1" flex="horizontal" gap="x1">
                  {`${startNode?.code}S`}
                </Text>
              </Text>
            </Box>
            <Box>
              <Box flex="horizontal" alignChildrenVertical="middle" gap="x1">
                <Buttons>
                  <Button
                    variant="secondary"
                    color="accent"
                    flex="vertical"
                    onClick={() => handleCaptureNodeImage(startNode?.uid, true)}
                  >
                    {startNodeLoading ? <Spinner /> : <Icons.Camera />}
                  </Button>
                  <Box
                    width="60px"
                    onClick={() => startNode?.uid && editNode(startNode?.uid)}
                  >
                    <ImageFromUrl
                      size={3}
                      src={getFullFilePath(startNode?.imageUrl)}
                      height="40px"
                    />
                  </Box>
                </Buttons>
              </Box>
            </Box>
          </Box>

          <Box>
            <Box flex="vertical" alignChildrenVertical="middle">
              <Box flex="horizontal" gap="x1" alignChildrenHorizontal="middle">
                <Text size="x1" align="middle" strong>
                  {`${endNode?.code}F`}
                </Text>
              </Box>

              <Box flex="horizontal" alignChildrenVertical="middle" gap="x1">
                <Buttons>
                  <Button
                    variant="secondary"
                    color="accent"
                    flex="vertical"
                    gap="x0"
                    onClick={() => handleCaptureNodeImage(endNode?.uid, false)}
                  >
                    {endNodeLoading ? <Spinner /> : <Icons.Camera />}
                  </Button>
                  <Box
                    width="60px"
                    onClick={() => endNode?.uid && editNode(endNode?.uid)}
                  >
                    <ImageFromUrl
                      src={getFullFilePath(endNode?.imageUrl)}
                      height="40px"
                    />
                  </Box>
                </Buttons>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default VideoPlayerSectionEditActions;
