"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAreaUnitText = exports.normaliseArea = exports.convertMSqToInSq = exports.convertMSqToFtSq = exports.convertMSqToMMSq = exports.convertMMSqToMSq = exports.convertFtSqToMSq = exports.convertInSqToMSq = void 0;
const types_1 = require("@drainify/types");
const convertInSqToMSq = (value) => [
    value / 1550,
    types_1.AreaUnit.M_SQUARE,
];
exports.convertInSqToMSq = convertInSqToMSq;
const convertFtSqToMSq = (value) => [
    value / 10.764,
    types_1.AreaUnit.M_SQUARE,
];
exports.convertFtSqToMSq = convertFtSqToMSq;
const convertMMSqToMSq = (value) => [
    value / 1000000,
    types_1.AreaUnit.M_SQUARE,
];
exports.convertMMSqToMSq = convertMMSqToMSq;
const convertMSqToMMSq = (value) => [
    value * 1000000,
    types_1.AreaUnit.MM_SQUARE,
];
exports.convertMSqToMMSq = convertMSqToMMSq;
const convertMSqToFtSq = (value) => [
    value * 10.764,
    types_1.AreaUnit.FEET_SQUARE,
];
exports.convertMSqToFtSq = convertMSqToFtSq;
const convertMSqToInSq = (value) => [
    value * 1550,
    types_1.AreaUnit.INCH_SQUARE,
];
exports.convertMSqToInSq = convertMSqToInSq;
const normaliseArea = (area) => {
    const [value, unit] = area;
    if (unit === types_1.AreaUnit.M_SQUARE)
        return area;
    if (unit === types_1.AreaUnit.FEET_SQUARE)
        return (0, exports.convertFtSqToMSq)(value);
    if (unit === types_1.AreaUnit.MM_SQUARE)
        return (0, exports.convertMMSqToMSq)(value);
    if (unit === types_1.AreaUnit.INCH_SQUARE)
        return (0, exports.convertInSqToMSq)(value);
    return area;
};
exports.normaliseArea = normaliseArea;
const getAreaUnitText = (unit, short) => {
    return {
        [types_1.AreaUnit.MM_SQUARE]: short ? 'mm²' : 'Millimeters squared',
        [types_1.AreaUnit.M_SQUARE]: short ? 'm²' : 'Meters squared',
        [types_1.AreaUnit.INCH_SQUARE]: short ? 'in²' : 'Inches squared',
        [types_1.AreaUnit.FEET_SQUARE]: short ? 'ft²' : 'Feet squared',
    }[unit];
};
exports.getAreaUnitText = getAreaUnitText;
