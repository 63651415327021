import { ErrorValidation, ReportError } from '@drainify/utils';
import { Alert, AlertProps, Icons, Text } from 'preshape';
import React from 'react';

const ReportErrorMessage = ({
  error,
  ...props
}: Omit<AlertProps, 'color'> & {
  error: ReportError;
}) => {
  return (
    <Alert
      {...props}
      alignChildrenVertical="middle"
      backgroundColor="negative-shade-2"
      color="negative"
      flex="horizontal"
      gap="x3"
      padding="x3"
    >
      <Icons.AlertTriangle />
      <Text basis="0" grow size="x2" strong>
        {error.message}
      </Text>
    </Alert>
  );
};

export const ReportErrorMessageInteractive = ({
  errors,
  onEdit,
  ...props
}: Omit<AlertProps, 'color'> & {
  errors: Record<string, ErrorValidation>;
  onEdit: (step: string) => void;
}) => {
  const errorFields = Object.keys(errors).map((key) => (
    <Text
      key={key}
      onClick={() => onEdit(key)}
      strong
      style={{ textDecoration: 'underline', cursor: 'pointer' }}
    >
      {errors[key].field}
    </Text>
  ));

  return (
    <Alert
      {...props}
      alignChildrenVertical="middle"
      backgroundColor="negative-shade-2"
      color="negative"
      flex="horizontal"
      gap="x3"
      padding="x3"
    >
      <Icons.AlertTriangle />
      <Text basis="0" grow size="x2">
        Invalid fields:
        {errorFields}
      </Text>
    </Alert>
  );
};

export default ReportErrorMessage;
