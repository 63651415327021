import { GeoJSONPointBlank } from '@drainify/utils';
import { motion } from 'framer-motion';
import {
  Appear,
  AppearProps,
  Attributes,
  Box,
  sizeX1Px,
  transitionTimingFunction,
  TypeColor,
} from 'preshape';
import React from 'react';
import useGeometryStoreRegister from '../useGeometryStore/useGeometryStoreRegister';
import MapMarker from './MapMarker';

type Props = AppearProps & {
  id?: string;
  point?: GeoJSON.Point | null;
  strokeColor?: TypeColor;
  strokeDasharray?: string;
};

const transition = {
  type: 'tween',
  delay: 0.1,
  duration: 0.3,
  ease: transitionTimingFunction,
};

const MapLabel = ({
  children,
  id,
  point,
  strokeColor = 'text-shade-1',
  strokeDasharray = '4 4',
  visible,
  ...rest
}: Attributes<HTMLDivElement, Props>) => {
  const [registerEntry, ref] = useGeometryStoreRegister({
    geometry: point,
    opts: {
      id: `MapLabel.${id}`,
      padding: sizeX1Px,
      shiftFromCollisions: true,
    },
  });

  const {
    domRect,
    relative: { translate: { coordinates: [tx, ty] } = GeoJSONPointBlank } = {},
  } = registerEntry || {};

  const absTx = Math.max(2, Math.abs(tx));
  const absTy = Math.max(2, Math.abs(ty));

  return (
    <MapMarker point={point}>
      <Appear {...rest} animation="Pop" ref={ref} visible={visible}>
        <Box
          style={{ transform: `translate(${tx}px, ${ty}px)` }}
          transitionProperty="transform"
        >
          <Box
            absolute="top-left"
            height={absTy}
            tag="svg"
            textColor={strokeColor}
            viewBox={`${tx - (domRect?.width || 0) * 0.5} ${
              ty - (domRect?.height || 0) * 0.5
            } ${absTx} ${absTy}`}
            width={absTx}
            zIndex={-1}
          >
            <motion.line
              animate={{ x1: tx, y1: ty }}
              initial={{ x1: 0, y1: 0 }}
              key={`${tx},${ty}`}
              stroke="currentColor"
              strokeDasharray={strokeDasharray}
              strokeWidth="1"
              transition={transition}
              x2={0}
              y2={0}
            />

            <motion.circle cx={0} cy={0} fill="currentColor" r="1.5" />
          </Box>

          <Box>{children}</Box>
        </Box>
      </Appear>
    </MapMarker>
  );
};

export default MapLabel;
