import { WithTracking } from './Api';
import { Location } from './Location';
import { Org } from './Org';

export interface Customer extends WithTracking {
  uid: string;
  name: string;
  email: string;
  location: Location;
  phoneNumber: string;
  org: Org;
}

export type CustomerPostBody = Omit<Customer, 'uid' | 'org'>;
