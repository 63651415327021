import { MapLayerPolygon, MapLayerReturn, MapLayerUpdateFn } from '../useMap';

const getPathFromPolygon = (polygon: GeoJSON.Polygon) => {
  return polygon.coordinates.map((positions) =>
    positions.map(([lng, lat]) => new google.maps.LatLng(lat, lng))
  );
};

const getGeometryPolygon = (
  map: google.maps.Map,
  layer: MapLayerPolygon
): MapLayerReturn<MapLayerPolygon> => {
  const polygon = new google.maps.Polygon({
    map,
    paths: layer.geometry ? getPathFromPolygon(layer.geometry) : [],
    ...layer.style,
  });

  let prevLayer = layer;

  const set = (layer: MapLayerPolygon) => {
    polygon.setOptions({
      paths: layer.geometry ? getPathFromPolygon(layer.geometry) : [],
      ...layer.style,
    });

    prevLayer = layer;
  };

  const update: MapLayerUpdateFn<MapLayerPolygon> = (value) => {
    if (typeof value === 'function') {
      set(value(prevLayer));
    } else if (value) {
      set({
        ...prevLayer,
        ...value,
      });
    }
  };

  const remove = () => {
    polygon.setMap(null);
  };

  return { update, remove };
};

export default getGeometryPolygon;
