import { DateTime } from 'luxon';
import {
  Modal,
  ModalBody,
  Box,
  Button,
  Text,
  ModalHeader,
  Icons,
  Alert,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHeaderCell,
  TableCell,
} from 'preshape';
import React from 'react';
import { useUsageContext } from './UsageProvider';

const UsageDetailsInvoice = () => {
  const { usage, refetch } = useUsageContext();
  const [visible, setVisible] = React.useState(false);

  return (
    <>
      <Button
        variant="tertiary"
        color="accent"
        onClick={() => {
          setVisible(true);
          refetch?.();
        }}
        flex="horizontal"
        gap="x1"
        size="x1"
      >
        Upcoming invoice
      </Button>
      <Modal visible={visible} onClose={() => setVisible(false)}>
        <ModalHeader> Upcoming invoice</ModalHeader>
        <ModalBody>
          <Box flex="horizontal" alignChildrenHorizontal="around">
            <Text>
              <Text strong></Text>
              {usage?.resetDate &&
                `${'Date'}: ${DateTime.fromSeconds(usage.resetDate).toFormat(
                  'dd/MM/yy'
                )}`}
            </Text>
            <Button onClick={() => refetch?.()}>
              <Icons.RefreshCcw />
            </Button>
          </Box>
          <Box flex="vertical" gap="x3">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell>Name</TableHeaderCell>
                  <TableHeaderCell>Price</TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {usage?.lineItems?.map((e, i) => (
                  <TableRow key={`table-row-${i}`}>
                    <TableCell>{e.name}</TableCell>
                    <TableCell>£{e.price / 100}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell>Account credit</TableCell>
                  <TableCell>
                    £{Math.abs((usage?.endingBalance || 0) / 100)}
                  </TableCell>
                </TableRow>
                <TableRow backgroundColor="background-shade-3">
                  <TableCell>Total</TableCell>
                  <TableCell>£{(usage?.amountDue || 0) / 100}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Alert color="accent" padding="x3" backgroundColor="accent-shade-2">
              <Text size="x1" strong>
                Please note invoice may take several minutes to update after
                subscription changes
              </Text>
            </Alert>
          </Box>
        </ModalBody>
      </Modal>
    </>
  );
};

export default UsageDetailsInvoice;
