import { Box, BoxProps } from 'preshape';
import React, { PropsWithChildren } from 'react';

type Props = BoxProps & {};

const MapSidebarContent = (props: PropsWithChildren<Props>) => {
  return <Box {...props} container grow />;
};

export default MapSidebarContent;
