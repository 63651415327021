import React, { PropsWithChildren, useMemo } from 'react';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { useNotify } from '../Notifications/Notifications';

const Api = ({ children }: PropsWithChildren<{}>) => {
  const notify = useNotify();

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
        queryCache: new QueryCache({
          onError: (error) =>
            notify({
              color: 'negative',
              message: (error as Error).toString(),
            }),
        }),
      }),
    []
  );

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default Api;
