import { Booking } from '@drainify/types';
import { Box, Text } from 'preshape';
import React from 'react';
import useBookingEmails from '../../hooks/useBookingEmails';
import Spinner from '../Spinner/Spinner';
import EmailRow from './EmailRow';

type Props = {
  job: Booking;
};

const EmailLog = ({ job }: Props) => {
  const { list } = useBookingEmails(job.uid);

  if (list.isLoading) {
    return <Spinner />;
  }

  if (list.data?.data.length === 0) {
    return (
      <Box
        padding="x6"
        flex="horizontal"
        alignChildrenHorizontal="middle"
        backgroundColor="background-shade-2"
      >
        <Text textColor="text-shade-4" size="x2">
          No emails
        </Text>
      </Box>
    );
  }

  return (
    <>
      {list?.data?.data.map((e) => (
        <EmailRow key={e.uid} emailStatus={e} />
      ))}
    </>
  );
};

export default EmailLog;
