import { EmailStatus, EmailStatusCodes, EmailType } from '@drainify/types';
import { DateTime } from 'luxon';
import { Box, Icons, Text } from 'preshape';
import React, { useContext } from 'react';
import { ServiceWorkerContext } from '../Bootstrap/ServiceWorkerProvider';
import EmailRowPending from './EmailRowPending';

type Props = {
  emailStatus: EmailStatus;
};

const emailTypeDescriptionMap: Record<EmailType, string> = {
  [EmailType.EMAIL_BOOKING_TO_ASSIGNEE]: 'Email engineer',
  [EmailType.EMAIL_INVITE_TO_INVITEE]: 'Invite',
  [EmailType.EMAIL_SIGNUP_REQUEST]: '',
  [EmailType.EMAIL_REQUEST_ACCESS]: '',
  [EmailType.EMAIL_BOOKING_READY_FOR_QA]: ''
};

const EmailRow = ({ emailStatus }: Props) => {
  const { pendingChanges } = useContext(ServiceWorkerContext);
  return (
    <Box
      flex="horizontal"
      padding="x4"
      backgroundColor="background-shade-2"
      alignChildrenHorizontal="between"
      alignChildrenVertical="middle"
    >
      <Box flex="horizontal" gap="x3">
        <Box>
          {emailStatus.status === EmailStatusCodes.PENDING &&
            !pendingChanges && <EmailRowPending emailStatus={emailStatus} />}
          {emailStatus.status === EmailStatusCodes.SUCCESS && (
            <Icons.Check color="green" />
          )}
          {emailStatus.status === EmailStatusCodes.FAILED && (
            <Icons.X color="red" />
          )}
        </Box>
        <Box flex="vertical">
          <Text strong size="x2">
            {emailTypeDescriptionMap[emailStatus.type]}
          </Text>{' '}
          <Text size="x1">
            {emailStatus.sent &&
              DateTime.fromJSDate(new Date(emailStatus.sent)).toFormat(
                'dd MMM yyyy @ HH:mm'
              )}
          </Text>
          <Text size="x1">{emailStatus.recipient}</Text>
        </Box>
      </Box>
    </Box>
  );
};

export default EmailRow;
