import { Customer } from '@drainify/types/src';
import { Attributes, ButtonProps, Icons, Text } from 'preshape';
import React, { PropsWithChildren, useState } from 'react';
import AsyncButton from '../AsyncButton/AsyncButton';
import { useReportContext } from '../Report/ReportProvider';
import CustomerModal from './CustomerModal';

type Props = ButtonProps & {
  onSave: (customer: Customer) => void;
  customer?: Customer;
};

const CustomerCreateButton = ({
  onSave,
  customer,
  ...rest
}: Attributes<HTMLButtonElement, PropsWithChildren<Props>>) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isError, isLoading } = useReportContext();

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <AsyncButton
        variant="tertiary"
        color="accent"
        {...rest}
        isError={isError}
        isLoading={isLoading}
        onClick={handleOpenModal}
        type="button"
      >
        <Text>New customer</Text>
        <Icons.Plus size="1rem" />
      </AsyncButton>

      <CustomerModal
        onClose={handleCloseModal}
        visible={isModalOpen}
        onSave={onSave}
        customer={customer}
      />
    </>
  );
};

export default CustomerCreateButton;
