import { Member, MemberPostBody, ResponseError } from '@drainify/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useFetch, { hasAllParams } from '../components/Api/useFetch';
import { DataHookSingleEntity } from '.';

const useOrgMember: DataHookSingleEntity<
  'orgId' | 'memberId',
  Member,
  MemberPostBody
> = ({ orgId, memberId }, { noQuery } = {}) => {
  const queryClient = useQueryClient();
  const fetch = useFetch();
  const key = [`/orgs/${orgId}/members/${memberId}`, fetch];
  const params = { orgId: orgId!, memberId: memberId! };

  const query = useQuery<Member, ResponseError>(
    key,
    () => fetch('/orgs/:orgId/members/:memberId', { method: 'GET', params }),
    { enabled: hasAllParams(params) && !noQuery }
  );

  const update = useMutation<Member, ResponseError, MemberPostBody>(
    (body) =>
      fetch('/orgs/:orgId/members/:memberId', { method: 'PUT', body, params }),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(key, data);
        queryClient.refetchQueries(['projects']);
        queryClient.refetchQueries([`/orgs/${orgId}/members`]);
      },
    }
  );

  const remove = useMutation<void, ResponseError>(
    () => fetch('/orgs/:orgId/members/:memberId', { method: 'DELETE', params }),
    {
      onSuccess: () => {
        queryClient.setQueryData(key, null);
        queryClient.refetchQueries(['projects']);
        queryClient.refetchQueries([`/orgs/${orgId}/members`]);
      },
    }
  );

  return { query, update, remove };
};

export default useOrgMember;
