import { Box } from 'preshape';
import React from 'react';
import Spinner from '../Spinner/Spinner';

type Props = {
  onMore: () => void;
};

const InfiniteScroll = ({ onMore }: Props) => {
  const refMoreTrigger = React.useRef<HTMLElement>(null);

  React.useEffect(() => {
    if (onMore && refMoreTrigger.current) {
      const target = refMoreTrigger.current;
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries.some((entry) => entry.isIntersecting)) {
            onMore();
          }
        },
        {
          threshold: [0.0],
        }
      );

      observer.observe(refMoreTrigger.current);

      return () => {
        observer.unobserve(target);
        observer.disconnect();
      };
    }
  }, [onMore]);

  return (
    <Box ref={refMoreTrigger}>
      <Spinner />
    </Box>
  );
};

export default InfiniteScroll;
