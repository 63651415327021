import { Section, SectionUseCode } from '@drainify/types';
import { getMidPointBetweenPoints } from '@drainify/utils';
import round from 'lodash.round';
import { Label, Text } from 'preshape';
import React, { useMemo } from 'react';
import Distance from '../../../DistanceInput/Distance';
import MapLabel from '../../../Map/MapMarker/MapLabel';
import MapMarkerToolbar from '../../../Map/MapMarker/MapMarkerToolbar';
import { useReportEditorContext } from '../../ReportEditorProvider';
import { useReportMapContext } from '../ReportMapProvider';
import useDrawReportMapPipeSimple from './useDrawReportMapPipeSimple';

type Props = {
  section: Section;
  showLabel?: boolean;
};

const ReportMapSectionSimple = ({ section, showLabel = false }: Props) => {
  const { reportEditor } = useReportEditorContext();
  const { focusSection } = useReportMapContext();

  const [nodeStart, nodeEnd] = reportEditor.getSectionNodes(section.uid);

  const midPoint = useMemo(() => {
    if (
      nodeEnd?.point &&
      nodeStart?.point &&
      (!section.additionalPoints || section.additionalPoints.length === 0)
    ) {
      return getMidPointBetweenPoints(nodeStart?.point, nodeEnd?.point);
    } else if (
      nodeStart?.point &&
      section.additionalPoints &&
      section.additionalPoints.length > 0
    ) {
      return getMidPointBetweenPoints(
        nodeStart?.point,
        section.additionalPoints[0].point
      );
    }
  }, [nodeStart, nodeEnd]);

  useDrawReportMapPipeSimple({
    pointA: nodeStart?.point,
    additionalPoints: section.additionalPoints,
    pointB: nodeEnd?.point,
    flow: section.attributes.flow,
    stroke:
      (section.attributes.use &&
        reportMapBorderColorMap[section.attributes.use]) ||
      undefined,
    visible: true,
  });

  if (!showLabel) {
    return null;
  }

  return (
    <MapLabel
      id={section.uid}
      point={midPoint}
      onClick={() => {
        focusSection(section.uid);
      }}
    >
      <MapMarkerToolbar>
        <Label alignChildren="middle" flex="vertical" size="x1">
          <Text strong>{reportEditor.getSectionName(section)}</Text>

          {length && (
            <Text weak>
              (
              <Distance inline type="long" value={round(length, 2)} />)
            </Text>
          )}
        </Label>
      </MapMarkerToolbar>
    </MapLabel>
  );
};

export const reportMapBorderColorMap: Record<
  SectionUseCode,
  string | undefined
> = {
  C: 'purple',
  F: 'red',
  S: 'blue',
  T: 'black',
  W: 'black',
  Z: 'black',
  CB_PACP: 'black',
  DP_PACP: 'black',
  FM_PACP: 'black',
  LG_PACP: 'black',
  LP_PACP: 'black',
  PR_PACP: 'black',
  SS_PACP: 'black',
  SW_PACP: 'black',
  XX_PACP: 'black',
  ZZ_PACP: 'black',
};

export default ReportMapSectionSimple;
