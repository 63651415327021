import {
  ResponseError,
  ResponseMultipleEntities,
  Booking,
} from '@drainify/types';
import { useQuery } from 'react-query';
import useFetch from '../components/Api/useFetch';

const useMeBookings = () => {
  const fetch = useFetch();
  const key = ['/me/bookings', fetch];

  const query = useQuery<ResponseMultipleEntities<Booking>, ResponseError>(
    key,
    () => fetch('/me/bookings', { method: 'GET' })
  );

  return { query };
};

export default useMeBookings;
