import { VideoTranscodeStatus } from '@drainify/types';
import { Box, Text } from 'preshape';
import React from 'react';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import BookingPendingVideos from './BookingPendingVideos';

type Props = {
  revalidate: () => Promise<boolean>;
};

const JobVideoValidation = ({ revalidate }: Props) => {
  const { activeBooking } = useProjectContext();
  const { reportEditor } = useReportEditorContext();

  const pendingVideo =
    activeBooking &&
    reportEditor
      .getJobInspections(activeBooking?.uid)
      .filter((e) => e.video?.status === VideoTranscodeStatus.PENDING);

  if (pendingVideo && pendingVideo?.length > 0) {
    const succeeded = reportEditor
      .getJobInspections(activeBooking.uid)
      .filter((e) => e.video?.status === VideoTranscodeStatus.SUCCEEDED);

    return (
      <Box width={'150px'}>
        <Text size="x1">
          Validating
          {Math.abs(
            pendingVideo.length -
              reportEditor
                .getJobInspections(activeBooking.uid)
                .filter((e) => e.video).length
          )}
          /
          {
            reportEditor
              .getJobInspections(activeBooking.uid)
              .filter((e) => e.video).length
          }
        </Text>
        {pendingVideo.slice(0, 1).map((e) => (
          <BookingPendingVideos inspection={e} key={e.uid} />
        ))}
        <Box width={'150px'}>
          <Box
            backgroundColor="accent-shade-1"
            borderRadius="10px"
            borderColor="text-shade-4"
          >
            <Box
              borderRadius="10px"
              height="6px"
              backgroundColor="accent-shade-5"
              width={`${
                (succeeded.length /
                  reportEditor
                    .getJobInspections(activeBooking.uid)
                    .filter((e) => e.video).length) *
                100
              }%`}
            />
          </Box>
        </Box>
      </Box>
    );
  } else {
    revalidate();
  }

  return null;
};

export default JobVideoValidation;
