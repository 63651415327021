import {
  Member,
  ResponseError,
  ResponseMultipleEntities,
} from '@drainify/types';
import { useQuery } from 'react-query';
import useFetch, { hasAllParams } from '../components/Api/useFetch';

const useOrgMembers = (orgId?: string, searchString: string = '') => {
  const fetch = useFetch();
  const key = [`/orgs/${orgId}/members`, searchString, fetch];
  const params = { orgId: orgId as string };

  const query = useQuery<ResponseMultipleEntities<Member>, ResponseError>(
    key,
    () =>
      fetch(
        '/orgs/:orgId/members',
        { method: 'GET', params },
        { searchString }
      ),
    { enabled: hasAllParams(params) }
  );

  return { query };
};

export default useOrgMembers;
