import { ReportElementType } from '@drainify/utils';
import { DateTime } from 'luxon';
import { Alert, Box, Button, Text } from 'preshape';
import React, { useCallback, useState } from 'react';
import { isMobile } from '../../../../utils/client';
import Explainer from '../../../Explainer/Explainer';
import FeatureFlag from '../../../FeatureFlag/FeatureFlag';
import MapBounds from '../../../Map/MapBounds/MapBounds';
import { useMapLayersContext } from '../../../Map/MapLayers/MapLayers';
import MapNotification from '../../../Map/MapNotification/MapNotification';
import ReportMapDrawing from '../../../Measurement/ReportMapDrawing';
import ReportMapMeasurement from '../../../Measurement/ReportMapMeasurement';
import ReportMapMeasurementScale from '../../../Measurement/ReportMapMeasurementScale';
import { useProjectContext } from '../../../Project/ProjectProvider';
import { useReportEditorContext } from '../../ReportEditorProvider';
import { useReportContext } from '../../ReportProvider';
import { useReportMapContext } from '../ReportMapProvider';
import ReportMapEditSectionShape from './ReportMapEditSectionShape';
import ReportMapNode from './ReportMapNode';
import ReportMapObservation from './ReportMapObservation';
import ReportMapPlan from './ReportMapPlan';
import ReportMapSection from './ReportMapSection';
import ReportMapSectionSimple from './ReportMapSectionSimple';

export const reportMapLayerOrder = {
  [ReportElementType.PLAN]: 0,
  [ReportElementType.DRAWING]: 0,
  [ReportElementType.SECTION]: 1,
  [ReportElementType.NODE]: 2,
  [ReportElementType.OBSERVATION]: 3,
  [ReportElementType.MEASUREMENT]: 4,
};

const RENDER_N_NODES = 10;
const PERFORMANCE_MODE_ITEMS = 10;

const ReportMapElements = () => {
  const { bounds, showSimpleSections, showObservations, limitNodes } =
    useReportContext();
  const { isLayerVisible } = useMapLayersContext();
  const { reportEditor, editSection } = useReportEditorContext();
  const {
    hasFeature,
    focusInspection,
    focusedElementType,
    focusedElementUid,
    isEditingSectionShape,
  } = useReportMapContext();
  const { project } = useProjectContext();
  const { activeBookingId } = useProjectContext();

  const [performanceMode, setPerformanceMode] = React.useState(
    reportEditor.report.nodes.filter((e) => e.point !== undefined).length > 40
  );

  React.useEffect(() => {
    setPerformanceMode(
      reportEditor.report.nodes.filter((e) => e.point !== undefined).length > 40
    );
  }, [reportEditor.report.nodes]);

  const [[linkNodeA, linkNodeB], setLinkNodes] = useState<
    [string | null, string | null]
  >([null, null]);

  const onLinkStart = (nodeUid: string) => {
    setLinkNodes([nodeUid, null]);
  };

  const renderNode = (index: number) =>
    !limitNodes || index > reportEditor.report.nodes.length - RENDER_N_NODES;

  const onLinkNodeFound = (nodeUid: string | null) => {
    setLinkNodes((linkNodes) => {
      if (linkNodes[1] === nodeUid) {
        return linkNodes;
      } else {
        return [linkNodes[0], nodeUid];
      }
    });
  };

  const onLink = () => {
    setLinkNodes(([nodeStartUid, nodeEndUid]) => {
      if (
        nodeStartUid &&
        nodeEndUid &&
        !reportEditor.doesSectionExist(nodeStartUid, nodeEndUid)
      ) {
        const section = reportEditor.addSection(
          {
            nodeStartUid,
            nodeEndUid,
            attributes: project?.siteDefaults?.section || {},
            createdAt: DateTime.now().toISO(),
          },
          activeBookingId
        );
        if (project?.siteDefaults?.showModalOnDrop) {
          editSection(section.uid);
        }
        const sectionSurveys = reportEditor
          .getSectionInspections(section.uid)
          .filter((e) => e.jobId === activeBookingId);
        if (sectionSurveys) {
          focusInspection(sectionSurveys[sectionSurveys.length - 1].uid);
        }
      }

      return [null, null];
    });
  };

  const handleBoundsChange = useCallback(
    (bounds: GeoJSON.Polygon) => {
      reportEditor.updateBounds(bounds);
    },
    [bounds, reportEditor]
  );

  if (performanceMode && focusedElementType === undefined && !isMobile()) {
    return (
      <>
        <MapNotification color={'black'} typePosition="top-right">
          <Box flex="vertical" gap="x3">
            <Alert
              color={'positive'}
              backgroundColor="positive-shade-1"
              padding="x3"
            >
              <Box flex="horizontal" alignChildrenVertical="middle">
                <Text strong>Performance mode</Text>
                <Explainer title={'What is performance mode?'}>
                  <Text>When you have many items on the field</Text>
                  <Text>In order to keep Drainify running at good speed</Text>
                  <Text>
                    We will reduce the quality of the sections on the screen
                  </Text>
                </Explainer>
              </Box>
              <Text size="x2">We noticed your project is very big!</Text>

              <Text size="x2">
                We've simplified the graphic for some of your nodes/sections
              </Text>
              <Text size="x2">
                You can cycle through them to see them in full detail
              </Text>
              <Text size="x2">Click the "?" for more information</Text>
            </Alert>
            <FeatureFlag>
              <Button onClick={() => setPerformanceMode(false)}>
                Override
              </Button>
            </FeatureFlag>
          </Box>
        </MapNotification>
        {reportEditor.report.plan && (
          <ReportMapPlan
            plan={reportEditor.report.plan}
            key={reportEditor.report.plan.uid}
          />
        )}


        {reportEditor.report.nodes
          .slice(
            reportEditor.report.nodes.filter((e) => e.point !== undefined)
              .length - PERFORMANCE_MODE_ITEMS,
            reportEditor.report.nodes.filter((e) => e.point !== undefined)
              .length
          )
          .map((e) => (
            <>
              <ReportMapNode
                key={e.uid}
                isLinkingEnd={linkNodeB === e.uid}
                isLinkingStart={linkNodeA === e.uid}
                onLink={onLink}
                onLinkStart={() => onLinkStart(e.uid!)}
                onLinkNodeFound={onLinkNodeFound}
                node={e}
                showLabel={true}
              />
            </>
          ))}

        {reportEditor.report.sections.map((e) => (
          <ReportMapSectionSimple
            key={e.uid + 'simple'}
            section={reportEditor.getSectionByUid(e.uid)!}
          />
        ))}

        {reportEditor.report.sections
          .slice(
            reportEditor.report.sections.length -
            Math.floor(PERFORMANCE_MODE_ITEMS / 2),
            reportEditor.report.sections.length
          )
          .map((e) => (
            <>
              <ReportMapSection
                key={e.uid}
                section={e}
                showObservations={false}
              />
            </>
          ))}

        {reportEditor.report.measurements.map((measurement) => (
          <ReportMapMeasurement
            key={measurement.uid}
            measurement={measurement}
            points={measurement.points}
          />
        ))}

        {reportEditor.report.drawings.map((drawing) => (
          <ReportMapDrawing key={drawing.uid} drawing={drawing} />
        ))}

        {reportEditor.report.scale && (
          <ReportMapMeasurementScale
            key={reportEditor.report.scale.uid} // TODO
            scale={reportEditor.report.scale}
          />
        )}
        {bounds && (
          <MapBounds
            bounds={bounds}
            onChange={
              hasFeature('Bounds:change') ? handleBoundsChange : undefined
            }
            blackout={
              reportEditor.report?.plan?.isToScale !== undefined &&
              reportEditor.report?.plan?.isToScale === false
            }
          />
        )}
      </>
    );
  }

  if (
    performanceMode &&
    focusedElementType !== undefined &&
    focusedElementUid
  ) {
    return (
      <>
        {bounds && (
          <MapBounds
            bounds={bounds}
            onChange={
              hasFeature('Bounds:change') ? handleBoundsChange : undefined
            }
            blackout={
              reportEditor.report?.plan?.isToScale !== undefined &&
              reportEditor.report?.plan?.isToScale === false
            }
          />
        )}
        {reportEditor.report.plan && (
          <ReportMapPlan
            plan={reportEditor.report.plan}
            key={reportEditor.report.plan.uid}
          />
        )}

        {focusedElementType === ReportElementType.DRAWING &&
          reportEditor.report.plan && (
            <ReportMapPlan
              plan={reportEditor.report.plan}
              key={reportEditor.report.plan.uid}
            />
          )}
        {focusedElementType === ReportElementType.DRAWING &&
          reportEditor.report.drawings
            .filter((e) => e.uid === focusedElementUid)
            .map((drawing) => (
              <ReportMapDrawing key={drawing.uid} drawing={drawing} />
            ))}
        {focusedElementType === ReportElementType.SECTION &&
          reportEditor.report.sections
            .filter((e) => e.uid === focusedElementUid)
            .map((section) => (
              <>
                <ReportMapSection
                  key={section.uid}
                  section={section}
                  showObservations={true}
                />
                {section.nodeStartUid && (
                  <ReportMapNode
                    key={section.nodeStartUid}
                    isLinkingEnd={false}
                    isLinkingStart={false}
                    onLink={() => { }}
                    onLinkStart={() => () => { }}
                    onLinkNodeFound={() => { }}
                    node={reportEditor.getNodeByUid(section.nodeStartUid)!}
                    showLabel={true}
                  />
                )}
                {section.nodeEndUid && (
                  <ReportMapNode
                    key={section.nodeEndUid}
                    isLinkingEnd={false}
                    isLinkingStart={false}
                    onLink={() => { }}
                    onLinkStart={() => () => { }}
                    onLinkNodeFound={() => { }}
                    node={reportEditor.getNodeByUid(section.nodeEndUid)!}
                    showLabel={true}
                  />
                )}
              </>
            ))}

        {focusedElementType === ReportElementType.INSPECTION &&
          reportEditor.report.sections
            .filter(
              (e) =>
                e.uid ===
                reportEditor.getInspectionByUid(focusedElementUid)?.sectionUid
            )
            .map((section) => (
              <>
                <ReportMapSection
                  key={section.uid}
                  section={section}
                  showObservations={true}
                />
                {section.nodeStartUid && (
                  <ReportMapNode
                    key={section.nodeStartUid}
                    isLinkingEnd={false}
                    isLinkingStart={false}
                    onLink={() => { }}
                    onLinkStart={() => () => { }}
                    onLinkNodeFound={() => { }}
                    node={reportEditor.getNodeByUid(section.nodeStartUid)!}
                    showLabel={true}
                  />
                )}
                {section.nodeEndUid && (
                  <ReportMapNode
                    key={section.nodeEndUid}
                    isLinkingEnd={false}
                    isLinkingStart={false}
                    onLink={() => { }}
                    onLinkStart={() => () => { }}
                    onLinkNodeFound={() => { }}
                    node={reportEditor.getNodeByUid(section.nodeEndUid)!}
                    showLabel={true}
                  />
                )}
              </>
            ))}

        {focusedElementType === ReportElementType.OBSERVATION &&
          reportEditor.report.sections
            .filter(
              (e) =>
                e.uid ===
                reportEditor.getInspectionByUid(
                  reportEditor.getObservationByUid(focusedElementUid)
                    ?.inspectionUid
                )?.sectionUid
            )
            .map((section) => (
              <>
                <ReportMapSection
                  key={section.uid}
                  section={section}
                  showObservations={false}
                />
                <ReportMapObservation
                  observation={
                    reportEditor.getObservationByUid(focusedElementUid)!
                  }
                />
                {section.nodeStartUid && (
                  <ReportMapNode
                    key={section.nodeStartUid}
                    isLinkingEnd={false}
                    isLinkingStart={false}
                    onLink={() => { }}
                    onLinkStart={() => () => { }}
                    onLinkNodeFound={() => { }}
                    node={reportEditor.getNodeByUid(section.nodeStartUid)!}
                    showLabel={true}
                  />
                )}
                {section.nodeEndUid && (
                  <ReportMapNode
                    key={section.nodeEndUid}
                    isLinkingEnd={false}
                    isLinkingStart={false}
                    onLink={() => { }}
                    onLinkStart={() => () => { }}
                    onLinkNodeFound={() => { }}
                    node={reportEditor.getNodeByUid(section.nodeEndUid)!}
                    showLabel={true}
                  />
                )}
              </>
            ))}

        {focusedElementType === ReportElementType.MEASUREMENT &&
          reportEditor.report.measurements
            .filter((e) => e.uid === focusedElementUid)
            .map((measurement) => (
              <ReportMapMeasurement
                key={measurement.uid}
                measurement={measurement}
                points={measurement.points}
              />
            ))}
        {focusedElementType === ReportElementType.NODE &&
          reportEditor.report.nodes
            .filter((e) => e.uid === focusedElementUid)
            .map((node) => (
              <>
                <ReportMapNode
                  key={node.uid}
                  isLinkingEnd={linkNodeB === node.uid}
                  isLinkingStart={linkNodeA === node.uid}
                  onLink={onLink}
                  onLinkStart={() => onLinkStart(node.uid)}
                  onLinkNodeFound={onLinkNodeFound}
                  node={node}
                  showLabel={true}
                />
                {reportEditor.report.sections
                  .filter(
                    (e) =>
                      e.nodeStartUid === node.uid || e.nodeEndUid === node.uid
                  )
                  .map((section) => (
                    <ReportMapSection
                      key={section.uid}
                      section={section}
                      showObservations={true}
                    />
                  ))}
              </>
            ))}

        {focusedElementType === ReportElementType.SCALE}
      </>
    );
  }

  return (
    <>
      {reportEditor.report.plan && (
        <ReportMapPlan
          plan={reportEditor.report.plan}
          key={reportEditor.report.plan.uid}
        />
      )}
      {reportEditor.report.nodes.map(
        (node, index) =>
          isLayerVisible(ReportElementType.NODE, node.uid) &&
          renderNode(index) && (
            <ReportMapNode
              key={node.uid}
              isLinkingEnd={linkNodeB === node.uid}
              isLinkingStart={linkNodeA === node.uid}
              onLink={onLink}
              onLinkStart={() => onLinkStart(node.uid)}
              onLinkNodeFound={onLinkNodeFound}
              node={node}
              showLabel={true}
            />
          )
      )}

      {!showSimpleSections &&
        !isEditingSectionShape &&
        reportEditor.report.sections.map((section) => (
          <ReportMapSection
            key={section.uid}
            section={section}
            showObservations={showObservations}
          />
        ))}

      {showSimpleSections &&
        reportEditor.report.sections.map((section) => (
          <ReportMapSectionSimple key={section.uid} section={section} />
        ))}

      {focusedElementType !== undefined &&
        isEditingSectionShape &&
        reportEditor.report.sections
          .filter((section) =>
            isLayerVisible(ReportElementType.SECTION, section.uid)
          )
          .map((section) => (
            <ReportMapEditSectionShape key={section.uid} section={section} />
          ))}

      {reportEditor.report.measurements.map((measurement) => (
        <ReportMapMeasurement
          key={measurement.uid}
          measurement={measurement}
          points={measurement.points}
        />
      ))}

      {reportEditor.report.drawings.map((drawing) => (
        <ReportMapDrawing key={drawing.uid} drawing={drawing} />
      ))}

      {reportEditor.report.scale && (
        <ReportMapMeasurementScale
          key={reportEditor.report.scale.uid} // TODO
          scale={reportEditor.report.scale}
        />
      )}
      {bounds && focusedElementType !== ReportElementType.PLAN && (
        <MapBounds
          bounds={bounds}
          onChange={
            hasFeature('Bounds:change') ? handleBoundsChange : undefined
          }
          blackout={
            reportEditor.report?.plan?.isToScale !== undefined &&
            reportEditor.report?.plan?.isToScale === false
          }
        />
      )}

    </>
  );
};

export default ReportMapElements;
