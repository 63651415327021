import groupBy from 'lodash.groupby';
import { Modal, ModalBody, ModalHeader, Text, Box } from 'preshape';
import React from 'react';
import ReactPlayer from 'react-player';
import ClassroomIcon from '../../../components/IconsMisc/ClassroomIcon';
import PageContext from '../../../components/Page/PageContext';
import { mobileClassroomVideos } from '../../settingsMenu';
import Page from '../Layout/Page';
import PageBody from '../Layout/PageBody';
import PageHeader from '../Layout/PageHeader';
import PageMenu from '../Layout/PageMenu';
import PageMenuItem from '../Layout/PageMenuItem';

const ClassroomPage = () => {
  const [activeVideo, setActiveVideo] =
    React.useState<{ name: string; url: string }>();

  const groupedVideoes = groupBy(
    mobileClassroomVideos,
    ({ category }) => category
  );

  return (
    <PageContext backToLink="/" backToText="Back">
      <Page>
        <PageHeader />
        <Box flex='horizontal' alignChildrenHorizontal='middle'>
          <Text textColor='accent-shade-5'>
            <ClassroomIcon size='5rem' />
          </Text>
        </Box>
        <PageBody>
          <Box flex="vertical" gap="x3">
            {Object.entries(groupedVideoes)
              .sort((a, b) => (a[0] < b[0] ? -1 : 1))
              .map(([category, videoes]) => (
                <Box key={category} margin='x3'>
                  <Text strong size="x5">
                    {category}
                  </Text>
                  <PageMenu>
                    {videoes.map((v) => (
                      <PageMenuItem onClick={() => setActiveVideo(v)} key={v.url}>
                        {v.name}
                      </PageMenuItem>
                    ))}
                  </PageMenu>
                </Box>
              ))}
          </Box>
        </PageBody>
      </Page>
      <Modal
        visible={activeVideo !== undefined}
        onClose={() => setActiveVideo(undefined)}
      >
        <ModalHeader>{activeVideo?.name}</ModalHeader>
        <ModalBody>
          <ReactPlayer
            url={activeVideo?.url}
            width="auto"
            height="calc(100vh - 150px)"
          />
        </ModalBody>
      </Modal>
    </PageContext>
  );
};

export default ClassroomPage;
