import { useEffect, useState } from 'react';
import { useMapContext } from './Map';
import { MapLayer, MapLayerReturn } from './useMap';

const useDrawGeometry = <T extends MapLayer>(layer: T) => {
  const { drawGeometry } = useMapContext();
  const [layerReturn, setLayerReturn] = useState<MapLayerReturn<T> | null>(
    null
  );

  useEffect(() => {
    const layerReturn = drawGeometry(layer);

    setLayerReturn(layerReturn);

    return () => {
      if (layerReturn) {
        layerReturn.remove();
      }
    };
  }, [layer, drawGeometry]);

  return layerReturn?.update;
};

export default useDrawGeometry;
