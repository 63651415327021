import {
  isCircular,
  LegalStatus,
  LiningType,
  Ownership,
  PipeType,
  PLRSuffix,
  ProjectType,
  Section,
  SectionFlowCode,
  SectionMaterialCode,
  SectionPostBody,
  SectionShapeCode,
  SectionUseCode,
} from '@drainify/types';
import { boolean, mixed, number, object, string } from 'yup';
import ReportEditor from './ReportEditor';

const inputIsValid = (value?: number) => value === undefined || (value >=0 && value <= 1000)

export const getSectionValidations = (
  reportEditor: ReportEditor,
  sectionUid?: string
) => {
  const sectionNames = reportEditor.report.sections
    .filter(({ uid }) => uid !== sectionUid)
    .map((section) => reportEditor.getSectionName(section));

  const section = reportEditor.getSectionByUid(sectionUid);

  return object().shape({
    name: string().unique(sectionNames, 'Name already exists'),
    nodeStartUid: string().required('Required'),
    nodeEndUid: string().test(
      'End node',
      'Select end node, or mark as unknown',
      (e) => e !== ''
    ),
    attributes: object().shape({
      alternateID: string().test(
        'length',
        `Maximum length is 50 characters`,
        (e) => !e || e.length <= 50
      ),
      cleaned: boolean(),
      diameter: number().test('Diameter', 'diameter is invalid', (e) => {
        if (!section?.attributes.shape) {
          return inputIsValid(e)
        } else if (!e) {
          return !isCircular(section.attributes.shape);
        } else if (!isCircular(section.attributes.shape)) {
          return inputIsValid(e)
        } else {
          return isCircular(section.attributes.shape) && inputIsValid(e);
        }
      }),
      height: number().test('Height', 'height is invalid', (e) => {
        if (!section?.attributes.shape) {
          return inputIsValid(e)
        } else if (isCircular(section.attributes.shape)) {
          return inputIsValid(e)
        } else if (!e) {
          return isCircular(section?.attributes.shape);
        } else {
          return !isCircular(section.attributes.shape) && inputIsValid(e);
        }
      }),
      width: number().test('Width', 'width', (e) => {
        if (!section?.attributes.shape) {
          return inputIsValid(e);
        } else if (!e) {
          return true;
        } else if (isCircular(section.attributes.shape)) {
          return isCircular(section?.attributes.shape) && inputIsValid(e);
        } else {
          return !isCircular(section.attributes.shape) && inputIsValid(e);
        }
      }),
      divisionOrDistrict: string().test(
        'length',
        `Maximum length is 50 characters`,
        (e) => !e || e.length <= 50
      ),
      drainageArea: string().test(
        'length',
        `Maximum length is 15 characters`,
        (e) => !e || e.length <= 15
      ),

      flow: mixed().oneOf(Object.values(SectionFlowCode)).required('Required'),
      jointLength: number().max(10, 'Joint length must be under 10000mm').min(0),
      sectionOwnership: mixed()
        .oneOf(Object.values(Ownership))
        .required('Required'),
      landOwnership: mixed().oneOf(Object.values(Ownership)),
      legalStatus: mixed().oneOf(Object.values(LegalStatus)),
      length: number()
        .min(0.001)
        .max(10000, 'Length must be under 10000m')
        .required(),
      liningType: mixed().oneOf(Object.values(LiningType)),
      material: mixed()
        .oneOf(Object.values(SectionMaterialCode))
        .required('Required'),
      pipeType: mixed().oneOf(Object.values(PipeType)).required('Required'),
      PLRSuffix: mixed().oneOf(Object.values(PLRSuffix)),
      shape: mixed()
        .oneOf(Object.values(SectionShapeCode))
        .required('Required'),
      use: mixed().oneOf(Object.values(SectionUseCode)).required('Required'),
      yearConstructed: number().max(10000).min(0),
      nodeOneRef: string().test(
        'length',
        `Maximum length is 12 characters`,
        (e) => !e || e.length <= 12
      ),
      nodeTwoRef: string().test(
        'length',
        `Maximum length is 12 characters`,
        (e) => !e || e.length <= 12
      ),
      nodeThreeRef: string().test(
        'length',
        `Maximum length is 12 characters`,
        (e) => !e || e.length <= 12
      ),
      pipeUnitLength: string().test(
        'length',
        `Maximum length is 4 characters`,
        (e) => !e || e.length <= 4
      ),
      expectedLength: number().max(
        10000,
        'Expected length must be under 10000m'
      ).min(0),
      nodeOneCoordinate: string().test(
        'length',
        `Maximum length is 20 characters`,
        (e) => !e || e.length <= 20
      ),
      nodeTwoCoordinate: string().test(
        'length',
        `Maximum length is 20 characters`,
        (e) => !e || e.length <= 20
      ),
      nodeThreeCoordinate: string().test(
        'length',
        `Maximum length is 20 characters`,
        (e) => !e || e.length <= 20
      ),
    }),
  });
};

/**
 *
 */
export type SectionPoints = {
  //
  a: GeoJSON.Point | null;
  //
  b: GeoJSON.Point | null;
  //
  bbox: GeoJSON.BBox | null;
  //
  distance: number | null;
  //
  line: GeoJSON.LineString | null;
  //
  poi: GeoJSON.Point | null;
};

/**
 *
 */
export const emptySectionPoints: SectionPoints = {
  a: null,
  b: null,
  bbox: null,
  distance: null,
  line: null,
  poi: null,
};

/**
 *
 */
export const isSection = (s?: Section | SectionPostBody): s is Section =>
  !!s && 'uid' in s;

export const isBendySection = (s?: Section | SectionPostBody): boolean =>
  s && s.additionalPoints && s.additionalPoints?.length > 0 ? true : false;


const ATTRIBUTE_PIPETYPE = 'attributes.pipeType';
const ATTRIBUTE_FLOW  = 'attributes.flow';
const ATTRIBUTE_LENGTH = 'attributes.length';
const ATTRIBUTE_DIAMETER = 'attributes.diameter';
const ATTRIBUTE_HEIGHT = 'attributes.height';
const ATTRIBUTE_WIDTH = 'attributes.width';
const ATTRIBUTE_MATERIAL = 'attributes.material';
const ATTRIBUTE_SHAPE = 'attributes.shape';
const ATTRIBUTE_USE = 'attributes.use';
const ATTRIBUTE_SECTION_OWNERSHIP = 'attributes.sectionOwnership';

const ATTRIBUTE_DRAINAGE_AREA = 'attributes.drainageArea';
const ATTRIBUTE_JOINT_LENGTH = 'attributes.jointLength';
const ATTRIBUTE_LINING_TYPE = 'attributes.liningType';
const ATTRIBUTE_YEAR_CONSTRUCTED = 'attributes.yearConstructed';
const ATTRIBUTE_LOCATION_TYPE_CODE = 'attributes.locationTypeCode';

const ATTRIBUTE_ALTERNATE_ID = 'attributes.alternateID';
const ATTRIBUTE_DIVISION_OR_DISTRICT = 'attributes.divisionOrDistrict';
const ATTRIBUTE_PLR_SUFFIX = 'attributes.PLRSuffix';
const ATTRIBUTE_NODE_ONE_COORDINATE = 'attributes.nodeOneCoordinate';
const ATTRIBUTE_NODE_ONE_REF = 'attributes.nodeOneRef';
const ATTRIBUTE_TWO_COORDINATE = 'attributes.nodeTwoCoordinate';
const ATTRIBUTE_TWO_REF = 'attributes.nodeTwoRef';
const ATTRIBUTE_THREE_COORDINATE = 'attributes.nodeThreeCoordinate';
const ATTRIBUTE_THREE_REF = 'attributes.nodeThreeRef';
const ATTRIBUTE_LAND_OWNERSHIP = 'attributes.landOwnership';
const ATTRIBUTE_PIPE_LENGTH_REF = 'attributes.pipeLengthRef';
const ATTRIBUTE_DRAIN_SEWER_TYPE = 'attributes.drainSewerType';
const ATTRIBUTE_PIPE_UNIT_LENGTH = 'attributes.pipeUnitLength';
const ATTRIBUTE_EXPECTED_LENGTH = 'attributes.expectedLength';
const ATTRIBUTE_LEGAL_STATUS = 'attributes.legalStatus';

const ATTRIBUTE_COATING_METHOD = 'attributes.coatingMethod';
const ATTRIBUTE_UP_NORTHING = 'attributes.upNorthing';
const ATTRIBUTE_UP_EASTING = 'attributes.upEasting';
const ATTRIBUTE_UP_ELEVATION = 'attributes.upElevation';
const ATTRIBUTE_DOWN_NORTHING = 'attributes.downNorthing';
const ATTRIBUTE_DOWN_EASTING = 'attributes.downEasting';
const ATTRIBUTE_DOWN_ELEVATION = 'attributes.downElevation';
const ATTRIBUTE_UP_RIM_TO_INVERT = 'attributes.upRimToInvert';
const ATTRIBUTE_UP_RIM_TO_GRADE = 'attributes.upRimToGrade';
const ATTRIBUTE_UP_GRADE_TO_INVERT = 'attributes.upGradeToInvert';
const ATTRIBUTE_DOWN_RIM_TO_INVERT = 'attributes.downRimToInvert';
const ATTRIBUTE_DOWN_RIM_TO_GRADE = 'attributes.downRimToGrade';
const ATTRIBUTE_DOWN_GRADE_TO_INVERT = 'attributes.downGradeToInvert';

export const sectionAttributeApplicable = (
  key: string,
  projectType?: ProjectType
) => {
  if (!projectType) return false;
  switch (key) {
    case 'Section name':
    case 'nodeStartUid':
    case 'nodeEndUid':
    case 'additionalPoints':
    case ATTRIBUTE_PIPETYPE:
    case ATTRIBUTE_FLOW:
    case ATTRIBUTE_LENGTH:
    case ATTRIBUTE_DIAMETER:
    case ATTRIBUTE_HEIGHT:
    case ATTRIBUTE_WIDTH:
    case ATTRIBUTE_MATERIAL:
    case ATTRIBUTE_SHAPE:
    case ATTRIBUTE_USE:
    case ATTRIBUTE_SECTION_OWNERSHIP:
    case ATTRIBUTE_DRAINAGE_AREA:
    case ATTRIBUTE_JOINT_LENGTH:
    case ATTRIBUTE_LINING_TYPE:
    case ATTRIBUTE_YEAR_CONSTRUCTED:
    case ATTRIBUTE_LOCATION_TYPE_CODE:
      return [
        ProjectType.MSCC5_COMMERERCIAL,
        ProjectType.MSCC5_DOMESTIC,
        ProjectType.PACP,
        ProjectType.LACP,
      ].includes(projectType);

    // MSCC5 only
    case ATTRIBUTE_ALTERNATE_ID:
    case ATTRIBUTE_DIVISION_OR_DISTRICT:
    case ATTRIBUTE_PLR_SUFFIX:
    case ATTRIBUTE_NODE_ONE_COORDINATE:
    case ATTRIBUTE_NODE_ONE_REF:
    case ATTRIBUTE_TWO_COORDINATE:
    case ATTRIBUTE_TWO_REF:
    case ATTRIBUTE_THREE_COORDINATE:
    case ATTRIBUTE_THREE_REF:
    case ATTRIBUTE_LAND_OWNERSHIP:
    case ATTRIBUTE_PIPE_LENGTH_REF:
    case ATTRIBUTE_DRAIN_SEWER_TYPE:
    case ATTRIBUTE_PIPE_UNIT_LENGTH:
    case ATTRIBUTE_EXPECTED_LENGTH:
    case ATTRIBUTE_LEGAL_STATUS:
      return [
        ProjectType.MSCC5_COMMERERCIAL,
        ProjectType.MSCC5_DOMESTIC,
      ].includes(projectType);

    case ATTRIBUTE_COATING_METHOD:
    case ATTRIBUTE_UP_NORTHING:
    case ATTRIBUTE_UP_EASTING:
    case ATTRIBUTE_UP_ELEVATION:
    case ATTRIBUTE_DOWN_NORTHING:
    case ATTRIBUTE_DOWN_EASTING:
    case ATTRIBUTE_DOWN_ELEVATION:
    case ATTRIBUTE_UP_RIM_TO_INVERT:
    case ATTRIBUTE_UP_RIM_TO_GRADE:
    case ATTRIBUTE_UP_GRADE_TO_INVERT:
    case ATTRIBUTE_DOWN_RIM_TO_INVERT:
    case ATTRIBUTE_DOWN_RIM_TO_GRADE:
    case ATTRIBUTE_DOWN_GRADE_TO_INVERT:
      return [ProjectType.PACP].includes(projectType);
  }
};
