import { ProjectPostBody } from '@drainify/types';
import { ButtonProps, Icons, Text } from 'preshape';
import React, { PropsWithChildren, useContext } from 'react';
import { useNavigate } from 'react-router';
import useMeOrg from '../../hooks/useMeOrg';
import useOrgProjects from '../../hooks/useOrgProjects';
import AsyncButton from '../AsyncButton/AsyncButton';
import { ServiceWorkerContext } from '../Bootstrap/ServiceWorkerProvider';
import { useHasPermission } from '../Permissions/Permissions';
import ProjectModal from './ProjectModal';

type Props = ButtonProps & {
  mini?: boolean;
};

const ProjectCreateButton = ({ mini, ...rest }: PropsWithChildren<Props>) => {
  const navigate = useNavigate();
  const { query: queryMeOrg } = useMeOrg();
  const { create } = useOrgProjects(queryMeOrg.data?.uid);
  const hasCreateProjectPermission = useHasPermission('projects:create');
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { pendingChanges } = useContext(ServiceWorkerContext);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const save = async (project: ProjectPostBody) => {
    const data = await create.mutateAsync(project);
    setIsModalOpen(false);
    navigate(`/projects/${data.uid}`);
  };

  if (!hasCreateProjectPermission) {
    return null;
  }

  return (
    <>
      <AsyncButton
        variant="tertiary"
        {...rest}
        color="accent"
        isError={create.isError}
        isLoading={create.isLoading}
        disabled={pendingChanges}
        onClick={handleOpenModal}
      >
        {mini || <Text>New project</Text>}
        <Icons.Plus />
      </AsyncButton>

      <ProjectModal
        visible={isModalOpen}
        onClose={handleCloseModal}
        onSave={save}
        isCreateError={create.isError}
        isCreateLoading={create.isLoading}
        isCreateSuccess={create.isSuccess}
      />
    </>
  );
};

export default ProjectCreateButton;
