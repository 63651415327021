export type ScalePostBody = {
  startPoint?: GeoJSON.Point;
  endPoint?: GeoJSON.Point;
  geoLength?: number;
  userLength?: number;
};

export type Scale = ScalePostBody & {
  uid: string;
};
