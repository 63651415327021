import {
  Alert,
  Box,
  FormError,
  FormProvider,
  Icons,
  Input,
  InputLabel,
  Text,
} from 'preshape';
import React, { ChangeEvent, FormEvent } from 'react';
import useSignupPreForm from '../../hooks/forms/useSignupPreForm';
import PageHeader from '../../pages/mobile/Layout/PageHeader';
import AsyncButton from '../AsyncButton/AsyncButton';
import Divider from '../Divider/Divider';

const SignupRequestForm = () => {
  const { form } = useSignupPreForm();
  const [error, setError] = React.useState<string>();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  // temp
  const [password, setPassword] = React.useState('');

  const handleSubmit = async (e: FormEvent) => {
    setError(undefined);
    setIsLoading(false);
    setIsSuccess(false);
    setIsError(false);
    if (!form.hasError) {
      setIsLoading(true);
      e.preventDefault();
      const { email, fullName, organisationName } = form.state;
      fetch(`${process.env.DRAINIFY_API_URL}/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          fullName,
          organisationName,
          password,
        }),
      }).then(async (e) => {
        if (e.ok) {
          setIsSuccess(true);
        } else {
          const resp = await e.json();
          setError(resp['message']);
          setIsLoading(false);
          setIsError(true);
        }
      });
    }
  };

  if (isSuccess) {
    return (
      <Box
        alignChildren="middle"
        backgroundColor="background-shade-3"
        flex="vertical"
        alignChildrenHorizontal="middle"
        grow
      >
        <PageHeader title={'Almost there!'} />
        <Box alignChildrenHorizontal="middle" gap="x6" flex="vertical">
          <Box>
            <Text align="middle">Email sent to:</Text>
            <Text strong align="middle" size="x5">
              {form.state.email}
            </Text>
            <Text align="middle"> for a link to get started!</Text>
          </Box>
          <Text align="middle" weak size="x2">
            Allow up to 5 minutes to arrive
          </Text>
        </Box>
      </Box>
    );
  }

  return (
    <Box padding="x6">
      <Text strong size="x6" align='middle'>
        Sign up
      </Text>
      <FormProvider form={form} validateOnlyOneAtATime>
        <FormError name="fullName" margin="x4">
          <InputLabel label="Your name">
            <Input
              borderSize='x1'
              name="fullName"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                form.setState((s) => ({
                  ...s,
                  fullName: e.target.value,
                }))
              }
              value={form.state.fullName}
            />
          </InputLabel>
        </FormError>

        <FormError name="organisationName" id="organisationName" margin="x4">
          <InputLabel label="Organistion name">
            <Input
              borderSize='x1'
              name="organisationName"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                form.setState((s) => ({
                  ...s,
                  organisationName: e.target.value,
                }))
              }
              value={form.state.organisationName}
            />
          </InputLabel>
        </FormError>

        <FormError name="email" margin="x4">
          <InputLabel label="Email">
            <Input
              borderSize='x1'
              name="email"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                form.setState((s) => ({
                  ...s,
                  email: e.target.value,
                }))
              }
              value={form.state.email}
            />
          </InputLabel>
        </FormError>

        <FormError name="confirmEmail" margin="x4">
          <InputLabel label="Confirm email">
            <Input
              borderSize='x1'
              name="confirmEmail"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                form.setState((s) => ({
                  ...s,
                  confirmEmail: e.target.value,
                }))
              }
              value={form.state.confirmEmail}
            />
          </InputLabel>
        </FormError>
        <InputLabel label="Password">
          <Input
              borderSize='x1'
            name="password"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setPassword(e.target.value)
            }
            value={password}
          />
        </InputLabel>
      </FormProvider>
      <Divider />
      <Box flex="horizontal" paddingTop='x8'>
        <AsyncButton
          color="positive"
          isError={isError}
          disabled={form.hasError}
          isLoading={isLoading}
          isSuccess={isSuccess}
          onClick={handleSubmit}
          grow
          size="x3"
          variant="primary"
        >
          Sign up
        </AsyncButton>
      </Box>

      <Divider />
      {error && (
        <Alert
          alignChildrenVertical="middle"
          backgroundColor="negative-shade-2"
          color="negative"
          flex="horizontal"
          gap="x3"
          padding="x3"
        >
          <Icons.AlertTriangle />
          <Text basis="0" grow size="x2">
            {`There was an issue: ${error}`}
          </Text>
        </Alert>
      )}
    </Box>
  );
};

export default SignupRequestForm;
