import { Node } from '@drainify/types';
import { Appear, Box, Icons, Tooltip } from 'preshape';
import React from 'react';
import { useReportEditorContext } from '../Report/ReportEditorProvider';

type Props = Icons.Props & {
  node: Node;
};

const NodeLinkIndicator = ({ node }: Props) => {
  const { reportEditor } = useReportEditorContext();
  const hasSectionsConnected =
    reportEditor.getNodeSections(node.uid).length > 0;
  if (hasSectionsConnected) {
    return null;
  }
  return (
    <Tooltip
      content={'No connected sections'}
      placement="top"
      maxWidth="15rem"
      textColor="light-shade-1"
    >
      {(props) => (
        <Appear>
          <Box>
            <Icons.Link
              {...props}
              size="1.25rem"
              textColor="negative-shade-5"
            />
          </Box>
        </Appear>
      )}
    </Tooltip>
  );
};

export default NodeLinkIndicator;
