import { getSectionPoints } from '@drainify/utils';
import { Box, Motion, Text } from 'preshape';
import React from 'react';
import Scale from '../../../IconsMisc/Scale';
import { useMapContext } from '../../../Map/Map';
import MapNotification from '../../../Map/MapNotification/MapNotification';
import { useReportEditorContext } from '../../ReportEditorProvider';
import useDrawMeasurementScaleMap from '../ReportMapElements/useDrawMeasurementScaleMap';
import { useReportMapContext } from '../ReportMapProvider';

type Props = {};

const ReportMapMeasureScale = ({}: Props) => {
  const {
    addPointerDownListener,
    enableInteractivity,
    disableInteractivity,
    addPointerMoveListener,
    addPointerUpListener,
  } = useMapContext();

  const { reportEditor } = useReportEditorContext();
  const { focusScale } = useReportMapContext();

  const [toggled, setToggled] = React.useState(false);
  const [isDrawing, setIsDrawing] = React.useState(false);
  const [startPoint, setStartPoint] = React.useState<GeoJSON.Point>();
  const [endPoint, setEndPoint] = React.useState<GeoJSON.Point>();

  React.useEffect(() => {
    if (toggled) {
      return addPointerDownListener((point) => {
        setStartPoint(point);
        disableInteractivity();
        setIsDrawing(true);
      });
    }
  }, [toggled]);

  useDrawMeasurementScaleMap({
    pointA: startPoint,
    pointB: endPoint,
    visible: isDrawing,
  });

  React.useEffect(() => {
    if (toggled && isDrawing) {
      return addPointerMoveListener((point) => {
        setEndPoint(point);
      });
    }
  }, [toggled, isDrawing]);

  React.useEffect(() => {
    if (toggled && startPoint) {
      return addPointerUpListener((point) => {
        enableInteractivity();
        setIsDrawing(false);
        const estimatedDistance = getSectionPoints(
          startPoint,
          point
        ).distance?.toFixed(2);
        reportEditor.addScale({
          geoLength: Number(estimatedDistance),
          startPoint: startPoint,
          endPoint: point,
          userLength: 1,
        });
        setStartPoint(undefined);
        setEndPoint(undefined);
        setToggled(false);
        focusScale();
      });
    }
  }, [toggled, startPoint]);

  return (
    <>
      <Box flex="vertical" alignChildrenHorizontal="middle" gap="x1">
        <Box
          name={'Name'}
          style={{
            cursor: 'pointer',
          }}
          padding="x3"
          borderSize="x2"
          borderRadius="10px"
          backgroundColor={toggled ? 'accent-shade-5' : 'accent-shade-1'}
          onClick={() => setToggled(!toggled)}
          borderStyle="dashed"
        >
          <Box flex="horizontal" gap="x2">
            <Scale
              size="1.75rem"
              fill={toggled ? 'white' : 'black'}
              stroke={toggled ? 'white' : 'black'}
            />
          </Box>
        </Box>
        <Text uppercase size="x1" strong>
          {reportEditor.report.scale ? 'Edit scale' : 'Set scale'}
        </Text>
      </Box>
      {toggled && (
        <MapNotification color="negative-shade-4" typePosition="top-left">
          <Motion
            initial={{ opacity: 0.1 }}
            animate={{ opacity: 1 }}
            transition={{
              repeatType: 'loop',
              repeat: Infinity,
              duration: 1,
              repeatDelay: 0.5,
            }}
          >
            <Text size="x4">Drawing scale</Text>
          </Motion>
        </MapNotification>
      )}
    </>
  );
};

export default ReportMapMeasureScale;
