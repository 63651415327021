import { Box } from 'preshape';
import React from 'react';
import { ReportMapNodeRendererType } from '.';
import './css/BN.css';

const ReportMapNodeBR: ReportMapNodeRendererType = ({ locked }) => {
  return (
    <Box
      className="Icon"
      tag="svg"
      viewBox="0 0 24 24"
      height="1.75rem"
      width="1.75rem"
      stroke="white"
      fill="white"
      textColor="white"
      backgroundColor={locked ? 'text-shade-1' : 'accent-shade-5'}
      borderRadius="full"
    >
      {
        <svg id="BR" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
          <path
            className="br-cls-2"
            d="M134.62,177.19c3.85,0,10.49,4.72,16.67,15.27,8.59,14.68,13.52,35.65,13.52,57.54s-4.93,42.87-13.52,57.54c-6.17,10.55-12.82,15.27-16.67,15.27s-10.49-4.72-16.67-15.27c-8.59-14.68-13.52-35.65-13.52-57.54s4.93-42.87,13.52-57.54c6.17-10.55,12.82-15.27,16.67-15.27m0-30c-33.24,0-60.18,46.03-60.18,102.81s26.94,102.81,60.18,102.81,60.18-46.03,60.18-102.81-26.94-102.81-60.18-102.81h0Z"
          />
          <g>
            <path
              className="br-cls-2"
              d="M308.69,182.19c.08,.07,.16,.14,.24,.22,1.95-2.03,3.89-3.14,5.67-3.14,4.41,0,9.78,6.75,14,17.62,.45,1.15,.87,2.35,1.29,3.57,2.24,1.85,4.47,3.71,6.7,5.57,5.53,4.63,10.96,9.4,16.37,14.2-1.68-11.67-4.35-22.43-7.9-31.58-7.46-19.22-18.28-29.8-30.45-29.8-6.98,0-13.51,3.48-19.22,10.07,4.25,4.68,8.76,9.06,13.31,13.28Z"
            />
            <path
              className="br-cls-2"
              d="M336.93,256.59c5.66,5.14,11.32,10.26,16.99,15.39,.73-7.1,1.11-14.47,1.11-21.98,0-1.97-.04-3.94-.09-5.89-6.37-5.63-12.77-11.22-19.21-16.74,.86,7.21,1.32,14.82,1.32,22.63,0,2.21-.04,4.41-.11,6.59Z"
            />
            <path
              className="br-cls-2"
              d="M294.94,217.65c1.36-7.46,3.21-14.33,5.5-20.32-4.87-4.41-9.61-8.99-14.12-13.84-.75,1.64-1.48,3.36-2.17,5.15-1.7,4.37-3.19,9.11-4.47,14.13,5.05,5.01,10.13,9.97,15.26,14.88Z"
            />
            <path
              className="br-cls-2"
              d="M344.74,312.17c.11-.27,.22-.54,.32-.81,2.3-5.93,4.23-12.55,5.76-19.64-5.24-4.76-10.49-9.51-15.74-14.27-1.26,8.44-3.11,16.23-5.5,23.01,5.02,3.95,10.08,7.84,15.16,11.71Z"
            />
            <path
              className="br-cls-2"
              d="M293.17,269.84c-.66-6.37-1.01-13.03-1.01-19.84,0-3.94,.12-7.83,.34-11.64-5.56-5.23-11.08-10.52-16.56-15.86-1.15,8.78-1.76,18.03-1.76,27.5,0,.47,.01,.94,.01,1.41,6.16,6.38,12.5,12.51,18.98,18.43Z"
            />
            <path
              className="br-cls-2"
              d="M321.8,315.84c-2.46,3.15-4.95,4.9-7.2,4.9-4.41,0-9.78-6.75-14-17.62-.77-1.99-1.49-4.1-2.17-6.28-7.7-6.53-15.26-13.27-22.62-20.28,1.63,12.91,4.45,24.81,8.33,34.8,7.46,19.22,18.28,29.8,30.46,29.8,8.2,0,15.77-4.8,22.13-13.8-5-3.81-9.98-7.64-14.93-11.52Z"
            />
          </g>
          <path
            className="br-cls-1"
            d="M421.76,250c0,50.34-20.89,91.16-50.36,91.16H203.47s22.46-36.87,22.46-91.16-22.46-92.16-22.46-92.16c0,0,151.39-2.66,167.93,1,33.77,7.47,50.36,54.33,50.36,91.16Z"
          />
        </svg>
      }
    </Box>
  );
};

export default ReportMapNodeBR;
