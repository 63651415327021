import { Box } from 'preshape';
import React from 'react';
import { ReportMapNodeRendererType } from '.';
import './css/BN.css';

const ReportMapNodeBN: ReportMapNodeRendererType = ({ locked }) => {
  return (
    <Box
      className="Icon"
      tag="svg"
      viewBox="0 0 24 24"
      height="1.75rem"
      width="1.75rem"
      stroke="white"
      fill="white"
      textColor="white"
      backgroundColor={locked ? 'text-shade-1' : 'accent-shade-5'}
      borderRadius="full"
    >
      {
        <svg id="BN" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
          <circle className="bn-cls-1" cx="250" cy="250" r="194.31" />
          <circle className="bn-cls-1" cx="250" cy="250" r="77.52" />
          <ellipse
            className="bn-cls-1"
            cx="250"
            cy="250"
            rx="10.69"
            ry="28.33"
          />
          <ellipse
            className="bn-cls-2"
            cx="382.3"
            cy="249.65"
            rx="10.69"
            ry="28.33"
          />
        </svg>
      }
    </Box>
  );
};

export default ReportMapNodeBN;
