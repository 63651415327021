import { ReportElementType } from '@drainify/utils';
import { useCallback, useMemo } from 'react';
import { useAppContext } from '../../../Bootstrap/Bootstrap';
import useDrawGeometry from '../../../Map/useDrawGeometry';
import { MapLayerUpdateFn, MapLayerLine } from '../../../Map/useMap';
import { reportMapLayerOrder } from './ReportMapElements';

type Props = {
  pointA?: GeoJSON.Point | null;
  pointB?: GeoJSON.Point | null;
  visible?: boolean;
  scale?: number;
};

export const PIPE_WIDTH = 10;
export const PIPE_BORDER_WIDTH = 1;

const useDrawMeasurementScaleMap = ({
  pointA,
  pointB,
  visible = true,
}: Props): MapLayerUpdateFn<MapLayerLine> => {
  const { theme } = useAppContext();

  const geometry = useMemo<GeoJSON.LineString | undefined | null>(
    () =>
      pointA && pointB
        ? {
            type: 'LineString',
            coordinates: [pointA.coordinates, pointB.coordinates],
          }
        : {
            type: 'LineString',
            coordinates: [],
          },
    [pointA, pointB]
  );

  const updatePipeFill = useDrawGeometry<MapLayerLine>(
    useMemo(
      () => ({
        geometry,
        style: {
          zIndex: reportMapLayerOrder[ReportElementType.MEASUREMENT], // TODO change to it's own one
          strokeColor: '#20c997',
          strokeWeight: 4,
          strokeOpacity: 1,
        },
      }),
      [geometry, theme, visible]
    )
  );

  return useCallback(
    (layer) => {
      updatePipeFill?.(layer);
    },
    [updatePipeFill]
  );
};

export default useDrawMeasurementScaleMap;
