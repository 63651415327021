import { Button, ButtonProps } from 'preshape';
import React from 'react';
import useBooking from '../../hooks/useBooking';
import ConfirmationSubmitModal from '../../utils/ConfirmationSubmitModal';
import { useProjectContext } from '../Project/ProjectProvider';

type Props = ButtonProps & {
  onSubmit: () => void;
  validate: () => Promise<boolean>;
};

const JobSubmitButton = ({ onSubmit, validate, ...rest }: Props) => {
  const { activeBookingId } = useProjectContext();
  const { query, update } = useBooking(activeBookingId);

  const [confirmSubmitModalVisible, setConfirmSumitModalVisible] =
    React.useState(false);

  const handleJobSubmit = async () => {
    if (query.data) {
      await update.mutateAsync({
        ...query.data,
        submittedAt: new Date(),
      });
      setConfirmSumitModalVisible(false);
      onSubmit();
    }
  };

  if (!query.data) {
    return null;
  }

  return (
    <>
      <Button
        onClick={async () =>
          (await validate()) && setConfirmSumitModalVisible(true)
        }
        color="positive"
        variant="primary"
        {...rest}
      >
        Submit
      </Button>

      <ConfirmationSubmitModal
        action={handleJobSubmit}
        subheading="This will mark the job as finished and move to 'submitted'"
        visible={confirmSubmitModalVisible}
        onClose={() => setConfirmSumitModalVisible(false)}
      />
    </>
  );
};

export default JobSubmitButton;
