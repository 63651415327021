import { Drawing, DrawingPostBody } from "@drainify/types"
import { Text } from 'preshape'
import React from "react"
import SummaryCardSection from "../SummaryCard/SummaryCardSection";
import { useWizardContext } from "../Wizard/Wizard";

type Props = {
  drawing: DrawingPostBody | Drawing;
}

export default function DrawingModalSummary({ drawing }: Props) {
  const { setActiveStepId } = useWizardContext()
  return (
    <SummaryCardSection
      title="Name"
      value={drawing.name || 'Unnamed drawing'}

      onEdit={() => setActiveStepId('name')}
    >
      <Text>{drawing.name || "Unnamed drawing"}</Text>
    </SummaryCardSection>
  )

}