import { SignupRequest } from '@drainify/types';
import { signInWithEmailAndPassword } from '@firebase/auth';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { getAuth } from 'firebase/auth';
import {
  Alert,
  Box,
  Button,
  FormProvider,
  Icons,
  Input,
  InputLabel,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from 'preshape';
import React, { ChangeEvent } from 'react';
import { useNavigate } from 'react-router';
import useCreateAccountForm from '../../hooks/forms/useCreateAccountForm';
import { FULL_SCREEN_MODAL_WIDTH } from '../App/App';
import Divider from '../Divider/Divider';
import Logo from '../Logo/Logo';
import Wizard from '../Wizard/Wizard';
import WizardControls from '../Wizard/WizardControls/WizardControls';
import WizardReviewStep from '../Wizard/WizardReviewStep';
import WizardStep from '../Wizard/WizardStep';
import WizardStepError from '../Wizard/WizardStepError';
import CardInput from './CardInput';

type Props = {
  signupRequest?: SignupRequest;
};

const SignupCheckoutForm = ({ signupRequest }: Props) => {
  const { form } = useCreateAccountForm();
  const stripe = useStripe();
  const elements = useElements();
  const [showPassword, setShowPassword] = React.useState(false);
  const [error, setError] = React.useState<string>();
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();

  if (!stripe || !elements || !signupRequest) {
    return null;
  }

  const handleSubmit = async () => {
    if (stripe && signupRequest) {
      const resp = await fetch(
        `${process.env.DRAINIFY_API_URL}/payment/setup-card`,
        {
          method: 'POST',
        }
      );
      const { clientSecret } = await resp.json();
      const cardElement = elements!.getElement(CardElement)!;

      const result = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            email: signupRequest.email,
            name: signupRequest.organisationName,
          },
        },
      });

      if (!result.error && result.setupIntent.status === 'succeeded') {
        try {
          await fetch(`${process.env.DRAINIFY_API_URL}/payment/subscribe`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              signupRequestUid: signupRequest?.uid,
              password: form.state.password,
              subscriptionTier: form.state.subscriptionTier,
              paymentMethodId: result.setupIntent.payment_method,
            }),
          });
          // if the above is OK
          await signInWithEmailAndPassword(
            getAuth(),
            signupRequest.email,
            form.state.password
          );
          navigate('/');
        } catch (error) {
          setError('there was an error');
        }
      } else if (result.error) {
        setError(result.error.message);
      }
      setIsLoading(false);
    }
    // handle error cases
  };

  return (
    <FormProvider form={form}>
      <Wizard
        flow={'create'}
        isError={error !== undefined}
        isLoading={isLoading}
        onSave={async () => {
          setIsLoading(true);
          handleSubmit();
        }}
      >
        <Modal
          animation="FadeSlideUp"
          margin="x4"
          maxWidth={FULL_SCREEN_MODAL_WIDTH}
          onClose={() => {}}
          overlayBackgroundCloseOnClick={false}
          visible={true}
        >
          <ModalHeader>
            Welcome, {signupRequest?.organisationName}!{' '}
          </ModalHeader>
          <ModalBody>
            <WizardStep id="welcome">
              <Box flex="vertical" gap="x3">
                <Text strong size="x4">
                  Congratulations!
                </Text>
                <Text strong size="x3">
                  You've taken the first step in enabling your business to
                  deliver world class CCTV Reports
                </Text>
                <Text
                  textColor="accent-shade-5"
                  flex="horizontal"
                  alignChildrenHorizontal="middle"
                >
                  <Logo />
                </Text>
                <Text>
                  We'd like to invite you to play and explore all the features
                  we know you'll love
                </Text>
                <Text></Text>

                <Text>
                  But first. A couple bits of information to seamlessly get you
                  set up.
                </Text>
              </Box>
            </WizardStep>
            <WizardStep id="confirmPassword">
              <Box flex="vertical" gap="x3">
                <InputLabel
                  label="Email"
                  readOnly
                  disabled
                  contentEditable={false}
                >
                  <Input
                    name="email"
                    readOnly
                    value={signupRequest.email}
                    borderSize="x0"
                  />
                </InputLabel>

                <InputLabel label="Enter password">
                  <Input
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      form.setState((s) => ({
                        ...s,
                        password: e.target.value,
                      }))
                    }
                    value={form.state.password}
                  />
                </InputLabel>
                <InputLabel label="Confirm password">
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    disabled={!!form.error['password']}
                    name="confirmPassword"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      form.setState((s) => ({
                        ...s,
                        confirmPassword: e.target.value,
                      }))
                    }
                    value={form.state.confirmPassword}
                  />
                </InputLabel>
                <Divider />
                <Button
                  flex="horizontal"
                  gap="x2"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <>
                      <Icons.EyeOff /> Hide password
                    </>
                  ) : (
                    <>
                      <Icons.Eye /> Show password
                    </>
                  )}
                </Button>
              </Box>
            </WizardStep>
            <WizardReviewStep>
              <Box flex="vertical" gap="x3">
                <Text strong>
                  You are signing up to a free 7 day trial of Drainify
                </Text>

                <Text size="x2">
                  After the trial period, we'll automatically switch you to the
                  flex subscription tier (£7.99 a month/1 credit/100MB storage)
                </Text>

                <Text size="x2">
                  You can cancel at any time by clicking the "Cancel" button in
                  account settings
                </Text>

                <Text size="x2">
                  You will need an active Drainify subscription to publish your
                  report
                </Text>

                <InputLabel label="Card details">
                  <CardInput />
                </InputLabel>
                {error && (
                  <Alert
                    alignChildrenVertical="middle"
                    backgroundColor="negative-shade-2"
                    color="negative"
                    flex="horizontal"
                    gap="x3"
                    padding="x3"
                  >
                    <Icons.AlertTriangle />
                    <Text basis="0" grow size="x2">
                      {`There was an issue: ${error}`}
                    </Text>
                  </Alert>
                )}
              </Box>
            </WizardReviewStep>
          </ModalBody>

          <ModalFooter>
            <WizardStepError />
            <WizardControls />
          </ModalFooter>
        </Modal>
      </Wizard>
    </FormProvider>
  );
};

export default SignupCheckoutForm;
