import { Box } from 'preshape';
import React, { useContext } from 'react';
import { NodeMapContext } from './NodeMapProvider';

type Props = {
  onChange: (point: GeoJSON.Point) => void;
};

const NodeLocationHack = ({ onChange }: Props) => {
  const { setVisible, setOnChange, setMarkType } = useContext(NodeMapContext);
  React.useEffect(() => {
    setOnChange(onChange);
    setMarkType('node');
    setVisible(true);
    return () => {
      setOnChange(() => {});
      setVisible(false);
      setMarkType(undefined);
    };
  }, []);

  return <Box width="calc(100vw - 100px)" height="calc(100vh - 380px)"></Box>;
};

export default NodeLocationHack;
