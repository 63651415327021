import { ButtonProps, Icons, Text, Button } from 'preshape';
import React, { PropsWithChildren, useState } from 'react';
import InviteModal from './InviteModal';

type Props = ButtonProps & {};

const InviteCreateButton = ({ ...rest }: PropsWithChildren<Props>) => {
  const [visible, setVisible] = useState(false);

  const openModal = () => setVisible(true);
  const closeModal = () => setVisible(false);

  return (
    <>
      <Button {...rest} color="accent" onClick={openModal}>
        <Text>Add member</Text>
        <Icons.Plus size="1rem" />
      </Button>

      <InviteModal onClose={closeModal} visible={visible} />
    </>
  );
};

export default InviteCreateButton;
