import { Button, ButtonProps } from 'preshape';
import React from 'react';
import useBooking from '../../hooks/useBooking';
import ConfirmationSubmitModal from '../../utils/ConfirmationSubmitModal';
import { useProjectContext } from '../Project/ProjectProvider';

type Props = ButtonProps;

const JobReturnToAwaitingQAButton = ({ ...rest }: Props) => {
  const { activeBookingId } = useProjectContext();
  const { query, update } = useBooking(activeBookingId);

  const [retractCompletionModalVisible, setRetractCompletionModalVisible] =
    React.useState(false);

  const handleJobReturnToQA = async () => {
    if (query.data) {
      await update.mutateAsync({
        ...query.data,
        completedAt: null,
        pdfReport: null,
      });
    }
  };

  if (!query.data) {
    return null;
  }

  return (
    <>
      <Button
        color="negative"
        variant="primary"
        onClick={() => setRetractCompletionModalVisible(true)}
        {...rest}
      >
        Reopen
      </Button>
      <ConfirmationSubmitModal
        action={handleJobReturnToQA}
        subheading="This will place the job back into 'submitted'"
        visible={retractCompletionModalVisible}
        onClose={() => setRetractCompletionModalVisible(false)}
      />
    </>
  );
};

export default JobReturnToAwaitingQAButton;
