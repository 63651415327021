import { EmailStatus, EmailStatusCodes, ResponseError } from '@drainify/types';
import { useQuery, useQueryClient } from 'react-query';
import useFetch from '../components/Api/useFetch';

const useEmailStatus = (bookingId?: string, emailStatusId?: string) => {
  const fetch = useFetch();
  const key = [`/bookings/${bookingId}/emails/status/${emailStatusId}`, fetch];
  const params = {
    bookingId: bookingId as string,
    emailStatusId: emailStatusId as string,
  };
  const queryClient = useQueryClient();

  const poll = useQuery<EmailStatus, ResponseError>(
    key,
    () =>
      fetch('/bookings/:bookingId/emails/status/:emailStatusId', {
        method: 'PUT',
        params,
      }),
    {
      onSuccess: (data) => {
        if (
          data.status &&
          (data.status === EmailStatusCodes.FAILED ||
            data.status === EmailStatusCodes.SUCCESS)
        ) {
          queryClient.refetchQueries([`/bookings/${bookingId}/emails`]);
        }
      },
      refetchInterval: (data) =>
        data?.status && data.status === EmailStatusCodes.PENDING
          ? 10000
          : false,
    }
  );

  return { poll };
};

export default useEmailStatus;
