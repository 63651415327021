import {
  Booking,
  BookingPostBody,
  InspectionStage,
  ProjectType,
  User,
} from '@drainify/types';
import { runValidations } from '@drainify/utils';
import { DateTime } from 'luxon';
import { useForm } from 'preshape';
import { useCallback, useMemo } from 'react';
import { v4 } from 'uuid';
import * as yup from 'yup';
import { object } from 'yup';
import useMeOrg from '../useMeOrg';
import useOrgBookings from '../useOrgBookings';

export const jobValidations = (jobIds: string[]) =>
  yup.object().shape({
    jobId: yup
      .string()
      .required('Job id is required')
      .unique(jobIds, 'Job number must be unique')
      .test(
        'Length',
        'Max length is 50 characters',
        (e) => !!e && e.length <= 50
      ),
    assignedTo: yup.array(),
    bookedForDate: yup.string().required('Booked for date is required'),
    bookedForTimeStart: yup.array().required(''),
    projectType: yup
      .mixed()
      .oneOf(Object.values(ProjectType))
      .required('Project type is required'),
    clientJobRef: yup
      .string()
      .test(
        'Length',
        'Max length is 26 characters',
        (e) => !e || e.length <= 26
      ),
    attributes: object().shape({
      inspectionStage: yup.mixed().oneOf(Object.values(InspectionStage)),
      clientDefinedOne: yup
        .string()
        .test(
          'Length',
          'Max length is 80 characters',
          (e) => !e || e.length <= 80
        ),
      clientDefinedTwo: yup
        .string()
        .test(
          'Length',
          'Max length is 80 characters',
          (e) => !e || e.length <= 80
        ),
      clientDefinedThree: yup
        .string()
        .test(
          'Length',
          'Max length is 80 characters',
          (e) => !e || e.length <= 80
        ),
      clientDefinedFour: yup
        .string()
        .test(
          'Length',
          'Max length is 80 characters',
          (e) => !e || e.length <= 80
        ),
      clientDefinedFive: yup
        .string()
        .test(
          'Length',
          'Max length is 80 characters',
          (e) => !e || e.length <= 80
        ),
      clientDefinedSix: yup
        .string()
        .test(
          'Length',
          'Max length is 80 characters',
          (e) => !e || e.length <= 80
        ),
    }),
  });

const useBookingForm = (
  booking?: Booking | BookingPostBody,
  assignedTo?: User[],
  parentJobId?: string,
  defaultJobId?: string
) => {
  const { query: queryOrg } = useMeOrg();
  const { query: queryBookings } = useOrgBookings(queryOrg.data?.uid);
  const jobIds = queryBookings.data?.data
    .map((e) => e.jobId || '')
    .filter((e) => e !== booking?.jobId);
  const initial = useMemo<BookingPostBody>(
    () => ({
      jobId: defaultJobId || booking?.jobId || `DRNFY-${v4().slice(0, 4)}`,
      clientJobRef: booking?.clientJobRef,
      assignedTo: booking?.assignedTo || assignedTo || [],
      parentJobId,
      bookedForDate:
        booking?.bookedForDate || DateTime.now().toFormat('yyyy-MM-dd'),
      bookedForTimeStart: booking?.bookedForTimeStart || [9, 0],
      jobPurpose: booking?.jobPurpose,
      projectType: booking?.projectType,
      attributes: booking?.attributes || {},
    }),
    [assignedTo, booking, parentJobId]
  );

  return useForm({
    initial: initial,
    validate: useCallback((booking, setError) => {
      setError(runValidations(jobValidations(jobIds || []), booking));
    }, []),
  });
};

export default useBookingForm;
