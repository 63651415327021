import {
  getJobManagementSearchTypeSchema,
  jobManagementSearchTypes,
} from '@drainify/utils';
import { Box, Button, CheckBox, Icons, Motion } from 'preshape';
import React from 'react';
import { useJobSearchContext } from '../../pages/desktop/Pages/AdminPage';
import { useLanguageContext } from '../Usage/LanguageProvider';

const JobAdminSearchOptionsSearch = () => {
  const { language } = useLanguageContext();
  const { setSearchType, searchType } = useJobSearchContext();
  const [dropdown, setDropdown] = React.useState(false);

  return (
    <Box>
      <Button
        onClick={() => setDropdown(!dropdown)}
        variant="tertiary"
        flex="horizontal"
        alignChildrenHorizontal="start"
        gap="x2"
        borderSize="x1"
      >
        {dropdown ? <Icons.ChevronUp /> : <Icons.ChevronDown />}{' '}
        {getJobManagementSearchTypeSchema(searchType).name[language]}
      </Button>
      <Motion
        overflow="hidden"
        animate={{
          height: dropdown ? '100%' : '0px',
        }}
        initial={{ height: '0px' }}
        style={{ maxWidth: '100%' }}
        transition={{ bounceStiffness: 100, bounceDamping: 8 }}
      >
        <Box flex="vertical" gap="x1">
          {jobManagementSearchTypes.map((e) => (
            <CheckBox
              key={e.code}
              checked={e.code === searchType}
              onChange={() => {
                setSearchType(e.code);
                setDropdown(false);
              }}
            >
              {getJobManagementSearchTypeSchema(e.code).name[language]}
            </CheckBox>
          ))}
        </Box>
      </Motion>
    </Box>
  );
};

export default JobAdminSearchOptionsSearch;
