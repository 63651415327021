import { Box, Icons } from 'preshape';
import React from 'react';
import NodesIcon from '../../../IconsMisc/NodesIcon';
import SectionsIcon from '../../../IconsMisc/SectionsIcon';
import MapSidebar from '../../../Map/MapSidebar/MapSidebar';
import MapSidebarContent from '../../../Map/MapSidebar/MapSidebarContent';
import MapSidebarMenu from '../../../Map/MapSidebar/MapSidebarMenu';
import MapSidebarMenuItem from '../../../Map/MapSidebar/MapSidebarMenuItem';
import MapSidebarMenuItemGroup from '../../../Map/MapSidebar/MapSidebarMenuItemGroup';
import { useReportMapContext } from '../ReportMapProvider';
import ReportMapSidebarDrawing from './ReportMapSidebarDrawing';
import ReportMapSidebarInspection from './ReportMapSidebarInspection';
import ReportMapSidebarJob from './ReportMapSidebarJob';
import ReportMapSidebarMeasurement from './ReportMapSidebarMeasurement';
import ReportMapSidebarMeasurementScale from './ReportMapSidebarMeasurementScale';
import ReportMapSidebarNode from './ReportMapSidebarNode';
import ReportMapSidebarNodes from './ReportMapSidebarNodes';
import ReportMapSidebarObservation from './ReportMapSidebarObservation';
import ReportMapSidebarPlan from './ReportMapSidebarPlan';
import ReportMapSidebarPlanEdit from './ReportMapSidebarPlanEdit';
import ReportMapSidebarSection from './ReportMapSidebarSection';
import ReportMapSidebarSections from './ReportMapSidebarSections';
import ReportMapSidebarTools from './ReportMapSidebarTools';

const ReportMapSidebar = () => {
  const { activeSidebar, setActiveSidebar } = useReportMapContext();
  const { isFocused } = useReportMapContext();

  return (
    <Box flex="vertical" grow>
      <MapSidebar basis="0" grow>
        <MapSidebarMenu>
          <MapSidebarMenuItemGroup>
            <MapSidebarMenuItem
              active={activeSidebar === 'job'}
              icon={<Icons.CheckSquare size="1.25rem" />}
              onClick={() => setActiveSidebar('job')}
              title="Job"
            />
          </MapSidebarMenuItemGroup>
          <MapSidebarMenuItemGroup>
            <MapSidebarMenuItem
              active={activeSidebar === 'nodes'}
              icon={<NodesIcon size="1.25rem" />}
              onClick={() => setActiveSidebar('nodes')}
              title="Nodes"
            />
            <MapSidebarMenuItem
              active={activeSidebar === 'sections'}
              icon={<SectionsIcon size="1.25rem" />}
              onClick={() => setActiveSidebar('sections')}
              title="Sections"
            />
            <MapSidebarMenuItem
              active={activeSidebar === 'plan'}
              icon={<Icons.BookOpen size="1.25rem" />}
              onClick={() => setActiveSidebar('plan')}
              title="Plan"
            />
            <MapSidebarMenuItem
              active={activeSidebar === 'tools'}
              icon={<Icons.Tool size="1.25rem" />}
              onClick={() => setActiveSidebar('tools')}
              title="Tools"
            />
          </MapSidebarMenuItemGroup>

          {isFocused && (
            <MapSidebarMenuItemGroup>
              <MapSidebarMenuItem
                active={activeSidebar === 'info'}
                icon={<Icons.Info size="1.25rem" />}
                onClick={() => setActiveSidebar('info')}
                title="Info"
              />
            </MapSidebarMenuItemGroup>
          )}
        </MapSidebarMenu>

        <Box flex="vertical" width="350px">
          <MapSidebarContent>
            <ReportMapSidebarJob />
            <ReportMapSidebarNodes />
            <ReportMapSidebarSections />
            <ReportMapSidebarNode />
            <ReportMapSidebarMeasurement />
            <ReportMapSidebarDrawing />
            <ReportMapSidebarMeasurementScale />
            <ReportMapSidebarTools />
            <ReportMapSidebarPlan />
            <ReportMapSidebarPlanEdit />
            <ReportMapSidebarSection />
            <ReportMapSidebarInspection />
            <ReportMapSidebarObservation />
          </MapSidebarContent>
        </Box>
      </MapSidebar>
    </Box>
  );
};

export default ReportMapSidebar;
