import { Section, SectionPostBody } from '@drainify/types';
import { isSection, sectionAttributeApplicable } from '@drainify/utils';
import React from 'react';
import Distance from '../DistanceInput/Distance';
import { useHasPermission } from '../Permissions/Permissions';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import SummaryCard from '../SummaryCard/SummaryCard';
import SummaryCardSection from '../SummaryCard/SummaryCardSection';

type Props = {
  section?: Section | SectionPostBody;
};

const SectionAdditionalSummary = ({ section }: Props) => {
  const { editSectionAdditional } = useReportEditorContext();
  const { reportEditor } = useReportEditorContext();
  const hasUpdateProjectPermission = useHasPermission('projects:update');
  const error = reportEditor.validateSection(section);
  const { activeBooking } = useProjectContext();

  const editSectionStep = (step: string) => {
    if (!hasUpdateProjectPermission) {
      return undefined;
    }
    if (!!activeBooking?.completedAt) {
      return undefined;
    }

    return () => {
      if (isSection(section)) {
        editSectionAdditional(section.uid, step);
      }
    };
  };

  return (
    <SummaryCard title="Additional information">
      {sectionAttributeApplicable(
        'attributes.alternateID',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          error={error?.['attributes.alternateID']}
          onEdit={editSectionStep('attributes.alternateID')}
          title="Alternate ID"
          value={section?.attributes?.alternateID}
        >
          {section?.attributes.alternateID}
        </SummaryCardSection>
      )}
      {sectionAttributeApplicable(
        'attributes.divisionOrDistrict',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Division or district"
          error={error?.['attributes.divisionOrDistrict']}
          onEdit={editSectionStep('attributes.divisionOrDistrict')}
          value={section?.attributes?.divisionOrDistrict}
        >
          {section?.attributes.divisionOrDistrict}
        </SummaryCardSection>
      )}
      {sectionAttributeApplicable(
        'attributes.drainageArea',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Drainage area"
          error={error?.['attributes.drainageArea']}
          onEdit={editSectionStep('attributes.drainageArea')}
          value={section?.attributes?.drainageArea}
        >
          {section?.attributes.drainageArea}
        </SummaryCardSection>
      )}
      {sectionAttributeApplicable(
        'attributes.jointLength',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Joint length"
          error={error?.['attributes.jointLength']}
          onEdit={editSectionStep('attributes.jointLength')}
          value={section?.attributes?.jointLength}
        >
          <Distance type="short" value={section?.attributes.jointLength} />
        </SummaryCardSection>
      )}
      {sectionAttributeApplicable(
        'attributes.PLRSuffix',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          error={error?.['attributes.PLRSuffix']}
          title="PLR Suffix"
          onEdit={editSectionStep('attributes.PLRSuffix')}
          value={section?.attributes?.PLRSuffix}
        >
          {section?.attributes.PLRSuffix}
        </SummaryCardSection>
      )}
      {sectionAttributeApplicable(
        'attributes.landOwnership',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          error={error?.['attributes.landOwnership']}
          title="Land ownership"
          onEdit={editSectionStep('attributes.landOwnership')}
          value={section?.attributes?.landOwnership}
        >
          {section?.attributes.landOwnership}
        </SummaryCardSection>
      )}
      {sectionAttributeApplicable(
        'attributes.legalStatus',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          error={error?.['attributes.landOwnership']}
          title="Legal status"
          onEdit={editSectionStep('attributes.legalStatus')}
          value={section?.attributes?.legalStatus}
        >
          {section?.attributes.legalStatus}
        </SummaryCardSection>
      )}
      {sectionAttributeApplicable(
        'attributes.liningType',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Lining type"
          error={error?.['attributes.liningType']}
          onEdit={editSectionStep('attributes.liningType')}
          value={section?.attributes?.liningType}
        >
          {section?.attributes.liningType}
        </SummaryCardSection>
      )}
      {sectionAttributeApplicable(
        'attributes.coatingMethod',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Coating method"
          error={error?.['attributes.coatingMethod']}
          onEdit={editSectionStep('attributes.coatingMethod')}
          value={section?.attributes?.coatingMethod}
        >
          {section?.attributes.coatingMethod}
        </SummaryCardSection>
      )}
      {sectionAttributeApplicable(
        'attributes.yearConstructed',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Year constructed"
          error={error?.['attributes.yearConstructed']}
          onEdit={editSectionStep('attributes.yearConstructed')}
          value={section?.attributes?.yearConstructed}
        >
          {section?.attributes.yearConstructed}
        </SummaryCardSection>
      )}
      {sectionAttributeApplicable(
        'attributes.yearRenewed',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Year Renewed"
          error={error?.['attributes.yearRenewed']}
          onEdit={editSectionStep('attributes.yearRenewed')}
          value={section?.attributes?.yearRenewed}
        >
          {section?.attributes.yearRenewed}
        </SummaryCardSection>
      )}
      {sectionAttributeApplicable(
        'attributes.nodeOneCoordinate',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Node one coordinate"
          error={error?.['attributes.nodeOneCoordinate']}
          onEdit={editSectionStep('attributes.nodeOneCoordinate')}
          value={section?.attributes?.nodeOneCoordinate}
        >
          {section?.attributes.nodeOneCoordinate}
        </SummaryCardSection>
      )}
      {sectionAttributeApplicable(
        'attributes.nodeOneRef',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Node one reference"
          error={error?.['attributes.nodeOneRef']}
          onEdit={editSectionStep('attributes.nodeOneRef')}
          value={section?.attributes?.nodeOneRef}
        >
          {section?.attributes.nodeOneRef}
        </SummaryCardSection>
      )}
      {sectionAttributeApplicable(
        'attributes.nodeTwoCoordinate',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Node two coordinate"
          error={error?.['attributes.nodeTwoCoordinate']}
          onEdit={editSectionStep('attributes.nodeTwoCoordinate')}
          value={section?.attributes?.nodeTwoCoordinate}
        >
          {section?.attributes.nodeTwoCoordinate}
        </SummaryCardSection>
      )}
      {sectionAttributeApplicable(
        'attributes.nodeTwoRef',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Node two reference"
          error={error?.['attributes.nodeTwoRef']}
          onEdit={editSectionStep('attributes.nodeTwoRef')}
          value={section?.attributes?.nodeTwoRef}
        >
          {section?.attributes.nodeTwoRef}
        </SummaryCardSection>
      )}
      {sectionAttributeApplicable(
        'attributes.nodeThreeCoordinate',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Node three coordinate"
          error={error?.['attributes.nodeThreeCoordinate']}
          onEdit={editSectionStep('attributes.nodeThreeCoordinate')}
          value={section?.attributes?.nodeThreeCoordinate}
        >
          {section?.attributes.nodeThreeCoordinate}
        </SummaryCardSection>
      )}
      {sectionAttributeApplicable(
        'attributes.nodeThreeRef',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Node three reference"
          error={error?.['attributes.nodeThreeRef']}
          onEdit={editSectionStep('attributes.nodeThreeRef')}
          value={section?.attributes?.nodeThreeRef}
        >
          {section?.attributes.nodeThreeRef}
        </SummaryCardSection>
      )}
      {sectionAttributeApplicable(
        'attributes.nodeTwoCoordinate',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Node two coordinate"
          error={error?.['attributes.nodeTwoCoordinate']}
          onEdit={editSectionStep('attributes.nodeTwoCoordinate')}
          value={section?.attributes?.nodeTwoCoordinate}
        >
          {section?.attributes.nodeTwoCoordinate}
        </SummaryCardSection>
      )}
      {sectionAttributeApplicable(
        'attributes.pipeLengthRef',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Pipe length reference"
          error={error?.['attributes.pipeLengthRef']}
          onEdit={editSectionStep('attributes.pipeLengthRef')}
          value={section?.attributes?.pipeLengthRef}
        >
          {section?.attributes.pipeLengthRef}
        </SummaryCardSection>
      )}
      {sectionAttributeApplicable(
        'attributes.locationTypeCode',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Location type code"
          error={error?.['attributes.locationTypeCode']}
          onEdit={editSectionStep('attributes.locationTypeCode')}
          value={section?.attributes?.locationTypeCode}
        >
          {section?.attributes.locationTypeCode}
        </SummaryCardSection>
      )}
      {sectionAttributeApplicable(
        'attributes.drainSewerType',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Drain sewer type"
          error={error?.['attributes.drainSewerType']}
          onEdit={editSectionStep('attributes.drainSewerType')}
          value={section?.attributes?.drainSewerType}
        >
          {section?.attributes.drainSewerType}
        </SummaryCardSection>
      )}
      {sectionAttributeApplicable(
        'attributes.pipeUnitLength',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Pipe unit length"
          error={error?.['attributes.pipeUnitLength']}
          onEdit={editSectionStep('attributes.pipeUnitLength')}
          value={section?.attributes?.pipeUnitLength}
        >
          {section?.attributes.pipeUnitLength}
        </SummaryCardSection>
      )}
      {sectionAttributeApplicable(
        'attributes.expectedLength',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Expected length"
          error={error?.['attributes.expectedLength']}
          onEdit={editSectionStep('attributes.expectedLength')}
          value={section?.attributes?.expectedLength}
        >
          <Distance type="long" value={section?.attributes.expectedLength} />
        </SummaryCardSection>
      )}
      {sectionAttributeApplicable(
        'attributes.upNorthing',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Up Northing"
          error={error?.['attributes.upNorthing']}
          onEdit={editSectionStep('attributes.upNorthing')}
          value={section?.attributes?.upNorthing}
        >
          {section?.attributes.upNorthing}
        </SummaryCardSection>
      )}
      {sectionAttributeApplicable(
        'attributes.upEasting',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Up Easting"
          error={error?.['attributes.upEasting']}
          onEdit={editSectionStep('attributes.upEasting')}
          value={section?.attributes?.upEasting}
        >
          {section?.attributes.upEasting}
        </SummaryCardSection>
      )}
      {sectionAttributeApplicable(
        'attributes.upEasting',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Up Elevation"
          error={error?.['attributes.upElevation']}
          onEdit={editSectionStep('attributes.upElevation')}
          value={section?.attributes?.upElevation}
        >
          {section?.attributes.upElevation}
        </SummaryCardSection>
      )}
      {sectionAttributeApplicable(
        'attributes.downNorthing',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Down Northing"
          error={error?.['attributes.downNorthing']}
          onEdit={editSectionStep('attributes.downNorthing')}
          value={section?.attributes?.downNorthing}
        >
          {section?.attributes.downNorthing}
        </SummaryCardSection>
      )}
      {sectionAttributeApplicable(
        'attributes.downEasting',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Down Easting"
          error={error?.['attributes.downEasting']}
          onEdit={editSectionStep('attributes.downEasting')}
          value={section?.attributes?.downEasting}
        >
          {section?.attributes.downEasting}
        </SummaryCardSection>
      )}
      {sectionAttributeApplicable(
        'attributes.downEasting',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Down Elevation"
          error={error?.['attributes.downElevation']}
          onEdit={editSectionStep('attributes.downElevation')}
          value={section?.attributes?.downElevation}
        >
          {section?.attributes.downElevation}
        </SummaryCardSection>
      )}
      {sectionAttributeApplicable(
        'attributes.upRimToInvert',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Up Rim to Invert"
          error={error?.['attributes.upRimToInvert']}
          onEdit={editSectionStep('attributes.upRimToInvert')}
          value={section?.attributes?.upRimToInvert}
        >
          {section?.attributes.upRimToInvert}
        </SummaryCardSection>
      )}
      {sectionAttributeApplicable(
        'attributes.upRimToGrade',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Up Rim to Grade"
          error={error?.['attributes.upRimToGrade']}
          onEdit={editSectionStep('attributes.upRimToGrade')}
          value={section?.attributes?.upRimToGrade}
        >
          {section?.attributes.upRimToGrade}
        </SummaryCardSection>
      )}
      {sectionAttributeApplicable(
        'attributes.upGradeToInvert',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Up Grade to Invert"
          error={error?.['attributes.upGradeToInvert']}
          onEdit={editSectionStep('attributes.upGradeToInvert')}
          value={section?.attributes?.upGradeToInvert}
        >
          {section?.attributes.upGradeToInvert}
        </SummaryCardSection>
      )}
      {sectionAttributeApplicable(
        'attributes.downRimToInvert',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Down Rim to Invert"
          error={error?.['attributes.downRimToInvert']}
          onEdit={editSectionStep('attributes.downRimToInvert')}
          value={section?.attributes?.downRimToInvert}
        >
          {section?.attributes.downRimToInvert}
        </SummaryCardSection>
      )}
      {sectionAttributeApplicable(
        'attributes.downRimToGrade',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Down Rim to Grade"
          error={error?.['attributes.downRimToGrade']}
          onEdit={editSectionStep('attributes.downRimToGrade')}
          value={section?.attributes?.downRimToGrade}
        >
          {section?.attributes.downRimToGrade}
        </SummaryCardSection>
      )}
      {sectionAttributeApplicable(
        'attributes.downGradeToInvert',
        activeBooking?.projectType
      ) && (
        <SummaryCardSection
          title="Down Grade to Invert"
          error={error?.['attributes.downGradeToInvert']}
          onEdit={editSectionStep('attributes.downGradeToInvert')}
          value={section?.attributes?.downGradeToInvert}
        >
          {section?.attributes.downGradeToInvert}
        </SummaryCardSection>
      )}
    </SummaryCard>
  );
};

export default SectionAdditionalSummary;
