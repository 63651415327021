import { Buttons } from 'preshape';
import React from 'react';
import { useNavigate } from 'react-router';
import CustomerCreateButton from '../../../components/Customer/CustomerCreateButton';
import CustomerList from '../../../components/Customer/CustomerList';
import PageContext from '../../../components/Page/PageContext';
import Page from '../Layout/Page';
import PageBody from '../Layout/PageBody';
import PageHeader from '../Layout/PageHeader';

const CustomersPage = () => {
  const navigate = useNavigate();
  return (
    <PageContext backToLink="/settings/user" backToText="Back">
      <Page>
        <PageHeader
          title="Customers"
        />
        <PageBody>
          <CustomerList
            onSelect={(customer) =>
              navigate(`/admin/org/customers/${customer.uid}`)
            }
            withSearch
          />
        </PageBody>
      <Buttons padding="x6">
          <CustomerCreateButton onSave={(c) => navigate(c.uid)} variant='primary' grow/>
      </Buttons>
      </Page>
    </PageContext>
  );
};

export default CustomersPage;
