import { Text } from 'preshape';
import React, { PropsWithChildren } from 'react';
import Card from '../Card/Card';
import SearchResultGroup from '../Search/SearchResultGroup';

type Props = {
  title?: string;
};

const DataTableGroup = ({ children, title }: PropsWithChildren<Props>) => {
  return (
    <SearchResultGroup>
      <Text margin="x8" tag="li">
        {title && (
          <Text margin="x2" paddingHorizontal="x4" size="x3" strong>
            {title}
          </Text>
        )}

        <Card flex="vertical" gap="x2" padding="x1" tag="ul">
          {children}
        </Card>
      </Text>
    </SearchResultGroup>
  );
};

export default DataTableGroup;
