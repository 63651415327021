import { Alert, Box, Text } from 'preshape';
import React from 'react';
import MapSidebarContentItem from '../../../Map/MapSidebar/MapSidebarContentItem';
import MapSidebarContentItemSection from '../../../Map/MapSidebar/MapSidebarContentItemSection';
import DrawingList from '../../../Measurement/DrawingList';
import MeasurementsList from '../../../Measurement/MeasurementsList';
import { useProjectContext } from '../../../Project/ProjectProvider';
import XMLExport from '../../../XMLExport/XMLExport';
import { useReportEditorContext } from '../../ReportEditorProvider';
import { useReportMapContext } from '../ReportMapProvider';
import ReportMapMeasure from '../ReportMapTools/ReportMapMeasure';
import ReportMapMeasureScale from '../ReportMapTools/ReportMapMeasureScale';
import ReportMapPolygon from '../ReportMapTools/ReportMapPolygon';
import ReportMapSidebarBatchVideoUploadButton from './ReportMapSidebarBatchVideoUploadButton';
import ReportMapSidebarSectionsBulkUpdate from './ReportMapSidebarSectionsBulkUpdate';
import WincanXMLImport from './WincanXMLImport';

const ReportMapSidebarTools = () => {
  const { reportEditor } = useReportEditorContext();
  const { activeBooking } = useProjectContext();
  const { activeSidebar, focusMeasurement, focusDrawing } =
    useReportMapContext();
  const { report } = reportEditor;

  if (activeSidebar !== 'tools') {
    return null;
  }

  return (
    <MapSidebarContentItem active={activeSidebar === 'tools'} title={'Tools'}>
      <MapSidebarContentItemSection title="Report">
        <Box flex="horizontal" alignChildrenHorizontal="around">
          <ReportMapSidebarBatchVideoUploadButton />
          <ReportMapSidebarSectionsBulkUpdate />
        </Box>
      </MapSidebarContentItemSection>
      {!!!activeBooking?.completedAt && (
        <MapSidebarContentItemSection title="Plan tools">
          <Box flex="horizontal" alignChildrenHorizontal="around">
            <ReportMapMeasure />
            <ReportMapPolygon />
            {report?.plan?.isToScale === false && <ReportMapMeasureScale />}
          </Box>
        </MapSidebarContentItemSection>
      )}
      <MapSidebarContentItemSection title="Polygons">
        <DrawingList onItemClick={(id) => focusDrawing(id)} />
      </MapSidebarContentItemSection>
      <MapSidebarContentItemSection title="Measurements">
        <MeasurementsList onItemClick={(id) => focusMeasurement(id)} />
      </MapSidebarContentItemSection>
      <MapSidebarContentItemSection title="Import project">
        <Box>
          <WincanXMLImport />
          {reportEditor.report.nodes.length +
            reportEditor.report.sections.length !==
            0 && (
            <Box
              padding="x6"
              flex="horizontal"
              alignChildrenHorizontal="middle"
              backgroundColor="background-shade-2"
            >
              <Alert
                color={'accent'}
                padding="x2"
                backgroundColor="accent-shade-2"
              >
                <Text size="x1" strong>
                  You cannot upload an XML to projects with items.
                </Text>
                <Text size="x1" align="middle">
                  This will cause overwriting/duplication
                </Text>
                <Text size="x1" align="middle">
                  Please use a new project if unavoidable
                </Text>
              </Alert>
            </Box>
          )}
        </Box>
      </MapSidebarContentItemSection>
      {activeBooking?.completedAt && (
        <MapSidebarContentItemSection title="Reports">
          <XMLExport />
        </MapSidebarContentItemSection>
      )}
    </MapSidebarContentItem>
  );
};

export default ReportMapSidebarTools;
