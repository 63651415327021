import { ApiSchema, Endpoint } from '@drainify/api/src/apiSchema';
import { ResponseError } from '@drainify/types';
import { ApiError } from '@drainify/utils';
import { StatusCodes } from 'http-status-codes';
import { useContext } from 'react';
import { AuthenticationContext } from '../Authentication/Authenticate';
import fetch, { RequestOptions, Query } from './fetch';

export const hasAllParams = <T extends Partial<Record<string, string>>>(
  params: T
): params is Required<T> => {
  return Object.values(params).every(Boolean);
};

export default function useFetch() {
  const { user } = useContext(AuthenticationContext);
  async function callFetch<
    U extends Endpoint,
    M extends keyof ApiSchema[U]['methods'],
    R extends ApiSchema[U]['methods'][M] extends { res: any }
      ? Exclude<ApiSchema[U]['methods'][M]['res'], ResponseError>
      : never
  >(url: U, opts: RequestOptions<ApiSchema, U, M>, query?: Query): Promise<R> {
    const token = await user?.getIdToken();
    return fetchCommon(url, opts, query, token);
  }

  // https://github.com/tannerlinsley/react-query/issues/1829
  callFetch.toJSON = () => (user ? 1 : 2);

  return callFetch;
}

export function useFetchReport(token?: string) {
  async function callFetch<
    U extends Endpoint,
    M extends keyof ApiSchema[U]['methods'],
    R extends ApiSchema[U]['methods'][M] extends { res: any }
      ? Exclude<ApiSchema[U]['methods'][M]['res'], ResponseError>
      : never
  >(url: U, opts: RequestOptions<ApiSchema, U, M>, query?: Query): Promise<R> {
    return fetchCommon(url, opts, query, token);
  }

  // https://github.com/tannerlinsley/react-query/issues/1829
  callFetch.toJSON = () => (token ? 1 : 2);

  return callFetch;
}

async function fetchCommon<
  U extends Endpoint,
  M extends keyof ApiSchema[U]['methods'],
  R extends ApiSchema[U]['methods'][M] extends { res: any }
    ? Exclude<ApiSchema[U]['methods'][M]['res'], ResponseError>
    : never
>(
  url: U,
  opts: RequestOptions<ApiSchema, U, M>,
  query?: Query,
  token?: string
): Promise<R> {
  let response;

  try {
    response = await fetch(
      url,
      {
        ...opts,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
      query
    );
  } catch (e) {
    throw e;
  }

  try {
    if (response.status !== StatusCodes.NO_CONTENT) {
      const json = await response.json();

      if (response.ok) {
        return json as R;
      }

      const error = new ApiError(json as ResponseError);
      throw error;
    }

    return null as R;
  } catch (e) {
    throw e;
  }
}
