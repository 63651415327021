import { ObservationOptionAngleRange } from '@drainify/utils';
import { InputLabel } from 'preshape';
import React from 'react';
import ClockRefInput from '../../ClockRefInput/ClockRefInput';
import ClockRefSymbol from '../../ClockRefInput/ClockRefSymbol';

type Props = {
  asSummary?: boolean;
  height: number;
  name?: string;
  option: ObservationOptionAngleRange;
  onSelect: (value: [number, number]) => void;
  value?: [number, number];
  width: number;
  asIcon?: boolean;
};

const ObservationOptionAngleRangeInput = ({
  asSummary,
  asIcon,
  height,
  name,
  onSelect,
  value,
  width,
}: Props) => {
  if (asSummary) {
    return <ClockRefSymbol clockFrom={value?.[0]} clockTo={value?.[1]} asIcon={asIcon}/>;
  }

  return (
    <InputLabel grow>
      <ClockRefInput
        clockFrom={value?.[0]}
        clockTo={value?.[1]}
        name={name}
        onChange={onSelect}
        minHeight={300}
        size={Math.min(height, width)}
      />
    </InputLabel>
  );
};

export default ObservationOptionAngleRangeInput;
