import { SectionFlowCode } from '@drainify/types/dist';
import { Box, BoxProps, Icons } from 'preshape';
import React from 'react';
import SymbolTemplate from '../Symbol/SymbolTemplate';

type Props = BoxProps & {
  value?: SectionFlowCode;
  withText?: boolean;
};

const FlowSymbol = ({ value, withText, ...rest }: Props) => {
  return (
    <Box flex="horizontal" {...rest} alignChildrenVertical="middle">
      {withText && (
        <Box grow>
          {value === SectionFlowCode.STE && 'Upstream'}
          {value === SectionFlowCode.ETS && 'Downstream'}
        </Box>
      )}
      <SymbolTemplate>
        {(value === SectionFlowCode.STE && (
          <Icons.ArrowLeft size={24} textColor="accent-shade-3" />
        )) ||
          (value === SectionFlowCode.ETS && (
            <Icons.ArrowRight size={24} textColor="accent-shade-3" />
          )) || <Icons.Minus size={24} />}
      </SymbolTemplate>
    </Box>
  );
};

export default FlowSymbol;
