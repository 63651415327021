import {
  Box,
  Button,
  Placement,
  PlacementContent,
  PlacementManager,
  PlacementReference,
} from 'preshape';
import React from 'react';
import { useHasPermission } from '../Permissions/Permissions';
import { useReportEditorContext } from '../Report/ReportEditorProvider';

type Props = {
  sectionUid?: string;
};

const InspectionAdvancedActions = ({ sectionUid }: Props) => {
  const [visible, setVisible] = React.useState(false);
  const { createNewInspection } = useReportEditorContext();
  const hasUpdateProjectPermission = useHasPermission('projects:update');

  if (!hasUpdateProjectPermission || !sectionUid) {
    return null;
  }
  return (
    <PlacementManager>
      <PlacementReference>
        {(props) => (
          <Box {...props}>
            <Box flex="horizontal" alignChildrenHorizontal="end">
              <Button variant="tertiary" onClick={() => setVisible(!visible)}>
                ..
              </Button>
            </Box>
          </Box>
        )}
      </PlacementReference>

      <Placement
        visible={visible}
        animation="FadeSlideDown"
        width="reference"
        zIndex={1}
      >
        <PlacementContent backgroundColor="background-shade-2" borderSize="x2">
          <Box flex="horizontal" alignChildrenHorizontal="middle" padding="x4">
            <Button
              onClick={() => {
                setVisible(false);
                createNewInspection(sectionUid);
              }}
            >
              Add additional inspection
            </Button>
          </Box>
        </PlacementContent>
      </Placement>
    </PlacementManager>
  );
};

export default InspectionAdvancedActions;
