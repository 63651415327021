import React from 'react';
import PageContext from '../../../components/Page/PageContext';
import UserSummary from '../../../components/User/UserSummary';
import useMe from '../../../hooks/useMe';
import Page from '../Layout/Page';
import PageBody from '../Layout/PageBody';
import PageHeader from '../Layout/PageHeader';

const UserProfilePage = () => {
  const { query } = useMe();

  return (
    <PageContext backToText="Back" backToLink="/settings/user">
      <Page>
        <PageHeader title="Profile" />

        <PageBody>
          <UserSummary user={query.data} />
        </PageBody>
      </Page>
    </PageContext>
  );
};

export default UserProfilePage;
