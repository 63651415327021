import { Grid } from 'preshape';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';

const MapToolsContext = createContext<{
  enabledTool: string;
  isEnabled: (tool: string) => boolean;
  setEnabledTool: (tool: string) => void;
}>({
  enabledTool: '',
  isEnabled: () => false,
  setEnabledTool: () => {},
});

export const useMapContextools = () => useContext(MapToolsContext);

const MapTools = ({ children }: PropsWithChildren<{}>) => {
  const [enabledTool, setEnabledTool] = useState('');

  const isEnabled = (tool: string) => enabledTool === tool;

  return (
    <MapToolsContext.Provider
      value={{ isEnabled, setEnabledTool, enabledTool }}
    >
      <Grid gap="x2" repeat={4} repeatWidth="1fr">
        {children}
      </Grid>
    </MapToolsContext.Provider>
  );
};

export default MapTools;
