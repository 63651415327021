import { User } from '@drainify/types';
import { getFullFilePath } from '@drainify/utils';
import React from 'react';
import Avatar from '../Avatar/Avatar';
import SummaryCard from '../SummaryCard/SummaryCard';
import SummaryCardSection from '../SummaryCard/SummaryCardSection';
import { useWizardContext } from '../Wizard/Wizard';
import { useUserContext } from './UserProvider';

type Props = {
  user?: User;
};

const UserSummary = ({ user }: Props) => {
  const { setActiveStepId } = useWizardContext();
  const { edit } = useUserContext();

  const editStep = (stepId: string) => {
    return () => {
      setActiveStepId(stepId);
      edit(stepId);
    };
  };

  return (
    <SummaryCard title="Summary">
      <SummaryCardSection
        onEdit={editStep('avatar')}
        title="Profile picture"
        value={user?.avatarUrlOriginal}
      >
        <Avatar
          onClick={editStep('avatar')}
          size="8rem"
          src={getFullFilePath(user?.avatarUrlCropped)}
          uid={user?.uid}
        />
      </SummaryCardSection>

      <SummaryCardSection
        onEdit={editStep('fullName')}
        title="Full name"
        value={user?.fullName}
      >
        {user?.fullName}
      </SummaryCardSection>

      <SummaryCardSection
        onEdit={editStep('email')}
        title="Email"
        value={user?.email}
      >
        {user?.email}
      </SummaryCardSection>

      <SummaryCardSection
        onEdit={editStep('phoneNumber')}
        title="Phone number"
        value={user?.phoneNumber}
      >
        {user?.phoneNumber}
      </SummaryCardSection>
    </SummaryCard>
  );
};

export default UserSummary;
