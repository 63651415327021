import { Drawing, DrawingPostBody } from '@drainify/types';
import { ReportError } from '@drainify/utils';
import { FormValidateFn, useForm } from 'preshape';
import { useCallback, useMemo } from 'react';
import { useReportEditorContext } from '../../components/Report/ReportEditorProvider';

const useDrawingForm = (
  jobId?: string,
  drawing?: Drawing | DrawingPostBody
) => {
  const { reportEditor } = useReportEditorContext();

  const initial = useMemo<Drawing | DrawingPostBody>(
    () =>
      drawing || {
        jobId: jobId,
        points: [],
      },
    [drawing, jobId]
  );

  const validate = useCallback<
    FormValidateFn<Drawing | DrawingPostBody, ReportError>
  >(
    (drawing, setError) => {
      setError(reportEditor.validateDrawing(drawing));
    },
    [reportEditor]
  );

  const form = useForm({
    initial,
    validate,
  });

  return form;
};

export default useDrawingForm;
