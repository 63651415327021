import { Box, Text } from 'preshape';
import React from 'react';
import BuddyJobManagerButton from '../../../components/BuddyReviewTool/BuddyJobManagerButton';
import Card from '../../../components/Card/Card';
import DataTableGroup from '../../../components/DataTable/DataTableGroup';
import DataTableItem from '../../../components/DataTable/DataTableItem';
import DataTableMessage from '../../../components/DataTable/DataTableMessage';
import InspectionVideo from '../../../components/Inspection/InspectionVideo';
import { useProjectContext } from '../../../components/Project/ProjectProvider';
import { useReportEditorContext } from '../../../components/Report/ReportEditorProvider';
import VideoAddReplaceButton from '../../../components/Video/VideoAddReplaceButton';
import SecretAIOCRPOI from '../../SecretAIOCRPOI';
import Page from '../Layout/Page';
import PageBody from '../Layout/PageBody';
import PageHeader from '../Layout/PageHeader';

const VideoManagementPage = () => {
  const { reportEditor } = useReportEditorContext();
  const { project } = useProjectContext()

  return (
    <Page>
      <PageHeader title="Video management & analysis" />
      <PageBody flex="vertical" gap="x3" overflow="auto" basis="0" grow>
        <BuddyJobManagerButton/>
        <Box flex='vertical'>
          {
            reportEditor.report.inspections.length === 0 && 
        <DataTableGroup>
          <DataTableItem>
            <DataTableMessage>
              Add sections and inspections to apply videos
            </DataTableMessage>
          </DataTableItem>
            </DataTableGroup>
          }
            <Box flex='horizontal' gap='x3' wrap alignChildrenHorizontal='around'>
          {
            reportEditor.report.inspections.map(e => 
            <Card key={e.uid} width='300px'>
              <Text size='x3' strong> {reportEditor.getSectionName(reportEditor.getSectionByUid(e.sectionUid))}/{reportEditor.getInspectionName(e)} </Text>

                <InspectionVideo
                  inspection={e}
                />
                <Box flex='horizontal' alignChildrenHorizontal='around'>
                  <SecretAIOCRPOI projectId={project?.uid} inspectionId={e.uid} />
                  <VideoAddReplaceButton inspection={e} />
                </Box>

              </Card>)
          }
        </Box>
        </Box>
      </PageBody>
    </Page>
  );
};

export default VideoManagementPage;
