import {
  Button,
  Buttons,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'preshape';
import React from 'react';
import InspectionAdditionalSummary from '../../../components/Inspection/InspectionAdditionalSummary';
import { useInspectionContext } from '../../../components/Inspection/InspectionProvider';

type Props = {
  visible: boolean;
  onClose: () => void;
};

const InspectionDetailsModal = ({ visible, onClose }: Props) => {
  const { inspection } = useInspectionContext();

  return (
    <Modal visible={visible} onClose={onClose}>
      <ModalHeader>Inspection details</ModalHeader>
      <ModalBody>
        <InspectionAdditionalSummary inspection={inspection} />
      </ModalBody>
      <ModalFooter>
        <Buttons>
          <Button
            variant="primary"
            color="accent"
            onClick={() => onClose()}
            grow
          >
            Close
          </Button>
        </Buttons>
      </ModalFooter>
    </Modal>
  );
};

export default InspectionDetailsModal;
