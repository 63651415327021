import React, { PropsWithChildren, useEffect } from 'react';
import { SearchKeyword, useSearchResultContext } from './SearchResultGroup';

type Props = {
  keywords?: SearchKeyword;
};

const SearchResult = ({ children, keywords }: PropsWithChildren<Props>) => {
  const { getMatchesKeywords, setSearchKeywords } = useSearchResultContext();

  useEffect(() => {
    if (keywords) {
      return setSearchKeywords(keywords);
    }
  }, [keywords]);

  if (!keywords || getMatchesKeywords(keywords)) {
    return <>{children}</>;
  }

  return null;
};

export default SearchResult;
