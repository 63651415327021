import { Box, Icons, Label } from 'preshape';
import React, { useContext } from 'react';
import { ServiceWorkerContext } from '../Bootstrap/ServiceWorkerProvider';
import Spinner from '../Spinner/Spinner';

const SyncLabel = () => {
  const { updating, pendingChanges } = useContext(ServiceWorkerContext);

  return (
    <Box flex="horizontal" alignChildrenHorizontal="middle">
      {pendingChanges && !updating && (
        <Label
          borderSize="x1"
          borderColor="accent-shade-5"
          borderRadius="15px"
          backgroundColor="background-shade-1"
          textColor="accent-shade-5"
          uppercase
        ><Icons.WifiOff/></Label>
      )}
      {updating && (
        <Label
          borderSize="x1"
          borderColor="accent-shade-5"
          borderRadius="15px"
          backgroundColor="background-shade-1"
          textColor="accent-shade-5"
          uppercase
        >
          <Spinner size="1rem" />
        </Label>
      )}
    </Box>
  );
};

export default SyncLabel;
