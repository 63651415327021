import { getCornersFromBbox } from '@drainify/utils';
import getBbox from '@turf/bbox';
import isEqual from 'lodash.isequal';
import { themes } from 'preshape';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { getWhat3WordsGrid } from '../../../utils/what3words';
import { RootContext } from '../../Bootstrap/Bootstrap';
import { useReportContext } from '../../Report/ReportProvider';
import { useMapContext } from '../Map';

const MIN_ZOOM_LEVEL = 18;

const MapWhat3WordsGrid = () => {
  const { theme } = useContext(RootContext);
  const { bounds } = useReportContext();
  const { addBoundsChangeListener, getZoom, drawData } = useMapContext();
  const [what3wordsGeometry, setWhat3WordsGeometry] =
    useState<GeoJSON.FeatureCollection>();
  const refPreviousCorners = useRef<ReturnType<typeof getCornersFromBbox>>();

  const onBoundsChange = useCallback(async () => {
    if (bounds) {
      const corners = getCornersFromBbox(getBbox(bounds));
      const zoom = getZoom();

      if (zoom === null || zoom < MIN_ZOOM_LEVEL) {
        return setWhat3WordsGeometry(undefined);
      }

      if (isEqual(refPreviousCorners.current, corners)) {
        return;
      }

      const geometry = await getWhat3WordsGrid(corners.ne, corners.sw);

      if (geometry) {
        refPreviousCorners.current = corners;
        setWhat3WordsGeometry(geometry);
      }
    }
  }, [getZoom, bounds]);

  useEffect(() => {
    onBoundsChange();

    return addBoundsChangeListener(() => onBoundsChange());
  }, [addBoundsChangeListener, onBoundsChange]);

  useEffect(() => {
    if (what3wordsGeometry) {
      return drawData(what3wordsGeometry, {
        strokeColor: themes[theme].colorTextShade4,
        strokeWeight: 0.5,
        strokeOpacity: 0.5,
      });
    }
  }, [drawData, what3wordsGeometry, theme]);

  return null;
};

export default MapWhat3WordsGrid;
