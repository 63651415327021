export enum ProductType {
  BUDDY_TOKEN = "BUDDY_TOKEN",
}

export interface AdditionalProduct {
  stripeId: string;
  displayName: string;
  price: number;
  type: ProductType;
}

export interface SubscriptionTierDetails {
  tier: string;
  stripeId: string;
  price: number;
  currency: string;
  displayName: string;

  creditPrice: number;
  credits: number;
  creditPriceId: string;

  recommendation: string;
  storage: number;
  additionalProducts: AdditionalProduct[];
}
