import { ModalBody, ModalHeader } from 'preshape';
import React from 'react';
import { useUserContext } from '../../../components/User/UserProvider';
import UserSummary from '../../../components/User/UserSummary';

const UserProfilePage = () => {
  const { user } = useUserContext();

  return (
    <>
      <ModalHeader>Profile</ModalHeader>
      <ModalBody>
        <UserSummary user={user} />
      </ModalBody>
    </>
  );
};

export default UserProfilePage;
