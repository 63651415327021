import { Text, Label } from 'preshape';
import React from 'react';

type Props = {
  icon: JSX.Element;
  value: string | number;
};

const UsageDetailsPlanTotal = ({ icon, value }: Props) => {
  return (
    <Label
      flex="horizontal"
      alignChildrenVertical="middle"
      alignChildrenHorizontal="middle"
      gap="x1"
      backgroundColor="background-shade-3"
      textColor="text-shade-4"
    >
      <Text textColor="accent-shade-3">{icon}</Text>
      <Text>{value}</Text>
    </Label>
  );
};

export default UsageDetailsPlanTotal;
