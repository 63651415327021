import { Buttons } from 'preshape';
import React from 'react';
import MapSidebarContentItem from '../../../Map/MapSidebar/MapSidebarContentItem';
import MapSidebarContentItemSection from '../../../Map/MapSidebar/MapSidebarContentItemSection';
import ObservationDeleteButton from '../../../Observations/ObservationDeleteButton';
import ObservationSummary from '../../../Observations/ObservationSummary';
import { useReportEditorContext } from '../../ReportEditorProvider';
import ReportErrorMessage from '../../ReportErrorMessage';
import { useReportMapContext } from '../ReportMapProvider';

const ReportMapSidebarObservation = () => {
  const { reportEditor } = useReportEditorContext();
  const { activeSidebar, focusedObservationUid, focusInspection } =
    useReportMapContext();

  const observation = reportEditor.getObservationByUid(focusedObservationUid);

  const inspection = reportEditor.getObservationInspection(
    focusedObservationUid
  );
  const error = reportEditor.getObservationError(focusedObservationUid);

  const handleBack =
    inspection &&
    (() => {
      focusInspection(inspection.uid);
    });

  return (
    <MapSidebarContentItem
      active={activeSidebar === 'info' && !!focusedObservationUid}
      onBack={handleBack}
      title={reportEditor.getObservationName(observation) || ''}
    >
      {error && (
        <MapSidebarContentItemSection>
          {<ReportErrorMessage error={error} />}
        </MapSidebarContentItemSection>
      )}

      <MapSidebarContentItemSection>
        <ObservationSummary observation={observation} />
      </MapSidebarContentItemSection>

      <MapSidebarContentItemSection>
        <Buttons>
          <ObservationDeleteButton
            grow
            observationUid={observation?.uid}
            variant="tertiary"
            onDelete={handleBack}
          />
        </Buttons>
      </MapSidebarContentItemSection>
    </MapSidebarContentItem>
  );
};

export default ReportMapSidebarObservation;
