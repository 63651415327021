import { Attributes, Icons, Input, InputProps } from 'preshape';
import React from 'react';
import { isDesktop } from '../../utils/client';
import { useSearchContext } from './SearchProvider';

type Props = InputProps;

const SearchInput = (props: Attributes<HTMLInputElement, Props>) => {
  const { searchString, setSearchString } = useSearchContext();

  return (
    <Input
      addonStart={<Icons.Search />}
      backgroundColor="background-shade-2"
      borderSize="x0"
      placeholder="Search..."
      autoFocus={isDesktop()}
      {...props}
      onChange={(event: any) => setSearchString(event.target.value)}
      value={searchString}
    />
  );
};

export default SearchInput;
