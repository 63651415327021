import { Appear, Button, Text } from 'preshape';
import React, { PropsWithChildren, useContext, useEffect } from 'react';
import { WizardStepOptionsContext } from './WizardStepOptions';

type Props = {
  contentActive?: JSX.Element;
  contentInactive?: JSX.Element;
  selected?: boolean;
  onSelect?: () => void;
  titleActive: string;
  titleInactive: string;
};

const WizardStepOption = ({
  contentActive,
  contentInactive,
  onSelect,
  selected,
  titleActive,
  titleInactive,
}: PropsWithChildren<Props>) => {
  const { activeStep, resetOptions, setOptionActive } = useContext(
    WizardStepOptionsContext
  );
  const id = titleActive;

  const isActive = activeStep === id;

  const handleClick = () => {
    onSelect?.();
    resetOptions();
    setOptionActive(id, true);
  };

  useEffect(() => {
    setOptionActive(id, !!selected);
  }, [selected, id]);

  return (
    <>
      <Button
        active={isActive}
        flex="vertical"
        onClick={handleClick}
        paddingVertical="x4"
        size="x3"
        uppercase={false}
        weak
        tag="div"
      >
        <Appear animation="Expand" visible={!isActive} width="100%">
          <Text strong>{titleInactive}</Text>
          {contentInactive && (
            <Text align="start" margin="x2" textColor="text-shade-1">
              {contentInactive}
            </Text>
          )}
        </Appear>

        <Appear animation="Expand" visible={isActive} width="100%">
          <Text strong>{titleActive}</Text>
          {contentActive && (
            <Text align="start" margin="x2" textColor="text-shade-1">
              {contentActive}
            </Text>
          )}
        </Appear>
      </Button>
    </>
  );
};

export default WizardStepOption;
