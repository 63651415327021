import { Attributes, Box, BoxProps, Text, Tooltip } from 'preshape';
import React, { ForwardedRef, forwardRef, PropsWithChildren } from 'react';
import './MapDroppable.css';

type Props = Attributes<HTMLDivElement, PropsWithChildren<BoxProps>> & {
  name: string;
  title: string;
};

const MapDroppable = (
  { children, name, title, ...rest }: Props,
  ref: ForwardedRef<HTMLDivElement>
) => {
  return (
    <Tooltip content={title} placement="top">
      {(props) => (
        <Box {...rest} {...props} className="MapDroppable">
          <Box alignChildren="middle" flex="vertical">
            <Box
              backgroundColor="accent-shade-1"
              borderSize="x2"
              borderRadius="x3"
              borderStyle="dashed"
            >
              <Box padding="x4" ref={ref}>
                {children}
              </Box>
            </Box>

            <Text align="middle" margin="x1" size="x1" strong>
              {name}
            </Text>
          </Box>
        </Box>
      )}
    </Tooltip>
  );
};

export default forwardRef(MapDroppable);
