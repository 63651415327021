import { Text, TextProps } from 'preshape';
import React, { ForwardRefRenderFunction } from 'react';

type Props = TextProps & {
  value?: string;
};

const Remarks: ForwardRefRenderFunction<HTMLLabelElement, Props> = ({
  value,
  ...rest
}) => {
  return <Text {...rest}>{value}</Text>;
};

export default Remarks;
