import {
  JobDocument,
  ResponseError,
  ResponseMultipleEntities,
} from '@drainify/types';
import { useQuery } from 'react-query';
import useFetch from '../components/Api/useFetch';

const useJobDocuments = (bookingId?: string) => {
  const fetch = useFetch();
  const params = {
    bookingId: bookingId as string,
  };

  const key = [`/bookings/${bookingId}/documents`, fetch];

  const list = useQuery<ResponseMultipleEntities<JobDocument>, ResponseError>(
    key,
    () => fetch('/bookings/:bookingId/documents', { method: 'GET', params })
  );

  return { list };
};

export default useJobDocuments;
