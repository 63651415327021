import { BoxProps } from 'preshape';
import React from 'react';
import Distance from '../DistanceInput/Distance';
import QuickOption from '../QuickOptions/QuickOption';
import QuickOptions from '../QuickOptions/QuickOptions';

const diameterQuickOptions = [0.1, 0.15, 0.225, 0.3, 0.35, 0.45, 0.75, 0.9];

type Props = BoxProps & {
  onChange: (diameter: number) => void;
  value?: number;
};

const SectionDiameterQuickOptions = ({ onChange, value, ...rest }: Props) => {
  return (
    <QuickOptions {...rest}>
      {diameterQuickOptions.map((distance, index) => (
        <QuickOption
          active={value === diameterQuickOptions[index]}
          key={index}
          onClick={() => onChange(diameterQuickOptions[index])}
          size="x2"
          uppercase={false}
        >
          <Distance type="short" value={distance} />
        </QuickOption>
      ))}
    </QuickOptions>
  );
};

export default SectionDiameterQuickOptions;
