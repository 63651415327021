import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HiddenContent from '../../../components/HiddenContent/HiddenContent';
import NodeAdditionalSummary from '../../../components/Node/NodeAdditionalSummary';
import NodeDeleteButton from '../../../components/Node/NodeDeleteButton';
import NodeSummary from '../../../components/Node/NodeSummary';
import { useProjectContext } from '../../../components/Project/ProjectProvider';
import { useReportEditorContext } from '../../../components/Report/ReportEditorProvider';
import Page from '../Layout/Page';
import PageBody from '../Layout/PageBody';
import PageHeader from '../Layout/PageHeader';
import PageMenu from '../Layout/PageMenu';

const NodePage = () => {
  const { nodeId } = useParams();

  const { reportEditor } = useReportEditorContext();
  const { activeBookingId } = useProjectContext()
  const node = reportEditor.getNodeByUid(nodeId);
  const navigate = useNavigate()

  if (!node) {
    return null;
  }

  return (
    <Page>
      <PageHeader title={reportEditor.getNodeName(node)} />
      <PageBody>
        <PageMenu margin="x3">
          <NodeSummary node={node} />
          <HiddenContent content="Additional node info">
            <NodeAdditionalSummary node={node} />
          </HiddenContent>
        </PageMenu>
      </PageBody>
      <NodeDeleteButton nodeUid={node.uid} onDelete={() => navigate(`../?activeBookingId=${activeBookingId}`)}/>
    </Page>
  );
};

export default NodePage;
