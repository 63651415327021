import { ApiSchema, Endpoint } from '@drainify/api/src/apiSchema';
import { generatePath } from 'react-router-dom';

export type Method = 'GET' | 'PATCH' | 'POST' | 'PUT' | 'DELETE';

export type Query = Record<string, string>;

export type RequestSpec<Req = {}, Res = {}> = {
  params?: Record<string, string>;
  methods: Partial<
    Record<
      Method,
      {
        req?: Req;
        res?: Res;
      }
    >
  >;
};

export type ApiSpec = Record<string, RequestSpec<any, any>>;

export type RequestOptions<
  A extends ApiSpec,
  U extends keyof A,
  M extends keyof A[U]['methods']
> = Omit<RequestInit, 'method' | 'body'> & { method: M } & (A[U] extends {
    params: Record<string, string>;
  }
    ? { params: A[U]['params'] }
    : {}) &
  (A[U]['methods'][M] extends { req: Record<string, any> }
    ? { body: A[U]['methods'][M]['req'] }
    : {});

export default function fetch<
  U extends Endpoint,
  M extends keyof ApiSchema[U]['methods']
>(url: U, opts: RequestOptions<ApiSchema, U, M>, query?: Query) {
  if (!process.env.DRAINIFY_API_URL) {
    throw new Error('DRAINIFY_API_URL is not set.');
  }

  const { params, body } = opts as Omit<RequestInit, 'method' | 'body'> & {
    params?: Record<string, string>;
    body?: Record<string, any>;
  };

  const finalUrl = `${process.env.DRAINIFY_API_URL}${generatePath(
    url,
    params
  )}?${new URLSearchParams(query)}`;

  return window.fetch(finalUrl, {
    ...(opts as any),
    body: typeof body === 'undefined' ? body : JSON.stringify(body),
  });
}
