import { Box, Text } from 'preshape';
import React from 'react';
import SymbolTemplate from '../Symbol/SymbolTemplate';

type Props = {
  value?: number;
  asIcon?: boolean;
};

const PercentageSymbol = ({ value = 0, asIcon }: Props) => {
  return (
    <Box alignChildrenVertical="start" flex="horizontal" gap="x2">
      <Box grow>
        {
          !asIcon && <Text>{value}%</Text>
        }
      </Box>

      <SymbolTemplate borderRadius="50%">
        <Box
          borderRadius="50%"
          flex="vertical"
          grow
          overflow="hidden"
          reverse
          width="100%"
        >
          {Array.from({ length: 10 }).map((_, index) => (
            <Box
              alignChildren="middle"
              backgroundColor={
                value >= (index + 1) * 10 ? 'accent-shade-4' : 'accent-shade-2'
              }
              basis="0"
              borderBottom
              borderColor="background-shade-1"
              grow
              key={index}
              transitionProperty="background-color"
            />
          ))}
        </Box>
      </SymbolTemplate>
    </Box>
  );
};

export default PercentageSymbol;
