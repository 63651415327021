import { Inspection, VideoTranscodeStatus } from '@drainify/types';
import { getFullFilePath, getFullVideoFilePath } from '@drainify/utils';
import {
  Box,
  Button,
  Icons,
  Link,
  Modal,
  ModalBody,
  ModalHeader,
  Text,
} from 'preshape';
import React from 'react';
import VideoPage from '../../pages/desktop/Pages/VideoPage';
import { isMobile } from '../../utils/client';
import DataTableMessage from '../DataTable/DataTableMessage';
import ImageFromUrl from '../Image/ImageFromUrl';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import SummaryCard from '../SummaryCard/SummaryCard';
import SummaryCardSection from '../SummaryCard/SummaryCardSection';
import VideoPlayerSidebar from '../VideoPlayer/Sidebar/VideoPlayerSidebar';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import VideoProvider from '../VideoPlayer/VideoProvider';
import InspectionVideoThumbnailPending from './InspectionVideoThumbnailPending';

type Props = {
  inspection?: Inspection;
  onOpen?: () => void;
  onClose?: () => void;
};

const InspectionVideo = ({ inspection, onOpen, onClose }: Props) => {
  const { isCustomerView, project } = useProjectContext();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [engineerModalOpen, setEngineerModalOpen] = React.useState(false);
  const { reportEditor } = useReportEditorContext();

  const section = reportEditor.getSectionByUid(inspection?.sectionUid);
  if (!inspection) {
    return null;
  }

  return (
    <>
      <SummaryCard>
        <SummaryCardSection title={''}>
          {!inspection?.video && (
            <Box>
              <DataTableMessage>
                There is no uploaded video for this inspection
              </DataTableMessage>
            </Box>
          )}

          {inspection.video?.status === VideoTranscodeStatus.SUCCEEDED && (
            <Button
              padding="x0"
              size="x1"
              onClick={() => {
                isCustomerView
                  ? setModalOpen(true)
                  : setEngineerModalOpen(true);
                onOpen?.();
              }}
              flex="vertical"
              alignChildrenHorizontal="middle"
              width="100%"
            >
              {inspection.video.thumbnailUrl ? (
                <>
                  <ImageFromUrl
                    src={getFullFilePath(inspection.video.thumbnailUrl)}
                    height={'150px'}
                  />
                  <Box style={{ position: 'absolute' }} textColor="white">
                    <Icons.PlayCircle size="6rem" opacity="0.6" />
                  </Box>
                </>
              ) : (
                <>
                  <Box>
                    <Icons.PlayCircle size="6rem" />
                    <Text>Open video survey</Text>
                  </Box>
                </>
              )}
            </Button>
          )}

          {inspection.video?.status === VideoTranscodeStatus.FAILED && (
            <Box
              flex="vertical"
              alignChildrenHorizontal="middle"
              textColor="negative-shade-5"
            >
              <Icons.XCircle size="6rem" />
              <Text>Video failed during upload</Text>
            </Box>
          )}

          {inspection.video?.status === VideoTranscodeStatus.PENDING && (
            <InspectionVideoThumbnailPending inspection={inspection} />
          )}

          {inspection.video?.status === VideoTranscodeStatus.NOT_TRANSCODED && (
            <Box flex="horizontal" gap="x2" textColor="accent-shade-5">
              <Icons.Download />
              <Link
                href={getFullVideoFilePath(inspection.video.videoUrl)}
                target="_blank"
                rel="noreferrer"
                grow
                basis="0"
              >
                Download
              </Link>
            </Box>
          )}
        </SummaryCardSection>
      </SummaryCard>
      <Modal
        visible={modalOpen}
        onClose={() => {
          setModalOpen(false);
          onClose?.();
        }}
      >
        <ModalHeader>
          {reportEditor.getSectionName(section)} /
          {reportEditor.getInspectionName(inspection)}
        </ModalHeader>
        <VideoProvider>
          <Box flex="vertical" grow>
            <Box
              alignChildrenVertical="middle"
              paddingLeft="x3"
              gap="x3"
              flex="horizontal"
              elevate
            >
              <Box flex="horizontal" gap="x2" padding="x3" grow>
                <VideoPlayer
                  projectId={project!.uid}
                  inspectionId={inspection.uid}
                />
              </Box>
              <Box
                flex="vertical"
                width="340px"
                borderLeft
                backgroundColor="background-shade-2"
                borderColor="background-shade-3"
              >
                <VideoPlayerSidebar
                  projectId={project!.uid}
                  inspectionId={inspection.uid}
                />
              </Box>
            </Box>
          </Box>
        </VideoProvider>
      </Modal>
      <Modal
        visible={engineerModalOpen && !isMobile()}
        onClose={() => {
          setEngineerModalOpen(false);
          onClose?.();
        }}
      >
        <ModalHeader>
          {`${reportEditor.getSectionName(
            section
          )} / ${reportEditor.getInspectionName(inspection)}`}
        </ModalHeader>
        <ModalBody>
          <VideoPage
            inspection={inspection}
            onClose={() => {
              setEngineerModalOpen(false);
              onClose?.();
            }}
          />
        </ModalBody>
      </Modal>
      <Modal
        visible={engineerModalOpen && isMobile()}
        onClose={() => {
          setEngineerModalOpen(false);
          onClose?.();
        }}
      >
        <ModalHeader>
          {`${reportEditor.getSectionName(
            section
          )} / ${reportEditor.getInspectionName(inspection)}`}
        </ModalHeader>
        <ModalBody width={'90vw'}>
          <VideoPage 
          
            inspection={inspection}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default InspectionVideo;
