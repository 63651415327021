import { isNode } from '@drainify/utils';
import { Buttons } from 'preshape';
import React from 'react';
import HiddenContent from '../../../HiddenContent/HiddenContent';
import MapSidebarContentItem from '../../../Map/MapSidebar/MapSidebarContentItem';
import MapSidebarContentItemSection from '../../../Map/MapSidebar/MapSidebarContentItemSection';
import NodeAdditionalSummary from '../../../Node/NodeAdditionalSummary';
import NodeDeleteButton from '../../../Node/NodeDeleteButton';
import NodeSummary from '../../../Node/NodeSummary';
import { useProjectContext } from '../../../Project/ProjectProvider';
import { useReportEditorContext } from '../../ReportEditorProvider';
import { ReportErrorMessageInteractive } from '../../ReportErrorMessage';
import { useReportMapContext } from '../ReportMapProvider';

const ReportMapSidebarNode = () => {
  const { reportEditor } = useReportEditorContext();
  const { activeSidebar, focusedNodeUid, unfocus, setActiveSidebar } =
    useReportMapContext();
  const { editNode } = useReportEditorContext();
  const { activeBooking } = useProjectContext();

  const node = reportEditor.getNodeByUid(focusedNodeUid);
  const errors = reportEditor.getFullNodeValidationResult(node?.uid);

  const handleBack = () => {
    unfocus();
    setActiveSidebar('nodes');
  };

  return (
    <MapSidebarContentItem
      active={activeSidebar === 'info' && !!focusedNodeUid}
      onBack={handleBack}
      title={reportEditor.getNodeName(node)}
    >
      {errors && !!activeBooking === false && isNode(node) && (
        <MapSidebarContentItemSection>
          <ReportErrorMessageInteractive
            errors={errors}
            onEdit={(step) => editNode(node?.uid, step)}
          />
        </MapSidebarContentItemSection>
      )}

      <MapSidebarContentItemSection>
        <NodeSummary node={node} />
      </MapSidebarContentItemSection>

      <MapSidebarContentItemSection>
        <HiddenContent content="Further Node Details">
          <NodeAdditionalSummary node={node} />
        </HiddenContent>
      </MapSidebarContentItemSection>

      <MapSidebarContentItemSection>
        <Buttons>
          <NodeDeleteButton grow nodeUid={node?.uid} variant="tertiary" />
        </Buttons>
      </MapSidebarContentItemSection>
    </MapSidebarContentItem>
  );
};

export default ReportMapSidebarNode;
