import { ResponseError, TranscodeUsageView } from '@drainify/types';
import { useQuery } from 'react-query';
import useFetch, { hasAllParams } from '../components/Api/useFetch';

type Props = {
  orgId?: string;
};

const useOrgTranscodeUsage = ({ orgId }: Props) => {
  const fetch = useFetch();
  const key = [`/orgs/${orgId}/transcode-usage`, fetch];
  const params = { orgId: orgId as string };

  const query = useQuery<TranscodeUsageView, ResponseError>(
    key,
    () =>
      fetch(`/orgs/:orgId/transcode-usage`, { method: 'GET', params: params }),
    {
      enabled: hasAllParams(params),
    }
  );

  return { query };
};

export default useOrgTranscodeUsage;
