import { AccountState, Usage } from '@drainify/types';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { DateTime } from 'luxon';
import {
  Modal,
  ModalBody,
  ModalFooter,
  Box,
  Buttons,
  Text,
  Icons,
  ModalHeader,
  BulletPoints,
  BulletPoint,
} from 'preshape';
import React, { createContext, PropsWithChildren, useContext } from 'react';
import useOrgUsage from '../../hooks/useOrgUsage';
import { FULL_SCREEN_MODAL_WIDTH } from '../App/App';
import Logo from '../Logo/Logo';
import { useOrgContext } from '../Org/OrgProvider';
import UserAddCredits from './UsageAddCredits';
import UsageAddStorage from './UsageAddStorage';
import UsageChangePlan from './UsageChangePlan';

type Context = {
  usage?: Usage;
  refetch?: () => void;
  noCreditUpsell: () => void;
  noStorageUpsell: () => void;
};

const UsageContext = createContext<Context>({
  usage: undefined,
  refetch: undefined,
  noCreditUpsell: () => {},
  noStorageUpsell: () => {},
});

export const useUsageContext = () => useContext(UsageContext);

const UsageProvider = ({ children }: PropsWithChildren<{}>) => {
  const { org } = useOrgContext();
  const { query } = useOrgUsage({ orgId: org?.uid });
  const [showOverLimit, setShowOverLimit] = React.useState(false);
  const stripePromise = React.useState(() =>
    loadStripe(process.env.STRIPE_PUBLISHABLE_KEY!)
  )[0];

  const [noCreditsWarning, setNoCreditsWarning] = React.useState(false);

  const context: Context = {
    usage: query.data,
    refetch: () => query.refetch(),
    noCreditUpsell: () => setNoCreditsWarning(true),
    noStorageUpsell: () => setShowOverLimit(true),
  };

  React.useEffect(() => {
    if (query.data) {
      setShowOverLimit(
        query.data?.usedStorage >
          query.data?.totalStorage + query.data.additionalStorage
      );
    }
  }, [query.data]);

  return (
    <UsageContext.Provider value={context}>
      {noCreditsWarning &&
        query.data?.state !== AccountState.OVERDUE &&
        (query.data?.additionalCredits || 0) +
          (query.data?.planCredits || 0) ===
          0 && (
          <Modal visible={true} onClose={() => setNoCreditsWarning(false)}>
            <ModalHeader>Alert: Action required</ModalHeader>
            <ModalBody>
              <Box flex="vertical" gap="x3">
                <Text
                  textColor="negative-shade-5"
                  flex="horizontal"
                  gap="x2"
                  alignChildrenVertical="middle"
                  size="x4"
                  strong
                >
                  <Icons.AlertCircle size="2rem" />
                  You do not have any survey credits remaining
                </Text>

                <Text>To complete the job please do one of the following:</Text>
                <BulletPoints>
                  <BulletPoint>Upgrade plan</BulletPoint>
                  <BulletPoint>Purchase additional credits</BulletPoint>
                </BulletPoints>
                <Elements stripe={stripePromise}>
                  <Box
                    flex="horizontal"
                    gap="x3"
                    alignChildrenHorizontal="middle"
                  >
                    <UsageChangePlan />
                    <UserAddCredits />
                  </Box>
                </Elements>
              </Box>
            </ModalBody>
          </Modal>
        )}

      {noCreditsWarning && query.data?.state === AccountState.TRIAL && (
        <Modal
          visible
          onClose={() => setNoCreditsWarning(false)}
          maxWidth={FULL_SCREEN_MODAL_WIDTH}
        >
          <ModalHeader>Thank you for trying Drainify</ModalHeader>
          <ModalBody>
            <Box flex="vertical" gap="x2">
              <Text strong>
                We can't thank you enough for taking the time to play and
                explore Drainify.
              </Text>
              <Box flex="horizontal" alignChildrenHorizontal="middle">
                <Text textColor="accent-shade-5">
                  <Logo height={60} />
                </Text>
              </Box>
              <Text>Please subscribe to Drainify to unlock this feature!</Text>
              <Text>
                You're scheduled to start your <strong>ACCESS</strong> plan{' '}
                {query.data?.resetDate &&
                  DateTime.fromSeconds(query.data?.resetDate).toRelative({
                    unit: 'days',
                  })}
              </Text>
              <Text>
                Can't wait? Click the button below and take your reports to the
                next level <strong>now</strong>
              </Text>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Buttons flex="horizontal" alignChildrenHorizontal="middle">
              <Elements stripe={stripePromise}>
                <UsageChangePlan />
              </Elements>
            </Buttons>
          </ModalFooter>
        </Modal>
      )}

      {showOverLimit && query.data?.state === AccountState.TRIAL && (
        <Modal
          visible
          onClose={() => setShowOverLimit(false)}
          maxWidth={FULL_SCREEN_MODAL_WIDTH}
        >
          <ModalHeader>Thank you for trying Drainify</ModalHeader>
          <ModalBody>
            <Box flex="vertical" gap="x2">
              <Text strong>Trial data limit exceeded</Text>
              <Box flex="horizontal" alignChildrenHorizontal="middle">
                <Text textColor="accent-shade-5">
                  <Logo height={60} />
                </Text>
              </Box>
              <Text>
                Out packages include storage plans from 100MB to Unlimited data.
                Choose what works for you.
              </Text>
              <Text>
                You're scheduled to start your <strong>ACCESS</strong> plan{' '}
                {query.data?.resetDate &&
                  DateTime.fromSeconds(query.data?.resetDate).toRelative({
                    unit: 'days',
                  })}
              </Text>
              <Text>
                Can't wait? Click the button below and take your reports to the
                next level <strong>now</strong>
              </Text>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Buttons flex="horizontal" alignChildrenHorizontal="middle">
              <Elements stripe={stripePromise}>
                <UsageChangePlan />
              </Elements>
            </Buttons>
          </ModalFooter>
        </Modal>
      )}

      {showOverLimit &&
        query.data?.state !== AccountState.TRIAL &&
        query.data?.state !== AccountState.OVERDUE && (
          <Modal visible={true} onClose={() => setShowOverLimit(false)}>
            <ModalHeader>Alert: Action required</ModalHeader>
            <ModalBody>
              <Box flex="vertical" gap="x3">
                <Text
                  textColor="negative-shade-5"
                  flex="horizontal"
                  gap="x2"
                  alignChildrenVertical="middle"
                  size="x4"
                  strong
                >
                  <Icons.AlertCircle size="2rem" />
                  You have exceeded your storage limit
                </Text>

                <Text>
                  To continue using please action one of the following:
                </Text>
                <BulletPoints>
                  <BulletPoint>Remove redundant data</BulletPoint>
                  <BulletPoint>Upgrade plan</BulletPoint>
                  <BulletPoint>Purchase additional storage</BulletPoint>
                </BulletPoints>
                <Elements stripe={stripePromise}>
                  <Box
                    flex="horizontal"
                    gap="x3"
                    alignChildrenHorizontal="middle"
                  >
                    <UsageChangePlan />
                    <UsageAddStorage />
                  </Box>
                </Elements>
              </Box>
            </ModalBody>
          </Modal>
        )}
      {children}
    </UsageContext.Provider>
  );
};

export default UsageProvider;
