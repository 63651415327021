import { Box } from 'preshape';
import React from 'react';

type Props = {
  size?: string;
  fill?: string;
};

const NodesIcon = ({ size = '1.5rem', fill = 'currentColor' }: Props) => {
  return (
    <Box
      className="Icon"
      tag="svg"
      height={size}
      viewBox="0 0 85 85"
      width={size}
      borderRadius="full"
      fill={fill}
    >
      <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87.69 52.48">
        <g id="Layer_1-2">
          <path className="cls-1" d="m87.69,41.43h-22.72l3-15.19-3-15.19h22.72v30.39Zm-21.72-6h15.72v-18.39h-15.72v18.39Z" />
          <path className="cls-1" d="m22.72,41.43H0V11.05h22.72l-3,15.19,3,15.19Zm-16.72-6h13.72v-18.39H6v18.39Z" />
          <path className="cls-1" d="m43.52,6c11.1,0,20.21,9.11,20.22,20.23.01,11.11-9.11,20.23-20.23,20.24h-.02c-11.1,0-20.21-9.11-20.22-20.23-.01-11.11,9.11-20.23,20.23-20.24h.02m0-6h-.02c-14.49.02-26.25,11.79-26.24,26.25.02,14.46,11.78,26.23,26.22,26.23,7.01,0,13.59-2.75,18.56-7.72,4.97-4.97,7.7-11.55,7.7-18.53-.02-14.46-11.78-26.23-26.22-26.23h0Z" />
          <path className="cls-1" d="m52.7,26.24c0,5.05-4.14,9.19-9.19,9.2-5.05,0-9.19-4.14-9.2-9.19,0-5.05,4.14-9.19,9.19-9.2,5.05,0,9.19,4.14,9.2,9.19Z" />
        </g>
      </svg>
    </Box>
  );
};

export default NodesIcon;
