import { BoxProps } from 'preshape';
import React from 'react';
import PlaceholderBlock from './PlaceholderBlock';

type Props = BoxProps & {
  size?: string;
};

const PlaceholderAvatar = ({ size = '2rem', ...rest }: Props) => {
  return (
    <PlaceholderBlock
      {...rest}
      borderRadius="full"
      height={size}
      margin="x2"
      width={size}
    />
  );
};

export default PlaceholderAvatar;
