"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isNode = exports.getNodeCodeText = exports.getNodeValidations = void 0;
const types_1 = require("@drainify/types");
const yup_1 = require("yup");
const inputIsValid = (value) => value === undefined || (value >= 0 && value <= 1000);
const getNodeValidations = (reportEditor, nodeUid) => {
    const nodeNames = reportEditor.report.nodes
        .filter(({ uid }) => uid !== nodeUid)
        .map((node) => reportEditor.getNodeName(node));
    const needsPoint = reportEditor.report.nodes.filter((e) => e.point).length > 0;
    return (0, yup_1.object)().shape({
        code: (0, yup_1.mixed)().oneOf(Object.values(types_1.NodeCode)).defined(),
        depth: (0, yup_1.number)().test('Depth', 'depth is invalid', (e) => {
            return inputIsValid(e);
        }),
        imageUrl: (0, yup_1.string)().max(2048),
        index: (0, yup_1.number)().required(),
        point: reportEditor.report.planType === types_1.PlanType.GOOGLE_MAPS && needsPoint
            ? (0, yup_1.object)().required()
            : (0, yup_1.object)(),
        name: (0, yup_1.string)()
            .unique(nodeNames, 'Name already exists')
            .test('len', `Maximum length is 11`, (name) => name ? (name === null || name === void 0 ? void 0 : name.length) < 11 : true),
        remarks: (0, yup_1.string)().test('length', 'Maximum 160 characters', (e) => !e || e.length <= 160),
        uid: (0, yup_1.string)().required().max(2048),
        attributes: (0, yup_1.object)().shape({
            material: (0, yup_1.mixed)().oneOf(Object.values(types_1.NodeMaterial)),
            shape: (0, yup_1.mixed)().oneOf(Object.values(types_1.NodeShape)),
            wallCondition: (0, yup_1.mixed)().oneOf(Object.values(types_1.NodeWallCondition)),
            surfaceType: (0, yup_1.mixed)().oneOf(Object.values(types_1.NodeSurfaceType)),
            coverFrameCondition: (0, yup_1.mixed)().oneOf(Object.values(types_1.NodeCoverFrameCondition)),
            laterialConnections: (0, yup_1.number)().max(10).min(0),
            breadth: (0, yup_1.number)().test('Breadth', 'breadth is invalid', (e) => {
                return inputIsValid(e);
            }),
            width: (0, yup_1.number)().test('Width', 'width is invalid', (e) => {
                return inputIsValid(e);
            }),
            diameter: (0, yup_1.number)().test('Diameter', 'diameter is invalid', (e) => {
                return inputIsValid(e);
            }),
        }),
    });
};
exports.getNodeValidations = getNodeValidations;
const getNodeCodeText = (code, position) => {
    if (!code)
        return undefined;
    if (position === types_1.SectionNodePosition.END)
        return `${code}F`;
    return code;
};
exports.getNodeCodeText = getNodeCodeText;
const isNode = (node) => {
    return !!node && 'uid' in node;
};
exports.isNode = isNode;
