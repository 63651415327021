"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sectionAttributeApplicable = exports.isBendySection = exports.isSection = exports.emptySectionPoints = exports.getSectionValidations = void 0;
const types_1 = require("@drainify/types");
const yup_1 = require("yup");
const inputIsValid = (value) => value === undefined || (value >= 0 && value <= 1000);
const getSectionValidations = (reportEditor, sectionUid) => {
    const sectionNames = reportEditor.report.sections
        .filter(({ uid }) => uid !== sectionUid)
        .map((section) => reportEditor.getSectionName(section));
    const section = reportEditor.getSectionByUid(sectionUid);
    return (0, yup_1.object)().shape({
        name: (0, yup_1.string)().unique(sectionNames, 'Name already exists'),
        nodeStartUid: (0, yup_1.string)().required('Required'),
        nodeEndUid: (0, yup_1.string)().test('End node', 'Select end node, or mark as unknown', (e) => e !== ''),
        attributes: (0, yup_1.object)().shape({
            alternateID: (0, yup_1.string)().test('length', `Maximum length is 50 characters`, (e) => !e || e.length <= 50),
            cleaned: (0, yup_1.boolean)(),
            diameter: (0, yup_1.number)().test('Diameter', 'diameter is invalid', (e) => {
                if (!(section === null || section === void 0 ? void 0 : section.attributes.shape)) {
                    return inputIsValid(e);
                }
                else if (!e) {
                    return !(0, types_1.isCircular)(section.attributes.shape);
                }
                else if (!(0, types_1.isCircular)(section.attributes.shape)) {
                    return inputIsValid(e);
                }
                else {
                    return (0, types_1.isCircular)(section.attributes.shape) && inputIsValid(e);
                }
            }),
            height: (0, yup_1.number)().test('Height', 'height is invalid', (e) => {
                if (!(section === null || section === void 0 ? void 0 : section.attributes.shape)) {
                    return inputIsValid(e);
                }
                else if ((0, types_1.isCircular)(section.attributes.shape)) {
                    return inputIsValid(e);
                }
                else if (!e) {
                    return (0, types_1.isCircular)(section === null || section === void 0 ? void 0 : section.attributes.shape);
                }
                else {
                    return !(0, types_1.isCircular)(section.attributes.shape) && inputIsValid(e);
                }
            }),
            width: (0, yup_1.number)().test('Width', 'width', (e) => {
                if (!(section === null || section === void 0 ? void 0 : section.attributes.shape)) {
                    return inputIsValid(e);
                }
                else if (!e) {
                    return true;
                }
                else if ((0, types_1.isCircular)(section.attributes.shape)) {
                    return (0, types_1.isCircular)(section === null || section === void 0 ? void 0 : section.attributes.shape) && inputIsValid(e);
                }
                else {
                    return !(0, types_1.isCircular)(section.attributes.shape) && inputIsValid(e);
                }
            }),
            divisionOrDistrict: (0, yup_1.string)().test('length', `Maximum length is 50 characters`, (e) => !e || e.length <= 50),
            drainageArea: (0, yup_1.string)().test('length', `Maximum length is 15 characters`, (e) => !e || e.length <= 15),
            flow: (0, yup_1.mixed)().oneOf(Object.values(types_1.SectionFlowCode)).required('Required'),
            jointLength: (0, yup_1.number)().max(10, 'Joint length must be under 10000mm').min(0),
            sectionOwnership: (0, yup_1.mixed)()
                .oneOf(Object.values(types_1.Ownership))
                .required('Required'),
            landOwnership: (0, yup_1.mixed)().oneOf(Object.values(types_1.Ownership)),
            legalStatus: (0, yup_1.mixed)().oneOf(Object.values(types_1.LegalStatus)),
            length: (0, yup_1.number)()
                .min(0.001)
                .max(10000, 'Length must be under 10000m')
                .required(),
            liningType: (0, yup_1.mixed)().oneOf(Object.values(types_1.LiningType)),
            material: (0, yup_1.mixed)()
                .oneOf(Object.values(types_1.SectionMaterialCode))
                .required('Required'),
            pipeType: (0, yup_1.mixed)().oneOf(Object.values(types_1.PipeType)).required('Required'),
            PLRSuffix: (0, yup_1.mixed)().oneOf(Object.values(types_1.PLRSuffix)),
            shape: (0, yup_1.mixed)()
                .oneOf(Object.values(types_1.SectionShapeCode))
                .required('Required'),
            use: (0, yup_1.mixed)().oneOf(Object.values(types_1.SectionUseCode)).required('Required'),
            yearConstructed: (0, yup_1.number)().max(10000).min(0),
            nodeOneRef: (0, yup_1.string)().test('length', `Maximum length is 12 characters`, (e) => !e || e.length <= 12),
            nodeTwoRef: (0, yup_1.string)().test('length', `Maximum length is 12 characters`, (e) => !e || e.length <= 12),
            nodeThreeRef: (0, yup_1.string)().test('length', `Maximum length is 12 characters`, (e) => !e || e.length <= 12),
            pipeUnitLength: (0, yup_1.string)().test('length', `Maximum length is 4 characters`, (e) => !e || e.length <= 4),
            expectedLength: (0, yup_1.number)().max(10000, 'Expected length must be under 10000m').min(0),
            nodeOneCoordinate: (0, yup_1.string)().test('length', `Maximum length is 20 characters`, (e) => !e || e.length <= 20),
            nodeTwoCoordinate: (0, yup_1.string)().test('length', `Maximum length is 20 characters`, (e) => !e || e.length <= 20),
            nodeThreeCoordinate: (0, yup_1.string)().test('length', `Maximum length is 20 characters`, (e) => !e || e.length <= 20),
        }),
    });
};
exports.getSectionValidations = getSectionValidations;
/**
 *
 */
exports.emptySectionPoints = {
    a: null,
    b: null,
    bbox: null,
    distance: null,
    line: null,
    poi: null,
};
/**
 *
 */
const isSection = (s) => !!s && 'uid' in s;
exports.isSection = isSection;
const isBendySection = (s) => { var _a; return s && s.additionalPoints && ((_a = s.additionalPoints) === null || _a === void 0 ? void 0 : _a.length) > 0 ? true : false; };
exports.isBendySection = isBendySection;
const ATTRIBUTE_PIPETYPE = 'attributes.pipeType';
const ATTRIBUTE_FLOW = 'attributes.flow';
const ATTRIBUTE_LENGTH = 'attributes.length';
const ATTRIBUTE_DIAMETER = 'attributes.diameter';
const ATTRIBUTE_HEIGHT = 'attributes.height';
const ATTRIBUTE_WIDTH = 'attributes.width';
const ATTRIBUTE_MATERIAL = 'attributes.material';
const ATTRIBUTE_SHAPE = 'attributes.shape';
const ATTRIBUTE_USE = 'attributes.use';
const ATTRIBUTE_SECTION_OWNERSHIP = 'attributes.sectionOwnership';
const ATTRIBUTE_DRAINAGE_AREA = 'attributes.drainageArea';
const ATTRIBUTE_JOINT_LENGTH = 'attributes.jointLength';
const ATTRIBUTE_LINING_TYPE = 'attributes.liningType';
const ATTRIBUTE_YEAR_CONSTRUCTED = 'attributes.yearConstructed';
const ATTRIBUTE_LOCATION_TYPE_CODE = 'attributes.locationTypeCode';
const ATTRIBUTE_ALTERNATE_ID = 'attributes.alternateID';
const ATTRIBUTE_DIVISION_OR_DISTRICT = 'attributes.divisionOrDistrict';
const ATTRIBUTE_PLR_SUFFIX = 'attributes.PLRSuffix';
const ATTRIBUTE_NODE_ONE_COORDINATE = 'attributes.nodeOneCoordinate';
const ATTRIBUTE_NODE_ONE_REF = 'attributes.nodeOneRef';
const ATTRIBUTE_TWO_COORDINATE = 'attributes.nodeTwoCoordinate';
const ATTRIBUTE_TWO_REF = 'attributes.nodeTwoRef';
const ATTRIBUTE_THREE_COORDINATE = 'attributes.nodeThreeCoordinate';
const ATTRIBUTE_THREE_REF = 'attributes.nodeThreeRef';
const ATTRIBUTE_LAND_OWNERSHIP = 'attributes.landOwnership';
const ATTRIBUTE_PIPE_LENGTH_REF = 'attributes.pipeLengthRef';
const ATTRIBUTE_DRAIN_SEWER_TYPE = 'attributes.drainSewerType';
const ATTRIBUTE_PIPE_UNIT_LENGTH = 'attributes.pipeUnitLength';
const ATTRIBUTE_EXPECTED_LENGTH = 'attributes.expectedLength';
const ATTRIBUTE_LEGAL_STATUS = 'attributes.legalStatus';
const ATTRIBUTE_COATING_METHOD = 'attributes.coatingMethod';
const ATTRIBUTE_UP_NORTHING = 'attributes.upNorthing';
const ATTRIBUTE_UP_EASTING = 'attributes.upEasting';
const ATTRIBUTE_UP_ELEVATION = 'attributes.upElevation';
const ATTRIBUTE_DOWN_NORTHING = 'attributes.downNorthing';
const ATTRIBUTE_DOWN_EASTING = 'attributes.downEasting';
const ATTRIBUTE_DOWN_ELEVATION = 'attributes.downElevation';
const ATTRIBUTE_UP_RIM_TO_INVERT = 'attributes.upRimToInvert';
const ATTRIBUTE_UP_RIM_TO_GRADE = 'attributes.upRimToGrade';
const ATTRIBUTE_UP_GRADE_TO_INVERT = 'attributes.upGradeToInvert';
const ATTRIBUTE_DOWN_RIM_TO_INVERT = 'attributes.downRimToInvert';
const ATTRIBUTE_DOWN_RIM_TO_GRADE = 'attributes.downRimToGrade';
const ATTRIBUTE_DOWN_GRADE_TO_INVERT = 'attributes.downGradeToInvert';
const sectionAttributeApplicable = (key, projectType) => {
    if (!projectType)
        return false;
    switch (key) {
        case 'Section name':
        case 'nodeStartUid':
        case 'nodeEndUid':
        case 'additionalPoints':
        case ATTRIBUTE_PIPETYPE:
        case ATTRIBUTE_FLOW:
        case ATTRIBUTE_LENGTH:
        case ATTRIBUTE_DIAMETER:
        case ATTRIBUTE_HEIGHT:
        case ATTRIBUTE_WIDTH:
        case ATTRIBUTE_MATERIAL:
        case ATTRIBUTE_SHAPE:
        case ATTRIBUTE_USE:
        case ATTRIBUTE_SECTION_OWNERSHIP:
        case ATTRIBUTE_DRAINAGE_AREA:
        case ATTRIBUTE_JOINT_LENGTH:
        case ATTRIBUTE_LINING_TYPE:
        case ATTRIBUTE_YEAR_CONSTRUCTED:
        case ATTRIBUTE_LOCATION_TYPE_CODE:
            return [
                types_1.ProjectType.MSCC5_COMMERERCIAL,
                types_1.ProjectType.MSCC5_DOMESTIC,
                types_1.ProjectType.PACP,
                types_1.ProjectType.LACP,
            ].includes(projectType);
        // MSCC5 only
        case ATTRIBUTE_ALTERNATE_ID:
        case ATTRIBUTE_DIVISION_OR_DISTRICT:
        case ATTRIBUTE_PLR_SUFFIX:
        case ATTRIBUTE_NODE_ONE_COORDINATE:
        case ATTRIBUTE_NODE_ONE_REF:
        case ATTRIBUTE_TWO_COORDINATE:
        case ATTRIBUTE_TWO_REF:
        case ATTRIBUTE_THREE_COORDINATE:
        case ATTRIBUTE_THREE_REF:
        case ATTRIBUTE_LAND_OWNERSHIP:
        case ATTRIBUTE_PIPE_LENGTH_REF:
        case ATTRIBUTE_DRAIN_SEWER_TYPE:
        case ATTRIBUTE_PIPE_UNIT_LENGTH:
        case ATTRIBUTE_EXPECTED_LENGTH:
        case ATTRIBUTE_LEGAL_STATUS:
            return [
                types_1.ProjectType.MSCC5_COMMERERCIAL,
                types_1.ProjectType.MSCC5_DOMESTIC,
            ].includes(projectType);
        case ATTRIBUTE_COATING_METHOD:
        case ATTRIBUTE_UP_NORTHING:
        case ATTRIBUTE_UP_EASTING:
        case ATTRIBUTE_UP_ELEVATION:
        case ATTRIBUTE_DOWN_NORTHING:
        case ATTRIBUTE_DOWN_EASTING:
        case ATTRIBUTE_DOWN_ELEVATION:
        case ATTRIBUTE_UP_RIM_TO_INVERT:
        case ATTRIBUTE_UP_RIM_TO_GRADE:
        case ATTRIBUTE_UP_GRADE_TO_INVERT:
        case ATTRIBUTE_DOWN_RIM_TO_INVERT:
        case ATTRIBUTE_DOWN_RIM_TO_GRADE:
        case ATTRIBUTE_DOWN_GRADE_TO_INVERT:
            return [types_1.ProjectType.PACP].includes(projectType);
    }
};
exports.sectionAttributeApplicable = sectionAttributeApplicable;
