import { Measurement } from '@drainify/types';
import { getMidPointBetweenPoints, ReportElementType } from '@drainify/utils';
import { NearestPointOnLine } from '@turf/nearest-point-on-line';
import round from 'lodash.round';
import { Box, Icons, Label, sizeX10Px, Text } from 'preshape';
import React, { PropsWithChildren, useMemo } from 'react';
import Distance from '../DistanceInput/Distance';
import { useMapLayersContext } from '../Map/MapLayers/MapLayers';
import MapLabel from '../Map/MapMarker/MapLabel';
import MapMarker from '../Map/MapMarker/MapMarker';
import MapMarkerToolbar from '../Map/MapMarker/MapMarkerToolbar';
import useGeometryStoreRegister from '../Map/useGeometryStore/useGeometryStoreRegister';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import useDrawMeasurementMap from '../Report/ReportMap/ReportMapElements/useDrawMeasurementMap';
import { useReportMapContext } from '../Report/ReportMap/ReportMapProvider';
import MeasurementView from './MeasurementView';
import ReportMapMeasurementSegment from './ReportMapMeasurementSegment';

type Props = {
  measurement: Measurement;
  points: GeoJSON.Point[];
};

const ReportMapMeasurement = ({
  measurement,
  points,
}: PropsWithChildren<Props>) => {
  const { isLayerVisible } = useMapLayersContext();
  const { reportEditor } = useReportEditorContext();
  const { focusMeasurement, focusedMeasurementUid, hasFeature } =
    useReportMapContext();

  const visible = isLayerVisible(
    ReportElementType.MEASUREMENT,
    measurement.uid
  );

  useDrawMeasurementMap({
    points: measurement.points,
    visible: visible,
  });

  const handleDragEnd = (origin: GeoJSON.Point) => (point: GeoJSON.Point) => {
    const copy = [...points];
    copy[points.indexOf(origin)] = point;

    reportEditor.updateMeasurement(measurement.uid, {
      ...measurement,
      points: copy,
    });
  };

  const midPoint = React.useMemo(() => {
    if (points.length % 2 === 0) {
      return getMidPointBetweenPoints(
        points[points.length / 2 - 1],
        points[points.length / 2]
      );
    } else {
      return points[Math.floor(points.length / 2)];
    }
  }, [points]);

  const lineString = useMemo<GeoJSON.LineString | undefined>(() => {
    if (points.length > 1) {
      return {
        type: 'LineString',
        coordinates: points.map((e) => e.coordinates),
      };
    }
  }, [points]);

  useGeometryStoreRegister({
    geometry: lineString,
    opts: {
      id: `ReportMapMesurement.${measurement.uid}`,
      keepInFocus: true,
      padding: sizeX10Px,
    },
  });

  const length = reportEditor.getMeasurementLength(measurement.uid);

  const handleRemovePoint = (point: GeoJSON.Point) => {
    reportEditor.updateMeasurement(measurement.uid, {
      ...measurement,
      points: points.filter((e) => e !== point),
    });
  };

  function handleOnAdd(
    point: NearestPointOnLine['geometry'],
    end: GeoJSON.Point
  ): void {
    const copy = [...points];
    copy.splice(points.indexOf(end), 0, point);
    reportEditor.updateMeasurement(measurement.uid, {
      ...measurement,
      points: copy,
    });
  }

  return (
    <>
      <MapLabel
        id={measurement.uid}
        onClick={() => focusMeasurement(measurement.uid)}
        point={midPoint}
        visible={visible}
      >
        <MapMarkerToolbar
          information={<MeasurementView measurement={measurement} />}
          // enabled={hasFeature('Elements:edit') && visible}
        >
          <Label
            alignChildren="middle"
            flex="vertical"
            size="x1"
            backgroundColor="light-shade-3"
            borderColor="dark-shade-1"
            borderStyle="dashed"
          >
            <Box
              textColor="dark-shade-1"
              flex="vertical"
              alignChildrenHorizontal="middle"
            >
              <Text>{reportEditor.getMeasurementName(measurement)}</Text>

              <Text weak>
                {length !== null && length !== undefined ? (
                  <Distance inline type="long" value={round(length, 2)} />
                ) : (
                  <Text>No scale set</Text>
                )}
              </Text>
            </Box>
          </Label>
        </MapMarkerToolbar>
      </MapLabel>

      {focusedMeasurementUid === measurement.uid &&
        points.map((e, i) => (
          <Box key={i}>
            {i < points.length - 1 && (
              <ReportMapMeasurementSegment
                key={measurement.uid + '-' + i + '-segment'}
                points={[measurement.points[i], measurement.points[i + 1]]}
                onAdd={handleOnAdd}
              />
            )}
            <MapMarker
              key={measurement.uid + '-' + i + '-marker'}
              point={e}
              onDragEnd={
                hasFeature('Elements:move') ? handleDragEnd(e) : undefined
              }
            >
              <MapMarkerToolbar
                enabled={true}
                tools={[
                  {
                    icon: <Icons.Trash2 />,
                    onClick: () => handleRemovePoint(e),
                  },
                ]}
              >
                <Box backgroundColor="accent-shade-5" borderRadius="full">
                  <Icons.Circle />
                </Box>
              </MapMarkerToolbar>
            </MapMarker>
          </Box>
        ))}
    </>
  );
};

export default ReportMapMeasurement;
