import {
  Project,
  ProjectPostBody,
  ResponseError,
  ResponseMultipleEntities,
} from '@drainify/types';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import useFetch, { hasAllParams } from '../components/Api/useFetch';

const useOrgProjects = (orgId?: string) => {
  const queryClient = useQueryClient();
  const fetch = useFetch();
  const key = ['projects', `/orgs/${orgId}/projects`, fetch];
  const params = { orgId: orgId as string };

  const query = useQuery<ResponseMultipleEntities<Project>, ResponseError>(
    key,
    () => fetch(`/orgs/:orgId/projects`, { method: 'GET', params: params }),
    {
      enabled: hasAllParams(params),
    }
  );

  const create = useMutation<Project, ResponseError, ProjectPostBody>(
    (body: ProjectPostBody) =>
      fetch('/orgs/:orgId/projects', { method: 'POST', body, params }),
    {
      onSuccess: () => {
        queryClient.fetchQuery(key);
      },
    }
  );

  return { create, query };
};

export default useOrgProjects;
