import { Buttons, Text } from 'preshape';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Explainer from '../../../components/Explainer/Explainer';
import NodeCreateButton from '../../../components/Node/NodeCreateButton';
import NodeList from '../../../components/Node/NodeList';
import { useProjectContext } from '../../../components/Project/ProjectProvider';
import Page from '../Layout/Page';
import PageBody from '../Layout/PageBody';
import PageHeader from '../Layout/PageHeader';
import PageMenu from '../Layout/PageMenu';

const NodesPage = () => {
  const navigate = useNavigate();
  const { activeBookingId } = useProjectContext();

  return (
    <Page>
      <PageHeader
        action={
          <Explainer title="Nodes">
            <Text>
              Capture all the essential features of nodes to streamline drain
              surveys{' '}
            </Text>
            <Text>
              Record editable references, numbers, depths, sizes, construction
              features, and remarks{' '}
            </Text>
            <Text>
              Additionally, you can include optional pictures to enhance the
              level of detail in your survey{' '}
            </Text>
            <Text>
              With Nodes, conducting thorough drain surveys becomes more
              effective and comprehensive
            </Text>
          </Explainer>
        }
        title="Nodes"
      />
      <PageBody flex="vertical" gap="x3" overflow="auto" basis="0" grow>
        <PageMenu margin="x3">
          <NodeList
            onItemClick={(e) =>
              navigate(e + `?activeBookingId=${activeBookingId}`)
            }
          />
        </PageMenu>
      </PageBody>
      <Buttons padding="x6">
        <NodeCreateButton variant="primary" grow />
      </Buttons>
    </Page>
  );
};

export default NodesPage;
