import { Org, WithTracking } from '.';

export interface TranscodeUsage extends WithTracking {
  uid: string;
  orgUid: string;
  org: Org;
  date: Date;
  duration: number;
}

export interface TranscodeUsageView extends WithTracking {
  total: number;
  used: number;
}
