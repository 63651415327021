import { Button, Grid, Icons, Text } from 'preshape';
import React from 'react';
import { useMapContext } from '../../../Map/Map';
import MapNotification from '../../../Map/MapNotification/MapNotification';
import MapSidebarContentItem from '../../../Map/MapSidebar/MapSidebarContentItem';
import MapSidebarContentItemSection from '../../../Map/MapSidebar/MapSidebarContentItemSection';
import PlanUploadModal from '../../../Plan/PlanUploadModal';
import { useProjectContext } from '../../../Project/ProjectProvider';
import SitePhotoCreateButton from '../../../SitePhotos/SitePhotoCreateButton';
import SitePhotoView from '../../../SitePhotos/SitePhotoView';
import { useReportEditorContext } from '../../ReportEditorProvider';
import { useReportMapContext } from '../ReportMapProvider';
import ReportMapToolAddPlan from '../ReportMapTools/ReportMapToolAddPlan';
import ReportMapSidebarPlanEdit from './ReportMapSidebarPlanEdit';

const ReportMapSidebarPlan = () => {
  const { reportEditor } = useReportEditorContext();
  const { activeSidebar } = useReportMapContext();
  const { activeBooking } = useProjectContext();
  const { report } = reportEditor;
  const [planModalVisible, setPlanModalVisible] = React.useState(false);
  const { toggleMapType, mapType } = useMapContext();

  return (
    <>
      <MapSidebarContentItem active={activeSidebar === 'plan'} title={'Plan'}>
        <MapSidebarContentItemSection
          title="Plan"
          action={
            <Button
              color="accent"
              gap="x2"
              disabled={!!activeBooking?.completedAt}
              variant="tertiary"
              onClick={() => setPlanModalVisible(true)}
            >
              {report?.plan ? (
                <></>
              ) : (
                <>
                  <Text>Add</Text>
                  <Icons.Plus />
                </>
              )}
            </Button>
          }
        >
          <ReportMapToolAddPlan plan={report?.plan} />
        </MapSidebarContentItemSection>
        {report.plan && <ReportMapSidebarPlanEdit />}
        <MapSidebarContentItemSection
          title="Site photography"
          action={<SitePhotoCreateButton variant="tertiary" gap="x2" />}
        >
          <Grid margin="x2" repeat="2" repeatWidth="1fr">
            {reportEditor.report.sitePhotos
              .filter((e) => e.jobId === activeBooking?.uid)
              .map((e) => (
                <SitePhotoView sitePhoto={e} key={e.uid} />
              ))}
          </Grid>
        </MapSidebarContentItemSection>
        <MapSidebarContentItemSection
          title="Map view options"
        >
            <Button
              borderSize="x0"
              onClick={() => {
                toggleMapType();
              }}
          >
            {mapType === google.maps.MapTypeId.ROADMAP ? 'Satellite' : 'Map'}
          </Button>
          {
            mapType === google.maps.MapTypeId.HYBRID && <MapNotification color={'highlight'}>

              <Text> Satellite view should be used for reference only </Text>
            </MapNotification>
          }
        </MapSidebarContentItemSection>
      </MapSidebarContentItem>
      <PlanUploadModal
        onClose={() => setPlanModalVisible(false)}
        visible={planModalVisible}
      />
    </>
  );
};

export default ReportMapSidebarPlan;
