import { User } from '@drainify/types';
import { runValidations } from '@drainify/utils';
import { useForm } from 'preshape';
import { useCallback, useMemo } from 'react';
import * as yup from 'yup';

const validations = yup.object().shape({
  uid: yup.string().required(),
  fullName: yup.string().required('Your name is required'),
  email: yup
    .string()
    .email('Must be a valid email')
    .required('Email is required'),
  avatarCropData: yup.object(),
  avatarUrlCropped: yup.string(),
  avatarUrlOriginal: yup.string(),
  phoneNumber: yup.string().required('Your phone number is required'),
  isSysAdmin: yup.boolean(),
});

const useUserForm = (user?: User) => {
  const initial = useMemo<User>(
    () =>
      user || {
        uid: '',
        fullName: '',
        email: '',
        phoneNumber: '',
      },
    [user]
  );

  return useForm({
    initial: initial,
    validate: useCallback((user, setError) => {
      setError(runValidations(validations, user));
    }, []),
  });
};

export default useUserForm;
