import {
  Booking,
  ResponseError,
  ResponseMultipleEntities,
} from '@drainify/types';
import { useQuery } from 'react-query';
import useFetch from '../components/Api/useFetch';

const useBookingSearch = (searchString?: string) => {
  const fetch = useFetch();
  const key = ['bookings', `/bookings/search/${searchString}`, fetch];

  const query = useQuery<ResponseMultipleEntities<Booking>, ResponseError>(
    key,
    () =>
      fetch(
        '/bookings/search',
        { method: 'GET' },
        {
          searchString: searchString || '',
        }
      )
  );

  return { query };
};

export default useBookingSearch;
