"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiError = void 0;
const http_status_codes_1 = require("http-status-codes");
class ApiError extends Error {
    constructor(props) {
        super(props.message);
        this.errors = props.errors;
        this.code = props.code;
        this.message = props.message;
        this.status = props.status;
    }
    toJSON() {
        return {
            errors: this.errors,
            code: this.code,
            status: this.status,
        };
    }
    toString() {
        let errorString = '';
        if (this.status) {
            errorString += `${(0, http_status_codes_1.getReasonPhrase)(this.status)} (${this.status}): `;
        }
        errorString += this.message;
        return errorString;
    }
}
exports.ApiError = ApiError;
