import { getDistanceUnitText } from '@drainify/utils';
import { Label, LabelProps } from 'preshape';
import React, { ForwardRefRenderFunction } from 'react';
import useDisplayDistance, { DisplayDistanceType } from './useDisplayUnit';

type Props = LabelProps & {
  type: DisplayDistanceType;
  value?: number;
};

const DistanceUnitLabel: ForwardRefRenderFunction<HTMLLabelElement, Props> = ({
  type,
  value,
  ...rest
}) => {
  const [, unit] = useDisplayDistance(type, value);
  return (
    <Label
      backgroundColor="text-shade-1"
      borderRadius="x2"
      {...rest}
      paddingHorizontal="x2"
      paddingVertical="x1"
      strong
      textColor="background-shade-1"
    >
      {getDistanceUnitText(unit, true)}
    </Label>
  );
};

export default DistanceUnitLabel;
