import { ResponseError } from '@drainify/types';
import { useMutation, useQueryClient } from 'react-query';
import useFetch from '../components/Api/useFetch';

const useJobDocument = (bookingId?: string, documentId?: string) => {
  const fetch = useFetch();
  const params = {
    bookingId: bookingId as string,
    documentId: documentId as string,
  };

  const queryClient = useQueryClient();

  const remove = useMutation<void, ResponseError>(
    () =>
      fetch('/bookings/:bookingId/documents/:documentId', {
        method: 'DELETE',
        params,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries([`/bookings/${bookingId}/documents`]);
      },
    }
  );

  return { remove };
};

export default useJobDocument;
