"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.arrayAsAndList = exports.removeArrayIndex = exports.setArrayIndexValue = void 0;
const setArrayIndexValue = (array, index, value) => {
    return [
        ...array.slice(0, index || 0),
        value,
        ...array.slice((index || 0) + 1),
    ];
};
exports.setArrayIndexValue = setArrayIndexValue;
const removeArrayIndex = (array, index) => {
    return array.filter((_, i) => i !== index);
};
exports.removeArrayIndex = removeArrayIndex;
const arrayAsAndList = (array) => {
    const validEntries = array.filter((v) => v !== undefined);
    if (validEntries.length === 1) {
        return validEntries[0];
    }
    return `${validEntries.slice(0, -1).join(', ')} and ${validEntries[validEntries.length - 1]}`;
};
exports.arrayAsAndList = arrayAsAndList;
