import { Booking } from "./Booking";
import { Org } from "./Org";
import { Project } from "./Project";
import { User } from "./User";


export interface SurveyReview {
    uid: string;
    reviewedBy?: User;
    surveyBy?: User;
    org?: Org;
    rating?: number;
    comments?: string;
    project?: Project;
    booking?: Booking;
    state: SurveyReviewState;
}


export enum SurveyReviewState {
    IN_PROGRESS = "IN_PROGRESS",
    SUBMITTED = "SUBMITTED",
}