import {
  Box,
  Icons,
  Input,
  Placement,
  PlacementContent,
  PlacementManager,
  PlacementReference,
  transitionTimeBox,
} from 'preshape';
import React, { useRef, useState } from 'react';
import ProjectSearchResults from './ProjectSearchResults';

const ProjectSearchNoAnimate = () => {
  const refTimeout = useRef<number>();
  const [showResults, setShowResults] = useState(false);
  const [searchString, setSearchString] = useState<string>('');

  const handleStartSearch = () => {
    refTimeout.current = window.setTimeout(() => {
      setShowResults(true);
    }, transitionTimeBox);
  };

  const handleEndSearch = () => {
    setShowResults(false);
    setSearchString('');
  };

  return (
    <PlacementManager>
      <PlacementReference>
        {(props) => (
          <Box {...props}  style={{ backgroundColor: 'white' }}>
            <Input
              addonStart={<Icons.Search />}
              borderSize="x0"
              placeholder="Search all jobs..."
              {...props}
              onChange={(event: any) => setSearchString(event.target.value)}
              value={searchString}
              onFocus={handleStartSearch}
            />
          </Box>
        )}
      </PlacementReference>

      <Placement
        animation="FadeSlideUp"
        options={{
          modifiers: {
            preventOverflow: {
              boundariesElement: 'window',
            },
          },
        }}
        onClose={handleEndSearch}
        placement="bottom"
        visible={showResults}
        width="reference"
      >
        {searchString !== '' && (
          <PlacementContent
            backgroundColor="background-shade-1"
            borderColor="background-shade-3"
            borderSize="x2"
            borderRadius="x3"
            elevate
            overflow="auto"
            paddingBottom="x4"
            paddingHorizontal="x4"
            paddingVertical="x4"
            maxHeight={400}
          >
            <ProjectSearchResults
              onEndSearch={handleEndSearch}
              searchString={searchString}
            />
          </PlacementContent>
        )}
      </Placement>
    </PlacementManager>
  );
};

export default ProjectSearchNoAnimate;
