import { Drawing, DrawingPostBody } from '@drainify/types';
import { object, string, array } from 'yup';

export const getDrawingValidations = () => {
  return object().shape({
    points: array()
      .test('Points', 'Must close polygon', (e) => {
        return (
          Array.isArray(e) && e.length > 0 && e[0].uid === e[e.length - 1].uid
        );
      })
      .min(3)
      .required(),
    name: string().required(),
  });
};

/**
 *
 */
// export type SectionPoints = { // a: GeoJSON.Point | null; // b: GeoJSON.Point | null; // bbox: GeoJSON.BBox | null; // distance: number | null; // line: GeoJSON.LineString | null; // poi: GeoJSON.Point | null; };

/**
 *
 */
// export const emptySectionPoints: SectionPoints = { a: null, b: null, bbox: null, distance: null, line: null, poi: null, };

export const isDrawing = (s?: Drawing | DrawingPostBody): s is Drawing =>
  !!s && 'uid' in s;

export const isClosed = (drawing: Drawing) => {
  return (
    drawing.points[0].uid === drawing.points[drawing.points.length - 1].uid
  );
};
