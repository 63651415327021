import { BookingPostBody } from '@drainify/types';
import { getCodesetCodeSchema } from '@drainify/utils';
import React, { useContext } from 'react';
import DateRangeText from '../DatePicker/DateRangeText';
import SummaryCard from '../SummaryCard/SummaryCard';
import SummaryCardSection from '../SummaryCard/SummaryCardSection';
import TimeRangeText from '../TimePicker/TimeRangeText';
import { useLanguageContext } from '../Usage/LanguageProvider';
import UserList from '../User/UserList';
import { WizardContext } from '../Wizard/Wizard';

type Props = {
  booking: BookingPostBody;
};

const BookingModalSummary = ({ booking }: Props) => {
  const { language } = useLanguageContext();
  const { setActiveStepId } = useContext(WizardContext);

  return (
    <SummaryCard title="Observation">
      <SummaryCardSection
        onEdit={() => setActiveStepId('jobId')}
        title="Job number"
        value={booking.jobId}
      >
        {booking.jobId}
      </SummaryCardSection>

      <SummaryCardSection
        onEdit={() => setActiveStepId('clientJobRef')}
        title="Client job ref"
        value={booking.clientJobRef}
      >
        {booking.clientJobRef}
      </SummaryCardSection>

      <SummaryCardSection
        onEdit={() => setActiveStepId('bookedForDate')}
        title="Date"
        value={booking.bookedForDate}
      >
        <DateRangeText startDate={booking.bookedForDate} />
      </SummaryCardSection>

      <SummaryCardSection
        onEdit={() => setActiveStepId('bookedForTime')}
        title="Time"
        value={booking.bookedForTimeStart}
      >
        <TimeRangeText startTime={booking.bookedForTimeStart} />
      </SummaryCardSection>

      <SummaryCardSection
        onEdit={() => setActiveStepId('assignedTo')}
        padding="x0"
        title="Assigned"
        value={booking.assignedTo}
      >
        <UserList
          noUsersMessage="No users assigned"
          users={booking.assignedTo}
        />
      </SummaryCardSection>
      <SummaryCardSection
        onEdit={() => setActiveStepId('projectType')}
        title="Project type"
        value={booking.projectType}
      >
        {booking.projectType &&
          getCodesetCodeSchema(booking.projectType).name[language]}
      </SummaryCardSection>
      <SummaryCardSection
        onEdit={() => setActiveStepId('jobPurpose')}
        title="Job purpose"
        value={booking.jobPurpose}
      >
        {booking.jobPurpose}
      </SummaryCardSection>
    </SummaryCard>
  );
};

export default BookingModalSummary;
