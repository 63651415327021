import { ReportView, ResponseError } from '@drainify/types';
import React from 'react';
import { useQuery } from 'react-query';
import useReportAccessToken from '../../hooks/useReportAccessToken';
import ReportPlaceholderPage from '../../pages/ReportPlaceholderPage';
import ReportViewer from '../../pages/ReportViewer';
import { useFetchReport } from '../Api/useFetch';

const ReportAccessPage = () => {
  try {
    const { token } = useReportAccessToken();
    const fetch = useFetchReport(token);
    const query = useQuery<ReportView, ResponseError>([`report`], () =>
      fetch('/report', { method: 'GET' })
    );

    if (query.isLoading) return <ReportPlaceholderPage content={'Loading'} />;
    if (query.error) return <ReportPlaceholderPage content={'Error'} />;

    return <ReportViewer data={query.data} />;
  } catch (e) {
    return <ReportPlaceholderPage content={'Invalid token'} />;
  }
};

export default ReportAccessPage;
