import {
  CritcatalityGrade,
  FlowControlMeasures,
  MethodOfInspection,
  PhotographImageStorageFormat,
  PreCleaned,
  PurposeOfInspection,
  Inspection,
  VideoImageFormat,
  VideoImageStorageMedia,
  Weather,
  Temperature,
  YesNo,
  InspectionStatus,
  AccuracyOfGPS,
} from '@drainify/types';
import {
  getAccuracyOfGPSSchema,
  getCriticalityGradeSchema,
  getFlowControlSchema,
  getInspectionStatusSchema,
  getMethodOfInspectionSchema,
  getPhotographImageStorageFormatsSchema,
  getPrecleanSchema,
  getPurposesOfInspectionSchema,
  getTempraturesSchema,
  getVideoImageFormatsSchema,
  getVideoStorageSchema,
  getWeatherSchema,
  getYesNoMethodSchema,
} from '@drainify/utils';
import {
  CheckBox,
  FormProvider,
  Input,
  InputLabel,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalProps,
  ModalTitle,
} from 'preshape';
import React, { ChangeEvent, useEffect } from 'react';
import useInspectionForm from '../../hooks/forms/useInspectionForm';
import { FULL_SCREEN_MODAL_WIDTH } from '../App/App';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import { useLanguageContext } from '../Usage/LanguageProvider';
import ProjectTypeWizardStep from '../Wizard/ProjectTypeWizardStep';
import Wizard from '../Wizard/Wizard';
import WizardControls from '../Wizard/WizardControls/WizardControls';
import WizardReviewStep from '../Wizard/WizardReviewStep';
import WizardStepError from '../Wizard/WizardStepError';
import InspectionAdditionalSummary from './InspectionAdditionalSummary';

type Props = ModalProps & {
  onClose: () => void;
  initialActiveStepId?: string;
  inspectionId: string;
};

const InspectionAdditionalModal = ({
  onClose,
  initialActiveStepId,
  inspectionId,
  visible,
  ...rest
}: Props) => {
  const { language } = useLanguageContext();
  const { reportEditor } = useReportEditorContext();
  const { activeBooking } = useProjectContext();
  const inspection = reportEditor.getInspectionByUid(inspectionId);
  const form = useInspectionForm(
    inspection?.sectionUid,
    inspection,
    activeBooking?.uid
  );

  const handleSave = async () => {
    if (inspection) {
      reportEditor.updateInspection(inspection.uid, form.state);
    }

    onClose();
  };

  const handleSetAttribute =
    <T extends keyof Inspection['attributes']>(attribute: T) =>
    (value?: Inspection['attributes'][T]) => {
      form.setState((s) => ({
        ...s,
        attributes: {
          ...s.attributes,
          [attribute]: value,
        },
      }));
    };

  const handleSetAttributeFromValue =
    <T extends keyof Inspection['attributes']>(
      attribute: T,
      value: Inspection['attributes'][T]
    ) =>
    () =>
      handleSetAttribute(attribute)(value);

  const handleSetAttributeFromTextInput =
    <T extends keyof Inspection['attributes']>(attribute: T) =>
    (event: ChangeEvent<HTMLInputElement>) =>
      handleSetAttribute(attribute)(
        event.target.value as Inspection['attributes'][T]
      );

  useEffect(() => {
    if (!visible) {
      form.reset();
    }
  }, [visible]);

  return (
    <FormProvider form={form}>
      <Wizard
        flow={'update'}
        onSave={handleSave}
        onCancel={onClose}
        reset={visible}
        initialActiveStepId={initialActiveStepId}
      >
        <Modal
          {...rest}
          visible={visible}
          animation="FadeSlideUp"
          margin="x4"
          maxWidth={FULL_SCREEN_MODAL_WIDTH}
          onClose={onClose}
          overlayBackgroundCloseOnClick={false}
        >
          <ModalHeader>
            <ModalTitle>
              {reportEditor.getSectionName(
                reportEditor.getSectionByUid(inspection?.sectionUid)
              ) +
                '/' +
                reportEditor.getInspectionName(inspection)}
            </ModalTitle>
          </ModalHeader>

          <ModalBody flex="vertical">
            <ProjectTypeWizardStep
              id="attributes.inspectionStatus"
              title="Inspection Status"
            >
              <InputLabel>
                {Object.values(InspectionStatus)
                  .filter(
                    (e) =>
                      activeBooking?.projectType &&
                      getInspectionStatusSchema(e).projectTypes?.includes(
                        activeBooking?.projectType
                      )
                  )
                  .map((inspectionStatus) => (
                    <CheckBox
                      borderSize='x1'
                      checked={
                        form.state.attributes?.inspectionStatus ===
                        inspectionStatus
                      }
                      key={inspectionStatus}
                      margin="x1"
                      onChange={handleSetAttributeFromValue(
                        'inspectionStatus',
                        inspectionStatus
                      )}
                    >
                      {
                        getInspectionStatusSchema(inspectionStatus).name[
                          language
                        ]
                      }
                    </CheckBox>
                  ))}
              </InputLabel>
            </ProjectTypeWizardStep>

            <ProjectTypeWizardStep id="attributes.weather" title="Weather">
              <InputLabel>
                {Object.values(Weather)
                  .filter(
                    (e) =>
                      activeBooking?.projectType &&
                      getWeatherSchema(e).projectTypes?.includes(
                        activeBooking?.projectType
                      )
                  )
                  .map((weather) => (
                    <CheckBox
                      borderSize='x1'
                      checked={form.state.attributes?.weather === weather}
                      key={weather}
                      margin="x1"
                      onChange={handleSetAttributeFromValue('weather', weather)}
                    >
                      {getWeatherSchema(weather).name[language]}
                    </CheckBox>
                  ))}
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.temperature"
              title="Temperature"
            >
              <InputLabel>
                {Object.values(Temperature)
                  .filter(
                    (e) =>
                      activeBooking?.projectType &&
                      getTempraturesSchema(e).projectTypes?.includes(
                        activeBooking.projectType
                      )
                  )
                  .map((temperature) => (
                    <CheckBox
                      borderSize='x1'
                      checked={
                        form.state.attributes?.temperature === temperature
                      }
                      key={temperature}
                      margin="x1"
                      onChange={handleSetAttributeFromValue(
                        'temperature',
                        temperature
                      )}
                    >
                      {getTempraturesSchema(temperature).name[language]}
                    </CheckBox>
                  ))}
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep id="attributes.time" title="Time">
              <InputLabel>
                <Input
                  value={form.state.attributes.time || ''}
                  onChange={handleSetAttributeFromTextInput('time')}
                />
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep id="attributes.date" title="Date">
              <InputLabel>
                <Input
                  value={form.state.attributes.date || ''}
                  onChange={handleSetAttributeFromTextInput('date')}
                />
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.preCleaned"
              title="Pre-cleaned"
            >
              <InputLabel>
                {Object.values(PreCleaned)
                  .filter(
                    (e) =>
                      activeBooking?.projectType &&
                      getPrecleanSchema(e).projectTypes?.includes(
                        activeBooking.projectType
                      )
                  )

                  .map((preCleaned) => (
                    <CheckBox
                      borderSize='x1'
                      checked={form.state.attributes?.preCleaned === preCleaned}
                      key={preCleaned}
                      margin="x1"
                      onChange={handleSetAttributeFromValue(
                        'preCleaned',
                        preCleaned
                      )}
                    >
                      {getPrecleanSchema(preCleaned).name[language]}
                    </CheckBox>
                  ))}
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.dateCleaned"
              title="Date Cleaned"
            >
              <InputLabel>
                <Input
                  value={form.state.attributes.dateCleaned || ''}
                  onChange={handleSetAttributeFromTextInput('dateCleaned')}
                />
              </InputLabel>
            </ProjectTypeWizardStep>

            <ProjectTypeWizardStep
              id="attributes.pressureValue"
              title="Pressure Value"
            >
              <InputLabel>
                <Input
                  value={form.state.attributes.pressureValue || ''}
                  onChange={handleSetAttributeFromTextInput('pressureValue')}
                />
              </InputLabel>
            </ProjectTypeWizardStep>

            <ProjectTypeWizardStep
              id="attributes.methodOfInspection"
              title="Method of inspection"
            >
              <InputLabel>
                {Object.values(MethodOfInspection).map((methodOfInspection) => (
                  <CheckBox
                    borderSize='x1'
                    checked={
                      form.state.attributes?.methodOfInspection ===
                      methodOfInspection
                    }
                    key={methodOfInspection}
                    margin="x1"
                    onChange={handleSetAttributeFromValue(
                      'methodOfInspection',
                      methodOfInspection
                    )}
                  >
                    {
                      getMethodOfInspectionSchema(methodOfInspection).name[
                        language
                      ]
                    }
                  </CheckBox>
                ))}
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.critcatalityGrade"
              title="Critcatality grade"
            >
              <InputLabel>
                {Object.values(CritcatalityGrade).map((critcatalityGrade) => (
                  <CheckBox
                    borderSize='x1'
                    checked={
                      form.state.attributes?.critcatalityGrade ===
                      critcatalityGrade
                    }
                    key={critcatalityGrade}
                    margin="x1"
                    onChange={handleSetAttributeFromValue(
                      'critcatalityGrade',
                      critcatalityGrade
                    )}
                  >
                    {
                      getCriticalityGradeSchema(critcatalityGrade).name[
                        language
                      ]
                    }
                  </CheckBox>
                ))}
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.flowControlMeasures"
              title="Flow control measures"
            >
              <InputLabel>
                {Object.values(FlowControlMeasures).map(
                  (flowControlMeasures) => (
                    <CheckBox
                      borderSize='x1'
                      checked={
                        form.state.attributes?.flowControlMeasures ===
                        flowControlMeasures
                      }
                      key={flowControlMeasures}
                      margin="x1"
                      onChange={handleSetAttributeFromValue(
                        'flowControlMeasures',
                        flowControlMeasures
                      )}
                    >
                      {getFlowControlSchema(flowControlMeasures).name[language]}
                    </CheckBox>
                  )
                )}
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.purposeOfInspection"
              title="Purpose of inspection"
            >
              <InputLabel>
                {Object.values(PurposeOfInspection).map(
                  (purposeOfInspection) => (
                    <CheckBox
                      borderSize='x1'
                      checked={
                        form.state.attributes?.purposeOfInspection ===
                        purposeOfInspection
                      }
                      key={purposeOfInspection}
                      margin="x1"
                      onChange={handleSetAttributeFromValue(
                        'purposeOfInspection',
                        purposeOfInspection
                      )}
                    >
                      {
                        getPurposesOfInspectionSchema(purposeOfInspection).name[
                          language
                        ]
                      }
                    </CheckBox>
                  )
                )}
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.videoImageStorageMedia"
              title="Video image storage media"
            >
              {Object.values(VideoImageStorageMedia).map(
                (videoImageStorageMedia) => (
                  <CheckBox
                    borderSize='x1'
                    checked={
                      form.state.attributes?.videoImageStorageMedia ===
                      videoImageStorageMedia
                    }
                    key={videoImageStorageMedia}
                    margin="x1"
                    onChange={handleSetAttributeFromValue(
                      'videoImageStorageMedia',
                      videoImageStorageMedia
                    )}
                  >
                    {
                      getVideoStorageSchema(videoImageStorageMedia)?.name[
                        language
                      ]
                    }
                  </CheckBox>
                )
              )}
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.videoImageLocationSystem"
              title="Video image location system"
            >
              <InputLabel>
                <Input
                  value={form.state.attributes.videoImageLocationSystem || ''}
                  onChange={handleSetAttributeFromTextInput(
                    'videoImageLocationSystem'
                  )}
                />
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.videoImageFormat"
              title="Video image format"
            >
              {Object.values(VideoImageFormat).map((videoImageFormat) => (
                <CheckBox
                  borderSize='x1'
                  checked={
                    form.state.attributes?.videoImageFormat === videoImageFormat
                  }
                  key={videoImageFormat}
                  margin="x1"
                  onChange={handleSetAttributeFromValue(
                    'videoImageFormat',
                    videoImageFormat
                  )}
                >
                  {getVideoImageFormatsSchema(videoImageFormat).name[language]}
                </CheckBox>
              ))}
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.videoImageFileName"
              title="Video image file name"
            >
              <InputLabel>
                <Input
                  value={form.state.attributes.videoImageFileName || ''}
                  onChange={handleSetAttributeFromTextInput(
                    'videoImageFileName'
                  )}
                />
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.videoVolumeRef"
              title="Video volume reference"
            >
              <InputLabel>
                <Input
                  value={form.state.attributes.videoVolumeRef || ''}
                  onChange={handleSetAttributeFromTextInput('videoVolumeRef')}
                />
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.photographImageStorageFormat"
              title="Photograph image storage format"
            >
              {Object.values(PhotographImageStorageFormat).map(
                (photographImageStorageFormat) => (
                  <CheckBox
                    borderSize='x1'
                    checked={
                      form.state.attributes?.photographImageStorageFormat ===
                      photographImageStorageFormat
                    }
                    key={photographImageStorageFormat}
                    margin="x1"
                    onChange={handleSetAttributeFromValue(
                      'photographImageStorageFormat',
                      photographImageStorageFormat
                    )}
                  >
                    {
                      getPhotographImageStorageFormatsSchema(
                        photographImageStorageFormat
                      ).name[language]
                    }
                  </CheckBox>
                )
              )}
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.longitudinalLocationOfStartPoint"
              title="Longitudinal location of start point"
            >
              <InputLabel>
                <Input
                  value={
                    form.state.attributes.longitudinalLocationOfStartPoint || ''
                  }
                  onChange={handleSetAttributeFromTextInput(
                    'longitudinalLocationOfStartPoint'
                  )}
                />
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.circumferentialLocationOfStartPoint"
              title="Circumferential location of start point"
            >
              <InputLabel>
                <Input
                  value={
                    form.state.attributes
                      ?.circumferentialLocationOfStartPoint || ''
                  }
                  onChange={handleSetAttributeFromTextInput(
                    'circumferentialLocationOfStartPoint'
                  )}
                />
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.technologyCCTV"
              title="Used CCTV"
            >
              <InputLabel>
                {Object.values(YesNo)
                  .filter(
                    (e) =>
                      activeBooking?.projectType &&
                      getYesNoMethodSchema(e).projectTypes?.includes(
                        activeBooking.projectType
                      )
                  )
                  .map((technologyCCTV) => (
                    <CheckBox
                      borderSize='x1'
                      checked={
                        form.state.attributes.technologyCCTV === technologyCCTV
                      }
                      key={technologyCCTV}
                      margin="x1"
                      onChange={handleSetAttributeFromValue(
                        'technologyCCTV',
                        technologyCCTV
                      )}
                    >
                      {getYesNoMethodSchema(technologyCCTV).name[language]}
                    </CheckBox>
                  ))}
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.technologyLaser"
              title="Used Laser"
            >
              <InputLabel>
                {Object.values(YesNo)
                  .filter(
                    (e) =>
                      activeBooking?.projectType &&
                      getYesNoMethodSchema(e).projectTypes?.includes(
                        activeBooking.projectType
                      )
                  )
                  .map((technologyLaser) => (
                    <CheckBox
                      borderSize='x1'
                      checked={
                        form.state.attributes.technologyLaser ===
                        technologyLaser
                      }
                      key={technologyLaser}
                      margin="x1"
                      onChange={handleSetAttributeFromValue(
                        'technologyLaser',
                        technologyLaser
                      )}
                    >
                      {getYesNoMethodSchema(technologyLaser).name[language]}
                    </CheckBox>
                  ))}
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.technologySonar"
              title="Used Sonar"
            >
              <InputLabel>
                {Object.values(YesNo)
                  .filter(
                    (e) =>
                      activeBooking?.projectType &&
                      getYesNoMethodSchema(e).projectTypes?.includes(
                        activeBooking.projectType
                      )
                  )
                  .map((technologySonar) => (
                    <CheckBox
                      borderSize='x1'
                      checked={
                        form.state.attributes.technologySonar ===
                        technologySonar
                      }
                      key={technologySonar}
                      margin="x1"
                      onChange={handleSetAttributeFromValue(
                        'technologySonar',
                        technologySonar
                      )}
                    >
                      {getYesNoMethodSchema(technologySonar).name[language]}
                    </CheckBox>
                  ))}
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.technologySidewall"
              title="Used Sidewall"
            >
              <InputLabel>
                {Object.values(YesNo)
                  .filter(
                    (e) =>
                      activeBooking?.projectType &&
                      getYesNoMethodSchema(e).projectTypes?.includes(
                        activeBooking.projectType
                      )
                  )
                  .map((technologySidewall) => (
                    <CheckBox
                      borderSize='x1'
                      checked={
                        form.state.attributes.technologySidewall ===
                        technologySidewall
                      }
                      key={technologySidewall}
                      margin="x1"
                      onChange={handleSetAttributeFromValue(
                        'technologySidewall',
                        technologySidewall
                      )}
                    >
                      {getYesNoMethodSchema(technologySidewall).name[language]}
                    </CheckBox>
                  ))}
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.technologyZoom"
              title="Used Zoom"
            >
              <InputLabel>
                {Object.values(YesNo)
                  .filter(
                    (e) =>
                      activeBooking?.projectType &&
                      getYesNoMethodSchema(e).projectTypes?.includes(
                        activeBooking.projectType
                      )
                  )
                  .map((technologyZoom) => (
                    <CheckBox
                      borderSize='x1'
                      checked={
                        form.state.attributes.technologyZoom === technologyZoom
                      }
                      key={technologyZoom}
                      margin="x1"
                      onChange={handleSetAttributeFromValue(
                        'technologyZoom',
                        technologyZoom
                      )}
                    >
                      {getYesNoMethodSchema(technologyZoom).name[language]}
                    </CheckBox>
                  ))}
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.technologyOther"
              title="Used Other"
            >
              <InputLabel>
                {Object.values(YesNo)
                  .filter(
                    (e) =>
                      activeBooking?.projectType &&
                      getYesNoMethodSchema(e).projectTypes?.includes(
                        activeBooking.projectType
                      )
                  )
                  .map((technologyOther) => (
                    <CheckBox
                      borderSize='x1'
                      checked={
                        form.state.attributes.technologyOther ===
                        technologyOther
                      }
                      key={technologyOther}
                      margin="x1"
                      onChange={handleSetAttributeFromValue(
                        'technologyOther',
                        technologyOther
                      )}
                    >
                      {getYesNoMethodSchema(technologyOther).name[language]}
                    </CheckBox>
                  ))}
              </InputLabel>
            </ProjectTypeWizardStep>

            <ProjectTypeWizardStep id="attributes.workOrder" title="Work Order">
              <InputLabel>
                <Input
                  value={form.state.attributes?.workOrder || ''}
                  onChange={handleSetAttributeFromTextInput('workOrder')}
                />
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.mediaLabel"
              title="Media Label"
            >
              <InputLabel>
                <Input
                  value={form.state.attributes?.mediaLabel || ''}
                  onChange={handleSetAttributeFromTextInput('mediaLabel')}
                />
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.consequenceOfFailure"
              title="Consequence of Failiure"
            >
              <InputLabel>
                <Input
                  value={form.state.attributes?.consequenceOfFailure || ''}
                  onChange={handleSetAttributeFromTextInput(
                    'consequenceOfFailure'
                  )}
                />
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.pipeSegmentReference"
              title="Pipe Segment Reference"
            >
              <InputLabel>
                <Input
                  value={form.state.attributes?.pipeSegmentReference || ''}
                  onChange={handleSetAttributeFromTextInput(
                    'pipeSegmentReference'
                  )}
                />
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.locationDetails"
              title="Location Details"
            >
              <InputLabel>
                <Input
                  value={form.state.attributes?.locationDetails || ''}
                  onChange={handleSetAttributeFromTextInput('locationDetails')}
                />
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.MHCoordinateSystem"
              title="MH Coordinate System"
            >
              <InputLabel>
                <Input
                  value={form.state.attributes?.MHCoordinateSystem || ''}
                  onChange={handleSetAttributeFromTextInput(
                    'MHCoordinateSystem'
                  )}
                />
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.verticalDatum"
              title="Vertical Datum"
            >
              <InputLabel>
                <Input
                  value={form.state.attributes?.verticalDatum || ''}
                  onChange={handleSetAttributeFromTextInput('verticalDatum')}
                />
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.GPSAccuracy"
              title="GPS Accuracy"
            >
              {Object.values(AccuracyOfGPS)
                .filter(
                  (e) =>
                    activeBooking?.projectType &&
                    getAccuracyOfGPSSchema(e).projectTypes?.includes(
                      activeBooking.projectType
                    )
                )
                .map((GPSAccuracy) => (
                  <CheckBox
                    borderSize='x1'
                    checked={form.state.attributes.GPSAccuracy === GPSAccuracy}
                    key={GPSAccuracy}
                    margin="x1"
                    onChange={handleSetAttributeFromValue(
                      'GPSAccuracy',
                      GPSAccuracy
                    )}
                  >
                    {getAccuracyOfGPSSchema(GPSAccuracy).name[language]}
                  </CheckBox>
                ))}
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.additonalInfo"
              title="Additional info"
            >
              <InputLabel>
                <Input
                  value={form.state.attributes?.additonalInfo || ''}
                  onChange={handleSetAttributeFromTextInput('additonalInfo')}
                />
              </InputLabel>
            </ProjectTypeWizardStep>

            <WizardReviewStep>
              <InspectionAdditionalSummary inspection={form.state} />
            </WizardReviewStep>
          </ModalBody>

          <ModalFooter>
            <WizardStepError />
            <WizardControls />
          </ModalFooter>
        </Modal>
      </Wizard>
    </FormProvider>
  );
};

export default InspectionAdditionalModal;
