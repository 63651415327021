import {
  ObservationCode,
  PENDING_IMAGE_UPLOAD,
  VideoTranscodeStatus,
} from '@drainify/types';
import { getRepairReccomendationsSchema } from '@drainify/utils';
import {
  Alert,
  Box,
  Button,
  Buttons,
  CheckBox,
  Icons,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
  Text,
} from 'preshape';
import React from 'react';
import { generatePath } from 'react-router-dom';
import useFileUpload from '../../../../hooks/useFileUpload';
import SecretAIOCRPOI from '../../../../pages/SecretAIOCRPOI';
import FileUpload from '../../../FileUpload/FileUpload';
import HiddenContent from '../../../HiddenContent/HiddenContent';
import resizeImage from '../../../Image/ImageCompressor';
import InspectionAdditionalSummary from '../../../Inspection/InspectionAdditionalSummary';
import InspectionDeleteButton from '../../../Inspection/InspectionDeleteButton';
import InspectionSummary from '../../../Inspection/InspectionSummary';
import InspectionVideo from '../../../Inspection/InspectionVideo';
import MapSidebarContentItem from '../../../Map/MapSidebar/MapSidebarContentItem';
import MapSidebarContentItemSection from '../../../Map/MapSidebar/MapSidebarContentItemSection';
import ObservationCreateButton from '../../../Observations/ObservationCreateButton';
import ObservationsList from '../../../Observations/ObservationsList';
import { useOrgContext } from '../../../Org/OrgProvider';
import { useHasPermission } from '../../../Permissions/Permissions';
import { useProjectContext } from '../../../Project/ProjectProvider';
import { useLanguageContext } from '../../../Usage/LanguageProvider';
import VideoAddReplaceButton from '../../../Video/VideoAddReplaceButton';
import { useReportEditorContext } from '../../ReportEditorProvider';
import ReportErrorMessage from '../../ReportErrorMessage';
import { useReportMapContext } from '../ReportMapProvider';

const ReportMapSidebarInspection = () => {
  const { reportEditor, editSection, editInspection } =
    useReportEditorContext();
  const { language } = useLanguageContext();
  const {
    activeSidebar,
    focusedInspectionUid,
    focusObservation,
    focusSection,
    isEditingSectionShape,
    toggleEditSectionShape,
    unfocus,
  } = useReportMapContext();
  const { activeBooking, project, isCustomerView } = useProjectContext();
  const inspection = reportEditor.getInspectionByUid(focusedInspectionUid);
  const error = reportEditor.getInspectionError(focusedInspectionUid);
  const section = reportEditor.getInspectionSection(focusedInspectionUid);
  const hasPermission = useHasPermission('projects:update');
  const observationFileUpload = useFileUpload();
  const [finishInspectionVisible, setFinishInspectionVisible] =
    React.useState(false);

  const [editReccomendationsVisible, setEditReccomendationsVisible] =
    React.useState(false);
  const { org } = useOrgContext();

  const [showObservationList, setShowObservationList] = React.useState(true);

  const handleBack =
    section &&
    (() => {
      focusSection(section.uid);
    });

  const finishInspectionSubmit = async (file: File) => {
    const section = reportEditor.getInspectionSection(inspection?.uid);
    if (inspection) {
      const savedObservation = reportEditor.addObservationNoUpdate({
        attributes: {},
        code: [ObservationCode.GP],
        distance: section?.attributes.length || 0,
        imageUrl: PENDING_IMAGE_UPLOAD,
        inspectionUid: inspection?.uid,
        inspectionMarker: 'finish',
      });

      await reportEditor.updateAsync?.(reportEditor.report);

      const compressedBlob = (await resizeImage({
        file,
        maxSize: 500,
      })) as Blob;
      const compressedImage = new File(
        [compressedBlob],
        'obervation-snapshot.jpg',
        {
          type: compressedBlob.type,
        }
      );

      observationFileUpload.upload(
        generatePath(
          '/projects/:projectId/report/observations/:observationId/image',
          {
            projectId: project?.uid,
            observationId: savedObservation.uid,
          }
        ),
        compressedImage
      );
    }
    setFinishInspectionVisible(false);
    handleBack?.();
  };

  return (
    <>
      <MapSidebarContentItem
        active={activeSidebar === 'info' && !!focusedInspectionUid}
        onBack={handleBack}
        title={
          `${reportEditor.getSectionName(
            reportEditor.getSectionByUid(inspection?.sectionUid)
          )}/${reportEditor.getInspectionName(inspection)} ` || ''
        }
      >
        {error && (
          <MapSidebarContentItemSection>
            <ReportErrorMessage error={error} />
          </MapSidebarContentItemSection>
        )}

        <MapSidebarContentItemSection>
          <InspectionSummary inspection={inspection} />
        </MapSidebarContentItemSection>

        {hasPermission &&
          section?.uid &&
          (!activeBooking?.completedAt ||
            activeBooking.completedAt === null) && (
            <MapSidebarContentItemSection>
              <Box flex="vertical" gap="x2">
                {reportEditor.getInspectionObservations(inspection?.uid)
                  .length == 0 &&
                  inspection?.video === undefined && (
                    <Button
                      color="negative"
                      gap="x1"
                      onClick={() => {
                        if (inspection) {
                          reportEditor.removeInspection(inspection.uid);
                          handleBack?.();
                        }
                      }}
                    >
                      <Icons.AlertCircle />
                      <Text>No inspection possible/required </Text>
                    </Button>
                  )}
                <Button
                  active={isEditingSectionShape}
                  onClick={() => toggleEditSectionShape()}
                  flex="horizontal"
                  gap="x2"
                  color="accent"
                  variant="tertiary"
                >
                  <Icons.Edit3 />
                  Edit pipe route
                </Button>
              </Box>
            </MapSidebarContentItemSection>
          )}

        <MapSidebarContentItemSection
          action={
            hasPermission ? (
              <VideoAddReplaceButton inspection={inspection} />
            ) : undefined
          }
          title="Video"
        >
          <InspectionVideo
            inspection={inspection}
            onOpen={() => setShowObservationList(false)}
            onClose={() => {
              setShowObservationList(true);
            }}
          />
          {!isCustomerView && inspection?.video && (
            <Buttons flex="horizontal" alignChildrenHorizontal="middle">
              <SecretAIOCRPOI
                orgId={org?.uid}
                inspectionId={inspection?.uid}
                projectId={project?.uid}
              />
            </Buttons>
          )}
        </MapSidebarContentItemSection>

        {!isCustomerView && (
          <MapSidebarContentItemSection
            title="Private notes"
            action={
              <Button
                onClick={() =>
                  inspection && editInspection(inspection?.uid, 'notes')
                }
                variant="tertiary"
                color="accent"
                gap="x2"
              >
                <Text>Edit</Text>
                <Icons.Edit2 />
              </Button>
            }
          >
            <Box padding="x2" backgroundColor="background-shade-2">
              {reportEditor.getInspectionByUid(inspection?.uid)?.notes ? (
                <Text textColor="text-shade-3" size="x3" strong>
                  {reportEditor.getInspectionByUid(inspection?.uid)?.notes}
                </Text>
              ) : (
                <Text textColor="text-shade-4" align="middle" strong size="x2">
                  No notes yet
                </Text>
              )}
            </Box>
          </MapSidebarContentItemSection>
        )}

        <MapSidebarContentItemSection
          title="Public notes"
          action={
            isCustomerView ? undefined : (
              <Button
                onClick={() =>
                  inspection && editInspection(inspection?.uid, 'publicNotes')
                }
                variant="tertiary"
                color="accent"
                gap="x2"
              >
                <Text>Edit</Text>
                <Icons.Edit2 />
              </Button>
            )
          }
        >
          <Box padding="x2" backgroundColor="background-shade-2">
            {reportEditor.getInspectionByUid(inspection?.uid)?.notes ? (
              <Text textColor="text-shade-3" size="x3" strong>
                {reportEditor.getInspectionByUid(inspection?.uid)?.publicNotes}
              </Text>
            ) : (
              <Text textColor="text-shade-4" align="middle" strong size="x2">
                No notes yet
              </Text>
            )}
          </Box>
        </MapSidebarContentItemSection>

        {isCustomerView &&
          inspection &&
          reportEditor.getInspectionByUid(inspection.uid) &&
          reportEditor.getInspectionByUid(inspection.uid)!.repairs &&
          reportEditor.getInspectionByUid(inspection.uid)!.repairs!.length >
            0 && (
            <MapSidebarContentItemSection title="Repair reccomendations">
              <Box padding="x2" backgroundColor="background-shade-2">
                {reportEditor
                  .getInspectionByUid(inspection!.uid)!
                  .repairs!.map((e) => (
                    <Box key={e} flex="vertical" gap="x1">
                      <Text strong>
                        {' '}
                        {getRepairReccomendationsSchema(e).name[language]}{' '}
                      </Text>
                      <Text weak size="x1">
                        {getRepairReccomendationsSchema(e).description}{' '}
                      </Text>
                    </Box>
                  ))}
              </Box>
            </MapSidebarContentItemSection>
          )}

        {(!inspection?.video ||
          inspection.video.status !== VideoTranscodeStatus.PENDING) &&
          showObservationList && (
            <MapSidebarContentItemSection
              action={
                <ObservationCreateButton
                  inspectionUid={focusedInspectionUid}
                  variant="tertiary"
                />
              }
              title="Observations"
            >
              <ObservationsList
                onItemClick={focusObservation}
                inspectionUid={inspection?.uid}
                shouldValidate={true}
              />
            </MapSidebarContentItemSection>
          )}
        {inspection?.video?.status === VideoTranscodeStatus.PENDING && (
          <MapSidebarContentItemSection>
            <Box backgroundColor="background-shade-2" padding="x4">
              <Text textColor="text-shade-4" strong size="x2" align="middle">
                Observerations will appear after video processing complete
              </Text>
            </Box>
          </MapSidebarContentItemSection>
        )}

        <MapSidebarContentItemSection>
          <HiddenContent>
            <InspectionAdditionalSummary inspection={inspection} />
          </HiddenContent>
        </MapSidebarContentItemSection>

        <MapSidebarContentItemSection>
          <Buttons>
            <Button
              grow
              disabled={!!activeBooking?.completedAt}
              color="positive"
              variant="tertiary"
              gap="x2"
              onClick={() => {
                const section = reportEditor.getSectionByUid(
                  inspection?.sectionUid
                );
                if (section && section?.attributes.length === undefined) {
                  editSection(section.uid, 'attributes.length');
                }
                if (
                  !reportEditor
                    .getInspectionObservations(inspection?.uid)
                    .find(
                      (e) =>
                        e.code.includes(ObservationCode.SA) ||
                        e.inspectionMarker === 'finish'
                    )
                ) {
                  setFinishInspectionVisible(true);
                } else {
                  unfocus();
                }
              }}
            >
              <Text>Finish</Text>
              <Icons.CheckCircle />
            </Button>
            <InspectionDeleteButton
              grow
              inspectionUid={inspection?.uid}
              variant="tertiary"
            />
          </Buttons>
        </MapSidebarContentItemSection>
      </MapSidebarContentItem>
      <Modal
        animation="FadeSlideUp"
        margin="x4"
        onClose={() => setFinishInspectionVisible(false)}
        visible={finishInspectionVisible}
      >
        <ModalHeader>
          <ModalTitle>Finish inspection</ModalTitle>
        </ModalHeader>

        <ModalBody flex="vertical" gap="x12" alignChildrenVertical="middle">
          <Box flex="vertical" gap="x4">
            <Buttons>
              <FileUpload
                variant="primary"
                color="accent"
                accept="image/png, image/jpeg"
                onChange={finishInspectionSubmit}
              >
                <Icons.Camera />
                <Text>Finish image</Text>
              </FileUpload>
              <Button
                onClick={() => {
                  setFinishInspectionVisible(false);
                  handleBack?.();
                }}
              >
                <Icons.CameraOff />
                <Text>No finish image</Text>
              </Button>
            </Buttons>
          </Box>
        </ModalBody>
      </Modal>
      <Modal
        visible={editReccomendationsVisible}
        onClose={() => setEditReccomendationsVisible(false)}
      >
        <ModalHeader>Edit reccomendations</ModalHeader>
        <ModalBody>
          <Alert
            color={'accent'}
            backgroundColor="background-shade-3"
            padding="x2"
          >
            <Text strong size="x3">
              We found the following potential repairs for section 2/inspection
              1.
            </Text>
            <Text>Review/Agree or decheck to not pass on to customer</Text>
          </Alert>
          {inspection?.repairs && inspection.repairs.length > 0 ? (
            reportEditor
              .getInspectionByUid(inspection!.uid)!
              .repairs!.map((repair) => (
                <CheckBox key={repair} margin="x4" padding="x2" checked={true}>
                  <Text strong size="x2">
                    {getRepairReccomendationsSchema(repair).name[language]}
                  </Text>
                </CheckBox>
              ))
          ) : (
            <Alert color="accent" backgroundColor="accent-shade-2" padding="x2">
              <Text strong>Use the repair tool to suggest repairs</Text>
            </Alert>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default ReportMapSidebarInspection;
