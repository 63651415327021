import { Box, Tooltip, Label, TableHeaderCell } from "preshape";
import React from "react";

type Props = {
    icon: JSX.Element;
    content: string;
}

const BuddyTableHeader = ({ icon, content }: Props) => {

    return (
        <TableHeaderCell style={{ borderBottom: 'none' }}>
            <Tooltip
                content={content}
                placement='top'
                zIndex={10}
            >
                {(props) => (
                    <Box {...props} flex='horizontal' alignChildrenHorizontal="middle">
                        <Label flex='horizontal' gap='x1' backgroundColor='background-shade-3' textColor='text-shade-1'>
                            {icon}
                        </Label>
                    </Box>
                )}
            </Tooltip>
        </TableHeaderCell>
    )
}

export default BuddyTableHeader;