import classNames from 'classnames';
import { Box, BoxProps } from 'preshape';
import React, { PropsWithChildren } from 'react';
import './PlaceholderGradientAnimation.css';

type Props = BoxProps & {
  animating?: boolean;
};

const PlaceholderGradientAnimation = ({
  animating,
  ...rest
}: PropsWithChildren<Props>) => {
  return (
    <Box
      {...rest}
      className={classNames('PlaceholderGradientAnimation', {
        'PlaceholderGradientAnimation--active': animating,
      })}
    />
  );
};

export default PlaceholderGradientAnimation;
