import { ReportView } from '@drainify/types';
import { getAddressString, getFullFilePath } from '@drainify/utils';
import {
  Box,
  Button,
  Icons,
  Modal,
  ModalBody,
  ModalHeader,
  Text,
} from 'preshape';
import React, { useContext } from 'react';
import DocumentListCustomer from '../../../components/Document/DocumentListCustomer';
import NodeLocationHack from '../../../components/Node/NodeLocationHack';
import { NodeMapContext } from '../../../components/Node/NodeMapProvider';
import ObservationSummary from '../../../components/Observations/ObservationSummary';
import ObservationsList from '../../../components/Observations/ObservationsList';
import OrgView from '../../../components/Org/OrgView';
import { useReportEditorContext } from '../../../components/Report/ReportEditorProvider';
import SectionView from '../../../components/Section/SectionView';
import Page from '../Layout/Page';
import PageBody from '../Layout/PageBody';
import PageHeader from '../Layout/PageHeader';
import PageMenu from '../Layout/PageMenu';
import PageMenuItem from '../Layout/PageMenuItem';

type Props = {
  data: ReportView;
};

const ReportViewMobile = ({ data }: Props) => {
  const { reportEditor } = useReportEditorContext();
  const { setProject, setReport } = useContext(NodeMapContext);
  const jobInspections = reportEditor.report.inspections.filter(
    (e) => e.jobId === data.job.uid
  );
  const [planModalVisible, setPlanModalVisible] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setProject(data.project);
      setReport(data.report);
    }, 1000);
  }, []);

  const [documentsModalVisible, setDocumentsModalVisible] =
    React.useState(false);
  const [activeInspectionUid, setActiveInspectionUid] =
    React.useState<string>();

  const [activeObservationId, setActiveObservationId] =
    React.useState<string>();

  return (
    <Box flex="vertical" grow>
      <Page>
        <PageBody>
          <PageHeader title={data.job.jobId} />
          <Text size='x1'> © 2024, Drainify, All rights reserved.</Text>
          <Box flex="vertical" gap="x2">
            <OrgView org={data?.project.org} />
            <Box flex="horizontal" alignChildrenHorizontal="around">
              <Box flex="vertical" grow>
                <Text size="x1">Tel: {data?.project.org.contactNumber}</Text>
                <Text size="x1">Email: {data?.project.org.contactEmail} </Text>
              </Box>
            </Box>
            <Box flex="horizontal" alignChildrenHorizontal="between">
              <Button
                variant="tertiary"
                href={getFullFilePath(data.job.pdfReport!)}
                tag="a"
                target="_blank"
                color="accent"
                flex="horizontal"
                gap="x2"
                grow
                alignChildrenHorizontal="start"
              >
                <Icons.Download />
                pdf report
              </Button>
              <Box>
                <Button
                  variant="tertiary"
                  color="accent"
                  flex="horizontal"
                  gap="x2"
                  grow
                  alignChildrenHorizontal="start"
                  onClick={() => setDocumentsModalVisible(true)}
                >
                  Documents/videos <Icons.File />
                </Button>
              </Box>
            </Box>
          </Box>
          <Text strong>Site</Text>
          <PageMenu>
            <PageMenuItem
              backgroundColor="background-shade-3"
              icon={<Icons.Map />}
              onClick={() => setPlanModalVisible(true)}
            >
              Plan
            </PageMenuItem>
          </PageMenu>
          <Box flex="vertical" gap="x1">
            <Text strong>Sections & laterals</Text>
            {jobInspections.map((e) => (
              <Box
                onClick={() => setActiveInspectionUid(e.uid)}
                key={e.uid}
                borderRadius="10px"
                borderSize="x1"
                padding="x2"
                borderColor="background-shade-2"
                flex="horizontal"
                backgroundColor="background-shade-3"
                alignChildrenVertical="middle"
              >
                <SectionView
                  section={reportEditor.getSectionByUid(e.sectionUid)}
                  grow
                />
              </Box>
            ))}
          </Box>
        </PageBody>
      </Page>

      <Modal
        visible={activeInspectionUid !== undefined}
        onClose={() => setActiveInspectionUid(undefined)}
      >
        <ModalHeader>
          {reportEditor.getInspectionName(
            reportEditor.getInspectionByUid(activeInspectionUid)
          )}
        </ModalHeader>
        <ModalBody>
          <ObservationsList
            inspectionUid={activeInspectionUid}
            onItemClick={(id) => setActiveObservationId(id)}
          />
        </ModalBody>
      </Modal>

      <Modal
        visible={planModalVisible}
        onClose={() => setPlanModalVisible(false)}
      >
        <ModalHeader>
          <Text>{data.location && getAddressString(data.location)}</Text>
        </ModalHeader>
        <ModalBody height="100vh" width="100vw">
          <NodeLocationHack onChange={() => {}} />
        </ModalBody>
      </Modal>

      <Modal
        visible={activeObservationId !== undefined}
        onClose={() => setActiveObservationId(undefined)}
      >
        <ModalHeader>
          {reportEditor.getObservationName(
            reportEditor.getObservationByUid(activeObservationId)
          )}
        </ModalHeader>
        <ModalBody>
          <ObservationSummary
            observation={reportEditor.getObservationByUid(activeObservationId)}
          />
        </ModalBody>
      </Modal>
      <Modal
        visible={documentsModalVisible}
        onClose={() => setDocumentsModalVisible(false)}
      >
        <ModalHeader>Documents</ModalHeader>
        <ModalBody>
          <DocumentListCustomer />
        </ModalBody>
      </Modal>
    </Box>
  );
};

export default ReportViewMobile;
