import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Box, Text } from 'preshape';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import SignupCheckoutForm from '../components/Signup/SignupCheckoutForm';
import '../components/Signup/Signup.css';
import useSignupRequest from '../hooks/useSignupRequest';
import PageHeader from './desktop/Layout/PageHeader';

const SignUpPage = () => {
  const stripePromise = React.useState(() =>
    loadStripe(process.env.STRIPE_PUBLISHABLE_KEY!)
  )[0];
  const { signupUid } = useParams();
  const { query } = useSignupRequest(signupUid);
  if (query.data?.state === 'finished') {
    return (
      <Box
        alignChildren="middle"
        backgroundColor="background-shade-3"
        flex="vertical"
        alignChildrenHorizontal="middle"
        grow
      >
        <PageHeader title={'Invalid link'} />
        <Box>
          <Text>Sign up link has been used</Text>
        </Box>
        <Link to="/">Click here to go back to homepage</Link>
      </Box>
    );
  }
  return (
    <Box alignChildren="middle" backgroundColor="background-shade-3" grow>
      <Box maxWidth="500px">
        <PageHeader title={'Sign up'} />
        <Elements stripe={stripePromise}>
          <SignupCheckoutForm signupRequest={query.data} />
        </Elements>
      </Box>
    </Box>
  );
};

export default SignUpPage;
