import React from 'react';
import MemberInsightView from '../../../components/Insights/MemberInsightView';
import PageContext from '../../../components/Page/PageContext';
import useMeOrgMember from '../../../hooks/useMeOrgMember';
import Page from '../Layout/Page';
import PageBody from '../Layout/PageBody';
import PageHeader from '../Layout/PageHeader';

const UserProfilePage = () => {
  const { query } = useMeOrgMember()

  return (
    <PageContext backToText="Back" backToLink="/settings/user">
      <Page>
        <PageHeader title="My performance" />

        <PageBody>
          <MemberInsightView member={query.data} onClose={() => {}}/>
        </PageBody>
      </Page>
    </PageContext>
  );
};

export default UserProfilePage;
