import React from 'react';
import DesktopApp from '../../pages/desktop/DesktopApp';
import MobileApp from '../../pages/mobile/MobileApp';
import { isDesktop } from '../../utils/client';

export const MOBILE_APP_WIDTH = '820px';
export const FULL_SCREEN_MODAL_WIDTH = '420px';

const App = () => {
  if (isDesktop()) {
    return <DesktopApp />;
  }

  return <MobileApp />;
};

export default App;
