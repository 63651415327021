import { Box } from 'preshape';
import React from 'react';
import { ReportMapNodeRendererType } from '.';
import './css/BN.css';

const ReportMapNodeLH: ReportMapNodeRendererType = ({ locked }) => {
  return (
    <Box
      className="Icon"
      tag="svg"
      viewBox="0 0 24 24"
      height="1.75rem"
      width="1.75rem"
      stroke="white"
      fill="white"
      textColor="white"
      backgroundColor={locked ? 'text-shade-1' : 'accent-shade-5'}
      borderRadius="full"
    >
      {
        <svg id="LH" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
          <path
            className="lh-cls-1"
            d="M337.54,201.65c10.46,0,20.55,1.94,28.42,5.47,4.17,1.87,6.81,3.78,8.31,5.16-1.5,1.38-4.13,3.29-8.31,5.16-7.87,3.53-17.97,5.47-28.42,5.47s-20.55-1.94-28.42-5.47c-4.17-1.87-6.81-3.78-8.31-5.16,1.5-1.38,4.13-3.29,8.31-5.16,7.87-3.53,17.97-5.47,28.42-5.47m0-30c-37.52,0-67.94,18.19-67.94,40.63s30.42,40.63,67.94,40.63,67.94-18.19,67.94-40.63-30.42-40.63-67.94-40.63h0Z"
          />
          <path
            className="lh-cls-1"
            d="M163.46,63.59c10.46,0,20.55,1.94,28.42,5.47,4.17,1.87,6.81,3.78,8.31,5.16-1.5,1.38-4.13,3.29-8.31,5.16-7.87,3.53-17.97,5.47-28.42,5.47s-20.55-1.94-28.42-5.47c-4.17-1.87-6.81-3.78-8.31-5.16,1.5-1.38,4.13-3.29,8.31-5.16,7.87-3.53,17.97-5.47,28.42-5.47m0-30c-37.52,0-67.94,18.19-67.94,40.63s30.42,40.63,67.94,40.63,67.94-18.19,67.94-40.63-30.42-40.63-67.94-40.63h0Z"
          />
          <path
            className="lh-cls-1"
            d="M228.65,104.5s0,0,0,0l1.37,223.46h44.19v-83.97s0,0,0,0c0,0,20.85,21.77,62.95,21.77,38.67,0,64.18-21.82,64.19-21.82,0,0,0,0,0,0l-.79,120.51c.79,65.35-60.3,85.06-95.98,85.06h-114.7c-36.2,0-90.77-31.6-90.77-80.45v-87.02s0-177.54,0-177.54c0,0,20.05,20.19,62.72,20.19s66.82-20.19,66.82-20.19Z"
          />
        </svg>
      }
    </Box>
  );
};

export default ReportMapNodeLH;
