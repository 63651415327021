import { ResponseError, TranscodedVideo } from '@drainify/types';
import { useMutation, useQueryClient } from 'react-query';
import useFetch from '../components/Api/useFetch';
import { useOrgContext } from '../components/Org/OrgProvider';

const useVideoPostUpload = (projectId?: string, inspectionId?: string, useBuddy?: boolean) => {
  const queryClient = useQueryClient();
  const fetch = useFetch();
  const { org } = useOrgContext();
  const key = [`/video/${projectId}/${inspectionId}/process`, fetch];
  const params = {
    projectId: projectId as string,
    inspectionId: inspectionId as string,
  };

  const postVideupUpload = useMutation<TranscodedVideo, ResponseError>(
    key,
    () =>
      fetch('/video/:projectId/:inspectionId/postupload', {
        method: 'POST',
        params,
        body: {
          useBuddy: !!useBuddy,
          frames: [],
        },
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries([`/projects/${projectId}/report`]);
        queryClient.refetchQueries([`/orgs/${org?.uid}/transcode-usage`]);
        queryClient.refetchQueries([`/orgs/${org?.uid}/usage`]);
      },
    }
  );

  return { postVideupUpload };
};

export default useVideoPostUpload;
