import { Attributes, Button, ButtonProps, Icons, Text } from 'preshape';
import React, { PropsWithChildren } from 'react';
import ConfirmationDeleteModal from '../../utils/ConfirmationDeleteModal';
import { useHasPermission } from '../Permissions/Permissions';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';

type Props = ButtonProps & {
  nodeUid?: string;
  onDelete?: () => void;
};

const NodeDeleteButton = ({
  nodeUid,
  onDelete,
  ...rest
}: Attributes<HTMLButtonElement, PropsWithChildren<Props>>) => {
  const { removeNode } = useReportEditorContext();
  const hasUpdateProjectPermission = useHasPermission('projects:update');
  const [alertVisible, setAlertVisible] = React.useState(false);
  const { activeBooking } = useProjectContext();

  if (!hasUpdateProjectPermission || !nodeUid) {
    return null;
  }

  return (
    <>
      <Button
        variant="tertiary"
        {...rest}
        color="negative"
        gap="x2"
        onClick={() => setAlertVisible(true)}
        disabled={!!activeBooking?.completedAt}
        type="button"
      >
        <Text>Delete node</Text>
        <Icons.Trash2 size="1rem" />
      </Button>
      <ConfirmationDeleteModal
        visible={alertVisible}
        onClose={() => setAlertVisible(false)}
        action={() => {
          removeNode(nodeUid)
          onDelete?.();
        }}
        content="Are you sure you want to delete this node?"
        subheading=""
      />
    </>
  );
};

export default NodeDeleteButton;
