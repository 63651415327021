import { Box, Button, Icons } from 'preshape';
import React from 'react';
import './VideoPlayerProgressbar.css';
import { useVideoContext } from '../VideoProvider';

const VideoPlayerProgressbarPlayPauseButton = () => {
  const { playing, handlePlayPause } = useVideoContext();

  return (
    <Button
      onClick={handlePlayPause}
      variant="primary"
      color="accent"
      id="accel- "
    >
      {playing ? (
        <Box className="fill-svg">
          <Icons.Pause />
        </Box>
      ) : (
        <Box className="fill-svg">
          <Icons.Play />
        </Box>
      )}
    </Button>
  );
};

export default VideoPlayerProgressbarPlayPauseButton;
