export enum ObservationCode {
  B = 'B',
  BJ = 'BJ',
  C = 'C',
  C_MSCC5_DOMESTIC = 'C_MSCC5_DOMESTIC',
  CC = 'CC',
  CC_MSCC5_DOMESTIC = 'CC_MSCC5_DOMESTIC',
  CCJ = 'CCJ',
  CCJ_MSCC5_DOMESTIC = 'CCJ_MSCC5_DOMESTIC',
  CL = 'CL',
  CL_MSCC5_DOMESTIC = 'CL_MSCC5_DOMESTIC',
  CLJ = 'CLJ',
  CLJ_MSCC5_DOMESTIC = 'CLJ_MSCC5_DOMESTIC',
  CM = 'CM',
  CM_MSCC5_DOMESTIC = 'CM_MSCC5_DOMESTIC',
  CMJ = 'CMJ',
  CMJ_MSCC5_DOMESTIC = 'CMJ_MSCC5_DOMESTIC',
  CN = 'CN',
  CN_MSCC5_DOMESTIC = 'CN_MSCC5_DOMESTIC',
  CNC = 'CNC',
  CR = 'CR',
  CRJ = 'CRJ',
  CR_MSCC5_DOMESTIC = 'CR_MSCC5_DOMESTIC',
  CRJ_MSCC5_DOMESTIC = 'CRJ_MSCC5_DOMESTIC',
  CS = 'CS',
  CS_MSCC5_DOMESTIC = 'CS_MSCC5_DOMESTIC',
  CSJ = 'CSJ',
  CSJ_MSCC5_DOMESTIC = 'CSJ_MSCC5_DOMESTIC',
  CU = 'CU',
  CU_MSCC5_DOMESTIC = 'CU_MSCC5_DOMESTIC',
  CUW = 'CUW',
  CUW_MSCC5_DOMESTIC = 'CUW_MSCC5_DOMESTIC',
  CUWJ = 'CUWJ',
  CUD = 'CUD',
  CUDJ = 'CUDJ',
  CUS = 'CUS',
  CUSJ = 'CUSJ',
  CUZ = 'CUZ',
  CUZJ = 'CUZJ',
  CX = 'CX',
  CXI = 'CXI',
  CXP = 'CXP',
  CXPI = 'CXPI',
  CXB = 'CXB',
  CXBI = 'CXBI',
  CXD = 'CXD',
  CXDI = 'CXDI',
  CXZ = 'CXZ',
  D = 'D',
  D_MSCC5_DOMESTIC = 'D_MSCC5_DOMESTIC',
  DI = 'DI',
  DB = 'DB',
  DH = 'DH',
  DV = 'DV',
  DE = 'DE',
  DEC = 'DEC',
  DECJ = 'DECJ',
  DEE = 'DEE',
  DEEJ = 'DEEJ',
  DEF = 'DEF',
  DEFJ = 'DEFJ',
  DEG = 'DEG',
  DEGJ = 'DEGJ',
  DER = 'DER',
  DERJ = 'DERJ',
  DES = 'DES',
  DESJ = 'DESJ',
  DEX = 'DEX',
  DEXJ = 'DEXJ',
  DEZ = 'DEZ',
  DEZJ = 'DEZJ',
  EX = 'EX',
  EXJ = 'EXJ',
  F = 'F',
  F_MSCC5_DOMESTIC = 'F_MSCC5_DOMESTIC',
  FC = 'FC',
  FC_MSCC5_DOMESTIC = 'FC_MSCC5_DOMESTIC',
  FCJ = 'FCJ',
  FCJ_MSCC5_DOMESTIC = 'FCJ_MSCC5_DOMESTIC',
  FL = 'FL',
  FL_MSCC5_DOMESTIC = 'FL_MSCC5_DOMESTIC',
  FLJ = 'FLJ',
  FLJ_MSCC5_DOMESTIC = 'FLJ_MSCC5_DOMESTIC',
  FM = 'FM',
  FM_MSCC5_DOMESTIC = 'FM_MSCC5_DOMESTIC',
  FMJ = 'FMJ',
  FMJ_MSCC5_DOMESTIC = 'FMJ_MSCC5_DOMESTIC',
  FR = 'FR',
  FRJ = 'FRJ',
  FR_MSCC5_DOMESTIC = 'FR_MSCC5_DOMESTIC',
  FRJ_MSCC5_DOMESTIC = 'FRJ_MSCC5_DOMESTIC',
  FS = 'FS',
  FSJ = 'FSJ',
  FS_MSCC5_DOMESTIC = 'FS_MSCC5_DOMESTIC',
  FSJ_MSCC5_DOMESTIC = 'FSJ_MSCC5_DOMESTIC',
  FW = 'FW',
  FWC = 'FWC',
  FWCS = 'FWCS',
  FWT = 'FWT',
  FWTF = 'FWTF',
  GP = 'GP',
  GZ = 'GZ',
  H = 'H',
  HJ = 'HJ',
  HS = 'HS',
  I = 'I',
  I_MSCC5_DOMESTIC = 'I_MSCC5_DOMESTIC',
  ING = 'ING',
  INGF = 'INGF',
  INGFJ = 'INGFJ',
  INGS = 'INGS',
  INGSJ = 'INGSJ',
  INGP = 'INGP',
  INGPJ = 'INGPJ',
  INGG = 'INGG',
  INGGJ = 'INGGJ',
  INGZ = 'INGZ',
  INGZJ = 'INGZJ',
  IS = 'IS',
  ISJ = 'ISJ',
  IR = 'IR',
  IRJ = 'IRJ',
  ID = 'ID',
  IDJ = 'IDJ',
  IG = 'IG',
  IGJ = 'IGJ',
  // IJ = 'IJ', - check
  JD = 'JD',
  JD_MSCC5_DOMESTIC = 'JD_MSCC5_DOMESTIC',
  JDL = 'JDL',
  JDL_MSCC5_DOMESTIC = 'JDL_MSCC5_DOMESTIC',
  JDM = 'JDM',
  JDM_MSCC5_DOMESTIC = 'JDM_MSCC5_DOMESTIC',
  L = 'L',
  LC = 'LC',
  LC_MSCC5_DOMESTIC = 'LC_MSCC5_DOMESTIC',
  LCCF = 'LCCF',
  LCCIP = 'LCCIP',
  LCCP = 'LCCP',
  LCDP = 'LCDP',
  LCM = 'LCM',
  LCSEG = 'LCSEG',
  LCSP = 'LCSP',
  LCSW = 'LCSW',
  LCZ = 'LCZ',
  LD = 'LD',
  LDQ = 'LDQ',
  LDH = 'LDH',
  LDF = 'LDF',
  LL = 'LL',
  LLQ = 'LLQ',
  LLH = 'LLH',
  LLF = 'LLF',
  LR = 'LR',
  LRQ = 'LRQ',
  LRH = 'LRH',
  LRF = 'LRF',
  LU = 'LU',
  LUQ = 'LUQ',
  LUH = 'LUH',
  LUF = 'LUF',
  LX = 'LX',
  LX_MSCC5_DOMESTIC = 'LX_MSCC5_DOMESTIC',
  LXD = 'LXD',
  LXE = 'LXE',
  LXCZ = 'LXCZ',
  LXCX = 'LXCX',
  LXC = 'LXC',
  LXW = 'LXW',
  LXWL = 'LXWL',
  LXWC = 'LXWC',
  LXWH = 'LXWH',
  LXWM = 'LXWM',
  LXB = 'LXB',
  LXEB = 'LXEB',
  LXES = 'LXES',
  LXF = 'LXF',
  LXH = 'LXH',
  LXR = 'LXR',
  LXS = 'LXS',
  LXSC = 'LXSC',
  LXSH = 'LXSH',
  LXSL = 'LXSL',
  LXSM = 'LXSM',
  LXSF = 'LXSF',
  LXSS = 'LXSS',
  LXZ = 'LXZ',
  ME = 'ME',
  MM = 'MM',
  MMM = 'MMM',
  MMS = 'MMS',
  MMT = 'MMT',
  JN_MSCC5_DOMESTIC = 'JN_MSCC5_DOMESTIC',
  JN = 'JN',
  JNC = 'JNC',
  JNB = 'JNB',
  JX = 'JX',
  JX_MSCC5_DOMESTIC = 'JX_MSCC5_DOMESTIC',
  JXD = 'JXD',
  JXB = 'JXB',
  JXP = 'JXP',
  JXZ = 'JXZ',
  MC = 'MC',
  MC_MSCC5_DOMESTIC = 'MC_MSCC5_DOMESTIC',
  MCAC = 'MCAC',
  MCBR = 'MCBR',
  MCCI = 'MCCI',
  MCCO = 'MCCO',
  MCCS = 'MCCS',
  MCDI = 'MCDI',
  MCEP = 'MCEP',
  MCFC = 'MCFC',
  MCFRP = 'MCFRP',
  MCGCI = 'MCGCI',
  MCMAC = 'MCMAC',
  MCMAR = 'MCMAR',
  MCMX = 'MCMX',
  MCPE = 'MCPE',
  MCPF = 'MCPF',
  MCPP = 'MCPP',
  MCPS = 'MCPS',
  MCPVC = 'MCPVC',
  MCRC = 'MCRC',
  MCST = 'MCST',
  MCVC = 'MCVC',
  MCX = 'MCX',
  MCXI = 'MCXI',
  MCXP = 'MCXP',
  MCZ = 'MCZ',
  MB = 'MB',
  OB = 'OB',
  OB_MSCC5_DOMESTIC = 'OB_MSCC5_DOMESTIC',
  OBB = 'OBB',
  OBM = 'OBM',
  OBX = 'OBX',
  OBI = 'OBI',
  OBC = 'OBC',
  OBP = 'OBP',
  OBS = 'OBS',
  OBZ = 'OBZ',
  OD = 'OD',
  OJ = 'OJ',
  OJL = 'OJL',
  OJM = 'OJM',
  OJL_MSCC5_DOMESTIC = 'OJL_MSCC5_DOMESTIC',
  OJM_MSCC5_DOMESTIC = 'OJM_MSCC5_DOMESTIC',
  PC = 'PC',
  PP = 'PP',
  R = 'R',
  REM = 'REM',
  RF = 'RF',
  RFJ = 'RFJ',
  RM = 'RM',
  RMJ = 'RMJ',
  RP = 'RP',
  RPR = 'RPR',
  RPL = 'RPL',
  RPI = 'RPI',
  RPS = 'RPS',
  RPH = 'RPH',
  RPC = 'RPC',
  RPT = 'RPT',
  RPZ = 'RPZ',
  RT = 'RT',
  RTJ = 'RTJ',
  RX = 'RX',
  RXM = 'RXM',
  RXC = 'RXC',
  RXH = 'RXH',
  RXMR = 'RXMR',
  RXS = 'RXS',
  RXXM = 'RXXM',
  RXZ = 'RXZ',
  S = 'S',
  S_MSCC5_DOMESTIC = 'S_MSCC5_DOMESTIC',
  SA = 'SA',
  SC = 'SC',
  SC_MSCC5_DOMESTIC = 'SC_MSCC5_DOMESTIC',
  SCA = 'SCA',
  SCB = 'SCB',
  SCC = 'SCC',
  SCE = 'SCE',
  SCH = 'SCH',
  SCO = 'SCO',
  SCR = 'SCR',
  SCT = 'SCT',
  SCU = 'SCU',
  SCK = 'SCK',
  SCCSC = 'SCCSC',
  SCRSC = 'SCRSC',
  SCZ = 'SCZ',
  SO = 'SO',
  SR = 'SR',
  SRI = 'SRI',
  SRB = 'SRB',
  SW = 'SW',
  SS = 'SS',
  SB = 'SB',
  SAV = 'SAV',
  SAP = 'SAP',
  SRV = 'SRV',
  SRP = 'SRP',
  SRC = 'SRC',
  SCP = 'SCP',
  SV = 'SV',
  SVJ = 'SVJ',
  SZ = 'SZ',
  V = 'V',
  V_MSCC5_DOMESTIC = 'V_MSCC5_DOMESTIC',
  VR = 'VR',
  VRC = 'VRC',
  VRJ = 'VRJ',
  VZ = 'VZ',
  VV = 'VV',
  WL = 'WL',
  WL_MSCC5_DOMESTIC = 'WL_MSCC5_DOMESTIC',
  WLC = 'WLC',
  WLT = 'WLT',
  WX = 'WX',
  WXL = 'WXL',
  WXC = 'WXC',
  WXS = 'WXS',
  XP = 'XP',
  XB = 'XB',

  // PACP
  B_PACP = 'B_PACP',
  BJ_PACP = 'BJ_PACP',
  BSV_PACP = 'BSV_PACP',
  BSVJ_PACP = 'BSVJ_PACP',
  BVV_PACP = 'BVV_PACP',
  BVVJ_PACP = 'BVVJ_PACP',
  CC_PACP = 'CC_PACP',
  CCJ_PACP = 'CCJ_PACP',
  CH2_PACP = 'CH2_PACP',
  CH2J_PACP = 'CH2J_PACP',
  CH3_PACP = 'CH3_PACP',
  CH3J_PACP = 'CH3J_PACP',
  CH4_PACP = 'CH4_PACP',
  CH4J_PACP = 'CH4J_PACP',
  CL_PACP = 'CL_PACP',
  CLJ_PACP = 'CLJ_PACP',
  CM_PACP = 'CM_PACP',
  CMJ_PACP = 'CMJ_PACP',
  CS_PACP = 'CS_PACP',
  CSJ_PACP = 'CSJ_PACP',
  DAE_PACP = 'DAE_PACP',
  DAEJ_PACP = 'DAEJ_PACP',
  DAGS_PACP = 'DAGS_PACP',
  DAGSJ_PACP = 'DAGSJ_PACP',
  DAR_PACP = 'DAR_PACP',
  DARJ_PACP = 'DARJ_PACP',
  DAZ_PACP = 'DAZ_PACP',
  DAZJ_PACP = 'DAZJ_PACP',
  DFBI_PACP = 'DFBI_PACP',
  DFBR_PACP = 'DFBR_PACP',
  DFC_PACP = 'DFC_PACP',
  DFE_PACP = 'DFE_PACP',
  DI_PACP = 'DI_PACP',
  DNF_PACP = 'DNF_PACP',
  DB_PACP = 'DB_PACP',
  DNFJ_PACP = 'DNFJ_PACP',
  DNGV_PACP = 'DNGV_PACP',
  DNGVJ_PACP = 'DNGVJ_PACP',
  DNZ_PACP = 'DNZ_PACP',
  DNZJ_PACP = 'DNZJ_PACP',
  DR_PACP = 'DR_PACP',
  DSC_PACP = 'DSC_PACP',
  DSCJ_PACP = 'DSCJ_PACP',
  DSF_PACP = 'DSF_PACP',
  DSFJ_PACP = 'DSFJ_PACP',
  DSGV_PACP = 'DSGV_PACP',
  DSGVJ_PACP = 'DSGVJ_PACP',
  DSZ_PACP = 'DSZ_PACP',
  DSZJ_PACP = 'DSZJ_PACP',
  DTBI_PACP = 'DTBI_PACP',
  DTBR_PACP = 'DTBR_PACP',
  FBEMD_PACP = 'FBEMD_PACP',
  FBEML_PACP = 'FBEML_PACP',
  FBEMLD_PACP = 'FBEMLD_PACP',
  FBEMLU_PACP = 'FBEMLU_PACP',
  FBEMR_PACP = 'FBEMR_PACP',
  FBEMRD_PACP = 'FBEMRD_PACP',
  FBEMRU_PACP = 'FBEMRU_PACP',
  FBEMU_PACP = 'FBEMU_PACP',
  FBESD_PACP = 'FBESD_PACP',
  FBESL_PACP = 'FBESL_PACP',
  FBESLD_PACP = 'FBESLD_PACP',
  FBESLU_PACP = 'FBESLU_PACP',
  FBESR_PACP = 'FBESR_PACP',
  FBESRD_PACP = 'FBESRD_PACP',
  FBESRU_PACP = 'FBESRU_PACP',
  FBESU_PACP = 'FBESU_PACP',
  FC_PACP = 'FC_PACP',
  FCJ_PACP = 'FCJ_PACP',
  FCA_PACP = 'FCA_PACP',
  FCAF_PACP = 'FCAF_PACP',
  FCAS_PACP = 'FCAS_PACP',
  FH2_PACP = 'FH2_PACP',
  FH2J_PACP = 'FH2J_PACP',
  FH3_PACP = 'FH3_PACP',
  FH3J_PACP = 'FH3J_PACP',
  FH4_PACP = 'FH4_PACP',
  FH4J_PACP = 'FH4J_PACP',
  FL_PACP = 'FL_PACP',
  FLJ_PACP = 'FLJ_PACP',
  FM_PACP = 'FM_PACP',
  FMJ_PACP = 'FMJ_PACP',
  FRV_PACP = 'FRV_PACP',
  FS_PACP = 'FS_PACP',
  FSJ_PACP = 'FSJ_PACP',
  FTDS_PACP = 'FTDS_PACP',
  FTS_PACP = 'FTS_PACP',
  GRT_PACP = 'GRT_PACP',
  GTFJ_PACP = 'GTFJ_PACP',
  GTFL_PACP = 'GTFL_PACP',
  GTPJ_PACP = 'GTPJ_PACP',
  GTPL_PACP = 'GTPL_PACP',
  GTUJ_PACP = 'GTUJ_PACP',
  GTUL_PACP = 'GTUL_PACP',
  H_PACP = 'H_PACP',
  HJ_PACP = 'HJ_PACP',
  HSV_PACP = 'HSV_PACP',
  HSVJ_PACP = 'HSVJ_PACP',
  HVV_PACP = 'HVV_PACP',
  HVVJ_PACP = 'HVVJ_PACP',
  ID_PACP = 'ID_PACP',
  IDJ_PACP = 'IDJ_PACP',
  IDB_PACP = 'IDB_PACP',
  IDBJ_PACP = 'IDBJ_PACP',
  IDC_PACP = 'IDC_PACP',
  IDCJ_PACP = 'IDCJ_PACP',
  IDL_PACP = 'IDL_PACP',
  IDLJ_PACP = 'IDLJ_PACP',
  IG_PACP = 'IG_PACP',
  IGB_PACP = 'IGB_PACP',
  IGBJ_PACP = 'IGBJ_PACP',
  IGC_PACP = 'IGC_PACP',
  IGCJ_PACP = 'IGCJ_PACP',
  IGJ_PACP = 'IGJ_PACP',
  IGL_PACP = 'IGL_PACP',
  IGLJ_PACP = 'IGLJ_PACP',
  IR_PACP = 'IR_PACP',
  IRB_PACP = 'IRB_PACP',
  IRBJ_PACP = 'IRBJ_PACP',
  IRC_PACP = 'IRC_PACP',
  IRCJ_PACP = 'IRCJ_PACP',
  IRJ_PACP = 'IRJ_PACP',
  IRL_PACP = 'IRL_PACP',
  IRLJ_PACP = 'IRLJ_PACP',
  IS_PACP = 'IS_PACP',
  ISB_PACP = 'ISB_PACP',
  ISBJ_PACP = 'ISBJ_PACP',
  ISC_PACP = 'ISC_PACP',
  ISCJ_PACP = 'ISCJ_PACP',
  ISGT_PACP = 'ISGT_PACP',
  ISJ_PACP = 'ISJ_PACP',
  ISL_PACP = 'ISL_PACP',
  ISLJ_PACP = 'ISLJ_PACP',
  ISSR_PACP = 'ISSR_PACP',
  ISSRB_PACP = 'ISSRB_PACP',
  ISSRH_PACP = 'ISSRH_PACP',
  ISSRL_PACP = 'ISSRL_PACP',
  ISZ_PACP = 'ISZ_PACP',
  IW_PACP = 'IW_PACP',
  IWB_PACP = 'IWB_PACP',
  IWBJ_PACP = 'IWBJ_PACP',
  IWC_PACP = 'IWC_PACP',
  IWCJ_PACP = 'IWCJ_PACP',
  IWJ_PACP = 'IWJ_PACP',
  IWL_PACP = 'IWL_PACP',
  IWLJ_PACP = 'IWLJ_PACP',
  JAL_PACP = 'JAL_PACP',
  JAM_PACP = 'JAM_PACP',
  JAS_PACP = 'JAS_PACP',
  JOL_PACP = 'JOL_PACP',
  JOLD_PACP = 'JOLD_PACP',
  JOM_PACP = 'JOM_PACP',
  JOMD_PACP = 'JOMD_PACP',
  JOS_PACP = 'JOS_PACP',
  JOSD_PACP = 'JOSD_PACP',
  JSL_PACP = 'JSL_PACP',
  JSM_PACP = 'JSM_PACP',
  JSS_PACP = 'JSS_PACP',
  LD_PACP = 'LD_PACP',
  LFAC_PACP = 'LFAC_PACP',
  LFACJ_PACP = 'LFACJ_PACP',
  LFAS_PACP = 'LFAS_PACP',
  LFASJ_PACP = 'LFASJ_PACP',
  LFB_PACP = 'LFB_PACP',
  LFBJ_PACP = 'LFBJ_PACP',
  LFCS_PACP = 'LFCS_PACP',
  LFCSJ_PACP = 'LFCSJ_PACP',
  LFD_PACP = 'LFD_PACP',
  LFDJ_PACP = 'LFDJ_PACP',
  LFDC_PACP = 'LFDC_PACP',
  LFDCJ_PACP = 'LFDCJ_PACP',
  LFDE_PACP = 'LFDE_PACP',
  LFDEJ_PACP = 'LFDEJ_PACP',
  LFDL_PACP = 'LFDL_PACP',
  LFDLJ_PACP = 'LFDLJ_PACP',
  LFOC_PACP = 'LFOC_PACP',
  LFOCJ_PACP = 'LFOCJ_PACP',
  LFRS_PACP = 'LFRS_PACP',
  LFRSJ_PACP = 'LFRSJ_PACP',
  LFUC_PACP = 'LFUC_PACP',
  LFUCJ_PACP = 'LFUCJ_PACP',
  LFW_PACP = 'LFW_PACP',
  LFWJ_PACP = 'LFWJ_PACP',
  LFZ_PACP = 'LFZ_PACP',
  LFZJ_PACP = 'LFZJ_PACP',
  LL_PACP = 'LL_PACP',
  LLD_PACP = 'LLD_PACP',
  LLU_PACP = 'LLU_PACP',
  LR_PACP = 'LR_PACP',
  LRD_PACP = 'LRD_PACP',
  LRU_PACP = 'LRU_PACP',
  LU_PACP = 'LU_PACP',
  MCU_PACP = 'MCU_PACP',
  MGO_PACP = 'MGO_PACP',
  MGP_PACP = 'MGP_PACP',
  MJL_PACP = 'MJL_PACP',
  MLC_PACP = 'MLC_PACP',
  MMC_PACP = 'MMC_PACP',
  MMM_PACP = 'MMM_PACP',
  MML_PACP = 'MML_PACP',
  MMS_PACP = 'MMS_PACP',
  MB_PACP = 'MB_PACP',
  MSA_PACP = 'MSA_PACP',
  MSC_PACP = 'MSC_PACP',
  MWL_PACP = 'MWL_PACP',
  MWLS_PACP = 'MWLS_PACP',
  MWM_PACP = 'MWM_PACP',
  MYN_PACP = 'MYN_PACP',
  MYV_PACP = 'MYV_PACP',
  OBB_PACP = 'OBB_PACP',
  OBBJ_PACP = 'OBBJ_PACP',
  OBC_PACP = 'OBC_PACP',
  OBCJ_PACP = 'OBCJ_PACP',
  OBI_PACP = 'OBI_PACP',
  OBIJ_PACP = 'OBIJ_PACP',
  OBJ_PACP = 'OBJ_PACP',
  OBM_PACP = 'OBM_PACP',
  OBMJ_PACP = 'OBMJ_PACP',
  OBN_PACP = 'OBN_PACP',
  OBNJ_PACP = 'OBNJ_PACP',
  OBP_PACP = 'OBP_PACP',
  OBPJ_PACP = 'OBPJ_PACP',
  OBR_PACP = 'OBR_PACP',
  OBRJ_PACP = 'OBRJ_PACP',
  OBS_PACP = 'OBS_PACP',
  OBSJ_PACP = 'OBSJ_PACP',
  OBZ_PACP = 'OBZ_PACP',
  OBZJ_PACP = 'OBZJ_PACP',
  RBB_PACP = 'RBB_PACP',
  RBC_PACP = 'RBC_PACP',
  RBJ_PACP = 'RBJ_PACP',
  RBL_PACP = 'RBL_PACP',
  RFB_PACP = 'RFB_PACP',
  RFC_PACP = 'RFC_PACP',
  RFJ_PACP = 'RFJ_PACP',
  RFL_PACP = 'RFL_PACP',
  RMB_PACP = 'RMB_PACP',
  RMC_PACP = 'RMC_PACP',
  RMJ_PACP = 'RMJ_PACP',
  RML_PACP = 'RML_PACP',
  RPL_PACP = 'RPL_PACP',
  RPLJ_PACP = 'RPLJ_PACP',
  RPLD_PACP = 'RPLD_PACP',
  RPLDJ_PACP = 'RPLDJ_PACP',
  RPP_PACP = 'RPP_PACP',
  RPPJ_PACP = 'RPPJ_PACP',
  RPPD_PACP = 'RPPD_PACP',
  RPPDJ_PACP = 'RPPDJ_PACP',
  RPR_PACP = 'RPR_PACP',
  RPRJ_PACP = 'RPRJ_PACP',
  RPRD_PACP = 'RPRD_PACP',
  RPRDJ_PACP = 'RPRDJ_PACP',
  RPZ_PACP = 'RPZ_PACP',
  RPZJ_PACP = 'RPZJ_PACP',
  RPZD_PACP = 'RPZD_PACP',
  RPZDJ_PACP = 'RPZDJ_PACP',
  RTB_PACP = 'RTB_PACP',
  RTC_PACP = 'RTC_PACP',
  RTJ_PACP = 'RTJ_PACP',
  RTL_PACP = 'RTL_PACP',
  SAM_PACP = 'SAM_PACP',
  SAMJ_PACP = 'SAMJ_PACP',
  SAP_PACP = 'SAP_PACP',
  SAPJ_PACP = 'SAPJ_PACP',
  SAV_PACP = 'SAV_PACP',
  SAVJ_PACP = 'SAVJ_PACP',
  SCP_PACP = 'SCP_PACP',
  SCPJ_PACP = 'SCPJ_PACP',
  SMW_PACP = 'SMW_PACP',
  SMWJ_PACP = 'SMWJ_PACP',
  SRC_PACP = 'SRC_PACP',
  SRCJ_PACP = 'SRCJ_PACP',
  SRI_PACP = 'SRI_PACP',
  SRIJ_PACP = 'SRIJ_PACP',
  SSC_PACP = 'SSC_PAPC',
  SSCJ_PACP = 'SSCJ_PAPC',
  SRP_PACP = 'SRP_PACP',
  SRPJ_PACP = 'SRPJ_PACP',
  SRV_PACP = 'SRV_PACP',
  SRVJ_PACP = 'SRVJ_PACP',
  SSS_PACP = 'SSS_PACP',
  SSSJ_PACP = 'SSSJ_PACP',
  SZ_PACP = 'SZ_PACP',
  SZJ_PACP = 'SZJ_PACP',
  TB_PACP = 'TB_PACP',
  TBJ_PACP = 'TBJ_PACP',
  TBA_PACP = 'TBA_PACP',
  TBAJ_PACP = 'TBAJ_PACP',
  TBB_PACP = 'TBB_PACP',
  TBBJ_PACP = 'TBBJ_PACP',
  TBC_PACP = 'TBC_PACP',
  TF_PACP = 'TF_PACP',
  TFJ_PACP = 'TFJ_PACP',
  TFA_PACP = 'TFA_PACP',
  TFAJ_PACP = 'TFAJ_PACP',
  TBCJ_PACP = 'TBCJ_PACP',
  TFB_PACP = 'TFB_PACP',
  TFBJ_PACP = 'TFBJ_PACP',
  TFC_PACP = 'TFC_PACP',
  TFCJ_PACP = 'TFCJ_PACP',
  TR_PACP = 'TR_PACP',
  TRJ_PACP = 'TRJ_PACP',
  TRA_PACP = 'TRA_PACP',
  TRAJ_PACP = 'TRAJ_PACP',
  TRB_PACP = 'TRB_PACP',
  TRBJ_PACP = 'TRBJ_PACP',
  TRC_PACP = 'TRC_PACP',
  TRCJ_PACP = 'TRCJ_PACP',
  TS_PACP = 'TS_PACP',
  TSJ_PACP = 'TSJ_PACP',
  TSA_PACP = 'TSA_PACP',
  TSAJ_PACP = 'TSAJ_PACP',
  TSB_PACP = 'TSB_PACP',
  TSBJ_PACP = 'TSBJ_PACP',
  TSC_PACP = 'TSC_PACP',
  TSCJ_PACP = 'TSCJ_PACP',
  TSD_PACP = 'TSD_PACP',
  TSDJ_PACP = 'TSDJ_PACP',
  TFD_PACP = 'TFD_PACP',
  TFDJ_PACP = 'TFDJ_PACP',
  TBD_PACP = 'TBD_PACP',
  TBDJ_PACP = 'TBDJ_PACP',
  TRD_PACP = 'TRD_PACP',
  TRDJ_PACP = 'TRDJ_PACP',
  TBI_PACP = 'TBI_PACP',
  TBIJ_PACP = 'TBIJ_PACP',
  TFI_PACP = 'TFI_PACP',
  TFIJ_PACP = 'TFIJ_PACP',
  TRI_PACP = 'TRI_PACP',
  TRIJ_PACP = 'TRIJ_PACP',
  TSI_PACP = 'TSI_PACP',
  TSIJ_PACP = 'TSIJ_PACP',
  VC_PACP = 'VC_PACP',
  VCJ_PACP = 'VCJ_PACP',
  VR_PACP = 'VR_PACP',
  VRJ_PACP = 'VRJ_PACP',
  VZ_PACP = 'VZ_PACP',
  VZJ_PACP = 'VZJ_PACP',
  WFC_PACP = 'WFC_PACP',
  WFCJ_PACP = 'WFCJ_PACP',
  WFL_PACP = 'WFL_PACP',
  WFLJ_PACP = 'WFLJ_PACP',
  WFM_PACP = 'WFM_PACP',
  WFMJ_PACP = 'WFMJ_PACP',
  WFS_PACP = 'WFS_PACP',
  WFSJ_PACP = 'WFSJ_PACP',
  WFZ_PACP = 'WFZ_PACP',
  WFZJ_PACP = 'WFZJ_PACP',
  X_PACP = 'X_PACP',
}

export type ObservationContinuousState = 'start' | 'finish';

export type InspectionMarker = 'start' | 'finish';

export type ObservationPostBody = {
  attributes: Record<
    string,
    boolean | number | string | { set: boolean; value?: number }
  >;
  observationContinuousState?: ObservationContinuousState;
  linkedObservationUid?: string;
  code: ObservationCode[];
  distance?: number;
  imageUrl?: string;
  inspectionUid: string;
  remarks?: string;
  timeStamp?: number;
  inspectionMarker?: InspectionMarker;
};

export type Observation = ObservationPostBody & {
  uid: string;
  index: number;
};

export const PENDING_IMAGE_UPLOAD = 'pending.jpg';
export const PLACEHOLDER = 'placeholder.png';
export const DEFAULT_ORIGINAL_LOGO = 'original-default.jpeg';
export const DEFAULT_CROPPED_LOGO = 'cropped-default.png';

export const PROTECTED_FILES = [
  PENDING_IMAGE_UPLOAD,
  DEFAULT_ORIGINAL_LOGO,
  DEFAULT_CROPPED_LOGO,
  PLACEHOLDER,
];
