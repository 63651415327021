import { Observation, ObservationPostBody } from '@drainify/types';
import { isObservation } from '@drainify/utils';
import { Attributes, Box, Icons, LinkProps, Text } from 'preshape';
import React from 'react';
import Distance from '../DistanceInput/Distance';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import ReportErrorIndicator from '../Report/ReportErrorIndicator';
import SymbolTemplate from '../Symbol/SymbolTemplate';
import ObservationCodeIcon from './ObservationCodeIcon';
import ObservationCodeText from './ObservationCodeText';
import ObservationSymbol from './ObservationSymbol';

type Props = LinkProps & {
  observation?: Observation | ObservationPostBody;
  validate?: boolean;
  showText?: boolean;
};

const ObservationView = ({
  observation,
  validate,
  showText = true,
  ...props
}: Attributes<HTMLAnchorElement, Props>) => {
  const { reportEditor } = useReportEditorContext();
  const observationName = reportEditor.getObservationName(observation);
  const error =
    validate && isObservation(observation)
      ? reportEditor.getObservationError(observation.uid)
      : null;

  if (observation?.inspectionMarker) {
    return (
      <Text {...props} flex="horizontal" gap="x4" size="x3">
        <Box basis="0" grow minWidth="0" shrink>
          <Box alignChildrenVertical="middle" flex="horizontal" gap="x2" grow>
            <Box grow>
              <Text strong>
                {observation.inspectionMarker === 'start'
                  ? 'Start inspection'
                  : 'Finish inspection'}
              </Text>
            </Box>
          </Box>
        </Box>
        <Text textColor="text-shade-1" align="end">
          {observation?.distance !== undefined ? (
            <Distance value={observation?.distance} type="long" />
          ) : (
            <Text textColor="negative-shade-5">Missing</Text>
          )}
        </Text>

        <Box
          alignChildrenHorizontal="middle"
          flex="vertical"
          gap="x2"
          textColor="text-shade-1"
        >
          <SymbolTemplate>
            {observation.inspectionMarker === 'start' ? 'ST' : 'FN'}
          </SymbolTemplate>
        </Box>
        {
          !observation.imageUrl &&
          <Box flex='vertical' alignChildrenVertical='middle'>
            <Text>
              <Icons.CameraOff />
            </Text>
          </Box>
        }
      </Text>
    );
  }

  return (
    <Text {...props} flex="horizontal" gap="x4" size="x3">
      <Box basis="0" grow minWidth="0" shrink>
        <Box alignChildrenVertical="middle" flex="horizontal" gap="x2" grow>
          <Box>
            <ObservationCodeIcon code={observation?.code} />
          </Box>

          <Box grow>
            <Text strong>{observationName}</Text>
          </Box>

          <Text textColor="text-shade-1" align="end">
            {observation?.distance !== undefined ? (
              <Distance value={observation?.distance} type="long" />
            ) : (
              <Text textColor="negative-shade-5">Missing</Text>
            )}
          </Text>
        </Box>

        {
          showText && <ObservationCodeText code={observation?.code} ellipsis margin="x1" />
        }
      </Box>

      {validate && (
        <Box>
          <ReportErrorIndicator error={error} />
        </Box>
      )}

      <Box
        alignChildrenHorizontal="middle"
        flex="vertical"
        gap="x2"
        textColor="text-shade-1"
      >
        <ObservationSymbol code={observation?.code} />
      </Box>
        {
          !observation?.imageUrl &&
          <Box flex='vertical' alignChildrenVertical='middle'>
            <Text>
              <Icons.CameraOff />
            </Text>
          </Box>
        }
    </Text>
  );
};

export default ObservationView;
