import { getFullFilePath } from '@drainify/utils';
import { Button, ButtonProps, Text } from 'preshape';
import React from 'react';
import useBooking from '../../hooks/useBooking';
import { useProjectContext } from '../Project/ProjectProvider';
import JobPDFGeneratingButton from './JobPDFGeneratingButton';

type Props = ButtonProps;

const JobPDFGenerateButton = ({ ...rest }: Props) => {
  const { activeBooking } = useProjectContext();
  const { query } = useBooking(activeBooking?.uid);

  if (query.data?.pdfReport) {
    return (
      <Button
        href={getFullFilePath(query.data.pdfReport)}
        download="proposed_file_name"
        target="_blank"
        rel="noreferrer"
        color="positive"
        variant="primary"
        flex="horizontal"
        tag="a"
        {...rest}
      >
        <Text>pdf</Text>
      </Button>
    );
  } else if (query.data?.completedAt) {
    return <JobPDFGeneratingButton />;
  } else return null;
};

export default JobPDFGenerateButton;
