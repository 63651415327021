import { Attributes, Button, ButtonProps } from 'preshape';
import React, { PropsWithChildren } from 'react';

type Props = Attributes<HTMLButtonElement, ButtonProps & {}>;

const QuickOption = ({ children, ...rest }: PropsWithChildren<Props>) => {
  return (
    <Button {...rest} size="x2" uppercase={false}>
      {children}
    </Button>
  );
};

export default QuickOption;
