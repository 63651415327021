import { RefObject, useRef } from 'react';
import useDrag, { DragEvent, Position } from '../../hooks/useDrag';
import { useMapContext } from './Map';

type Opts = {
  onDrop: (point: GeoJSON.Point) => void;
  ref: RefObject<HTMLElement>;
  target: HTMLElement | null;
};

const useMapDragAndDrop = ({ onDrop, ref, target }: Opts) => {
  const { container, fromScreenToGeoJSONPoint } = useMapContext();
  const refContainer = useRef<HTMLElement>(document.createElement('div'));
  const refBounds = useRef<DOMRect>();

  const insertClone = (position: Position) => {
    if (ref.current) {
      refBounds.current = ref.current.getBoundingClientRect();
      refContainer.current.innerHTML = ref.current?.outerHTML;
      updateClonePosition(position);
      document.body.appendChild(refContainer.current);
    }
  };

  const updateClonePosition = ([x, y]: Position) => {
    if (refBounds.current) {
      const { height, width } = refBounds.current;

      refContainer.current.style.position = 'absolute';
      refContainer.current.style.overflow = 'hidden';
      refContainer.current.style.pointerEvents = 'none';

      refContainer.current.style.left = `${x - width * 0.5}px`;
      refContainer.current.style.top = `${y - height * 0.5}px`;
    }
  };

  const reset = () => {
    document.body.style.cursor = 'default';
    removeClone();
  };

  const removeClone = () => {
    if (refContainer.current.parentElement === document.body) {
      document.body.removeChild(refContainer.current);
    }
  };

  const handleDragStart = ({ currentAbs }: DragEvent) => {
    document.body.style.cursor = 'grabbing';
    insertClone(currentAbs);
  };

  const handleDrag = ({ currentAbs }: DragEvent) => {
    updateClonePosition(currentAbs);
  };

  const handleDragCancel = () => {
    reset();
  };

  const handleDragEnd = ({ current }: DragEvent) => {
    reset();

    const [x, y] = current;
    const point = fromScreenToGeoJSONPoint({ x, y });

    if (point) {
      onDrop(point);
    }
  };

  return useDrag(container, {
    onDrag: handleDrag,
    onDragCancel: handleDragCancel,
    onDragStart: handleDragStart,
    onDragEnd: handleDragEnd,
    target,
  });
};

export default useMapDragAndDrop;
