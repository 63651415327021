import { Org, ResponseError } from '@drainify/types';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';
import { UseQueryResult } from 'react-query';
import useMeOrg from '../../hooks/useMeOrg';
import userOrg from '../../hooks/useOrg';
import OrgModal from './OrgModal';

type Context = {
  isUpdating: boolean;
  isUpdatingFailed: boolean;
  isUpdatingSuccess: boolean;
  org?: Org;
  query: UseQueryResult<Org, ResponseError>;
  closeModal: () => void;
  edit: (stepId?: string) => void;
  update: (org: Org) => Promise<void>;
};

const OrgContext = createContext<Context>({
  isUpdating: false,
  isUpdatingFailed: false,
  isUpdatingSuccess: false,
  org: {} as unknown as Org,
  query: {} as UseQueryResult<Org, ResponseError>,
  closeModal: () => {},
  edit: () => {},
  update: () => Promise.reject(new Error('Not implemented')),
});

export const useOrgContext = () => useContext(OrgContext);

const OrgProvider = ({ children }: PropsWithChildren<{}>) => {
  const { query } = useMeOrg();
  const { update } = userOrg({ orgId: query.data?.uid }, { noQuery: true });
  const [activeEditStep, setActiveEditStep] = useState<string>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = (stepId?: string) => {
    setIsModalOpen(true);
    setActiveEditStep(stepId);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setActiveEditStep(undefined);
  };

  const handleUpdate = async (org: Org) => {
    await update.mutateAsync(org);
  };

  const context: Context = {
    isUpdating: update.isLoading,
    isUpdatingFailed: update.isError,
    isUpdatingSuccess: update.isSuccess,
    org: query.data,
    query,
    closeModal: handleCloseModal,
    edit: handleOpenModal,
    update: handleUpdate,
  };

  return (
    <OrgContext.Provider value={context}>
      {children}

      <OrgModal
        initialActiveStepId={activeEditStep}
        onClose={handleCloseModal}
        visible={isModalOpen}
      />
    </OrgContext.Provider>
  );
};

export default OrgProvider;
