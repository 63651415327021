import { ObservationCode, PENDING_IMAGE_UPLOAD } from '@drainify/types';
import {
  Box,
  Button,
  Buttons,
  Icons,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Text,
} from 'preshape';
import React from 'react';
import { generatePath, useNavigate, useParams } from 'react-router';
import { MOBILE_APP_WIDTH } from '../../../components/App/App';
import Divider from '../../../components/Divider/Divider';
import FileUpload from '../../../components/FileUpload/FileUpload';
import resizeImage from '../../../components/Image/ImageCompressor';
import InspectionAdditionalSummary from '../../../components/Inspection/InspectionAdditionalSummary';
import InspectionDeleteButton from '../../../components/Inspection/InspectionDeleteButton';
import { useInspectionContext } from '../../../components/Inspection/InspectionProvider';
import ObservationCreateButton from '../../../components/Observations/ObservationCreateButton';
import ObservationsList from '../../../components/Observations/ObservationsList';
import PageContext from '../../../components/Page/PageContext';
import PercentageInput from '../../../components/PercentageInput/PercentageInput';
import { useProjectContext } from '../../../components/Project/ProjectProvider';
import RepairTool from '../../../components/RepairTool/RepairTool';
import { useReportEditorContext } from '../../../components/Report/ReportEditorProvider';
import ReportErrorMessage from '../../../components/Report/ReportErrorMessage';
import useFileUpload from '../../../hooks/useFileUpload';
import Page from '../Layout/Page';
import PageBody from '../Layout/PageBody';
import PageHeader from '../Layout/PageHeader';
import PageMenu from '../Layout/PageMenu';
import InspectionDetailsModal from './InspectionDetailsModal';
import InspectionPostInspectionModal from './InspectionPostInspectionModal';

const InspectionPage = () => {
  const { inspection } = useInspectionContext();
  const { reportEditor } = useReportEditorContext();
  const [additionalActionsVisible, setAdditionalActionsVisible] =
    React.useState(false);
  const error = reportEditor.getInspectionError(inspection?.uid);
  const { activeBooking } = useProjectContext();
  const { editObservation, editInspection } = useReportEditorContext();
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = React.useState(false);
  const [validationModalVisible, setValidationModalVisible] =
    React.useState(false);
  const observations = reportEditor.getInspectionObservations(inspection?.uid);
  const [startInspectionModalVisible, setStartInspectionModalVisible] =
    React.useState(false);
  const [notesVisible, setNotesVisible] = React.useState(false);

  const publishAndReturnHome = async () => {
    navigate(`../../../../../snapshot?activeBookingId=${activeBooking?.uid}`);
  };
  const [finishInspectionVisible, setFinishInspectionVisible] =
    React.useState(false);

  const inspectionErrors = reportEditor.getInspectionError(inspection?.uid);

  const section = reportEditor.getInspectionSection(inspection?.uid);
  const closeValidation = () => {
    setValidationModalVisible(false);
  };
  const [initialWaterLevelPercentage, setInitialWaterLevelPercentage] =
    React.useState(0);

  const finishValidation = async () => {
    closeValidation();
    const finish = observations.find((e) => e.inspectionMarker === 'finish');
    if (finish && section) {
      reportEditor.updateObservation(finish.uid, {
        ...finish,
        distance: section.attributes.length,
      });
    }
    publishAndReturnHome();
  };

  const finishInspection = () => {
    setValidationModalVisible(true);
    if (
      !observations.find(
        (e) =>
          e.code.includes(ObservationCode.SA) || e.inspectionMarker === 'finish'
      )
    ) {
      setFinishInspectionVisible(true);
    }
  };

  const startInspection = async (file?: File) => {
    if (inspection) {
      const savedObservation = reportEditor.addObservationNoUpdate({
        attributes: {
          percentage: initialWaterLevelPercentage,
        },
        code: [ObservationCode.WL],
        distance: 0,
        imageUrl: file ? PENDING_IMAGE_UPLOAD : undefined,
        inspectionUid: inspection?.uid,
      });
      await reportEditor.updateAsync?.(reportEditor.report);

      if (file) {
        const compressedBlob = (await resizeImage({
          file,
          maxSize: 500,
        })) as Blob;
        const compressedImage = new File([compressedBlob], 'initial-wl.jpg', {
          type: compressedBlob.type,
        });

        observationFileUpload.upload(
          generatePath(
            '/projects/:projectId/report/observations/:observationId/image',
            {
              projectId: projectId,
              observationId: savedObservation.uid,
            }
          ),
          compressedImage
        );
      }
    }

    setStartInspectionModalVisible(false);
  };

  const finishInspectionSubmit = async (file: File) => {
    const section = reportEditor.getInspectionSection(inspection?.uid);
    if (inspection) {
      const savedObservation = reportEditor.addObservationNoUpdate({
        attributes: {},
        code: [ObservationCode.GP],
        distance: section?.attributes.length || 0,
        imageUrl: PENDING_IMAGE_UPLOAD,
        inspectionUid: inspection?.uid,
        inspectionMarker: 'finish',
      });

      await reportEditor.updateAsync?.(reportEditor.report);

      const compressedBlob = (await resizeImage({
        file,
        maxSize: 500,
      })) as Blob;
      const compressedImage = new File(
        [compressedBlob],
        'obervation-snapshot.jpg',
        {
          type: compressedBlob.type,
        }
      );

      observationFileUpload.upload(
        generatePath(
          '/projects/:projectId/report/observations/:observationId/image',
          {
            projectId: projectId,
            observationId: savedObservation.uid,
          }
        ),
        compressedImage
      );
    }
    setFinishInspectionVisible(false);
  };

  const observationFileUpload = useFileUpload();
  const { projectId } = useParams<'projectId'>();

  React.useEffect(() => {
    setStartInspectionModalVisible(observations.length === 0);
  }, [inspection]);

  return (
    <PageContext
      backToLink={`../../../../../snapshot?activeBookingId=${activeBooking?.uid}`}
      backToText="Inspections"
      title={reportEditor.getInspectionName(inspection) || 'Missing'}
    >
      <Page>
        <PageHeader
          action={
            <Button
              variant="tertiary"
              onClick={() => setAdditionalActionsVisible(true)}
            >
              <Icons.MoreHorizontal />
            </Button>
          }
        />

        <PageBody flex="vertical" gap="x3" overflow="auto" basis="0" grow>
          <PageMenu margin="x6">
            {error && <ReportErrorMessage error={error} margin="x4" />}
            <ObservationsList
              inspectionUid={inspection?.uid}
              onItemClick={editObservation}
              shouldValidate
            />
            <Divider />
          </PageMenu>
        </PageBody>
      </Page>
      <Buttons padding="x6" alignChildrenHorizontal="between">
        <Button
          onClick={() => inspection && setNotesVisible(true)}
          grow
          gap="x2"
          color="accent"
          size="x1"
        >
          <Text>Recs/Notes</Text>
        </Button>

        <ObservationCreateButton
          inspectionUid={inspection?.uid}
          color="accent"
          variant="primary"
          grow
        />
        <Button
          color="positive"
          variant="primary"
          gap="x2"
          onClick={finishInspection}
          disabled={inspectionErrors ? true : false}
          grow
        >
          <Text>Finish</Text>
          <Icons.Check />
        </Button>
      </Buttons>
      <InspectionDetailsModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
      />

      {inspection && validationModalVisible && (
        <InspectionPostInspectionModal
          visible={validationModalVisible}
          onClose={publishAndReturnHome}
          onFinish={finishValidation}
          sectionUid={inspection.sectionUid}
        />
      )}

      <Modal
        animation="FadeSlideUp"
        margin="x4"
        onClose={() => setStartInspectionModalVisible(false)}
        visible={startInspectionModalVisible}
        maxWidth={MOBILE_APP_WIDTH}
      >
        <ModalHeader>
          <ModalTitle>Start inspection (Water level)</ModalTitle>
        </ModalHeader>

        <ModalBody alignChildrenVertical="middle">
          <PercentageInput
            name={'water level'}
            onChange={setInitialWaterLevelPercentage}
            value={initialWaterLevelPercentage}
          />
        </ModalBody>
        <ModalFooter>
          <Box flex="vertical" gap="x2" alignChildrenHorizontal="middle">
            <Buttons>
              <FileUpload
                variant="primary"
                color="accent"
                elevate
                accept="image/png, image/jpeg"
                onChange={startInspection}
              >
                <Icons.Camera />
                <Text
                  style={{
                    WebkitUserSelect: 'none',
                    msUserSelect: 'none',
                    userSelect: 'none',
                    WebkitTouchCallout: 'none',
                    KhtmlUserSelect: 'none',
                    MozUserSelect: 'none',
                  }}
                >
                  Add photo
                </Text>
              </FileUpload>
              <Button onClick={() => startInspection()}>
                <Icons.CameraOff />

                <Text
                  style={{
                    WebkitUserSelect: 'none',
                    msUserSelect: 'none',
                    userSelect: 'none',
                    WebkitTouchCallout: 'none',
                    KhtmlUserSelect: 'none',
                    MozUserSelect: 'none',
                  }}
                >
                  No photo
                </Text>
              </Button>
            </Buttons>
            <Buttons>
              <Button
                color="negative"
                variant="secondary"
                onClick={() => {
                  if (inspection) {
                    reportEditor.removeInspection(inspection?.uid);
                    publishAndReturnHome();
                  }
                }}
                gap="x1"
              >
                <Icons.AlertCircle />
                <Text
                  style={{
                    WebkitUserSelect: 'none',
                    msUserSelect: 'none',
                    userSelect: 'none',
                    WebkitTouchCallout: 'none',
                    KhtmlUserSelect: 'none',
                    MozUserSelect: 'none',
                  }}
                >
                  No inspection possible/required
                </Text>
              </Button>
            </Buttons>
          </Box>
        </ModalFooter>
      </Modal>

      <Modal
        animation="FadeSlideUp"
        margin="x4"
        onClose={() => setFinishInspectionVisible(false)}
        visible={finishInspectionVisible}
      >
        <ModalHeader>
          <ModalTitle>Finish inspection</ModalTitle>
        </ModalHeader>

        <ModalBody>
          <Buttons>
            <FileUpload
              variant="primary"
              color="accent"
              elevate
              accept="image/png, image/jpeg"
              onChange={finishInspectionSubmit}
            >
              <Icons.Camera />
              <Text>Finish image</Text>
            </FileUpload>
            <Button
              onClick={() => {
                setFinishInspectionVisible(false);
              }}
            >
              <Icons.CameraOff />
              <Text>No finish image</Text>
            </Button>
          </Buttons>
        </ModalBody>
      </Modal>
      <Modal
        animation="FadeSlideUp"
        margin="x4"
        onClose={() => setAdditionalActionsVisible(false)}
        visible={additionalActionsVisible}
      >
        <ModalHeader>
          <ModalTitle>Inspection</ModalTitle>
        </ModalHeader>

        <ModalBody flex="vertical" gap="x12" alignChildrenVertical="middle">
          <InspectionAdditionalSummary inspection={inspection} />
          <InspectionDeleteButton
            variant="secondary"
            color="negative"
            inspectionUid={inspection?.uid}
            onDelete={publishAndReturnHome}
          />
        </ModalBody>
      </Modal>
      <Modal visible={notesVisible} onClose={() => setNotesVisible(false)}>
        <ModalHeader>Notes</ModalHeader>
        <ModalBody>
          <Box flex="vertical" gap="x3">
            <Box
              backgroundColor="background-shade-2"
              padding="x2"
              flex="vertical"
              borderRadius="10px"
              gap="x2"
            >
              <Text strong size="x4">
                Private notes
              </Text>
              <Text size="x2">Use this for private information</Text>

              <Button
                onClick={() =>
                  inspection && editInspection(inspection?.uid, 'notes')
                }
                grow
                gap="x2"
                color="accent"
              >
                <Text>Private Notes</Text>
                <Icons.Edit2 />
              </Button>
            </Box>

            <Box
              backgroundColor="background-shade-2"
              padding="x2"
              flex="vertical"
              borderRadius="10px"
              gap="x2"
            >
              <Text strong size="x4">
                Reccomendations
              </Text>
              {reportEditor.getInspectionByUid(inspection?.uid)?.publicNotes ? (
                reportEditor
                  .getInspectionByUid(inspection?.uid)
                  ?.publicNotes?.split('\n')
                  .map((e) => (
                    <Text
                      key={e}
                      textColor="text-shade-3"
                      size="x3"
                      strong
                      wrap
                    >
                      {e}
                    </Text>
                  ))
              ) : (
                <Text textColor="text-shade-4" align="middle" strong size="x2">
                  No notes yet
                </Text>
              )}
              <Button
                onClick={() =>
                  inspection && editInspection(inspection?.uid, 'publicNotes')
                }
                grow
                gap="x2"
                color="accent"
              >
                <Text>edit</Text>
                <Icons.Edit2 />
              </Button>
              <RepairTool inspection={inspection} />
            </Box>
          </Box>
        </ModalBody>
      </Modal>
    </PageContext>
  );
};

export default InspectionPage;
