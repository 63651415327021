import { Attributes, Button, ButtonProps, Icons, Text } from 'preshape';
import React from 'react';

type Props = ButtonProps & {
  showAssigneeList?: boolean;
  showBookingsList?: boolean;
};

const CardEditButton = (props: Attributes<HTMLButtonElement, Props>) => {
  return (
    <Button {...props} color="accent" gap="x2" variant="tertiary">
      <Text>Edit</Text>
      <Icons.Edit2 />
    </Button>
  );
};

export default CardEditButton;
