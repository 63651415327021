"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromPlaceResultToLocation = void 0;
const fromPlaceResultToLocation = (place) => {
    const { address_components, geometry } = place;
    if (!address_components || !geometry || !geometry.location) {
        throw new Error('Invalid place format');
    }
    const point = {
        type: 'Point',
        coordinates: [geometry.location.lng(), geometry.location.lat()],
    };
    const address = {
        company: '',
        streetNumber: '',
        street: '',
        town: '',
        county: '',
        country: '',
        postcode: '',
    };
    for (const component of address_components) {
        const componentType = component.types[0];
        switch (componentType) {
            case 'street_number': {
                address.streetNumber = component.long_name;
                break;
            }
            case 'route': {
                address.street = component.long_name;
                break;
            }
            case 'postal_code': {
                address.postcode = `${component.long_name}${address.postcode}`;
                break;
            }
            case 'postal_code_suffix': {
                address.postcode = `${address.postcode}-${component.long_name}`;
                break;
            }
            case 'administrative_area_level_2': {
                address.county = component.long_name;
                break;
            }
            case 'postal_town': {
                address.town = component.long_name;
                break;
            }
            case 'country': {
                address.country = component.long_name;
                break;
            }
        }
    }
    return {
        address,
        point,
    };
};
exports.fromPlaceResultToLocation = fromPlaceResultToLocation;
