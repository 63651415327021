export type Address = {
  company: string;
  streetNumber: string;
  street: string;
  town: string;
  county: string;
  country: string;
  postcode: string;
};

export type Location = {
  address: Address;
  point: GeoJSON.Point;
};
