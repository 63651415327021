import { WithTracking } from './Api';
import { Booking } from './Booking';
import { EmailType } from './Email';

export enum EmailStatusCodes {
  PENDING = 'pending',
  SUCCESS = 'success',
  FAILED = 'failed',
}

export interface EmailStatus extends WithTracking {
  uid?: string;
  status?: EmailStatusCodes;
  sent?: string;
  recipient: string;
  type: EmailType;
  job: Booking;
}
