import { Member } from '@drainify/types';
import { getFullFilePath } from '@drainify/utils';
import {
  Appear,
  Box,
  BoxProps,
  Button,
  Icons,
  Label,
  Table,
  TableCell,
  TableRow,
  Text,
  TextProps,
} from 'preshape';
import React, { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import useSurveyReviewsMember from '../../hooks/useSurveyReviewsMember';
import Avatar from '../Avatar/Avatar';
import DataTable from '../DataTable/DataTable';
import DataTableItem from '../DataTable/DataTableItem';
import Droplet from '../IconsMisc/Droplet';
import PlaceholderAvatar from '../Placeholders/PlaceholderAvatar';
import PlaceholderBlock from '../Placeholders/PlaceholderBlock';

type Props = TextProps & {
  member?: Member;
  onClose: () => void;
};

const MemberInsightView = ({
  member,
  onClose,
}: PropsWithChildren<BoxProps & Props>) => {
  const { query } = useSurveyReviewsMember(member?.uid);
  const [expanded, setExpanded] = React.useState(false);
  const navigate = useNavigate();

  const sum =
    query.data
      ?.map((num) => num.rating)
      .reduce((acc, curr) => (acc || 0) + (curr || 0), 0) || 0;
  const average = sum / (query.data?.length || 1);

  return (
    <>
      <DataTable>
        <DataTableItem
          alignChildrenVertical="start"
          flex="horizontal"
          gap="x4"
          onClick={() => setExpanded(!expanded)}
        >
          <Box
            alignChildrenVertical="middle"
            flex="horizontal"
            gap="x4"
            grow
            maxWidth="100%"
          >
            <Box>
              {member ? (
                <Avatar
                  src={getFullFilePath(member.user.avatarUrlCropped)}
                  uid={member.user.uid}
                  size="3rem"
                />
              ) : (
                <PlaceholderAvatar size="3rem" />
              )}
            </Box>

            <Box basis="0" grow overflow="hidden">
              {member?.user ? (
                <>
                  <Text size="x3">{member.user.fullName}</Text>
                  <Text size="x3" textColor="text-shade-4">
                    {member.user.email}
                  </Text>
                  <Text size="x3" textColor="text-shade-4">
                    {member.user.phoneNumber}
                  </Text>
                </>
              ) : (
                <>
                  <PlaceholderBlock />
                  <PlaceholderBlock />
                </>
              )}
            </Box>
            <Box>
              <Text strong>Total QA: </Text>
              <Text>{query.data?.length || 0} </Text>
            </Box>
            <Box>
              <Text strong>Average: </Text>
              <Text>{average.toFixed(2)} </Text>
            </Box>
            {expanded ? <Icons.ChevronUp /> : <Icons.ChevronDown />}
          </Box>
        </DataTableItem>
      </DataTable>
      {expanded && (
        <Appear animation="FadeSlideDown">
          <Table>
            {query.data?.map((e) => (
              <TableRow key={e.uid}>
                <TableCell>
                  <Label> {e.booking?.jobId} </Label>
                </TableCell>
                <TableCell>
                  <Box flex="horizontal" alignChildrenHorizontal="middle">
                    <Droplet
                      height={20}
                      fill={
                        e.rating! >= 1 ? 'var(--color-accent-shade-5)' : 'gray'
                      }
                    />
                    <Droplet
                      height={20}
                      fill={
                        e.rating! >= 2 ? 'var(--color-accent-shade-5)' : 'gray'
                      }
                    />
                    <Droplet
                      height={20}
                      fill={
                        e.rating! >= 3 ? 'var(--color-accent-shade-5)' : 'gray'
                      }
                    />
                    <Droplet
                      height={20}
                      fill={
                        e.rating! >= 4 ? 'var(--color-accent-shade-5)' : 'gray'
                      }
                    />
                    <Droplet
                      height={20}
                      fill={
                        e.rating! >= 5 ? 'var(--color-accent-shade-5)' : 'gray'
                      }
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box flex="horizontal" alignChildrenHorizontal="end">
                    <Button
                      color="accent"
                      variant="tertiary"
                      onClick={() => {
                        navigate(
                          `/projects/${e.project?.uid}?activeBookingId=${e.booking?.uid}`
                        );
                        onClose();
                      }}
                    >
                      Go to job <Icons.ChevronRight />
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </Appear>
      )}
    </>
  );
};

export default MemberInsightView;
