import {
  Observation,
  Node,
  Section,
  Inspection,
  Plan,
  Measurement,
  Scale,
  Drawing,
} from '@drainify/types';
import {
  getSectionPoints,
  isBendySection,
  isClosed,
  ReportElementType,
} from '@drainify/utils';
import getBbox from '@turf/bbox';
import { Link, Text } from 'preshape';
import React, { useEffect, useMemo, useRef } from 'react';
import { useMapContext } from '../../Map/Map';
import { useMapLayersContext } from '../../Map/MapLayers/MapLayers';
import MapNotification from '../../Map/MapNotification/MapNotification';
import { useGeometryStoreContext } from '../../Map/useGeometryStore/GeometryStore';
import { useProjectContext } from '../../Project/ProjectProvider';
import { useReportEditorContext } from '../ReportEditorProvider';
import { useReportMapContext } from './ReportMapProvider';

type Props = {
  withNotification?: boolean;
};

const ReportMapFocusOnElement = ({ withNotification }: Props) => {
  const { fitToBounds } = useMapContext();
  const { hideAllLayers, showLayers, showAllLayers } = useMapLayersContext();
  const { focusedElementType, focusedElementUid, unfocus } =
    useReportMapContext();
  const { reportEditor } = useReportEditorContext();
  const { store } = useGeometryStoreContext();
  const { activeBookingId } = useProjectContext();

  const refPreviousElement = useRef<
    | Node
    | Observation
    | Section
    | Inspection
    | Plan
    | Measurement
    | Drawing
    | Scale
    | null
  >();
  const refPreviousType = useRef<ReportElementType>();
  const refPreviousUid = useRef<string>();

  const elementName = useMemo(() => {
    switch (focusedElementType) {
      case ReportElementType.NODE:
        return reportEditor.getNodeName(
          reportEditor.getNodeByUid(focusedElementUid)
        );
      case ReportElementType.OBSERVATION:
        return reportEditor.getObservationName(
          reportEditor.getObservationByUid(focusedElementUid)
        );
      case ReportElementType.SECTION:
        return reportEditor.getSectionName(
          reportEditor.getSectionByUid(focusedElementUid)
        );
      case ReportElementType.INSPECTION:
        return reportEditor.getInspectionName(
          reportEditor.getInspectionByUid(focusedElementUid)
        );
      case ReportElementType.PLAN:
        return reportEditor.report.plan?.name;
      case ReportElementType.SCALE:
        return 'scale';
      case ReportElementType.MEASUREMENT:
        return reportEditor.getMeasurementName(
          reportEditor.getMeasurementByUid(focusedElementUid)
        );
      case ReportElementType.DRAWING:
        return (
          reportEditor.getDrawingByUid(focusedElementUid)?.name ||
          'Unnamed drawing'
        );
      default:
        return null;
    }
  }, [focusedElementType, focusedElementUid, reportEditor]);

  const element = useMemo(() => {
    switch (focusedElementType) {
      case ReportElementType.NODE:
        return reportEditor.getNodeByUid(focusedElementUid);
      case ReportElementType.OBSERVATION:
        return reportEditor.getObservationByUid(focusedElementUid);
      case ReportElementType.SECTION:
        return reportEditor.getSectionByUid(focusedElementUid);
      case ReportElementType.INSPECTION:
        return reportEditor.getInspectionByUid(focusedElementUid);
      case ReportElementType.PLAN:
        return reportEditor.report.plan;
      case ReportElementType.MEASUREMENT:
        return reportEditor.getMeasurementByUid(focusedElementUid);
      case ReportElementType.DRAWING:
        return reportEditor.getDrawingByUid(focusedElementUid);
      case ReportElementType.SCALE:
        return reportEditor.report.scale;
      default:
        return null;
    }
  }, [focusedElementType, focusedElementUid, reportEditor]);

  const bbox = useMemo(() => {
    switch (focusedElementType) {
      case ReportElementType.NODE:
        return reportEditor.getNodePoints(focusedElementUid).bbox;
      case ReportElementType.OBSERVATION:
        return reportEditor.getObservationPoints(focusedElementUid).bbox;
      case ReportElementType.SECTION:
        const section = reportEditor.getSectionByUid(focusedElementUid);
        if (
          isBendySection(section) &&
          section?.nodeStartUid &&
          section.nodeEndUid
        ) {
          const bendyGeo: GeoJSON.MultiLineString = {
            type: 'MultiLineString',
            coordinates: [
              [
                reportEditor.getNodeByUid(section?.nodeStartUid)!.point
                  ?.coordinates!,
              ],
              ...section.additionalPoints!.map((e) => [e.point.coordinates]),
              [
                reportEditor.getNodeByUid(section?.nodeEndUid)!.point
                  ?.coordinates!,
              ],
            ],
          };
          return getBbox(bendyGeo) || null;
        } else {
          return reportEditor.getSectionPoints(focusedElementUid).bbox;
        }
      case ReportElementType.INSPECTION:
        const inspectionSection =
          reportEditor.getInspectionSection(focusedElementUid);
        if (
          isBendySection(inspectionSection) &&
          inspectionSection?.nodeStartUid &&
          inspectionSection.nodeEndUid
        ) {
          const bendyGeo: GeoJSON.MultiLineString = {
            type: 'MultiLineString',
            coordinates: [
              [
                reportEditor.getNodeByUid(inspectionSection?.nodeStartUid)!
                  .point?.coordinates!,
              ],
              ...inspectionSection.additionalPoints!.map((e) => [
                e.point.coordinates,
              ]),
              [
                reportEditor.getNodeByUid(inspectionSection?.nodeEndUid)!.point
                  ?.coordinates!,
              ],
            ],
          };
          return getBbox(bendyGeo) || null;
        } else {
          return reportEditor.getInspectionPoints(focusedElementUid).bbox;
        }
      case ReportElementType.PLAN:
        return reportEditor.getPlanPoints();
      case ReportElementType.MEASUREMENT:
        const measurement = reportEditor.getMeasurementByUid(focusedElementUid);
        if (!measurement) {
          return null;
        }
        const measurementBB: GeoJSON.MultiLineString = {
          type: 'MultiLineString',
          coordinates: measurement.points.map((e) => [e.coordinates])!,
        };
        return getBbox(measurementBB) || null;
      case ReportElementType.DRAWING:
        // TODO - get the current BBOX, or somehow ignore the focus
        const drawing = reportEditor.getDrawingByUid(focusedElementUid);
        if (!drawing) {
          return null;
        }
        const drawingBB: GeoJSON.MultiLineString = {
          type: 'MultiLineString',
          coordinates: drawing.points.map((e) => [e.point.coordinates])!,
        };
        return getBbox(drawingBB) || null;
      case ReportElementType.SCALE:
        return getSectionPoints(
          reportEditor.report.scale?.startPoint,
          reportEditor.report.scale?.endPoint
        ).bbox;
      default:
        return null;
    }
  }, [focusedElementType, focusedElementUid, reportEditor]);

  const linkedElements = useMemo(() => {
    switch (focusedElementType) {
      case ReportElementType.NODE:
        return reportEditor.getNodeElements(focusedElementUid);
      case ReportElementType.OBSERVATION:
        return reportEditor.getObservationElements(focusedElementUid);
      case ReportElementType.SECTION:
        return reportEditor.getSectionElements(
          focusedElementUid,
          activeBookingId
        );
      case ReportElementType.INSPECTION:
        return reportEditor.getInspectionElements(focusedElementUid);
      case ReportElementType.PLAN:
        return [[ReportElementType.PLAN, focusedElementUid]].filter(
          (e): e is [ReportElementType, string] => !!e[1]
        );
      case ReportElementType.MEASUREMENT:
        return [
          [ReportElementType.MEASUREMENT, focusedElementUid],
          ...reportEditor.report.sections.map((e) => [
            ReportElementType.SECTION,
            e.uid,
          ]),
        ].filter((e): e is [ReportElementType, string] => !!e[1]);
      case ReportElementType.DRAWING:
        return [[ReportElementType.DRAWING, focusedElementUid]].filter(
          (e): e is [ReportElementType, string] => !!e[1]
        );
      case ReportElementType.SCALE:
        return [];
    }
  }, [focusedElementType, focusedElementUid, reportEditor, activeBookingId]);

  // Change the maps focus
  useEffect(() => {
    const hasInputsChanged =
      focusedElementType !== refPreviousType.current ||
      focusedElementUid !== refPreviousUid.current;

    if (refPreviousElement.current && !element) {
      unfocus();
    } else if (element) {
      refPreviousElement.current = element;

      // Chore: Update this to a "should not focus" function
      if(focusedElementType === ReportElementType.DRAWING) {
        const drawing = reportEditor.getDrawingByUid(focusedElementUid);
        if(drawing && !isClosed(drawing)) {
          return;
        }
      }

      if (hasInputsChanged) {
        if (bbox && fitToBounds({ bbox })) {
          refPreviousType.current = focusedElementType;
          refPreviousUid.current = focusedElementUid;
        } else if (!bbox && store.bbox) {
          fitToBounds({
            bbox: store.bbox,
          });
        }
      }
    }
  }, [element, bbox, fitToBounds, store.bbox]);

  // Change what layers are visible
  useEffect(() => {
    if (linkedElements?.length) {
      hideAllLayers();
      showLayers(...linkedElements);
    }

    return () => {
      showAllLayers();
    };
  }, [linkedElements]);

  if (!withNotification || !elementName) {
    return null;
  }

  if (!bbox) {
    return (
      <MapNotification color="negative-shade-4" typePosition="bottom-right">
        <Text size="x2">
          You are currently focusing on{' '}
          <Text inline strong>
            {elementName}
          </Text>{' '}
          which has no location .{' '}
          <Link isTextLink onClick={() => unfocus()}>
            Click here
          </Link>{' '}
          to remove focus.
        </Text>
      </MapNotification>
    );
  }

  return (
    <MapNotification color="accent-shade-4" typePosition="bottom-right">
      <Text size="x2">
        You are currently focusing on{' '}
        <Text inline strong>
          {elementName}
        </Text>
        .{' '}
        <Link isTextLink onClick={() => unfocus()}>
          Click here
        </Link>{' '}
        to remove focus.
      </Text>
    </MapNotification>
  );
};

export default ReportMapFocusOnElement;
