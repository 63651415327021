import { BoxProps } from 'preshape';
import React, { PropsWithChildren } from 'react';
import getAggregatedQueryState, {
  Query,
} from '../../utils/getAggregatedQueryState';
import PlaceholderGradientAnimation from '../Placeholders/PlaceholderGradientAnimation';
import DataTable from './DataTable';
import DataTableGroup from './DataTableGroup';
import DataTableItem from './DataTableItem';
import DataTableMessage from './DataTableMessage';

type Props = BoxProps & {
  entity: string;
  placeholder: JSX.Element;
  query: Query | Query[];
};

const DataQueryTable = ({
  children,
  entity,
  placeholder,
  query,
  ...rest
}: PropsWithChildren<Props>) => {
  const { hasData, isLoading, isLoadingError } = getAggregatedQueryState(query);

  if (isLoading) {
    return (
      <PlaceholderGradientAnimation animating={isLoading}>
        <DataTable>
          <DataTableGroup>
            {Array.from({ length: 5 }).map((_, i) => (
              <DataTableItem key={i}>{placeholder}</DataTableItem>
            ))}
          </DataTableGroup>
        </DataTable>
      </PlaceholderGradientAnimation>
    );
  }

  if (isLoadingError) {
    return (
      <DataTable>
        <DataTableGroup>
          <DataTableItem>
            <DataTableMessage textColor="negative-shade-4">
              Failed to get {entity}
            </DataTableMessage>
          </DataTableItem>
        </DataTableGroup>
      </DataTable>
    );
  }

  if (!hasData) {
    return (
      <DataTable>
        <DataTableGroup>
          <DataTableItem>
            <DataTableMessage>No {entity} found</DataTableMessage>
          </DataTableItem>
        </DataTableGroup>
      </DataTable>
    );
  }

  return (
    <PlaceholderGradientAnimation {...rest}>
      <DataTable>{children}</DataTable>
    </PlaceholderGradientAnimation>
  );
};

export default DataQueryTable;
