import { Appear, AppearProps } from 'preshape';
import React, { PropsWithChildren } from 'react';
import { NO_DROP_PROPS } from '../../../hooks/useDrag';

type Props = AppearProps & {};

const MapSidebar = ({ children, ...rest }: PropsWithChildren<Props>) => {
  return (
    <Appear
      {...rest}
      {...NO_DROP_PROPS}
      animation="FadeSlideLeft"
      backgroundColor="background-shade-1"
      borderColor="background-shade-3"
      borderSize="x1"
      flex="horizontal"
      grow
      overflow="hidden"
      textColor="text-shade-1"
    >
      {children}
    </Appear>
  );
};

export default MapSidebar;
