import { ButtonProps, Icons, Text } from 'preshape';
import React, { PropsWithChildren, useState } from 'react';
import useProjectBookings from '../../hooks/useProjectBookings';
import AsyncButton from '../AsyncButton/AsyncButton';
import { useHasPermission } from '../Permissions/Permissions';
import { useProjectContext } from '../Project/ProjectProvider';
import BookingModal from './BookingModal';

type Props = ButtonProps & {};

const BookingCreateButton = ({ ...rest }: PropsWithChildren<Props>) => {
  const [visible, setVisible] = useState(false);
  const { project } = useProjectContext();
  const { create } = useProjectBookings(project?.uid);
  const hasCreateBookingPermission = useHasPermission('bookings:create');

  if (!hasCreateBookingPermission) {
    return null;
  }

  return (
    <>
      <AsyncButton
        variant="tertiary"
        {...rest}
        color="accent"
        isError={create.isError}
        isLoading={create.isLoading}
        isSuccess={create.isSuccess}
        onClick={() => setVisible(true)}
        type="button"
      >
        <Text>Add job</Text>
        <Icons.Plus size="1rem" />
      </AsyncButton>

      <BookingModal
        onClose={() => setVisible(false)}
        onDone={create}
        visible={visible}
      />
    </>
  );
};

export default BookingCreateButton;
