import { WithTracking } from './Api';
import { Location } from './Location';
import { Project } from './Project';
import { User } from './User';
import { Org } from '.';

export type Booking = WithTracking & {
  uid: string;
  jobId?: string;
  org: Org;
  project: Project;
  assignedTo?: User[];
  bookedForDate: string;
  bookedForTimeStart: [number, number];
  submittedAt?: Date | null;
  completedAt?: Date | null;
  jobPurpose?: JobPurpose;
  parentJobId?: string;
  childJobId?: string;
  projectType?: ProjectType;
  clientJobRef?: string;
  attributes: {
    inspectionStage?: InspectionStage;
    clientDefinedOne?: string;
    clientDefinedTwo?: string;
    clientDefinedThree?: string;
    clientDefinedFour?: string;
    clientDefinedFive?: string;
    clientDefinedSix?: string;
  };
  location?: Location;
  customerName?: string;
  searchableAddress?: string;
  pdfReport?: string | null;
};

export enum JobPurpose {
  BUILD_OVER = 'Build over',
  GENERAL = 'General',
  PRE_PURCHASE = 'Pre Purchase',
  INVESTIGATION_OF_KNOWN_STRUCTURAL_OR_SERVICE_DEFECTS = 'Investigation of known structural or service defects',
  INVESTIGATION_OF_INFILTRATION_PROBLEMS = 'Investigation of infiltration problems',
  POST_COMPLETION_INSPECTION_OF_REPAIRS_OR_RENOVATIONS = 'Post-completion inspection of repairs or renovations',
  PRE_ADOPTION_SURVEY_PRIOR_TO_VESTING_AS_PUBLIC_SEWER = 'Pre-adoption survey prior to vesting as public sewer',
  POST_COMPLETION_INSPECTION_OF_NEW_SEWERS = 'Post-completion inspection of new sewers',
  SAMPLE_SURVEY_OF_SEWERS_TO_DETERMINE_ASSET_CONDITIOR = 'Sample survey of sewers to determine asset conditior',
  ROUTINE_INSPECTION_OF_CONDITION = 'Routine inspection of condition',
  INVESTIGATION_OF_A_SUSPECTED_OPERATIONAL_PROBLEM = 'Investigation of a suspected operational problem',
  INVESTMENT_PLANNING = 'Investment planning',
  END_OF_WARRANTY_PERIOD = 'End of warranty period',
  OTHER = 'Other',
}

export enum ProjectType {
  MSCC5_COMMERERCIAL = 'MSCC5_COMMERERCIAL',
  MSCC5_DOMESTIC = 'MSCC5_DOMESTIC',
  PACP = 'PACP',
  LACP = 'LACP',
}

export enum InspectionStage {
  INFORMATION_PROVIDED_BY_CLIENT = 'Information provided by client',
  INFORMATION_PROVIDED_BY_INSPECTOR_TO_CLIENT = 'Information provided by inspector to client',
  OTHER = 'Other',
}

export type BookingPostBody = Omit<Booking, 'uid' | 'project' | 'org'>;
