import { runValidations } from '@drainify/utils';
import { useForm } from 'preshape';
import { useCallback, useMemo } from 'react';
import * as yup from 'yup';

export type CreateAccountState = {
  organisation: string;
  description: string;
  password: string;
  confirmPassword: string;
  subscriptionTier?: string;
};

const validations = yup.object().shape({
  confirmEmail: yup
    .string()
    .email()
    .required('Please re-enter your email')
    .when(['email'], (email) => {
      if (email) {
        return yup
          .string()
          .test(
            'Email does not match',
            'Email does not match',
            (e) => e === email
          );
      } else return yup.string();
    }),
  password: yup
    .string()
    .required('Please enter a password')
    .test(
      'password not strong enough',
      'Password must have at least 1 uppercase, 1 lowercase, 1 special character and be at least 8 character long',
      (e) =>
        e !== undefined &&
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/.test(e)
    ),
  confirmPassword: yup
    .string()
    .required('Please re-enter your password')
    .when(['password'], (password) => {
      if (password) {
        return yup
          .string()
          .test(
            'Passwords do not match',
            'Passwords do not match',
            (e) => e === password
          )
          .test(
            'password not strong enough',
            'Password must have at least 1 uppercase, 1 lowercase, 1 special character and be at least 8 character long',
            () =>
              /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/.test(
                password
              )
          );
      } else return yup.string().required();
    }),
  subscriptionTier: yup.string().required(),
});

const useCreateAccountForm = () => {
  const initial = useMemo<CreateAccountState>(
    () => ({
      organisation: '',
      description: '',
      password: '',
      confirmPassword: '',
    }),
    []
  );

  const form = useForm({
    initial: initial,
    validate: useCallback((value, setError) => {
      setError(runValidations(validations, value));
    }, []),
  });

  return { form };
};

export default useCreateAccountForm;
