import React from 'react';
import { useNavigate } from 'react-router-dom';
import GeometryStore from '../../../components/Map/useGeometryStore/GeometryStore';
import GeometryStoreFocusEverything from '../../../components/Map/useGeometryStore/GeometryStoreFocusOnEverything';
import NodeMapUserMarker from '../../../components/Node/NodeMapUserMarker';
import ProjectMapMarker from '../../../components/Project/ProjectMapMarker';
import useMeOrg from '../../../hooks/useMeOrg';
import useOrgBookingsWithState from '../../../hooks/useOrgBookingsWithState';

const ProjectsPage = () => {
  const { query: queryMeOrg } = useMeOrg();

  const { query: queryBookings } = useOrgBookingsWithState(
    queryMeOrg.data?.uid,
    'dispatched'
  );
  const navigate = useNavigate();

  return (
    <GeometryStore>
      <NodeMapUserMarker />
      {queryBookings.data?.data.map((booking) => (
        <ProjectMapMarker
          onClick={() =>
            navigate(
              `/projects/${booking.project.uid}?activeBookingId=${booking.uid}`
            )
          }
          key={booking.uid}
          booking={booking}
        />
      ))}

      <GeometryStoreFocusEverything />
    </GeometryStore>
  );
};

export default ProjectsPage;
