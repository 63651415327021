import { Appear, Icons, sizeX2Px } from 'preshape';
import React, { useMemo } from 'react';
import MapMarker, { MapMarkerProps } from '../MapMarker/MapMarker';
import MapMarkerToolbar from '../MapMarker/MapMarkerToolbar';
import useGeometryStoreRegister from '../useGeometryStore/useGeometryStoreRegister';

type Props = Omit<MapMarkerProps, 'point'> & {
  index: number;
  position: GeoJSON.Position;
  onRemove?: () => void;
  recenter?: () => void;
  visible?: boolean;
  isShifting: boolean;
};

const MapBoundsHandle = ({
  index,
  onRemove,
  recenter,
  isShifting,
  position,
  visible,
  ...rest
}: Props) => {
  const point = useMemo<GeoJSON.Point>(
    () => ({
      type: 'Point',
      coordinates: position,
    }),
    [position]
  );

  const [, ref] = useGeometryStoreRegister({
    geometry: point,
    opts: {
      anchor: 'center',
      id: 'MapBoundsHandle',
      keepInFocus: true,
      padding: sizeX2Px,
    },
  });

  return (
    <MapMarker {...rest} point={point}>
      <MapMarkerToolbar
        enabled={!!onRemove}
        tools={[
          {
            icon: <Icons.Trash2 />,
            onClick: onRemove,
          },
          {
            icon: <Icons.Maximize />,
            onClick: recenter,
          },
        ]}
      >
        <Appear
          animation="Pop"
          backgroundColor={isShifting ? 'accent-shade-5' : 'text-shade-1'}
          borderRadius={onRemove ? 'full' : undefined}
          data-bounds-handle-index={index}
          ref={ref}
          textColor="background-shade-1"
          style={{ cursor: 'move' }}
          visible={visible}
        >
          {onRemove ? <Icons.Circle /> : <Icons.Square />}
        </Appear>
      </MapMarkerToolbar>
    </MapMarker>
  );
};

export default MapBoundsHandle;
