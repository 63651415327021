import { getCircleSegmentPath } from '@drainify/utils';
import { motion } from 'framer-motion';
import { Box, BoxProps, Icons, Text } from 'preshape';
import React, { Fragment, useRef, useState } from 'react';
import useDrag, { DragEvent } from '../../hooks/useDrag';
import ClockRefTick from './ClockRefTick';
import useTicks from './useTicks';

export const SEGMENT_SIZE_TOLERANCE = 0.5;

export const isTickSelected = (
  n: number,
  clockFrom?: number,
  clockTo?: number
) => {
  if (clockFrom !== undefined && clockTo !== undefined) {
    if (clockFrom > clockTo) {
      return n >= clockFrom || n <= clockTo;
    } else {
      return clockFrom <= n && n <= clockTo;
    }
  }

  return false;
};

type Props = BoxProps & {
  clockFrom?: number;
  clockTo?: number;
  name?: string;
  onChange: ([clockTo, clockFrom]: [number, number]) => void;
  size?: number;
};

function ClockRefInput({
  clockFrom,
  clockTo,
  name,
  onChange,
  size = 230,
  ...props
}: Props) {
  const [container, setContainer] = useState<Element | null>(null);
  const refClock = useRef<[number | undefined, number | undefined]>([
    clockFrom,
    clockTo,
  ]);

  const { quadtree, segmentSize, ticks, R } = useTicks({
    segments: 12,
    size,
  });

  const startTick = ticks.find(({ n }) => n === clockFrom);
  const endTick = ticks.find(({ n }) => n === clockTo);

  const startAngle =
    startTick &&
    startTick.a - segmentSize * SEGMENT_SIZE_TOLERANCE - Math.PI * 0.5;
  const endAngle =
    endTick && endTick.a + segmentSize * SEGMENT_SIZE_TOLERANCE - Math.PI * 0.5;

  const handleDragStart = (event: DragEvent) => {
    const tick = quadtree.find(...event.current);

    if (tick) {
      refClock.current = [tick.n, tick.n];
      onChange(refClock.current as [number, number]);
    }
  };

  const handleDrag = (event: DragEvent) => {
    const tick = quadtree.find(...event.current);

    if (tick) {
      refClock.current = [refClock.current[0] ?? tick.n, tick.n];
      onChange(refClock.current as [number, number]);
    }
  };

  const { onPointerDown } = useDrag(container, {
    onDrag: handleDrag,
    onDragStart: handleDragStart,
  });

  return (
    <Box
      {...props}
      alignChildren="middle"
      flex="vertical"
      gap="x6"
      grow
      name={name}
    >
      <Box
        clickable
        height={size}
        maxWidth={size}
        onPointerDown={onPointerDown}
        ref={setContainer}
        style={{ touchAction: 'none' }}
        tag="svg"
        viewBox={`0 0 ${size} ${size}`}
        width={size}
      >
        <g>
          {[startAngle, endAngle].map(
            (angle, index) =>
              angle && (
                <motion.line
                  key={index}
                  stroke="var(--color-accent-shade-5)"
                  strokeDasharray="5 5"
                  strokeWidth="2"
                  x1={R}
                  y1={R}
                  x2={R + Math.cos(angle) * R}
                  y2={R + Math.sin(angle) * R}
                />
              )
          )}

          {ticks.map((tick) => (
            <Fragment key={tick.n}>
              <path
                d={getCircleSegmentPath(
                  R,
                  R,
                  R - 24,
                  tick.aOffset - segmentSize * SEGMENT_SIZE_TOLERANCE,
                  tick.aOffset + segmentSize * SEGMENT_SIZE_TOLERANCE
                )}
                fill="var(--color-accent-shade-3)"
                opacity={isTickSelected(tick.n, clockFrom, clockTo) ? 1 : 0}
                stroke="var(--color-accent-shade-3)"
                strokeWidth={0.5}
              />

              <ClockRefTick
                key={tick.a}
                isSelected={isTickSelected(tick.n, clockFrom, clockTo)}
                R={R}
                tick={tick}
                tickLength={R}
                tickProps={{
                  strokeDasharray: '10, 10',
                  strokeWidth: 1,
                }}
              >
                {tick.n}
              </ClockRefTick>
            </Fragment>
          ))}

          <circle
            cx={R}
            cy={R}
            fill="none"
            r={R - 24}
            stroke="currentColor"
            strokeWidth={2}
          />
        </g>
      </Box>

      <Box
        alignChildren="middle"
        flex="horizontal"
        gap="x2"
        textColor="text-shade-4"
      >
        <Text size="x3" strong>
          Select clockwise
        </Text>
        <Icons.RotateCw />
      </Box>
    </Box>
  );
}

export default ClockRefInput;
