import { Button, Buttons, Icons, Text, useFormContext } from 'preshape';
import React from 'react';
import AsyncButton from '../../AsyncButton/AsyncButton';
import { useWizardContext } from '../Wizard';

const WizardControlsOnSummaryWithChanges = () => {
  const { activeStepId, onCancel, onSave, isLoading, isError, isSuccess } =
    useWizardContext();
  const { getError } = useFormContext();
  const stepError = activeStepId && getError(activeStepId);

  return (
    <Buttons>
      {onCancel && false && (
        <Button basis="0" grow onClick={onCancel} size="x3">
          <Text>Cancel</Text>
        </Button>
      )}

      <AsyncButton
        basis="0"
        color="accent"
        isLoading={isLoading}
        isError={isError}
        isSuccess={isSuccess}
        elevate={!stepError}
        id="accel-Enter"
        disabled={!!stepError || isLoading}
        gap="x2"
        grow
        onClick={onSave}
        size="x3"
        variant="primary"
        style={{
          WebkitUserSelect: 'none',
          msUserSelect: 'none',
          userSelect: 'none',
          WebkitTouchCallout: 'none',
          KhtmlUserSelect: 'none',
          MozUserSelect: 'none',
        }}
      >
        <Text>Done</Text>
        <Icons.Check />
      </AsyncButton>
    </Buttons>
  );
};

export default WizardControlsOnSummaryWithChanges;
