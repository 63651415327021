import { Observation, Node } from '@drainify/types';
import {
  Alert,
  Box,
  Button,
  Buttons,
  Icons,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from 'preshape';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FULL_SCREEN_MODAL_WIDTH } from '../components/App/App';
import DataTable from '../components/DataTable/DataTable';
import DataTableGroup from '../components/DataTable/DataTableGroup';
import DataTableItem from '../components/DataTable/DataTableItem';
import NodeView from '../components/Node/NodeView';
import ObservationView from '../components/Observations/ObservationView';
import { useProjectContext } from '../components/Project/ProjectProvider';
import { useReportEditorContext } from '../components/Report/ReportEditorProvider';
import { isDesktop } from './client';

type Props = {
  subheading: string;
  action: () => void;
  visible: boolean;
  onClose: () => void;
};

const ConfirmationSubmitModal = ({
  action,
  subheading,
  visible,
  onClose,
}: Props) => {
  const { reportEditor, editNode, editObservation } = useReportEditorContext();
  const { activeBooking, project, activeBookingId } = useProjectContext();
  const [nodeWarnings, setNodeWarnings] = React.useState<Node[]>();
  const [observationWarnings, setObservationWarnings] =
    React.useState<Observation[]>();

  const navigate = useNavigate();

  React.useEffect(() => {
    setNodeWarnings([]);
    setObservationWarnings([]);
    const nodeWarnings: Node[] = [];

    for (const node of reportEditor.report.nodes) {
      const errors = reportEditor.getNodeWarning(node.uid);
      if (errors) {
        nodeWarnings.push(node);
      }
    }

    const observationWarnings: Observation[] = [];

    for (const inspection of reportEditor.report.inspections.filter(
      (e) => e.jobId === activeBooking?.uid
    )) {
      for (const observation of reportEditor.getInspectionObservations(
        inspection.uid
      )) {
        if (reportEditor.getObservationWarnings(observation.uid)) {
          observationWarnings?.push(observation);
        }
      }
    }

    setNodeWarnings(nodeWarnings);
    setObservationWarnings(observationWarnings);
  }, [reportEditor.report]);

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      maxWidth={FULL_SCREEN_MODAL_WIDTH}
    >
      <ModalHeader>Update job</ModalHeader>
      <ModalBody>
        <Box flex="vertical" padding="x1">
          <Box padding="x1" flex="vertical">
            <Alert backgroundColor='accent-shade-2' padding='x2' color={'accent'}>
              <Text size='x2' strong> {subheading} </Text>
              </Alert>
          </Box>
        </Box>
        {((observationWarnings && observationWarnings?.length > 0) ||
          (nodeWarnings && nodeWarnings?.length > 0)) && (
          <Box padding="x2" borderColor="text-shade-4">
            <Box flex="horizontal" gap="x2">
              <Icons.AlertCircle textColor="highlight" />
              <Box flex="vertical">
                <Text strong>Reminder: Missing photos</Text>
                <Text size="x1">You can add now or later</Text>
              </Box>
            </Box>

            {observationWarnings && observationWarnings.length > 0 && (
              <DataTable>
                <DataTableGroup title="Observations">
                  {observationWarnings.map((observation) => (
                    <DataTableItem
                      key={observation.uid}
                      onClick={(e) => {
                        e.stopPropagation();
                        isDesktop()
                          ? navigate(
                              `/projects/${project?.uid}/inspections/${observation.inspectionUid}?activeBookingId=${activeBookingId}`
                            )
                          : editObservation(observation.uid);
                      }}
                    >
                      <ObservationView
                        observation={reportEditor.getObservationByUid(
                          observation.uid
                        )}
                      />
                    </DataTableItem>
                  ))}
                </DataTableGroup>
              </DataTable>
            )}

            {nodeWarnings && nodeWarnings.length > 0 && (
              <DataTable>
                <DataTableGroup title="Nodes">
                  {nodeWarnings.map((node) => (
                    <DataTableItem
                      key={node.uid}
                      onClick={(e) => {
                        e.stopPropagation();
                        isDesktop()
                          ? navigate(
                              `/projects/${project?.uid}/nodes/${node.uid}?activeBookingId=${activeBookingId}`
                            )
                          : editNode(node.uid);
                      }}
                    >
                      <NodeView node={reportEditor.getNodeByUid(node.uid)} />
                    </DataTableItem>
                  ))}
                </DataTableGroup>
              </DataTable>
            )}
          </Box>
        )}
      </ModalBody>
      <ModalFooter>
        <Buttons alignChildrenHorizontal="around" flex="horizontal">
          <Button basis="0" grow onClick={onClose} size="x3">
            <Text>Cancel</Text>
          </Button>
          <Button
            basis="0"
            color="positive"
            elevate={true}
            gap="x2"
            grow
            size="x3"
            variant="primary"
            onClick={() => {
              action();
              onClose();
            }}
          >
            <Text>Submit</Text>
            <Icons.Check />
          </Button>
        </Buttons>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationSubmitModal;
