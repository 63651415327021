import { hasPermission, orgRoleOrder, permissions } from '@drainify/utils';
import {
  Box,
  Button,
  Icons,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Text,
} from 'preshape';
import React, { Fragment, useMemo, useState } from 'react';
import { FULL_SCREEN_MODAL_WIDTH } from '../App/App';
import Card from '../Card/Card';
import OrgRoleBadge from './OrgRoleBadge';
import OrgRoleList from './OrgRoleList';

const getGroupedPermissions = () => {
  const groups: Record<string, (keyof typeof permissions)[]> = {};

  Object.keys(permissions).forEach((permission) => {
    const entity = permission.split(':')[0];

    groups[entity] = groups[entity] || [];
    groups[entity].push(permission as keyof typeof permissions);
  });

  return groups;
};

const OrgRolesInformationButton = () => {
  const [visible, setVisible] = useState(false);
  const groupedPermissions = useMemo(getGroupedPermissions, []);

  return (
    <>
      <Button
        color="accent"
        gap="x2"
        onClick={() => setVisible((v) => !v)}
        variant="tertiary"
        uppercase={false}
      >
        <Text>Role information</Text>
        <Icons.HelpCircle />
      </Button>

      <Modal
        onClose={() => setVisible(false)}
        margin="x6"
        maxWidth={FULL_SCREEN_MODAL_WIDTH}
        visible={visible}
      >
        <ModalHeader>
          <ModalTitle>Role information</ModalTitle>
        </ModalHeader>

        <ModalBody>
          <Card
            description="An overview of the roles and their impact on users"
            title="Roles overview"
            padding="x0"
            backgroundColor="transparent"
          >
            <OrgRoleList />
          </Card>

          <Card
            description="A full breakdown of what each role can and cannot do."
            overflow="auto"
            margin="x8"
            title="Permissions"
          >
            <Table size="x2">
              <TableHeader>
                <TableRow>
                  <TableHeaderCell />
                  {orgRoleOrder.map((orgRole) => (
                    <TableHeaderCell key={orgRole}>
                      <OrgRoleBadge role={orgRole} />
                    </TableHeaderCell>
                  ))}
                </TableRow>
              </TableHeader>

              <TableBody>
                {Object.entries(groupedPermissions).map(
                  ([entity, permissions]) => (
                    <Fragment key={entity}>
                      <TableRow>
                        <TableCell colSpan={orgRoleOrder.length + 1}>
                          <Text strong titlecase>
                            {entity}
                          </Text>
                        </TableCell>
                      </TableRow>

                      {permissions.map((permission) => (
                        <TableRow key={permission}>
                          <TableCell titlecase>
                            {permission.split(':')[1]}
                          </TableCell>

                          {orgRoleOrder.map((orgRole) => (
                            <TableCell key={orgRole}>
                              <Box
                                alignChildrenHorizontal="middle"
                                flex="horizontal"
                              >
                                {hasPermission(orgRole, permission) ? (
                                  <Icons.CheckCircle textColor="positive-shade-3" />
                                ) : (
                                  <Icons.XCircle textColor="negative-shade-3" />
                                )}
                              </Box>
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </Fragment>
                  )
                )}
              </TableBody>
            </Table>
          </Card>
        </ModalBody>
      </Modal>
    </>
  );
};

export default OrgRolesInformationButton;
