import { Box, Button } from 'preshape';
import React from 'react';
import DataTable from '../../DataTable/DataTable';
import DataTableGroup from '../../DataTable/DataTableGroup';
import DataTableItem from '../../DataTable/DataTableItem';
import DataTableMessage from '../../DataTable/DataTableMessage';
import { useReportEditorContext } from '../../Report/ReportEditorProvider';
import { useVideoContext } from '../VideoProvider';
import VideoPlayerSidebarChapterView from './VideoPlayerSidebarChapterView';

type Props = {
  inspectionId: string;
  projectId: string;
};

const VideoPlayerSidebarChapterContainer = ({
  inspectionId,
  projectId,
}: Props) => {
  const {
    handleSeekChange,
    handleSetActiveObservationId,
    handlePause,
    duration,
    activeObservationId,
    player,
  } = useVideoContext();
  const { reportEditor } = useReportEditorContext();

  const observations = reportEditor.getInspectionObservations(inspectionId);

  const [loading, setLoading] = React.useState(false);

  const setActiveId = (observationId: string) => {
    handlePause();
    handleSetActiveObservationId(observationId);
  };

  const activeSnaphotRef = React.useRef<null | HTMLButtonElement>(null);

  React.useEffect(() => {
    if (activeObservationId && activeSnaphotRef.current) {
      activeSnaphotRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [activeObservationId]);

  if (observations.length === 0) {
    return (
      <DataTable>
        <DataTableGroup>
          <DataTableItem>
            <DataTableMessage>
              There are no observations to show here
            </DataTableMessage>
          </DataTableItem>
        </DataTableGroup>
      </DataTable>
    );
  }

  return (
    <Box flex="vertical" height="calc(100vh - 375px)" overflow="auto">
      {observations.map((observation) => (
        <Button
          ref={
            observation.uid === activeObservationId
              ? activeSnaphotRef
              : undefined
          }
          variant="tertiary"
          disabled={loading}
          key={observation.uid}
          active={activeObservationId === observation.uid}
          backgroundColorActive="accent-shade-2"
          textColorActive="text-shade-2"
          // backgroundColor={'background-shade-2'}
          onClick={() => {
            handlePause();
            setActiveId(observation.uid);

            if (observation.timeStamp) {
              handleSeekChange(observation.timeStamp / duration);
              player?.current &&
                player.current.seekTo(observation.timeStamp! / duration);
            }
          }}
        >
          <VideoPlayerSidebarChapterView
            loading={loading}
            setLoading={setLoading}
            observation={observation}
            projectId={projectId}
          />
        </Button>
      ))}
    </Box>
  );
};

export default VideoPlayerSidebarChapterContainer;
