import {
  isCircular,
  Ownership,
  PipeType,
  SectionAttributes,
} from '@drainify/types';
import { runValidations } from '@drainify/utils';
import {
  Text,
  Box,
  ModalHeader,
  Modal,
  ModalBody,
  ModalFooter,
  FormProvider,
  useForm,
  InputLabel,
  CheckBox,
} from 'preshape';
import React, { useCallback, useMemo } from 'react';
import { number, object } from 'yup';
import useProject from '../../../../hooks/useProject';
import { FULL_SCREEN_MODAL_WIDTH } from '../../../App/App';
import Distance from '../../../DistanceInput/Distance';
import DistanceInput from '../../../DistanceInput/DistanceInput';
import { useProjectContext } from '../../../Project/ProjectProvider';
import SectionAttributeList from '../../../Section/SectionAttribute/SectionAttributeList';
import SectionDiameterQuickOptions from '../../../Section/SectionDiameterQuickOptions';
import SymbolTemplate from '../../../Symbol/SymbolTemplate';
import Wizard from '../../../Wizard/Wizard';
import WizardControls from '../../../Wizard/WizardControls/WizardControls';
import WizardReviewStep from '../../../Wizard/WizardReviewStep';
import WizardStep from '../../../Wizard/WizardStep';
import WizardStepError from '../../../Wizard/WizardStepError';
import SiteSectionDefaultsSummary from './SiteSectionDefaultsSummary';

type Props = {
  modalVisible: boolean;
  onClose: () => void;
  newJob?: boolean;
};

const ReportMapSidebarToolsSiteSectionDefaults = ({
  modalVisible,
  onClose,
  newJob,
}: Props) => {
  const { project } = useProjectContext();

  const { update } = useProject({ projectId: project?.uid });

  const siteSectionDefaultValidations = object().shape({
    diameter: number().max(1000),
  });

  const initial = useMemo<Partial<SectionAttributes>>(
    () => ({
      use: project?.siteDefaults?.section?.use,
      material: project?.siteDefaults?.section?.material,
      shape: project?.siteDefaults?.section?.shape,
      diameter: project?.siteDefaults?.section?.diameter,
      pipeType: project?.siteDefaults?.section?.pipeType,
      sectionOwnership: project?.siteDefaults?.section?.sectionOwnership,
      width: project?.siteDefaults?.section?.width,
      height: project?.siteDefaults?.section?.height,
    }),
    [project?.siteDefaults]
  );

  const form = useForm({
    initial: initial,
    validate: useCallback(
      (booking, setError) => {
        setError(runValidations(siteSectionDefaultValidations, booking));
      },
      [modalVisible]
    ),
  });

  React.useEffect(() => {
    form.reset();
  }, [modalVisible]);

  if (!project) {
    return null;
  }

  const handleSave = async () => {
    if (!form.hasError) {
      update.mutateAsync({
        ...project,
        siteDefaults: {
          ...project.siteDefaults,
          section: form.state,
        },
      });
      onClose();
    }
  };

  return (
    <>
      <Box flex="horizontal" alignChildrenHorizontal="between">
        <Box flex="vertical" alignChildrenHorizontal="middle">
          <Text size="x1">Ownership</Text>
          <Box
            flex="vertical"
            alignChildrenVertical="middle"
            alignChildrenHorizontal="middle"
            basis="0"
            grow
            borderSize="x1"
            borderColor="text-shade-4"
            borderRadius="10px"
            width="45px"
          >
            <Text size="x1" strong>
              {project?.siteDefaults?.section?.sectionOwnership || 'Not set'}
            </Text>
          </Box>
        </Box>

        {project.siteDefaults?.section?.shape !== undefined &&
          isCircular(form.state.shape) && (
            <Box flex="vertical" alignChildrenHorizontal="middle">
              <Text size="x1">Dia</Text>
              <Box
                flex="vertical"
                alignChildrenVertical="middle"
                alignChildrenHorizontal="middle"
                basis="0"
                grow
                borderSize="x1"
                borderColor="text-shade-4"
                borderRadius="10px"
                width="45px"
              >
                <Text size="x1" strong>
                  {(project.siteDefaults?.section?.diameter && (
                    <Distance
                      type="short"
                      value={project.siteDefaults?.section?.diameter}
                    />
                  )) ||
                    'Not set'}
                </Text>
              </Box>
            </Box>
          )}

        {project.siteDefaults?.section?.shape !== undefined &&
          !isCircular(form.state.shape) && (
            <>
              <Box flex="vertical" alignChildrenHorizontal="middle">
                <Text size="x1">Width</Text>
                <Box
                  flex="vertical"
                  alignChildrenVertical="middle"
                  alignChildrenHorizontal="middle"
                  basis="0"
                  grow
                  borderSize="x1"
                  borderColor="text-shade-4"
                  borderRadius="10px"
                  width="45px"
                >
                  <Text size="x1" strong>
                    {(project.siteDefaults?.section?.width && (
                      <Distance
                        type="short"
                        value={project.siteDefaults?.section?.width}
                      />
                    )) ||
                      'Not set'}
                  </Text>
                </Box>
              </Box>
              <Box flex="vertical" alignChildrenHorizontal="middle">
                <Text size="x1">Height</Text>
                <Box
                  flex="vertical"
                  alignChildrenVertical="middle"
                  alignChildrenHorizontal="middle"
                  basis="0"
                  grow
                  borderSize="x1"
                  borderColor="text-shade-4"
                  borderRadius="10px"
                  width="45px"
                >
                  <Text size="x1" strong>
                    {(project.siteDefaults?.section?.height && (
                      <Distance
                        type="short"
                        value={project.siteDefaults?.section?.height}
                      />
                    )) ||
                      'Not set'}
                  </Text>
                </Box>
              </Box>
            </>
          )}

        <Box flex="vertical" alignChildrenHorizontal="middle">
          <Text size="x1">P.Type</Text>
          <Box
            flex="vertical"
            alignChildrenVertical="middle"
            alignChildrenHorizontal="middle"
            basis="0"
            grow
            borderSize="x1"
            borderColor="text-shade-4"
            borderRadius="10px"
            width="45px"
          >
            <Text size="x1" strong>
              {project?.siteDefaults?.section?.pipeType || 'Not set'}
            </Text>
          </Box>
        </Box>

        <Box flex="vertical" alignChildrenHorizontal="middle">
          <Text size="x1">Use</Text>
          <SymbolTemplate>
            <Text size="x1">
              {project?.siteDefaults?.section?.use || 'Not set'}
            </Text>
          </SymbolTemplate>
        </Box>
        <Box flex="vertical" alignChildrenHorizontal="middle">
          <Text size="x1">Mat</Text>
          <SymbolTemplate>
            <Text size="x1">
              {project?.siteDefaults?.section?.material || 'Not set'}
            </Text>
          </SymbolTemplate>
        </Box>

        <Box flex="vertical" alignChildrenHorizontal="middle">
          <Text size="x1">Shape</Text>
          <SymbolTemplate>
            <Text size="x1">
              {project?.siteDefaults?.section?.shape || 'Not set'}
            </Text>
          </SymbolTemplate>
        </Box>
      </Box>

      <FormProvider form={form}>
        <Wizard flow={'update'} onCancel={onClose} onSave={handleSave}>
          <Modal visible={modalVisible} onClose={onClose}>
            <ModalHeader>
              {newJob
                ? 'Would you like to set up section defaults?'
                : 'Site section defaults'}
            </ModalHeader>
            <ModalBody minWidth={FULL_SCREEN_MODAL_WIDTH}>
              <WizardStep id="sectionOwnership" title="Section ownership">
                <InputLabel>
                  {Object.values(Ownership).map((sectionOwnership) => (
                    <CheckBox
                      borderSize='x1'
                      checked={form.state.sectionOwnership === sectionOwnership}
                      key={sectionOwnership}
                      margin="x1"
                      onChange={() =>
                        form.setState((s) => ({
                          ...s,
                          sectionOwnership,
                        }))
                      }
                    >
                      {sectionOwnership}
                    </CheckBox>
                  ))}
                </InputLabel>
              </WizardStep>

              <WizardStep id="diameter" title="Diameter">
                <DistanceInput
                  type="short"
                  value={form.state.diameter}
                  onChange={(e) =>
                    form.setState((s) => ({
                      ...s,
                      diameter: e,
                    }))
                  }
                />
                <SectionDiameterQuickOptions
                  margin="x6"
                  onChange={(e) =>
                    form.setState((s) => ({
                      ...s,
                      diameter: e,
                    }))
                  }
                  value={form.state.diameter}
                />
              </WizardStep>

              <WizardStep id="width" title="Width">
                <DistanceInput
                  type="short"
                  value={form.state.width}
                  onChange={(e) =>
                    form.setState((s) => ({
                      ...s,
                      width: e,
                    }))
                  }
                />
              </WizardStep>

              <WizardStep id="height" title="Height">
                <DistanceInput
                  type="short"
                  value={form.state.height}
                  onChange={(e) =>
                    form.setState((s) => ({
                      ...s,
                      height: e,
                    }))
                  }
                />
              </WizardStep>

              <WizardStep id="pipeType" title="Pipe type">
                <InputLabel>
                  {Object.values(PipeType).map((pipeType) => (
                    <CheckBox
                      borderSize='x1'
                      checked={form.state.pipeType === pipeType}
                      key={pipeType}
                      margin="x1"
                      onChange={() =>
                        form.setState((s) => ({
                          ...s,
                          pipeType,
                        }))
                      }
                    >
                      {pipeType}
                    </CheckBox>
                  ))}
                </InputLabel>
              </WizardStep>

              <WizardStep id="use" title="Use">
                <SectionAttributeList
                  attribute="use"
                  onChange={(use) =>
                    form.setState((s) => ({
                      ...s,
                      use,
                    }))
                  }
                  value={form.state.use}
                />
              </WizardStep>

              <WizardStep id="material" title="Material">
                <SectionAttributeList
                  attribute="material"
                  onChange={(material) =>
                    form.setState((s) => ({
                      ...s,
                      material,
                    }))
                  }
                  value={form.state.material}
                />
              </WizardStep>
              <WizardStep id="shape" title="Shape">
                <SectionAttributeList
                  attribute="shape"
                  onChange={(shape) =>
                    form.setState((s) => ({
                      ...s,
                      shape,
                    }))
                  }
                  value={form.state.shape}
                />
              </WizardStep>
              <WizardReviewStep>
                <SiteSectionDefaultsSummary
                  sectionDefaults={form.state}
                  onClear={() => form.setState(() => ({}))}
                />
              </WizardReviewStep>
            </ModalBody>
            <ModalFooter>
              <WizardStepError />
              <WizardControls />
            </ModalFooter>
          </Modal>
        </Wizard>
      </FormProvider>
    </>
  );
};

export default ReportMapSidebarToolsSiteSectionDefaults;
