import { AccountState, SubscriptionTierDetails, Usage } from '@drainify/types';
import { Box, Label, Text } from 'preshape';
import React from 'react';
import { labelColor } from '../../utils/subscriptionTiers';
import PlaceholderText from '../Placeholders/PlaceholderText';
import Logo from './Logo';

const LogoTier = ({
  tier,
  usage,
}: {
  tier?: SubscriptionTierDetails;
  usage?: Usage;
}) => {
  let name;
  if (usage?.state === AccountState.TRIAL) {
    name = 'Trial';
  } else if (tier) {
    name = tier.displayName;
  }
  return (
    <Text textColor="accent-shade-4">
      <Logo />
      <Box
        style={{
          position: 'relative',
          top: '-20px',
          left: '40px',
        }}
      >
        {name ? (
          <Label style={labelColor(tier!)}>{name}</Label>
        ) : (
          <PlaceholderText />
        )}
      </Box>
    </Text>
  );
};

export default LogoTier;
