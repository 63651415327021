import { Box } from 'preshape';
import React from 'react';

type Props = {
  size?: string;
  fill?: string;
};

const RulerIcon = ({ size = '1.25rem', fill = 'currentColor' }: Props) => {
  return (
    <Box
      className="Icon"
      tag="svg"
      viewBox="-1 0 22 24"
      height={size}
      width={size}
      borderRadius="full"
    >
      {
        <svg
          id="ruler"
          fill={fill}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 500 500"
        >
          <path
            d="M 371.6525 16.7249 L -61.0438 319.7038 l 86.5653 123.6262 l 52.4397 -36.7186 l 0.0244 0.0348 l 21.8542 -15.3016 l -0.0244 -0.0348 l 21.8551 -15.3038 l 0.0238 0.0347 l 21.8542 -15.3018 l -0.0238 -0.0347 l 21.8546 -15.3039 l 0.0244 0.0348 l 21.8539 -15.3016 l -0.0238 -0.0347 l 21.8549 -15.3012 l 0.0248 0.0324 l 21.8529 -15.3018 l -0.0244 -0.0348 l 21.8557 -15.3013 l 0.0248 0.0324 l 21.8529 -15.3018 l -0.0244 -0.0348 l 21.8562 -15.3023 l 0.0244 0.0348 l 21.8531 -15.3031 l -0.0244 -0.0348 l 21.8559 -15.3021 l 0.022 0.0338 l 21.8556 -15.3024 l -0.0244 -0.0345 l 21.8562 -15.3025 l 0.0219 0.0344 l 21.8557 -15.3026 l -0.0244 -0.0343 l 52.4396 -36.719 L 371.6525 16.7249 Z m 18.825 138.4927 l 30.5837 -21.4164 l -55.9605 -79.919 l -388.9871 272.3735 l 55.9595 79.9193 l 30.5855 -21.4169 l -20.3283 -29.0331 l 21.8541 -15.3013 l 20.3288 29.0304 l 21.8544 -15.3013 l -20.3283 -29.0331 l 21.8542 -15.3016 l 20.329 29.0307 l 21.8539 -15.3016 l -35.6313 -50.8865 l 21.8551 -15.3038 l 35.6314 50.8862 l 21.8541 -15.3013 l -20.3283 -29.0331 l 21.8542 -15.3016 l 20.3291 29.0332 l 21.8533 -15.3041 l -20.3271 -29.0318 l 21.8533 -15.3028 l 20.3295 29.032 l 21.8531 -15.3028 l -35.6308 -50.8867 l 21.8559 -15.3023 l 35.631 50.8867 l 21.8531 -15.3031 l -20.3295 -29.0323 l 21.8559 -15.3023 l 20.3295 29.032 l 21.8531 -15.3028 l -20.3292 -29.0322 l 21.8557 -15.3026 l 20.3297 29.0326 Z"
            fillRule="evenodd"
          />
        </svg>
      }
    </Box>
  );
};

export default RulerIcon;
