import {
  Modal,
  ModalBody,
  Box,
  Button,
  Text,
  ModalHeader,
  Icons,
  Alert,
  TextArea,
} from 'preshape';
import React, { ChangeEvent } from 'react';
import { isMobile } from '../../utils/client';
import useFetch from '../Api/useFetch';
import { FULL_SCREEN_MODAL_WIDTH, MOBILE_APP_WIDTH } from '../App/App';
import { useUsageContext } from './UsageProvider';

const UsageDetailsCancel = () => {
  const fetch = useFetch();

  const { refetch } = useUsageContext();
  const [reason, setReason] = React.useState<string>();
  const [visible, setVisble] = React.useState(false);

  return (
    <>
      <Button
        variant="tertiary"
        color="negative"
        onClick={() => setVisble(true)}
        flex="horizontal"
        gap="x1"
        size="x1"
      >
        <Text>Cancel account</Text>
      </Button>
      <Modal visible={visible} onClose={() => setVisble(false)}>
        <ModalHeader>Cancel account</ModalHeader>
        <ModalBody
          maxWidth={isMobile() ? MOBILE_APP_WIDTH : FULL_SCREEN_MODAL_WIDTH}
        >
          <Box flex="vertical" gap="x3">
            <Text strong size="x6">
              Are you sure?
            </Text>
            <Box>
              <Alert
                color={'negative'}
                padding="x3"
                backgroundColor="negative-shade-1"
              >
                <Box flex="horizontal" gap="x2">
                  <Icons.AlertCircle size="1.25rem" />
                  <Text strong>Account closures are final</Text>
                </Box>
                <Text size="x3">
                  Please ensure you have backups of any data you need. As it
                  will be deleted.
                </Text>
                <Text size="x3">
                  Upon cancellation there will be no further charges.
                </Text>

                <Text size="x3" strong>
                  Positive account balances are nonrefundable.
                </Text>
              </Alert>
            </Box>
            <Box>
              <TextArea
                value={reason}
                placeholder="Please put in the reason why you are unsatisfied. (Minimum 50 characters)"
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                  setReason(e.target.value)
                }
                rows={6}
              ></TextArea>
              <Text size="x1">
                {reason
                  ? reason.length < 50
                    ? `Remaining ${50 - reason.length}`
                    : ''
                  : ''}
              </Text>
            </Box>
            <Button
              variant="primary"
              color="negative"
              disabled={reason === undefined || reason.length < 50}
              onClick={async () => {
                await fetch('/payment/cancel', {
                  method: 'POST',
                  body: {
                    reason: reason!,
                  },
                });
                refetch?.();
                setVisble(false);
              }}
            >
              Cancel your account
            </Button>
          </Box>
        </ModalBody>
      </Modal>
    </>
  );
};

export default UsageDetailsCancel;
