import {
  Box,
  Button,
  Icons,
  Placement,
  PlacementArrow,
  PlacementContent,
  PlacementManager,
  PlacementReference,
  Text,
} from 'preshape';
import React from 'react';
import { useJobSearchContext } from '../../pages/desktop/Pages/AdminPage';
import UserSelectionList from '../User/UserSelectorList';

const JobAdminSearchOptionsEngineer = () => {
  const { engineer, setEngineer } = useJobSearchContext();

  return (
    <PlacementManager trigger="click">
      <PlacementReference>
        {(props) => (
          <Box flex="vertical" alignChildrenHorizontal="middle">
            <Button
              variant="tertiary"
              {...props}
              borderSize="x1"
              active={engineer !== undefined}
            >
              <Icons.User size="1rem" />
              <Text size="x1">{engineer?.fullName || 'All'}</Text>
            </Button>
          </Box>
        )}
      </PlacementReference>

      <Placement placement="bottom" zIndex={1}>
        <PlacementArrow backgroundColor="background-shade-3" />
        <PlacementContent
          backgroundColor="background-shade-1"
          borderColor="background-shade-3"
          borderRadius="x3"
          borderSize="x2"
          textColor="text-shade-1"
        >
          <Box flex="vertical" gap="x4" padding="x3">
            <UserSelectionList
              onSelect={setEngineer}
              withSearch
              selected={engineer ? [engineer] : undefined}
            />
          </Box>
        </PlacementContent>
      </Placement>
    </PlacementManager>
  );
};

export default JobAdminSearchOptionsEngineer;
