import { normaliseDistance } from '@drainify/utils';
import { InputLabelProps, Input, InputLabel, Icons } from 'preshape';
import React, {
  ChangeEvent,
  forwardRef,
  ForwardRefRenderFunction,
} from 'react';
import { isDesktop } from '../../utils/client';
import DistanceUnitLabel from './DistanceUnitLabel';
import useDisplayDistance, { DisplayDistanceType } from './useDisplayUnit';

type Props = InputLabelProps & {
  name?: string;
  onChange: (value?: number) => void;
  type: DisplayDistanceType;
  value?: number;
  addOnStart?: JSX.Element;
  placeholder?: string;
};

const DistanceInput: ForwardRefRenderFunction<HTMLLabelElement, Props> = (
  {
    addOnStart = <Icons.Hash size="1.25rem" />,
    name,
    placeholder,
    onChange,
    type,
    value,
    ...rest
  },
  ref
) => {
  const [valueConverted, unit] = useDisplayDistance(type, value);

  const size = isDesktop() ? 'x3' : 'x8';

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const float = parseFloat(event.target.value);
    const [normValue] = normaliseDistance([float, unit]);

    onChange(isNaN(float) ? undefined : normValue);
  };

  return (
    <InputLabel {...rest} ref={ref}>
      <Input
      backgroundColor='accent-shade-1'
        placeholder={placeholder}
        autoFocus
        addonEnd={<DistanceUnitLabel type={type} value={value} size={size} />}
        addonStart={addOnStart}
        name={name}
        onChange={handleChange}
        type="number"
        value={value === undefined ? '' : valueConverted}
        size={size}
      />
    </InputLabel>
  );
};

export default forwardRef(DistanceInput);
