import { ReportError } from '@drainify/utils';
import { Box, BoxProps, Button, Buttons } from 'preshape';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import ReportErrorIndicator from '../Report/ReportErrorIndicator';
import SearchResult from '../Search/SearchResult';
import SearchResultGroup from '../Search/SearchResultGroup';
import './SummaryCard.css';

export type NullAction = {
  title: string;
  value?: any | any[];
  onEdit?: () => void;
  error?: ReportError;
};

const SummaryCardContext = createContext<{
  addNullAction: (action: NullAction) => void;
}>({
  addNullAction: () => {},
});

export const useSummaryCardAction = (
  { title, value, onEdit, error }: NullAction,
  enable = true
) => {
  const { addNullAction } = useContext(SummaryCardContext);

  useEffect(() => {
    if (enable) {
      addNullAction({ title, value, onEdit, error });
    }
  }, [enable, title, value, onEdit, error]);
};

const SummaryCard = ({
  children,
  ...rest
}: PropsWithChildren<BoxProps & { title?: string }>) => {
  const [nullActions, setNullActions] = useState<(NullAction | undefined)[]>(
    []
  );

  const nullActionEntries = Object.values(nullActions).filter(
    (v): v is NullAction => !!v
  );

  const addNullAction = ({ title, value, onEdit, error }: NullAction) => {
    const hasValue = Array.isArray(value)
      ? !!value.length
      : value !== undefined && value !== '';

    setNullActions((actions) => ({
      ...actions,
      [title]:
        !hasValue && onEdit ? { title, value, onEdit, error } : undefined,
    }));
  };

  return (
    <SummaryCardContext.Provider value={{ addNullAction }}>
      <Box {...rest}>
        <Box>{children}</Box>

        {/* // TODO(): NullDataAction: This is version A to test. */}
        {!!nullActionEntries.length && (
          <SearchResultGroup>
            <Buttons alignChildrenHorizontal="end" flex="vertical" margin="x6">
              {nullActionEntries.map(({ title, onEdit, error }) => (
                <SearchResult key={title} keywords={title}>
                  <Button
                    color="accent"
                    gap="x2"
                    onClick={onEdit}
                    variant="tertiary"
                  >
                    {error && <ReportErrorIndicator error={error} />}
                    {title}
                  </Button>
                </SearchResult>
              ))}
            </Buttons>
          </SearchResultGroup>
        )}
      </Box>
    </SummaryCardContext.Provider>
  );
};

export default SummaryCard;
