import { Box, Text } from 'preshape';
import React from 'react';

type Props = {
  content: string;
};

const ReportPlaceholderPage = ({ content }: Props) => {
  return (
    <Box flex="vertical" grow>
      <Box
        alignChildren="middle"
        backgroundColor="background-shade-3"
        flex="vertical"
        grow
        padding="x12"
      >
        <Box maxWidth="400px">
          <Text align="middle" margin="x8" size="x6" strong>
            {content}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default ReportPlaceholderPage;
