import { SectionPostBody } from '@drainify/types';
import { Box, BoxProps, Text } from 'preshape';
import React from 'react';
import FlowSymbol from '../FlowInput/FlowSymbol';
import NodeSymbol from '../Node/NodeSymbol';
import { useReportEditorContext } from '../Report/ReportEditorProvider';

type Props = BoxProps & {
  section?: SectionPostBody;
};

const SectionFlowVisualisation = ({ gap = 'x2', section }: Props) => {
  const { reportEditor } = useReportEditorContext();
  const startNode = reportEditor.getNodeByUid(section?.nodeStartUid);
  const endNode = reportEditor.getNodeByUid(section?.nodeEndUid);

  return (
    <Box flex="vertical">
      <Box
        alignChildrenVertical="middle"
        flex="horizontal"
        gap={gap}
        margin="x2"
      >
        <Box>
          <NodeSymbol code={startNode?.code} />
        </Box>

        <Box alignChildren="middle" flex="horizontal">
          <FlowSymbol value={section?.attributes.flow} />
        </Box>

        <Box>
          <NodeSymbol code={endNode?.code} />
        </Box>
      </Box>
      <Box flex="horizontal" alignChildrenHorizontal="between">
        <Text>{reportEditor.getNodeName(startNode)}</Text>
        <Text>{reportEditor.getNodeName(endNode)}</Text>
      </Box>
    </Box>
  );
};

export default SectionFlowVisualisation;
