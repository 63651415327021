import { Member, User } from '@drainify/types';
import { getFullFilePath } from '@drainify/utils';
import { Box, BoxProps, Text, TextProps } from 'preshape';
import React, { PropsWithChildren } from 'react';
import Avatar from '../Avatar/Avatar';
import OrgRoleBadge from '../Org/OrgRoleBadge';
import PlaceholderAvatar from '../Placeholders/PlaceholderAvatar';
import PlaceholderBlock from '../Placeholders/PlaceholderBlock';

type Props = TextProps & {
  member?: Member;
  user?: User;
};

const UserView = ({
  member,
  user,
  ...rest
}: PropsWithChildren<BoxProps & Props>) => {
  return (
    <Text {...rest} alignChildrenVertical="start" flex="horizontal" gap="x4">
      <Box alignChildrenVertical="middle" flex="horizontal" gap="x4" grow maxWidth='100%'>
        <Box>
          {user ? (
            <Avatar
              src={getFullFilePath(user.avatarUrlCropped)}
              uid={user.uid}
              size="3rem"
            />
          ) : (
            <PlaceholderAvatar size="3rem" />
          )}
        </Box>

        <Box basis="0" grow overflow='hidden'>
          {user ? (
            <>
              <Text size="x3">{user.fullName}</Text>
              <Text size="x3" textColor="text-shade-4">
                {user.email}
              </Text>
              <Text size="x3" textColor="text-shade-4">
                {user.phoneNumber}
              </Text>
            </>
          ) : (
            <>
              <PlaceholderBlock />
              <PlaceholderBlock />
            </>
          )}
        </Box>

      {member && (
        <Box>
          <OrgRoleBadge role={member.role} />
        </Box>
      )}
      </Box>
    </Text>
  );
};

export default UserView;
