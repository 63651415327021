import { Section, SectionUseCode } from '@drainify/types';
import { getMidPointBetweenPoints, ReportElementType } from '@drainify/utils';
import round from 'lodash.round';
import { Icons, Label, Text, sizeX2Px } from 'preshape';
import React, { useMemo } from 'react';
import { isDesktop } from '../../../../utils/client';
import Distance from '../../../DistanceInput/Distance';
import { useMapLayersContext } from '../../../Map/MapLayers/MapLayers';
import MapLabel from '../../../Map/MapMarker/MapLabel';
import MapMarkerToolbar from '../../../Map/MapMarker/MapMarkerToolbar';
import useGeometryStoreRegister from '../../../Map/useGeometryStore/useGeometryStoreRegister';
import { useProjectContext } from '../../../Project/ProjectProvider';
import { useReportEditorContext } from '../../ReportEditorProvider';
import { useReportMapContext } from '../ReportMapProvider';
import ReportMapObservation from './ReportMapObservation';
import useDrawReportMapPipe, { PIPE_WIDTH } from './useDrawReportMapPipe';

type Props = {
  section: Section;
  showObservations: boolean;
};

const ReportMapSection = ({ section, showObservations }: Props) => {
  const { isLayerVisible } = useMapLayersContext();
  const { reportEditor } = useReportEditorContext();
  const {
    focusSection,
    toggleFocusSection,
    focusedInspectionUid,
    focusedElementUid,
    hasFeature,
  } = useReportMapContext();
  const { activeBookingId } = useProjectContext();

  const hasInspections =
    reportEditor.getSectionInspections(section.uid, activeBookingId).length > 0;

  const visible = isLayerVisible(ReportElementType.SECTION, section.uid);
  const [nodeStart, nodeEnd] = reportEditor.getSectionNodes(section.uid);

  const lineString = useMemo<GeoJSON.LineString | undefined>(() => {
    if (nodeStart?.point && nodeEnd?.point) {
      return {
        type: 'LineString',
        coordinates: [
          nodeStart.point.coordinates,
          ...(section.additionalPoints || [])?.map((e) => e.point.coordinates),
          nodeEnd.point.coordinates,
        ],
      };
    }
  }, [nodeStart, nodeEnd]);

  const midPoint = useMemo(() => {
    if (
      nodeEnd?.point &&
      nodeStart?.point &&
      (!section.additionalPoints || section.additionalPoints.length === 0)
    ) {
      return getMidPointBetweenPoints(nodeStart?.point, nodeEnd?.point);
    } else if (
      nodeStart?.point &&
      section.additionalPoints &&
      section.additionalPoints.length > 0
    ) {
      return getMidPointBetweenPoints(
        nodeStart?.point,
        section.additionalPoints[0].point
      );
    }
  }, [nodeStart, nodeEnd]);

  const length = reportEditor.getSectionLength(section.uid);

  const observations =
    focusedInspectionUid &&
    reportEditor.getInspectionSection(focusedInspectionUid)?.uid === section.uid
      ? reportEditor.getInspectionObservations(focusedInspectionUid)
      : reportEditor.getSectionInspectionLatestObservations(
          section.uid,
          activeBookingId
        );

  useDrawReportMapPipe({
    pointA: nodeStart?.point,
    additionalPoints: section.additionalPoints,
    pointB: nodeEnd?.point,
    flow: section.attributes.flow,
    stroke:
      (section.attributes.use &&
        reportMapBorderColorMap[section.attributes.use]) ||
      undefined,
    visible: visible,
    opacity: hasInspections ? 1 : 0.3,
  });

  useGeometryStoreRegister({
    geometry: lineString,
    opts: {
      id: `ReportMapSection.${section.uid}`,
      padding: PIPE_WIDTH + sizeX2Px,
    },
  });

  if (!nodeStart?.point || !nodeEnd?.point) {
    return null;
  }

  return (
    <>
      <MapLabel
        id={section.uid}
        onClick={
          hasFeature('Elements:inspect')
            ? () => focusSection(section.uid)
            : undefined
        }
        point={midPoint}
        visible={visible}
      >
        <MapMarkerToolbar
          enabled={hasFeature('Elements:edit') && visible}
          tools={[
            {
              icon:
                focusedElementUid === section.uid ? (
                  <Icons.EyeOff />
                ) : (
                  <Icons.Eye />
                ),
              onClick: () => toggleFocusSection(section.uid),
            },
          ]}
        >
          <Label alignChildren="middle" flex="vertical" size="x1">
            <Text strong>{reportEditor.getSectionName(section)}</Text>

            {length && (
              <Text weak>
                <Distance inline type="long" value={round(length, 2)} />
              </Text>
            )}
          </Label>
        </MapMarkerToolbar>
      </MapLabel>

      {isDesktop() &&
        observations.length < 20 &&
        (showObservations || (focusedElementUid && visible)) &&
        observations.map((observation) => (
          <ReportMapObservation
            key={observation.uid}
            observation={observation}
            showOnHover={true}
            visible={true}
          />
        ))}
    </>
  );
};

export const reportMapBorderColorMap: Record<
  SectionUseCode,
  string | undefined
> = {
  C: 'purple',
  F: 'red',
  S: 'blue',
  T: 'black',
  W: 'black',
  Z: 'black',

  CB_PACP: 'black',
  DP_PACP: 'black',
  FM_PACP: 'black',
  LG_PACP: 'black',
  LP_PACP: 'black',
  PR_PACP: 'black',
  SS_PACP: 'black',
  SW_PACP: 'black',
  XX_PACP: 'black',
  ZZ_PACP: 'black',
};

export default ReportMapSection;
