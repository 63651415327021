import { useResizeObserver } from 'preshape';
import React, { useEffect, useMemo, useState } from 'react';
import { useGeometryStoreContext } from './GeometryStore';
import { GeoStoreGeometry, GeoStoreEntryOptions, GeoStoreEntry } from './types';

type Opts<T extends GeoStoreGeometry> = {
  geometry?: T | null;
  opts: GeoStoreEntryOptions;
};

type useGeometryStoreRegisterResult<T extends GeoStoreGeometry> = [
  GeoStoreEntry<T> | undefined,
  React.Ref<HTMLElement>
];

const useGeometryStoreRegister = <T extends GeoStoreGeometry>({
  geometry,
  opts,
}: Opts<T>): useGeometryStoreRegisterResult<T> => {
  const { store, update, register, remove } = useGeometryStoreContext();
  const [size, setNode] = useResizeObserver<HTMLElement>();
  const [registerKey, setRegisterKey] = useState<string>();
  const registerEntry = registerKey ? store.map[registerKey] : undefined;

  const optsMemo = useMemo(
    () => opts,
    [
      opts.anchor,
      opts.id,
      opts.keepInFocus,
      opts.padding,
      opts.shiftFromCollisions,
      opts.type,
    ]
  );

  useEffect(() => {
    if (geometry) {
      if (registerKey) {
        update(registerKey, geometry, optsMemo, size);
      } else {
        setRegisterKey(register(geometry, optsMemo, size));
      }
    }

    return () => {
      if (registerKey) {
        remove(registerKey);
      }
    };
  }, [register, remove, update, optsMemo, geometry, registerKey, size]);

  return [registerEntry, setNode];
};

export default useGeometryStoreRegister;
