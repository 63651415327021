import { ReportElementType } from '@drainify/utils';
import {
  Button,
  Buttons,
  Icons,
  Modal,
  ModalBody,
  ModalHeader,
  Text,
} from 'preshape';
import React from 'react';
import Distance from '../../../DistanceInput/Distance';
import DistanceInput from '../../../DistanceInput/DistanceInput';
import MapSidebarContentItem from '../../../Map/MapSidebar/MapSidebarContentItem';
import MapSidebarContentItemSection from '../../../Map/MapSidebar/MapSidebarContentItemSection';
import SummaryCard from '../../../SummaryCard/SummaryCard';
import SummaryCardSection from '../../../SummaryCard/SummaryCardSection';
import { useReportEditorContext } from '../../ReportEditorProvider';
import { useReportMapContext } from '../ReportMapProvider';

const ReportMapSidebarMeasurementScale = () => {
  const { activeSidebar, focusedElementType, unfocus, setActiveSidebar } =
    useReportMapContext();
  const { reportEditor } = useReportEditorContext();

  const handleBack = () => {
    unfocus();
    setActiveSidebar('tools');
  };

  const [modalVisible, setModalVisible] = React.useState(false);
  const [distance, setDistance] = React.useState<number>();

  React.useEffect(() => {
    setDistance(Number(reportEditor.report.scale?.userLength) || 0);
  }, [modalVisible]);

  const handleConfirm = () => {
    reportEditor.updateScale({
      userLength: distance,
    });
    setModalVisible(false);
  };

  const handleRemove = () => {
    reportEditor.removeScale();
    handleBack();
  };

  return (
    <>
      <MapSidebarContentItem
        active={
          activeSidebar === 'info' &&
          focusedElementType === ReportElementType.SCALE
        }
        onBack={handleBack}
        title={'Scale'}
      >
        <MapSidebarContentItemSection>
          <SummaryCard title="Additional information">
            <SummaryCardSection
              title="Length"
              onEdit={() => setModalVisible(true)}
            >
              {reportEditor.report.scale &&
              reportEditor.report.scale.geoLength &&
              reportEditor.report.scale.userLength ? (
                <>
                  <Distance
                    value={reportEditor.report.scale.userLength}
                    type={'long'}
                  />
                </>
              ) : (
                <Text weak>Not set</Text>
              )}
            </SummaryCardSection>
          </SummaryCard>
        </MapSidebarContentItemSection>

        <MapSidebarContentItemSection>
          <Buttons>
            <Button
              basis="0"
              grow
              color="negative"
              variant="tertiary"
              flex="horizontal"
              gap="x2"
              onClick={handleRemove}
            >
              <Text>Delete scale</Text>
              <Icons.Trash2 />
            </Button>
          </Buttons>
        </MapSidebarContentItemSection>
      </MapSidebarContentItem>
      <Modal visible={modalVisible}>
        <ModalHeader>Set scale length</ModalHeader>
        <ModalBody flex="vertical" gap="x2">
          <DistanceInput
            onChange={(e) => setDistance(e)}
            type={'long'}
            value={distance}
          />
          <Buttons>
            <Button
              basis="0"
              color="accent"
              gap="x2"
              grow
              onClick={handleConfirm}
              size="x3"
              variant="primary"
            >
              <Text>Confirm</Text>
              <Icons.Check />
            </Button>
            <Button
              basis="0"
              grow
              onClick={() => setModalVisible(false)}
              size="x3"
            >
              <Text>Cancel</Text>
            </Button>
          </Buttons>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ReportMapSidebarMeasurementScale;
