export type PlanPostBody = {
  point?: GeoJSON.Polygon;
  name: string;
  rotate?: number;
  opacity?: number;
  imageUrl: string;
  originalWidth: number;
  originalHeight: number;
  isToScale: boolean;
};

export type Plan = PlanPostBody & {
  uid: string;
};
