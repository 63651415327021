import React, { createContext, PropsWithChildren } from 'react';
import SigningInWrapper from './SigningInWrapper';
import useAuth, { UseAuthResult } from './useAuth';

export const AuthenticationContext = createContext<UseAuthResult>({
  error: null,
  isFetching: true,
  user: null,
});

const Authenticate = ({ children }: PropsWithChildren<{}>) => {
  const auth = useAuth();

  return (
    <AuthenticationContext.Provider value={auth}>
      <SigningInWrapper>{children}</SigningInWrapper>
    </AuthenticationContext.Provider>
  );
};

export default Authenticate;
