import { Wrapper as GoogleMapsWrapper } from '@googlemaps/react-wrapper';
import React from 'react';
import { render } from 'react-dom';
import { Route, Routes } from 'react-router-dom';
import Api from './components/Api/Api';
import App from './components/App/App';
import Authenticate from './components/Authentication/Authenticate';
import Bootstrap from './components/Bootstrap/Bootstrap';
import ServiceWorkerProvider from './components/Bootstrap/ServiceWorkerProvider';
import GeoLocation from './components/GeoLocation/GeoLocation';
import Logging from './components/Logging/Logging';
import GeometryStoreFocusEverything from './components/Map/useGeometryStore/GeometryStoreFocusOnEverything';
import Notifications from './components/Notifications/Notifications';
import PWANotifications from './components/Notifications/PWANotifications';
import OrgProvider from './components/Org/OrgProvider';
import Permissions from './components/Permissions/Permissions';
import ReportMapFocusOnElement from './components/Report/ReportMap/ReportMapFocusOnElement';
import ReportAccessPage from './components/ReportAccessPage/ReportAccessPage';
import TranscodeUsageProvider from './components/TranscodeUsage/TranscodeUsageProvider';
import LanguageProvider from './components/Usage/LanguageProvider';
import UsageProvider from './components/Usage/UsageProvider';
import UserProvider from './components/User/UserProvider';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import InvalidIOSVersionPage from './pages/InvalidIOSVersionPage';
import InviteAcceptPage from './pages/InviteAcceptPage';
import LoginPage from './pages/LoginPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import SignUpPage from './pages/SignUpPage';
import SignupRequestPage from './pages/mobile/Pages/SignupRequestPage';

import './utils/firebase';



declare module 'react' {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}
// End source.

if ('serviceWorker' in navigator && process.env.NODE_ENV !== 'development') {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register(new URL('./service-worker.js', import.meta.url), {
        type: 'module',
        scope: '/',
      })
  });
}
const isIOS15OrBelow = () => {
    const userAgent = window.navigator.userAgent;
    const iOSMatch = userAgent.match(/OS (\d+)_/); // Detect iOS version
    if (iOSMatch && iOSMatch[1]) {
      const iOSVersion = parseInt(iOSMatch[1], 10);
      return iOSVersion <= 15;
    }
    return false;
  }

if (!process.env.GOOGLE_MAPS_API_KEY) {
  throw new Error('Google Maps API key is not set');
}

const rootElement = document.getElementById('Root');
if (isIOS15OrBelow() && rootElement) {
  render(<InvalidIOSVersionPage />, rootElement)
} else if (rootElement) {
  render(
    <Bootstrap>
      <Authenticate>
        <Routes>
          <Route element={<LoginPage />} path="/login" />
          <Route element={<SignupRequestPage />} path="/signup" />
          <Route element={<ForgotPasswordPage />} path="/forgot-password" />
          <Route element={<ResetPasswordPage />} path="/reset-password" />
          <Route
            element={
              <Api>
                <SignUpPage />
              </Api>
            }
            path="/signup/:signupUid"
          />
          <Route
            element={
              <Api>
                <InviteAcceptPage />
              </Api>
            }
            path="/invite/:inviteUid/accept"
          />
          <Route
            element={
              <Notifications>
                <Logging>
                  <Api>
                    <GoogleMapsWrapper
                      apiKey={process.env.GOOGLE_MAPS_API_KEY}
                      libraries={['places']}
                    >
                      <Routes>
                        <Route path="/report" element={<ReportAccessPage />}>
                          <Route
                            index
                            element={<GeometryStoreFocusEverything />}
                          />

                          <Route
                            path=":type/:uid"
                            element={
                              <ReportMapFocusOnElement withNotification />
                            }
                          />
                        </Route>
                        <Route
                          element={
                            <Permissions>
                              <LanguageProvider>
                                <GeoLocation>
                                  <PWANotifications>
                                    <UserProvider>
                                      <OrgProvider>
                                        <UsageProvider>
                                          <TranscodeUsageProvider>
                                            <ServiceWorkerProvider>
                                              <App />
                                            </ServiceWorkerProvider>
                                          </TranscodeUsageProvider>
                                        </UsageProvider>
                                      </OrgProvider>
                                    </UserProvider>
                                  </PWANotifications>
                                </GeoLocation>
                              </LanguageProvider>
                            </Permissions>
                          }
                          path="*"
                        />
                      </Routes>
                    </GoogleMapsWrapper>
                  </Api>
                </Logging>
              </Notifications>
            }
            path="/*"
          />
        </Routes>
      </Authenticate>
    </Bootstrap>,
    rootElement
  );
}
