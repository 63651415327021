import { MapLayerCircle, MapLayerReturn, MapLayerUpdateFn } from '../useMap';

const getLngLatFromPoint = (point: GeoJSON.Point) => {
  return new google.maps.LatLng(point.coordinates[1], point.coordinates[0]);
};

const getGeometryCircle = (
  map: google.maps.Map,
  layer: MapLayerCircle
): MapLayerReturn<MapLayerCircle> => {
  const circle = new google.maps.Circle({
    map,
    center: layer.geometry ? getLngLatFromPoint(layer.geometry) : undefined,
    ...layer.style,
  });

  let prevLayer = layer;

  const set = (layer: MapLayerCircle) => {
    circle.setOptions({
      center: layer.geometry ? getLngLatFromPoint(layer.geometry) : undefined,
      // radius,
      ...layer.style,
    });

    prevLayer = layer;
  };

  const update: MapLayerUpdateFn<MapLayerCircle> = (value) => {
    if (typeof value === 'function') {
      set(value(prevLayer));
    } else if (value) {
      set({
        ...prevLayer,
        ...value,
      });
    }
  };

  const remove = () => {
    circle.setMap(null);
  };

  return { update, remove };
};

export default getGeometryCircle;
