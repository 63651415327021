import { Button, ButtonProps, Icons, Text } from 'preshape';
import React, { ChangeEvent, PropsWithChildren } from 'react';

export type ImageSelectData = {
  base64String?: string;
  file: File;
};

type Props = ButtonProps & {
  onSelect: (data: ImageSelectData) => void;
};

const ImageSelectButton = ({
  children,
  onSelect,
  ...props
}: PropsWithChildren<Props>) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { files },
    } = event;

    const file = files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        onSelect({
          base64String: reader.result?.toString(),
          file,
        });
      });
      reader.readAsDataURL(file);
    }
  };

  return (
    <Button {...props} color="accent" variant="tertiary" className="FileUpload" gap="x2" tag="label">
      <input
        accept="image/*"
        className="FileUpload__input"
        onChange={handleChange}
        style={{ position: 'absolute', top: 0, width: 0 }}
        type="file"
      />
      <Text>{children}</Text>
      <Icons.Upload />
    </Button>
  );
};

export default ImageSelectButton;
