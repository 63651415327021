import { ObservationOptionBoolean } from '@drainify/utils';
import {
  InputLabelProps,
  InputLabel,
  CheckBox,
  Text,
  Box,
  Icons,
} from 'preshape';
import React, { forwardRef, ForwardRefRenderFunction, useEffect } from 'react';

type Props = InputLabelProps & {
  asSummary?: boolean;
  asIcon?: boolean;
  name?: string;
  option: ObservationOptionBoolean;
  onSelect: (value: boolean) => void;
  value?: boolean;
};

const ObservationOptionBooleanInput: ForwardRefRenderFunction<
  HTMLLabelElement,
  Props
> = ({ asSummary, asIcon, name, option, onSelect, value, ...rest }, ref) => {
  // Default set for boolean codes
  useEffect(() => {
    if (value === undefined) {
      onSelect(false);
    }
  }, []);

  if (asSummary) {
    return (
      <Box flex="horizontal">
        <Box grow>
          {
            !asIcon && <Text>{value ? 'Yes' : 'No'}</Text>
          }
        </Box>

        <Box
          alignChildren="middle"
          borderSize="x3"
          borderRadius="x3"
          flex="horizontal"
          height={40}
          width={40}
        >
          {value ? (
            <Icons.Check size="2rem" textColor="accent-shade-4" />
          ) : (
            <Icons.X size="2rem" textColor="negative-shade-4" />
          )}
        </Box>
      </Box>
    );
  }

  return (
    <InputLabel {...rest} ref={ref}>
      <CheckBox checked={!!value} name={name} onChange={() => onSelect(!value)} borderSize='x1'>
        {
          !asIcon && <Text>{option.name}</Text>
        }
        <Text weak>{option.description}</Text>
      </CheckBox>
    </InputLabel>
  );
};

export default forwardRef(ObservationOptionBooleanInput);
