import { User, ResponseError, UserPostBody } from '@drainify/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useFetch, { hasAllParams } from '../components/Api/useFetch';
import { DataHookSingleEntity } from '.';

const useUser: DataHookSingleEntity<'userId', User, UserPostBody> = ({
  userId,
}) => {
  const queryClient = useQueryClient();
  const fetch = useFetch();
  const key = [`/users/${userId}`, fetch];
  const params = { userId: userId as string };

  const query = useQuery<User, ResponseError>(
    key,
    () => fetch('/users/:userId', { method: 'GET', params }),
    { enabled: hasAllParams(params) }
  );

  const update = useMutation<User, ResponseError, UserPostBody>(
    (body) => fetch('/users/:userId', { method: 'PUT', body, params }),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(key, data);
        queryClient.refetchQueries([`/me`]);
      },
    }
  );

  const remove = useMutation<void, ResponseError>(
    () => fetch('/users/:userId', { method: 'DELETE', params }),
    {
      onSuccess: () => {
        queryClient.setQueryData(key, null);
      },
    }
  );

  return { query, update, remove };
};

export default useUser;
