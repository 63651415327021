import { Box } from 'preshape';
import React, { useContext } from 'react';
import { isDesktop } from '../../utils/client';
import { NodeMapContext } from '../Node/NodeMapProvider';

type Props = {
  onChange: (point: GeoJSON.Point[]) => void;
};

const DrawingLocationhack = ({ onChange }: Props) => {
  const mobile = !isDesktop();
  if (!mobile) {
    return null;
  }
  const { setOnChangePoints, setVisible, setMarkType } =
    useContext(NodeMapContext);

  React.useEffect(() => {
    setVisible(true);
    setMarkType('drawing-point');
    setOnChangePoints(onChange);
    return () => {
      setOnChangePoints(() => {});
      setVisible(false);
      setMarkType(undefined);
    };
  }, []);

  return <Box width="calc(100vw - 100px)" height="calc(100vh - 380px)" />;
};

export default DrawingLocationhack;
