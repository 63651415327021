import { Customer, CustomerPostBody, ResponseError } from '@drainify/types';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';
import { UseQueryResult } from 'react-query';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import useCustomer from '../../hooks/useCustomer';
import { useProjectContext } from '../Project/ProjectProvider';
import CustomerModal from './CustomerModal';

type Context = {
  isRemoving: boolean;
  isRemovingFailed: boolean;
  isRemovingSuccess: boolean;
  isUpdating: boolean;
  isUpdatingFailed: boolean;
  isUpdatingSuccess: boolean;
  customer?: Customer;
  query: UseQueryResult<Customer, ResponseError>;
  edit: (stepId?: string) => void;
  remove: () => Promise<void>;
  backToLink: string;
  backToText: string;
};

const CustomerContext = createContext<Context>({
  isRemoving: false,
  isRemovingFailed: false,
  isRemovingSuccess: false,
  isUpdating: false,
  isUpdatingFailed: false,
  isUpdatingSuccess: false,
  customer: {} as unknown as Customer,
  edit: () => {},
  query: {} as UseQueryResult<Customer, ResponseError>,
  remove: () => Promise.reject(new Error('Context')),
  backToText: '',
  backToLink: '',
});

export const useCustomerContext = () => useContext(CustomerContext);

type Props = {
  backToLink: string;
  backToText: string;
};

const CustomerProvider = ({
  backToText,
  backToLink,
}: PropsWithChildren<Props>) => {
  const navigate = useNavigate();
  const { customerId } = useParams();
  const { query, update, remove: removeMutation } = useCustomer(customerId);
  const [activeEditStep, setActiveEditStep] = useState<string>();
  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);
  const { activeBookingId } = useProjectContext();
  const finalBacklink =
    backToLink + (activeBookingId ? `?activeBookingId=${activeBookingId}` : '');

  const handleOpenModal = (stepId?: string) => {
    setIsCustomerModalOpen(true);
    setActiveEditStep(stepId);
  };

  const handleCloseModal = () => {
    setIsCustomerModalOpen(false);
    setActiveEditStep(undefined);
  };

  const remove = async () => {
    await removeMutation.mutateAsync();
    navigate(finalBacklink);
  };

  const onUpdate = async (customer: CustomerPostBody) => {
    await update.mutateAsync(customer);
    handleCloseModal();
  };

  const context: Context = {
    isRemoving: removeMutation.isLoading,
    isRemovingFailed: removeMutation.isError,
    isRemovingSuccess: removeMutation.isSuccess,
    isUpdating: update.isLoading,
    isUpdatingFailed: update.isError,
    isUpdatingSuccess: update.isSuccess,
    customer: query.data,
    query,
    edit: handleOpenModal,
    remove,
    backToLink: finalBacklink,
    backToText,
  };

  return (
    <CustomerContext.Provider value={context}>
      <Outlet />

      <CustomerModal
        initialActiveStepId={activeEditStep}
        onClose={handleCloseModal}
        visible={isCustomerModalOpen}
        customer={query.data}
        onSave={onUpdate}
      />
    </CustomerContext.Provider>
  );
};

export default CustomerProvider;
