import { Buttons, Text } from 'preshape';
import React from 'react';
import Explainer from '../../../components/Explainer/Explainer';
import DrawingCreateButton from '../../../components/Measurement/DrawingCreateButton';
import DrawingList from '../../../components/Measurement/DrawingList';
import { useReportEditorContext } from '../../../components/Report/ReportEditorProvider';
import Page from '../Layout/Page';
import PageBody from '../Layout/PageBody';
import PageHeader from '../Layout/PageHeader';
import PageMenu from '../Layout/PageMenu';

const SiteDrawPolygonPage = () => {
  const { editDrawing } = useReportEditorContext();
  return (
    <Page>
      <PageHeader
        title="Polygons"
        action={
          <Explainer title="Drawing polygons">
            <Text>
              The polygon drawing tool allows you to draw arbitury polygons onto
              the map to describe things like extensions etc...
            </Text>
          </Explainer>
        }
      />
      <PageBody flex="vertical" gap="x3" overflow="auto" basis="0" grow>
        <PageMenu margin="x3">
          <DrawingList onItemClick={(uid) => editDrawing(uid)} />
        </PageMenu>
      </PageBody>
      <Buttons padding="x6">
        <DrawingCreateButton variant="primary" grow />
      </Buttons>
    </Page>
  );
};

export default SiteDrawPolygonPage;
