import { Appear, Button, Buttons, Icons, Text } from 'preshape';
import React, { PropsWithChildren, useState } from 'react';

type Props = PropsWithChildren<{}> & {
  content?: string;
};

const HiddenContent = ({ children, content }: Props) => {
  const [isAdditionalDetailsVisible, setIsAdditionalDetailsVisible] =
    useState(false);

  return (
    <>
      {!isAdditionalDetailsVisible && (
        <Buttons>
          <Button
            gap="x2"
            grow
            onClick={() => setIsAdditionalDetailsVisible(true)}
            variant="tertiary"
            size="x3"
          >
            <Text>{content || 'Show additional header info'}</Text>
            <Icons.ChevronDown />
          </Button>
        </Buttons>
      )}

      <Appear animation="Expand" visible={isAdditionalDetailsVisible}>
        {children}
      </Appear>
    </>
  );
};

export default HiddenContent;
