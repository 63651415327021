import { Inspection, VideoTranscodeStatus } from '@drainify/types';
import { ApiError, formatFileSize } from '@drainify/utils';
import {
  Alert,
  Box,
  Button,
  ButtonProps,
  Buttons,
  CheckBox,
  Icons,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Text,
} from 'preshape';
import React from 'react';
import { useParams } from 'react-router';
import useFileUpload from '../../hooks/useFileUpload';
import useVideoPostUpload from '../../hooks/useVideoProcess';
import useFetch from '../Api/useFetch';
import { FULL_SCREEN_MODAL_WIDTH } from '../App/App';
import FileUpload from '../FileUpload/FileUpload';
import AILogo from '../IconsMisc/AILogo';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import { useUsageContext } from '../Usage/UsageProvider';

type Props = ButtonProps & {
  inspection?: Inspection;
};

const VideoAddReplaceButton = ({ inspection }: Props) => {
  const { projectId } = useParams();
  const nodeFileUpload = useFileUpload();
  const [fileToUpload, setFileToUpload] = React.useState<File>();
  const [modalVisible, setModalVisible] = React.useState(false);
  const fetch = useFetch();
  const [useBuddy, setUseBuddy] = React.useState(true)
  const { postVideupUpload } = useVideoPostUpload(projectId, inspection?.uid, useBuddy);
  const isRemoving = !!inspection?.video;
  const { reportEditor } = useReportEditorContext();
  const { usage } = useUsageContext();
  const [error, setError] = React.useState<string>();
  const { noStorageUpsell } = useUsageContext();

  React.useEffect(() => {
    setFileToUpload(undefined);
    nodeFileUpload.reset();
    setError(undefined);
  }, [modalVisible]);

  React.useEffect(() => {
    if (usage && usage?.buddyTokens <= 0) {
      setUseBuddy(false)
    }
  }, [usage])

  React.useEffect(() => {
    if (nodeFileUpload.isSuccess) {
      setModalVisible(false);
    }
  }, [nodeFileUpload.isSuccess]);

  const handleSelectFile = async (file: File) => {
    setFileToUpload(file);
  };

  const uploadVideo = async () => {
    try {
      if (fileToUpload) {
        const signedUrl = await fetch(
          '/video/:projectId/:inspectionId/preupload',
          {
            method: 'POST',
            params: {
              projectId: projectId!,
              inspectionId: inspection!.uid,
            },
            body: {
              fileSize: fileToUpload.size,
            },
          }
        );
        await nodeFileUpload.uploadSignedUrl(signedUrl, fileToUpload);
        postVideupUpload.mutate();
      }
    } catch (error) {
      if (error instanceof ApiError) {
        setError(error.message);
        if (error.code === 'drainify/storage-limit-exceeded') {
          noStorageUpsell();
        }
      } else {
        setError('An error occured');
      }
    }
  };

  const handleDelete = async () => {
    if (inspection) {
      reportEditor.updateInspection(inspection.uid, {
        video: undefined,
      });
      setModalVisible(false);
    }
  };

  if (!usage) {
    return null;
  }

  return (
    <>
      <Box flex='vertical'>
        <Button
          color={isRemoving ? 'negative' : 'accent'}
          gap="x2"
          onClick={() => setModalVisible(true)}
          type="button"
          variant="tertiary"
          disabled={inspection?.video?.status === VideoTranscodeStatus.PENDING}
        >
          {isRemoving ? (
            <>
              <Icons.Trash2 size="1rem" />
            </>
          ) : (
            <>
              <Text>Add video</Text>
              <Icons.Film size="1rem" />
            </>
          )}
        </Button>
      </Box>

      <Modal
        animation="FadeSlideUp"
        margin="x4"
        maxWidth={FULL_SCREEN_MODAL_WIDTH}
        overlayBackgroundCloseOnClick={false}
        visible={modalVisible}
      >
        <ModalHeader>
          <ModalTitle>
            {inspection?.video ? (
              <Text>Remove video</Text>
            ) : (
              <Text>Add video</Text>
            )}
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          {inspection?.video ? (
            <Box margin="x8">
              <Text strong size="x6">
                Delete video
              </Text>
              <Text>Are you sure you want to delete this video?</Text>
            </Box>
          ) : (
            <Box flex="vertical" gap="x3">
              <FileUpload
                accept="video/*"
                maxWidth="300px"
                onChange={handleSelectFile}
                value={fileToUpload}
              >
                <Text
                  style={{
                    WebkitUserSelect: 'none',
                    msUserSelect: 'none',
                    userSelect: 'none',
                    WebkitTouchCallout: 'none',
                    KhtmlUserSelect: 'none',
                    MozUserSelect: 'none',
                  }}
                >
                  Upload
                </Text>
              </FileUpload>
              <Box flex='horizontal' alignChildrenHorizontal='middle' grow paddingBottom='x3'>
                {
                  usage && usage?.buddyTokens < 1 ?
                    <Box gap='x2' backgroundColor='background-shade-3' textColor='dark-shade-3' flex='horizontal' padding='x3' borderRadius='15px'>
                      <AILogo fill='grey' />
                      <Text strong size='x2'> You are out of Buddy tokens </Text>
                    </Box>
                    :
                    <CheckBox checked={useBuddy} onChange={() => setUseBuddy(!useBuddy)} borderSize='x0'>
                      <Box flex='horizontal' gap='x2'>
                        <Text flex='horizontal'> Use </Text> <AILogo />
                      </Box>
                    </CheckBox>
                }
              </Box>
            </Box>
          )}

          <Box>
            <Box
              backgroundColor="accent-shade-1"
              maxWidth="300px"
              borderRadius="10px"
              borderColor="text-shade-4"
            >
              <Box
                borderRadius="10px"
                height="6px"
                backgroundColor="accent-shade-5"
                width={`${nodeFileUpload.isLoadingProgress}%`}
              />
            </Box>
          </Box>
          {usage && (
            <Box>
              <Text weak align="middle" size="x3">
                Your remaining allowance is{' '}
                {formatFileSize(
                  usage.additionalStorage +
                  usage.totalStorage -
                  usage?.usedStorage,
                  true,
                  0
                )}
                .
              </Text>
            </Box>
          )}
          {error && (
            <Alert
              padding="x2"
              color="negative"
              backgroundColor="negative-shade-2"
              borderSize="x2"
            >
              <Text strong size="x2">
                {error}
              </Text>
            </Alert>
          )}
          <Alert color={'accent'} padding='x2' backgroundColor='accent-shade-2'>
            <Text size='x1' strong align='middle'>We advise you upload video only on WiFi</Text>
          </Alert>
        </ModalBody>

        <ModalFooter>
          <Buttons>
            <>
              {inspection?.video ? (
                <Button
                  color="negative"
                  elevate={true}
                  grow
                  onClick={handleDelete}
                  variant="primary"
                  size="x3"
                >
                  Delete
                </Button>
              ) : (
                <Button
                  color={fileToUpload && 'accent'}
                  disabled={
                    fileToUpload === undefined || nodeFileUpload.isLoading
                  }
                  elevate={true}
                  grow
                  onClick={uploadVideo}
                  variant="primary"
                  size="x3"
                >
                  <Text>Upload</Text>
                </Button>
              )}
            </>
            <Button
              size="x3"
              grow
              onClick={() => setModalVisible(false)}
              disabled={nodeFileUpload.isLoading}
              variant="secondary"
            >
              Cancel
            </Button>
          </Buttons>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default VideoAddReplaceButton;
