import { Conflict } from '@drainify/types';
import { getAuth } from 'firebase/auth';
import { DateTime } from 'luxon';
import { Box, Icons, Modal, ModalBody, ModalHeader, Text } from 'preshape';
import React, { PropsWithChildren, useContext } from 'react';
import { useQueryClient } from 'react-query';
import { MOBILE_APP_WIDTH } from '../App/App';
import ConflictResolutionItem from './ConflictResolutionItem';

export const ServiceWorkerContext = React.createContext<{
  updating: boolean;
  pendingChanges: boolean;
  filesPending: number;
  filesTotal: number;
}>({
  updating: false,
  pendingChanges: false,
  filesPending: 0,
  filesTotal: 0,
});

export const useAppContext = () => useContext(ServiceWorkerContext);

const ServiceWorkerProvider = ({ children }: PropsWithChildren<{}>) => {
  const queryClient = useQueryClient();
  const [updating, setUpdating] = React.useState(false);
  const [pendingChanges, setPendingChanges] = React.useState(false);
  const [filesPending, setFilesPending] = React.useState(0);
  const [filesTotal, setFilesTotal] = React.useState(0);
  const [conflict, setConflict] = React.useState<Conflict>();

  React.useEffect(() => {
    updateToken();
    const interval = setInterval(() => {
      updateToken();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    getState();
    const interval = setInterval(() => {
      if (conflict === undefined) {
        getState();
      }
    }, 500);

    return () => clearInterval(interval);
  }, []);

  const resolveConflict = (url: string, callback: () => void) => {
    const messageChannel = new MessageChannel();
    messageChannel.port1.onmessage = () => {
      callback();
      messageChannel.port1.close();
      messageChannel.port2.close();
    };
    window.navigator.serviceWorker.controller?.postMessage(
      {
        action: 'RESOLVE_CONFLICT',
        url: url,
      },
      [messageChannel.port2]
    );
  };

  const getState = () => {
    const messageChannel = new MessageChannel();
    messageChannel.port1.onmessage = (event) => {
      setUpdating(event.data.updating);
      setPendingChanges(event.data.lastAttemptState === 'failed');
      setFilesPending(event.data.filesPending);
      setFilesTotal(event.data.filesTotal);
      setConflict(event.data.conflict);
      messageChannel.port1.close();
      messageChannel.port2.close();
    };
    window.navigator.serviceWorker.controller?.postMessage(
      {
        action: 'GET_STATE',
      },
      [messageChannel.port2]
    );
  };

  const updateToken = async () => {
    getUserIdTokenWithTimeout(3000)
      .then((token) => {
        const message = {
          action: 'UPDATE_TOKEN',
          token,
        };
        window.navigator.serviceWorker.controller?.postMessage(message);
      })
      .catch(() => {
        const message = {
          action: 'UPDATE_TOKEN',
          token: undefined,
        };
        window.navigator.serviceWorker.controller?.postMessage(message);
      });
  };

  function getUserIdTokenWithTimeout(timeout: number) {
    // Create a promise that resolves after the specified timeout
    let resolved = false;
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        if (!resolved) {
          reject(new Error(`Timeout after ${timeout}ms`));
        }
      }, timeout);
    });

    // Create a promise that gets the user's ID token
    const idTokenPromise = getAuth()
      .currentUser?.getIdToken()
      .then((res) => {
        resolved = true;
        return res;
      });

    // Return a promise that resolves with the user's ID token or rejects with a timeout error
    return Promise.race([idTokenPromise, timeoutPromise]);
  }

  return (
    <ServiceWorkerContext.Provider
      value={{
        pendingChanges,
        updating,
        filesPending,
        filesTotal,
      }}
    >
      {
        conflict !== undefined && (
          <Modal visible zIndex={30} maxWidth={MOBILE_APP_WIDTH}>
            <ModalHeader>
              <Box flex="horizontal" alignChildrenVertical="middle" gap="x1">
                <Icons.AlertCircle />
                Alert
              </Box>
            </ModalHeader>
            <ModalBody>
              <Text strong size="x6">
                Conflict detected!
              </Text>
              <Box padding="x2" flex="vertical" gap="x3">
                <Box>
                  <Text>
                    {`"${conflict.jobId}" had been edited since you last updated with conflicting information`}
                  </Text>
                </Box>
                <Box>
                  <Text size="x4" strong>
                    You will need to choose a version
                  </Text>
                </Box>

                <Box
                  flex="horizontal"
                  alignChildrenHorizontal="around"
                  gap="x1"
                >
                  <ConflictResolutionItem
                    icon={<Icons.HardDrive size="2rem" />}
                    origin={'Local'}
                    originState={conflict.localDetails}
                    conflictingState={conflict.cloudDetails}
                    onClick={async () => {
                      fetch(
                        process.env.DRAINIFY_API_URL +
                          conflict.url +
                          'overwrite=true',
                        {
                          method: 'PUT',
                          body: JSON.stringify(conflict.proposedReport),
                          headers: {
                            authorization: 'Bearer to-be-replaced',
                            'Content-Type': 'application/json',
                          },
                        }
                      );

                      setConflict(undefined);
                    }}
                  />
                  <ConflictResolutionItem
                    icon={<Icons.Cloud size="2rem" />}
                    origin={'Cloud'}
                    originState={conflict.cloudDetails}
                    conflictingState={conflict.localDetails}
                    onClick={() =>
                      resolveConflict(conflict.url, () => {
                        queryClient.refetchQueries([
                          `/projects/${conflict.projectId}/report`,
                        ]);
                      })
                    }
                  />
                </Box>
                <Box>
                  <Text size="x1">
                    {`Cloud version was updated at ${
                      conflict.cloudDetails.updatedAt !== '' &&
                      DateTime.fromJSDate(
                        new Date(conflict.cloudDetails.updatedAt)
                      ).toFormat('DD MM@HH:mm')
                    }`}
                  </Text>
                </Box>
              </Box>
            </ModalBody>
          </Modal>
        )
        // updating && (
        // <Box
        // backgroundColor="accent-shade-4"
        // flex="horizontal"
        // alignChildrenHorizontal="middle"
        // >
        // </ServiceWorkerContext.Provider><Text textColor="white" strong flex="horizontal">
        // </ServiceWorkerContext.Provider>Publishing changes to server.
        // </ServiceWorkerContext.Provider></Text>
        // </ServiceWorkerContext.Provider><Spinner />
        // </ServiceWorkerContext.Provider></Box>
        // )
      }

      {
        // success && (
        // <Box
        // backgroundColor="positive-shade-4"
        // flex="horizontal"
        // alignChildrenHorizontal="middle"
        // >
        // <Text textColor="white" strong flex="horizontal">
        // Successfully updated
        // </Text>
        // </ServiceWorkerContext.Provider></Box>
        // )
      }

      {
        // unpublishedChanges && (
        // <Box
        // backgroundColor="accent-shade-5"
        // flex="horizontal"
        // alignChildrenHorizontal="middle"
        // >
        // <Text textColor="white" strong flex="horizontal">
        // You have unpublished changes.
        // </Text>
        // </ServiceWorkerContext.Provider></Box>
        // )
      }
      {children}
    </ServiceWorkerContext.Provider>
  );
};

export default ServiceWorkerProvider;
