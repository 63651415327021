import { NodeCode, SectionNodePosition } from '@drainify/types';
import { getNodeSchema } from '@drainify/utils';
import { Box, BoxProps, Text } from 'preshape';
import React from 'react';
import PlaceholderBlock from '../Placeholders/PlaceholderBlock';
import { useLanguageContext } from '../Usage/LanguageProvider';
import NodeSymbol from './NodeSymbol';

type Props = BoxProps & {
  code?: NodeCode;
  nodePosition?: SectionNodePosition;
};

const NodeCodeView = ({ code, nodePosition, ...rest }: Props) => {
  const nodeSchema = code && getNodeSchema(code);
  const { language } = useLanguageContext();

  return (
    <Text flex="horizontal" gap="x4" {...rest} alignChildrenVertical="middle">
      <Box basis="0" grow>
        {nodeSchema ? (
          <Text breakOn="word" size="x3" strong>
            {nodeSchema?.name[language]}
          </Text>
        ) : (
          <PlaceholderBlock />
        )}
      </Box>

      <Box>
        <NodeSymbol code={code} nodePosition={nodePosition} />
      </Box>
    </Text>
  );
};

export default NodeCodeView;
