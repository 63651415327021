import { NodeCode } from '@drainify/types';
import { DateTime } from 'luxon';
import React, { useRef } from 'react';
import { useMapContext } from '../../../Map/Map';
import MapDroppable from '../../../Map/MapDroppable/MapDroppable';
import useMapDragAndDrop from '../../../Map/useMapDragAndDrop';
import { useProjectContext } from '../../../Project/ProjectProvider';
import { useReportEditorContext } from '../../ReportEditorProvider';
import ReportMapNodeRenderer from '../ReportMapElements/ReportMapNodeRenderer';

type Props = {
  code: NodeCode;
  name: string;
};

const ReportMapToolAddNode = ({ code, name }: Props) => {
  const { reportEditor, editNode } = useReportEditorContext();
  const { container } = useMapContext();
  const { project } = useProjectContext();
  const refContainer = useRef<HTMLDivElement>(null);

  const handleCreateNode = async (point: GeoJSON.Point) => {
    const createdNode = reportEditor.addNode({
      code,
      point,
      createdAt: DateTime.now().toISO(),
      attributes: {},
    });
    if (project?.siteDefaults?.showModalOnDrop) {
      editNode(createdNode.uid);
    }
  };

  const { onPointerDown } = useMapDragAndDrop({
    onDrop: handleCreateNode,
    ref: refContainer,
    target: container,
  });

  return (
    <MapDroppable
      name={code}
      onPointerDown={onPointerDown}
      ref={refContainer}
      title={name}
    >
      <ReportMapNodeRenderer code={code} locked={true} />
    </MapDroppable>
  );
};

export default ReportMapToolAddNode;
