import { Attributes, Icons, ImageProps, Text } from 'preshape';
import React from 'react';
import useProjectReportPolling from '../../hooks/useProjectReportPolling';
import Blueprint from '../Blueprint/Blueprint';
import { useProjectContext } from '../Project/ProjectProvider';

type Props = ImageProps;

const ImagePending = ({
  height,
  margin,
}: Attributes<HTMLImageElement, Props>) => {
  const { project } = useProjectContext();

  useProjectReportPolling({
    projectId: project?.uid,
  });

  return (
    <Blueprint
      container
      backgroundColor="background-shade-3"
      borderRadius="x2"
      borderSize="x2"
      height={height}
      margin={margin}
      overflow="hidden"
      width="100%"
    >
      <Text
        alignChildren="middle"
        absolute="center"
        flex="vertical"
        gap="x2"
        textColor="accent-shade-4"
      >
        <Icons.WifiOff size="2rem" />
        <Text>Image will appear when synced</Text>
      </Text>
    </Blueprint>
  );
};

export default ImagePending;
