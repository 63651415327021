import { ProjectType } from '@drainify/types';
import { getObservationSchema, getObservationsScores } from '@drainify/utils';
import { Box, Label, Text } from 'preshape';
import React from 'react';
import ObservationScoreBadge from '../Observations/ObservationScoreBadge';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';

type Props = {
  inspectionUid?: string;
};

const InspectionConditionBadges = ({ inspectionUid }: Props) => {
  const { reportEditor } = useReportEditorContext();
  const inspection = reportEditor.getInspectionByUid(inspectionUid);
  const observations = reportEditor.getInspectionObservations(inspection?.uid);
  const { activeBooking } = useProjectContext();

  if (
    activeBooking?.projectType === ProjectType.LACP ||
    activeBooking?.projectType === ProjectType.PACP
  ) {
    const observations = reportEditor.getInspectionObservations(
      inspection?.uid
    );
    const structureScores: Map<number, number> = new Map<number, number>();
    const serviceScores: Map<number, number> = new Map<number, number>();
    const overall: Map<number, number> = new Map<number, number>();

    for (const observation of observations.filter(
      (e) => e.observationContinuousState !== 'finish'
    )) {
      for (const code of observation.code) {
        const { scoringFunction } = getObservationSchema(code);
        const { service, structure } = scoringFunction(
          observation,
          reportEditor.getSectionByUid(inspection?.sectionUid)!
        );
        let increment = 1;

        if (observation.observationContinuousState === 'start') {
          const end = reportEditor.getObservationByUid(
            observation.linkedObservationUid
          );
          if (end?.distance && observation.distance) {
            const distance =
              Math.max(end.distance, observation.distance) -
              Math.min(end.distance, observation.distance);
            increment = Math.max(Math.round(distance / 1.5), 1);
          }
        }

        if (service) {
          serviceScores.set(
            service,
            (serviceScores.get(service) || 0) + increment
          );
          overall.set(service, (overall.get(service) || 0) + increment);
        }
        if (structure) {
          structureScores.set(
            structure,
            (structureScores.get(structure) || 0) + increment
          );
          overall.set(structure, (overall.get(structure) || 0) + increment);
        }
      }
    }
    // PACP/LACP
    return (
      <Box flex="vertical" gap="x1">
        <Text strong>Quick</Text>
        <Box flex="horizontal" gap="x2">
          <Label flex="vertical" alignChildren="middle">
            <Text size="x1">Structure</Text>
            <Text>{quickScore(structureScores)}</Text>
          </Label>
          <Label flex="vertical" alignChildren="middle">
            <Text size="x1">O&M</Text>
            <Text>{quickScore(serviceScores)}</Text>
          </Label>
        </Box>
        <Label flex="vertical" alignChildren="middle">
          <Text size="x1">Total</Text>
          <Text>{quickScore(overall)}</Text>
        </Label>
        <Text strong>Pipe rating</Text>
        <Box flex="horizontal" gap="x2">
          <Label flex="vertical" alignChildren="middle">
            <Text size="x1">Structure</Text>
            <Text>
              {Array.from(structureScores.entries()).reduce(
                (sum, current) => sum + current[0] * current[1],
                0
              )}
            </Text>
          </Label>
          <Label flex="vertical" alignChildren="middle">
            <Text size="x1">O&M</Text>
            <Text>
              {Array.from(serviceScores.entries()).reduce(
                (sum, current) => sum + current[0] * current[1],
                0
              )}
            </Text>
          </Label>
        </Box>
        <Label flex="vertical" alignChildren="middle">
          <Text size="x1">Total</Text>
          <Text>
            {Array.from(overall.entries()).reduce(
              (sum, current) => sum + current[0] * current[1],
              0
            )}
          </Text>
        </Label>

        <Text strong>Index</Text>
        <Box flex="horizontal" gap="x2">
          <Label flex="vertical" alignChildren="middle">
            <Text size="x1">Structure</Text>
            <Text>
              {(
                Array.from(structureScores.entries()).reduce(
                  (sum, current) => sum + current[0] * current[1],
                  0
                ) /
                Array.from(structureScores.values()).reduce(
                  (sum, current) => sum + current,
                  0
                )
              ).toFixed(2)}
            </Text>
          </Label>
          <Label flex="vertical" alignChildren="middle">
            <Text size="x1">O&M</Text>
            <Text>
              {(
                Array.from(serviceScores.entries()).reduce(
                  (sum, current) => sum + current[0] * current[1],
                  0
                ) /
                Array.from(serviceScores.values()).reduce(
                  (sum, current) => sum + current,
                  0
                )
              ).toFixed(1)}
            </Text>
          </Label>
        </Box>
        <Label flex="vertical" alignChildren="middle">
          <Text size="x1">Total</Text>
          <Text>
            {(
              Array.from(overall.entries()).reduce(
                (sum, current) => sum + current[0] * current[1],
                0
              ) /
              Array.from(overall.values()).reduce(
                (sum, current) => sum + current,
                0
              )
            ).toFixed(2)}
          </Text>
        </Label>
      </Box>
    );
  }

  const { service, structure } = getObservationsScores(
    observations,
    reportEditor.getSectionByUid(inspection?.sectionUid)!
  );

  return (
    <Box flex="vertical" gap="x1">
      <ObservationScoreBadge code="SRV" score={service} />
      <ObservationScoreBadge code="STR" score={structure} />
    </Box>
  );
};

export default InspectionConditionBadges;

const convertNumberToLetter = (number: number) => {
  if (number < 10) {
    return number.toString();
  } else {
    const baseNumber = Math.floor((number - 10) / 5) + 10;
    return String.fromCharCode(65 + baseNumber);
  }
};

const quickScore = (grades: Map<number, number>) => {
  const orderedGrades = Array.from(grades.keys()).sort((a, b) => b - a);
  if (orderedGrades.length === 0) {
    return '0000';
  } else if (orderedGrades.length === 1) {
    return `${orderedGrades[0]}${convertNumberToLetter(
      grades.get(orderedGrades[0]) || 0
    )}00`;
  } else {
    return `${orderedGrades[0]}${convertNumberToLetter(
      grades.get(orderedGrades[0]) || 0
    )}${orderedGrades[1]}${convertNumberToLetter(
      grades.get(orderedGrades[1]) || 0
    )}`;
  }
};
