import { Section } from '@drainify/types';
import { materials, shapes, uses } from '@drainify/utils';
import { SchemaEntry } from '@drainify/utils/src/schema';
import { BoxProps } from 'preshape';
import React from 'react';
import DataTable from '../../DataTable/DataTable';
import DataTableGroup from '../../DataTable/DataTableGroup';
import DataTableItem from '../../DataTable/DataTableItem';
import { useProjectContext } from '../../Project/ProjectProvider';
import SectionAttributeView from './SectionAttributeView';

type Attribute = Extract<
  keyof Section['attributes'],
  'material' | 'shape' | 'use'
>;

type Props<T extends Attribute, V = Section['attributes'][T]> = BoxProps & {
  attribute: T;
  onChange: (code: V) => void;
  value?: V;
};

const schemaData: Record<Attribute, SchemaEntry<any>[]> = {
  material: materials,
  shape: shapes,
  use: uses,
};

const SectionAttributeList = <T extends Attribute>({
  attribute,
  onChange,
  value,
  ...rest
}: Props<T>) => {
  const schemaOptions = schemaData[attribute];
  const { activeBooking } = useProjectContext();

  return (
    <DataTable {...rest}>
      <DataTableGroup>
        {schemaOptions
          .filter((e) =>
            activeBooking?.projectType
              ? e.projectTypes?.includes(activeBooking.projectType)
              : true
          )
          .map(({ code, name }) => (
            <DataTableItem
              key={code}
              keywords={`${code},${name}`}
              name={`Section.attributes.${attribute}`}
              onClick={() => onChange(code)}
              selected={code === value}
            >
              <SectionAttributeView
                {...rest}
                attribute={attribute}
                code={code}
              />
            </DataTableItem>
          ))}
      </DataTableGroup>
    </DataTable>
  );
};

export default SectionAttributeList;
