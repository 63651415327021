import { FirebaseError } from '@firebase/util';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { Alert, Box, Button, Form, Icons, Input, Link, Text } from 'preshape';
import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const searchParams = useSearchParams()[0];
  const continueUrl = searchParams.get('continueUrl');
  const [error, setError] = React.useState<string>();

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    try {
      await signInWithEmailAndPassword(getAuth(), email, password);
      setError(undefined);
      if (continueUrl) {
        navigate(continueUrl);
      } else {
        navigate('/');
      }
    } catch (error) {
      // TODO(hhogg): Handle error
      if (error instanceof FirebaseError) {
        if (error.code === 'auth/wrong-password') {
          setError('Entered password was incorrect');
        } else if (error.code === 'auth/too-many-requests') {
          setError('Too many failed logins. Please try again later.');
        } else if (error.code === 'auth/user-disabled') {
          setError('Account closed');
        } else if(error.code === 'auth/user-not-found') {
          setError('User not found')
        }
      } else {
        setError('There was an issue signing in.');
      }
    }
  };

  return (
    <Box flex="vertical" grow>
      <Box
        alignChildren="middle"
        backgroundColor="background-shade-3"
        flex="vertical"
        grow
        padding="x12"
      >
        <Box maxWidth="400px">
          <Text align="middle" margin="x8" size="x6" strong>
            Sign in to your account
          </Text>

          <Form margin="x6" onSubmit={handleSubmit}>
            <Input
              addonEnd={<Icons.Mail size="24px" />}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
              margin="x2"
              placeholder="Email Address"
              value={email}
            />

            <Input
              addonEnd={<Icons.Key size="24px" />}
              autoComplete="currentPassword"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setPassword(e.target.value)
              }
              margin="x2"
              placeholder="Password"
              type="password"
              value={password}
            />

            <Box flex='horizontal' gap='x3' alignChildrenHorizontal='around'>
              <Button color="accent" size="x3" variant="primary" grow>
                Log in
              </Button>
              {
              // <Button color="accent" size="x3" variant='secondary' grow onClick={e => {
              // e.preventDefault()
              // navigate('/signup')
             // }}>

                  // Sign up
              // </Button>
            }
            </Box>
            {error && (
              <>
                <Alert
                  alignChildrenVertical="middle"
                  backgroundColor="negative-shade-2"
                  color="negative"
                  flex="horizontal"
                  gap="x3"
                  padding="x3"
                >
                  <Icons.AlertTriangle />
                  <Text basis="0" grow size="x2">
                    {`There was an issue: ${error}`}
                  </Text>
                </Alert>
              </>
            )}
          </Form>

          <Text>
            <Text align="middle" margin="x2" size="x2">
              <Link isTextLink to="/forgot-password">
                Forgot your password?
              </Link>
            </Text>

          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginPage;
