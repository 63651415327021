import { getProjectBounds } from '@drainify/utils';
import getBbox from '@turf/bbox';
import { Text } from 'preshape'
import React, { useContext, useEffect } from 'react';
import { NodeMapContext } from '../../Node/NodeMapProvider';
import { useMapContext } from '../Map';
import MapNotification from '../MapNotification/MapNotification';


type Props = {
  projectBounds?: GeoJSON.BBox;
  projectId?: string;
};

const GeometryStoreNodeMap = ({}: Props) => {
  const { project, report } = useContext(NodeMapContext);
  const { fitToBounds, mapType } = useMapContext();

  useEffect(() => {
    const pb = getProjectBounds(project, report);
    const bounds = pb && getBbox(pb);
    fitToBounds({
      bbox: bounds,
    });
  }, [project, report]);

  if(mapType === google.maps.MapTypeId.HYBRID) {
    return <MapNotification color='negative-shade-5'>
      <Text strong size='x1'>
      Satellite is for reference only.
      </Text>
      </MapNotification>
  }
  return null;
};

export default GeometryStoreNodeMap;
