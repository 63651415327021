import {
  Customer,
  CustomerPostBody,
  ResponseError,
  ResponseMultipleEntities,
} from '@drainify/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useFetch from '../components/Api/useFetch';

const useCustomers = (searchString: string = '') => {
  const fetch = useFetch();
  const key = [`/customers`, searchString, fetch];
  const queryClient = useQueryClient();

  const query = useQuery<ResponseMultipleEntities<Customer>, ResponseError>(
    key,
    () => fetch('/customers', { method: 'GET' }, { searchString })
  );

  const create = useMutation<Customer, ResponseError, CustomerPostBody>(
    (body: CustomerPostBody) => fetch('/customers', { method: 'POST', body }),
    {
      onSuccess: () => {
        queryClient.fetchQuery(key);
      },
    }
  );

  return { query, create };
};

export default useCustomers;
