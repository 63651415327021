import {
  Inspection,
  InspectionPostBody,
  MethodOfInspection,
} from '@drainify/types';
import { isInspection, ReportError } from '@drainify/utils';
import { DateTime } from 'luxon';
import { FormValidateFn, useForm } from 'preshape';
import { useCallback, useMemo } from 'react';
import { useReportEditorContext } from '../../components/Report/ReportEditorProvider';

const useInspectionForm = (
  sectionUid?: string,
  inspection?: Inspection | InspectionPostBody,
  activeBookingId?: string
) => {
  const { reportEditor } = useReportEditorContext();
  const initial = useMemo<Inspection | InspectionPostBody>(() => {
    if (isInspection(inspection)) {
      return inspection;
    }

    if (sectionUid && activeBookingId) {
      return reportEditor.toInspection({
        sectionUid,
        jobId: activeBookingId,
        createdAt: DateTime.now().toISO(),
        attributes: {
          date: DateTime.now().toISODate(),
          time: DateTime.now().toISOTime(),
          methodOfInspection: MethodOfInspection.C,
        },
      });
    }

    throw new Error(
      'You must specify a sectionUid, active booking id or inspectionUid'
    );
  }, [inspection]);

  const validate = useCallback<
    FormValidateFn<Inspection | InspectionPostBody, ReportError>
  >(
    (inspection, setError) => {
      setError(reportEditor.validateInspection(inspection));
    },
    [reportEditor]
  );

  return useForm({
    initial,
    validate,
  });
};

export default useInspectionForm;
