import React from 'react';
import useMeOrg from '../../hooks/useMeOrg';
import useOrgMembers from '../../hooks/useOrgMembers';
import DataQueryTable from '../DataTable/DataQueryTable';
import DataTableGroup from '../DataTable/DataTableGroup';
import DataTableItem from '../DataTable/DataTableItem';
import SearchInput from '../Search/SearchInput';
import SearchProvider from '../Search/SearchProvider';
import UserView from '../User/UserView';
import MemberInsightView from './MemberInsightView';


type Props = {
  onClose: () => void;
}

const MembersInsightList = ({ onClose}: Props) => {
  const { query: queryMeOrg } = useMeOrg();
  const { query } = useOrgMembers(queryMeOrg.data?.uid);

  return (
    <>
    <SearchProvider>

      <DataQueryTable
        entity="members"
        placeholder={<UserView />}
        query={[queryMeOrg, query]}
      >
          <SearchInput/>
          <DataTableGroup>
            {
            query.data?.data.map((member) => (
              <DataTableItem key={member.uid} keywords={member.user.fullName}>
                <MemberInsightView member={member} onClose={onClose}/>
              </DataTableItem>

            ))}
        </DataTableGroup>
      </DataQueryTable>
    </SearchProvider>
    </>
  );
};

export default MembersInsightList;
