import { Customer } from '@drainify/types';
import React from 'react';
import CustomerModal from '../../../components/Customer/CustomerModal';
import Address from '../../../components/Location/Address';
import { useHasPermission } from '../../../components/Permissions/Permissions';
import SummaryCard from '../../../components/SummaryCard/SummaryCard';
import SummaryCardSection from '../../../components/SummaryCard/SummaryCardSection';

type Props = {
  customer?: Customer;
  update: (c: Customer) => void;
};

const OrgCustomerPage = ({ customer, update }: Props) => {
  const hasUpdateProjectPermission = useHasPermission('customers:update');
  const [activeStepId, setActiveStepId] = React.useState<string>();

  const editStep = (stepId: string) => {
    if (!hasUpdateProjectPermission) {
      return undefined;
    }

    return () => {
      setActiveStepId(stepId);
    };
  };

  if (!customer) {
    return null;
  }

  return (
    <>
      <SummaryCard title="Customer">
        <SummaryCardSection
          onEdit={editStep('name')}
          title="Name"
          value={customer?.name}
        >
          {customer?.name}
        </SummaryCardSection>

        <SummaryCardSection
          onEdit={editStep('email')}
          title="Email"
          value={customer?.email}
        >
          {customer?.email}
        </SummaryCardSection>

        <SummaryCardSection
          onEdit={editStep('phoneNumber')}
          title="Phone number"
          value={customer?.phoneNumber}
        >
          {customer?.phoneNumber}
        </SummaryCardSection>

        <SummaryCardSection
          onEdit={editStep('location')}
          title="Location"
          value={customer?.location}
        >
          <Address location={customer?.location} />
        </SummaryCardSection>
      </SummaryCard>
      <CustomerModal
        initialActiveStepId={activeStepId}
        onClose={() => setActiveStepId(undefined)}
        visible={activeStepId !== undefined}
        customer={customer}
        onSave={update}
      />
    </>
  );
};

export default OrgCustomerPage;
