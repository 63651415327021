import { Box } from 'preshape';
import React from 'react';
import MapSidebar from '../../../Map/MapSidebar/MapSidebar';
import MapSidebarContent from '../../../Map/MapSidebar/MapSidebarContent';
import ReportMapSidebarHomeCustomer from './ReportMapSidebarHomeCustomer';
import ReportMapSidebarInspection from './ReportMapSidebarInspection';
import ReportMapSidebarMeasurement from './ReportMapSidebarMeasurement';
import ReportMapSidebarNode from './ReportMapSidebarNode';
import ReportMapSidebarObservation from './ReportMapSidebarObservation';
import ReportMapSidebarSection from './ReportMapSidebarSection';

const ReportMapSidebarCustomer = () => {
  return (
    <Box flex="vertical" height="100%">
      <MapSidebar
        basis="0"
        grow
        borderRadius="10px"
        borderColor="white"
        borderSize="x2"
      >
        <Box flex="vertical" width="400px">
          <MapSidebarContent>
            <ReportMapSidebarHomeCustomer />
            <ReportMapSidebarSection />
            <ReportMapSidebarInspection />
            <ReportMapSidebarMeasurement />
            <ReportMapSidebarObservation />
            <ReportMapSidebarNode />
          </MapSidebarContent>
        </Box>
      </MapSidebar>
    </Box>
  );
};

export default ReportMapSidebarCustomer;
