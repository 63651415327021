import { Box, Text } from 'preshape';
import React, { useState } from 'react';
import useDrag, { DragEvent } from '../../hooks/useDrag';

type Props = {
  name?: string;
  onChange: (percent: number) => void;
  value?: number;
};

const PercentageInput = ({ name, onChange, value = 0 }: Props) => {
  React.useEffect(() => {
    function handleClick(e: KeyboardEvent) {
      if (e.code === 'ArrowUp') {
        onChange(value + 5);
      }
      if (e.code === 'ArrowDown') {
        onChange(value - 5);
      }
    }

    window.addEventListener('keydown', handleClick);
    return () => window.removeEventListener('keydown', handleClick);
  }, [value]);
  const [container, setContainer] = useState<Element | null>(null);

  const handleDrag = (event: DragEvent) => {
    const yPositionPercent = 1 - event.current[1] / event.boundingBox.height;
    const yPositionPercentSnapped = Math.round(yPositionPercent * 20);
    onChange(yPositionPercentSnapped * 5);
  };

  const { onPointerDown } = useDrag(container, {
    onDrag: handleDrag,
    onDragStart: handleDrag,
  });

  return (
    <Box
      borderSize="x3"
      borderRadius="x3"
      grow
      flex="vertical"
      maxWidth="200px"
      name={name}
      padding="x1"
      onPointerDown={onPointerDown}
      ref={setContainer}
      style={{ touchAction: 'none' }}
    >
      <Box
        borderRadius="x2"
        flex="vertical"
        gap="x1"
        grow
        overflow="hidden"
        reverse
      >
        {Array.from({ length: 20 }).map((_, index) => (
          <Box
            alignChildren="middle"
            backgroundColor={
              value >= (index + 1) * 5 ? 'accent-shade-4' : 'accent-shade-2'
            }
            basis="0"
            clickable
            flex="horizontal"
            grow
            minHeight="1rem"
            key={index}
            transitionProperty="background-color"
            textColor={
              value >= (index + 1) * 5 ? 'light-shade-1' : 'text-shade-3'
            }
          >
            <Text size="x2" strong>
              {(index + 1) * 5}%
            </Text>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default PercentageInput;
