import { ObservationCode, PENDING_IMAGE_UPLOAD } from '@drainify/types';
import {
  getFullFilePath,
  getObservationOptions,
  getObservationSchema,
  isOptionCode,
} from '@drainify/utils';
import {
  Appear,
  Box,
  FormProvider,
  Icons,
  Modal,
  ModalBody,
  ModalFooter,
  ModalFooterInsert,
  ModalHeader,
  ModalProps,
  ModalTitle,
  Text,
  useResizeObserver,
} from 'preshape';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { generatePath } from 'react-router-dom';
import useObservationForm from '../../hooks/forms/useObservationForm';
import useFileUpload from '../../hooks/useFileUpload';
import { isMobile } from '../../utils/client';
import { FULL_SCREEN_MODAL_WIDTH } from '../App/App';
import DataTable from '../DataTable/DataTable';
import DataTableGroup from '../DataTable/DataTableGroup';
import DataTableItem from '../DataTable/DataTableItem';
import DistanceInput from '../DistanceInput/DistanceInput';
import FileUpload from '../FileUpload/FileUpload';
import resizeImage from '../Image/ImageCompressor';
import ImageFromFile from '../Image/ImageFromFile';
import ImageFromUrl from '../Image/ImageFromUrl';
import { useHasPermission } from '../Permissions/Permissions';
import RemarksInput from '../RemarksInput/RemarksInput';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import { useReportContext } from '../Report/ReportProvider';
import SearchProvider from '../Search/SearchProvider';
import SectionView from '../Section/SectionView';
import Spinner from '../Spinner/Spinner';
import Wizard from '../Wizard/Wizard';
import WizardControls from '../Wizard/WizardControls/WizardControls';
import WizardReviewStep from '../Wizard/WizardReviewStep';
import WizardSearch from '../Wizard/WizardSearch';
import WizardStep from '../Wizard/WizardStep';
import ObservationCodeSelector from './ObservationCodeSelector';
import ObservationCodeView from './ObservationCodeView';
import ObservationOptionRenderer from './ObservationOptionRenderer/ObservationOptionRenderer';
import ObservationSummary from './ObservationSummary';

type Props = ModalProps & {
  initialActiveStepId?: string;
  observationUid?: string;
  onClose: () => void;
  onCreate?: (observationUid: string) => void;
  inspectionUid?: string;
  image?: File;
  imageUrl?: string;
  timeStamp?: number;
  distance?: number;
};

const ObservationModal = ({
  initialActiveStepId,
  observationUid,
  onClose,
  onCreate,
  inspectionUid: maybeInspectionUid,
  visible,
  imageUrl,
  image,
  timeStamp,
  distance,
  ...rest
}: Props) => {
  const { isError, isLoading, isSuccess } = useReportContext();
  const { reportEditor } = useReportEditorContext();
  const { projectId } = useParams<'projectId'>();
  const [sizeModalBody, refModalBody] = useResizeObserver<HTMLDivElement>();
  const [sizeImageArea, refImageArea] = useResizeObserver<HTMLDivElement>();
  const hasUpdateProjectPermission = useHasPermission('projects:update');

  const observation = reportEditor.getObservationByUid(observationUid);
  const inspectionUid = maybeInspectionUid || observation?.inspectionUid;

  const form = useObservationForm(
    inspectionUid,
    imageUrl,
    observation,
    timeStamp,
    distance,
  );
  const [fileToUpload, setFileToUpload] = useState<File>();
  const observationFileUpload = useFileUpload();
  const section = reportEditor.getInspectionSection(inspectionUid);

  React.useEffect(() => {
    if (image) {
      async function compressAndAttach() {
        const compressedBlob = (await resizeImage({
          file: image!,
          maxSize: 500,
        })) as Blob;
        const compressedImage = new File(
          [compressedBlob],
          'obervation-snapshot.jpg',
          {
            type: compressedBlob.type,
          }
        );
        setFileToUpload(compressedImage);
        form.setDirty('imageUrl');
      }
      compressAndAttach();
    }
  }, [image]);

  const handleSelectFile = async (file: File) => {
    const compressedBlob = (await resizeImage({ file, maxSize: 500 })) as Blob;
    const compressedImage = new File(
      [compressedBlob],
      'obervation-snapshot.jpg',
      {
        type: compressedBlob.type,
      }
    );
    setFileToUpload(compressedImage);
    form.setDirty('imageUrl');
    form.setState((observation) => ({
      ...observation,
      imageUrl: 'pending.jpg',
    }));
  };

  const handleRemove = async () => {
    if (observationUid) {
      reportEditor.removeObservation(observationUid);
    }

    onClose();
  };

  const handleSave = async () => {
    let savedObservationUid = observationUid;
    form.setState((observation) => ({
      ...observation,
      imageUrl: fileToUpload ? PENDING_IMAGE_UPLOAD : form.state.imageUrl,
    }));
    if (observationUid) {
      if (
        observation?.observationContinuousState !=
        form.state.observationContinuousState
      ) {
        if (
          form.state.observationContinuousState === undefined &&
          observation?.linkedObservationUid
        ) {
          reportEditor.removeObservation(observation?.linkedObservationUid);
          reportEditor.updateObservation(observationUid, {
            ...form.state,
            linkedObservationUid: undefined,
            imageUrl: fileToUpload ? PENDING_IMAGE_UPLOAD : form.state.imageUrl,
          });
        } else {
          const { uid: finishUid } = reportEditor.addObservationNoUpdate({
            ...form.state,
            distance: undefined,
            timeStamp: undefined,
            imageUrl: undefined,
            observationContinuousState: 'finish',
            linkedObservationUid: observationUid,
          });
          reportEditor.updateObservation(observationUid, {
            ...form.state,
            linkedObservationUid: finishUid,
            imageUrl: fileToUpload ? PENDING_IMAGE_UPLOAD : form.state.imageUrl,
          });
        }
      } else {
        reportEditor.updateObservation(observationUid, {
          ...form.state,
          imageUrl: fileToUpload ? PENDING_IMAGE_UPLOAD : form.state.imageUrl,
        });
      }
    } else {
      const { uid } = reportEditor.addObservationNoUpdate({
        ...form.state,
        imageUrl: fileToUpload ? PENDING_IMAGE_UPLOAD : form.state.imageUrl,
      });

      if (form.state.observationContinuousState === 'start') {
        const { uid: finishUid } = reportEditor.addObservationNoUpdate({
          ...form.state,
          distance: undefined,
          imageUrl: undefined,
          timeStamp: undefined,
          observationContinuousState: 'finish',
          linkedObservationUid: uid,
        });
        reportEditor.updateObservation(uid, {
          linkedObservationUid: finishUid,
          imageUrl: fileToUpload ? PENDING_IMAGE_UPLOAD : form.state.imageUrl,
        });
      }
      await reportEditor.updateAsync?.(reportEditor.report);
      savedObservationUid = uid;
      onCreate?.(savedObservationUid);
    }

    if (fileToUpload) {
      observationFileUpload.upload(
        generatePath(
          '/projects/:projectId/report/observations/:observationId/image',
          {
            projectId: projectId,
            observationId: savedObservationUid,
          }
        ),
        fileToUpload
      );
    }

    // Set all unresolved finished to the SA
    if (form.state.code.includes(ObservationCode.SA)) {
      const observations = reportEditor.getInspectionObservations(
        form.state.inspectionUid
      );
      const updates = observations
        .filter((e) => e.observationContinuousState === 'finish' && !e.distance)
        .map((e) => e.uid);
      updates.forEach((e) => {
        reportEditor.updateObservation(e, {
          distance: form.state.distance,
        });
      });
    }
    if (
      form.state.inspectionMarker === 'finish' &&
      form.state.distance &&
      section
    ) {
      reportEditor.updateSection(section?.uid, {
        attributes: {
          ...section.attributes,
          length: form.state.distance,
        },
      });
    }

    onClose();
  };

  const handleSelectCode = (code: ObservationCode[]) => {
    form.setState((observation) => ({
      ...observation,
      attributes: {},
      code,
    }));
  };

  const handleSetDistance = (distance?: number) => {
    form.setState((observation) => ({
      ...observation,
      distance,
    }));
  };

  const handleSetRemarks = (remarks?: string) => {
    form.setState((observation) => ({
      ...observation,
      remarks,
    }));
  };

  const observationSchema = getObservationSchema(
    form.state.code[form.state.code.length - 1]
  );

  const options =
    observationSchema && getObservationOptions(observationSchema.path);

  useEffect(() => {
    if (!visible) {
      form.reset();
      setFileToUpload(undefined);
    }
  }, [visible]);

  if (!inspectionUid) {
    return (
      <Modal
        {...rest}
        animation="FadeSlideUp"
        margin="x4"
        maxWidth={(image || imageUrl) && !isMobile() ? '1220px' : FULL_SCREEN_MODAL_WIDTH}
        onClose={onClose}
        overlayBackgroundCloseOnClick={false}
        visible={visible}
      >
        <ModalBody>
          <Box flex="horizontal" alignChildrenHorizontal="middle">
            <Spinner size="8rem" />
          </Box>
        </ModalBody>
      </Modal>
    );
    // throw new Error(`
    // The ObservationModal has been used without a inspectionUid.
    // This needs providing explicitly for a new observation,
    // or implicitly via the observationUid for updating an observation.
    // `);
  }

  return (
    <SearchProvider>
      <FormProvider form={form}>
        <Wizard
          flow={observationUid ? 'update' : 'create'}
          initialActiveStepId={initialActiveStepId}
          isError={isError || observationFileUpload.isError}
          isLoading={isLoading || observationFileUpload.isLoading}
          isSuccess={isSuccess && observationFileUpload.isSuccess}
          onSave={hasUpdateProjectPermission ? handleSave : undefined}
          onRemove={hasUpdateProjectPermission ? handleRemove : undefined}
          reset={visible}
        >
          <Modal
            {...rest}
            animation="FadeSlideUp"
            margin="x4"
            maxWidth={(image || imageUrl) && !isMobile() ? '1220px' : FULL_SCREEN_MODAL_WIDTH}
            onClose={onClose}
            overlayBackgroundCloseOnClick={false}
            visible={visible}
          >
            <ModalHeader>
              <ModalTitle>
                {observation
                  ? reportEditor.getObservationName(observation)
                  : 'New observation'}
              </ModalTitle>
            </ModalHeader>

            <ModalBody flex="vertical" alignChildrenVertical="middle">
              <div id="accel-o-ignore" />
              <div id="accel-g-ignore" />
              <div id="accel- -ignore" />
              <div id="accel-s-ignore" />
              <div id="accel-f-ignore" />
              <Box flex="horizontal" gap="x4">
                {imageUrl && !isMobile() && (
                  <Box grow width={FULL_SCREEN_MODAL_WIDTH} maxWidth='500px' height="100%">
                    <ImageFromUrl src={getFullFilePath(imageUrl)} height="400px" />
                  </Box>
                )}
                {image && !isMobile() && (
                  <Box grow width={FULL_SCREEN_MODAL_WIDTH} maxWidth='500px' height="100%">
                    <ImageFromFile file={image} height="400px" />
                  </Box>
                )}
                <Box maxWidth={FULL_SCREEN_MODAL_WIDTH} ref={refModalBody}>
                  <WizardStep
                    id="code.0"
                    title="Type of observation"
                    withSearch
                  >
                    <Box>
                      {form.state.code[0] && (
                    <Appear>
                      <Box paddingBottom="x2">
                        <Text strong>Selected</Text>
                        <DataTable borderRadius="10px">
                          <DataTableGroup>
                            <DataTableItem
                              selected={true}
                            >
                              <ObservationCodeView
                                code={[
                                  form.state.code[form.state.code.length - 1],
                                ]}
                              />
                            </DataTableItem>
                          </DataTableGroup>
                        </DataTable>
                      </Box>
                    </Appear>
                      )}
                    </Box>

                    <ObservationCodeSelector
                      onSelect={handleSelectCode}
                      value={form.state.code}
                      exactSelected
                    />
                  </WizardStep>
                  <WizardStep id="distance" title="Distance">
                    <DistanceInput
                      name="distance"
                      onChange={handleSetDistance}
                      type="long"
                      value={form.state.distance}
                    />
                  </WizardStep>

                  {options
                    ?.filter(
                      (e) =>
                        !(
                          form.state.observationContinuousState === 'finish' &&
                          e.type === 'continuous'
                        )
                    )
                    .map(
                      (option, index) =>
                        !isOptionCode(option) && (
                          <WizardStep
                            id={`attributes.${option.attribute}`}
                            key={index}
                            title={option.name}
                          >
                            <Box flex="vertical" grow>
                              <ObservationOptionRenderer
                                height={sizeModalBody.height}
                                name={`attributes.${option.attribute}`}
                                width={sizeModalBody.width}
                                option={option}
                                setState={form.setState}
                                value={form.state.attributes[option.attribute]}
                              />
                            </Box>
                          </WizardStep>
                        )
                    )}

                  <WizardStep id="remarks" title="Remarks">
                    <RemarksInput
                      onChange={handleSetRemarks}
                      value={form.state.remarks}
                    />
                  </WizardStep>

                  {!image && !imageUrl && (
                    <WizardStep id="imageUrl" title="Image">
                      <Box flex="vertical" gap="x8" grow>
                        <Box maxWidth="300px">
                          <Text align="middle">
                            You can capture a picture of the observation using
                            this device's viewfinder or add it later from a
                            video.
                          </Text>
                        </Box>

                        <Box grow minHeight="200px" ref={refImageArea}>
                          {((!form.state.imageUrl && !fileToUpload) ||
                            fileToUpload) && (
                            <ImageFromFile
                              file={fileToUpload}
                              height={sizeImageArea.height}
                            />
                          )}

                          {form.state.imageUrl && !fileToUpload && (
                            <ImageFromUrl
                              src={getFullFilePath(form.state.imageUrl)}
                              height={sizeImageArea.height}
                            />
                          )}
                        </Box>

                        <Box>
                          <FileUpload
                            accept="image/png, image/jpeg"
                            maxWidth="300px"
                            onChange={handleSelectFile}
                            value={fileToUpload}
                          >
                            <Icons.Camera />
                            <Text
                              style={{
                                WebkitUserSelect: 'none',
                                msUserSelect: 'none',
                                userSelect: 'none',
                                WebkitTouchCallout: 'none',
                                KhtmlUserSelect: 'none',
                                MozUserSelect: 'none',
                              }}
                            >
                              Take/Upload picture
                            </Text>
                          </FileUpload>
                        </Box>
                      </Box>
                    </WizardStep>
                  )}

                  <WizardReviewStep>
                    <ObservationSummary
                      observation={form.state}
                      imageFile={fileToUpload}
                    />
                  </WizardReviewStep>
                </Box>
              </Box>
            </ModalBody>

            <ModalFooter>
              <WizardSearch />
              {
                // <WizardStepError />
              }

              {
                !isMobile() && !image && !imageUrl &&
                <ModalFooterInsert visible>
                  <SectionView section={section} />
                </ModalFooterInsert>
              }

              <WizardControls />
            </ModalFooter>
          </Modal>
        </Wizard>
      </FormProvider>
    </SearchProvider>
  );
};

export default ObservationModal;
