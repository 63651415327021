import { Org } from '@drainify/types';
import { getFullFilePath } from '@drainify/utils';
import React from 'react';
import Avatar from '../Avatar/Avatar';
import CountryView from '../Country/CountryView';
import SummaryCard from '../SummaryCard/SummaryCard';
import SummaryCardSection from '../SummaryCard/SummaryCardSection';
import { useWizardContext } from '../Wizard/Wizard';
import { useOrgContext } from './OrgProvider';

type Props = {
  org?: Org;
};

const OrgSummary = ({ org }: Props) => {
  const { setActiveStepId } = useWizardContext();
  const { edit } = useOrgContext();

  const editStep = (stepId: string) => {
    return () => {
      setActiveStepId(stepId);
      edit(stepId);
    };
  };

  return (
    <SummaryCard title="Summary">
      <SummaryCardSection
        onEdit={editStep('logo')}
        title="Logo"
        value={org?.logoUrlOriginal}
      >
        <Avatar
          onClick={editStep('logo')}
          size="8rem"
          src={getFullFilePath(org?.logoUrlCropped)}
          uid={org?.uid}
        />
      </SummaryCardSection>

      <SummaryCardSection
        onEdit={editStep('name')}
        title="Name"
        value={org?.name}
      >
        {org?.name}
      </SummaryCardSection>

      <SummaryCardSection
        onEdit={editStep('description')}
        title="Tagline / mission statement"
        value={org?.description}
      >
        {org?.description}
      </SummaryCardSection>

      <SummaryCardSection
        onEdit={editStep('contactEmail')}
        title="Contact email"
        value={org?.contactEmail}
      >
        {org?.contactEmail}
      </SummaryCardSection>

      <SummaryCardSection
        onEdit={editStep('contactNumber')}
        title="Contact number"
        value={org?.contactNumber}
      >
        {org?.contactNumber}
      </SummaryCardSection>

      <SummaryCardSection
        onEdit={editStep('country')}
        title="Country"
        value={org?.country}
      >
        {org?.country && <CountryView code={org.country} />}
      </SummaryCardSection>
    </SummaryCard>
  );
};

export default OrgSummary;
