import { Buttons, ButtonsProps } from 'preshape';
import React, {
  createContext,
  PropsWithChildren,
  useMemo,
  useState,
} from 'react';

export const WizardStepOptionsContext = createContext<{
  activeStep?: string;
  setOptionActive: (step: string, isActive: boolean) => void;
  resetOptions: () => void;
}>({
  activeStep: undefined,
  setOptionActive: () => {},
  resetOptions: () => {},
});

const WizardStepOptions = (props: PropsWithChildren<ButtonsProps>) => {
  const [options, setOptions] = useState<Record<string, boolean>>({});

  const setOptionActive = (option: string, isActive: boolean) => {
    setOptions((o) => ({
      ...o,
      [option]: isActive,
    }));
  };

  const resetOptions = () => {
    setOptions({});
  };

  const activeStep = useMemo(() => {
    return Object.entries(options).find(([, isActive]) => isActive)?.[0];
  }, [options]);

  return (
    <WizardStepOptionsContext.Provider
      value={{ activeStep, setOptionActive, resetOptions }}
    >
      <Buttons
        {...props}
        alignChildrenVertical="start"
        flex="vertical"
        gap="x1"
        grow
      />
    </WizardStepOptionsContext.Provider>
  );
};

export default WizardStepOptions;
