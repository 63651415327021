import { Endpoint } from '@drainify/api/src/apiSchema';
import { StripeSubscriptionResponse } from '@drainify/types';
import { CardElement } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { User } from 'firebase/auth';

type Props = {
  onError: (s: string | undefined) => void;
  onLoading: (b: boolean) => void;
  onSuccess: () => Promise<void>;
  payload: object;
  stripe: Stripe;
  elements: StripeElements | null;
  user: User | null;
  endpoint: Endpoint;
  loading: boolean;
  verificationPayload?: object;
  cardless?: boolean;
};

export const stripePaymentWithFallback = async ({
  onError,
  onLoading,
  onSuccess,
  payload,
  stripe,
  user,
  endpoint,
  loading,
  verificationPayload = {},
}: Props) => {
  if (loading) {
    return;
  }
  const token = user ? await user?.getIdToken() : '';
  onLoading(true);
  onError(undefined);
  fetch(`${process.env.DRAINIFY_API_URL}${endpoint}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ ...payload }),
  })
    .then(async (e) => {
      if (e.status === 200) {
        return (await e.json()) as StripeSubscriptionResponse;
      }
      const error = await e.json();
      throw Error(error['message']);
    })
    .then(async (e) => {
      if (!e.clientSecret || !e.status) {
        throw new Error('Unexpected stripe response.');
      }

      if (e.status === 'succeeded') {
        onLoading(false);
        await onSuccess();
      } else if (
        e.status === 'requires_action' ||
        e.status === 'requires_confirmation'
      ) {
        stripe
          .confirmCardPayment(e.clientSecret, {
            payment_method: e.paymentMethod,
          })
          .then(async (result) => {
            if (result.error) {
              throw new Error(result.error.message);
            } else {
              fetch(`${process.env.DRAINIFY_API_URL}/payment/verify`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                  paymentIntentId: result.paymentIntent.id,
                  ...verificationPayload,
                }),
              })
                .then(async (e) => {
                  if (e.ok) {
                    const result =
                      (await e.json()) as StripeSubscriptionResponse;
                    if (result.status === 'succeeded') {
                      onLoading(false);
                      await onSuccess();
                    } else {
                      onError('something went wrong');
                      onLoading(false);
                    }
                  } else {
                    onError('something went wrong');
                    onLoading(false);
                  }
                })
                .catch((e: Error) => {
                  onLoading(false);
                  onError(e.message);
                });
            }
          })
          .catch((e: Error) => {
            onLoading(false);
            onError(e.message);
          });
      }
    })
    .catch((e: Error) => {
      onError(e.message);
      onLoading(false);
    });
};

export const stripeSetupWithFallback = async ({
  onError,
  onLoading,
  onSuccess,
  payload,
  stripe,
  elements,
  user,
  endpoint,
  loading,
  cardless = false,
  verificationPayload = {},
}: Props) => {
  if (loading) {
    return;
  }
  const { paymentMethod, error } = cardless
    ? { paymentMethod: undefined, error: undefined }
    : await stripe.createPaymentMethod({
        type: 'card',
        card: elements!.getElement(CardElement)!,
      });
  const token = user ? await user?.getIdToken() : '';
  if (!error && ((elements && paymentMethod) || cardless)) {
    onLoading(true);
    onError(undefined);
    fetch(`${process.env.DRAINIFY_API_URL}${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: cardless
        ? JSON.stringify({ ...payload })
        : JSON.stringify({ ...payload, paymentMethodId: paymentMethod!.id }),
    })
      .then(async (e) => {
        if (e.status === 200) {
          return (await e.json()) as StripeSubscriptionResponse;
        }
        const error = await e.json();
        throw Error(error['message']);
      })
      .then(async (e) => {
        if (!e.clientSecret || !e.status) {
          throw new Error('Unexpected stripe response.');
        }

        if (e.status === 'succeeded') {
          onLoading(false);
          await onSuccess();
        } else if (
          e.status === 'requires_action' ||
          e.status === 'requires_confirmation'
        ) {
          stripe
            .confirmCardSetup(e.clientSecret)
            .then(async (result) => {
              if (result.error) {
                throw new Error(result.error.message);
              } else {
                fetch(`${process.env.DRAINIFY_API_URL}/setup/verify`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                  },
                  body: JSON.stringify({
                    setupIntentId: result.setupIntent.id,
                    ...verificationPayload,
                  }),
                })
                  .then(async (e) => {
                    if (e.ok) {
                      const result =
                        (await e.json()) as StripeSubscriptionResponse;
                      if (result.status === 'succeeded') {
                        onLoading(false);
                        await onSuccess();
                      } else {
                        onError('something went wrong');
                        onLoading(false);
                      }
                    } else {
                      onError('something went wrong');
                      onLoading(false);
                    }
                  })
                  .catch((e: Error) => {
                    onLoading(false);
                    onError(e.message);
                  });
              }
            })
            .catch((e: Error) => {
              onLoading(false);
              onError(e.message);
            });
        } else {
          // if it's not requires_action
        }
      })
      .catch((e: Error) => {
        onError(e.message);
        onLoading(false);
      });
  } else {
    if (error) {
      onError(error.message);
      onLoading(false);
    }
  }
};
