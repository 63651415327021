import { Measurement, MeasurementPostBody } from '@drainify/types';
import { isMeasurement } from '@drainify/utils';
import { Box, Text, TextProps } from 'preshape';
import React from 'react';
import Distance from '../DistanceInput/Distance';
import RulerIcon from '../IconsMisc/Ruler';
import { useReportEditorContext } from '../Report/ReportEditorProvider';

type Props = TextProps & {
  measurement?: Measurement | MeasurementPostBody;
};

const MeasurementView = ({ measurement, ...rest }: Props) => {
  const { reportEditor } = useReportEditorContext();

  // const sectionName = reportEditor.getSectionName(section);
  // const error = isMeasurement(section) ? reportEditor.getMesurement(section?.uid) : null;

  const length = isMeasurement(measurement)
    ? reportEditor.getMeasurementLength(measurement.uid)
    : measurement?.length;

  if (!length) {
    return null;
  }

  return (
    <Text {...rest} size="x3">
      <Box
        alignChildrenVertical="middle"
        flex="horizontal"
        gap="x4"
        grow
        margin="x2"
      >
        <Box alignChildrenVertical="middle" flex="horizontal" gap="x2" grow>
          <Box>
            <RulerIcon />
          </Box>
          <Box grow>
            <Text strong>{reportEditor.getMeasurementName(measurement)}</Text>
          </Box>
        </Box>

        <Text textColor="text-shade-1">
          {length && <Distance inline value={length} type="long" />}
        </Text>
      </Box>
    </Text>
  );
};

export default MeasurementView;
