import { ReportPostBody } from './Report';

export interface Conflict {
  url: string;
  jobId: string;
  projectId: string;
  cloudDetails: ConflictDetails;
  localDetails: ConflictDetails;
  proposedReport: ReportPostBody;
}

export interface ConflictDetails {
  sections: number;
  inspections: number;
  observations: number;
  nodes: number;
  measurements: number;
  images: number;
  videos: number;
  polygons: number;
  plans: number;
  scale: number;
  updatedAt: string;
}
