import { ImageCropData } from '@drainify/types';
import { getFullFilePath } from '@drainify/utils';
import {
  FormProvider,
  Input,
  InputLabel,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalProps,
  ModalTitle,
  TextArea,
} from 'preshape';
import React, { ChangeEvent, useEffect, useState } from 'react';
import useOrgForm from '../../hooks/forms/useOrgForm';
import useFileUpload from '../../hooks/useFileUpload';
import { FULL_SCREEN_MODAL_WIDTH } from '../App/App';
import CountryList from '../Country/CountryList';
import ImageCropper from '../Image/ImageCropper';
import ImageSelectButton, { ImageSelectData } from '../Image/ImageUploadButton';
import SearchProvider from '../Search/SearchProvider';
import Wizard from '../Wizard/Wizard';
import WizardControls from '../Wizard/WizardControls/WizardControls';
import WizardReviewStep from '../Wizard/WizardReviewStep';
import WizardSearch from '../Wizard/WizardSearch';
import WizardStep from '../Wizard/WizardStep';
import WizardStepError from '../Wizard/WizardStepError';
import { useOrgContext } from './OrgProvider';
import OrgSummary from './OrgSummary';

type Props = ModalProps & {
  onClose: () => void;
  visible: boolean;
  initialActiveStepId?: string;
};

const OrgModal = ({
  initialActiveStepId,
  onClose,
  visible,
  ...rest
}: Props) => {
  const {
    isUpdating,
    isUpdatingFailed,
    isUpdatingSuccess,
    org,
    query,
    closeModal,
    update,
  } = useOrgContext();
  const form = useOrgForm(org);
  const fileUpload = useFileUpload();
  const [cropData, setCropData] = useState<ImageCropData>();
  const [imageSelectData, setImageSelectData] = useState<ImageSelectData>();

  const handleRemove = async () => {
    onClose();
  };

  const handleUpdate = async () => {
    await update({
      ...form.state,
      logoCropData: cropData || form.state.logoCropData,
    });

    if (imageSelectData?.file && org) {
      await fileUpload.upload(
        org && `/orgs/${org.uid}/logo`,
        imageSelectData.file,
        cropData
      );
    } else if (cropData && org) {
      await fileUpload.update(org && `/orgs/${org.uid}/logo`, cropData);
    }

    await query.refetch();

    closeModal();
  };

  const handleSelectImage = (data: ImageSelectData) => {
    setImageSelectData(data);
    setCropData(undefined);
  };

  useEffect(() => {
    if (!visible) {
      form.reset();
    }
  }, [visible]);

  return (
    <SearchProvider>
      <FormProvider form={form}>
        <Wizard
          flow="update"
          initialActiveStepId={initialActiveStepId}
          isError={fileUpload.isError || isUpdatingFailed}
          isLoading={fileUpload.isLoading || isUpdating}
          isSuccess={fileUpload.isSuccess || isUpdatingSuccess}
          onSave={handleUpdate}
          onRemove={handleRemove}
          onCancel={onClose}
          reset={visible}
        >
          <Modal
            {...rest}
            animation="FadeSlideUp"
            margin="x4"
            maxWidth={FULL_SCREEN_MODAL_WIDTH}
            onClose={onClose}
            overlayBackgroundCloseOnClick={false}
            visible={visible}
          >
            <ModalHeader>
              <ModalTitle>Edit profile</ModalTitle>
            </ModalHeader>

            <ModalBody flex="vertical">
              <WizardStep id="name" title="Name">
                <InputLabel>
                  <Input
                    name="name"
                    placeholder="e.g. DomesticPipes Inc."
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      form.setState((s) => ({ ...s, name: e.target.value }))
                    }
                    value={form.state.name}
                  />
                </InputLabel>
              </WizardStep>

              <WizardStep id="description" title="Tagline / mission statement">
                <InputLabel>
                  <TextArea
                    name="description"
                    placeholder="e.g. Seeing is Believing"
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                      form.setState((s) => ({
                        ...s,
                        description: e.target.value,
                      }))
                    }
                    rows={3}
                    value={form.state.description}
                  />
                </InputLabel>
              </WizardStep>

              <WizardStep id="contactEmail" title="Contact email">
                <InputLabel>
                  <Input
                    name="Contact email"
                    placeholder="support@drainify.io"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      form.setState((s) => ({
                        ...s,
                        contactEmail: e.target.value,
                      }))
                    }
                    value={form.state.contactEmail}
                  />
                </InputLabel>
              </WizardStep>

            <WizardStep id="contactNumber" title="Contact number">
              <InputLabel>
                <Input
                  name="Contact number"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    form.setState((s) => ({
                      ...s,
                      contactNumber: e.target.value,
                    }))
                  }
                  value={form.state.contactNumber}
                />
              </InputLabel>
            </WizardStep>

              <WizardStep id="country" title="Country" withSearch>
                <CountryList
                  onSelect={(country) =>
                    form.setState((s) => ({
                      ...s,
                      country,
                    }))
                  }
                  value={form.state.country}
                />
              </WizardStep>

              <WizardStep
                action={
                  form.state.logoUrlOriginal ? (
                    <ImageSelectButton onSelect={handleSelectImage}>
                      Replace
                    </ImageSelectButton>
                  ) : undefined
                }
                id="logo"
                title="Profile picture"
              >
                <ImageCropper
                  onChange={setCropData}
                  cropData={
                    imageSelectData?.base64String
                      ? cropData
                      : cropData || form.state.logoCropData
                  }
                  src={
                    imageSelectData?.base64String ||
                    getFullFilePath(form.state.logoUrlOriginal)
                  }
                />
              </WizardStep>

              <WizardReviewStep>
                <OrgSummary org={form.state} />
              </WizardReviewStep>
            </ModalBody>

            <ModalFooter>
              <WizardSearch />
              <WizardStepError />
              <WizardControls />
            </ModalFooter>
          </Modal>
        </Wizard>
      </FormProvider>
    </SearchProvider>
  );
};

export default OrgModal;
