import { User } from '@drainify/types';
import { getFullFilePath } from '@drainify/utils';
import { Appear, Box, BoxProps, Tooltip } from 'preshape';
import React, { PropsWithChildren } from 'react';
import Avatar from './Avatar';
import './AvatarStack.css';

const spaceDelta = 0.6;

type Props = {
  size?: number;
  users?: User[];
  bookingId?: string;
};

const AvatarStack = ({
  size = 32,
  bookingId,
  users = [],
  ...rest
}: PropsWithChildren<Props & BoxProps>) => {
  const width = size + (users.length - 1) * size * spaceDelta;

  if (!users.length) {
    return null;
  }

  return (
    <Box
      {...rest}
      className="AvatarStack"
      container
      height={size}
      width={width}
    >
      {users
        .map((user, index) => [user, index] as [User, number])
        .reverse()
        .map(([{ avatarUrlCropped, fullName, uid }, index]) => (
          <Tooltip
            content={fullName}
            key={`${bookingId}-${uid}`}
            placement="top"
          >
            {(props) => (
              <Box
                {...props}
                className="AvatarStack__item"
                style={{ left: index * size * spaceDelta }}
                transitionProperty="transform"
              >
                <Box
                  className="AvatarStack__item-avatar"
                  transitionProperty="opacity"
                >
                  <Appear>
                    <Avatar
                      size={size}
                      src={getFullFilePath(avatarUrlCropped)}
                      uid={uid}
                    />
                  </Appear>
                </Box>
              </Box>
            )}
          </Tooltip>
        ))}
    </Box>
  );
};

export default AvatarStack;
