import { OrgRole } from '@drainify/types';
import { orgRoleOrder } from '@drainify/utils';
import React from 'react';
import DataTable, { DataTableProps } from '../DataTable/DataTable';
import DataTableGroup from '../DataTable/DataTableGroup';
import DataTableItem from '../DataTable/DataTableItem';
import OrgRoleView from './OrgRoleView';

type Props = DataTableProps & {
  onSelect?: (role: OrgRole) => void;
  value?: OrgRole;
};

const OrgRoleList = ({ onSelect, value, ...rest }: Props) => {
  return (
    <DataTable {...rest}>
      <DataTableGroup>
        {orgRoleOrder.map((role) => (
          <DataTableItem
            key={role}
            onClick={onSelect && (() => onSelect(role))}
            selected={value ? value === role : undefined}
          >
            <OrgRoleView role={role} />
          </DataTableItem>
        ))}
      </DataTableGroup>
    </DataTable>
  );
};

export default OrgRoleList;
