import { Customer } from '@drainify/types/src';
import { Input } from 'preshape';
import React, { ChangeEvent } from 'react';
import useCustomers from '../../hooks/useCustomers';
import DataQueryTable from '../DataTable/DataQueryTable';
import DataTableGroup from '../DataTable/DataTableGroup';
import DataTableItem from '../DataTable/DataTableItem';
import CustomerView from './CustomerView';

type Props = {
  onSelect: (customer: Customer) => void;
  selectedUids?: string[];
  withSearch?: boolean;
};

const CustomerList = ({ onSelect, selectedUids, withSearch }: Props) => {
  const [searchString, setSearchString] = React.useState<string>('');
  const { query } = useCustomers(searchString);

  return (
    <>
      {withSearch && (
        <Input
          value={searchString}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setSearchString(e.target.value)
          }
        />
      )}
      <DataQueryTable
        entity="customers"
        placeholder={<CustomerView />}
        query={query}
      >
        <DataTableGroup>
          {query.data?.data.map((customer) => (
            <DataTableItem
              key={customer.uid}
              onClick={() => onSelect(customer)}
              selected={selectedUids?.includes(customer.uid)}
            >
              <CustomerView customer={customer} />
            </DataTableItem>
          ))}
        </DataTableGroup>
      </DataQueryTable>
    </>
  );
};

export default CustomerList;
