import { Box, Icons } from 'preshape';
import React from 'react';
import { isDesktop } from '../../utils/client';
import DataTable, { DataTableProps } from '../DataTable/DataTable';
import DataTableGroup from '../DataTable/DataTableGroup';
import DataTableItem from '../DataTable/DataTableItem';
import DataTableMessage from '../DataTable/DataTableMessage';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import { useReportContext } from '../Report/ReportProvider';
import SearchResult from '../Search/SearchResult';
import InspectionView from './InspectionView';

type Props = DataTableProps & {
  disabledUids?: string[];
  onItemClick?: (inspectionUid: string) => void;
  sectionUid?: string;
  selectedUids?: string[];
};

const InspectionList = ({
  disabledUids,
  onItemClick,
  sectionUid,
  selectedUids,
  ...rest
}: Props) => {
  const { report } = useReportContext();
  const { reportEditor } = useReportEditorContext();
  const inspections = reportEditor.getSectionInspections(sectionUid);
  const { activeBooking } = useProjectContext();

  if (!report) {
    return (
      <DataTable {...rest}>
        <DataTableGroup>
          {Array.from({ length: 5 }).map((_, index) => (
            <DataTableItem key={index}>
              <InspectionView />
            </DataTableItem>
          ))}
        </DataTableGroup>
      </DataTable>
    );
  }

  if (
    !inspections.filter((e) =>
      activeBooking?.uid ? e.jobId === activeBooking?.uid : true
    ).length
  ) {
    return (
      <DataTable {...rest}>
        <DataTableGroup>
          <DataTableItem>
            <DataTableMessage>
              There are no inspections to show here
            </DataTableMessage>
          </DataTableItem>
        </DataTableGroup>
      </DataTable>
    );
  }

  return (
    <DataTable {...rest}>
      <DataTableGroup>
        {inspections
          .filter((e) =>
            activeBooking?.uid ? e.jobId === activeBooking?.uid : true
          )
          .map((inspection) => (
            <SearchResult
              keywords={reportEditor.getInspectionKeywords(inspection.uid)}
              key={inspection.uid}
            >
              <DataTableItem
                disabled={disabledUids?.includes(inspection.uid)}
                key={inspection.uid}
                onClick={onItemClick && (() => onItemClick(inspection.uid))}
                selected={selectedUids?.includes(inspection.uid)}
              >
                <Box flex="horizontal" alignChildrenHorizontal="between">
                  {isDesktop() ? (
                    <Box grow>
                      <InspectionView inspection={inspection} />
                    </Box>
                  ) : (
                    <>
                      <Box grow flex="horizontal">
                        <InspectionView
                          inspection={inspection}
                          showScore={false}
                          validate={false}
                        />
                        <Icons.ChevronRight />
                      </Box>
                    </>
                  )}
                </Box>
              </DataTableItem>
            </SearchResult>
          ))}
      </DataTableGroup>
    </DataTable>
  );
};

export default InspectionList;
