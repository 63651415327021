export enum NodeCode {
  BN = 'BN',
  BR = 'BR',
  CP = 'CP',
  GY = 'GY',
  IC = 'IC',
  LH = 'LH',
  MH = 'MH',
  OC = 'OC',
  OF = 'OF',
  OS = 'OS',
  RE = 'RE',
  SK = 'SK',
  RG = 'RG',
  WR = 'WR',
  WC = 'WC',
  RWG = 'RWG',
  RWP = 'RWP',
  FWG = 'FWG',
  SVP = 'SVP',
  CWG = 'CWG',
  TNK = 'TNK',
  PIT = 'PIT',
  UNP = 'UNP',
  UUA = 'UUA',

  // PAPC
  ABP = 'ABP',
  ACB = 'ACB',
  APB = 'APB',
  ACOH = 'ACOH',
  ACOM = 'ACOM',
  ACOP = 'ACOP',
  ACOS = 'ACOS',
  ADP = 'ADP',
  AEP = 'AEP',
  AJB = 'AJB',
  ALT = 'ALT',
  AM = 'AM',
  AMH = 'AMH',
  AML = 'AML',
  AOC = 'AOC',
  ARV = 'ARV',
  ATC = 'ATC',
  AW = 'AW',
  AWA = 'AWA',
  AWD = 'AWD',
  AWW = 'AWW',
  AZ = 'AZ',
}

export type NodeAttributes = {
  material?: NodeMaterial;
  shape?: NodeShape;
  wallCondition?: NodeWallCondition;
  laterialConnections?: number;
  surfaceType?: NodeSurfaceType;
  coverFrameCondition?: NodeCoverFrameCondition;
  breadth?: number;
  width?: number;
  diameter?: number;
  benchingCondition?: BenchingCondition;
};

export enum NodeShape {
  CIRCULAR = 'Circular',
  RECTANGULAR = 'Rectangular',
  SQUARE = 'Square',
  OTHER = 'Other',
  TRIANGULAR = 'Triangular',
  OVAL = 'Oval',
  HEXAGANOL = 'Hexaganol',
  OCTAGAGONAL = 'Octagagonal',
}

export enum NodeMaterial {
  CAST_IRON = 'Cast Iron',
  BRICK = 'Brick',
  PLASTIC = 'Plastic',
  CONCRETE_SEGMENTS = 'Concrete Segments',
  DUCTILE_IRON = 'Ductile Iron',
  EPOXY = 'Epoxy',
  FIBRE_CEMENT = 'Fibre Cement',
  FIBREGLASS_REINFORCED_PLASTIC = 'Fibreglass reinforced Plastic',
  GREY_CAST_IRON = 'Grey Cast Iron',
  MASONRY_REGULAR = 'Masonry (Regular)',
  MASONRY_RANDOM = 'Masonry (Random)',
  MIXED_MATERIAL = 'Mixed Material',
  POLYVINYL = 'Polyvinyl',
}

export enum NodeWallCondition {
  GOOD = 'Good',
  NEEDS_ATTENTION = ' Needs Attention',
  MAJOR_REPAIR_REQUIRED = ' Major Repair Required',
  REPLACEMENT_RECOMMENDED = ' Replacement Recommended',
}

export enum NodeSurfaceType {
  PAVING_SLABS = 'Paving slabs',
  GRASS = 'Grass',
  CONCRETE = 'Concrete',
  TARMAC = 'Tarmac',
  BLOCK_WORK = 'Block work',
  OTHER = 'Other',
}

export enum NodeCoverFrameCondition {
  GOOD = 'Good',
  NEEDS_ATTENTION = ' Needs Attention',
  MAJOR_REPAIR_REQUIRED = ' Major Repair Required',
  REPLACEMENT_RECOMMENDED = ' Replacement Recommended',
}

export enum BenchingCondition {
  GOOD = 'Good',
  NEEDS_ATTENTION = 'Needs attention',
  REPLACEMENT_REQUIRED = 'Replacement required',
}

export type NodePostBody = {
  code: NodeCode;
  imageUrl?: string;
  point?: GeoJSON.Point;
  name?: string;
  depth?: number;
  remarks?: string;
  createdAt: string;
  attributes: NodeAttributes;
};

export type Node = NodePostBody & {
  index: number;
  uid: string;
};
