import { Appear, AppearProps, Box, Button, Icons, Text } from 'preshape';
import React, { PropsWithChildren } from 'react';
import SearchInput from '../../Search/SearchInput';
import SearchProvider from '../../Search/SearchProvider';

type Props = AppearProps & {
  action?: JSX.Element;
  active: boolean;
  onBack?: () => void;
  title?: JSX.Element | string;
};

const MapSidebarContentItem = ({
  action,
  active,
  children,
  padding,
  paddingHorizontal = padding,
  onBack,
  title,
  ...rest
}: PropsWithChildren<Props>) => {
  return (
    <SearchProvider>
      <Appear
        {...rest}
        absolute="edge-to-edge"
        animation={active ? 'FadeSlideRight' : 'FadeSlideLeft'}
        overflow="auto"
        style={{ pointerEvents: active ? 'unset' : 'none' }}
        visible={active}
      >
        {(onBack || title || action) && (
          <Box
            backgroundColor="background-shade-1"
            borderBottom
            borderColor="background-shade-3"
            padding="x4"
            style={{ position: 'sticky', top: 0 }}
          >
            <Box
              alignChildrenVertical="middle"
              flex="horizontal"
              gap="x2"
              margin="x4"
            >
              {onBack && (
                <Box>
                  <Button
                    borderRadius="100%"
                    paddingHorizontal="x1"
                    paddingVertical="x1"
                    onClick={onBack}
                    variant="tertiary"
                  >
                    <Icons.ChevronLeft size="1.25rem" />
                  </Button>
                </Box>
              )}

              {title && (
                <Text basis="0" grow size="x5" strong>
                  {title}
                </Text>
              )}

              {action && <Box>{action}</Box>}
            </Box>

            <SearchInput />
          </Box>
        )}

        <Box paddingHorizontal={paddingHorizontal}>{children}</Box>
      </Appear>
    </SearchProvider>
  );
};

export default MapSidebarContentItem;
