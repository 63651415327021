import { Modal, ModalBody, Icons, Box, Text } from "preshape";
import React from "react";
import Logo from "../Logo/Logo";



export default function PWAInstallPrompt() {

    const isInstalled = () => {
        // @ts-ignore: Property 'standalone' does not exist on type 'Navigator'
        return window.navigator.standalone;
    }

    const isAppleDevice = () => /iPhone|iPad|iPod/.test(navigator.userAgent);

    if (process.env.NODE_ENV !== 'development' && !isInstalled() && isAppleDevice()) {
        return (
            <Modal visible={true}>
                <ModalBody>
                    <Box flex="vertical" gap="x6">
                        <Text size="x5" strong>
                            How to install
                        </Text>
                        <Box textColor='accent-shade-5' alignChildrenHorizontal='middle' grow flex='horizontal'>
                            <Logo />
                        </Box>

                        <Box
                            flex="vertical"
                            borderSize='x1'
                            borderColor='background-shade-3'
                            alignChildrenVertical="middle"
                            alignChildrenHorizontal="middle"
                            gap="x2"
                            padding='x4'
                            backgroundColor='background-shade-2'
                            borderRadius='15px'
                        >
                            <Text size="x4" strong>
                                1. Open in Safari (Apple browser)
                            </Text>
                            <Icons.Compass size="2rem" />
                        </Box>

                        <Box
                            flex="vertical"
                            alignChildrenVertical="middle"
                            borderSize='x1'
                            borderColor='background-shade-3'
                            borderRadius='15px'
                            alignChildrenHorizontal="middle"
                            gap="x2"
                            padding='x4'
                            backgroundColor='background-shade-2'
                        >
                            <Text
                                flex="horizontal"
                                alignChildrenVertical="middle"
                                alignChildrenHorizontal="middle"
                                strong
                            >
                                2. Click 'share'
                            </Text>
                            <Icons.Share size="2rem" />
                        </Box>

                        <Box
                            flex="vertical"
                            alignChildrenVertical="middle"
                            borderSize='x1'
                            borderRadius='15px'
                            borderColor='background-shade-3'
                            alignChildrenHorizontal="middle"
                            gap="x2"
                            padding='x4'
                            backgroundColor='background-shade-2'
                        >
                            <Text strong>3. Click 'Add to Home Screen'</Text>
                            <Icons.PlusSquare size="2rem" />
                        </Box>
                    </Box>
                </ModalBody>
            </Modal>
        )


    }
    else return null;

}