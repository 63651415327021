import { Report, ReportPostBody, ResponseError } from '@drainify/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useFetch, { hasAllParams } from '../components/Api/useFetch';
import { useOrgContext } from '../components/Org/OrgProvider';
import { DataHookSingleEntity } from '.';

const useProjectReport: DataHookSingleEntity<
  'projectId',
  Report,
  ReportPostBody
> = ({ projectId }) => {
  const queryClient = useQueryClient();
  const fetch = useFetch();
  const key = [`/projects/${projectId}/report`, fetch];
  const params = { projectId: projectId as string };

  const { org } = useOrgContext();

  const query = useQuery<Report, ResponseError>(
    key,
    () => fetch('/projects/:projectId/report', { method: 'GET', params }),
    { enabled: hasAllParams(params) }
  );

  const update = useMutation<Report, ResponseError, ReportPostBody>(
    (body: ReportPostBody) =>
      fetch('/projects/:projectId/report', { method: 'PUT', body, params }),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(key, data);
        queryClient.refetchQueries([`/orgs/${org?.uid}/usage`]);
      },
    }
  );

  const remove = useMutation<void, ResponseError>(
    () => fetch('/projects/:projectId/report', { method: 'DELETE', params }),
    {
      onSuccess: () => {
        queryClient.setQueryData(key, null);
        queryClient.refetchQueries([`/orgs/${org?.uid}/usage`]);
      },
    }
  );

  return { query, update, remove };
};

export default useProjectReport;
