import { Box } from 'preshape';
import React from 'react';

type Props = {
  size?: string;
  fill?: string;
};

const AILogo = ({ size = '1rem', fill='#4362eb' }: Props) => {
  return (
    <Box
      className="Icon"
      tag="svg"
      viewBox="0 0 24 24"
      height={size}
      width={size}
      borderRadius="full"
    >
      {
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192.98 192.98">
          <g>
            <path fill={fill} d="M47.52,139.52c0,6.24,4.29,9.29,11.11,9.29s10.86-3.21,15.95-8.29v-16.91c-20.4,2.75-27.06,8.59-27.06,15.91Z"/>
            <path fill={fill} d="M180.98,0H12C5.4,0,0,5.4,0,12v168.98c0,6.6,5.4,12,12,12h168.98c6.6,0,12-5.4,12-12V12c0-6.6-5.4-12-12-12ZM103.27,168.66h-23.41l-2.07-9.92h-.71c-8.15,7.16-17.29,12.26-28.17,12.26-17.66,0-28.86-12.88-28.86-29.2,0-20.4,16.23-31.45,54.53-35.49-.57-8.47-4.8-14-15.31-14-8.13,0-16.23,3.42-25.62,8.75l-10.2-18.84c12.29-7.51,26.14-12.74,41.36-12.74,24.75,0,38.47,14.14,38.47,43.82v55.36ZM111.25,34.6c-.31-.12-.52-.51-.77-.78.25-.24.47-.58.77-.7,2.1-.82,4.18-1.69,6.32-2.36,3.47-1.08,5.69-3.27,6.78-6.74.68-2.17,1.54-4.29,2.37-6.42.11-.28.48-.59.77-.63.19-.03.57.34.67.61.98,2.52,1.93,5.06,2.87,7.6.9,2.41,2.53,4.11,4.92,5.04,2.58,1,5.18,1.92,7.77,2.91.3.12.55.38,1.02.72-.47.34-.71.61-1.02.73-2.45.94-4.89,1.88-7.36,2.76-2.85,1.02-4.61,3.03-5.59,5.83-.8,2.29-1.66,4.55-2.54,6.81-.12.32-.49.54-.74.81-.28-.28-.69-.5-.82-.83-.94-2.42-1.82-4.86-2.72-7.29-1.12-3.03-3.39-4.74-6.38-5.71-2.14-.69-4.23-1.54-6.32-2.36ZM148.39,168.66h-28.69v-96.83h3.59c2.07.8,4.18,1.57,6.32,2.27,4.54,1.47,7.17,3.78,8.55,7.48.44,1.18.87,2.35,1.3,3.53,1.13,3.06,2.29,6.22,3.5,9.32.99,2.56,3.04,4.15,5.34,4.15h.09s0,70.09,0,70.09ZM179.15,60.86c-4.29,1.65-8.58,3.3-12.91,4.85-4.99,1.79-8.08,5.31-9.81,10.23-1.41,4.01-2.92,7.99-4.45,11.95-.22.56-.65,1.41-1.3,1.43-.69.01-1.21-.88-1.44-1.46-1.65-4.24-3.19-8.52-4.78-12.79-1.97-5.31-5.94-8.31-11.19-10.02-3.75-1.22-7.42-2.69-11.09-4.14-.55-.21-.91-.89-1.35-1.36.44-.42.82-1.02,1.34-1.22,3.68-1.44,7.33-2.97,11.09-4.13,6.09-1.89,9.99-5.74,11.89-11.83,1.19-3.81,2.7-7.53,4.15-11.25.19-.5.84-1.04,1.35-1.11.34-.05,1,.6,1.18,1.06,1.72,4.43,3.38,8.88,5.04,13.33,1.58,4.23,4.44,7.22,8.64,8.84,4.52,1.75,9.09,3.38,13.62,5.1.53.2.97.67,1.8,1.26-.82.6-1.25,1.07-1.78,1.28Z"/>
          </g>
        </svg>
      }
    </Box>
  );
};

export default AILogo;
