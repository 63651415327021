import { Customer, CustomerPostBody } from '@drainify/types';
import { locationValidation, runValidations } from '@drainify/utils';
import { useForm } from 'preshape';
import { useCallback, useMemo } from 'react';
import * as yup from 'yup';

const validations = yup.object().shape({
  name: yup.string().max(30, 'Max name length 30').required('Name is required'),
  email: yup
    .string()
    .email('Must be a valid email')
    .required('Email is required'),
  phoneNumber: yup
    .string()
    .matches(
      /(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{4,20}$/,
      'Incorrect format'
    )
    .required('Phone number is required'),
  location: locationValidation.required('Address is required'),
});

const useCustomerForm = (customer?: Customer | CustomerPostBody) => {
  const initial = useMemo<Customer | CustomerPostBody>(
    () =>
      customer || {
        email: '',
        phoneNumber: '',
        name: '',
        location: undefined!,
      },
    [customer]
  );

  return useForm({
    initial: initial,
    validate: useCallback((customer, setError) => {
      setError(runValidations(validations, customer));
    }, []),
  });
};

export default useCustomerForm;
