import { isClosed } from '@drainify/utils';
import {
  Box,
  BulletPoint,
  BulletPoints,
  Button,
  Buttons,
  Range,
  Icons,
  Text,
} from 'preshape';
import React, { ChangeEvent } from 'react';
import Explainer from '../../../Explainer/Explainer';
import MapSidebarContentItem from '../../../Map/MapSidebar/MapSidebarContentItem';
import MapSidebarContentItemSection from '../../../Map/MapSidebar/MapSidebarContentItemSection';
import DrawingModal from '../../../Observations/DrawingModal';
import { useHasPermission } from '../../../Permissions/Permissions';
import { useProjectContext } from '../../../Project/ProjectProvider';
import { useReportEditorContext } from '../../ReportEditorProvider';
import { useReportMapContext } from '../ReportMapProvider';

const ReportMapSidebarDrawing = () => {
  const { reportEditor } = useReportEditorContext();
  const { activeSidebar, focusedDrawingUid, unfocus, setActiveSidebar } =
    useReportMapContext();
  const [stepId, setStepId] = React.useState<string>();
  const [modalVisible, setModalVisible] = React.useState(false);
  const hasUpdateProjectPermission = useHasPermission('projects:update');
  const { activeBooking } = useProjectContext();

  const drawing = reportEditor.getDrawingByUid(focusedDrawingUid);
  const disabled = !hasUpdateProjectPermission || !!activeBooking?.completedAt;
  const [cachedOpacity, setCachedOpacity] = React.useState(drawing?.opacity);

  React.useEffect(() => {
    setCachedOpacity(drawing?.opacity);
  }, [drawing]);

  if (!drawing) {
    return null;
  }

  const editStep = (step: string) => {
    if (!hasUpdateProjectPermission) {
      return undefined;
    }
    if (!!activeBooking?.completedAt) {
      return undefined;
    }

    return () => {
      setStepId(step);
      setModalVisible(true);
    };
  };

  const handleBack = () => {
    unfocus();
    setActiveSidebar('tools');
  };

  return (
    <>
      <MapSidebarContentItem
        active={activeSidebar === 'info' && !!focusedDrawingUid}
        onBack={handleBack}
        title={drawing?.name || 'Unnamed drawing'}
      >
        {!isClosed(drawing) && (
          <Box
            flex="horizontal"
            gap="x1"
            alignChildrenVertical="middle"
            alignChildrenHorizontal="middle"
          >
            <Text strong> How do I use?</Text>
            <Explainer title={'How to use polygon drawing'}>
              <BulletPoints>
                <BulletPoint>
                  Click anywhere to add new points to polygon.
                </BulletPoint>
                <BulletPoint>
                  Once finished. Click the link symbol to link the last point,
                  to the first
                </BulletPoint>
              </BulletPoints>
            </Explainer>
          </Box>
        )}

        {isClosed(drawing) && (
          <>
            <MapSidebarContentItemSection
              title="Name"
              action={
                disabled ? undefined : (
                  <Button
                    variant="tertiary"
                    color="accent"
                    onClick={editStep('name')}
                    flex="horizontal"
                    gap="x2"
                  >
                    Edit
                    <Icons.Edit2 />
                  </Button>
                )
              }
            >
              {drawing.name}
            </MapSidebarContentItemSection>
            <MapSidebarContentItemSection title={'Opacity'}>
              <Range
                min="0.1"
                max="1.1"
                addonEnd={<Icons.Eye />}
                step="0.1"
                value={cachedOpacity}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setCachedOpacity(parseFloat(e.target.value));
                }}
                onMouseUp={() => {
                  reportEditor.updateDrawing(drawing.uid, {
                    opacity: cachedOpacity as number,
                  });
                }}
              />
            </MapSidebarContentItemSection>
          </>
        )}
        <MapSidebarContentItemSection>
          <Buttons>
            <Button
              grow
              variant="tertiary"
              color="negative"
              gap="x2"
              onClick={() => reportEditor.removeDrawing(drawing.uid)}
            >
              <Text>Delete drawing</Text>
              <Icons.Trash2 size="1rem" />
            </Button>
          </Buttons>
        </MapSidebarContentItemSection>
      </MapSidebarContentItem>
      <DrawingModal
        initialActiveStepId={stepId}
        drawingUid={drawing.uid}
        onClose={() => setModalVisible(false)}
        visible={modalVisible}
      />
    </>
  );
};

export default ReportMapSidebarDrawing;
