import { Box } from 'preshape';
import React from 'react';

type Props = {
  size?: string;
  fill?: string;
};

const SectionsIcon = ({ size = '1.5rem', fill = 'currentColor' }: Props) => {
  return (
    <Box
      className="Icon"
      tag="svg"
      height={size}
      viewBox="-3 -5 80 101"
      width={size}
      borderRadius="full"
      fill={fill}
    >
        <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg"
        
        >
          <g id="Layer_1-2">
            <path className="cls-1" d="m52.05,54.16l-27.44-27.44c-.9.81-1.82,1.6-2.74,2.39-.33.36-.66.72-1,1.08l27.73,27.73c1.14-1.26,2.29-2.52,3.45-3.76Z" />
            <path className="cls-1" d="m58.44,47.65l-27.57-27.57c-.2.2-.39.41-.59.61-.98,1.01-1.99,2.02-3.02,3.02l27.38,27.38-.38.38c1.36-1.29,2.76-2.56,4.18-3.82Z" />
            <path className="cls-1" d="m17.35,6c6.22,0,11.32,5.11,11.33,11.34,0,6.22-5.1,11.33-11.34,11.34h-.01c-6.22,0-11.32-5.11-11.33-11.34,0-6.22,5.1-11.33,11.34-11.34h.01m0-6h-.01C7.76.01-.01,7.79,0,17.35c.01,9.55,7.78,17.33,17.33,17.33,4.64,0,8.98-1.82,12.26-5.1,3.28-3.29,5.09-7.64,5.08-12.25-.01-9.56-7.78-17.33-17.33-17.33h0Z" />
            <path className="cls-1" d="m61.47,50.12c6.22,0,11.32,5.11,11.33,11.34,0,6.22-5.1,11.33-11.34,11.34h-.01c-6.22,0-11.32-5.11-11.33-11.34,0-6.22,5.1-11.33,11.34-11.34h.01m0-6h-.01c-9.57.01-17.35,7.79-17.34,17.35.01,9.55,7.78,17.33,17.33,17.33,4.64,0,8.98-1.82,12.26-5.1,3.28-3.29,5.09-7.64,5.08-12.25-.01-9.56-7.78-17.33-17.33-17.33h0Z" />
          </g>
        </svg>
    </Box>
  );
};

export default SectionsIcon;
