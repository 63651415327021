import {
  Booking,
  BookingPostBody,
  ResponseError,
  ResponseMultipleEntities,
} from '@drainify/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useFetch, { hasAllParams } from '../components/Api/useFetch';

type Opts = {
  noQuery?: boolean;
};

const useProjectBookings = (projectId?: string, opts?: Opts) => {
  const queryClient = useQueryClient();
  const fetch = useFetch();
  const key = ['bookings', `/projects/${projectId}/bookings`, fetch];
  const params = { projectId: projectId as string };

  const query = useQuery<ResponseMultipleEntities<Booking>, ResponseError>(
    key,
    () => fetch('/projects/:projectId/bookings', { method: 'GET', params }),
    { enabled: !opts?.noQuery && hasAllParams(params) }
  );

  const create = useMutation<Booking, ResponseError, BookingPostBody>(
    (body: BookingPostBody) =>
      fetch('/projects/:projectId/bookings', { method: 'POST', body, params }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(['bookings']);
      },
    }
  );

  return { query, create };
};

export default useProjectBookings;
