import React from 'react';
import BookingCreateButton from '../../../components/Booking/BookingCreateButton';
import BookingDataTableForProject from '../../../components/Booking/BookingDataTableForProject';
import Page from '../Layout/Page';
import PageBody from '../Layout/PageBody';
import PageHeader from '../Layout/PageHeader';

const ProjectBookingsPage = () => {
  return (
    <Page>
      <PageHeader
        action={<BookingCreateButton variant="tertiary" />}
        title="Jobs"
      />
      <PageBody>
        <BookingDataTableForProject />
      </PageBody>
    </Page>
  );
};

export default ProjectBookingsPage;
