import {
  getBookingStateColor,
  getBookingStateText,
  BookingStateCode,
} from '@drainify/utils';
import { Label, Box, Text } from 'preshape';
import React from 'react';

type Props = {
  bookingState?: BookingStateCode;
  onClick?: (b?: BookingStateCode) => void;
};

const JobAdminSearchOptionsJobStateLabel = ({
  bookingState,
  onClick,
}: Props) => {
  const text = getBookingStateText(bookingState as BookingStateCode);
  const color = getBookingStateColor(bookingState as BookingStateCode);

  if (bookingState !== undefined) {
    return (
      <Label
        alignChildrenVertical="middle"
        flex="horizontal"
        gap="x1"
        size="x1"
        onClick={() => onClick?.(bookingState)}
        style={{ backgroundColor: `rgba(${color}, 0.2)`, cursor: 'pointer' }}
        textColor="text-shade-1"
      >
        <Box
          borderRadius="full"
          height={8}
          style={{ backgroundColor: `rgb(${color})` }}
          width={8}
        />
        <Text strong style={{ color: `rgb(${color})` }} uppercase>
          {text}
        </Text>
      </Label>
    );
  }

  return (
    <Text size="x1" onClick={() => onClick?.(undefined)}>
      All
    </Text>
  );
};

export default JobAdminSearchOptionsJobStateLabel;
