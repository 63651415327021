import { SubscriptionTierDetails } from '@drainify/types';

export const labelColor = (subscriptionTier?: SubscriptionTierDetails) => {
  let color = 'white';
  switch (subscriptionTier?.tier) {
    case 'pro':
      color = 'silver';
      break;
    case 'enterprise':
      color = 'gold';
      break;
  }
  return { color };
};
