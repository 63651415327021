import { WithTracking } from './Api';
import { ImageCropData } from './Image';

export type User = WithTracking & {
  uid: string;
  fullName: string;
  email: string;
  avatarCropData?: ImageCropData;
  avatarUrlOriginal?: string;
  avatarUrlCropped?: string;
  phoneNumber: string;
  isSysAdmin?: boolean;
};

export type UserPostBody = Omit<User, 'uid' | 'isSysAdmin'>;

export type Me = User;
export type MePostBody = UserPostBody;
