import { Booking } from '@drainify/types';
import { getProjectLocation } from '@drainify/utils';
import {
  Appear,
  Box,
  Icons,
  Placement,
  PlacementArrow,
  PlacementContent,
  PlacementManager,
  PlacementReference,
  Text,
} from 'preshape';
import React, { MouseEvent } from 'react';
import MapMarker from '../Map/MapMarker/MapMarker';
import useGeometryStoreRegister from '../Map/useGeometryStore/useGeometryStoreRegister';
import ProjectRef from './ProjectRef';
import ProjectView from './ProjectView';

type Props = {
  isFocused?: boolean;
  isHidden?: boolean;
  onClick?: (event: MouseEvent) => void;
  onDrag?: (point: GeoJSON.Point) => void;
  booking: Booking;
};

const ProjectMapMarker = ({
  isFocused,
  isHidden,
  onClick,
  booking,
  onDrag,
}: Props) => {
  const point = getProjectLocation(booking.project)?.point;

  useGeometryStoreRegister({
    geometry: point,
    opts: {
      id: `ProjectMapMarker`,
      keepInFocus: true,
    },
  });

  if (!point) {
    return null;
  }

  return (
    <MapMarker anchor="bottom" onDragEnd={onDrag} point={point}>
      <PlacementManager trigger="hover">
        <PlacementReference>
          {(props) => (
            <Text {...props} container onClick={onClick}>
              <Appear animation="FadeSlideDown" visible={!isHidden}>
                <Icons.MapPin size="2rem" />
              </Appear>

              <Appear animation="FadeSlideUp" visible={!isHidden}>
                <Box
                  absolute="bottom"
                  style={{
                    left: '50%',
                    transform: `translate(-50%, calc(var(--size--x1) + 100%))`,
                  }}
                >
                  <ProjectRef project={booking} />
                </Box>
              </Appear>
            </Text>
          )}
        </PlacementReference>

        <Placement
          placement="top"
          theme="night"
          visible={isFocused || undefined}
        >
          <PlacementArrow backgroundColor="background-shade-1" />
          <PlacementContent
            backgroundColor="background-shade-1"
            borderRadius="x3"
            padding="x4"
            maxWidth="20rem"
            textColor="text-shade-1"
          >
            <ProjectView
              booking={booking as unknown as Booking}
              project={booking.project}
            />
          </PlacementContent>
        </Placement>
      </PlacementManager>
    </MapMarker>
  );
};

export default ProjectMapMarker;
