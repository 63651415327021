import { ResponseError, SurveyReview } from '@drainify/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useFetch, { hasAllParams } from '../components/Api/useFetch';

type Opts = {
  noQuery?: boolean;
};

const useSurveyReview = (projectId?: string, bookingId?: string, { noQuery }: Opts = {}) => {
  const queryClient = useQueryClient();
  const fetch = useFetch();
  const key = [`survey-review/${projectId}/${bookingId}`, fetch];
  const params = { projectId: projectId as string, bookingId: bookingId as string };

  const update = useMutation<SurveyReview, ResponseError, SurveyReview>(
    (body: SurveyReview) =>
      fetch('/survey-reviews/:projectId/:bookingId', { method: 'PUT', body, params }),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(key, data);
      },
    }
  );

  const create = useMutation(
    () =>
      fetch('/survey-reviews/:projectId/:bookingId', { method: 'POST', params }),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(key, data);
      }
    }
  );

  const query = useQuery<SurveyReview, ResponseError>(
    key,
    () => { 
      return fetch('/survey-reviews/:projectId/:bookingId', { method: 'GET', params })
    },
    { 
      enabled: hasAllParams(params) && !noQuery, 
    },
  );

  return { query, update, create };
};

export default useSurveyReview;
