import { InvitePostBody, OrgRole } from '@drainify/types';
import { ReportError, runValidations } from '@drainify/utils';
import { FormValidateFn, useForm } from 'preshape';
import { useCallback, useMemo } from 'react';
import * as yup from 'yup';
import { useReportEditorContext } from '../../components/Report/ReportEditorProvider';

const validations = yup.object().shape({
  recipient: yup
    .string()
    .email('Email address is invalid')
    .required('Email address is required'),
  role: yup.mixed().oneOf(Object.values(OrgRole)).required(),
});

const useInviteForm = (invite?: InvitePostBody) => {
  const { reportEditor } = useReportEditorContext();
  const initial = useMemo<InvitePostBody>(
    () =>
      invite || {
        recipient: '',
        role: OrgRole.ENGINEER,
      },
    [invite]
  );

  const validate = useCallback<FormValidateFn<InvitePostBody, ReportError>>(
    (invite, setError) => {
      setError(runValidations(validations, invite));
    },
    [reportEditor]
  );

  return useForm({
    initial,
    validate,
  });
};

export default useInviteForm;
