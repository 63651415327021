import { getDistanceUnitText } from '@drainify/utils';
import round from 'lodash.round';
import { Text, TextProps } from 'preshape';
import React, { ForwardRefRenderFunction } from 'react';
import useDisplayDistance, { DisplayDistanceType } from './useDisplayUnit';

type Props = TextProps & {
  type: DisplayDistanceType;
  value?: null | number;
};

const Distance: ForwardRefRenderFunction<HTMLLabelElement, Props> = ({
  type,
  value,
  ...rest
}) => {
  const [valueConverted, unit] = useDisplayDistance(type, value);

  if (value === null) {
    return null;
  }

  return (
    <Text {...rest}>
      <Text inline>{round(valueConverted, 2)}</Text>{' '}
      <Text inline>{getDistanceUnitText(unit, true)}</Text>
    </Text>
  );
};

export default Distance;
