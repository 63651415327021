import { Drawing } from '@drainify/types';
import React, { PropsWithChildren } from 'react';
import { v4 } from 'uuid';
import { useMapContext } from '../Map/Map';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import useDrawMeasurementMapGhost from '../Report/ReportMap/ReportMapElements/useDrawMeasurementMapGhost';

type Props = {
  drawing: Drawing;
};

const ReportMapDrawing = ({ drawing }: PropsWithChildren<Props>) => {
  const { reportEditor } = useReportEditorContext();
  const { addPointerUpListener, addPointerMoveListener } = useMapContext();
  const [staged, setStage] = React.useState<GeoJSON.Point[]>([])

  useDrawMeasurementMapGhost({
    points: staged,
    visible: true,
  });

  React.useEffect(() => {
    return addPointerMoveListener((point) => {
      setStage([drawing.points![drawing.points.length - 1].point, point]) });
  }, [drawing]);

  React.useEffect(() => {
    return addPointerUpListener((point) => {
      reportEditor.updateDrawing(drawing.uid, {
        points: [...(drawing.points || []), { uid: v4(), point }],
      });
    });
  }, [drawing]);

  return null;
};

export default ReportMapDrawing;
