import { ObservationOptionAngle } from '@drainify/utils';
import { InputLabel } from 'preshape';
import React from 'react';
import ClockRefInput from '../../ClockRefInput/ClockRefInput';
import ClockRefSymbol from '../../ClockRefInput/ClockRefSymbol';

type Props = {
  asSummary?: boolean;
  asIcon?: boolean;
  height: number;
  name?: string;
  option: ObservationOptionAngle;
  onSelect: (value: number) => void;
  value?: number;
  width: number;
};

const ObservationOptionAngleInput = ({
  asSummary,
  height,
  name,
  onSelect,
  asIcon,
  value,
  width,
}: Props) => {
  if (asSummary) {
    return <ClockRefSymbol clockFrom={value} asIcon={asIcon}/>;
  }

  return (
    <InputLabel grow>
      <ClockRefInput
        clockFrom={value}
        clockTo={value}
        name={name}
        onChange={([value]) => onSelect(value)}
        size={Math.min(width, height)}
      />
    </InputLabel>
  );
};

export default ObservationOptionAngleInput;
