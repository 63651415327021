// import { Alert, Box } from 'preshape';
// import React from 'react';
// import { useNotifications } from '../../../components/Notifications/Notifications';

const Notifications = () => {
  // const { notifications } = useNotifications();
  // TODO(hhogg): Show notifications.
  return null;
};

export default Notifications;
