import { LanguageCodes, ObservationCode } from '@drainify/types';
import {
  getObservationSchema,
  ObservationSchemaEntry,
  observationsFlatRoot,
} from '@drainify/utils';
import React, { ForwardRefRenderFunction } from 'react';
import DataTable, { DataTableProps } from '../DataTable/DataTable';
import DataTableGroup from '../DataTable/DataTableGroup';
import DataTableItem from '../DataTable/DataTableItem';
import { useProjectContext } from '../Project/ProjectProvider';
import { useSearchContext } from '../Search/SearchProvider';
import ObservationCodeView from './ObservationCodeView';

type Props = DataTableProps & {
  observations?: ObservationSchemaEntry[];
  onSelect: (code: ObservationCode[]) => void;
  value?: ObservationCode[];
  exactSelected?: boolean;
};

const ObservationCodeSelector: ForwardRefRenderFunction<HTMLDivElement, Props> =
  ({
    observations = observationsFlatRoot,
    onSelect,
    value,
    exactSelected,
    ...rest
  }) => {
    const { project, activeBooking } = useProjectContext();
    if (!project) {
      return null;
    }
    const { searchString } = useSearchContext();

    return (
      <DataTable {...rest}>
        <DataTableGroup
          title={searchString.length > 0 ? 'Matched from code' : ''}
        >
          {observations
            .filter(
              (e) =>
                activeBooking?.projectType &&
                e.projectTypes?.includes(activeBooking?.projectType)
            )
            .map(({ code, path }) => (
              <DataTableItem
                onChange={(e) => e.preventDefault()}
                key={code}
                keywords={`${code}`}
                onClick={() => {
                  if (value && code === value[value.length - 1]) {
                    onSelect(value.slice(0, value.length - 1));
                  } else {
                    onSelect(path || getObservationSchema(code).path!);
                  }
                }}
                selected={
                  value
                    ? exactSelected
                      ? code === value[value.length - 1]
                      : value.includes(code)
                    : false
                }
              >
                <ObservationCodeView code={[code]} />
              </DataTableItem>
            ))}
        </DataTableGroup>
        {searchString.length > 0 && (
          <DataTableGroup title="Matched from name">
            {observations
              .filter(
                (e) =>
                  activeBooking?.projectType &&
                  e.projectTypes?.includes(activeBooking?.projectType)
              )
              .map(({ name, code, path }) => (
                <DataTableItem
                  key={name[LanguageCodes.EN]}
                  keywords={`${name[LanguageCodes.EN]}`}
                  onClick={() => {
                    if (value && code === value[value.length - 1]) {
                      onSelect(value.slice(0, value.length - 1));
                    } else {
                      onSelect(path || getObservationSchema(code).path!);
                    }
                  }}
                  selected={
                    value
                      ? exactSelected
                        ? code === value[value.length - 1]
                        : value.includes(code)
                      : false
                  }
                >
                  <ObservationCodeView code={[code]} />
                </DataTableItem>
              ))}
          </DataTableGroup>
        )}
      </DataTable>
    );
  };

// const getChildSearchTerms = (options?: ObservationOption[]): string => {
// if (!options) {
// return '';
// } else {
// const a = options.flatMap((e) => {
// if (isOptionCode(e)) {
// return e.schema.map(
// // eslint-disable-next-line @typescript-eslint/no-unused-vars
// (s) => `,${s.code},${s.name}${getChildSearchTerms(s.options)}`
// );
// } else return [];
// });
// return a.join();
// }
// };

export default ObservationCodeSelector;
