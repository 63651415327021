import { Location as LocationType } from '@drainify/types';
import { Text, TextProps } from 'preshape';
import React from 'react';
import Card from '../Card/Card';
import MapSearchInput from '../Map/MapSearchInput';
import AddressInput from './AddressInput';

type Props = TextProps & {
  name?: string;
  onChange: (location: LocationType) => void;
  value?: LocationType;
};

const LocationInput = ({ name, onChange, value, ...rest }: Props) => {
  return (
    <Text {...rest}>
      <MapSearchInput
        name={name}
        margin="x3"
        onChange={onChange}
        value={value}
      />

      {value && (
        <Card margin="x4">
          <AddressInput location={value} name={name} onChange={onChange} />
        </Card>
      )}
    </Text>
  );
};

export default LocationInput;
