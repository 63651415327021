import { Box, BoxProps } from 'preshape';
import React, { PropsWithChildren } from 'react';

type Props = BoxProps & {};

const MapSidebarMenu = (props: PropsWithChildren<Props>) => {
  return (
    <Box
      {...props}
      borderColor="background-shade-3"
      borderRight
      gap="x3"
      flex="vertical"
    />
  );
};

export default MapSidebarMenu;
