import { ProjectPostBody } from '@drainify/types';
import {
  locationValidation,
  ReportError,
  runValidations,
} from '@drainify/utils';
import { FormValidateFn, useForm } from 'preshape';
import { useCallback, useMemo } from 'react';
import { v4 } from 'uuid';
import * as yup from 'yup';

const validations = yup.object().shape({
  siteLinkedToCustomer: yup.boolean().required(),
  location: yup
    .string()
    .when('siteLinkedToCustomer', (siteLinkedToCustomer) => {
      if (!siteLinkedToCustomer) {
        return locationValidation.required('Address is required');
      }
    }),
  customer: yup.object().shape({}).required('Customer is required'),
});

const useProjectForm = (project?: ProjectPostBody) => {
  const initial = useMemo<ProjectPostBody>(
    () =>
      project || {
        ref: v4().slice(-8),
        uid: v4(),
        siteLinkedToCustomer: true,
      },
    [project]
  );

  const validate = useCallback<FormValidateFn<ProjectPostBody, ReportError>>(
    (project, setError) => {
      setError(
        runValidations(validations, project, {
          ref: 'reference',
        })
      );
    },
    []
  );

  return useForm({
    initial,
    validate,
  });
};

export default useProjectForm;
