import {
  Booking,
  Project,
  ProjectPostBody,
  ReportView,
  ResponseError,
} from '@drainify/types';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { UseQueryResult } from 'react-query';
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import useBooking from '../../hooks/useBooking';
import useProject from '../../hooks/useProject';
import ProjectModal from './ProjectModal';

type Context = {
  isRemoving: boolean;
  isRemovingFailed: boolean;
  isRemovingSuccess: boolean;
  isUpdating: boolean;
  isUpdatingFailed: boolean;
  isUpdatingSuccess: boolean;
  project?: Project;
  customerReportView?: ReportView;
  activeBookingId?: string;
  setActiveBookingId: (bookingId?: string) => void;
  activeBooking?: Booking;
  isCustomerView: boolean;
  query: UseQueryResult<Project, ResponseError>;
  edit: (stepId?: string) => void;
  remove: () => Promise<void>;
};

export const ProjectContext = createContext<Context>({
  isRemoving: false,
  isRemovingFailed: false,
  isRemovingSuccess: false,
  isUpdating: false,
  isUpdatingFailed: false,
  isUpdatingSuccess: false,
  project: {} as unknown as Project,
  customerReportView: {} as unknown as ReportView,
  activeBookingId: undefined,
  activeBooking: undefined,
  isCustomerView: false,
  setActiveBookingId: () => {},
  edit: () => {},
  query: {} as UseQueryResult<Project, ResponseError>,
  remove: () => Promise.reject(new Error('Context')),
});

export const useProjectContext = () => useContext(ProjectContext);

const ProjectProvider = ({}: PropsWithChildren<{}>) => {
  const searchParams = useSearchParams()[0];
  const activeBookingIdParam = searchParams.get('activeBookingId');
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { query, update, remove: removeMutation } = useProject({ projectId });
  const [activeEditStep, setActiveEditStep] = useState<string>();
  const [activeBookingId, setActiveBookingId] = useState<string | undefined>();
  const { query: activeBookingQuery } = useBooking(activeBookingIdParam || '');
  const [activeBooking, setActiveBooking] = useState<Booking>();
  const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);

  const handleSetActiveBookingId = (bookingId?: string) => {
    if (!bookingId) {
      setActiveBooking(undefined);
    } else if (activeBookingQuery) {
      const activeBooking = activeBookingQuery.data;
      setActiveBookingId(bookingId);
      if (activeBooking) {
        setActiveBooking(activeBookingQuery.data);
      }
    }
  };

  useEffect(() => {
    setActiveBookingId(activeBookingIdParam || undefined);
    handleSetActiveBookingId(activeBookingIdParam || undefined);
  }, [activeBookingQuery]);

  const handleOpenProjectModal = (stepId?: string) => {
    setIsProjectModalOpen(true);
    setActiveEditStep(stepId);
  };

  const handleCloseProjectModal = () => {
    setIsProjectModalOpen(false);
    setActiveEditStep(undefined);
  };

  const remove = async () => {
    await removeMutation.mutateAsync();
    navigate('/');
  };

  const onUpdate = async (project: ProjectPostBody) => {
    await update.mutateAsync(project);
    handleCloseProjectModal();
  };

  const context: Context = {
    isRemoving: removeMutation.isLoading,
    isRemovingFailed: removeMutation.isError,
    isRemovingSuccess: removeMutation.isSuccess,
    isUpdating: update.isLoading,
    isUpdatingFailed: update.isError,
    isUpdatingSuccess: update.isSuccess,
    project: query.data,
    customerReportView: undefined,
    activeBookingId,
    activeBooking,
    isCustomerView: false,
    setActiveBookingId: handleSetActiveBookingId,
    query,
    edit: handleOpenProjectModal,
    remove,
  };

  return (
    <ProjectContext.Provider value={context}>
      <Outlet />

      <ProjectModal
        initialActiveStepId={activeEditStep}
        onClose={handleCloseProjectModal}
        visible={isProjectModalOpen}
        project={query.data}
        onSave={onUpdate}
      />
    </ProjectContext.Provider>
  );
};

export default ProjectProvider;
