import { Booking, BookingPostBody, ResponseError } from '@drainify/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useFetch, { hasAllParams } from '../components/Api/useFetch';
import { useOrgContext } from '../components/Org/OrgProvider';
import { useUsageContext } from '../components/Usage/UsageProvider';

type Opts = {
  noQuery?: boolean;
};

const useBooking = (bookingId?: string, { noQuery }: Opts = {}) => {
  const queryClient = useQueryClient();
  const fetch = useFetch();
  const { org } = useOrgContext();
  const key = [`/bookings/${bookingId}`, fetch];
  const params = { bookingId: bookingId as string };
  const { noCreditUpsell } = useUsageContext();

  const query = useQuery<Booking, ResponseError>(
    key,
    () => fetch('/bookings/:bookingId', { method: 'GET', params }),
    { enabled: hasAllParams(params) && !noQuery }
  );

  const update = useMutation<Booking, ResponseError, BookingPostBody>(
    (body: BookingPostBody) =>
      fetch('/bookings/:bookingId', { method: 'PUT', body, params }),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(key, data);
        queryClient.refetchQueries(['bookings']);
        queryClient.refetchQueries([`/orgs/${org?.uid}/usage`]);
        queryClient.refetchQueries([`/bookings/${bookingId}/emails`]);
      },
      onError: () => {
        noCreditUpsell();
      },
    }
  );

  const remove = useMutation<void, ResponseError>(
    () => fetch('/bookings/:bookingId', { method: 'DELETE', params }),
    {
      onSuccess: () => {
        queryClient.setQueryData(key, null);
        queryClient.refetchQueries(['bookings']);
      },
    }
  );

  return { query, update, remove };
};

export default useBooking;
