import { Box, Icons, Text, TextProps } from 'preshape';
import React, { ForwardRefRenderFunction } from 'react';

type Props = TextProps & {
  value?: boolean;
};

const ContinuousDefect: ForwardRefRenderFunction<HTMLLabelElement, Props> = ({
  value,
}) => {
  return (
    <Box flex="horizontal" alignChildrenVertical="middle">
      <Box grow>
        <Text>{value ? 'Yes' : 'No'}</Text>
      </Box>
      <Box
        flex="horizontal"
        alignChildrenHorizontal="middle"
        alignChildrenVertical="middle"
      >
        <Box
          alignChildren="middle"
          borderSize="x3"
          borderRadius="x3"
          flex="horizontal"
          height={40}
          width={40}
        >
          {value ? (
            <Icons.Check size="2rem" textColor="accent-shade-4" />
          ) : (
            <Icons.X size="2rem" textColor="negative-shade-4" />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ContinuousDefect;
