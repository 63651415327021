import { OCRResult, ResponseError } from '@drainify/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useFetch, { hasAllParams } from '../components/Api/useFetch';

type Opts = {
  noQuery?: boolean;
};

const useAIOCRReportResults = (projectId?: string, bookingId?: string, { noQuery }: Opts = {}) => {
  const queryClient = useQueryClient();
  const fetch = useFetch();
  const key = ['pois', fetch];
  const params = { projectId: projectId as string, bookingId: bookingId as string };

  const create = useMutation(
    () =>
      fetch('/ai-report-pois/:projectId/:bookingId', { method: 'POST', params }),
    {
      onSuccess: (data) => {
        queryClient.refetchQueries([`poi`]);
        queryClient.setQueryData(key, data);
      }
    }
  );

  const query = useQuery<OCRResult[], ResponseError>(
    key,
    () => fetch('/ai-report-pois/:projectId/:bookingId', { method: 'GET', params }),
    { 
      enabled: hasAllParams(params) && !noQuery, 
    },
  );

  return { query, create };
};

export default useAIOCRReportResults;
