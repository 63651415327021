import { Box } from 'preshape';
import React from 'react';

const MobileNodeAppMiddleCrosshair = () => {
  return (
    <Box
      clickable={false}
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
      }}
    >
      <svg
        height="40px"
        width="40px"
        style={{ transform: 'translate(-50%,-50%)' }}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 334.312 334.312"
      >
        <circle style={{ fill: 'red' }} cx="167.156" cy="167.155" r="13.921" />
        <path
          style={{ fill: '#eeab00' }}
          d="M110.483,135.793c3.497,3.491,8.079,5.239,12.656,5.239s9.159-1.748,12.656-5.245
			c6.993-6.987,6.993-18.324,0-25.317L30.556,5.244c-6.993-6.987-18.318-6.987-25.311,0s-6.993,18.324,0,25.317L110.483,135.793z"
        />
        <path
          style={{ fill: '#eeab00' }}
          d="M211.173,141.038c4.583,0,9.159-1.748,12.656-5.239L329.067,30.561
			c6.993-6.993,6.993-18.324,0-25.317c-6.993-6.993-18.318-6.987-25.311,0L198.518,110.475c-6.993,6.993-6.993,18.324,0,25.317
			C202.014,139.289,206.591,141.038,211.173,141.038z"
        />
        <path
          style={{ fill: '#eeab00' }}
          d="M303.755,329.066c3.497,3.491,8.079,5.239,12.656,5.239s9.159-1.748,12.656-5.245
			c6.993-6.987,6.993-18.324,0-25.317L223.829,198.517c-6.993-6.987-18.318-6.987-25.311,0s-6.993,18.324,0,25.317L303.755,329.066z
			"
        />
        <path
          style={{ fill: '#eeab00' }}
          d="M17.901,334.311c4.583,0,9.159-1.748,12.656-5.239L135.794,223.84
			c6.993-6.993,6.993-18.324,0-25.317s-18.318-6.987-25.311,0L5.245,303.748c-6.993,6.993-6.993,18.324,0,25.317
			C8.741,332.562,13.324,334.311,17.901,334.311z"
        />
      </svg>
    </Box>
  );
};

export default MobileNodeAppMiddleCrosshair;
