import { WithTracking } from './Api';
import { Customer } from './Customer';
import { Location } from './Location';
import { NodeAttributes } from './Node';
import { Org } from './Org';
import { SectionAttributes } from './Section';

export type ProjectState = 'open' | 'closed';

export type Project = WithTracking & {
  location?: Location | null;
  org: Org;
  uid: string;
  customer?: Customer;
  siteLinkedToCustomer?: boolean;
  siteDefaults?: SiteDefaults;
};

export type ProjectPostBody = Omit<Project, 'uid' | 'org' | 'state'>;

export type SiteDefaults = {
  section?: Partial<SectionAttributes>;
  node?: Partial<NodeAttributes>;
  showModalOnDrop?: boolean;
};
