import { runValidations } from '@drainify/utils';
import {
  getAuth,
} from '@firebase/auth';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useForm } from 'preshape';
import React, { useCallback, useMemo } from 'react';
import * as yup from 'yup';

export type ForgotPasswordState = {
  newPassword: string;
  confirmNewPassword: string;
};

const validations = yup.object().shape({
  newPassword: yup
    .string()
    .required('Please enter a new password')
    .test(
      'password not strong enough',
      'Password must have at least 1 uppercase, 1 lowercase, 1 special character and be at least 8 character long',
      (e) =>
        e !== undefined &&
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/.test(e)
    ),
  confirmNewPassword: yup
    .string()
    .required('Please re-enter your new password')
    .when(['newPassword'], (newPassword) => {
      if (newPassword) {
        return yup
          .string()
          .test(
            'Passwords do not match',
            'Passwords do not match',
            (e) => e === newPassword
          );
      } else return yup.string();
    }),
});

const usePasswordForgotForm = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const initial = useMemo<ForgotPasswordState>(
    () => ({
      newPassword: '',
      confirmNewPassword: '',
    }),
    []
  );

  const form = useForm({
    initial: initial,
    validate: useCallback((value, setError) => {
      setError(runValidations(validations, value));
    }, []),
  });

  const submit = async () => {
    form.setSubmitted();

    if (!form.hasError) {
      setIsLoading(true);
      setIsSuccess(false);
      setIsError(false);

      try {
        await sendPasswordResetEmail(getAuth(), form.state.newPassword);
      } catch (error) {
        console.error(error);
        form.reset();
        console.error(error);
        form.setError({ currentPassword: 'Password was incorrect' });

        setIsLoading(false);
        setIsError(true);
      }
    }
  };

  return { form, submit, isLoading, isError, isSuccess };
};

export default usePasswordForgotForm;
