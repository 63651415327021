import { Booking } from '@drainify/types';
import {
  getProjectState,
  getProjectStateColor,
  getProjectStateText,
} from '@drainify/utils';
import { Box, BoxProps, Label, LabelProps, Text } from 'preshape';
import React, { PropsWithChildren } from 'react';

type Props = LabelProps & {
  booking?: Booking;
};

const ProjectStatus = ({
  booking,
  ...rest
}: PropsWithChildren<BoxProps & Props>) => {
  const state = getProjectState(booking);
  const color = getProjectStateColor(state);
  const text = getProjectStateText(state);

  return (
    <Label
      {...rest}
      alignChildrenVertical="middle"
      flex="horizontal"
      gap="x1"
      size="x1"
      style={{ backgroundColor: `rgba(${color}, 0.2)` }}
      textColor="text-shade-1"
    >
      <Box
        borderRadius="full"
        height={8}
        style={{ backgroundColor: `rgb(${color})` }}
        width={8}
      />
      <Text strong style={{ color: `rgb(${color})` }} uppercase>
        {text}
      </Text>
    </Label>
  );
};

export default ProjectStatus;
