import {
  OCRResult,
  OCRSearchParams,
  ResponseError,
  ResponseMultipleEntities,
} from '@drainify/types';
import { useQuery } from 'react-query';
import useFetch from '../components/Api/useFetch';

const useOCRSearch = (searchParams: OCRSearchParams, offset: number = 0, pageSize: number = 5) => {
  const fetch = useFetch();
  const key = ['ocr-results', offset, pageSize, fetch];

  const query = useQuery<ResponseMultipleEntities<OCRResult>, ResponseError>(
    key,
    () =>
      fetch(
        `/ocr-results`,
        {
          method: 'PUT',
          body: searchParams,
        },
        {
            offset: String(offset),
            pageSize: String(pageSize),
            sortField: 'createdAt',
            sortDirection: '1'
        }
      )
  );

  return { query };
};

export default useOCRSearch;
