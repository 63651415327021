import { Appear, Box } from "preshape";
import React from "react";
import Droplet from "../IconsMisc/Droplet";


type Props = {
    score: number;
    rating?: number;
    onUpdate: (rating: number) => void;
}

const SurveyReviewRatingStar = ({ score, rating, onUpdate }: Props) => {
    const backgroundColor = rating !== undefined && score <= rating ? 'var(--color-accent-shade-5)' : 'gray'
    const [size, setSize] = React.useState(30)

    if (rating === score) {
        return (
            <Appear animation='Pop'>
                <Box
                    onPointerOver={() => {
                        setSize(40)
                    }}
                    onPointerLeave={() => {
                        setSize(30)
                    }}
                    style={{ cursor: 'pointer' }}
                    alignChildrenVertical='middle'
                    flex='vertical'
                    height='50px'
                >
                    <Droplet height={size} fill={backgroundColor} />
                </Box>
            </Appear>

        )
    }

    return <Box
        onPointerOver={() => {
            setSize(40)
        }}
        onPointerLeave={() => {
            setSize(30)
        }}
        style={{ cursor: 'pointer' }}
        onClick={() => onUpdate(score)}
        alignChildrenVertical='middle'
        flex='vertical'
        height='50px'
    >
        <Droplet height={size} fill={backgroundColor} />
    </Box>
}

export default SurveyReviewRatingStar;