import { ResponseError, Usage } from '@drainify/types';
import { useQuery } from 'react-query';
import useFetch, { hasAllParams } from '../components/Api/useFetch';

type Props = {
  orgId?: string;
};

const useOrgUsage = ({ orgId }: Props) => {
  const fetch = useFetch();
  const key = [`/orgs/${orgId}/usage`, fetch];
  const params = { orgId: orgId as string };

  const query = useQuery<Usage, ResponseError>(
    key,
    () => fetch(`/orgs/:orgId/usage`, { method: 'GET', params: params }),
    {
      enabled: hasAllParams(params),
      onError: () => {},
      onSuccess: () => {},
    }
  );

  return { query };
};

export default useOrgUsage;
