import { Report, ResponseError } from '@drainify/types';
import { getProjectBounds } from '@drainify/utils';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react';
import { QueryObserverBaseResult, UseQueryResult } from 'react-query';
import { Outlet, useParams } from 'react-router-dom';
import useProjectReport from '../../hooks/useProjectReport';
import { isDesktop } from '../../utils/client';
import { ServiceWorkerContext } from '../Bootstrap/ServiceWorkerProvider';
import { NodeMapContext } from '../Node/NodeMapProvider';
import PageContext from '../Page/PageContext';
import { useProjectContext } from '../Project/ProjectProvider';
import ReportEditorContextProvider from './ReportEditorProvider';

type Props = {};

type Context = {
  bounds?: GeoJSON.Polygon;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  refetch: QueryObserverBaseResult<Report>['refetch'];
  report?: Report;
  query: UseQueryResult<Report, ResponseError>;

  showObservations: boolean;
  showSimpleSections: boolean;

  setShowObservations: (show: boolean) => void;
  setShowSimpleSections: (show: boolean) => void;

  limitNodes: boolean;
  setLimitNodes: (show: boolean) => void;
};

export const ReportContext = createContext<Context>({
  isError: false,
  isLoading: false,
  isSuccess: false,
  refetch: () => Promise.reject(new Error('Context')),
  report: {} as Report,
  query: {} as UseQueryResult<Report, ResponseError>,
  showObservations: true,
  showSimpleSections: false,

  limitNodes: false,
  setLimitNodes: () => {},

  setShowObservations: () => {},
  setShowSimpleSections: () => {},
});

export const useReportContext = () => useContext(ReportContext);

const ReportProvider = ({}: PropsWithChildren<Props>) => {
  const { projectId } = useParams();
  const { query: queryProject } = useProjectContext();
  const { query, update } = useProjectReport({ projectId });
  const { setReport } = useContext(NodeMapContext);
  const { updating } = useContext(ServiceWorkerContext);

  const [showObservations, setShowObservations] = React.useState(true);
  const [showSimpleSections, setShowSimpleSections] = React.useState(false);
  const [limitNodes, setLimitNodes] = React.useState(false);

  React.useEffect(() => {
    if (query.data && !isDesktop()) {
      setReport(query.data);
    }
    if (query.data) {
      setShowSimpleSections(query.data.sections.length > 15);
      setShowObservations(query.data.observations.length < 50);
    }
  }, [query.data]);

  React.useEffect(() => {
    if (!updating) {
      query.refetch();
    }
    // const interval = setInterval(() => {
      // query.refetch();
    // }, 10000);

    // return () => clearInterval(interval);
  }, [updating]);

  const bounds = useMemo(
    () => getProjectBounds(queryProject.data, query.data),
    [query.data, queryProject.data]
  );

  const value: Context = {
    bounds: bounds,
    isError: update.isError,
    isLoading: update.isLoading,
    isSuccess: update.isSuccess,
    refetch: query.refetch,
    report: query.data,
    query: query,
    showSimpleSections,
    setShowSimpleSections,
    showObservations,
    setShowObservations,
    limitNodes,
    setLimitNodes,
  };

  return (
    <PageContext title="Report" query={[query, queryProject]}>
      <ReportContext.Provider value={value}>
        <ReportEditorContextProvider
          report={value.report}
          update={update.mutate}
          updateAsync={update.mutateAsync}
        >
          <Outlet />
        </ReportEditorContextProvider>
      </ReportContext.Provider>
    </PageContext>
  );
};

export default ReportProvider;
