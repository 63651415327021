import { Member } from '@drainify/types';
import React from 'react';
import DataTableItem from '../DataTable/DataTableItem';
import { useHasPermission } from '../Permissions/Permissions';
import UserView from '../User/UserView';

type Props = {
  member: Member;
  onClick: () => void;
};

const MemberDataTableRow = ({ member, onClick }: Props) => {
  const hasUpdateMemberPermission = useHasPermission('memberships:update');

  return (
    <DataTableItem onClick={hasUpdateMemberPermission ? onClick : undefined}>
      <UserView member={member} user={member.user} />
    </DataTableItem>
  );
};

export default MemberDataTableRow;
