import React from 'react';

const ratio = 100 / 109;

const Logo = ({ height = 100 }: { height?: number }) => {
  return (
    <svg
      width={height * ratio}
      height={height}
      viewBox="0 0 100 109"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="drainify"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group" fill="currentColor">
          <path
            d="M28.675826,0 C37.2435285,0 42.9809926,4.23424946 45.8882181,12.7027484 C52.138664,4.23424946 59.7398787,1.86244902 68.691862,5.58734707 C86.8603927,13.7851449 99.7491418,31.8852142 99.7491418,54.7416697 C99.7491418,84.6374811 75.8168986,109.47422 45.8882181,109.47422 L29.8009298,109.474977 C29.5082329,109.475007 29.2168737,109.475037 28.9268521,109.475069 L22.2231647,109.475991 C21.9545456,109.476036 21.6872642,109.476083 21.4213205,109.47613 L17.5324884,109.476903 C17.2799212,109.476959 17.0286917,109.477015 16.7787998,109.477073 L13.8443048,109.477806 C13.6051141,109.477871 13.3672612,109.477937 13.1307459,109.478003 L10.3567696,109.478841 C10.1309555,109.478915 9.90647909,109.478989 9.68334033,109.479064 L7.71120825,109.479763 C7.49609544,109.479844 7.28232027,109.479925 7.06988277,109.480007 L5.19406193,109.480769 C4.98965036,109.480857 4.78657645,109.480945 4.58484019,109.481034 L2.8053306,109.481859 C2.61162027,109.481953 2.41924761,109.482049 2.2282126,109.482145 L1.09805443,109.482732 C0.726685661,109.482931 0.360667518,109.483133 0,109.483339 L0,0 L28.675826,0 Z M45.7132751,13.8218169 C32.469362,33.805299 25.8474054,47.489145 25.8474054,54.8733549 C25.8474054,65.9496697 34.8118457,74.928797 45.8700433,74.928797 C56.9282409,74.928797 65.8926813,65.9496697 65.8926813,54.8733549 C65.8926813,47.489145 59.1662126,33.805299 45.7132751,13.8218169 Z"
            id="Combined-Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default Logo;
