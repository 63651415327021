import { OrgRole, SurveyReviewState } from "@drainify/types";
import { Button, Box, Text, Icons, Modal, ModalHeader, ModalBody, TextArea, Alert, ModalFooter } from "preshape"
import React, { useContext } from "react"
import useMeOrgMember from "../../hooks/useMeOrgMember";
import useSurveyReview from "../../hooks/useSurveyReview";
import { isMobile } from "../../utils/client";
import { AuthenticationContext } from "../Authentication/Authenticate";
import Droplet from "../IconsMisc/Droplet";
import { useHasPermission } from "../Permissions/Permissions";
import { useProjectContext } from "../Project/ProjectProvider";
import UserView from "../User/UserView";
import BuddyReviewToolModal from "./BuddyReviewToolModal";

const BuddyReviewTool = () => {
  const [visible, setVisible] = React.useState(false)
  const { activeBooking, project } = useProjectContext()
  const { query, update: reviewUpdate, create } = useSurveyReview(project?.uid, activeBooking?.uid)
  const { query: meOrgQuery } = useMeOrgMember();
  const hasCreateSurveyReviewPermission = useHasPermission('survey-review:create');
  const { user } = useContext(AuthenticationContext);

  if(query.isLoading || meOrgQuery.isLoading) {
    return null;
  }

  if(!hasCreateSurveyReviewPermission && (query.data?.state !== SurveyReviewState.SUBMITTED)) {
    return null;
  }

  if(!hasCreateSurveyReviewPermission && user?.uid !== query.data?.surveyBy?.uid) {
    return null;
  }

  if((isMobile() && window.innerWidth < 720 ) && query.data?.state !== SurveyReviewState.SUBMITTED) {
    return meOrgQuery.data?.role === OrgRole.ENGINEER ? null : <Alert color='accent' padding='x2' backgroundColor='accent-shade-2'>
      <Text size='x1' strong>Create survey review on desktop</Text> </Alert>
  }

  if(query.isSuccess && query.data === null && meOrgQuery.data?.role !== OrgRole.ENGINEER) {
    return(
      <Button onClick={() => create.mutateAsync()} color='accent'>+ survey review</Button>
    )
  }


  if ((isMobile() && window.innerWidth < 720) && query.data) {
    return <>
    <Button onClick={() => setVisible(true)}>Review</Button>
    {
      visible && <Modal visible={true} onClose={() => setVisible(false)}>
        <ModalHeader>Review</ModalHeader>
        <ModalBody>
          <Box flex='vertical' gap='x3'>
            <Text align='middle' size='x6' strong>{activeBooking?.jobId}</Text>
            <Box flex='vertical' grow maxWidth='600px'>
              <TextArea readOnly value={query.data.comments} rows={10} style={{ resize: 'none' }} />
            </Box>

            <Box flex='horizontal' gap='x3' alignChildrenVertical='middle' alignChildrenHorizontal='middle'>
              <Droplet height={40} fill={query.data.rating! >= 1 ? 'var(--color-accent-shade-5)' : 'gray'} />
              <Droplet height={40} fill={query.data.rating! >= 2 ? 'var(--color-accent-shade-5)' : 'gray'} />
              <Droplet height={40} fill={query.data.rating! >= 3 ? 'var(--color-accent-shade-5)' : 'gray'} />
              <Droplet height={40} fill={query.data.rating! >= 4 ? 'var(--color-accent-shade-5)' : 'gray'} />
              <Droplet height={40} fill={query.data.rating! >= 5 ? 'var(--color-accent-shade-5)' : 'gray'} />
            </Box>
            </Box>
        </ModalBody>
        <ModalFooter>
          Reviewed by <UserView user={query.data.reviewedBy}/>
        </ModalFooter>

      </Modal>
    }
    </>
  }

  return (
    <>
      <Button color='accent' flex='vertical' variant='primary' textColor='white' onClick={() => setVisible(!visible)}>
        <Box flex='horizontal' gap='x2'>
          <Text>QA survey</Text>
          {
            query.data?.state === SurveyReviewState.SUBMITTED && <Icons.Check />
          }
        </Box>
      </Button>
      {visible && <BuddyReviewToolModal
        onClose={() => setVisible(false)}
        surveyReview={query.data}
        onSubmit={surveyReview => { reviewUpdate.mutate(surveyReview) }}
      />}
    </>
  )

}

export default BuddyReviewTool;