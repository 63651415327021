import { Box, Icons } from 'preshape';
import React from 'react';
import JobAdminSearchOptionsDate from './JobAdminSearchOptionsDate';
import JobAdminSearchOptionsEngineer from './JobAdminSearchOptionsEngineer';
import JobAdminSearchOptionJobState from './JobAdminSearchOptionsJobState';
import JobAdminSearchOptionsSearch from './JobAdminSearchOptionsSearch';
import JobAdminSearchOptionsSearchField from './JobAdminSearchOptionsSearchField';

const JobAdminSearchOptions = () => {
  return (
    <Box
      flex="horizontal"
      wrap
      alignChildrenHorizontal="middle"
      padding="x2"
      alignChildrenVertical="start"
    >
      <Box flex="horizontal" gap="x3" padding="x2">
        <Box>
          <JobAdminSearchOptionsSearch />
        </Box>
        <Box>
          <JobAdminSearchOptionsSearchField />
        </Box>
      </Box>
      <Box
        flex="horizontal"
        alignChildrenVertical="middle"
        padding="x2"
        gap="x2"
      >
        <Box>
          <Icons.Filter size="1.25rem" textColor="text-shade-4" />
        </Box>
        <JobAdminSearchOptionJobState />
        <JobAdminSearchOptionsDate />
        <JobAdminSearchOptionsEngineer />
      </Box>
    </Box>
  );
};

export default JobAdminSearchOptions;
