import { Section, SectionAttributes, SectionPostBody } from '@drainify/types';
import { ReportError } from '@drainify/utils';
import { DateTime } from 'luxon';
import { useForm, FormValidateFn } from 'preshape';
import { useCallback, useMemo } from 'react';
import { useReportEditorContext } from '../../components/Report/ReportEditorProvider';

const useSectionForm = (
  section?: Section | SectionPostBody,
  defaultAttributes: Partial<SectionAttributes> = {},
  defaultLength?: number
) => {
  const { reportEditor } = useReportEditorContext();
  const initial = useMemo<Section | SectionPostBody>(
    () =>
      section || {
        nodeStartUid: undefined,
        nodeEndUid: '',
        attributes: defaultAttributes,
        createdAt: DateTime.now().toISO(),
        length: defaultLength,
      },
    [
      section,
      defaultAttributes.use,
      defaultAttributes.shape,
      defaultAttributes.pipeType,
      defaultAttributes.material,
      defaultAttributes.use,
      defaultAttributes.shape,
      defaultAttributes.sectionOwnership,
      defaultAttributes.diameter,
    ]
  );

  const validate = useCallback<
    FormValidateFn<Section | SectionPostBody, ReportError>
  >(
    (section, setError) => {
      setError(reportEditor.validateSection(section));
    },
    [reportEditor]
  );

  return useForm({
    initial,
    validate,
  });
};

export default useSectionForm;
