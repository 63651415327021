import {
  Alert,
  Box,
  Button,
  Buttons,
  Image,
  Modal,
  ModalBody,
  ModalFooter,
  Text,
  useLocalStorage,
} from 'preshape';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FULL_SCREEN_MODAL_WIDTH } from '../App/App';
import { useLogging } from '../Logging/Logging';
import GeoLocationRequestButton from './GeoLocationRequestButton';

const GeoLocationContext = createContext<{
  position?: GeoJSON.Point & { accuracy: number };
  hasPermission?: boolean;
  resetPermission?: () => void;
}>({});

export const useGeoLocation = () => useContext(GeoLocationContext);

const GeoLocation = ({ children }: PropsWithChildren<{}>) => {
  const [position, setPosition] = useState<
    GeoJSON.Point & { accuracy: number }
  >();
  const [hasPermission, setHasPermission] = useLocalStorage<
    boolean | undefined
  >('com.drainify.geolocation', undefined);

  const refWatchId = useRef<number>();
  const { logError } = useLogging();

  const handleSetPosition = (pos: GeolocationPosition) => {
    setPosition({
      type: 'Point',
      coordinates: [pos.coords.longitude, pos.coords.latitude],
      accuracy: pos.coords.accuracy,
    });
  };

  const teardownGeoLocationWatch = () => {
    if (refWatchId.current) {
      navigator.geolocation.clearWatch(refWatchId.current);
      refWatchId.current = undefined;
    }
  };

  const setupGeoLocationWatch = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        handleSetPosition,
        handleRequestError
      );

      teardownGeoLocationWatch();
      refWatchId.current = navigator.geolocation.watchPosition(
        handleSetPosition,
        handleRequestError,
        {
          enableHighAccuracy: true,
        }
      );
    }
  };

  const handleRequestSuccess: PositionCallback = (position) => {
    handleSetPosition(position);
    setupGeoLocationWatch();
    setHasPermission(true);
  };

  const handleRequestError: PositionErrorCallback = (error) => {
    setPosition(undefined);
    if (error.code !== 2) {
      teardownGeoLocationWatch();
      logError(new Error(error.message));
      setHasPermission(false);
    }
  };

  const context = {
    position,
    hasPermission,
    resetPermission: () => setHasPermission(undefined)
  };

  useEffect(() => {
    if ('permissions' in navigator) {
      navigator.permissions.query({ name: 'geolocation' }).then(({ state }) => {
        if (state === 'granted') {
          setupGeoLocationWatch();
          setHasPermission(true);
        }

        if (state === 'denied') {
          teardownGeoLocationWatch();
          setHasPermission(false);
        }
      });
    }
  }, [hasPermission]);

  useEffect(() => {
    if (hasPermission) {
      setupGeoLocationWatch();
    }
    return () => {
      teardownGeoLocationWatch();
    };
  }, []);

  return (
    <GeoLocationContext.Provider value={context}>
      {children}

      <Modal
        animation="FadeSlideUp"
        margin="x4"
        maxWidth={FULL_SCREEN_MODAL_WIDTH}
        visible={hasPermission === undefined}
      >
        <ModalBody flex="vertical">
          <Box alignChildren="middle" gap="x16" grow flex="vertical">
            <Box>
              <Image
                src={require('@drainify/assets/images/illustrations/undraw_map_dark_re_36sy.svg')}
              />
            </Box>

            <Box>
              <Text align="middle" margin="x2" strong>
                We have so many useful features that smartly work around your
                location.
              </Text>

              <Text align="middle" margin="x2" strong>
                To get the full experience, please allow your location when
                prompted.
              </Text>

              <Alert color='accent' backgroundColor='accent-shade-1' padding='x2'>
                <Text align="middle" size='x1' strong>
                  If there's any issues. Please make sure you have allowed location services on your browser in your device settings.
                </Text>
              </Alert>
            </Box>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Buttons flex="vertical" gap="x2">
            <GeoLocationRequestButton
              onError={handleRequestError}
              onSuccess={handleRequestSuccess}
              hasPermission={hasPermission === true}
            />

            <Button onClick={() => setHasPermission(false)} variant="tertiary">
              I'll think about it later
            </Button>
          </Buttons>
        </ModalFooter>
      </Modal>
    </GeoLocationContext.Provider>
  );
};

export default GeoLocation;
