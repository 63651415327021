import { Usage } from '@drainify/types';
import { DateTime } from 'luxon';
import { Modal, ModalBody, Text, ModalHeader, Icons, Alert } from 'preshape';
import React from 'react';

type Props = {
  usage: Usage;
};

const UsageDetailsCancelled = ({ usage }: Props) => {
  const [cancelledVisable, setCancelledVisable] = React.useState(true);

  return (
    <Modal
      visible={cancelledVisable}
      onClose={() => setCancelledVisable(false)}
    >
      <ModalHeader>Sorry to see you go</ModalHeader>
      <ModalBody>
        <Alert
          color={'negative'}
          backgroundColor="negative-shade-2"
          textColor="negative-shade-5"
          padding="x3"
        >
          <Text strong size="x2" flex="horizontal" gap="x2">
            <Icons.AlertCircle />
            <Text>
              Your account has been cancelled. It will be accessable until{' '}
              {DateTime.fromSeconds(usage.resetDate).toFormat('dd/MM/yy')}
            </Text>
          </Text>
          <Text strong size="x2" flex="horizontal" gap="x2">
            Please backup any data stored as it will be removed at time of
            expiration.
          </Text>
        </Alert>
      </ModalBody>
    </Modal>
  );
};

export default UsageDetailsCancelled;
