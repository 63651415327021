import { Observation, ObservationPostBody } from '@drainify/types';
import {
  getFullFilePath,
  getObservationOptions,
  isObservation,
  isOptionCode,
} from '@drainify/utils';
import { Alert, Box, Button, Modal, ModalBody, ModalHeader, Text } from 'preshape';
import React from 'react';
import { isDesktop } from '../../utils/client';
import { FULL_SCREEN_MODAL_WIDTH } from '../App/App';
import Distance from '../DistanceInput/Distance';
import ImageFromFile from '../Image/ImageFromFile';
import ImageFromUrl from '../Image/ImageFromUrl';
import { useHasPermission } from '../Permissions/Permissions';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import SummaryCard from '../SummaryCard/SummaryCard';
import SummaryCardSection from '../SummaryCard/SummaryCardSection';
import ObservationCodeView from './ObservationCodeView';
import ObservationOptionRenderer from './ObservationOptionRenderer/ObservationOptionRenderer';

type Props = {
  observation?: Observation | ObservationPostBody;
  imageFile?: File;
  readOnly?: boolean;
};

const ObservationSummary = ({ observation, imageFile }: Props) => {
  const { editObservation, reportEditor } = useReportEditorContext();
  const options = getObservationOptions(observation?.code);
  const { isCustomerView } = useProjectContext();
  const hasUpdateProjectPermission = useHasPermission('projects:update');
  const error = reportEditor.fullValidateObservation(observation);
  const observationName = reportEditor.getObservationName(observation);
  const [ showFullscreenImage, setShowFullscreenImage ] = React.useState(false)

  const editStep = (step: string) => {
    if (!hasUpdateProjectPermission) {
      return undefined;
    }

    return () => {
      if (isObservation(observation)) {
        editObservation(observation.uid, step);
      } else {
        editObservation(null, step);
      }
    };
  };

  return (
    <SummaryCard title="Observation">
      {error?.distance && (
        <Alert
          padding="x2"
          color="negative"
          backgroundColor="negative-shade-2"
          borderSize="x2"
        >
          <Text strong size="x2">
            {error.distance.message}
          </Text>
        </Alert>
      )}
      <SummaryCardSection
        error={error?.name}
        title="Name"
        value={observationName}
      >
        <Text>{observationName}</Text>
      </SummaryCardSection>

      <SummaryCardSection
        error={error?.codes}
        onEdit={editStep('code.0')}
        title="Type of observation"
        value={observation?.code}
      >
        <ObservationCodeView code={observation?.code} />
      </SummaryCardSection>

      <SummaryCardSection
        onEdit={editStep('distance')}
        error={error?.distance}
        title="Distance"
        value={observation?.distance}
      >
        <ObservationOptionRenderer
          asSummary
          height={32}
          option={{
            attribute: 'distance',
            description: 'Distance to the observation in this section',
            name: 'Distance',
            type: 'distance',
          }}
          value={observation?.distance || 0}
          width={32}
        />
      </SummaryCardSection>

      {imageFile ? (
        <SummaryCardSection
          onEdit={editStep('imageUrl')}
          title="Image"
          value={observation?.imageUrl}
        >
          <ImageFromFile file={imageFile} height="200px" />
        </SummaryCardSection>
      ) : (
        <SummaryCardSection
          onEdit={editStep('imageUrl')}
          error={error?.imageUrl}
          title="Image"
          value={observation?.imageUrl}
        >
          <ImageFromUrl
            src={getFullFilePath(observation?.imageUrl)}
            height="200px"
          />

          {
            observation?.imageUrl && isDesktop() && isCustomerView && 
            
            <Box flex='horizontal' padding='x3' alignChildrenHorizontal='middle'>
              <Button variant='tertiary' onClick={() => setShowFullscreenImage(true)}>Enlarge</Button>
            </Box>
          }
          {
            isCustomerView && showFullscreenImage && <Modal visible={true}
              maxWidth={FULL_SCREEN_MODAL_WIDTH}
              onClose={() => setShowFullscreenImage(false)}
              overlayBackgroundCloseOnClick={true}
            >
              <ModalHeader>{reportEditor.getObservationName(observation)}</ModalHeader>
              <ModalBody>
                <ImageFromUrl
                  src={getFullFilePath(observation?.imageUrl)}
                  height="400px"
                  width="600px"
                />

                <ObservationCodeView code={observation?.code}/>
                <Distance value={observation?.distance} type={'long'}/>

                {options
                  ?.filter(
                    (e) =>
                      !(
                        observation?.observationContinuousState === 'finish' &&
                        e.type === 'continuous'
                      )
                  )
                  .map(
                    (option, index) =>
                      !isOptionCode(option) && (
                        <SummaryCardSection
                          error={error?.[option.attribute]}
                          key={index}
                          onEdit={editStep(`attributes.${option.attribute}`)}
                          title={option.name}
                          value={observation?.attributes?.[option.attribute]}
                        >
                          <ObservationOptionRenderer
                            asSummary
                            height={32}
                            option={option}
                            value={observation?.attributes[option.attribute]}
                            width={32}
                          />
                        </SummaryCardSection>
                      )
                  )}

              </ModalBody>

            </Modal>
          }
        </SummaryCardSection>
      )}

      {options
        ?.filter(
          (e) =>
            !(
              observation?.observationContinuousState === 'finish' &&
              e.type === 'continuous'
            )
        )
        .map(
          (option, index) =>
            !isOptionCode(option) && (
              <SummaryCardSection
                error={error?.[option.attribute]}
                key={index}
                onEdit={editStep(`attributes.${option.attribute}`)}
                title={option.name}
                value={observation?.attributes?.[option.attribute]}
              >
                <ObservationOptionRenderer
                  asSummary
                  height={32}
                  option={option}
                  value={observation?.attributes[option.attribute]}
                  width={32}
                />
              </SummaryCardSection>
            )
        )}

      <SummaryCardSection
        onEdit={editStep('remarks')}
        error={error?.remarks}
        title="Remarks"
        value={observation?.remarks}
      >
        <ObservationOptionRenderer
          asSummary
          height={32}
          option={{
            attribute: 'remarks',
            description: 'Free text remarks',
            name: 'Remarks',
            type: 'remarks',
          }}
          value={observation?.remarks || ''}
          width={32}
        />
      </SummaryCardSection>
    </SummaryCard>
  );
};

export default ObservationSummary;
