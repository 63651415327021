import { Section, SectionPostBody } from '@drainify/types';
import { isSection } from '@drainify/utils';
import { Box, Label, Text, TextProps } from 'preshape';
import React from 'react';
import Distance from '../DistanceInput/Distance';
import SectionsIcon from '../IconsMisc/SectionsIcon';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import ReportErrorIndicator from '../Report/ReportErrorIndicator';
import SectionConditionBadges from './SectionCondition/SectionConditionBadges';
import SectionFlowVisualisation from './SectionFlowVisualisation';

type Props = TextProps & {
  section?: Section | SectionPostBody;
  validate?: boolean;
};

const SectionView = ({ section, validate = true, ...rest }: Props) => {
  const { reportEditor } = useReportEditorContext();
  const sectionName = reportEditor.getSectionName(section);

  const error =
    isSection(section) && validate
      ? reportEditor.getSectionError(section?.uid)
      : null;

  return (
    <Text {...rest} size="x3">
      <Box
        alignChildrenVertical="middle"
        flex="horizontal"
        gap="x4"
        grow
        margin="x2"
      >
        <Box alignChildrenVertical="middle" flex="horizontal" gap="x2" grow>
          <Box>
            <SectionsIcon />
          </Box>

          <Box grow>
            <Text strong>{sectionName}</Text>
          </Box>
        </Box>

        <Text textColor="text-shade-1">
          {section?.attributes.length ? (
            <Distance inline value={section?.attributes.length} type="long" />
          ) : (
            <Text>? m</Text>
          )}
        </Text>

        {validate && (
          <Box>
            <ReportErrorIndicator error={error} />
          </Box>
        )}
      </Box>

      <Text
        alignChildrenVertical="middle"
        flex="horizontal"
        gap="x4"
        textColor="text-shade-1"
      >
        <Box grow>
          <SectionFlowVisualisation section={section} />
        </Box>

        {section && isSection(section) && (
          <Box alignChildrenVertical="middle" flex="vertical">
            {reportEditor.getSectionInspections(section.uid).length > 0 ? 
              ( <SectionConditionBadges sectionUid={section.uid} />) :
              (<Label>
                <Text size='x1'>
                  Not inspected
                </Text>
              </Label>)
            }
          </Box>
        )}
      </Text>
    </Text>
  );
};

export default SectionView;
