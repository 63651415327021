import {
  BenchingCondition,
  Node,
  NodeCoverFrameCondition,
  NodeMaterial,
  NodeShape,
  NodeSurfaceType,
  NodeWallCondition,
} from '@drainify/types';
import {
  CheckBox,
  FormProvider,
  Input,
  InputLabel,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalProps,
  ModalTitle,
} from 'preshape';
import React, { ChangeEvent, useEffect } from 'react';
import useNodeForm from '../../hooks/forms/useNodeForm';
import { FULL_SCREEN_MODAL_WIDTH } from '../App/App';
import DistanceInput from '../DistanceInput/DistanceInput';
import { useHasPermission } from '../Permissions/Permissions';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import { useReportContext } from '../Report/ReportProvider';
import SearchProvider from '../Search/SearchProvider';
import Wizard from '../Wizard/Wizard';
import WizardControls from '../Wizard/WizardControls/WizardControls';
import WizardSearch from '../Wizard/WizardSearch';
import WizardStep from '../Wizard/WizardStep';
import WizardStepError from '../Wizard/WizardStepError';
import NodeDistanceQuickOptions from './NodeDistanceQuickOptions';

type Props = ModalProps & {
  initialActiveStep?: string;
  nodeUid?: string;
  onClose: () => void;
  onCreate?: (nodeUid: string) => void;
};

const NodeAdditionalInformationModal = ({
  initialActiveStep,
  nodeUid,
  onClose,
  onCreate,
  visible,
  ...rest
}: Props) => {
  const { isError, isLoading, isSuccess } = useReportContext();
  const { reportEditor } = useReportEditorContext();
  const node = reportEditor.getNodeByUid(nodeUid);
  const hasUpdateProjectPermission = useHasPermission('projects:update');

  const form = useNodeForm(node);
  const defaultName = reportEditor.getNodeName(reportEditor.toNode(form.state));

  const handleRemove = async () => {
    if (nodeUid) {
      reportEditor.removeNode(nodeUid);
    }

    onClose();
  };

  const handleSave = async () => {
    let savedNodeUid = nodeUid;

    if (nodeUid) {
      reportEditor.updateNode(nodeUid, form.state);
    } else {
      const { uid } = reportEditor.addNode({
        ...form.state,
        name: form.state.name ? form.state.name : defaultName,
      });
      savedNodeUid = uid;
      onCreate?.(savedNodeUid);
    }

    onClose();
  };

  const handleSetAttribute =
    <T extends keyof Node['attributes']>(attribute: T) =>
    (value?: Node['attributes'][T]) => {
      form.setState((s) => ({
        ...s,
        attributes: {
          ...s.attributes,
          [attribute]: value,
        },
      }));
    };

  const handleSetAttributeFromTextInputAsNumber =
    <T extends keyof Node['attributes']>(attribute: T) =>
    (event: ChangeEvent<HTMLInputElement>) =>
      handleSetAttribute(attribute)(
        parseInt(event.target.value) as Node['attributes'][T]
      );

  useEffect(() => {
    if (!visible) {
      form.reset();
    }
  }, [visible]);

  return (
    <SearchProvider>
      <FormProvider form={form}>
        <Wizard
          flow={nodeUid ? 'update' : 'create'}
          initialActiveStepId={initialActiveStep}
          isError={isError}
          isLoading={isLoading}
          isSuccess={isSuccess}
          onSave={hasUpdateProjectPermission ? handleSave : undefined}
          onRemove={hasUpdateProjectPermission ? handleRemove : undefined}
          onCancel={onClose}
          reset={visible}
        >
          <Modal
            {...rest}
            animation="FadeSlideUp"
            margin="x4"
            maxWidth={FULL_SCREEN_MODAL_WIDTH}
            onClose={onClose}
            overlayBackgroundCloseOnClick={false}
            visible={visible}
          >
            <ModalHeader>
              <ModalTitle>{node ? defaultName : 'New Node'}</ModalTitle>
            </ModalHeader>

            <ModalBody flex="vertical">
              <WizardStep
                id="attributes.coverFrameCondition"
                title="Cover frame condition"
              >
                <InputLabel>
                  {Object.values(NodeCoverFrameCondition).map(
                    (coverFrameCondition) => (
                      <CheckBox
                        borderSize='x1'
                        checked={
                          form.state.attributes.coverFrameCondition ===
                          coverFrameCondition
                        }
                        key={coverFrameCondition}
                        margin="x1"
                        onChange={() =>
                          handleSetAttribute('coverFrameCondition')(
                            coverFrameCondition
                          )
                        }
                      >
                        {coverFrameCondition}
                      </CheckBox>
                    )
                  )}
                </InputLabel>
              </WizardStep>

              <WizardStep id="attributes.diameter" title="Diameter">
                <DistanceInput
                  type="long"
                  onChange={handleSetAttribute('diameter')}
                  value={form.state.attributes.diameter}
                />
                <NodeDistanceQuickOptions
                  quickOptions={[0.315, 0.45, 0.475, 0.65, 0.9, 1.25]}
                  value={form.state.attributes.diameter}
                  onChange={handleSetAttribute('diameter')}
                />
              </WizardStep>

              <WizardStep id="attributes.breadth" title="Breadth">
                <DistanceInput
                  type="long"
                  onChange={handleSetAttribute('breadth')}
                  value={form.state.attributes.breadth}
                />
                <NodeDistanceQuickOptions
                  quickOptions={[0.6, 1, 1.2]}
                  value={form.state.attributes.breadth}
                  onChange={handleSetAttribute('breadth')}
                />
              </WizardStep>

              <WizardStep
                id="attributes.laterialConnections"
                title="Laterial Connections"
              >
                <InputLabel>
                  <Input
                    onChange={handleSetAttributeFromTextInputAsNumber(
                      'laterialConnections'
                    )}
                    value={
                      form.state.attributes.laterialConnections || undefined
                    }
                  />
                </InputLabel>
              </WizardStep>

              <WizardStep id="attributes.width" title="Width">
                <DistanceInput
                  type="long"
                  onChange={handleSetAttribute('width')}
                  value={form.state.attributes.width}
                />
                <NodeDistanceQuickOptions
                  quickOptions={[0.45, 0.675, 0.75]}
                  value={form.state.attributes.width}
                  onChange={handleSetAttribute('width')}
                />
              </WizardStep>

              <WizardStep id="attributes.material" title="Material">
                <InputLabel>
                  {Object.values(NodeMaterial).map((material) => (
                    <CheckBox
                      borderSize='x1'
                      checked={form.state.attributes.material === material}
                      key={material}
                      margin="x1"
                      onChange={() => handleSetAttribute('material')(material)}
                    >
                      {material}
                    </CheckBox>
                  ))}
                </InputLabel>
              </WizardStep>

              <WizardStep id="attributes.shape" title="Shape">
                <InputLabel>
                  {Object.values(NodeShape).map((shape) => (
                    <CheckBox
                      borderSize='x1'
                      checked={form.state.attributes.shape === shape}
                      key={shape}
                      margin="x1"
                      onChange={() => handleSetAttribute('shape')(shape)}
                    >
                      {shape}
                    </CheckBox>
                  ))}
                </InputLabel>
              </WizardStep>

              <WizardStep id="attributes.surfaceType" title="Surface type">
                <InputLabel>
                  {Object.values(NodeSurfaceType).map((surfaceType) => (
                    <CheckBox
                      borderSize='x1'
                      checked={
                        form.state.attributes.surfaceType === surfaceType
                      }
                      key={surfaceType}
                      margin="x1"
                      onChange={() =>
                        handleSetAttribute('surfaceType')(surfaceType)
                      }
                    >
                      {surfaceType}
                    </CheckBox>
                  ))}
                </InputLabel>
              </WizardStep>

              <WizardStep id="attributes.wallCondition" title="Wall condition">
                <InputLabel>
                  {Object.values(NodeWallCondition).map((wallCondition) => (
                    <CheckBox
                      borderSize='x1'
                      checked={
                        form.state.attributes.wallCondition === wallCondition
                      }
                      key={wallCondition}
                      margin="x1"
                      onChange={() =>
                        handleSetAttribute('wallCondition')(wallCondition)
                      }
                    >
                      {wallCondition}
                    </CheckBox>
                  ))}
                </InputLabel>
              </WizardStep>

              <WizardStep
                id="attributes.benchingCondition"
                title="Benching condition"
              >
                <InputLabel>
                  {Object.values(BenchingCondition).map((benchingCondition) => (
                    <CheckBox
                      borderSize='x1'
                      checked={
                        form.state.attributes.benchingCondition ===
                        benchingCondition
                      }
                      key={benchingCondition}
                      margin="x1"
                      onChange={() =>
                        handleSetAttribute('benchingCondition')(
                          benchingCondition
                        )
                      }
                    >
                      {benchingCondition}
                    </CheckBox>
                  ))}
                </InputLabel>
              </WizardStep>
            </ModalBody>

            <ModalFooter>
              <WizardSearch />
              <WizardStepError />
              <WizardControls />
            </ModalFooter>
          </Modal>
        </Wizard>
      </FormProvider>
    </SearchProvider>
  );
};

export default NodeAdditionalInformationModal;
