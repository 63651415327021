import { Me, ResponseError } from '@drainify/types';
import { useQuery } from 'react-query';
import useFetch from '../components/Api/useFetch';

const useMe = () => {
  const fetch = useFetch();

  const query = useQuery<Me, ResponseError>(['/me', fetch], () =>
    fetch('/me', { method: 'GET' })
  );

  return { query };
};

export default useMe;
