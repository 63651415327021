import {
  isCircular,
  ObservationCode,
  Ownership,
  PipeType,
  Section,
  SectionNodePosition,
} from '@drainify/types';
import { getLandOwnershipCodeSchema, isSection } from '@drainify/utils';
import {
  CheckBox,
  InputLabel,
  FormProvider,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalPaddingOffset,
  ModalProps,
  ModalTitle,
  Box,
  Text,
  Appear,
  Button,
} from 'preshape';
import React, { useEffect } from 'react';
import { v4 } from 'uuid';
import useSectionForm from '../../hooks/forms/useSectionForm';
import useProject from '../../hooks/useProject';
import { FULL_SCREEN_MODAL_WIDTH } from '../App/App';
import DataTable from '../DataTable/DataTable';
import DataTableGroup from '../DataTable/DataTableGroup';
import DataTableItem from '../DataTable/DataTableItem';
import DistanceInput from '../DistanceInput/DistanceInput';
import Divider from '../Divider/Divider';
import FlowInput from '../FlowInput/FlowInput';
import ImageFromFile from '../Image/ImageFromFile';
import NodeCreateButton from '../Node/NodeCreateButton';
import NodeList from '../Node/NodeList';
import SectionRouteHack from '../Node/SectionRouteHack';
import { useHasPermission } from '../Permissions/Permissions';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import { useReportContext } from '../Report/ReportProvider';
import SearchProvider from '../Search/SearchProvider';
import { useLanguageContext } from '../Usage/LanguageProvider';
import Wizard from '../Wizard/Wizard';
import WizardControls from '../Wizard/WizardControls/WizardControls';
import WizardReviewStep from '../Wizard/WizardReviewStep';
import WizardSearch from '../Wizard/WizardSearch';
import WizardStep from '../Wizard/WizardStep';
import WizardStepError from '../Wizard/WizardStepError';
import SectionAttributeList from './SectionAttribute/SectionAttributeList';
import SectionAttributeView from './SectionAttribute/SectionAttributeView';
import SectionDiameterQuickOptions from './SectionDiameterQuickOptions';
import SectionSummary from './SectionSummary';

type Props = ModalProps & {
  initialActiveStepId?: string;
  onClose: () => void;
  onCreate?: (sectionUid: string, inspectionId?: string) => void;
  sectionUid?: string;
  image?: File;
};

const SectionModal = ({
  initialActiveStepId,
  onClose,
  onCreate,
  sectionUid,
  visible,
  image,
  ...rest
}: Props) => {
  const { language } = useLanguageContext();
  const { isError, isLoading, isSuccess } = useReportContext();
  const { activeBooking, project } = useProjectContext();
  const { reportEditor } = useReportEditorContext();
  const section = reportEditor.getSectionByUid(sectionUid);
  const hasUpdateProjectPermission = useHasPermission('projects:update');
  const form = useSectionForm(
    section,
    project?.siteDefaults?.section,
    reportEditor.getSectionLength(section?.uid)
  );
  const { update } = useProject({ projectId: project?.uid });

  const [lengthPlaceholder, setLengthPlaceholder] = React.useState<string>();

  React.useEffect(() => {
    const startNode = reportEditor.getNodeByUid(form.state.nodeStartUid);
    const endNode = reportEditor.getNodeByUid(form.state.nodeEndUid);
    if (startNode?.point && endNode?.point) {
      const ratio = reportEditor.getScaleRatio();
      const len = reportEditor.getSectionLength(section?.uid, true);
      if (len) {
        setLengthPlaceholder((len * ratio).toFixed(2).toString());
      }
    } else {
      setLengthPlaceholder(undefined);
    }
  }, [form.state.nodeEndUid, form.state.nodeStartUid]);

  const handleSelectStartNode = (nodeStartUid?: string) => {
    form.setState((s) => ({ ...s, nodeStartUid }));
  };

  const handleSelectEndNode = (nodeEndUid?: string) => {
    form.setState((s) => ({ ...s, nodeEndUid }));
  };

  const handleSetAdditionalPoints = (points: GeoJSON.Point[]) => {
    form.setState((s) => ({
      ...s,
      additionalPoints: points.map((e) => ({ point: e, uid: v4() })),
    }));
  };

  const handleSetAttribute =
    <T extends keyof Section['attributes']>(attribute: T) =>
    (value?: Section['attributes'][T]) => {
      form.setState((s) => ({
        ...s,
        attributes: {
          ...s.attributes,
          [attribute]: value,
        },
      }));
      if (attribute === 'length') {
        if (section?.uid) {
          reportEditor.getSectionInspections(sectionUid).forEach((e) => {
            reportEditor
              .getInspectionObservations(e.uid)
              .filter((e) => e.inspectionMarker === 'finish')
              .forEach((e) => {
                reportEditor.updateObservationNoAsync(e.uid, {
                  ...e,
                  distance: value as number,
                });
              });
          });
        }
      }
    };

  const handleSave = async () => {
    update.mutateAsync({
      ...project,
      siteDefaults: {
        section: {
          use: form.state.attributes.use,
          material: form.state.attributes.material,
          shape: form.state.attributes.shape,
          diameter: form.state.attributes.diameter,
          pipeType: form.state.attributes.pipeType,
          sectionOwnership: form.state.attributes.sectionOwnership,
          width: form.state.attributes.width,
          height: form.state.attributes.height,
        },
      },
    });
    if (sectionUid) {
      reportEditor.updateSection(sectionUid, form.state);
      onClose();
    } else {
      const { uid } = reportEditor.addSection(form.state, activeBooking?.uid);
      const inspection = reportEditor.getSectionInspectionLatest(uid);
      onCreate?.(uid, inspection?.uid);
      onClose();
    }
  };

  useEffect(() => {
    if (visible) {
      form.reset();
    }
  }, [visible]);

  return (
    <SearchProvider>
      <FormProvider form={form}>
        <Wizard
          flow={sectionUid ? 'update' : 'create'}
          initialActiveStepId={initialActiveStepId}
          isError={isError}
          isLoading={isLoading}
          isSuccess={isSuccess}
          onSave={hasUpdateProjectPermission ? handleSave : undefined}
          onCancel={onClose}
          reset={visible}
        >
          <Modal
            {...rest}
            animation="FadeSlideUp"
            margin="x4"
            maxWidth={FULL_SCREEN_MODAL_WIDTH}
            onClose={onClose}
            overlayBackgroundCloseOnClick={false}
            visible={visible}
          >
            <ModalHeader>
              <ModalTitle>
                {section ? reportEditor.getSectionName(section) : 'New section'}
              </ModalTitle>
            </ModalHeader>

            <ModalBody flex="vertical" alignChildrenVertical="middle">
              {isSection(section) &&
                section.nodeEndUid &&
                section.nodeStartUid &&
                reportEditor.getNodeByUid(section.nodeStartUid)?.point &&
                reportEditor.getNodeByUid(section.nodeStartUid)?.point && (
                  <WizardStep id="additionalPoints" title="Pipe route">
                    <SectionRouteHack
                      onChange={(e) => handleSetAdditionalPoints(e)}
                      section={section}
                    />
                  </WizardStep>
                )}
              <WizardStep
                action={
                  <NodeCreateButton
                    onCreate={handleSelectStartNode}
                    nextStep="nodeEndUid"
                    variant="secondary"
                  />
                }
                id="nodeStartUid"
                withSearch
                title="Start node"
              >
                <ModalPaddingOffset paddingHorizontal="x4">
                  <NodeList
                    validate={false}
                    disabledUids={
                      form.state.nodeEndUid
                        ? [form.state.nodeEndUid]
                        : undefined
                    }
                    onItemClick={handleSelectStartNode}
                    selectedUids={
                      form.state.nodeStartUid ? [form.state.nodeStartUid] : []
                    }
                  />
                </ModalPaddingOffset>
              </WizardStep>

              <WizardStep
                action={
                  <NodeCreateButton
                    onCreate={handleSelectEndNode}
                    nextStep="attributes.flow"
                    variant="secondary"
                  />
                }
                id="nodeEndUid"
                title="Finish node"
                withSearch
              >
                <ModalPaddingOffset paddingHorizontal="x4">
                  <NodeList
                    validate={false}
                    disabledUids={
                      form.state.nodeStartUid
                        ? [
                            form.state.nodeStartUid,
                            ...reportEditor.getNodesWithStartNode(
                              form.state.nodeStartUid
                            ),
                          ]
                        : undefined
                    }
                    nodePosition={SectionNodePosition.END}
                    onItemClick={handleSelectEndNode}
                    selectedUids={
                      form.state.nodeEndUid ? [form.state.nodeEndUid] : []
                    }
                  />
                  <Divider borderSize={'x3'} />
                  <DataTable margin="x4">
                    <DataTableGroup>
                      <DataTableItem
                        onClick={() => handleSelectEndNode(undefined)}
                        selected={form.state.nodeEndUid === undefined}
                      >
                        <Box flex="vertical" wrap>
                          <Text size="x3" strong>
                            Unknown
                          </Text>
                          <Text strong>
                            You will need to replace with new/existing node
                            before submitting.
                          </Text>
                        </Box>
                      </DataTableItem>
                    </DataTableGroup>
                  </DataTable>
                </ModalPaddingOffset>
              </WizardStep>

              <WizardStep id="attributes.flow" title="Flow direction">
                <FlowInput
                  onChange={handleSetAttribute('flow')}
                  section={form.state}
                  value={form.state.attributes.flow}
                />
              </WizardStep>

              {sectionUid && (
                <WizardStep id="attributes.length" title={`Length`}>
                  <Box flex="vertical" gap="x2">
                    {image && <ImageFromFile file={image} height="300px" />}
                    <DistanceInput
                      name="attributes.length"
                      onChange={handleSetAttribute('length')}
                      type="long"
                      value={form.state.attributes.length}
                    />
                    {lengthPlaceholder && (
                      <Box
                        flex="horizontal"
                        gap="x2"
                        alignChildrenVertical="middle"
                        alignChildrenHorizontal="middle"
                        padding="x2"
                      >
                        <Button
                          size="x1"
                          variant="tertiary"
                          color="accent"
                          onClick={() => {
                            const float = parseFloat(lengthPlaceholder);
                            handleSetAttribute('length')(float);
                          }}
                          titlecase
                        >
                          <Text size="x2">{`Estimated: ${lengthPlaceholder}m`}</Text>
                        </Button>
                      </Box>
                    )}
                    {reportEditor
                      .getInspectionObservations(
                        reportEditor.getSectionInspectionLatest(section?.uid)
                          ?.uid
                      )
                      .filter(
                        (e) => e.code.includes(ObservationCode.SA) && e.distance
                      ).length > 0 && (
                      <Button
                        onClick={() =>
                          handleSetAttribute('length')(
                            reportEditor
                              .getInspectionObservations(
                                reportEditor.getSectionInspectionLatest(
                                  section?.uid
                                )?.uid
                              )
                              .filter(
                                (e) =>
                                  e.code.includes(ObservationCode.SA) &&
                                  e.distance
                              )[0].distance
                          )
                        }
                      >
                        Length unknown - Use SA
                      </Button>
                    )}
                  </Box>
                </WizardStep>
              )}

              <WizardStep id="attributes.shape" title="Pipe shape" withSearch>
                <Box>
                  {form.state.attributes.shape && (
                    <Appear>
                      <Box paddingBottom="x2">
                        <Text strong>Selected</Text>
                        <DataTable borderRadius="10px">
                          <DataTableGroup>
                            <DataTableItem
                              key={form.state.attributes.shape}
                              selected={true}
                            >
                              <SectionAttributeView
                                attribute="shape"
                                code={form.state.attributes.shape}
                              />
                            </DataTableItem>
                          </DataTableGroup>
                        </DataTable>
                      </Box>
                    </Appear>
                  )}
                </Box>
                <SectionAttributeList
                  attribute="shape"
                  onChange={(e) => {
                    handleSetAttribute('shape')?.(e);
                    if (isCircular(e)) {
                      handleSetAttribute('height')?.(undefined);
                      handleSetAttribute('width')?.(undefined);
                    } else if (e) {
                      handleSetAttribute('diameter')?.(undefined);
                    }
                  }}
                  value={form.state.attributes.shape}
                />
              </WizardStep>

              {isCircular(form.state.attributes.shape) && (
                <WizardStep id="attributes.diameter" title="Pipe diameter">
                  <DistanceInput
                    name="attributes.diameter"
                    onChange={handleSetAttribute('diameter')}
                    type="short"
                    value={form.state.attributes.diameter}
                  />

                  <SectionDiameterQuickOptions
                    margin="x6"
                    onChange={handleSetAttribute('diameter')}
                    value={form.state.attributes.diameter}
                  />
                </WizardStep>
              )}

              {form.state.attributes.shape &&
                !isCircular(form.state.attributes.shape) && (
                  <>
                    <WizardStep id="attributes.height" title="Pipe height">
                      {image && <ImageFromFile file={image} height="300px" />}
                      <DistanceInput
                        name="attributes.height"
                        onChange={handleSetAttribute('height')}
                        type="short"
                        value={form.state.attributes.height}
                      />
                    </WizardStep>

                    <WizardStep id="attributes.width" title="Pipe width">
                      {image && <ImageFromFile file={image} height="300px" />}
                      <DistanceInput
                        name="attributes.width"
                        onChange={handleSetAttribute('width')}
                        type="short"
                        value={form.state.attributes.width}
                      />
                    </WizardStep>
                  </>
                )}

              <WizardStep
                id="attributes.material"
                title="Pipe material"
                withSearch
              >
                {image && <ImageFromFile file={image} height="300px" />}
                {form.state.attributes.material && (
                  <Appear>
                    <Box paddingBottom="x2">
                      <Text strong>Selected</Text>
                      <DataTable borderRadius="10px">
                        <DataTableGroup>
                          <DataTableItem
                            key={form.state.attributes.material}
                            selected={true}
                          >
                            <SectionAttributeView
                              attribute="material"
                              code={form.state.attributes.material}
                            />
                          </DataTableItem>
                        </DataTableGroup>
                      </DataTable>
                    </Box>
                  </Appear>
                )}
                <SectionAttributeList
                  attribute="material"
                  onChange={handleSetAttribute('material')}
                  value={form.state.attributes.material}
                />
              </WizardStep>

              <WizardStep id="attributes.pipeType" title="Pipe type">
                {Object.values(PipeType).map((pipeType) => (
                  <CheckBox
                    borderSize="x1"
                    checked={form.state.attributes.pipeType === pipeType}
                    key={pipeType}
                    margin="x1"
                    onChange={() => handleSetAttribute('pipeType')(pipeType)}
                  >
                    {pipeType}
                  </CheckBox>
                ))}
              </WizardStep>

              <WizardStep id="attributes.use" title="Section use" withSearch>
                <Box>
                  {form.state.attributes.use && (
                    <Appear>
                      <Box paddingBottom="x2">
                        <Text strong>Selected</Text>
                        <DataTable borderRadius="10px">
                          <DataTableGroup>
                            <DataTableItem
                              key={form.state.attributes.use}
                              selected={true}
                            >
                              <SectionAttributeView
                                attribute="use"
                                code={form.state.attributes.use}
                              />
                            </DataTableItem>
                          </DataTableGroup>
                        </DataTable>
                      </Box>
                    </Appear>
                  )}
                </Box>
                {image && <ImageFromFile file={image} height="300px" />}
                <SectionAttributeList
                  attribute="use"
                  onChange={handleSetAttribute('use')}
                  value={form.state.attributes.use}
                />
              </WizardStep>

              <WizardStep
                id="attributes.sectionOwnership"
                title="Section ownership"
              >
                <InputLabel>
                  {Object.values(Ownership).map((sectionOwnership) => (
                    <CheckBox
                      checked={
                        form.state.attributes.sectionOwnership ===
                        sectionOwnership
                      }
                      key={sectionOwnership}
                      borderSize="x1"
                      margin="x1"
                      onChange={() =>
                        handleSetAttribute('sectionOwnership')(sectionOwnership)
                      }
                    >
                      {
                        getLandOwnershipCodeSchema(sectionOwnership).name[
                          language
                        ]
                      }
                    </CheckBox>
                  ))}
                </InputLabel>
              </WizardStep>

              <WizardReviewStep>
                <SectionSummary section={form.state} />
              </WizardReviewStep>
            </ModalBody>

            <ModalFooter>
              <WizardSearch />
              <WizardStepError />
              <WizardControls />
            </ModalFooter>
          </Modal>
        </Wizard>
      </FormProvider>
    </SearchProvider>
  );
};

export default SectionModal;
