"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAddressStringInformal = exports.getAddressString = exports.locationValidation = void 0;
const yup = __importStar(require("yup"));
const addressValidation = yup.object().shape({
    company: yup.string().max(40),
    streetNumber: yup.string().required('Street number is required'),
    street: yup.string().required('Street is required'),
    town: yup.string().required('Town is required'),
    county: yup.string().required('County is required'),
    postcode: yup.string().required('Postcode is required'),
    country: yup.string().required('Country is required'),
});
exports.locationValidation = yup.object().shape({
    address: addressValidation.required('Address is required'),
    point: yup.object().required('Address is required'),
});
const getAddressString = ({ address: { company, county, country, postcode, street, streetNumber, town }, }) => {
    const address = [
        company,
        [streetNumber, street].join(' '),
        town,
        county,
        postcode,
        country,
    ]
        .filter(Boolean)
        .join(', ');
    return address;
};
exports.getAddressString = getAddressString;
const getAddressStringInformal = ({ address: { company, postcode, street, streetNumber, town }, }) => {
    const address = [company, [streetNumber, street].join(' '), town, postcode]
        .filter(Boolean)
        .join(', ');
    return address;
};
exports.getAddressStringInformal = getAddressStringInformal;
