import { OrgRole } from '@drainify/types';
import { Box, BoxProps, Text, TextProps } from 'preshape';
import React, { PropsWithChildren } from 'react';
import PlaceholderBlock from '../Placeholders/PlaceholderBlock';
import SymbolTemplate from '../Symbol/SymbolTemplate';

export const orgRoleNames: Record<OrgRole, string> = {
  [OrgRole.OWNER]: 'Owner',
  [OrgRole.ADMIN]: 'Admin',
  [OrgRole.ENGINEER]: 'Engineer',
};

export const orgRoleDescriptions: Record<OrgRole, string> = {
  [OrgRole.OWNER]:
    'Owners have full access to manage the organisation (including but limited to billing and user management)',
  [OrgRole.ADMIN]:
    'Admins have access to manage projects and customers, but not the organisation',
  [OrgRole.ENGINEER]:
    'Engineers have access to contribute to projects and bookings but have limited management',
};

export const orgRoleSymbol: Record<OrgRole, string> = {
  [OrgRole.ADMIN]: 'A',
  [OrgRole.ENGINEER]: 'E',
  [OrgRole.OWNER]: 'O',
};

type Props = TextProps & {
  role?: OrgRole;
};

const OrgRoleView = ({
  role,
  ...rest
}: PropsWithChildren<BoxProps & Props>) => {
  return (
    <Text flex="horizontal" gap="x4" {...rest} alignChildrenVertical="middle">
      <Box basis="0" grow>
        {role ? (
          <Text size="x3">
            <Text strong>{orgRoleNames[role]}</Text>
            <Text>{orgRoleDescriptions[role]}</Text>
          </Text>
        ) : (
          <PlaceholderBlock />
        )}
      </Box>

      <Box>
        <SymbolTemplate>
          {role && (
            <Text breakOn="none" size="x5">
              {orgRoleSymbol[role]}
            </Text>
          )}
        </SymbolTemplate>
      </Box>
    </Text>
  );
};

export default OrgRoleView;
