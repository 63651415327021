import { ObservationOptionEnum } from '@drainify/utils';
import { Box, CheckBox, Icons, Text } from 'preshape';
import React, { forwardRef, ForwardRefRenderFunction } from 'react';

type Props = {
  asSummary?: boolean;
  name?: string;
  option: ObservationOptionEnum;
  onSelect: (value: string) => void;
  value?: string;
};

const ObservationOptionEnumInput: ForwardRefRenderFunction<
  HTMLDivElement,
  Props
> = ({ asSummary, name: inputName, option, onSelect, value }, ref) => {
  if (asSummary) {
    return <Icons.Image size="3rem" />;
  }

  return (
    <Box ref={ref}>
      {Object.entries(option.enum).map(([enumValue, { name, description }]) => (
        <CheckBox 
          borderSize='x1'
          checked={value === enumValue}
          name={inputName}
          onChange={() => onSelect(enumValue)}
          key={enumValue}
          margin="x2"
        >
          <Box alignChildrenVertical="middle" flex="horizontal" gap="x2">
            <Box grow minWidth={0} shrink>
              <Text>{name}</Text>
              {description && <Text weak>{description}</Text>}
            </Box>

            <Box>
              <Icons.Image size="3rem" />
            </Box>
          </Box>
        </CheckBox>
      ))}
    </Box>
  );
};

export default forwardRef(ObservationOptionEnumInput);
