import { User } from '@drainify/types';
import { Input } from 'preshape';
import React, { ChangeEvent } from 'react';
import useMeOrg from '../../hooks/useMeOrg';
import useOrgMembers from '../../hooks/useOrgMembers';
import DataQueryTable from '../DataTable/DataQueryTable';
import DataTableGroup from '../DataTable/DataTableGroup';
import DataTableItem from '../DataTable/DataTableItem';
import UserView from './UserView';

type Props = {
  onSelect: (user: User) => void;
  selected?: User[];
  withSearch?: boolean;
};

const UserSelectionList = ({ onSelect, selected = [], withSearch }: Props) => {
  const { query: queryMeOrg } = useMeOrg();
  const [searchString, setSearchString] = React.useState<string>('');
  const { query: queryMembers } = useOrgMembers(
    queryMeOrg.data?.uid,
    searchString
  );

  return (
    <>
      {withSearch && (
        <Input
          value={searchString}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setSearchString(e.target.value)
          }
        />
      )}
      <DataQueryTable
        entity="users"
        placeholder={<UserView />}
        query={queryMembers}
      >
        <DataTableGroup>
          {queryMembers.data?.data.map((member) => (
            <DataTableItem
              key={member.uid}
              onClick={() => onSelect(member.user)}
              selected={selected.some((user) => user.uid === member.user.uid)}
            >
              <UserView member={member} user={member.user} />
            </DataTableItem>
          ))}
        </DataTableGroup>
      </DataQueryTable>
    </>
  );
};

export default UserSelectionList;
