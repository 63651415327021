import { Button, ButtonProps, Icons, Text } from 'preshape';
import React from 'react';
import { useHasPermission } from '../Permissions/Permissions';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';

type Props = ButtonProps & {
  onCreate?: (nodeUid: string) => void;
  sectionUid?: string;
};

const InspectionCreateButton = ({ onCreate, sectionUid, ...rest }: Props) => {
  const { createNewInspection } = useReportEditorContext();
  const { activeBooking } = useProjectContext();
  const hasUpdateProjectPermission = useHasPermission('projects:update');

  if (!hasUpdateProjectPermission || !sectionUid) {
    return null;
  }

  return (
    <Button
      variant="secondary"
      {...rest}
      color="accent"
      gap="x2"
      onClick={() => createNewInspection(sectionUid, onCreate)}
      type="button"
      disabled={!!activeBooking?.completedAt}
    >
      <Text>New inspection</Text>
      <Icons.Plus size="1rem" />
    </Button>
  );
};

export default InspectionCreateButton;
