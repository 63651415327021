import { Box, BoxProps } from 'preshape';
import React, { PropsWithChildren } from 'react';
import CardHeader from './CardHeader';

export type CardProps = BoxProps & {
  action?: JSX.Element;
  description?: string;
  title?: JSX.Element | string;
};

const Card = ({
  action,
  backgroundColor = 'background-shade-2',
  borderSize,
  children,
  description,
  overflow,
  padding = 'x4',
  paddingHorizontal = padding,
  paddingVertical = padding,
  title,
  ...rest
}: PropsWithChildren<CardProps>) => {
  return (
    <Box {...rest} flex="vertical" gap="x2">
      {(title || action) && (
        <CardHeader action={action} description={description} title={title} />
      )}

      {children && (
        <Box
          backgroundColor={backgroundColor}
          borderSize={borderSize}
          borderRadius="x2"
          grow
          overflow={overflow}
          paddingHorizontal={paddingHorizontal}
          paddingVertical={paddingVertical}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

export default Card;
