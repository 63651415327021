import { Button, Buttons, Icons, Text, useFormContext } from 'preshape';
import React, { useCallback, useContext } from 'react';
import { WizardContext } from '../Wizard';
import WizardStepProgress from '../WizardStepProgress';

const WizardControlsTraverseSteps = () => {
  const { activeStepId, flow, onCancel, setActiveStepId, stepIds } =
    useContext(WizardContext);
  const { getError } = useFormContext();

  const activeStepIndex = activeStepId ? stepIds.indexOf(activeStepId) : -1;
  const hasPrevious = activeStepIndex > 0;
  const hasNext =
    activeStepIndex !== -1 && activeStepIndex < stepIds.length - 1;
  const isReviewStep = activeStepId === 'review';
  const stepError = activeStepId && getError(activeStepId);

  const goToNextStep = useCallback(() => {
    const nextStep = stepIds[activeStepIndex + 1];

    if (nextStep) {
      setActiveStepId(nextStep);
    }
  }, [activeStepIndex, stepIds]);

  const goToPreviousStep = useCallback(() => {
    const previousStep = stepIds[activeStepIndex - 1];

    if (previousStep) {
      setActiveStepId(previousStep);
    }
  }, [activeStepIndex, stepIds]);

  return (
    <>
      <WizardStepProgress />

      <Buttons margin="x4">
        {hasPrevious && (
          <Button basis="0" gap="x2" grow onClick={goToPreviousStep} size="x3">
            <Icons.ChevronLeft />
            <Text>Previous</Text>
          </Button>
        )}

        {hasNext && (
          <Button
            basis="0"
            color={!stepError ? 'accent' : undefined}
            disabled={!!stepError}
            elevate={!stepError}
            gap="x2"
            grow
            onClick={goToNextStep}
            id="accel-Enter"
            variant="primary"
            size="x3"
          >
            <Text
              style={{
                WebkitUserSelect: 'none',
                msUserSelect: 'none',
                userSelect: 'none',
                WebkitTouchCallout: 'none',
                KhtmlUserSelect: 'none',
                MozUserSelect: 'none',
              }}
            >
              Next
            </Text>
            <Icons.ChevronRight />
          </Button>
        )}
      </Buttons>

      {!isReviewStep && flow === 'create' && onCancel && false && (
        <Buttons alignChildrenHorizontal="end">
          <Button
            onClick={onCancel}
            variant="tertiary"
            style={{
              WebkitUserSelect: 'none',
              msUserSelect: 'none',
              userSelect: 'none',
              WebkitTouchCallout: 'none',
              KhtmlUserSelect: 'none',
              MozUserSelect: 'none',
            }}
          >
            Cancel
          </Button>
        </Buttons>
      )}
    </>
  );
};

export default WizardControlsTraverseSteps;
