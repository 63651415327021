import { Appear, Box, Text } from 'preshape';
import React, { PropsWithChildren, useContext, useEffect } from 'react';
import { isDesktop } from '../../utils/client';
import { WizardContext } from './Wizard';

export type WizardStepProps = {
  action?: JSX.Element;
  id: string;
  title?: string;
  withSearch?: boolean;
  noAnimate?: boolean;
};

const WizardStep = ({
  action,
  children,
  id,
  title,
  withSearch,
  noAnimate,
}: PropsWithChildren<WizardStepProps>) => {
  const { activeStepId, registerStep } = useContext(WizardContext);

  useEffect(() => {
    return registerStep(id, {
      withSearch,
    });
  }, [registerStep, id, withSearch]);

  if (activeStepId !== id) {
    return null;
  }

  if (noAnimate) {
    return (
      <Box
        flex="vertical"
        grow
        alignChildrenVertical={isDesktop() ? 'start' : 'middle'}
      >
        {(title || action) && (
          <Box alignChildrenVertical="middle" flex="horizontal" margin="x4">
            {title && (
              <Text grow shrink size="x6" strong>
                {title}
              </Text>
            )}

            {action && <Box>{action}</Box>}
          </Box>
        )}

        {children}
      </Box>
    );
  }

  return (
    <Appear
      flex="vertical"
      grow
      alignChildrenVertical={isDesktop() ? 'start' : 'middle'}
    >
      {(title || action) && (
        <Box alignChildrenVertical="middle" flex="horizontal" margin="x4">
          {title && (
            <Text grow shrink size="x6" strong>
              {title}
            </Text>
          )}

          {action && <Box>{action}</Box>}
        </Box>
      )}

      {children}
    </Appear>
  );
};

export default WizardStep;
