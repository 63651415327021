import { Attributes, ImageProps } from 'preshape';
import React, { useEffect, useRef, useState } from 'react';
import ImageFallback from './ImageFallback';

type Props = ImageProps & {
  file?: File;
};

const ImageFromFile = ({
  file,
  ...rest
}: Attributes<HTMLImageElement, Props>) => {
  const refUnmounted = useRef(false);
  const [{ isError, isLoading, dataUrl }, setState] = useState({
    isError: false,
    isLoading: !!file,
    dataUrl: '',
  });

  useEffect(() => {
    refUnmounted.current = false;
    const reader = new FileReader();

    const handleFileLoad = () => {
      if (!refUnmounted.current) {
        setState({
          isError: false,
          isLoading: false,
          dataUrl: reader.result?.toString() || '',
        });
      }
    };

    const handleFileError = () => {
      if (!refUnmounted.current) {
        setState({
          isError: true,
          isLoading: false,
          dataUrl: '',
        });
      }
    };

    reader.addEventListener('load', handleFileLoad);
    reader.addEventListener('error', handleFileError);

    if (file) {
      reader.readAsDataURL(file);
    }

    return () => {
      refUnmounted.current = true;
    };
  }, [file]);

  return (
    <ImageFallback
      {...rest}
      isError={isError}
      isLoading={isLoading}
      src={dataUrl}
    />
  );
};

export default ImageFromFile;
