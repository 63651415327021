import { useStripe } from '@stripe/react-stripe-js';
import {
  Alert,
  Box,
  Button,
  Buttons,
  Icons,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from 'preshape';
import React, { useContext, useEffect } from 'react';
import AsyncButton from '../AsyncButton/AsyncButton';
import { AuthenticationContext } from '../Authentication/Authenticate';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import { useOrgContext } from '../Org/OrgProvider';
import { useUsageContext } from './UsageProvider';

const FIFTY_GB = 50000000000;

const UsageAddStorage = () => {
  const [visible, setVisible] = React.useState(false);
  const [confirmationVisible, setConfirmationVisible] = React.useState(false);
  const stripe = useStripe();
  const { refetch: refetchUsage, usage } = useUsageContext();
  const { user } = useContext(AuthenticationContext);
  const [error, setError] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);
  const { org } = useOrgContext();
  const [quantity, setQuantity] = React.useState(usage?.additionalStorage || 0);

  useEffect(() => {
    if (!visible) {
      setError(undefined);
      setQuantity(usage?.additionalStorage || 0);
    }
  }, [visible]);

  if (!org || !stripe) {
    return null;
  }

  const handleSave = async () => {
    const payload = { quantity };
    const token = await user?.getIdToken();
    const resp = await fetch(
      `${process.env.DRAINIFY_API_URL}/payment/storage`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ...payload }),
      }
    );

    refetchUsage?.();
    setLoading(false);
    if (resp.ok) {
      // do ok
      setConfirmationVisible(true);
      setVisible(false);
    } else {
      const json = (await resp.json()) as Error;
      setError(json.message);
    }
    // setVisible(false);
  };

  return (
    <>
      <Button
        onClick={() => setVisible(true)}
        color="accent"
        variant="tertiary"
        size="x1"
      >
        More storage
      </Button>
      {visible && (
        <Modal
          animation="FadeSlideUp"
          margin="x4"
          onClose={() => setVisible(false)}
          overlayBackgroundCloseOnClick={false}
          visible={visible}
        >
          <ModalHeader>Adjust data plan</ModalHeader>

          <ModalBody flex="vertical" alignChildrenVertical="middle" gap="x3">
            <Text size="x6" strong>
              Adjust data plan
            </Text>
            <Box
              backgroundColor="background-shade-3"
              flex="vertical"
              gap="x2"
              padding="x3"
            >
              <Box flex="horizontal" alignChildren="middle" gap="x1">
              <DropdownMenu options={[
                  {
                    value: 0,
                    name: '0',
                    description: usage?.additionalStorage === 0 ? 'Your current plan' : ''
                  },
                  {
                    value: FIFTY_GB * 10,
                    name: '500GB',
                    description: usage?.additionalStorage === FIFTY_GB * 10 ? 'Your current plan' : ''
                  },
                  {
                    value: FIFTY_GB * 20,
                    name: '1TB',
                    description: usage?.additionalStorage === FIFTY_GB * 20 ? 'Your current plan' : ''
                  },
                  {
                    value: FIFTY_GB * 200,
                    name: '10TB',
                    description: usage?.additionalStorage === FIFTY_GB * 200 ? 'Your current plan' : ''
                  }
                ]}
                onChange={e => setQuantity(e)} value={quantity}              
              />
              </Box>
              <Box
                flex="horizontal"
                alignChildrenVertical="middle"
                alignChildrenHorizontal="middle"
                gap="x3"
              >
                <Label>
                  <Text strong size="x3">
                    {`£${Math.abs(
                      Math.round(
                        (quantity - (usage?.additionalStorage || 0)) /
                          (1000000000 * 12)
                      )
                    )} ${
                      quantity >= (usage?.additionalStorage || 0)
                        ? 'extra to your data plan'
                        : 'reduction to your data plan'
                    }`}
                  </Text>
                </Label>
              </Box>
            </Box>
            <Text size="x2">Price is on top of your base subscription</Text>
            <Text size="x2">
              You'll only be charged for the remaining part of the cycle for
              this period
            </Text>
            <Alert color="accent" padding="x3" backgroundColor="accent-shade-2">
              <Text size="x1">
                You will be able to use the additional storage straight away and
                charged.
              </Text>
              <Text size="x1">
                Any adjustments (up or down) will be reflect in your next
                invoice.
              </Text>
              <Text size="x1">
                Additional data plans are charged monthly on top of your base
                Drainify subscription
              </Text>
            </Alert>
          </ModalBody>
          <ModalFooter>
            <Buttons>
              <AsyncButton
                color="positive"
                variant="primary"
                disabled={quantity === usage?.additionalStorage}
                grow
                isError={error !== undefined}
                isLoading={loading}
                onClick={async () => {
                  setLoading(true);
                  handleSave();
                }}
              >
                <Icons.CreditCard />
                Confirm purchase
              </AsyncButton>
            </Buttons>
          </ModalFooter>
        </Modal>
      )}
      {confirmationVisible && (
        <Modal
          onClose={() => {
            setQuantity(usage?.additionalStorage || 0);
            setConfirmationVisible(false);
          }}
          visible
        >
          <ModalHeader>Success</ModalHeader>
          <ModalBody>
            <Box flex="vertical">
              <Box flex="vertical" gap="x3">
                <Box flex="horizontal" alignChildrenHorizontal="middle">
                  <Text textColor="positive-shade-5" align="middle">
                    <Icons.CheckCircle size="5rem" alignChildren="middle" />
                  </Text>
                </Box>
                Subscription updated
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Box flex="horizontal">
              <Button
                grow
                color="accent"
                onClick={() => setConfirmationVisible(false)}
                variant="primary"
              >
                Close
              </Button>
            </Box>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default UsageAddStorage;
