import { SurveyReview, SurveyReviewState } from '@drainify/types';
import { ReportError, runValidations } from '@drainify/utils';
import { FormValidateFn, useForm } from 'preshape';
import { useCallback, useMemo } from 'react';
import { v4 } from 'uuid';
import * as yup from 'yup';
import { useReportEditorContext } from '../../components/Report/ReportEditorProvider';

const validations = yup.object().shape({
  rating: yup
    .number()
    .required('Rating is required'),
});

const useSurveyReviewForm = (surveyReview?: SurveyReview) => {
  const { reportEditor } = useReportEditorContext();
  const initial = useMemo<SurveyReview>(
    () =>
      surveyReview || {
        uid: v4(),
        state: SurveyReviewState.IN_PROGRESS,
      },
    [surveyReview]
  );

  const validate = useCallback<FormValidateFn<SurveyReview, ReportError>>(
    (surveyReview, setError) => {
      setError(runValidations(validations, surveyReview));
    },
    [reportEditor]
  );

  return useForm({
    initial,
    validate,
  });
};

export default useSurveyReviewForm;
