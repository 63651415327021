import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';
import { useSearchContext } from './SearchProvider';

export type SearchKeyword = undefined | string;
export type SearchResults = Record<string, boolean>;

type SearchResultGroupContextProps = {
  getMatchesKeywords: (keywords: SearchKeyword) => boolean;
  setSearchKeywords: (keywords: SearchKeyword) => void;
};

const SearchResultGroupContext = createContext<SearchResultGroupContextProps>({
  getMatchesKeywords: () => true,
  setSearchKeywords: () => {},
});

export const useSearchResultContext = () =>
  useContext(SearchResultGroupContext);

const SearchResultGroup = ({ children }: PropsWithChildren<{}>) => {
  const { searchString } = useSearchContext();
  const [searchKeywords, setSearchKeywords] = useState<SearchResults>({});
  const previousSearchString = React.useRef('');

  const getSearchKey = (keywords: SearchKeyword) => {
    return (keywords || '').toLowerCase();
  };

  const testSearchStringOnKey = (key: string) => {
    if (previousSearchString.current.length > searchString.length) {
      allSearchKeys.map((key) => {
        setSearchKeywords((r) => ({ ...r, [key]: true }));
      });
    }
    previousSearchString.current = searchString;
    return (
      !searchString ||
      (searchKeywords[key] && key.startsWith(searchString.toLowerCase()))
    );
  };

  const handleSetSearchKeywords = (keywords: SearchKeyword) => {
    const key = getSearchKey(keywords);

    setSearchKeywords((r) => ({ ...r, [key]: true }));

    return () => {
      setSearchKeywords((r) => ({ ...r, [key]: false }));
    };
  };

  const getMatchesKeywords = (keywords: SearchKeyword) => {
    return testSearchStringOnKey(getSearchKey(keywords));
  };

  const allSearchKeys = Object.keys(searchKeywords);
  const hasAnyResults =
    allSearchKeys.length === 0 || allSearchKeys.some(testSearchStringOnKey);

  const value = {
    getMatchesKeywords,
    setSearchKeywords: handleSetSearchKeywords,
  };

  return (
    <SearchResultGroupContext.Provider value={value}>
      {hasAnyResults && <>{children}</>}
    </SearchResultGroupContext.Provider>
  );
};

export default SearchResultGroup;
