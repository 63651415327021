
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import UserAddBuddyTokens from '../Usage/UsageAddBuddyTokens';


const AIPOIBuddyUpsellButton = () => {
  const stripePromise = React.useState(() =>
    loadStripe(process.env.STRIPE_PUBLISHABLE_KEY!)
  )[0];


  return (
        <Elements stripe={stripePromise}>
            <UserAddBuddyTokens/>
        </Elements>
    )

};

export default AIPOIBuddyUpsellButton;
