import { Appear, Box, Buttons, Text } from 'preshape';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import DataTableGroup from '../../../components/DataTable/DataTableGroup';
import DataTableItem from '../../../components/DataTable/DataTableItem';
import DataTableMessage from '../../../components/DataTable/DataTableMessage';
import Explainer from '../../../components/Explainer/Explainer';
import InfiniteScroll from '../../../components/InfiniteScroll/InfiniteScroll';
import InspectionAdvancedActions from '../../../components/Inspection/InspectionAdvancedActions';
import InspectionCreateButton from '../../../components/Inspection/InspectionCreateButton';
import InspectionList from '../../../components/Inspection/InspectionList';
import { useProjectContext } from '../../../components/Project/ProjectProvider';
import { useReportEditorContext } from '../../../components/Report/ReportEditorProvider';
import SectionCreateButton from '../../../components/Section/SectionCreateButton';
import Page from '../Layout/Page';
import PageBody from '../Layout/PageBody';
import PageHeader from '../Layout/PageHeader';

const SectionsSnapshotMobilePage = () => {
  const navigate = useNavigate();
  const { activeBooking } = useProjectContext();
  const { reportEditor } = useReportEditorContext();
  const [sectionNumber, setSectionNumber] = React.useState(10);

  return (
    <Page>
      <PageHeader
        title={`${activeBooking?.jobId}`}
        action={
          <Explainer title={'Sections'}>
            <Text>
              Capturing each section's essential details becomes a breeze on
              site.
            </Text>
            <Text>
              You can effortlessly upload the data to the cloud, making it
              instantly accessible for editing at your office.
            </Text>
            <Text>
              Once back at the office, you can easily upload the relevant
              section video, incorporate observations, and finalise the survey.
            </Text>
          </Explainer>
        }
      />

      <PageBody flex="vertical" gap="x3" overflow="auto" basis="0" grow>
        <Box flex="vertical" gap="x2">
          {
            reportEditor.report.sections.length === 0 && 

      <DataTable>
            <DataTableGroup>
              <DataTableItem>
                <DataTableMessage>
                  There are no sections to show here
                </DataTableMessage>
              </DataTableItem>
            </DataTableGroup>
          </DataTable>
          }
          {reportEditor.report.sections
            .slice(0, sectionNumber)
            .map((section) => (
              <Box key={section.uid}>
                <Box flex="horizontal" alignChildrenHorizontal="between">
                  <Text strong>{reportEditor.getSectionName(section)}</Text>
                  {reportEditor
                    .getSectionInspections(section.uid)
                    .filter((e) => e.jobId === activeBooking?.uid).length <
                    1 && (
                    <InspectionCreateButton
                      shrink
                      sectionUid={section?.uid}
                      variant="tertiary"
                      color="accent"
                    />
                  )}
                </Box>
                <Appear animate="FadeSlideDown" flex="vertical" gap="x3">
                  <InspectionList
                    onItemClick={(e) =>
                      navigate(
                        `${section.uid}/inspections/${e}?activeBookingId=${activeBooking?.uid}`
                      )
                    }
                    sectionUid={section?.uid}
                  />
                </Appear>
                {reportEditor
                  .getSectionInspections(section.uid)
                  .filter((e) => e.jobId === activeBooking?.uid).length > 0 && (
                  <InspectionAdvancedActions sectionUid={section.uid} />
                )}
              </Box>
            ))}
          {sectionNumber < reportEditor.report.sections.length && (
            <InfiniteScroll
              onMore={() => setSectionNumber(sectionNumber + 5)}
            />
          )}
        </Box>
      </PageBody>
      <Buttons padding="x6">
        <SectionCreateButton
          onCreate={(e, inspectionId) => {
            navigate(
              e +
                `/inspections/${inspectionId}?activeBookingId=${activeBooking?.uid}`
            );
          }}
          color="accent"
          variant="primary"
          grow
        />
      </Buttons>
    </Page>
  );
};

export default SectionsSnapshotMobilePage;
