import { useRef, PointerEvent as ReactPointerEvent } from 'react';

export type Position = [number, number];

export type DragEvent = {
  boundingBox: DOMRect;
  start: Position;
  startAbs: Position;
  current: Position;
  currentAbs: Position;
  getRelativePosition: (position: Position) => Position;
};

export type Opts = {
  onDrag: (
    event: DragEvent,
    originalEvent: PointerEvent | ReactPointerEvent
  ) => void;
  onDragCancel?: () => void;
  onDragEnd?: (
    event: DragEvent,
    originalEvent: PointerEvent | ReactPointerEvent
  ) => void;
  onDragStart: (
    event: DragEvent,
    originalEvent: PointerEvent | ReactPointerEvent
  ) => void;
  target?: HTMLElement | null;
};

export const NO_DROP_PROP_NAME = 'data-no-drop';
export const NO_DROP_PROPS = { [NO_DROP_PROP_NAME]: true };

const useDrag = (
  container: Element | null | undefined,
  { onDrag, onDragCancel, onDragEnd, onDragStart, target }: Opts
): {
  onPointerDown: (event: ReactPointerEvent) => void;
} => {
  const refBoundingBox = useRef<DOMRect>();
  const refCurrentRelPosition = useRef<Position>();
  const refCurrentAbsPosition = useRef<Position>();
  const refDownRelPosition = useRef<Position>();
  const refDownAbsPosition = useRef<Position>();
  const refUpRelPosition = useRef<Position>();
  const refUpAbsPosition = useRef<Position>();

  const getRelativePosition = ([x, y]: Position): Position => [
    Math.min(
      Math.max(0, x - (refBoundingBox.current?.left || 0)),
      refBoundingBox.current?.width || 0
    ),
    Math.min(
      Math.max(0, y - (refBoundingBox.current?.top || 0)),
      refBoundingBox.current?.height || 0
    ),
  ];

  const handlePointerDown = (event: ReactPointerEvent) => {
    if (container) {
      refBoundingBox.current = container?.getBoundingClientRect();

      refUpRelPosition.current = undefined;

      refDownAbsPosition.current = [event.clientX, event.clientY];
      refDownRelPosition.current = getRelativePosition(
        refDownAbsPosition.current
      );

      refCurrentAbsPosition.current = refDownAbsPosition.current;
      refCurrentRelPosition.current = refDownRelPosition.current;

      document.body.style.userSelect = 'none';

      onDragStart(
        {
          boundingBox: refBoundingBox.current,
          current: refCurrentRelPosition.current,
          currentAbs: refCurrentAbsPosition.current,
          start: refDownRelPosition.current,
          startAbs: refDownAbsPosition.current,
          getRelativePosition,
        },
        event
      );

      document.addEventListener('pointerup', handlePointerUp);
      document.addEventListener('pointermove', handlePointerMove);
    }
  };

  const handlePointerMove = (event: PointerEvent) => {
    if (refDownRelPosition.current && refDownAbsPosition.current) {
      refCurrentAbsPosition.current = [event.clientX, event.clientY];
      refCurrentRelPosition.current = getRelativePosition(
        refCurrentAbsPosition.current
      );

      if (refBoundingBox.current) {
        onDrag(
          {
            boundingBox: refBoundingBox.current,
            current: refCurrentRelPosition.current,
            currentAbs: refCurrentAbsPosition.current,
            start: refDownRelPosition.current,
            startAbs: refDownAbsPosition.current,
            getRelativePosition,
          },
          event
        );
      }
    }
  };

  const handlePointerUp = (event: PointerEvent) => {
    refUpAbsPosition.current = [event.clientX, event.clientY];
    refUpRelPosition.current = getRelativePosition(refUpAbsPosition.current);

    if (
      onDragCancel &&
      target &&
      event.target &&
      !target.contains(event.target as Node)
    ) {
      const elements = document.elementsFromPoint(event.clientX, event.clientY);

      for (const element of elements) {
        if (element.getAttribute(NO_DROP_PROP_NAME)) {
          onDragCancel();
          document.removeEventListener('pointerup', handlePointerUp);
          document.removeEventListener('pointermove', handlePointerMove);
          return;
        }
      }
    }

    if (
      refBoundingBox.current &&
      refCurrentRelPosition.current &&
      refCurrentAbsPosition.current &&
      refDownRelPosition.current &&
      refDownAbsPosition.current
    ) {
      onDragEnd?.(
        {
          boundingBox: refBoundingBox.current,
          current: refCurrentRelPosition.current,
          currentAbs: refCurrentAbsPosition.current,
          start: refDownRelPosition.current,
          startAbs: refDownAbsPosition.current,
          getRelativePosition,
        },
        event
      );
    }

    refDownRelPosition.current = undefined;
    refCurrentRelPosition.current = undefined;

    document.body.style.userSelect = 'unset';

    document.removeEventListener('pointerup', handlePointerUp);
    document.removeEventListener('pointermove', handlePointerMove);
  };

  return {
    onPointerDown: handlePointerDown,
  };
};

export default useDrag;
