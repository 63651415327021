import { User } from '@drainify/types';
import React from 'react';
import DataTable from '../DataTable/DataTable';
import DataTableGroup from '../DataTable/DataTableGroup';
import DataTableItem from '../DataTable/DataTableItem';
import DataTableMessage from '../DataTable/DataTableMessage';
import UserView from './UserView';

type Props = {
  noUsersMessage?: string;
  onSelect?: (user: User) => void;
  users?: (User | undefined)[];
};

const UserList = ({
  noUsersMessage = 'There are no users to show',
  onSelect,
  users = Array.from({ length: 5 }),
}: Props) => {
  if (!users.length) {
    return (
      <DataTable>
        <DataTableGroup>
          <DataTableItem>
            <DataTableMessage>{noUsersMessage}</DataTableMessage>
          </DataTableItem>
        </DataTableGroup>
      </DataTable>
    );
  }

  return (
    <DataTable>
      <DataTableGroup>
        {users.map((user, index) => (
          <DataTableItem
            key={user?.uid || index}
            onClick={onSelect && user && (() => onSelect(user))}
          >
            <UserView user={user} />
          </DataTableItem>
        ))}
      </DataTableGroup>
    </DataTable>
  );
};

export default UserList;
