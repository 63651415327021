import { Org, ResponseError } from '@drainify/types';
import { useQuery } from 'react-query';
import useFetch from '../components/Api/useFetch';

const useMeOrg = () => {
  const fetch = useFetch();

  const query = useQuery<Org, ResponseError>(['/me/org', fetch], () =>
    fetch('/me/org', { method: 'GET' })
  );

  return { query };
};

export default useMeOrg;
