import { User } from '@drainify/types';
import { ButtonProps, Icons, Text } from 'preshape';
import React, { PropsWithChildren, useState } from 'react';
import useProjectBookings from '../../hooks/useProjectBookings';
import AsyncButton from '../AsyncButton/AsyncButton';
import { useHasPermission } from '../Permissions/Permissions';
import { useProjectContext } from '../Project/ProjectProvider';
import BookingModal from './BookingModal';

type Props = ButtonProps & {
  parentJobId: string;
  assignedTo?: User[];
  defaultJobId?: string;
};

const BookingSubjobCreateButton = ({
  parentJobId,
  assignedTo,
  defaultJobId,
  ...rest
}: PropsWithChildren<Props>) => {
  const [visible, setVisible] = useState(false);
  const { project } = useProjectContext();
  const { create } = useProjectBookings(project?.uid);
  const hasCreateBookingPermission = useHasPermission('bookings:create');

  if (!hasCreateBookingPermission) {
    return null;
  }

  return (
    <>
      <AsyncButton
        variant="primary"
        {...rest}
        color="accent"
        isError={create.isError}
        isLoading={create.isLoading}
        isSuccess={create.isSuccess}
        onClick={() => setVisible(true)}
        type="button"
        gap="x1"
        flex="horizontal"
      >
        <Icons.Plus size="1rem" />
        <Text>Subjob</Text>
      </AsyncButton>

      <BookingModal
        parentJobId={parentJobId}
        assignedTo={assignedTo}
        onClose={() => setVisible(false)}
        onDone={create}
        visible={visible}
        defaultJobId={defaultJobId}
      />
    </>
  );
};

export default BookingSubjobCreateButton;
