import { NodeCode } from '@drainify/types';
import { AppearProps, Attributes } from 'preshape';
import React from 'react';
import ReportMapNodeIcon from '../ReportMapNodeIcon';
import ReportMapNodeBN from './BN';
import ReportMapNodeBR from './BR';
import ReportMapNodeCP from './CP';
import ReportMapNodeCWG from './CWG';
import ReportMapNodeFWG from './FWG';
import ReportMapNodeGY from './GY';
import ReportMapNodeIC from './IC';
import ReportMapNodeLH from './LH';
import ReportMapNodeUNP from './UNP';

export type ReportMapNodeRendererProps = Attributes<
  HTMLElement,
  AppearProps & {
    code: NodeCode;
    locked?: boolean;
    size?: string;
  }
>;

export type ReportMapNodeRendererType = (
  props: ReportMapNodeRendererProps
) => JSX.Element | null;

const reportMapNodeMap: Partial<Record<NodeCode, ReportMapNodeRendererType>> = {
  [NodeCode.IC]: ReportMapNodeIC,
  [NodeCode.CWG]: ReportMapNodeCWG,
  [NodeCode.BN]: ReportMapNodeBN,
  [NodeCode.BR]: ReportMapNodeBR,
  [NodeCode.CP]: ReportMapNodeCP,
  [NodeCode.FWG]: ReportMapNodeFWG,
  [NodeCode.GY]: ReportMapNodeGY,
  [NodeCode.LH]: ReportMapNodeLH,
  [NodeCode.UNP]: ReportMapNodeUNP,
};

const ReportMapNodeRenderer: ReportMapNodeRendererType = (props) => {
  const Renderer = reportMapNodeMap[props.code];

  if (Renderer) {
    return <Renderer {...props} />;
  }

  const { locked, size } = props;

  return <ReportMapNodeIcon locked={locked} size={size} />;
};

export default ReportMapNodeRenderer;
