import { Buttons } from 'preshape';
import React from 'react';
import InviteCreateButton from '../../../components/Invite/InviteCreateButton';
import MemberDataTable from '../../../components/Member/MemberDataTable';
import PageContext from '../../../components/Page/PageContext';
import { useHasPermission } from '../../../components/Permissions/Permissions';
import Page from '../Layout/Page';
import PageBody from '../Layout/PageBody';
import PageHeader from '../Layout/PageHeader';

const OrgMembersPage = () => {
  const hasCreateInvitePermission = useHasPermission('invites:create');
  return (
    <PageContext backToLink="/settings/user" backToText="Back">
      <Page>
        <PageHeader title="Members" />
        <PageBody>
          <MemberDataTable />
        </PageBody>

        <Buttons padding="x6">
          {hasCreateInvitePermission ? (
            <InviteCreateButton variant="primary" grow/>
          ) : undefined}
        </Buttons>
      </Page>
    </PageContext>
  );
};

export default OrgMembersPage;
