import {
  JobDocument,
  ResponseError,
  ResponseMultipleEntities,
} from '@drainify/types';
import { getFullFilePath, getFullVideoFilePath } from '@drainify/utils';
import { Box, Button, Icons, Link, Text } from 'preshape';
import React from 'react';
import { useQuery } from 'react-query';
import useReportAccessToken from '../../hooks/useReportAccessToken';
import { useFetchReport } from '../Api/useFetch';
import { useReportEditorContext } from '../Report/ReportEditorProvider';

const DocumentListCustomer = () => {
  const { token } = useReportAccessToken();
  const fetch = useFetchReport(token);
  const a = useQuery<ResponseMultipleEntities<JobDocument>, ResponseError>(
    [],
    () => fetch('/bookings/documents/customer', { method: 'GET' })
  );
  const { reportEditor } = useReportEditorContext();
  const videos = reportEditor.report.inspections
    .map((e) => ({ sectionId: e.sectionUid, video: e.video }))
    .filter((e) => !!e.video);

  if (a.data?.data.length === 0 && videos.length === 0) {
    return <Text>No documents attached</Text>;
  }

  return (
    <Box>
      {a.data?.data.map((document) => (
        <Box
          flex="horizontal"
          alignChildrenHorizontal="between"
          key={document.uid}
        >
          <Link
            href={getFullFilePath(document.path)}
            download={document.filename}
            target="_blank"
            rel="noreferrer"
            grow
            basis="0"
          >
            <Button
              variant="tertiary"
              color="accent"
              flex="horizontal"
              gap="x2"
              width="100%"
              alignChildrenHorizontal="start"
            >
              <Icons.Download />
              <Text size="x1" strong>
                {document.filename.substr(0, 35) +
                  (document.filename.length > 35 ? '...' : '')}
              </Text>
            </Button>
          </Link>
        </Box>
      ))}
      {videos.map(({ video, sectionId }) => (
        <Box
          flex="horizontal"
          alignChildrenHorizontal="between"
          key={video?.jobId}
        >
          <Link
            href={getFullVideoFilePath(video?.videoUrl)}
            download={`${reportEditor.getSectionName(
              reportEditor.getSectionByUid(sectionId)
            )}.mp4`}
            target="_blank"
            rel="noreferrer"
            grow
            basis="0"
          >
            <Button
              variant="tertiary"
              color="accent"
              flex="horizontal"
              gap="x2"
              width="100%"
              alignChildrenHorizontal="start"
            >
              <Icons.Download />
              <Text size="x1">
                {`${reportEditor.getSectionName(
                  reportEditor.getSectionByUid(sectionId)
                )}.mp4`}
              </Text>
            </Button>
          </Link>
        </Box>
      ))}
    </Box>
  );
};

export default DocumentListCustomer;
