import { MapLayerLine, MapLayerReturn, MapLayerUpdateFn } from '../useMap';

const getPathFromLineString = (lineString: GeoJSON.LineString) => {
  return lineString.coordinates.map(
    (point) => new google.maps.LatLng(point[1], point[0])
  );
};

const getGeometryLine = (
  map: google.maps.Map,
  layer: MapLayerLine
): MapLayerReturn<MapLayerLine> => {
  const line = new google.maps.Polyline({
    map,
    path: layer.geometry ? getPathFromLineString(layer.geometry) : [],
    ...layer.style,
  });

  let prevLayer = layer;

  const set = (layer: MapLayerLine) => {
    line.setOptions({
      path: layer.geometry ? getPathFromLineString(layer.geometry) : [],
      ...layer.style,
    });

    prevLayer = layer;
  };

  const update: MapLayerUpdateFn<MapLayerLine> = (value) => {
    if (typeof value === 'function') {
      set(value(prevLayer));
    } else if (value) {
      set({
        ...prevLayer,
        ...value,
      });
    }
  };

  const remove = () => {
    line.setMap(null);
  };

  return { update, remove };
};

export default getGeometryLine;
