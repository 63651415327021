import { Booking, ResponseError } from '@drainify/types';
import { useQuery, useQueryClient } from 'react-query';
import useFetch, { hasAllParams } from '../components/Api/useFetch';

type Opts = {
  noQuery?: boolean;
};

const usePDF = (jobId?: string, projectId?: string, { noQuery }: Opts = {}) => {
  const queryClient = useQueryClient();
  const fetch = useFetch();
  const key = [`/pdf/${jobId}/${jobId}`, fetch];
  const params = { projectId: projectId as string, jobId: jobId as string };

  const query = useQuery<Booking, ResponseError, Booking>(
    key,
    () =>
      fetch('/pdfgenerate/:projectId/:jobId', {
        method: 'PUT',
        params,
      }),
    {
      enabled: hasAllParams(params) && !noQuery,
      onSuccess: (data) => {
        queryClient.setQueryData([`/bookings/${jobId}`], data);
        queryClient.refetchQueries([`/bookings/${jobId}`]);
      },
    }
  );

  return { query };
};

export default usePDF;
