import { Booking } from '@drainify/types';
import {
  getBookingState,
  getBookingStateColor,
  getBookingStateText,
} from '@drainify/utils';
import { Box, BoxProps, Label, LabelProps, Text } from 'preshape';
import React, { PropsWithChildren } from 'react';

type Props = LabelProps & {
  booking: Booking;
  large?: boolean;
};

const BookingStatus = ({
  booking,
  large = false,
  ...rest
}: PropsWithChildren<BoxProps & Props>) => {
  const state = getBookingState(booking);
  const color = getBookingStateColor(state);
  const text = getBookingStateText(state);

  return (
    <Label
      {...rest}
      alignChildrenVertical="middle"
      flex="horizontal"
      gap="x1"
      size={large ? 'x4' : 'x1'}
      style={{ backgroundColor: `rgba(${color}, 0.2)` }}
      textColor="text-shade-1"
    >
        <Box
          borderRadius="full"
          height={8}
          style={{ backgroundColor: `rgb(${color})` }}
          width={8}
        />
      <Text strong style={{ color: `rgb(${color})` }} uppercase>
        {text}
      </Text>
    </Label>
  );
};

export default BookingStatus;
