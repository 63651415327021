import { Project, ProjectPostBody } from '@drainify/types';
import { getProjectLocation } from '@drainify/utils';
import React from 'react';
import CustomerView from '../Customer/CustomerView';
import Address from '../Location/Address';
import { useHasPermission } from '../Permissions/Permissions';
import SummaryCard from '../SummaryCard/SummaryCard';
import SummaryCardSection from '../SummaryCard/SummaryCardSection';
import { useWizardContext } from '../Wizard/Wizard';
import { useProjectContext } from './ProjectProvider';

type Props = {
  project?: Project | ProjectPostBody;
};

const ProjectSummary = ({ project }: Props) => {
  const { setActiveStepId } = useWizardContext();
  const { edit } = useProjectContext();
  const hasUpdateProjectPermission = useHasPermission('projects:update');
  const { activeBooking } = useProjectContext();

  const editStep = (stepId: string) => {
    if (!hasUpdateProjectPermission) {
      return undefined;
    }
    if (!!activeBooking?.completedAt) {
      return undefined;
    }

    return () => {
      setActiveStepId(stepId);
      edit(stepId);
    };
  };

  return (
    <SummaryCard title="Project">
      <SummaryCardSection
        onEdit={editStep('customer')}
        title="Customer"
        value={project?.customer}
      >
        <CustomerView customer={project?.customer} />
      </SummaryCardSection>

      <SummaryCardSection
        onEdit={editStep('location')}
        title="Site location"
        value={getProjectLocation(project)}
      >
        <Address location={getProjectLocation(project)} />
      </SummaryCardSection>
    </SummaryCard>
  );
};

export default ProjectSummary;
