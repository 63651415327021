import { Text, Button } from 'preshape';
import React from 'react';
import usePDF from '../../hooks/usePDF';
import { useProjectContext } from '../Project/ProjectProvider';
import Spinner from '../Spinner/Spinner';

const JobPDFGeneratingButton = () => {
  const { activeBooking, project } = useProjectContext();
  usePDF(activeBooking?.uid, project?.uid); // todo
  return (
    <Button
      flex="horizontal"
      gap="x1"
      alignChildrenVertical="middle"
      alignChildrenHorizontal="middle"
    >
      <Spinner size="1rem" />
      <Text size="x1">Generating</Text>
    </Button>
  );
};

export default JobPDFGeneratingButton;
