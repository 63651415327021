import { Box, CheckBox, InputLabelProps } from 'preshape';
import React, { ForwardRefRenderFunction } from 'react';

type Props = InputLabelProps & {
  name?: string;
  onChange: (value: boolean) => void;
  value?: boolean;
};
const ContinuousDefectInput: ForwardRefRenderFunction<HTMLLabelElement, Props> =
  ({ value, onChange }) => {
    return (
      <Box>
        <CheckBox checked={value} onChange={() => onChange(!value)} borderSize="x1">
          This is the start point of a continuous defect
        </CheckBox>
      </Box>
    );
  };

export default ContinuousDefectInput;
