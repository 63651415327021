import { BookingStateCode } from '@drainify/utils';
import {
  Box,
  Button,
  Icons,
  Placement,
  PlacementArrow,
  PlacementContent,
  PlacementManager,
  PlacementReference,
} from 'preshape';
import { PlacementManagerContext } from 'preshape/lib/cjs/Placement/PlacementManager';
import React from 'react';
import { useJobSearchContext } from '../../pages/desktop/Pages/AdminPage';
import JobAdminSearchOptionsJobStateLabel from './JobAdminSearchOptionsJobStateLabel';

const searchableJobBookingStates = [
  undefined, // Pseudo element for 'all'
  BookingStateCode.COMPLETED,
  BookingStateCode.PENDING,
  BookingStateCode.SUBMITTED,
  BookingStateCode.UNASSIGNED,
  BookingStateCode.REPORT_OVERDUE,
  BookingStateCode.IN_PROGRESS,
];

const JobAdminSearchOptionJobState = () => {
  const { state } = useJobSearchContext();

  return (
    <PlacementManager trigger="click">
      <PlacementReference>
        {(props) => (
          <Box flex="vertical" alignChildrenHorizontal="middle">
            <Button
              {...props}
              variant="tertiary"
              flex="horizontal"
              gap="x1"
              borderSize="x1"
              active={state !== undefined}
            >
              <Icons.Activity size="1rem" />
              <JobAdminSearchOptionsJobStateLabel bookingState={state} />
            </Button>
          </Box>
        )}
      </PlacementReference>

      <Placement placement="bottom" zIndex={1}>
        <PlacementArrow backgroundColor="background-shade-3" />
        <PlacementContent
          backgroundColor="background-shade-1"
          borderColor="background-shade-3"
          borderRadius="x3"
          borderSize="x2"
          textColor="text-shade-1"
        >
          <Box flex="vertical" gap="x4" padding="x3">
            {searchableJobBookingStates
              .filter((e) => e !== state)
              .map((bookingStatus) => (
                <WrapWithClosure
                  key={bookingStatus}
                  bookingStatus={bookingStatus}
                />
              ))}
          </Box>
        </PlacementContent>
      </Placement>
    </PlacementManager>
  );
};

const WrapWithClosure = ({
  bookingStatus,
}: {
  bookingStatus: BookingStateCode | undefined;
}) => {
  const { setState } = useJobSearchContext();
  const { onClose } = React.useContext(PlacementManagerContext);

  return (
    <JobAdminSearchOptionsJobStateLabel
      key={`${bookingStatus}-status`}
      bookingState={bookingStatus}
      onClick={() => {
        setState(bookingStatus);
        onClose?.();
      }}
    />
  );
};
export default JobAdminSearchOptionJobState;
