import React from 'react';
import InspectionConditionBadges from '../../Inspection/InspectionConditionBadges';
import { useReportEditorContext } from '../../Report/ReportEditorProvider';

type Props = {
  sectionUid?: string;
};

const SectionConditionBadges = ({ sectionUid }: Props) => {
  const { reportEditor } = useReportEditorContext();
  const inspection = reportEditor.getSectionInspectionLatest(sectionUid);

  return <InspectionConditionBadges inspectionUid={inspection?.uid} />;
};

export default SectionConditionBadges;
