import { Org } from './Org';

export type PaymentIntentState = 'requires_action' | 'suceeded';

export enum PurchaseType {
  SIGNUP = 'SIGNUP',
  CREDIT = 'CREDIT',
  BUDDY_TOKEN = 'BUDDY_TOKEN',
  UPGRADE_SUBSCRIPTION = 'UPGRADE_SUBSCRIPTION',
  STORAGE = 'STORAGE',
}

export type PaymentIntent = {
  purchaseType: PurchaseType;
  paymentIntentUid: string;
  state: PaymentIntentState;
  subscriptionId?: string;
  resetDate?: number;
  customerId?: string;
  quantity?: number;
  org?: Org;
  initialTier?: string;
  upgradeTier?: string;
};
