import { InputLabelProps, InputLabel, TextArea } from 'preshape';
import React, {
  ChangeEvent,
  forwardRef,
  ForwardRefRenderFunction,
} from 'react';

type Props = InputLabelProps & {
  name?: string;
  onChange: (value?: string) => void;
  value?: string;
};

const RemarksInput: ForwardRefRenderFunction<HTMLLabelElement, Props> = (
  { name, onChange, value, ...rest },
  ref
) => {
  return (
    <InputLabel {...rest} ref={ref}>
      <TextArea
        placeholder="N/A"
        name={name}
        autoFocus
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
          onChange(e.target.value)
        }
        value={value === undefined ? '' : value}
        rows={6}
      />
    </InputLabel>
  );
};

export default forwardRef(RemarksInput);
