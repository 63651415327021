import { OCRResult, OCRResultStatus, ResponseError } from '@drainify/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useFetch, { hasAllParams } from '../components/Api/useFetch';
import { useOrgContext } from '../components/Org/OrgProvider';

type Opts = {
  noQuery?: boolean;
};

const useAIOCRResult = (projectId?: string, inspectionId?: string, { noQuery }: Opts = {}) => {
  const queryClient = useQueryClient();
  const { org } = useOrgContext()
  const fetch = useFetch();
  const key = ['poi', `/ai-poi/${projectId}/${inspectionId}`, fetch];
  const params = { projectId: projectId as string, inspectionId: inspectionId as string };

  const query = useQuery<OCRResult, ResponseError>(
    key,
    () => fetch('/ai-poi/:projectId/:inspectionId', { method: 'GET', params }),
    { 
      enabled: hasAllParams(params) && !noQuery, 
      refetchInterval: (data) => {
        return data?.status === OCRResultStatus.PENDING ? 5000 : false
      },
      onSuccess: (data) => {
        queryClient.refetchQueries([`pois`]);
        if(data && data.status === OCRResultStatus.PENDING) {

        } else {
          queryClient.refetchQueries([`/orgs/${org?.uid}/usage`]);
        }
      
    }, },
  );

  const create = useMutation(
    () =>
      fetch('/ai-poi/:projectId/:inspectionId', { method: 'POST', params }),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(key, data);
        queryClient.refetchQueries([`pois`]);
      }
    }
  );

  const update = useMutation(
    (body: OCRResult) =>
      fetch('/ai-poi/:projectId/:inspectionId', { method: 'PUT', params, body }),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(key, data);
        queryClient.refetchQueries([`pois`]);
        queryClient.refetchQueries([`ocr-results`]);
      }
    }
  );

  const remove = useMutation(
    () =>
      fetch('/ai-poi/:projectId/:inspectionId', { method: 'DELETE', params }),
    {
      onSuccess: () => {
        queryClient.setQueryData(key, null);
        queryClient.refetchQueries([`pois`]);
      }
    }
  );

  return { query, create, update, remove };
};

export default useAIOCRResult;
