import { TextProps } from 'preshape';
import React from 'react';
import PlaceholderBlock from './PlaceholderBlock';

type Props = TextProps;

const sizeMap = {
  x8: 52,
  x7: 42,
  x6: 32,
  x5: 22,
  x4: 22,
  x3: 20,
  x2: 16,
  x1: 13,
};

const PlaceholderText = ({ size = 'x4', ...rest }: Props) => {
  return <PlaceholderBlock {...rest} height={sizeMap[size]} />;
};

export default PlaceholderText;
