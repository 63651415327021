import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { Box, Button, Form, Icons, Input, InputLabel, Text } from 'preshape';
import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    try {
      await sendPasswordResetEmail(getAuth(), email);
      setSubmitted(true);
    } catch (error) {
      console.error(error);
    }
  };

  if (submitted) {
    return (
      <Box flex="vertical" grow>
        <Box
          alignChildren="middle"
          backgroundColor="background-shade-3"
          flex="vertical"
          grow
          padding="x12"
        >
          <Box maxWidth="400px">
            <Text align="middle" margin="x8" size="x6" strong>
              Forgotten password link sent to {email}! Please check email.
            </Text>
            <Button
              color="accent"
              size="x3"
              variant="primary"
              width="100%"
              onClick={() => navigate('/')}
            >
              Return to homepage
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box flex="vertical" grow>
      <Box
        alignChildren="middle"
        backgroundColor="background-shade-3"
        flex="vertical"
        grow
        padding="x12"
      >
        <Box maxWidth="400px">
          <Text align="middle" margin="x8" size="x6" strong>
            Forgot password
          </Text>
          <Form margin="x6" onSubmit={handleSubmit}>
            <InputLabel label="Email" margin="x4">
              <Input
                addonEnd={<Icons.Mail size="24px" />}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setEmail(e.target.value)
                }
                margin="x2"
                placeholder="Email Address"
                value={email}
              />
            </InputLabel>
          </Form>
          <Button
            color="accent"
            size="x3"
            variant="primary"
            width="100%"
            onClick={handleSubmit}
          >
            Reset Password
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ForgotPasswordPage;
