"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.papcObservations = void 0;
const types_1 = require("@drainify/types");
exports.papcObservations = [
    {
        code: types_1.ObservationCode.B_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Broken',
            [types_1.LanguageCodes.ES]: 'Rotura',
            [types_1.LanguageCodes.FR]: 'Bris',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    structure: 5,
                };
            }
            return {
                structure: 4,
            };
        },
        description: 'Pieces of pipe have visibly moved',
        options: [
            {
                name: 'Broken Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.BJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Broken Joint',
                            [types_1.LanguageCodes.ES]: 'Rotura Junta',
                            [types_1.LanguageCodes.FR]: 'Bris Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Breakage on joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.BSV_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Broken Soil Visible',
            [types_1.LanguageCodes.ES]: 'Rotura Suelo Visible',
            [types_1.LanguageCodes.FR]: 'Bris – Sol visible derrière le défaut',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({
            structure: 5,
        }),
        description: 'Pieces of pipe have visibly moved',
        options: [
            {
                name: 'Broken soil Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.BSVJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Broken Soil Visible Joint',
                            [types_1.LanguageCodes.ES]: 'Rotura Suelo Visible Junta',
                            [types_1.LanguageCodes.FR]: 'Bris – Sol visible derrière le défaut joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Breakage on joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Value 2',
                attribute: 'value2',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.BVV_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Broken Void Visible',
            [types_1.LanguageCodes.ES]: 'Rotura Vacío Visible',
            [types_1.LanguageCodes.FR]: 'Bris – Vide visible derrière le défaut',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({
            structure: 5,
        }),
        description: 'Pieces of pipe have visibly moved',
        options: [
            {
                name: 'Broken void Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.BVVJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Broken Void Visible Joint',
                            [types_1.LanguageCodes.ES]: 'Rotura Vacío Visible Junta',
                            [types_1.LanguageCodes.FR]: 'Bris – Vide visible derrière le défaut joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Breakage on joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Value 2',
                attribute: 'value2',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.CC_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Crack Circumferential',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({
            structure: 1,
        }),
        description: 'Crack Circumferential',
        options: [
            {
                name: 'Crack Circumferential Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.CCJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Crack Circumferential Joint',
                            [types_1.LanguageCodes.ES]: 'Grieta Circular Junta',
                            [types_1.LanguageCodes.FR]: 'Fissure – Circulaire joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Crack Circumferential Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.CL_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Crack Longitudinal',
            [types_1.LanguageCodes.ES]: 'Grieta Longitudinal',
            [types_1.LanguageCodes.FR]: 'Fissure – Longitudinale',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attr) => {
            if ((0, types_1.isPacpLeveeOrDam)(attr.attributes.use)) {
                return { structure: 3 };
            }
            else {
                return { structure: 2 };
            }
        },
        description: 'Crack Longitudinal',
        options: [
            {
                name: 'Crack Longitudinal Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.CLJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Crack Longitudinal Joint',
                            [types_1.LanguageCodes.ES]: 'Grieta Longitudinal Junta',
                            [types_1.LanguageCodes.FR]: 'Fissure – Longitudinale Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Crack Longitudinal Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Fracture longitudinal at',
                attribute: 'angleAt',
                description: 'The clock reference the fracture is at',
                type: 'angle',
            },
            {
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Value 2',
                attribute: 'value2',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.CM_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Crack Multiple',
            [types_1.LanguageCodes.ES]: 'Grieta Múltiple',
            [types_1.LanguageCodes.FR]: 'Fissures – Multiples',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({
            structure: 3,
        }),
        description: 'Crack Multiple',
        options: [
            {
                name: 'Crack Multiple Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.CMJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Crack Multiple Joint',
                            [types_1.LanguageCodes.ES]: 'Grieta Múltiple Junta',
                            [types_1.LanguageCodes.FR]: 'Fissures – Multiples Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Crack Multiple Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.CS_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Crack Spiral',
            [types_1.LanguageCodes.ES]: 'Grieta Espiral',
            [types_1.LanguageCodes.FR]: 'Fissure – En spirale',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attr) => {
            if ((0, types_1.isPacpLeveeOrDam)(attr.attributes.use)) {
                return { structure: 3 };
            }
            else {
                return { structure: 2 };
            }
        },
        description: 'Crack Spiral',
        options: [
            {
                name: 'Crack Spiral Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.CSJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Crack Spiral Joint',
                            [types_1.LanguageCodes.ES]: 'Grieta Espiral Junta',
                            [types_1.LanguageCodes.FR]: 'Fissure – En spirale joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Crack Spiral Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.CH2_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Crack Longitudinal Hinge, 2',
            [types_1.LanguageCodes.ES]: 'Grieta Bisagra, 2',
            [types_1.LanguageCodes.FR]: 'Fissures – En croix, 2',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attr) => {
            if ((0, types_1.isPacpLeveeOrDam)(attr.attributes.use)) {
                return { structure: 5 };
            }
            else {
                return { structure: 2 };
            }
        },
        description: 'Crack Longitudinal Hinge, 2',
        options: [
            {
                name: 'Crack Longitudinal Hinge, 2 Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.CH2J_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Crack Longitudinal Hinge, 2 Joint',
                            [types_1.LanguageCodes.ES]: 'Grieta Bisagra, 2 joint',
                            [types_1.LanguageCodes.FR]: 'Fissures – En croix, 2 junta',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Crack Longitudinal Hinge, 2 Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Hinge 1 - Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                name: 'Hinge 1 - Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Hinge 1 - Value 2',
                attribute: 'value2',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Hinge 2 - Start/end clock reference ',
                attribute: 'angle2',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                name: 'Hinge 2 - Value 1',
                attribute: 'value1-2',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Hinge 2 - Value 2',
                attribute: 'value2-2',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.CH3_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Crack Longitudinal Hinge, 3',
            [types_1.LanguageCodes.ES]: 'Grieta Bisagra, 3',
            [types_1.LanguageCodes.FR]: 'Fissures – En croix, 3',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attr) => {
            if ((0, types_1.isPacpLeveeOrDam)(attr.attributes.use)) {
                return { structure: 5 };
            }
            else {
                return { structure: 3 };
            }
        },
        description: 'Crack Longitudinal Hinge, 3',
        options: [
            {
                name: 'Crack Longitudinal Hinge, 3 Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.CH3J_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Crack Longitudinal Hinge, 3 Joint',
                            [types_1.LanguageCodes.ES]: 'Grieta Bisagra, 3 joint',
                            [types_1.LanguageCodes.FR]: 'Fissures – En croix, 3 junta',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Crack Longitudinal Hinge, 3 Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Hinge 1 - Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                name: 'Hinge 1 - Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Hinge 1 - Value 2',
                attribute: 'value2',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Hinge 2 - Start/end clock reference ',
                attribute: 'angle2',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                name: 'Hinge 2 - Value 1',
                attribute: 'value1-2',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Hinge 2 - Value 2',
                attribute: 'value2-2',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Hinge 3 - Start/end clock reference ',
                attribute: 'angle3',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                name: 'Hinge 3 - Value 1',
                attribute: 'value1-3',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Hinge 3 - Value 2',
                attribute: 'value2-3',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.CH4_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Crack Longitudinal Hinge, 4',
            [types_1.LanguageCodes.ES]: 'Grieta Bisagra, 4',
            [types_1.LanguageCodes.FR]: 'Fissures – En croix, 4',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attr) => {
            if ((0, types_1.isPacpLeveeOrDam)(attr.attributes.use)) {
                return { structure: 5 };
            }
            else {
                return { structure: 4 };
            }
        },
        description: 'Crack Longitudinal Hinge, 4',
        options: [
            {
                name: 'Crack Longitudinal Hinge, 4 Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.CH4J_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Crack Longitudinal Hinge, 4 Joint',
                            [types_1.LanguageCodes.ES]: 'Grieta Bisagra, 4 joint',
                            [types_1.LanguageCodes.FR]: 'Fissures – En croix, 4 junta',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Crack Longitudinal Hinge, 4 Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Hinge 1 - Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                name: 'Hinge 1 - Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Hinge 1 - Value 2',
                attribute: 'value2',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Hinge 2 - Start/end clock reference ',
                attribute: 'angle2',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                name: 'Hinge 2 - Value 1',
                attribute: 'value1-2',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Hinge 2 - Value 2',
                attribute: 'value2-2',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Hinge 3 - Start/end clock reference ',
                attribute: 'angle3',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                name: 'Hinge 3 - Value 1',
                attribute: 'value1-3',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Hinge 3 - Value 2',
                attribute: 'value2-3',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Hinge 4 - Start/end clock reference ',
                attribute: 'angle4',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                name: 'Hinge 4 - Value 1',
                attribute: 'value1-4',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Hinge 4 - Value 2',
                attribute: 'value2-4',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.FC_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Fracture Circumferential',
            [types_1.LanguageCodes.ES]: 'Fractura Circular',
            [types_1.LanguageCodes.FR]: 'Fracture – Circulaire',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({
            structure: 2,
        }),
        description: 'Fracture Circumferential',
        options: [
            {
                name: 'Fracture Circumferential Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.FCJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Fracture Circumferential Joint',
                            [types_1.LanguageCodes.ES]: 'Fractura Circular Junta',
                            [types_1.LanguageCodes.FR]: 'Fracture – Circulaire Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Fracture Circumferential Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.FH2_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Fracture Longitudinal Hinge, 2',
            [types_1.LanguageCodes.ES]: 'Fractura Bisagra, 2',
            [types_1.LanguageCodes.FR]: 'Fractures – En croix, 2',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attr) => {
            if ((0, types_1.isPacpLeveeOrDam)(attr.attributes.use)) {
                return { structure: 5 };
            }
            else {
                return { structure: 3 };
            }
        },
        description: 'Fracture Longitudinal Hinge, 2',
        options: [
            {
                name: 'Fracture Longitudinal Hinge, 2 Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.FH2J_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Fracture Longitudinal Hinge, 2 Joint',
                            [types_1.LanguageCodes.ES]: 'Fractura Bisagra, 2 Junta',
                            [types_1.LanguageCodes.FR]: 'Fractures – En croix, 2 Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Fracture Longitudinal Hinge, 2 Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Hinge 1 - Start/end clock reference ',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                name: 'Hinge 1 - Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Hinge 1 - Value 2',
                attribute: 'value2',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Hinge 2 - Start/end clock reference ',
                attribute: 'angle2',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                name: 'Hinge 2 - Value 1',
                attribute: 'value1-2',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Hinge 2 - Value 2',
                attribute: 'value2-2',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.FH3_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Fracture Longitudinal Hinge, 3',
            [types_1.LanguageCodes.ES]: 'Fractura Bisagra, 3',
            [types_1.LanguageCodes.FR]: 'Fractures – En croix, 3',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attr) => {
            if ((0, types_1.isPacpLeveeOrDam)(attr.attributes.use)) {
                return { structure: 5 };
            }
            else {
                return { structure: 4 };
            }
        },
        description: 'Fracture Longitudinal Hinge, 3',
        options: [
            {
                name: 'Fracture Longitudinal Hinge, 3 Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.FH3J_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Fracture Longitudinal Hinge, 3 Joint',
                            [types_1.LanguageCodes.ES]: 'Fractura Bisagra, 3 Junta',
                            [types_1.LanguageCodes.FR]: 'Fractures – En croix, 3 Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Fracture Longitudinal Hinge, 3 Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Hinge 1 Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                name: 'Hinge 1 - Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Hinge 1 - Value 2',
                attribute: 'value2',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Hinge 2 - Start/end clock reference ',
                attribute: 'angle2',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                name: 'Hinge 2 - Value 1',
                attribute: 'value1-2',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Hinge 2 - Value 2',
                attribute: 'value2-2',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Hinge 3 - Start/end clock reference ',
                attribute: 'angle3',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                name: 'Hinge 3 - Value 1',
                attribute: 'value1-3',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Hinge 3 - Value 2',
                attribute: 'value2-3',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.FH4_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Fracture Longitudinal Hinge, 4',
            [types_1.LanguageCodes.ES]: 'Fractura Bisagra, 4',
            [types_1.LanguageCodes.FR]: 'Fractures – En croix, 4',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attr) => {
            if ((0, types_1.isPacpLeveeOrDam)(attr.attributes.use)) {
                return { structure: 5 };
            }
            else {
                return { structure: 4 };
            }
        },
        description: 'Fracture Longitudinal Hinge, 4',
        options: [
            {
                name: 'Fracture Longitudinal Hinge, 4 Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.FH4J_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Fracture Longitudinal Hinge, 4 Joint',
                            [types_1.LanguageCodes.ES]: 'Fractura Bisagra, 4 Junta',
                            [types_1.LanguageCodes.FR]: 'Fractures – En croix, 4 Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Fracture Longitudinal Hinge, 4 Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Hinge 1 - Start/end clock reference ',
                attribute: 'angle2',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                name: 'Hinge 1 - Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Hinge 1 - Value 2',
                attribute: 'value2',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Hinge 2 - Start/end clock reference ',
                attribute: 'angle2',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                name: 'Hinge 2 - Value 1',
                attribute: 'value1-2',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Hinge 2 - Value 2',
                attribute: 'value2-2',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Hinge 3 - Start/end clock reference ',
                attribute: 'angle3',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                name: 'Hinge 3 - Value 1',
                attribute: 'value1-3',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Hinge 3 - Value 2',
                attribute: 'value2-3',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Hinge 4 - Start/end clock reference ',
                attribute: 'angle4',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                name: 'Hinge 4 - Value 1',
                attribute: 'value1-4',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Hinge 4 - Value 2',
                attribute: 'value2-4',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.FL_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Fracture Longitudinal',
            [types_1.LanguageCodes.ES]: 'Fractura Longitudinal',
            [types_1.LanguageCodes.FR]: 'Fracture – Longitudinale',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    structure: 4,
                };
            }
            return {
                structure: 3,
            };
        },
        description: 'Fracture Longitudinal',
        options: [
            {
                name: 'Fracture Longitudinal',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.FLJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Fracture Longitudinal Joint',
                            [types_1.LanguageCodes.ES]: 'Fractura Longitudinal Junta',
                            [types_1.LanguageCodes.FR]: 'Fracture – Longitudinale Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Fracture Longitudinal Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Value 2',
                attribute: 'value2',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.FM_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Fracture Multiple',
            [types_1.LanguageCodes.ES]: 'Fractura Múltiple',
            [types_1.LanguageCodes.FR]: 'Fractures – Multiples',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ structure: 4 }),
        description: 'Fracture Multiple',
        options: [
            {
                name: 'Fracture Multiple',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.FMJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Fracture Multiple Joint',
                            [types_1.LanguageCodes.ES]: 'Fractura Múltiple Junta',
                            [types_1.LanguageCodes.FR]: 'Fractures – Multiples Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Fracture Multiple Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.FS_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Fracture Spiral',
            [types_1.LanguageCodes.ES]: 'Fractura Espiral',
            [types_1.LanguageCodes.FR]: 'Fracture – En spirale',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    structure: 4,
                };
            }
            return {
                structure: 3,
            };
        },
        description: 'Fracture Spiral',
        options: [
            {
                name: 'Fracture Spiral',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.FSJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Fracture Spiral Joint',
                            [types_1.LanguageCodes.ES]: 'Fractura Espiral Junta',
                            [types_1.LanguageCodes.FR]: 'Fracture – En spirale Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Fracture Spiral Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Value 2',
                attribute: 'value2',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.H_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Hole',
            [types_1.LanguageCodes.ES]: 'Agujero',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { angle = [0, 0] } }, { attributes: { use } }) => {
            if ((0, types_1.isPacpLeveeOrDam)(use)) {
                return {
                    structure: 5,
                };
            }
            else {
                const start = angle[0];
                const end = angle[1];
                const coverage = start <= end ? end - start + 1 : 12 - (start - end) + 1;
                if (coverage < 2) {
                    return {
                        structure: 4,
                    };
                }
                else {
                    return {
                        structure: 5,
                    };
                }
            }
        },
        description: 'Hole',
        options: [
            {
                name: 'Hole',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.HJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Hole on Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: '',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Hole on Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.HSV_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Hole Soil Visible',
            [types_1.LanguageCodes.ES]: 'Agujero Suelo Visible',
            [types_1.LanguageCodes.FR]: 'Trou – Sol visible derrière le défaut',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ structure: 5 }),
        description: 'Hole Soil Visible',
        options: [
            {
                name: 'Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.HSVJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Hole Soil Visible on Joint',
                            [types_1.LanguageCodes.ES]: 'Agujero Suelo Visible Junta',
                            [types_1.LanguageCodes.FR]: 'Trou – Sol visible derrière le défaut',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Hole Soil Visible on Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Value 2',
                attribute: 'value2',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.HVV_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Hole Void Visible',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ structure: 5 }),
        description: 'Hole Void Visible',
        options: [
            {
                name: 'Hole',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.HVVJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Hole Void Visible on Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: '',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Hole Void Visible on Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Value 2',
                attribute: 'value2',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.DAE_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Deposits Attached Encrustation',
            [types_1.LanguageCodes.ES]: 'Depósitos Adheridos Incrustación',
            [types_1.LanguageCodes.FR]: 'Dépôts attachés – Incrustation',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }) => {
            if (percentage <= 10)
                return { service: 2 };
            else if (percentage <= 20)
                return { service: 3 };
            else if (percentage <= 30)
                return { service: 4 };
            else if (percentage <= 10)
                return { service: 2 };
            else
                return { service: 5 };
        },
        description: 'Deposits Attached Encrustation',
        options: [
            {
                name: 'Deposits Attached Encrustation Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.DAEJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Deposits Attached Encrustation Joint',
                            [types_1.LanguageCodes.ES]: 'Depósitos Adheridos Incrustación Junta',
                            [types_1.LanguageCodes.FR]: 'Dépôts attachés – Incrustation Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Deposits Attached Encrustation Joint',
                    },
                ],
            },
            {
                required: true,
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.DAGS_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Deposits Attached Grease',
            [types_1.LanguageCodes.ES]: 'Depósitos Adheridos Grasa',
            [types_1.LanguageCodes.FR]: 'Dépôts attachés – Graisse',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }) => {
            if (percentage <= 10)
                return { service: 2 };
            else if (percentage <= 20)
                return { service: 3 };
            else if (percentage <= 30)
                return { service: 4 };
            else if (percentage <= 10)
                return { service: 2 };
            else
                return { service: 5 };
        },
        description: 'Deposits Attached Grease',
        options: [
            {
                name: 'Deposits Attached Grease Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.DAGSJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Deposits Attached Grease Joint',
                            [types_1.LanguageCodes.ES]: 'Depósitos Adheridos Grasa Junta',
                            [types_1.LanguageCodes.FR]: 'Dépôts attachés – Graisse Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Deposits Attached Grease Joint',
                    },
                ],
            },
            {
                required: true,
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.DAR_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Deposits Attached Ragging',
            [types_1.LanguageCodes.ES]: 'Depósitos Adheridos Desechos',
            [types_1.LanguageCodes.FR]: 'Dépôts attachés – Filasse',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }) => {
            if (percentage <= 10)
                return { service: 2 };
            else if (percentage <= 20)
                return { service: 3 };
            else if (percentage <= 30)
                return { service: 4 };
            else if (percentage <= 10)
                return { service: 2 };
            else
                return { service: 5 };
        },
        description: 'Deposits Attached Ragging',
        options: [
            {
                name: 'Deposits Attached Ragging Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.DARJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Deposits Attached Ragging Joint',
                            [types_1.LanguageCodes.ES]: 'Depósitos Adheridos Desechos Junta',
                            [types_1.LanguageCodes.FR]: 'Dépôts attachés – Filasse Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Deposits Attached Ragging Joint',
                    },
                ],
            },
            {
                required: true,
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.DAZ_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Deposits Attached Other',
            [types_1.LanguageCodes.ES]: 'Depósitos Adheridos Otros',
            [types_1.LanguageCodes.FR]: 'Dépôts attachés – Autre',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }) => {
            if (percentage <= 10)
                return { service: 2 };
            else if (percentage <= 20)
                return { service: 3 };
            else if (percentage <= 30)
                return { service: 4 };
            else if (percentage <= 10)
                return { service: 2 };
            else
                return { service: 5 };
        },
        description: 'Deposits Attached Other',
        options: [
            {
                name: 'Deposits Attached Other Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.DAZJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Deposits Attached Other Joint',
                            [types_1.LanguageCodes.ES]: 'Depósitos Adheridos Otros Junta',
                            [types_1.LanguageCodes.FR]: 'Dépôts attachés – Autre Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Deposits Attached Other Joint',
                    },
                ],
            },
            {
                required: true,
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.DB_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Displaced Brick',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.PACP],
        scoringFunction: () => ({
            structure: 3,
        }),
        description: 'Displaced Brick',
        options: [
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.DNF_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Deposits Ingress Fine',
            [types_1.LanguageCodes.ES]: 'Depósitos Filtrados Finos',
            [types_1.LanguageCodes.FR]: 'Dépôts introduits – Fins',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }) => {
            if (percentage <= 10)
                return { service: 2 };
            else if (percentage <= 20)
                return { service: 3 };
            else if (percentage <= 30)
                return { service: 4 };
            else if (percentage <= 10)
                return { service: 2 };
            else
                return { service: 5 };
        },
        description: 'Deposits Ingress Fine',
        options: [
            {
                name: 'Deposits Ingress Fine Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.DNFJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Deposits Ingress Fine Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: '',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Deposits Ingress Fine Joint',
                    },
                ],
            },
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.DNGV_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Deposits Ingress Gravel',
            [types_1.LanguageCodes.ES]: 'Depósitos Filtrados Gravilla',
            [types_1.LanguageCodes.FR]: 'Dépôts introduits – Gravier',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }) => {
            if (percentage <= 10)
                return { service: 2 };
            else if (percentage <= 20)
                return { service: 3 };
            else if (percentage <= 30)
                return { service: 4 };
            else if (percentage <= 10)
                return { service: 2 };
            else
                return { service: 5 };
        },
        description: 'Deposits Ingress Fine',
        options: [
            {
                name: 'Deposits Ingress Gravel Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.DNGVJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Deposits Ingress Gravel Joint',
                            [types_1.LanguageCodes.ES]: 'Depósitos Filtrados Gravilla Junta',
                            [types_1.LanguageCodes.FR]: 'Dépôts introduits – Gravier Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Deposits Ingress Gravel Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.DNZ_PACP,
        remarksRequired: true,
        name: {
            [types_1.LanguageCodes.EN]: 'Deposits Ingress Other',
            [types_1.LanguageCodes.ES]: 'Depósitos Filtrados Otros',
            [types_1.LanguageCodes.FR]: 'Dépôts introduits – Autre',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }) => {
            if (percentage <= 10)
                return { service: 2 };
            else if (percentage <= 20)
                return { service: 3 };
            else if (percentage <= 30)
                return { service: 4 };
            else if (percentage <= 10)
                return { service: 2 };
            else
                return { service: 5 };
        },
        description: 'Deposits Ingress Fine',
        options: [
            {
                name: 'Deposits Ingress Gravel Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.DNZJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Deposits Ingress Other Joint',
                            [types_1.LanguageCodes.ES]: 'Depósitos Filtrados Otros Junta',
                            [types_1.LanguageCodes.FR]: 'Dépôts introduits – Autre Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Deposits Ingress Gravel Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.DSC_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Deposits Settled Hard/Compacted',
            [types_1.LanguageCodes.ES]: 'Depósitos Asentados Compactados',
            [types_1.LanguageCodes.FR]: 'Dépôts déposés – Durs/compactés',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }) => {
            if (percentage <= 10)
                return { service: 2 };
            else if (percentage <= 20)
                return { service: 3 };
            else if (percentage <= 30)
                return { service: 4 };
            else if (percentage <= 10)
                return { service: 2 };
            else
                return { service: 5 };
        },
        description: 'Deposits Settled Hard/Compacted',
        options: [
            {
                name: 'Deposits Settled Hard/Compacted Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.DSCJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Deposits Settled Hard/Compacted Joint',
                            [types_1.LanguageCodes.ES]: 'Depósitos Asentados Compactados Junta',
                            [types_1.LanguageCodes.FR]: 'Dépôts déposés – Durs/compactés Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Deposits Settled Hard/Compacted Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.DSF_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Deposits Settled Fine',
            [types_1.LanguageCodes.ES]: 'Depósitos Asentados Finos',
            [types_1.LanguageCodes.FR]: 'Dépôts déposés – Fins',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }) => {
            if (percentage <= 10)
                return { service: 2 };
            else if (percentage <= 20)
                return { service: 3 };
            else if (percentage <= 30)
                return { service: 4 };
            else if (percentage <= 10)
                return { service: 2 };
            else
                return { service: 5 };
        },
        description: 'Deposits Settled Fine',
        options: [
            {
                name: 'Deposits Settled Fine Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.DSFJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Deposits Settled Fine Joint',
                            [types_1.LanguageCodes.ES]: 'Depósitos Asentados Finos Junta',
                            [types_1.LanguageCodes.FR]: 'Dépôts déposés – Fins Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Deposits Settled Fine Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.DSGV_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Deposits Settled Gravel',
            [types_1.LanguageCodes.ES]: 'Depósitos Asentados Gravilla',
            [types_1.LanguageCodes.FR]: 'Dépôts déposés – Gravier',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }) => {
            if (percentage <= 10)
                return { service: 2 };
            else if (percentage <= 20)
                return { service: 3 };
            else if (percentage <= 30)
                return { service: 4 };
            else if (percentage <= 10)
                return { service: 2 };
            else
                return { service: 5 };
        },
        description: 'Deposits Settled Gravel',
        options: [
            {
                name: 'Deposits Settled Gravel Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.DSGVJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Deposits Settled Gravel Joint',
                            [types_1.LanguageCodes.ES]: 'Depósitos Asentados Gravilla',
                            [types_1.LanguageCodes.FR]: 'Dépôts déposés – Gravier',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Deposits Settled Gravel Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.DSZ_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Deposits Settled Other',
            [types_1.LanguageCodes.ES]: 'Depósitos Asentados Otros',
            [types_1.LanguageCodes.FR]: 'Dépôts déposés – Autre',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }) => {
            if (percentage <= 10)
                return { service: 2 };
            else if (percentage <= 20)
                return { service: 3 };
            else if (percentage <= 30)
                return { service: 4 };
            else if (percentage <= 10)
                return { service: 2 };
            else
                return { service: 5 };
        },
        description: 'Deposits Settled Other',
        options: [
            {
                name: 'Deposits Settled Other Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.DSZJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Deposits Settled Other Joint',
                            [types_1.LanguageCodes.ES]: 'Depósitos Asentados Otros Junta',
                            [types_1.LanguageCodes.FR]: 'Dépôts déposés – Autre Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Deposits Settled Other Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.OBB_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Obstruction Brick or Masonry',
            [types_1.LanguageCodes.ES]: 'Obstáculo Ladrillos',
            [types_1.LanguageCodes.FR]: 'Obstruction – Brique ou maçonnerie',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }) => {
            if (percentage <= 10)
                return { service: 2 };
            else if (percentage <= 20)
                return { service: 3 };
            else if (percentage <= 30)
                return { service: 4 };
            else if (percentage <= 10)
                return { service: 2 };
            else
                return { service: 5 };
        },
        description: 'Obstruction Brick or Masonry',
        options: [
            {
                name: 'Obstruction Brick or Masonry Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.OBBJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Obstruction Brick or Masonry Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: '',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Obstruction Brick or Masonry Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.OBC_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Obstruction Through Connection',
            [types_1.LanguageCodes.ES]: 'Obstáculo a través de la Conexión',
            [types_1.LanguageCodes.FR]: 'Obstruction – Objet entrant par le raccordement',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }) => {
            if (percentage <= 10)
                return { service: 2 };
            else if (percentage <= 20)
                return { service: 3 };
            else if (percentage <= 30)
                return { service: 4 };
            else if (percentage <= 10)
                return { service: 2 };
            else
                return { service: 5 };
        },
        description: 'Obstruction Through Connection',
        options: [
            {
                name: 'Obstruction Through Connection Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.OBCJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Obstruction Through Connection Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: '',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Obstruction Through Connection Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.OBI_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Obstruction Intruding Through Wall',
            [types_1.LanguageCodes.ES]: 'Obstáculo Insertado a través de la Pared',
            [types_1.LanguageCodes.FR]: 'Obstruction – Objet pénétrant à travers la paroi',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ service: 5 }),
        description: 'Obstruction Intruding Through Wall',
        options: [
            {
                name: 'Obstruction Intruding Through Wall Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.OBIJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Obstruction Intruding Through Wall Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: '',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Obstruction Intruding Through Wall Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.OBJ_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Obstruction Wedged In The Joint',
            [types_1.LanguageCodes.ES]: 'Obstáculo Encajado en las Junta',
            [types_1.LanguageCodes.FR]: 'Obstruction – Objet coincé au joint',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }) => {
            if (percentage <= 10)
                return { service: 2 };
            else if (percentage <= 20)
                return { service: 3 };
            else if (percentage <= 30)
                return { service: 4 };
            else if (percentage <= 10)
                return { service: 2 };
            else
                return { service: 5 };
        },
        description: 'Obstruction Wedged In The Joint',
        options: [
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.OBM_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Obstruction Pipe Material in Invert',
            [types_1.LanguageCodes.ES]: 'Obstáculo Material de la Tubería en la Solera',
            [types_1.LanguageCodes.FR]: 'Obstruction – Morceau de tuyau sur le radier',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }) => {
            if (percentage <= 10)
                return { service: 2 };
            else if (percentage <= 20)
                return { service: 3 };
            else if (percentage <= 30)
                return { service: 4 };
            else if (percentage <= 10)
                return { service: 2 };
            else
                return { service: 5 };
        },
        description: 'Obstruction Pipe Material in Invert',
        options: [
            {
                name: 'Obstruction Pipe Material in Invert Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.OBMJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Obstruction Pipe Material in Invert Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: '',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Obstruction Pipe Material in Invert Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.OBN_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Obstruction Construction Debris',
            [types_1.LanguageCodes.ES]: 'Obstáculo Desechos de Construcción',
            [types_1.LanguageCodes.FR]: 'Obstruction – Débris de construction',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }) => {
            if (percentage <= 10)
                return { service: 2 };
            else if (percentage <= 20)
                return { service: 3 };
            else if (percentage <= 30)
                return { service: 4 };
            else if (percentage <= 10)
                return { service: 2 };
            else
                return { service: 5 };
        },
        description: 'Obstruction Construction Debris',
        options: [
            {
                name: 'Obstruction Construction Debris Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.OBNJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Obstruction Construction Debris Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: '',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Obstruction Construction Debris Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.OBP_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Obstruction External Pipe or Cable',
            [types_1.LanguageCodes.ES]: 'Obstáculo Cable o Tubería Externa',
            [types_1.LanguageCodes.FR]: 'Obstruction – Conduite ou câble externe',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }) => {
            if (percentage <= 10)
                return { service: 2 };
            else if (percentage <= 20)
                return { service: 3 };
            else if (percentage <= 30)
                return { service: 4 };
            else if (percentage <= 10)
                return { service: 2 };
            else
                return { service: 5 };
        },
        description: 'Obstruction External Pipe or Cable',
        options: [
            {
                name: 'Obstruction External Pipe or Cable Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.OBPJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Obstruction External Pipe or Cable Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: '',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Obstruction External Pipe or Cable Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.OBR_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Obstruction Rocks',
            [types_1.LanguageCodes.ES]: 'Obstáculo Rocas',
            [types_1.LanguageCodes.FR]: 'Obstruction – Roches',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }) => {
            if (percentage <= 10)
                return { service: 2 };
            else if (percentage <= 20)
                return { service: 3 };
            else if (percentage <= 30)
                return { service: 4 };
            else if (percentage <= 10)
                return { service: 2 };
            else
                return { service: 5 };
        },
        description: 'Obstruction Rocks',
        options: [
            {
                name: 'Obstruction Rocks Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.OBRJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Obstruction Rocks Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: '',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Obstruction Rocks Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.OBS_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Obstruction Built Into Structure',
            [types_1.LanguageCodes.ES]: 'Obstáculo Construido dentro de la Estructura',
            [types_1.LanguageCodes.FR]: 'Obstruction – Intégrée à la structure',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }) => {
            if (percentage <= 10)
                return { service: 2 };
            else if (percentage <= 20)
                return { service: 3 };
            else if (percentage <= 30)
                return { service: 4 };
            else if (percentage <= 10)
                return { service: 2 };
            else
                return { service: 5 };
        },
        description: 'Obstruction Built Into Structure',
        options: [
            {
                name: 'Obstruction Built Into Structure Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.OBSJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Obstruction Built Into Structure Joint',
                            [types_1.LanguageCodes.ES]: 'Obstáculo Construido dentro de la Estructura Junta',
                            [types_1.LanguageCodes.FR]: 'Obstruction – Intégrée à la structure joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Obstruction Built Into Structure Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.OBZ_PACP,
        remarksRequired: true,
        name: {
            [types_1.LanguageCodes.EN]: 'Obstruction Other',
            [types_1.LanguageCodes.ES]: 'Obstáculo Otro',
            [types_1.LanguageCodes.FR]: 'Obstruction – Autre',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }) => {
            if (percentage <= 10)
                return { service: 2 };
            else if (percentage <= 20)
                return { service: 3 };
            else if (percentage <= 30)
                return { service: 4 };
            else if (percentage <= 10)
                return { service: 2 };
            else
                return { service: 5 };
        },
        description: 'Obstruction Other',
        options: [
            {
                name: 'Obstruction Other Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.OBZJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Obstruction Other Joint',
                            [types_1.LanguageCodes.ES]: 'Obstáculo Otro Junta',
                            [types_1.LanguageCodes.FR]: 'Obstruction – Autre Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Obstruction Other Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.ID_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Infiltration Dripper',
            [types_1.LanguageCodes.ES]: 'Infil. Goteo',
            [types_1.LanguageCodes.FR]: 'Infiltration – Goutte à goutte',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ service: 3 }),
        description: 'Infiltration Dripper',
        options: [
            {
                name: 'Infiltration Dripper Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.IDJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Infiltration Dripper Joint',
                            [types_1.LanguageCodes.ES]: 'Infil. Goteo Junta',
                            [types_1.LanguageCodes.FR]: 'Infiltration – Goutte à goutte joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Infiltration Dripper Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.IDB_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Infiltration Dripper Barrel',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Infiltration – Goutte à goutte – Paroi',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ service: 3 }),
        description: 'Infiltration Dripper Barrel',
        options: [
            {
                name: 'Infiltration Dripper Barrel Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.IDBJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Infiltration Dripper Barrel Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: 'Infiltration – Goutte à goutte – Paroi Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Infiltration Dripper Barrel Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.IDC_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Infiltration Dripper Barrel',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Infiltration – Goutte à goutte – Raccordement',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ service: 3 }),
        description: 'Infiltration Dripper Connection',
        options: [
            {
                name: 'Infiltration Dripper Connection Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.IDCJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Infiltration Dripper Connection Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: 'Infiltration – Goutte à goutte – Raccordement Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Infiltration Dripper Connection Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.IDL_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Infiltration Dripper Lateral',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Infiltration – Goutte à goutte – Branchement',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ service: 3 }),
        description: 'Infiltration Dripper Lateral',
        options: [
            {
                name: 'Infiltration Dripper Lateral Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.IDLJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Infiltration Dripper Lateral Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: 'Infiltration – Goutte à goutte – Branchement Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Infiltration Dripper Lateral Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.IG_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Infiltration Gusher',
            [types_1.LanguageCodes.ES]: 'Infil. A Presión',
            [types_1.LanguageCodes.FR]: 'Infiltration – Écoulement sous pression',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({
            service: 5,
        }),
        description: 'Infiltration Gusher',
        options: [
            {
                name: 'Infiltration Gusher Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.IGJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Infiltration Gusher Joint',
                            [types_1.LanguageCodes.ES]: 'Infil. A Presión Junta',
                            [types_1.LanguageCodes.FR]: 'Infiltration – Écoulement sous pression Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Infiltration Gusher Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.IGB_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Infiltration Gusher Barrel',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Infiltration – Écoulement sous pression – Paroi',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ service: 5 }),
        description: 'Infiltration Gusher Barrel',
        options: [
            {
                name: 'Infiltration Gusher Barrel Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.IGBJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Infiltration Gusher Barrel Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: 'Infiltration – Écoulement sous pression – Paroi Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Infiltration Gusher Barrel Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.IGC_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Infiltration Gusher Connection',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Infiltration – Écoulement sous pression – Raccordement',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ service: 5 }),
        description: 'Infiltration Gusher Connection',
        options: [
            {
                name: 'Infiltration Gusher Connection Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.IGCJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Infiltration Gusher Connection Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: 'Infiltration – Écoulement sous pression – Raccordement Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Infiltration Gusher Connection Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.IGL_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Infiltration Gusher Connection',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Infiltration – Écoulement sous pression – Branchement',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ service: 5 }),
        description: 'Infiltration Gusher Lateral',
        options: [
            {
                name: 'Infiltration Gusher Lateral Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.IGLJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Infiltration Gusher Lateral Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: 'Infiltration – Écoulement sous pression – Branchement',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Infiltration Gusher Lateral Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.IR_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Infiltration Runner',
            [types_1.LanguageCodes.ES]: 'Infil. Torrente',
            [types_1.LanguageCodes.FR]: 'Infiltration – Écoulement continu',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ service: 4 }),
        description: 'Infiltration Runner',
        options: [
            {
                name: 'Infiltration Runner Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.IRJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Infiltration Runner Joint',
                            [types_1.LanguageCodes.ES]: 'Infil. Torrente Junta',
                            [types_1.LanguageCodes.FR]: 'Infiltration – Écoulement continu Joint',
                        },
                        scoringFunction: () => ({ service: 4 }),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Infiltration Gusher Lateral Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.IRC_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Infiltration Runner Connection',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Infiltration – Écoulement continu – Raccordement',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ service: 4 }),
        description: 'Infiltration Runner Connection',
        options: [
            {
                name: 'Infiltration Runner Barrel Connection',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.IRCJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Infiltration Runner Barrel Connection',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: 'Infiltration – Écoulement continu – Raccordement',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Infiltration Gusher Lateral Connection',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.IRB_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Infiltration Runner Barrel',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Infiltration – Écoulement continu – Paroi',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ service: 4 }),
        description: 'Infiltration Runner Barrel',
        options: [
            {
                name: 'Infiltration Runner Barrel Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.IRBJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Infiltration Runner Barrel Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: 'Infiltration – Écoulement continu – Paroi Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Infiltration Runner Barrel Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.IRL_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Infiltration Runner Lateral',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Infiltration – Écoulement continu – Branchement',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ service: 4 }),
        description: 'Infiltration Runner Lateral',
        options: [
            {
                name: 'Infiltration Runner Lateral Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.IRLJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Infiltration Runner Lateral Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: 'Infiltration – Écoulement continu – Branchement Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Infiltration Runner Lateral Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.IS_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Infiltration Stain',
            [types_1.LanguageCodes.ES]: 'Infil. Mancha',
            [types_1.LanguageCodes.FR]: 'Infiltration – Traces',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({
            service: 1,
        }),
        description: 'Infiltration Stain',
        options: [
            {
                name: 'Infiltration Stain Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.ISJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Infiltration Stain Joint',
                            [types_1.LanguageCodes.ES]: 'Infil. Mancha Junta',
                            [types_1.LanguageCodes.FR]: 'Infiltration – Traces Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Infiltration Stain Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.ISL_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Infiltration Stain Lateral',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Infiltration – Traces – Branchement',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ service: 1 }),
        description: 'Infiltration Stain Lateral',
        options: [
            {
                name: 'Infiltration Stain Lateral',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.ISLJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Infiltration Stain Lateral Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: 'Infiltration – Traces – Branchement Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Infiltration Stain Lateral Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.ISB_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Infiltration Stain Barrel',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Infiltration – Traces – Paroi',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ service: 1 }),
        description: 'Infiltration Stain Barrel',
        options: [
            {
                name: 'Infiltration Stain Barrel Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.ISBJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Infiltration Stain Barrel Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: 'Infiltration – Traces – Paroi Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Infiltration Stain Barrel Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.ISC_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Infiltration Stain Connection',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Infiltration – Traces – Raccordement',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({
            service: 1,
        }),
        description: 'Infiltration Stain Connection',
        options: [
            {
                name: 'Infiltration Stain Connection Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.ISCJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Infiltration Stain Connection Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: 'Infiltration – Traces – Raccordement',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Infiltration Stain Connection Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.LFAC_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Lining Feature Abandoned Connection',
            [types_1.LanguageCodes.ES]: 'Revestimiento Conexión Abandonada',
            [types_1.LanguageCodes.FR]: 'Caractéristique du revêtement – Branchement abandonné',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Lining Feature Abandoned Connection',
        options: [
            {
                name: 'Lining Feature Abandoned Connection Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.LFACJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Lining Feature Abandoned Connection Joint',
                            [types_1.LanguageCodes.ES]: 'Revestimiento Conexión Abandonada junta',
                            [types_1.LanguageCodes.FR]: 'Caractéristique du revêtement – Branchement abandonné joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Lining Feature Abandoned Connection Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.LFAS_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Lining Feature Annular Space',
            [types_1.LanguageCodes.ES]: 'Revestimiento Espacio Anular',
            [types_1.LanguageCodes.FR]: 'Caractéristique du revêtement – Espace annulaire',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ structure: 3 }),
        description: 'Lining Feature Annular Space',
        options: [
            {
                name: 'Lining Feature Annular Space Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.LFASJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Lining Feature Annular Space Joint',
                            [types_1.LanguageCodes.ES]: 'Revestimiento Espacio Anular Junta',
                            [types_1.LanguageCodes.FR]: 'Caractéristique du revêtement – Espace annulai joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Lining Feature Annular Space Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.LFB_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Lining Feature Blistered',
            [types_1.LanguageCodes.ES]: 'Revestimiento Ampollado',
            [types_1.LanguageCodes.FR]: 'Revestimiento AmpolladoRevestimiento Ampollado',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ structure: 3 }),
        description: 'Lining Feature Blistered',
        options: [
            {
                name: 'Lining Feature Blistered Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.LFBJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Lining Feature Blistered Joint',
                            [types_1.LanguageCodes.ES]: 'Revestimiento Ampollado junta',
                            [types_1.LanguageCodes.FR]: 'Revestimiento Ampollado joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Lining Feature Blistered Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.LFCS_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Lining Feature Service Cut Shifted',
            [types_1.LanguageCodes.ES]: 'Revestimiento Corte Desplazado',
            [types_1.LanguageCodes.FR]: "Caractéristique du revêtement – Déplacement de l'ouverture du branchement",
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ structure: 3 }),
        description: 'Lining Feature Service Cut Shifted',
        options: [
            {
                name: 'Lining Feature Service Cut Shifted Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.LFCSJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Lining Feature Service Cut Shifted Joint',
                            [types_1.LanguageCodes.ES]: 'Revestimiento Corte Desplazado junta',
                            [types_1.LanguageCodes.FR]: "Caractéristique du revêtement – Déplacement de l'ouverture du branchement joint",
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Lining Feature Service Cut Shifted Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.LFD_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Lining Feature Detached',
            [types_1.LanguageCodes.ES]: 'Revestimiento Separado',
            [types_1.LanguageCodes.FR]: 'Caractéristique du revêtement – Revêtement décollé',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ structure: 3 }),
        description: 'Lining Feature Detached',
        options: [
            {
                name: 'Lining Feature Detached Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.LFDJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Lining Feature Detached Joint',
                            [types_1.LanguageCodes.ES]: 'Revestimiento Separado junta',
                            [types_1.LanguageCodes.FR]: 'Caractéristique du revêtement – Revêtement décollé joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Lining Feature Detached Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.LFDC_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Lining Feature Discoloration',
            [types_1.LanguageCodes.ES]: 'Revestimiento Descoloración',
            [types_1.LanguageCodes.FR]: 'Caractéristique du revêtement – Décoloration',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attr) => {
            if ((0, types_1.isPacpLeveeOrDam)(attr.attributes.use))
                return { structure: 1 };
            else
                return { structure: 3 };
        },
        description: 'Lining Feature Discoloration',
        options: [
            {
                name: 'Lining Feature Discoloration Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.LFDCJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Lining Feature Discoloration Joint',
                            [types_1.LanguageCodes.ES]: 'Revestimiento Descoloración Junta',
                            [types_1.LanguageCodes.FR]: 'Caractéristique du revêtement – Décoloration Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Lining Feature Discoloration Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.LFDE_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Lining Feature Defective End',
            [types_1.LanguageCodes.ES]: 'Revestimiento Extremo Defectuoso',
            [types_1.LanguageCodes.FR]: 'Caractéristique du revêtement – Extrémité défectueuse',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ structure: 3 }),
        description: 'Lining Feature Defective End',
        remarksRequired: true,
        options: [
            {
                name: 'Lining Feature Defective End Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.LFDEJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Lining Feature Defective End Joint',
                            [types_1.LanguageCodes.ES]: 'Revestimiento Extremo Defectuoso',
                            [types_1.LanguageCodes.FR]: 'Caractéristique du revêtement – Extrémité défectueuse',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Lining Feature Defective End Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.LFDL_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Lining Feature Delaminating',
            [types_1.LanguageCodes.ES]: 'Revestimiento Delaminación',
            [types_1.LanguageCodes.FR]: 'Caractéristique du revêtement – Délamination',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ structure: 3 }),
        description: 'Lining Feature Delaminating',
        options: [
            {
                name: 'Lining Feature Delaminating Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.LFDLJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Lining Feature Delaminating Joint',
                            [types_1.LanguageCodes.ES]: 'Revestimiento Delaminación Junta',
                            [types_1.LanguageCodes.FR]: 'Caractéristique du revêtement – Délamination Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Lining Feature Delaminating Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.LFOC_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Lining Feature Overcut Service',
            [types_1.LanguageCodes.ES]: 'Revestimiento Corte Excedido',
            [types_1.LanguageCodes.FR]: 'Caractéristique du revêtement – Ouverture du branchement trop grande',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ structure: 3 }),
        description: 'Lining Feature Overcut Service',
        options: [
            {
                name: 'Lining Feature Overcut Service Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.LFOCJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Lining Feature Overcut Service Joint',
                            [types_1.LanguageCodes.ES]: 'Revestimiento Corte Excedido Junta',
                            [types_1.LanguageCodes.FR]: 'Caractéristique du revêtement – Ouverture du branchement trop grande Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Lining Feature Overcut Service Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.LFRS_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Lining Feature Resin slug',
            [types_1.LanguageCodes.ES]: 'Revestimiento Descarga de Resina',
            [types_1.LanguageCodes.FR]: 'Caractéristique du revêtement – Amas de résine',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ structure: 3 }),
        description: 'Lining Feature Resin slug',
        options: [
            {
                name: 'Lining Feature Resin slug Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.LFRSJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Lining Feature Resin slug Joint',
                            [types_1.LanguageCodes.ES]: 'Revestimiento Descarga de Resina Junta',
                            [types_1.LanguageCodes.FR]: 'Caractéristique du revêtement – Amas de résine Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Lining Feature Resin slug Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.LFUC_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Lining Feature Undercut Service',
            [types_1.LanguageCodes.ES]: 'Revestimiento Corte Restringido',
            [types_1.LanguageCodes.FR]: 'Caractéristique du revêtement – Ouverture du branchement trop petite',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ structure: 3 }),
        description: 'Lining Feature Undercut Service',
        options: [
            {
                name: 'Lining Feature Undercut Service Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.LFUCJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Lining Feature Undercut Service Joint',
                            [types_1.LanguageCodes.ES]: 'Revestimiento Corte Restringido Junta',
                            [types_1.LanguageCodes.FR]: 'Caractéristique du revêtement – Ouverture du branchement trop petite joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Lining Feature Undercut Service Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.LFW_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Lining Feature Wrinkled',
            [types_1.LanguageCodes.ES]: 'Revestimiento Arrugado',
            [types_1.LanguageCodes.FR]: 'Caractéristique du revêtement – Revêtement plissé',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ structure: 3 }),
        description: 'Lining Feature Wrinkled',
        options: [
            {
                name: 'Lining Feature Wrinkled Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.LFWJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Lining Feature Wrinkled Joint',
                            [types_1.LanguageCodes.ES]: 'Revestimiento Arrugado Junta',
                            [types_1.LanguageCodes.FR]: 'Caractéristique du revêtement – Revêtement plissé joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Lining Feature Wrinkled Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.LFZ_PACP,
        remarksRequired: true,
        name: {
            [types_1.LanguageCodes.EN]: 'Lining Feature Other',
            [types_1.LanguageCodes.ES]: 'Revestimiento Otro',
            [types_1.LanguageCodes.FR]: 'Caractéristique du revêtement – Autre',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Lining Feature Other',
        options: [
            {
                name: 'Lining Feature Other Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.LFZJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Lining Feature Other Joint',
                            [types_1.LanguageCodes.ES]: 'Revestimiento Otro junta',
                            [types_1.LanguageCodes.FR]: 'Caractéristique du revêtement – Autre joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Lining Feature Other Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.SAM_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Surface Damage Aggregate Missing',
            [types_1.LanguageCodes.ES]: 'Superficie Agregado Ausente',
            [types_1.LanguageCodes.FR]: 'Dommage à la surface – Agrégat manquant',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    structure: 3,
                };
            }
            return {
                structure: 4,
            };
        },
        description: 'Surface Damage Aggregate Missing',
        options: [
            {
                name: 'Surface Damage Aggregate Missing Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.SAMJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Surface Damage Aggregate Missing Joint',
                            [types_1.LanguageCodes.ES]: 'Superficie Agregado Ausente Junta',
                            [types_1.LanguageCodes.FR]: 'Dommage à la surface – Agrégat manquant Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Surface Damage Aggregate Missing Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.SAP_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Surface  Damage Aggregate Projecting',
            [types_1.LanguageCodes.ES]: 'Superficie Agregado Protuberante',
            [types_1.LanguageCodes.FR]: 'Dommage à la surface – Agrégat saillant',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ structure: 3 }),
        description: 'Surface  Damage Aggregate Projecting',
        options: [
            {
                name: 'Surface  Damage Aggregate Projecting',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.SAPJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Surface Damage Aggregate Projecting Joint',
                            [types_1.LanguageCodes.ES]: 'Superficie Agregado Protuberante Junta',
                            [types_1.LanguageCodes.FR]: 'Dommage à la surface – Agrégat saillant joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Surface  Damage Aggregate Projecting Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.SAV_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Surface Damage Aggregate Visible',
            [types_1.LanguageCodes.ES]: 'Superficie Agregado Visible',
            [types_1.LanguageCodes.FR]: 'Dommage à la surface – Agrégat visible',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    structure: 3,
                };
            }
            return {
                structure: 2,
            };
        },
        description: 'Surface Damage Aggregate Visible',
        options: [
            {
                name: 'Surface Damage Aggregate Visible Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.SAVJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Surface Damage Aggregate Visible Joint',
                            [types_1.LanguageCodes.ES]: 'Superficie Agregado Visible Junta',
                            [types_1.LanguageCodes.FR]: 'Dommage à la surface – Agrégat visible Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Surface Damage Aggregate Visible Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.SCP_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Surface Damage Corrosion',
            [types_1.LanguageCodes.ES]: 'Superficie Corrosión Tubería Metálica',
            [types_1.LanguageCodes.FR]: 'Dommage à la surface – Corrosion',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    structure: 4,
                };
            }
            return {
                structure: 3,
            };
        },
        description: 'Surface Damage Corrosion',
        options: [
            {
                name: 'Surface Damage Corrosion Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.SCPJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Surface Damage Corrosion Joint',
                            [types_1.LanguageCodes.ES]: 'Superficie Corrosión Tubería Metálica Junta',
                            [types_1.LanguageCodes.FR]: 'Dommage à la surface – Corrosion Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Surface Damage Corrosion Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.SMW_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Surface Damage Missing Wall',
            [types_1.LanguageCodes.ES]: 'Superficie Pared Ausente',
            [types_1.LanguageCodes.FR]: 'Dommage à la surface – Paroi manquante',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ structure: 5 }),
        description: 'Surface Damage Missing Wall',
        options: [
            {
                name: 'Surface Damage Missing Wall Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.SMWJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Surface Damage Missing Wall Joint',
                            [types_1.LanguageCodes.ES]: 'Superficie Pared Ausente Junta',
                            [types_1.LanguageCodes.FR]: 'Dommage à la surface – Paroi manquante Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Surface Damage Missing Wall Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.SRC_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Surface Damage Reinforcement Corroded',
            [types_1.LanguageCodes.ES]: 'Superficie Refuerzo Corroido',
            [types_1.LanguageCodes.FR]: 'Dommage à la surface – Armature corrodée',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ structure: 5 }),
        description: 'Surface Damage Reinforcement Corroded',
        options: [
            {
                name: 'Surface Damage Reinforcement Corroded',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.SRCJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Surface Damage Reinforcement Corroded Joint',
                            [types_1.LanguageCodes.ES]: 'Superficie Refuerzo Corroido Junta',
                            [types_1.LanguageCodes.FR]: 'Dommage à la surface – Armature corrodée Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Surface Damage Reinforcement Corroded Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.SRI_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Surface Damage Roughness Increased',
            [types_1.LanguageCodes.ES]: 'Superficie Aumento de Rugosidad',
            [types_1.LanguageCodes.FR]: 'Dommage à la surface – Rugosité accrue',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ structure: 1 }),
        description: 'Surface Damage Roughness Increased',
        options: [
            {
                name: 'Surface Damage Roughness Increased Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.SRIJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Surface Damage Roughness Increased Joint',
                            [types_1.LanguageCodes.ES]: 'Superficie Aumento de Rugosidad Junta',
                            [types_1.LanguageCodes.FR]: 'Dommage à la surface – Rugosité accrue joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Surface Damage Roughness Increased Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.SRP_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Surface Damage Reinforcement Projecting',
            [types_1.LanguageCodes.ES]: 'Superficie Refuerzo Protuberante',
            [types_1.LanguageCodes.FR]: 'Dommage à la surface – Armature saillante',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    structure: 4,
                };
            }
            return {
                structure: 5,
            };
        },
        description: 'Surface Damage Reinforcement Projecting',
        options: [
            {
                name: 'Surface Damage Reinforcement Projecting Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.SRPJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Surface Damage Reinforcement Projecting Joint',
                            [types_1.LanguageCodes.ES]: 'Superficie Refuerzo Protuberante Junta',
                            [types_1.LanguageCodes.FR]: 'Dommage à la surface – Armature saillante joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Surface Damage Reinforcement Projecting Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.SRV_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Surface Damage Reinforcement Visible',
            [types_1.LanguageCodes.ES]: 'Superficie Refuerzo Visible',
            [types_1.LanguageCodes.FR]: 'Dommage à la surface – Armature visible',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ structure: 4 }),
        description: 'Surface Damage Reinforcement Visible',
        options: [
            {
                name: 'Surface Damage Reinforcement Visible Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.SRVJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Surface Damage Reinforcement Visible Joint',
                            [types_1.LanguageCodes.ES]: 'Superficie Refuerzo Visible Junta',
                            [types_1.LanguageCodes.FR]: 'Dommage à la surface – Armature visible Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Surface Damage Reinforcement Visible Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.SSC_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Surface Spalling of Damage Coating',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.PACP],
        scoringFunction: () => ({ structure: 1 }),
        description: 'Surface Spalling of Damage Coating',
        options: [
            {
                name: 'Surface Spalling of Damage Coating Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.SSCJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Surface Spalling of Damage Coating Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: '',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP],
                        description: 'Surface Spalling of Damage Coating Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.SSS_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Surface Damage Surface Spalling',
            [types_1.LanguageCodes.ES]: 'Superficie Astillada',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    structure: 3,
                };
            }
            return {
                structure: 2,
            };
        },
        description: 'Surface Damage Surface Spalling',
        options: [
            {
                name: 'Surface Damage Surface Spalling Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.SSSJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Surface Damage Surface Spalling Joint',
                            [types_1.LanguageCodes.ES]: 'Superficie Astillada Junta',
                            [types_1.LanguageCodes.FR]: 'Dommage à la surface – Éclat de surface joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Surface Damage Surface Spalling Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.IWB_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Infiltration Weeper Barrel',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Infiltration – Suintement – Paroi',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ service: 2 }),
        description: 'Infiltration Weeper Barrel',
        options: [
            {
                name: 'Infiltration Weeper Barrel Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.IWBJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Infiltration Weeper Barrel Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: '',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Infiltration Weeper Barrel Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.IWC_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Infiltration Weeper Connection',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Infiltration – Suintement – Raccordement',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ service: 2 }),
        description: 'Infiltration Weeper Connection',
        options: [
            {
                name: 'Infiltration Weeper Connection Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.IWCJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Infiltration Weeper Connection Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: 'Infiltration – Suintement – Raccordement Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Infiltration Weeper Connection Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.IWJ_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Infiltration Weeper Joint',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Infiltration – Suintement – Joint',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ service: 2 }),
        description: 'Infiltration Weeper Joint',
        options: [
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.IWL_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Infiltration Weeper Lateral',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ service: 2 }),
        description: 'Infiltration Weeper Lateral',
        options: [
            {
                name: 'Infiltration Weeper Lateral Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.IWLJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Infiltration Weeper Lateral Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: '',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Infiltration Weeper Lateral Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.WFC_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Weld Feature Circumferential',
            [types_1.LanguageCodes.ES]: 'Falla Soldadura Circular',
            [types_1.LanguageCodes.FR]: 'Défaillance de soudure – Circulaire',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attr) => {
            if ((0, types_1.isPacpLeveeOrDam)(attr.attributes.use)) {
                return { structure: 4 };
            }
            else {
                return { structure: 2 };
            }
        },
        description: 'Weld Feature Circumferential',
        options: [
            {
                name: 'Weld Feature Circumferential Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.WFCJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Weld Feature Circumferential Joint',
                            [types_1.LanguageCodes.ES]: 'Falla Soldadura Circular Junta',
                            [types_1.LanguageCodes.FR]: 'Défaillance de soudure – Circulaire Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Weld Feature Circumferential Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.WFL_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Weld Feature Longitudinal',
            [types_1.LanguageCodes.ES]: 'Falla Soldadura Longitudinal',
            [types_1.LanguageCodes.FR]: 'Défaillance de soudure – Longitudinale',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attr) => {
            if ((0, types_1.isPacpLeveeOrDam)(attr.attributes.use)) {
                return { structure: 4 };
            }
            else {
                return { structure: 2 };
            }
        },
        description: 'Weld Feature Longitudinal',
        options: [
            {
                name: 'Weld Feature Longitudinal Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.WFLJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Weld Feature Longitudinal Joint',
                            [types_1.LanguageCodes.ES]: 'Falla Soldadura Longitudinal Junta',
                            [types_1.LanguageCodes.FR]: 'Défaillance de soudure – Longitudinale Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Weld Feature Longitudinal Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.WFM_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Weld Feature Multiple',
            [types_1.LanguageCodes.ES]: 'Falla Soldadura Multiple',
            [types_1.LanguageCodes.FR]: 'Défaillances de soudure – Multiples',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attr) => {
            if ((0, types_1.isPacpLeveeOrDam)(attr.attributes.use)) {
                return { structure: 5 };
            }
            else {
                return { structure: 3 };
            }
        },
        description: 'Weld Feature Multiple',
        options: [
            {
                name: 'Weld Feature Multiple Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.WFMJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Weld Feature Multiple Joint',
                            [types_1.LanguageCodes.ES]: 'Falla Soldadura Multiple Junta',
                            [types_1.LanguageCodes.FR]: 'Défaillances de soudure – Multiples Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Weld Feature Multiple Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.WFS_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Weld Feature Spiral',
            [types_1.LanguageCodes.ES]: 'Falla Soldadura Espiral',
            [types_1.LanguageCodes.FR]: 'Défaillance de soudure – En spirale',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attr) => {
            if ((0, types_1.isPacpLeveeOrDam)(attr.attributes.use)) {
                return { structure: 4 };
            }
            else {
                return { structure: 2 };
            }
        },
        description: 'Weld Feature Spiral',
        options: [
            {
                name: 'Weld Feature Spiral Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.WFSJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Weld Feature Spiral Joint',
                            [types_1.LanguageCodes.ES]: 'Falla Soldadura Espiral Junta',
                            [types_1.LanguageCodes.FR]: 'Défaillance de soudure – En spirale joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Weld Feature Spiral Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.WFZ_PACP,
        remarksRequired: true,
        name: {
            [types_1.LanguageCodes.EN]: 'Weld Feature Other',
            [types_1.LanguageCodes.ES]: 'Falla Soldadura Otros',
            [types_1.LanguageCodes.FR]: 'Défaillance de soudure – Autre',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Weld Feature Other',
        options: [
            {
                name: 'Weld Feature Other Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.WFZJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Weld Feature Other Joint',
                            [types_1.LanguageCodes.ES]: 'Falla Soldadura Otros Junta',
                            [types_1.LanguageCodes.FR]: 'Défaillance de soudure – Autre Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Weld Feature Other Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.ISGT_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Intruding Sealing Material Grout',
            [types_1.LanguageCodes.ES]: 'Sello Penetrante Lechada',
            [types_1.LanguageCodes.FR]: 'Intrusion du produit d’étanchéité – Coulis',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }) => {
            if (percentage <= 10)
                return { service: 2 };
            else if (percentage <= 20)
                return { service: 3 };
            else if (percentage <= 30)
                return { service: 4 };
            else if (percentage <= 10)
                return { service: 2 };
            else
                return { service: 5 };
        },
        description: 'Intruding Sealing Material Grout',
        options: [
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.ISSR_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Intruding Sealing Material Sealing Ring',
            [types_1.LanguageCodes.ES]: 'Sello Penetrante Empaque',
            [types_1.LanguageCodes.FR]: 'Intrusion du produit d’étanchéité – Garniture',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }) => {
            if (percentage <= 10)
                return { service: 2 };
            else if (percentage <= 20)
                return { service: 3 };
            else if (percentage <= 30)
                return { service: 4 };
            else if (percentage <= 10)
                return { service: 2 };
            else
                return { service: 5 };
        },
        description: 'Intruding Sealing Material Sealing Ring',
        options: [
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.ISSRB_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Intruding Sealing Material Sealing Ring Broken',
            [types_1.LanguageCodes.ES]: 'Sello Penetrante Empaque Roto',
            [types_1.LanguageCodes.FR]: 'Intrusion du produit d’étanchéité – Garniture – Brisée',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }) => {
            if (percentage <= 10)
                return { service: 2 };
            else if (percentage <= 20)
                return { service: 3 };
            else if (percentage <= 30)
                return { service: 4 };
            else if (percentage <= 10)
                return { service: 2 };
            else
                return { service: 5 };
        },
        description: 'Intruding Sealing Material Sealing Ring Broken',
        options: [
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.ISSRH_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Intruding Sealing Material Sealing Ring Hanging',
            [types_1.LanguageCodes.ES]: 'Sello Penetrante Empaque Colgando',
            [types_1.LanguageCodes.FR]: 'Intrusion du produit d’étanchéité – Garniture – Pendante',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }) => {
            if (percentage <= 10)
                return { service: 2 };
            else if (percentage <= 20)
                return { service: 3 };
            else if (percentage <= 30)
                return { service: 4 };
            else if (percentage <= 10)
                return { service: 2 };
            else
                return { service: 5 };
        },
        description: 'Intruding Sealing Material Sealing Ring Hanging',
        options: [
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.ISSRL_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Intruding Sealing Material Sealing Ring Loose/Poorly Fitting',
            [types_1.LanguageCodes.ES]: 'Sello Penetrante Empaque Suelto/ Mal Ajustado',
            [types_1.LanguageCodes.FR]: 'Intrusion du produit d’étanchéité – Garniture – Lâche, mal ajustée',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }) => {
            if (percentage <= 10)
                return { service: 2 };
            else if (percentage <= 20)
                return { service: 3 };
            else if (percentage <= 30)
                return { service: 4 };
            else if (percentage <= 10)
                return { service: 2 };
            else
                return { service: 5 };
        },
        description: 'Intruding Sealing Material Sealing Ring Loose/Poorly Fitting',
        options: [
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.ISZ_PACP,
        remarksRequired: true,
        name: {
            [types_1.LanguageCodes.EN]: 'Intruding Sealing Material Other',
            [types_1.LanguageCodes.ES]: 'Sello Penetrante Otro',
            [types_1.LanguageCodes.FR]: 'Intrusion du produit d’étanchéité – Autre',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }) => {
            if (percentage <= 10)
                return { service: 2 };
            else if (percentage <= 20)
                return { service: 3 };
            else if (percentage <= 30)
                return { service: 4 };
            else if (percentage <= 10)
                return { service: 2 };
            else
                return { service: 5 };
        },
        description: 'Intruding Sealing Material Other',
        options: [
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.DFBI_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Deformed Flexible Bulging Inverse Curvature',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Déformation – Conduite flexible – Gondolement courbure inverse',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }, { attributes: { use } }) => {
            if ((0, types_1.isPacpLeveeOrDam)(use)) {
                if (percentage <= 10)
                    return { structure: 4 };
                else
                    return { service: 5 };
            }
            else {
                return { structure: 5 };
            }
        },
        description: 'Deformed Flexible Bulging Inverse Curvature',
        options: [
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.DFBR_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Deformed Flexible Bulging Round',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Déformation – Conduite flexible – Gondolement arrondi',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }, { attributes: { use } }) => {
            if ((0, types_1.isPacpLeveeOrDam)(use)) {
                if (percentage <= 5)
                    return { structure: 2 };
                else if (percentage <= 10)
                    return { structure: 3 };
                else if (percentage <= 20)
                    return { structure: 4 };
                else
                    return { structure: 5 };
            }
            else {
                if (percentage <= 5)
                    return { structure: 3 };
                else if (percentage <= 10)
                    return { structure: 4 };
                else
                    return { structure: 5 };
            }
        },
        description: 'Deformed Flexible Bulging Round',
        options: [
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.DTBR_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Deformed Brick Bulging Round',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Déformation – Brique – Gondolement arrondi',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }, { attributes: { use } }) => {
            if ((0, types_1.isPacpLeveeOrDam)(use)) {
                if (percentage <= 10)
                    return { structure: 4 };
                else
                    return { service: 5 };
            }
            else {
                if (percentage <= 5)
                    return { structure: 4 };
                else
                    return { service: 5 };
            }
        },
        description: 'Deformed Brick Bulging Round',
        options: [
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.RTB_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Roots Tap Barrel',
            [types_1.LanguageCodes.ES]: 'Raíces Pivotantes en el Barril',
            [types_1.LanguageCodes.FR]: 'Racines – Cordon – Paroi',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ service: 3 }),
        description: 'Roots Tap Barrel',
        options: [
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.RTC_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Roots Tap Connection',
            [types_1.LanguageCodes.ES]: 'Raíces Pivotantes en la Conexión',
            [types_1.LanguageCodes.FR]: 'Racines – Cordon – Raccordement',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    service: 3,
                };
            }
            return {
                service: 2,
            };
        },
        description: 'Roots Tap Connection',
        options: [
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.RBB_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Roots Ball Barrel',
            [types_1.LanguageCodes.ES]: 'Bola de Raíces en el Barril',
            [types_1.LanguageCodes.FR]: 'Racines – Écran – Paroi',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    structure: 4,
                };
            }
            return {
                structure: 5,
            };
        },
        description: 'Roots Ball Barrel',
        options: [
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.RBC_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Roots Ball Connection',
            [types_1.LanguageCodes.ES]: 'Bola de Raíces en la Conexión',
            [types_1.LanguageCodes.FR]: 'Racines – Écran – Raccordement',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ service: 4 }),
        description: 'Roots Ball Connection',
        options: [
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.RBL_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Roots Ball Lateral',
            [types_1.LanguageCodes.ES]: 'Bola de Raíces en la Acometida',
            [types_1.LanguageCodes.FR]: 'Racines – Écran – Branchement',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ service: 4 }),
        description: 'Roots Ball Lateral',
        options: [
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.RMB_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Roots Medium Barrel',
            [types_1.LanguageCodes.ES]: 'Raíces Medianas en el Barril',
            [types_1.LanguageCodes.FR]: 'Racines – Moyennes – Paroi',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ service: 4 }),
        description: 'Roots Medium Barrel',
        options: [
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.RMC_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Roots Medium Connection',
            [types_1.LanguageCodes.ES]: 'Raíces Medianas en la Conexión',
            [types_1.LanguageCodes.FR]: 'Racines – Moyennes – Raccordement',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    service: 4,
                };
            }
            return {
                service: 3,
            };
        },
        description: 'Roots Medium Connection',
        options: [
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.RML_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Roots Medium Lateral',
            [types_1.LanguageCodes.ES]: 'Raíces Medianas en la Acometida',
            [types_1.LanguageCodes.FR]: 'Racines – Moyennes – Branchement',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    service: 4,
                };
            }
            return {
                service: 3,
            };
        },
        description: 'Roots Medium Lateral',
        options: [
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.RTL_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Roots Tap Lateral',
            [types_1.LanguageCodes.ES]: 'Raíces Pivotantes en la Acometida',
            [types_1.LanguageCodes.FR]: 'Racines – Cordon – Branchement',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    service: 3,
                };
            }
            return {
                service: 2,
            };
        },
        description: 'Roots Tap Lateral',
        options: [
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.MWLS_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Miscellaneous Water Level Sag',
            [types_1.LanguageCodes.ES]: 'Nivel del Agua',
            [types_1.LanguageCodes.FR]: 'Caractéristiques diverses – Niveau d’eau',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }, { attributes: { use } }) => {
            if ((0, types_1.isPacpLeveeOrDam)(use)) {
                return {};
            }
            else {
                if (percentage <= 30) {
                    return {
                        structure: 2,
                    };
                }
                else if (percentage <= 50) {
                    return {
                        structure: 3,
                    };
                }
                else if (percentage <= 75) {
                    return {
                        structure: 4,
                    };
                }
                else {
                    return { structure: 5 };
                }
            }
        },
        description: 'Roots Tap Lateral',
        options: [
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.DFC_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Deformed Flexible Creasing',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Déformation – Conduite flexible – Pliée',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, { attributes: { use } }) => {
            if ((0, types_1.isPacpLeveeOrDam)(use)) {
                return {
                    structure: 4,
                };
            }
            else {
                return {
                    structure: 5,
                };
            }
        },
        description: 'Roots Tap Lateral',
        options: [
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.DFE_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Deformed Flexible Elliptical',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Déformation – Conduite flexible – Ovale',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }, { attributes: { use } }) => {
            if ((0, types_1.isPacpLeveeOrDam)(use)) {
                if (percentage <= 5)
                    return { structure: 2 };
                else if (percentage <= 10)
                    return { structure: 3 };
                else if (percentage <= 20)
                    return { structure: 4 };
                else
                    return { structure: 5 };
            }
            else {
                return { structure: 3 };
            }
        },
        description: 'Roots Tap Lateral',
        options: [
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.DR_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Deformed Rigid',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Déformation – Conduite rigide',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }) => {
            if (percentage <= 5)
                return { structure: 4 };
            else
                return { service: 5 };
        },
        description: 'Roots Tap Lateral',
        options: [
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.LD_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Line Down',
            [types_1.LanguageCodes.ES]: 'Alineación Abajo',
            [types_1.LanguageCodes.FR]: 'Alignement – Vers le bas',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }, { attributes: { use } }) => {
            if ((0, types_1.isPacpLeveeOrDam)(use)) {
                return { service: 1 };
            }
            else {
                if (percentage <= 10)
                    return { service: 1 };
                else if (percentage <= 20)
                    return { service: 2 };
                else
                    return { service: 4 };
            }
        },
        description: 'Roots Tap Lateral',
        options: [
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.LL_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Line Left',
            [types_1.LanguageCodes.ES]: 'Alineación Izquierda',
            [types_1.LanguageCodes.FR]: 'Alignement – À gauche',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }, { attributes: { use } }) => {
            if ((0, types_1.isPacpLeveeOrDam)(use)) {
                return { service: 1 };
            }
            else {
                if (percentage <= 10)
                    return { service: 1 };
                else if (percentage <= 20)
                    return { service: 2 };
                else
                    return { service: 4 };
            }
        },
        description: 'Roots Tap Lateral',
        options: [
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.LLD_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Line Left Down',
            [types_1.LanguageCodes.ES]: 'Alineación Izquierda Abajo',
            [types_1.LanguageCodes.FR]: 'Alignement – À gauche vers le bas',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }, { attributes: { use } }) => {
            if ((0, types_1.isPacpLeveeOrDam)(use)) {
                return { service: 1 };
            }
            else {
                if (percentage <= 10)
                    return { service: 1 };
                else if (percentage <= 20)
                    return { service: 2 };
                else
                    return { service: 4 };
            }
        },
        description: 'Roots Tap Lateral',
        options: [
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.LLU_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Line Left Up',
            [types_1.LanguageCodes.ES]: 'Alineación Izquierda Arriba',
            [types_1.LanguageCodes.FR]: 'Alignement – À gauche vers le haut',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }, { attributes: { use } }) => {
            if ((0, types_1.isPacpLeveeOrDam)(use)) {
                return { service: 1 };
            }
            else {
                if (percentage <= 10)
                    return { service: 1 };
                else if (percentage <= 20)
                    return { service: 2 };
                else
                    return { service: 4 };
            }
        },
        description: 'Roots Tap Lateral',
        options: [
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.LR_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Line Right',
            [types_1.LanguageCodes.ES]: 'Alineación Derecha',
            [types_1.LanguageCodes.FR]: 'Alignement – À droite',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }, { attributes: { use } }) => {
            if ((0, types_1.isPacpLeveeOrDam)(use)) {
                return { service: 1 };
            }
            else {
                if (percentage <= 10)
                    return { service: 1 };
                else if (percentage <= 20)
                    return { service: 2 };
                else
                    return { service: 4 };
            }
        },
        description: 'Roots Tap Lateral',
        options: [
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.LRD_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Line Right Down',
            [types_1.LanguageCodes.ES]: 'Alineación Derecha Abajo',
            [types_1.LanguageCodes.FR]: 'Alignement – À droite vers le bas',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }, { attributes: { use } }) => {
            if ((0, types_1.isPacpLeveeOrDam)(use)) {
                return { service: 1 };
            }
            else {
                if (percentage <= 10)
                    return { service: 1 };
                else if (percentage <= 20)
                    return { service: 2 };
                else
                    return { service: 4 };
            }
        },
        description: 'Roots Tap Lateral',
        options: [
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.LRU_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Line Right Up',
            [types_1.LanguageCodes.ES]: 'Alineación Derecha Arriba',
            [types_1.LanguageCodes.FR]: 'Alignement – À droite vers le haut',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }, { attributes: { use } }) => {
            if ((0, types_1.isPacpLeveeOrDam)(use)) {
                return { service: 1 };
            }
            else {
                if (percentage <= 10)
                    return { service: 1 };
                else if (percentage <= 20)
                    return { service: 2 };
                else
                    return { service: 4 };
            }
        },
        description: 'Roots Tap Lateral',
        options: [
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.LU_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Line Up',
            [types_1.LanguageCodes.ES]: 'Alineación Arriba',
            [types_1.LanguageCodes.FR]: 'Alignement – Vers le haut',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }, { attributes: { use } }) => {
            if ((0, types_1.isPacpLeveeOrDam)(use)) {
                return { service: 1 };
            }
            else {
                if (percentage <= 10)
                    return { service: 1 };
                else if (percentage <= 20)
                    return { service: 2 };
                else
                    return { service: 4 };
            }
        },
        description: 'Roots Tap Lateral',
        options: [
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.DI_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Brickwork Dropped Invert',
            [types_1.LanguageCodes.ES]: 'Solera Desplomada',
            [types_1.LanguageCodes.FR]: 'Conduite en brique – Affaissement du radier',
        },
        scoringFunction: () => ({ structure: 5 }),
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        description: 'Brickwork Dropped Invert',
        options: [
            {
                name: 'Size of gap',
                attribute: 'diameter',
                description: 'Size of gap in mm',
                type: 'distanceShort',
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.DTBI_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Deformed Brick Bulging Inverse Curvature',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Déformation – Brique – Gondolement courbure inverse',
        },
        scoringFunction: ({ attributes: { percentage = 0 } }, { attributes: { use } }) => {
            if ((0, types_1.isPacpLeveeOrDam)(use)) {
                if (percentage <= 10)
                    return { structure: 4 };
                else
                    return { service: 5 };
            }
            else {
                return { structure: 5 };
            }
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        description: 'Deformed Brick Bulging Inverse Curvature',
        options: [
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
            {
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                name: 'Fracture longitudinal starts at',
                attribute: 'angleAt',
                description: 'Clock reference the fractures are at',
                type: 'angle',
            },
        ],
    },
    {
        code: types_1.ObservationCode.IW_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Infiltration Weeper',
            [types_1.LanguageCodes.ES]: 'Infil. Lagrimeo',
            [types_1.LanguageCodes.FR]: 'Infiltration – Suintement',
        },
        scoringFunction: () => ({ service: 2 }),
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        description: 'Infiltration Weeper',
        options: [
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
        ],
    },
    {
        code: types_1.ObservationCode.RFJ_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Roots Fine Joint',
            [types_1.LanguageCodes.ES]: 'Raíces Finas en la Junta',
            [types_1.LanguageCodes.FR]: 'Racines – Radicelles – Joint',
        },
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    service: 2,
                };
            }
            return {
                service: 1,
            };
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        description: 'Roots Fine Joint',
        options: [
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
        ],
    },
    {
        code: types_1.ObservationCode.RPP_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Point Repair Patch',
            [types_1.LanguageCodes.ES]: 'Reparación con Parche',
            [types_1.LanguageCodes.FR]: 'Réparation ponctuelle – Rapiéçage',
        },
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    structure: 3,
                };
            }
            else
                return {};
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        description: 'Point Repair Patch',
        options: [
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                name: 'Point Repair Patch Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.RPPJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Point Repair Patch Joint',
                            [types_1.LanguageCodes.ES]: 'Reparación con Parche Junta',
                            [types_1.LanguageCodes.FR]: 'Réparation ponctuelle – Rapiéçage Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Point Repair Patch joint',
                    },
                ],
            },
        ],
    },
    {
        code: types_1.ObservationCode.RPPD_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Point Repair Patch Patch Defective',
            [types_1.LanguageCodes.ES]: 'Reparación con Parche Defectuoso',
            [types_1.LanguageCodes.FR]: 'Réparation ponctuelle – Rapiéçage – Défectueuse',
        },
        scoringFunction: () => ({ structure: 4 }),
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        description: 'Point Repair Patch Patch Defective',
        options: [
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
            {
                name: 'Point Repair Patch Patch Defective Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.RPPDJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Point Repair Patch Patch Defective Joint',
                            [types_1.LanguageCodes.ES]: 'Reparación con Parche Defectuoso Junta',
                            [types_1.LanguageCodes.FR]: 'Réparation ponctuelle – Rapiéçage – Défectueuse Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Point Repair Patch Patch Defective joint',
                    },
                ],
            },
        ],
    },
    {
        code: types_1.ObservationCode.MML_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Missing Mortar Large',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: '',
        },
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    structure: 5,
                };
            }
            return {
                structure: 3,
            };
        },
        projectTypes: [types_1.ProjectType.PACP],
        description: 'Missing Mortar Large',
        options: [
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
        ],
    },
    {
        code: types_1.ObservationCode.MMM_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Missing Mortar Medium',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: '',
        },
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    structure: 4,
                };
            }
            return {
                structure: 3,
            };
        },
        projectTypes: [types_1.ProjectType.PACP],
        description: 'Missing Mortar Medium',
        options: [
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
        ],
    },
    {
        code: types_1.ObservationCode.MMS_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Missing Mortar Small',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: '',
        },
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    structure: 3,
                };
            }
            return {
                structure: 2,
            };
        },
        projectTypes: [types_1.ProjectType.PACP],
        description: 'Missing Mortar Small',
        options: [
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
        ],
    },
    {
        code: types_1.ObservationCode.RFB_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Roots Fine Barrel',
            [types_1.LanguageCodes.ES]: 'Raíces Finas en el Barril',
            [types_1.LanguageCodes.FR]: 'Racines – Radicelles – Paroi',
        },
        scoringFunction: () => ({ structure: 2 }),
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        description: 'Roots Fine Barrel',
        options: [
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
        ],
    },
    {
        code: types_1.ObservationCode.MB_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Missing Brick',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: '',
        },
        scoringFunction: () => ({ structure: 4 }),
        projectTypes: [types_1.ProjectType.PACP],
        description: 'Missing Brick',
        options: [
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
        ],
    },
    {
        code: types_1.ObservationCode.RFC_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Roots Fine Connection',
            [types_1.LanguageCodes.ES]: 'Raíces Finas en la Conexión',
            [types_1.LanguageCodes.FR]: 'Racines – Radicelles – Raccordement',
        },
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    service: 2,
                };
            }
            return {
                service: 1,
            };
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        description: 'Roots Fine Connection',
        options: [
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
        ],
    },
    {
        code: types_1.ObservationCode.RFL_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Roots Fine Lateral',
            [types_1.LanguageCodes.ES]: 'Raíces Finas en la Acometida',
            [types_1.LanguageCodes.FR]: 'Racines – Radicelles – Branchement',
        },
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    service: 2,
                };
            }
            return {
                service: 1,
            };
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        description: 'Roots Fine Lateral',
        options: [
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
        ],
    },
    {
        code: types_1.ObservationCode.MMC_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Miscellaneous Material Change',
            [types_1.LanguageCodes.ES]: 'Cambio de Material',
            [types_1.LanguageCodes.FR]: 'Caractéristiques diverses – Changement de matériau',
        },
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        description: 'Roots Fine Lateral',
    },
    {
        code: types_1.ObservationCode.MGO_PACP,
        remarksRequired: true,
        name: {
            [types_1.LanguageCodes.EN]: 'Miscellaneous General Observation',
            [types_1.LanguageCodes.ES]: 'Observación General',
            [types_1.LanguageCodes.FR]: 'Caractéristiques diverses – Observation générale',
        },
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        description: 'Miscellaneous General Observation',
    },
    {
        code: types_1.ObservationCode.MSA_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Miscellaneous Survey Abandoned',
            [types_1.LanguageCodes.ES]: 'Inspección Abandonada',
            [types_1.LanguageCodes.FR]: 'Caractéristiques diverses – Inspection avortée',
        },
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    service: 4,
                };
            }
            else
                return {};
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        description: 'Miscellaneous Survey Abandoned',
    },
    {
        code: types_1.ObservationCode.MYN_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Miscellaneous Dye Test Not Visible',
            [types_1.LanguageCodes.ES]: 'Prueba con Colorante No Visible',
            [types_1.LanguageCodes.FR]: 'Caractéristiques diverses – Essai au colorant – Non visible',
        },
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        description: 'Miscellaneous Dye Test Not Visible',
    },
    {
        code: types_1.ObservationCode.MYV_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Miscellaneous Dye Test Visible',
            [types_1.LanguageCodes.ES]: 'Prueba con Colorante Visible',
            [types_1.LanguageCodes.FR]: 'Caractéristiques diverses – Essai au colorant – Visible',
        },
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        description: 'Roots Fine Lateral',
    },
    {
        code: types_1.ObservationCode.GTUJ_PACP,
        remarksRequired: true,
        name: {
            [types_1.LanguageCodes.EN]: 'Grout Test Unable to Test Joint',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: '',
        },
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        description: 'Roots Fine Lateral',
    },
    {
        code: types_1.ObservationCode.GTUL_PACP,
        remarksRequired: true,
        name: {
            [types_1.LanguageCodes.EN]: 'Grout Test Unable to Test Lateral',
            [types_1.LanguageCodes.ES]: 'Lechada Inviable Acometida',
            [types_1.LanguageCodes.FR]: 'Essai d’étanchéité – Impossible – Branchement',
        },
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        description: 'Roots Fine Lateral',
    },
    {
        code: types_1.ObservationCode.MCU_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Miscellaneous Camera Underwater',
            [types_1.LanguageCodes.ES]: 'Videocámara Sumergida',
            [types_1.LanguageCodes.FR]: 'Caractéristiques diverses – Caméra submergée',
        },
        scoringFunction: () => ({ service: 4 }),
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        description: 'Miscellaneous Camera Underwater',
        options: [
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.MLC_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Miscellaneous Lining Change',
            [types_1.LanguageCodes.ES]: 'Cambio de Revestimiento',
            [types_1.LanguageCodes.FR]: 'Caractéristiques diverses – Changement de revêtement',
        },
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        description: 'Miscellaneous Lining Change',
        options: [
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.MGP_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Miscellaneous General Photograph',
            [types_1.LanguageCodes.ES]: 'Fotografía General',
            [types_1.LanguageCodes.FR]: 'Caractéristiques diverses – Photographie générale',
        },
        scoringFunction: () => ({}),
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        description: 'Miscellaneous General Photograph',
        options: [
            {
                required: false,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
        ],
    },
    {
        code: types_1.ObservationCode.MWL_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Miscellaneous Water Level',
            [types_1.LanguageCodes.ES]: 'Nivel del Agua',
            [types_1.LanguageCodes.FR]: 'Caractéristiques diverses – Niveau d’eau',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Miscellaneous Water Level',
        options: [
            {
                required: false,
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.MWM_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Miscellaneous Water Mark',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Miscellaneous Water Mark',
        options: [
            {
                required: false,
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.X_PACP,
        remarksRequired: true,
        name: {
            [types_1.LanguageCodes.EN]: 'Collapse',
            [types_1.LanguageCodes.ES]: 'Colapso En Tubería',
            [types_1.LanguageCodes.FR]: 'Effondrement',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ structure: 5 }),
        description: 'Collapse',
        options: [
            {
                required: false,
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.RBJ_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Roots Ball Joint',
            [types_1.LanguageCodes.ES]: 'Bola de Raíces en la Junta',
            [types_1.LanguageCodes.FR]: 'Racines – Écran – Joint',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ service: 4 }),
        description: 'Roots Ball Joint',
        options: [
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
            {
                required: false,
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
        ],
    },
    {
        code: types_1.ObservationCode.RMJ_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Roots Medium Joint',
            [types_1.LanguageCodes.ES]: 'Raíces Medianas en la Junta',
            [types_1.LanguageCodes.FR]: 'Racines – Moyennes – Joint',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    service: 4,
                };
            }
            return {
                service: 3,
            };
        },
        description: 'Roots Medium Joint',
        options: [
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
            {
                required: false,
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
        ],
    },
    {
        code: types_1.ObservationCode.RTJ_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Roots Tap Joint',
            [types_1.LanguageCodes.ES]: 'Raíces Pivotantes en la Junta',
            [types_1.LanguageCodes.FR]: 'Racines – Cordon – Joint',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    service: 3,
                };
            }
            return {
                service: 2,
            };
        },
        description: 'Roots Tap Joint',
        options: [
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
            {
                required: false,
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
            {
                required: true,
                name: 'Start/end clock reference',
                attribute: 'angle',
                description: 'Angle break starts and ends',
                type: 'angleRange',
            },
        ],
    },
    {
        code: types_1.ObservationCode.RPL_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Point Repair Liner',
            [types_1.LanguageCodes.ES]: 'Reparación Revest. Loc.',
            [types_1.LanguageCodes.FR]: 'Réparation ponctuelle – Revêtement',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Point Repair Liner',
        options: [
            {
                name: 'Point Repair Liner Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.RPLJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Point Repair Liner Joint',
                            [types_1.LanguageCodes.ES]: 'Reparación Revest. Loc. Junta',
                            [types_1.LanguageCodes.FR]: 'Réparation ponctuelle – Revêtement Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Point Repair Liner Joint',
                    },
                ],
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.RPLD_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Point Repair Liner Defective',
            [types_1.LanguageCodes.ES]: 'Reparación Revest. Loc. Defectuoso',
            [types_1.LanguageCodes.FR]: 'Réparation ponctuelle – Revêtement – Défectueuse',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ structure: 4 }),
        description: 'Point Repair Liner Defective',
        options: [
            {
                name: 'Point Repair Liner Defective Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.RPLDJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Point Repair Liner Defective Joint',
                            [types_1.LanguageCodes.ES]: 'Reparación Revest. Loc. Defectuoso Junta',
                            [types_1.LanguageCodes.FR]: 'Réparation ponctuelle – Revêtement – Défectueuse Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Point Repair Liner Defective Joint',
                    },
                ],
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.RPR_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Point Repair Replacement',
            [types_1.LanguageCodes.ES]: 'Reparación Tubería Reemplazada',
            [types_1.LanguageCodes.FR]: 'Réparation ponctuelle – Remplacement',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    structure: 3,
                };
            }
            else
                return {};
        },
        description: 'Point Repair Replacement',
        options: [
            {
                name: 'Point Repair Replacement Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.RPRJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Point Repair Replacement Joint',
                            [types_1.LanguageCodes.ES]: 'Reparación Tubería Reemplazada Junta',
                            [types_1.LanguageCodes.FR]: 'Réparation ponctuelle – Remplacement Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Point Repair Replacement Joint',
                    },
                ],
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.RPRD_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Point Repair Replacement Defective',
            [types_1.LanguageCodes.ES]: 'Tubería Reemplazada Defectuosa',
            [types_1.LanguageCodes.FR]: 'Réparation ponctuelle – Remplacement – Défectueuse',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ structure: 4 }),
        description: 'Point Repair Replacement Defective',
        options: [
            {
                name: 'Point Repair Replacement Defective Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.RPRDJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Point Repair Replacement Defective Joint',
                            [types_1.LanguageCodes.ES]: 'Tubería Reemplazada Defectuosa Junta',
                            [types_1.LanguageCodes.FR]: 'Réparation ponctuelle – Remplacement – Défectueuse Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Point Repair Replacement Defective Joint',
                    },
                ],
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.RPZ_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Point Repair Other',
            [types_1.LanguageCodes.ES]: 'Reparación Otra',
            [types_1.LanguageCodes.FR]: 'Réparation ponctuelle – Autre',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Point Repair Other',
        options: [
            {
                name: 'Point Repair Other Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.RPZJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Point Repair Other Joint',
                            [types_1.LanguageCodes.ES]: 'Reparación Otra Junta',
                            [types_1.LanguageCodes.FR]: 'Réparation ponctuelle – Autre Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Point Repair Other Joint',
                    },
                ],
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.RPZD_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Point Repair Other Defective',
            [types_1.LanguageCodes.ES]: 'Reparación Otra Defectuosa',
            [types_1.LanguageCodes.FR]: 'Réparation ponctuelle – Autre – Défectueuse',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ structure: 4 }),
        description: 'Point Repair Other Defective',
        options: [
            {
                name: 'Point Repair Other Defective Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.RPZDJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Point Repair Other Defective Joint',
                            [types_1.LanguageCodes.ES]: 'Reparación Otra Defectuosa Junta',
                            [types_1.LanguageCodes.FR]: 'Réparation ponctuelle – Autre – Défectueuse Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Point Repair Other Defective Joint',
                    },
                ],
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.SZ_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Point Repair Other Defective',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Point Repair Other Defective',
        options: [
            {
                name: 'Point Repair Other Defective Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.SZJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Point Repair Other Defective Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: '',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Point Repair Other Defective Joint',
                    },
                ],
            },
            {
                required: false,
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.VC_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Vermin Cockroach',
            [types_1.LanguageCodes.ES]: 'Alimañas Cucaracha',
            [types_1.LanguageCodes.FR]: 'Vermine – Coquerelles',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ service: 1 }),
        description: 'Point Repair Other Defective',
        options: [
            {
                name: 'Vermin Cockroach Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.VCJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Vermin Cockroach Joint',
                            [types_1.LanguageCodes.ES]: 'Alimañas Cucaracha Junta',
                            [types_1.LanguageCodes.FR]: 'Vermine – Coquerelles Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Vermin Cockroach Joint',
                    },
                ],
            },
        ],
    },
    {
        code: types_1.ObservationCode.JAL_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Joint Angular Large',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    structure: 5,
                };
            }
            return {
                structure: 4,
            };
        },
        description: 'Joint Angular Large',
        options: [
            {
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Value 2',
                attribute: 'value2',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.JAM_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Joint Angular Medium',
            [types_1.LanguageCodes.ES]: 'Junta Angular M',
            [types_1.LanguageCodes.FR]: 'Joint en angle – Moyen',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    structure: 5,
                };
            }
            return {
                structure: 3,
            };
        },
        description: 'Joint Angular Medium',
        options: [
            {
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Value 2',
                attribute: 'value2',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.JAS_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Joint Angular Small',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Joint en angle – Faible',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    structure: 4,
                };
            }
            else
                return {};
        },
        description: 'Joint Angular Small',
        options: [
            {
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Value 2',
                attribute: 'value2',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.JOL_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Joint Offset Large',
            [types_1.LanguageCodes.ES]: 'Junta Desplazada L',
            [types_1.LanguageCodes.FR]: 'Joint décalé – Important',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    structure: 5,
                };
            }
            return {
                structure: 4,
            };
        },
        description: 'Joint Offset Large',
        options: [
            {
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Value 2',
                attribute: 'value2',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.JOLD_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Joint Offset Large Defective',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Joint décalé – Important – Défectueux',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    structure: 5,
                    service: 5,
                };
            }
            return {
                structure: 4,
            };
        },
        description: 'Joint Offset Large Defective',
        options: [
            {
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Value 2',
                attribute: 'value2',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.JOM_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Joint Offset Medium',
            [types_1.LanguageCodes.ES]: 'Junta Desplazada M',
            [types_1.LanguageCodes.FR]: 'Joint décalé – Moyen',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    structure: 5,
                };
            }
            return {
                structure: 3,
            };
        },
        description: 'Joint Offset Medium',
        options: [
            {
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Value 2',
                attribute: 'value2',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.JOMD_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Joint Offset Medium Defective',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Joint décalé – Moyen – Défectueux',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    structure: 5,
                    service: 4,
                };
            }
            return {
                structure: 3,
            };
        },
        description: 'Joint Offset Medium Defective',
        options: [
            {
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Value 2',
                attribute: 'value2',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.JOS_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Joint Offset Small',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Joint décalé – Faible',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    structure: 4,
                };
            }
            else
                return {};
        },
        description: 'Joint Offset Small',
        options: [
            {
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Value 2',
                attribute: 'value2',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.JOSD_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Joint Offset Small Defective',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: 'Joint décalé – Faible – Défectueux',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    structure: 5,
                };
            }
            else
                return {};
        },
        description: 'Joint Offset Small Defective',
        options: [
            {
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Value 2',
                attribute: 'value2',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.JSL_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Joint Separated Large',
            [types_1.LanguageCodes.ES]: 'Junta Separada L',
            [types_1.LanguageCodes.FR]: 'Joint ouvert – Important',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    structure: 5,
                };
            }
            else
                return {
                    structure: 4,
                };
        },
        description: 'Joint Separated Large',
        options: [
            {
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Value 2',
                attribute: 'value2',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.JSM_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Joint Separated Medium',
            [types_1.LanguageCodes.ES]: 'Junta Separada M',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    structure: 5,
                };
            }
            else
                return {
                    structure: 3,
                };
        },
        description: 'Joint Separated Medium',
        options: [
            {
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Value 2',
                attribute: 'value2',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.JSS_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Joint Separated Small',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    structure: 5,
                };
            }
            else
                return {};
        },
        description: 'Joint Separated Small',
        options: [
            {
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Value 2',
                attribute: 'value2',
                description: 'Value 2 in mm',
                type: 'distanceShort',
            },
            {
                name: 'Continuous',
                attribute: 'continuous',
                description: 'Continuous',
                type: 'continuous',
            },
        ],
    },
    {
        code: types_1.ObservationCode.MJL_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Miscellaneous Pipe Joint Length Change',
            [types_1.LanguageCodes.ES]: 'Cambio Longitud de Junta',
            [types_1.LanguageCodes.FR]: 'Caractéristiques diverses – Changement de longueur du tuyau',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Miscellaneous Pipe Joint Length Change',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
        ],
    },
    {
        code: types_1.ObservationCode.GTFJ_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Grout Test Fail Joint',
            [types_1.LanguageCodes.ES]: 'Lechada Fallida Junta',
            [types_1.LanguageCodes.FR]: 'Essai d’étanchéité – Échoué – Joint',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Grout Test Fail Joint',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Value 2',
                attribute: 'value2',
                description: 'Value 2 measured in mm',
                type: 'distanceShort',
            },
        ],
    },
    {
        code: types_1.ObservationCode.GTFL_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Grout Test Fail Lateral',
            [types_1.LanguageCodes.ES]: 'Lechada Fallida Acometida',
            [types_1.LanguageCodes.FR]: 'Essai d’étanchéité – Échoué – Branchement',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Grout Test Fail Lateral',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Value 2',
                attribute: 'value2',
                description: 'Value 2 measured in mm',
                type: 'distanceShort',
            },
        ],
    },
    {
        code: types_1.ObservationCode.MSC_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Miscellaneous Shape/Size Change',
            [types_1.LanguageCodes.ES]: 'Cambio de Perfil y/o Tamaño',
            [types_1.LanguageCodes.FR]: 'Caractéristiques diverses – Changement de forme / dimension',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Miscellaneous Shape/Size Change',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Value 2',
                attribute: 'value2',
                description: 'Value 2 measured in mm',
                type: 'distanceShort',
            },
        ],
    },
    {
        code: types_1.ObservationCode.TB_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Tap Break-in/Hammer',
            [types_1.LanguageCodes.ES]: 'Acometida Rotura',
            [types_1.LanguageCodes.FR]: 'Raccordement – À la masse',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Tap Break-in/Hammer',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Angle at',
                attribute: 'angleAt',
                description: 'The clock reference of the observation',
                type: 'angle',
            },
            {
                name: 'Tap Break-in/Hammer Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.TBJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Tap Break-in/Hammer Joint',
                            [types_1.LanguageCodes.ES]: 'Acometida Rotura Joint',
                            [types_1.LanguageCodes.FR]: 'Raccordement – À la masse joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Tap Break-in/Hammer on joint',
                    },
                ],
            },
        ],
    },
    {
        code: types_1.ObservationCode.TBA_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Tap Break-in Activity',
            [types_1.LanguageCodes.ES]: 'Acometida Rotura Actividad',
            [types_1.LanguageCodes.FR]: 'Raccordement – À la masse – Actif',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Tap Break-in Activity',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Angle at',
                attribute: 'angleAt',
                description: 'The clock reference of the observation',
                type: 'angle',
            },
            {
                name: 'Tap Break-in Activity Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.TBAJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Tap Break-in Activity Joint',
                            [types_1.LanguageCodes.ES]: 'Acometida Rotura Actividad Junta',
                            [types_1.LanguageCodes.FR]: 'Raccordement – À la masse – Actif Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Tap Break-in Activity on joint',
                    },
                ],
            },
        ],
    },
    {
        code: types_1.ObservationCode.TBB_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Tap Break-in Abandoned',
            [types_1.LanguageCodes.ES]: 'Acometida Rotura Abandonada',
            [types_1.LanguageCodes.FR]: 'Raccordement – À la masse – Abandonné',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Tap Break-in Abandoned',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Angle at',
                attribute: 'angleAt',
                description: 'The clock reference of the observation',
                type: 'angle',
            },
            {
                name: 'Tap Break-in Abandoned Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.TBBJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Tap Break-in Abandoned Joint',
                            [types_1.LanguageCodes.ES]: 'Acometida Rotura Abandonada Junta',
                            [types_1.LanguageCodes.FR]: 'Raccordement – À la masse – Abandonné Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Tap Break-in Abandoned on joint',
                    },
                ],
            },
        ],
    },
    {
        code: types_1.ObservationCode.TBC_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Tap Break-in Capped',
            [types_1.LanguageCodes.ES]: 'Acometida Rotura Tapada',
            [types_1.LanguageCodes.FR]: 'Raccordement – À la masse – Obturé',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Tap Break-in Capped',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Angle at',
                attribute: 'angleAt',
                description: 'The clock reference of the observation',
                type: 'angle',
            },
            {
                name: 'Tap Break-in Capped Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.TBCJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Tap Break-in Capped Joint',
                            [types_1.LanguageCodes.ES]: 'Acometida Rotura Tapada Junta',
                            [types_1.LanguageCodes.FR]: 'Raccordement – À la masse – Obturé Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Tap Break-in Capped on joint',
                    },
                ],
            },
        ],
    },
    {
        code: types_1.ObservationCode.TF_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Tap Factory',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.PACP],
        scoringFunction: () => ({}),
        description: 'Tap Factory',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Angle at',
                attribute: 'angleAt',
                description: 'The clock reference of the observation',
                type: 'angle',
            },
            {
                name: 'Tap Factory Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.TFJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Tap Factory Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: '',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP],
                        description: 'Tap Factory on joint',
                    },
                ],
            },
        ],
    },
    {
        code: types_1.ObservationCode.TFA_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Tap Factory Activity',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.PACP],
        scoringFunction: () => ({}),
        description: 'Tap Factory Activity',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Angle at',
                attribute: 'angleAt',
                description: 'The clock reference of the observation',
                type: 'angle',
            },
            {
                name: 'Tap Factory Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.TFAJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Tap Factory Activity Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: '',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP],
                        description: 'Tap Factory Activity on joint',
                    },
                ],
            },
        ],
    },
    {
        code: types_1.ObservationCode.TFB_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Tap Factory Abandoned',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.PACP],
        scoringFunction: () => ({}),
        description: 'Tap Factory Abandoned',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Angle at',
                attribute: 'angleAt',
                description: 'The clock reference of the observation',
                type: 'angle',
            },
            {
                name: 'Tap Factory Abandoned Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.TFBJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Tap Factory Abandoned Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: '',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP],
                        description: 'Tap Factory Abandoned on joint',
                    },
                ],
            },
        ],
    },
    {
        code: types_1.ObservationCode.TFC_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Tap Factory Capped',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.PACP],
        scoringFunction: () => ({}),
        description: 'Tap Factory Capped',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Angle at',
                attribute: 'angleAt',
                description: 'The clock reference of the observation',
                type: 'angle',
            },
            {
                name: 'Tap Factory Capped Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.TFCJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Tap Factory Capped Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: '',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP],
                        description: 'Tap Factory Capped on joint',
                    },
                ],
            },
        ],
    },
    {
        code: types_1.ObservationCode.TR_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Tap Factory Rehabilitated',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.PACP],
        scoringFunction: () => ({}),
        description: 'Tap Factory Rehabilitated',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Angle at',
                attribute: 'angleAt',
                description: 'The clock reference of the observation',
                type: 'angle',
            },
            {
                name: 'Tap Factory Rehabilitated Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.TRJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Tap Factory Rehabilitated Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: '',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP],
                        description: 'Tap Factory Rehabilitated on joint',
                    },
                ],
            },
        ],
    },
    {
        code: types_1.ObservationCode.TRA_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Tap Factory Rehabilitated Activity',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.PACP],
        scoringFunction: () => ({}),
        description: 'Tap Factory Rehabilitated Activity',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Angle at',
                attribute: 'angleAt',
                description: 'The clock reference of the observation',
                type: 'angle',
            },
            {
                name: 'Tap Factory Rehabilitated Activity Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.TRAJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Tap Factory Rehabilitated Activity Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: '',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP],
                        description: 'Tap Factory Rehabilitated Activity on joint',
                    },
                ],
            },
        ],
    },
    {
        code: types_1.ObservationCode.TRB_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Tap Factory Rehabilitated Abandoned',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.PACP],
        scoringFunction: () => ({}),
        description: 'Tap Factory Rehabilitated Abandoned',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Angle at',
                attribute: 'angleAt',
                description: 'The clock reference of the observation',
                type: 'angle',
            },
            {
                name: 'Tap Factory Rehabilitated Abandoned Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.TRBJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Tap Factory Rehabilitated Abandoned Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: '',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP],
                        description: 'Tap Factory Rehabilitated Abandoned on joint',
                    },
                ],
            },
        ],
    },
    {
        code: types_1.ObservationCode.TRC_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Tap Rehabilitated Capped',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.PACP],
        scoringFunction: () => ({}),
        description: 'Tap Rehabilitated Capped',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Angle at',
                attribute: 'angleAt',
                description: 'The clock reference of the observation',
                type: 'angle',
            },
            {
                name: 'Tap Rehabilitated Capped Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.TRCJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Tap Rehabilitated Capped Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: '',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP],
                        description: 'Tap Rehabilitated Capped on joint',
                    },
                ],
            },
        ],
    },
    {
        code: types_1.ObservationCode.TS_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Tap Saddle',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.PACP],
        scoringFunction: () => ({}),
        description: 'Tap Saddle',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Angle at',
                attribute: 'angleAt',
                description: 'The clock reference of the observation',
                type: 'angle',
            },
            {
                name: 'Tap Saddle Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.TSJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Tap Saddle Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: '',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP],
                        description: 'Tap Saddle on joint',
                    },
                ],
            },
        ],
    },
    {
        code: types_1.ObservationCode.TSA_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Tap Saddle Activity',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.PACP],
        scoringFunction: () => ({}),
        description: 'Tap Saddle Activity',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Angle at',
                attribute: 'angleAt',
                description: 'The clock reference of the observation',
                type: 'angle',
            },
            {
                name: 'Tap Saddle Activity Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.TSAJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Tap Saddle Activity Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: '',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP],
                        description: 'Tap Saddle Activity on joint',
                    },
                ],
            },
        ],
    },
    {
        code: types_1.ObservationCode.TSB_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Tap Saddle Abandoned',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.PACP],
        scoringFunction: () => ({}),
        description: 'Tap Saddle Abandoned',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Angle at',
                attribute: 'angleAt',
                description: 'The clock reference of the observation',
                type: 'angle',
            },
            {
                name: 'Tap Saddle Abandoned Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.TSBJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Tap Saddle Abandoned Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: '',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP],
                        description: 'Tap Saddle Abandoned on joint',
                    },
                ],
            },
        ],
    },
    {
        code: types_1.ObservationCode.TSC_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Tap Saddle Capped',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.PACP],
        scoringFunction: () => ({}),
        description: 'Tap Saddle Capped',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Angle at',
                attribute: 'angleAt',
                description: 'The clock reference of the observation',
                type: 'angle',
            },
            {
                name: 'Tap Saddle Capped Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.TSCJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Tap Saddle Capped Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: '',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP],
                        description: 'Tap Saddle Capped on joint',
                    },
                ],
            },
        ],
    },
    {
        code: types_1.ObservationCode.TSD_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Tap Saddle Defective',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.PACP],
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    service: 4,
                };
            }
            return {
                service: 3,
            };
        },
        description: 'Tap Saddle Defective',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Angle at',
                attribute: 'angleAt',
                description: 'The clock reference of the observation',
                type: 'angle',
            },
            {
                name: 'Tap Saddle Defective Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.TSDJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Tap Saddle Defective Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: '',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP],
                        description: 'Tap Saddle Defective on joint',
                    },
                ],
            },
        ],
    },
    {
        code: types_1.ObservationCode.TFD_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Tap Factory Defective',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.PACP],
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    service: 4,
                };
            }
            return {
                service: 3,
            };
        },
        description: 'Tap Factory Defective',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Value 2',
                attribute: 'value2',
                description: 'Value 2 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Angle at',
                attribute: 'angleAt',
                description: 'The clock reference of the observation',
                type: 'angle',
            },
            {
                name: 'Tap Factory Defective Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.TFDJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Tap Factory Defective Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: '',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP],
                        description: 'Tap Factory Defective on joint',
                    },
                ],
            },
        ],
    },
    {
        code: types_1.ObservationCode.TBD_PACP,
        remarksRequired: true,
        name: {
            [types_1.LanguageCodes.EN]: 'Tap Break-in/Hammer Defective',
            [types_1.LanguageCodes.ES]: 'Acometida Rotura Defectuosa',
            [types_1.LanguageCodes.FR]: 'Raccordement – À la masse – Défectueux',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    service: 4,
                };
            }
            return {
                service: 3,
            };
        },
        description: 'Tap Break-in/Hammer Defective',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Value 2',
                attribute: 'value2',
                description: 'Value 2 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Angle at',
                attribute: 'angleAt',
                description: 'The clock reference of the observation',
                type: 'angle',
            },
            {
                name: 'Tap Break-in/Hammer Defective Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.TBDJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Tap Break-in/Hammer Defective Joint',
                            [types_1.LanguageCodes.ES]: 'Acometida Rotura Defectuosa Junta',
                            [types_1.LanguageCodes.FR]: 'Raccordement – À la masse – Défectueux Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Tap Break-in/Hammer Defective on joint',
                    },
                ],
            },
        ],
    },
    {
        code: types_1.ObservationCode.TRD_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Tap Rehabilitated Defective',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.PACP],
        scoringFunction: (_, attrs) => {
            if ((0, types_1.isPacpLeveeOrDam)(attrs.attributes.use)) {
                return {
                    service: 4,
                };
            }
            return {
                service: 3,
            };
        },
        description: 'Tap Rehabilitated Defective',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                name: 'Value 2',
                attribute: 'value2',
                description: 'Value 2 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Angle at',
                attribute: 'angleAt',
                description: 'The clock reference of the observation',
                type: 'angle',
            },
            {
                name: 'Tap Rehabilitated Defective Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.TRDJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Tap Rehabilitated Defective Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: '',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP],
                        description: 'Tap Rehabilitated Defective on joint',
                    },
                ],
            },
        ],
    },
    {
        code: types_1.ObservationCode.TBI_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Tap Break-in Intruding',
            [types_1.LanguageCodes.ES]: 'Acometida Rotura Penetrante',
            [types_1.LanguageCodes.FR]: 'Raccordement – À la masse – Pénétrant',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: ({ attributes: { percentage = 0 } }, { attributes: { use } }) => {
            if ((0, types_1.isPacpLeveeOrDam)(use)) {
                return {
                    service: 3,
                };
            }
            else {
                if (percentage <= 10)
                    return { service: 2 };
                else if (percentage <= 20)
                    return { service: 3 };
                else if (percentage <= 30)
                    return { service: 4 };
                else
                    return { service: 5 };
            }
        },
        description: 'Tap Break-in Intruding',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Value 2',
                attribute: 'value2',
                description: 'Value 2 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Angle at',
                attribute: 'angleAt',
                description: 'The clock reference of the observation',
                type: 'angle',
            },
            {
                name: 'Tap Break-in Intruding Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.TBIJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Tap Break-in Intruding Joint',
                            [types_1.LanguageCodes.ES]: 'Acometida Rotura Penetrante Junta',
                            [types_1.LanguageCodes.FR]: 'Raccordement – À la masse – Pénétrant Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Tap Break-in Intruding on joint',
                    },
                ],
            },
        ],
    },
    {
        code: types_1.ObservationCode.TFI_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Tap Factory Intruding',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.PACP],
        scoringFunction: ({ attributes: { percentage = 0 } }, { attributes: { use } }) => {
            if ((0, types_1.isPacpLeveeOrDam)(use)) {
                return {
                    service: 3,
                };
            }
            else {
                if (percentage <= 10)
                    return { service: 2 };
                else if (percentage <= 20)
                    return { service: 3 };
                else if (percentage <= 30)
                    return { service: 4 };
                else
                    return { service: 5 };
            }
        },
        description: 'Tap Factory Intruding',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Value 2',
                attribute: 'value2',
                description: 'Value 2 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Angle at',
                attribute: 'angleAt',
                description: 'The clock reference of the observation',
                type: 'angle',
            },
            {
                name: 'Tap Factory Intruding Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.TFIJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Tap Factory Intruding Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: '',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Tap Factory Intruding on joint',
                    },
                ],
            },
        ],
    },
    {
        code: types_1.ObservationCode.TRI_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Tap Rehabilitated Intruding',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.PACP],
        scoringFunction: ({ attributes: { percentage = 0 } }, { attributes: { use } }) => {
            if ((0, types_1.isPacpLeveeOrDam)(use)) {
                return {
                    service: 3,
                };
            }
            else {
                if (percentage <= 10)
                    return { service: 2 };
                else if (percentage <= 20)
                    return { service: 3 };
                else if (percentage <= 30)
                    return { service: 4 };
                else
                    return { service: 5 };
            }
        },
        description: 'Tap Rehabilitated Intruding Defective',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Value 2',
                attribute: 'value2',
                description: 'Value 2 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Angle at',
                attribute: 'angleAt',
                description: 'The clock reference of the observation',
                type: 'angle',
            },
            {
                name: 'Tap Rehabilitated Intruding Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.TRIJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Tap Rehabilitated Intruding Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: '',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP],
                        description: 'Tap Rehabilitated Intruding on joint',
                    },
                ],
            },
        ],
    },
    {
        code: types_1.ObservationCode.TSI_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Tap Saddle Intruding',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.PACP],
        scoringFunction: ({ attributes: { percentage = 0 } }, { attributes: { use } }) => {
            if ((0, types_1.isPacpLeveeOrDam)(use)) {
                return {
                    service: 3,
                };
            }
            else {
                if (percentage <= 10)
                    return { service: 2 };
                else if (percentage <= 20)
                    return { service: 3 };
                else if (percentage <= 30)
                    return { service: 4 };
                else
                    return { service: 5 };
            }
        },
        description: 'Tap Saddle Intruding',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Value 2',
                attribute: 'value2',
                description: 'Value 2 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                name: 'Angle at',
                attribute: 'angleAt',
                description: 'The clock reference of the observation',
                type: 'angle',
            },
            {
                name: 'Tap Saddle Intruding Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.TSIJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Tap Saddle Intruding Joint',
                            [types_1.LanguageCodes.ES]: '',
                            [types_1.LanguageCodes.FR]: '',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP],
                        description: 'Tap Saddle Intruding on joint',
                    },
                ],
            },
        ],
    },
    {
        code: types_1.ObservationCode.GRT_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Grout at a Location',
            [types_1.LanguageCodes.ES]: 'Lechada Puntual',
            [types_1.LanguageCodes.FR]: 'Colmatage localisé',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Grout at a Location',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
        ],
    },
    {
        code: types_1.ObservationCode.GTPJ_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Grout Test Pass Joint',
            [types_1.LanguageCodes.ES]: 'Lechada Satisfactoria Junta',
            [types_1.LanguageCodes.FR]: 'Essai d’étanchéité – Réussi – Joint',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Grout Test Pass Joint',
        options: [
            {
                required: true,
                name: 'Value 2',
                attribute: 'value2',
                description: 'Value 2 measured in mm',
                type: 'distanceShort',
            },
        ],
    },
    {
        code: types_1.ObservationCode.GTPL_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Grout Test Pass Lateral',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Grout Test Pass Lateral',
        options: [
            {
                required: true,
                name: 'Value 2',
                attribute: 'value2',
                description: 'Value 2 measured in mm',
                type: 'distanceShort',
            },
        ],
    },
    {
        code: types_1.ObservationCode.VR_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Vermin Rat',
            [types_1.LanguageCodes.ES]: 'Alimañas Rata',
            [types_1.LanguageCodes.FR]: 'Vermine – Rat',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ service: 2 }),
        description: 'Vermin Rat',
        options: [
            {
                name: 'Vermin Rat Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.VRJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Vermin Rat Joint',
                            [types_1.LanguageCodes.ES]: 'Alimañas Rata Junta',
                            [types_1.LanguageCodes.FR]: 'Vermine – Rat Joint',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Vermin Rat on Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
        ],
    },
    {
        code: types_1.ObservationCode.VZ_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Vermin Other',
            [types_1.LanguageCodes.ES]: 'Alimañas Otros',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
        scoringFunction: () => ({ service: 1 }),
        description: 'Vermin Other',
        options: [
            {
                name: 'Vermin Other Joint',
                type: 'code',
                schema: [
                    {
                        code: types_1.ObservationCode.VZJ_PACP,
                        name: {
                            [types_1.LanguageCodes.EN]: 'Vermin Other Joint',
                            [types_1.LanguageCodes.ES]: 'Alimañas Otros Junta',
                            [types_1.LanguageCodes.FR]: '',
                        },
                        scoringFunction: () => ({}),
                        projectTypes: [types_1.ProjectType.PACP, types_1.ProjectType.LACP],
                        description: 'Vermin Other on Joint',
                    },
                ],
            },
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
        ],
    },
    {
        code: types_1.ObservationCode.FBEMD_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Fitting Mitered Bend Down',
            [types_1.LanguageCodes.ES]: 'Curva de Inglete Abajo',
            [types_1.LanguageCodes.FR]: 'Raccord – Coude à arêtes – Vers le bas',
        },
        projectTypes: [types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Fitting Mitered Bend Down',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.FBEML_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Fitting Mitered Bend Left',
            [types_1.LanguageCodes.ES]: 'Curva de Inglete Izquierda',
            [types_1.LanguageCodes.FR]: 'Raccord – Coude à arêtes – Vers la gauche',
        },
        projectTypes: [types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Fitting Mitered Bend Left',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.FBEMLD_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Fitting Mitered Bend Left Down',
            [types_1.LanguageCodes.ES]: 'Curva de Inglete Izquierda Abajo',
            [types_1.LanguageCodes.FR]: 'Raccord – Coude à arêtes – Vers la gauche et le bas',
        },
        projectTypes: [types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Fitting Mitered Bend Left Down',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.FBEMLU_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Fitting Mitered Bend Left Up',
            [types_1.LanguageCodes.ES]: 'Curva de Inglete Izquierda Arriba',
            [types_1.LanguageCodes.FR]: 'Raccord – Coude à arêtes – Vers la gauche et le haut',
        },
        projectTypes: [types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Fitting Mitered Bend Left Up',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.FBEMR_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Fitting Mitered Bend Right',
            [types_1.LanguageCodes.ES]: 'Curva de Inglete Derecha',
            [types_1.LanguageCodes.FR]: 'Raccord – Coude à arêtes – Vers la droite',
        },
        projectTypes: [types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Fitting Mitered Bend Right',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.FBEMRD_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Fitting Mitered Bend Right Down',
            [types_1.LanguageCodes.ES]: 'Curva de Inglete Derecha Abajo',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Fitting Mitered Bend Right Down',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.FBEMRU_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Fitting Mitered Bend Right Up',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Fitting Mitered Bend Right Up',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.FBEMU_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Fitting Mitered Bend Up',
            [types_1.LanguageCodes.ES]: 'Curva de Inglete Arriba',
            [types_1.LanguageCodes.FR]: 'Raccord – Coude à arêtes – Vers le haut',
        },
        projectTypes: [types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Fitting Mitered Bend Up',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.FBESD_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Fitting Sweep Bend Down',
            [types_1.LanguageCodes.ES]: 'Curva de Barrido Abajo',
            [types_1.LanguageCodes.FR]: 'Raccord – Coude arrondi – Vers le bas',
        },
        projectTypes: [types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Fitting Sweep Bend Down',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.FBESL_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Fitting Sweep B end Left',
            [types_1.LanguageCodes.ES]: 'Curva de Barrido Izquierda',
            [types_1.LanguageCodes.FR]: 'Raccord – Coude arrondi – Vers la gauche',
        },
        projectTypes: [types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Fitting Sweep B end Left',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.FBESLD_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Fitting Sweep Bend Left Down',
            [types_1.LanguageCodes.ES]: 'Curva Barrido Izquierda Abajo',
            [types_1.LanguageCodes.FR]: 'Raccord – Coude arrondi – Vers la gauche et le bas',
        },
        projectTypes: [types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Fitting Sweep Bend Left Down',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.FBESLU_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Fitting Sweep Bend Left Up',
            [types_1.LanguageCodes.ES]: 'Curva Barrido Izquierda Arriba',
            [types_1.LanguageCodes.FR]: 'Raccord – Coude arrondi – Vers la gauche et le haut',
        },
        projectTypes: [types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Fitting Sweep Bend Left Up',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.FBESR_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Fitting Sweep Bend Right',
            [types_1.LanguageCodes.ES]: 'Curva Barrido Derecha',
            [types_1.LanguageCodes.FR]: 'Raccord – Coude arrondi – Vers la droite',
        },
        projectTypes: [types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Fitting Sweep Bend Right',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.FBESRD_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Fitting Sweep Bend Right Down',
            [types_1.LanguageCodes.ES]: 'Curva Barrido Derecha Abajo',
            [types_1.LanguageCodes.FR]: 'Raccord – Coude arrondi – Vers la droite et le bas',
        },
        projectTypes: [types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Fitting Sweep Bend Right Down',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.FBESRU_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Fitting Sweep Bend Right Up',
            [types_1.LanguageCodes.ES]: 'Curva Barrido Derecha Arriba',
            [types_1.LanguageCodes.FR]: 'Raccord – Coude arrondi – Vers la droite et le haut',
        },
        projectTypes: [types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Fitting Sweep Bend Right Up',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.FBESU_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Fitting Sweep Bend Up',
            [types_1.LanguageCodes.ES]: 'Curva Barrido Arriba',
            [types_1.LanguageCodes.FR]: 'Raccord – Coude arrondi – Vers le haut',
        },
        projectTypes: [types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Fitting Sweep Bend Up',
        options: [
            {
                required: true,
                name: 'Value 1',
                attribute: 'value1',
                description: 'Value 1 measured in mm',
                type: 'distanceShort',
            },
            {
                required: true,
                attribute: 'percentage',
                name: 'Cross sectional loss',
                description: 'Cross sectional loss as a percentage',
                type: 'percentage',
            },
        ],
    },
    {
        code: types_1.ObservationCode.FCA_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Fitting Cap',
            [types_1.LanguageCodes.ES]: 'Accesorio Tapón',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Fitting Cap',
    },
    {
        code: types_1.ObservationCode.FCAF_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Fitting Cap Fitting',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Fitting Cap Fitting',
    },
    {
        code: types_1.ObservationCode.FCAS_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Fitting Cap Seal',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: '',
        },
        projectTypes: [types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Fitting Cap Seal',
    },
    {
        code: types_1.ObservationCode.FRV_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Fitting Vertical Riser',
            [types_1.LanguageCodes.ES]: 'Accesorio Tubo Montante',
            [types_1.LanguageCodes.FR]: 'Raccord – Accès vertical',
        },
        projectTypes: [types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Fitting Vertical Riser',
    },
    {
        code: types_1.ObservationCode.FTDS_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Fitting Double Sweep Tee',
            [types_1.LanguageCodes.ES]: 'Accesorio Tee Doble Barrido',
            [types_1.LanguageCodes.FR]: 'Raccord – En T double',
        },
        projectTypes: [types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Fitting Double Sweep Tee',
    },
    {
        code: types_1.ObservationCode.FTS_PACP,
        name: {
            [types_1.LanguageCodes.EN]: 'Fitting Sweep Tee',
            [types_1.LanguageCodes.ES]: 'Accesorio Tee Barrido',
            [types_1.LanguageCodes.FR]: 'Raccord – En T',
        },
        projectTypes: [types_1.ProjectType.LACP],
        scoringFunction: () => ({}),
        description: 'Fitting Sweep Tee',
    },
];
