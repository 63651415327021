import { ReportError } from '@drainify/utils';
import { Appear, Icons, Tooltip } from 'preshape';
import React from 'react';

type Props = Icons.Props & {
  error: null | ReportError;
};

const ReportErrorIndicator = ({ error }: Props) => {
  return (
    <Tooltip
      backgroundColor={error ? 'negative-shade-4' : 'positive-shade-4'}
      content={error?.message || 'No errors'}
      placement="top"
      maxWidth="15rem"
      textColor="light-shade-1"
    >
      {(props) => (
        <Appear>
          {error ? (
            <Icons.AlertTriangle
              {...props}
              size="1.25rem"
              textColor="negative-shade-4"
            />
          ) : (
            <Icons.Check
              {...props}
              size="1.25rem"
              textColor="positive-shade-4"
            />
          )}
        </Appear>
      )}
    </Tooltip>
  );
};

export default ReportErrorIndicator;
