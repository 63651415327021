import {
  isCircular,
  Ownership,
  Section,
  SectionNodePosition,
  SectionPostBody,
} from '@drainify/types';
import { getLandOwnershipCodeSchema, isSection } from '@drainify/utils';
import { Text } from 'preshape';
import React from 'react';
import DataTable from '../DataTable/DataTable';
import DataTableGroup from '../DataTable/DataTableGroup';
import DataTableItem from '../DataTable/DataTableItem';
import Distance from '../DistanceInput/Distance';
import FlowSymbol from '../FlowInput/FlowSymbol';
import NodeView from '../Node/NodeView';
import { useHasPermission } from '../Permissions/Permissions';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import SummaryCard from '../SummaryCard/SummaryCard';
import SummaryCardSection from '../SummaryCard/SummaryCardSection';
import { useLanguageContext } from '../Usage/LanguageProvider';
import SectionAttributeView from './SectionAttribute/SectionAttributeView';

type Props = {
  onNodeClick?: (nodeUid: string) => void;
  section?: Section | SectionPostBody;
};

const SectionSummary = ({ onNodeClick, section }: Props) => {
  const { language } = useLanguageContext();
  const { reportEditor, editSection } = useReportEditorContext();
  const hasUpdateProjectPermission = useHasPermission('projects:update');
  const sectionName =
    section && reportEditor.getSectionName(reportEditor.toSection(section));
  const { activeBooking } = useProjectContext();
  const error = reportEditor.fullValidateSection(section);
  const disabled = !!activeBooking?.completedAt;

  const editSectionStep = (step: string) => {
    if (!hasUpdateProjectPermission) {
      return undefined;
    }
    if (disabled) {
      return undefined;
    }

    return () => {
      if (isSection(section)) {
        editSection(section.uid, step);
      } else {
        editSection(null, step);
      }
    };
  };

  return (
    <SummaryCard>
      <SummaryCardSection
        error={error?.name}
        title="Section name"
        value={sectionName}
      >
        <Text>{sectionName}</Text>
      </SummaryCardSection>

      <SummaryCardSection
        error={error?.nodeStartUid}
        padding="x0"
        onEdit={editSectionStep('nodeStartUid')}
        title="Start Node"
      >
        <DataTable>
          <DataTableGroup>
            <DataTableItem
              onClick={
                onNodeClick && section?.nodeStartUid
                  ? () =>
                      section.nodeStartUid && onNodeClick(section.nodeStartUid)
                  : undefined
              }
            >
              <NodeView
                node={reportEditor.getNodeByUid(section?.nodeStartUid)}
                nodePosition={SectionNodePosition.START}
              />
            </DataTableItem>
          </DataTableGroup>
        </DataTable>
      </SummaryCardSection>

      {isSection(section) && (
        <SummaryCardSection
          error={error?.['additionalPoints']}
          title="Pipe route"
          onEdit={editSectionStep('additionalPoints')}
          value={section?.attributes?.pipeType}
        >
          {section?.additionalPoints && section.additionalPoints.length > 0
            ? 'Curved'
            : 'Straight'}
        </SummaryCardSection>
      )}

      <SummaryCardSection
        error={error?.['attributes.pipeType']}
        title="Pipe type"
        onEdit={editSectionStep('attributes.pipeType')}
        value={section?.attributes?.pipeType}
      >
        {section?.attributes.pipeType}
      </SummaryCardSection>

      <SummaryCardSection
        error={error?.nodeEndUid}
        padding="x0"
        onEdit={editSectionStep('nodeEndUid')}
        title="Finish Node"
      >
        <DataTable>
          <DataTableGroup>
            <DataTableItem
              onClick={
                onNodeClick && section?.nodeEndUid
                  ? () => section.nodeEndUid && onNodeClick(section.nodeEndUid)
                  : undefined
              }
            >
              <NodeView
                node={reportEditor.getNodeByUid(section?.nodeEndUid)}
                nodePosition={SectionNodePosition.END}
              />
            </DataTableItem>
          </DataTableGroup>
        </DataTable>
      </SummaryCardSection>

      <SummaryCardSection
        error={error?.['attributes.flow']}
        onEdit={editSectionStep('attributes.flow')}
        title="Flow direction"
        value={section?.attributes?.flow}
      >
        <FlowSymbol value={section?.attributes.flow} withText />
      </SummaryCardSection>

      {isSection(section) && (
        <SummaryCardSection
          error={error?.['attributes.length']}
          onEdit={editSectionStep('attributes.length')}
          title="Length"
          value={section?.attributes.length}
        >
          <Distance type="long" value={section?.attributes.length} />
        </SummaryCardSection>
      )}

      {isCircular(section?.attributes.shape) && (
        <SummaryCardSection
          error={error?.['attributes.diameter']}
          onEdit={editSectionStep('attributes.diameter')}
          title="Diameter"
          value={section?.attributes.diameter}
        >
          <Distance type="short" value={section?.attributes.diameter} />
        </SummaryCardSection>
      )}

      {section?.attributes.shape && !isCircular(section?.attributes.shape) && (
        <>
          <SummaryCardSection
            error={error?.['attributes.height']}
            onEdit={editSectionStep('attributes.height')}
            title="Height"
            value={section?.attributes.height}
          >
            <Distance type="short" value={section?.attributes.height} />
          </SummaryCardSection>

          <SummaryCardSection
            error={error?.['attributes.width']}
            onEdit={editSectionStep('attributes.width')}
            title="Width"
            value={section?.attributes.width}
          >
            <Distance type="short" value={section?.attributes.width} />
          </SummaryCardSection>
        </>
      )}

      <SummaryCardSection
        error={error?.['attributes.material']}
        onEdit={editSectionStep('attributes.material')}
        title="Material"
        value={section?.attributes.material}
      >
        <SectionAttributeView
          attribute="material"
          code={section?.attributes.material}
        />
      </SummaryCardSection>

      <SummaryCardSection
        error={error?.['attributes.shape']}
        onEdit={editSectionStep('attributes.shape')}
        title="Shape"
        value={section?.attributes.shape}
      >
        <SectionAttributeView
          attribute="shape"
          code={section?.attributes.shape}
        />
      </SummaryCardSection>

      <SummaryCardSection
        error={error?.['attributes.use']}
        onEdit={editSectionStep('attributes.use')}
        title="Use"
        value={section?.attributes.use}
      >
        <SectionAttributeView attribute="use" code={section?.attributes.use} />
      </SummaryCardSection>

      <SummaryCardSection
        error={error?.['attributes.sectionOwnership']}
        onEdit={editSectionStep('attributes.sectionOwnership')}
        title="Section ownership"
        value={section?.attributes?.sectionOwnership}
      >
        {section?.attributes.sectionOwnership &&
          Object.keys(Ownership).includes(
            section.attributes.sectionOwnership
          ) &&
          getLandOwnershipCodeSchema(section.attributes.sectionOwnership).name[
            language
          ]}
      </SummaryCardSection>
    </SummaryCard>
  );
};

export default SectionSummary;
