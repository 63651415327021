import { ObservationCode } from '@drainify/types';
import { getFullFilePath } from '@drainify/utils';
import { Box, Button, ButtonProps, Buttons, Icons, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, Text } from 'preshape';
import React from 'react';
import { isMobile } from '../../utils/client';
import { MOBILE_APP_WIDTH } from '../App/App';
import ImageFromUrl from '../Image/ImageFromUrl';
import PercentageInput from '../PercentageInput/PercentageInput';
import { useHasPermission } from '../Permissions/Permissions';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';

type Props = ButtonProps & {
  onCreate?: (observationUid: string) => void;
  inspectionUid?: string;
  imageUrl?: string;
  initialWL?: boolean;
};

const ObservationCreateButton = ({
  onCreate,
  inspectionUid,
  imageUrl,
  initialWL,
  ...rest
}: Props) => {
  const { createNewObservation, reportEditor } = useReportEditorContext();
  const hasUpdateProjectPermission = useHasPermission('projects:update');

  const hasObservations =
    reportEditor.getInspectionObservations(inspectionUid).length > 0;
  const { activeBooking } = useProjectContext();
  const [initialWaterLevelPercentage, setInitialWaterLevelPercentage] =
    React.useState(0);
  const [startInspectionModalVisible, setStartInspectionModalVisible] =
    React.useState(false);

  if (!hasUpdateProjectPermission || !inspectionUid) {
    return null;
  }

  const addInitalWaterLevel = () => {
    const { uid } = reportEditor.addObservation({
      attributes: {
        percentage: initialWaterLevelPercentage,
      },
      code: [ObservationCode.WL],
      distance: 0,
      inspectionMarker: 'start',
      imageUrl: imageUrl,
      inspectionUid: inspectionUid
    });
    onCreate?.(uid)
  }

  return (
    <>
      <Button
        variant="secondary"
        {...rest}
        disabled={!!activeBooking?.completedAt}
        color="accent"
        gap="x2"
        onClick={() => hasObservations && !initialWL ? createNewObservation(inspectionUid, onCreate) : setStartInspectionModalVisible(true)}
        type="button"
      >
        <Text>{hasObservations ? 'Observation' : 'Start'}</Text>
        <Icons.Plus size="1rem" />
      </Button>
      <Modal
        animation="FadeSlideUp"
        margin="x4"
        onClose={() => setStartInspectionModalVisible(false)}
        visible={startInspectionModalVisible}
        maxWidth={MOBILE_APP_WIDTH}
      >
        <ModalHeader>
          <ModalTitle>Start inspection (Water level)</ModalTitle>
        </ModalHeader>

        <ModalBody>
          <Box alignChildrenHorizontal="middle" flex='horizontal' gap='x1'>
            {

              !isMobile() && imageUrl && <Box width='500px' height='400px' >
                <ImageFromUrl src={getFullFilePath(imageUrl)} width='500px' height='400px' />
                </Box>
            }
            <PercentageInput
              name={'water level'}
              onChange={setInitialWaterLevelPercentage}
              value={initialWaterLevelPercentage}
            />
          </Box>
        </ModalBody>
        <ModalFooter>
          <Buttons flex='horizontal' alignChildrenHorizontal='middle'>
          <Button variant='primary' color='accent' onClick={() => {
            addInitalWaterLevel()
            setStartInspectionModalVisible(false)
          }}>Confirm</Button>

          </Buttons>
        </ModalFooter>

        </Modal>
      </>
  );
};

export default ObservationCreateButton;
