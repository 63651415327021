import React from 'react';
import OrgSummary from '../../../components/Org/OrgSummary';
import useMeOrg from '../../../hooks/useMeOrg';

const OrgProfilePage = () => {
  const { query } = useMeOrg();

  return <OrgSummary org={query.data} />;
};

export default OrgProfilePage;
