import { Invite } from '@drainify/types';
import React from 'react';
import DataTableItem from '../DataTable/DataTableItem';
import InviteView from '../Invite/InviteView';
import { useHasPermission } from '../Permissions/Permissions';

type Props = {
  invite: Invite;
  onClick: () => void;
};

const MemberInviteDataTableRow = ({ invite, onClick }: Props) => {
  const hasUpdateMemberPermission = useHasPermission('invites:update');

  return (
    <DataTableItem onClick={hasUpdateMemberPermission ? onClick : undefined}>
      <InviteView invite={invite} />
    </DataTableItem>
  );
};

export default MemberInviteDataTableRow;
