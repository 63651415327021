import { Box, BoxProps } from 'preshape';
import React from 'react';

const Divider = ({
  borderColor = 'background-shade-3',
  borderSize = 'x1',
  margin = 'x1',
  ...props
}: BoxProps) => {
  return (
    <Box
      {...props}
      borderColor={borderColor}
      borderSize={borderSize}
      borderTop
      margin={margin}
    />
  );
};

export default Divider;
