import { Observation } from '@drainify/types';
import { getFullFilePath, ReportElementType } from '@drainify/utils';
import { Box, Icons, Text } from 'preshape';
import React, { PropsWithChildren } from 'react';
import ImageFromUrl from '../../../Image/ImageFromUrl';
import { useMapLayersContext } from '../../../Map/MapLayers/MapLayers';
import MapMarkerToolbar from '../../../Map/MapMarker/MapMarkerToolbar';
import ObservationView from '../../../Observations/ObservationView';
import { useReportMapContext } from '../ReportMapProvider';

const ReportMapObservationTooltip = ({
  children,
  observation,
  showOnHover,
}: PropsWithChildren<{
  observation: Observation;
}> & { showOnHover?: boolean }) => {
  const { toggleFocusObservation, focusedElementUid } = useReportMapContext();
  const { isLayerVisible } = useMapLayersContext();

  // showOnHover is a stand in for 'is customer'

  const visible = isLayerVisible(
    ReportElementType.OBSERVATION,
    observation.uid
  );

  return (
    <MapMarkerToolbar
      showOnHover={showOnHover}
      enabled={visible}
      information={
        <Box flex="vertical" gap="x3">
          <ObservationView observation={observation} />{' '}
          {observation.imageUrl && (
            <ImageFromUrl
              src={getFullFilePath(observation.imageUrl)}
              height="150px"
            />
          )}
          {observation.remarks && <Text>{observation.remarks}</Text>}
        </Box>
      }
      tools={
        showOnHover
          ? undefined
          : [
              {
                icon:
                  focusedElementUid === observation.uid ? (
                    <Icons.EyeOff />
                  ) : (
                    <Icons.Eye />
                  ),
                onClick: () => toggleFocusObservation(observation.uid),
              },
            ]
      }
    >
      {children}
    </MapMarkerToolbar>
  );
};

export default ReportMapObservationTooltip;
