import { Box, BoxProps, Button, Buttons, Icons, Text } from 'preshape';
import React, { PropsWithChildren } from 'react';
import { usePageContext } from '../../../components/Page/PageContext';
import PlaceholderBlock from '../../../components/Placeholders/PlaceholderBlock';

type Props = BoxProps & {
  action?: JSX.Element;
  title?: string;
};

const PageHeader = ({
  action,
  title: titleProps,
}: PropsWithChildren<Props>) => {
  const {
    backToLink,
    backToText,
    hasData,
    isLoading,
    isLoadingError,
    title: titleContext,
  } = usePageContext();

  const hasAction = action && !isLoading && !isLoadingError && hasData;
  const hasBackLink = backToLink && backToText;
  const title = (isLoadingError && 'Error') || titleProps || titleContext;

  return (
    <Box>
      <Box backgroundColor="accent-shade-3" textColor="white" padding="x1">
        {(hasAction || hasBackLink) && (
          <Box alignChildrenVertical="middle" flex="horizontal">
            <Box grow>
              {hasBackLink && (
                <Buttons>
                  <Button
                    paddingHorizontal="x1"
                    size="x2"
                    to={backToLink}
                    variant="tertiary"
                    uppercase={false}
                    textColor="white"
                    textColorActive="white"
                    textColorHover="white"
                  >
                    <Icons.ChevronLeft />
                    <Text>{backToText}</Text>
                  </Button>
                </Buttons>
              )}
            </Box>

            {hasAction && <Box>{action}</Box>}
          </Box>
        )}
      </Box>
      <Box padding="x4">
        {isLoading && !titleProps ? (
          <PlaceholderBlock />
        ) : typeof title === 'string' ? (
          <>
            <Text size="x6" strong>
              {title}
            </Text>
          </>
        ) : (
          title
        )}
      </Box>
    </Box>
  );
};

export default PageHeader;
