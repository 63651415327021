import { Node, NodePostBody, PlanType } from '@drainify/types';
import { getFullFilePath, isNode } from '@drainify/utils';
import { DateTime } from 'luxon';
import { Box, Button, Modal, ModalBody, ModalHeader, Text } from 'preshape';
import React from 'react';
import { isDesktop, isMobile } from '../../utils/client';
import { FULL_SCREEN_MODAL_WIDTH } from '../App/App';
import Distance from '../DistanceInput/Distance';
import ImageFromFile from '../Image/ImageFromFile';
import ImageFromUrl from '../Image/ImageFromUrl';
import What3Words from '../Location/What3Words';
import { useHasPermission } from '../Permissions/Permissions';
import { useProjectContext } from '../Project/ProjectProvider';
import Remarks from '../RemarksInput/Remarks';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import SummaryCard from '../SummaryCard/SummaryCard';
import SummaryCardSection from '../SummaryCard/SummaryCardSection';
import NodeCodeView from './NodeCodeView';

type Props = {
  node?: Node | NodePostBody;
  imageFile?: File;
  withMap?: boolean;
};

const NodeSummary = ({ imageFile, node }: Props) => {
  const { editNode, reportEditor } = useReportEditorContext();
  const { activeBooking, isCustomerView } = useProjectContext();
  const hasUpdateProjectPermission = useHasPermission('projects:update');
  const nodeName = reportEditor.getNodeName(node);
  const error = reportEditor.validateNode(node);
  const [showFullscreenImage, setShowFullscreenImage] = React.useState(false)

  const editNodeStep = (step: string) => {
    if (!hasUpdateProjectPermission) {
      return undefined;
    }
    if (!!activeBooking?.completedAt) {
      return undefined;
    }
    return () => {
      if (isNode(node)) {
        editNode(node.uid, step);
      } else {
        editNode(null, step);
      }
    };
  };

  return (
    <SummaryCard>
      <Text weak size="x1">
        {node &&
          'Created at ' +
            DateTime.fromISO(node?.createdAt).toFormat('DDDD @ HH:mm')}
      </Text>
      <SummaryCardSection
        error={error?.name}
        onEdit={editNodeStep('name')}
        title="Node name"
        value={nodeName}
      >
        <Text>{nodeName}</Text>
      </SummaryCardSection>
      <SummaryCardSection
        error={error?.code}
        onEdit={editNodeStep('code')}
        title="Type of node"
        value={node?.code}
      >
        <NodeCodeView code={node?.code} />
      </SummaryCardSection>

      {(imageFile || !node?.imageUrl) && (
        <SummaryCardSection
          error={error?.['imageUrl']}
          onEdit={editNodeStep('imageUrl')}
          title="Image"
          value={imageFile}
        >
          <ImageFromFile file={imageFile} height="200px" />
        </SummaryCardSection>
      )}

      {node?.imageUrl && !imageFile && (
        <SummaryCardSection
          error={error?.['imageUrl']}
          onEdit={editNodeStep('imageUrl')}
          title="Image"
          value={node.imageUrl}
        >
          <ImageFromUrl src={getFullFilePath(node.imageUrl)} height="200px" />
          {
            node?.imageUrl && isDesktop() && isCustomerView && 
            
            <Box flex='horizontal' padding='x3' alignChildrenHorizontal='middle'>
              <Button variant='tertiary' onClick={() => setShowFullscreenImage(true)}>Enlarge</Button>
            </Box>
          }
          {
            isCustomerView && showFullscreenImage && <Modal visible={true}
              maxWidth={FULL_SCREEN_MODAL_WIDTH}
              onClose={() => setShowFullscreenImage(false)}
              overlayBackgroundCloseOnClick={true}
            >
              <ModalHeader>{reportEditor.getNodeName(node)}</ModalHeader>
              <ModalBody>
                <ImageFromUrl
                  src={getFullFilePath(node?.imageUrl)}
                  height="300px"
                  width="800px"
                />

                <NodeCodeView code={node?.code}/>

              </ModalBody>

            </Modal>
          }
        </SummaryCardSection>
      )}

      <SummaryCardSection
        error={error?.depth}
        onEdit={editNodeStep('depth')}
        title="Depth"
        value={node?.depth}
      >
        <Distance value={node?.depth} type="long" />
      </SummaryCardSection>

      {(reportEditor.report.planType === PlanType.GOOGLE_MAPS ||
        (reportEditor.report.planType === PlanType.UPLOADED_PLAN &&
          reportEditor.report.plan?.isToScale)) && (
        <SummaryCardSection
          error={error?.point}
          title="Location"
          onEdit={isMobile() ? editNodeStep('point') : undefined}
          value={node?.point}
        >
          <What3Words point={node?.point} margin="x4" />
        </SummaryCardSection>
      )}

      <SummaryCardSection
        error={error?.remarks}
        onEdit={editNodeStep('remarks')}
        title="Remarks"
        value={node?.remarks}
      >
        <Remarks value={node?.remarks} />
      </SummaryCardSection>
    </SummaryCard>
  );
};

export default NodeSummary;
