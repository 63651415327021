import { Project } from '@drainify/types';
import React from 'react';
import useBookingSearch from '../../hooks/useBookingSearch';
import useMeOrg from '../../hooks/useMeOrg';
import BookingView from '../Booking/BookingView';
import DataQueryTable from '../DataTable/DataQueryTable';
import DataTableGroup from '../DataTable/DataTableGroup';
import DataTableItem from '../DataTable/DataTableItem';
import ProjectView from './ProjectView';

type Props = {
  onPointerEnterProject?: (project: Project) => void;
  onPointerLeaveProject?: () => void;
  onClickProject?: (project: Project) => void;
  searchString?: string;
};

const ProjectList = ({
  onPointerEnterProject,
  onPointerLeaveProject,
  onClickProject,
  searchString,
}: Props) => {
  const { query: queryOrg } = useMeOrg();
  const { query: queryBookings } = useBookingSearch(searchString);

  return (
    <DataQueryTable
      entity="projects"
      placeholder={<ProjectView />}
      query={[queryOrg, queryBookings]}
    >
      <DataTableGroup>
        {queryBookings?.data?.data.map((booking) => {
          return (
            <DataTableItem
              key={booking.uid}
              onClick={() => onClickProject?.(booking.project)}
              onPointerEnter={
                onPointerEnterProject &&
                (() => onPointerEnterProject(booking.project))
              }
              onPointerLeave={onPointerLeaveProject}
              to={`/projects/${booking.project.uid}?activeBookingId=${booking.uid}`}
            >
              <BookingView booking={booking} />
            </DataTableItem>
          );
        })}
      </DataTableGroup>
    </DataQueryTable>
  );
};

export default ProjectList;
