"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDayRelative = void 0;
const luxon_1 = require("luxon");
const getDayRelative = (dateTime) => {
    const daysFromNow = dateTime
        .endOf('day')
        .diff(luxon_1.DateTime.now().endOf('day'), 'days').days;
    if (daysFromNow === 0)
        return 'Today';
    if (daysFromNow === 1)
        return 'Tomorrow';
    if (daysFromNow <= 6)
        return dateTime.toFormat('LLL dd');
    return dateTime.toLocaleString(luxon_1.DateTime.DATE_SHORT);
};
exports.getDayRelative = getDayRelative;
