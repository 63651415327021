import { isCircular, SectionAttributes } from '@drainify/types';
import { Text, Box, Button } from 'preshape';
import React from 'react';
import Distance from '../../../DistanceInput/Distance';
import SectionAttributeView from '../../../Section/SectionAttribute/SectionAttributeView';
import SummaryCard from '../../../SummaryCard/SummaryCard';
import SummaryCardSection from '../../../SummaryCard/SummaryCardSection';
import { useWizardContext } from '../../../Wizard/Wizard';

type Props = {
  sectionDefaults: Partial<SectionAttributes>;
  onClear: () => void;
};

const SiteSectionDefaultsSummary = ({ sectionDefaults, onClear }: Props) => {
  const { setActiveStepId } = useWizardContext();

  return (
    <SummaryCard>
      <SummaryCardSection title={'Use'} onEdit={() => setActiveStepId('use')}>
        <SectionAttributeView attribute="use" code={sectionDefaults.use} />
      </SummaryCardSection>
      <SummaryCardSection title="Shape" onEdit={() => setActiveStepId('shape')}>
        <SectionAttributeView attribute="shape" code={sectionDefaults.shape} />
      </SummaryCardSection>
      <SummaryCardSection
        title="Material"
        onEdit={() => setActiveStepId('material')}
      >
        <SectionAttributeView
          attribute="material"
          code={sectionDefaults.material}
        />
      </SummaryCardSection>

      {isCircular(sectionDefaults.shape) && (
        <SummaryCardSection
          title="Diameter"
          onEdit={() => setActiveStepId('diameter')}
        >
          <Box alignChildrenVertical="middle" flex="horizontal" gap="x2">
            <Box basis="0" grow>
              <Text size="x3" strong>
                {sectionDefaults?.diameter && (
                  <Distance type="short" value={sectionDefaults.diameter} />
                )}
              </Text>
            </Box>
          </Box>
        </SummaryCardSection>
      )}

      {sectionDefaults.shape !== undefined &&
        !isCircular(sectionDefaults.shape) && (
          <>
            <SummaryCardSection
              title="Width"
              onEdit={() => setActiveStepId('width')}
            >
              <Box alignChildrenVertical="middle" flex="horizontal" gap="x2">
                <Box basis="0" grow>
                  <Text size="x3" strong>
                    {sectionDefaults?.width && (
                      <Distance type="short" value={sectionDefaults.width} />
                    )}
                  </Text>
                </Box>
              </Box>
            </SummaryCardSection>

            <SummaryCardSection
              title="Height"
              onEdit={() => setActiveStepId('height')}
            >
              <Box alignChildrenVertical="middle" flex="horizontal" gap="x2">
                <Box basis="0" grow>
                  <Text size="x3" strong>
                    {sectionDefaults?.height && (
                      <Distance type="short" value={sectionDefaults.height} />
                    )}
                  </Text>
                </Box>
              </Box>
            </SummaryCardSection>
          </>
        )}

      <SummaryCardSection
        title="Section ownership"
        onEdit={() => setActiveStepId('sectionOwnership')}
      >
        <Box alignChildrenVertical="middle" flex="horizontal" gap="x2">
          <Box basis="0" grow>
            <Text size="x3" strong>
              {sectionDefaults?.sectionOwnership}
            </Text>
          </Box>
        </Box>
      </SummaryCardSection>

      <SummaryCardSection
        title="Pipe type"
        onEdit={() => setActiveStepId('pipeType')}
      >
        <Box alignChildrenVertical="middle" flex="horizontal" gap="x2">
          <Box basis="0" grow>
            <Text size="x3" strong>
              {sectionDefaults?.pipeType}
            </Text>
          </Box>
        </Box>
      </SummaryCardSection>
      <Button variant="tertiary" color="negative" onClick={onClear}>
        Clear site section defaults
      </Button>
    </SummaryCard>
  );
};

export default SiteSectionDefaultsSummary;
