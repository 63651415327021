import { FirebaseError } from 'firebase/app';
import {
  getAuth,
  confirmPasswordReset,
  checkActionCode,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import {
  Alert,
  Box,
  Buttons,
  Form,
  FormError,
  FormProvider,
  Icons,
  Input,
  InputLabel,
  Text,
  useUrlState,
} from 'preshape';
import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AsyncButton from '../components/AsyncButton/AsyncButton';
import usePasswordForgotForm from '../hooks/forms/usePasswordForgotForm';

const ResetPasswordPage = () => {
  const { search } = useLocation();
  const { oobCode } = useUrlState<{
    oobCode: string;
  }>({
    decoders: {
      oobCode: (v) => v,
    },
    defaults: {
      oobCode: undefined,
    },
    encoders: {
      oobCode: (v) => v,
    },
    search,
    onUpdateSearch: (search) => navigate({ search }),
    validators: {},
  });

  const [error, setError] = useState('');

  const navigate = useNavigate();
  const { form, isError, isLoading, isSuccess } = usePasswordForgotForm()

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    try {
      const {
        data: { email },
      } = await checkActionCode(getAuth(), oobCode);
      if (!email) {
        throw Error('Action code expired');
      }
      await confirmPasswordReset(getAuth(), oobCode, form.state.confirmNewPassword);
      await signInWithEmailAndPassword(getAuth(), email, form.state.confirmNewPassword);
      navigate('/');
    } catch (error) {
      if (error instanceof FirebaseError) {
        if (error.code === 'auth/internal-error') {
          setError('One time use code has expired');
        } 
      } else {
        setError('There was an issue. Please retry');
      }
    }
  };

  return (
    <FormProvider form={form} validateOnlyOneAtATime>
      <Box flex="vertical" grow>
        <Box
          alignChildren="middle"
          backgroundColor="background-shade-3"
          flex="vertical"
          grow
          padding="x12"
        >
          <Box maxWidth="400px">
            <Text align="middle" margin="x8" size="x6" strong>
              Forgot password
            </Text>
            <Form >

              <FormError name="newPassword" margin="x4">
                <InputLabel label="New password">
                  <Input
                    name="newPassword"
                    type="password"
                    autoComplete="new-password"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      form.setState((s) => ({ ...s, newPassword: e.target.value }))
                    }
                    value={form.state.newPassword}
                  />
                </InputLabel>
              </FormError>

              <FormError name="confirmNewPassword" margin="x4">
                <InputLabel label="Confirm new password">
                  <Input
                    name="confirmNewPassword"
                    type="password"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      form.setState((s) => ({
                        ...s,
                        confirmNewPassword: e.target.value,
                      }))
                    }
                    value={form.state.confirmNewPassword}
                  />
                </InputLabel>
              </FormError>
            </Form>
            <Buttons>
              <AsyncButton
                color="accent"
                basis="0"
                size="x3"
                grow
                disabled={form.hasError}
                isError={isError}
                isLoading={isLoading}
                isSuccess={isSuccess}
                onClick={handleSubmit}
                variant="primary"
              >
                <Box alignChildrenVertical="middle" flex="horizontal" gap="x2">
                  <Icons.Lock />
                  <Text>Update</Text>
                </Box>
              </AsyncButton>
            </Buttons>
            {
              error && <Alert backgroundColor='negative-shade-1' textColor='negative-shade-5' color='negative' padding='x2' flex='horizontal' alignChildrenHorizontal='middle'>
                <Text strong>
{error}
                </Text>
                </Alert>
            }
          </Box>
        </Box>
      </Box>
    </FormProvider>
  );
};

export default ResetPasswordPage;
