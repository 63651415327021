"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRepairMapForInspection = exports.getRepairableInspectionIds = exports.hasPotentialRepairs = void 0;
const observationSchema_1 = require("./observationSchema");
const hasPotentialRepairs = (reportEditor, activeBookingId) => {
    return (0, exports.getRepairableInspectionIds)(reportEditor, activeBookingId).length > 0;
};
exports.hasPotentialRepairs = hasPotentialRepairs;
const getRepairableInspectionIds = (reportEditor, activeBookingId) => {
    const inspections = reportEditor.getJobInspections(activeBookingId);
    const repairableInspections = new Set();
    for (const inspection of inspections) {
        for (const observation of reportEditor.getInspectionObservations(inspection.uid)) {
            for (const code of observation.code) {
                if ((0, observationSchema_1.getObservationSchema)(code).repairs !== undefined) {
                    repairableInspections.add(inspection.uid);
                }
            }
        }
    }
    return Array.from(repairableInspections);
};
exports.getRepairableInspectionIds = getRepairableInspectionIds;
const getRepairMapForInspection = (reportEditor, inspectionId) => {
    const observations = reportEditor.getInspectionObservations(inspectionId);
    const map = new Map();
    for (const o of observations) {
        for (const k of o.code) {
            const repairs = (0, observationSchema_1.getObservationSchema)(k).repairs || [];
            for (const repair of repairs) {
                if (repair) {
                    const value = map.get(repair) || [];
                    map.set(repair, [...value, o]);
                }
            }
        }
    }
    return map;
};
exports.getRepairMapForInspection = getRepairMapForInspection;
