import { Box, Icons, Motion, Text, Tooltip } from 'preshape';
import React from 'react';
import { v4 } from 'uuid';
import { useMapContext } from '../../../Map/Map';
import MapNotification from '../../../Map/MapNotification/MapNotification';
import { useProjectContext } from '../../../Project/ProjectProvider';
import { useReportEditorContext } from '../../ReportEditorProvider';
import useDrawMeasurementMap from '../ReportMapElements/useDrawMeasurementMap';
import { useReportMapContext } from '../ReportMapProvider';

const ReportMapPolygon = () => {
  const {
    addPointerDownListener,
    disableInteractivity,
    addPointerMoveListener,
    addPointerUpListener,
    enableInteractivity,
  } = useMapContext();
  const { activeBookingId } = useProjectContext();

  const { reportEditor } = useReportEditorContext();
  const [toggled, setToggled] = React.useState(false);
  const { focusDrawing } = useReportMapContext();
  const [startPoint, setStartPoint] = React.useState<GeoJSON.Point>();
  const [endPoint, setEndPoint] = React.useState<GeoJSON.Point>();
  const [staged, setStaged] = React.useState<GeoJSON.Point[]>([]);

  React.useEffect(() => {
    if (startPoint && endPoint) {
      setStaged([startPoint, endPoint]);
    } else {
      setStaged([]);
    }
  }, [startPoint, endPoint]);

  React.useEffect(() => {
    if (toggled) {
      return addPointerDownListener((point) => {
        setStartPoint(point);
        disableInteractivity();
      });
    }
  }, [toggled]);

  useDrawMeasurementMap({
    points: staged,
    visible: true,
  });

  React.useEffect(() => {
    if (toggled) {
      return addPointerMoveListener((point) => {
        setEndPoint(point);
      });
    }
  }, [toggled]);

  React.useEffect(() => {
    if (toggled && startPoint) {
      return addPointerUpListener((point) => {
        enableInteractivity();

        const { uid } = reportEditor.addDrawing({
          points: [
            { point: startPoint, uid: v4() },
            { point, uid: v4() },
          ],
          jobId: activeBookingId,
        });

        setStartPoint(undefined);
        setEndPoint(undefined);
        setToggled(false);
        focusDrawing(uid);
      });
    }
  }, [toggled, startPoint]);

  return (
    <>
      <Box flex="vertical" alignChildrenHorizontal="middle" gap="x1">
        <Box>
          <Tooltip
            content={toggled ? 'Cancel' : 'Start drawing polygon!'}
            trigger="hover"
            placement="top"
          >
            {(props) => (
              <Box
                {...props}
                shrink
                name={'Name'}
                backgroundColor={toggled ? 'accent-shade-3' : 'accent-shade-1'}
                style={{
                  cursor: 'pointer',
                }}
                padding="x3"
                borderRadius="10px"
                borderSize="x2"
                borderStyle="dashed"
                borderColor={'black'}
                textColor={toggled ? 'white' : 'black'}
                onClick={() => setToggled(!toggled)}
                title={'Draw'}
              >
                <Icons.Edit3
                  size="1.75rem"
                  fill={toggled ? 'white' : 'black'}
                />
              </Box>
            )}
          </Tooltip>
        </Box>
        <Text size="x1" uppercase strong>
          Polygon
        </Text>
      </Box>
      {toggled && (
        <MapNotification color="negative-shade-4" typePosition="bottom-right">
          <Motion
            initial={{ opacity: 0.1 }}
            animate={{ opacity: 1 }}
            transition={{
              repeatType: 'loop',
              repeat: Infinity,
              duration: 1,
              repeatDelay: 0.5,
            }}
          >
            <Text size="x4">Drawing polygon</Text>
          </Motion>
        </MapNotification>
      )}
    </>
  );
};

export default ReportMapPolygon;
