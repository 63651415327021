import { Ownership, PipeType, SectionMaterialCode, SectionShapeCode } from '@drainify/types';
import { getLandOwnershipCodeSchema, getMaterialSchema, getShapeSchema } from '@drainify/utils';
import { Box, Button, Buttons, CheckBox, Icons, InputLabel, Label, Modal, ModalBody, ModalFooter, ModalHeader, Table, TableBody, TableCell, TableRow, Text } from 'preshape';
import React from 'react';
import { FULL_SCREEN_MODAL_WIDTH } from '../../../App/App';
import { useLanguageContext } from '../../../Usage/LanguageProvider';
import { useReportEditorContext } from '../../ReportEditorProvider';

type Props = {
  inline?: boolean;
}

const ReportMapSidebarSectionsBulkUpdate = ({ inline }: Props) => {
  const [editOwnershipVisable, setEditOwnershipVisable] = React.useState(false)
  const [editPipeTypeVisable, setEditPipeTypeVisable] = React.useState(false)
  const [editShapeVisable, setEditShapeVisable] = React.useState(false)
  const [editMaterialVisable, setEditMaterialVisable] = React.useState(false)
  const [modalVisible, setModalVisible] = React.useState(false)
  const { language } = useLanguageContext();
  const [stagedOwnership, setStagedOwnership] = React.useState<Ownership>()
  const [stagedPipeType, setStagedPipeType] = React.useState<PipeType>()
  const [stagedShape, setStagedShape] = React.useState<SectionShapeCode>()
  const [stagedMaterial, setStagedMaterial] = React.useState<SectionMaterialCode>()
  const { reportEditor } = useReportEditorContext()

  React.useEffect(() => {
    setStagedOwnership(undefined)
    setStagedPipeType(undefined)
    setStagedShape(undefined)
    setStagedMaterial(undefined)
    // use: project?.siteDefaults?.section?.use,
    // material: project?.siteDefaults?.section?.material,
    // shape: project?.siteDefaults?.section?.shape,
    // diameter: project?.siteDefaults?.section?.diameter,
    // width: project?.siteDefaults?.section?.width,
    // height: project?.siteDefaults?.section?.height,
  }, [editOwnershipVisable, editPipeTypeVisable, editShapeVisable, editMaterialVisable])


  return (
    <>
      {
        inline ? <Button variant='tertiary' flex='horizontal' onClick={() => setModalVisible(true)} gap='x1' color='accent' alignChildrenVertical='end'>
          <Text>
            Update all sections

          </Text>
          <Icons.Upload />
        </Button> :
          <Box flex='vertical' gap='x2' alignChildrenHorizontal='middle'>
            <Button onClick={() => setModalVisible(true)} borderSize='x1'><Icons.Upload size='1.5rem' /></Button>
            <Text size='x2' strong>Site detail update</Text>
          </Box>

      }

      {
        modalVisible && <><Modal visible={true} maxWidth={FULL_SCREEN_MODAL_WIDTH} onClose={() => setModalVisible(false)}>
          <ModalHeader>Site detail update</ModalHeader>
          <ModalBody>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell> <Text> Section ownership </Text> </TableCell>
                  <TableCell flex='horizontal' alignChildrenHorizontal='middle'> <Button variant='tertiary' color='accent' onClick={() => setEditOwnershipVisable(true)}>
                    <Label>
                      {
                        reportEditor.report.sections.length > 0 &&
                          new Set(reportEditor.report.sections.map(section => section.attributes.sectionOwnership).filter(e => !!e)).size === 1 ?
                          getLandOwnershipCodeSchema(reportEditor.report.sections.map(section => section.attributes.sectionOwnership).filter(e => !!e)[0] as string).name[language] : 'Mixed'
                      }
                    </Label></Button> </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell> <Text>Pipe type</Text> </TableCell>
                  <TableCell flex='horizontal' alignChildrenHorizontal='middle'> <Button variant='tertiary' color='accent' onClick={() => setEditPipeTypeVisable(true)}>
                    <Label>
                      {
                        reportEditor.report.sections.length > 0 &&
                          new Set(reportEditor.report.sections.map(section => section.attributes.pipeType).filter(e => !!e)).size === 1 ?
                          reportEditor.report.sections.map(section => section.attributes.pipeType).filter(e => !!e)[0] : 'Mixed'
                      }
                    </Label>
                  </Button> </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell> <Text>Shape</Text> </TableCell>
                  <TableCell flex='horizontal' alignChildrenHorizontal='middle'> <Button variant='tertiary' color='accent' onClick={() => setEditShapeVisable(true)}>
                    <Label>
                      {
                        reportEditor.report.sections.length > 0 &&
                          new Set(reportEditor.report.sections.map(section => section.attributes.shape).filter(e => !!e)).size === 1 ?
                          getShapeSchema(reportEditor.report.sections.map(section => section.attributes.shape).filter(e => !!e)[0] as string).name[language] : 'Mixed'
                      }
                    </Label>
                  </Button> </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell> <Text>Material</Text> </TableCell>
                  <TableCell flex='horizontal' alignChildrenHorizontal='middle'> <Button variant='tertiary' color='accent' onClick={() => setEditMaterialVisable(true)}>
                    <Label>
                      {
                        reportEditor.report.sections.length > 0 &&
                          new Set(reportEditor.report.sections.map(section => section.attributes.material).filter(e => !!e)).size === 1 ?
                          getMaterialSchema(reportEditor.report.sections.map(section => section.attributes.material).filter(e => !!e)[0] as string).name[language] : 'Mixed'
                      }
                    </Label>
                  </Button> </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Box padding='x2'>
              <Text size='x1'>Manage common site details</Text>
              <Text size='x1'>Update here to apply to all sections/laterals</Text>
            </Box>
          </ModalBody>
        </Modal>


          <Modal visible={editOwnershipVisable} maxWidth={FULL_SCREEN_MODAL_WIDTH} onClose={() => setEditOwnershipVisable(false)}>
            <ModalHeader>Edit ownership</ModalHeader>
            <ModalBody>
              <InputLabel>
                {Object.values(Ownership).map((sectionOwnership) => (
                  <CheckBox
                    checked={sectionOwnership === stagedOwnership}
                    key={sectionOwnership}
                    onChange={() => setStagedOwnership(sectionOwnership)}
                    borderSize='x1'
                    margin="x1"
                  >
                    {getLandOwnershipCodeSchema(sectionOwnership).name[language]}
                  </CheckBox>
                ))}
              </InputLabel>
              <ModalFooter>
                <Buttons>
                  <Button
                    color="positive"
                    elevate={true}
                    disabled={stagedOwnership === undefined}
                    grow
                    onClick={() => {
                      reportEditor.report.sections.map(e => {
                        reportEditor.updateSection(e.uid, {
                          attributes: {
                            ...e.attributes,
                            sectionOwnership: stagedOwnership
                          }
                        }
                        )
                      })
                      setEditOwnershipVisable(false)
                    }}
                    variant="primary"
                    size="x3"
                  >
                    <Text>Confirm</Text>
                  </Button>
                </Buttons>
              </ModalFooter>
            </ModalBody>
          </Modal>

          <Modal visible={editPipeTypeVisable} maxWidth={FULL_SCREEN_MODAL_WIDTH} onClose={() => setEditPipeTypeVisable(false)}>
            <ModalHeader>Edit pipe type</ModalHeader>
            <ModalBody>
              <InputLabel>
                {Object.values(PipeType).map((pipeType) => (
                  <CheckBox
                    checked={pipeType === stagedPipeType}
                    key={pipeType}
                    onChange={() => setStagedPipeType(pipeType)}
                    borderSize='x1'
                    margin="x1"
                  >
                    {pipeType}
                  </CheckBox>
                ))}
              </InputLabel>
              <ModalFooter>
                <Buttons>
                  <Button
                    color="positive"
                    elevate={true}
                    disabled={stagedPipeType === undefined}
                    grow
                    onClick={() => {
                      reportEditor.report.sections.map(e => {
                        reportEditor.updateSection(e.uid, {
                          attributes: {
                            ...e.attributes,
                            pipeType: stagedPipeType
                          }
                        }
                        )
                      })
                      setEditPipeTypeVisable(false)
                    }}
                    variant="primary"
                    size="x3"
                  >
                    <Text>Confirm</Text>
                  </Button>
                </Buttons>
              </ModalFooter>
            </ModalBody>
          </Modal>

          <Modal visible={editShapeVisable} maxWidth={FULL_SCREEN_MODAL_WIDTH} onClose={() => setEditShapeVisable(false)}>
            <ModalHeader>Edit Shape</ModalHeader>
            <ModalBody>
              <InputLabel>
                {Object.values(SectionShapeCode).map((shape) => (
                  <CheckBox
                    checked={shape === stagedShape}
                    key={shape}
                    onChange={() => setStagedShape(shape)}
                    borderSize='x1'
                    margin="x1"
                  >
                    {getShapeSchema(shape).name[language]}
                  </CheckBox>
                ))}
              </InputLabel>
            </ModalBody>
            <ModalFooter>
              <Buttons>
                <Button
                  color="positive"
                  elevate={true}
                  disabled={stagedShape === undefined}
                  grow
                  onClick={() => {
                    reportEditor.report.sections.map(e => {
                      reportEditor.updateSection(e.uid, {
                        attributes: {
                          ...e.attributes,
                          shape: stagedShape
                        }
                      }
                      )
                    })
                    setEditShapeVisable(false)
                  }}
                  variant="primary"
                  size="x3"
                >
                  <Text>Confirm</Text>
                </Button>
              </Buttons>
            </ModalFooter>
          </Modal>

          <Modal visible={editMaterialVisable} maxWidth={FULL_SCREEN_MODAL_WIDTH} onClose={() => setEditMaterialVisable(false)}>
            <ModalHeader>Edit Shape</ModalHeader>
            <ModalBody>
              <InputLabel>
                {Object.values(SectionMaterialCode).map((material) => (
                  <CheckBox
                    checked={material === stagedMaterial}
                    key={material}
                    onChange={() => setStagedMaterial(material)}
                    borderSize='x1'
                    margin="x1"
                  >
                    {getMaterialSchema(material).name[language]}
                  </CheckBox>
                ))}
              </InputLabel>
            </ModalBody>
            <ModalFooter>
              <Buttons>
                <Button
                  color="positive"
                  elevate={true}
                  disabled={stagedMaterial === undefined}
                  grow
                  onClick={() => {
                    reportEditor.report.sections.map(e => {
                      reportEditor.updateSection(e.uid, {
                        attributes: {
                          ...e.attributes,
                          material: stagedMaterial
                        }
                      }
                      )
                    })
                    setEditMaterialVisable(false)
                  }}
                  variant="primary"
                  size="x3"
                >
                  <Text>Confirm</Text>
                </Button>
              </Buttons>
            </ModalFooter>
          </Modal>




        </>
      }

    </>
  );
};

export default ReportMapSidebarSectionsBulkUpdate;
