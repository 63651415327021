import { Customer } from '@drainify/types';
import { Box, Text, TextProps } from 'preshape';
import React from 'react';
import Address from '../Location/Address';
import PlaceholderBlock from '../Placeholders/PlaceholderBlock';

type Props = TextProps & {
  customer?: Customer;
};

const CustomerView = ({ customer, ...rest }: Props) => {
  if (!customer) {
    return (
      <Box>
        <PlaceholderBlock margin="x2" />
        <PlaceholderBlock height=".75rem" margin="x1" />
        <PlaceholderBlock height=".75rem" margin="x1" />
        <PlaceholderBlock height=".75rem" margin="x1" />
        <PlaceholderBlock height=".75rem" margin="x1" />
      </Box>
    );
  }

  return (
    <Text {...rest}>
      <Text size="x4">{customer.name}</Text>
      <Address
        location={customer.location}
        size="x3"
        textColor="text-shade-4"
      />
    </Text>
  );
};

export default CustomerView;
