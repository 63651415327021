import { captureVideoFrame } from 'capture-video-frame';
import { Button, Text } from 'preshape';
import React from 'react';
import { useReportEditorContext } from './../Report/ReportEditorProvider';
import { useVideoContext } from './VideoProvider';

type Props = {
  inspectionId: string;
  projectId: string;
};

const VideoPlayerCreateObservationButton = ({ inspectionId }: Props) => {
  const { handlePause, player, playedSeconds } = useVideoContext();

  const { createNewObservationFromVideo } = useReportEditorContext();

  const handleCaptureObservation = () => {
    handlePause();
    const frame = captureVideoFrame(player?.current?.getInternalPlayer())
      .blob as Blob;
    const file = new File([frame], 'name.jpg', { type: frame.type });

    createNewObservationFromVideo(inspectionId, file, playedSeconds);
  };

  return (
    <Button
      onClick={handleCaptureObservation}
      variant="primary"
      color="accent"
      id="accel-o"
    >
      <Text size="x1" titlecase strong>
        Observation
      </Text>
    </Button>
  );
};

export default VideoPlayerCreateObservationButton;
