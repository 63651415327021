"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getProjectBounds = exports.getProjectLocation = void 0;
const geojson_1 = require("./geojson");
const getProjectLocation = (project) => {
    if (project) {
        const { siteLinkedToCustomer, customer, location } = project;
        return (siteLinkedToCustomer ? customer === null || customer === void 0 ? void 0 : customer.location : location) || undefined;
    }
};
exports.getProjectLocation = getProjectLocation;
const getProjectBounds = (project, report) => {
    if (report === null || report === void 0 ? void 0 : report.bounds) {
        return report.bounds;
    }
    const location = (0, exports.getProjectLocation)(project);
    if (location) {
        return (0, geojson_1.getBoundsFromPoint)(location.point, 0.05);
    }
};
exports.getProjectBounds = getProjectBounds;
