import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import PageContext from '../../../components/Page/PageContext';
import { useProjectContext } from '../../../components/Project/ProjectProvider';
import { useReportEditorContext } from '../../../components/Report/ReportEditorProvider';
import { useReportContext } from '../../../components/Report/ReportProvider';

const SectionReturnPageContext = () => {
  const { query: queryProject } = useProjectContext();
  const { query: queryReport } = useReportContext();
  const { sectionId } = useParams();
  const { reportEditor } = useReportEditorContext();
  const section = reportEditor.getSectionByUid(sectionId);

  return (
    <PageContext
      backToLink="../.."
      backToText={reportEditor.getSectionName(section)}
      query={[queryProject, queryReport]}
    >
      <Outlet />
    </PageContext>
  );
};

export default SectionReturnPageContext;
