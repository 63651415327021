import {
  EmailStatus,
  ResponseError,
  ResponseMultipleEntities,
} from '@drainify/types';
import { useQuery } from 'react-query';
import useFetch, { hasAllParams } from '../components/Api/useFetch';

type Opts = {
  noQuery?: boolean;
};

const useBookingEmails = (bookingId?: string, { noQuery }: Opts = {}) => {
  const fetch = useFetch();
  const key = [`/bookings/${bookingId}/emails`, fetch];
  const params = { bookingId: bookingId as string };

  const list = useQuery<ResponseMultipleEntities<EmailStatus>, ResponseError>(
    key,
    () => fetch('/bookings/:bookingId/emails', { method: 'GET', params }),
    { enabled: hasAllParams(params) && !noQuery }
  );

  return { list };
};

export default useBookingEmails;
