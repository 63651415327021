import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';

const LoggingContext = createContext<{
  logError: (error: Error) => void;
}>({
  logError: () => {},
});

export const useLogging = () => useContext(LoggingContext);

// TODO(hhogg): Handle proper frontend error logging
const Logging = (props: PropsWithChildren<{}>) => {
  const [, setErrors] = useState<Error[]>([]);

  const logError = (error: Error) => {
    setErrors((errors) => [...errors, error]);
  };

  return (
    <LoggingContext.Provider
      {...props}
      value={{ logError }}
    ></LoggingContext.Provider>
  );
};

export default Logging;
