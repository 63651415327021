import { PENDING_IMAGE_UPLOAD } from '@drainify/types';
import {
  Box,
  Button,
  ButtonProps,
  Buttons,
  Icons,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
  TextArea,
  useResizeObserver,
} from 'preshape';
import React, { ChangeEvent } from 'react';
import { generatePath } from 'react-router';
import useFileUpload from '../../hooks/useFileUpload';
import { isDesktop } from '../../utils/client';
import FileUpload from '../FileUpload/FileUpload';
import resizeImage from '../Image/ImageCompressor';
import ImageFromFile from '../Image/ImageFromFile';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';

type Props = ButtonProps;

const SitePhotoCreateButton = ({ ...rest }: Props) => {
  const { reportEditor } = useReportEditorContext();
  const { activeBooking, project } = useProjectContext();
  const [modalVisible, setModalVisible] = React.useState(false);
  const [fileToUpload, setFileToUpload] = React.useState<File>();
  const [sizeImageArea, refImageArea] = useResizeObserver<HTMLDivElement>();
  const [annotation, setAnnotation] = React.useState<string>();
  const sitePhotoUpload = useFileUpload();

  React.useEffect(() => {
    if (!modalVisible) {
      setAnnotation(undefined);
      setFileToUpload(undefined);
    }
  }, [modalVisible]);

  const handleSelectFile = async (file: File) => {
    const compressedBlob = (await resizeImage({ file, maxSize: 500 })) as Blob;
    const compressedImage = new File(
      [compressedBlob],
      'obervation-snapshot.jpg',
      {
        type: compressedBlob.type,
      }
    );
    setFileToUpload(compressedImage);
  };

  if (!activeBooking || !project) {
    return null;
  }

  const uploadImage = async () => {
    if (fileToUpload) {
      const { uid } = reportEditor.addSitePhoto({
        annotation,
        imageUrl: PENDING_IMAGE_UPLOAD,
        jobId: activeBooking.uid,
      });
      await reportEditor.updateAsync?.(reportEditor.report);

      sitePhotoUpload.upload(
        generatePath(
          '/projects/:projectId/report/sitePhotos/:sitePhotoId/image',
          {
            projectId: project.uid,
            sitePhotoId: uid,
          }
        ),
        fileToUpload
      );
      setModalVisible(false);
    }
  };

  return (
    <>
      <Button
        onClick={() => setModalVisible(true)}
        variant="secondary"
        color="accent"
        disabled={!!activeBooking.completedAt}
        flex="horizontal"
        grow
        {...rest}
      >
        Add
        <Icons.Plus />
      </Button>
      <Modal visible={modalVisible} onClose={() => setModalVisible(false)}>
        <ModalHeader>Upload site image</ModalHeader>
        <ModalBody>
          <Box flex="vertical" gap="x3">
            <Box grow minHeight="200px" ref={refImageArea}>
              <ImageFromFile
                file={fileToUpload}
                height={sizeImageArea.height}
              />
            </Box>
            <FileUpload
              accept="image/png, image/jpeg"
              maxWidth="300px"
              onChange={handleSelectFile}
              value={fileToUpload}
            >
              <Icons.Camera />
              <Text
                style={{
                  WebkitUserSelect: 'none',
                  msUserSelect: 'none',
                  userSelect: 'none',
                  WebkitTouchCallout: 'none',
                  KhtmlUserSelect: 'none',
                  MozUserSelect: 'none',
                }}
              >
                {
                  isDesktop() ? 'Upload picture' : 'Take/Upload picture'
                }
              </Text>
            </FileUpload>
            <TextArea
              maxLength={120}
              placeholder="Add description here..."
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                setAnnotation(e.target.value)
              }
              value={annotation === undefined ? '' : annotation}
              rows={4}
            />
          </Box>
        </ModalBody>
        <ModalFooter>
          <Buttons>
            <Button
              variant="primary"
              color="accent"
              elevate
              grow
              disabled={!fileToUpload}
              onClick={uploadImage}
            >
              Add
            </Button>
          </Buttons>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default SitePhotoCreateButton;
