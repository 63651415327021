import { SurveyReview } from "@drainify/types";
import { Appear, Box, Button, Icons, TextArea } from 'preshape'
import React, { ChangeEvent } from "react";
import Droplet from "../IconsMisc/Droplet";
import SurveyReviewRatingStar from "./SurveyReviewRatingStar";

type Props = {
    onRatingUpdate: (rating: number) => void;
    onCommentUpdate: (comments: string) => void;
    surveyReview: SurveyReview;
    readOnly?: boolean;
}

const SurveyReviewRating = ({ onRatingUpdate, onCommentUpdate, surveyReview, readOnly }: Props) => {
    const { rating, comments } = surveyReview;
    const [textInputVisible, setTextInputVisible] = React.useState(false)

    const update = (rating: number) => {
        onRatingUpdate(rating)
        setTextInputVisible(true)
    }

    React.useEffect(() => {
        function handleClick(e: KeyboardEvent) {
            if (e.code === 'Enter') {
                onCommentUpdate(comments + '\n')
            }
        }

        window.addEventListener('keydown', handleClick);
        return () => window.removeEventListener('keydown', handleClick);
    }, [comments]);

    if (readOnly) {
        return (
            <Box flex='vertical' alignChildrenHorizontal='middle' gap='x3'>
                <Box flex='horizontal' gap='x3' width='200px' alignChildrenVertical='middle' height='50px'>
                    <Droplet height={30} fill={surveyReview.rating! >= 1 ? 'var(--color-accent-shade-5)' : 'gray'} />
                    <Droplet height={30} fill={surveyReview.rating! >= 2 ? 'var(--color-accent-shade-5)' : 'gray'} />
                    <Droplet height={30} fill={surveyReview.rating! >= 3 ? 'var(--color-accent-shade-5)' : 'gray'} />
                    <Droplet height={30} fill={surveyReview.rating! >= 4 ? 'var(--color-accent-shade-5)' : 'gray'} />
                    <Droplet height={30} fill={surveyReview.rating! >= 5 ? 'var(--color-accent-shade-5)' : 'gray'} />
                </Box>
                <Box width='600px' paddingBottom='x3'>
                    <TextArea readOnly value={surveyReview.comments} rows={10} style={{ resize: 'none' }} />
                </Box>
            </Box>

        )

    }

    return (<Box flex='vertical' alignChildrenHorizontal='middle' gap='x3'>
        <Box flex='horizontal' alignChildrenHorizontal='middle' height='50px'>
            <Box flex='horizontal' gap='x3' width='200px' alignChildrenVertical='middle'>
                <SurveyReviewRatingStar score={1} onUpdate={update} rating={rating} />
                <SurveyReviewRatingStar score={2} onUpdate={update} rating={rating} />
                <SurveyReviewRatingStar score={3} onUpdate={update} rating={rating} />
                <SurveyReviewRatingStar score={4} onUpdate={update} rating={rating} />
                <SurveyReviewRatingStar score={5} onUpdate={update} rating={rating} />
            </Box>
            <Box flex='horizontal' alignChildrenVertical='middle'>
                <Button onClick={() => setTextInputVisible(!textInputVisible)} variant='tertiary' size='x1'>Comments {textInputVisible ? <Icons.ChevronUp/> : <Icons.ChevronDown/>}</Button>
            </Box>
        </Box>
        {
            textInputVisible &&
            <Appear>
                <Box width='600px' paddingBottom='x3'>
                    <TextArea
                        style={{ resize: 'none' }}
                        placeholder="Add your review here..."
                        rows={10}
                        value={comments}
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => onCommentUpdate(e.target.value)}
                    />

                </Box>
            </Appear>

        }
    </Box>)
}

export default SurveyReviewRating;