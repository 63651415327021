import { EmailStatus } from '@drainify/types';
import React from 'react';
import useEmailStatus from '../../hooks/useEmailStatus';
import { useProjectContext } from '../Project/ProjectProvider';
import Spinner from '../Spinner/Spinner';

type Props = {
  emailStatus: EmailStatus;
};

const EmailRowPending = ({ emailStatus }: Props) => {
  const { activeBooking } = useProjectContext();
  const { poll } = useEmailStatus(activeBooking?.uid, emailStatus.uid);
  poll.data;

  return <Spinner />;
};

export default EmailRowPending;
