import { PENDING_IMAGE_UPLOAD } from '@drainify/types';
import { Attributes, Box, Icons, Image, ImageProps, Text } from 'preshape';
import React from 'react';
import Blueprint from '../Blueprint/Blueprint';
import Spinner from '../Spinner/Spinner';

type Props = ImageProps & {
  isError: boolean;
  isLoading: boolean;
};

const ImageFallback = ({
  height,
  isError,
  isLoading,
  margin,
  ...rest
}: Attributes<HTMLImageElement, Props>) => {
  return (
    <Blueprint
      container
      backgroundColor="background-shade-3"
      borderRadius="x2"
      borderSize="x2"
      height={height}
      margin={margin}
      overflow="hidden"
      width="100%"
    >
      {isLoading && (
        <Box absolute="center">
          <Spinner textColor="accent-shade-4" />
        </Box>
      )}

      {isError && !rest.src?.endsWith(PENDING_IMAGE_UPLOAD) && (
        <Text
          alignChildren="middle"
          absolute="center"
          flex="vertical"
          gap="x2"
          textColor="negative-shade-4"
        >
          <Icons.AlertTriangle size="2rem" />
          <Text>This image failed to load.</Text>
        </Text>
      )}

      {!isLoading && !isError && !rest.src && (
        <Text
          alignChildren="middle"
          absolute="center"
          flex="vertical"
          gap="x2"
          strong
          textColor="text-shade-1"
        >
          No image
        </Text>
      )}

      {!isLoading && !isError && rest.src && (
        <Box alignChildren="middle" absolute="edge-to-edge" flex="horizontal">
          <Image {...rest} maxHeight="100%" />
        </Box>
      )}
    </Blueprint>
  );
};

export default ImageFallback;
