import { Inspection } from '@drainify/types';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import InspectionModal from './InspectionModal';

type Context = {
  inspection?: Inspection;
  editInspection: (stepId?: string) => void;
  removeInspection: () => void;
};

type Props = {};

export const InspectionContext = createContext<Context>({
  inspection: {} as unknown as Inspection,
  editInspection: () => {},
  removeInspection: () => {},
});

export const useInspectionContext = () => useContext(InspectionContext);

const InspectionProvider = ({}: PropsWithChildren<Props>) => {
  const { project } = useProjectContext();
  const { reportEditor } = useReportEditorContext();
  const { sectionId = '', inspectionId = '' } = useParams();
  const navigate = useNavigate();
  const inspection = reportEditor.getInspectionByUid(inspectionId);
  const [isInspectionModalOpen, setIsInspectionModalOpen] = useState(false);
  const [activeEditStep, setActiveEditStep] = useState<string>();

  const handleOpenInspectionModal = (stepId?: string) => {
    setIsInspectionModalOpen(true);
    setActiveEditStep(stepId);
  };

  const handleCloseInspectionModal = () => {
    setIsInspectionModalOpen(false);
    setActiveEditStep(undefined);
  };

  const removeInspection = async () => {
    if (project && inspection) {
      reportEditor.removeInspection(inspection.uid);
      navigate('../');
    }
  };

  const value: Context = {
    inspection: inspection,
    editInspection: handleOpenInspectionModal,
    removeInspection: removeInspection,
  };

  return (
    <InspectionContext.Provider value={value}>
      <Outlet />

      <InspectionModal
        initialActiveStepId={activeEditStep}
        onClose={handleCloseInspectionModal}
        sectionUid={sectionId}
        inspectionUid={inspectionId}
        visible={isInspectionModalOpen}
      />
    </InspectionContext.Provider>
  );
};

export default InspectionProvider;
