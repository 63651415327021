import { BendPoint, Section } from '@drainify/types';
import { getMidPointBetweenPoints } from '@drainify/utils';
import round from 'lodash.round';
import { Box, Icons, Label, Text } from 'preshape';
import React, { useMemo } from 'react';
import { v4 } from 'uuid';
import Distance from '../../../DistanceInput/Distance';
import MapLabel from '../../../Map/MapMarker/MapLabel';
import MapMarker from '../../../Map/MapMarker/MapMarker';
import MapMarkerToolbar from '../../../Map/MapMarker/MapMarkerToolbar';
import useGeometryStoreRegister from '../../../Map/useGeometryStore/useGeometryStoreRegister';
import ReportMapMeasurementSegment from '../../../Measurement/ReportMapMeasurementSegment';
import SectionView from '../../../Section/SectionView';
import { useReportEditorContext } from '../../ReportEditorProvider';
import { useReportMapContext } from '../ReportMapProvider';

type Props = {
  section: Section;
};

const ReportMapEditSectionShape = ({ section }: Props) => {
  const { reportEditor } = useReportEditorContext();
  const { hasFeature } = useReportMapContext();
  const startNode = reportEditor.getNodeByUid(section.nodeStartUid);
  const endNode = reportEditor.getNodeByUid(section.nodeEndUid);
  const additionalPoints = section.additionalPoints || [];

  const lineString = useMemo<GeoJSON.LineString | undefined>(() => {
    if (startNode?.point && endNode?.point) {
      return {
        type: 'LineString',
        coordinates: [
          startNode.point.coordinates,
          ...(section.additionalPoints || [])?.map((e) => e.point.coordinates),
          endNode.point.coordinates,
        ],
      };
    }
  }, [startNode, endNode, section.additionalPoints]);

  const handleOnAdd = (index: number) => (point: GeoJSON.Point) => {
    const copy = section.additionalPoints || [];
    copy.splice(index, 0, {
      uid: v4(),
      point,
    });
    reportEditor.updateSection(section.uid, {
      additionalPoints: copy,
    });
  };

  const handleDragEnd = (point: BendPoint, update: GeoJSON.Point) => {
    reportEditor.updateSection(section.uid, {
      additionalPoints: section.additionalPoints?.map((e) => {
        if (point.uid === e.uid) {
          return {
            uid: e.uid,
            point: update,
          };
        } else return e;
      }),
    });
  };

  const handleRemovePoint = (toRemoveUid: string) => {
    reportEditor.updateSection(section.uid, {
      additionalPoints: section.additionalPoints?.filter(
        (e) => e.uid !== toRemoveUid
      ),
    });
  };

  useGeometryStoreRegister({
    geometry: lineString,
    opts: {
      id: `ReportMapSection.${section.uid}`,
    },
  });

  if (!startNode?.point || !endNode?.point) {
    return null;
  }

  const points = [
    { point: startNode.point, uid: 'start' },
    ...additionalPoints,
    { point: endNode.point, uid: 'finish' },
  ];

  return (
    <>
      <MapLabel
        id={section.uid}
        point={getMidPointBetweenPoints(
          startNode.point,
          section.additionalPoints && section.additionalPoints.length > 0
            ? section.additionalPoints[0].point
            : endNode.point
        )}
        visible={true}
      >
        <MapMarkerToolbar information={<SectionView section={section} />}>
          <Label alignChildren="middle" flex="vertical" size="x1">
            <Text strong>{reportEditor.getSectionName(section)}</Text>

            {length && (
              <Text weak>
                (
                <Distance
                  inline
                  type="long"
                  value={round(
                    reportEditor.getSectionLength(section.uid) || 0,
                    2
                  )}
                />
                )
              </Text>
            )}
          </Label>
        </MapMarkerToolbar>
      </MapLabel>
      {points.map((e, i) => (
        <Box key={i}>
          {i < points.length - 1 && (
            <ReportMapMeasurementSegment
              key={section.uid + '-' + i + '-segment'}
              points={[points[i].point, points[i + 1].point]}
              onAdd={handleOnAdd(i)}
            />
          )}
          {e.uid !== 'start' && e.uid !== 'finish' && (
            <MapMarker
              key={section.uid + '-' + i + '-marker'}
              point={e.point}
              onDragEnd={(p) =>
                hasFeature('Elements:move') ? handleDragEnd(e, p) : undefined
              }
            >
              <MapMarkerToolbar
                enabled={true}
                tools={[
                  {
                    icon: <Icons.Trash2 />,
                    onClick: () => handleRemovePoint(e.uid),
                  },
                ]}
              >
                <Box backgroundColor="accent-shade-5" borderRadius="full">
                  <Icons.Circle />
                </Box>
              </MapMarkerToolbar>
            </MapMarker>
          )}
        </Box>
      ))}
    </>
  );
};

export default ReportMapEditSectionShape;
