import { Box } from 'preshape';
import React from 'react';

type Props = {
  size?: string;
};

const ClassroomIcon = ({ size = '1.5rem' }: Props) => {
  return (
    <Box
      className="Icon"
      tag="svg"
      viewBox="0 0 24 24"
      height={size}
      width={size}
      borderRadius="full"
      fill='currentColor'
    >
      {
        <svg
          id="Layer_2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 161.93 87.27"
        >
          <defs></defs>
          <g id="Layer_1-2">

<path className="cls-1" d="m146.96,20.76v-4.62S102.1,6.08,79.76.86c-3.14-.66-6.32-1.19-9.52-.63C46.78,5.05,0,14.99,0,14.99v5c.93,1.31,47.23,15.56,67.17,20.09,3.67.83,7.49.82,11.15-.05,14.42-3.42,51.03-13.74,68.65-18.88.02-.12.04-.24.06-.36-.02,0-.04-.01-.06-.02Z"/><path className="cls-1" d="m114.15,62.86c.48-5.63.74-21.21,1.06-26.86-55.45,12.6-26.91,14.51-83.93-.74.42,5.23,1.09,20.4,1.38,25.63.28,4.83,2.35,8.43,6.39,11.05,14.84,9.63,33.5,12.27,50.56,7.86,7.93-2.43,23.59-7.4,24.54-16.93Z"/><g id="InMspu.tif"><path className="cls-1" d="m137.74,47.1c2.34,2.98,4.57,6.05,6.61,9.24,1.64,2.55,3.16,5.17,4.4,7.95.66,1.49,1.22,3.02,1.52,4.63.07.39.12.79.17,1.19v1.3c-.01.06-.03.12-.03.19-.06,1.04-.24,2.06-.52,3.06-2.08,7.25-9.21,11.75-16.65,10.44-4.13-.73-7.35-2.88-9.68-6.31-1.41-2.07-2.22-4.49-2.43-6.99-.06-.71-.07-1.42.02-2.13.14-1.21.47-2.37.89-3.51.88-2.44,2.08-4.73,3.39-6.96,2.49-4.25,5.35-8.24,8.38-12.12.32-.41,1.91-2.51,1.91-2.51,0,0,1.63,2.04,2.02,2.53Zm-2.43,33.83c.07,0,.15,0,.22,0,.93-.01,1.59-.89,1.35-1.79-.16-.61-.72-1.04-1.38-1.04-.98-.01-1.93-.18-2.81-.59-2.73-1.29-4.15-3.46-4.26-6.48-.03-.82-.64-1.44-1.44-1.43-.79,0-1.43.65-1.39,1.47.03.63.07,1.27.19,1.89.86,4.53,4.89,7.9,9.52,7.98Z"/><path className="cls-1" d="m133.7,51.18c1.38-.13,3.8-.02,5.15.26-.03-.03-1.05-.07-1.08-.1-.85-1.09-.02-2.17,0-3.46,0-6.08.3-12.11-.2-18.19-2.17.29-3.09-.03-3.24,1.6,0,5.06.03,12.72.1,17.78.01.79-.19,1.4-.67,2.02-.02.03-.04.05-.06.08Z"/>
            </g>
          </g>
        </svg>
      }
    </Box>
  );
};

export default ClassroomIcon;
