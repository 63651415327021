import decode from 'jwt-decode';
import { useLocation } from 'react-router';

export default function useReportAccessToken() {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  try {
    const token = queryParams.get('token');

    if (!token) throw Error('No token param provided');

    const decodedToken = decode(token);

    return {
      token,
      decodedToken,
    };
  } catch (e) {
    return {
      token: undefined,
      decodedToken: undefined,
    };
  }
}
