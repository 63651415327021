import { ReportElementType } from '@drainify/utils';
import React from 'react';
import { useMapLayersContext } from '../../../Map/MapLayers/MapLayers';
import MapMarker from '../../../Map/MapMarker/MapMarker';
import { useReportEditorContext } from '../../ReportEditorProvider';
import ReportMapObservationRenderer from './ReportMapObservationRenderer';

const ReportMapObservation: typeof ReportMapObservationRenderer = ({
  observation,
  showOnHover,
  visible,
}) => {
  const { reportEditor } = useReportEditorContext();
  const { isLayerVisible } = useMapLayersContext();
  const { poi } = reportEditor.getObservationPoints(observation.uid);

  const show =
    isLayerVisible(ReportElementType.OBSERVATION, observation.uid) || visible;

  if (!poi || !observation.distance) {
    return null;
  }

  return (
    <MapMarker point={poi}>
      <ReportMapObservationRenderer
        observation={observation}
        visible={show}
        showOnHover={showOnHover}
      />
    </MapMarker>
  );
};

export default ReportMapObservation;
