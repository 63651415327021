import { Member, ResponseError } from '@drainify/types';
import { useQuery } from 'react-query';
import useFetch from '../components/Api/useFetch';

const useMeOrgMember = () => {
  const fetch = useFetch();

  const query = useQuery<Member, ResponseError>(['/me/org/member', fetch], () =>
    fetch('/me/org/member', { method: 'GET' })
  );

  return { query };
};

export default useMeOrgMember;
