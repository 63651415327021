import { DateTime } from 'luxon';
import { Appear, Icons, Text, Box } from 'preshape';
import React from 'react';
import useMeBookings from '../../hooks/useMeBookings';
import Spinner from '../Spinner/Spinner';

const ReloadSpinner = () => {
    const [spin, setSpin] = React.useState(false)
    const { query } = useMeBookings();
    const [activeTimeout, setActiveTimeout] = React.useState(false)

    React.useEffect(() => {
        if (query.isFetching && !spin && !activeTimeout) {
            setSpin(true)
        } else if (!query.isFetching && spin && !activeTimeout) {
            setActiveTimeout(true)
            setTimeout(() => {
                setSpin(false)
                setActiveTimeout(false)
            }, 1000)
        }
    }, [query, spin])

    if (spin) {
        return (
            <Box flex='horizontal' alignChildrenHorizontal='middle' alignChildrenVertical='middle' textColor='text-shade-4' paddingTop='x6'>
                <Appear animation='FadeSlideDown'>
                    <Box flex='horizontal' gap='x2'>
                        <Spinner size='1rem' />
                        <Text strong size='x3'> Fetching </Text>
                    </Box>
                </Appear>
            </Box>

        )
    }
    else {
        return (
            <Box flex='horizontal' alignChildrenHorizontal='middle' gap='x2' textColor='text-shade-4'>
                <Text strong size='x1'>
                    {`Updated ${DateTime.fromJSDate(new Date(query.dataUpdatedAt)).toLocaleString(DateTime.DATETIME_MED)}`}
                </Text>
                <Icons.ArrowUp size='0.75rem' />
            </Box>
        )
    }
}

export default ReloadSpinner;