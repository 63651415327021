import { BoxProps, Icons, Motion } from 'preshape';
import React, { PropsWithChildren } from 'react';

const Spinner = ({
  size = '1.5rem',
  ...rest
}: PropsWithChildren<BoxProps & { size?: string }>) => {
  return (
    <Motion
      {...rest}
      animate={{ rotate: 360 }}
      display="inline-block"
      transition={{
        ease: 'linear',
        repeat: Infinity,
        duration: 2,
      }}
    >
      <Icons.Loader display="block" size={size} />
    </Motion>
  );
};

export default Spinner;
