import { Button, Buttons, Icons, Text, useFormContext } from 'preshape';
import React from 'react';
import { useWizardContext } from '../Wizard';

const WizardControlsOnStepFromSummary = () => {
  const { activeStepId, setActiveStepId } = useWizardContext();
  const { getError } = useFormContext();
  const stepError = activeStepId && getError(activeStepId);

  const handleCancel = () => {
    // TODO: Do not retain changes on cancel
    setActiveStepId('review');
  };

  const handleDone = () => {
    setActiveStepId('review');
  };

  return (
    <Buttons>
      <Button
        basis="0"
        grow
        onClick={handleCancel}
        size="x3"
        style={{
          WebkitUserSelect: 'none',
          msUserSelect: 'none',
          userSelect: 'none',
          WebkitTouchCallout: 'none',
          KhtmlUserSelect: 'none',
          MozUserSelect: 'none',
        }}
      >
        <Text>Cancel</Text>
      </Button>

      <Button
        basis="0"
        color="accent"
        disabled={!!stepError}
        elevate={!stepError}
        gap="x2"
        grow
        id="accel-Enter"
        onClick={handleDone}
        size="x3"
        variant="primary"
        style={{
          WebkitUserSelect: 'none',
          msUserSelect: 'none',
          userSelect: 'none',
          WebkitTouchCallout: 'none',
          KhtmlUserSelect: 'none',
          MozUserSelect: 'none',
        }}
      >
        <Text>Done</Text>
        <Icons.Check />
      </Button>
    </Buttons>
  );
};

export default WizardControlsOnStepFromSummary;
