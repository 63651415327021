import { Box } from 'preshape';
import React, { PropsWithChildren } from 'react';
import Card, { CardProps } from '../../Card/Card';
import SearchResultGroup from '../../Search/SearchResultGroup';

type Props = CardProps & {};

const MapSidebarContentItemSection = (props: PropsWithChildren<Props>) => {
  return (
    <SearchResultGroup>
      <Box
        paddingHorizontal="x4"
        paddingVertical="x4"
        borderBottom
        borderColor="background-shade-3"
        borderStyle="dashed"
      >
        <Card {...props} backgroundColor="transparent" padding="x0" />
      </Box>
    </SearchResultGroup>
  );
};

export default MapSidebarContentItemSection;
