import { getFullFilePath } from '@drainify/utils';
import {
  Button,
  Buttons,
  Icons,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Placement,
  PlacementArrow,
  PlacementContent,
  PlacementManager,
  PlacementReference,
} from 'preshape';
import React from 'react';
import { FULL_SCREEN_MODAL_WIDTH } from '../../../components/App/App';
import Avatar from '../../../components/Avatar/Avatar';
import CustomerCreateButton from '../../../components/Customer/CustomerCreateButton';
import InviteCreateButton from '../../../components/Invite/InviteCreateButton';
import { useOrgContext } from '../../../components/Org/OrgProvider';
import OrgView from '../../../components/Org/OrgView';
import { useHasPermission } from '../../../components/Permissions/Permissions';
import OrgCustomersPage from '../Pages/OrgCustomersPage';
import OrgMembersPage from '../Pages/OrgMembersPage';
import OrgProfilePage from '../Pages/OrgProfilePage';
import ContextMenu from './ContextMenu';
import ContextMenuItem from './ContextMenuItem';

const AdminMenu = () => {
  const hasPermission = useHasPermission('admin-page:view');

  const [membersPageVisible, setMembersPageVisible] = React.useState(false);
  const [orgPageVisible, setOrgPageVisible] = React.useState(false);
  const [customerPageVisible, setCustomerPageVisible] = React.useState(false);
  const { org } = useOrgContext();

  if (!hasPermission) {
    return null;
  }

  return (
    <>
      <PlacementManager trigger="click">
        <PlacementReference>
          {(props) => (
            <Button
              {...props}
              borderColor="background-shade-3"
              borderRadius="100px"
              paddingHorizontal="x1"
              paddingVertical="x1"
              size="x2"
            >
              <Avatar
                src={getFullFilePath(org?.logoUrlCropped)}
                uid={org?.uid}
                size="1.75rem"
              />
              <Icons.ChevronDown />
            </Button>
          )}
        </PlacementReference>

        <Placement placement="bottom">
          <PlacementArrow backgroundColor="background-shade-3" />
          <PlacementContent
            backgroundColor="background-shade-1"
            borderColor="background-shade-3"
            borderRadius="x3"
            borderSize="x2"
            overflow="hidden"
            textColor="text-shade-1"
          >
            <ContextMenu backgroundColor="background-shade-1" margin="x2">
              <OrgView org={org} />
              <ContextMenuItem
                icon={<Icons.Trello />}
                size="x2"
                onClick={() => setOrgPageVisible(true)}
              >
                Org details
              </ContextMenuItem>

              <ContextMenuItem
                icon={<Icons.Users />}
                size="x2"
                onClick={() => setMembersPageVisible(true)}
              >
                Members
              </ContextMenuItem>

              <ContextMenuItem
                icon={<Icons.Users />}
                size="x2"
                onClick={() => setCustomerPageVisible(true)}
              >
                Customers
              </ContextMenuItem>
            </ContextMenu>
          </PlacementContent>
        </Placement>
      </PlacementManager>

      <Modal
        visible={membersPageVisible}
        onClose={() => setMembersPageVisible(false)}
      >
        <ModalHeader>Member admin</ModalHeader>
        <ModalBody>
          <OrgMembersPage />
        </ModalBody>
        <ModalFooter>
          <Buttons>
            {useHasPermission('invites:create') && (
              <InviteCreateButton grow size="x3" variant="primary" />
            )}
            <Button grow size="x3" onClick={() => setMembersPageVisible(false)}>
              Cancel
            </Button>
          </Buttons>
        </ModalFooter>
      </Modal>

      <Modal
        visible={orgPageVisible}
        onClose={() => setOrgPageVisible(false)}
        maxWidth={FULL_SCREEN_MODAL_WIDTH}
      >
        <ModalHeader>Organisation admin</ModalHeader>
        <ModalBody>
          <OrgProfilePage />
        </ModalBody>
      </Modal>
      <Modal
        visible={customerPageVisible}
        onClose={() => setCustomerPageVisible(false)}
        maxWidth={FULL_SCREEN_MODAL_WIDTH}
      >
        <ModalHeader>Admin customers</ModalHeader>
        <ModalBody>
          <OrgCustomersPage />
        </ModalBody>
        <ModalFooter>
          <Buttons>
            {useHasPermission('customers:create') && (
              <CustomerCreateButton
                size="x1"
                grow
                onSave={() => {}}
                variant="primary"
              />
            )}
            <Button
              grow
              size="x3"
              onClick={() => setCustomerPageVisible(false)}
            >
              Close
            </Button>
          </Buttons>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AdminMenu;
