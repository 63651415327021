import { Box } from 'preshape';
import React from 'react';
import Card from '../../Card/Card';
import Divider from '../../Divider/Divider';
import ProjectList from '../ProjectList';

type Props = {
  onEndSearch: () => void;
  searchString?: string;
};

const ProjectSearchResults = ({ onEndSearch, searchString }: Props) => {
  const handleSelectProject = () => {
    onEndSearch();
  };

  return (
    <Box flex="horizontal" gap="x8">
      <Card
        basis="0"
        backgroundColor="transparent"
        grow
        padding="x2"
        title="Open projects"
      >
        <Box paddingOffsetHorizontal="x4">
          <Divider margin="x2" />
          <ProjectList
            onClickProject={handleSelectProject}
            searchString={searchString}
          />
          <Divider margin="x2" />
        </Box>
      </Card>
    </Box>
  );
};

export default ProjectSearchResults;
