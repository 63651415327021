import { StripeSubscriptionResponse } from '@drainify/types';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import {
  Box,
  Button,
  Text,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
  Icons,
  Label,
} from 'preshape';
import React, { useContext } from 'react';
import { FULL_SCREEN_MODAL_WIDTH } from '../App/App';
import AsyncButton from '../AsyncButton/AsyncButton';
import { AuthenticationContext } from '../Authentication/Authenticate';
import Card from '../Card/Card';
import Divider from '../Divider/Divider';
import CardBrandIcon from '../IconsMisc/Cards/CardBrandIcon';
import { useHasPermission } from '../Permissions/Permissions';
import CardInput from '../Signup/CardInput';
import { useUsageContext } from './UsageProvider';

const UsageUpdateCard = () => {
  const [visible, setVisible] = React.useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const { usage, refetch } = useUsageContext();
  const [error, setError] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const { user } = useContext(AuthenticationContext);

  const hasPaymentPermission = useHasPermission('subscription:cancel');

  React.useEffect(() => {
    setError(undefined);
    setLoading(false);
    setSuccess(false);
  }, [visible]);

  if (!stripe || !hasPaymentPermission) {
    return null;
  }

  const setupCard = async () => {
    const token = await user?.getIdToken();

    if (token && !loading) {
      await fetch(`${process.env.DRAINIFY_API_URL}/payment/update-card`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then(async (e) => {
          if (e.status === 200) {
            return (await e.json()) as StripeSubscriptionResponse;
          }
          const error = await e.json();
          throw Error(error['message']);
        })
        .then(async (e) => {
          if (!e.clientSecret || !e.status) {
            throw new Error('Unexpected stripe response.');
          } else {
            const cardElement = elements!.getElement(CardElement)!;

            const result = await stripe.confirmCardSetup(e.clientSecret, {
              payment_method: {
                card: cardElement,
              },
            });
            if (result.error) {
              setError(result.error.message);
            } else {
              setTimeout(() => {
                setLoading(false);
                setSuccess(true);
                refetch?.();
              }, 1000);
            }
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Button
        onClick={() => setVisible(true)}
        variant="tertiary"
        color="accent"
        size="x1"
      >
        <Box flex="horizontal" gap="x1">
          <Text>Payment details</Text>
        </Box>
      </Button>
      {visible && (
        <Modal
          animation="FadeSlideUp"
          margin="x4"
          // onClose={onClose}
          onClose={() => setVisible(false)}
          overlayBackgroundCloseOnClick={false}
          visible={visible}
          maxWidth={FULL_SCREEN_MODAL_WIDTH}
          grow
        >
          <ModalHeader>
            <ModalTitle>Payment details</ModalTitle>
          </ModalHeader>

          <ModalBody flex="vertical" alignChildrenVertical="middle">
            <Box flex="vertical" gap="x3">
              <Card flex="horizontal" title="Current card">
                <Box flex="horizontal" alignChildrenHorizontal="around">
                  <Box>
                    <Icons.CreditCard size="6rem" />
                  </Box>
                  <Box flex="vertical" alignChildrenVertical="middle" gap="x2">
                    {usage?.last4 && (
                      <Label flex="vertical" alignChildrenVertical="middle">
                        <Text>**** **** **** {usage?.last4}</Text>
                      </Label>
                    )}
                    <Box flex="horizontal" gap="x2">
                      <Label
                        backgroundColor="background-shade-3"
                        textColor="text-shade-4"
                        flex="vertical"
                        shrink
                        alignChildrenVertical="middle"
                      >
                        <Text>
                          Exp: {usage?.expMonth}/{usage?.expYear}
                        </Text>
                      </Label>
                      <CardBrandIcon brand={usage?.brand} />
                    </Box>
                  </Box>
                </Box>
              </Card>
              <Divider />
              <Card title="Update">
                <Box flex="vertical" gap="x4">
                  <Box>
                    <CardInput />
                  </Box>
                  <Box>
                    <AsyncButton
                      variant="primary"
                      color="accent"
                      isLoading={loading}
                      isError={error !== undefined}
                      isSuccess={success}
                      grow
                      onClick={async () => {
                        setLoading(true);
                        await setupCard();
                      }}
                    >
                      Update
                    </AsyncButton>
                  </Box>
                </Box>
              </Card>
            </Box>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default UsageUpdateCard;
