import { Box, BoxProps } from 'preshape';
import React, { PropsWithChildren } from 'react';
import Divider from '../../Divider/Divider';

type Props = BoxProps & {};

const MapSidebarMenuItemGroup = ({
  children,
  ...rest
}: PropsWithChildren<Props>) => {
  return (
    <Box {...rest} flex="vertical" gap="x3">
      <Box flex="vertical">{children}</Box>

      <Box paddingHorizontal="x2">
        <Divider borderColor="background-shade-3" borderSize="x1" />
      </Box>
    </Box>
  );
};

export default MapSidebarMenuItemGroup;
