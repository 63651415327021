import { Appear, Button, Icons } from 'preshape';
import React from 'react';
import { useMapContext } from '../Map';
import MapMarker, { MapMarkerProps } from '../MapMarker/MapMarker';

type Props = {
  onClick: () => void;
  point?: MapMarkerProps['point'];
};

const MapBoundsAddVectorMarker = ({ onClick, point, ...rest }: Props) => {
  const { isInteractive } = useMapContext();

  return (
    <MapMarker {...rest} point={point}>
      <Appear
        animation="Pop"
        visible={isInteractive && !!point}
        style={{ pointerEvents: point ? undefined : 'none' }}
      >
        <Button
          borderRadius="full"
          onClick={() => onClick()}
          paddingHorizontal="x0"
          paddingVertical="x0"
          size="x1"
          variant="primary"
        >
          <Icons.PlusCircle size="1.5rem" />
        </Button>
      </Appear>
    </MapMarker>
  );
};

export default MapBoundsAddVectorMarker;
