import { Booking } from '@drainify/types';
import React, { useState } from 'react';
import useBooking from '../../hooks/useBooking';
import useProjectBookings from '../../hooks/useProjectBookings';
import DataQueryTable from '../DataTable/DataQueryTable';
import DataTableGroup from '../DataTable/DataTableGroup';
import DataTableItem from '../DataTable/DataTableItem';
import { useProjectContext } from '../Project/ProjectProvider';
import BookingModal from './BookingModal';
import BookingView from './BookingView';

const BookingDataTableForProject = () => {
  const { project, query: queryProject } = useProjectContext();
  const { query: queryBookings } = useProjectBookings(project?.uid);
  const [editBooking, setEditBooking] = useState<Booking>();
  const { update } = useBooking(editBooking?.uid, { noQuery: true });

  return (
    <>
      <DataQueryTable
        padding="x2"
        entity="jobs"
        placeholder={<BookingView />}
        query={[queryProject, queryBookings]}
      >
        <DataTableGroup>
          {queryBookings.data?.data.map((booking) => (
            <DataTableItem
              key={booking.uid}
              onClick={() => setEditBooking(booking)}
            >
              <BookingView booking={booking} />
            </DataTableItem>
          ))}
        </DataTableGroup>
      </DataQueryTable>

      <BookingModal
        onClose={() => setEditBooking(undefined)}
        onDone={update}
        visible={!!editBooking}
        booking={editBooking}
      />
    </>
  );
};

export default BookingDataTableForProject;
