"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasPermission = exports.orgRoleOrder = exports.permissions = void 0;
const types_1 = require("@drainify/types");
exports.permissions = {
    'bookings:create': types_1.OrgRole.ADMIN,
    'bookings:update': types_1.OrgRole.ENGINEER,
    'bookings:delete': types_1.OrgRole.ADMIN,
    'memberships:create': types_1.OrgRole.OWNER,
    'memberships:update': types_1.OrgRole.OWNER,
    'memberships:delete': types_1.OrgRole.OWNER,
    'invites:create': types_1.OrgRole.ADMIN,
    'invites:update': types_1.OrgRole.ADMIN,
    'invites:delete': types_1.OrgRole.ADMIN,
    'organisation:update': types_1.OrgRole.OWNER,
    'organisation:delete': types_1.OrgRole.OWNER,
    'projects:create': types_1.OrgRole.ADMIN,
    'projects:update': types_1.OrgRole.ENGINEER,
    'projects:delete': types_1.OrgRole.ADMIN,
    'customers:delete': types_1.OrgRole.ADMIN,
    'customers:update': types_1.OrgRole.ADMIN,
    'customers:create': types_1.OrgRole.ADMIN,
    'admin-page:view': types_1.OrgRole.ADMIN,
    'purchase:credit': types_1.OrgRole.OWNER,
    'purchase:storage': types_1.OrgRole.OWNER,
    'purchase:upgrade': types_1.OrgRole.OWNER,
    'purchase:token': types_1.OrgRole.OWNER,
    'survey-review:create': types_1.OrgRole.ADMIN,
    'survey-review:update': types_1.OrgRole.ADMIN,
    'survey-review:view': types_1.OrgRole.ENGINEER,
    'subscription:cancel': types_1.OrgRole.OWNER,
};
exports.orgRoleOrder = [
    types_1.OrgRole.OWNER,
    types_1.OrgRole.ADMIN,
    types_1.OrgRole.ENGINEER,
];
const hasPermission = (role, permission) => {
    return (exports.orgRoleOrder.indexOf(role) <= exports.orgRoleOrder.indexOf(exports.permissions[permission]));
};
exports.hasPermission = hasPermission;
