import { nodes } from '@drainify/utils';
import { Box, Icons, Tooltip, Text } from 'preshape';
import React from 'react';
import MapSidebarContentItem from '../../../Map/MapSidebar/MapSidebarContentItem';
import MapSidebarContentItemSection from '../../../Map/MapSidebar/MapSidebarContentItemSection';
import MapTools from '../../../Map/MapTools/MapTools';
import NodeCreateButton from '../../../Node/NodeCreateButton';
import NodeList from '../../../Node/NodeList';
import { useProjectContext } from '../../../Project/ProjectProvider';
import SearchResult from '../../../Search/SearchResult';
import { useLanguageContext } from '../../../Usage/LanguageProvider';
import { useReportEditorContext } from '../../ReportEditorProvider';
import { useReportMapContext } from '../ReportMapProvider';
import ReportMapToolAddNode from '../ReportMapTools/ReportMapToolAddNode';

const ReportMapSidebarNodes = () => {
  const { activeSidebar, focusNode } = useReportMapContext();
  const { activeBooking } = useProjectContext();
  const { reportEditor } = useReportEditorContext();
  const [collapseNodes, setCollapseNodes] = React.useState(true);
  const { language } = useLanguageContext();

  React.useEffect(() => {
    setCollapseNodes(true);
  }, [activeSidebar]);

  if (activeSidebar !== 'nodes') {
    return null;
  }

  return (
    <MapSidebarContentItem
      active={activeSidebar === 'nodes'}
      action={<NodeCreateButton onCreate={focusNode} />}
      title="Nodes"
    >
      {!!activeBooking?.completedAt === false && (
        <MapSidebarContentItemSection>
          <MapTools>
            {!!activeBooking &&
              nodes
                .filter(({ projectTypes }) =>
                  activeBooking
                    ? projectTypes?.includes(activeBooking.projectType!)
                    : true
                )
                .slice(0, collapseNodes ? 7 : nodes.length)
                .map(({ code, name }) => (
                  <SearchResult
                    key={code}
                    keywords={`${code},${name[language]}`}
                  >
                    <ReportMapToolAddNode code={code} name={name[language]} />
                  </SearchResult>
                ))}
            {collapseNodes && (
              <Tooltip content={'Show more'} placement="top">
                {(props) => (
                  <Box
                    {...props}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setCollapseNodes(false)}
                  >
                    <Box alignChildren="middle" flex="vertical">
                      <Box
                        backgroundColor="accent-shade-1"
                        borderSize="x2"
                        borderRadius="x3"
                        borderStyle="dashed"
                        paddingTop="x4"
                        width="60px"
                        height="60px"
                        flex="horizontal"
                        alignChildrenHorizontal="middle"
                        alignChildrenVertical="middle"
                        gap="x1"
                      >
                        <Icons.Circle size="0.5rem" />
                        <Icons.Circle size="0.5rem" />
                        <Icons.Circle size="0.5rem" />
                      </Box>

                      <Text align="middle" margin="x1" size="x1" strong>
                        More...
                      </Text>
                    </Box>
                  </Box>
                )}
              </Tooltip>
            )}
          </MapTools>
        </MapSidebarContentItemSection>
      )}
      <MapSidebarContentItemSection>
        <NodeList
          onItemClick={focusNode}
          validate={reportEditor.report.nodes.length < 15}
        />
      </MapSidebarContentItemSection>
    </MapSidebarContentItem>
  );
};

export default ReportMapSidebarNodes;
