import { ModalBody, ModalHeader } from 'preshape';
import React from 'react';
import MemberInsightView from '../../../components/Insights/MemberInsightView';
import useMeOrgMember from '../../../hooks/useMeOrgMember';


type Props = {
  onClose: () => void;
}

const MyPerformancePage = ({ onClose}: Props) => {
  const { query } = useMeOrgMember()

  return (
    <>
      <ModalHeader>My performance</ModalHeader>
      <ModalBody>
        <MemberInsightView onClose={onClose} member={query.data} />
      </ModalBody>
    </>
  );
};

export default MyPerformancePage;
