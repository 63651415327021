import { Box, Button, Label, Text } from 'preshape';
import React from 'react';

type Props = {
  onClick: () => void;
  icon: JSX.Element;
  text: string;
  count?: number;
  label?: string;
};

const Tile = ({ onClick, text, icon, count, label }: Props) => {
  return (
    <Button
      onClick={onClick}
      flex="vertical"
      alignChildrenHorizontal="middle"
      maxWidth="150px"
      alignChildrenVertical="middle"
      gap="x1"
      backgroundColor="accent-shade-1"
      padding="x3"
      borderColor="accent-shade-5"
      borderSize="x1"
      borderRadius="10px"
      backgroundColorActive="accent-shade-5"
      textColorActive="white"
      textColor="accent-shade-5"
      grow
    >
      <Box flex="horizontal" gap="x2" alignChildrenVertical="middle">
        <Text>{icon}</Text>
      </Box>
      <Text size="x1" strong uppercase>
        {text}
      </Text>
      {count !== undefined && (
        <Box>
          <Label size="x1" backgroundColor="accent-shade-5" textColor="white">
            {count}
          </Label>
        </Box>
      )}
      {label && (
        <Box>
          <Label size="x1" backgroundColor="accent-shade-5" textColor="white">
            {label}
          </Label>
        </Box>
      )}
    </Button>
  );
};

export default Tile;
