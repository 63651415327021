"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateChars = exports.getRandomCharCode = void 0;
const getRandomCharCode = () => Math.floor(Math.random() * (90 - 65) + 65);
exports.getRandomCharCode = getRandomCharCode;
const generateChars = (length = 4) => {
    const randomCharNumbers = Array.from({ length }).map(() => (0, exports.getRandomCharCode)());
    return String.fromCharCode(...randomCharNumbers);
};
exports.generateChars = generateChars;
