import { isSection } from '@drainify/utils';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HiddenContent from '../../../components/HiddenContent/HiddenContent';
import PageContext from '../../../components/Page/PageContext';
import { useProjectContext } from '../../../components/Project/ProjectProvider';
import { useReportEditorContext } from '../../../components/Report/ReportEditorProvider';
import { ReportErrorMessageInteractive } from '../../../components/Report/ReportErrorMessage';
import SectionAdditionalSummary from '../../../components/Section/SectionAdditionalSummary';
import SectionDeleteButton from '../../../components/Section/SectionDeleteButton';
import SectionSummary from '../../../components/Section/SectionSummary';
import Page from '../Layout/Page';
import PageBody from '../Layout/PageBody';
import PageHeader from '../Layout/PageHeader';
import PageMenu from '../Layout/PageMenu';

const SectionPage = () => {
  const { sectionId } = useParams();
  const { reportEditor, editSection } = useReportEditorContext();
  const section = reportEditor.getSectionByUid(sectionId);
  const { activeBooking } = useProjectContext();

  const navigate = useNavigate();
  const error = reportEditor.validateSection(section);

  return (
    <PageContext
      backToLink={`../?activeBookingId=${activeBooking?.uid}`}
      backToText="Sections"
      title={reportEditor.getSectionName(section) || 'Missing'}
    >
      <Page>
        <PageHeader />
        <PageBody>
          <PageMenu margin="x3">
            {error && isSection(section) && (
              <ReportErrorMessageInteractive
                errors={error}
                onEdit={(step) => editSection(section?.uid, step)}
                margin="x3"
              />
            )}
            <SectionSummary section={section} />
            <HiddenContent content="Additional section info">
              <SectionAdditionalSummary section={section} />
            </HiddenContent>
          </PageMenu>
        </PageBody>
        <SectionDeleteButton
          sectionUid={section?.uid}
          variant="tertiary"
          grow
          onDelete={() => navigate(`..?activeBookingId=${activeBooking?.uid}`)}
        />
      </Page>
    </PageContext>
  );
};

export default SectionPage;
