import { inspectionAttributeApplicable } from '@drainify/types';
import { sectionAttributeApplicable } from '@drainify/utils';
import React, { PropsWithChildren } from 'react';
import { useProjectContext } from '../Project/ProjectProvider';
import WizardStep from './WizardStep';

export type WizardStepProps = {
  action?: JSX.Element;
  id: string;
  title?: string;
  withSearch?: boolean;
};

const ProjectTypeWizardStep = ({
  children,
  id,
  title,
}: PropsWithChildren<WizardStepProps>) => {
  const { activeBooking } = useProjectContext();

  const shouldRender =
    inspectionAttributeApplicable(id, activeBooking?.projectType) ||
    sectionAttributeApplicable(id, activeBooking?.projectType);

  if (!shouldRender) {
    return null;
  }

  return (
    <WizardStep id={id} title={title}>
      {children}
    </WizardStep>
  );
};

export default ProjectTypeWizardStep;
