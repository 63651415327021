import { Section, Node, Inspection } from '@drainify/types';
import {
  Box,
  Button,
  ButtonProps,
  Modal,
  ModalBody,
  ModalHeader,
  Text,
} from 'preshape';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useBooking from '../../hooks/useBooking';
import useMeOrgMember from '../../hooks/useMeOrgMember';
import InspectionPostInspectionModal from '../../pages/mobile/Pages/InspectionPostInspectionModal';
import { isMobile } from '../../utils/client';
import Card from '../Card/Card';
import DataTable from '../DataTable/DataTable';
import DataTableGroup from '../DataTable/DataTableGroup';
import DataTableItem from '../DataTable/DataTableItem';
import InfiniteScroll from '../InfiniteScroll/InfiniteScroll';
import InspectionView from '../Inspection/InspectionView';
import NodeView from '../Node/NodeView';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import ReportMapSidebarSectionsBulkUpdate from '../Report/ReportMap/ReportMapSidebar/ReportMapSidebarSectionsBulkUpdate';
import SectionView from '../Section/SectionView';

type Props = ButtonProps & {
  nodeErrors: Node[];
  sectionErrors: Section[];
  inspectionErrors: Inspection[];
  validate: () => void;
};

const JobErrorReportButton = ({
  nodeErrors,
  sectionErrors,
  inspectionErrors,
  validate,
  ...rest
}: Props) => {

  const { activeBookingId, project } = useProjectContext();
  const { editNode } = useReportEditorContext();
  const { query } = useBooking(activeBookingId);
  const activeBooking = query.data;
  const { query: meQuery } = useMeOrgMember();
  const [errorReportModalVisible, setErrorReportModalVisible] =
    React.useState(true);
  const { reportEditor } = useReportEditorContext();

  const [sectionNumber, setSectionNumber] = React.useState(5);
  const [nodeNumber, setNodeNumber] = React.useState(5);
  const [fixing, setFixing] = React.useState<Section>()

  const navigate = useNavigate()

  if (!query.data || !meQuery.data || !activeBooking) {
    return null;
  }

  return (
    <>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setErrorReportModalVisible(true);
        }}
        variant="primary"
        color="positive"
        {...rest}
      >
        <Box flex="horizontal" gap="x1">
          <Text textColor="white">Submit</Text>
        </Box>
      </Button>
      <Modal
        visible={errorReportModalVisible}
        onClose={() => {
          setErrorReportModalVisible(false);
        }}
      >
        <ModalHeader>Report Errors</ModalHeader>
        <ModalBody>
          {sectionErrors && sectionErrors.length > 0 && (
            <Card title='Sections' action={<ReportMapSidebarSectionsBulkUpdate inline />}>
              <DataTable>
                <DataTableGroup >
                  {sectionErrors
                    .filter((e) => reportEditor.getSectionError(e.uid, true))
                    .slice(0, sectionNumber)
                    .map((section) => (
                      <DataTableItem onClick={() => setFixing(section)} key={section.uid}>
                        <SectionView section={section} />
                      </DataTableItem>
                    ))}
                </DataTableGroup>
              </DataTable>
            </Card>
          )}

          {inspectionErrors && inspectionErrors.length > 0 && (
            <Card title='Inspections'>
              <DataTable>
                <DataTableGroup >
                  {inspectionErrors
                    .filter((e) => reportEditor.getInspectionError(e.uid))
                    .slice(0, sectionNumber)
                    .map((inspection) => (
                      <DataTableItem key={inspection.uid} onClick={() => { 

                        isMobile() ?
                        navigate(`/projects/${project?.uid}/report/sections/snapshot/${inspection.sectionUid}/inspections/${inspection.uid}?activeBookingId=${activeBooking.uid}`)
                        : navigate(`/projects/${project?.uid}/inspections/${inspection.uid}?activeBookingId=${activeBooking.uid}`)
                        
                      }}>
                        <InspectionView inspection={inspection} />
                      </DataTableItem>
                    ))}
                </DataTableGroup>
              </DataTable>
            </Card>
          )}

          {nodeErrors && nodeErrors.length > 0 && (
            <DataTable>
              <DataTableGroup title="Nodes">
                {nodeErrors.slice(0, nodeNumber).map((node) => (
                  <DataTableItem
                    key={node.uid}
                    onClick={(e) => {
                      e.stopPropagation();
                      setErrorReportModalVisible(false);
                      editNode(node.uid);
                    }}
                  >
                    <NodeView node={node} />
                  </DataTableItem>
                ))}
              </DataTableGroup>
            </DataTable>
          )}
          {nodeErrors && nodeNumber < nodeErrors?.length && (
            <InfiniteScroll onMore={() => setNodeNumber(nodeNumber + 5)} />
          )}







          {sectionErrors && sectionNumber < sectionErrors?.length && (
            <InfiniteScroll
              onMore={() => setSectionNumber(sectionNumber + 5)}
            />
          )}
          {
            fixing && <InspectionPostInspectionModal visible={fixing !== undefined} onClose={() => {
              validate()
              setFixing(undefined)
            }} onFinish={() => setFixing(undefined)} sectionUid={fixing.uid}/>
          }
        </ModalBody>
      </Modal>
    </>
  );
};

export default JobErrorReportButton;
