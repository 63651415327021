"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNodeSchema = exports.nodes = void 0;
const types_1 = require("@drainify/types");
const schema_1 = require("./schema");
exports.nodes = [
    {
        code: types_1.NodeCode.MH,
        name: {
            [types_1.LanguageCodes.EN]: 'Manhole',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.NodeCode.IC,
        name: {
            [types_1.LanguageCodes.EN]: 'Inspection Chamber',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.NodeCode.WC,
        name: {
            [types_1.LanguageCodes.EN]: 'Water Closet',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.NodeCode.RWG,
        name: {
            [types_1.LanguageCodes.EN]: 'Surface Water Gully',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.NodeCode.FWG,
        name: {
            [types_1.LanguageCodes.EN]: 'Foul Water Gully',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.NodeCode.SVP,
        name: {
            [types_1.LanguageCodes.EN]: 'Soil vent pipe',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.NodeCode.CWG,
        name: {
            [types_1.LanguageCodes.EN]: 'Combined Waste Gully',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.NodeCode.TNK,
        name: {
            [types_1.LanguageCodes.EN]: 'Septic Tank',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.NodeCode.PIT,
        name: {
            [types_1.LanguageCodes.EN]: 'Cess Pit',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.NodeCode.BN,
        name: {
            [types_1.LanguageCodes.EN]: 'Buchan Trap',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.NodeCode.UNP,
        name: {
            [types_1.LanguageCodes.EN]: 'Unknown point',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.NodeCode.BR,
        name: {
            [types_1.LanguageCodes.EN]: 'Major connection without manhole',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.NodeCode.RWP,
        name: {
            [types_1.LanguageCodes.EN]: 'Rain water pipe',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.NodeCode.CP,
        name: {
            [types_1.LanguageCodes.EN]: 'Catchpit',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.NodeCode.GY,
        name: {
            [types_1.LanguageCodes.EN]: 'Gully',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.NodeCode.LH,
        name: {
            [types_1.LanguageCodes.EN]: 'Lamphole',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.NodeCode.OC,
        name: {
            [types_1.LanguageCodes.EN]: 'Other special chamber',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
    },
    {
        code: types_1.NodeCode.OF,
        name: {
            [types_1.LanguageCodes.EN]: 'Outfall',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
    },
    {
        code: types_1.NodeCode.OS,
        name: {
            [types_1.LanguageCodes.EN]: 'Oil Separator',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL],
    },
    {
        code: types_1.NodeCode.UUA,
        name: {
            [types_1.LanguageCodes.EN]: 'Underground Utility Access',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.NodeCode.RE,
        name: {
            [types_1.LanguageCodes.EN]: 'Rodding eye',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.NodeCode.SK,
        name: {
            [types_1.LanguageCodes.EN]: 'Soakaway',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.NodeCode.RG,
        name: {
            [types_1.LanguageCodes.EN]: 'Running trap',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.NodeCode.WR,
        name: {
            [types_1.LanguageCodes.EN]: 'Winser trap',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: 'TODO',
        },
        projectTypes: [types_1.ProjectType.MSCC5_COMMERERCIAL, types_1.ProjectType.MSCC5_DOMESTIC],
    },
    {
        code: types_1.NodeCode.ABP,
        name: {
            [types_1.LanguageCodes.EN]: 'Access Point Backflow Preventer',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: "Point d'accès – Dispositif antirefoulement",
        },
        projectTypes: [types_1.ProjectType.LACP],
    },
    {
        code: types_1.NodeCode.ACOS,
        name: {
            [types_1.LanguageCodes.EN]: 'Access Point Cleanout Saddle',
            [types_1.LanguageCodes.ES]: 'Acceso Servicio Silla',
            [types_1.LanguageCodes.FR]: "Point d'accès – Regard de nettoyage – Avec sellette",
        },
        projectTypes: [types_1.ProjectType.LACP],
    },
    {
        code: types_1.NodeCode.ALT,
        name: {
            [types_1.LanguageCodes.EN]: 'Access Point Lateral Traps',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: "Point d'accès – Siphon de branchement",
        },
        projectTypes: [types_1.ProjectType.LACP],
    },
    {
        code: types_1.NodeCode.AML,
        name: {
            [types_1.LanguageCodes.EN]: 'Access Point Mainline',
            [types_1.LanguageCodes.ES]: 'Colector',
            [types_1.LanguageCodes.FR]: "Point d'accès – Conduite principale",
        },
        projectTypes: [types_1.ProjectType.LACP],
    },
    {
        code: types_1.NodeCode.ARV,
        name: {
            [types_1.LanguageCodes.EN]: 'Access Point Roof Vent',
            [types_1.LanguageCodes.ES]: '',
            [types_1.LanguageCodes.FR]: "Point d'accès – Évent de toiture",
        },
        projectTypes: [types_1.ProjectType.LACP],
    },
    {
        code: types_1.NodeCode.AW,
        name: {
            [types_1.LanguageCodes.EN]: 'Access Point Wye',
            [types_1.LanguageCodes.ES]: 'Yee',
            [types_1.LanguageCodes.FR]: "Point d'accès – Raccord en Y",
        },
        projectTypes: [types_1.ProjectType.LACP],
    },
    {
        code: types_1.NodeCode.AWD,
        name: {
            [types_1.LanguageCodes.EN]: 'Access Point Double Wye',
            [types_1.LanguageCodes.ES]: 'Doble Yee',
            [types_1.LanguageCodes.FR]: "Point d'accès – Raccord en Y double",
        },
        projectTypes: [types_1.ProjectType.LACP],
    },
    {
        code: types_1.NodeCode.ACB,
        name: {
            [types_1.LanguageCodes.EN]: 'Access Point Catch Basin',
            [types_1.LanguageCodes.ES]: 'Pozo de Aguas Lluvias',
            [types_1.LanguageCodes.FR]: "Point d'accès – Puisard",
        },
        projectTypes: [types_1.ProjectType.PACP],
    },
    {
        code: types_1.NodeCode.ACOH,
        name: {
            [types_1.LanguageCodes.EN]: 'Access Point Cleanout House',
            [types_1.LanguageCodes.ES]: "Point d'accès – Regard de nettoyage – Bâtiment",
            [types_1.LanguageCodes.FR]: 'Punto de Acceso Casa',
        },
        projectTypes: [types_1.ProjectType.PACP],
    },
    {
        code: types_1.NodeCode.ACOM,
        name: {
            [types_1.LanguageCodes.EN]: 'Access Point Cleanout Mainline',
            [types_1.LanguageCodes.ES]: 'Punto de Acceso Colector',
            [types_1.LanguageCodes.FR]: "Point d'accès – Regard de nettoyage – Conduite principale",
        },
        projectTypes: [types_1.ProjectType.PACP],
    },
    {
        code: types_1.NodeCode.ACOP,
        name: {
            [types_1.LanguageCodes.EN]: 'Access Point Cleanout Propertyline',
            [types_1.LanguageCodes.ES]: 'Punto de Acceso Propiedad',
            [types_1.LanguageCodes.FR]: "Point d'accès – Regard de nettoyage – Propriété privée",
        },
        projectTypes: [types_1.ProjectType.PACP],
    },
    {
        code: types_1.NodeCode.ADP,
        name: {
            [types_1.LanguageCodes.EN]: 'Access Point Discharge Point',
            [types_1.LanguageCodes.ES]: 'Punto de Descarga',
            [types_1.LanguageCodes.FR]: "Point d'accès – Point de déversement",
        },
        projectTypes: [types_1.ProjectType.PACP],
    },
    {
        code: types_1.NodeCode.AEP,
        name: {
            [types_1.LanguageCodes.EN]: 'Access Point End of Pipe',
            [types_1.LanguageCodes.ES]: 'Fin de la Tubería',
            [types_1.LanguageCodes.FR]: "Point d'accès – Extrémité de la conduite",
        },
        projectTypes: [types_1.ProjectType.PACP],
    },
    {
        code: types_1.NodeCode.AJB,
        name: {
            [types_1.LanguageCodes.EN]: 'Access Point Junction Box',
            [types_1.LanguageCodes.ES]: 'Caja de Instersección',
            [types_1.LanguageCodes.FR]: "Point d'accès – Boîte de jonction",
        },
        projectTypes: [types_1.ProjectType.PACP],
    },
    {
        code: types_1.NodeCode.AM,
        name: {
            [types_1.LanguageCodes.EN]: 'Access Point Meter',
            [types_1.LanguageCodes.ES]: 'Medidor',
            [types_1.LanguageCodes.FR]: "Point d'accès – Chambre de débitmètre",
        },
        projectTypes: [types_1.ProjectType.PACP],
    },
    {
        code: types_1.NodeCode.AMH,
        name: {
            [types_1.LanguageCodes.EN]: 'Access Point Manhole',
            [types_1.LanguageCodes.ES]: 'Pozo de Acceso',
            [types_1.LanguageCodes.FR]: "Point d'accès – Regard d'égout",
        },
        projectTypes: [types_1.ProjectType.PACP],
    },
    {
        code: types_1.NodeCode.AOC,
        name: {
            [types_1.LanguageCodes.EN]: 'Access Point Special Chamber',
            [types_1.LanguageCodes.ES]: 'Cámaras Especiales',
            [types_1.LanguageCodes.FR]: "Point d'accès – Autre chambre spéciale",
        },
        projectTypes: [types_1.ProjectType.PACP],
    },
    {
        code: types_1.NodeCode.ATC,
        name: {
            [types_1.LanguageCodes.EN]: 'Access Point Tee Connection',
            [types_1.LanguageCodes.ES]: 'Conexión en Tee',
            [types_1.LanguageCodes.FR]: "Point d'accès – Raccord en T",
        },
        projectTypes: [types_1.ProjectType.PACP],
    },
    {
        code: types_1.NodeCode.AWA,
        name: {
            [types_1.LanguageCodes.EN]: 'Access Point Wastewater Access Device',
            [types_1.LanguageCodes.ES]: 'Acceso de Aguas Residuales',
            [types_1.LanguageCodes.FR]: "Point d'accès – Cheminée d’accès",
        },
        projectTypes: [types_1.ProjectType.PACP],
    },
    {
        code: types_1.NodeCode.AWW,
        name: {
            [types_1.LanguageCodes.EN]: 'Access Point Wet Well',
            [types_1.LanguageCodes.ES]: 'Cámara de Bombeo',
            [types_1.LanguageCodes.FR]: "Point d'accès – Puits humide",
        },
        projectTypes: [types_1.ProjectType.PACP],
    },
    {
        code: types_1.NodeCode.AZ,
        name: {
            [types_1.LanguageCodes.EN]: 'Access Point Other',
            [types_1.LanguageCodes.ES]: 'TODO',
            [types_1.LanguageCodes.FR]: "Point d'accès – Autre",
        },
        projectTypes: [types_1.ProjectType.PACP],
    },
];
exports.getNodeSchema = (0, schema_1.createSchemaLookup)(exports.nodes);
