import {
  Box,
  Icons,
  Input,
  Motion,
  Placement,
  PlacementContent,
  PlacementManager,
  PlacementReference,
  transitionTimeBox,
} from 'preshape';
import React, { useRef, useState } from 'react';
import ProjectSearchResults from './ProjectSearchResults';

const ProjectSearch = () => {
  const refTimeout = useRef<number>();
  const [hasFocus, setHasFocus] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [searchString, setSearchString] = useState<string>('');

  const handleStartSearch = () => {
    setHasFocus(true);
    refTimeout.current = window.setTimeout(() => {
      setShowResults(true);
    }, transitionTimeBox);
  };

  const handleEndSearch = () => {
    setHasFocus(false);
    setShowResults(false);
    setSearchString('');
  };

  return (
    <PlacementManager>
      <PlacementReference>
        {(props) => (
          <Motion
            animate={{
              width: hasFocus ? 'min(100%, 400px)' : 'min(100%, 200px)',
            }}
            initial={{ width: 'min(100%, 200px)' }}
            style={{ maxWidth: '100%' }}
            transition={{ bounceStiffness: 100, bounceDamping: 8 }}
          >
            <Box {...props}>
              <Input
                addonStart={<Icons.Search />}
                backgroundColor="background-shade-2"
                borderSize="x0"
                placeholder="Search..."
                {...props}
                onChange={(event: any) => setSearchString(event.target.value)}
                value={searchString}
                onFocus={handleStartSearch}
              />
            </Box>
          </Motion>
        )}
      </PlacementReference>

      <Placement
        animation="FadeSlideUp"
        options={{
          modifiers: {
            preventOverflow: {
              boundariesElement: 'window',
            },
          },
        }}
        onClose={handleEndSearch}
        placement="bottom"
        visible={showResults}
        width="reference"
      >
        {searchString !== '' && (
          <PlacementContent
            backgroundColor="background-shade-1"
            borderColor="background-shade-3"
            borderSize="x2"
            borderRadius="x3"
            elevate
            overflow="auto"
            paddingBottom="x4"
            paddingHorizontal="x4"
            paddingVertical="x4"
            maxHeight={400}
          >
            <ProjectSearchResults
              onEndSearch={handleEndSearch}
              searchString={searchString}
            />
          </PlacementContent>
        )}
      </Placement>
    </PlacementManager>
  );
};

export default ProjectSearch;
