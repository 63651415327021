import { InviteState } from '@drainify/types';
import { getFullFilePath, runValidations } from '@drainify/utils';
import { FirebaseError } from 'firebase/app';
import {
  signOut,
  getAuth,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import {
  Alert,
  Box,
  Button,
  FormProvider,
  Icons,
  Input,
  InputLabel,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from 'preshape';
import React, { ChangeEvent } from 'react';
import { useNavigate, useParams } from 'react-router';
import useFetch from '../components/Api/useFetch';
import { FULL_SCREEN_MODAL_WIDTH } from '../components/App/App';
import Divider from '../components/Divider/Divider';
import ImageFromUrl from '../components/Image/ImageFromUrl';
import Spinner from '../components/Spinner/Spinner';
import SummaryCard from '../components/SummaryCard/SummaryCard';
import SummaryCardSection from '../components/SummaryCard/SummaryCardSection';
import Wizard from '../components/Wizard/Wizard';
import WizardControls from '../components/Wizard/WizardControls/WizardControls';
import WizardReviewStep from '../components/Wizard/WizardReviewStep';
import WizardStep from '../components/Wizard/WizardStep';
import WizardStepError from '../components/Wizard/WizardStepError';
import useInviteAcceptForm, {
  inviteAcceptValidations,
} from '../hooks/forms/useInviteAcceptForm';
import useInvite from '../hooks/useInvite';

const InviteAcceptPage = () => {
  const { inviteUid } = useParams();
  const form = useInviteAcceptForm();
  const [stepId, setStepId] = React.useState<string>();
  const error = runValidations(inviteAcceptValidations, form.state);
  const { query } = useInvite({ inviteId: inviteUid });
  const fetch = useFetch();
  const navigate = useNavigate();
  const [visible, setIsVisible] = React.useState(true);
  const [isError, setError] = React.useState<string>();
  const [isLoading, setIsLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  React.useEffect(() => {
    signOut(getAuth());
  }, []);

  const acceptInvite = async () => {
    setError(undefined);
    const auth = getAuth();
    if (query.data?.recipient && inviteUid) {
      try {
        const a = await createUserWithEmailAndPassword(
          auth,
          query.data.recipient,
          form.state.password
        );
        await fetch('/invite/:inviteId/accept', {
          method: 'POST',
          params: { inviteId: inviteUid },
          body: {
            authUid: a.user.uid,
            fullName: form.state.fullName,
            phoneNumber: form.state.phoneNumber,
          },
        });
        setIsVisible(false);
        navigate('/');
      } catch (error) {
        if (error instanceof FirebaseError) {
          if (error.code === 'auth/email-already-in-use') {
            setError('This email is already in use');
          }
        } else {
          setError('Something went wrong! Please try again soon.');
        }
      }
      setIsLoading(false);
    }
  };

  if (query.isLoading) {
    return <Spinner />;
  }

  if (query.data?.state !== InviteState.PENDING) {
    return (
      <Box flex="vertical" grow>
        <Box
          alignChildren="middle"
          backgroundColor="background-shade-3"
          flex="vertical"
          grow
          padding="x12"
        >
          <Box>This invite has expired</Box>
        </Box>
      </Box>
    );
  }

  return (
    <FormProvider form={form}>
      <Wizard
        flow="create"
        initialActiveStepId={stepId}
        isError={isError !== undefined}
        isLoading={isLoading}
        onSave={async () => {
          setIsLoading(true);
          await acceptInvite();
        }}
      >
        <Modal
          animation="FadeSlideUp"
          margin="x4"
          maxWidth={FULL_SCREEN_MODAL_WIDTH}
          // onClose={onClose}
          overlayBackgroundCloseOnClick={false}
          visible={visible}
        >
          <ModalHeader>Invite</ModalHeader>
          <ModalBody>
            <WizardStep id="welcome">
              <Box>
                <Text size="x6" padding="x6" flex="horizontal">
                  Welcome to{' '}
                  <Text strong paddingLeft="x2">
                    Drainify.
                  </Text>
                </Text>
                <Box
                  flex="horizontal"
                  alignChildrenHorizontal="around"
                  alignChildrenVertical="middle"
                >
                  <Box width="80px" height="80px">
                    <ImageFromUrl
                      src={getFullFilePath(query.data?.org.logoUrlCropped)}
                      height="100%"
                    />
                  </Box>
                </Box>
              </Box>
              <Box padding="x3">
                <Text>
                  You have been invited to join {query.data?.org.name}
                </Text>
              </Box>
            </WizardStep>

            <WizardStep id="fullName" title="Name">
              <InputLabel>
                <Input
                  name="name"
                  placeholder="e.g. John Smith"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    form.setState((s) => ({ ...s, fullName: e.target.value }))
                  }
                  value={form.state.fullName}
                />
              </InputLabel>
            </WizardStep>

            <WizardStep id="phoneNumber" title="Phone number">
              <InputLabel>
                <Input
                  name="phoneNumber"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      form.setState((s) => ({
                        ...s,
                        phoneNumber: e.target.value,
                      }));
                  }}
                  value={form.state.phoneNumber}
                />
              </InputLabel>
            </WizardStep>

            <WizardStep id="confirmPassword" title="Password">
              <Box flex="vertical" gap="x3">
                <Input
                  name="email"
                  readOnly
                  value={query.data.recipient}
                  borderSize="x0"
                />

                <InputLabel label="Enter password">
                  <Input
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      form.setState((s) => ({
                        ...s,
                        password: e.target.value,
                      }))
                    }
                    value={form.state.password}
                  />
                </InputLabel>
                <InputLabel label="Confirm password">
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    disabled={!!form.error['password']}
                    name="confirmPassword"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      form.setState((s) => ({
                        ...s,
                        confirmPassword: e.target.value,
                      }))
                    }
                    value={form.state.confirmPassword}
                  />
                </InputLabel>
                <Divider />
                <Button
                  flex="horizontal"
                  gap="x2"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <>
                      <Icons.EyeOff /> Hide password
                    </>
                  ) : (
                    <>
                      <Icons.Eye /> Show password
                    </>
                  )}
                </Button>
              </Box>
            </WizardStep>
            <WizardReviewStep>
              <SummaryCard title="Accept invite">
                <SummaryCardSection
                  error={error?.['fullname']}
                  onEdit={() => setStepId('fullName')}
                  title="Name"
                  value={form.state.fullName}
                >
                  {form.state.fullName}
                </SummaryCardSection>

                <SummaryCardSection
                  error={error?.['confirmPassword']}
                  onEdit={() => setStepId('confirmPassword')}
                  title="Password"
                >
                  {form.state.password.replace(/./g, '*')}
                </SummaryCardSection>
              </SummaryCard>
              {isError && (
                <Alert
                  color="negative"
                  padding="x3"
                  backgroundColor="negative-shade-2"
                >
                  <Text strong>{isError}</Text>
                </Alert>
              )}
            </WizardReviewStep>
          </ModalBody>
          <ModalFooter>
            <WizardStepError />
            <WizardControls />
          </ModalFooter>
        </Modal>
      </Wizard>
    </FormProvider>
  );
};

export default InviteAcceptPage;
