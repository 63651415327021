import { Observation, ObservationCode } from '@drainify/types';
import { Appear, AppearProps, Attributes, Box, Icons } from 'preshape';
import React from 'react';
import { useReportMapContext } from '../../ReportMapProvider';
import ReportMapObservationTooltip from '../ReportMapObservationTooltip';
import ReportMapObservationSA from './SA';
import ReportMapObservationWL from './WL';

export type ReportMapObservationRendererProps = Attributes<
  HTMLElement,
  AppearProps & {
    observation: Observation;
    visible?: boolean;
    showOnHover?: boolean;
  }
>;

export type ReportMapObservationRendererType = (
  props: ReportMapObservationRendererProps
) => JSX.Element | null;

const reportMapObservationsMap: Partial<
  Record<ObservationCode, ReportMapObservationRendererType>
> = {
  [ObservationCode.WL]: ReportMapObservationWL,
  [ObservationCode.WL_MSCC5_DOMESTIC]: ReportMapObservationWL,
  [ObservationCode.SA]: ReportMapObservationSA,
};

const ReportMapObservationRenderer: ReportMapObservationRendererType = (
  props
) => {
  const { focusObservation } = useReportMapContext();
  for (let i = props.observation.code.length; i--; i >= 0) {
    const Renderer = reportMapObservationsMap[props.observation.code[i]];

    if (Renderer) {
      return (
        <Renderer
          {...props}
          onClick={() =>
            props.showOnHover
              ? focusObservation(props.observation.uid)
              : undefined
          }
        />
      );
    }
  }

  const { observation, visible } = props;

  return (
    <ReportMapObservationTooltip
      observation={observation}
      showOnHover={props.showOnHover}
    >
      <Appear animation="Pop" visible={visible}>
        <Box
          backgroundColor="highlight"
          borderColor="text-shade-1"
          borderSize="x2"
          borderRadius="full"
          textColor="dark-shade-1"
          onClick={() =>
            props.showOnHover
              ? focusObservation(props.observation.uid)
              : undefined
          }
        >
          <Icons.AlertCircle size="1rem" />
        </Box>
      </Appear>
    </ReportMapObservationTooltip>
  );
};

export default ReportMapObservationRenderer;
