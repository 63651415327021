import { ResponseError, User } from '@drainify/types';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';
import { UseQueryResult } from 'react-query';
import useMe from '../../hooks/useMe';
import useUser from '../../hooks/useUser';
import UserModal from './UserModal';

type Context = {
  isUpdating: boolean;
  isUpdatingFailed: boolean;
  isUpdatingSuccess: boolean;
  user?: User;
  query: UseQueryResult<User, ResponseError>;
  closeModal: () => void;
  edit: (stepId?: string) => void;
  update: (user: User) => Promise<void>;
};

const UserContext = createContext<Context>({
  isUpdating: false,
  isUpdatingFailed: false,
  isUpdatingSuccess: false,
  user: {} as unknown as User,
  query: {} as UseQueryResult<User, ResponseError>,
  closeModal: () => {},
  edit: () => {},
  update: () => Promise.reject(new Error('Not implemented')),
});

export const useUserContext = () => useContext(UserContext);

const UserProvider = ({ children }: PropsWithChildren<{}>) => {
  const userId = useMe().query.data?.uid;
  const { query, update } = useUser({ userId });
  const [activeEditStep, setActiveEditStep] = useState<string>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = (stepId?: string) => {
    setIsModalOpen(true);
    setActiveEditStep(stepId);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setActiveEditStep(undefined);
  };

  const handleUpdate = async (user: User) => {
    await update.mutateAsync(user);
  };

  const context: Context = {
    isUpdating: update.isLoading,
    isUpdatingFailed: update.isError,
    isUpdatingSuccess: update.isSuccess,
    user: query.data,
    query,
    closeModal: handleCloseModal,
    edit: handleOpenModal,
    update: handleUpdate,
  };

  return (
    <UserContext.Provider value={context}>
      {children}

      <UserModal
        initialActiveStepId={activeEditStep}
        onClose={handleCloseModal}
        visible={isModalOpen}
      />
    </UserContext.Provider>
  );
};

export default UserProvider;
