import { Invite, Member, OrgRole } from '@drainify/types';
import groupBy from 'lodash.groupby';
import React from 'react';
import useMeOrg from '../../hooks/useMeOrg';
import useOrgInvites from '../../hooks/useOrgInvites';
import useOrgMembers from '../../hooks/useOrgMembers';
import DataQueryTable from '../DataTable/DataQueryTable';
import DataTableGroup from '../DataTable/DataTableGroup';
import InviteModal from '../Invite/InviteModal';
import UserView from '../User/UserView';
import MemberDataTableRow from './MemberDataTableRow';
import MemberInviteDataTableRow from './MemberInviteDataTableRow';
import MemberModal from './MemberModal';

const MemberDataTable = () => {
  const { query: queryMeOrg } = useMeOrg();
  const { query: queryInviteOrg } = useOrgInvites(queryMeOrg.data?.uid);
  const { query } = useOrgMembers(queryMeOrg.data?.uid);
  const [memberModalVisible, setMemberModalVisible] = React.useState(false);
  const [inviteModalVisible, setInviteModalVisible] = React.useState(false);
  const [editingMember, setEditingMember] = React.useState<Member>();
  const [editingInvite, setEditingInviteMember] = React.useState<Invite>();

  const editMember = (member: Member) => {
    setEditingMember(member);
    setMemberModalVisible(true);
  };

  const editInvite = (invite: Invite) => {
    setEditingInviteMember(invite);
    setInviteModalVisible(true);
  };

  const groupedMembers = query.data
    ? groupBy(query.data.data, ({ role }) => role)
    : {};

  const groupedInvites = queryInviteOrg.data
    ? groupBy(queryInviteOrg.data.data, ({ role }) => role)
    : {};

  return (
    <>
      <DataQueryTable
        entity="members"
        placeholder={<UserView />}
        query={[queryMeOrg, query]}
      >
        {(groupedMembers[OrgRole.OWNER] || groupedInvites[OrgRole.OWNER]) && (
          <DataTableGroup title="Owners">
            {groupedMembers[OrgRole.OWNER] &&
              groupedMembers[OrgRole.OWNER].map((member) => (
                <MemberDataTableRow
                  member={member}
                  key={member.uid}
                  onClick={() => editMember(member)}
                />
              ))}

            {groupedInvites[OrgRole.OWNER] &&
              groupedInvites[OrgRole.OWNER].map((invite) => (
                <MemberInviteDataTableRow
                  invite={invite}
                  onClick={() => editInvite(invite)}
                  key={invite.uid}
                />
              ))}
          </DataTableGroup>
        )}

        {(groupedMembers[OrgRole.ADMIN] || groupedInvites[OrgRole.ADMIN]) && (
          <DataTableGroup title="Admins">
            {groupedMembers[OrgRole.ADMIN] &&
              groupedMembers[OrgRole.ADMIN].map((member) => (
                <MemberDataTableRow
                  member={member}
                  key={member.uid}
                  onClick={() => editMember(member)}
                />
              ))}

            {groupedInvites[OrgRole.ADMIN] &&
              groupedInvites[OrgRole.ADMIN].map((invite) => (
                <MemberInviteDataTableRow
                  invite={invite}
                  onClick={() => editInvite(invite)}
                  key={invite.uid}
                />
              ))}
          </DataTableGroup>
        )}

        {(groupedMembers[OrgRole.ENGINEER] ||
          groupedInvites[OrgRole.ENGINEER]) && (
          <DataTableGroup title="Engineers">
            {groupedMembers[OrgRole.ENGINEER] &&
              groupedMembers[OrgRole.ENGINEER].map((member) => (
                <MemberDataTableRow
                  member={member}
                  key={member.uid}
                  onClick={() => editMember(member)}
                />
              ))}

            {groupedInvites[OrgRole.ENGINEER] &&
              groupedInvites[OrgRole.ENGINEER].map((invite) => (
                <MemberInviteDataTableRow
                  invite={invite}
                  onClick={() => editInvite(invite)}
                  key={invite.uid}
                />
              ))}
          </DataTableGroup>
        )}
      </DataQueryTable>

      {editingMember && (
        <MemberModal
          visible={memberModalVisible}
          onClose={() => setMemberModalVisible(false)}
          member={editingMember}
        />
      )}

      {editingInvite && (
        <InviteModal
          visible={inviteModalVisible}
          onClose={() => setInviteModalVisible(false)}
          invite={editingInvite}
        />
      )}
    </>
  );
};

export default MemberDataTable;
