import { OCRResult, OCRResultStatus } from '@drainify/types';
import { getFullFilePath } from '@drainify/utils';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import {
  Alert,
  Appear,
  Box,
  Button,
  Icons,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Text,
} from 'preshape';
import { AlertTriangle } from 'preshape/lib/cjs/Icon';
import React from 'react';
import AIPOIBuddyUpsellButton from '../components/AI/AIPOIBuddyUpsellButton';
import BuddyQAReviewButton from '../components/BuddyReviewTool/BuddyQAReviewButton';
import DistanceInput from '../components/DistanceInput/DistanceInput';
import Explainer from '../components/Explainer/Explainer';
import FeatureFlag from '../components/FeatureFlag/FeatureFlag';
import ImageFromUrl from '../components/Image/ImageFromUrl';
import { useProjectContext } from '../components/Project/ProjectProvider';
import { useReportEditorContext } from '../components/Report/ReportEditorProvider';
import Spinner from '../components/Spinner/Spinner';
import { useUsageContext } from '../components/Usage/UsageProvider';
import useAIOCRResult from '../hooks/useAIOCRResult';
import { isMobile } from '../utils/client';
import OCRView from './OCRView';

type Props = {
  orgId?: string;
  inspectionId?: string;
  projectId?: string;
};

const SecretAIOCRPOI = ({ orgId, inspectionId, projectId }: Props) => {
  const [poiVisible, setPoiVisible] = React.useState(false);
  const [ocrResult, setOCRResult] = React.useState<OCRResult>();
  const { activeBooking } = useProjectContext();
  const [showingImageForIndex, setShowingImageForIndex] = React.useState<
    number | undefined
  >(undefined);
  const { query, update, remove, create } = useAIOCRResult(
    projectId,
    inspectionId
  );
  const { usage } = useUsageContext();

  const { reportEditor } = useReportEditorContext();

  React.useEffect(() => {
    if (query.data) {
      const { data } = query;
      setOCRResult(data);
    }
  }, [query.data]);

  const handleTextChange = (index: number) => (newText?: number) => {
    const newData = [...ocrResult!.matches];
    newData[index].text = newText || 0;
    setOCRResult({
      ...ocrResult!,
      matches: newData,
    });
  };

  const fetchImagesAndExport = async () => {
    const zip = new JSZip();

    zip.file('export.json', JSON.stringify(ocrResult?.matches, null, 2));

    const imgFolder = zip.folder('Images');

    const imagePromises = ocrResult!.matches.map(async (item, index) => {
      const response = await fetch(
        `https://storage.googleapis.com/project-d-328516-surveys/${orgId}/${projectId}/${inspectionId}/frames/${item.frame}.jpeg`
      );
      const blob = await response.blob();
      imgFolder?.file(`${index + 1}.jpeg`, blob);
    });

    await Promise.all(imagePromises);

    zip.generateAsync({ type: 'blob' }).then((content: any) => {
      saveAs(
        content,
        `${activeBooking?.jobId}-${
          reportEditor.getSectionByUid(
            reportEditor.getInspectionByUid(inspectionId)?.sectionUid
          )?.index
        }.zip`
      );
    });
  };

  if (reportEditor.getInspectionByUid(inspectionId)?.video === undefined) {
    return null;
  }

  if (query.isLoading) {
    return (
      <Box
        padding="x2"
        gap="x1"
        flex="horizontal"
        alignChildrenVertical="middle"
      >
        <Text size="x1" strong>
          Fetching details
        </Text>
        <Spinner size="1rem" />
      </Box>
    );
  }

  if (query.data?.status === OCRResultStatus.PENDING) {
    return (
      <>
        <Alert
          color="accent"
          flex="horizontal"
          gap="x1"
          alignChildrenVertical="middle"
          padding="x2"
          backgroundColor="accent-shade-1"
        >
          <Spinner size="1rem" />
        </Alert>
        <FeatureFlag>
          <Button
            onClick={() => remove.mutateAsync()}
            flex="vertical"
            color="negative"
          >
            <Text strong>Superuser action</Text>
            <Text weak> remove</Text>
          </Button>
        </FeatureFlag>
      </>
    );
  }

  if (
    query.data &&
    query.data?.matches.filter((e) => e.interpolated).length >=
      query.data.matches.length * 0.25
  ) {
    return (
      <>
        <Alert
          backgroundColor="negative-shade-1"
          color="negative"
          flex="horizontal"
          alignChildrenVertical="middle"
        >
          <Explainer
            title={'How to improve'}
            color="negative-shade-5"
            text="Requires camera tweaks"
          >
            <Alert
              color={'accent'}
              padding="x3"
              backgroundColor="accent-shade-1"
              flex="vertical"
              gap="x1"
            >
              <Text size="x2" flex="horizontal" gap="x1">
                Detected camera:{' '}
                <Text strong>
                  {query.data?.binarizor}{' '}
                  {query.data?.usedCachedBB ? 'preproc' : 'adhoc'}
                </Text>
              </Text>
              <Text size="x1"> If this is incorrect - please contact us</Text>
            </Alert>
            <Text>Most popular camera are supported by Buddy.</Text>
            <Text>Depending on your camera we have suggestions for each.</Text>
            <Text strong>AM Industrial</Text> <Text>Green</Text>
            <Text strong>IPEK</Text> <Text>White with a solid background</Text>
            <Text strong>Minicam solopro/proteus</Text> <Text>White</Text>
            <Text strong>Pearpoint 330</Text>{' '}
            <Text>White with solid background</Text>
            <Text strong>Pearpoint 550</Text>{' '}
            <Text>Light blue with solid background</Text>
            <Text strong>Scanprobe Maxprobe</Text> <Text>Green</Text>
            <Text strong>Vivax</Text> <Text>Yellow</Text>
          </Explainer>
        </Alert>
      </>
    );
  }

  if (query.data?.status === OCRResultStatus.FAILED) {
    return (
      <Box flex="vertical" gap="x2">
        <Alert backgroundColor="negative-shade-1" color="negative" padding="x2">
          <Box flex="horizontal" gap="x2">
            <AlertTriangle size="1rem" />
            <Text size="x2" strong>
              Buddy failed - no cost incurred
            </Text>
          </Box>
        </Alert>
        <FeatureFlag>
          <Button
            onClick={() => remove.mutateAsync()}
            flex="vertical"
            color="negative"
          >
            <Text strong>Superuser action</Text>
            <Text weak> remove</Text>
          </Button>
        </FeatureFlag>
      </Box>
    );
  }

  if (query.data === null) {
    return (
      <Box flex="vertical" alignChildrenHorizontal="middle">
        <Text size="x1">Analysis not run</Text>
        {usage && usage.buddyTokens == 0 && (
          <Box>
            <AIPOIBuddyUpsellButton />
          </Box>
        )}
        <FeatureFlag>
          <Button onClick={() => create.mutateAsync()}>
            Superuser action: Run single
          </Button>
        </FeatureFlag>
      </Box>
    );
  }

  if (!isMobile() && poiVisible) {
    return (
      <Modal
        visible={true}
        onClose={() => {
          setPoiVisible(false);
        }}
      >
        <ModalHeader>OCR Debug view</ModalHeader>
        <ModalBody>
          <Box flex="vertical" grow overflow="auto" width="600px">
            <Box flex="horizontal" alignChildrenHorizontal="around">
              <Box flex="horizontal" gap="x2">
                <Button onClick={fetchImagesAndExport}>
                  <Icons.Download />
                </Button>
              </Box>
              <Box flex="horizontal" gap="x2">
                <Label>
                  <Box flex="vertical" alignChildrenHorizontal="middle">
                    <Text>{ocrResult?.binarizor}</Text>
                    <Text size="x1">{ocrResult?.bounds.join(',')}</Text>
                  </Box>
                </Label>
                <Label grow>
                  {ocrResult?.matches.filter((e) => e.text > -998).length}/
                  {ocrResult?.matches.length}
                </Label>
                <Label
                  grow
                  backgroundColor={
                    ocrResult?.usedCachedBB
                      ? 'positive-shade-4'
                      : 'negative-shade-4'
                  }
                  alignChildrenHorizontal="middle"
                >
                  {ocrResult?.usedCachedBB ? <Icons.Check /> : <Icons.X />}
                </Label>
                <Label grow alignChildrenHorizontal="middle" flex="vertical">
                  <Box>
                    <Text>
                      {query.data?.matches.filter((e) => e.interpolated).length}
                    </Text>
                  </Box>
                  <Box>
                    <Text>interpolated</Text>
                  </Box>
                </Label>
              </Box>
            </Box>

            <Box>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell>#</TableHeaderCell>
                    <TableHeaderCell>Text</TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {ocrResult?.matches.map((e, i) => (
                    <>
                      <TableRow>
                        {showingImageForIndex === i && (
                          <TableCell colSpan={5}>
                            <Appear animation="FadeSlideDown">
                              <ImageFromUrl
                                height="500px"
                                width="700px"
                                src={getFullFilePath(
                                  `${orgId}/images/observations/${projectId}/${inspectionId}/frames/${e.frame}.jpeg`
                                )}
                              />
                            </Appear>
                          </TableCell>
                        )}
                      </TableRow>
                      <TableRow
                        key={i}
                        backgroundColor={
                          e.interpolated ? 'positive-shade-4' : 'light-shade-1'
                        }
                      >
                        <TableCell>{e.frame}</TableCell>
                        <TableCell
                          onFocus={() => {
                            setShowingImageForIndex(i);
                          }}
                        >
                          <DistanceInput
                            name="distance"
                            onChange={handleTextChange(i)}
                            type="long"
                            value={e.text}
                          />
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </ModalBody>
      </Modal>
    );
  }

  return (
    <Box flex="vertical" gap="x2" grow>
      <BuddyQAReviewButton
        inspection={reportEditor.getInspectionByUid(inspectionId)!!}
        update={(e) => update.mutateAsync(e)}
        ocrResult={query.data}
      />
      <FeatureFlag>
        <Button
          onClick={() => remove.mutateAsync()}
          flex="vertical"
          color="negative"
        >
          <Text strong>Superuser action</Text>
          <Text weak> remove</Text>
        </Button>
      </FeatureFlag>
      {query.data && (
        <OCRView
          og={query.data}
          projectId={projectId}
          inspectionId={inspectionId}
          orgId={orgId}
        />
      )}
      <FeatureFlag>
        <Box flex="horizontal" padding="x2" alignChildrenHorizontal="around">
          <Box>
            <Text size="x2" strong>
              {' '}
              Time:
            </Text>
            <Text>{query.data?.time?.toFixed(2)}s </Text>
          </Box>
          <Box>
            <Text size="x2" strong>
              {' '}
              Credits used:
            </Text>
            <Text>{query.data?.creditsUsed}</Text>
          </Box>
        </Box>
      </FeatureFlag>
    </Box>
  );

  // if (isMobile()) {
  //   return <>
  //     <Button onClick={() => setPoiVisible(true)} variant='tertiary' color='accent' flex='horizontal' gap='x1'>
  //       <Text paddingTop='x1'>
  //         Buddy
  //       </Text>
  //       <AILogo />
  //     </Button>

  //     {
  //       poiVisible &&
  //       <Wizard flow={'create'} onSave={async () => setPoiVisible(false)}>
  //         <Modal overlayBackgroundCloseOnClick={false} onClose={() => setPoiVisible(false)} visible={true} >

  //           <ModalHeader>
  //             <Box flex='horizontal' alignChildrenHorizontal='between'>
  //               Buddy's report
  //             </Box>
  //           </ModalHeader>
  //             {
  //             <Box flex='vertical' width='150px' gap='x3' borderSize='x1' borderRadius='10px' padding='x3'>
  //               <Text strong size='x1'>POI sample Rate</Text>
  //             </Box>
  //             }
  //           <ModalBody height='calc(100vh - 75px)' width='100vw'>
  //             {framesOfInterest
  //               ?.sort((a, b) => a - b)
  //               .map((distance, second) => (
  //                 <WizardStep id={`${second}`} key={second}>

  //                   <Box flex="vertical" gap='x3' grow paddingBottom='x2'>
  //                     <Box flex='horizontal' gap='x2' alignChildrenVertical='end'>
  //                       <Box width='90vw'>
  //                         <AIPOIDisplay distance={distance} second={second} projectId={projectId!} inspectionId={inspectionId!} />
  //                       </Box>
  //                     </Box>
  //                     <Box flex='horizontal' gap='x2' alignChildrenVertical='end'>

  //                     </Box>
  //                     <Box grow>
  //                       <ImageFromUrl
  //                         height="100%"
  //                         src={getFullOCRFrameImage(distance)}
  //                       />
  //                     </Box>
  //                     <AIPOIAddObservationButton
  //                       photoUrl={getFullOCRFrameImage(distance)}
  //                       distance={distance}
  //                       second={second}
  //                       projectId={projectId!}
  //                       inspectionId={inspectionId!}
  //                       maxDistance={maxDistance!}
  //                     />
  //                     <Box
  //                       overflow="auto"
  //                       padding='x1'
  //                       height='200px'
  //                       flex='vertical'
  //                       alignChildrenHorizontal="middle"
  //                       backgroundColor="background-shade-2"
  //                     >
  //                       <DataTable>
  //                         <DataTableGroup>
  //                           {reportEditor
  //                             .getInspectionObservations(inspectionId)
  //                             .filter((e) => e.distance === distance).length == 0 && (
  //                               <DataTableItem>
  //                                 <DataTableMessage>
  //                                   There are no observations to show here
  //                                 </DataTableMessage>
  //                               </DataTableItem>
  //                             )}
  //                           {reportEditor
  //                             .getInspectionObservations(inspectionId)
  //                             .filter((e) => e.distance === distance)
  //                             .map((observation) => (
  //                               <DataTableItem key={observation.uid} padding="x2" margin="x3" borderBottom onClick={() => editObservation(observation.uid)}>
  //                                 <DataTableMessage>
  //                                   <ObservationView observation={observation} showText={false} />
  //                                 </DataTableMessage>
  //                               </DataTableItem>
  //                             ))}
  //                         </DataTableGroup>
  //                       </DataTable>
  //                     </Box>

  //                   </Box>
  //                 </WizardStep>
  //               ))}
  //             <WizardReviewStep>
  //               No futher details detected.
  //               <SummaryCard>
  //               </SummaryCard>
  //             </WizardReviewStep>
  //             <WizardControls />
  //           </ModalBody>
  //         </Modal>
  //       </Wizard>
  //     }
  //   </>

  // }

  // return (
  //   <Box flex='horizontal' gap='x1'>
  //     <Button onClick={() => setPoiVisible(true)} variant='tertiary' color='accent' flex='horizontal' gap='x1'>
  //       <Text paddingTop='x1'> Buddy </Text>
  //       <AILogo />
  //     </Button>
  //     {
  //       poiVisible &&
  //       <Wizard flow={'create'} onSave={async () => setPoiVisible(false)}>
  //         <Modal overlayBackgroundCloseOnClick={false} onClose={() => setPoiVisible(false)} visible={true}>

  //           <ModalHeader>
  //             <Box flex='horizontal' alignChildrenHorizontal='between'>
  //               Buddy's report
  //             </Box>
  //           </ModalHeader>
  //           <ModalBody>
  //             <Box flex='horizontal' gap='x2' alignChildrenVertical='middle'>
  //               <Toggle shrink value={includeInterpolated} onChange={() => setIncludeInterpolated(!includeInterpolated)}/>
  //                 <Text strong size='x1'>Include estimated results</Text>
  //             </Box>
  //             {framesOfInterest
  //               ?.sort((a, b) => a - b)
  //               .map((distance, second) => (
  //                 <WizardStep id={`${second}`} key={second}>
  //                   <Box flex='horizontal' gap='x1' alignChildrenHorizontal='middle' alignChildrenVertical='middle'>
  //                   </Box>

  //                   <Box flex="horizontal" gap="x4" padding='x3'>
  //                     <Box>
  //                       <Box width="375px" height="300px">
  //                         <ImageFromUrl
  //                           height="100%"
  //                           src={getFullOCRFrameImage(distance)}
  //                         />
  //                       </Box>
  //                     </Box>

  //                     <Box>
  //                       <AIPOIDisplay distance={distance} second={second} projectId={projectId!} inspectionId={inspectionId!} />

  //                       <Box flex="vertical" gap="x2" paddingTop='x2'>
  //                         <AIPOIAddObservationButton
  //                           photoUrl={getFullOCRFrameImage(distance)}
  //                           distance={distance}
  //                           second={second}
  //                           projectId={projectId!}
  //                           inspectionId={inspectionId!}
  //                           maxDistance={maxDistance!}
  //                         />
  //                         <Box
  //                           maxHeight="260px"
  //                           overflow="auto"
  //                           flex="vertical"
  //                           alignChildrenHorizontal="middle"
  //                           backgroundColor="background-shade-2"
  //                           grow
  //                           gap='x2'
  //                         >
  //                           {reportEditor
  //                             .getInspectionObservations(inspectionId)
  //                             .filter((e) => e.distance === distance).length == 0 && (
  //                               <DataTable>
  //                                 <DataTableGroup>
  //                                   <DataTableItem>
  //                                     <DataTableMessage>
  //                                       There are no observations to show here
  //                                     </DataTableMessage>
  //                                   </DataTableItem>
  //                                 </DataTableGroup>
  //                               </DataTable>
  //                             )}
  //                           {reportEditor
  //                             .getInspectionObservations(inspectionId)
  //                             .filter((e) => e.distance === distance)
  //                             .map((observation) => (
  //                               <Box key={observation.uid} flex='vertical' padding='x2'>
  //                                 <Box flex='horizontal' alignChildrenHorizontal='middle'>
  //                                   <Text size='x2' strong> {observation.inspectionMarker ? observation.inspectionMarker[0].toUpperCase() + observation.inspectionMarker.slice(1) : observation.code[observation.code.length - 1]} </Text>
  //                                 </Box>
  //                                 <Box flex='horizontal' grow gap='x2' width='100%' padding='x2' borderBottom borderColor='background-shade-3' >
  //                                   <Box
  //                                     width="100px"
  //                                     height="60px"
  //                                   >
  //                                     <ImageFromUrl
  //                                       src={getFullFilePath(observation.imageUrl)}
  //                                       height="60px"
  //                                     />
  //                                   </Box>
  //                                   <Box flex='horizontal'>

  //                                     <Button
  //                                       flex="vertical"
  //                                       variant="tertiary"
  //                                       size="x1"
  //                                       onClick={() => replacePhoto(observation, second)}
  //                                     >
  //                                       <Text size="x1">
  //                                         <Icons.Camera />
  //                                       </Text>
  //                                     </Button>
  //                                     <Button
  //                                       flex="vertical"
  //                                       color="accent"
  //                                       variant="tertiary"
  //                                       size="x1"
  //                                       onClick={() => editObservation(observation.uid)}
  //                                     >
  //                                       <Icons.Edit3 />
  //                                     </Button>
  //                                   </Box>
  //                                 </Box>
  //                               </Box>
  //                             ))}
  //                         </Box>

  //                       </Box>
  //                     </Box>
  //                   </Box>
  //                 </WizardStep>
  //               ))}
  //             <WizardReviewStep>
  //               <Button onClick={() => setAIView(false)}> OCR View </Button>
  //               No futher details detected.
  //             </WizardReviewStep>
  //           </ModalBody>
  //           <ModalFooter>
  //               <ModalFooterInsert>
  //                 <Button onClick={() => setAIView(false)}> OCR View </Button>
  //               </ModalFooterInsert>
  //             <WizardControls />
  //           </ModalFooter>
  //         </Modal>
  //       </Wizard>

  //     }
  //   </Box>
  // );
};

export default SecretAIOCRPOI;
