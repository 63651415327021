import { ResponseError, SignupRequest } from '@drainify/types';
import { useQuery } from 'react-query';
import useFetch, { hasAllParams } from '../components/Api/useFetch';

type Opts = {
  noQuery?: boolean;
};

const useSignupRequest = (signupRequestId?: string, { noQuery }: Opts = {}) => {
  const params = { signupRequestId: signupRequestId as string };
  const fetch = useFetch();
  const key = [`/signup/${signupRequestId}`, fetch];

  const query = useQuery<SignupRequest, ResponseError>(
    key,
    () => fetch('/signup/:signupRequestId', { method: 'GET', params }),
    { enabled: hasAllParams(params) && !noQuery }
  );

  return { query };
};

export default useSignupRequest;
