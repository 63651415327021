import { getFullFilePath } from '@drainify/utils';
import { Appear, Box, Modal, ModalBody, ModalFooter, Text } from 'preshape';
import React from 'react';
import { FULL_SCREEN_MODAL_WIDTH } from '../components/App/App';
import { RootContext } from '../components/Bootstrap/Bootstrap';
import ImageFromUrl from '../components/Image/ImageFromUrl';
import Logo from '../components/Logo/Logo';
import Wizard from '../components/Wizard/Wizard';
import WizardControls from '../components/Wizard/WizardControls/WizardControls';
import WizardReviewStep from '../components/Wizard/WizardReviewStep';
import WizardStep from '../components/Wizard/WizardStep';

const WelcomeWizardMobile = () => {
  const [visible, setVisible] = React.useState(true);
  const { setSeenTutorials } = React.useContext(RootContext);

  const finish = () => {
    setVisible(false);
    setSeenTutorials('mobile-start');
  };

  return (
    <Wizard flow={'create'} onSave={() => Promise.resolve(finish())}>
      <Modal
        animation="FadeSlideUp"
        margin="x4"
        maxWidth={FULL_SCREEN_MODAL_WIDTH}
        onClose={() => {}}
        overlayBackgroundCloseOnClick={false}
        visible={visible}
      >
        <ModalBody>
          <WizardStep id="welcome">
            <Box flex="vertical" gap="x3" alignChildrenHorizontal="middle">
              <Appear duration={2000}>
                <Text
                  textColor="accent-shade-5"
                  alignChildrenHorizontal="middle"
                >
                  <Logo />
                </Text>
              </Appear>
              <Appear delay={0} duration={1000}>
                <Text size="x5">Welcome to Drainify Mobile</Text>
              </Appear>
              <Appear delay={2000} duration={500}>
                <Text>Let's take a quick tour. Hit 'Next' below to start</Text>
              </Appear>
            </Box>
          </WizardStep>

          <WizardStep id="homepage" title="Home">
            <Box flex="vertical" gap="x3">
              <Box flex="horizontal" alignChildrenHorizontal="middle">
                <Box width="250px">
                  <ImageFromUrl
                    src={getFullFilePath('tutorials/home-add-job.png')}
                    height={'400px'}
                  />
                </Box>
              </Box>
              <Text>Add new jobs and projects</Text>
            </Box>
          </WizardStep>

          <WizardStep id="jobsPage1" title="Home">
            <Box flex="vertical" gap="x3">
              <Box flex="horizontal" alignChildrenHorizontal="middle">
                <Box width="250px">
                  <ImageFromUrl
                    src={getFullFilePath('tutorials/home-job.png')}
                    height={'400px'}
                  />
                </Box>
              </Box>
              <Text>Select jobs to complete report assigned to you</Text>
            </Box>
          </WizardStep>

          <WizardStep id="jobsPage2" title="Home">
            <Box flex="vertical" gap="x3">
              <Box flex="horizontal" alignChildrenHorizontal="middle">
                <Box width="250px">
                  <ImageFromUrl
                    src={getFullFilePath('tutorials/home-search.png')}
                    height={'400px'}
                  />
                </Box>
              </Box>
              <Text>Search jobs by job number, site address and more</Text>
            </Box>
          </WizardStep>

          <WizardStep id="settingsPage1" title="Settings">
            <Box flex="vertical" gap="x3">
              <Box flex="horizontal" alignChildrenHorizontal="middle">
                <Box width="250px">
                  <ImageFromUrl
                    src={getFullFilePath('tutorials/settings-user.png')}
                    height={'400px'}
                  />
                </Box>
              </Box>
              <Text>Configure user settings</Text>
            </Box>
          </WizardStep>

          <WizardStep id="settingsPage2" title="Settings">
            <Box flex="vertical" gap="x3">
              <Box flex="horizontal" alignChildrenHorizontal="middle">
                <Box width="250px">
                  <ImageFromUrl
                    src={getFullFilePath('tutorials/settings-org.png')}
                    height={'400px'}
                  />
                </Box>
              </Box>
              <Text>Configure your organisation details</Text>
            </Box>
          </WizardStep>

          <WizardReviewStep>
            <Box flex="vertical" gap="x3">
              <Box flex="horizontal" alignChildrenHorizontal="middle">
                <Box width="250px">
                  <ImageFromUrl
                    src={getFullFilePath('tutorials/settings-classroom.png')}
                    height={'400px'}
                  />
                </Box>
              </Box>
              <Text>
                Visit the classroom for in-depth explanations and tutorials
              </Text>
            </Box>
          </WizardReviewStep>
        </ModalBody>

        <ModalFooter>
          <WizardControls />
        </ModalFooter>
      </Modal>
    </Wizard>
  );
};

export default WelcomeWizardMobile;
