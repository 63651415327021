import { InviteAccept } from '@drainify/types';
import { runValidations } from '@drainify/utils';
import { useForm } from 'preshape';
import { useCallback, useMemo } from 'react';
import * as yup from 'yup';

export const inviteAcceptValidations = yup.object().shape({
  fullName: yup.string().max(50).required('name is required'),
  password: yup
    .string()
    .required('Please enter a password')
    .test(
      'password not strong enough',
      'Password must have at least 1 uppercase, 1 lowercase, 1 special character and be at least 8 character long',
      (e) =>
        e !== undefined &&
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/.test(e)
    ),
  confirmPassword: yup
    .string()
    .required('Please re-enter your password')
    .when(['password'], (password) => {
      if (password) {
        return yup
          .string()
          .test(
            'Passwords do not match',
            'Passwords do not match',
            (e) => e === password
          )
          .test(
            'password not strong enough',
            'Password must have at least 1 uppercase, 1 lowercase, 1 special character and be at least 8 character long',
            () =>
              /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/.test(
                password
              )
          );
      } else return yup.string().required();
    }),
  phoneNumber: yup
    .string()
    .matches(
      /(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{4,20}$/,
      'Incorrect format'
    )
    .required('Phone number is required'),
});

const useInviteAcceptForm = (inviteAccept?: InviteAccept) => {
  const initial = useMemo<InviteAccept>(
    () =>
      inviteAccept || {
        fullName: '',
        password: '',
        confirmPassword: '',
        phoneNumber: '',
      },
    []
  );

  return useForm({
    initial: initial,
    validate: useCallback((inviteAccept, setError) => {
      setError(runValidations(inviteAcceptValidations, inviteAccept));
    }, []),
  });
};

export default useInviteAcceptForm;
