import React from 'react';
import Amex from './Amex';
import Generic from './Generic';
import Mastercard from './Mastercard';
import Visa from './Visa';

type Props = {
  brand?: string;
  size?: string;
};

const CardBrandIcon = ({ size = '2.25rem', brand }: Props) => {
  switch (brand) {
    case 'visa':
      return <Visa size={size} />;
    case 'mastercard':
      return <Mastercard size={size} />;
    case 'amex':
      return <Amex size={size} />;
    default:
      return <Generic size={size} />;
  }
};

export default CardBrandIcon;
