import getNearestPointOnLine, {
  NearestPointOnLine,
} from '@turf/nearest-point-on-line';
import React, { PropsWithChildren } from 'react';
import { useMapContext } from '../Map/Map';
import MapBoundsAddVectorMarker from '../Map/MapBounds/MapBoundsAddVectorMarker';
import useDrawMeasurementMap from '../Report/ReportMap/ReportMapElements/useDrawMeasurementMap';
import { useReportMapContext } from '../Report/ReportMap/ReportMapProvider';

type Props = {
  points: GeoJSON.Point[];
  onAdd: (point: NearestPointOnLine['geometry'], end: GeoJSON.Point) => void;
};

const ReportMapMeasurementSegment = ({
  points,
  onAdd,
}: PropsWithChildren<Props>) => {
  const { addPointerMoveListener, getNearestPoint, isInteractive } =
    useMapContext();
  const { focusedMeasurementUid } = useReportMapContext();
  const EDGE_NEAR_DISTANCE = 25;

  useDrawMeasurementMap({
    points: points,
    visible: true,
  });

  const [addVectorMarkerPosition, setAddVectorMarkerPosition] = React.useState<
    NearestPointOnLine | undefined | false
  >();

  React.useEffect(() => {
    return addPointerMoveListener((point) => {
      // We don't want to do anything while we're dragging stuff about
      if (!isInteractive) {
        return null;
      }
      // if (focusedMeasurementUid !== measurement.uid) {
      // return null;
      // }

      const nearestPointOnLine = getNearestPointOnLine(
        {
          type: 'MultiLineString',
          coordinates: [[points[0].coordinates, points[1].coordinates]],
        },
        point
      );

      if (
        !getNearestPoint(
          point,
          [nearestPointOnLine.geometry],
          EDGE_NEAR_DISTANCE
        )
      ) {
        return setAddVectorMarkerPosition(undefined);
      }

      const handlePoints: GeoJSON.Point[] = points;

      if (getNearestPoint(point, handlePoints, EDGE_NEAR_DISTANCE)) {
        return setAddVectorMarkerPosition(undefined);
      }

      setAddVectorMarkerPosition(nearestPointOnLine);
    });
  }, [
    addPointerMoveListener,
    isInteractive,
    getNearestPoint,
    focusedMeasurementUid,
    points,
  ]);

  if (
    addVectorMarkerPosition === false ||
    addVectorMarkerPosition === undefined
  ) {
    return null;
  }

  return (
    <MapBoundsAddVectorMarker
      onClick={() => {
        onAdd(addVectorMarkerPosition?.geometry, points[1]);
        setAddVectorMarkerPosition(undefined);
      }}
      point={addVectorMarkerPosition?.geometry}
    />
  );
};

export default ReportMapMeasurementSegment;
