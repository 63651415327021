import { BoxProps } from 'preshape';
import React from 'react';
import Distance from '../DistanceInput/Distance';
import QuickOption from '../QuickOptions/QuickOption';
import QuickOptions from '../QuickOptions/QuickOptions';

type Props = BoxProps & {
  onChange: (diameter: number) => void;
  value?: number;
  quickOptions: number[];
};

const NodeDistanceQuickOptions = ({
  onChange,
  value,
  quickOptions,
  ...rest
}: Props) => {
  return (
    <QuickOptions {...rest}>
      {quickOptions.map((option, index) => (
        <QuickOption
          active={value === quickOptions[index]}
          key={index}
          onClick={() => onChange(quickOptions[index])}
          size="x2"
          uppercase={false}
        >
          <Distance type="short" value={option} />
        </QuickOption>
      ))}
    </QuickOptions>
  );
};

export default NodeDistanceQuickOptions;
