import { Inspection, Match, Observation, OCRResult } from '@drainify/types';
import {
  getFullFilePath,
  getObservationOptions,
  isOptionCode,
} from '@drainify/utils';
import round from 'lodash.round';
import {
  Alert,
  Appear,
  Box,
  Button,
  Buttons,
  Icons,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Text,
  Tooltip,
} from 'preshape';
import React from 'react';
import { isMobile } from '../../utils/client';
import AIPOIAddObservationButton from '../AI/AIPOIAddObservationButton';
import Distance from '../DistanceInput/Distance';
import AILogo from '../IconsMisc/AILogo';
import ImageFromUrl from '../Image/ImageFromUrl';
import ObservationOptionRenderer from '../Observations/ObservationOptionRenderer/ObservationOptionRenderer';
import ObservationSymbol from '../Observations/ObservationSymbol';
import { useOrgContext } from '../Org/OrgProvider';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import SymbolTemplate from '../Symbol/SymbolTemplate';

type Props = {
  ocrResult?: OCRResult;
  inspection: Inspection;
  ignoreTooFast?: boolean;
  update: (result: OCRResult) => void;
};

const BuddyQAReviewButton = ({
  ocrResult,
  inspection,
  update,
  ignoreTooFast,
}: Props) => {
  const [visible, setVisible] = React.useState(false);
  const { reportEditor, editObservation, createNewObservationFromAI } =
    useReportEditorContext();
  const totalObvs = reportEditor.getInspectionObservations(
    inspection.uid
  ).length;
  const { org } = useOrgContext();
  const { project } = useProjectContext();
  const [maxDistance, setMaxDistance] = React.useState(99999);
  const [missing, setMissing] = React.useState<Observation[]>([]);
  const section = reportEditor.getSectionByUid(inspection.sectionUid);

  function truncateToOneDecimal(num: number) {
    return Math.trunc(num * 10) / 10;
  }

  const [unmatchedPoi, setUnmatchedPoi] = React.useState<Match[]>([]);

  function truncateToTwoDecimal(num: number) {
    return round(num, 2);
  }

  React.useEffect(() => {
    if (ocrResult?.matches) {
      const textCount: Record<string, Match[]> = ocrResult.matches
        .filter((e) => e.text !== -999)
        .reduce((acc, match) => {
          if (isNaN(match.text)) {
            return acc;
          }
          const text = truncateToTwoDecimal(match.text);
          // @ts-ignore
          acc[text] = acc[text] ? [...acc[text], match] : [match];
          return acc;
        }, {});

      const up: Match[] = [];
      const mv = Math.max(
        ...ocrResult.matches.filter((e) => !e.dismissed).map((e) => e.text)
      );

      for (const readValue of Object.keys(textCount)) {
        // @ts-ignore
        if (textCount[readValue].length >= 2) {
          // @ts-ignore
          if (textCount[readValue].filter((e) => !e.interpolated)) {
            const potentialPOI = parseFloat(readValue);
            if (
              !reportEditor
                .getInspectionObservations(inspection.uid)
                .filter((e) => e.distance !== undefined)
                .find(
                  (e) =>
                    e.distance! >= potentialPOI - 0.3 &&
                    e.distance! <= potentialPOI + 0.3
                )
            ) {
              up.push(textCount[readValue][0]);
            }
          }
        } else if (parseFloat(readValue) === mv) {
          const potentialPOI = parseFloat(readValue);
          if (
            !reportEditor
              .getInspectionObservations(inspection.uid)
              .filter((e) => e.distance !== undefined)
              .find(
                (e) =>
                  e.distance! >= potentialPOI - 0.3 &&
                  e.distance! <= potentialPOI + 0.3
              )
          ) {
            up.push(textCount[readValue][0]);
          }
        }
      }
      setUnmatchedPoi(up.filter((e) => !e.dismissed));
      setMaxDistance(mv);
      if (unmatchedPoi.length === 0) {
        setVisible(false);
      }

      const m: Observation[] = [];
      for (const j of reportEditor.getInspectionObservations(
        ocrResult.inspectionId
      )) {
        if (appearencesInOCR(j.distance) < 2) {
          m.push(j);
        }
      }
      setMissing(m);
    }
  }, [ocrResult, reportEditor]);

  if (totalObvs + unmatchedPoi.length === 0) {
    return <Box>No observations</Box>;
  }

  const appearencesInOCR = (distance?: number) => {
    if (distance === undefined) return 0;
    const results =
      ocrResult?.matches
        .filter((e) => !isNaN(e.text))
        .filter(
          (e) => truncateToOneDecimal(e.text) === truncateToOneDecimal(distance)
        ) || [];
    return results?.length;
  };

  const fulSet: (Match | Observation)[] = [
    ...unmatchedPoi,
    ...reportEditor.getInspectionObservations(inspection.uid),
  ];
  fulSet.sort((a, b) => {
    const aValue = 'text' in a ? a.text : a.distance;
    const bValue = 'text' in b ? b.text : b.distance;

    return aValue! - bValue!;
  });

  return (
    <>
      <Box
        onClick={() => setVisible(true)}
        flex="vertical"
        gap="x1"
        grow
        style={{ cursor: 'pointer' }}
      >
        <Label
          backgroundColor="background-shade-3"
          textColor="text-shade-3"
          flex="horizontal"
          gap="x2"
          grow
        >
          <Icons.User size="0.75rem" />
          <Text size="x1"> {totalObvs} Coded</Text>
          {missing.length > 0 && <Icons.AlertCircle size="0.75rem" />}
        </Label>

        {unmatchedPoi.length > 0 && (
          <Label
            backgroundColor="accent-shade-5"
            textColor="white"
            flex="horizontal"
            gap="x2"
            grow
          >
            <AILogo fill="white" size="0.75rem" />
            <Text size="x1"> {unmatchedPoi.length} Found </Text>
          </Label>
        )}
      </Box>

      <Modal visible={visible} onClose={() => setVisible(false)}>
        <ModalHeader>
          {reportEditor.getSectionName(section)} /{' '}
          {reportEditor.getInspectionName(inspection)} observations
        </ModalHeader>
        <ModalBody
          width={isMobile() ? '100vw' : '95vw'}
          height="85vh"
          style={{ overflow: 'scroll' }}
        >
          <Box flex="horizontal" wrap alignChildrenHorizontal="around" gap="x8">
            {fulSet.map((e) => {
              if ('uid' in e) {
                return (
                  <Box
                    width="300px"
                    flex="vertical"
                    gap="x3"
                    alignChildrenVertical="between"
                    padding="x3"
                    key={e.uid}
                    borderSize="x1"
                    borderColor="background-shade-3"
                    borderBottom
                  >
                    <Box backgroundColor="text-shade-1">
                      <ImageFromUrl
                        src={getFullFilePath(e.imageUrl)}
                        height="175px"
                        width="300px"
                      />
                    </Box>
                    <Box
                      flex="horizontal"
                      alignChildrenHorizontal="between"
                      alignChildrenVertical="middle"
                    >
                      {e.inspectionMarker === 'start' ? (
                        <SymbolTemplate> ST </SymbolTemplate>
                      ) : e.inspectionMarker === 'finish' ? (
                        <SymbolTemplate>FN</SymbolTemplate>
                      ) : (
                        <ObservationSymbol code={e?.code} />
                      )}
                      {
                        <Box flex="vertical" alignChildrenVertical="middle">
                          <Text strong size="x1">
                            {e.observationContinuousState}
                          </Text>
                        </Box>
                      }
                      {getObservationOptions(e.code)
                        ?.filter((o) => !(o.type === 'continuous'))
                        .map(
                          (option) =>
                            !isOptionCode(option) && (
                              <Tooltip
                                key={e.uid + '-' + option.name}
                                content={option.name}
                                placement="top"
                                zIndex={10}
                              >
                                {(props) => (
                                  <Appear>
                                    <Box {...props}>
                                      <ObservationOptionRenderer
                                        asSummary
                                        height={8}
                                        option={option}
                                        value={e?.attributes[option.attribute]}
                                        width={8}
                                        asIcon
                                      />
                                    </Box>
                                  </Appear>
                                )}
                              </Tooltip>
                            )
                        )}
                      <Distance strong value={e.distance} type={'long'} />
                    </Box>
                    <Box>
                      <Box flex="horizontal" alignChildrenHorizontal="between">
                        {appearencesInOCR(e.distance) >= 2 ? (
                          <Tooltip
                            key={e.uid}
                            content="Engineer stopped correctly"
                            placement="top"
                            zIndex={10}
                          >
                            {(props) => (
                              <Alert
                                {...props}
                                padding="x1"
                                color={'positive'}
                                backgroundColor="positive-shade-2"
                                flex="horizontal"
                                alignChildrenHorizontal="around"
                              >
                                <Icons.Check />
                              </Alert>
                            )}
                          </Tooltip>
                        ) : ignoreTooFast ? (
                          <></>
                        ) : (
                          <Tooltip
                            key={e.uid}
                            content="Engineer did not stop for at least 3 seconds"
                            placement="top"
                            zIndex={10}
                          >
                            {(props) => (
                              <Alert
                                {...props}
                                padding="x1"
                                color={'negative'}
                                backgroundColor="negative-shade-2"
                                flex="horizontal"
                                alignChildrenHorizontal="around"
                              >
                                <Icons.X />
                              </Alert>
                            )}
                          </Tooltip>
                        )}
                        <Buttons
                          flex="horizontal"
                          alignChildrenHorizontal="middle"
                          gap="x1"
                        >
                          <Button
                            variant="tertiary"
                            color="accent"
                            onClick={() => editObservation(e.uid)}
                            size="x1"
                          >
                            <Icons.Edit2 />
                          </Button>
                          {e.imageUrl && (
                            <Button
                              onClick={async () =>
                                createNewObservationFromAI(
                                  inspection.uid,
                                  e.imageUrl!,
                                  e.timeStamp || 0,
                                  e.distance!
                                )
                              }
                              variant="tertiary"
                              size="x1"
                            >
                              <Icons.Plus />
                            </Button>
                          )}
                          {ocrResult?.matches.find(
                            (m) => m.text === e.distance
                          ) &&
                            !e.imageUrl?.includes('/frames/') && (
                              <Button
                                onClick={() => {
                                  reportEditor.updateObservation(e.uid, {
                                    imageUrl: `${
                                      org?.uid
                                    }/images/observations/${project?.uid}/${
                                      inspection.uid
                                    }/frames/${
                                      ocrResult?.matches.find(
                                        (m) => m.text === e.distance
                                      )?.frame
                                    }.jpeg`,
                                  });
                                }}
                                size="x1"
                              >
                                {' '}
                                <Text size="x1">replace</Text>
                              </Button>
                            )}

                          <Button
                            color="negative"
                            onClick={() =>
                              reportEditor.removeObservation(e.uid)
                            }
                            variant="tertiary"
                            size="x1"
                          >
                            <Icons.Trash2 />
                          </Button>
                        </Buttons>
                      </Box>
                    </Box>
                  </Box>
                );
              } else if ('text' in e) {
                return (
                  <Box
                    width="300px"
                    padding="x3"
                    flex="vertical"
                    gap="x3"
                    alignChildrenVertical="between"
                    key={e.frame}
                    backgroundColor="accent-shade-1"
                    borderColor="accent-shade-3"
                    borderSize="x1"
                    borderBottom
                  >
                    <Box backgroundColor="text-shade-1">
                      <ImageFromUrl
                        src={getFullFilePath(
                          `${org?.uid}/images/observations/${project?.uid}/${inspection.uid}/frames/${e.frame}.jpeg`
                        )}
                        height="175px"
                        width="300px"
                      />
                    </Box>
                    <Box
                      flex="horizontal"
                      alignChildrenVertical="middle"
                      alignChildrenHorizontal="end"
                    >
                      <Distance strong value={e.text} type={'long'} />
                    </Box>

                    <Buttons
                      alignChildrenHorizontal="between"
                      alignChildrenVertical="end"
                    >
                      <AILogo size="25px" />
                      <Box flex="horizontal" gap="x2">
                        <AIPOIAddObservationButton
                          imageUrl={`${org?.uid}/images/observations/${project?.uid}/${inspection.uid}/frames/${e.frame}.jpeg`}
                          distance={e.text}
                          second={e.text}
                          projectId={project?.uid!}
                          inspectionId={inspection.uid}
                          maxDistance={maxDistance}
                        />
                        <Button
                          variant="tertiary"
                          flex="horizontal"
                          color="negative"
                          size="x1"
                          onClick={() => {
                            update({
                              ...ocrResult!,
                              matches: ocrResult!.matches.map((j) => {
                                if (j.text === e.text) {
                                  return {
                                    ...j,
                                    dismissed: true,
                                  };
                                } else return j;
                              }),
                            });
                          }}
                        >
                          <Icons.Trash2 />
                        </Button>
                      </Box>
                    </Buttons>
                  </Box>
                );
              }
            })}
          </Box>
        </ModalBody>
      </Modal>
    </>
  );
};

export default BuddyQAReviewButton;
