import { motion, SVGMotionProps } from 'framer-motion';
import React, { PropsWithChildren } from 'react';
import { Tick } from './useTicks';

type Props = {
  isSelected?: boolean;
  R: number;
  tick: Tick;
  tickLength?: number;
  tickOffset?: number;
  tickProps?: SVGMotionProps<SVGLineElement>;
};

const ClockRefTick = ({
  children,
  isSelected,
  R,
  tick,
  tickLength = 10,
  tickOffset = 10,
  tickProps,
}: PropsWithChildren<Props>) => {
  return (
    <motion.g>
      {children !== undefined && (
        <motion.g
          animate={{
            x: tick.x(),
            y: tick.y(),
          }}
          initial={{ x: R, y: R }}
        >
          <motion.text
            alignmentBaseline="middle"
            className="RadialSelector__tick-text"
            fill={
              isSelected
                ? 'var(--color-accent-shade-4)'
                : 'var(--color-text-shade-1)'
            }
            textAnchor="middle"
          >
            {children}
          </motion.text>
        </motion.g>
      )}

      <motion.line
        {...tickProps}
        animate={{
          x1: tick.x(tickOffset),
          y1: tick.y(tickOffset),
          x2: tick.x(tickOffset + tickLength),
          y2: tick.y(tickOffset + tickLength),
        }}
        initial={{ x2: R, y2: R }}
        stroke={isSelected ? 'var(--color-white)' : 'var(--color-text-shade-1)'}
      />
    </motion.g>
  );
};

export default ClockRefTick;
