import { PENDING_IMAGE_UPLOAD } from '@drainify/types';
import { Attributes, ImageProps } from 'preshape';
import React, { useEffect, useRef, useState } from 'react';
import ImageFallback from './ImageFallback';
import ImagePending from './ImagePending';

type Props = ImageProps;

const ImageFromUrl = ({
  src,
  ...rest
}: Attributes<HTMLImageElement, Props>) => {
  const refUnmounted = useRef(false);
  const [{ isError, isLoading }, setState] = useState({
    isError: false,
    isLoading: !!src,
  });

  useEffect(() => {
    if (src?.endsWith(PENDING_IMAGE_UPLOAD)) {
      return;
    }

    refUnmounted.current = false;

    const handleLoad = () => {
      if (!refUnmounted.current) {
        setState({ isError: false, isLoading: false });
      }
    };

    const handleError = () => {
      if (!refUnmounted.current) {
        setState({ isError: true, isLoading: false });
      }
    };

    if (src) {
      const image = new Image();
      image.src = src;
      image.addEventListener('load', handleLoad);
      image.addEventListener('error', handleError);
    }

    return () => {
      refUnmounted.current = true;
    };
  }, [src]);

  if (src?.endsWith(PENDING_IMAGE_UPLOAD)) {
    return <ImagePending {...rest} />;
  }

  return (
    <ImageFallback
      {...rest}
      isError={isError}
      isLoading={isLoading}
      src={src}
    />
  );
};

export default ImageFromUrl;
