import { Drawing, DrawingPostBody } from '@drainify/types';
import { Box, Icons, Text, TextProps } from 'preshape';
import React from 'react';

type Props = TextProps & {
  drawing?: Drawing | DrawingPostBody;
};

const DrawingView = ({ drawing, ...rest }: Props) => {
  return (
    <Text {...rest} size="x3">
      <Box
        alignChildrenVertical="middle"
        flex="horizontal"
        gap="x4"
        grow
        margin="x2"
      >
        <Box alignChildrenVertical="middle" flex="horizontal" gap="x2" grow>
          <Box>
            <Icons.Edit3 />
          </Box>
          <Box grow>
            <Text strong>{drawing?.name || 'Unnamed drawing'}</Text>
          </Box>
        </Box>
      </Box>
    </Text>
  );
};

export default DrawingView;
