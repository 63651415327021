import { Node, NodePostBody, SectionNodePosition } from '@drainify/types';
import { getNodeSchema, isNode } from '@drainify/utils';
import { Box, Icons, Text, TextProps } from 'preshape';
import React from 'react';
import { isDesktop } from '../../utils/client';
import NodesIcon from '../IconsMisc/NodesIcon';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import ReportErrorIndicator from '../Report/ReportErrorIndicator';
import ReportMapToolAddExistingNode from '../Report/ReportMap/ReportMapTools/ReportMapToolAddExistingNode';
import { useLanguageContext } from '../Usage/LanguageProvider';
import NodeLinkIndicator from './NodeLinkIndicator';
import NodeSymbol from './NodeSymbol';

type Props = TextProps & {
  node?: Node | NodePostBody;
  nodePosition?: SectionNodePosition;
  validate?: boolean;
};

const NodeView = ({
  node,
  nodePosition = SectionNodePosition.START,
  validate = true,
  ...rest
}: Props) => {
  const { language } = useLanguageContext();
  const { reportEditor } = useReportEditorContext();
  const { isCustomerView } = useProjectContext()
  const nodeName = reportEditor.getNodeName(node);
  const nodeSchema = node && getNodeSchema(node.code);
  const error =
    isNode(node) && validate ? reportEditor.getNodeError(node.uid) : null;

  return (
    <Text {...rest} flex="horizontal" gap="x4" size="x3">
      {isDesktop() && isNode(node) && !node.point && !isCustomerView && (
        <ReportMapToolAddExistingNode node={node} />
      )}
      <Box basis="0" grow>
        <Box alignChildrenVertical="middle" flex="horizontal" gap="x2" grow>
          <Box>
            <NodesIcon/>
          </Box>

          <Box grow>
            <Text strong>{nodeName || 'Unknown'}</Text>
          </Box>
        </Box>

        {node ? (
          nodeSchema?.name && <Text>{nodeSchema.name[language]}</Text>
        ) : (
          <Text>Node is unknown at this time</Text>
        )}
      </Box>

      {isNode(node) && (
        <Box>
          <NodeLinkIndicator node={node} />
        </Box>
      )}

      {node !== undefined && validate && (
        <Box>
          <ReportErrorIndicator error={error} />
        </Box>
      )}

      {node && (
        <Box
          alignChildrenHorizontal="middle"
          flex="vertical"
          gap="x2"
          textColor="text-shade-1"
        >
          <NodeSymbol code={node?.code} nodePosition={nodePosition} />
        </Box>
      )}
      {
        node && !node.imageUrl && <Icons.CameraOff/>
      }
    </Text>
  );
};

export default NodeView;
