import {
  Box,
  Button,
  Buttons,
  FormProvider,
  Icons,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from 'preshape';
import React from 'react';
import AsyncButton from '../../../components/AsyncButton/AsyncButton';
import PasswordResetInput from '../../../components/User/PasswordResetInputs';
import usePasswordResetForm from '../../../hooks/forms/usePasswordResetForm';

type Props = {
  onClose: () => void;
};

const UserSecurityPage = ({ onClose }: Props) => {
  const { form, submit, isError, isLoading, isSuccess } =
    usePasswordResetForm();

  return (
    <>
      <ModalHeader>Change password</ModalHeader>
      <ModalBody>
        <FormProvider form={form} validateOnlyOneAtATime>
          <PasswordResetInput />
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <Buttons>
          <AsyncButton
            color="accent"
            basis="0"
            size="x3"
            grow
            disabled={form.hasError}
            isError={isError}
            isLoading={isLoading}
            isSuccess={isSuccess}
            onClick={async () => {
              await submit();
              onClose();
            }}
            variant="primary"
          >
            <Box alignChildrenVertical="middle" flex="horizontal" gap="x2">
              <Icons.Lock />
              <Text>Update</Text>
            </Box>
          </AsyncButton>
          <Button grow size="x3" onClick={onClose}>
            Cancel
          </Button>
        </Buttons>
      </ModalFooter>
    </>
  );
};

export default UserSecurityPage;
