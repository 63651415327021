import { Drawing } from '@drainify/types';
import { useMemo } from 'react';
import useDrawGeometry from '../Map/useDrawGeometry';
import { MapLayerPolygon } from '../Map/useMap';

type Props = {
  drawing: Drawing;
};

const ReportMapDrawingShow = ({ drawing }: Props) => {
  useDrawGeometry<MapLayerPolygon>(
    useMemo(
      () => ({
        geometry: {
          type: 'Polygon',
          coordinates: [drawing.points.map((e) => e.point.coordinates)],
        },
        style: {
          fillColor: '#5c7cfa',
          fillOpacity: drawing.opacity || 1,
          strokeWeight: 1,
        },
      }),
      [drawing]
    )
  );

  return null;
};

export default ReportMapDrawingShow;
