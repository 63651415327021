"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.observationPointPatterns = exports.getObservationWaterLevelPoints = exports.isObservationWaterLevel = exports.isObservation = exports.getObservationColor = exports.getObservationValidations = void 0;
const types_1 = require("@drainify/types");
const yup_1 = require("yup");
const geojson_1 = require("./geojson");
const observationSchema_1 = require("./observationSchema");
const _1 = require(".");
const OBSERVATION_COLOR_MAP = {};
const getObservationValidations = (reportEditor, observationUid, schema) => {
    const observationNames = reportEditor.report.observations
        .filter(({ uid }) => uid !== observationUid)
        .map((observation) => reportEditor.getObservationName(observation));
    const options = schema && (0, observationSchema_1.getObservationOptions)(schema.path);
    const codes = [(0, yup_1.mixed)().oneOf(Object.values(types_1.ObservationCode)).required()];
    const attributes = {};
    const distance = (0, yup_1.number)().min(0).required('Distance is required');
    if (options) {
        for (const option of options) {
            let v;
            if ((0, observationSchema_1.isOptionCode)(option)) {
                v = (0, yup_1.mixed)().oneOf(option.schema.map(({ code }) => code));
                if (option.required) {
                    v = v.required('Required');
                    codes.push(v);
                }
            }
            else {
                if ((0, observationSchema_1.isOptionAngle)(option)) {
                    v = (0, yup_1.number)()
                        .min(1, 'Clock reference must be greater than or equal to 1')
                        .max(12, 'Clock reference must be less than or equal to 12');
                }
                if ((0, observationSchema_1.isOptionAngleRange)(option)) {
                    v = (0, yup_1.array)()
                        .of((0, yup_1.number)()
                        .min(1, 'Clock reference must be greater than or equal to 1')
                        .max(12, 'Clock reference must be less than or equal to 12'))
                        .length(2, 'Clock reference range must have a start and end');
                }
                if ((0, observationSchema_1.isOptionBoolean)(option)) {
                    v = (0, yup_1.boolean)();
                }
                if ((0, observationSchema_1.isOptionEnum)(option)) {
                    v = (0, yup_1.mixed)().oneOf(Object.keys(option.enum));
                }
                if ((0, observationSchema_1.isOptionPercentage)(option)) {
                    v = (0, yup_1.number)()
                        .min(0, 'Percentage must be greater than or equal to 0')
                        .max(100, 'Percentage must be less than or equal to 100');
                }
                if ((0, _1.isOptionContinuous)(option)) {
                    v = (0, yup_1.boolean)();
                }
                if (option.required && v)
                    v = v.required('Required');
                if (v)
                    attributes[option.attribute] = v;
            }
        }
    }
    return (0, yup_1.object)().shape({
        code: (0, yup_1.object)(Object.assign({ codes: (0, yup_1.array)().min(codes.length) }, Object.fromEntries(Object.entries(codes)))).transform((_, originalValue) => (Object.assign({ codes: originalValue }, Object.fromEntries(Object.entries(originalValue))))),
        attributes: (0, yup_1.object)().shape(attributes),
        imageUrl: (0, yup_1.string)(),
        index: (0, yup_1.number)().required(),
        distance,
        name: (0, yup_1.string)()
            .unique(observationNames, 'Name must be unique')
            .test('length', `Maximum length is 12 characters`, (e) => !e || e.length <= 12),
        uid: (0, yup_1.string)(),
        inspectionUid: (0, yup_1.string)().required(),
        remarks: (schema === null || schema === void 0 ? void 0 : schema.remarksRequired)
            ? (0, yup_1.string)()
                .test('length', `Maximum length is 160 characters`, (e) => !e || e.length <= 160)
                .required()
            : (0, yup_1.string)().test('length', `Maximum length is 160 characters`, (e) => !e || e.length <= 160),
    });
};
exports.getObservationValidations = getObservationValidations;
/**
 * Gets an observations identity color
 */
const getObservationColor = (code) => {
    return OBSERVATION_COLOR_MAP[code] || 'text-shade-1';
};
exports.getObservationColor = getObservationColor;
/**
 * Identity function for determining between an Observation and
 * an ObservationPostBody
 */
const isObservation = (observation) => {
    return !!observation && 'uid' in observation;
};
exports.isObservation = isObservation;
/**
 * Checks if an Observation is a Water Level Observation
 */
const isObservationWaterLevel = (observation) => {
    return observation.code.includes(types_1.ObservationCode.WL);
};
exports.isObservationWaterLevel = isObservationWaterLevel;
/**
 * Return the focal point for a Water Level Observation.
 */
const getObservationWaterLevelPoints = (observation, reportEditor) => {
    const waterLevelObservations = reportEditor
        .getInspectionObservations(observation.inspectionUid, types_1.ObservationCode.WL)
        .filter(({ attributes, distance }) => distance !== undefined && attributes.percentage !== undefined)
        .sort((a, b) => (a.distance || 0) - (b.distance || 0));
    const index = waterLevelObservations.findIndex(({ uid }) => uid === observation.uid);
    const waterLevelPost = waterLevelObservations[index + 1];
    const section = reportEditor.getObservationSection(observation.uid);
    const a = reportEditor.getSectionPointFromDistance(section === null || section === void 0 ? void 0 : section.uid, observation.distance || 0);
    const b = reportEditor.getSectionPointFromDistance(section === null || section === void 0 ? void 0 : section.uid, (waterLevelPost === null || waterLevelPost === void 0 ? void 0 : waterLevelPost.distance) || Infinity);
    return (0, geojson_1.getSectionPoints)(a, b);
};
exports.getObservationWaterLevelPoints = getObservationWaterLevelPoints;
/**
 *
 */
exports.observationPointPatterns = [[exports.isObservationWaterLevel, exports.getObservationWaterLevelPoints]];
