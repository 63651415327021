import { Inspection, InspectionPostBody } from '@drainify/types';
import { DateTime } from 'luxon';
import { Text } from 'preshape';
import React from 'react';
import SummaryCard from '../SummaryCard/SummaryCard';

type Props = {
  inspection?: Inspection | InspectionPostBody;
  image?: File;
};

const InspectionSummary = ({ inspection }: Props) => {
  return (
    <SummaryCard>
      <Text weak size="x1">
        {inspection &&
          'Created at ' +
            DateTime.fromISO(inspection?.createdAt).toFormat('DDDD @ HH:mm')}
      </Text>
    </SummaryCard>
  );
};

export default InspectionSummary;
