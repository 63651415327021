import { LanguageCodes } from '@drainify/types';
import React, { createContext, PropsWithChildren, useContext } from 'react';

type Context = {
  language: LanguageCodes;
  setLanguage: (l: LanguageCodes) => void;
};

const LanguageContext = createContext<Context>({
  language: LanguageCodes.EN,
  setLanguage: () => {},
});

export const useLanguageContext = () => useContext(LanguageContext);

const LanguageProvider = ({ children }: PropsWithChildren<{}>) => {
  const [language, setLanguage] = React.useState<LanguageCodes>(
    LanguageCodes.EN
  );

  const context: Context = {
    language,
    setLanguage,
  };

  return (
    <LanguageContext.Provider value={context}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
