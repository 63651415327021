export interface TranscodedVideo {
  jobId: string;
  status: VideoTranscodeStatus;
  thumbnailUrl?: string;
  videoUrl?: string;
  outputVideoSize?: number;
}

export enum VideoTranscodeStatus {
  FAILED = 0,
  SUCCEEDED = 1,
  PENDING = 2,
  NOT_TRANSCODED = 3,
}

export interface VideoTranscodeJobCloudFunction {
  frames: ExtractionFrames[];
  orgId: string;
  inspectionId: string;
  actorId: string;
  projectId: string;
  created: FirebaseFirestore.Timestamp;
  executionTime?: number;
  totalStorage?: number;
  status: VideoTranscodeStatus;
  outputVideoUrl?: string;
  failedFrameExtractions?: string[];
}

export interface ExtractionFrames {
  timeStamp: number;
  observationId: string;
}

export interface VideoTranscodeStatusWrapper {
  status: VideoTranscodeStatus;
}
