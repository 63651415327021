import { InspectionMarker, ObservationCode } from '@drainify/types';
import { Button, ButtonProps, Buttons, Icons } from 'preshape';
import React from 'react';
import ObservationCreateButton from '../Observations/ObservationCreateButton';
import { useReportEditorContext } from '../Report/ReportEditorProvider';

type Props = ButtonProps & {
  distance: number;
  second: number;
  projectId: string;
  inspectionId: string;
  maxDistance: number;
  imageUrl: string;
};

const AIPOIAddObservationButton = ({
  distance,
  inspectionId,
  maxDistance,
  imageUrl,
  second,
}: Props) => {

  const { reportEditor, editSection, createNewObservationFromAI } = useReportEditorContext()
  const inspection = reportEditor.getInspectionByUid(inspectionId)


  const newSA = async (
    inspectionId: string,
    second: number,
    distance: number
  ) => {
    reportEditor.addObservationNoUpdate({
      code: [ObservationCode.SA],
      attributes: {},
      inspectionUid: inspectionId,
      timeStamp: second,
      distance,
      imageUrl: imageUrl
    });

    await reportEditor.updateAsync?.(reportEditor.report);
  };


  const newGeneralPhotography = async (
    inspectionId: string,
    inspectionMarker: InspectionMarker,
    second: number,
    distance: number
  ) => {
    reportEditor.addObservation({
      code: [ObservationCode.GP],
      attributes: {},
      inspectionUid: inspectionId,
      timeStamp: second,
      inspectionMarker,
      distance,
      imageUrl: imageUrl
    });

    if (inspectionMarker === 'finish') {
      editSection(inspection?.sectionUid!, 'attributes.length');
    }
  };

  if (distance === 0 && reportEditor.report.observations.filter(e => e.inspectionMarker === 'start' && e.inspectionUid === inspectionId).length === 0) {
    return (
      <Buttons flex='horizontal' alignChildrenHorizontal='middle'>
        <ObservationCreateButton variant='tertiary' inspectionUid={inspection?.uid} imageUrl={imageUrl} initialWL/>
      </Buttons>
    )
  }

  if (distance === maxDistance && reportEditor.report.observations.filter(e => (e.inspectionMarker === 'finish' || e.code.includes(ObservationCode.SA)) && e.inspectionUid === inspectionId).length === 0) {
    return (
      <Buttons flex='horizontal' alignChildrenHorizontal='middle'>
        <Button size='x1' variant="secondary" color="accent" flex="horizontal" gap="x2" onClick={async () => {
          newGeneralPhotography(inspectionId!, 'finish', second, distance);
          reportEditor.updateSection(inspection?.sectionUid!, {
            attributes: {
              ...reportEditor.getSectionByUid(inspection?.sectionUid)?.attributes,
              length: distance
            }
          })
        }
        } >
          Finish
        </Button>
        <Button size='x1' variant="secondary" color="accent" flex="horizontal" gap="x2" onClick={async () => { newSA(inspectionId!, second, distance); }}>Survey abandoned</Button>
      </Buttons>

    )

  }




  return (<Button variant='tertiary' color="accent" flex="horizontal" size='x1' gap="x2" onClick={async () => { 
    
    // const file = await getFileForImage(); 
    createNewObservationFromAI(inspectionId!, imageUrl, second, distance); }} >
    <Icons.Plus />
  </Button>
  )


};

export default AIPOIAddObservationButton;
