import { DateTime } from "luxon"
import { Modal, ModalBody, ModalHeader, Table, TableCell, TableRow, Icons, TableHeader, TableHeaderCell, Text, Box, Button, CheckBox, ModalFooter, TableBody, Placement, PlacementArrow, PlacementContent, PlacementManager, PlacementReference } from "preshape"
import React from "react"
import useOCRSearch from "../../hooks/useOCRResultsSearch"
import OCRView from "../../pages/OCRView"
import DatePicker from "../DatePicker/DatePicker"
import PlaceholderBlock from "../Placeholders/PlaceholderBlock"
import PlaceholderGradientAnimation from "../Placeholders/PlaceholderGradientAnimation"

type Props = {
    onClose: () => void
}

const PAGE_SIZE = 5

const ALL_BINARIZORS = [
    "AM Industrial Green",
    "AM Industrial Black",
    "AM Industrial white",
    "Scanprobe maxprobe v2 red",
    "Scanprobe maxprobe v2 generic",
    "Scanprobe maxprobe v2 white",
    "Scanprobe_maxprobe v3 green",
    "Scanprobe maxprobe v3 red",
    "Scanprobe maxprobe v3 white",
    "Scanprobe maxprobe v3 blue",
    "minicam solopro black",
    "minicam solopro white dark",
    "minicam solopro grey",
    "minicam solopro grey 2",
    "Unknown 1 ",
    "Unknown 2 ",
    "Unknown 3 ",
    "pearpoint 330 white on black",
    "pearpoint 330 blue on black",
    "pearpoint 330 red on black",
    "pearpoint 330 yellow on black",
    "Minicam proteus white",
    "Pearpoint 550 light blue",
    "pearpoint 550 dark blue",
    "pearpoint 550 generic",
    "Vivax green",
    "Vivax yellow",
    "Vivax black",
    "Ipek white on black",
    "Ipek white on colour",
    "Ipek purple on black",
    "Ipek grey on black",
    "Ipek black on white",
    "Ipek black on red",
    "Ipek red dark",
    "Ipek red light",
    "Ipek green light on black",
    "Ipek blue dark on black",
    "Ipek green dark on black",
    "ipek light blue",
    "Scanprobe v3 pink",
    "Scanprobe maxprobe v3 black",
    "itouch white",
    "xrange white",
]


const OCRExtractionTool = ({ onClose }: Props) => {
    const [binarizor, setBinarizor] = React.useState<string[]>(ALL_BINARIZORS);
    const [dateFrom, setDateFrom] = React.useState<string>('2024-09-01');
    const [offset, setOffset] = React.useState(0)
    const { query } = useOCRSearch({
        binarizor,
        dateFrom,
        usingBB: undefined
    },
        offset,
        PAGE_SIZE
    )
    const [loading, setLoading] = React.useState(query.isLoading)

    React.useEffect(() => {
        if (query.isLoading) {
            setLoading(true)
        } else {
            setTimeout(() => setLoading(false), 300)
        }
    }, [query.isLoading])

    React.useEffect(() => {
        query.refetch()
    }, [binarizor, dateFrom])


    return (
        <Modal visible={true} onClose={onClose}>
            <ModalHeader>OCR Extraction tool</ModalHeader>
            <Box flex='horizontal' alignChildrenHorizontal='around'>
            </Box>
            <ModalBody height='90vh' width='90vw'>
                <Table>
                    <TableHeader>
                        <TableHeaderCell>Binarizor</TableHeaderCell>
                        <TableHeaderCell>Frames</TableHeaderCell>
                        <TableHeaderCell>date</TableHeaderCell>
                        <TableHeaderCell>action</TableHeaderCell>
                    </TableHeader>
                    <TableBody>
                        {
                            loading &&
                            <>
                                <TableRow> <TableCell colSpan={4}> <PlaceholderGradientAnimation animating> <PlaceholderBlock height='2rem' /> </PlaceholderGradientAnimation> </TableCell> </TableRow>
                                <TableRow> <TableCell colSpan={4}> <PlaceholderGradientAnimation animating> <PlaceholderBlock height='2rem' /> </PlaceholderGradientAnimation> </TableCell> </TableRow>
                                <TableRow> <TableCell colSpan={4}> <PlaceholderGradientAnimation animating> <PlaceholderBlock height='2rem' /> </PlaceholderGradientAnimation> </TableCell> </TableRow>
                                <TableRow> <TableCell colSpan={4}> <PlaceholderGradientAnimation animating> <PlaceholderBlock height='2rem' /> </PlaceholderGradientAnimation> </TableCell> </TableRow>
                                <TableRow> <TableCell colSpan={4}> <PlaceholderGradientAnimation animating> <PlaceholderBlock height='2rem' /> </PlaceholderGradientAnimation> </TableCell> </TableRow>
                            </>
                        }

                        {!loading &&
                            query.data?.data.map(e => <TableRow key={e.inspectionId}>
                                <TableCell>
                                    <Box flex='horizontal' gap='x2' alignChildrenVertical='middle'>
                                         {e.usedCachedBB ? <Icons.Check /> : <Icons.X />} {e.binarizor}
                                    </Box>
                                </TableCell>
                                <TableCell>{e.matches.length}</TableCell>
                                <TableCell>{`${DateTime.fromISO(e.createdAt).toFormat('DD')}`}</TableCell>
                                <TableCell>{<OCRView og={e} orgId={e.org.uid} projectId={e.project.uid} inspectionId={e.inspectionId} refetch={() => query.refetch()} />}</TableCell>
                            </TableRow>)
                        }

                    </TableBody>
                </Table>
                {
                    !loading && query.data?.data.length === 0 && <Box flex='horizontal' grow padding='x3' alignChildrenHorizontal='middle'>
                        <Text strong textColor='text-shade-3'> No results found </Text>
                    </Box>
                }
            </ModalBody>
            <ModalFooter>
                <Box flex='horizontal' alignChildrenHorizontal='around'  alignChildrenVertical='middle'>
                    <PlacementManager trigger="click">
                        <PlacementReference>
                            {(props) => (
                                <Box>
                                    <Button {...props} variant='secondary'>Show binarizors {binarizor.length}/{ALL_BINARIZORS.length} </Button>
                                </Box>
                            )}
                        </PlacementReference>

                        <Placement placement="bottom" zIndex={1}>
                            <PlacementArrow backgroundColor="background-shade-3" />
                            <PlacementContent
                                backgroundColor="background-shade-1"
                                borderColor="background-shade-3"
                                borderRadius="x3"
                                borderSize="x2"
                                textColor="text-shade-1"
                            >
                                <Box flex='vertical' gap='x1' padding='x3' overflow='auto' height='300px'>
                                    <Button grow onClick={() => 
                                        setBinarizor(ALL_BINARIZORS)}>All</Button>
                                    <Button grow onClick={() => setBinarizor([])}>None</Button>
                                    {ALL_BINARIZORS.map(e => (
                                        <CheckBox key={e} checked={binarizor?.includes(e)} onChange={() => {
                                            if (binarizor.includes(e)) {
                                                setBinarizor(binarizor.filter(b => b !== e))
                                            } else {
                                                setBinarizor([...binarizor, e])
                                            }
                                        }}>{e}</CheckBox>
                                    ))
                                    }
                                </Box>
                            </PlacementContent>
                        </Placement>
                    </PlacementManager>
                    <Box flex='horizontal' gap='x3'>
                        <Button size='x1' variant='tertiary' color='accent' onClick={() => setOffset(offset - PAGE_SIZE)} disabled={offset === 0}><Icons.ChevronLeft /></Button>
                        <Box flex='vertical' alignChildrenHorizontal='middle'>
                            <Text>{(offset / PAGE_SIZE) + 1}/{Math.ceil((query.data?.meta?.total || PAGE_SIZE) / PAGE_SIZE)} </Text>
                            <Text size='x1'> {query.data?.meta?.total} found </Text>
                        </Box>
                        <Button variant='tertiary' size='x1' color='accent' onClick={() => setOffset(offset + PAGE_SIZE)} disabled={query.data?.meta?.total! <= (offset + PAGE_SIZE)}><Icons.ChevronRight /></Button>
                    </Box>

                    <PlacementManager trigger="click">
                        <PlacementReference>
                            {(props) => (
                                <Box>
                                    <Button {...props} variant='secondary' >{`From  ${dateFrom}`}</Button>
                                </Box>
                            )}
                        </PlacementReference>

                        <Placement placement="bottom" zIndex={1}>
                            <PlacementArrow backgroundColor="background-shade-3" />
                            <PlacementContent
                                backgroundColor="background-shade-1"
                                borderColor="background-shade-3"
                                borderRadius="x3"
                                borderSize="x2"
                                overflow="hidden"
                                textColor="text-shade-1"
                            >
                                <DatePicker padding='x3' onChange={e => {
                                    setDateFrom(e)
                                }} startDate={dateFrom} withSelectablePast />
                            </PlacementContent>
                        </Placement>
                    </PlacementManager>
                </Box>
            </ModalFooter>
        </Modal>
    )
}

export default OCRExtractionTool;