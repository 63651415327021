import {
  Booking,
  ResponseError,
  ResponseMultipleEntities,
} from '@drainify/types';
import { useQuery } from 'react-query';
import useFetch, { hasAllParams } from '../components/Api/useFetch';

export type jobState = 'unassigned' | 'dispatched' | 'submitted';

const useOrgBookingsWithState = (orgId?: string, states?: jobState) => {
  const fetch = useFetch();
  const key = ['bookings', `bookings/search/${states}`, fetch];
  const params = {
    states: states as string,
  };

  const query = useQuery<ResponseMultipleEntities<Booking>, ResponseError>(
    key,
    () => fetch('/bookings/states/:states', { method: 'GET', params }),
    { enabled: hasAllParams(params) }
  );

  return { query };
};

export default useOrgBookingsWithState;
