import { Booking } from '@drainify/types';
import {
  Box,
  Button,
  Buttons,
  Icons,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from 'preshape';
import React from 'react';
import { generatePath } from 'react-router';
import useFileUpload from '../../hooks/useFileUpload';
import useJobDocuments from '../../hooks/useJobDocuments';
import { FULL_SCREEN_MODAL_WIDTH } from '../App/App';
import AsyncButton from '../AsyncButton/AsyncButton';
import FileUpload from '../FileUpload/FileUpload';
import { useProjectContext } from '../Project/ProjectProvider';

type Props = {
  job: Booking;
};

const DocumentAddButton = ({ job }: Props) => {
  const { list } = useJobDocuments(job.uid);
  const [fileToUpload, setFileToUpload] = React.useState<File>();
  const [documentUploadModalVisible, setDocumentUploadModalVisible] =
    React.useState(false);
  const documentUpload = useFileUpload();
  const { activeBooking } = useProjectContext();

  const handleSubmit = async () => {
    if (fileToUpload) {
      await documentUpload.upload(
        generatePath('/bookings/:bookingId/documents', {
          bookingId: job.uid,
        }),
        fileToUpload,
        {
          filename: fileToUpload.name,
        }
      );
      list.refetch();
      handleClose();
    }
  };

  const handleClose = () => {
    setDocumentUploadModalVisible(false);
    setFileToUpload(undefined);
    documentUpload.reset();
  };

  return (
    <>
      <Button
        color="accent"
        variant="tertiary"
        onClick={() => setDocumentUploadModalVisible(true)}
        disabled={!!activeBooking?.completedAt}
        gap="x2"
      >
        Add
        <Icons.Plus />
      </Button>

      <Modal
        visible={documentUploadModalVisible}
        maxWidth={FULL_SCREEN_MODAL_WIDTH}
        onClose={handleClose}
      >
        <ModalHeader>Add document</ModalHeader>
        <ModalBody>
          <FileUpload
            maxWidth="300px"
            onChange={setFileToUpload}
            value={fileToUpload}
          >
            <Icons.File />
            <Text
              style={{
                WebkitUserSelect: 'none',
                msUserSelect: 'none',
                userSelect: 'none',
                WebkitTouchCallout: 'none',
                KhtmlUserSelect: 'none',
                MozUserSelect: 'none',
              }}
            >
              Select a document
            </Text>
          </FileUpload>

          <Box>
            <Box
              backgroundColor="accent-shade-1"
              maxWidth="300px"
              borderRadius="10px"
              borderColor="text-shade-4"
            >
              <Box
                borderRadius="10px"
                height="6px"
                backgroundColor="accent-shade-5"
                width={`${documentUpload.isLoadingProgress}%`}
              />
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Buttons>
            <AsyncButton
              onClick={handleSubmit}
              isLoading={documentUpload.isLoading}
              isSuccess={documentUpload.isSuccess}
              isError={documentUpload.isError}
              basis="0"
              disabled={fileToUpload === undefined}
              color="accent"
              elevate={true}
              gap="x2"
              grow
              size="x3"
              variant="primary"
            >
              Upload
            </AsyncButton>
          </Buttons>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default DocumentAddButton;
