import { Section } from '@drainify/types';
import { GeoJSONPointBlank } from '@drainify/utils';
import { Box } from 'preshape';
import React, { useContext } from 'react';
import { isDesktop } from '../../utils/client';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import { NodeMapContext } from './NodeMapProvider';

type Props = {
  onChange: (point: GeoJSON.Point[]) => void;
  section: Section;
};

const SectionRouteHack = ({ onChange, section }: Props) => {
  const { reportEditor } = useReportEditorContext();
  if (isDesktop()) {
    return null;
  }
  const {
    setOnChangePoints,
    setVisible,
    setMarkType,
    setStartPoint,
    setInitialPoints,
    setEndPoint,
  } = useContext(NodeMapContext);

  React.useEffect(() => {
    setOnChangePoints(onChange);
    setMarkType('route');
    setInitialPoints(section.additionalPoints?.map((e) => e.point) || []);
    setVisible(true);
    setStartPoint(reportEditor.getNodeByUid(section.nodeStartUid)?.point!);
    setEndPoint(reportEditor.getNodeByUid(section.nodeEndUid)?.point!);

    return () => {
      setOnChangePoints(() => {});
      setVisible(false);
      setMarkType(undefined);
      setStartPoint(GeoJSONPointBlank);
      setEndPoint(GeoJSONPointBlank);
    };
  }, []);

  return <Box width="calc(100vw - 100px)" height="calc(100vh)" />;
};

export default SectionRouteHack;
