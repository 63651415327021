import { PlanType } from '@drainify/types';
import { Alert, Box, BulletPoint, BulletPoints, Button, Icons, Modal, ModalBody, ModalHeader, Text } from 'preshape';
import React, { useContext } from 'react';
import BookingModal from '../../../components/Booking/BookingModal';
import { NodeMapContext } from '../../../components/Node/NodeMapProvider';
import PlanLocationHack from '../../../components/Node/PlanLocationHack';
import { useProjectContext } from '../../../components/Project/ProjectProvider';
import useBooking from '../../../hooks/useBooking';
import useProjectBookings from '../../../hooks/useProjectBookings';
import useProjectReport from '../../../hooks/useProjectReport';
import LoadingPage from '../Layout/LoadingPage';
import Page from '../Layout/Page';
import PageBody from '../Layout/PageBody';
import PageFooter from '../Layout/PageFooter';
import ProjectPageSnapshot from './ProjectPageSnaphot';

const ProjectPage = () => {
  const { project, activeBooking, activeBookingId } = useProjectContext();
  const { setProject, setReport } = useContext(NodeMapContext);
  const { update: updateBooking } = useBooking(activeBooking?.uid);

  const { query: queryReport, update: updateQueryReport } = useProjectReport({
    projectId: project?.uid,
  });

  const { create, query: bookingQuery } = useProjectBookings(project?.uid);
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    if (project) {
      setProject(project);
    }
    if (queryReport.data) {
      setReport(queryReport.data);
    }
  }, [project, queryReport.data]);

  const handleNoMap = () => {
    if (queryReport.data) {
      updateQueryReport.mutateAsync({
        ...queryReport.data,
        planType: PlanType.NO_PLAN,
      });
    }
  };

  if (!queryReport) {
    return null;
  }

  if (
    activeBookingId === undefined &&
    !create.isLoading &&
    !bookingQuery.isFetching
  ) {
    return (
      <BookingModal
        onClose={() => setVisible(false)}
        onDone={create}
        visible={true}
      />
    );
  }

  if (!activeBooking) {
    return <LoadingPage />;
  }

  if (activeBooking.assignedTo?.length === 0) {
    return (
      <Modal visible>
        <ModalHeader>Job needs assignee</ModalHeader>
        <ModalBody width='100vw' height='100vh' flex="vertical" alignChildrenVertical="middle" gap="x6">
          <Box flex="vertical" alignChildrenHorizontal="middle" gap="x4">
            <Icons.Users size="5rem" />
            <Text strong>Job is unassigned</Text>
            <Button onClick={() => setVisible(true)} color="accent">
              Click here to assign
            </Button>
            <BookingModal
              onClose={() => setVisible(false)}
              initialActiveStepId="assignedTo"
              booking={activeBooking}
              onDone={updateBooking}
              visible={visible}
            />
          </Box>
        </ModalBody>
      </Modal>
    );
  }

  if (
    queryReport.isFetched &&
    queryReport.data?.bounds === undefined &&
    queryReport.data?.planType === PlanType.GOOGLE_MAPS
  ) {
    return (
      <Page>
        <ModalHeader>Set site boundaries</ModalHeader>
        <PageBody>
        </PageBody>
        <PlanLocationHack onChange={() => { }} />
        <PageFooter>

          <Alert color='accent' margin='x3' padding='x2'>
            <BulletPoints numbered>
              <BulletPoint>
                <Box flex="horizontal" gap="x2">
                  Click
                  <Text
                    strong
                    flex="horizontal"
                    textColor='text-shade-1'
                    alignChildrenVertical='middle'
                  >
                    <Icons.Maximize />
                  </Text>
                  to set bounds
                </Box>
              </BulletPoint>
              <BulletPoint>
                <Box>
                  You can update this any time by going to "View Plan" on the main job page
                </Box>
              </BulletPoint>
            </BulletPoints>
          </Alert>
          <Box flex="horizontal" alignChildrenHorizontal="end">
            <Button onClick={handleNoMap} variant="secondary">
              I am not using the map
              <Icons.ChevronRight />
            </Button>
          </Box>
        </PageFooter>
      </Page>
    );
  }

  return <ProjectPageSnapshot />;
};

export default ProjectPage;
