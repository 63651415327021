import { Address, Location } from '@drainify/types';
import { getAddressString } from '@drainify/utils';
import { Text, TextProps } from 'preshape';
import React from 'react';

type Props = TextProps & {
  location?: Location | null;
};

const Address = ({ location, ...rest }: Props) => {
  if (!location) {
    return <Text>Missing location/customer</Text>;
  }

  return <Text {...rest}>{getAddressString(location)}</Text>;
};

export default Address;
