import {
  ResponseError,
  VideoTranscodeStatus,
  VideoTranscodeStatusWrapper,
} from '@drainify/types';
import { useQuery, useQueryClient } from 'react-query';
import useFetch from '../components/Api/useFetch';
import { useOrgContext } from '../components/Org/OrgProvider';

const useVideoStatus = (projectId?: string, inspectionId?: string) => {
  const fetch = useFetch();
  const org = useOrgContext();
  const key = [`/video/${projectId}/${inspectionId}/status`, fetch];
  const params = {
    projectId: projectId as string,
    inspectionId: inspectionId as string,
  };
  const queryClient = useQueryClient();

  const poll = useQuery<VideoTranscodeStatusWrapper, ResponseError>(
    key,
    () =>
      fetch('/video/:projectId/:inspectionId/status', {
        method: 'PUT',
        params,
      }),
    {
      onSuccess: (data) => {
        if (
          data.status === VideoTranscodeStatus.SUCCEEDED ||
          data.status === VideoTranscodeStatus.FAILED
        ) {
          queryClient.refetchQueries([`/orgs/${org.org?.uid}/usage`]);
          queryClient.refetchQueries([`/projects/${projectId}/report`]);
        }
      },
      refetchInterval: (videoTranscodeStatus) =>
        !videoTranscodeStatus ||
        videoTranscodeStatus.status === VideoTranscodeStatus.PENDING
          ? 3000
          : false,
    }
  );

  return { poll };
};

export default useVideoStatus;
