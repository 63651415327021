import React from 'react';
import useDrawMeasurementMap from '../../Report/ReportMap/ReportMapElements/useDrawMeasurementMap';

export type Props = {
  points: GeoJSON.Point[];
};

const MapLine = ({ points }: Props) => {
  useDrawMeasurementMap({ points, visible: true });

  return <></>;
};

export default MapLine;
