import { ImageCropData } from '@drainify/types';
import { getFullFilePath } from '@drainify/utils';
import {
  FormProvider,
  Input,
  InputLabel,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalProps,
  ModalTitle,
} from 'preshape';
import React, { ChangeEvent, useEffect, useState } from 'react';
import useUserForm from '../../hooks/forms/useUserForm';
import useFileUpload from '../../hooks/useFileUpload';
import { FULL_SCREEN_MODAL_WIDTH } from '../App/App';
import ImageCropper from '../Image/ImageCropper';
import ImageSelectButton, { ImageSelectData } from '../Image/ImageUploadButton';
import Wizard from '../Wizard/Wizard';
import WizardControls from '../Wizard/WizardControls/WizardControls';
import WizardReviewStep from '../Wizard/WizardReviewStep';
import WizardStep from '../Wizard/WizardStep';
import WizardStepError from '../Wizard/WizardStepError';
import { useUserContext } from './UserProvider';
import UserSummary from './UserSummary';

type Props = ModalProps & {
  onClose: () => void;
  visible: boolean;
  initialActiveStepId?: string;
};

const UserModal = ({
  initialActiveStepId,
  onClose,
  visible,
  ...rest
}: Props) => {
  const {
    isUpdating,
    isUpdatingFailed,
    isUpdatingSuccess,
    user,
    query,
    closeModal,
    update,
  } = useUserContext();
  const form = useUserForm(user);
  const fileUpload = useFileUpload();
  const [cropData, setCropData] = useState<ImageCropData>();
  const [imageSelectData, setImageSelectData] = useState<ImageSelectData>();

  const handleRemove = async () => {
    onClose();
  };

  const handleUpdate = async () => {
    await update({
      ...form.state,
      avatarCropData: cropData || form.state.avatarCropData,
    });

    if (imageSelectData?.file && user) {
      await fileUpload.upload(
        user && `/users/${user.uid}/avatar`,
        imageSelectData.file,
        cropData
      );
    } else if (cropData && user) {
      await fileUpload.update(user && `/users/${user.uid}/avatar`, cropData);
    }

    await query.refetch();

    closeModal();
  };

  const handleSelectImage = (data: ImageSelectData) => {
    setImageSelectData(data);
    setCropData(undefined);
  };

  useEffect(() => {
    if (!visible) {
      form.reset();
    }
  }, [visible]);

  return (
    <FormProvider form={form}>
      <Wizard
        flow="update"
        initialActiveStepId={initialActiveStepId}
        isError={fileUpload.isError || isUpdatingFailed}
        isLoading={fileUpload.isLoading || isUpdating}
        isSuccess={fileUpload.isSuccess || isUpdatingSuccess}
        onSave={handleUpdate}
        onRemove={handleRemove}
        onCancel={onClose}
        reset={visible}
      >
        <Modal
          {...rest}
          animation="FadeSlideUp"
          margin="x4"
          maxWidth={FULL_SCREEN_MODAL_WIDTH}
          onClose={onClose}
          overlayBackgroundCloseOnClick={false}
          visible={visible}
        >
          <ModalHeader>
            <ModalTitle>{user?.fullName}</ModalTitle>
          </ModalHeader>

          <ModalBody flex="vertical">
            <WizardStep id="fullName" title="Full name">
              <InputLabel>
                <Input
                  name="fullName"
                  placeholder="e.g. John Smith"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    form.setState((s) => ({ ...s, fullName: e.target.value }))
                  }
                  value={form.state.fullName}
                />
              </InputLabel>
            </WizardStep>

            <WizardStep id="email" title="Email">
              <InputLabel>
                <Input
                  name="email"
                  placeholder="e.g. john.smith@email.com"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    form.setState((s) => ({ ...s, email: e.target.value }))
                  }
                  value={form.state.email}
                />
              </InputLabel>
            </WizardStep>

            <WizardStep id="phoneNumber" title="Phone number">
              <InputLabel>
                <Input
                  name="phoneNumber"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    form.setState((s) => ({
                      ...s,
                      phoneNumber: e.target.value,
                    }))
                  }
                  value={form.state.phoneNumber}
                />
              </InputLabel>
            </WizardStep>

            <WizardStep
              action={
                <ImageSelectButton onSelect={handleSelectImage}>
                  {form.state.avatarUrlOriginal ? 'Replace' : 'Add'}
                </ImageSelectButton>
              }
              id="avatar"
              title="Profile picture"
            >
              <ImageCropper
                onChange={setCropData}
                cropData={
                  imageSelectData?.base64String
                    ? cropData
                    : cropData || form.state.avatarCropData
                }
                src={
                  imageSelectData?.base64String ||
                  getFullFilePath(form.state.avatarUrlOriginal)
                }
              />
            </WizardStep>

            <WizardReviewStep>
              <UserSummary user={form.state} />
            </WizardReviewStep>
          </ModalBody>

          <ModalFooter>
            <WizardStepError />
            <WizardControls />
          </ModalFooter>
        </Modal>
      </Wizard>
    </FormProvider>
  );
};

export default UserModal;
