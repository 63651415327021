import { Box, Buttons, FormProvider, Icons, Text } from 'preshape';
import React from 'react';
import AsyncButton from '../../../components/AsyncButton/AsyncButton';
import PageContext from '../../../components/Page/PageContext';
import PasswordResetInput from '../../../components/User/PasswordResetInputs';
import usePasswordResetForm from '../../../hooks/forms/usePasswordResetForm';
import Page from '../Layout/Page';
import PageBody from '../Layout/PageBody';
import PageFooter from '../Layout/PageFooter';
import PageHeader from '../Layout/PageHeader';

const UserSecurityPage = () => {
  const { form, submit, isError, isLoading, isSuccess } =
    usePasswordResetForm();

  return (
    <PageContext backToText="Back" backToLink="/settings/user">
      <FormProvider form={form} validateOnlySubmitted validateOnlyOneAtATime>
        <Page>
          <PageHeader title="Security" />

          <PageBody>
            <PasswordResetInput />
          </PageBody>

          <PageFooter>
            <Buttons>
              <AsyncButton
                color="accent"
                isError={isError}
                isLoading={isLoading}
                isSuccess={isSuccess}
                grow
                onClick={submit}
                size="x3"
                variant="primary"
              >
                <Box alignChildrenVertical="middle" flex="horizontal" gap="x2">
                  <Icons.Lock />
                  <Text>Change Password</Text>
                </Box>
              </AsyncButton>
            </Buttons>
          </PageFooter>
        </Page>
      </FormProvider>
    </PageContext>
  );
};

export default UserSecurityPage;
