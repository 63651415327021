import { InspectionMarker, Observation, ObservationPostBody } from '@drainify/types';
import { ReportError } from '@drainify/utils';
import { FormValidateFn, useForm } from 'preshape';
import { useCallback, useMemo } from 'react';
import { useReportEditorContext } from '../../components/Report/ReportEditorProvider';

const useObservationForm = (
  inspectionUid?: string,
  imageUrl?: string,
  observation?: Observation | ObservationPostBody,
  timeStamp?: number,
  distance?: number,
  inspectionMarker?: InspectionMarker
) => {
  const { reportEditor } = useReportEditorContext();

  // @ts-ignore
  const initial = useMemo<Observation | ObservationPostBody>(() => {
    if (observation) {
      return observation;
    }

    // if (inspectionUid) {
    return {
      code: [],
      inspectionUid,
      imageUrl,
      attributes: {},
      timeStamp,
      distance,
      inspectionMarker
    };
    // }

    // TODO(hhogg): Work out how to do conditional props so TS can catch this.
    // throw new Error(
    // 'Observation modal either needs an observationUid or inspectionUid'
    // );
  }, [observation, timeStamp, distance]);

  const validate = useCallback<
    FormValidateFn<Observation | ObservationPostBody, ReportError>
  >(
    (observation, setError) => {
      setError(reportEditor.validateObservation(observation));
    },
    [reportEditor]
  );

  const form = useForm({
    initial,
    validate,
  });

  return form;
};

export default useObservationForm;
