import { OCRResult, OCRResultStatus } from "@drainify/types"
import { Appear, Text, Tooltip } from "preshape"
import React from "react"

type Props = {
    ocrResult?: OCRResult;
}

const BuddySpeed = ({ ocrResult }: Props) => {
    if (!ocrResult || ocrResult.status === OCRResultStatus.PENDING) {
        return <Text>Loading</Text>
    }
    const { matches } = ocrResult

    function getAverageSpeed(meterages: number[], threshold: number = 0): number {
        const distances: number[] = [];
        for (let i = 1; i < meterages.length; i++) {
            const distance = meterages[i] - meterages[i - 1];
            if (distance > threshold) {
                distances.push(distance);
            }
        }
    
        if (distances.length === 0) {
            return 0;
        }
    
        const totalDistance = distances.reduce((acc, distance) => acc + distance, 0);
    
        const totalTime = distances.length; 
    
        const averageSpeed = totalDistance / totalTime;
    
        return averageSpeed;
    }

    const averageSpeed = getAverageSpeed(matches.map(e => e.text))

    if (averageSpeed <= 0.4) {
        return <Text align='middle' size='x2' strong>{averageSpeed.toFixed(2)}mps</Text>
    }

    return <Tooltip content="Keep speed between 0.1-0.4mps">
        {(props) => (
            <Appear>
                <Text {...props} align='middle' size='x2' textColor='negative-shade-5' strong style={{ textDecoration: 'underline' }}>{averageSpeed.toFixed(2)}mps</Text>
            </Appear>
        )}

    </Tooltip>

}

export default BuddySpeed;