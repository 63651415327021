import { Grid, GridProps } from 'preshape';
import React, { PropsWithChildren } from 'react';

const PageMenu = (props: PropsWithChildren<GridProps>) => {
  return (
    <Grid
      {...props}
      gap="x6"
      margin="x6"
      repeat={3}
      repeatWidthMax="1fr"
      repeatWidthMin="200px"
    />
  );
};

export default PageMenu;
