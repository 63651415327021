import { BoxProps, Buttons, Box, Text } from 'preshape';
import React, { PropsWithChildren } from 'react';

type Props = BoxProps & {};

const QuickOptions = ({ children, ...rest }: PropsWithChildren<Props>) => {
  return (
    <Box {...rest}>
      <Text margin="x3" strong>
        Quick options
      </Text>

      <Buttons wrap>{children}</Buttons>
    </Box>
  );
};

export default QuickOptions;
