export enum SectionMaterialCode {
  ABS = 'ABS',
  AC = 'AC',
  BR = 'BR',
  CAS = 'CAS',
  CI = 'CI',
  CLC = 'CLC',
  CMP = 'CMP',
  CO = 'CO',
  CP = 'CP',
  CS = 'CS',
  CSB = 'CSB',
  CSU = 'CSU',
  CT = 'CT',
  DI = 'DI',
  DIP = 'DIP',
  EP = 'EP',
  FC = 'FC',
  FRP = 'FRP',
  GCI = 'GCI',
  MAC = 'MAC',
  MAR = 'MAR',
  MX = 'MX',
  OB = 'OB',
  PCCP = 'PCCP',
  PCP = 'PCP',
  PE = 'PE',
  PF = 'PF',
  PP = 'PP',
  PS = 'PS',
  PSC = 'PSC',
  PVC = 'PVC',
  RC = 'RC',
  RCP = 'RCP',
  RPM = 'RPM',
  SB = 'SB',
  SP = 'SP',
  ST = 'ST',
  VC = 'VC',
  VCP = 'VCP',
  WD = 'WD',
  X = 'X',
  XI = 'XI',
  XP = 'XP',
  XXX = 'XXX',
  Z = 'Z',
  ZZZ = 'ZZZ',
}

export enum SectionShapeCode {
  A = 'A',
  B = 'B',
  C = 'C',
  E = 'E',
  H = 'H',
  K = 'K',
  O = 'O',
  R = 'R',
  T = 'T',
  U = 'U',
  CSC = 'CSC',
  RSC = 'RSC',
  Z = 'Z',
}

export enum SectionUseCode {
  C = 'C',
  F = 'F',
  S = 'S',
  T = 'T',
  W = 'W',
  Z = 'Z',
  CB_PACP = 'CB_PACP',
  DP_PACP = 'DP_PACP',
  FM_PACP = 'FM_PACP',
  LG_PACP = 'LG_PACP',
  LP_PACP = 'LP_PACP',
  PR_PACP = 'PR_PACP',
  SS_PACP = 'SS_PACP',
  SW_PACP = 'SW_PACP',
  XX_PACP = 'XX_PACP',
  ZZ_PACP = 'ZZ_PACP',
}

export enum SectionFlowCode {
  STE = 'STE',
  ETS = 'ETS',
}

export enum SectionNodePosition {
  START = 'S',
  END = 'E',
}

export enum Ownership {
  PU = 'PU',
  PR = 'PR',
  Z = 'Z',
}

export enum LegalStatus {
  PUBLIC = 'Public',
  PRIVATE = 'Private',
  MIXED = 'Mixed',
  UNKNOWN = 'Unknown',
}

export enum LiningType {
  CF = 'CF',
  CIP = 'CIP',
  CP = 'CP',
  DP = 'DP',
  M = 'M',
  SEG = 'SEG',
  SP = 'SP',
  SW = 'SW',
  Z = 'Z',
  FF = 'FF',
  FP = 'FP',
  GP = 'GP',
  GRC = 'GRC',
  N = 'N',
  SC = 'SC',
  SE = 'SE',
  SL = 'SL',
  SN = 'SN',
  XX = 'XX',
  ZZ = 'ZZ',
}

export enum CoatingMethod {
  CM = 'CM',
  CT = 'CT',
  EP = 'EP',
  PE = 'PE',
  PO = 'PO',
  PU = 'PU',
  PVC = 'PVC',
  XX = 'XX',
  ZZ = 'ZZ',
}

export enum PipeType {
  SECTION = 'Section',
  LATERAL = 'Lateral',
}

export enum LocationTypeCode {
  RD = 'RD',
  FWY = 'FWY',
  VG = 'VG',
  PD = 'PD',
  FLD = 'FLD',
  PR = 'PR',
  GDN = 'GDN',
  BLG = 'BLG',
  WLD = 'WLD',
  DIF = 'DIF',
  WWY = 'WWY',
  Z = 'Z',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  L = 'L',
  M = 'M',
  N = 'N',
  O = 'O',
  P = 'P',
  Y = 'Y',
}

export enum DrainSewerType {
  A = 'A',
  B = 'B',
  V = 'V',
}

export enum YesNo {
  Y = 'Y',
  N = 'N',
}

export enum AccuracyOfGPS {
  L = 'L',
  M = 'M',
  N = 'N',
}

export enum PLRSuffix {
  X = 'X',
  Y = 'Y',
  Z = 'Z',
}

export type SectionAttributes = {
  alternateID?: string;
  diameter?: number;
  height?: number;
  width?: number;
  divisionOrDistrict?: string;
  drainageArea?: string;
  flow?: SectionFlowCode;
  jointLength?: number;
  sectionOwnership?: Ownership;
  landOwnership?: Ownership;
  legalStatus?: LegalStatus;
  length?: number;
  liningType?: LiningType;
  material?: SectionMaterialCode;
  pipeType?: PipeType;
  PLRSuffix?: PLRSuffix;
  shape?: SectionShapeCode;
  use?: SectionUseCode;
  yearConstructed?: number;

  nodeOneCoordinate?: string;
  nodeOneRef?: string;
  nodeTwoCoordinate?: string;
  nodeTwoRef?: string;
  nodeThreeCoordinate?: string;
  nodeThreeRef?: string;
  pipeLengthRef?: string;
  locationTypeCode?: LocationTypeCode;
  drainSewerType?: DrainSewerType;
  pipeUnitLength?: string;
  expectedLength?: number;

  coatingMethod?: CoatingMethod;
  yearRenewed?: number;
  upNorthing?: string;
  upEasting?: string;
  upElevation?: string;
  downNorthing?: string;
  downEasting?: string;
  downElevation?: string;

  upRimToInvert?: string;
  upRimToGrade?: string;
  upGradeToInvert?: string;
  downRimToInvert?: string;
  downRimToGrade?: string;
  downGradeToInvert?: string;
};

export type SectionPostBody = {
  nodeStartUid?: string;
  nodeEndUid?: string;
  additionalPoints?: BendPoint[];
  attributes: SectionAttributes;
  createdAt: string;
};

export type BendPoint = {
  point: GeoJSON.Point;
  uid: string;
};

export type Section = SectionPostBody & {
  uid: string;
  index: number;
};

export const isCircular = (shape?: SectionShapeCode) =>
  shape === SectionShapeCode.C || shape === SectionShapeCode.CSC;

export const isMultiPoint = (section: Section | SectionPostBody) =>
  section.additionalPoints !== undefined && section.additionalPoints.length > 0;

export const isPacpLeveeOrDam = (use?: SectionUseCode) =>
  use === SectionUseCode.LG_PACP ||
  use === SectionUseCode.LP_PACP ||
  use === SectionUseCode.DP_PACP;
