import { Observation, ObservationPostBody } from '@drainify/types';
import {
  isOptionAngle,
  isOptionAngleRange,
  isOptionBoolean,
  isOptionCode,
  isOptionContinuous,
  isOptionDistance,
  isOptionDistanceShort,
  isOptionEnum,
  isOptionPercentage,
  isOptionRemarks,
  ObservationOption,
  ObservationOptionDistanceShort,
  ObservationValue,
} from '@drainify/utils';
import React, { SetStateAction } from 'react';
import Distance from '../../DistanceInput/Distance';
import DistanceInput from '../../DistanceInput/DistanceInput';
import QuickOption from '../../QuickOptions/QuickOption';
import QuickOptions from '../../QuickOptions/QuickOptions';
import ContinuousDefect from '../../RemarksInput/ContinuousDefect';
import ContinuousDefectInput from '../../RemarksInput/ContinuousDefectInput';
import Remarks from '../../RemarksInput/Remarks';
import RemarksInput from '../../RemarksInput/RemarksInput';
import { useReportEditorContext } from '../../Report/ReportEditorProvider';
import ObservationCodeSelector from '../ObservationCodeSelector';
import ObservationOptionAngleInput from './ObservationOptionAngleInput';
import ObservationOptionAngleRangeInput from './ObservationOptionAngleRangeInput';
import ObservationOptionBooleanInput from './ObservationOptionBooleanInput';
import ObservationOptionEnumInput from './ObservationOptionEnumInput';
import ObservationOptionPercentageInput from './ObservationOptionPercentageInput';

type Props<O extends ObservationOption> = {
  asSummary?: boolean;
  asIcon?: boolean;
  height: number;
  name?: string;
  option: O;
  setState?: (cb: SetStateAction<ObservationPostBody | Observation>) => void;
  value: ObservationValue<O>;
  width: number;
};
const hasQuickOptions = (option: ObservationOption): option is ObservationOptionDistanceShort  =>
  (option as ObservationOptionDistanceShort).quickOptions !== undefined;


const ObservationOptionRenderer = <O extends ObservationOption>({
  asSummary,
  asIcon,
  height,
  name,
  option,
  setState,
  value,
  width,
}: Props<O>) => {
  const { reportEditor } = useReportEditorContext();

  const handleSelect = (value: any) => {
    if (!setState) {
      return null;
    }

    if (isOptionCode(option)) {
      setState((observation) =>
        reportEditor.toObservation({
          ...observation,
          code: value,
        })
      );
    } else if (isOptionContinuous(option)) {
      setState((observation) => ({
        ...observation,
        observationContinuousState:
          observation.observationContinuousState === 'finish'
            ? undefined
            : value
            ? 'start'
            : undefined,
        attributes: {
          ...observation.attributes,
          [option.attribute]: value,
        },
      }));
    } else {
      setState((observation) => ({
        ...observation,
        attributes: {
          ...observation.attributes,
          [option.attribute]: value,
        },
      }));
    }
  };

  if (isOptionAngle(option)) {
    return (
      <ObservationOptionAngleInput
        asSummary={asSummary}
        height={height}
        name={name}
        option={option}
        onSelect={handleSelect}
        value={value}
        width={width}
        asIcon={asIcon}
      />
    );
  }

  if (isOptionAngleRange(option)) {
    return (
      <ObservationOptionAngleRangeInput
        asSummary={asSummary}
        height={height}
        name={name}
        option={option}
        onSelect={handleSelect}
        value={value}
        width={width}
        asIcon={asIcon}
      />
    );
  }

  if (isOptionBoolean(option)) {
    return (
      <ObservationOptionBooleanInput
        asSummary={asSummary}
        name={name}
        option={option}
        onSelect={handleSelect}
        value={value}
        asIcon={asIcon}
      />
    );
  }

  if (isOptionCode(option)) {
    return (
      <ObservationCodeSelector
        observations={option.schema}
        onSelect={handleSelect}
        value={value}
      />
    );
  }

  if (isOptionDistanceShort(option)) {
    return asSummary ? (
      <Distance type="short" value={value} />
    ) : (
      <>
      <DistanceInput
        name={name}
        onChange={handleSelect}
        type="short"
        value={value}
      />
      {
        hasQuickOptions(option) &&  (
          <QuickOptions>
            {(option as ObservationOptionDistanceShort).quickOptions!.map((v, index) => (
              <QuickOption
                active={value === v}
                key={index}
                onClick={() => handleSelect(v)}
                size="x2"
                uppercase={false}
              >
                <Distance type="short" value={v} />
              </QuickOption>
            ))}
          </QuickOptions>

        )
        }
      </>
    );
  }

  if (isOptionDistance(option)) {
    return asSummary ? (
      <Distance type="long" value={value} />
    ) : (
      <DistanceInput
        name={name}
        onChange={handleSelect}
        type="long"
        value={value}
      />
    );
  }

  if (isOptionEnum(option)) {
    return (
      <ObservationOptionEnumInput
        asSummary={asSummary}
        name={name}
        option={option}
        onSelect={handleSelect}
        value={value}
      />
    );
  }

  if (isOptionPercentage(option)) {
    return (
      <ObservationOptionPercentageInput
        asSummary={asSummary}
        name={name}
        option={option}
        onSelect={handleSelect}
        value={value}
        asIcon={asIcon}
      />
    );
  }

  if (isOptionRemarks(option)) {
    return asSummary ? (
      <Remarks value={value} />
    ) : (
      <RemarksInput name={name} onChange={handleSelect} value={value} />
    );
  }

  if (isOptionContinuous(option)) {
    return asSummary ? (
      <ContinuousDefect value={value} />
    ) : (
      <ContinuousDefectInput value={value} onChange={handleSelect} />
    );
  }

  console.warn(`Option renderer for "${option.attribute}" not available.`);

  return null;
};

export default ObservationOptionRenderer;
