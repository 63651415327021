import { Plan } from '@drainify/types';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import MapLabel from '../../../Map/MapMarker/MapLabel';
import MapPlanMarker from '../../../Map/MapMarker/MapPlanMarker';
import { useReportMapContext } from '../ReportMapProvider';

type Props = {
  plan: Plan;
};

const ReportMapPlanCustomer = ({ plan }: PropsWithChildren<Props>) => {
  const { focusedPlanUid } = useReportMapContext();

  const [cachedDimensions, setCachedDimensions] = useState<GeoJSON.Polygon>();

  useEffect(() => {
    setCachedDimensions(plan.point);
  }, [plan.point]);

  if (!plan.point || !cachedDimensions) {
    return null;
  }

  return (
    <>
      <MapLabel
        id={plan.uid}
        point={{
          type: 'Point',
          coordinates: cachedDimensions.coordinates[0][0],
        }}
        visible={focusedPlanUid !== plan.uid}
      ></MapLabel>

      <MapPlanMarker
        points={cachedDimensions}
        plan={plan}
        opacity={plan.opacity}
      ></MapPlanMarker>
    </>
  );
};

export default ReportMapPlanCustomer;
