import { CountryData, countryCode } from 'emoji-flags';
import { Box, Text, InputLabelProps } from 'preshape';
import React from 'react';

type Props = {
  code: CountryData['code'];
};

const CountryView = ({ code }: Props & InputLabelProps) => {
  const country = countryCode(code);

  return (
    <Box alignChildrenVertical="middle" flex="horizontal" gap="x2">
      <Box basis="0" grow minWidth="0">
        <Text breakOn="word" size="x3" strong>
          {country.name}
        </Text>
      </Box>

      <Box>
        <Text size="x5">{country.emoji}</Text>
      </Box>
    </Box>
  );
};

export default CountryView;
