import { Member, OrgRole } from '@drainify/types';
import { ReportError, runValidations } from '@drainify/utils';
import { FormValidateFn, useForm } from 'preshape';
import { useCallback } from 'react';
import * as yup from 'yup';
import { useReportEditorContext } from '../../components/Report/ReportEditorProvider';

const validations = yup.object().shape({
  role: yup.mixed().oneOf(Object.values(OrgRole)).required(),
});

const useMemberForm = (member: Member) => {
  const { reportEditor } = useReportEditorContext();

  const validate = useCallback<FormValidateFn<Member, ReportError>>(
    (member, setError) => {
      setError(runValidations(validations, member));
    },
    [reportEditor]
  );

  return useForm({
    initial: member,
    validate,
  });
};

export default useMemberForm;
