import { clockRefString, getCircleSegmentPath } from '@drainify/utils';
import { Box, Text } from 'preshape';
import React from 'react';
import SymbolTemplate from '../Symbol/SymbolTemplate';
import { isTickSelected, SEGMENT_SIZE_TOLERANCE } from './ClockRefInput';
import useTicks from './useTicks';

type Props = {
  clockFrom?: number;
  clockTo?: number;
  asIcon?: boolean;
};

const ClockRefSymbol = ({ clockFrom, clockTo, asIcon }: Props) => {
  const { segmentSize, ticks, R } = useTicks({
    segments: 12,
    size: 28,
  });

  return (
    <Box alignChildrenVertical="start" flex="horizontal" gap="x2">
      <Box grow>
        {clockFrom !== undefined && !asIcon && (
          <Text>
            {clockTo ? clockRefString(clockFrom, clockTo) : clockFrom}
          </Text>
        )}
      </Box>

      <SymbolTemplate borderRadius="50%">
        <Box height={28} tag="svg" viewBox={`0 0 ${28} ${28}`} width={28}>
          {ticks.map((tick) => (
            <path
              d={getCircleSegmentPath(
                R,
                R,
                R,
                tick.aOffset - segmentSize * SEGMENT_SIZE_TOLERANCE,
                tick.aOffset + segmentSize * SEGMENT_SIZE_TOLERANCE
              )}
              fill="var(--color-accent-shade-4)"
              key={tick.n}
              opacity={
                isTickSelected(tick.n, clockFrom, clockTo ? clockTo : clockFrom)
                  ? 1
                  : 0
              }
              stroke="var(--color-accent-shade-4)"
              strokeWidth={0.5}
            />
          ))}
        </Box>
      </SymbolTemplate>
    </Box>
  );
};

export default ClockRefSymbol;
