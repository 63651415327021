import { useEffect, useRef } from 'react';
import { useMapContext } from '../Map';
import { useGeometryStoreContext } from './GeometryStore';

const GeometryStoreFocusEverything = () => {
  const { fitToBounds } = useMapContext();
  const geometryStore = useGeometryStoreContext();
  const refHasFocused = useRef(false);

  useEffect(() => {
    if (
      geometryStore.store.bbox &&
      isFinite(geometryStore.store.bbox[0]) &&
      !refHasFocused.current &&
      fitToBounds({
        bbox: geometryStore.store.bbox,
      })
    ) {
      refHasFocused.current = true;
    }
  }, [geometryStore.store, fitToBounds]);

  return null;
};

export default GeometryStoreFocusEverything;
