import { Project, ReportView, ResponseError } from '@drainify/types';
import React from 'react';
import { UseQueryResult } from 'react-query';
import { Outlet, useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import Map from '../components/Map/Map';
import NodeMapProvider from '../components/Node/NodeMapProvider';
import { ProjectContext } from '../components/Project/ProjectProvider';
import ReportEditorContextProvider from '../components/Report/ReportEditorProvider';
import ReportMapCustomer from '../components/Report/ReportMap/ReportMapCustomer';
import ReportMapProvider from '../components/Report/ReportMap/ReportMapProvider';
import ReportCustomerProvider from '../components/ReportAccessPage/ReportCustomerProvider';
import { isMobile } from '../utils/client';
import { typeParmToReportElementMap } from './desktop/Pages/ProjectPage';
import ReportViewMobile from './mobile/Pages/ReportViewMobile';

type Props = {
  data?: ReportView;
};

const ReportViewer = ({ data }: Props) => {
  const navigate = useNavigate();

  const searchParams = useSearchParams()[0];
  const token = searchParams.get('token');
  const params = `?token=${token}`;
  const { type, uid } = useParams();

  const elementType = type ? typeParmToReportElementMap[type] : undefined;

  if (!data) {
    return null;
  }

  const projectContext = {
    isRemoving: false,
    isRemovingFailed: false,
    isRemovingSuccess: false,
    isUpdating: false,
    isUpdatingFailed: false,
    isUpdatingSuccess: false,
    project: data.project,
    customerReportView: data,
    activeBookingId: data.job.uid,
    activeBooking: data.job,
    isCustomerView: true,
    setActiveBookingId: () => { },
    edit: () => { },
    query: {} as UseQueryResult<Project, ResponseError>,
    remove: () => Promise.reject(new Error('Context')),
  };

  if (isMobile()) {
    return (
      <NodeMapProvider isCustomerReport>
        <ProjectContext.Provider value={projectContext}>
          <ReportEditorContextProvider report={data.report}>
            <ReportViewMobile data={data} />
          </ReportEditorContextProvider>
        </ProjectContext.Provider>
      </NodeMapProvider>
    );
  }

  return (
    <ReportMapProvider>
      <Map isInteractive>
        <ProjectContext.Provider value={projectContext}>
          <ReportEditorContextProvider report={data.report}>
            <ReportCustomerProvider>
              <ReportMapCustomer
                reportView={data}
                withSidebar
                focusedElementType={elementType}
                focusedElementUid={uid}
                key={data?.project.uid}
                onFocusNode={(uid) => navigate(`nodes/${uid}${params}`)}
                onFocusObservation={(uid) =>
                  navigate(`observations/${uid}${params}`)
                }
                onFocusSection={(uid) => navigate(`sections/${uid}${params}`)}
                onFocusInspection={(uid) =>
                  navigate(`inspections/${uid}${params}`)
                }
                onFocusPlan={(uid) => navigate(`plans/${uid}${params}`)}
                onUnfocus={() => navigate(`./${params}`)}
              >
                <Outlet />
              </ReportMapCustomer>
            </ReportCustomerProvider>
          </ReportEditorContextProvider>
        </ProjectContext.Provider>
      </Map>
    </ReportMapProvider>
  );
};

export default ReportViewer;
