import React, { createContext, PropsWithChildren, useContext } from 'react';
import GeometryStoreDebugger from './GeometryStoreDebugger';
import useGeometryStore, { useGeometryStoreResult } from './useGeometryStore';

const GeometryStoreContext = createContext<useGeometryStoreResult>({
  store: {
    map: {},
    list: [],
    groups: {
      LineString: [],
      Point: [],
    },
  },
  register: () => '',
  update: () => {},
  remove: () => {},
});

export const useGeometryStoreContext = () => useContext(GeometryStoreContext);

const GeometryStore = ({ children }: PropsWithChildren<{}>) => {
  const geometryStore = useGeometryStore();

  return (
    <GeometryStoreContext.Provider value={geometryStore}>
      {children}
      {window.location.search.includes('debug') && <GeometryStoreDebugger />}
    </GeometryStoreContext.Provider>
  );
};

export default GeometryStore;
