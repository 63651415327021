import { Node, NodePostBody } from '@drainify/types';
import { ReportError } from '@drainify/utils';
import { DateTime } from 'luxon';
import { FormValidateFn, useForm } from 'preshape';
import { useCallback, useMemo } from 'react';
import { useReportEditorContext } from '../../components/Report/ReportEditorProvider';

const useNodeForm = (node?: Node | NodePostBody) => {
  const { reportEditor } = useReportEditorContext();
  const initial = useMemo<Node | NodePostBody>(
    () =>
      node || {
        code: undefined!,
        createdAt: DateTime.now().toISO(),
        attributes: {},
      },
    [node]
  );

  const validate = useCallback<
    FormValidateFn<Node | NodePostBody, ReportError>
  >(
    (node, setError) => {
      setError(reportEditor.validateNode(node));
    },
    [reportEditor]
  );

  return useForm({
    initial,
    validate,
  });
};

export default useNodeForm;
