import { Booking } from '@drainify/types';
import { Box, BoxProps, Label } from 'preshape';
import React from 'react';
import AvatarStack from '../Avatar/AvatarStack';
import DateRangeText from '../DatePicker/DateRangeText';
import Address from '../Location/Address';
import PlaceholderAvatar from '../Placeholders/PlaceholderAvatar';
import PlaceholderBlock from '../Placeholders/PlaceholderBlock';
import PlaceholderLabel from '../Placeholders/PlaceholderLabel';
import ProjectRef from '../Project/ProjectRef';
import TimeRangeText from '../TimePicker/TimeRangeText';
import BookingStatus from './BookingStatus';

type Props = BoxProps & {
  booking?: Booking;
  showTime?: boolean;
};

const BookingView = ({ booking, showTime, ...rest }: Props) => {
  return (
    <Box flex="vertical">
      <Box {...rest} flex="horizontal" gap="x4" grow>
        <Box grow>
          {booking ? (
            <>
              <ProjectRef project={booking} />
              <BookingStatus booking={booking} />
              {showTime ? (
                <TimeRangeText
                  margin="x1"
                  startTime={booking.bookedForTimeStart}
                />
              ) : (
                <DateRangeText margin="x1" startDate={booking.bookedForDate} />
              )}
            </>
          ) : (
            <>
              <PlaceholderLabel />
              <PlaceholderBlock />
              <PlaceholderBlock />
            </>
          )}
        </Box>

        <Box alignChildrenVertical="end" flex="vertical">
          {booking ? (
            <AvatarStack users={booking.assignedTo} bookingId={booking.uid} />
          ) : (
            <PlaceholderAvatar />
          )}
        </Box>
      </Box>
      {
        <Box padding="x1">
          <Label backgroundColor="accent-shade-1" textColor="text-shade-1">
            <Box wrap width="240px">
              <Address size="x1" location={booking?.location} wrap />
            </Box>
          </Label>
        </Box>
      }
    </Box>
  );
};

export default BookingView;
