import { Invite } from '@drainify/types';
import { Box, BoxProps, Icons, Text, TextProps } from 'preshape';
import React, { PropsWithChildren } from 'react';
import OrgRoleBadge from '../Org/OrgRoleBadge';
import PlaceholderAvatar from '../Placeholders/PlaceholderAvatar';
import PlaceholderBlock from '../Placeholders/PlaceholderBlock';

type Props = TextProps & {
  invite?: Invite;
};

const INVITED_USER_NAME_PLACEHOLDER = 'Invited user';

const InviteView = ({
  invite,
  ...rest
}: PropsWithChildren<BoxProps & Props>) => {
  return (
    <Text {...rest} alignChildrenVertical="start" flex="horizontal" gap="x4">
      <Box alignChildrenVertical="middle" flex="horizontal" gap="x4" grow maxWidth='100%'>
        <Box>
          {invite ? (
            <Box
              alignChildrenHorizontal="middle"
              alignChildrenVertical="middle"
              backgroundColor="background-shade-3"
              borderRadius="full"
              flex="horizontal"
              height="3rem"
              width="3rem"
              textColor="text-shade-1"
            >
              <Icons.Mail size="1.5rem" />
            </Box>
          ) : (
            <PlaceholderAvatar size="3rem" />
          )}
        </Box>

        <Box basis="0" grow shrink overflow='hidden'>
          {invite ? (
            <>
              <Text size="x3">{INVITED_USER_NAME_PLACEHOLDER}</Text>
              <Text size="x3" textColor="text-shade-4">
                {invite.recipient}
              </Text>
            </>
          ) : (
            <>
              <PlaceholderBlock />
              <PlaceholderBlock />
            </>
          )}
        </Box>

      {invite && (
        <Box>
          <OrgRoleBadge role={invite.role} />
        </Box>
      )}
      </Box>
    </Text>
  );
};

export default InviteView;
