import { Node, NodePostBody, NodeShape } from '@drainify/types';
import { isNode } from '@drainify/utils';
import React from 'react';
import Distance from '../DistanceInput/Distance';
import { useHasPermission } from '../Permissions/Permissions';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import SummaryCard from '../SummaryCard/SummaryCard';
import SummaryCardSection from '../SummaryCard/SummaryCardSection';

type Props = {
  node?: Node | NodePostBody;
};

const NodeSummary = ({ node }: Props) => {
  const { editNodeAdditional, reportEditor } = useReportEditorContext();
  const hasUpdateProjectPermission = useHasPermission('projects:update');
  const error = reportEditor.validateNode(node);
  const { activeBooking } = useProjectContext();

  const editNodeStep = (step: string) => {
    if (!hasUpdateProjectPermission) {
      return undefined;
    }
    if (!!activeBooking?.completedAt) {
      return undefined;
    }

    return () => {
      if (isNode(node)) {
        editNodeAdditional(node.uid, step);
      } else {
        editNodeAdditional(null, step);
      }
    };
  };

  return (
    <SummaryCard>
      <SummaryCardSection
        error={error?.['attributes.coverFrameCondition']}
        onEdit={editNodeStep('attributes.coverFrameCondition')}
        title="Cover frame condition"
        value={node?.attributes.coverFrameCondition}
      >
        {node?.attributes.coverFrameCondition}
      </SummaryCardSection>
      <SummaryCardSection
        error={error?.['attributes.shape']}
        onEdit={editNodeStep('attributes.shape')}
        title="Shape"
        value={node?.attributes.shape}
      >
        {node?.attributes.shape}
      </SummaryCardSection>

      {node?.attributes.shape && node.attributes.shape === NodeShape.CIRCULAR && (
        <SummaryCardSection
          error={error?.['attributes.diameter']}
          onEdit={editNodeStep('attributes.diameter')}
          title="Diameter"
          value={node?.attributes.diameter}
        >
          <Distance value={node?.attributes.diameter} type="long" />
        </SummaryCardSection>
      )}
      {node?.attributes.shape && node.attributes.shape !== NodeShape.CIRCULAR && (
        <>
          <SummaryCardSection
            error={error?.['attributes.breadth']}
            onEdit={editNodeStep('attributes.breadth')}
            title="Breadth"
            value={node?.attributes.breadth}
          >
            <Distance value={node?.attributes.breadth} type="long" />
          </SummaryCardSection>
          <SummaryCardSection
            error={error?.['attributes.width']}
            onEdit={editNodeStep('attributes.width')}
            title="Width"
            value={node?.attributes.width}
          >
            <Distance value={node?.attributes.width} type="long" />
          </SummaryCardSection>
        </>
      )}
      <SummaryCardSection
        error={error?.['attributes.laterialConnections']}
        onEdit={editNodeStep('attributes.laterialConnections')}
        title="Laterial connections"
        value={node?.attributes.laterialConnections}
      >
        {node?.attributes.laterialConnections}
      </SummaryCardSection>
      <SummaryCardSection
        error={error?.['attributes.material']}
        onEdit={editNodeStep('attributes.material')}
        title="Material"
        value={node?.attributes.material}
      >
        {node?.attributes.material}
      </SummaryCardSection>
      <SummaryCardSection
        error={error?.['attributes.surfaceType']}
        onEdit={editNodeStep('attributes.surfaceType')}
        title="Surface type"
        value={node?.attributes.surfaceType}
      >
        {node?.attributes.surfaceType}
      </SummaryCardSection>
      <SummaryCardSection
        error={error?.['attributes.wallCondition']}
        onEdit={editNodeStep('attributes.wallCondition')}
        title="Wall condition"
        value={node?.attributes.wallCondition}
      >
        {node?.attributes.wallCondition}
      </SummaryCardSection>
      <SummaryCardSection
        error={error?.['attributes.benchingCondition']}
        onEdit={editNodeStep('attributes.benchingCondition')}
        title="Benching Condition"
        value={node?.attributes.benchingCondition}
      >
        {node?.attributes.benchingCondition}
      </SummaryCardSection>
    </SummaryCard>
  );
};

export default NodeSummary;
