import { ReportPostBody } from '@drainify/types';
import { ReportEditor } from '@drainify/utils';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';
import InspectionAdditionalModal from '../Inspection/InspectionAdditionalModal';
import InspectionModal from '../Inspection/InspectionModal';
import MeasurementModal from '../Measurement/MeasurementModal';
import NodeAdditionalInformationModal from '../Node/NodeAdditionalInformationModal';
import NodeModal from '../Node/NodeModal';
import DrawingModal from '../Observations/DrawingModal';
import ObservationModal from '../Observations/ObservationModal';
import SectionAdditionalModal from '../Section/SectionAdditionalModal';
import SectionModal from '../Section/SectionModal';

type Props = {
  report?: ReportPostBody;
  update?: (report: ReportPostBody) => any;
  updateAsync?: (report: ReportPostBody) => Promise<any>;
};

type Context = {
  reportEditor: ReportEditor<Promise<ReportPostBody>>;
  createNewNode: (onCreate?: (uid: string) => void) => void;
  createNewObservation: (
    inspectionUid: string,
    onCreate?: (uid: string) => void
  ) => void;
  createNewObservationFromAI: (
    inspectionUid: string,
    imageUrl: string,
    timestamp: number,
    distance: number,
    onCreate?: (uid: string) => void
  ) => void;
  createNewObservationFromVideo: (
    inspectionUid: string,
    image: File,
    timestamp: number
  ) => void;
  createNewSection: (onCreate?: (uid: string) => void) => void;
  createNewDrawing: (onCreate?: (uid: string) => void) => void;
  createNewMeasurement: (onCreate?: (uid: string) => void) => void;
  createNewInspection: (
    sectionUid: string,
    onCreate?: (uid: string) => void
  ) => void;
  editNode: (nodeUid: string | null, step?: string) => void;
  editNodeAdditional: (nodeUid: string | null, step?: string) => void;
  editObservation: (observationUid: string | null, step?: string) => void;
  editMeasurement: (measurementUid: string | null, step?: string) => void;
  editDrawing: (drawingUid: string | null, step?: string) => void;
  editSection: (sectionUid: string | null, step?: string, file?: File) => void;
  editSectionAdditional: (sectionUid: string, step?: string) => void;
  editInspection: (inspectionUid: string | null, step?: string) => void;
  editInspectionAdditional: (sectionUid: string, step?: string) => void;
  removeNode: (nodeUid: string) => void;
  removeObservation: (observationUid: string) => void;
  removeSection: (sectionUid: string) => void;
  removeInspection: (inspectionUid: string) => void;
  removeMeasurement: (inspectionUid: string) => void;
  removeDrawing: (inspectionUid: string) => void;
};

export const ReportEditorContext = createContext<Context>({
  createNewNode: () => {},
  createNewObservation: () => {},
  createNewObservationFromVideo: () => {},
  createNewObservationFromAI: () => {},
  createNewSection: () => {},
  createNewMeasurement: () => {},
  createNewDrawing: () => {},
  createNewInspection: () => {},
  editNode: () => {},
  editNodeAdditional: () => {},
  editObservation: () => {},
  editMeasurement: () => {},
  editDrawing: () => {},
  editSection: () => {},
  editSectionAdditional: () => {},
  editInspection: () => {},
  editInspectionAdditional: () => {},
  removeNode: () => {},
  removeObservation: () => {},
  removeSection: () => {},
  removeInspection: () => {},
  removeMeasurement: () => {},
  removeDrawing: () => {},
  reportEditor: ReportEditor.fromBlank((report) => Promise.resolve(report)),
});

export const useReportEditorContext = () => useContext(ReportEditorContext);

const ReportEditorContextProvider = ({
  children,
  report,
  update,
  updateAsync,
}: PropsWithChildren<Props>) => {
  const reportEditor = useMemo(() => {
    if (report) {
      return new ReportEditor(report, update, updateAsync);
    }

    return ReportEditor.fromBlank();
  }, [report]);

  const [capturedImage, setCapturedImage] = useState<File>();
  const [capturedImageUrl, setCapturedImageUrl] = useState<string>();
  const [capturedDistance, setCapturedDistance] = useState<number>();
  const [timeStamp, setTimestamp] = useState<number>();
  const [selectedNodeUid, setSelectedNodeUid] = useState<string>();
  const [selectedNodeAdditionalUid, setSelectedNodeAdditionalUid] =
    useState<string>();
  const [selectedObservationUid, setSelectedObservationUid] =
    useState<string>();
  const [
    selectedObservationInspectionUid,
    setSelectedObservationInspectionUid,
  ] = useState<string>();
  const [selectedSectionUid, setSelectedSectionUid] = useState<string>();
  const [selectedMeasurementUid, setSelectedMeasurementUid] =
    useState<string>();
  const [selectedDrawingUid, setSelectedDrawingUid] = useState<string>();
  const [selectedSectionAdditionalUid, setSelectedSectionAdditionalUid] =
    useState<string>();
  const [selectedInspectionUid, setSelectedInspectionUid] = useState<string>();
  const [selectedInspectionSectionUid, setSelectedInspectionSectionUid] =
    useState<string>();
  const [selectedInspectionAdditionalUid, setSelectedInspectionAdditionalUid] =
    useState<string>();

  const [selectedNodeEditStep, setSelectedNodeEditStep] = useState<string>();
  const [selectedSectionEditStep, setSelectedSectionEditStep] =
    useState<string>();
  const [selectedMeasurementEditStep, setSelectedMeasurementEditStep] =
    useState<string>();
  const [selectedDrawingEditStep, setSelectedDrawingEditStep] =
    useState<string>();
  const [
    selectedSectionAdditionalEditStep,
    setSelectedSectionAdditionalEditStep,
  ] = useState<string>();
  const [selectedObservationEditStep, setSelectedObservationEditStep] =
    useState<string>();
  const [selectedInspectionEditStep, setSelectedInspectionEditStep] =
    useState<string>();
  const [
    selectedInspectionAdditionalEditStep,
    setSelectedInspectionAdditionalEditStep,
  ] = useState<string>();

  const [isCreatingNode, setIsCreatingNode] = useState(false);
  const [isCreatingObservation, setIsCreatingObservation] = useState(false);
  const [isCreatingSection, setIsCreatingSection] = useState(false);
  const [isCreatingMeasurement, setIsCreatingMeasurement] = useState(false);
  const [isCreatingDrawing, setIsCreatingDrawing] = useState(false);
  const [isCreatingInspection, setIsCreatingInspection] = useState(false);

  const [createNodeCallback, setCreateNodeCallback] =
    useState<(uid: string) => void>();
  const [createObservationCallback, setCreateObservationCallback] =
    useState<(uid: string) => void>();
  const [createSectionCallback, setCreateSectionCallback] =
    useState<(uid: string) => void>();
  const [createMeasurementCallback, setCreateMeasurementCallback] =
    useState<(uid: string) => void>();
  const [createDrawingCallback, setCreateDrawingCallback] =
    useState<(uid: string) => void>();
  const [createInspectionCallback, setCreateInspectionCallback] =
    useState<(uid: string) => void>();

  const handleCloseNodeModal = () => {
    setSelectedNodeUid(undefined);
    setSelectedNodeEditStep(undefined);
    setIsCreatingNode(false);
    setCreateNodeCallback(undefined);
  };

  const handleCloseNodeAdditionalInformationModal = () => {
    setSelectedNodeAdditionalUid(undefined);
    setSelectedNodeEditStep(undefined);
    setIsCreatingNode(false);
    setCreateNodeCallback(undefined);
  };

  const handleCloseObservationModal = () => {
    setSelectedObservationUid(undefined);
    setSelectedObservationInspectionUid(undefined);
    setSelectedObservationEditStep(undefined);
    setIsCreatingObservation(false);
    setCreateObservationCallback(undefined);
    setCapturedImage(undefined);
    setCapturedImageUrl(undefined);
    setCapturedDistance(undefined);
  };

  const handleCloseSectionModal = () => {
    setSelectedSectionUid(undefined);
    setSelectedSectionEditStep(undefined);
    setIsCreatingSection(false);
    setCreateSectionCallback(undefined);
    setCapturedImage(undefined);
    setCapturedImageUrl(undefined);
    setCapturedDistance(undefined);
  };

  const handleCloseMeasurementModal = () => {
    setSelectedMeasurementUid(undefined);
    setSelectedMeasurementEditStep(undefined);
    setIsCreatingMeasurement(false);
    setCreateMeasurementCallback(undefined);
  };

  const handleCloseDrawingModal = () => {
    setSelectedDrawingUid(undefined);
    setSelectedDrawingEditStep(undefined);
    setIsCreatingDrawing(false);
    setCreateDrawingCallback(undefined);
  };

  const handleCloseSectionAdditionalModal = () => {
    setSelectedSectionAdditionalUid(undefined);
    setSelectedSectionAdditionalEditStep(undefined);
  };

  const handleCloseInspectionModal = () => {
    setSelectedInspectionUid(undefined);
    setSelectedInspectionSectionUid(undefined);
    setSelectedInspectionEditStep(undefined);
    setIsCreatingInspection(false);
    setCreateInspectionCallback(undefined);
  };

  const handleCloseInspectionAdditionalModal = () => {
    setSelectedInspectionAdditionalUid(undefined);
    setSelectedInspectionAdditionalEditStep(undefined);
  };

  const createNewNode = (onCreate?: (uid: string) => void) => {
    setCreateNodeCallback(() => onCreate);
    setIsCreatingNode(true);
  };

  const createNewObservation = (
    inspectionUid: string,
    onCreate?: (uid: string) => void
  ) => {
    setIsCreatingObservation(true);
    setSelectedObservationInspectionUid(inspectionUid);
    setCreateObservationCallback(() => onCreate);
  };

  const createNewObservationFromVideo = (
    inspectionUid: string,
    image: File,
    timeStamp: number
  ) => {
    setIsCreatingObservation(true);
    setCapturedImage(image);
    setSelectedObservationInspectionUid(inspectionUid);
    setTimestamp(timeStamp);
  };

  const createNewObservationFromAI = (
    inspectionUid: string,
    imageUrl: string,
    timeStamp: number,
    distance: number,
    onCreate?: (uid: string) => void
  ) => {
    setIsCreatingObservation(true);
    setCapturedImageUrl(imageUrl);
    setSelectedObservationInspectionUid(inspectionUid);
    setTimestamp(timeStamp);
    setCapturedDistance(distance);
    setCreateObservationCallback(() => onCreate);
  };

  const createNewSection = (onCreate?: (uid: string) => void) => {
    setIsCreatingSection(true);
    setCreateSectionCallback(() => onCreate);
  };

  const createNewMeasurement = (onCreate?: (uid: string) => void) => {
    setIsCreatingMeasurement(true);
    setCreateMeasurementCallback(() => onCreate);
  };

  const createNewDrawing = (onCreate?: (uid: string) => void) => {
    setIsCreatingDrawing(true);
    setCreateDrawingCallback(() => onCreate);
  };

  const createNewInspection = (
    sectionUid: string,
    onCreate?: (uid: string) => void
  ) => {
    setIsCreatingInspection(true);
    setSelectedInspectionSectionUid(sectionUid);
    setCreateInspectionCallback(() => onCreate);
  };

  const editNode = (uid: string | null, step?: string) => {
    if (uid) setSelectedNodeUid(uid);
    if (step) setSelectedNodeEditStep(step);
  };

  const editNodeAdditional = (uid: string | null, step?: string) => {
    if (uid) setSelectedNodeAdditionalUid(uid);
    if (step) setSelectedNodeEditStep(step);
  };

  const editObservation = (uid: string | null, step?: string) => {
    if (uid) setSelectedObservationUid(uid);
    if (step) setSelectedObservationEditStep(step);
  };

  const editMeasurement = (uid: string | null, step?: string) => {
    if (uid) setSelectedMeasurementUid(uid);
    if (step) setSelectedMeasurementEditStep(step);
  };

  const editDrawing = (uid: string | null, step?: string) => {
    if (uid) setSelectedDrawingUid(uid);
    if (step) setSelectedDrawingEditStep(step);
  };

  const editSection = (uid: string | null, step?: string, image?: File) => {
    if (uid) setSelectedSectionUid(uid);
    if (step) setSelectedSectionEditStep(step);
    if (image) setCapturedImage(image);
  };

  const editSectionAdditional = (uid: string, step?: string) => {
    setSelectedSectionAdditionalUid(uid);
    if (step) setSelectedSectionAdditionalEditStep(step);
  };

  const editInspectionAdditional = (uid: string, step?: string) => {
    setSelectedInspectionAdditionalUid(uid);
    if (step) setSelectedInspectionAdditionalEditStep(step);
  };

  const editInspection = (uid: string | null, step?: string) => {
    if (uid) setSelectedInspectionUid(uid);
    if (step) setSelectedInspectionEditStep(step);
  };

  const removeNode = (uid: string) => {
    reportEditor.removeNode(uid);
  };

  const removeObservation = (uid: string) => {
    reportEditor.removeObservation(uid);
  };

  const removeSection = (uid: string) => reportEditor.removeSection(uid);

  const removeInspection = (uid: string) => reportEditor.removeInspection(uid);

  const removeMeasurement = (uid: string) =>
    reportEditor.removeMeasurement(uid);

  const removeDrawing = (uid: string) => reportEditor.removeDrawing(uid);

  const value: Context = {
    createNewNode,
    createNewObservation,
    createNewObservationFromVideo,
    createNewObservationFromAI,
    createNewSection,
    createNewMeasurement,
    createNewDrawing,
    createNewInspection: createNewInspection,
    editNode,
    editNodeAdditional,
    editObservation,
    editMeasurement,
    editSection,
    editSectionAdditional,
    editInspection,
    editDrawing,
    editInspectionAdditional: editInspectionAdditional,
    reportEditor,
    removeNode,
    removeObservation,
    removeSection,
    removeInspection,
    removeMeasurement,
    removeDrawing,
  };

  return (
    <ReportEditorContext.Provider value={value}>
      {children}

      <NodeModal
        initialActiveStep={selectedNodeEditStep}
        nodeUid={selectedNodeUid}
        onClose={handleCloseNodeModal}
        onCreate={createNodeCallback}
        visible={!!selectedNodeUid || isCreatingNode}
      />
      <NodeAdditionalInformationModal
        initialActiveStep={selectedNodeEditStep}
        nodeUid={selectedNodeAdditionalUid}
        onClose={handleCloseNodeAdditionalInformationModal}
        onCreate={createNodeCallback}
        visible={!!selectedNodeAdditionalUid}
      />

      {(selectedObservationUid || selectedObservationInspectionUid) && (
        <ObservationModal
          initialActiveStepId={selectedObservationEditStep}
          observationUid={selectedObservationUid}
          onClose={handleCloseObservationModal}
          onCreate={createObservationCallback}
          inspectionUid={selectedObservationInspectionUid}
          visible={!!selectedObservationUid || isCreatingObservation}
          image={capturedImage}
          imageUrl={capturedImageUrl}
          distance={capturedDistance}
          timeStamp={timeStamp}
        />
      )}

      <SectionModal
        initialActiveStepId={selectedSectionEditStep}
        onClose={handleCloseSectionModal}
        onCreate={createSectionCallback}
        sectionUid={selectedSectionUid}
        image={capturedImage}
        visible={!!selectedSectionUid || isCreatingSection}
      />

      <MeasurementModal
        initialActiveStepId={selectedMeasurementEditStep}
        onClose={handleCloseMeasurementModal}
        onCreate={createMeasurementCallback}
        measurementUid={selectedMeasurementUid}
        visible={!!selectedMeasurementUid || isCreatingMeasurement}
      />

      <DrawingModal
        initialActiveStepId={selectedDrawingEditStep}
        onClose={handleCloseDrawingModal}
        onCreate={createDrawingCallback}
        drawingUid={selectedDrawingUid}
        visible={!!selectedDrawingUid || isCreatingDrawing}
      />

      {selectedSectionAdditionalUid && (
        <SectionAdditionalModal
          initialActiveStepId={selectedSectionAdditionalEditStep}
          onClose={handleCloseSectionAdditionalModal}
          sectionUid={selectedSectionAdditionalUid}
          visible={!!selectedSectionAdditionalUid}
        />
      )}

      {(selectedInspectionUid || selectedInspectionSectionUid) && (
        <InspectionModal
          initialActiveStepId={selectedInspectionEditStep}
          onClose={handleCloseInspectionModal}
          onCreate={createInspectionCallback}
          sectionUid={selectedInspectionSectionUid}
          inspectionUid={selectedInspectionUid}
          visible={!!selectedInspectionUid || isCreatingInspection}
        />
      )}

      {selectedInspectionAdditionalUid && (
        <InspectionAdditionalModal
          initialActiveStepId={selectedInspectionAdditionalEditStep}
          onClose={handleCloseInspectionAdditionalModal}
          inspectionId={selectedInspectionAdditionalUid}
          visible={!!selectedInspectionAdditionalUid}
        />
      )}
    </ReportEditorContext.Provider>
  );
};

export default ReportEditorContextProvider;
