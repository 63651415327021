export enum AreaUnit {
  MM_SQUARE = 'mm2',
  M_SQUARE = 'm2',
  INCH_SQUARE = 'in2',
  FEET_SQUARE = 'ft2',
}

export enum DistanceUnit {
  MM = 'mm',
  M = 'm',
  INCHES = 'in',
  FEET = 'ft',
}

export enum UnitSystem {
  METRIC = 'metric',
  IMPERIAL = 'imperial',
}

export type Distance = [number, DistanceUnit];
export type Area = [number, AreaUnit];
