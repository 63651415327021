import { Project, Booking, Inspection, Section, SectionFlowCode, isCircular } from "@drainify/types";
import { ReportEditor, getObservationOptions, isOptionCode, getProjectLocation } from "@drainify/utils";
import { Duration } from "luxon";

export const convertToXML = (reportEditor: ReportEditor, project: Project, job: Booking) => {
  const surveys = reportEditor.getJobInspections(job.uid)!;
  return formatXml(
    '<?xml-stylesheet type="text/xsl" href="http://www.wrcplc.co.uk/srm/schemas/mscc4_cctv.xsl"?>' +
    '<SurveyGroup xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xsi:schemaLocation="http://www.wrcplc.co.uk/srm/mscc http://www.wrcplc.co.uk/srm/schemas/mscc5_cctv.xsd" xmlns="http://www.wrcplc.co.uk/srm/mscc">' +
    surveys
      .flatMap((survey) => {
        const section = reportEditor.getSectionByUid(survey.sectionUid)!;
        let continuousCounter = 0;
        const continousMap: Map<string, string> = new Map()
        for(const o of reportEditor.getInspectionObservations(survey.uid)) {
          if(o.observationContinuousState === 'start') {
            continousMap.set(o.uid, 'S' + continuousCounter)
            continousMap.set(o.linkedObservationUid!, 'F' + continuousCounter)
            continuousCounter++;
          }
        }

        return (
          '<Survey>' +
          surveyHeaderInformation(survey, reportEditor, section, project, job) +
          (reportEditor.getInspectionObservations(survey.uid).length === 0
            ? '<Observations/>'
            : '<Observations>' +
              reportEditor
                .getInspectionObservations(survey.uid)
                .flatMap((observation) => {
                  const options = getObservationOptions(observation?.code);
                  return (
                    '<Observation>' +
                    (observation.timeStamp
                      ? `<VideoRef>${Duration.fromMillis(
                          observation.timeStamp * 1000
                        ).toFormat('hh:mm:ss')}</VideoRef>`
                      : '') +
                    (observation.imageUrl
                      ? `<PhotographRefs><PhotographRef>${observation.inspectionUid}\\${observation.uid}.jpg</PhotographRef></PhotographRefs>`
                      : '') +
                    (observation.remarks
                      ? `<Remarks>${observation.remarks}</Remarks>`
                      : '') +

                    (continousMap.has(observation.uid) ? `<ContinuousDefect>${continousMap.get(observation.uid)}</ContinuousDefect>` : '') +
                    (observation.inspectionMarker
                      ? `<DrainifyInspectionMarker>${observation.inspectionMarker}</DrainifyInspectionMarker>`
                      : '') +

                    (observation.distance
                      ? `<Distance>${observation.distance}</Distance>`
                      : '') +
                    (observation.code
                      ? `<Code>${observation.code[
                          observation.code.length - 1
                        ]?.replace(/_.*/, '')}</Code>`
                      : '') +
                    options
                      .filter(
                        (option) =>
                          !isOptionCode(option) &&
                          observation.attributes[option.attribute]
                      )
                      .map((option) =>
                        'attribute' in option
                          ?  generateAttributeTag(option.type, observation?.attributes?.[option.attribute])
                          : ''
                      )
                      .join('') +
                    '</Observation>'
                  );
                })
                .join('') +
              '</Observations>') +
          '</Survey>'
        );
      })
      .join('') +
    '</SurveyGroup>'
  );
};

const surveyHeaderInformation = (
  survey: Inspection,
  reportEditor: ReportEditor,
  section: Section,
  project: Project,
  job: Booking
): string => {
  const startNode = reportEditor.getNodeByUid(section?.nodeStartUid);
  const endNode = reportEditor.getNodeByUid(section?.nodeEndUid);
  return (
    '<Header>' +
    (project?.customer ? `<Client>${project.customer.name}</Client>` : '') +
    (job.assignedTo && job.assignedTo.length > 0
      ? `<NameOfSurveyor>${job.assignedTo[0].fullName}</NameOfSurveyor>`
      : '') +
    (job.clientJobRef
      ? `<ClientsJobRef>${job.clientJobRef}</ClientsJobRef>`
      : '') +
    (job.jobId ? `<ContractorsJobRef>${job.jobId}</ContractorsJobRef>` : '') +
    (section.attributes.drainageArea
      ? `<DrainageArea>${section.attributes.drainageArea}</DrainageArea>`
      : '') +
    (section.attributes.divisionOrDistrict
      ? `<DivisionDistrict>${section.attributes.divisionOrDistrict}</DivisionDistrict>`
      : '') +
    (section?.attributes.pipeLengthRef
      ? `<PipelineLengthRef>${section?.attributes.pipeLengthRef}</PipelineLengthRef>`
      : '') +
    (survey.attributes.date ? `<Date>${survey.attributes.date}</Date>` : '') +
    (survey.attributes.time ? `<Time>${survey.attributes.time}</Time>` : '') +
    (getProjectLocation(project)?.address.town
      ? `<LocationTown>${
          getProjectLocation(project)?.address.town
        }</LocationTown>`
      : '') +
    (section.attributes.locationTypeCode
      ? `<LocationTypeCode>${section.attributes.locationTypeCode}</LocationTypeCode>`
      : '') +
    (section.attributes.landOwnership
      ? `<LandOwner>${section.attributes.landOwnership}</LandOwner>`
      : '') +
    (startNode
      ? `<StartNodeRef>${reportEditor.getNodeName(startNode)}</StartNodeRef>`
      : '') +
    (startNode && startNode.point && startNode.point.coordinates
      ? `<StartNodeGridRef><StartNodeGridRefX>${startNode.point.coordinates[0]}</StartNodeGridRefX><StartNodeGridRefY>${startNode.point.coordinates[1]}</StartNodeGridRefY></StartNodeGridRef>`
      : '') +
    (section.attributes.nodeOneRef
      ? `<Node1Ref>${section.attributes.nodeOneRef}</Node1Ref>`
      : '') +
    (section.attributes.nodeOneCoordinate
      ? `<Node1GridRef><Node1GridRefX>${section.attributes.nodeOneCoordinate}</Node1GridRefX><Node1GridRefY>${section.attributes.nodeOneCoordinate}</Node1GridRefY></Node1GridRef></Node1Ref>`
      : '') +
    (startNode?.depth
      ? `<DepthAtStartNode>${startNode.depth}</DepthAtStartNode>`
      : '') +
    (endNode
      ? `<FinishNodeRef>${reportEditor.getNodeName(endNode)}</FinishNodeRef>`
      : '') +
    (endNode?.depth
      ? `<DepthAtFinishNode>${endNode.depth}</DepthAtFinishNode>`
      : '') +
    (section.attributes.nodeTwoCoordinate
      ? `<Node2GridRef><Node2GridRefX>${section.attributes.nodeTwoCoordinate}</Node2GridRefX><Node2GridRefY>${section.attributes.nodeTwoCoordinate}</Node2GridRefY></Node2GridRef>`
      : '') +
    (section?.attributes.use
      ? `<UseOfDrainSewer>${section.attributes.use}</UseOfDrainSewer>`
      : '') +
    (section?.attributes.drainSewerType
      ? `<TypeOfDrainSewer>${section.attributes.drainSewerType}</TypeOfDrainSewer>`
      : '') +
    (section?.attributes.flow
      ? `<Direction>${
          section.attributes.flow === SectionFlowCode.ETS ? 'U' : 'D'
        }</Direction>`
      : '<Direction/>') +
    (isCircular(section.attributes.shape)
      ? section?.attributes.diameter
        ? `<HeightDiameter>${section.attributes.diameter}</HeightDiameter>`
        : ''
      : section?.attributes.height
      ? `<HeightDiameter>${section.attributes.height}</HeightDiameter>`
      : '') +
    (section?.attributes.width
      ? `<Width>${section.attributes.width}</Width>`
      : '') +
    (section?.attributes.shape
      ? `<Shape>${section.attributes.shape}</Shape>`
      : '') +
    (section?.attributes.material
      ? `<Material>${section.attributes.material}</Material>`
      : '') +
    (section?.attributes.liningType
      ? `<LiningType>${section.attributes.liningType}</LiningType>`
      : '') +
    (survey.attributes.preCleaned
      ? `<Precleaned>${survey.attributes.preCleaned}</Precleaned>`
      : '') +
    (survey.attributes.critcatalityGrade
      ? `<CriticalDrainSewer>${survey.attributes.critcatalityGrade}</CriticalDrainSewer>`
      : '') +
    (survey.attributes.purposeOfInspection
      ? `<PurposeOfInspection>${survey.attributes.purposeOfInspection}</PurposeOfInspection>`
      : '') +
    (job.attributes.inspectionStage
      ? `<InspectionStage>${job.attributes.inspectionStage}</InspectionStage>`
      : '') +
    (survey.attributes.flowControlMeasures
      ? `<FlowControlMeasures>${survey.attributes.flowControlMeasures}</FlowControlMeasures>`
      : '') +
    (survey.attributes.weather
      ? `<Weather>${survey.attributes.weather}</Weather>`
      : '') +
    (survey.attributes.temperature
      ? `<Temprature>${survey.attributes.temperature}</Temprature>`
      : '') +
    (section?.attributes.pipeUnitLength
      ? `<PipeUnitLength>${section.attributes.pipeUnitLength}</PipeUnitLength>`
      : '') +
    (section.attributes.length
      ? `<ExpectedLength>${section?.attributes.length}</ExpectedLength>`
      : '') +
    (section?.attributes.yearConstructed
      ? `<YearConstructed>${section?.attributes.yearConstructed}</YearConstructed>`
      : '') +
    (survey?.attributes.methodOfInspection
      ? `<MethodOfInspection>${survey.attributes.methodOfInspection}</MethodOfInspection>`
      : '') +
    '<Standard>BS EN 13508-2:2003</Standard>' +
    (survey?.attributes.videoImageStorageMedia
      ? `<VideoImageStorage>${survey.attributes.videoImageStorageMedia}</VideoImageStorage>`
      : '') +
    (survey?.attributes.videoImageLocationSystem
      ? `<VideoImageLocationSystem>${survey.attributes.videoImageLocationSystem}</VideoImageLocationSystem>`
      : '') +
    (survey?.attributes.videoImageFormat
      ? `<VideoImageFormat>${survey.attributes.videoImageFormat}</VideoImageFormat>`
      : '') +
    (survey?.video 
      ? `<VideoImageFileName>${survey.uid}\\video.mp4</VideoImageFileName>`
      : '') +
    (survey?.attributes.videoVolumeRef
      ? `<VideoVolumeRef>${survey.attributes.videoVolumeRef}</VideoVolumeRef>`
      : '') +
    (survey?.attributes.photographImageStorageFormat
      ? `<PhotographicStorageFormat>${survey.attributes.photographImageStorageFormat}</PhotographicStorageFormat>`
      : '') +
    (job?.attributes.clientDefinedOne
      ? `<ClientDefined1>${job?.attributes.clientDefinedOne}</ClientDefined1>`
      : '') +
    (job?.attributes.clientDefinedTwo
      ? `<ClientDefined2>${job?.attributes.clientDefinedTwo}</ClientDefined2>`
      : '') +
    (job?.attributes.clientDefinedThree
      ? `<ClientDefined3>${job?.attributes.clientDefinedThree}</ClientDefined3>`
      : '') +
    (job?.attributes.clientDefinedFour
      ? `<ClientDefined4>${job?.attributes.clientDefinedFour}</ClientDefined4>`
      : '') +
    (job?.attributes.clientDefinedFive
      ? `<ClientDefined5>${job?.attributes.clientDefinedFive}</ClientDefined4>`
      : '') +
    '</Header>'
  );
};
const formatXml = (xml: string) => {
  // tab = optional indent value, default is tab (\t)
  let formatted = '';
  let indent = '';
  const tab = '\t';
  xml.split(/>\s*</).forEach(function (node) {
    if (node.match(/^\/\w/)) indent = indent.substring(tab.length); // decrease indent by one 'tab'
    formatted += indent + '<' + node + '>\r\n';
    if (node.match(/^<?\w[^>]*[^\/]$/)) indent += tab; // increase indent
  });
  return formatted.substring(1, formatted.length - 3);
};

function generateAttributeTag(type: string, arg1: string | number | boolean | [number, number] | { set: boolean; value?: number | undefined; }): any {
  if(type === 'angleRange') {
    return `<ClockRefAtFrom>${(arg1 as [number, number])[0]}</ClockRefAtFrom><ClockRefTo>${(arg1 as [number, number])[1]}</ClockRefTo>`
  }
  if(type === 'angle') {
    return `<ClockRefAtFrom>${arg1}</ClockRefAtFrom>`
  }
  if(type === 'percentage') {
    return `<Percentage>${arg1}</Percentage>`
  }
  if(type === 'distanceShort') {
    return `<Dimension1>${(arg1 as number) * 1000}</Dimension1>`
  }
  return ''
}

