import { Text, TextProps } from 'preshape';
import React, { PropsWithChildren } from 'react';
import './DataTable.css';

export type DataTableProps = TextProps & {};

const DataTable = ({
  children,
  ...rest
}: PropsWithChildren<DataTableProps>) => {
  return (
    <Text {...rest} className="DataTable" tag="ul">
      {children}
    </Text>
  );
};

export default DataTable;
