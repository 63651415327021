import { BookingPostBody } from '@drainify/types';
import { Icons, Label, LabelProps } from 'preshape';
import React from 'react';
import PlaceholderBlock from '../Placeholders/PlaceholderBlock';

type ProjectRefProps = LabelProps & {
  project?: BookingPostBody;
};

const ProjectRef = ({ project, ...rest }: ProjectRefProps) => {
  return (
    <Label
      {...rest}
      alignChildrenVertical="middle"
      borderSize="x1"
      flex="horizontal"
      gap="x1"
      width="125px"
    >
      <Icons.Trello size=".75rem" />
      {project?.jobId}
      {!project && (
        <PlaceholderBlock height=".75rem" margin="x0" width="5rem" />
      )}
    </Label>
  );
};

export default ProjectRef;
