import { Box } from 'preshape';
import React from 'react';
import ReactPlayer from 'react-player';
import { v4 } from 'uuid';
import { useOrgContext } from '../Org/OrgProvider';
import { useProjectContext } from '../Project/ProjectProvider';
import VideoPlayerProgressbarRunner from './Progressbar/VideoPlayerProgressbarRunner';
import { useVideoContext } from './VideoProvider';

type Props = {
  projectId: string;
  inspectionId: string;
};

const VideoPlayer = ({ projectId, inspectionId }: Props) => {

  const {
    playbackRate,
    player,
    playing,
    handleDuration,
    handleEnded,
    handleOnPlaybackRateChange,
    handlePause,
    handlePlay,
    handlePlayPause,
    handleProgress,
  } = useVideoContext();

  const { isCustomerView, customerReportView } = useProjectContext()

  const cacheBust  = React.useState(v4())[0];


  const { org } = useOrgContext()
  const url = isCustomerView ? 
  `${`https://storage.googleapis.com/project-d-328516-surveys/${customerReportView?.project.org.uid}/${projectId}/${inspectionId}/transcoded.mp4`}`: 
  `${`https://storage.googleapis.com/project-d-328516-surveys/${org?.uid}/${projectId}/${inspectionId}/transcoded.mp4?cacheBust=${cacheBust}`}` ;

  return (
    <Box onClick={handlePlayPause} backgroundColor="black">
      <ReactPlayer
        ref={player}
        className="react-player"
        url={url}
        playing={playing}
        controls={false}
        config={{
          file: {
            attributes: {
              crossOrigin: 'anonymous',
            },
          },
        }}
        loop={false}
        playbackRate={playbackRate}
        progressInterval={200}
        volume={0}
        muted={true}
        onReady={() => {}}
        onStart={() => {}}
        onPlay={handlePlay}
        onPause={handlePause}
        onBuffer={() => {}}
        onPlaybackRateChange={handleOnPlaybackRateChange}
        onProgress={handleProgress}
        onSeek={() => {}}
        onEnded={handleEnded}
        onError={() => {}}
        onDuration={handleDuration}
        basis="0"
      />
      <VideoPlayerProgressbarRunner />
    </Box>
  );
};

export default VideoPlayer;
