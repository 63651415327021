import { Buttons, Text } from 'preshape';
import React from 'react';
import Explainer from '../../../components/Explainer/Explainer';
import MeasurementCreateButton from '../../../components/Measurement/MeasurementCreateButton';
import MeasurementsList from '../../../components/Measurement/MeasurementsList';
import { useReportEditorContext } from '../../../components/Report/ReportEditorProvider';
import Page from '../Layout/Page';
import PageBody from '../Layout/PageBody';
import PageHeader from '../Layout/PageHeader';
import PageMenu from '../Layout/PageMenu';

const SiteMeasurementsPage = () => {
  const { editMeasurement } = useReportEditorContext();
  return (
    <Page>
      <PageHeader
        title="Measurements"
        action={
          <Explainer title="Measurements">
            <Text>
              The measuring tool allows you to measure distances between two
              points on a map
            </Text>
            <Text>
              Particularly useful when conducting a build-over survey, such as
              measuring the distance from a building to a sewer
            </Text>
          </Explainer>
        }
      />
      <PageBody flex="vertical" gap="x3" overflow="auto" basis="0" grow>
        <PageMenu margin="x3">
          <MeasurementsList onItemClick={(uid) => editMeasurement(uid)} />
        </PageMenu>
      </PageBody>
      <Buttons padding="x6">
        <MeasurementCreateButton variant="primary" grow />
      </Buttons>
    </Page>
  );
};

export default SiteMeasurementsPage;
