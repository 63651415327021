import { WithTracking } from './Api';
import { Drawing } from './Drawing';
import { Inspection } from './Inspection';
import { Measurement } from './Measurement';
import { Node } from './Node';
import { Observation } from './Observation';
import { Org } from './Org';
import { Plan } from './Plan';
import { Project } from './Project';
import { Scale } from './Scale';
import { Section } from './Section';
import { SitePhoto } from './SitePhoto';

export type ReportPostBody = {
  bounds?: GeoJSON.Polygon;
  nodes: Node[];
  observations: Observation[];
  sections: Section[];
  measurements: Measurement[];
  drawings: Drawing[];
  scale?: Scale | null;
  inspections: Inspection[];
  plan?: Plan | null;
  sitePhotos: SitePhoto[];
  surveyType?: SurveyType;
  planType?: PlanType;
  version: number;
};

export type Report = WithTracking &
  ReportPostBody & {
    uid: string;
    org: Org;
    project: Project;
  };

export enum SurveyType {
  RAPID_REPORT = 'RAPID_REPORT',
  VIDEO = 'VIDEO',
}

export enum PlanType {
  GOOGLE_MAPS = 'GOOGLE_MAPS',
  UPLOADED_PLAN = 'UPLOADED_PLAN',
  NO_PLAN = 'NO_PLAN',
}
