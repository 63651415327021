import { formatFileSize } from '@drainify/utils';
import { Alert, Button, ButtonProps, Icons, Text } from 'preshape';
import React, { ChangeEvent, PropsWithChildren } from 'react';
import './FileUpload.css';

type Props = ButtonProps & {
  accept?: string;
  onChange: (file: File) => void;
  value?: File;
};
const MAX_FILE_SIZE = 1000000000;

const FileUpload = ({
  accept,
  children,
  onChange,
  value,
  ...rest
}: PropsWithChildren<Props>) => {
  const [error, setError] = React.useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { files },
    } = event;

    // here you do the size check
    if (files?.[0]) {
      if (files[0].size < MAX_FILE_SIZE) {
        setError(false);
        onChange(files[0]);
      } else {
        setError(true);
      }
    }
  };

  return (
    <>
      <Button
        {...rest}
        className="FileUpload"
        gap="x1"
        flex="horizontal"
        size="x3"
        tag="label"
        type="button"
      >
        <input
          accept={accept}
          className="FileUpload__input"
          onChange={handleChange}
          type="file"
        />

        {value ? (
          <>
            <Icons.File />
            <Text basis="0" grow ellipsis>
              {value.name}
            </Text>
            <Text strong>({formatFileSize(value.size)})</Text>
          </>
        ) : (
          children
        )}
      </Button>
      {error && (
        <Alert
          color="negative"
          backgroundColor="negative-shade-1"
          borderSize="x2"
        >
          <Text strong textColor="negative-shade-5" align="middle">
            Max file size 2GB
          </Text>
        </Alert>
      )}
    </>
  );
};

export default FileUpload;
