import { Project, ProjectPostBody, ResponseError } from '@drainify/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useFetch, { hasAllParams } from '../components/Api/useFetch';
import { useOrgContext } from '../components/Org/OrgProvider';
import { DataHookSingleEntity } from '.';

const useProject: DataHookSingleEntity<'projectId', Project, ProjectPostBody> =
  ({ projectId }, { noQuery } = {}) => {
    const queryClient = useQueryClient();
    const fetch = useFetch();
    const org = useOrgContext();

    const key = [`/projects/${projectId}`, fetch];
    const params = { projectId: projectId as string };

    const query = useQuery<Project, ResponseError>(
      key,
      () => fetch('/projects/:projectId', { method: 'GET', params }),
      { enabled: hasAllParams(params) && !noQuery }
    );

    const update = useMutation<Project, ResponseError, ProjectPostBody>(
      (body: ProjectPostBody) =>
        fetch('/projects/:projectId', { method: 'PUT', body, params }),
      {
        onSuccess: (data) => {
          queryClient.setQueryData(key, data);
          queryClient.refetchQueries(['projects']);
        },
      }
    );

    const remove = useMutation<void, ResponseError>(
      () => fetch('/projects/:projectId', { method: 'DELETE', params }),
      {
        onSuccess: () => {
          queryClient.setQueryData(key, null);
          queryClient.refetchQueries(['projects']);
          queryClient.removeQueries([
            'bookings',
            `/projects/${projectId}/bookings`,
          ]);
          queryClient.refetchQueries([`/orgs/${org.org?.uid}/usage`]);
        },
      }
    );

    return { query, update, remove };
  };

export default useProject;
