import { ResponseError, ResponseMultipleEntities } from '@drainify/types';
import { UseQueryResult, UseInfiniteQueryResult } from 'react-query';

export type Query =
  | UseQueryResult<object, ResponseError>
  | UseQueryResult<ResponseMultipleEntities<object>, ResponseError>
  | UseInfiniteQueryResult<ResponseMultipleEntities<object>, ResponseError>;

export type QueryState = {
  hasData: boolean;
  isLoading: boolean;
  isLoadingError: boolean;
  isRefetching: boolean;
};

const hasQueryData = (query: Query) => {
  if (!query.data) return false;
  if ('pages' in query.data) return query.data.pages.length > 0;
  if ('data' in query.data) return query.data.data.length > 0;
  return true;
};

const getAggregatedQueryState = (
  ...queries: (Query | Query[] | undefined)[]
): QueryState => {
  let hasData = true;
  let isLoading = false;
  let isLoadingError = false;
  let isRefetching = false;

  queries.forEach((queries) => {
    if (Array.isArray(queries)) {
      queries.forEach((query) => {
        isLoading = isLoading || query.isLoading;
        isLoadingError = isLoadingError || query.isLoadingError;
        isRefetching = isRefetching || query.isRefetching;
        hasData = hasQueryData(query);
      });
    } else if (queries) {
      isLoading = queries.isLoading;
      isLoadingError = queries.isLoadingError;
      isRefetching = queries.isRefetching;
      hasData = hasQueryData(queries);
    }
  });

  return {
    isLoading,
    isLoadingError,
    isRefetching,
    hasData,
  };
};

export default getAggregatedQueryState;
