import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import {
    Box,
    Button,
    Buttons,
    Icons,
    Modal,
    ModalBody,
    ModalFooter,
    Text,
    useLocalStorage,
} from 'preshape';
import React, {
    createContext,
    PropsWithChildren,
    useContext,
} from 'react';
import { FULL_SCREEN_MODAL_WIDTH } from '../App/App';
import AsyncButton from '../AsyncButton/AsyncButton';
import { AuthenticationContext } from '../Authentication/Authenticate';

const NotificationPermissionContext = createContext<{
  hasPermission?: boolean;
  togglePermissions?: (p: boolean) => void;
}>({});

export const useNotificationContext = () => useContext(NotificationPermissionContext);

const PWANotifications = ({ children }: PropsWithChildren<{}>) => {
    const [hasPermission, setHasPermission] = useLocalStorage<
        boolean | undefined
    >('com.drainify.notifications', undefined);
    const [isRequesting, setIsRequesting] = React.useState(false)

    const app = initializeApp({
        apiKey: 'AIzaSyAYxGEnIWyv9In-xFLpr2yzdtb6jY5tkcc',
        authDomain: 'project-d-328516.firebaseapp.com',
        projectId: 'project-d-328516',
        storageBucket: 'project-d-328516.appspot.com',
        messagingSenderId: '362307105035',
        appId: '1:362307105035:web:3d6aa281bef730571d320e',
    });

    const messaging = getMessaging(app);
    const { user } = useContext(AuthenticationContext);

      

    const requestPermission = async () => navigator.serviceWorker.getRegistration().then(async reg => {
        const token = await user?.getIdToken();
        setIsRequesting(true);
        Notification.requestPermission().then(permission => {
            if (permission === 'granted') {
                getToken(messaging, {
                    vapidKey: 'BP56BCyxqwDgmIVp4K6XEL4FCIGgv_AVnGHVG1e9_b3u0j393rbkbryIPj6_bWuKwI8yE_3pujW0OwBNcBW2fQ0',
                    serviceWorkerRegistration: reg,
                }).then((currentToken) => {
                    if (currentToken) {
                        fetch(process.env.DRAINIFY_API_URL + '/notification/registrations', {
                            method: 'POST',
                            body: JSON.stringify({
                                token: currentToken
                            }),
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            }
                        })
                        setHasPermission(true)
                    }
                })
            } else if(permission === 'denied'){
                setHasPermission(false)

            }
        })
    })

    React.useEffect(() => {
        if ('permissions' in navigator && hasPermission !== undefined) {
            navigator.permissions.query({ name: 'notifications' }).then(({ state }) => {
                if (state === 'granted') {
                    setHasPermission(true)
                } else {
                    setHasPermission(false)
                }
            });
        } else {
            setHasPermission(false)
        }
    }, [])

  const context = {
    hasPermission,
    togglePermissions: (p: boolean) => {
        setHasPermission(p)
        if ('permissions' in navigator && p) {
            navigator.permissions.query({ name: 'notifications' }).then(({ state }) => {
                if (state !== 'granted') {
                    requestPermission()
                }
            });
        }
    }
  };

    return (
        <NotificationPermissionContext.Provider value={context}>
            {children}
            <Modal
                animation="FadeSlideUp"
                margin="x4"
                maxWidth={FULL_SCREEN_MODAL_WIDTH}
                visible={hasPermission === undefined}
            >
                <ModalBody flex="vertical">
                    <Box alignChildren="middle" gap="x16" grow flex="vertical">
                        <Box>
                            <Icons.Bell size='8rem' />
                        </Box>

                        <Box>
                            <Text align="middle" margin="x2" strong>
                                We have so many useful notifications such as: Job dispatch and ready for QA.
                            </Text>

                            <Text align="middle" margin="x2" strong>
                                To get the full experience, please allow notifications when
                                prompted.
                            </Text>

                        </Box>
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <Buttons flex="vertical" gap="x2">
                        <AsyncButton
                            color="accent"
                            gap="x2"
                            isLoading={isRequesting}
                            onClick={requestPermission}
                            size="x3"
                            variant="primary"
                        >
                            <Icons.Bell />
                            <Text>Allow notifications</Text>
                        </AsyncButton>
                        <Button onClick={() => setHasPermission(false)} variant="tertiary">
                            I'll think about it later
                        </Button>
                    </Buttons>
                </ModalFooter>
            </Modal>
        </NotificationPermissionContext.Provider>
    );
};

export default PWANotifications;
