import React from 'react';
import useDrawMeasurementMapGhost from '../../Report/ReportMap/ReportMapElements/useDrawMeasurementMapGhost';

export type Props = {
  points: GeoJSON.Point[];
};

const MapLineGhost = ({ points }: Props) => {
  useDrawMeasurementMapGhost({ points, visible: true });

  return <></>;
};

export default MapLineGhost;
