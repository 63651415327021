import { Report, ResponseError } from '@drainify/types';
import { useQuery } from 'react-query';
import useFetch, { hasAllParams } from '../components/Api/useFetch';

type Props = {
  projectId?: string;
};

const useProjectReportPolling = ({ projectId }: Props) => {
  const fetch = useFetch();
  const key = [`/projects/${projectId}/report`, fetch];
  const params = { projectId: projectId as string };

  const poll = useQuery<Report, ResponseError>(
    key,
    () => fetch('/projects/:projectId/report', { method: 'GET', params }),
    {
      enabled: hasAllParams(params),
      refetchInterval: () => 1000,
    }
  );

  return { poll };
};

export default useProjectReportPolling;
