import {
  Box,
  Button,
  Buttons,
  Icons,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from 'preshape';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import NodesIcon from '../../../components/IconsMisc/NodesIcon';
import { useProjectContext } from '../../../components/Project/ProjectProvider';
import { useReportEditorContext } from '../../../components/Report/ReportEditorProvider';
import SectionView from '../../../components/Section/SectionView';
import { isMobile } from '../../../utils/client';

type Props = {
  visible: boolean;
  onClose: () => void;
  onFinish: () => void;
  sectionUid: string;
};

const InspectionPostInspectionModal = ({
  visible,
  onClose,
  sectionUid
}: Props) => {
  const { reportEditor, editSection } = useReportEditorContext();
  const section = reportEditor.getSectionByUid(sectionUid)
  const [sectionErrors2, setSectionErrors2] = React.useState(reportEditor.fullValidateSection(section));
  const navigate = useNavigate()
  const { activeBooking, project } = useProjectContext()

  React.useEffect(() => {
    setSectionErrors2(reportEditor.fullValidateSection(section));
    if (!reportEditor.fullValidateSection(section)) {
      onClose()
    }
  }, [reportEditor])

  if (!section) {
    return null;
  }

  return (
    <Modal
      visible={visible}
      animation="FadeSlideUp"
      margin="x4"
      overlayBackgroundCloseOnClick={false}
      onClose={onClose}
    >
      <ModalHeader>Missing information</ModalHeader>

      <ModalBody>
        <SectionView section={section}/>
        <Box flex="vertical" gap="x2">
          <Box flex="horizontal" grow gap="x2" wrap>
            {sectionErrors2 && sectionErrors2['attributes.sectionOwnership'] && (
              <Button
                maxWidth="100px"
                grow
                elevate
                backgroundColor="negative-shade-2"
                borderColor="negative-shade-3"
                onClick={() => editSection(section.uid, 'attributes.sectionOwnership')}
                flex="vertical"
                textColor="negative-shade-4"
              >
                <Box flex="horizontal">
                  <Icons.Book size='2rem'/>
                </Box>
                <Text titlecase>Section ownership</Text>
              </Button>
            )}
            {sectionErrors2 && sectionErrors2['attributes.shape'] && (
              <Button
                maxWidth="100px"
                grow
                elevate
                backgroundColor="negative-shade-2"
                borderColor="negative-shade-3"
                onClick={() => editSection(section.uid, 'attributes.shape')}
                flex="vertical"
                textColor="negative-shade-4"
              >
                <Box flex="horizontal">
                  <Icons.Square size='2rem'/>
                  <Icons.Circle size='2rem'/>
                </Box>
                <Text titlecase>Shape</Text>
              </Button>
            )}
            {sectionErrors2 && sectionErrors2['attributes.material'] && (
              <Button
                maxWidth="100px"
                grow
                elevate
                backgroundColor="negative-shade-2"
                borderColor="negative-shade-3"
                onClick={() => editSection(section.uid, 'attributes.material')}
                flex="vertical"
                textColor="negative-shade-4"
              >
                <Box flex="horizontal">
                  <Icons.Maximize size='2rem'/>
                </Box>
                <Text titlecase>Material</Text>
              </Button>
            )}

            {sectionErrors2 && sectionErrors2['attributes.length'] && (
              <Button
                maxWidth="100px"
                grow
                elevate
                backgroundColor="negative-shade-2"
                borderColor="negative-shade-3"
                onClick={() => editSection(section.uid, 'attributes.length')}
                flex="vertical"
                textColor="negative-shade-4"
              >
                <Box flex="horizontal">
                  <Icons.Maximize2 size="2rem" />
                </Box>
                <Text titlecase>Length</Text>
              </Button>
            )}

            {sectionErrors2 && sectionErrors2['attributes.diameter'] && (
              <Button
                maxWidth="100px"
                grow
                elevate
                backgroundColor="negative-shade-2"
                borderColor="negative-shade-3"
                onClick={() => editSection(section.uid, 'attributes.diameter')}
                flex="vertical"
                textColor="negative-shade-4"
              >
                <Icons.RotateCw size="2rem" />
                <Text titlecase>Diameter</Text>
              </Button>
            )}

            {sectionErrors2 && sectionErrors2['attributes.height'] && (
              <Button
                maxWidth="100px"
                grow
                elevate
                backgroundColor="negative-shade-2"
                borderColor="negative-shade-3"
                onClick={() => editSection(section.uid, 'attributes.height')}
                flex="vertical"
                textColor="negative-shade-4"
              >
                <Icons.ArrowUp size="2rem" />
                <Text titlecase>Height</Text>
              </Button>
            )}

            {sectionErrors2 && sectionErrors2['attributes.width'] && (
              <Button
                maxWidth="100px"
                grow
                backgroundColor="negative-shade-2"
                borderColor="negative-shade-3"
                onClick={() => editSection(section.uid, 'attributes.width')}
                flex="vertical"
                textColor="negative-shade-4"
              >
                <Icons.ArrowRight size="2rem" />
                <Text titlecase>Width</Text>
              </Button>
            )}
            {sectionErrors2 && sectionErrors2['attributes.flow'] && (
              <Button
                maxWidth="100px"
                grow
                backgroundColor="negative-shade-2"
                borderColor="negative-shade-3"
                onClick={() => editSection(section.uid, 'attributes.flow')}
                flex="vertical"
                textColor="negative-shade-4"
              >
                <Box flex='horizontal'>
                  <Icons.ArrowLeft size="2rem" />
                  <Icons.ArrowRight size="2rem" />
</Box>
                <Text titlecase>Flow</Text>
              </Button>
            )}
            {sectionErrors2 && sectionErrors2['attributes.use'] && (
              <Button
                maxWidth="100px"
                grow
                backgroundColor="negative-shade-2"
                borderColor="negative-shade-3"
                onClick={() => editSection(section.uid, 'attributes.use')}
                flex="vertical"
                textColor="negative-shade-4"
              >
                <Icons.Tool size="2rem" />
                <Text titlecase>Use</Text>
              </Button>
            )}

            {sectionErrors2 && sectionErrors2['nodeEndUid'] && (
              <Button
                maxWidth="100px"
                grow
                backgroundColor="negative-shade-2"
                borderColor="negative-shade-3"
                onClick={() => editSection(section.uid, 'nodeEndUid')}
                flex="vertical"
                textColor="negative-shade-4"
              >
                <NodesIcon size='2rem'/>
                <Text titlecase>End node</Text>
              </Button>
            )}

          </Box>
        </Box>
      </ModalBody>
      <ModalFooter>
        <Buttons flex='horizontal' alignChildrenHorizontal='middle'>
          <Button variant='primary' color='accent' grow onClick={() => navigate(
            
            
            isMobile() ? 
            `/projects/${project?.uid}/report/sections/${section.uid}?activeBookingId=${activeBooking?.uid}`: 
            `/projects/${project?.uid}/sections/${section.uid}?activeBookingId=${activeBooking?.uid}`
            )}>Go to section</Button>
        </Buttons>
      </ModalFooter>

    </Modal>
  );
};

export default InspectionPostInspectionModal;
