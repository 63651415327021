
import { OCRResultStatus } from "@drainify/types";
import { Button, Icons } from "preshape"
import React from "react"
import useAIOCRReportResults from "../../hooks/useAIOCRReportResults";
import AILogo from "../IconsMisc/AILogo";
import { useProjectContext } from "../Project/ProjectProvider";
import { useReportEditorContext } from "../Report/ReportEditorProvider";
import Spinner from "../Spinner/Spinner";

const BuddyJobManagerButton = () => {
  const { reportEditor } = useReportEditorContext();
  const { activeBooking, project } = useProjectContext()
  const { query, create } = useAIOCRReportResults(project?.uid, activeBooking?.uid)
  const [hasAll, setHasAll] = React.useState(false)
  const [running, setRunning] = React.useState(false)

  React.useEffect(() => {
    if (activeBooking) {
      setHasAll(reportEditor.getJobInspections(activeBooking?.uid).filter(e => e.video !== undefined).every(e => query.data?.map(e => e.inspectionId).includes(e.uid)))
      setRunning(query.data?.find(e => e.status === OCRResultStatus.PENDING) !== undefined)
    }
  }, [query, activeBooking])


  if (!query || query.isLoading || query.data === undefined) {
    return null;
  }

  if (!activeBooking) {
    return null;
  }

  return (
          <>
            {running &&
              <Button variant='primary' color='accent' disabled>
                <AILogo fill='white'/>
                <Spinner size='1rem' />
              </Button>

            }
            {!hasAll && !running &&
              <Button variant='primary' color='accent' onClick={() => create.mutate()}>
                <AILogo fill='white'/>
                <Icons.Play />
              </Button>
            }
          </>
  )

}

export default BuddyJobManagerButton;