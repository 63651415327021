import { OCRResultStatus, OrgRole, SurveyReview, SurveyReviewState } from "@drainify/types";
import { Button, Box, Modal, ModalHeader, ModalBody, Table, TableHeader, TableHeaderCell, TableBody, Text, Icons, Label, ModalFooter } from "preshape"
import React from "react"
import useSurveyReviewForm from "../../hooks/forms/useSurveyReviewForm";
import useAIOCRReportResults from "../../hooks/useAIOCRReportResults";
import useMeOrgMember from "../../hooks/useMeOrgMember";
import FeatureFlag from "../FeatureFlag/FeatureFlag";
import AILogo from "../IconsMisc/AILogo"
import PlaceholderGradientAnimation from "../Placeholders/PlaceholderGradientAnimation";
import PlaceholderText from "../Placeholders/PlaceholderText";
import { useProjectContext } from "../Project/ProjectProvider";
import { useReportEditorContext } from "../Report/ReportEditorProvider";
import BuddyReviewToolInspectionSummary from "./BuddyReviewToolInspectionSummary";
import BuddyTableHeader from "./BuddyTableHeader";
import SurveyReviewRating from "./SurveyReviewRating";

type Props = {
  onClose: () => void;
  surveyReview?: SurveyReview;
  onSubmit: (surveyReview: SurveyReview) => void;
}

const BuddyReviewToolModal = ({ onClose, surveyReview, onSubmit }: Props) => {
  const { reportEditor } = useReportEditorContext();
  const { activeBooking, project } = useProjectContext()
  const { query, create } = useAIOCRReportResults(project?.uid, activeBooking?.uid)
  const [showDev, setShowDev] = React.useState(false)
  const [hasAll, setHasAll] = React.useState(false)
  const [running, setRunning] = React.useState(false)
  const form = useSurveyReviewForm(surveyReview)
  const { query: meOrgQuery } = useMeOrgMember();
  const humanReadable: Record<SurveyReviewState, string> = {
    [SurveyReviewState.IN_PROGRESS]: "In progress",
    [SurveyReviewState.SUBMITTED]: "Submitted"
  }


  const inspections = reportEditor.getJobInspections(activeBooking?.uid || '')

  React.useEffect(() => {
    if (activeBooking) {
      setHasAll(reportEditor.getJobInspections(activeBooking?.uid).filter(e => e.video !== undefined).every(e => query.data?.map(e => e.inspectionId).includes(e.uid)))
      setRunning(query.data?.find(e => e.status === OCRResultStatus.PENDING) !== undefined)
    }
  }, [query, activeBooking])


  if (!query || query.isLoading || query.data === undefined) {
    return null;
  }

  if (!activeBooking) {
    return null;
  }

  return (
    <Modal visible={true} onClose={onClose}>
      <ModalHeader>Quality assurance tool</ModalHeader>
      <ModalBody width='95vw' height='70vh' overflow='auto'>

        <Box flex='horizontal' alignChildrenHorizontal='around' gap='x3'>
          <Box flex='vertical' gap='x1'>
            <Box flex='horizontal' alignChildrenHorizontal='middle' gap='x2'>

              <Text flex='horizontal' alignChildrenHorizontal='middle' alignChildrenVertical='end' size='x6' strong> {activeBooking.jobId}</Text>
              <Box>
                <Label
                  backgroundColor={form.state.state === SurveyReviewState.IN_PROGRESS ? 'background-shade-3' : 'positive-shade-2'}
                  textColor={form.state.state === SurveyReviewState.IN_PROGRESS ? 'text-shade-3' : 'text-shade-2'}
                >
                  <Text>
                    {humanReadable[form.state.state]}
                  </Text>
                </Label>
              </Box>
            </Box>
            <SurveyReviewRating
              readOnly={surveyReview?.state === SurveyReviewState.SUBMITTED}
              surveyReview={form.state}
              onRatingUpdate={r => form.setState((s) => ({
                ...s,
                rating: r
              }))}
              onCommentUpdate={c => form.setState((s) => ({
                ...s,
                comments: c
              }))}

            />
          </Box>
        </Box>

        <Table maxWidth='900px'>
          <TableHeader>
            <TableHeaderCell style={{ borderBottom: 'none' }}>
            </TableHeaderCell>
            <BuddyTableHeader icon={<Icons.Award />} content='Focus' />
            <BuddyTableHeader icon={<Icons.ChevronsRight />} content='Speed' />
            <BuddyTableHeader icon={<Icons.Search />} content='User/AI Observations' />
            <BuddyTableHeader icon={<Icons.Hash />} content='Meterage' />
            <BuddyTableHeader icon={<Icons.Clock />} content='Video length' />
            {showDev && <>
              <TableHeaderCell style={{ borderBottom: 'none' }}>
                <Box flex='horizontal' alignChildrenHorizontal="middle">
                  <Label flex='horizontal' gap='x1' backgroundColor='background-shade-3' textColor='text-shade-1'>
                    <Icons.Video />
                  </Label>
                </Box>
              </TableHeaderCell>
              <TableHeaderCell style={{ borderBottom: 'none' }}>
                <Box flex='horizontal' alignChildrenHorizontal="middle">
                  <Label flex='horizontal' gap='x1' backgroundColor='background-shade-3' textColor='text-shade-1'>
                    <Icons.Target />
                  </Label>
                </Box>
              </TableHeaderCell>
            </>}
            <BuddyTableHeader icon={<Icons.Activity />} content='Pipe condition' />
            <BuddyTableHeader icon={<Icons.Film />} content='Video' />
          </TableHeader>
          <TableBody>
            {inspections.map(inspection => (
              <BuddyReviewToolInspectionSummary key={inspection.uid} inspection={inspection} projectId={project?.uid} showDev={showDev} />
            ))}
          </TableBody>
        </Table>

      </ModalBody>

      <ModalFooter>
        <Box flex='horizontal' alignChildrenVertical='middle' alignChildrenHorizontal='around'>
          <Box flex='horizontal' gap='x2'>
            {activeBooking?.assignedTo &&
              <Box>
                <Text size='x1'>Survey by</Text>
                <Text size='x1' strong> {activeBooking?.assignedTo[0].fullName} </Text>
              </Box>
            }
            <Box>
              <Text size='x1'>Review by</Text>
              <Text size='x1' strong> {form.state.reviewedBy?.fullName} </Text>
            </Box>
          </Box>
          <Box flex='horizontal' gap='x1' alignChildrenVertical='end'>

            {form.state.state === SurveyReviewState.IN_PROGRESS &&
              <Button variant='primary' color='positive' disabled={form.state.rating === undefined} onClick={() => {
                onSubmit({
                  ...form.state,
                  state: SurveyReviewState.SUBMITTED
                })
              }}>
                Submit
              </Button>
            }
            {meOrgQuery.data?.role !== OrgRole.ENGINEER &&
              form.state.state === SurveyReviewState.SUBMITTED && <Button variant='primary' color='positive' onClick={() =>
                onSubmit({
                  ...form.state,
                  state: SurveyReviewState.IN_PROGRESS
                })
              }

              >Edit</Button>
            }


            {running &&
              <PlaceholderGradientAnimation animating>
                <Button variant='primary' color='accent'>
                  <AILogo fill='white' />
                </Button>
              </PlaceholderGradientAnimation>

            }

            {!hasAll && !running &&
              <Button variant='primary' color='accent' onClick={() => create.mutate()}>
                <AILogo fill='white' />
              </Button>
            }

            <FeatureFlag> <Button onClick={() => setShowDev(!showDev)}>Toggle dev</Button> </FeatureFlag>
          </Box>
          <Box flex='vertical' alignChildrenHorizontal='around' gap='x2'>
            <Text size='x1'> Total length: </Text>
            <Text align='middle' strong>

              {hasAll && !running ? `${reportEditor.getJobInspections(activeBooking?.uid || '').map(e =>
                Math.max(0, ...reportEditor.getInspectionObservations(e.uid).map(e => e.distance || 0))
              )
                .reduce((sum, current) => sum + current, 0).toFixed(2)} M` :
                <PlaceholderGradientAnimation animating={running}>
                  <PlaceholderText width='75px' />
                </PlaceholderGradientAnimation>
              }</Text>
          </Box>
        </Box>
      </ModalFooter>
    </Modal>
  )

}

export default BuddyReviewToolModal;