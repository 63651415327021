import { Org } from './Org';
import { Project } from './Project';

export interface OCRResult {
  status: OCRResultStatus;
  binarizor: string;
  usedCachedBB: boolean;
  bounds: [number, number, number, number];
  matches: Match[];
  inspectionId: string;
  project: Project;
  org: Org;
  createdAt: string;
  harvested?: boolean;
  time?: number;
  creditsUsed?: number;
}

export interface Match {
  text: number;
  frame: number;
  interpolated?: boolean;
  dismissed?: boolean;
}

export enum OCRResultStatus {
  FAILED = 0,
  SUCCEEDED = 1,
  PENDING = 2,
}
