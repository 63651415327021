import { CountryData, data } from 'emoji-flags';
import React from 'react';
import DataTable, { DataTableProps } from '../DataTable/DataTable';
import DataTableGroup from '../DataTable/DataTableGroup';
import DataTableItem from '../DataTable/DataTableItem';
import CountryView from './CountryView';

type Props = DataTableProps & {
  onSelect: (code: CountryData['code']) => void;
  value?: CountryData['code'];
};

const CountryList = ({ onSelect, value, ...rest }: Props) => {
  return (
    <DataTable {...rest}>
      <DataTableGroup>
        {data.map((data) => (
          <DataTableItem
            key={data.code}
            keywords={`${data.name} ${data.code}`}
            onClick={() => onSelect(data.code)}
            selected={data.code === value}
          >
            <CountryView code={data.code} />
          </DataTableItem>
        ))}
      </DataTableGroup>
    </DataTable>
  );
};

export default CountryList;
