import { getDayRelative } from '@drainify/utils';
import groupBy from 'lodash.groupby';
import { DateTime } from 'luxon';
import { Box } from 'preshape';
import React from 'react';
import useMeBookings from '../../hooks/useMeBookings';
import DataQueryTable from '../DataTable/DataQueryTable';
import DataTableGroup from '../DataTable/DataTableGroup';
import DataTableItem from '../DataTable/DataTableItem';
import BookingView from './BookingView';
import ReloadSpinner from './ReloadSpinner';

const BookingDataTableForUser = () => {
  const { query } = useMeBookings();

  const groupedBookings = query.data
    ? groupBy(query.data.data, ({ bookedForDate }) => bookedForDate)
    : {};
  const [translateY, setTranslateY] = React.useState(0);
  const containerRef = React.useRef(null);
  const startY = React.useRef(0); 

  const refreshThreshold = 60; 


  const handleTouchStart = (e: TouchEvent) => {
    startY.current = e.touches[0].clientY; 
  };

  const handleTouchMove = (e: TouchEvent) => {
    const currentY = e.touches[0].clientY;
    const distance = currentY - startY.current;

    if (distance > 0 && !query.isFetching) {
      setTranslateY(Math.min(distance, 150)); 
    }
  };

  const handleTouchEnd = () => {
    if (translateY >= refreshThreshold && !query.isFetching) {
      query.refetch().then(() => {
        setTranslateY(0); 
      });
    } else {
      setTranslateY(0); 
    }
  };

  React.useEffect(() => {
    const container = containerRef.current! as HTMLElement;
    container.addEventListener('touchstart', handleTouchStart);
    container.addEventListener('touchmove', handleTouchMove);
    container.addEventListener('touchend', handleTouchEnd);

    return () => {
      container.removeEventListener('touchstart', handleTouchStart);
      container.removeEventListener('touchmove', handleTouchMove);
      container.removeEventListener('touchend', handleTouchEnd);
    };
  }, [translateY, query.isFetching]);


  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '100px',
          zIndex: -1, 
        }}
      ></div>
      <ReloadSpinner/>
      <Box ref={containerRef} height='100vh'>
        <DataQueryTable

          entity="bookings"
          placeholder={<BookingView />}
          query={query}
        >
          {Object.entries(groupedBookings).map(([date, bookings]) => (
            <DataTableGroup
              key={date}
              title={getDayRelative(DateTime.fromJSDate(new Date(date)))}
            >
              {bookings.map((booking) => (
                <DataTableItem
                  key={booking.uid}
                  to={`/projects/${booking.project.uid}?activeBookingId=${booking.uid}`}
                >
                  <BookingView booking={booking} showTime />
                </DataTableItem>
              ))}
            </DataTableGroup>
          ))}
        </DataQueryTable>
      </Box>
    </>
  );
};

export default BookingDataTableForUser;
