import { OrgRole } from '@drainify/types';
import { BoxProps, Label } from 'preshape';
import React, { PropsWithChildren } from 'react';
import { orgRoleNames } from './OrgRoleView';

type Props = {
  role: OrgRole;
};

const OrgRoleBadge = ({
  role,
  ...rest
}: PropsWithChildren<BoxProps & Props>) => {
  return (
    <Label
      {...rest}
      alignChildrenVertical="middle"
      flex="horizontal"
      gap="x2"
      size="x1"
      textColor="light-shade-1"
      uppercase
    >
      {orgRoleNames[role]}
    </Label>
  );
};

export default OrgRoleBadge;
