import { ObservationCode } from '@drainify/types';
import { Box, BoxProps, Text } from 'preshape';
import React from 'react';
import ObservationCodeText from './ObservationCodeText';
import ObservationSymbol from './ObservationSymbol';

type Props = BoxProps & {
  code?: ObservationCode[];
};

const ObservationCodeView = ({ code, ...rest }: Props) => {
  return (
    <Text flex="horizontal" gap="x4" {...rest} alignChildrenVertical="middle">
      <Text basis="0" grow size="x3" strong>
        <ObservationCodeText code={code} />
      </Text>

      <Box>
        <ObservationSymbol code={code} />
      </Box>
    </Text>
  );
};

export default ObservationCodeView;
