import { PENDING_IMAGE_UPLOAD } from '@drainify/types';
import {
  Alert,
  Box,
  Button,
  Buttons,
  CheckBox,
  Icons,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalProps,
  ModalTitle,
  Text,
  useResizeObserver,
} from 'preshape';
import React, { ChangeEvent, useState } from 'react';
import { generatePath } from 'react-router-dom';
import useFileUpload from '../../hooks/useFileUpload';
import { FULL_SCREEN_MODAL_WIDTH } from '../App/App';
import FileUpload from '../FileUpload/FileUpload';
import ImageFromFile from '../Image/ImageFromFile';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';

type Props = ModalProps & {
  onClose: () => void;
  visible: boolean;
};

const PlanUploadModal = ({ onClose, visible, ...rest }: Props) => {
  const [sizeImageArea, refImageArea] = useResizeObserver<HTMLDivElement>();
  const nodeFileUpload = useFileUpload();
  const { project } = useProjectContext();
  const { reportEditor } = useReportEditorContext();

  const [fileToUpload, setFileToUpload] = useState<File>();
  const [originalHeight, setOriginalHeight] = useState<number>();
  const [originalWidth, setOriginalWidth] = useState<number>();
  const [name, setName] = useState<string>();
  const [isToScale, setIsToScale] = useState<boolean | undefined>(undefined);

  const handleSubmit = async () => {
    if(isToScale === undefined) {
      return;
    }

    if (fileToUpload) {
      reportEditor.addPlan({
        imageUrl: PENDING_IMAGE_UPLOAD,
        originalWidth: 10,
        originalHeight: 10,
        name: 'plan',
        isToScale,
      });
      await nodeFileUpload.upload(
        generatePath('/projects/:projectId/report/plans/image', {
          projectId: project?.uid,
        }),
        fileToUpload,
        {
          originalWidth: originalWidth,
          originalHeight: originalHeight,
          name: name,
          isToScale: isToScale,
        }
      );
    }
    onClose();
  };

  const handleSelectFile = async (file: File) => {
    if (!name) {
      setName(file.name);
    }
    setFileToUpload(file);
  };

  React.useEffect(() => {
    if (!visible) {
      setName(undefined);
      setOriginalHeight(undefined);
      setOriginalWidth(undefined);
      setFileToUpload(undefined);
      setIsToScale(undefined)
    }
  }, [visible]);

  return (
    <Modal
      {...rest}
      animation="FadeSlideUp"
      maxWidth={FULL_SCREEN_MODAL_WIDTH}
      onClose={onClose}
      overlayBackgroundCloseOnClick={false}
      visible={visible}
    >
      <ModalHeader>
        <ModalTitle>Upload plan</ModalTitle>
      </ModalHeader>

      <ModalBody flex="vertical">
        <Box flex="vertical" grow gap="x3">
          <Box grow minHeight="200px" ref={refImageArea}>
            <ImageFromFile
              file={fileToUpload}
              height={sizeImageArea.height}
              onLoad={(e: ChangeEvent<HTMLImageElement>) => {
                setOriginalHeight(e.target.naturalHeight);
                setOriginalWidth(e.target.naturalWidth);
              }}
            />
          </Box>
          <Box padding='x2' flex='vertical' gap='x2'>
            <CheckBox
              borderSize='x1'
              checked={isToScale === false}
              onChange={() => setIsToScale(false)}
            >
              <Text strong> Focused plan (Reccomended for most)</Text>
              <Text weak size='x1'> Enlarges and isolates your plan. Use scale tool to calibrate measurements.</Text>
            </CheckBox>
            <CheckBox
              borderSize="x1"
              checked={isToScale === true}
              onChange={() => setIsToScale(true)}
            >
              <Text strong>Map overlay</Text>
              <Text weak size='x1'>Plan will overlayed on the map. Assure bounds are set before uploading.</Text>
            </CheckBox>
            {
              isToScale === undefined && <Alert padding='x3' color='negative' backgroundColor='negative-shade-1' >
                
                <Text size='x1' strong align='middle'> You must select a plan type </Text>
                
                </Alert>
            }
          </Box>
          <FileUpload
            accept="image/*"
            maxWidth="300px"
            onChange={handleSelectFile}
            value={fileToUpload}
          >
            <Icons.Image />
            <Text
              style={{
                WebkitUserSelect: 'none',
                msUserSelect: 'none',
                userSelect: 'none',
                WebkitTouchCallout: 'none',
                KhtmlUserSelect: 'none',
                MozUserSelect: 'none',
              }}
            >
              Select image
            </Text>
          </FileUpload>
        </Box>
      </ModalBody>
      <ModalFooter>
        <Buttons alignChildrenHorizontal="around" flex="horizontal">
          <Button
            color={fileToUpload && 'accent'}
            disabled={fileToUpload === undefined || isToScale === undefined}
            elevate={true}
            grow
            onClick={handleSubmit}
            variant="primary"
            size="x3"
          >
            <Text>Upload</Text>
          </Button>
          <Button size="x3" grow onClick={onClose} variant="secondary">
            Cancel
          </Button>
        </Buttons>
      </ModalFooter>
    </Modal>
  );
};

export default PlanUploadModal;
