import { Box, Text } from 'preshape';
import React  from 'react';

const InvalidIOSVersionPage = () => {

const iosVersion = () => {
    const userAgent = window.navigator.userAgent;
    const iOSMatch = userAgent.match(/OS (\d+)_/);
    if (iOSMatch && iOSMatch[1]) {
      const iOSVersion = parseInt(iOSMatch[1], 10);
      return iOSVersion;
    } else return 'unknown'
  }

  return (
    <Box flex="vertical" grow>
      <Box
        alignChildren="middle"
        backgroundColor="background-shade-3"
        flex="vertical"
        grow
        padding="x12"
      >
        <Box maxWidth="400px">
          <Text align="middle" margin="x8" size="x6" strong>
            I'm sorry!
          </Text>
          <Text align="middle" margin="x8" size="x4" strong>
            Looks like your iOS is a little retro for us
          </Text>

          <Text size='x2'> minimum version is 16. Detected version {iosVersion()} </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default InvalidIOSVersionPage;
