import { Box } from 'preshape';
import React, { PropsWithChildren } from 'react';

const SigningInWrapper = ({ children }: PropsWithChildren<{}>) => {
  // const { user } = useContext(AuthenticationContext);

  // if (user?.getIdToken() === undefined) {
    // return <Box>loading</Box>
    // TODO - maybe time this out?
    // return <Text>Loading</Text>;
  // }

  return (
    <Box flex="vertical" grow>
      {children}
    </Box>
  );
};

export default SigningInWrapper;
