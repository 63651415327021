import { Box, Button, Buttons, Icons, Text } from 'preshape';
import React from 'react';
import { useCustomerContext } from '../../../components/Customer/CustomerProvider';
import CustomerSummary from '../../../components/Customer/CustomerSummary';
import PageContext from '../../../components/Page/PageContext';
import PlaceholderText from '../../../components/Placeholders/PlaceholderText';
import Page from '../Layout/Page';
import PageBody from '../Layout/PageBody';
import PageHeader from '../Layout/PageHeader';

const CustomerPage = () => {
  const { customer, query, backToText, backToLink } = useCustomerContext();

  return (
    <PageContext backToLink={backToLink} backToText={backToText} query={query}>
      <PageHeader title="Customer" />
      <Page>
        <PageBody>
          <Box margin="x10">
            <Box flex="vertical" alignChildrenHorizontal="middle">
              {customer ? (
                <Text margin="x2" size="x6">
                  {customer.name}
                </Text>
              ) : (
                <PlaceholderText size="x6" margin="x4" maxWidth="200px" />
              )}
            </Box>
          </Box>

          <Buttons gap="x4" margin="x10">
            {customer?.phoneNumber && (
              <Button
                backgroundColor="background-shade-2"
                basis="0"
                borderRadius="x3"
                color="accent"
                href={customer ? `tel:${customer.phoneNumber}` : undefined}
                flex="vertical"
                grow
                size="x3"
                tag="a"
                textColor="accent-shade-4"
                variant="tertiary"
              >
                <Icons.PhoneOutgoing size="1.5rem" />
                <Text margin="x1" size="x1">
                  Call
                </Text>
              </Button>
            )}

            {customer?.phoneNumber && (
              <Button
                backgroundColor="background-shade-2"
                basis="0"
                borderRadius="x3"
                color="accent"
                href={customer ? `sms:${customer.phoneNumber}` : undefined}
                flex="vertical"
                grow
                size="x3"
                tag="a"
                textColor="accent-shade-4"
                variant="tertiary"
              >
                <Icons.MessageSquare size="1.5rem" />
                <Text margin="x1" size="x1">
                  Message
                </Text>
              </Button>
            )}

            {customer?.email && (
              <Button
                backgroundColor="background-shade-2"
                basis="0"
                borderRadius="x3"
                color="accent"
                href={customer ? `mailto:${customer.email}` : undefined}
                flex="vertical"
                grow
                size="x3"
                tag="a"
                textColor="accent-shade-4"
                variant="tertiary"
              >
                <Icons.Mail size="1.5rem" />
                <Text margin="x1" size="x1">
                  Mail
                </Text>
              </Button>
            )}
          </Buttons>

          <CustomerSummary customer={customer} />
        </PageBody>
      </Page>
    </PageContext>
  );
};

export default CustomerPage;
