"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clockRefString = exports.getCircleSegmentPath = exports.getCircleArcPath = void 0;
const getCircleArcPath = (cx, cy, cr, a, b, withMove = false) => {
    const ax = cx + cr * Math.cos(a);
    const ay = cy + cr * Math.sin(a);
    const bx = cx + cr * Math.cos(b);
    const by = cy + cr * Math.sin(b);
    const largeArcFlag = Math.abs(b - a) >= Math.PI ? 1 : 0;
    return `
    ${withMove ? `M ${ax} ${ay}` : ''}
    A ${cr} ${cr} 0 ${largeArcFlag} ${1} ${bx} ${by}`;
};
exports.getCircleArcPath = getCircleArcPath;
const getCircleSegmentPath = (cx, cy, cr, a, b) => {
    const ax = cx + cr * Math.cos(a);
    const ay = cy + cr * Math.sin(a);
    return `
    M ${cx} ${cy}
    L ${ax} ${ay}
    ${(0, exports.getCircleArcPath)(cx, cy, cr, a, b)}
    L ${cx} ${cy} Z`;
};
exports.getCircleSegmentPath = getCircleSegmentPath;
const clockRefString = (clockFrom, clockTo) => {
    if (clockFrom === clockTo) {
        return `${clockFrom} - ${clockTo + 1}`;
    }
    return `${clockFrom} - ${clockTo + 1 === 13 ? 1 : clockTo + 1}`;
};
exports.clockRefString = clockRefString;
