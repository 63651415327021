import { ModalFooterInsert } from 'preshape';
import React, { useContext } from 'react';
import SearchInput from '../Search/SearchInput';
import { WizardContext } from './Wizard';

const WizardSearch = () => {
  const { activeStepOpts } = useContext(WizardContext);

  return (
    <ModalFooterInsert visible={!!activeStepOpts?.withSearch}>
      <SearchInput />
    </ModalFooterInsert>
  );
};

export default WizardSearch;
