import { PENDING_IMAGE_UPLOAD, PlanType, Report } from '@drainify/types';
import {
  Box,
  Button,
  Buttons,
  CheckBox,
  Icons,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
  useResizeObserver,
} from 'preshape';
import React, { ChangeEvent } from 'react';
import { generatePath } from 'react-router-dom';
import useFileUpload from '../../hooks/useFileUpload';
import useProjectReport from '../../hooks/useProjectReport';
import Tile from '../../pages/mobile/Pages/Tile';
import Explainer from '../Explainer/Explainer';
import FileUpload from '../FileUpload/FileUpload';
import resizeImage from '../Image/ImageCompressor';
import ImageFromFile from '../Image/ImageFromFile';
import { useProjectContext } from '../Project/ProjectProvider';

type Props = {
  report?: Report;
};

const planTypeSettingsName: Record<PlanType, string> = {
  [PlanType.GOOGLE_MAPS]: 'Map',
  [PlanType.UPLOADED_PLAN]: 'Upload',
  [PlanType.NO_PLAN]: 'None',
};

const PlanTypeSelector = ({ report }: Props) => {
  const [selectorVisible, setSelectorVisible] = React.useState(false);
  const [sizeImageArea, refImageArea] = useResizeObserver<HTMLDivElement>();
  const [originalHeight, setOriginalHeight] = React.useState<number>();
  const [originalWidth, setOriginalWidth] = React.useState<number>();
  const nodeFileUpload = useFileUpload();
  const { project } = useProjectContext();
  const [fileToUpload, setFileToUpload] = React.useState<File>();
  const { update } = useProjectReport({
    projectId: project?.uid,
  });
  const [planType, setPlanType] = React.useState<PlanType | undefined>(
    report?.planType
  );

  const handleSetFileToUpload = async (file: File) => {
    const compressedBlob = (await resizeImage({ file, maxSize: 500 })) as Blob;
    const compressedImage = new File([compressedBlob], 'uploaded-plan.jpg', {
      type: compressedBlob.type,
    });
    setFileToUpload(compressedImage);
  };

  React.useEffect(() => {
    setPlanType(report?.planType);
  }, [selectorVisible]);

  if (!report) {
    return null;
  }

  const submit = async () => {
    if (planType !== PlanType.UPLOADED_PLAN) {
      await update.mutateAsync({
        ...report,
        plan: null,
        planType: planType,
      });
    }

    if (fileToUpload && planType === PlanType.UPLOADED_PLAN) {
      await update.mutateAsync({
        ...report,
        plan: {
          imageUrl: PENDING_IMAGE_UPLOAD,
          uid: 'plan',
          originalWidth: 10,
          originalHeight: 10,
          name: 'plan',
          isToScale: false,
        },
        planType,
      });
      await nodeFileUpload.upload(
        generatePath('/projects/:projectId/report/plans/image', {
          projectId: project?.uid,
        }),
        fileToUpload,
        {
          originalWidth,
          originalHeight,
          name: 'Plan',
          isToScale: false,
        }
      );
    }
    setSelectorVisible(false);
  };

  return (
    <>
      <Tile
        onClick={() => setSelectorVisible(true)}
        icon={<Icons.Map size="1.5rem" />}
        text="Plan"
        label={planTypeSettingsName[report.planType || PlanType.GOOGLE_MAPS]}
      />
      <Modal
        visible={selectorVisible}
        overlayBackgroundCloseOnClick
        onClose={() => setSelectorVisible(false)}
      >
        <ModalHeader>
          <Box
            flex="horizontal"
            alignChildrenHorizontal="between"
            alignChildrenVertical="middle"
          >
            <Text>Plan type</Text>
            <Explainer title="Plan types">
              <Text>Three options of plan types</Text>
              <Text> - Using Google Maps to build a drainage plan</Text>
              <Text>
                {' '}
                - Upload an existing plan for reference or annotation
              </Text>
              <Text> - Use without a plan!</Text>
            </Explainer>
          </Box>
        </ModalHeader>
        <ModalBody>
          <Box flex="vertical" gap="x3">
            <CheckBox
              borderSize="x1"
              checked={planType === PlanType.GOOGLE_MAPS}
              key={PlanType.GOOGLE_MAPS}
              onChange={() => setPlanType(PlanType.GOOGLE_MAPS)}
              margin="x2"
            >
              {planTypeSettingsName[PlanType.GOOGLE_MAPS]}
            </CheckBox>
            <CheckBox
              borderSize="x1"
              checked={planType === PlanType.NO_PLAN}
              key={PlanType.NO_PLAN}
              onChange={() => setPlanType(PlanType.NO_PLAN)}
              margin="x2"
            >
              {planTypeSettingsName[PlanType.NO_PLAN]}
            </CheckBox>
            <CheckBox
              borderSize="x1"
              checked={planType === PlanType.UPLOADED_PLAN}
              key={PlanType.UPLOADED_PLAN}
              onChange={() => setPlanType(PlanType.UPLOADED_PLAN)}
              margin="x2"
            >
              {planTypeSettingsName[PlanType.UPLOADED_PLAN]}
            </CheckBox>
            {planType === PlanType.UPLOADED_PLAN && (
              <>
                <Box grow minHeight="200px" ref={refImageArea}>
                  <ImageFromFile
                    file={fileToUpload}
                    height={sizeImageArea.height}
                    onLoad={(e: ChangeEvent<HTMLImageElement>) => {
                      setOriginalHeight(e.target.naturalHeight);
                      setOriginalWidth(e.target.naturalWidth);
                    }}
                  />
                </Box>
                <FileUpload
                  maxWidth="300px"
                  onChange={handleSetFileToUpload}
                  accept="image/png, image/jpeg"
                  value={fileToUpload}
                >
                  <Icons.File />
                  <Text
                    style={{
                      WebkitUserSelect: 'none',
                      msUserSelect: 'none',
                      userSelect: 'none',
                      WebkitTouchCallout: 'none',
                      KhtmlUserSelect: 'none',
                      MozUserSelect: 'none',
                    }}
                  >
                    Select a document
                  </Text>
                </FileUpload>
              </>
            )}
          </Box>
          <ModalFooter>
            <Buttons flex='horizontal' alignChildrenHorizontal='middle'>
              <Button
                color="positive"
                disabled={planType === PlanType.UPLOADED_PLAN && !fileToUpload}
                elevate={true}
                grow
                onClick={submit}
                variant="primary"
                size="x3"
              >
                <Text>Confirm</Text>
              </Button>
            </Buttons>
          </ModalFooter>
        </ModalBody>
      </Modal>
    </>
  );
};

export default PlanTypeSelector;
