import { firestore } from 'firebase-admin';

export enum EmailType {
  EMAIL_BOOKING_TO_ASSIGNEE = 'EmailBookingToAssignee',
  EMAIL_INVITE_TO_INVITEE = 'EmailInviteToInvitee',
  EMAIL_SIGNUP_REQUEST = 'EmailSignupRequest',
  EMAIL_REQUEST_ACCESS = 'EmailRequestAccess',
  EMAIL_BOOKING_READY_FOR_QA = 'EmailBookingReadyForQA',
}

export type Email<C> = {
  context: C;
  uid?: string;
  recipient: string;
  type: EmailType;
  timestamp?: firestore.Timestamp;
};

export type EmailSignupRequest = Email<{
  signupRequestUid: string;
  fullName: string;
  organisationName: string;
}>;

export type EmailRequestAccess = Email<{
    email: string,
    contactNumber: string,
    organization: string,
    name: string
}>;

export type EmailBookingReadyForQA = Email<{
    email: string,
    organization: string,
    bookingUid: string;
}>;

export type EmailBookingQAComplete = Email<{
    email: string,
    organization: string,
    bookingUid: string;
}>;

export type EmailBookingOnCreateToAssignee = Email<{
  bookingUid: string;
  assigneeUid: string;
}>;

export type EmailInviteOnCreateToInvitee = Email<{
  inviteUid: string;
  orgName: string;
}>;
