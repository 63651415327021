import { Box, Motion, Text } from 'preshape';
import React from 'react';
import Logo from '../../../components/Logo/Logo';

const LoadingPage = () => {
  return (
    <Box
      flex="horizontal"
      alignChildrenHorizontal="middle"
      alignChildrenVertical="middle"
      grow
    >
      <Motion
        initial={{ opacity: 1 }}
        animate={{ scale: 1.5 }}
        transition={{
          ease: 'easeInOut',
          repeat: Infinity,
          duration: 2,
          repeatType: 'reverse',
        }}
      >
        <Text textColor="accent-shade-5">
          <Logo height={100} />
        </Text>
      </Motion>
    </Box>
  );
};

export default LoadingPage;
