import { Member } from '@drainify/types';
import { getFullFilePath } from '@drainify/utils';
import { Box, Text } from 'preshape';
import React from 'react';
import OrgRoleView from '../Org/OrgRoleView';
import SummaryCard from '../SummaryCard/SummaryCard';
import SummaryCardSection from '../SummaryCard/SummaryCardSection';
import { useUserContext } from '../User/UserProvider';
import UserView from '../User/UserView';
import { useWizardContext } from '../Wizard/Wizard';

type Props = {
  member?: Member;
  certificate?: File;
};

const MemberSummary = ({ member, certificate }: Props) => {
  const { user } = useUserContext();
  const { setActiveStepId } = useWizardContext();

  const editStep = (stepId: string) => {
    return () => {
      setActiveStepId(stepId);
    };
  };

  return (
    <SummaryCard>
      <SummaryCardSection title="User" value={member?.user}>
        <UserView user={member?.user} />
      </SummaryCardSection>

      <SummaryCardSection
        onEdit={user?.uid === member?.user.uid ? undefined : editStep('role')}
        title="Role"
        value={member?.role}
      >
        <OrgRoleView role={member?.role} />
      </SummaryCardSection>

      {certificate ? (
        <SummaryCardSection
          onEdit={editStep('certificate')}
          title="Certification"
          value={true}
        >
          <Text>Added</Text>
        </SummaryCardSection>
      ) : (
        <SummaryCardSection
          onEdit={editStep('certificate')}
          title="Certification"
          value={member?.certificateUrl}
        >
          <Box
            tag="a"
            href={getFullFilePath(member?.certificateUrl)}
            target="_blank"
          >
            View
          </Box>
        </SummaryCardSection>
      )}

      <SummaryCardSection
        onEdit={editStep('certificateNumber')}
        title="Certificate Number"
        value={member?.certificateNumber}
      >
        {member?.certificateNumber}
      </SummaryCardSection>
    </SummaryCard>
  );
};

export default MemberSummary;
