import React, { createContext, PropsWithChildren, useContext } from 'react';
import ReactPlayer from 'react-player';

interface OnProgressProps {
  played: number;
  playedSeconds: number;
  loaded: number;
  loadedSeconds: number;
}

type Context = {
  activeObservationId: string | null;
  playbackRate: number;
  player: React.RefObject<ReactPlayer> | null;
  playing: boolean;
  played: number;
  playedSeconds: number;
  loaded: number;
  seeking: boolean;
  duration: number;
  handleSetActiveObservationId: (activeObservationId: string | null) => void;
  handleDuration: (duration: number) => void;
  handleEnded: () => void;
  handleOnPlaybackRateChange: (speed: string) => void;
  handlePause: () => void;
  handlePlay: () => void;
  handlePlayPause: () => void;
  handleSetPlaybackRate: (rate: number) => void;
  handleSeekMouseUp: (played: string) => void;
  handleSeekMouseDown: () => void;
  handleProgress: (state: OnProgressProps) => void;
  handleSeekChange: (played: number) => void;
};

const VideoContext = createContext<Context>({
  activeObservationId: null,
  playbackRate: 0,
  player: null,
  playing: false,
  played: 0,
  playedSeconds: 0,
  loaded: 0,
  seeking: false,
  duration: 0,
  handleSetActiveObservationId: () => {},
  handleDuration: () => {},
  handleEnded: () => {},
  handleOnPlaybackRateChange: () => {},
  handlePause: () => {},
  handlePlay: () => {},
  handlePlayPause: () => {},
  handleSetPlaybackRate: () => {},
  handleSeekMouseUp: () => {},
  handleSeekMouseDown: () => {},
  handleProgress: () => {},
  handleSeekChange: () => {},
});

export const useVideoContext = () => useContext(VideoContext);

const VideoProvider = ({ children }: PropsWithChildren<{}>) => {
  const [playing, setPlaying] = React.useState(true);
  const [played, setPlayed] = React.useState(0);
  const [playedSeconds, setPlayedSeconds] = React.useState(0);
  const [loaded, setLoaded] = React.useState(0);
  const [seeking, setSeeking] = React.useState(false);
  const [duration, setDuration] = React.useState(0);
  const [playbackRate, setPlaybackRate] = React.useState(1.0);
  const [activeObservationId, setActiveObservationId] = React.useState<
    string | null
  >(null);
  const player = React.useRef<ReactPlayer>(null);

  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  const handleSetPlaybackRate = (rate: number) => {
    setPlaybackRate(rate);
  };

  const handleOnPlaybackRateChange = (speed: string) => {
    setPlaybackRate(parseFloat(speed));
  };

  const handlePlay = () => {
    setPlaying(true);
  };

  const handlePause = () => {
    setPlaying(false);
  };

  const handleSeekMouseDown = () => {
    setSeeking(true);
  };

  const handleSetActiveObservationId = (activeObservationId: string | null) => {
    setActiveObservationId(activeObservationId);
  };

  const handleSeekChange = (played: number) => {
    setPlayed(played + 0.000000000000001);
  };

  const handleSeekMouseUp = (played: string) => {
    setSeeking(false);
    player.current && player.current.seekTo(parseFloat(played));
  };

  const handleProgress = (state: OnProgressProps) => {
    if (!seeking) {
      setPlayedSeconds(state.playedSeconds);
      setLoaded(state.loaded);
      setPlayed(state.played);
    }
  };

  const handleEnded = () => {};

  const handleDuration = (duration: number) => {
    setDuration(duration);
  };

  const context: Context = {
    activeObservationId,
    duration,
    loaded,
    playbackRate,
    played,
    playedSeconds,
    player,
    playing,
    seeking,
    handleSetActiveObservationId,
    handleSetPlaybackRate,
    handleSeekMouseDown,
    handleSeekChange,
    handleSeekMouseUp,
    handleDuration,
    handleEnded,
    handleOnPlaybackRateChange,
    handlePause,
    handlePlay,
    handlePlayPause,
    handleProgress,
  };

  return (
    <VideoContext.Provider value={context}>{children}</VideoContext.Provider>
  );
};

export default VideoProvider;
