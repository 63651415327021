import { LanguageCodes } from '@drainify/types';
import { getFullFilePath } from '@drainify/utils';
import { signOut, getAuth } from 'firebase/auth';
import {
  Box,
  Button,
  Icons,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Placement,
  PlacementArrow,
  PlacementContent,
  PlacementManager,
  PlacementReference,
  Text,
} from 'preshape';
import React from 'react';
import { FULL_SCREEN_MODAL_WIDTH } from '../../../components/App/App';
import Avatar from '../../../components/Avatar/Avatar';
import FeatureFlag from '../../../components/FeatureFlag/FeatureFlag';
import { useGeoLocation } from '../../../components/GeoLocation/GeoLocation';
import { useNotificationContext } from '../../../components/Notifications/PWANotifications';
import { useOrgContext } from '../../../components/Org/OrgProvider';
import PlaceholderAvatar from '../../../components/Placeholders/PlaceholderAvatar';
import UnitSystemToggle from '../../../components/UnitSystemToggle/UnitSystemToggle';
import { useLanguageContext } from '../../../components/Usage/LanguageProvider';
import UserView from '../../../components/User/UserView';
import useMe from '../../../hooks/useMe';
import MyPerformancePage from '../Pages/MyPerformancePage';
import UserProfilePage from '../Pages/UserProfilePage';
import UserSecurityPage from '../Pages/UserSecurityPage';
import ContextMenu from './ContextMenu';
import ContextMenuItem from './ContextMenuItem';

const UserMenu = () => {
  const { setLanguage, language } = useLanguageContext();
  const { query: queryMe } = useMe();
  const [securityModalVisible, setSecurityModalVisible] = React.useState(false);
  const [myPerformanceModalVisible, setMyPerformanceModalVisible] = React.useState(false)
  const [languageModalVisible, setLanguageModalVisible] = React.useState(false);
  const [profileModalVisible, setProfileModalVisible] = React.useState(false);
  const { org } = useOrgContext()
  const { hasPermission, resetPermission} = useGeoLocation()
  const {hasPermission: hasNotificationPermission, togglePermissions} = useNotificationContext()
  const version = process.env.VERSION_HASH!;

  return (
    <>
      <PlacementManager trigger="click">
        <PlacementReference>
          {(props) => (
            <Button
              {...props}
              borderColor="background-shade-3"
              borderRadius="100px"
              paddingHorizontal="x1"
              paddingVertical="x1"
              size="x2"
            >
              {queryMe.data ? (
                <>
                  <Avatar
                    src={getFullFilePath(queryMe.data.avatarUrlCropped)}
                    uid={queryMe.data.uid}
                    size="1.75rem"
                  />
                  <Icons.ChevronDown />
                </>
              ) : (
                <PlaceholderAvatar size="1.5rem" />
              )}
            </Button>
          )}
        </PlacementReference>

        <Placement placement="bottom">
          <PlacementArrow backgroundColor="background-shade-3" />
          <PlacementContent
            backgroundColor="background-shade-1"
            borderColor="background-shade-3"
            borderRadius="x3"
            borderSize="x2"
            overflow="hidden"
            textColor="text-shade-1"
          >
            <ContextMenu backgroundColor="background-shade-1" margin="x2">
              <UserView padding="x4" user={queryMe.data} size="x2" />

              <ContextMenuItem
                icon={<Icons.User />}
                size="x2"
                onClick={() => setProfileModalVisible(true)}
              >
                Profile
              </ContextMenuItem>

              <ContextMenuItem
                icon={<Icons.Lock />}
                size="x2"
                onClick={() => setSecurityModalVisible(true)}
              >
                Security
              </ContextMenuItem>
              <ContextMenuItem
                icon={<Icons.Activity />}
                size="x2"
                onClick={() => setMyPerformanceModalVisible(true)}
              >
                My performance
              </ContextMenuItem>
            </ContextMenu>

            <ContextMenu>
              {!hasPermission &&
                <ContextMenuItem>
                  <Box
                    alignChildrenVertical="middle"
                    flex="horizontal"
                    gap="x2"
                    onClick={resetPermission}
                  >
                    <Icons.MapPin />
                    <Box grow>
                      <Text align="start" size="x2">
                        Allow location services
                      </Text>
                    </Box>

                    <Box>
                    </Box>
                  </Box>
                </ContextMenuItem>

              }
                {
                  !hasNotificationPermission &&
                  <ContextMenuItem>
                    <Box
                      alignChildrenVertical="middle"
                      flex="horizontal"
                      gap="x2"
                      onClick={() => {
                        togglePermissions?.(true)
                      }
                      }
                    >
                      <Icons.Bell />
                      <Box grow>
                        <Text align="start" size="x2">
                          Push notifications
                        </Text>
                      </Box>

                    </Box>
                  </ContextMenuItem>
                }

              <ContextMenuItem>
                <UnitSystemToggle />
              </ContextMenuItem>

              <FeatureFlag>
                <ContextMenuItem
                  size="x2"
                  onClick={() => setLanguageModalVisible(true)}
                >
                  <Box
                    alignChildrenVertical="middle"
                    flex="horizontal"
                    alignChildrenHorizontal="between"
                  >
                    <Text>Language</Text>
                    <Label>{language}</Label>
                  </Box>
                </ContextMenuItem>
              </FeatureFlag>
            </ContextMenu>

            <ContextMenu>
              <ContextMenuItem
                icon={<Icons.LogOut />}
                onClick={() => signOut(getAuth())}
              >
                Log out
              </ContextMenuItem>
            </ContextMenu>
            <ContextMenuItem
              icon={<Icons.AlertTriangle />
              }>
              <Box tag='a' href={`mailto:sam@drainify.io?subject=Bug report: Actor ID ${org?.uid}`}> report a bug</Box>
            </ContextMenuItem>
            <ContextMenuItem>
              <Text size='x1'>0.0.{version}</Text>
              <Text size='x1' textColor='text-shade-1'>© 2024, Drainify, All rights reserved.</Text>
            </ContextMenuItem>
          </PlacementContent>
        </Placement>
      </PlacementManager>

      <Modal
        visible={profileModalVisible}
        onClose={() => setProfileModalVisible(false)}
        maxWidth={FULL_SCREEN_MODAL_WIDTH}
      >
        <UserProfilePage />
      </Modal>

      <Modal
        visible={securityModalVisible}
        onClose={() => setSecurityModalVisible(false)}
        maxWidth={FULL_SCREEN_MODAL_WIDTH}
      >
        <UserSecurityPage onClose={() => setSecurityModalVisible(false)} />
      </Modal>
      <Modal
        visible={myPerformanceModalVisible}
        onClose={() => setMyPerformanceModalVisible(false)}
        maxWidth={FULL_SCREEN_MODAL_WIDTH}
      >
        <MyPerformancePage onClose={() => setMyPerformanceModalVisible(false)} />
      </Modal>
      <Modal
        visible={languageModalVisible}
        onClose={() => setLanguageModalVisible(false)}
        maxWidth={FULL_SCREEN_MODAL_WIDTH}
      >
        <ModalHeader>Change language</ModalHeader>
        <ModalBody>
          <Button
            onClick={() => setLanguage(LanguageCodes.EN)}
            active={language === LanguageCodes.EN}
          >
            English
          </Button>
          <Button
            onClick={() => setLanguage(LanguageCodes.ES)}
            active={language === LanguageCodes.ES}
          >
            Spanish
          </Button>
          <Button
            onClick={() => setLanguage(LanguageCodes.FR)}
            active={language === LanguageCodes.FR}
          >
            French
          </Button>
        </ModalBody>
      </Modal>
    </>
  );
};

export default UserMenu;
