import { Org } from '@drainify/types';
import { runValidations } from '@drainify/utils';
import { useForm } from 'preshape';
import { useCallback, useMemo } from 'react';
import * as yup from 'yup';

const validations = yup.object().shape({
  uid: yup.string().required(),
  description: yup.string().required().max(25),
  logoCropData: yup.object(),
  logoUrlCropped: yup.string(),
  logoUrlOriginal: yup.string(),
  name: yup.string().required(),
  country: yup.string().required(),
  contactNumber: yup.string().required(),
  contactEmail: yup.string().required(),
});

const useOrgForm = (org?: Org) => {
  const initial = useMemo<Org>(
    () =>
      org || {
        uid: '',
        name: '',
        description: '',
        country: '',
        contactNumber: '',
        contactEmail: '',
      },
    [org]
  );

  return useForm({
    initial: initial,
    validate: useCallback((user, setError) => {
      setError(runValidations(validations, user));
    }, []),
  });
};

export default useOrgForm;
