import { Section, SectionPostBody, isCircular } from '@drainify/types';
import { isSection } from '@drainify/utils';
import { captureVideoFrame } from 'capture-video-frame';
import { Box } from 'preshape';
import React from 'react';
import Distance from '../DistanceInput/Distance';
import FlowSymbol from '../FlowInput/FlowSymbol';
import { useHasPermission } from '../Permissions/Permissions';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import SymbolTemplate from '../Symbol/SymbolTemplate';
import { useVideoContext } from '../VideoPlayer/VideoProvider';
import InspectionAdditionalSummaryCardVideo from './InspectionAdditionalSummaryCardVideo';

type Props = {
  section?: Section | SectionPostBody;
};

const InspectionAdditionalSummaryVideo = ({ section }: Props) => {
  const { editSection } = useReportEditorContext();
  const { player, handlePause } = useVideoContext();
  const hasUpdateProjectPermission = useHasPermission('projects:update');

  const editSectionStep = (step: string) => {
    if (!hasUpdateProjectPermission) {
      return undefined;
    }

    return () => {
      if (isSection(section)) {
        handlePause();
        const frame = captureVideoFrame(player?.current?.getInternalPlayer())
          .blob as Blob;
        const file = new File([frame], 'name.jpg', { type: frame.type });
        editSection(section.uid, step, file);
      }
    };
  };

  return (
    <>
      <Box
        flex="horizontal"
        width="640px"
        wrap
        gap="x3"
        backgroundColor="background-shade-2"
        alignChildrenHorizontal="start"
      >
        <InspectionAdditionalSummaryCardVideo
          name="Pipe type"
          onEdit={editSectionStep('attributes.pipeType')}
        >
          {section?.attributes?.pipeType}
        </InspectionAdditionalSummaryCardVideo>

        <InspectionAdditionalSummaryCardVideo
          name="Flow"
          onEdit={editSectionStep('attributes.flow')}
        >
          <FlowSymbol value={section?.attributes.flow} />
        </InspectionAdditionalSummaryCardVideo>

        <InspectionAdditionalSummaryCardVideo
          name="Length"
          onEdit={editSectionStep('attributes.length')}
        >
          <Distance type="long" value={section?.attributes.length} />
        </InspectionAdditionalSummaryCardVideo>

        {isCircular(section?.attributes.shape) && (
          <InspectionAdditionalSummaryCardVideo
            name="Diameter"
            onEdit={editSectionStep('attributes.diameter')}
          >
            <Distance type="short" value={section?.attributes.diameter} />
          </InspectionAdditionalSummaryCardVideo>
        )}

        {section?.attributes.shape && !isCircular(section?.attributes.shape) && (
          <>
            <InspectionAdditionalSummaryCardVideo
              name="Height"
              onEdit={editSectionStep('attributes.height')}
            >
              <Distance type="short" value={section?.attributes.height} />
            </InspectionAdditionalSummaryCardVideo>

            <InspectionAdditionalSummaryCardVideo
              name="Width"
              onEdit={editSectionStep('attributes.width')}
            >
              <Distance type="short" value={section?.attributes.width} />
            </InspectionAdditionalSummaryCardVideo>
          </>
        )}

        <InspectionAdditionalSummaryCardVideo
          name="Material"
          onEdit={editSectionStep('attributes.material')}
        >
          <SymbolTemplate>{section?.attributes.material}</SymbolTemplate>
        </InspectionAdditionalSummaryCardVideo>

        <InspectionAdditionalSummaryCardVideo
          name="Shape"
          onEdit={editSectionStep('attributes.shape')}
        >
          <SymbolTemplate>{section?.attributes.shape}</SymbolTemplate>
        </InspectionAdditionalSummaryCardVideo>

        <InspectionAdditionalSummaryCardVideo
          name="Use"
          onEdit={editSectionStep('attributes.use')}
        >
          <SymbolTemplate>{section?.attributes.use}</SymbolTemplate>
        </InspectionAdditionalSummaryCardVideo>

        <InspectionAdditionalSummaryCardVideo
          name="Section ownership"
          onEdit={editSectionStep('attributes.sectionOwnership')}
        >
          {section?.attributes.sectionOwnership}
        </InspectionAdditionalSummaryCardVideo>
      </Box>
    </>
  );
};

export default InspectionAdditionalSummaryVideo;
