import {
  Attributes,
  Box,
  CheckboxIndicator,
  Button,
  ButtonProps,
} from 'preshape';
import React, { PropsWithChildren } from 'react';
import { isDesktop } from '../../utils/client';
import SearchResult from '../Search/SearchResult';
import { SearchKeyword } from '../Search/SearchResultGroup';

export type DataTableItemProps = Attributes<
  HTMLButtonElement,
  ButtonProps & {
    disabled?: boolean;
    keywords?: SearchKeyword;
    selected?: boolean;
  }
>;

const DataTableItem = ({
  children,
  keywords,
  selected,
  ...props
}: PropsWithChildren<DataTableItemProps>) => {
  return (
    <SearchResult keywords={keywords}>
      <Box className="DataTable__item" tag="li">
        <Button
          {...props}
          backgroundColorHover={isDesktop() ? undefined : 'background-shade-2'}
          textColorHover={isDesktop() ? undefined : 'text-shade-2'}
          align="start"
          gap="x3"
          uppercase={false}
          variant="tertiary"
          strong={false}
          width="100%"
        >
          {selected !== undefined && (
            <Box>
              <CheckboxIndicator
                tabIndex={-1}
                checked={selected}
                onChange={(e) => e.preventDefault()}
              />
            </Box>
          )}

          <Box basis="0" grow minWidth="0" shrink>
            {children}
          </Box>
        </Button>
      </Box>
    </SearchResult>
  );
};

export default DataTableItem;
