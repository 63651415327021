import { getSectionPoints, isMeasurement } from '@drainify/utils';
import {
  Box,
  Button,
  FormProvider,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalProps,
  ModalTitle,
  Text,
  TextArea,
} from 'preshape';
import React, { ChangeEvent, useEffect } from 'react';
import useMeasurementForm from '../../hooks/forms/useMeasurementForm';
import { FULL_SCREEN_MODAL_WIDTH } from '../App/App';
import DistanceInput from '../DistanceInput/DistanceInput';
import { useHasPermission } from '../Permissions/Permissions';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import { useReportContext } from '../Report/ReportProvider';
import SearchProvider from '../Search/SearchProvider';
import Wizard from '../Wizard/Wizard';
import WizardControls from '../Wizard/WizardControls/WizardControls';
import WizardReviewStep from '../Wizard/WizardReviewStep';
import WizardSearch from '../Wizard/WizardSearch';
import WizardStep from '../Wizard/WizardStep';
import WizardStepError from '../Wizard/WizardStepError';
import MeasurementLocationhack from './MeasurementLocationHack';
import MeasurementSummary from './MeasurementSummary';

type Props = ModalProps & {
  initialActiveStepId?: string;
  onClose: () => void;
  onCreate?: (measurementUid: string, inspectionId?: string) => void;
  measurementUid?: string;
};

const MeasurementModal = ({
  initialActiveStepId,
  onClose,
  onCreate,
  measurementUid,
  visible,
  ...rest
}: Props) => {
  const { activeBooking } = useProjectContext();
  const { isError, isLoading, isSuccess } = useReportContext();
  const { reportEditor } = useReportEditorContext();
  const measurement = reportEditor.getMeasurementByUid(measurementUid);
  const hasUpdateProjectPermission = useHasPermission('projects:update');
  const form = useMeasurementForm(measurement, activeBooking?.uid);

  const [lengthPlaceholder, setLengthPlaceholder] = React.useState<string>();

  React.useEffect(() => {
    if (form.state.points.length > 1) {
      let acc = 0;
      for (let i = 0; i < form.state.points.length - 1; i++) {
        acc +=
          getSectionPoints(form.state.points[i], form.state.points[i + 1])
            .distance || 0;
      }
      setLengthPlaceholder(acc.toFixed(2).toString());
    } else {
      setLengthPlaceholder(undefined);
    }
  }, [form.state.points]);

  const handleRemove = async () => {
    if (measurementUid) {
      reportEditor.removeMeasurement(measurementUid);
    }

    onClose();
  };

  const handleSetPoints = (points: GeoJSON.Point[]) => {
    form.setState((s) => ({
      ...s,
      points,
    }));
  };

  const handleSetLength = (length?: number) => {
    form.setState((s) => ({
      ...s,
      length,
    }));
  };

  const handleSave = async () => {
    if (measurementUid) {
      reportEditor.updateMeasurement(measurementUid, form.state);
      onClose();
    } else {
      const { uid } = reportEditor.addMeasurement(form.state);
      const inspection = reportEditor.getSectionInspectionLatest(uid);
      onCreate?.(uid, inspection?.uid);
      onClose();
    }
  };

  useEffect(() => {
    if (visible) {
      form.reset();
    }
  }, [visible]);

  return (
    <SearchProvider>
      <FormProvider form={form}>
        <Wizard
          flow={measurementUid ? 'update' : 'create'}
          initialActiveStepId={initialActiveStepId}
          isError={isError}
          isLoading={isLoading}
          isSuccess={isSuccess}
          onSave={hasUpdateProjectPermission ? handleSave : undefined}
          onRemove={hasUpdateProjectPermission ? handleRemove : undefined}
          onCancel={onClose}
          reset={visible}
        >
          <Modal
            {...rest}
            animation="FadeSlideUp"
            margin="x4"
            maxWidth={FULL_SCREEN_MODAL_WIDTH}
            onClose={onClose}
            overlayBackgroundCloseOnClick={false}
            visible={visible}
          >
            <ModalHeader>
              <ModalTitle>
                {isMeasurement(measurement)
                  ? reportEditor.getMeasurementName(measurement)
                  : 'New measurement'}
              </ModalTitle>
            </ModalHeader>

            <ModalBody>
              <WizardStep id="name" title={`Measurement Name`}>
                <Box flex="vertical" gap="x2">
                  <Input
                    name="length"
                    placeholder={reportEditor.getMeasurementName(form.state)}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      form.setState((s) => ({
                        ...s,
                        name: e.target.value,
                      }))
                    }
                    type="long"
                    value={form.state.name || ''}
                  />
                </Box>
              </WizardStep>
              <WizardStep id="points">
                <MeasurementLocationhack
                  onChange={handleSetPoints}
                  markType="measurement-point"
                />
              </WizardStep>
              <WizardStep id="length" title={`Length`}>
                <Box flex="vertical" gap="x2">
                  <DistanceInput
                    name="length"
                    onChange={handleSetLength}
                    type="long"
                    value={form.state.length}
                  />
                  {lengthPlaceholder && (
                    <Box>
                      <Text size="x2">{`Estimated: ${lengthPlaceholder}m`}</Text>
                      <Button
                        size="x2"
                        onClick={() => {
                          const float = parseFloat(lengthPlaceholder);
                          handleSetLength(float);
                        }}
                      >
                        Confirm
                      </Button>
                    </Box>
                  )}
                </Box>
              </WizardStep>
              <WizardStep id="description" title={`Description`}>
                <Box flex="vertical" gap="x2">
                  <TextArea
                    name="length"
                    rows={6}
                    placeholder="From property to boundary..."
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                      form.setState((s) => ({
                        ...s,
                        description: e.target.value,
                      }))
                    }
                    type="long"
                    value={form.state.description || ''}
                  />
                </Box>
              </WizardStep>
              <WizardReviewStep>
                <MeasurementSummary measurement={form.state} />
              </WizardReviewStep>
            </ModalBody>

            <ModalFooter>
              <WizardStepError/>
              <WizardSearch />
              <WizardControls />
            </ModalFooter>
          </Modal>
        </Wizard>
      </FormProvider>
    </SearchProvider>
  );
};

export default MeasurementModal;
