import { Box, Button, Buttons, Icons, Text } from 'preshape';
import React from 'react';

const Error404 = () => {
  return (
    <Box
      alignChildren="middle"
      fixed="center"
      flex="vertical"
      grow
      padding="x4"
      maxWidth="30rem"
    >
      <Box
        backgroundColor="background-shade-2"
        borderRadius="x3"
        padding="x6"
        textColor="text-shade-1"
      >
        <Box margin="x12">
          <Icons.Frown margin="x2" size="3rem" />

          <Text size="x6" strong>
            Oops, page not found.
          </Text>

          <Text margin="x2" size="x5">
            You must have gone down the wrong pipe because there's nothing here.
          </Text>
        </Box>

        <Box margin="x12">
          <Text margin="x2" size="x3">
            You can try navigating to what you were looking for from the main
            screen but if you keep getting here, then please get it touch.
          </Text>
        </Box>

        <Buttons>
          <Button
            basis="0"
            color="accent"
            grow
            size="x3"
            to="/"
            variant="primary"
          >
            Go to home
          </Button>
        </Buttons>
      </Box>
    </Box>
  );
};

export default Error404;
