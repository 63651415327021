import { NodeCode, SectionNodePosition } from '@drainify/types';
import { getNodeCodeText } from '@drainify/utils';
import React from 'react';
import SymbolTemplate from '../Symbol/SymbolTemplate';

type Props = {
  code?: NodeCode;
  nodePosition?: SectionNodePosition;
};

const NodeSymbol = ({ code, nodePosition }: Props) => {
  return <SymbolTemplate>{getNodeCodeText(code, nodePosition)}</SymbolTemplate>;
};

export default NodeSymbol;
