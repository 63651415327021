import { Invite } from '@drainify/types';
import {
  ButtonProps,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Text,
  Input,
  InputLabel,
  FormProvider,
} from 'preshape';
import React, { ChangeEvent, PropsWithChildren } from 'react';
import useInviteForm from '../../hooks/forms/useInviteForm';
import useInvite from '../../hooks/useInvite';
import useMeOrg from '../../hooks/useMeOrg';
import useOrgInvites from '../../hooks/useOrgInvites';
import { FULL_SCREEN_MODAL_WIDTH } from '../App/App';
import OrgRoleList from '../Org/OrgRoleList';
import OrgRolesInformationButton from '../Org/OrgRolesInformationButton';
import Wizard from '../Wizard/Wizard';
import WizardControls from '../Wizard/WizardControls/WizardControls';
import WizardReviewStep from '../Wizard/WizardReviewStep';
import WizardStep from '../Wizard/WizardStep';
import WizardStepError from '../Wizard/WizardStepError';
import InviteSummary from './InviteSummary';

type Props = ButtonProps & {
  onClose: () => void;
  visible: boolean;
  invite?: Invite;
};

const InviteModal = ({
  onClose,
  invite,
  visible,
  ...rest
}: PropsWithChildren<Props>) => {
  const { query: queryMeOrg } = useMeOrg();
  const { create } = useOrgInvites(queryMeOrg.data?.uid);
  const { update, remove } = useInvite({ inviteId: invite?.uid });
  const form = useInviteForm(invite);

  const handleSave = async () => {
    if (invite) {
      await update.mutateAsync(form.state);
    } else {
      await create.mutateAsync(form.state);
    }

    form.reset();
    onClose();
  };

  const handleRemove = async () => {
    await remove.mutateAsync();
    form.reset();
    onClose();
  };

  return (
    <FormProvider form={form}>
      <Wizard
        flow={invite ? 'update' : 'create'}
        isError={create.isError || update.isError}
        isLoading={create.isLoading || update.isLoading}
        isSuccess={create.isSuccess || update.isSuccess}
        onSave={handleSave}
        onRemove={handleRemove}
        onCancel={onClose}
        reset={visible}
      >
        <Modal
          {...rest}
          animation="FadeSlideUp"
          margin="x4"
          maxWidth={FULL_SCREEN_MODAL_WIDTH}
          onClose={onClose}
          overlayBackgroundCloseOnClick={false}
          visible={visible}
        >
          <ModalHeader>
            <Text size="x5" strong>
              {invite ? 'Update invite' : 'New invite'}
            </Text>
          </ModalHeader>

          <ModalBody>
            <WizardStep id="recipient" title="Email">
              <InputLabel>
                <Input
                  name="email"
                  placeholder="e.g. john.smith@email.com"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    form.setState((s) => ({ ...s, recipient: e.target.value }))
                  }
                  value={form.state.recipient}
                />
              </InputLabel>
            </WizardStep>

            <WizardStep
              action={<OrgRolesInformationButton />}
              id="role"
              title="Role"
            >
              <OrgRoleList
                onSelect={(role) => form.setState((s) => ({ ...s, role }))}
                value={form.state.role}
              />
            </WizardStep>

            <WizardReviewStep>
              <InviteSummary invite={form.state} />
            </WizardReviewStep>
          </ModalBody>

          <ModalFooter>
            <WizardStepError />
            <WizardControls />
          </ModalFooter>
        </Modal>
      </Wizard>
    </FormProvider>
  );
};

export default InviteModal;
