import { JobDocument } from '@drainify/types';
import { getFullFilePath } from '@drainify/utils';
import { Box, Button, Icons, Link, Text } from 'preshape';
import React from 'react';
import useJobDocument from '../../hooks/useJobDocument';
import ConfirmationDeleteModal from '../../utils/ConfirmationDeleteModal';

type Props = {
  document: JobDocument;
  jobId?: string;
};

const DocumentButton = ({ document, jobId }: Props) => {
  const [documentDeleteModalVisible, setDocumentDeleteModalVisible] =
    React.useState(false);

  const { remove } = useJobDocument(jobId, document.uid);

  const removeDocument = async () => {
    remove.mutateAsync();
  };

  return (
    <>
      <Box flex="horizontal" alignChildrenHorizontal="between">
        <Link
          href={getFullFilePath(document.path)}
          download={document.filename}
          target="_blank"
          rel="noreferrer"
          grow
          basis="0"
        >
          <Button
            variant="tertiary"
            color="accent"
            flex="horizontal"
            gap="x2"
            width="100%"
            alignChildrenHorizontal="start"
          >
            <Icons.Download />
            <Text size="x1" strong>
              {document.filename.substr(0, 35) +
                (document.filename.length > 35 ? '...' : '')}
            </Text>
          </Button>
        </Link>
        <Button
          color="negative"
          variant="tertiary"
          onClick={() => setDocumentDeleteModalVisible(true)}
        >
          <Icons.Trash />
        </Button>
      </Box>

      <ConfirmationDeleteModal
        visible={documentDeleteModalVisible}
        content={'Are you sure you want to delete document'}
        subheading={'It will not be recoverable'}
        action={() => removeDocument()}
        onClose={() => setDocumentDeleteModalVisible(false)}
      />
    </>
  );
};

export default DocumentButton;
