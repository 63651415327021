import { Buttons, Text } from 'preshape';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Explainer from '../../../components/Explainer/Explainer';
import { useProjectContext } from '../../../components/Project/ProjectProvider';
import SectionCreateButton from '../../../components/Section/SectionCreateButton';
import SectionList from '../../../components/Section/SectionList';
import Page from '../Layout/Page';
import PageBody from '../Layout/PageBody';
import PageHeader from '../Layout/PageHeader';
import PageMenu from '../Layout/PageMenu';

const SectionsPage = () => {
  const navigate = useNavigate();
  const { activeBookingId } = useProjectContext();

  return (
    <Page>
      <PageHeader
        title="Sections"
        action={
          <Explainer title={'Sections'}>
            <Text>
              Capturing each section's essential details becomes a breeze on
              site.
            </Text>
            <Text>
              You can effortlessly upload the data to the cloud, making it
              instantly accessible for editing at your office.
            </Text>
            <Text>
              Once back at the office, you can easily upload the relevant
              section video, incorporate observations, and finalise the survey.
            </Text>
          </Explainer>
        }
      />

      <PageBody flex="vertical" gap="x3" overflow="auto" basis="0" grow>
        <PageMenu margin="x3">
          <SectionList
            onItemClick={(e) =>
              navigate(e + `?activeBookingId=${activeBookingId}`)
            }
          />
        </PageMenu>
      </PageBody>
      <Buttons padding="x6">
        <SectionCreateButton color="accent" variant="primary" grow />
      </Buttons>
    </Page>
  );
};

export default SectionsPage;
