import { Box, BoxProps } from 'preshape';
import React, { PropsWithChildren } from 'react';
import HoursAndMinutesPicker, { TimeFormat } from './HoursAndMinutesPicker';

type Props = BoxProps & {
  onChange: (startTime: TimeFormat) => void;
  startTime?: TimeFormat;
};

const DEFAULT_START_TIME: TimeFormat = [9, 0];

const TimePicker = ({
  onChange,
  startTime,
  ...props
}: PropsWithChildren<Props>) => {
  return (
    <Box {...props}>
      <HoursAndMinutesPicker
        onChange={(startTime) => onChange(startTime)}
        margin="x4"
        time={startTime || DEFAULT_START_TIME}
      />
    </Box>
  );
};

export default TimePicker;
