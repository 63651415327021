import { Appear, AppearProps, Box, Text } from 'preshape';
import React, { PropsWithChildren } from 'react';

type Props = AppearProps & {
  icon?: JSX.Element;
};

const DataTableMessage = ({
  children,
  icon,
  ...rest
}: PropsWithChildren<Props>) => {
  return (
    <Appear animation="Fade" textColor="text-shade-4" {...rest}>
      {icon && (
        <Box alignChildren="middle" flex="horizontal" margin="x3">
          {icon}
        </Box>
      )}

      {children && (
        <Text align="middle" size="x2" strong>
          {children}
        </Text>
      )}
    </Appear>
  );
};

export default DataTableMessage;
