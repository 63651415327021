import { BendPoint, SectionFlowCode } from '@drainify/types';
import { ReportElementType } from '@drainify/utils';
import { themes } from 'preshape';
import { useCallback, useMemo } from 'react';
import { useAppContext } from '../../../Bootstrap/Bootstrap';
import useDrawGeometry from '../../../Map/useDrawGeometry';
import { MapLayerUpdateFn, MapLayerLine } from '../../../Map/useMap';
import { reportMapLayerOrder } from './ReportMapElements';

type Props = {
  fill?: string;
  pointA?: GeoJSON.Point | null;
  pointB?: GeoJSON.Point | null;
  visible?: boolean;
  scale?: number;
  stroke?: string;
  additionalPoints?: BendPoint[];
  strokeWidth?: number;
  width?: number;
  flow?: SectionFlowCode;
};

export const PIPE_WIDTH = 10;
export const PIPE_BORDER_WIDTH = 1;

const useDrawReportMapPipeSimple = ({
  pointA,
  pointB,
  flow,
  visible = true,
  stroke,
  strokeWidth = PIPE_BORDER_WIDTH,
  width = PIPE_WIDTH,
  additionalPoints = [],
}: Props): MapLayerUpdateFn<MapLayerLine> => {
  const { theme } = useAppContext();

  const flowDirectionETS = flow === SectionFlowCode.ETS;

  const geometry = useMemo<GeoJSON.LineString | undefined | null>(
    () =>
      pointA && pointB
        ? {
            type: 'LineString',
            coordinates: [
              pointA.coordinates,
              ...additionalPoints.map((e) => e.point.coordinates),
              pointB.coordinates,
            ],
          }
        : {
            type: 'LineString',
            coordinates: [],
          },
    [pointA, pointB]
  );

  const updateFlowDirection = useDrawGeometry<MapLayerLine>(
    useMemo(
      () => ({
        geometry: geometry,
        style: {
          zIndex: reportMapLayerOrder[ReportElementType.SECTION],
          strokeColor: stroke || themes[theme].colorTextShade1,
          strokeOpacity: 0.8,
          strokeWeight: 1,
          icons: flow !== undefined && [
            {
              icon: {
                path: flowDirectionETS
                  ? google.maps.SymbolPath.FORWARD_OPEN_ARROW
                  : google.maps.SymbolPath.BACKWARD_OPEN_ARROW,
              },
              offset: '30px',
              repeat: '100px',
            },
          ],
        },
      }),
      [geometry, strokeWidth, theme, visible, width, flow, stroke]
    )
  );

  return useCallback(
    (layer) => {
      updateFlowDirection?.(layer);
    },
    [updateFlowDirection]
  );
};

export default useDrawReportMapPipeSimple;
