import { Booking, BookingPostBody } from '@drainify/types';
import { runValidations } from '@drainify/utils';
import React from 'react';
import { jobValidations } from '../../hooks/forms/useBookingForm';
import useMeOrg from '../../hooks/useMeOrg';
import useOrgBookings from '../../hooks/useOrgBookings';
import { useHasPermission } from '../Permissions/Permissions';
import { useProjectContext } from '../Project/ProjectProvider';
import SummaryCard from '../SummaryCard/SummaryCard';
import SummaryCardSection from '../SummaryCard/SummaryCardSection';
import JobAdditionalModal from './JobAdditionalModal';

type Props = {
  job?: Booking | BookingPostBody;
};

const JobAdditionalSummary = ({ job }: Props) => {
  const [additionalBookingModalVisible, setAdditionalBookinModalVisible] =
    React.useState(false);
  const [editStep, setEditStep] = React.useState<string>();
  const hasUpdateProjectPermission = useHasPermission('projects:update');
  const { query: queryOrg } = useMeOrg();
  const { query: queryBookings } = useOrgBookings(queryOrg.data?.uid);
  const jobIds = queryBookings.data?.data.map((e) => e.jobId || '');
  const { activeBooking } = useProjectContext();

  const error = runValidations(jobValidations(jobIds || []), job);

  const editSectionStep = (step: string) => {
    if (!hasUpdateProjectPermission) {
      return undefined;
    }
    if (!!activeBooking?.completedAt) {
      return undefined;
    }

    return () => {
      setAdditionalBookinModalVisible(true);
      setEditStep(step);
    };
  };
  if (!job) {
    return null;
  }

  return (
    <>
      <SummaryCard title="Additional information">
        <SummaryCardSection
          error={error?.['attributes.inspectionStage']}
          title="InspectionStage"
          onEdit={editSectionStep('attributes.inspectionStage')}
        >
          {job.attributes?.inspectionStage}
        </SummaryCardSection>
        <SummaryCardSection
          error={error?.['attributes.clientDefinedOne']}
          title="Client defined 1"
          onEdit={editSectionStep('attributes.clientDefinedOne')}
        >
          {job.attributes?.clientDefinedOne}
        </SummaryCardSection>
        <SummaryCardSection
          error={error?.['attributes.clientDefinedTwo']}
          title="Client defined 2"
          onEdit={editSectionStep('attributes.clientDefinedTwo')}
        >
          {job.attributes?.clientDefinedTwo}
        </SummaryCardSection>
        <SummaryCardSection
          error={error?.['attributes.clientDefinedThree']}
          title="Client defined 3"
          onEdit={editSectionStep('attributes.clientDefinedThree')}
        >
          {job.attributes?.clientDefinedThree}
        </SummaryCardSection>
        <SummaryCardSection
          error={error?.['attributes.clientDefinedFour']}
          title="Client defined 4"
          onEdit={editSectionStep('attributes.clientDefinedFour')}
        >
          {job.attributes?.clientDefinedFour}
        </SummaryCardSection>
        <SummaryCardSection
          title="Client defined 5"
          error={error?.['attributes.clientDefinedFive']}
          onEdit={editSectionStep('attributes.clientDefinedFive')}
        >
          {job.attributes?.clientDefinedFive}
        </SummaryCardSection>
        <SummaryCardSection
          title="Client defined 6"
          error={error?.['attributes.clientDefinedSix']}
          onEdit={editSectionStep('attributes.clientDefinedSix')}
        >
          {job.attributes?.clientDefinedSix}
        </SummaryCardSection>
      </SummaryCard>
      {'uid' in job && (
        <JobAdditionalModal
          job={job}
          visible={additionalBookingModalVisible}
          initialActiveStepId={editStep}
          onClose={() => setAdditionalBookinModalVisible(false)}
        />
      )}
    </>
  );
};

export default JobAdditionalSummary;
