import {
  CoatingMethod,
  DrainSewerType,
  LegalStatus,
  LiningType,
  LocationTypeCode,
  Ownership,
  PLRSuffix,
  Section,
} from '@drainify/types';
import {
  getCoatingMethodSchema,
  getDrainSewerTypesSchema,
  getLandOwnershipCodeSchema,
  getLiningTypeSchema,
  getLocationTypeCodeSchema,
  sectionAttributeApplicable,
} from '@drainify/utils';
import {
  CheckBox,
  FormProvider,
  Input,
  InputLabel,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalProps,
  ModalTitle,
} from 'preshape';
import React, { ChangeEvent, useEffect } from 'react';
import useSectionForm from '../../hooks/forms/useSectionForm';
import { FULL_SCREEN_MODAL_WIDTH } from '../App/App';
import DistanceInput from '../DistanceInput/DistanceInput';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import { useLanguageContext } from '../Usage/LanguageProvider';
import ProjectTypeWizardStep from '../Wizard/ProjectTypeWizardStep';
import Wizard from '../Wizard/Wizard';
import WizardControls from '../Wizard/WizardControls/WizardControls';
import WizardReviewStep from '../Wizard/WizardReviewStep';
import WizardStepError from '../Wizard/WizardStepError';
import SectionAdditionalSummary from './SectionAdditionalSummary';

type Props = ModalProps & {
  onClose: () => void;
  initialActiveStepId?: string;
  sectionUid: string;
};

const SectionAdditionalModal = ({
  onClose,
  initialActiveStepId,
  sectionUid,
  visible,
  ...rest
}: Props) => {
  const { language } = useLanguageContext();
  const { activeBooking } = useProjectContext();
  const { reportEditor } = useReportEditorContext();
  const section = reportEditor.getSectionByUid(sectionUid);
  const form = useSectionForm(section);

  const handleSave = async () => {
    if (section) {
      reportEditor.updateSection(section.uid, form.state);
    }

    onClose();
  };

  const handleSetAttribute =
    <T extends keyof Section['attributes']>(attribute: T) =>
    (value?: Section['attributes'][T]) => {
      form.setState((s) => ({
        ...s,
        attributes: {
          ...s.attributes,
          [attribute]: value,
        },
      }));
    };

  const handleSetAttributeFromValue =
    <T extends keyof Section['attributes']>(
      attribute: T,
      value: Section['attributes'][T]
    ) =>
    () =>
      handleSetAttribute(attribute)(value);

  const handleSetAttributeFromTextInput =
    <T extends keyof Section['attributes']>(attribute: T) =>
    (event: ChangeEvent<HTMLInputElement>) =>
      handleSetAttribute(attribute)(
        event.target.value as Section['attributes'][T]
      );

  const handleSetAttributeFromTextInputAsNumber =
    <T extends keyof Section['attributes']>(attribute: T) =>
    (event: ChangeEvent<HTMLInputElement>) =>
      handleSetAttribute(attribute)(
        parseInt(event.target.value) as Section['attributes'][T]
      );

  useEffect(() => {
    if (!visible) {
      form.reset();
    }
  }, [visible]);

  return (
    <FormProvider form={form}>
      <Wizard
        flow={'update'}
        onSave={handleSave}
        onCancel={onClose}
        reset={visible}
        initialActiveStepId={initialActiveStepId}
      >
        <Modal
          {...rest}
          visible={visible}
          animation="FadeSlideUp"
          margin="x4"
          maxWidth={FULL_SCREEN_MODAL_WIDTH}
          onClose={onClose}
          overlayBackgroundCloseOnClick={false}
        >
          <ModalHeader>
            <ModalTitle>{reportEditor.getSectionName(section)}</ModalTitle>
          </ModalHeader>

          <ModalBody flex="vertical">
            {sectionAttributeApplicable(
              'attributes.alternateID',
              activeBooking?.projectType
            ) && (
              <ProjectTypeWizardStep
                id="attributes.alternateID"
                title="Alternate ID"
              >
                <InputLabel>
                  <Input
                    value={form.state.attributes.alternateID || ''}
                    onChange={handleSetAttributeFromTextInput('alternateID')}
                  />
                </InputLabel>
              </ProjectTypeWizardStep>
            )}
            {sectionAttributeApplicable(
              'attributes.divisionOrDistrict',
              activeBooking?.projectType
            ) && (
              <ProjectTypeWizardStep
                id="attributes.divisionOrDistrict"
                title="Division or district code"
              >
                <InputLabel>
                  <Input
                    value={form.state.attributes.divisionOrDistrict || ''}
                    onChange={handleSetAttributeFromTextInput(
                      'divisionOrDistrict'
                    )}
                  />
                </InputLabel>
              </ProjectTypeWizardStep>
            )}
            {sectionAttributeApplicable(
              'attributes.drainageArea',
              activeBooking?.projectType
            ) && (
              <ProjectTypeWizardStep
                id="attributes.drainageArea"
                title="Drainage area"
              >
                <InputLabel>
                  <Input
                    type="large"
                    onChange={handleSetAttributeFromTextInput('drainageArea')}
                    value={form.state.attributes.drainageArea}
                  />
                </InputLabel>
              </ProjectTypeWizardStep>
            )}
            {sectionAttributeApplicable(
              'attributes.jointLength',
              activeBooking?.projectType
            ) && (
              <ProjectTypeWizardStep
                id="attributes.jointLength"
                title="Joint length"
              >
                <InputLabel>
                  <DistanceInput
                    onChange={handleSetAttribute('jointLength')}
                    type="short"
                    value={form.state.attributes.jointLength}
                  />
                </InputLabel>
              </ProjectTypeWizardStep>
            )}
            <ProjectTypeWizardStep id="attributes.PLRSuffix" title="PLR suffix">
              {Object.values(PLRSuffix).map((PLRSuffix) => (
                <CheckBox
                  borderSize='x1'
                  checked={form.state.attributes.PLRSuffix === PLRSuffix}
                  key={PLRSuffix}
                  margin="x1"
                  onChange={handleSetAttributeFromValue('PLRSuffix', PLRSuffix)}
                >
                  {PLRSuffix}
                </CheckBox>
              ))}
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.landOwnership"
              title="Land ownership"
            >
              <InputLabel>
                {Object.values(Ownership).map((landOwnership) => (
                  <CheckBox
                    borderSize='x1'
                    checked={
                      form.state.attributes.landOwnership === landOwnership
                    }
                    key={landOwnership}
                    margin="x1"
                    onChange={handleSetAttributeFromValue(
                      'landOwnership',
                      landOwnership
                    )}
                  >
                    {getLandOwnershipCodeSchema(landOwnership).name[language]}
                  </CheckBox>
                ))}
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.liningType"
              title="Lining type"
            >
              <InputLabel>
                {Object.values(LiningType)
                  .filter(
                    (e) =>
                      activeBooking?.projectType &&
                      getLiningTypeSchema(e).projectTypes?.includes(
                        activeBooking.projectType
                      )
                  )
                  .map((liningType) => (
                    <CheckBox
                      borderSize='x1'
                      checked={form.state.attributes.liningType === liningType}
                      key={liningType}
                      margin="x1"
                      onChange={handleSetAttributeFromValue(
                        'liningType',
                        liningType
                      )}
                    >
                      {getLiningTypeSchema(liningType).name[language]}
                    </CheckBox>
                  ))}
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.coatingMethod"
              title="Coating method"
            >
              <InputLabel>
                {Object.values(CoatingMethod)
                  .filter(
                    (e) =>
                      activeBooking?.projectType &&
                      getCoatingMethodSchema(e).projectTypes?.includes(
                        activeBooking.projectType
                      )
                  )
                  .map((coatingMethod) => (
                    <CheckBox
                      borderSize='x1'
                      checked={
                        form.state.attributes.coatingMethod === coatingMethod
                      }
                      key={coatingMethod}
                      margin="x1"
                      onChange={handleSetAttributeFromValue(
                        'coatingMethod',
                        coatingMethod
                      )}
                    >
                      {getCoatingMethodSchema(coatingMethod).name[language]}
                    </CheckBox>
                  ))}
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.legalStatus"
              title="Legal status"
            >
              <InputLabel>
                {Object.values(LegalStatus).map((legalStatus) => (
                  <CheckBox
                    borderSize='x1'
                    checked={form.state.attributes.legalStatus === legalStatus}
                    key={legalStatus}
                    margin="x1"
                    onChange={handleSetAttributeFromValue(
                      'legalStatus',
                      legalStatus
                    )}
                  >
                    {legalStatus}
                  </CheckBox>
                ))}
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.yearConstructed"
              title="Year constructed"
            >
              <InputLabel>
                <Input
                  value={
                    form.state.attributes.yearConstructed === undefined
                      ? ''
                      : form.state.attributes.yearConstructed
                  }
                  onChange={handleSetAttributeFromTextInputAsNumber(
                    'yearConstructed'
                  )}
                  type="number"
                />
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.yearRenewed"
              title="Year Renewed"
            >
              <InputLabel>
                <Input
                  value={
                    form.state.attributes.yearRenewed === undefined
                      ? ''
                      : form.state.attributes.yearRenewed
                  }
                  onChange={handleSetAttributeFromTextInputAsNumber(
                    'yearRenewed'
                  )}
                  type="number"
                />
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.nodeOneCoordinate"
              title="Node one coordinate"
            >
              <InputLabel>
                <Input
                  value={form.state.attributes.nodeOneCoordinate || ''}
                  onChange={handleSetAttributeFromTextInput(
                    'nodeOneCoordinate'
                  )}
                />
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.nodeOneRef"
              title="Node one reference"
            >
              <InputLabel>
                <Input
                  value={form.state.attributes.nodeOneRef || ''}
                  onChange={handleSetAttributeFromTextInput('nodeOneRef')}
                />
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.nodeTwoCoordinate"
              title="Node two coordinate"
            >
              <InputLabel>
                <Input
                  value={form.state.attributes.nodeTwoCoordinate || ''}
                  onChange={handleSetAttributeFromTextInput(
                    'nodeTwoCoordinate'
                  )}
                />
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.nodeTwoRef"
              title="Node two reference"
            >
              <InputLabel>
                <Input
                  value={form.state.attributes.nodeTwoRef || ''}
                  onChange={handleSetAttributeFromTextInput('nodeTwoRef')}
                />
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.nodeThreeCoordinate"
              title="Node three coordinate"
            >
              <InputLabel>
                <Input
                  value={form.state.attributes.nodeThreeCoordinate || ''}
                  onChange={handleSetAttributeFromTextInput(
                    'nodeThreeCoordinate'
                  )}
                />
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.nodeThreeRef"
              title="Node three reference"
            >
              <InputLabel>
                <Input
                  value={form.state.attributes.nodeThreeRef || ''}
                  onChange={handleSetAttributeFromTextInput('nodeThreeRef')}
                />
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.pipeLengthRef"
              title="Pipe length reference"
            >
              <InputLabel>
                <InputLabel>
                  <Input
                    value={form.state.attributes.pipeLengthRef || ''}
                    onChange={handleSetAttributeFromTextInput('pipeLengthRef')}
                  />
                </InputLabel>
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.locationTypeCode"
              title="Location type code"
            >
              <InputLabel>
                {Object.values(LocationTypeCode)
                  .filter(
                    (e) =>
                      activeBooking?.projectType &&
                      getLocationTypeCodeSchema(e).projectTypes?.includes(
                        activeBooking.projectType
                      )
                  )
                  .map((locationTypeCode) => (
                    <CheckBox
                      borderSize='x1'
                      checked={
                        form.state.attributes.locationTypeCode ===
                        locationTypeCode
                      }
                      key={locationTypeCode}
                      margin="x1"
                      onChange={handleSetAttributeFromValue(
                        'locationTypeCode',
                        locationTypeCode
                      )}
                    >
                      {
                        getLocationTypeCodeSchema(locationTypeCode).name[
                          language
                        ]
                      }
                    </CheckBox>
                  ))}
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.drainSewerType"
              title="Drain sewer type"
            >
              <InputLabel>
                {Object.values(DrainSewerType).map((drainSewerType) => (
                  <CheckBox
                    borderSize='x1'
                    checked={
                      form.state.attributes.drainSewerType === drainSewerType
                    }
                    key={drainSewerType}
                    margin="x1"
                    onChange={handleSetAttributeFromValue(
                      'drainSewerType',
                      drainSewerType
                    )}
                  >
                    {getDrainSewerTypesSchema(drainSewerType).name[language]}
                  </CheckBox>
                ))}
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.pipeUnitLength"
              title="Pipe unit length"
            >
              <InputLabel>
                <Input
                  value={form.state.attributes.pipeUnitLength || ''}
                  onChange={handleSetAttributeFromTextInput('pipeUnitLength')}
                />
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.expectedLength"
              title="Expected length"
            >
              <DistanceInput
                name="attributes.expectedLength"
                onChange={handleSetAttribute('expectedLength')}
                type="long"
                value={form.state.attributes.expectedLength}
              />
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.upNorthing"
              title="Up Nothing"
            >
              <InputLabel>
                <Input
                  value={form.state.attributes.upNorthing || ''}
                  onChange={handleSetAttributeFromTextInput('upNorthing')}
                />
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep id="attributes.upEasting" title="Up Easting">
              <InputLabel>
                <Input
                  value={form.state.attributes.upEasting || ''}
                  onChange={handleSetAttributeFromTextInput('upEasting')}
                />
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.upElevation"
              title="Up Elevation"
            >
              <InputLabel>
                <Input
                  value={form.state.attributes.upElevation || ''}
                  onChange={handleSetAttributeFromTextInput('upElevation')}
                />
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.downNorthing"
              title="Down Nothing"
            >
              <InputLabel>
                <Input
                  value={form.state.attributes.downNorthing || ''}
                  onChange={handleSetAttributeFromTextInput('downNorthing')}
                />
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.downEasting"
              title="Down Easting"
            >
              <InputLabel>
                <Input
                  value={form.state.attributes.downEasting || ''}
                  onChange={handleSetAttributeFromTextInput('downEasting')}
                />
              </InputLabel>
            </ProjectTypeWizardStep>
            <ProjectTypeWizardStep
              id="attributes.downElevation"
              title="Down Elevation"
            >
              <InputLabel>
                <Input
                  value={form.state.attributes.downElevation || ''}
                  onChange={handleSetAttributeFromTextInput('downElevation')}
                />
              </InputLabel>
            </ProjectTypeWizardStep>

            <ProjectTypeWizardStep
              id="attributes.upRimToInvert"
              title="Up Rim to Invert"
            >
              <InputLabel>
                <Input
                  value={form.state.attributes.upRimToInvert || ''}
                  onChange={handleSetAttributeFromTextInput('upRimToInvert')}
                />
              </InputLabel>
            </ProjectTypeWizardStep>

            <ProjectTypeWizardStep
              id="attributes.upRimToGrade"
              title="Up Rim to Grade"
            >
              <InputLabel>
                <Input
                  value={form.state.attributes.upRimToGrade || ''}
                  onChange={handleSetAttributeFromTextInput('upRimToGrade')}
                />
              </InputLabel>
            </ProjectTypeWizardStep>

            <ProjectTypeWizardStep
              id="attributes.upGradeToInvert"
              title="Up Grade to Invert"
            >
              <InputLabel>
                <Input
                  value={form.state.attributes.upGradeToInvert || ''}
                  onChange={handleSetAttributeFromTextInput('upGradeToInvert')}
                />
              </InputLabel>
            </ProjectTypeWizardStep>

            <ProjectTypeWizardStep
              id="attributes.downRimToInvert"
              title="Down Rim to Invert"
            >
              <InputLabel>
                <Input
                  value={form.state.attributes.downRimToInvert || ''}
                  onChange={handleSetAttributeFromTextInput('downRimToInvert')}
                />
              </InputLabel>
            </ProjectTypeWizardStep>

            <ProjectTypeWizardStep
              id="attributes.downRimToGrade"
              title="Down Rim to Grade"
            >
              <InputLabel>
                <Input
                  value={form.state.attributes.downRimToGrade || ''}
                  onChange={handleSetAttributeFromTextInput('downRimToGrade')}
                />
              </InputLabel>
            </ProjectTypeWizardStep>

            <ProjectTypeWizardStep
              id="attributes.downGradeToInvert"
              title="Down Grade to Invert"
            >
              <InputLabel>
                <Input
                  value={form.state.attributes.downGradeToInvert || ''}
                  onChange={handleSetAttributeFromTextInput(
                    'downGradeToInvert'
                  )}
                />
              </InputLabel>
            </ProjectTypeWizardStep>

            <WizardReviewStep>
              <SectionAdditionalSummary section={form.state} />
            </WizardReviewStep>
          </ModalBody>

          <ModalFooter>
            <WizardStepError />
            <WizardControls />
          </ModalFooter>
        </Modal>
      </Wizard>
    </FormProvider>
  );
};

export default SectionAdditionalModal;
