import { AlertProps } from 'preshape';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

type Notification = {
  color: AlertProps['color'];
  message: string;
};

type NotificationsContextProps = {
  hasNotification: boolean;
  notifications: Notification[];
  notify: (notification: Notification) => void;
};

const NotificationsContext = createContext<NotificationsContextProps>({
  hasNotification: false,
  notifications: [],
  notify: () => {},
});

export const useNotify = () => useContext(NotificationsContext).notify;
export const useNotifications = () => useContext(NotificationsContext);

const Notifications = (props: PropsWithChildren<{}>) => {
  const refTimeout = useRef<number>(0);
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const startNotificationRemoveTimeout = () => {
    cancelNotificationRemoveTimeout();
    refTimeout.current = window.setTimeout(() => {
      setNotifications((notifications) => {
        if (notifications.length > 1) {
          startNotificationRemoveTimeout();
        }

        return notifications.slice(1);
      });
    }, 2000); // TODO: Smartly calculate the lifespan of a notifications
  };

  const cancelNotificationRemoveTimeout = () => {
    if (refTimeout.current) {
      window.clearTimeout(refTimeout.current);
    }
  };

  const handleShowNotification = (notification: Notification) => {
    setNotifications((notifications) => [...notifications, notification]);
    startNotificationRemoveTimeout();
  };

  useEffect(() => {
    return () => cancelNotificationRemoveTimeout();
  }, []);

  return (
    <>
      <NotificationsContext.Provider
        {...props}
        value={{
          hasNotification: notifications.length > 0,
          notifications,
          notify: handleShowNotification,
        }}
      />
    </>
  );
};

export default Notifications;
