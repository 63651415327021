import { LanguageCodes } from '@drainify/types';
import {
  getRepairMapForInspection,
  getRepairReccomendationsSchema,
} from '@drainify/utils';
import { Box, CheckBox, Text } from 'preshape';
import React, { useContext } from 'react';
import ObservationView from '../Observations/ObservationView';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import { WizardContext } from '../Wizard/Wizard';
import WizardStep from '../Wizard/WizardStep';

type Props = {
  inspectionId: string;
};

const JobRepairWizardStep = ({ inspectionId }: Props) => {
  const { reportEditor } = useReportEditorContext();
  const { activeStepId, flow } = useContext(WizardContext);
  const inspection = reportEditor.getInspectionByUid(inspectionId);
  const section = reportEditor.getSectionByUid(inspection?.sectionUid);
  const repairMap = getRepairMapForInspection(reportEditor, inspection!.uid);

  React.useEffect(() => {
    if (activeStepId === inspectionId && flow === 'create') {
      reportEditor.updateInspection(inspectionId, {
        repairs: Array.from(repairMap.keys()),
      });
    }
  }, [activeStepId]);

  if (!inspection) {
    return null;
  }

  return (
    <WizardStep id={inspection.uid}>
      <Text strong size="x6">
        `{reportEditor.getSectionName(section)}/{' '}
        {reportEditor.getInspectionName(inspection)}`
      </Text>
      <Box flex="vertical" gap="x16">
        {Array.from(repairMap.keys()).map((repair) => (
          <Box key={repair}>
            {repairMap.get(repair)?.map((o) => (
              <ObservationView key={o.uid} observation={o} />
            ))}
            <CheckBox
              checked={reportEditor
                .getInspectionByUid(inspectionId)
                ?.repairs?.includes(repair)}
              onChange={() => {
                const inspection =
                  reportEditor.getInspectionByUid(inspectionId);
                if (inspection?.repairs?.includes(repair)) {
                  reportEditor.updateInspection(inspectionId, {
                    repairs: inspection?.repairs?.filter((e) => e !== repair),
                  });
                } else {
                  reportEditor.updateInspection(inspectionId, {
                    repairs: [...(inspection?.repairs || []), repair],
                  });
                }
              }}
            >
              {getRepairReccomendationsSchema(repair).name[LanguageCodes.EN]}
            </CheckBox>
          </Box>
        ))}
      </Box>
    </WizardStep>
  );
};

export default JobRepairWizardStep;
