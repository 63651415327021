import {
  Appear,
  AppearProps,
  Box,
  BoxProps,
  useResizeObserver,
} from 'preshape';
import React, { PropsWithChildren, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useMapContext } from '../Map';

type Position = 'bottom' | 'top-left' | 'top-right';

type MapContentAreaProps = BoxProps & {
  position: Position;
  visible?: boolean;
};

const MapContentArea = ({
  height,
  padding,
  position,
  visible,
  ...rest
}: PropsWithChildren<MapContentAreaProps>) => {
  const { addPadding, isInteractive, isReady, container } = useMapContext();
  const [size, ref, node] = useResizeObserver();

  useEffect(() => {
    if (node) {
      const { width } = node.getBoundingClientRect();

      if (position === 'top-left') {
        return addPadding({
          left: width,
        });
      } else if (position === 'top-right') {
        return addPadding({
          right: width,
        });
      }
    }
  }, [addPadding, position, size, node]);

  if (!isReady) {
    return null;
  }

  const animations: Record<Position, AppearProps['animation']> = {
    bottom: 'FadeSlideUp',
    'top-left': 'FadeSlideRight',
    'top-right': 'FadeSlideLeft',
  };

  if (!container || !container.parentElement) {
    return null;
  }

  return createPortal(
    <Box
      absolute={position}
      flex="vertical"
      height={height}
      maxHeight="100%"
      overflow="hidden"
      padding={padding}
      ref={ref}
      style={{ pointerEvents: isInteractive ? 'all' : 'none' }}
    >
      <Appear
        {...rest}
        animation={position ? animations[position] : 'Pop'}
        flex="vertical"
        gap="x4"
        shrink
        maxHeight="100%"
        minHeight="0"
        visible={visible}
      />
    </Box>,
    container.parentElement
  );
};

export default MapContentArea;
