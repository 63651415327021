import { Scale } from '@drainify/types';
import {
  getMidPointBetweenPoints,
  getSectionPoints,
  ReportElementType,
} from '@drainify/utils';
import round from 'lodash.round';
import { Box, Icons, Label, Text } from 'preshape';
import React, { PropsWithChildren, useMemo } from 'react';
import Distance from '../DistanceInput/Distance';
import { useMapLayersContext } from '../Map/MapLayers/MapLayers';
import MapLabel from '../Map/MapMarker/MapLabel';
import MapMarker from '../Map/MapMarker/MapMarker';
import MapMarkerToolbar from '../Map/MapMarker/MapMarkerToolbar';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import useDrawMeasurementScaleMap from '../Report/ReportMap/ReportMapElements/useDrawMeasurementScaleMap';
import { useReportMapContext } from '../Report/ReportMap/ReportMapProvider';

type Props = {
  scale: Scale;
};

const ReportMapMeasurementScale = ({ scale }: PropsWithChildren<Props>) => {
  const { reportEditor } = useReportEditorContext();
  const { isLayerVisible } = useMapLayersContext();
  const { focusedElementType, hasFeature, focusScale } = useReportMapContext();

  const { startPoint, endPoint } = scale;

  useDrawMeasurementScaleMap({
    pointA: scale.startPoint,
    pointB: scale.endPoint,
    visible: true,
  });

  const handleStartDragEnd = (point: GeoJSON.Point) => {
    reportEditor.updateScale({
      startPoint: point,
      geoLength: Number(getSectionPoints(point, endPoint).distance?.toFixed(2)),
    });
  };

  const handleEndDragEnd = (point: GeoJSON.Point) => {
    reportEditor.updateScale({
      endPoint: point,
      geoLength: Number(
        getSectionPoints(startPoint, point).distance?.toFixed(2)
      ),
    });
  };

  const visible = isLayerVisible(ReportElementType.SCALE, scale.uid);

  useMemo<GeoJSON.LineString | undefined>(() => {
    if (startPoint && endPoint) {
      return {
        type: 'LineString',
        coordinates: [startPoint.coordinates, endPoint.coordinates],
      };
    }
  }, [startPoint, endPoint]);

  const midPoint = React.useMemo(
    () =>
      startPoint && endPoint && getMidPointBetweenPoints(startPoint, endPoint),
    [startPoint, endPoint]
  );

  return (
    <>
      <MapLabel id={'scale'} point={midPoint} visible={visible}>
        <MapMarkerToolbar>
          <Label
            onClick={focusScale}
            alignChildren="middle"
            flex="vertical"
            size="x1"
            backgroundColor="positive-shade-3"
          >
            {scale.userLength && (
              <Text weak textColor="black">
                <Distance
                  inline
                  type="long"
                  value={round(scale.userLength, 2)}
                />
              </Text>
            )}
          </Label>
        </MapMarkerToolbar>
      </MapLabel>
      {focusedElementType === ReportElementType.SCALE && (
        <>
          <MapMarker
            point={scale.startPoint}
            onDragEnd={
              hasFeature('Elements:move') ? handleStartDragEnd : undefined
            }
          >
            <Box backgroundColor="accent-shade-5" borderRadius="full">
              <Icons.Circle />
            </Box>
          </MapMarker>
          <MapMarker
            point={scale.endPoint}
            onDragEnd={
              hasFeature('Elements:move') ? handleEndDragEnd : undefined
            }
          >
            <Box backgroundColor="accent-shade-5" borderRadius="full">
              <Icons.Circle />
            </Box>
          </MapMarker>
        </>
      )}
    </>
  );
};

export default ReportMapMeasurementScale;
