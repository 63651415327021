import bbox from '@turf/bbox';
import bboxPolygon from '@turf/bbox-polygon';
import centroid from '@turf/centroid';
import distance from '@turf/distance';
import transformScale from '@turf/transform-scale';
import { Box, Button, Buttons, Icons, Text, Image } from 'preshape';
import React from 'react';
import { createPortal } from 'react-dom';
import What3Words from '../Location/What3Words';
import { useMapContext } from '../Map/Map';
import PlaceholderBlock from '../Placeholders/PlaceholderBlock';
import PlaceholderGradientAnimation from '../Placeholders/PlaceholderGradientAnimation';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import { MarkType } from './NodeMapProvider';

type Props = {
  bounds?: GeoJSON.BBox;
  visible: boolean;
  isCustomerReport: boolean;
  markType: MarkType;
  handleOnAddPoint: (point: GeoJSON.Point) => void;
  handleOnRemovePoint: (point: GeoJSON.Point) => void;
  handleLinkPoint: () => void;
  handleOnClear: () => void;
  selectedPoint: GeoJSON.Point | null;
  measuringPoints: GeoJSON.Point[];
  drawingPoints: GeoJSON.Point[];
  closed: () => boolean;
};

const NodeMapButtons = ({
  isCustomerReport,
  handleOnAddPoint,
  handleOnRemovePoint,
  handleOnClear,
  handleLinkPoint,
  markType,
  selectedPoint,
  measuringPoints,
  drawingPoints,
  closed,
}: Props) => {
  const minimapContiner = document.getElementById('google-map')!;
  const { reportEditor } = useReportEditorContext();
  const { toggleMapType, getBounds, mapCenter, isMoving, mapType } =
    useMapContext();
  const isBlackout = 
              reportEditor.report?.plan?.isToScale !== undefined &&
              reportEditor.report?.plan?.isToScale === false


  if (!bbox || !minimapContiner) {
    return null;
  }

  if(markType === 'plan' && isBlackout) {
    return null;
  }

  if (markType === 'plan' || isCustomerReport) {
    return createPortal(
      <Buttons flex="horizontal" alignChildrenHorizontal="around" margin="x3">
        {!isCustomerReport && (
          <Button
            color="accent"
            size="x1"
            onClick={() => {
              const bounds = getBounds();
              if (bounds && bounds.bbox) {
                const shrinkCoords = transformScale(
                  {
                    type: 'Polygon',
                    coordinates: bboxPolygon(bounds.bbox).geometry.coordinates,
                  },
                  0.9
                );
                reportEditor.updateBounds({
                  type: 'Polygon',
                  coordinates: shrinkCoords.coordinates,
                });
              }
            }}
          >
            <Icons.Maximize size="1.5rem" />
          </Button>
        )}

        <Button size="x1" onClick={toggleMapType}>
          {mapType === google.maps.MapTypeId.HYBRID ? (
            <Icons.Globe size="1.5rem" />
          ) : (
            <Icons.Map size="1.5rem" />
          )}
        </Button>
      </Buttons>,
      minimapContiner
    );
  }

  return createPortal(
    <Box
      paddingTop="x2"
      paddingBottom={markType === 'drawing-point' ? 'x8' : 'x0'}
    >
      <Box flex="vertical" gap="x3">
        <Box flex="horizontal" alignChildrenHorizontal="around">
          {!isMoving && mapCenter && !isBlackout && <What3Words point={mapCenter} /> }  
          
          { isMoving || !mapCenter && !isBlackout &&
            <Text alignChildrenVertical="middle" flex="horizontal" gap="x1">
              <Text>
                <Image
                  height="1.5rem"
                  src={require('../../../../assets/vendors/what3words.svg')}
                  width="1.5rem"
                />
              </Text>

              <Box grow width="150px">
                <PlaceholderGradientAnimation animating>
                  <PlaceholderBlock />
                </PlaceholderGradientAnimation>
              </Box>
            </Text>
          }

          {markType === 'measurement-point' && (
            <Box
              style={{ position: 'absolute', top: '0px' }}
              padding="x2"
              borderRadius="15px"
              backgroundColor="text-shade-3"
            >
              <Text strong textColor="white">
                {(measuringPoints.length > 0
                  ? [
                      ...measuringPoints.map((e, i) =>
                        i === measuringPoints.length - 1
                          ? 0
                          : distance(e, measuringPoints[i + 1], {
                              units: 'meters',
                            })
                      ),
                      distance(
                        mapCenter,
                        measuringPoints[measuringPoints.length - 1],
                        {
                          units: 'meters',
                        }
                      ),
                    ].reduce((sum, current) => sum + current, 0)
                  : 0.0
                ).toFixed(2)}
                m
              </Text>
            </Box>
          )}
        </Box>

        <Buttons flex="horizontal" alignChildrenHorizontal='around'>
          {selectedPoint && markType === 'measurement-point' && (
            <>
              <Button
                color="negative"
                variant="primary"
                size="x1"
                onClick={() => handleOnRemovePoint(selectedPoint)}
              >
                <Icons.XCircle size="1.5rem" />
              </Button>
            </>
          )}
          {selectedPoint && markType === 'drawing-point' && (
            <>
              <Button
                color="negative"
                variant="primary"
                disabled={closed()}
                size="x1"
                onClick={() => handleOnRemovePoint(selectedPoint)}
              >
                <Icons.XCircle size="1.5rem" />
              </Button>
            </>
          )}
          {selectedPoint &&
            !closed() &&
            selectedPoint === drawingPoints[0] &&
            markType === 'drawing-point' &&
            drawingPoints.length > 2 && (
              <>
                <Button
                  color="accent"
                  variant="primary"
                  size="x1"
                  onClick={handleLinkPoint}
                >
                  <Icons.Link2 size="1.5rem" />
                </Button>
              </>
            )}

          {
            !isBlackout &&
            <Button size="x1" onClick={toggleMapType}>
              {mapType === google.maps.MapTypeId.HYBRID ? (
                <Icons.Globe size="1.5rem" />
              ) : (
                <Icons.Map size="1.5rem" />
              )}
            </Button>
          }
          {!selectedPoint && (
            <Button
              color="positive"
              variant="primary"
              disabled={markType === 'drawing-point' && closed()}
              size="x1"
              onClick={() => {
                // Dispatch/Trigger the event on the document
                const bbox = getBounds()?.bbox;
                if (bbox) {
                  const polygon = bboxPolygon(bbox);

                  // Get the centroid of the polygon
                  const center = centroid(polygon);

                  handleOnAddPoint(center.geometry);
                }
              }}
            >
              <Icons.PlusCircle size="1.5rem" />
            </Button>
          )}
          {markType !== 'node' && (
            <Button
              size="x1"
              onClick={handleOnClear}
              disabled={
                (markType === 'drawing-point' && drawingPoints.length === 0) ||
                (markType === 'measurement-point' &&
                  measuringPoints.length === 0)
              }
            >
              <Icons.Trash2 size="1.5rem" />
            </Button>
          )}
        </Buttons>
      </Box>
    </Box>,

    minimapContiner
  );
};

export default NodeMapButtons;
