import { Button, ButtonProps } from 'preshape';
import React from 'react';
import useBooking from '../../hooks/useBooking';
import ConfirmationSubmitModal from '../../utils/ConfirmationSubmitModal';
import { useProjectContext } from '../Project/ProjectProvider';

type Props = ButtonProps & {};

const JobReturnInProgressButton = ({ ...rest }: Props) => {
  const { activeBookingId } = useProjectContext();
  const { query, update } = useBooking(activeBookingId);

  const [retractSubmissionModalVisible, setRetractSubmissionModalVisible] =
    React.useState(false);

  const handleJobReturnToInProgress = async () => {
    if (query.data) {
      await update.mutateAsync({
        ...query.data,
        submittedAt: null,
      });
    }
  };

  if (!query.data) {
    return null;
  }

  return (
    <>
      <Button
        color="negative"
        variant="primary"
        onClick={() => setRetractSubmissionModalVisible(true)}
        {...rest}
      >
        Reopen
      </Button>
      <ConfirmationSubmitModal
        action={handleJobReturnToInProgress}
        subheading="This will place the job back into 'in progress'"
        visible={retractSubmissionModalVisible}
        onClose={() => setRetractSubmissionModalVisible(false)}
      />
    </>
  );
};

export default JobReturnInProgressButton;
