export type SignupRequestState = 'pending' | 'finished';

export type SignupRequest = {
  uid: string;
  organisationName: string;
  fullName: string;
  email: string;
  state: SignupRequestState;
  password?: string;
};

export type SignupRequestPostBody = Omit<SignupRequest, 'uid' | 'state'>;
