import { Box, Image, Text, TextProps } from 'preshape';
import React, { Fragment, useEffect, useState } from 'react';
import { getWhat3WordsAddress } from '../../utils/what3words';
import PlaceholderBlock from '../Placeholders/PlaceholderBlock';
import PlaceholderGradientAnimation from '../Placeholders/PlaceholderGradientAnimation';

type Props = TextProps & {
  point?: GeoJSON.Point;
};

const What3Words = ({ point, ...rest }: Props) => {
  const [what3Words, setWhat3Words] = useState('');

  const updateWhat3Words = async (point: GeoJSON.Point) => {
    const [lng, lat] = point.coordinates;

    setWhat3Words(await getWhat3WordsAddress(lng, lat));
  };

  useEffect(() => {
    if (point) {
      updateWhat3Words(point);
    }
  }, [point]);

  return (
    <Text {...rest} alignChildrenVertical="middle" flex="horizontal" gap="x1">
      <Text>
        <Image
          height="1.5rem"
          src={require('../../../../assets/vendors/what3words.svg')}
          width="1.5rem"
        />
      </Text>

      {what3Words ? (
        <Text size="x3">
          {what3Words.split('.').map((word, index, array) => (
            <Fragment key={word}>
              <Text inline paddingHorizontal="x1" strong>
                {word}
              </Text>

              {index !== array.length - 1 && (
                <Text inline textColor="text-shade-4">
                  •
                </Text>
              )}
            </Fragment>
          ))}
        </Text>
      ) : (
        <Box grow>
          <PlaceholderGradientAnimation animating>
            <PlaceholderBlock />
          </PlaceholderGradientAnimation>
        </Box>
      )}
    </Text>
  );
};

export default What3Words;
