import { SitePhoto } from '@drainify/types';
import { getFullFilePath } from '@drainify/utils';
import {
  Box,
  Button,
  Buttons,
  Icons,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
  TextArea,
} from 'preshape';
import React, { ChangeEvent } from 'react';
import { FULL_SCREEN_MODAL_WIDTH } from '../App/App';
import Divider from '../Divider/Divider';
import ImageFromUrl from '../Image/ImageFromUrl';
import { useReportEditorContext } from '../Report/ReportEditorProvider';

type Props = {
  sitePhoto: SitePhoto;
  canRemove?: boolean;
};

const SitePhotoView = ({ sitePhoto, canRemove = true }: Props) => {
  const [editModalVisible, setEditModalVisible] = React.useState(false);
  const { reportEditor } = useReportEditorContext();
  const [ description, setDescription] = React.useState(sitePhoto.annotation)


  const remove = () => {
    reportEditor.removeSitePhoto(sitePhoto.uid);
    setEditModalVisible(false);
  };

  const update = () => {
    reportEditor.updateSitePhoto(sitePhoto.uid, {
      annotation: description
    })
    setEditModalVisible(false)
  }

  return (
    <>
      <Box
        padding="x1"
        minWidth="50px"
        maxWidth="44vw"
        wrap
        borderRadius="5px"
        onClick={() => setEditModalVisible(true)}
      >
        <ImageFromUrl
          src={getFullFilePath(sitePhoto.imageUrl)}
          height="150px"
          borderRadius="0px"
        />
        <Divider />
        <Box wrap minHeight="50px">
          <Text size="x1" wrap padding="x3">
            {sitePhoto.annotation}
          </Text>
        </Box>
      </Box>
      <Modal
        visible={editModalVisible}
        onClose={() => setEditModalVisible(false)}
      >
        <ModalHeader>Site photo</ModalHeader>
        <ModalBody width={FULL_SCREEN_MODAL_WIDTH}>
          <Box flex='vertical' gap='x3'>
            <ImageFromUrl
              src={getFullFilePath(sitePhoto.imageUrl)}
              height="300px"
            />
            {
              canRemove ?  
              <TextArea 
                value={description}
                rows={3}
                placeholder="Optional description"
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                  setDescription(e.target.value)
                }
              >
              </TextArea>
              :
            <Text size="x1" wrap padding="x3">
              {sitePhoto.annotation}
            </Text>
            }
        </Box>
        </ModalBody>
        {canRemove && (
          <ModalFooter>
            <Buttons alignChildrenHorizontal='around'>
              <Button variant="primary" color='accent' onClick={update} size='x2'>
                <Icons.Check /> Update
              </Button>
              <Button variant="primary" color="negative" onClick={remove} size='x2'>
                <Icons.Trash2 /> Remove
              </Button>
          </Buttons>
          </ModalFooter>
        )}
      </Modal>
    </>
  );
};

export default SitePhotoView;
