import { AccountState, SubscriptionTierDetails } from '@drainify/types';
import { formatFileSize } from '@drainify/utils';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import {
  Box,
  Button,
  CheckBox,
  Text,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Alert,
  Icons,
  Label,
  Buttons,
} from 'preshape';
import React, { useContext } from 'react';
import useSubscriptionsDetails from '../../hooks/useSubscriptionsDetails';
import { stripePaymentWithFallback } from '../../utils/payments';
import { labelColor } from '../../utils/subscriptionTiers';
import AsyncButton from '../AsyncButton/AsyncButton';
import { AuthenticationContext } from '../Authentication/Authenticate';
import Logo from '../Logo/Logo';
import { useOrgContext } from '../Org/OrgProvider';
import { useHasPermission } from '../Permissions/Permissions';
import { useUsageContext } from './UsageProvider';

export interface UpgradePackageWrapper {
  upgradePlan?: string;
}

const UsageChangePlan = () => {
  const canUpgrade = useHasPermission('purchase:upgrade');
  const [visible, setVisible] = React.useState(false);
  const { org } = useOrgContext();
  const stripe = useStripe();
  const elements = useElements();
  const { usage, refetch } = useUsageContext();
  const [error, setError] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);
  const { user } = useContext(AuthenticationContext);
  const [confirmationVisible, setConfirmationVisible] = React.useState(false);
  const { query: subscriptionQuery } = useSubscriptionsDetails();
  const userSubscription = subscriptionQuery.data?.data.find(
    (e) => e.tier === usage?.tier
  );
  const [selectedSubscription, setSelectedSubscription] =
    React.useState<SubscriptionTierDetails>();

  React.useEffect(() => {
    setSelectedSubscription(undefined);
    setError(undefined);
    setLoading(false);
  }, [visible]);

  const handleSelect = (upgradeTarget: string) => {
    setSelectedSubscription(
      subscriptionQuery.data?.data.find((e) => e.tier === upgradeTarget)
    );
  };
  if (!stripe || !canUpgrade) {
    return null;
  }

  const handleSave = async () => {
    await stripePaymentWithFallback({
      onError: setError,
      onLoading: setLoading,
      onSuccess: async () => {
        refetch?.();
        setConfirmationVisible(true);
      },
      user,
      stripe,
      elements,
      payload: { upgradePackage: selectedSubscription?.tier },
      endpoint: '/payment/upgrade',
      loading,
    });
  };

  if (!org || !userSubscription) {
    return null;
  }

  return (
    <>
      <Button
        onClick={() => setVisible(true)}
        variant="tertiary"
        color="accent"
      >
        Change plan
      </Button>
      {visible && (
        <>
          <Modal
            animation="FadeSlideUp"
            margin="x4"
            // onClose={onClose}
            onClose={() => setVisible(false)}
            overlayBackgroundCloseOnClick={false}
            visible={visible}
          >
            <ModalHeader>
              <ModalTitle> Change plan </ModalTitle>
            </ModalHeader>

            <ModalBody flex="vertical" alignChildrenVertical="middle">
              <Box flex="vertical" gap="x3">
                <Box>
                  <Text size="x6" strong>
                    Change plan
                  </Text>
                </Box>
                {subscriptionQuery.data?.data
                  .filter(
                    (e) =>
                      e.tier !== usage?.tier ||
                      usage?.state === AccountState.TRIAL
                  )
                  .sort((a, b) => (a.price < b.price ? -1 : 1))
                  .map((e) => (
                    <CheckBox
                      borderSize="x1"
                      checked={e.tier === selectedSubscription?.tier}
                      key={e.tier}
                      onChange={() => {
                        handleSelect(e.tier);
                      }}
                    >
                      <Box flex="horizontal" alignChildrenHorizontal="around">
                        <Box flex="horizontal" gap="x2">
                          <Box shrink>
                            <Text textColor="accent-shade-5">
                              <Logo height={50} />
                            </Text>
                            <Label
                              size="x1"
                              style={{
                                position: 'relative',
                                top: '-10px',
                                left: '10px',
                                ...labelColor(e),
                              }}
                            >
                              {e.displayName}
                            </Label>
                          </Box>
                        </Box>
                        <Box flex="vertical">
                          <Text size="x2" weak>
                            {e.credits} Survey/s
                          </Text>
                          <Text size="x2" weak>
                            {formatFileSize(e.storage, true, 0)}
                            Storage
                          </Text>
                          <Text size="x2" weak>
                            {`Monthly price £${(e.price / 100).toFixed(2)}`}
                          </Text>
                        </Box>
                        {e.price > userSubscription.price ||
                        usage?.state === AccountState.TRIAL ? (
                          <Box
                            textColor="positive-shade-5"
                            flex="vertical"
                            alignChildrenHorizontal="middle"
                            alignChildrenVertical="middle"
                          >
                            <Icons.ChevronUp size="2rem" />
                            <Text size="x2">UPGRADE</Text>
                          </Box>
                        ) : (
                          <Box
                            textColor="negative-shade-5"
                            flex="vertical"
                            alignChildrenHorizontal="middle"
                            alignChildrenVertical="middle"
                          >
                            <Icons.ChevronDown size="2rem" />
                            <Text size="x2">DOWNGRADE</Text>
                          </Box>
                        )}
                      </Box>
                    </CheckBox>
                  ))}

                <Alert
                  maxWidth="350px"
                  flex="vertical"
                  gap="x2"
                  padding="x3"
                  color={'accent'}
                  backgroundColor="accent-shade-1"
                >
                  <Text size="x1">Billing cycle will be unaffected</Text>
                  <Text size="x1">
                    Upgrading: Your subscription level will be upgraded right
                    away, and you'll be charged the price difference for this
                    period
                  </Text>
                  <Text size="x1">
                    Downgrading: Any unused usage will be added to your monthly
                    allowance and reset when the next period ends
                  </Text>
                </Alert>
              </Box>
              <Box>
                {error && (
                  <Alert
                    alignChildrenVertical="middle"
                    backgroundColor="negative-shade-2"
                    color="negative"
                    flex="horizontal"
                    gap="x3"
                    padding="x3"
                  >
                    <Icons.AlertTriangle />
                    <Text basis="0" grow size="x2">
                      {`There was an issue: ${error}`}
                    </Text>
                  </Alert>
                )}
              </Box>
            </ModalBody>
            <ModalFooter>
              <Buttons>
                <AsyncButton
                  grow
                  isError={error !== undefined}
                  isLoading={loading}
                  color="positive"
                  variant="primary"
                  disabled={selectedSubscription === undefined}
                  onClick={async () => {
                    setLoading(true);
                    handleSave();
                  }}
                >
                  <Icons.CreditCard /> Confirm change
                </AsyncButton>
              </Buttons>
            </ModalFooter>
          </Modal>
          <Modal
            visible={confirmationVisible}
            onClose={() => {
              setConfirmationVisible(false);
              setVisible(false);
            }}
          >
            <ModalHeader>Success</ModalHeader>
            <ModalBody>
              <Box flex="vertical">
                <Box flex="vertical" gap="x3">
                  <Box flex="horizontal" alignChildrenHorizontal="middle">
                    <Text textColor="positive-shade-5" align="middle">
                      <Icons.CheckCircle size="5rem" alignChildren="middle" />
                    </Text>
                  </Box>
                  Subscription updated
                </Box>
              </Box>
            </ModalBody>
            <ModalFooter>
              <Box flex="horizontal">
                <Button
                  grow
                  color="accent"
                  onClick={() => {
                    setConfirmationVisible(false);
                    setVisible(false);
                  }}
                  variant="primary"
                >
                  Close
                </Button>
              </Box>
            </ModalFooter>
          </Modal>
        </>
      )}
    </>
  );
};

export default UsageChangePlan;
