import { ResponseError, SurveyReview } from '@drainify/types';
import { useQuery } from 'react-query';
import useFetch, { hasAllParams } from '../components/Api/useFetch';

type Opts = {
  noQuery?: boolean;
};

const useSurveyReviewsMember = (memberId?: string, { noQuery }: Opts = {}) => {
  const fetch = useFetch();
  const key = [`survey-reviews/member/${memberId}`, fetch];
  const params = {  memberId: memberId as string };

  const query = useQuery<SurveyReview[], ResponseError>(
    key,
    () => { 
      return fetch('/survey-reviews/member/:memberId', { method: 'GET', params })
    },
    { 
      enabled: hasAllParams(params) && !noQuery, 
    },
  );

  return { query };
};

export default useSurveyReviewsMember;
