"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSitePhoto = void 0;
/**
 * Identity function for determining between an SitePhoto and
 * an SitePhotoBody
 */
const isSitePhoto = (sitePhoto) => {
    return !!sitePhoto && 'uid' in sitePhoto;
};
exports.isSitePhoto = isSitePhoto;
