import { ResponseError } from '@drainify/types';
import {
  Attributes,
  Appear,
  Box,
  Button,
  ButtonProps,
  Icons,
  Tooltip,
} from 'preshape';
import React, {
  Dispatch,
  forwardRef,
  PropsWithChildren,
  Ref,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import Spinner from '../Spinner/Spinner';

type Props = ButtonProps & {
  error?: ResponseError | Error | null;
  isError?: boolean;
  isLoading?: boolean;
  isSuccess?: boolean;
};

const AsyncButton = (
  {
    children,
    error,
    isError,
    isLoading,
    isSuccess,
    variant = 'secondary',
    ...rest
  }: PropsWithChildren<Attributes<HTMLButtonElement, Props>>,
  ref: Ref<HTMLButtonElement>
) => {
  const refPrevIsError = useRef(isError);
  const refPrevIsSuccess = useRef(isSuccess);
  const [showSadFace, setShowSadFace] = useState(false);
  const [showHappyFace, setShowHappyFace] = useState(false);
  const refTimeout = useRef<number>(0);

  const setTimeoutState = (setState: Dispatch<SetStateAction<boolean>>) => {
    setState(true);
    window.clearTimeout(refTimeout.current);
    refTimeout.current = window.setTimeout(() => {
      setState(false);
    }, 2500);
  };

  useEffect(() => {
    if (isError && refPrevIsError.current === false) {
      refPrevIsError.current = true;
      setTimeoutState(setShowSadFace);
    } else {
      refPrevIsError.current = false;
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess && refPrevIsSuccess.current === false) {
      refPrevIsSuccess.current = true;
      setTimeoutState(setShowHappyFace);
    } else {
      refPrevIsSuccess.current = false;
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isLoading) {
      setShowSadFace(false);
      setShowHappyFace(false);
    }
  }, [isLoading]);

  useEffect(() => {
    return () => {
      window.clearTimeout(refTimeout.current);
    };
  }, []);

  return (
    <Button
      {...rest}
      variant={
        isLoading || showHappyFace || showSadFace ? 'secondary' : variant
      }
      color={
        (showSadFace && 'negative') ||
        (showHappyFace && 'positive') ||
        rest.color
      }
      container
      ref={ref}
    >
      <Appear
        absolute="center"
        animation="Pop"
        visible={isLoading || showSadFace || showHappyFace}
      >
        <Box absolute="center">
          {isLoading && <Spinner />}

          {showHappyFace && <Icons.Smile size="1.5rem" />}

          {showSadFace && <Icons.Frown size="1.5rem" />}
        </Box>
      </Appear>

      <Appear
        alignChildrenVertical="middle"
        animation="Pop"
        gap="x2"
        flex="horizontal"
        visible={!isLoading && !showSadFace && !showHappyFace}
      >
        {children}
      </Appear>

      {error && (
        <Tooltip content={error.message || 'Something went wrong'}>
          {(props) => (
            <Appear absolute="top-right">
              <Icons.AlertCircle
                {...props}
                backgroundColor="negative-shade-5"
                borderRadius="full"
                size="1.5rem"
                style={{ transform: 'translate(50%, -50%)' }}
              />
            </Appear>
          )}
        </Tooltip>
      )}
    </Button>
  );
};

export default forwardRef(AsyncButton);
