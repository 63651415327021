import { Org } from '@drainify/types';
import { getFullFilePath } from '@drainify/utils';
import { Box, BoxProps, Text, TextProps } from 'preshape';
import React, { PropsWithChildren } from 'react';
import Avatar from '../Avatar/Avatar';
import PlaceholderAvatar from '../Placeholders/PlaceholderAvatar';
import PlaceholderBlock from '../Placeholders/PlaceholderBlock';

type Props = TextProps & {
  org?: Org;
  large?: boolean;
};

const OrgView = ({
  org,
  large = false,
  ...rest
}: PropsWithChildren<BoxProps & Props>) => {
  const textSize = large ? 'x4' : 'x3';
  const avatarSize = large ? '4rem' : '3rem';

  return (
    <Text {...rest} alignChildrenVertical="start" flex="horizontal" gap="x4">
      <Box alignChildrenVertical="middle" flex="horizontal" gap="x4" grow>
        <Box>
          {org ? (
            <Avatar
              src={getFullFilePath(org.logoUrlCropped)}
              uid={org.uid}
              size={avatarSize}
            />
          ) : (
            <PlaceholderAvatar size={avatarSize} />
          )}
        </Box>

        <Box basis="0" grow>
          {org ? (
            <>
              <Text size={textSize}>{org.name}</Text>
              <Text size={textSize} textColor="text-shade-4">
                {org.description}
              </Text>
            </>
          ) : (
            <>
              <PlaceholderBlock />
              <PlaceholderBlock />
            </>
          )}
        </Box>
      </Box>
    </Text>
  );
};

export default OrgView;
