import {
  Inspection,
  Node,
  OrgRole,
  Section,
  VideoTranscodeStatus,
} from '@drainify/types';
import { getBookingState, BookingStateCode } from '@drainify/utils';
import confetti from 'canvas-confetti';
import {
  Box,
  Button,
  ButtonProps,
  Buttons,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from 'preshape';
import React from 'react';
import useMeOrgMember from '../../hooks/useMeOrgMember';
import BuddyReviewTool from '../BuddyReviewTool/BuddyReviewTool';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import BookingSubjobCreateButton from './BookingSubjobCreateButton';
import JobErrorReportButton from './JobErrorReportButton';
import JobFinishButton from './JobFinishButton';
import JobPDFGenerateButton from './JobPDFGenerateButton';
import JobReturnToAwaitingQAButton from './JobReturnToAwaitingQAButton';
import JobReturnInProgressButton from './JobReturnToInProgressButton';
import JobSubmitButton from './JobSubmitButton';
import JobVideoValidation from './JobVideoValidation';

type Props = ButtonProps & {};

const JobActionButtons = ({ ...rest }: Props) => {
  const [hasError, setError] = React.useState(false);
  const { reportEditor } = useReportEditorContext();
  const { activeBooking } = useProjectContext();
  const [nodeErrors, setNodeErrors] = React.useState<Node[]>([]);
  const [inspectionErrors, setInspectionErrors] = React.useState<Inspection[]>([]);
  const [sectionErrors, setSectionErrors] = React.useState<Section[]>([]);
  const [pendingVideo, setPendingVideo] = React.useState(false);

  const validate = async () => {
    return new Promise<boolean>((ok) => {
      return setTimeout(() => {
         const inspections = reportEditor.report.inspections.filter(
           (e) => e.jobId === activeBooking?.uid
         );

        const localNodeErrors: Node[] = [];

        const localSectionErrors: Section[] = [];

       const localInspectionErrors: Inspection[] = [];

        for (const node of reportEditor.report.nodes) {
          const errors = reportEditor.getNodeError(node.uid);
          if (errors) {
            localNodeErrors.push(node);
          }
        }

        for (const section of reportEditor.report.sections) {
          if (reportEditor.getSectionError(section.uid)) {
            localSectionErrors.push(section);
          }
        }

         for (const inspection of inspections) {
          // TODO - Make sure section has length
           if (reportEditor.getInspectionError(inspection.uid)) {
             localInspectionErrors.push(inspection);
           }
         }

        setInspectionErrors(localInspectionErrors);
        setNodeErrors(localNodeErrors);
        setSectionErrors(localSectionErrors);
        setError(
          (localNodeErrors && localNodeErrors.length > 0) ||
            (localSectionErrors && localSectionErrors.length > 0)  ||
             (localInspectionErrors && localInspectionErrors.length > 0)
        );

        const pendingVideo =
          reportEditor
            .getJobInspections(activeBooking!.uid)
            .filter((e) => e.video?.status === VideoTranscodeStatus.PENDING)
            .length > 0;

        setPendingVideo(pendingVideo);

        if (
          !(
            (localNodeErrors && localNodeErrors.length > 0) ||
            (localSectionErrors && localSectionErrors.length > 0)  ||
             (localInspectionErrors && localInspectionErrors.length > 0)
          )
        ) {
        }
        return ok(
          !(localNodeErrors && localNodeErrors.length > 0) ||
            (localSectionErrors && localSectionErrors.length > 0)  ||
             (localInspectionErrors && localInspectionErrors.length > 0)
        );
      }, 0);
    });
  };

  const { query: meQuery } = useMeOrgMember();
  const ref = React.useRef<HTMLCanvasElement>(null);
  const [showConfirmation, setShowConfirmation] = React.useState(false);

  const handleOnSubmit = () => {
    setShowConfirmation(true);
    showConfetti();
  };

  const showConfetti = () => {
    if (ref.current) {
      const con = confetti.create(ref.current, {
        resize: true,
        useWorker: true,
      });
      con({
        particleCount: 500,
        origin: { y: 0.5 },
        spread: 360,
      });
    }
  };

  if (pendingVideo) {
    return <JobVideoValidation revalidate={validate} />;
  }

  if (!activeBooking || !meQuery.data) {
    return null;
  }

  const role = meQuery.data.role;
  const state = getBookingState(activeBooking);
  if (showConfirmation) {
    return (
      <>
        <Box
          absolute="edge-to-edge"
          ref={ref}
          style={{ pointerEvents: 'none', zIndex: 10 }}
          tag="canvas"
        />
        <Modal visible={true}>
          <ModalHeader>Well done!</ModalHeader>
          <ModalBody>
            <Text>
              {state === BookingStateCode.COMPLETED && 'Job is now completed'}
              {state === BookingStateCode.SUBMITTED && 'Job is now submitted'}
            </Text>
          </ModalBody>
          <ModalFooter>
            <Buttons>
              <Button
                basis="0"
                grow
                size="x3"
                onClick={() => setShowConfirmation(false)}
                variant='primary'
                color='accent'
                >
                <Text>Close</Text>
              </Button>

            </Buttons>
          </ModalFooter>
        </Modal>
      </>
    );
  }

  return (
    <>
      {hasError && (
        <JobErrorReportButton
          sectionErrors={sectionErrors}
          inspectionErrors={inspectionErrors}
          nodeErrors={nodeErrors}
          validate={validate}
        />
      )}
      {!hasError &&
        (state === BookingStateCode.IN_PROGRESS ||
          state === BookingStateCode.REPORT_OVERDUE ||
          state === BookingStateCode.PENDING) && (
          <JobSubmitButton
            {...rest}
            onSubmit={handleOnSubmit}
            validate={validate}
          />
        )}


      {state === BookingStateCode.SUBMITTED && role === OrgRole.ENGINEER && (
        <JobReturnInProgressButton {...rest} />
      )}

      {state === BookingStateCode.SUBMITTED &&
        (role === OrgRole.OWNER || role === OrgRole.ADMIN) && (
          <>
            {!hasError && (
              <JobFinishButton {...rest} onSubmit={handleOnSubmit} />
            )}
            <JobReturnInProgressButton {...rest} />
          </>
        )}
      {state === BookingStateCode.COMPLETED &&
        (role === OrgRole.OWNER || role === OrgRole.ADMIN) && (
          <>
            {!hasError && <JobPDFGenerateButton {...rest} />}
            <JobReturnToAwaitingQAButton {...rest} />
          </>
        )}
      {activeBooking.childJobId === undefined &&
        state === BookingStateCode.COMPLETED &&
        (role === OrgRole.ADMIN || role === OrgRole.OWNER) && (
          <BookingSubjobCreateButton
            parentJobId={activeBooking.uid}
            assignedTo={activeBooking.assignedTo}
            defaultJobId={activeBooking.jobId}
          />
        )}
        {
          (state !== BookingStateCode.IN_PROGRESS && state !== BookingStateCode.REPORT_OVERDUE && state !== BookingStateCode.UNASSIGNED) && <BuddyReviewTool />
        }

    </>
  );
};

export default JobActionButtons;
