import { Booking, Project } from '@drainify/types';
import { getProjectLocation } from '@drainify/utils';
import { Box } from 'preshape';
import React from 'react';
import Address from '../Location/Address';
import PlaceholderBlock from '../Placeholders/PlaceholderBlock';
import PlaceholderLabel from '../Placeholders/PlaceholderLabel';
import ProjectRef from './ProjectRef';
import ProjectStatus from './ProjectStatus';

type Props = {
  project?: Project;
  booking?: Booking;
};

const ProjectView = ({ project, booking }: Props) => {
  const location = getProjectLocation(project);

  return (
    <Box flex="vertical" gap="x2">
      <Box flex="horizontal" gap="x4">
        <Box basis="0" grow>
          {booking ? (
            <ProjectRef margin="x2" project={booking} />
          ) : (
            <PlaceholderLabel />
          )}
        </Box>

        <Box>
          <ProjectStatus booking={booking} />
        </Box>
      </Box>

      <Box flex="horizontal" gap="x4">
        <Box basis="0" grow>
          {location ? (
            <Address location={location} size="x3" />
          ) : (
            <>
              <PlaceholderBlock />
              <PlaceholderBlock />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ProjectView;
