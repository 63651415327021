import { Box, BoxProps } from 'preshape';
import React, { PropsWithChildren } from 'react';

const LayoutMain = (props: PropsWithChildren<BoxProps>) => {
  return (
    <Box
      {...props}
      basis="0"
      grow
      flex="vertical"
      style={{ overflowY: 'auto', overflowX: 'hidden' }}
    />
  );
};

export default LayoutMain;
