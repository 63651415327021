import {
  CritcatalityGrade,
  FlowControlMeasures,
  MethodOfInspection,
  PhotographImageStorageFormat,
  PreCleaned,
  PurposeOfInspection,
  Temperature,
  VideoImageStorageMedia,
  Weather,
} from './Inspection';
import { InspectionMarker, ObservationCode } from './Observation';
import {
  DrainSewerType,
  LiningType,
  LocationTypeCode,
  Ownership,
  PLRSuffix,
  SectionMaterialCode,
  SectionShapeCode,
  SectionUseCode,
} from './Section';

export interface WincanXMLImport {
  exportdata: WincanXMLExportData;
}

export interface WincanXMLExportData {
  section: WincanXMLSection[];
}

export interface WincanXMLSection {
  minicamsectioncomments?: string;
  minicamsummary?: string;
  minicamxmlname?: string;
  pcdcityname?: string;
  pcdconstructionyear?: number;
  pcddrainarea?: string;
  pcdenddepth?: number;
  pcdendnode?: string;
  pcdfinalcategory?: string;
  pcdflowcontrol?: FlowControlMeasures;
  pcdliningmaterial?: string;
  pcdliningtype?: string;
  pcdlocation?: string;
  pcdmiddepth?: string;
  pcdmunicipal?: string;
  pcdnumber?: string;
  pcdoveralcostfactor?: string;
  pcdplr?: PLRSuffix;
  pcdpipedimension1: number;
  pcdpipedimension2?: number;
  pcdpipejointlength?: number;
  pcdpipelength?: number;
  pidlengthestimated?: number;
  pcdpipematerial: SectionMaterialCode;
  pcdpipeshape: SectionShapeCode;
  pcdprojectname?: string;
  pcdrepaircostfactor?: string;
  pcdsectionlength?: string;
  pcdsoiltype?: string;
  pcdstartdepth?: number;
  pcdstartnode: string;
  pcdstartstreetname?: string;
  pcdtakingoffreference?: string;
  pcdtrafficflow?: string;
  pcdtrafficgrade?: string;
  pcdtype?: DrainSewerType;
  pcdusage?: SectionUseCode;
  sectioninspection: WincanXMLInspection;
  sectionname?: string;
}

export interface WincanXMLInspection {
  minicamlastcontdefectnumber?: string;
  minicamnumofobs?: string;
  pidcleaning?: PreCleaned;
  purposeofinspection?: PurposeOfInspection;
  methodofinspection?: MethodOfInspection;
  pidclient?: string;
  pidcontractor?: string;
  piddate?: string;
  piddirection?: string;
  pidendnode?: string;
  pidequipment?: string;
  pidjobid?: string;
  pidlength?: string;
  pidlengthestimated?: string;
  pidmethod?: MethodOfInspection;
  pidoperator?: string;
  pidpurpose?: PurposeOfInspection;
  pidremarks?: string;
  pidstartnode?: string;
  pidtemperature?: Temperature;
  pidtime?: string;
  pidweather?: Weather;
  sectionobservation?: WincanXMLSectionObservation[];
}

export interface WincanXMLSectionObservation {
  minicamvideoframecount?: string;
  podpicture1?: string;
  podphoto1?: string;
  podquantification1?: number;
  podfromclock?: number;
  podtoclock?: number;
  podremarks?: string;
  podcode: ObservationCode;
  poddistance: number;
  podcontinuousdefect: string;
  podmovie?: string;
  podmpegposition?: string;
  podtext?: string;
}

export interface MinaXMLImport {
  surveygroup: MinaXMLSurveyGroup;
}

export interface MinaXMLSurveyGroup {
  survey: MinaXMLSurvey[];
}

export interface MinaXMLSurvey {
  header: MinaXMLSurveyHeader;
  observations: MinaXMLObservations;
}

export interface MinaXMLSurveyHeader {
  client: string;
  nameofsurveyor: string;
  pipelinelengthref: string;
  date: string;
  time: string;
  locationstreet: string;
  locationtown: string;
  startnoderef: string;
  node1ref: string;
  depthatstartnode?: number;
  depthatfinishnode?: number;
  finishnoderef: string;
  typeofdrainsewer?: DrainSewerType;
  direction: string;
  useofdrainsewer: SectionUseCode;
  shape: SectionShapeCode;
  material: SectionMaterialCode;
  liningmaterial: LiningType;
  precleaned?: PreCleaned;
  flowcontrolmeasures?: FlowControlMeasures;
  weather?: Weather;
  methodofinspection?: MethodOfInspection;
  standard: string;
  heightdiameter: number;
  width?: number;
  videoimagefilename: string;

  locationtypecode?: LocationTypeCode;
  landownership?: Ownership;
  verticalref?: string;
  circumferentialref?: string;
  useofmanhole?: string;
  generalremarks?: string;
  shapeofcover?: string;
  materialofcover?: string;
  widthofcover?: string;
  breadthofcover?: string;
  materialofsteps?: string;
  critical?: string;
  purposeofinspection?: PurposeOfInspection;
  temperature?: Temperature;
  chamberunitlength: string;
  yearconstructed?: number;
  videoimagestorage?: VideoImageStorageMedia;
  videoimagelocationsystem?: string;
  videoimagevolume?: string;
  photographicstorage?: PhotographImageStorageFormat;
  photographicvolume?: string;
  nodetype?: string;
  locationplanimagefilename?: string;
  drainagearea?: string;
  criticaldrainsewer?: CritcatalityGrade;
  pipeunitlength?: string;
  expectedlength?: number;
  divisionDistrict?: string;
}

export interface MinaXMLObservations {
  observation: MinaXMLObservation[];
}

export interface MinaXMLObservation {
  videoref?: string;
  photographrefs?: MinaXMLObservationPhotoRef;
  distance?: number;
  continuousdefect: string;
  drainifyinspectionmarker?: InspectionMarker;
  code: ObservationCode;
  percentage?: number;
  remarks?: string;
  dimension1?: number;
  clockrefatfrom?: number;
  clockrefto?: number;
}

export interface MinaXMLObservationPhotoRef {
  photographref?: string;
}
