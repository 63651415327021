import {
  Box,
  Button,
  Buttons,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from 'preshape';
import React from 'react';
import { FULL_SCREEN_MODAL_WIDTH } from '../components/App/App';

type Props = {
  content: string;
  subheading: string;
  action: () => void;
  visible: boolean;
  onClose: () => void;
};

const ConfirmationDeleteModal = ({
  action,
  content,
  subheading,
  visible,
  onClose,
}: Props) => {
  return (
    <Modal
      animation="FadeSlideUp"
      maxWidth={FULL_SCREEN_MODAL_WIDTH}
      onClose={onClose}
      overlayBackgroundCloseOnClick={false}
      visible={visible}
    >
      <ModalHeader>Warning: Destructive action</ModalHeader>
      <ModalBody flex="vertical">
        <Box alignChildrenHorizontal="around" flex="vertical" padding="x1">
          <Box flex="vertical" alignChildrenHorizontal="middle">
            <Text strong size="x5" padding="x2">
              {content}
            </Text>
            <Text weak size="x2" padding="x2">
              {subheading || 'This action can not be undone'}
            </Text>
          </Box>
        </Box>
      </ModalBody>
      <ModalFooter>
        <Buttons alignChildrenHorizontal="around" flex="horizontal">
          <Button size="x3" grow onClick={onClose}>
            Cancel
          </Button>
          <Button
            size="x3"
            variant="primary"
            color="negative"
            grow
            elevate
            onClick={() => {
              action();
              onClose();
            }}
          >
            Delete
          </Button>
        </Buttons>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationDeleteModal;
