import { UnitSystem } from '@drainify/types';
import { useLocalStorage, useTheme, ModalManager, TypeTheme } from 'preshape';
import React, { PropsWithChildren, useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';

export type Tutorials = 'mobile-start';

export const RootContext = React.createContext<{
  onChangeUnitSystem: (unitSystem: UnitSystem) => void;
  onChangeTheme: (theme: TypeTheme) => void;
  theme: TypeTheme;
  unitSystem: UnitSystem;
  seenTutorials: Tutorials[];
  setSeenTutorials: (t: Tutorials) => void;
}>({
  onChangeUnitSystem: () => {},
  onChangeTheme: () => {},
  theme: 'day',
  unitSystem: UnitSystem.METRIC,
  seenTutorials: [],
  setSeenTutorials: () => {},
});

export const useAppContext = () => useContext(RootContext);

const Bootstrap = ({ children }: PropsWithChildren<{}>) => {
  const [theme, onChangeTheme] = useLocalStorage<TypeTheme>(
    'com.drainify.theme',
    'day'
  );

  const [unitSystem, onChangeUnitSystem] = useLocalStorage<UnitSystem>(
    'com.drainify.unitSystem',
    UnitSystem.METRIC
  );

  const [seenTutorials, onChangeSeenTutorials] = useLocalStorage<Tutorials[]>(
    'com.drainify.tutorials',
    []
  );

  const setSeenTutorials = (tutorial: Tutorials) => {
    onChangeSeenTutorials([...seenTutorials, tutorial]);
  };

  useTheme(theme);

  return (
    <BrowserRouter>
      <RootContext.Provider
        value={{
          onChangeUnitSystem,
          onChangeTheme,
          theme,
          unitSystem,
          seenTutorials,
          setSeenTutorials,
        }}
      >
        <ModalManager>{children}</ModalManager>
      </RootContext.Provider>
    </BrowserRouter>
  );
};

export default Bootstrap;
