import { Inspection } from '@drainify/types';
import { getFullVideoFilePath } from '@drainify/utils';
import { Box } from 'preshape';
import React from 'react';
import { useParams } from 'react-router';
import InspectionAdditionalSummaryVideo from '../../../components/Inspection/InspectionAdditionalSummaryVideo';
import { useReportEditorContext } from '../../../components/Report/ReportEditorProvider';
import VideoPlayerSidebarC from '../../../components/VideoPlayer/Sidebar/VideoPlayerSidebar';
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer';
import VideoPlayerSectionEditActions from '../../../components/VideoPlayer/VideoPlayerSectionEditActions';
import VideoProvider from '../../../components/VideoPlayer/VideoProvider';
import { isMobile } from '../../../utils/client';

type Props = {
  onClose?: () => void;
  inspection: Inspection;
};

const VideoPage = ({ onClose, inspection }: Props) => {
  const { projectId } = useParams();
  const { reportEditor } = useReportEditorContext();

  if (isMobile() && inspection) {
    return (
      <video autoPlay loop muted playsInline controls>
        <source
          src={getFullVideoFilePath(inspection?.video?.videoUrl)}
          type="video/mp4"
        />
      </video>
    );
  }

  if (!projectId) {
    return null;
  }

  return (
    <Box flex="horizontal" alignChildrenHorizontal="middle" gap="x2">
      <VideoProvider>
        <Box flex="vertical">
          <VideoPlayer projectId={projectId} inspectionId={inspection.uid} />
          <Box flex="vertical">
            <VideoPlayerSectionEditActions
              inspectionId={inspection.uid}
              projectId={projectId}
              onClose={onClose}
            />
            <InspectionAdditionalSummaryVideo
              section={reportEditor.getSectionByUid(
                reportEditor.getInspectionByUid(inspection.uid)?.sectionUid
              )}
            />
          </Box>
        </Box>
        <Box flex="vertical" width="340px">
          <VideoPlayerSidebarC projectId={projectId} inspectionId={inspection.uid} />
        </Box>
      </VideoProvider>
    </Box>
  );
};

export default VideoPage;
