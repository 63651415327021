import { Button, Buttons, Icons, Text } from 'preshape';
import React, { useContext } from 'react';
import AsyncButton from '../../AsyncButton/AsyncButton';
import { WizardContext } from '../Wizard';

const WizardControlsOnSummaryWithNoChanges = () => {
  const { isError, isLoading, isSuccess, onCancel, onRemove, onSave } =
    useContext(WizardContext);

  return (
    <>
      <Buttons>
        {onCancel && false && (
          <Button
            basis="0"
            grow
            onClick={onCancel}
            size="x3"
            style={{
              WebkitUserSelect: 'none',
              msUserSelect: 'none',
              userSelect: 'none',
              WebkitTouchCallout: 'none',
              KhtmlUserSelect: 'none',
              MozUserSelect: 'none',
            }}
          >
            <Text>Cancel</Text>
          </Button>
        )}

        <AsyncButton
          basis="0"
          color="accent"
          grow
          isError={isError}
          isLoading={isLoading}
          disabled={isLoading}
          id={isLoading ? 'access-disabled' : 'accel-Enter'}
          isSuccess={isSuccess}
          onClick={onSave}
          size="x3"
          variant="primary"
          style={{
            WebkitUserSelect: 'none',
            msUserSelect: 'none',
            userSelect: 'none',
            WebkitTouchCallout: 'none',
            KhtmlUserSelect: 'none',
            MozUserSelect: 'none',
          }}
        >
          <Text>Done</Text>
          <Icons.Check />
        </AsyncButton>
      </Buttons>

      {onRemove && (
        <Buttons alignChildrenHorizontal="end" margin="x4">
          <AsyncButton
            variant="tertiary"
            color="negative"
            onClick={onRemove}
            style={{
              WebkitUserSelect: 'none',
              msUserSelect: 'none',
              userSelect: 'none',
              WebkitTouchCallout: 'none',
              KhtmlUserSelect: 'none',
              MozUserSelect: 'none',
            }}
          >
            <Text>Remove</Text>
            <Icons.Trash2 />
          </AsyncButton>
        </Buttons>
      )}
    </>
  );
};

export default WizardControlsOnSummaryWithNoChanges;
