import { Booking, InspectionStage } from '@drainify/types';
import {
  CheckBox,
  FormProvider,
  Input,
  InputLabel,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalProps,
  ModalTitle,
} from 'preshape';
import React, { ChangeEvent, useEffect } from 'react';
import useBookingForm from '../../hooks/forms/useBookingForm';
import useBooking from '../../hooks/useBooking';
import { FULL_SCREEN_MODAL_WIDTH } from '../App/App';
import Wizard from '../Wizard/Wizard';
import WizardControls from '../Wizard/WizardControls/WizardControls';
import WizardReviewStep from '../Wizard/WizardReviewStep';
import WizardStep from '../Wizard/WizardStep';
import WizardStepError from '../Wizard/WizardStepError';
import JobAdditionalSummary from './JobAdditionalSummary';

type Props = ModalProps & {
  onClose: () => void;
  initialActiveStepId?: string;
  job: Booking;
};

const JobAdditionalModal = ({
  onClose,
  initialActiveStepId,
  job,
  visible,
  ...rest
}: Props) => {
  const { update } = useBooking(job.uid);
  const form = useBookingForm(job);

  const handleSave = async () => {
    update.mutateAsync(form.state);
    onClose();
  };

  const handleSetAttribute =
    <T extends keyof Booking['attributes']>(attribute: T) =>
    (value?: Booking['attributes'][T]) => {
      form.setState((s) => ({
        ...s,
        attributes: {
          ...s.attributes,
          [attribute]: value,
        },
      }));
    };

  const handleSetAttributeFromValue =
    <T extends keyof Booking['attributes']>(
      attribute: T,
      value: Booking['attributes'][T]
    ) =>
    () =>
      handleSetAttribute(attribute)(value);

  const handleSetAttributeFromTextInput =
    <T extends keyof Booking['attributes']>(attribute: T) =>
    (event: ChangeEvent<HTMLInputElement>) =>
      handleSetAttribute(attribute)(
        event.target.value as Booking['attributes'][T]
      );

  useEffect(() => {
    if (!visible) {
      form.reset();
    }
  }, [visible]);

  return (
    <FormProvider form={form}>
      <Wizard
        flow={'update'}
        onSave={handleSave}
        onCancel={onClose}
        reset={visible}
        initialActiveStepId={initialActiveStepId}
      >
        <Modal
          {...rest}
          visible={visible}
          animation="FadeSlideUp"
          margin="x4"
          maxWidth={FULL_SCREEN_MODAL_WIDTH}
          onClose={onClose}
          overlayBackgroundCloseOnClick={false}
        >
          <ModalHeader>
            <ModalTitle>{job.jobId}</ModalTitle>
          </ModalHeader>

          <ModalBody>
            <WizardStep
              id="attributes.inspectionStage"
              title="Inspection stage"
            >
              <InputLabel>
                {Object.values(InspectionStage).map((inspectionStage) => (
                  <CheckBox
                    borderSize='x1'
                    checked={
                      form.state.attributes.inspectionStage === inspectionStage
                    }
                    key={inspectionStage}
                    margin="x1"
                    onChange={handleSetAttributeFromValue(
                      'inspectionStage',
                      inspectionStage
                    )}
                  >
                    {inspectionStage}
                  </CheckBox>
                ))}
              </InputLabel>
            </WizardStep>

            <WizardStep
              id="attributes.clientDefinedOne"
              title="Client defined one"
            >
              <InputLabel>
                <Input
                  value={form.state.attributes.clientDefinedOne || ''}
                  onChange={handleSetAttributeFromTextInput('clientDefinedOne')}
                />
              </InputLabel>
            </WizardStep>

            <WizardStep
              id="attributes.clientDefinedTwo"
              title="Client defined two"
            >
              <InputLabel>
                <Input
                  value={form.state.attributes.clientDefinedTwo || ''}
                  onChange={handleSetAttributeFromTextInput('clientDefinedTwo')}
                />
              </InputLabel>
            </WizardStep>

            <WizardStep
              id="attributes.clientDefinedThree"
              title="Client defined three"
            >
              <InputLabel>
                <Input
                  value={form.state.attributes.clientDefinedThree || ''}
                  onChange={handleSetAttributeFromTextInput(
                    'clientDefinedThree'
                  )}
                />
              </InputLabel>
            </WizardStep>

            <WizardStep
              id="attributes.clientDefinedFour"
              title="Client defined four"
            >
              <InputLabel>
                <Input
                  value={form.state.attributes.clientDefinedFour || ''}
                  onChange={handleSetAttributeFromTextInput(
                    'clientDefinedFour'
                  )}
                />
              </InputLabel>
            </WizardStep>

            <WizardStep
              id="attributes.clientDefinedFive"
              title="Client defined five"
            >
              <InputLabel>
                <Input
                  value={form.state.attributes.clientDefinedFive || ''}
                  onChange={handleSetAttributeFromTextInput(
                    'clientDefinedFive'
                  )}
                />
              </InputLabel>
            </WizardStep>

            <WizardStep
              id="attributes.clientDefinedSix"
              title="Client defined six"
            >
              <InputLabel>
                <Input
                  value={form.state.attributes.clientDefinedSix || ''}
                  onChange={handleSetAttributeFromTextInput('clientDefinedSix')}
                />
              </InputLabel>
            </WizardStep>

            <WizardReviewStep>
              <JobAdditionalSummary job={form.state} />
            </WizardReviewStep>
          </ModalBody>

          <ModalFooter>
            <WizardStepError />
            <WizardControls />
          </ModalFooter>
        </Modal>
      </Wizard>
    </FormProvider>
  );
};

export default JobAdditionalModal;
