import { Box } from 'preshape';
import React from 'react';
import SignupRequestForm from '../../../components/Signup/SignupRequestForm';

const SignupRequestPage = () => {
  return (
    <Box alignChildren="middle" backgroundColor="background-shade-3" grow>
      <Box maxWidth="500px">
        <SignupRequestForm />
      </Box>
    </Box>
  );
};

export default SignupRequestPage;
