import { Customer } from '@drainify/types';
import {
  Box,
  FormProvider,
  Input,
  InputLabel,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalProps,
  ModalTitle,
} from 'preshape';
import React, { ChangeEvent, useEffect } from 'react';
import useCustomerForm from '../../hooks/forms/useCustomerForm';
import useCustomer from '../../hooks/useCustomer';
import useCustomers from '../../hooks/useCustomers';
import { FULL_SCREEN_MODAL_WIDTH } from '../App/App';
import LocationInput from '../Location/LocationInput';
import Wizard from '../Wizard/Wizard';
import WizardControls from '../Wizard/WizardControls/WizardControls';
import WizardReviewStep from '../Wizard/WizardReviewStep';
import WizardStep from '../Wizard/WizardStep';
import WizardStepError from '../Wizard/WizardStepError';
import CustomerSummary from './CustomerSummary';

type Props = ModalProps & {
  onClose: () => void;
  onCreate?: (customer: Customer) => void;
  onSave: (customer: Customer) => void;
  customer?: Customer;
  visible: boolean;
  initialActiveStepId?: string;
};

const CustomerModal = ({
  initialActiveStepId,
  onClose,
  visible,
  onSave,
  customer,
  ...rest
}: Props) => {
  const { create } = useCustomers();
  const { update } = useCustomer(customer?.uid);
  const form = useCustomerForm(customer);

  const handleSave = async () => {
    if (customer) {
      await update.mutateAsync(form.state);
      onClose();
    } else {
      const saved = await create.mutateAsync(form.state);
      onSave(saved);
      onClose();
    }
  };

  const handleRemove = async () => {
    onClose();
  };

  useEffect(() => {
    if (!visible) {
      form.reset();
    }
  }, [visible]);

  return (
    <FormProvider form={form}>
      <Wizard
        flow={customer ? 'update' : 'create'}
        initialActiveStepId={initialActiveStepId}
        isError={update.isError || create.isError}
        isLoading={update.isLoading || create.isLoading}
        isSuccess={update.isSuccess || create.isSuccess}
        onSave={handleSave}
        onRemove={handleRemove}
        onCancel={onClose}
        reset={visible}
      >
        <Modal
          {...rest}
          animation="FadeSlideUp"
          margin="x4"
          maxWidth={FULL_SCREEN_MODAL_WIDTH}
          onClose={onClose}
          overlayBackgroundCloseOnClick={false}
          visible={visible}
        >
          <ModalHeader>
            <ModalTitle>{customer ? customer.name : 'New customer'}</ModalTitle>
          </ModalHeader>

          <ModalBody flex="vertical">
            <WizardStep id="name" title="Name">
              <InputLabel>
                <Input
                  name="name"
                  placeholder="e.g. John Smith"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    form.setState((s) => ({ ...s, name: e.target.value }))
                  }
                  value={form.state.name}
                />
              </InputLabel>
            </WizardStep>

            <WizardStep id="email" title="Email">
              <InputLabel>
                <Input
                  name="email"
                  placeholder="e.g. john.smith@email.com"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    form.setState((s) => ({ ...s, email: e.target.value }))
                  }
                  value={form.state.email}
                />
              </InputLabel>
            </WizardStep>

            <WizardStep id="phoneNumber" title="Phone number">
              <Box flex="vertical" gap="x1">
                <Input
                  name="phoneNumber"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      form.setState((s) => ({
                        ...s,
                        phoneNumber: e.target.value,
                      }));
                  }}
                  value={form.state.phoneNumber}
                />
              </Box>
            </WizardStep>

            <WizardStep id="location" title="Address">
              <LocationInput
                name="location"
                onChange={(location) =>
                  form.setState((s) => ({ ...s, location }))
                }
                value={form.state.location}
              />
            </WizardStep>

            <WizardReviewStep>
              <CustomerSummary customer={form.state} />
            </WizardReviewStep>
          </ModalBody>

          <ModalFooter>
            <WizardStepError />
            <WizardControls />
          </ModalFooter>
        </Modal>
      </Wizard>
    </FormProvider>
  );
};

export default CustomerModal;
