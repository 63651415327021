import { PlanType } from '@drainify/types';
import { getRadiusFromPoint } from '@drainify/utils';
import { Box, Icons } from 'preshape';
import React from 'react';
import { useGeoLocation } from '../GeoLocation/GeoLocation';
import { useMapContext } from '../Map/Map';
import MapMarker from '../Map/MapMarker/MapMarker';
import { useReportEditorContext } from '../Report/ReportEditorProvider';

type Props = {
  showAccuracyPadding?: boolean;
};

const NodeMapUserMarker = ({ showAccuracyPadding }: Props) => {
  const { fromGeoJSONPointToScreen } = useMapContext();
  const { position } = useGeoLocation();
  const { reportEditor } = useReportEditorContext()

  if (!position || reportEditor.report.planType === PlanType.UPLOADED_PLAN) {
    return null;
  }

  let accuracyRadius = '0px';

  if (showAccuracyPadding) {
    const radius = getRadiusFromPoint(position, position.accuracy / 100);
    const min = fromGeoJSONPointToScreen({
      type: 'Point',
      coordinates: [radius[1], radius[0]],
    });

    const max = fromGeoJSONPointToScreen({
      type: 'Point',
      coordinates: [radius[3], radius[2]],
    });

    if (!min || !max) {
      return null;
    }
    accuracyRadius = Math.abs(min.x - max.x) + 'px';
  }

  return (
    <MapMarker point={position}>
      <Box
        style={{ width: accuracyRadius, height: accuracyRadius }}
        backgroundColor={'negative-shade-1'}
        borderRadius="full"
        textColor="background-shade-1"
        flex="vertical"
        alignChildrenHorizontal="middle"
        alignChildrenVertical="middle"
      >
        <Box
          backgroundColor={'negative-shade-5'}
          borderRadius="full"
          textColor="background-shade-1"
        >
          <Icons.Circle size="1rem" />
        </Box>
      </Box>
    </MapMarker>
  );
};

export default NodeMapUserMarker;
