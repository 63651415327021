import { AccountState } from '@drainify/types';
import { Box, Text, Label } from 'preshape';
import React from 'react';
import { useHasPermission } from '../Permissions/Permissions';

type Props = {
  icon: JSX.Element;
  value?: number | string;
  purchaseComponent: JSX.Element;
  state: AccountState;
};

const UsageDetailsAddOn = ({
  icon,
  value,
  purchaseComponent,
  state,
}: Props) => {
  const canPurchaseCredits = useHasPermission('purchase:credit');

  if (state !== AccountState.ACTIVE) {
    return (
      <Box
        flex="vertical"
        alignChildrenHorizontal="middle"
        filter="grayscale(3)"
        padding="x3"
      >
        <Text textColor="text-shade-4">{icon}</Text>
        <Box flex="horizontal" gap="x2">
          <Box flex="vertical" gap="x1">
            {value && (
              <Label
                alignChildrenHorizontal="middle"
                flex="horizontal"
                backgroundColor="background-shade-3"
                textColor="text-shade-4"
              >
                <Text>{value}</Text>
              </Label>
            )}
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      flex="vertical"
      alignChildrenHorizontal="middle"
      gap="x2"
      textColor="accent-shade-4"
      padding="x2"
      maxWidth="150px"
    >
      {icon}
      <Box flex="vertical" gap="x1">
        {value !== undefined && (
          <Label
            alignChildrenHorizontal="middle"
            flex="horizontal"
            backgroundColor="background-shade-3"
            textColor="text-shade-4"
          >
            <Text>{value}</Text>
          </Label>
        )}
        <Box shrink>{canPurchaseCredits && purchaseComponent}</Box>
      </Box>
    </Box>
  );
};

export default UsageDetailsAddOn;
