import { OrgRole } from '@drainify/types';
import { signOut, getAuth } from 'firebase/auth';
import { Box, Button, Buttons, Icons, Text } from 'preshape';
import React from 'react';
import Card from '../../../components/Card/Card';
import { useGeoLocation } from '../../../components/GeoLocation/GeoLocation';
import Logo from '../../../components/Logo/Logo';
import { useNotificationContext } from '../../../components/Notifications/PWANotifications';
import { useOrgContext } from '../../../components/Org/OrgProvider';
import PageContext from '../../../components/Page/PageContext';
import PlaceholderGradientAnimation from '../../../components/Placeholders/PlaceholderGradientAnimation';
import UnitSystemToggle from '../../../components/UnitSystemToggle/UnitSystemToggle';
import UsageDetails from '../../../components/Usage/UsageDetails';
import UserView from '../../../components/User/UserView';
import useMe from '../../../hooks/useMe';
import useMeOrgMember from '../../../hooks/useMeOrgMember';
import { userSettings } from '../../settingsMenu';
import Page from '../Layout/Page';
import PageBody from '../Layout/PageBody';
import PageHeader from '../Layout/PageHeader';
import PageMenu from '../Layout/PageMenu';
import PageMenuItem from '../Layout/PageMenuItem';
import SettingsPageOrg from './SettingsPageOrg';

const SettingsPageUser = () => {
  const { query: queryMe } = useMe();
  const { query: queryMeOrg } = useMeOrgMember()
  const { org } = useOrgContext()
  const { hasPermission, resetPermission } = useGeoLocation()
  const { hasPermission: hasNotificationPermission, togglePermissions } = useNotificationContext()

  return (
    <PageContext backToText="Back" backToLink="/">
      <Box
        basis="0"
        grow
        flex="vertical"
        style={{ overflowY: 'auto', overflowX: 'hidden' }}
      >
        <Page>
          <PageHeader title="Settings" />
          <PageBody>
            <PlaceholderGradientAnimation
              animating={queryMe.isFetching}
              margin="x12"
            >
              <Card title="User">
                <UserView user={queryMe.data} />
              </Card>

              <PageMenu margin="x3">
                {userSettings.map(({ Icon, title, to }) => (
                  <PageMenuItem
                    disabled={queryMe.isLoading}
                    icon={<Icon />}
                    key={to}
                    size="x2"
                    to={to}
                  >
                    {title}
                  </PageMenuItem>
                ))}
              </PageMenu>
            </PlaceholderGradientAnimation>
            <PageMenu title='Your plan'>
              <UsageDetails/>
            </PageMenu>
            { queryMeOrg.data?.role !== OrgRole.ENGINEER && <SettingsPageOrg /> }

            <PageMenu margin="x12" title="App Preferences">
              {!hasPermission &&
                <PageMenuItem>
                  <Box
                    alignChildrenVertical="middle"
                    flex="horizontal"
                    gap="x2"
                    onClick={resetPermission}
                  >
                    <Box grow>
                      <Text align="start" size="x2">
                        Allow location services
                      </Text>
                    </Box>

                    <Box>
                      <Icons.MapPin />
                    </Box>
                  </Box>
                </PageMenuItem>

              }
              {
                !hasNotificationPermission &&
                <PageMenuItem>
                  <Box
                    alignChildrenVertical="middle"
                    flex="horizontal"
                    gap="x2"
                    onClick={() => togglePermissions?.(true)}
                  >
                    <Box grow>
                      <Text align="start" size="x2">
                        Push notifications
                      </Text>
                    </Box>

                    <Box>
                      <Icons.Bell />
                    </Box>
                  </Box>
                </PageMenuItem>
              }

              <PageMenuItem>
                <UnitSystemToggle />
              </PageMenuItem>
              <PageMenuItem>
                <Box tag='a' href={`mailto:sam@drainify.io?subject=Bug report: Actor ID ${org?.uid}`}> Report a bug</Box>
              </PageMenuItem>
              <PageMenuItem>
                <Text size='x1' textColor='text-shade-1'>© 2024, Drainify, All rights reserved.</Text>
              </PageMenuItem>
            </PageMenu>

            <Buttons margin="x12">
              <Button
                grow
                onClick={() => signOut(getAuth())}
                size="x3"
                variant="primary"
              >
                Log out
              </Button>
            </Buttons>
          </PageBody>
          <Box
            padding="x2"
            flex="horizontal"
            alignChildrenHorizontal="middle"
            gap="x2"
          >
            <Text textColor="accent-shade-3" align="middle">
              <Logo height={12} />
            </Text>
            <Text weak size="x1">
              0.0.{process.env.VERSION_HASH}
            </Text>
          </Box>
        </Page>
      </Box>
    </PageContext>
  );
};

export default SettingsPageUser;
