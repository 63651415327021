import {
  PlacementManager,
  PlacementReference,
  Input,
  Icons,
  Placement,
  Options,
  Option,
  InputLabel,
  InputLabelProps,
  Text,
} from 'preshape';
import React, {
  forwardRef,
  PointerEvent,
  Ref,
  useCallback,
  useEffect,
  useState,
} from 'react';

export type DropdownOption<T> = {
  name: string | T;
  description?: string;
  value: T;
};

type Props<T> = InputLabelProps & {
  label?: string;
  maxHeight?: string;
  onChange: (value: T) => void;
  placeholder?: string;
  value: T;
  options: DropdownOption<T>[];
};

function DropdownMenu<T>(
  {
    label,
    maxHeight,
    onChange,
    options,
    placeholder,
    value,
    ...rest
  }: Props<T>,
  ref: Ref<HTMLLabelElement>
) {
  const [visible, setVisible] = useState(false);
  const selectedOption = options.find((option) => option.value === value);

  const handleSelect = ({ value }: DropdownOption<T>) => {
    onChange(value);
  };

  const handleFocus = () => {
    setVisible(true);
  };

  const handleReset = useCallback(() => {
    setVisible(false);
  }, [value]);

  useEffect(() => {
    handleReset();
  }, [handleReset]);

  useEffect(() => {}, []);

  return (
    <PlacementManager>
      <PlacementReference>
        {(props) => (
          <InputLabel {...rest} {...props} label={label} ref={ref} padding="x0">
            <Input
              padding="x0"
              addonEnd={
                visible ? (
                  <Icons.ChevronUp size="24px" />
                ) : (
                  <Icons.ChevronDown size="24px" />
                )
              }
              onChange={() => {}}
              onFocus={handleFocus}
              onPointerUp={(event: PointerEvent) =>
                event.nativeEvent.stopPropagation()
              }
              placeholder={placeholder}
              ref={props.ref}
              size="x2"
              value={
                selectedOption?.name !== undefined
                  ? `${selectedOption?.name}`
                  : ''
              }
            />
          </InputLabel>
        )}
      </PlacementReference>

      <Placement
        animation="FadeSlideDown"
        width="reference"
        options={{
          modifiers: {
            preventOverflow: {
              boundariesElement: 'window',
            },
          },
        }}
        onClose={handleReset}
        placement="bottom"
        unrender
        visible={visible}
      >
        <Options
          container
          maxHeight={maxHeight}
          name={label}
          overflow="auto"
          padding="x0"
        >
          {options.map((option) => (
            <Option
              key={`${option.name}`}
              name={label}
              onChange={() => handleSelect(option)}
              checked={option?.value === value}
              title={`${option?.name}`}
            >
              {option.name}
              {option.description && (
                <Text size="x2" textColor="text-shade-4" weak>
                  {option.description}
                </Text>
              )}
            </Option>
          ))}
        </Options>
      </Placement>
    </PlacementManager>
  );
}

export default forwardRef(DropdownMenu);
