import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import { useEffect, useState } from 'react';

export interface UseAuthResult {
  error: null | Error;
  isFetching: boolean;
  user: null | User;
}

const useAuth = (): UseAuthResult => {
  const [state, setState] = useState<UseAuthResult>({
    error: null,
    isFetching: true,
    user: null,
  });

  useEffect(() => {
    return onAuthStateChanged(
      getAuth(),
      (user) => {
        setState({
          error: null,
          isFetching: false,
          user: user,
        });
      },
      (error) => {
        setState({
          error: error,
          isFetching: false,
          user: null,
        });
      }
    );
  }, []);

  return state;
};

export default useAuth;
