import { ObservationCode } from '@drainify/types';
import { Icons } from 'preshape';
import React from 'react';

type Props = {
  code?: ObservationCode[];
  size?: string;
};

const ObservationIconMap: Partial<
  Record<ObservationCode, (props: { size?: string }) => JSX.Element | null>
> = {
  [ObservationCode.WL]: Icons.Droplet,
  [ObservationCode.WL_MSCC5_DOMESTIC]: Icons.Droplet,
  [ObservationCode.SA]: Icons.XCircle,
};

const ObservationCodeIcon = ({ code, size = '1rem', ...rest }: Props) => {
  if (code) {
    for (let i = code.length; i >= 0; i--) {
      const Icon = ObservationIconMap[code[i]];

      if (Icon) {
        return <Icon {...rest} size={size} />;
      }
    }
  }

  return <Icons.AlertCircle {...rest} size={size} />;
};

export default ObservationCodeIcon;
