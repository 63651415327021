import { UseMapResult } from '../useMap';
import { GeoStoreGeometry } from './types';
import { isGeometryLineString, isGeometryPoint } from './utils';

// Projects from lng/lat to px/px
const getGeometryProjection = <T extends GeoStoreGeometry>(
  fromGeoJSONPointToScreen: UseMapResult['fromGeoJSONPointToScreen'],
  geometry: T
): T => {
  const projectPosition = (position: GeoJSON.Position) => {
    const projection = fromGeoJSONPointToScreen({
      type: 'Point',
      coordinates: [position[0], position[1]],
    });
    return projection ? [projection.x, projection.y] : null;
  };

  if (isGeometryLineString(geometry)) {
    return {
      type: 'LineString',
      coordinates: geometry.coordinates.map(projectPosition),
    } as T;
  }

  if (isGeometryPoint(geometry)) {
    return {
      type: 'Point',
      coordinates: projectPosition(geometry.coordinates),
    } as T;
  }

  throw new Error(`Register geometry is invalid: ${JSON.stringify(geometry)}`);
};

export default getGeometryProjection;
