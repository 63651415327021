import { Match, OCRResult, OCRResultStatus } from '@drainify/types';
import { getFullFilePath } from '@drainify/utils';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import {
  Alert,
  Box,
  Button,
  Icons,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
  Toggle,
} from 'preshape';
import React from 'react';
import DistanceInput from '../components/DistanceInput/DistanceInput';
import FeatureFlag from '../components/FeatureFlag/FeatureFlag';
import ImageFromUrl from '../components/Image/ImageFromUrl';
import { useOrgContext } from '../components/Org/OrgProvider';
import Wizard from '../components/Wizard/Wizard';
import WizardControls from '../components/Wizard/WizardControls/WizardControls';
import WizardReviewStep from '../components/Wizard/WizardReviewStep';
import WizardStep from '../components/Wizard/WizardStep';
import useAIOCRResult from '../hooks/useAIOCRResult';

type Props = {
  orgId?: string;
  inspectionId?: string;
  projectId?: string;
  og: OCRResult;
  refetch?: () => void;
};

const OCRView = ({ inspectionId, projectId, og, refetch }: Props) => {
  const { org } = useOrgContext();
  const orgId = org?.uid;
  const [x, sx] = React.useState(10);
  const [y, sy] = React.useState(10);
  const [w, sw] = React.useState(20);
  const [h, sh] = React.useState(20);
  const [m, sm] = React.useState(false);

  const [poiVisible, setPoiVisible] = React.useState(false);
  const { update } = useAIOCRResult(projectId, inspectionId);
  const [ocrResult, setOCRResult] = React.useState<OCRResult>(og);

  React.useEffect(() => {
    setHarvested(false);
  }, [poiVisible]);

  const [useOldLocation, setUseOldLocation] = React.useState(false);

  const [harvested, setHarvested] = React.useState(false);
  const [harvestError, setHarvestError] = React.useState<string>();

  const handleTextChange = (index: number) => (newText?: number) => {
    const newData = [...ocrResult!.matches];
    newData[index].text = newText || 0;
    setOCRResult({
      ...ocrResult!,
      matches: newData,
    });
  };
  const getImageLocation = (item: Match, a: boolean) =>
    a
      ? `https://storage.googleapis.com/project-d-328516-surveys/${orgId}/${projectId}/${inspectionId}/frames/${item.frame}.jpeg`
      : getFullFilePath(
          `${orgId}/images/observations/${projectId}/${inspectionId}/frames/${item.frame}.jpeg`
        );

  const fetchImagesAndExport = async () => {
    try {
      const zip = new JSZip();

      zip.file('export.json', JSON.stringify(ocrResult?.matches, null, 2));
      zip.file(
        'meta.json',
        JSON.stringify({
          inspectionId: inspectionId,
          projectId: projectId,
          orgId: orgId,
          framesNo: ocrResult.matches.length,
          bounds: ocrResult.bounds,
          usedCachedBB: ocrResult.usedCachedBB,
        })
      );

      const imgFolder = zip.folder('Images');

      const imagePromises = ocrResult!.matches.map(async (item, index) => {
        const response = await fetch(getImageLocation(item, useOldLocation)!);
        if (response.status !== 200)
          throw Error(`Error fetching ${response.url}`);
        const blob = await response.blob();
        imgFolder?.file(`${index + 1}.jpeg`, blob);
      });

      await Promise.all(imagePromises);

      zip.generateAsync({ type: 'blob' }).then((content: any) => {
        saveAs(content, `${og.binarizor.split(' ').join('-')}.zip`);
      });
      setHarvested(true);
    } catch (e) {
      if (e instanceof Error) {
        setHarvestError(e.message);
      }
    }
  };

  React.useEffect(() => {
    if (poiVisible) {
      ocrResult.matches.map((e) => fetch(getImageLocation(e, useOldLocation)!));
    }
  }, [poiVisible]);

  if (!ocrResult) {
    return null;
  }

  if (ocrResult.status === OCRResultStatus.PENDING) {
    return null;
  }

  return (
    <FeatureFlag>
      <Button onClick={() => setPoiVisible(true)} flex="vertical" gap="x1">
        <Text strong>Superuser action</Text>
        <Text weak>OCR extraction</Text>
      </Button>
      {poiVisible && (
        <Wizard
          flow={'create'}
          // flow={observationUid ? 'update' : 'create'}
          onSave={async () => {
            await update.mutateAsync({
              ...og,
              harvested: true,
            });
            setPoiVisible(false);
            refetch?.();
          }}
          isLoading={!harvested}
        >
          <Modal
            visible={poiVisible}
            onClose={() => {
              setPoiVisible(false);
            }}
          >
            <ModalHeader>OCR Debug view</ModalHeader>
            <ModalBody maxWidth="100vw" height="90vh">
              <Box flex="vertical" grow overflow="auto">
                <Box flex="vertical" gap="x2">
                  {ocrResult?.matches.map((e, i) => (
                    <WizardStep key={i} id={`${i}`} noAnimate>
                      <Box flex="horizontal" alignChildrenHorizontal="around">
                        <Box width="700px" height="475px">
                          <ImageFromUrl
                            width="700px"
                            height="475px"
                            src={getImageLocation(e, useOldLocation)}
                          />
                        </Box>
                        <Text></Text>
                        <Box
                          draggable
                          style={{
                            position: 'absolute',
                            left: x + 'px',
                            top: y + 'px',
                            zIndex: '101',
                          }}
                          backgroundColor="background-shade-1"
                          borderRadius="10px"
                          width={`${w}px`}
                          height={`${h}px`}
                          onDragStart={() => {
                            sw(200);
                            sh(30);
                            sm(true);
                          }}
                          onDragEnd={(e: React.DragEvent<HTMLDivElement>) => {
                            const parent = e.currentTarget
                              .offsetParent as HTMLElement | null;
                            sw(20);
                            sh(20);
                            sm(false);

                            if (parent) {
                              const parentRect = parent.getBoundingClientRect();

                              // Calculate the new position relative to the parent container
                              const newX = e.clientX - parentRect.left;
                              const newY = e.clientY - parentRect.top;

                              // Update the position
                              sx(newX);
                              sy(newY);
                            }
                          }}
                        >
                          <Icons.Move />
                        </Box>
                        {!m && (
                          <Box
                            backgroundColor="background-shade-1"
                            flex="vertical"
                            gap="x2"
                            style={{
                              position: 'absolute',
                              left: x + 'px',
                              top: y + 'px',
                              zIndex: '100',
                            }}
                          >
                            <DistanceInput
                              name="distance"
                              maxWidth="200px"
                              onChange={handleTextChange(i)}
                              type="long"
                              value={e.text}
                            />
                            <Box
                              shrink
                              flex="vertical"
                              alignChildrenHorizontal="middle"
                            >
                              <Text size="x1">
                                {' '}
                                {i}/{ocrResult.matches.length}{' '}
                              </Text>

                              <Box width="20px">
                                <Toggle
                                  value={useOldLocation}
                                  onChange={() =>
                                    setUseOldLocation(!useOldLocation)
                                  }
                                />
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </WizardStep>
                  ))}
                  <WizardReviewStep>
                    <Button onClick={fetchImagesAndExport}>
                      Download and confirm before confirming harvest
                      <Icons.Download />
                    </Button>
                    {harvestError && (
                      <Alert
                        color="negative"
                        backgroundColor="negative-shade-2"
                        padding="x2"
                      >
                        <Text strong size="x1">
                          {harvestError}
                        </Text>
                      </Alert>
                    )}
                  </WizardReviewStep>
                </Box>
              </Box>
            </ModalBody>
            <ModalFooter>
              <WizardControls />
            </ModalFooter>
          </Modal>
        </Wizard>
      )}
    </FeatureFlag>
  );
};

export default OCRView;
